export const columnsSchema = () => [
  {
    field: "id",
    title: "ID",
    visible: true,
    minWidth: 190,
    sortable: true,
    filterable: true,
    locked: "locked",
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
  },
  {
    field: "vesselID",
    title: "Vessel ID",
    visible: true,
    minWidth: 200,
    sortable: true,
    filterable: true,
    noDisabledColumn: false,
    notShownInTableColumnSelection: false,
    locked: "locked",
  },
  {
    field: "userID",
    title: "User ID",
    visible: true,
    minWidth: 200,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    locked: "locked",
  },
  {
    field: "username",
    title: "Username",
    visible: true,
    minWidth: 300,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    locked: "locked",
  },
];
