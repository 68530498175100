import React from "react";
import {
  Grid,
  GridColumn as Column,
  GridDetailRow,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { columnsSchema } from "./schema";
import _ from "lodash";
import { Window } from "@progress/kendo-react-dialogs";
import { filterBy } from "@progress/kendo-data-query";
import { Error } from "@progress/kendo-react-labels";
import { IconButton, Tooltip } from "@material-ui/core";
import { MyCommandCell } from "../../../../Clients/View/components/CellHOC";
import GridContainer from "../../../../../components/Grid/GridContainer";
import { serverApi } from "../../../../../networking/config";
import { DropDownCell } from "../../../../CRM/View/dropDownCell";
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Switch from "@material-ui/core/Switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import DropdownFilterCell from "../../../../common/dropdownFilterCell";
import { validateEmailWithRegex } from "../../../../../lib/general/utils";
// import {
//   showCreatorOnTable,
//   formatDatesOnTable,
// } from "../../../../../lib/GeneralUtils/utils";

class CrmContacts extends GridDetailRow {
  editField = "inEdit";
  CommandCell;
  _export;

  constructor(props) {
    super(props);

    this.state = {
      dunsPlaceholder: "Duns should be 9 digits",
      selectedCountry: null,
      visibleWindow: false,
      disableCreateContact: false,
      isDropOpened: false,
      columns: (columnsSchema && columnsSchema()) || [],
      gridData: {
        data: [],
        total: 10,
      },
      initialGridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 10,
        skip: 0,
      },
      openErrorModal: false,
      errorModalContent: "",
      delay: 400,
      loading: false,
      contacts: [],
      windowError: false,
      windowErrorMessage: "",
      disableEditButton: true,

      //create properties
      contact_name: "",
      contact_country: "",
      contact_email: "",
      contact_type: "",
      wh_notification_flg: false,
      notify_stock_email: false,
      notify_stock_ws: false,
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      hasDisable: true,
      disableEditButton: false,
      showSecondAction: true,
      showFirstAction: true,
    });
  }

  async componentDidMount() {
    this.setState({
      ...this.state,
      countries: this.props.countryCodes,
      initialCountries: this.props.countryCodes,
    });

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let filter2 = {
      filter: {
        where: {
          lov_type: "CONTACT_TYPE",
        },
      },
    };

    let lov_contact_types = await serverApi(
      "GET",
      `lovs`,
      filter2,
      "",
      options
    );

    lov_contact_types.data = (lov_contact_types.data || []).map((ct) => ({
      ...ct,
      text: ct.lov_value,
    }));

    let filter1 = {
      filter: {
        where: {
          relationID: this.props.dataItem && this.props.dataItem.id,
        },
        include: {
          relation: "masterEntity",
          scope: {
            include: [
              {
                relation: "creator",
              },
            ],
          },
        },
      },
    };

    let responseData = await serverApi(
      "GET",
      `masterEntityContacts`,
      filter1,
      "",
      options
    );

    // responseData = showCreatorOnTable(
    //   responseData,
    //   "masterEntity",
    //   "creator",
    //   "username"
    // );
    // responseData = formatDatesOnTable(responseData.data, ["dateCreated"]);

    if (responseData && responseData.data.length === 1) {
      this.setState({
        ...this.state,
        clientID: this.props.dataItem.id,
        gridData: {
          data: [responseData && responseData.data[0]],
          total: 1,
        },
        initialGridData: {
          data: [responseData && responseData.data[0]],
          total: 1,
        },

        valueContactType: "",
        contactTypes: lov_contact_types.data.sort((a, b) =>
          a.lov_value > b.lov_value ? 1 : -1
        ),
      });
    } else if (responseData && responseData.data.length > 1) {
      let finalData = [];
      _.forEach(responseData.data, (temp) => {
        finalData.push(temp);
      });

      this.setState({
        ...this.state,
        clientID: this.props.dataItem.id,
        gridData: {
          data: finalData,
          total: finalData.length,
        },
        initialGridData: {
          data: finalData,
          total: finalData.length,
        },
        contactTypes: lov_contact_types.data,
      });
    } else {
      this.setState({
        ...this.state,
        clientID: this.props.dataItem.id,
        gridData: {
          data: [],
          total: 0,
        },
        initialGridData: {
          data: [],
          total: 0,
        },
        contactTypes: lov_contact_types.data,
      });
    }
  }

  renderGridColumns = () => {
    let selectionCol = [];
    let normalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            width="65px"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((x) => x.visible)
      .map((temp) => {
        if (temp.isDropdown === "yes") {
          return (
            <Column
              sortable={temp.sortable}
              locked={temp.locked}
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              filterCell={(props) => {
                return (
                  <>
                    <DropdownFilterCell
                      {...props}
                      width="120px"
                      data={[
                        { text: "Yes", value: true },
                        { text: "No", value: false },
                      ].map((x) => x.text)}
                    />
                  </>
                );
              }}
              cell={(props) => {
                let field = props.field;
                switch (field) {
                  case "client_flag":
                  case "verified_flag":
                  case "supplier_flag":
                  case "agent_flag":
                  case "wh_flag":
                  case "location_flag":
                  case "transport_flag":
                  case "sm_agent_flag":
                  case "sm_vendor_flag":
                  case "seaport_flag":
                  case "airport_flag":
                  case "stocktbc_visible":
                  case "is_active":
                    return (
                      <>
                        <DropDownCell
                          {...props}
                          width="100px"
                          filterable={false}
                          data={[
                            { text: "Yes", value: true },
                            { text: "No", value: false },
                          ].sort((a, b) => (a.text > b.text ? 1 : -1))}
                        />
                      </>
                    );

                  default:
                    break;
                }
              }}
            />
          );
        } else if (temp.field === "contact_country") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              filterCell={(props) => {
                return (
                  <>
                    <DropdownFilterCell
                      {...props}
                      filterable={true}
                      width="150px"
                      data={(this.props.countryCodes || [])
                        .map((x) => x.alpha_2)
                        .sort((a, b) => (a > b ? 1 : -1))}
                    />
                  </>
                );
              }}
              cell={(props) => {
                let data = (this.props.countryCodes || [])
                  .map((x) => ({
                    text: x.alpha_2,
                    value: x.alpha_2,
                  }))
                  .sort((a, b) => (a.text > b.text ? 1 : -1));
                return (
                  <>
                    <DropDownCell
                      {...props}
                      filterable={true}
                      width="150px"
                      data={data}
                    />
                  </>
                );
              }}
            />
          );
        } else if (temp.field === "contact_state_code") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              filterCell={(props) => {
                return (
                  <>
                    <DropdownFilterCell
                      {...props}
                      width="120px"
                      data={(this.props.usaStates || [])
                        .map((x) => x.lov_value_1)
                        .filter((x) => x)
                        .sort((a, b) => (a > b ? 1 : -1))}
                    />
                  </>
                );
              }}
              cell={(props) => {
                let stateData = (this.props.usaStates || [])
                  .map((x) => ({
                    text: x.lov_value_1,
                    value: x.lov_value_1,
                  }))
                  .filter((x) => x)
                  .sort((a, b) => (a.text > b.text ? 1 : -1))
                  .filter((obj) => obj.text !== null && obj.value !== null);
                return (
                  <>
                    <DropDownCell
                      {...props}
                      width="100px"
                      filterable={true}
                      data={stateData}
                    />
                  </>
                );
              }}
            />
          );
        } else {
          return (
            <Column
              sortable={temp.sortable}
              locked={temp.locked}
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={
                temp.field === "id" ||
                temp.field === "ar_account" ||
                temp.field === "ap_account" ||
                temp.editable
                  ? false
                  : true
              }
            />
          );
        }
      });

    return [...selectionCol, ...normalCols];
  };

  enterEdit = (dataItem) => {
    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    let total = this.state.gridData.total;

    this.setState({
      gridData: {
        data:
          this.state.gridData &&
          this.state.gridData.data.map((item) =>
            item.id === dataItem.id ? { ...item, inEdit: true } : item
          ),
        total: total,
      },
    });
  };

  discard = (dataItem, hasError = false) => {
    let data = [...this.state.gridData.data];
    const total = this.state.gridData.total;

    if (hasError) {
      data[0].inEdit = true;
      this.setState({
        gridData: {
          data: data,
          total: total,
        },
      });
    } else {
      this.removeItem(data, dataItem);

      this.setState({
        gridData: {
          data: data,
          total: total,
        },
        visible: false,
      });
    }
  };

  cancel = (dataItem) => {
    const originalItem =
      this.state.initialGridData &&
      this.state.initialGridData.data.find((p) => p.id === dataItem.id);
    originalItem.inEdit = false;

    const data =
      this.state.gridData &&
      this.state.gridData.data.map((item) =>
        item.id === originalItem.id ? originalItem : item
      );

    const total = this.state.gridData.total;

    this.setState({
      ...this.state,
      gridData: {
        data: data,
        total: total,
      },
      openErrorModal: false,
      openErrorModalContent: "",
    });
  };

  removeItem(data, item) {
    let index = data.findIndex(
      (p) => p === item || item.id || p.id === item.id
    );
    if (index >= 0) {
      data.splice(index, 1);
    }
  }

  remove = async (dataItem) => {
    const updatedItem = { ...dataItem, inEdit: undefined };
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    let editPayload = {
      is_active: false,
    };
    const editRequest = await serverApi(
      "PATCH",
      `masterEntityContacts/${updatedItem.id}`,
      {},
      editPayload,
      options
    );
    if (editRequest.status === 200) {
      let filter1 = {
        filter: {
          where: {
            relationID: this.props.dataItem && this.props.dataItem.id,
          },
          include: {
            relation: "masterEntity",
            scope: {
              include: [
                {
                  relation: "creator",
                },
              ],
            },
          },
        },
      };

      let responseData = await serverApi(
        "GET",
        `masterEntityContacts`,
        filter1,
        "",
        options
      );

      //   responseData = showCreatorOnTable(
      //     responseData,
      //     "masterEntity",
      //     "creator",
      //     "username"
      //   );
      //     responseData = formatDatesOnTable(responseData.data, ["dateCreated"]);

      this.setState({
        ...this.state,
        gridData: {
          data: responseData?.data || [],
          total: responseData?.data?.length,
        },
        visibleWindow: false,
      });
    }

    try {
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent:
          e?.response?.data?.error?.message ||
          "Delete contact has failed.Please try again and if the problem persist contact the administrator.",
      });
    }
  };

  add = (dataItem) => {};

  addNew = () => {
    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    const newDataItem = { inEdit: true };

    this.setState({
      gridData: {
        data: [newDataItem, ...this.state.gridData.data],
        total: this.state.gridData.total,
      },
      visibleWindow: true,
    });
  };

  update = async (dataItem) => {
    const updatedItem = { ...dataItem, inEdit: undefined };
    const editPayload = this.createEditPayload(updatedItem);
    const errorMsg = this.validateEditPayload(updatedItem, editPayload);

    if (errorMsg.length > 0) {
      this.showErrorModal(errorMsg);
    } else {
      try {
        const options = {
          token: window.localStorage.getItem("access_token"),
        };

        const editRequest = await this.sendEditRequest(
          updatedItem,
          editPayload,
          options
        );

        if (editRequest.status === 200) {
          const responseData = await this.fetchUpdatedData();
          this.updateStateAfterSuccessfulEdit(responseData);
        }
      } catch (e) {
        this.handleEditError(e, dataItem);
      }
    }
  };

  createEditPayload = (updatedItem) => {
    let errorMsg = [];
    const editPayload = {
      contact_type: updatedItem.contact_type,
      contact_address: updatedItem.contact_address,
      contact_city: updatedItem.contact_city,
      contact_phone: updatedItem.contact_phone,
      contact_postal_code: updatedItem.contact_postal_code,
      contact_name: updatedItem.contact_name,
      contact_country: updatedItem.contact_country,
      contact_email: updatedItem.contact_email,
      is_active: updatedItem.is_active,
    };

    if (updatedItem.contact_type === "BILLING_CONTACT") {
      editPayload.ein_number = updatedItem.ein_number;
      editPayload.ss_number = updatedItem.ss_number;
      editPayload.tsa_number = updatedItem.tsa_number;
      editPayload.duns = updatedItem.duns;
      editPayload.company_name = updatedItem.company_name;
      editPayload.contact_vat_number = updatedItem.contact_vat_number || null; // Set to null if not provided
    } else if (updatedItem.contact_country !== "US") {
      editPayload.contact_vat_number = updatedItem.contact_vat_number || null; // Set to null if not provided
    }

    if (updatedItem.contact_country !== "US") {
      editPayload.contact_vat_number = updatedItem.contact_vat_number;
    }

    if (updatedItem.contact_country === "US") {
      editPayload.contact_state_code = updatedItem.contact_state_code;
      editPayload.ein_number = updatedItem.ein_number;
      editPayload.ss_number = updatedItem.ss_number;

      if (!updatedItem.contact_state_code) {
        errorMsg.push("State");
      }

      if (updatedItem.contact_type === "BILLING_CONTACT") {
        if (!updatedItem.ein_number && !updatedItem.ss_number) {
          errorMsg.push("EIN or SS");
        }
      }
    }

    return editPayload;
  };

  validateEditPayload = (updatedItem, editPayload) => {
    const errorMsg = [];

    // eslint-disable-next-line no-unused-vars
    for (const key in editPayload) {
      if (editPayload.hasOwnProperty(key)) {
        if (key === "website_url" && editPayload[key] === "") {
          // Allow an empty website URL (you can also allow null here if needed)
          continue;
        }
        if (!editPayload[key] && key !== "is_active") {
          errorMsg.push(key);
        }
      }
    }

    if (
      updatedItem.contact_type !== "BILLING_CONTACT" &&
      updatedItem.contact_country === "US"
    ) {
      editPayload.contact_vat_number = null;
    }

    // let errorMsgCamelCase = errorMsg.map((key) => key.split("_").join(" "));

    //no validation for those
    editPayload.website_url = this.state.website_url;

    if (updatedItem.contact_country !== "US") {
      editPayload.contact_state_code = null;
      if (updatedItem.contact_type !== "BILLING_CONTACT") {
        editPayload.ein_number = null;
        editPayload.ss_number = null;
      }
    }

    if (editPayload.duns) {
      const pattern = new RegExp("^\\d{9}$");
      if (!editPayload.duns.match(pattern)) {
        errorMsg.push("Duns should be 9 digits");
      }
    }

    if (!validateEmailWithRegex(updatedItem.contact_email)) {
      errorMsg.push("Invalid email format");
    }

    return errorMsg;
  };

  showErrorModal = (errorMsg) => {
    const errorMsgCamelCase = errorMsg.map((key) => key.split("_").join(" "));
    const messageErr = `Required fields to update a contact: ${errorMsgCamelCase.join(
      ", "
    )}`;

    this.setState({
      ...this.state,
      openErrorModal: true,
      errorModalContent: messageErr,
    });
  };

  sendEditRequest = async (updatedItem, editPayload, options) => {
    return await serverApi(
      "PATCH",
      `masterEntityContacts/${updatedItem.id}`,
      {},
      editPayload,
      options
    );
  };

  fetchUpdatedData = async () => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    const filter1 = {
      filter: {
        where: {
          relationID: this.props.dataItem && this.props.dataItem.id,
        },
        include: {
          relation: "masterEntity",
          scope: {
            include: [
              {
                relation: "creator",
              },
            ],
          },
        },
      },
    };

    return await serverApi("GET", `masterEntityContacts`, filter1, "", options);
  };

  updateStateAfterSuccessfulEdit = (responseData) => {
    this.setState({
      ...this.state,
      gridData: {
        data: responseData?.data || [],
        total: responseData?.data?.length,
      },
      visibleWindow: false,
      openErrorModal: false,
      errorModalContent: "",
    });
  };

  handleEditError = (e, dataItem) => {
    this.setState(
      {
        ...this.state,
        openErrorModal: true,
        errorModalContent:
          e?.response?.data?.error?.message ||
          "Update contact process has failed. Please check again the inputs.",
      },
      () => this.discard(dataItem, true)
    );
  };

  // update = async (dataItem) => {
  //   const data = [...this.state.gridData.data];
  //   const updatedItem = { ...dataItem, inEdit: undefined };
  //   this.updateItem(data, updatedItem);
  //   let options = {
  //     token: window.localStorage.getItem("access_token"),
  //   };

  //   let editPayload = {
  //     contact_type: updatedItem.contact_type,
  //     contact_address: updatedItem.contact_address,
  //     contact_city: updatedItem.contact_city,
  //     contact_phone: updatedItem.contact_phone,
  //     contact_postal_code: updatedItem.contact_postal_code,
  //     contact_name: updatedItem.contact_name,
  //     contact_country: updatedItem.contact_country,
  //     contact_email: updatedItem.contact_email,
  //     is_active: updatedItem.is_active,
  //   };

  //   let errorMsg = [];
  //   let otherErrors = [];

  //   if (updatedItem.contact_type === "BILLING_CONTACT") {
  //     editPayload.ein_number = updatedItem.ein_number;
  //     editPayload.ss_number = updatedItem.ss_number;
  //     editPayload.tsa_number = updatedItem.tsa_number;
  //     editPayload.duns = updatedItem.duns;
  //     editPayload.company_name = updatedItem.company_name;
  //     editPayload.contact_vat_number = updatedItem.contact_vat_number;
  //   }

  //   // Check for VAT Number for non-US countries
  //   if (updatedItem.contact_country !== "US") {
  //     editPayload.contact_vat_number = updatedItem.contact_vat_number;
  //   }

  //   if (updatedItem.contact_country === "US") {
  //     editPayload.contact_state_code = updatedItem.contact_state_code;
  //     editPayload.ein_number = updatedItem.ein_number;
  //     editPayload.ss_number = updatedItem.ss_number;

  //     if (!updatedItem.contact_state_code) {
  //       errorMsg.push("State");
  //     }

  //     // Check for EIN or SS
  //     if (updatedItem.contact_type === "BILLING_CONTACT") {
  //       if (!updatedItem.ein_number && !updatedItem.ss_number) {
  //         errorMsg.push("EIN or SS");
  //       }
  //     }
  //   }

  //   // eslint-disable-next-line no-unused-vars
  //   for (const key in editPayload) {
  //     if (editPayload.hasOwnProperty(key)) {
  //       if (!editPayload[key]) {
  //         errorMsg.push(key);
  //       }
  //     }
  //   }

  //   let errorMsgCamelCase = errorMsg.map((key) => key.split("_").join(" "));

  //   //no validation for those
  //   editPayload.website_url = this.state.website_url;

  //   if (updatedItem.contact_country !== "US") {
  //     editPayload.contact_state_code = null;
  //     if (updatedItem.contact_type !== "BILLING_CONTACT") {
  //       editPayload.ein_number = null;
  //       editPayload.ss_number = null;
  //     }
  //   }

  //   // business errors
  //   if (editPayload.duns) {
  //     let pattern = new RegExp("^\\d{9}$");
  //     if (!editPayload.duns.match(pattern)) {
  //       otherErrors.push("Duns should be 9 digits");
  //     }
  //   }
  //   if (!validateEmailWithRegex(updatedItem.contact_email)) {
  //     otherErrors.push("Invalid email format");
  //   }

  //   if (!_.isEmpty(errorMsg) || !_.isEmpty(otherErrors)) {
  //     let messageErr = `Required fields to update a contact: ${errorMsgCamelCase.join(
  //       " ,"
  //     )}`;
  //     if (otherErrors.length)
  //       messageErr = `${messageErr} - Other errors: ${otherErrors
  //         .join(" ,")
  //         .split(" ,")}`;

  //     this.setState({
  //       ...this.state,
  //       openErrorModal: true,
  //       errorModalContent: messageErr,
  //     });
  //   } else {
  //     try {
  //       const editRequest = await serverApi(
  //         "PATCH",
  //         `masterEntityContacts/${updatedItem.id}`,
  //         {},
  //         editPayload,
  //         options
  //       );
  //       if (editRequest.status === 200) {
  //         let filter1 = {
  //           filter: {
  //             where: {
  //               relationID: this.props.dataItem && this.props.dataItem.id,
  //             },
  //             include: {
  //               relation: "masterEntity",
  //               scope: {
  //                 include: [
  //                   {
  //                     relation: "creator",
  //                   },
  //                 ],
  //               },
  //             },
  //           },
  //         };

  //         let responseData = await serverApi(
  //           "GET",
  //           `masterEntityContacts`,
  //           filter1,
  //           "",
  //           options
  //         );

  //         this.setState({
  //           ...this.state,
  //           gridData: {
  //             data: responseData?.data || [],
  //             total: responseData?.data?.length,
  //           },
  //           visibleWindow: false,
  //           openErrorModal: false,
  //           errorModalContent: "",
  //         });
  //       }
  //     } catch (e) {
  //       this.setState(
  //         {
  //           ...this.state,
  //           openErrorModal: true,
  //           errorModalContent:
  //             e?.response?.data?.error?.message ||
  //             "Update contact process has failed.Please check again the inputs.",
  //         },
  //         () => this.discard(dataItem, true)
  //       );
  //     }
  //   }
  // };

  updateItem = (data, item) => {
    let index = data.findIndex(
      (p) => p === item || (item.id && p.id === item.id)
    );
    if (index >= 0) {
      data[index] = { ...item };
    }
  };

  itemChange = (event) => {
    const {
      dataItem: { contact_type, contact_country },
      field,
    } = event;

    if (
      (field === "ein_number" ||
        field === "ss_number" ||
        field === "duns" ||
        field === "company_name" ||
        field === "contact_vat_number") &&
      contact_type !== "BILLING_CONTACT"
    ) {
      if (field === "contact_vat_number" && contact_country !== "US") {
      } else if (
        (field === "ein_number" || field === "ss_number") &&
        contact_country === "US"
      ) {
      } else {
        return;
      }
    }

    const total = this.state.gridData.total;
    const data =
      this.state.gridData &&
      this.state.gridData.data.map((item) =>
        item.id === event.dataItem.id
          ? { ...item, [event.field]: event.value }
          : item
      );

    this.setState({
      gridData: {
        data: data,
        total: total,
      },
    });
  };

  refreshGridDataAfterCrud = async () => {};

  toggleWindow = (e) => {
    const data = this.state.initialGridData.data;

    this.setState({
      visibleWindow: !this.state.visibleWindow,
      gridData: {
        data: [...data],
        total: 10,
      },
      contacts: [],
      valueContactType: "",
      windowError: false,
      windowErrorMessage: "",
      wh_notification_flg: false,
      notify_stock_email: false,
      notify_stock_ws: false,
    });
  };

  listNoDataRender = (element) => {
    const noData = (
      <h4 style={{ fontSize: "1em" }}>
        <span className="k-icon k-i-warning" style={{ fontSize: "2.5em" }} />
        <br />
        <br />
        No contacts were found.
      </h4>
    );

    return React.cloneElement(element, { ...element.props }, noData);
  };

  saveNewContact = async (e) => {
    try {
      const options = {
        token: window.localStorage.getItem("access_token"),
      };

      const payload = this.createPayload();
      this.setState({ ...this.state, disableCreateContact: false });

      const errorMsg = this.validatePayload(payload);

      if (errorMsg.length > 0) {
        this.showErrorWindow(errorMsg);
      } else {
        let reqPayload = _.clone(payload);
        reqPayload.website_url = this.state.website_url;

        const createRequest = await this.sendCreateRequest(reqPayload, options);

        if (createRequest.status === 200) {
          this.setState({ ...this.state }, async () => {
            await this.resetStateAfterCreate();
          });
        }
      }
    } catch (e) {
      this.handleCreateError(e);
    }
  };

  createPayload = () => {
    let errorMsg = [];
    const payload = {
      contact_name: this.state.contact_name,
      contact_type: this.state.valueContactType.text,
      is_active: true,
      contact_city: this.state.contact_city,
      contact_country: this.state.selectedCountry,
      contact_postal_code: this.state.contact_postal_code,
      contact_address: this.state.contact_address,
      contact_phone: this.state.contact_phone,
      contact_email: this.state.contact_email,
      relationID: this.props.dataItem.id || this.state.clientID,
    };

    if (this.state.valueContactType.text === "BILLING_CONTACT") {
      payload.ein_number = this.state.ein_number;
      payload.ss_number = this.state.ss_number;
      payload.tsa_number = this.state.tsa_number;
      payload.duns = this.state.duns;
      payload.company_name = this.state.company_name;
      payload.contact_vat_number = this.state.contact_vat_number || null; // Set to null if not provided
    } else if (this.state.selectedCountry !== "US") {
      payload.contact_vat_number = this.state.contact_vat_number || null; // Set to null if not provided
    }

    if (this.state.selectedCountry === "US") {
      payload.contact_state_code = this.state.selectedState;
      payload.ein_number = this.state.ein_number;
      payload.ss_number = this.state.ss_number;

      if (!this.state.selectedState) {
        errorMsg.push("State");
      }

      if (!this.state.ein_number && !this.state.ss_number) {
        errorMsg.push("EIN or SS");
      } else if (this.state.ein_number && this.state.ss_number) {
        payload.ein_number = this.state.ein_number;
        payload.ss_number = this.state.ss_number;
      }
    }

    if (this.state.selectedCountry !== "US") {
      // payload.contact_state_code = null;
      payload.contact_vat_number = this.state.contact_vat_number;
    }

    //no validation for these

    return payload;
  };

  validatePayload = (payload) => {
    const errorMsg = [];

    // eslint-disable-next-line no-unused-vars
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        if (
          key === "website_url" &&
          (payload[key] === null || payload[key] === "")
        ) {
          continue;
        }
        if (!payload[key]) {
          errorMsg.push(key);
        }
      }
    }

    if (payload.duns) {
      const pattern = new RegExp("^\\d{9}$");
      if (!payload.duns.match(pattern)) {
        errorMsg.push("Duns should be 9 digits");
      }
    }

    if (!validateEmailWithRegex(payload.contact_email)) {
      errorMsg.push("Invalid email format");
    }

    return errorMsg;
  };

  showErrorWindow = (errorMsg) => {
    const errorMsgCamelCase = errorMsg.map((key) => key.split("_").join(" "));
    let messageErr = `Required fields to create a new contact: ${errorMsgCamelCase.join(
      ", "
    )}`;

    if (errorMsg.length) {
      messageErr = `${messageErr} - Other errors: ${errorMsg.join(" ,")}`;
    }

    this.setState({
      ...this.state,
      windowError: true,
      windowErrorMessage: messageErr,
    });
  };

  sendCreateRequest = async (payload, options) => {
    return await serverApi(
      "POST",
      "masterEntityContacts",
      {},
      payload,
      options
    );
  };

  resetStateAfterCreate = async () => {
    const responseFinal = await this.fetchUpdatedData();

    this.setState({
      ...this.state,
      contact_city: null,
      contact_postal_code: null,
      contact_address: null,
      contact_phone: null,
      website_url: null,
      relationID: this.props.dataItem.id || this.state.clientID,
      contact_state_code: null,
      ein_number: null,
      ss_number: null,
      tsa_number: null,
      duns: null,
      company_name: null,
      contact_vat_number: null,
      clientID: "",
      contact_type: "",
      contact_country: "",
      contact_name: "",
      contact_email: "",
      gridData: {
        data: responseFinal.data || [],
        total: responseFinal.data.length || null,
      },
      initialGridData: {
        data: responseFinal.data || [],
        total: responseFinal.data.length || null,
      },
      visibleWindow: false,
      windowError: false,
      windowErrorMessage: "",
      wh_notification_flg: false,
      notify_stock_email: false,
      notify_stock_ws: false,
    });
  };

  handleCreateError = (e) => {
    let message =
      "Attach contact to master entity failed. Please try again and if the problem persists, contact the administrator.";

    if (e?.response?.data?.error?.message.includes("unique constraint")) {
      message =
        "Duplicate ID detected because the contact you are trying to attach already exists.";
    }

    this.setState({
      ...this.state,
      visibleWindow: true,
      windowError: true,
      windowErrorMessage:
        message ||
        "Create new contact and then attach to the master entity process has failed. Please try again with the correct input.",
    });
  };

  // saveNewContact = async (e) => {
  //   try {
  //     let options = {
  //       token: window.localStorage.getItem("access_token"),
  //     };

  //     let payload = {
  //       contact_name: this.state.contact_name,
  //       contact_type: this.state.valueContactType.text,
  //       is_active: true,
  //       contact_city: this.state.contact_city,
  //       contact_country: this.state.selectedCountry,
  //       contact_postal_code: this.state.contact_postal_code,
  //       contact_address: this.state.contact_address,
  //       contact_phone: this.state.contact_phone,
  //       contact_email: this.state.contact_email,
  //       relationID: this.props.dataItem.id || this.state.clientID,
  //     };

  //     this.setState({
  //       ...this.state,
  //       disableCreateContact: false,
  //     });

  //     let errorMsg = [];
  //     let otherErrors = [];

  //     // Check if valueContactType is "BILLING_CONTACT"
  //     if (this.state.valueContactType.text === "BILLING_CONTACT") {
  //       // Add these fields to the payload
  //       payload.ein_number = this.state.ein_number;
  //       payload.ss_number = this.state.ss_number;
  //       payload.tsa_number = this.state.tsa_number;
  //       payload.duns = this.state.duns;
  //       payload.company_name = this.state.company_name;
  //       payload.contact_vat_number = this.state.contact_vat_number;
  //     }

  //     // Check for VAT Number for non-US countries
  //     if (this.state.selectedCountry !== "US") {
  //       payload.contact_vat_number = this.state.contact_vat_number;
  //     }

  //     if (this.state.selectedCountry === "US") {
  //       payload.contact_state_code = this.state.selectedState;
  //       payload.ein_number = this.state.ein_number;
  //       payload.ss_number = this.state.ss_number;

  //       if (!this.state.selectedState) {
  //         errorMsg.push("State");
  //       }

  //       // Check for EIN or SS
  //       if (!this.state.ein_number && !this.state.ss_number) {
  //         errorMsg.push("EIN or SS");
  //       } else if (this.state.ein_number && this.state.ss_number) {
  //         payload.ein_number = this.state.ein_number;
  //         payload.ss_number = this.state.ss_number;
  //       }
  //     }

  //     // eslint-disable-next-line no-unused-vars
  //     for (const key in payload) {
  //       if (payload.hasOwnProperty(key)) {
  //         if (!payload[key]) {
  //           errorMsg.push(key);
  //         }
  //       }
  //     }

  //     //no validation for those
  //     payload.website_url = this.state.website_url;
  //     if (this.state.selectedCountry !== "US") {
  //       payload.contact_state_code = null;
  //     }

  //     // business errors
  //     if (payload.duns) {
  //       let pattern = new RegExp("^\\d{9}$");
  //       if (!payload.duns.match(pattern)) {
  //         otherErrors.push("Duns should be 9 digits");
  //       }
  //     }
  //     if (!validateEmailWithRegex(payload.contact_email)) {
  //       otherErrors.push("Invalid email format");
  //     }

  //     // Convert errorMsg keys to camel case without underscores
  //     let errorMsgCamelCase = errorMsg.map((key) => key.split("_").join(" "));

  //     if (!_.isEmpty(errorMsg) || !_.isEmpty(otherErrors)) {
  //       let messageErr = `Required fields to create a new contact: ${errorMsgCamelCase.join(
  //         " ,"
  //       )}`;
  //       if (otherErrors.length)
  //         messageErr = `${messageErr} - Other errors: ${otherErrors
  //           .join(" ,")
  //           .split(" ,")}`;

  //       this.setState({
  //         ...this.state,
  //         windowError: true,
  //         windowErrorMessage: messageErr,
  //       });
  //     } else {
  //       let createRequest = await serverApi(
  //         "POST",
  //         "masterEntityContacts",
  //         {},
  //         payload,
  //         options
  //       );

  //       if (createRequest.status === 200) {
  //         this.setState(
  //           {
  //             ...this.state,
  //             visibleWindow: false,
  //           },
  //           async () => {
  //             let responseFinal = await serverApi(
  //               "GET",
  //               `masterEntityContacts?filter={"where":{"relationID":${
  //                 this.props.dataItem.id || this.state.clientID
  //               }},"include":["masterEntity"]}`,
  //               {},
  //               {},
  //               options
  //             );

  //             this.setState({
  //               ...this.state,

  //               contact_city: null,
  //               contact_postal_code: null,
  //               contact_address: null,
  //               contact_phone: null,
  //               website_url: null,
  //               relationID: this.props.dataItem.id || this.state.clientID,
  //               contact_state_code: null,
  //               ein_number: null,
  //               ss_number: null,
  //               tsa_number: null,
  //               duns: null,
  //               company_name: null,
  //               contact_vat_number: null,
  //               clientID: "",
  //               contact_type: "",
  //               contact_country: "",
  //               contact_name: "",
  //               contact_email: "",
  //               gridData: {
  //                 data: responseFinal.data || [],
  //                 total: responseFinal.data.length || null,
  //               },
  //               initialGridData: {
  //                 data: responseFinal.data || [],
  //                 total: responseFinal.data.length || null,
  //               },
  //               visibleWindow: false,
  //               windowError: false,
  //               windowErrorMessage: "",
  //               wh_notification_flg: false,
  //               notify_stock_email: false,
  //               notify_stock_ws: false,
  //             });
  //           }
  //         );
  //       }
  //     }
  //   } catch (e) {
  //     let message =
  //       "Attach contact to master entity failed.Please try again and if the problem persist contact the administrator.";
  //     if (e?.response?.data?.error?.message.includes("unique constraint")) {
  //       message =
  //         "Duplicate id detected because the contact you are trying to attach already exists.";
  //     }

  //     this.setState({
  //       ...this.state,
  //       visibleWindow: true,
  //       windowError: true,
  //       windowErrorMessage:
  //         message ||
  //         "Create new contact and then attach to the master entity process has failed.Please try again with correct input.",
  //     });
  //   }
  // };

  closeErrorModal = () => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      errorModalContent: "",
    });
  };

  handleErrorClose = (loginError) => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      openErrorModalContent: "",
    });
  };

  openErrorModal = (e) => {
    this.setState({
      ...this.state,
      openErrorModal: true,
      errorModalContent: e.response.data.error.message || "Please try again",
    });
  };

  onDialogInputChange = (event) => {
    let target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.props ? target.props.name : target.name;

    this.setState({
      ...this.state,
      gridData: {
        ...this.state.gridData,
        [name]: value,
      },
      [name]: value,
    });
  };

  handleChange = (event) => {
    this.setState({
      valueContactType: event.target.value,
    });
  };

  handleDropDownChange = (event, name) => {
    const value = event.value;

    this.setState({
      ...this.state,
      isDropOpened: !this.state.isDropOpened,
      gridData: {
        ...this.state.gridData,
        [name]: value,
      },
      [name]: value,
    });
  };

  filterCountryData = (filter) => {
    if (filter.value === "") {
      this.setState({
        countries: this.state.initialCountries,
      });

      return;
    } else {
      const data = this.state.initialCountries;
      filter.operator = "startswith";
      let filteredResults = filterBy(data, filter);

      return filteredResults;
    }
  };

  filterChange = (event) => {
    let filteredResults = this.filterCountryData(event.filter);

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.setState({
        countries:
          filteredResults !== undefined
            ? filteredResults
            : this.state.initialCountries,
        loading: false,
      });
    }, this.state.delay);

    this.setState({
      loading: true,
    });
  };

  disableSaveButton = () => {
    let errorMsg = [];
    let payload = {
      contact_name: this.state.contact_name,
      contact_type: this.state.valueContactType.text,
      is_active: true,
      contact_city: this.state.contact_city,
      contact_country: this.state.selectedCountry,
      contact_postal_code: this.state.contact_postal_code,
      contact_address: this.state.contact_address,
      contact_phone: this.state.contact_phone,
      contact_email: this.state.contact_email,
      relationID: this.props.dataItem.id || this.state.clientID,
    };

    if (this.state.selectedCountry === "US") {
      payload.contact_state_code = this.state.selectedState;
    }

    // eslint-disable-next-line no-unused-vars
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        if (key === "website_url" && payload[key] === "") {
          // Allow an empty website URL (you can also allow null here if needed)
          continue;
        }
        if (!payload[key]) {
          errorMsg.push(key);
        }
      }
    }

    if (errorMsg.length) return true;
  };

  //todo get the dropdown list data from lov api call
  renderCreateContactForm = (dataItem) => {
    return (
      <div>
        <fieldset style={{ borderColor: "rgba(13, 88, 105, 1)" }}>
          <legend>{`Master Entity: ${dataItem.relation_name}`}</legend>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h5>Contact Type</h5>
              <DropDownList
                style={{ width: "400px" }}
                data={this.state.contactTypes}
                textField="text"
                dataItemKey="id"
                onChange={(e) => this.handleChange(e)}
                value={this.state.valueContactType}
              />
              <span>
                <h3>Contact Name</h3>
                <Input
                  style={{ width: "400px" }}
                  name="contact_name"
                  value={
                    this.state.gridData.data.filter(
                      (temp) => temp.inEdit === true
                    ).contact_name
                  }
                  onChange={this.onDialogInputChange}
                />
              </span>
              <span>
                <h3>Address</h3>
                <Input
                  style={{ width: "400px" }}
                  name="contact_address"
                  value={
                    this.state.gridData.data.filter(
                      (temp) => temp.inEdit === true
                    ).contact_address
                  }
                  onChange={this.onDialogInputChange}
                />
              </span>
              <span>
                <h3>Contact Email</h3>
                <Input
                  style={{ width: "400px" }}
                  name="contact_email"
                  value={
                    this.state.gridData.data.filter(
                      (temp) => temp.inEdit === true
                    ).contact_email
                  }
                  onChange={this.onDialogInputChange}
                />
              </span>
              <span>
                <h3>Country</h3>
                <DropdownFilterCell
                  {...this.props}
                  name={this.state.selectedCountry}
                  filterable={true}
                  width="400px"
                  onFilterChange={this.filterChange}
                  onChange={(e) =>
                    this.handleDropDownChange(e, "selectedCountry")
                  }
                  data={(this.props.countryCodes || [])
                    .map((x) => x.alpha_2)
                    .sort((a, b) => (a > b ? 1 : -1))}
                />
              </span>
              {this.state.selectedCountry === "US" && (
                <span>
                  <h3>State</h3>
                  <DropdownFilterCell
                    {...this.props}
                    name={this.state.selectedState}
                    filterable={true}
                    width="400px"
                    onFilterChange={this.filterChange}
                    onChange={(e) =>
                      this.handleDropDownChange(e, "selectedState")
                    }
                    data={(this.props.usaStates || [])
                      .map((x) => x.lov_value_1)
                      .sort((a, b) => (a > b ? 1 : -1))}
                  />
                </span>
              )}
              <span>
                <h3>City</h3>
                <Input
                  style={{ width: "400px" }}
                  name="contact_city"
                  value={
                    this.state.gridData.data.filter(
                      (temp) => temp.inEdit === true
                    ).contact_city
                  }
                  onChange={this.onDialogInputChange}
                />
              </span>

              <span>
                <h3>Postal Code</h3>
                <Input
                  style={{ width: "400px" }}
                  name="contact_postal_code"
                  value={
                    this.state.gridData.data.filter(
                      (temp) => temp.inEdit === true
                    ).contact_postal_code
                  }
                  onChange={this.onDialogInputChange}
                />
              </span>

              <span>
                <h3>Phone</h3>
                <Input
                  style={{ width: "400px" }}
                  name="contact_phone"
                  value={
                    this.state.gridData.data.filter(
                      (temp) => temp.inEdit === true
                    ).contact_phone
                  }
                  onChange={this.onDialogInputChange}
                />
              </span>
              {(this.state.valueContactType.text === "BILLING_CONTACT" ||
                this.state.selectedCountry === "US") && (
                <div>
                  <span>
                    <h3>EIN Number</h3>
                    <Input
                      style={{ width: "400px" }}
                      name="ein_number"
                      value={
                        this.state.gridData.data.filter(
                          (temp) => temp.inEdit === true
                        ).ein_number
                      }
                      onChange={this.onDialogInputChange}
                    />
                  </span>
                  <span>
                    <h3>SS Number</h3>
                    <Input
                      style={{ width: "400px" }}
                      name="ss_number"
                      value={
                        this.state.gridData.data.filter(
                          (temp) => temp.inEdit === true
                        ).ss_number
                      }
                      onChange={this.onDialogInputChange}
                    />
                  </span>
                  {this.state.valueContactType.text === "BILLING_CONTACT" && (
                    <div>
                      <span>
                        <h3>TSA Number</h3>
                        <Input
                          style={{ width: "400px" }}
                          name="tsa_number"
                          value={
                            this.state.gridData.data.filter(
                              (temp) => temp.inEdit === true
                            ).tsa_number
                          }
                          onChange={this.onDialogInputChange}
                        />
                      </span>
                      <span>
                        <h3>Duns</h3>
                        <Input
                          style={{ width: "400px" }}
                          name="duns"
                          value={
                            this.state.gridData.data.filter(
                              (temp) => temp.inEdit === true
                            ).duns
                          }
                          onChange={this.onDialogInputChange}
                          placeholder="Duns should be 9 digits"
                        />
                      </span>
                    </div>
                  )}
                </div>
              )}
              {(this.state.selectedCountry !== "US" ||
                this.state.valueContactType.text === "BILLING_CONTACT") && (
                <div>
                  <span>
                    <h3>Vat Number</h3>
                    <Input
                      style={{ width: "400px" }}
                      name="contact_vat_number"
                      value={
                        this.state.gridData.data.filter(
                          (temp) => temp.inEdit === true
                        ).contact_vat_number
                      }
                      onChange={this.onDialogInputChange}
                    />
                  </span>
                </div>
              )}
              <span>
                <h3>Company Name</h3>
                <Input
                  style={{ width: "400px" }}
                  name="company_name"
                  value={
                    this.state.gridData.data.filter(
                      (temp) => temp.inEdit === true
                    ).company_name
                  }
                  onChange={this.onDialogInputChange}
                />
              </span>

              <span>
                <h3>Web Url</h3>
                <Input
                  style={{ width: "400px" }}
                  name="website_url"
                  value={
                    this.state.gridData.data.filter(
                      (temp) => temp.inEdit === true
                    ).website_url
                  }
                  onChange={this.onDialogInputChange}
                />
              </span>
            </div>
          </div>
          <br />
          {this.state.windowError ? (
            <Error style={{ color: "red" }}>
              <h3>{this.state.windowErrorMessage || ""}</h3>
            </Error>
          ) : null}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button
              style={{ justifyContent: "flex-end" }}
              type="button"
              className="k-button k-primary"
              onClick={(e) => this.saveNewContact(e)}
              disabled={this.disableSaveButton() || false}
            >
              Save
            </button>
          </div>
        </fieldset>
      </div>
    );
  };

  render() {
    const dataItem = this.props.dataItem;
    const { gridData } = this.state;
    const hasEditedItem =
      gridData && (gridData.data || []).some((p) => p.inEdit);
    const { hasBackofficeRead } = this.props;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <div
          style={{
            width: "83%",
            marginBottom: "30px",
          }}
        >
          <h4>Master Entity Name : {dataItem && dataItem.relation_name}</h4>
          {this.state.openErrorModal ? (
            <Error style={{ color: "red" }}>
              <h3>{this.state.errorModalContent || ""}</h3>
            </Error>
          ) : null}

          <Grid
            {...this.state.dataState}
            {...gridData}
            filterable={false}
            style={{ className: "grid-no-select" }}
            sortable={true}
            resizable
            editField={this.editField}
            onItemChange={this.itemChange}
            pageable={false}
          >
            <GridToolbar>
              <GridContainer xs={12} justify={"flex-start"} direction={"row"}>
                {!hasBackofficeRead && (
                  <Tooltip
                    justify={"flex-start"}
                    placement="top"
                    title={"Add Contact"}
                  >
                    <IconButton onClick={this.toggleWindow}>
                      <FontAwesomeIcon
                        color="#0D5869"
                        onClick={this.toggleWindow}
                        size="1.6x"
                        icon={faPlus}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </GridContainer>
            </GridToolbar>

            {!hasBackofficeRead && (
              <Column
                cell={this.CommandCell}
                title="Actions"
                filterable={false}
                width={hasEditedItem ? "240px" : "220px"}
              />
            )}

            {this.renderGridColumns(dataItem)}
          </Grid>
        </div>
        {this.state.visibleWindow && (
          <Window
            title={`Create contact for master entity`}
            onClose={this.toggleWindow}
            width={1200}
            height={1050}
          >
            <div>{this.renderCreateContactForm(dataItem)}</div>
          </Window>
        )}
      </div>
    );
  }
}

export default CrmContacts;
