import React, { useEffect, useState } from "react";
import {Button} from "@material-ui/core";

export default function ModalNewVersion(props) {
  const [seconds, setSeconds] = useState(120);

  useEffect(() => {
    setTimeout(() => {
      if (seconds > 1) {
        setSeconds(seconds - 1);
      } else {
        window.location.reload();
      }
    }, 1000);
  }, [seconds]);

  return (
    <>
      <div style={{fontWeight:"700"}}>
        An update is available.
        </div>
      <div>
       Please reload the app to get the latest changes.
        The app will automatically be reloaded in {seconds} seconds.
      </div>
      <span>
        <Button
          variant="contained"
          classes={{label: "sm-primary-button-label text-normal"}}
          color="primary"
          disableElevation
          onClick={() => {
            window.location.reload();
          }}
        >
          Reload app
        </Button>
      </span>
    </>
  );
}
