import React from 'react';
import { toODataString } from '@progress/kendo-data-query';
import { serverApi } from "../../../networking/config";
import LoadingPanel from "../../../components/PrimaryGridLoader"
import _ from "lodash";

export class Loader extends React.Component {

  lastSuccess = '';
  pending = '';

  requestDataIfNeeded = async () => {

    if (this.pending || toODataString(this.props.dataState) === this.lastSuccess) {
      return;
    }
    this.pending = toODataString(this.props.dataState);

    let options = {
      token: window.localStorage.getItem('access_token')
    };

    let params = { ...this.props.dataState };

    params['skip'] = this.props.dataState.skip;
    params['limit'] = this.props.dataState.take;
    params['where'] = this.props.dataState.filter !== null ? this.props.dataState.filter : {};

    if (!_.isEmpty(this.props.dataState.sort)) {
      params['order'] = this.props.dataState.sort[0].field + ' ' + this.props.dataState.sort[0].dir;
      delete params.sort;
    }

    delete params.take
    let filterColumn = params && (params.filter === null || params.filter === undefined)
      ? ''
      : params.filter.filters[0] !== null
        ? params.filter.filters[0].field
        : '';

    let filterValue = params && (params.filter === null || params.filter === undefined)
      ? ''
      : params.filter.filters[0] !== null
        ? params.filter.filters[0].value
        : '';

    let whereFinal = []
    let booleanColumns = ["supplier_flag", "client_flag", "transport_flag", "agent_flag", "" +
      "sm_agent_flag", "sm_vendor_flag", "location_flag", "airport_flag"]

    if (params.filter && params.filter.filters && params.filter.filters.length > 0) {
      _.forEach(params.filter.filters, (temp) => {

        //fixes the issue with & when searching...fucking loopback 3
        let finalValue = temp.value
        if ((typeof finalValue === 'string' || finalValue instanceof String) && finalValue.includes('&')) {
          finalValue = temp.value.replace('&', '.')
        } else if (typeof finalValue === 'boolean') {
          finalValue = temp.value
        }

        if (booleanColumns.includes(temp.field)) {
          whereFinal.push(
            {
              [`${temp.field}`]: `${finalValue}`,
            }
          )
        } else {
          whereFinal.push(
            {
              [`${temp.field}`]: {
                "regexp": `/^${finalValue}/i`
              },
            }
          )
        }
      })

    } else {

      let isFirstLoad = window.localStorage.getItem('isFirstLoad')
      if (booleanColumns.includes(filterColumn) && isFirstLoad === "true") {
        whereFinal = [
          {
            [`${filterColumn}`]: `${filterValue}`,
            
          }
        ]
      } else {
        if (whereFinal.length === 1) {
          whereFinal.push(
            {
              [`${filterColumn}`]: {
                "regexp": `/^${filterValue}/i`
              },
             
            }
          )
        } else {
          whereFinal.push(
            {
              [`${filterColumn}`]: {
                "regexp": `/^${filterValue}/i`
              },
             
            }
          )
        }

      }
    }

    let params2 = {
      filter: {
        include: [
          {
            relation: "client",
            scope: {
              fields: ["relation_name"],
            },
          },
          {
            relation: "agent",
            scope: {
              fields: ["relation_name"],
            },
          },
          {
            relation: "destination",
            scope: {
              fields: ["relation_name"],
            },
          },
        ],
        // fields: [],
        where: {
          and: whereFinal
        },
        order: [
          `${params.order}` !== [] ? `${params.order}` : [],
        ],
        limit: `${params.limit}`,
        skip: `${params.skip}`
      },
    };

    if (params2.filter.order[0] === 'undefined') {
      delete params2.filter.order;
    }

    if (params2.filter.where.and && params2.filter.where.and.length === 1) {
      params2.filter.where = whereFinal[0]
    }

    let finalParams = { ...params2 }

    let responseData
    let finalGridData = {
      data: [],
    }
    if (this.props.getURL.includes('filter')) {
      responseData = await serverApi('GET', `${this.props.getURL}`, {}, '', options);

      _.forEach(responseData.data, dato => {
        finalGridData.data.push({
          ...dato,
          agent_name: dato?.agent?.relation_name,
          client_name: dato?.client?.relation_name,
          destination_name: dato?.destination?.relation_name,
        })
      })

    } else {
      window.localStorage.setItem('refreshParams', JSON.stringify(finalParams))
      responseData = await serverApi('GET', `${this.props.getURL}`, finalParams, '', options);

      _.forEach(responseData.data, dato => {
        finalGridData.data.push({
          ...dato,
          agent_name: dato?.agent?.relation_name,
          client_name: dato?.client?.relation_name,
          destination_name: dato?.destination?.relation_name,
        })
      })
    }

    let count
    if (this.props.dataState.filter) {

      let x = JSON.stringify(this.props.dataState.filter)
      let t = JSON.stringify(JSON.parse(window.localStorage.getItem('finalParams')).filter)

      if (x !== t) {

        const countMasterEntity = await serverApi('GET', `${this.props.countURL}`, '', '', options)
        if (countMasterEntity) {
          count = countMasterEntity.data.count
        }
      } else {
        let url = whereFinal && whereFinal.length
          ? `ifwhMappingBoVs/count?where=${JSON.stringify(params2.filter.where)}`
          : 'ifwhMappingBoVs/count'
        encodeURI(url)
        const countMasterEntity = await serverApi('GET', `${url}`, '', '', options)
        if (countMasterEntity) {
          count = countMasterEntity.data.count
        }
      }


    } else {

      const countMasterEntity = await serverApi('GET', `${this.props.countURL}`, '', '', options)
      if (countMasterEntity) {
        count = countMasterEntity.data.count
      }

    }

    window.localStorage.setItem('exportFilter', JSON.stringify(finalParams.filter.where))

    this.lastSuccess = this.pending;
    this.pending = '';

    if (toODataString(this.props.dataState) === this.lastSuccess) {
      if (window.localStorage.getItem('finalParams')) {
        if (JSON.stringify(JSON.parse(window.localStorage.getItem('finalParams')).filter) !== "") {

          let final = JSON.parse(window.localStorage.getItem('finalParams')).filter

          this.props.onDataRecieved.call(undefined, {
            data: finalGridData.data,
            total: count === undefined ? finalGridData.data.length : count
          }, final);
        }
      } else {
        this.props.onDataRecieved.call(undefined, {
          data: finalGridData.data,
          total: count === undefined ? finalGridData.data.length : count
        });
      }


    } else {
      this.requestDataIfNeeded();
    }
  }

  render() {

    this.requestDataIfNeeded();

    return this.pending && <LoadingPanel />;
  }
}
