import React from "react";
import { columnsSchema } from "./schema";
import { MyCommandCell } from "../../../../Clients/View/components/CellHOC";
import { serverApi } from "../../../../../networking/config";
import _ from "lodash";
import { GridColumn as Column } from "@progress/kendo-react-grid/dist/npm/GridColumn";
import { DropDownCell } from "../../../../CRM/View/dropDownCell";
import { AutoComplete2 } from "../../../../Vessels/View/components/relationNameDropDown";
import { Grid, GridToolbar } from "@progress/kendo-react-grid";
import GridContainer from "../../../../../components/Grid/GridContainer";
import { IconButton, Tooltip } from "@material-ui/core";
import { Error } from "@progress/kendo-react-labels";
import { Window } from "@progress/kendo-react-dialogs";
import BlockUi from "react-block-ui";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class DomainToClient extends React.Component {
  editField = "inEdit";
  CommandCell;
  _export;

  constructor(props) {
    super(props);

    this.state = {
      visibleWindow: false,
      isDropOpened: false,
      columns: (columnsSchema && columnsSchema()) || [],
      gridData: {
        data: [],
        total: 10,
      },
      initialGridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 10,
        skip: 0,
      },
      openErrorModal: false,
      errorModalContent: "",
      delay: 400,
      loading: false,
      client_names: [],
      windowError: false,
      windowErrorMessage: "",
      disableEditButton: true,
      inputValue: "",
      clientAttachedToAnotherDomain: null,
      initialFinalUserClients: [],
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      disableEditButton: false,
      showSecondAction: true,
      showFirstAction: false,
    });
  }

  async componentDidMount() {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let filter1 = {
      filter: {
        where: {
          domainID: this.props.dataItem && this.props.dataItem.id,
        },
        include: ["masterEntity"],
      },
    };
    let filter2 = {
      filter: {
        where: {
          and: [
            {
              client_flag: true,
            },
          ],
        },
      },
    };
    const attachedClients = await serverApi(
      "GET",
      `clientToDomains`,
      filter1,
      "",
      options
    );
    let clonedAttachedClients = _.cloneDeep(attachedClients);

    const getClients = await serverApi(
      "GET",
      `masterEntities`,
      filter2,
      "",
      options
    );

    let result = this.constructClientsAndExistingClientsArray(
      getClients.data,
      attachedClients.data
    );

    result[0].attachedClients.forEach((item) => {
      let client = result[0].allClients.find((x) => x.id === item.id);
      if (client) {
        client.checked = true;
      }
    });

    if (attachedClients && attachedClients.data.length === 1) {
      let finalAttachedClients = [];
      _.forEach(clonedAttachedClients.data, (temp) => {
        finalAttachedClients.push({
          id: temp.clientID,
          relation_name: temp.masterEntity.relation_name,
          relation_address: temp.masterEntity.relation_address,
          relation_postal_code: temp.masterEntity.relation_postal_code,
          relation_country: temp.masterEntity.relation_country,
          relation_city: temp.masterEntity.relation_city,
          deleteID: temp.id,
        });
      });

      this.setState({
        ...this.state,
        domainID: this.props.dataItem.id,
        gridData: {
          data: [finalAttachedClients[0]],
          total: 1,
        },
        initialGridData: {
          data: [finalAttachedClients[0]],
          total: 1,
        },
        client_names: getClients.data.sort((a, b) =>
          a.relation_name > b.relation_name ? 1 : -1
        ),
        valueClientName: "",

        visibleClients: result[0].allClients || [],
        finalUserClients: result[0].allClients,
        initialFinalUserClients: result[0].allClients,
        blocking: false,
      });
    } else if (attachedClients && attachedClients.data.length > 1) {
      let finalData = [];
      _.forEach(attachedClients.data, (temp) => {
        finalData.push({
          id: temp.clientID,
          relation_name: temp.masterEntity.relation_name,
          relation_address: temp.masterEntity.relation_address,
          relation_postal_code: temp.masterEntity.relation_postal_code,
          relation_country: temp.masterEntity.relation_country,
          relation_city: temp.masterEntity.relation_city,
          deleteID: temp.id,
        });
      });
      finalData.sort((a, b) => (a.relation_name > b.relation_name ? 1 : -1));

      this.setState({
        ...this.state,
        domainID: this.props.dataItem.id, //todo
        gridData: {
          data: finalData,
          total: finalData.length,
        },
        initialGridData: {
          data: finalData,
          total: finalData.length,
        },
        client_names: getClients.data.sort((a, b) =>
          a.relation_name > b.relation_name ? 1 : -1
        ),

        visibleClients: result[0].allClients || [],
        finalUserClients: result[0].allClients,
        initialFinalUserClients: result[0].allClients,
        blocking: false,
      });
    } else {
      this.setState({
        ...this.state,
        domainID: this.props.dataItem.id, //todo
        gridData: {
          data: [],
          total: 0,
        },
        initialGridData: {
          data: [],
          total: 0,
        },
        client_names: getClients.data,

        visibleClients: result[0].allClients || [],
        finalUserClients: result[0].allClients,
        initialFinalUserClients: result[0].allClients,
        blocking: false,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  constructClientsAndExistingClientsArray = (getClients, attachedClients) => {
    let finalClients = [];
    _.forEach(getClients, (temp1) => {
      finalClients.push({
        name: temp1.relation_name,
        id: temp1.id,
        checked: false,
      });
    });

    let finalAttachedClients = [];
    _.forEach(attachedClients, (temp2) => {
      finalAttachedClients.push({
        name: temp2.masterEntity.relation_name,
        id: temp2.clientID,
        checked: true,
        deleteID: temp2.id,
      });
    });

    finalClients.sort((a, b) => (a.name > b.name ? 1 : -1));
    finalAttachedClients.sort((a, b) => (a.name > b.name ? 1 : -1));

    return [
      {
        allClients: finalClients,
        attachedClients: finalAttachedClients,
      },
    ];
  };

  renderGridColumns = (dataItem, changeTheCellComponent) => {
    let selectionCol = [];
    let normalCols = [];
    let finalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            width="70px"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.isFilterBoolean === "yes") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"420"}
              cell={DropDownCell}
            />
          );
        } else if (temp.field === "masterEntity.relation_name") {
          return (
            <Column
              field="masterEntity.relation_name"
              title="NAME"
              width="400px"
              cell={AutoComplete2}
            />
          );
        } else {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={temp.field === "id" || temp.editable ? false : true} //id not editable on edit
            />
          );
        }
      });

    finalCols = [...selectionCol, ...normalCols];
    return finalCols;
  };

  enterEdit = (dataItem) => {
    let total = this.state.gridData.total;

    let finalData =
      this.state.gridData &&
      this.state.gridData.data.map((item) =>
        item.id === dataItem.id ? { ...item, inEdit: true } : item
      );

    this.setState({
      ...this.state,
      gridData: {
        data: finalData,
        total: total,
      },
    });
  };

  discard = (dataItem, hasError = false) => {
    let data = [...this.state.gridData.data];
    const total = this.state.gridData.total;

    if (hasError) {
      data[0].inEdit = true;
      this.setState({
        ...this.state,
        gridData: {
          data: data,
          total: total,
        },
      });
    } else {
      this.removeItem(data, dataItem);

      this.setState({
        ...this.state,
        gridData: {
          data: data,
          total: total,
        },
        visible: false,
      });
    }
  };

  cancel = (dataItem) => {
    const originalItem =
      this.state.initialGridData &&
      this.state.initialGridData.data.find((p) => p.id === dataItem.id);
    originalItem.inEdit = false;

    const data =
      this.state.initialGridData &&
      this.state.initialGridData.data.map((item) =>
        item.id === originalItem.id ? originalItem : item
      );

    const total = this.state.initialGridData.total;

    this.setState({
      ...this.state,
      gridData: {
        data: data,
        total: total,
      },
      initialGridData: {
        data: data,
        total: total,
      },
      openErrorModal: false,
      openErrorModalContent: "",
    });
  };

  removeItem(data, item) {
    let index = data.findIndex((p) => p === item || p.id === item.id);
    if (index >= 0) {
      data.splice(index, 1);
    }
  }

  remove = async (dataItem) => {
    const data = [...this.state.gridData.data];

    let index = data.findIndex(
      (p) => p === dataItem || dataItem.id || p.id === dataItem.id
    );
    if (index >= 0) {
      data.splice(index, 1);
    }

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let delete_ID = dataItem.deleteID;
    let url = `clientToDomains/${delete_ID}`;

    try {
      const removeClientFromDomain = await serverApi(
        `DELETE`,
        url,
        "",
        "",
        options
      );
      if (removeClientFromDomain.status === 200) {
        let findClient = this.state.visibleClients.find(
          (x) => x.id === dataItem.id
        );
        if (findClient) {
          findClient.checked = false;
        }

        this.setState(
          {
            ...this.state,
            gridData: {
              data: data,
              total: this.state.gridData.total,
            },
            initialGridData: {
              data: data,
              total: this.state.gridData.total,
            },
            successMessage: "Client was successfully removed from domain.",
            openSuccessMessage: true,
          },
          () => {
            setTimeout(() => {
              this.handleSuccessSnackbar();
            }, 2500);
          }
        );
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent: e.response.data.error.message || "Please try again",
      });
    }
  };

  toggleWindow = (e) => {
    const data = this.state.initialGridData.data;

    this.setState({
      ...this.state,
      visibleWindow: !this.state.visibleWindow,
      gridData: {
        data: [...data],
        total: 100,
      },
      client_names: [],
      valueClientName: "",
      windowError: false,
      windowErrorMessage: "",
      openErrorModal: false,
      errorModalContent: "",
      // inputValue:'',
      // initialFinalUserClients: initialFinalUserClients
    });
  };

  openErrorModal = (e) => {
    this.setState({
      ...this.state,
      openErrorModal: true,
      errorModalContent: e.response.data.error.message || "Please try again",
    });
  };

  handleSuccessSnackbar = () => {
    this.setState({
      ...this.state,
      successMessage:
        this.state.openSuccessMessage === true ? this.state.successMessage : "",
      openSuccessMessage: !this.state.openSuccessMessage,
    });
  };

  handleSearch = (e) => {
    let initialFinalUserClients = this.state.initialFinalUserClients;

    if (e.value === "") {
      let searchArr = [];
      initialFinalUserClients.forEach((temp) => {
        if (temp.name.toUpperCase().includes(e.value.toUpperCase())) {
          searchArr.push(temp);
        }
      });

      if (
        this.state.clientAttachedToAnotherDomain !== null &&
        this.state.clientAttachedToAnotherDomain !== undefined
      ) {
        let newArr = searchArr.filter(
          (t) => t.id !== this.state.clientAttachedToAnotherDomain
        );
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalUserClients: newArr,
          openErrorModal: false,
          errorModalContent: "",
        });
      } else {
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalUserClients: initialFinalUserClients,
          openErrorModal: false,
          errorModalContent: "",
        });
      }
    } else {
      let searchArr = [];
      //case insensitive
      initialFinalUserClients.forEach((temp) => {
        if (temp.name.toUpperCase().includes(e.value.toUpperCase())) {
          searchArr.push(temp);
        }
      });

      this.setState({
        ...this.state,
        inputValue: e.value,
        finalUserClients: searchArr,
        openErrorModal: false,
        errorModalContent: "",
      });
    }
  };

  renderClientList = (finalClients = [], clients = [], doRefresh) => {
    if (doRefresh === true) {
      window.location.reload();
    } else {
      if (finalClients.length === clients.length) {
        return (
          clients &&
          clients.map((item) => (
            <ul style={{ marginBottom: "-5px" }} key={item.id}>
              <Checkbox
                name={item.name}
                key={item.id}
                value={item.checked}
                onChange={(e) => this.handleChange(e, item.name, item.id)}
                label={item.name}
                disabled={this.state.disableAllCheckboxes}
              />
            </ul>
          ))
        );
      } else {
        return (
          finalClients &&
          finalClients.map((item) => (
            <ul style={{ marginBottom: "-5px" }} key={item.id}>
              <Checkbox
                name={item.name}
                key={item.id}
                value={item.checked}
                onChange={(e) => this.handleChange(e, item.name, item.id)}
                label={item.name}
                disabled={this.state.disableAllCheckboxes}
              />
            </ul>
          ))
        );
      }
    }
  };

  handleChange = (event, name, key) => {
    let finalUserClient = [...this.state.finalUserClients];
    let finalObj = finalUserClient.find((test) => test.id === key);
    finalObj.checked = !finalObj.checked;

    let domainID = this.state.domainID;
    let data = [...this.state.gridData.data];

    this.setState(
      {
        ...this.state,
        finalUserClients: finalUserClient,
      },
      () => {
        let postOrDeleteClient = event.value === false ? "DELETE" : "POST";
        let options = {
          token: window.localStorage.getItem("access_token"),
        };

        if (postOrDeleteClient === "POST") {
          const url = `clientToDomains`;
          this.setState({
            ...this.state,
            blocking: true,
          });

          const addClientToDomain = serverApi(
            "POST",
            url,
            "",
            {
              clientID: key,
              domainID: domainID,
            },
            options,
            "",
            ""
          );

          addClientToDomain
            .then(async (response) => {
              const filter1 = {
                filter: {
                  where: {
                    domainID: domainID,
                  },
                  include: ["masterEntity"],
                },
              };
              const attachedClients = await serverApi(
                "GET",
                `clientToDomains`,
                filter1,
                "",
                options
              );

              let finalData = [];
              _.forEach(attachedClients.data, (temp) => {
                finalData.push({
                  id: temp.clientID,
                  relation_name: temp.masterEntity.relation_name,
                  deleteID: temp.id,
                });
              });
              finalData.sort((a, b) =>
                a.relation_name > b.relation_name ? 1 : -1
              );
              this.setState({
                ...this.state,
                gridData: {
                  data: finalData,
                  total: finalData.length,
                },
                initialGridData: {
                  data: finalData,
                  total: finalData.length,
                },
                blocking: false,
                openErrorModal: false,
                errorModalContent: "",
              });
            })
            .catch((error) => {
              this.setState({
                ...this.state,
                openErrorModal: true,
                errorModalContent:
                  error?.response?.data?.error?.message || "Please try again",
                blocking: false,
              });
            });
        } else if (postOrDeleteClient === "DELETE") {
          const deleteObj = data.find((obj) => obj.id === key);
          const delete_id = deleteObj.deleteID;
          const url = `clientToDomains/${delete_id}`;

          this.setState({
            ...this.state,
            blocking: true,
          });
          const removeClientFromDomain = serverApi(
            "DELETE",
            url,
            "",
            "",
            options,
            "",
            ""
          );
          removeClientFromDomain
            .then(async (response) => {
              let filter1 = {
                filter: {
                  where: {
                    domainID: domainID,
                  },
                  include: ["masterEntity"],
                },
              };
              const attachedClients = await serverApi(
                "GET",
                `clientToDomains`,
                filter1,
                "",
                options
              );
              let finalData = [];
              _.forEach(attachedClients.data, (temp) => {
                finalData.push({
                  id: temp.clientID,
                  relation_name: temp.masterEntity.relation_name,
                  deleteID: temp.id,
                });
              });
              finalData.sort((a, b) =>
                a.relation_name > b.relation_name ? 1 : -1
              );

              this.setState({
                ...this.state,
                gridData: {
                  data: finalData,
                  total: finalData.length,
                },
                initialGridData: {
                  data: finalData,
                  total: finalData.length,
                },
                blocking: false,
              });
            })
            .catch((error) => {
              this.setState({
                ...this.state,
                openErrorModal: true,
                errorModalContent:
                  error.response.data.error.message || "Please try again",
                blocking: false,
              });
            });
        }
      }
    );
  };

  render() {
    const dataItem = this.props.dataItem;
    const { gridData } = this.state;
    const { inputValue } = this.state;

    return (
      <div style={{ marginRight: "50px" }}>
        <div>
          <h4 style={{ fontFamily: "bold", textSize: "7px" }}>
            Domain name : {dataItem && dataItem.description}
          </h4>
        </div>
        <Grid
          {...gridData}
          filterable={false}
          style={{ className: "grid-no-select" }}
          sortable={false}
          resizable
          editField={this.editField}
          onItemChange={this.itemChange}
          pageable={false}
        >
          <GridToolbar>
            <GridContainer xs={12} justify={"flex-start"} direction={"row"}>
              <Tooltip
                justify={"flex-start"}
                placement="top"
                title={"Attach Client"}
              >
                <IconButton onClick={this.toggleWindow}>
                  <FontAwesomeIcon
                    color="#0D5869"
                    onClick={this.toggleWindow}
                    size="1.6x"
                    icon={faPlus}
                  />
                </IconButton>
              </Tooltip>
            </GridContainer>
          </GridToolbar>

          <Column
            cell={this.CommandCell}
            title="Actions"
            filterable={false}
            width="220px"
          />
          {this.renderGridColumns(dataItem)}
        </Grid>

        {this.state.visibleWindow && (
          <Window
            title={`Attach clients to domain: ${this.props.dataItem.description}`}
            // style={{backgroundColor:'#0d5869'}}
            onClose={this.toggleWindow}
            width={900}
            height={600}
            initialTop={110}
          >
            <>
              <BlockUi
                tag="div"
                blocking={this.state.blocking}
                message="Loading, please wait. . ."
                renderChildren={true}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "50px",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {this.state.openErrorModal ? (
                      <Error style={{ color: "red", fontSize: "15px" }}>
                        {this.state.errorModalContent || ""}
                      </Error>
                    ) : null}
                    <div
                      style={{
                        display: "flex",
                        marginLeft: "35px",
                        marginTop: "20px",
                      }}
                    >
                      <Input
                        name="clients"
                        value={inputValue}
                        style={{ marginBottom: "20px" }}
                        label="Search clients"
                        onChange={(e) => this.handleSearch(e)}
                        minLength={2}
                      />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {this.renderClientList(
                        this.state.finalUserClients,
                        this.state.visibleClients
                      )}
                    </div>
                  </div>
                </div>
              </BlockUi>
            </>
            {this.state.windowError ? (
              <Error style={{ color: "red" }}>
                <h3>{this.state.windowErrorMessage || ""}</h3>
              </Error>
            ) : null}
          </Window>
        )}
      </div>
    );
  }
}
