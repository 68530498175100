import React from "react";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";

class CustomSnackbar extends React.Component {
  closeSnackbar = () => {
    this.props.makeDeleteCall();
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.closeSnackbar();
  };

  render() {
    const { open, snackbarContent, action, onCloseSnackbar } = this.props;

    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={open}
          onClose={this.handleClose}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={
            <span id="message-id">
              {snackbarContent}
            </span>
          }
          action={[
            <Button
              key="undo"
              color="secondary"
              variant={"outlined"}
              size="small"
              onClick={() => onCloseSnackbar("yes")}
            >
              {action}
            </Button>,
          ]}
        />
      </div>
    );
  }
}

export default CustomSnackbar;
