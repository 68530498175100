
export const columnsSchema = () => [
  {
    field: 'id',
    title: 'ID',
    visible: true,
    minWidth: 120,
    sortable: true,
    filterable: true,
    locked: 'locked',
    noDisabledColumn: true,
    notShownInTableColumnSelection: false
  },
  {
    field: 'username',
    title: 'Username',
    visible: true,
    // minWidth: 170,
    sortable: true,
    filterable: true,
    locked: 'locked',
  },
  {
    field: 'userRealName',
    title: 'Real name',
    visible: true,
    // minWidth: 170,
    sortable: true,
    filterable: true,
    locked: 'locked',
  },
  {
    field: 'email',
    title: 'Email',
    visible: true,
    // minWidth: 170,
    sortable: true,
    filterable: true,
    locked: 'locked',
  },
  {
    field: 'last_login_date',
    title: 'Last login',
    visible: true,
    // minWidth: 170,
    sortable: true,
    filterable: true,
    locked: 'locked',
    editable:'false'
  },
  {
    field: 'created',
    title: 'Created',
    visible: true,
    // minWidth: 170,
    sortable: true,
    filterable: true,
    locked: 'locked',
    editable:'false'
  }
];
