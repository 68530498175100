export const columnsSchema = () => [
    {
        field: 'id',
        title: 'ID',
        visible: true,
        // minWidth: 190,
        sortable: true,
        filterable: true,
        locked: 'locked',
        noDisabledColumn: true,
        notShownInTableColumnSelection: false,
        // cell: true
    },
    {
        field: 'groupName',
        title: 'Group Name',
        visible: true,
        // minWidth: 350,
        sortable: true,
        filterable: true,
        noDisabledColumn: true,
        locked: 'locked',
    },
]
