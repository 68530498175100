export const vesselToClientColumnSchema = () => [
  {
    field: "masterEntity.id",
    title: "ID",
    visible: true,
    minWidth: 180,
    sortable: true,
    filterable: true,
    locked: "locked",
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
    editable: "false",
    // cell: true
  },
  {
    field: "primary_flg",
    title: "Primary",
    visible: true,
    sortable: false,
    minWidth: 170,
    filterable: false,
    locked: "locked",
    filter: "boolean",
    isDropdown: "yes",
  },
  {
    field: "masterEntity.relation_name",
    title: "NAME",
    visible: true,
    minWidth: 260,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    locked: "locked",
    editable: "false",
  },
  {
    field: "masterEntity.relation_postal_code",
    title: "POSTAL",
    visible: true,
    minWidth: 210,
    sortable: true,
    filterable: true,
    noDisabledColumn: false,
    notShownInTableColumnSelection: false,
    locked: "locked",
    editable: "false",
  },
  {
    field: "masterEntity.relation_country",
    title: "COUNTRY",
    visible: true,
    minWidth: 220,
    sortable: true,
    filterable: true,
    locked: "locked",
    editable: "false",
  },
  {
    field: "masterEntity.relation_city",
    title: "CITY",
    visible: true,
    minWidth: 230,
    sortable: true,
    filterable: true,
    locked: "locked",
    editable: "false",
  },
  {
    field: "masterEntity.relation_address",
    title: "ADDRESS",
    visible: true,
    minWidth: 290,
    sortable: true,
    filterable: true,
    locked: "locked",
    notShownInTableColumnSelection: false,
    editable: "false",
  },
];
