import { serverApi } from "../config";
import _ from "lodash";

export const GetGridData = (
  specific,
  includeArray,
  options,
  hasFinalParams
) => {
  try {
    const url = `${specific}`;
    let params = {};
    params = {
      filter: {
        include: includeArray,
        limit: 10,
        skip: 0,
        order: ["id asc"],
        where: {},
        // "where":{"and":[{"dept":{"like":"OPS1%"}}]}
      },
    };

    if (_.isEmpty(params.filter.include)) {
      delete params.filter.include;
    }

    const request = serverApi("GET", url, params, "", options);

    return request;
  } catch (err) {
    console.log(err);

    // throw error
  }
};
