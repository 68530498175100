import React from "react";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandPointLeft,
  faEraser,
} from "@fortawesome/free-solid-svg-icons/index";
import compose from "ramda/es/compose";
import { connect } from "react-redux";
import HandleAllHOC from "../../lib/HOCs/HandleAll";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import customSelectStyles from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import {
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
} from "@material-ui/core";
import Gridxa from "@material-ui/core/Grid";
import CustomInputStep1 from "../../components/CustomInput/CustomInputStep1";
import Search from "@material-ui/icons/Search";

class TableList2 extends React.Component {
  _export;
  _grid;

  constructor(props) {
    super(props);

    this.state = {
      rowIsExpanded: false,
      modalVisible: false,
      left: 450,
      top: 220,
      width: 1200,
      height: 780,
      howManyTable: this.props.howManyTable || 1,
      boxID: parseInt(this.props.match.params.id) || "",
      invoiceID: parseInt(this.props.match.params.id) || "",
      boxIdInputSuggestion: "",
      invoiceIdInputSuggestion: "",
      boxIDError: "",
      invoiceIDError: "",
    };
  }

  componentWillUnmount() {
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));
    if (hasChangedColumns) {
      window.localStorage.removeItem("cols");
    }

    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    if (hasFinalParams) {
      window.localStorage.removeItem("finalParams");
    }

    let hasFlagEnabled = window.localStorage.getItem("flagSet");
    if (hasFlagEnabled) {
      window.localStorage.removeItem("flagSet");
    }

    let hasExportFilter = window.localStorage.getItem("exportFilter");
    if (hasExportFilter) {
      window.localStorage.removeItem("exportFilter");
    }
  }

  exportToCsv = () => {
    // pass the products, instead the paginated data in the state.
    this._export.save(this.props.gridData.data, this.props.columns);
  };

  hasNumbers = t => {
    var regex = /^(0|[1-9][0-9]{0,2}(?:(,[0-9]{3})*|[0-9]*))(\.[0-9]+){0,1}$/;
    return regex.test(t);
  };

  onBoxIdChange = (e, props) => {
    let boxInputValue = e.target.value;
    let decide = this.hasNumbers(boxInputValue);
    if (decide !== true) {
      this.setState({
        boxID: boxInputValue || "",
      });
      props.history.push(`/app/master/logbox/:id`);
    } else {
      let inputVal = parseInt(e.target.value);

      this.setState({
        ...this.state,
        boxIDError: "",
      });

      if (inputVal !== props.match.params.id) {
        window.localStorage.removeItem("orderIDNextCall");

        this.setState(
          {
            boxID: isNaN(inputVal) ? "" : inputVal,
          },
          () => {
            props.history.push(`/app/master/logbox/${this.state.boxID}`);
          },
        );
      }
    }
  };

  onInvoiceIdChange = (e, props) => {
    let invoiceInputValue = e.target.value;
    let decide = this.hasNumbers(invoiceInputValue);
    if (decide !== true) {
      this.setState({
        invoiceID: invoiceInputValue || "",
      });
      props.history.push(`/app/master/invoicelog/:id`);
    } else {
      let inputVal = parseInt(e.target.value);

      this.setState({
        ...this.state,
        invoiceIDError: "",
      });

      if (inputVal !== props.match.params.id) {
        window.localStorage.removeItem("orderIDNextCall");

        this.setState(
          {
            invoiceID: isNaN(inputVal) ? "" : inputVal,
          },
          () => {
            props.history.push(
              `/app/master/invoicelog/${this.state.invoiceID}`,
            );
          },
        );
      }
    }
  };

  onSearchHandler = (e, props) => {
    if (props.tableTitle === "Invoice Log") {
      let decide = this.hasNumbers(this.state.invoiceID);

      if (decide !== true) {
        this.setState({
          ...this.state,
          invoiceIDError: "Please provide an invoice id with numbers only",
        });
        return;
      } else {
        let y = () =>
          setTimeout(() => {
            this.props.refreshGridDataAfterCrud();
          }, 200);

        y();
      }
    } else {
      let decide = this.hasNumbers(this.state.boxID);

      if (decide !== true) {
        this.setState({
          ...this.state,
          boxIDError: "Please provide a box id with numbers only",
        });
        return;
      } else {
        let y = () =>
          setTimeout(() => {
            this.props.refreshGridDataAfterCrud();
          }, 200);

        y();
      }
    }
  };

  constructTheReturnTable1 = (
    allGrid,
    showTableColumnSelection,
    showToggleColumns,
    columns,
    handleColumnsChange,
    showExportButton,
    exportTooltip,
    showGoBackButton,
    goBackTooltip,
    goBackFunc,
    showRefreshDataButton,
    refreshGridDataAfterCrud1,
    showClearFilters,
    clearGridColumnFilters,
    showCreateButton,
    createDetails,
    redirectToCreateForm,
    showCreateTable,
    goToAddOrderTable,
    showExpandedComponent,
    tableTitle,
    logo,
    dataState,
    selectionChange,
    filterable,
    reorderable,
    sortable,
    showPagination,
    dataStateChange,
    showGridActions,
    renderGridColumns,
    renderGridActions,
    finalData,
    clearFilters,
    labelPrinted,
  ) => {
    const showBack =
      showGoBackButton &&
      <GridToolbar>
        <Tooltip justify={"flex-end"} title={goBackTooltip.tooltip}>
          <IconButton disabled={false} aria-label="" onClick={goBackFunc}>
            <FontAwesomeIcon color="#f50057" size="1x" icon={faHandPointLeft} />
          </IconButton>
        </Tooltip>
      </GridToolbar>;

    // const showGridRefresh = showRefreshDataButton && <span>
    //     <Tooltip justify={'flex-start'} title={'Refresh Data'}>
    //         <IconButton
    //             disabled={false}
    //             aria-label=""
    //             onClick={refreshGridDataAfterCrud1}>
    //             <FontAwesomeIcon color="#0D5869" size="1x" icon={faSyncAlt}/>
    //         </IconButton>
    //     </Tooltip>
    // </span>;

    //this.context.router.push
    const showBoxIdInput = props =>
      true &&
      <CustomInputStep1
        labelText={
          <span style={{ fontWeight: "bold", fontSize: "14px" }}>
            Search Box ID :
          </span>
        }
        id="showBoxIdInput"
        formControlProps={{
          fullWidth: false,
        }}
        inputProps={{
          value: `${this.state.boxID}` || "",
          onChange: e => this.onBoxIdChange(e, props),
        }}
      />;

    const showInvoiceIdInput = props =>
      true &&
      <CustomInputStep1
        labelText={
          <span style={{ fontWeight: "bold", fontSize: "14px" }}>
            Search Invoice ID :
          </span>
        }
        id="showBoxIdInput"
        formControlProps={{
          fullWidth: false,
        }}
        inputProps={{
          value: `${this.state.invoiceID}` || "",
          onChange: e => this.onInvoiceIdChange(e, props),
        }}
      />;

    // const label = window.localStorage.getItem('labelPrinted') || ""
    let localDate;
    if (labelPrinted) {
      localDate = new Date(labelPrinted);
      localDate.toUTCString();
    } else {
      localDate = "Not Printed";
    }

    return (
      <div>
        <GridContainer
          style={{ backgroundColor: "#white" }}
          xs={12}
          justify={"flex-start"}
          direction={"row"}
        >
          {tableTitle !== "" && tableTitle !== "Invalid Date"
            ? <h2 style={{ marginLeft: "30px", color: "#0D5869" }}>
                {tableTitle}
                {/*{showGridRefresh}*/}
              </h2>
            : <div style={{ alignItems: "center", margin: "auto" }}>
                <img
                  alt=""
                  style={{ width: "50%" }}
                  src={logo}
                  width="70"
                  height="70"
                />
              </div>}
        </GridContainer>
        <Gridxa item>
          <GridContainer
            style={{ backgroundColor: "#white" }}
            xs={12}
            justify={"flex-start"}
            direction={"row"}
          >
            {labelPrinted !== ""
              ? <div style={{ marginLeft: "30px" }}>
                  {tableTitle === "Box Log"
                    ? this.state.boxIDError !== ""
                      ? <p style={{ color: "red" }}>
                          {this.state.boxIDError}
                        </p>
                      : null
                    : this.state.invoiceIDError !== ""
                      ? <p style={{ color: "red" }}>
                          {this.state.invoiceIDError}
                        </p>
                      : null}
                  {tableTitle === "Box Log"
                    ? showBoxIdInput(this.props)
                    : showInvoiceIdInput(this.props)}
                  <Tooltip
                    style={{ color: "orange" }}
                    justify={"flex-end"}
                    title={"Search"}
                    disableFocusListener={true}
                  >
                    <IconButton
                      type="submit"
                      onClick={e => this.onSearchHandler(e, this.props)}
                    >
                      <Search />
                    </IconButton>
                  </Tooltip>
                  {tableTitle === "Box Log"
                    ? <h4 style={{ color: "orange" }}>
                        {`LABEL PRINTED : ${localDate}`}
                      </h4>
                    : null}
                </div>
              : null}
          </GridContainer>
          {/*<GridContainer xs={12} justify={"flex-end"} direction={"row"}>*/}
          {/*    {showGridRefresh}*/}
          {/*</GridContainer>*/}
          {
            <GridContainer xs={12} justify={"space-around"} direction={"row"}>
              <GridContainer xs={4} justify={"flex-end"} direction={"row"}>
                {showBack}
              </GridContainer>
            </GridContainer>
          }
          {
            <Grid
              {...dataState}
              data={finalData}
              heigh={"100%"}
              resizable
              selectedField="selected"
              onSelectionChange={selectionChange}
              filterable={filterable}
              reorderable={reorderable}
              sortable={sortable}
              expandField="expanded"
              pageable={
                showPagination
                  ? {
                      buttonCount: 5,
                      info: true,
                      pageSizes: [5, 10, 20, 50, 100],
                    }
                  : false
              }
              onDataStateChange={dataStateChange}
              ref={grid => {
                this._grid = grid;
              }}
            >
              {showGridActions
                ? [
                    ...renderGridColumns(),
                    ...[
                      <Column
                        title="Actions"
                        cell={renderGridActions()}
                        width={"100%"}
                        filterable={false}
                      />,
                    ],
                  ]
                : [...renderGridColumns()]}
            </Grid>
          }
        </Gridxa>
        <br />
      </div>
    );
  };

  constructTheReturnTable2 = (
    allGrid,
    showTableColumnSelection,
    showToggleColumns,
    columns2,
    handleColumnsChange,
    showExportButton,
    exportTooltip,
    showGoBackButton,
    goBackTooltip,
    goBackFunc,
    showRefreshDataButton,
    refreshGridDataAfterCrud2,
    showClearFilters,
    clearGridColumnFilters,
    showCreateButton,
    createDetails,
    redirectToCreateForm,
    showCreateTable,
    goToAddOrderTable,
    showExpandedComponent,
    tableTitle2,
    logo,
    dataState,
    selectionChange,
    filterable,
    reorderable,
    sortable,
    showPagination,
    dataStateChange,
    showGridActions,
    renderGridColumns2,
    renderGridActions,
    finalData2,
    clearFilters,
  ) => {
    const tableColumnSelection =
      showTableColumnSelection &&
      showToggleColumns &&
      <GridItem xs={2} sm={4} md={4} lg={2} xl={2}>
        <FormControl fullWidth className={customSelectStyles.selectFormControl}>
          <InputLabel
            htmlFor="multiple-select"
            className={customSelectStyles.selectLabel}
          />
          <Select
            autoWidth={true}
            multiple
            value={columns2
              .filter(column => column.visible)
              .map(column => column.field)}
            onChange={handleColumnsChange}
            MenuProps={{ className: customSelectStyles.selectMenu }}
            inputProps={{
              name: "multipleSelect",
              id: "multiple-select",
            }}
            renderValue={selected => "Pick Columns"}
          >
            {columns2
              .filter(col => !col.notShownInTableColumnSelection)
              .map(column => {
                let name = column.title;
                let value = column.field;
                let isVisible = column.visible;
                let isDisabled = column.noDisabledColumn;

                return (
                  <MenuItem key={name} value={value} disabled={isDisabled}>
                    <Checkbox checked={isVisible} />
                    <ListItemText primary={name} />
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </GridItem>;

    const showBack =
      showGoBackButton &&
      <GridToolbar>
        <Tooltip justify={"flex-end"} title={goBackTooltip.tooltip}>
          <IconButton disabled={false} aria-label="" onClick={goBackFunc}>
            <FontAwesomeIcon color="#f50057" size="1x" icon={faHandPointLeft} />
          </IconButton>
        </Tooltip>
      </GridToolbar>;

    // const showGridRefresh = showRefreshDataButton && <span>
    //     <Tooltip justify={'flex-start'} title={'Refresh Data'}>
    //         <IconButton
    //             disabled={false}
    //             aria-label=""
    //             onClick={refreshGridDataAfterCrud2}>
    //             <FontAwesomeIcon color="#0D5869" size="1x" icon={faSyncAlt}/>
    //         </IconButton>
    //     </Tooltip>
    // </span>;

    const showClearGridFilters =
      showClearFilters &&
      <GridToolbar>
        <Tooltip justify={"flex-end"} title={clearFilters.tooltip}>
          <IconButton
            disabled={false}
            aria-label=""
            onClick={clearGridColumnFilters}
          >
            <FontAwesomeIcon color="#f50057" size="1x" icon={faEraser} />
          </IconButton>
        </Tooltip>
      </GridToolbar>;

    const showCreate =
      showCreateButton &&
      <GridItem xs={1}>
        <Tooltip justify={"flex-end"} title={createDetails.tooltip}>
          <IconButton>
            <Fab size="medium" color="secondary" aria-label="Add">
              <AddIcon onClick={redirectToCreateForm} />
            </Fab>
          </IconButton>
        </Tooltip>
      </GridItem>;

    const goToCreateTable =
      showCreateTable &&
      <GridItem xs={1}>
        <Tooltip justify={"flex-end"} title={createDetails.tooltip}>
          <IconButton>
            <Fab size="medium" color="secondary" aria-label="Add">
              <AddIcon onClick={goToAddOrderTable} />
            </Fab>
          </IconButton>
        </Tooltip>
      </GridItem>;

    // const showExpandedData = showExpandedComponent && GalleryRenderer;

    return (
      <div>
        <GridContainer
          style={{ backgroundColor: "#white" }}
          xs={12}
          justify={"flex-start"}
          direction={"row"}
        >
          {tableTitle2 !== ""
            ? <h2 style={{ marginLeft: "30px", color: "#0D5869" }}>
                {tableTitle2}
                {/*{showGridRefresh}*/}
              </h2>
            : <div style={{ alignItems: "center", margin: "auto" }}>
                <img
                  alt=""
                  style={{ width: "50%" }}
                  src={logo}
                  width="70"
                  height="70"
                />
              </div>}
        </GridContainer>
        <Gridxa item>
          {/*<GridContainer xs={12} justify={"flex-end"} direction={"row"}>*/}
          {/*    {showGridRefresh}*/}
          {/*</GridContainer>*/}
          {
            <GridContainer xs={12} justify={"space-around"} direction={"row"}>
              <GridContainer xs={6} justify={"flex-start"} direction={"row"}>
                {tableColumnSelection}
                {goToCreateTable}
              </GridContainer>
              <GridContainer xs={4} justify={"flex-end"} direction={"row"}>
                {showBack}
              </GridContainer>
              <GridContainer xs={2} justify={"flex-start"} direction={"row"}>
                {showClearGridFilters}
                {showCreate}
              </GridContainer>
            </GridContainer>
          }
          {
            <Grid
              {...dataState}
              data={finalData2}
              heigh={"100%"}
              resizable
              selectedField="selected"
              onSelectionChange={selectionChange}
              filterable={filterable}
              reorderable={reorderable}
              sortable={sortable}
              expandField="expanded"
              // detail={showExpandedData}
              pageable={
                showPagination
                  ? {
                      buttonCount: 5,
                      info: true,
                      pageSizes: [5, 10, 20, 50, 100],
                    }
                  : false
              }
              onDataStateChange={dataStateChange}
              ref={grid => {
                this._grid = grid;
              }}
            >
              {showGridActions
                ? [
                    ...renderGridColumns2(),
                    ...[
                      <Column
                        title="Actions"
                        cell={renderGridActions()}
                        width={"100%"}
                        filterable={false}
                      />,
                    ],
                  ]
                : [...renderGridColumns2()]}
            </Grid>
          }
        </Gridxa>
        <br />
      </div>
    );
  };

  constructTheReturnTable3 = (
    allGrid,
    showTableColumnSelection,
    showToggleColumns,
    columns3,
    handleColumnsChange,
    showExportButton,
    exportTooltip,
    showGoBackButton,
    goBackTooltip,
    goBackFunc,
    showRefreshDataButton,
    refreshGridDataAfterCrud3,
    showClearFilters,
    clearGridColumnFilters,
    showCreateButton,
    createDetails,
    redirectToCreateForm,
    showCreateTable,
    goToAddOrderTable,
    showExpandedComponent,
    tableTitle3,
    logo,
    dataState,
    selectionChange,
    filterable,
    reorderable,
    sortable,
    showPagination,
    dataStateChange,
    showGridActions,
    renderGridColumns3,
    renderGridActions,
    finalData3,
    clearFilters,
  ) => {
    const tableColumnSelection =
      showTableColumnSelection &&
      showToggleColumns &&
      <GridItem xs={2} sm={4} md={4} lg={2} xl={2}>
        <FormControl fullWidth className={customSelectStyles.selectFormControl}>
          <InputLabel
            htmlFor="multiple-select"
            className={customSelectStyles.selectLabel}
          />
          <Select
            autoWidth={true}
            multiple
            value={columns3
              .filter(column => column.visible)
              .map(column => column.field)}
            onChange={handleColumnsChange}
            MenuProps={{ className: customSelectStyles.selectMenu }}
            inputProps={{
              name: "multipleSelect",
              id: "multiple-select",
            }}
            renderValue={selected => "Pick Columns"}
          >
            {columns3
              .filter(col => !col.notShownInTableColumnSelection)
              .map(column => {
                let name = column.title;
                let value = column.field;
                let isVisible = column.visible;
                let isDisabled = column.noDisabledColumn;

                return (
                  <MenuItem key={name} value={value} disabled={isDisabled}>
                    <Checkbox checked={isVisible} />
                    <ListItemText primary={name} />
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </GridItem>;

    const showBack =
      showGoBackButton &&
      <GridToolbar>
        <Tooltip justify={"flex-end"} title={goBackTooltip.tooltip}>
          <IconButton disabled={false} aria-label="" onClick={goBackFunc}>
            <FontAwesomeIcon color="#f50057" size="1x" icon={faHandPointLeft} />
          </IconButton>
        </Tooltip>
      </GridToolbar>;

    // const showGridRefresh = showRefreshDataButton && <span>
    //     <Tooltip justify={'flex-start'} title={'Refresh Data'}>
    //         <IconButton
    //             disabled={false}
    //             aria-label=""
    //             onClick={refreshGridDataAfterCrud3}>
    //             <FontAwesomeIcon color="#0D5869" size="1x" icon={faSyncAlt}/>
    //         </IconButton>
    //     </Tooltip>
    // </span>;

    return (
      <div>
        <GridContainer
          style={{ backgroundColor: "#white" }}
          xs={12}
          justify={"flex-start"}
          direction={"row"}
        >
          {tableTitle3 !== ""
            ? <h2 style={{ marginLeft: "30px", color: "#0D5869" }}>
                {tableTitle3}
                {/*{showGridRefresh}*/}
              </h2>
            : <div style={{ alignItems: "center", margin: "auto" }}>
                <img
                  alt=""
                  style={{ width: "50%" }}
                  src={logo}
                  width="70"
                  height="70"
                />
              </div>}
        </GridContainer>
        <Gridxa item>
          {
            <GridContainer xs={12} justify={"space-around"} direction={"row"}>
              <GridContainer xs={6} justify={"flex-start"} direction={"row"}>
                {tableColumnSelection}
              </GridContainer>
              <GridContainer xs={4} justify={"flex-end"} direction={"row"}>
                {showBack}
              </GridContainer>
            </GridContainer>
          }
          {
            <Grid
              {...dataState}
              data={finalData3}
              heigh={"100%"}
              resizable
              selectedField="selected"
              onSelectionChange={selectionChange}
              filterable={filterable}
              reorderable={reorderable}
              sortable={sortable}
              expandField="expanded"
              pageable={
                showPagination
                  ? {
                      buttonCount: 5,
                      info: true,
                      pageSizes: [5, 10, 20, 50, 100],
                    }
                  : false
              }
              onDataStateChange={dataStateChange}
              ref={grid => {
                this._grid = grid;
              }}
            >
              {showGridActions
                ? [
                    ...renderGridColumns3(),
                    ...[
                      <Column
                        title="Actions"
                        cell={renderGridActions()}
                        width={"100%"}
                        filterable={false}
                      />,
                    ],
                  ]
                : [...renderGridColumns3()]}
            </Grid>
          }
        </Gridxa>
      </div>
    );
  };

  constructTheReturnTable4 = (
    allGrid,
    showTableColumnSelection,
    showToggleColumns,
    columns4,
    handleColumnsChange,
    showExportButton,
    exportTooltip,
    showGoBackButton,
    goBackTooltip,
    goBackFunc,
    showRefreshDataButton,
    refreshGridDataAfterCrud4,
    showClearFilters,
    clearGridColumnFilters,
    showCreateButton,
    createDetails,
    redirectToCreateForm,
    showCreateTable,
    goToAddOrderTable,
    showExpandedComponent,
    tableTitle4,
    logo,
    dataState,
    selectionChange,
    filterable,
    reorderable,
    sortable,
    showPagination,
    dataStateChange,
    showGridActions,
    renderGridColumns4,
    renderGridActions,
    finalData4,
    clearFilters,
  ) => {
    const tableColumnSelection =
      showTableColumnSelection &&
      showToggleColumns &&
      <GridItem xs={2} sm={4} md={4} lg={2} xl={2}>
        <FormControl fullWidth className={customSelectStyles.selectFormControl}>
          <InputLabel
            htmlFor="multiple-select"
            className={customSelectStyles.selectLabel}
          />
          <Select
            autoWidth={true}
            multiple
            value={columns4
              .filter(column => column.visible)
              .map(column => column.field)}
            onChange={handleColumnsChange}
            MenuProps={{ className: customSelectStyles.selectMenu }}
            inputProps={{
              name: "multipleSelect",
              id: "multiple-select",
            }}
            renderValue={selected => "Pick Columns"}
          >
            {columns4
              .filter(col => !col.notShownInTableColumnSelection)
              .map(column => {
                let name = column.title;
                let value = column.field;
                let isVisible = column.visible;
                let isDisabled = column.noDisabledColumn;

                return (
                  <MenuItem key={name} value={value} disabled={isDisabled}>
                    <Checkbox checked={isVisible} />
                    <ListItemText primary={name} />
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </GridItem>;

    const showBack =
      showGoBackButton &&
      <GridToolbar>
        <Tooltip justify={"flex-end"} title={goBackTooltip.tooltip}>
          <IconButton disabled={false} aria-label="" onClick={goBackFunc}>
            <FontAwesomeIcon color="#f50057" size="1x" icon={faHandPointLeft} />
          </IconButton>
        </Tooltip>
      </GridToolbar>;

    return (
      <div>
        <GridContainer
          style={{ backgroundColor: "#white" }}
          xs={12}
          justify={"flex-start"}
          direction={"row"}
        >
          {tableTitle4 !== ""
            ? <h2 style={{ marginLeft: "30px", color: "#0D5869" }}>
                {tableTitle4}
                {/*{showGridRefresh}*/}
              </h2>
            : <div style={{ alignItems: "center", margin: "auto" }}>
                <img
                  alt=""
                  style={{ width: "50%" }}
                  src={logo}
                  width="70"
                  height="70"
                />
              </div>}
        </GridContainer>
        <Gridxa item>
          {
            <GridContainer xs={12} justify={"space-around"} direction={"row"}>
              <GridContainer xs={6} justify={"flex-start"} direction={"row"}>
                {tableColumnSelection}
              </GridContainer>
              <GridContainer xs={4} justify={"flex-end"} direction={"row"}>
                {showBack}
              </GridContainer>
            </GridContainer>
          }
          {
            <Grid
              {...dataState}
              data={finalData4}
              heigh={"100%"}
              resizable
              selectedField="selected"
              onSelectionChange={selectionChange}
              filterable={filterable}
              reorderable={reorderable}
              sortable={sortable}
              expandField="expanded"
              pageable={
                showPagination
                  ? {
                      buttonCount: 5,
                      info: true,
                      pageSizes: [5, 10, 20, 50, 100],
                    }
                  : false
              }
              onDataStateChange={dataStateChange}
              ref={grid => {
                this._grid = grid;
              }}
            >
              {showGridActions
                ? [
                    ...renderGridColumns4(),
                    ...[
                      <Column
                        title="Actions"
                        cell={renderGridActions()}
                        width={"100%"}
                        filterable={false}
                      />,
                    ],
                  ]
                : [...renderGridColumns4()]}
            </Grid>
          }
        </Gridxa>
      </div>
    );
  };

  constructTheReturnTable5 = (
    allGrid,
    showTableColumnSelection,
    showToggleColumns,
    columns5,
    handleColumnsChange,
    showExportButton,
    exportTooltip,
    showGoBackButton,
    goBackTooltip,
    goBackFunc,
    showRefreshDataButton,
    refreshGridDataAfterCrud5,
    showClearFilters,
    clearGridColumnFilters,
    showCreateButton,
    createDetails,
    redirectToCreateForm,
    showCreateTable,
    goToAddOrderTable,
    showExpandedComponent,
    tableTitle5,
    logo,
    dataState,
    selectionChange,
    filterable,
    reorderable,
    sortable,
    showPagination,
    dataStateChange,
    showGridActions,
    renderGridColumns5,
    renderGridActions,
    finalData5,
    clearFilters,
  ) => {
    const tableColumnSelection =
      showTableColumnSelection &&
      showToggleColumns &&
      <GridItem xs={2} sm={4} md={4} lg={2} xl={2}>
        <FormControl fullWidth className={customSelectStyles.selectFormControl}>
          <InputLabel
            htmlFor="multiple-select"
            className={customSelectStyles.selectLabel}
          />
          <Select
            autoWidth={true}
            multiple
            value={columns5
              .filter(column => column.visible)
              .map(column => column.field)}
            onChange={handleColumnsChange}
            MenuProps={{ className: customSelectStyles.selectMenu }}
            inputProps={{
              name: "multipleSelect",
              id: "multiple-select",
            }}
            renderValue={selected => "Pick Columns"}
          >
            {columns5
              .filter(col => !col.notShownInTableColumnSelection)
              .map(column => {
                let name = column.title;
                let value = column.field;
                let isVisible = column.visible;
                let isDisabled = column.noDisabledColumn;

                return (
                  <MenuItem key={name} value={value} disabled={isDisabled}>
                    <Checkbox checked={isVisible} />
                    <ListItemText primary={name} />
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </GridItem>;

    const showBack =
      showGoBackButton &&
      <GridToolbar>
        <Tooltip justify={"flex-end"} title={goBackTooltip.tooltip}>
          <IconButton disabled={false} aria-label="" onClick={goBackFunc}>
            <FontAwesomeIcon color="#f50057" size="1x" icon={faHandPointLeft} />
          </IconButton>
        </Tooltip>
      </GridToolbar>;

    return (
      <div>
        <GridContainer
          style={{ backgroundColor: "#white" }}
          xs={12}
          justify={"flex-start"}
          direction={"row"}
        >
          {tableTitle5 !== ""
            ? <h2 style={{ marginLeft: "30px", color: "#0D5869" }}>
                {tableTitle5}
                {/*{showGridRefresh}*/}
              </h2>
            : <div style={{ alignItems: "center", margin: "auto" }}>
                <img
                  alt=""
                  style={{ width: "50%" }}
                  src={logo}
                  width="70"
                  height="70"
                />
              </div>}
        </GridContainer>
        <Gridxa item>
          {
            <GridContainer xs={12} justify={"space-around"} direction={"row"}>
              <GridContainer xs={6} justify={"flex-start"} direction={"row"}>
                {tableColumnSelection}
              </GridContainer>
              <GridContainer xs={4} justify={"flex-end"} direction={"row"}>
                {showBack}
              </GridContainer>
            </GridContainer>
          }
          {
            <Grid
              {...dataState}
              data={finalData5}
              heigh={"100%"}
              resizable
              selectedField="selected"
              onSelectionChange={selectionChange}
              filterable={filterable}
              reorderable={reorderable}
              sortable={sortable}
              expandField="expanded"
              pageable={
                showPagination
                  ? {
                      buttonCount: 5,
                      info: true,
                      pageSizes: [5, 10, 20, 50, 100],
                    }
                  : false
              }
              onDataStateChange={dataStateChange}
              ref={grid => {
                this._grid = grid;
              }}
            >
              {showGridActions
                ? [
                    ...renderGridColumns5(),
                    ...[
                      <Column
                        title="Actions"
                        cell={renderGridActions()}
                        width={"100%"}
                        filterable={false}
                      />,
                    ],
                  ]
                : [...renderGridColumns5()]}
            </Grid>
          }
        </Gridxa>
      </div>
    );
  };

  render() {
    const {
      columns,
      columns2,
      columns3,
      columns4,
      columns5,
      renderGridColumns,
      renderGridColumns2,
      renderGridColumns3,
      renderGridColumns4,
      renderGridColumns5,
      selectionChange,
      filterable,
      reorderable,
      allGrid,
      sortable,
      dataStateChange,
      renderGridActions,
      handleColumnsChange,
      showTableColumnSelection = true,
      showExpandedComponent = false,
      tableTitle,
      tableTitle2,
      tableTitle3,
      tableTitle4,
      tableTitle5,
      logo,
      redirectToCreateForm,
      goToAddOrderTable,
      dataState,
      goBackFunc,
      refreshGridDataAfterCrud1,
      refreshGridDataAfterCrud2,
      refreshGridDataAfterCrud3,
      refreshGridDataAfterCrud4,
      refreshGridDataAfterCrud5,
      clearGridColumnFilters,
      showExtraComponents: {
        showExportButton,
        showGridActions = true,
        showCreateTable,
        showPagination,
        showCreateButton,
        exportTooltip,
        createDetails,
        showGoBackButton,
        goBackTooltip,
        showClearFilters,
        clearFilters,
        showToggleColumns,
        showRefreshDataButton,
      },
      showTable2 = false,
      showTable3 = false,
      showTable4 = false,
      showTable5 = false,
      labelPrinted,
    } = this.props;

    let finalData = allGrid && allGrid.gridData;
    let finalData2 = allGrid && allGrid.gridData1;
    let finalData3 = allGrid && allGrid.gridData2;
    let finalData4 = allGrid && allGrid.gridData4;
    let finalData5 = allGrid && allGrid.gridData5;

    return (
      <div>
        {this.constructTheReturnTable1(
          allGrid,
          showTableColumnSelection,
          showToggleColumns,
          columns,
          handleColumnsChange,
          showExportButton,
          exportTooltip,
          showGoBackButton,
          goBackTooltip,
          goBackFunc,
          showRefreshDataButton,
          refreshGridDataAfterCrud1,
          showClearFilters,
          clearGridColumnFilters,
          showCreateButton,
          createDetails,
          redirectToCreateForm,
          showCreateTable,
          goToAddOrderTable,
          showExpandedComponent,
          tableTitle,
          logo,
          dataState,
          selectionChange,
          filterable,
          reorderable,
          sortable,
          showPagination,
          dataStateChange,
          showGridActions,
          renderGridColumns,
          renderGridActions,
          finalData,
          clearFilters,
          labelPrinted,
        )}
        {showTable2
          ? this.constructTheReturnTable2(
              allGrid,
              showTableColumnSelection,
              showToggleColumns,
              columns2,
              handleColumnsChange,
              showExportButton,
              exportTooltip,
              showGoBackButton,
              goBackTooltip,
              goBackFunc,
              showRefreshDataButton,
              refreshGridDataAfterCrud2,
              showClearFilters,
              clearGridColumnFilters,
              showCreateButton,
              createDetails,
              redirectToCreateForm,
              showCreateTable,
              goToAddOrderTable,
              showExpandedComponent,
              tableTitle2,
              logo,
              dataState,
              selectionChange,
              filterable,
              reorderable,
              sortable,
              showPagination,
              dataStateChange,
              showGridActions,
              renderGridColumns2,
              renderGridActions,
              finalData2,
              clearFilters,
            )
          : null}
        {showTable3
          ? this.constructTheReturnTable3(
              allGrid,
              showTableColumnSelection,
              showToggleColumns,
              columns3,
              handleColumnsChange,
              showExportButton,
              exportTooltip,
              showGoBackButton,
              goBackTooltip,
              goBackFunc,
              showRefreshDataButton,
              refreshGridDataAfterCrud3,
              showClearFilters,
              clearGridColumnFilters,
              showCreateButton,
              createDetails,
              redirectToCreateForm,
              showCreateTable,
              goToAddOrderTable,
              showExpandedComponent,
              tableTitle3,
              logo,
              dataState,
              selectionChange,
              filterable,
              reorderable,
              sortable,
              showPagination,
              dataStateChange,
              showGridActions,
              renderGridColumns3,
              renderGridActions,
              finalData3,
              clearFilters,
            )
          : null}
        {showTable4 && showTable5
          ? <GridContainer
              style={{ backgroundColor: "#white" }}
              xs={12}
              justify={"flex-start"}
              direction={"row"}
            >
              {this.constructTheReturnTable4(
                allGrid,
                showTableColumnSelection,
                showToggleColumns,
                columns4,
                handleColumnsChange,
                showExportButton,
                exportTooltip,
                showGoBackButton,
                goBackTooltip,
                goBackFunc,
                showRefreshDataButton,
                refreshGridDataAfterCrud4,
                showClearFilters,
                clearGridColumnFilters,
                showCreateButton,
                createDetails,
                redirectToCreateForm,
                showCreateTable,
                goToAddOrderTable,
                showExpandedComponent,
                tableTitle4,
                logo,
                dataState,
                selectionChange,
                filterable,
                reorderable,
                sortable,
                showPagination,
                dataStateChange,
                showGridActions,
                renderGridColumns4,
                renderGridActions,
                finalData4,
                clearFilters,
              )}
              {this.constructTheReturnTable5(
                allGrid,
                showTableColumnSelection,
                showToggleColumns,
                columns5,
                handleColumnsChange,
                showExportButton,
                exportTooltip,
                showGoBackButton,
                goBackTooltip,
                goBackFunc,
                showRefreshDataButton,
                refreshGridDataAfterCrud5,
                showClearFilters,
                clearGridColumnFilters,
                showCreateButton,
                createDetails,
                redirectToCreateForm,
                showCreateTable,
                goToAddOrderTable,
                showExpandedComponent,
                tableTitle5,
                logo,
                dataState,
                selectionChange,
                filterable,
                reorderable,
                sortable,
                showPagination,
                dataStateChange,
                showGridActions,
                renderGridColumns5,
                renderGridActions,
                finalData5,
                clearFilters,
              )}
            </GridContainer>
          : null}
        <br />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default compose(
  connect(mapStateToProps, null),
  HandleAllHOC({ domain: "tableList" }),
)(TableList2);
