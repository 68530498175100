import React from "react";
import {
  Grid,
  GridColumn as Column,
  GridDetailRow,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { columnsSchema } from "./schema";
import { MyCommandCell } from "../../../../Clients/View/components/CellHOC";
import _ from "lodash";
import { DropDownCell } from "../../../../CRM/View/dropDownCell";
import GridContainer from "../../../../../components/Grid/GridContainer";
import { serverApi } from "../../../../../networking/config";
import { AbstractDelete } from "../../../../../networking/apiCalls";
import { Window } from "@progress/kendo-react-dialogs";
import { filterBy } from "@progress/kendo-data-query";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { Error } from "@progress/kendo-react-labels";
import { IconButton, Tooltip } from "@material-ui/core";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ClientToBillingEntityExpanded extends GridDetailRow {
  editField = "inEdit";
  CommandCell;

  constructor(props) {
    super(props);
    this.state = {
      visibleWindow: false,
      columns: (columnsSchema && columnsSchema()) || [],
      gridData: { data: [], total: 0 },
      initialGridData: { data: [], total: 0 },
      billingEntities: [],
      relation_country: "",
      valueBillingEntity: "",
      errorModal: false,
      errorModalMessage: "",
      billing_entities: [],
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      disableEditButton: false,
      showSecondAction: true,
      showFirstAction: false,
    });
  }

  async componentDidMount() {
    let options = { token: window.localStorage.getItem("access_token") };
    let filter = {
      filter: {
        where: { clientId: this.props.dataItem.id },
        include: ["billingEntity"],
      },
    };

    const responseData = await serverApi(
      "GET",
      "clientToBillingEntities",
      filter,
      "",
      options
    );
    const getBillingEntities = await serverApi(
      "GET",
      "masterEntities",
      "",
      "",
      options
    );

    if (responseData && responseData.data.length === 1) {
      this.setState({
        ...this.state,
        clientId: this.props.dataItem.id,
        gridData: {
          data: [responseData && responseData.data[0]],
          total: 1,
        },
        initialGridData: {
          data: [responseData && responseData.data[0]],
          total: 1,
        },
        billing_entities: getBillingEntities.data.sort((a, b) =>
          a.relation_name > b.relation_name ? 1 : -1
        ),
        valueBillingEntity: "",
      });
    } else if (responseData && responseData.data.length > 1) {
      let finalData = [];
      _.forEach(responseData.data, (temp) => {
        finalData.push(temp);
      });

      this.setState({
        ...this.state,
        clientId: this.props.dataItem.id,
        gridData: {
          data: finalData,
          total: finalData.length,
        },
        initialGridData: {
          data: finalData,
          total: finalData.length,
        },
        billing_entities: getBillingEntities.data,
      });
    } else {
      this.setState({
        ...this.state,
        clientId: this.props.dataItem.id,
        gridData: {
          data: [],
          total: 0,
        },
        initialGridData: {
          data: [],
          total: 0,
        },
        billing_entities: getBillingEntities.data,
      });
    }

    this.setState({
      gridData: {
        data: responseData?.data || [],
        total: responseData?.data.length || 0,
      },
      initialGridData: {
        data: responseData?.data || [],
        total: responseData?.data.length || 0,
      },
      billingEntities: getBillingEntities?.data || [],
    });
  }

  exportToCsv = () => {
    //todo make export without count just like v2.
    this._export.save(this.state.gridData.data, this.state.columns);
  };

  renderGridColumns = (dataItem, changeTheCellComponent) => {
    let selectionCol = [];
    let normalCols = [];
    let finalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            width="65px"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.isFilterBoolean === "yes") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              cell={DropDownCell}
            />
          );
        } else {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={temp.field === "id" || temp.editable ? false : true} //id not editable on edit
            />
          );
        }
      });

    finalCols = [...selectionCol, ...normalCols];
    return finalCols;
  };

  onChange = (event) => {
    const { value } = event.target;

    const matchedRecord = this.state.billing_entities.find(
      (b) => b.relation_name === value
    );

    if (matchedRecord) {
      this.setState({
        valueBillingEntity: matchedRecord.relation_name, // Update the input field with the selected name
        postBillingID: matchedRecord.id || "",
        openedEntitiesNames: false, // Close the dropdown
      });
    } else {
      this.setState({
        valueBillingEntity: value,
        postBillingID: "",
        openedEntitiesNames: true,
      });

      if (value.length >= 3) {
        this.fetchAutocompleteData(value);
      }
    }
  };

  updateDetails = (x) => {
    this.setState({
      postBillingID: x.id || "",
    });
  };

  onBlur = () => {
    const matchedRecords = this.state.billing_entities.filter(
      (b) => b.relation_name === this.state.valueBillingEntity
    );

    if (matchedRecords.length === 1) {
      this.updateDetails(matchedRecords[0]);
    }

    this.setState({ openedEntitiesNames: false });
  };

  fetchAutocompleteData = (filterValue) => {
    let options = { token: window.localStorage.getItem("access_token") };
    const filter = {
      filter: {
        where: {
          and: [
            { relation_name: { regexp: `/${filterValue}/i` } },
            { or: [{ client_flag: true }, { isBillingEntity: true }] },
          ],
        },
        order: ["relation_name asc"],
      },
    };

    serverApi("GET", "masterEntities", filter, "", options)
      .then((response) => {
        this.setState({ billing_entities: response.data });
      })
      .catch((error) => console.error(error));
  };

  filterData = (value) => {
    const filter = {
      value: value,
      operator: "startswith",
      ignoreCase: true,
    };

    return filterBy(
      this.state.billing_entities.map((b) => b.relation_name),
      filter
    );
  };

  enterEdit = (dataItem) => {
    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    let total = this.state.gridData.total;

    this.setState({
      gridData: {
        data:
          this.state.gridData &&
          this.state.gridData.data.map((item) =>
            item.id === dataItem.id ? { ...item, inEdit: true } : item
          ),
        total: total,
      },
    });
  };

  discard = (dataItem, hasError = false) => {
    let data = [...this.state.gridData.data];
    const total = this.state.gridData.total;

    if (hasError) {
      data[0].inEdit = true;
      this.setState({
        gridData: {
          data: data,
          total: total,
        },
      });
    } else {
      this.removeItem(data, dataItem);

      this.setState({
        gridData: {
          data: data,
          total: total,
        },
        visible: false,
      });
    }
  };

  cancel = (dataItem) => {
    const originalItem =
      this.state.initialGridData &&
      this.state.initialGridData.data.find((p) => p.id === dataItem.id);
    originalItem.inEdit = false;

    const data =
      this.state.gridData &&
      this.state.gridData.data.map((item) =>
        item.id === originalItem.id ? originalItem : item
      );

    const total = this.state.gridData.total;

    this.setState({
      ...this.state,
      gridData: {
        data: data,
        total: total,
      },
      openErrorModal: false,
      openErrorModalContent: "",
    });
  };

  removeItem(data, item) {
    let index = data.findIndex(
      (p) => p === item || item.id || p.id === item.id
    );
    if (index >= 0) {
      data.splice(index, 1);
    }
  }

  add = (dataItem) => {};

  addNew = () => {
    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    const newDataItem = { inEdit: true };

    this.setState({
      gridData: {
        data: [newDataItem, ...this.state.gridData.data],
        total: this.state.gridData.total,
      },
    });
  };

  update = async (dataItem) => {};

  updateItem = (data, item) => {
    let index = data.findIndex(
      (p) => p === item || (item.id && p.id === item.id)
    );
    if (index >= 0) {
      data[index] = { ...item };
    }
  };

  itemChange = (event) => {};

  saveBillingEntityToClient = async () => {
    try {
      let options = { token: window.localStorage.getItem("access_token") };
      let payload = {
        clientId: this.props.dataItem.id,
        billingEntityId: this.state.postBillingID,
      };
      await serverApi("POST", "clientToBillingEntities", {}, payload, options);
      this.toggleWindow();
      this.componentDidMount();
    } catch (e) {
      this.setState({
        errorModal: true,
        errorModalMessage: "Error attaching billing entity.",
      });
    }
  };

  refreshGridDataAfterCrud = async () => {};

  remove = async (dataItem) => {
    const data = [...this.state.gridData.data];

    let index = data.findIndex(
      (p) => p === dataItem || dataItem.id | (p.id === dataItem.id)
    );
    if (index >= 0) {
      data.splice(index, 1);
    }

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let url = "clientToBillingEntities";

    try {
      let deleteItemRequest = await AbstractDelete(
        url,
        `${dataItem && dataItem.id}`,
        options
      );

      if (deleteItemRequest.status === 200) {
        //clear the column filters if grid data is 0 or let them if grid data is >= 1
        if (data.length <= 0) {
          this.setState({
            ...this.state,
            gridData: {
              data: data,
              total: data.length,
            },
            initialGridData: {
              data: data,
              total: data.length,
            },
            dataState: {
              skip: 0,
              take: 10,
            },
          });
        } else {
          this.setState({
            ...this.state,
            gridData: {
              data: data,
              total: data.length,
            },
            initialGridData: {
              data: data,
              total: data.length,
            },
          });
        }
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent:
          e?.response?.data?.error?.message || "Please try again",
      });
    }
  };

  toggleWindow = (e) => {
    const data = this.state.initialGridData.data;

    this.setState({
      visibleWindow: !this.state.visibleWindow,
      gridData: {
        data: [...data],
        total: 10,
      },
      billing_entities: [],
      valueBillingEntity: "",
      windowError: false,
      windowErrorMessage: "",
    });
  };

  listNoDataRender = (element) => {
    const noData = (
      <h4 style={{ fontSize: "1em" }}>
        <span className="k-icon k-i-warning" style={{ fontSize: "2.5em" }} />
        <br />
        <br />
        No billing entities found
      </h4>
    );

    return React.cloneElement(element, { ...element.props }, noData);
  };

  render() {
    const dataItem = this.props.dataItem;
    const { hasBackofficeRead } = this.props;
    const {
      gridData,
      relation_country,
      visibleWindow,
      errorModal,
      errorModalMessage,
    } = this.state;
    const hasEditedItem = gridData && gridData.data.some((p) => p.inEdit);

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <div
          style={{
            width: "20%",
            marginBottom: "20px",
          }}
        >
          <h4 style={{ fontFamily: "bold", textSize: "7px" }}>
            Client name : {dataItem && dataItem.relation_name}
          </h4>

          <Grid
            {...gridData}
            filterable={false}
            sortable={false}
            resizable={true}
            editField={this.editField}
            pageable={false}
          >
            <GridToolbar>
              <GridContainer>
                <Tooltip title="Add Billing Entity">
                  <IconButton onClick={this.toggleWindow}>
                    <FontAwesomeIcon
                      color="#0D5869"
                      size="1.6x"
                      icon={faPlus}
                    />
                  </IconButton>
                </Tooltip>
              </GridContainer>
            </GridToolbar>
            {!hasBackofficeRead && (
              <Column
                cell={this.CommandCell}
                title="Actions"
                filterable={false}
                width={hasEditedItem ? "240px" : "220px"}
              />
            )}
            {this.renderGridColumns(dataItem)}
          </Grid>
        </div>
        {this.state.visibleWindow && (
          <Window
            title={`Attach billing entities to the client below`}
            onClose={this.toggleWindow}
            width={1150}
            height={580}
            initialTop={10}
          >
            <div>
              <br />
              <fieldset style={{ borderColor: "rgba(13, 88, 105, 1)" }}>
                <br />
                <legend>{`Client Name: ${dataItem.relation_name}`}</legend>
                <div>
                  <AutoComplete
                    style={{ width: "450px" }}
                    data={this.state.billing_entities}
                    textField="relation_name"
                    value={this.state.valueBillingEntity}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    onClose={this.onBlur}
                    label={"Billing Entity"}
                    listNoDataRender={this.listNoDataRender}
                    // placeholder="Search client name"
                  />
                </div>
                <br />
                <br />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <button
                    style={{ justifyContent: "flex-end" }}
                    type="button"
                    className="k-button k-primary"
                    onClick={(e) => this.saveBillingEntityToClient(e)}
                    disabled={
                      this.state.valueBillingEntity !== "" ? false : true
                    }
                  >
                    Save
                  </button>
                </div>
              </fieldset>
              {this.state.windowError ? (
                <Error style={{ color: "red" }}>
                  <h3>{this.state.windowErrorMessage || ""}</h3>
                </Error>
              ) : null}
            </div>
          </Window>
        )}
      </div>
    );
  }
}

export default ClientToBillingEntityExpanded;
