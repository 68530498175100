export const schema = () => [
  {
    field: "id",
    title: "ID",
    visible: true,
    minWidth: 150,
    sortable: true,
    filterable: true,
    locked: "locked",
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
    export: false,
  },
  {
    field: "agentID",
    title: "Agent ID",
    visible: true,
    minWidth: 170,
    sortable: true,
    filterable: true,
    locked: "locked",
    export: true,
  },
  {
    field: "agentNane",
    title: "Agent",
    visible: true,
    minWidth: 300,
    sortable: true,
    filterable: true,
    locked: "locked",
    export: true,
  },
];
