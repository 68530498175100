import React from "react";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Window } from "@progress/kendo-react-dialogs";
import customSelectStyles from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Loader } from "../View/loader";
import { AbstractEdit } from "../../../networking/apiCalls";
import _ from "lodash";
import { MyCommandCell } from "../../common/commandCellHOC";
import { serverApi } from "../../../networking/config";
import { IconButton, Tooltip } from "@material-ui/core";
import { filterBy } from "@progress/kendo-data-query";
import GridContainer from "../../../components/Grid/GridContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import Snackbar from "../../../components/Snackbar/Snackbar";
import { columnsSchema, similaritySchema } from "./schema";
import { DropDownCell } from "../../CRM/View/dropDownCell";
import ExpandedTabs from "../../common/ExpandedTabs";
import ViewSettings from "../../common/ViewSettings";
import {
  showCreatorOnTable,
  removeUnwantedKeyValuesFromArr,
} from "../../../lib/GeneralUtils/utils";
import DropdownFilterCell from "../../common/dropdownFilterCell";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import WarehouseToAirport from "./components/WarehouseToAirport";
import WarehouseToAgent from "./components/WarehouseToAgent";

class Warehouses extends React.Component {
  editField = "inEdit";
  CommandCell;

  constructor(props) {
    super(props);

    this.state = {
      similarityData: [],
      similarityColumns: (similaritySchema && similaritySchema()) || [],
      visibleWindow: false,
      isDropOpened: false,
      columns: columnsSchema && columnsSchema(),
      initialColumns: (columnsSchema && columnsSchema()) || [],
      gridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 100,
        skip: 0,
      },
      openErrorModal: false,
      errorModalContent: "",
      visible: false,
      expandedRowIndex: null,
      selectedRowIndex: 0,
      selectedItem: null,
      delay: 400,
      loading: false,
      open: false,
      emailHasError: false,
      username: "",
      userRealName: "",
      email: "",
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      hasDisable: true,
      showSecondAction: false,
    });
  }

  componentDidMount() {
    let isFirstLoad = true;
    window.localStorage.setItem("isFirstLoad", isFirstLoad);

    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));

    if (hasFinalParams) {
      this.setState({
        ...this.state,
        dataState: hasFinalParams,
      });
    }

    if (hasChangedColumns) {
      this.setState({
        ...this.state,
        columns: hasChangedColumns,
      });
    }
  }

  componentWillUnmount() {
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));
    let finalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    let hasExpandedId = JSON.parse(window.localStorage.getItem("expandeId"));
    let hasExportFilter = window.localStorage.getItem("exportFilter");

    if (hasChangedColumns) {
      window.localStorage.removeItem("cols");
    }
    if (finalParams) {
      window.localStorage.removeItem("finalParams");
    }
    if (hasExpandedId) {
      window.localStorage.removeItem("expandeId");
    }
    if (hasExportFilter) {
      window.localStorage.removeItem("exportFilter");
    }
  }

  renderGridColumns = () => {
    let selectionCol = [];
    let normalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            width="65px"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((x) => x.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.field === "agent_type") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              filterCell={(props) => {
                return (
                  <>
                    <DropdownFilterCell
                      {...props}
                      filterable={false}
                      width="120px"
                      data={[
                        { text: "Domestic", value: "D" },
                        { text: "International", value: "I" },
                      ]
                        .map((x) => x.text)
                        .sort((a, b) => (a > b ? 1 : -1))}
                    />
                  </>
                );
              }}
              editable={
                temp.field === "id" ||
                temp.field === "createdByForUi" ||
                temp.field === "dateCreatedForUi" ||
                temp.field === "ap_account" ||
                temp.field === "ar_account"
                  ? false
                  : true
              } //id not editable on edit
              cell={(props) => {
                return (
                  <>
                    <DropDownCell
                      {...props}
                      filterable={false}
                      width="150px"
                      data={[
                        { text: "Domestic", value: "D" },
                        { text: "International", value: "I" },
                      ].sort((a, b) => (a > b ? 1 : -1))}
                    />
                  </>
                );
              }}
            />
          );
        } else if (temp.field === "relation_country") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              filterCell={(props) => {
                return (
                  <>
                    <DropdownFilterCell
                      {...props}
                      filterable={true}
                      width="140px"
                      data={(this.props.countryCodes || [])
                        .map((x) => x.alpha_2)
                        .sort((a, b) => (a > b ? 1 : -1))}
                    />
                  </>
                );
              }}
              cell={(props) => {
                let data = (this.props.countryCodes || [])
                  .map((x) => ({
                    text: x.alpha_2,
                    value: x.alpha_2,
                  }))
                  .sort((a, b) => (a.text > b.text ? 1 : -1));
                return (
                  <>
                    <DropDownCell
                      {...props}
                      filterable={true}
                      width="150px"
                      data={data}
                    />
                  </>
                );
              }}
            />
          );
        } else if (temp.field === "state") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              filterCell={(props) => {
                return (
                  <>
                    <DropdownFilterCell
                      {...props}
                      width="120px"
                      data={(this.props.usaStates || [])
                        .map((x) => x.lov_value_1)
                        .filter((x) => x)
                        .sort((a, b) => (a > b ? 1 : -1))}
                    />
                  </>
                );
              }}
              cell={(props) => {
                let stateData = (this.props.usaStates || [])
                  .map((x) => ({
                    text: x.lov_value_1,
                    value: x.lov_value_1,
                  }))
                  .filter((x) => x)
                  .sort((a, b) => (a.text > b.text ? 1 : -1))
                  .filter((obj) => obj.text !== null && obj.value !== null);
                return (
                  <>
                    <DropDownCell {...props} width="100px" data={stateData} />
                  </>
                );
              }}
            />
          );
        } else if (temp.isDropdown === "yes") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={
                temp.field === "id" ||
                temp.field === "createdByForUi" ||
                temp.field === "dateCreatedForUi" ||
                temp.field === "ap_account" ||
                temp.field === "ar_account"
                  ? false
                  : true
              } //id not editable on edit
            />
          );
        } else {
          return (
            <Column
              sortable={temp.sortable}
              locked={temp.locked}
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={temp.field === "id" ? false : true}
            />
          );
        }
      });

    return [...selectionCol, ...normalCols];
  };

  renderGridColumnsForSimilarity = () => {
    let selectionCol = [];
    let normalCols = [];

    _.forEach(this.state.similarityColumns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            width="65px"
            filterable={false}
            headerSelectionValue={
              this.state.finalGoogleData.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.similarityColumns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }
        return (
          <Column
            sortable={temp.sortable}
            locked={temp.locked}
            field={temp.field}
            filterable={temp.filterable}
            title={temp.title}
            width={temp.minWidth || "auto"}
            filter={temp.filter}
            visible={temp.visible}
            minGridWidth={"400"}
            editable={
              temp.field === "id" ||
              temp.field === "ar_account" ||
              temp.field === "ap_account" ||
              temp.editable
                ? false
                : true
            }
          />
        );
      });

    return [...selectionCol, ...normalCols];
  };

  filterChange = (event) => {
    let filteredResults = this.filterData(event.filter);

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.setState({
        countries:
          filteredResults !== undefined
            ? filteredResults
            : this.state.initialCountries,
        loading: false,
      });
    }, this.state.delay);

    this.setState({
      loading: true,
    });
  };

  filterData(filter) {
    if (filter.value === "") {
      this.setState({
        countries: this.state.initialCountries,
      });

      return;
    } else {
      const data = this.state.initialCountries;
      filter.operator = "startswith";
      let filteredResults = filterBy(data, filter);

      return filteredResults;
    }
  }

  toggleWindow = (e) => {
    this.setState({
      visibleWindow: !this.state.visibleWindow,
    });
  };

  toggleDialog = () => {
    const data = this.state.gridData.data;
    let finalData = [];
    finalData = data.slice(1);

    this.setState({
      visible: !this.state.visible,
      gridData: {
        data: [...finalData],
        total: 10,
      },
      finalGoogleData: [],
    });
  };

  enterEdit = (dataItem) => {
    this.applyNewColumnsOnGrid(dataItem);

    let total = this.state.gridData.total;

    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    this.setState({
      gridData: {
        data:
          this.state.gridData &&
          this.state.gridData.data.map((item) =>
            item.id === dataItem.id ? { ...item, inEdit: true } : item
          ),
        total: total,
      },
      initialGridData: {
        data:
          this.state.initialGridData &&
          this.state.initialGridData.data.map((item) =>
            item.id === dataItem.id ? { ...item, inEdit: true } : item
          ),
        total: total,
      },
    });
  };

  exportToCsv = async (state, props) => {
    const count = state.gridData.total;
    const where = window.localStorage.getItem("exportFilter") || {};
    const numberOfCalls = Math.min(
      Math.round(count / 1000) + 1,
      5 // Limit to 5000
    );

    const promises = [];
    for (let i = 0; i < numberOfCalls; i++) {
      promises.push(
        this.getPromises({
          skip: i * 1000,
          limit: 1000,
          where: !_.isEmpty(where) ? JSON.parse(where) : {},
          order: ["id asc"],
        })
      );
    }

    try {
      const responses = await Promise.all(promises);
      let allData = [];
      responses.forEach((resp) => {
        allData = [...allData, ...resp.data];
      });
      if (allData.length > 0) {
        this.setState({
          ...this.state,
          exportData: allData,
        });
        // this._export.save(allData, this.state.columns);
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent:
          (e.response.data && e.response.data.error.message) ||
          "Please try again",
      });
    }
  };

  getPromises = (filter) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    return new Promise((resolve, reject) => {
      const masterEntityRequest = serverApi(
        "GET",
        `masterEntities?filter=${JSON.stringify(filter)}`,
        "",
        "",
        options
      );
      masterEntityRequest
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getExportData = async () => {
    await this.exportToCsv(this.state, this.props);
  };

  remove = async (dataItem) => {};

  toggleSimilarityWindow = () => {
    this.setState({
      similarityWindow: !this.state.similarityWindow,
    });
  };

  applyNewColumnsOnGrid = (dataItem) => {
    let columns = this.state.initialColumns;
    let newColumns = columns.filter(
      (x) => x.field !== "id" && x.field !== "approval_status"
    );
    newColumns.forEach((x) => (x.minWidth = 290));
    if (dataItem && dataItem.relation_country !== "US") {
      newColumns = newColumns.filter((col) => col.field !== "state");
    }

    this.setState(
      {
        ...this.state,
        hideColumns: true,
        columns: newColumns,
        initialColumns: columns,
      },
      () => {}
    );
  };

  add = async (dataItem) => {
    const options = { token: window.localStorage.getItem("access_token") };
    const submitURL = "masterEntities";

    let payload = dataItem;
    payload = this.state.gridData.data[0];
    payload.client_flag = false;
    payload.supplier_flag = false;
    payload.transport_flag = false;
    payload.agent_flag = false;
    payload.location_flag = false;
    payload.sm_agent_flag = false;
    payload.sm_vendor_flag = false;
    payload.airport_flag = false;
    payload.can_create_bol_flag = false;
    payload.can_create_coo_flag = false;
    payload.seaport_flag = false;
    payload.verified_flag = false;
    payload.wh_flag = true;

    const hasSimilarity = await this.checkForSimilar(payload);
    if (!hasSimilarity) {
      dataItem.inEdit = undefined;
      delete payload.inEdit;

      if (payload.relation_country === "US" && payload.state === "") {
        this.setState(
          {
            ...this.state,
            openErrorModal: true,
            errorModalContent:
              "You need to provide the state field in order to create a warehouse",
          },
          () => {
            this.cancel(dataItem);
            this.refreshGridDataAfterCrud();
            return null;
          }
        );
        return null;
      } else if (
        payload.relation_name === undefined ||
        payload.relation_postal_code === undefined ||
        payload.relation_country === undefined
      ) {
        this.setState(
          {
            ...this.state,
            openErrorModal: true,
            errorModalContent:
              "Please provide all the required data in order to add a new warehouse",
          },
          () => {
            setTimeout(() => {
              this.cancel(dataItem);
              this.refreshGridDataAfterCrud();
              this.setState({
                ...this.state,
                openErrorModal: false,
                errorModalContent: "",
              });
            }, 4000);
            return null;
          }
        );
      } else {
        delete payload.inEdit;
        const createRequest = serverApi(
          "POST",
          `${submitURL}`,
          "",
          payload,
          options
        );
        createRequest
          .then(async (createResponse) => {
            this.setState(
              {
                ...this.state,
                inAdd: false,
                successMessage: "The warehouse was successfully created.",
                openSuccessMessage: true,
              },
              () => {
                setTimeout(() => {
                  this.handleSuccessSnackbar();
                  this.refreshGridDataAfterCrud();
                }, 4000);
              }
            );
          })
          .catch((createError) => {
            this.setState(
              {
                ...this.state,
                openErrorModal: true,
                errorModalContent:
                  (createError.response.data &&
                    createError.response.data.error.message) ||
                  "Please refresh",
              },
              () => this.discard(dataItem, true)
            );
          });
      }
    } else {
      this.toggleSimilarityWindow();
    }
  };

  discard = (dataItem, hasError = false) => {
    let data = [...this.state.gridData.data];
    const total = this.state.gridData.total;

    if (hasError) {
      let index = data.findIndex((p) => p.id === dataItem.id);

      data[index].inEdit = false;

      this.setState(
        {
          ...this.state,
          gridData: {
            data: data,
            total: total,
          },
        },
        () => {
          setTimeout(() => {
            this.setState({
              ...this.state,
              openErrorModal: false,
              errorModalContent: "",
            });
          }, 4000);
        }
      );
    } else {
      this.removeItem(data, dataItem);

      this.setState({
        ...this.state,
        gridData: {
          data: data,
          total: total,
        },
        visible: false,
      });
    }
  };

  update = async (dataItem) => {
    const data = [...this.state.gridData.data];
    const updatedItem = { ...dataItem, inEdit: undefined };

    this.updateItem(data, updatedItem);

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let editPayload = updatedItem; //todo check again if payload is ok
    delete editPayload.contacts;
    delete editPayload.snUsers;
    delete editPayload.ar_account;
    delete editPayload.ap_account;

    if (editPayload.expanded) delete editPayload.expanded;

    editPayload.supplier_flag = dataItem.supplier_flag || false;
    editPayload.verified_flag = dataItem.verified_flag || false;
    editPayload.transport_flag = dataItem.transport_flag || false;
    editPayload.agent_flag = dataItem.agent_falg || false;
    editPayload.sm_agent_flag = dataItem.sm_agent_flag || false;
    editPayload.sm_vendor_flag = dataItem.sm_vendor_flag || false;
    editPayload.airport_flag = dataItem.airport_flag || false;
    editPayload.seaport_flag = dataItem.seaport_flag || false;

    if (editPayload.relation_country !== "US") editPayload.state = null;

    const error2 = editPayload.relation_country === "US" && !editPayload.state;

    const error1 =
      editPayload.relation_name === undefined ||
      !editPayload.relation_name ||
      editPayload.relation_postal_code === undefined ||
      !editPayload.relation_postal_code ||
      editPayload.relation_country === undefined ||
      !editPayload.relation_postal_code;

    if (error2 || error1) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent: error1
            ? "Please provide Name,Postal and Country attributes in order to edit a warehouse"
            : "You need to provide the state field in order to create a warehouse",
        },
        () => {
          setTimeout(() => {
            this.cancel(dataItem);
            this.discard(dataItem, true);
            this.handleErrorClose();
          }, 4000);
        }
      );
    } else {
      try {
        removeUnwantedKeyValuesFromArr(editPayload);

        const editRequest = await AbstractEdit(
          dataItem.id,
          editPayload,
          "masterEntities",
          options
        );
        // const countMasterEntity = await serverApi('GET', 'masterEntities/count', '', '', options)

        if (editRequest.status === 200) {
          this.setState(
            {
              ...this.state,
              gridData: {
                data: data,
                total: this.state.gridData.total,
              },
              successMessage: "Warehouse was successfully updated",
              openSuccessMessage: true,
            },
            () => {
              setTimeout(() => {
                this.handleSuccessSnackbar();
                this.refreshGridDataAfterCrud();
              }, 4000);
            }
          );
        }
      } catch (e) {
        this.setState(
          {
            ...this.state,
            openErrorModal: true,
            errorModalContent:
              e?.response?.data?.error?.message || "Please try again",
          },
          () => this.discard(dataItem, true)
        );
      }
    }
  };

  cancel = (dataItem = {}) => {
    const originalItem =
      this.state.initialGridData &&
      this.state.initialGridData.data.find((p) => p.id === dataItem.id);

    if (originalItem) {
      originalItem.inEdit = false;

      const data =
        this.state.initialGridData &&
        this.state.initialGridData.data.map((item) =>
          item.id === originalItem.id ? originalItem : item
        );

      const total = this.state.initialGridData.total;

      this.setState({
        ...this.state,
        gridData: {
          data: data,
          total: total,
        },
      });
    }
  };

  updateItem = (data, item) => {
    let index = data.findIndex(
      (p) => p === item || (item.id && p.id === item.id)
    );
    if (index >= 0) {
      //todo check again
      delete item.inEdit;
      data[index] = { ...item };
    }
  };

  applyInitialColumnsOnGrid = () => {
    let initialColumns = this.state.initialColumns;
    let newColumns = initialColumns;
    this.setState({
      ...this.state,
      hideColumns: false,
      columns: newColumns,
    });
  };

  itemChange = (event) => {
    const { field, value } = event;

    if (field === "relation_country" && value === "US") {
      this.applyInitialColumnsOnGrid();
    } else if (field === "relation_country" && value !== "US") {
      this.setState({
        ...this.state,
        columns:
          (columnsSchema && columnsSchema()).filter(
            (col) => col.field !== "state"
          ) || [],
      });
    }

    const total = this.state.gridData.total;
    const data =
      this.state.gridData &&
      this.state.gridData.data.map((item) =>
        item.id === event.dataItem.id
          ? { ...item, [event.field]: event.value }
          : item
      );

    this.setState({
      gridData: {
        data: data,
        total: total,
      },
    });
  };

  addNew = () => {
    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    const newDataItem = { inEdit: true };

    this.setState({
      gridData: {
        data: [newDataItem, ...this.state.gridData.data],
        total: this.state.gridData.total,
      },
      visible: true,
    });
  };

  cancelCurrentChanges = () => {
    this.setState({
      ...this.state,
      gridData: this.state.initialGridData,
    });
  };

  removeItem(data, item) {
    let index = data.findIndex(
      (p) => p === item || (item.id && p.id === item.id)
    );
    if (index >= 0) {
      data.splice(index, 1);
    }
  }

  dataStateChange = (e) => {
    const reqGridParams = e.data;
    window.localStorage.setItem("finalParams", JSON.stringify(reqGridParams));

    this.setState({
      ...this.state,
      dataState: reqGridParams,
    });
  };

  dataRecieved = (gridData, reqGridParams) => {
    const finalData = showCreatorOnTable(gridData);

    this.setState({
      ...this.state,
      gridData: finalData,
      initialGridData: finalData,
      dataState:
        reqGridParams === !_.isEmpty(reqGridParams)
          ? reqGridParams
          : this.state.dataState, //TODO MAYBE PASS THE final params from local storage for persist the filters on REFRESH PAGE !
    });
  };

  onClearFilters = () => {
    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));

    let init = JSON.stringify({ take: 100, skip: 0 });
    if (hasFinalParams && hasFinalParams.filter)
      window.localStorage.setItem("finalParams", init);

    this.dataStateChange({ data: { take: 100, skip: 0 } });
  };

  handleSubmit(event) {
    event.preventDefault();
  }

  handleErrorClose = (loginError) => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      openErrorModalContent: "",
    });
  };

  handleSuccessSnackbar = () => {
    this.setState({
      ...this.state,
      successMessage:
        this.state.openSuccessMessage === true ? this.state.successMessage : "",
      openSuccessMessage: !this.state.openSuccessMessage,
    });
  };

  expandChange = (event) => {
    window.localStorage.setItem("expandeId", event.dataItem.id);

    if (this.state.gridData.data[0].inEdit === true) {
      return;
    } else {
      event.dataItem.expanded = !event.dataItem.expanded;
    }

    this.forceUpdate();
  };

  refreshGridDataAfterCrud = async () => {
    let token = window.localStorage.getItem("access_token");
    let options = {
      token: token,
    };
    let refreshParams = window.localStorage.getItem("refreshParams");
    refreshParams = JSON.parse(refreshParams);

    let responseData = await serverApi(
      "GET",
      `masterEntities`,
      refreshParams,
      "",
      options
    );
    const finalData = showCreatorOnTable(responseData);

    let count;
    const countMasterEntity = await serverApi(
      "GET",
      `masterEntities/count?where=${JSON.stringify(
        refreshParams.filter.where
      )}`,
      "",
      "",
      options
    );
    if (countMasterEntity) {
      count = countMasterEntity.data.count;
    }

    let sendObj = {
      gridData: {
        data: finalData.data,
        total: count,
      },
    };

    this.dataRecieved(sendObj.gridData, null);
  };

  handleColumnsChange = (event) => {
    let columns = this.state.columns;

    let oldColumns = columns.filter((c) => c.visible).map((c) => c.field);
    let newColumns = event.target.value;

    let unique1 = oldColumns.filter((c) => newColumns.indexOf(c) === -1);
    let unique2 = newColumns.filter((c) => oldColumns.indexOf(c) === -1);

    const columnLastUpdated = unique1.concat(unique2)[0];

    let index = columns.findIndex(
      (column) => column.field === columnLastUpdated
    );
    columns[index].visible = !columns[index].visible;

    this.setState(
      {
        ...this.state,
        columns: columns,
      },
      () => {
        let columns = this.state.columns;
        window.localStorage.setItem("cols", JSON.stringify(columns));
      }
    );
  };

  checkForSimilar = async (payload) => {
    try {
      const findCountryRecord = this.props.countryCodes.filter(
        (x) => x.alpha_2 === payload.relation_country
      );
      if (findCountryRecord.length) {
        const request = await serverApi(
          "GET",
          `masterEntities/findSimilarNames?fieldname=relation_name&name=${payload.relation_name}&country=${findCountryRecord[0].name}&flags=wh_flag`,
          "",
          "",
          {
            token: window.localStorage.getItem("access_token"),
          }
        );
        if (request.status === 200) {
          if (request.data.result.length) {
            //todo handle > 80% similatiry
            let data = request.data.result || [];
            let finalData = [];
            data.forEach((x) => {
              let similarity =
                x.SIMILARITY && parseInt(x.SIMILARITY.split("%")[0]);
              if (similarity > 80) finalData.push(x);
            });

            if (finalData.length > 0) {
              this.setState({
                ...this.state,
                similarityData: finalData,
              });
              return true;
            } else {
              return false;
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            "An error occured, please try again and if the contact persist contact the administration",
        },
        () => this.discard({}, true)
      );
    }
  };

  bypassCheck = () => {
    const submitURL = "masterEntities";
    const options = { token: window.localStorage.getItem("access_token") };

    const dataItem = this.state.gridData.data.filter((x) => x.inEdit)[0] || {};
    let payload = dataItem;
    delete payload.inEdit;
    const createRequest = serverApi(
      "POST",
      `${submitURL}`,
      "",
      payload,
      options
    );
    createRequest
      .then(async (createResponse) => {
        this.setState(
          {
            ...this.state,
            inAdd: false,
            similarityWindow: false,
            successMessage: "The warehouse was successfully created.",
            openSuccessMessage: true,
          },
          () => {
            setTimeout(() => {
              this.handleSuccessSnackbar();
              this.refreshGridDataAfterCrud();
            }, 4000);
          }
        );
      })
      .catch((createError) => {
        this.setState(
          {
            ...this.state,
            openErrorModal: true,
            errorModalContent:
              (createError.response.data &&
                createError.response.data.error.message) ||
              "Please refresh",
          },
          () => this.discard(dataItem, true)
        );
      });
  };

  render() {
    const { gridData, columns } = this.state;
    const hasEditedItem = gridData && gridData.data.some((p) => p.inEdit);

    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    let hasColumnFilters = true;
    if (found) {
      hasColumnFilters = false;
    }
    const { hasBackofficeRead } = this.props;

    return (
      <div>
        <Tooltip openDelay={100} position="right" anchorElement="target">
          <Grid
            {...this.state.dataState}
            {...this.state.gridData}
            filterable={hasColumnFilters}
            style={{ height: "92vh" }}
            sortable={true}
            resizable
            editField={this.editField}
            detail={(dataItem) => (
              <div>
                <ExpandedTabs
                  tabs={[
                    {
                      label: "Warehouse To Airport",
                      component: (
                        <WarehouseToAirport
                          {...dataItem}
                          hasBackofficeRead={hasBackofficeRead}
                        />
                      ),
                    },
                    {
                      label: "Warehouse To Agent",
                      component: (
                        <WarehouseToAgent
                          {...dataItem}
                          hasBackofficeRead={hasBackofficeRead}
                        />
                      ),
                    },
                  ]}
                />
              </div>
            )}
            // rowRender={this.rowRender}
            onItemChange={this.itemChange}
            onDataStateChange={this.dataStateChange}
            // rowRender={this.rowRender}
            expandField="expanded"
            onExpandChange={this.expandChange}
            pageable={{
              buttonCount: 5,
              info: true,
              pageSizes: [100, 200, 500, 1000, 1500],
            }}
          >
            <GridToolbar>
              <GridContainer xs={12} justify={"flex-start"} direction={"row"}>
                {!hasBackofficeRead && (
                  <Tooltip
                    justify={"flex-start"}
                    placement="top"
                    title={"Add Warehouse"}
                  >
                    <IconButton onClick={this.addNew}>
                      <FontAwesomeIcon
                        color="#0D5869"
                        onClick={this.addNew}
                        size="1.6x"
                        icon={faPlus}
                      />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip
                  justify={"flex-start"}
                  placement="top"
                  title={"Clear Filters"}
                >
                  <IconButton
                    disabled={false}
                    aria-label=""
                    onClick={(e) => this.onClearFilters(e)}
                    look="flat"
                  >
                    <FontAwesomeIcon
                      color="#0D5869"
                      size="1.6x"
                      icon={faEraser}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  justify={"flex-start"}
                  placement="top"
                  title={"Refresh Data"}
                >
                  <IconButton
                    disabled={false}
                    aria-label=""
                    onClick={this.refreshGridDataAfterCrud}
                  >
                    <FontAwesomeIcon
                      color="#0D5869"
                      size="1x"
                      icon={faSyncAlt}
                    />
                  </IconButton>
                </Tooltip>
                <ViewSettings
                  {...this.props}
                  export={{
                    data: this.state.exportData || [],
                    exportFunction: this.getExportData,
                    fileName: "Warehouses_",
                    columns: columnsSchema(),
                  }}
                  viewSettings={{
                    type: "",
                  }}
                  refreshSettings={{
                    data: [],
                  }}
                />
                {!this.state.hideColumns ? (
                  <div style={{ marginTop: hasBackofficeRead ? "-12px" : "" }}>
                    <FormControl
                      className={customSelectStyles.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="multiple-select"
                        className={customSelectStyles.selectLabel}
                      />
                      <Select
                        autoWidth={true}
                        multiple
                        value={columns
                          .filter((column) => column.visible)
                          .map((column) => column.field)}
                        onChange={this.handleColumnsChange}
                        MenuProps={{ className: customSelectStyles.selectMenu }}
                        inputProps={{
                          name: "multipleSelect",
                          id: "multiple-select",
                        }}
                        renderValue={(selected) => "Columns"}
                      >
                        {columns
                          .filter((col) => !col.notShownInTableColumnSelection)
                          .map((column) => {
                            let name = column.title;
                            let value = column.field;
                            let isVisible = column.visible;
                            let isDisabled = column.noDisabledColumn;

                            return (
                              <MenuItem
                                style={{ backgroundColor: "white" }}
                                key={name}
                                value={value}
                                disabled={isDisabled}
                              >
                                <Checkbox
                                  color={"default"}
                                  checked={isVisible}
                                />
                                <ListItemText
                                  style={{ marginLeft: "7px" }}
                                  primary={name}
                                />
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </div>
                ) : null}
              </GridContainer>
            </GridToolbar>

            {!hasBackofficeRead && (
              <Column
                cell={this.CommandCell}
                title="Actions"
                filterable={false}
                width={hasEditedItem ? "240px" : "140px"}
              />
            )}

            {this.renderGridColumns()}
          </Grid>
        </Tooltip>

        <Loader
          dataState={this.state.dataState}
          onDataRecieved={this.dataRecieved}
          getURL={"masterEntities"}
          include={["creator"]}
          countURL={'masterEntities/count?where={"wh_flag":true}'}
          view={"warehouse"}
        />

        <Snackbar
          place="tc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"warning"}
          message={this.state.errorModalContent}
          open={this.state.openErrorModal}
        />

        <Snackbar
          place="tc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"success"}
          message={this.state.successMessage}
          open={this.state.openSuccessMessage}
        />

        {this.state.similarityWindow && (
          <Window
            title={"Existing warehouses in navigator"}
            onClose={this.toggleSimilarityWindow}
            width={1420}
            height={700}
            style={{ marginLeft: "-100px" }}
          >
            <span>
              <fieldset style={{ borderColor: "rgba(13, 88, 105, 1)" }}>
                <div style={{ display: "contents", flexDirection: "row" }}>
                  <legend>
                    These existing warehouses have similar names.Is this the
                    warehouse you want one of them?
                  </legend>
                  <button
                    type="button"
                    className="k-button k-primary"
                    onClick={(e) => {
                      this.bypassCheck();
                    }}
                  >
                    Bypass check and save
                  </button>
                </div>
                <div>
                  <Grid
                    data={this.state.similarityData}
                    heigh={"100%"}
                    resizable
                    filterable={false}
                    sortable={false}
                  >
                    {this.renderGridColumnsForSimilarity()}
                  </Grid>
                </div>
              </fieldset>
            </span>
          </Window>
        )}
      </div>
    );
  }
}

export default Warehouses;
