import React, { Component } from "react";
import { columnsSchema } from "./schema";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { Loader } from "./loader";
import { IconButton, Tooltip } from "@material-ui/core";
import { MyCommandCell } from "../../CRM/View/myCommandCell";
import { DropDownCell } from "../../CRM/View/dropDownCell.js";
import { serverApi } from "../../../networking/config";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ViewSettings from "../../common/ViewSettings";
import customSelectStyles from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import { Checkbox } from "@progress/kendo-react-inputs";
import Snackbar from "../../../components/Snackbar/Snackbar";
import GridContainer from "../../../components/Grid/GridContainer";
import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { Error } from "@progress/kendo-react-labels";
import { Window } from "@progress/kendo-react-dialogs";
import { filterBy } from "@progress/kendo-data-query";
import ExpandedTabs from "../../common/ExpandedTabs";
import VesselsToFleet from "./components/vesselsToFleet/index";
import { faEraser, faSyncAlt, faPlus } from "@fortawesome/free-solid-svg-icons";

class Fleets extends Component {
  editField = "inEdit";
  CommandCell;

  constructor(props) {
    super(props);

    this.state = {
      visibleWindow: false,
      isDropOpened: false,
      client_flag: [],
      columns: (columnsSchema && columnsSchema()) || [],
      gridData: {
        data: [],
        total: 0,
      },
      initialGridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 100,
        skip: 0,
      },
      openErrorModal: false,
      errorModalContent: "",
      delay: 400,
      loading: false,
      selectedRowIndex: 0,
      selectedItem: null,
      googleOptions: [],
      expandedRowIndex: null,
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: () => {},
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      hasDisable: false,
      showSecondAction: false,
    });
  }

  componentDidMount() {
    let isFirstLoad = true;
    window.localStorage.setItem("isFirstLoad", isFirstLoad);

    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));

    if (hasFinalParams) {
      this.setState({
        ...this.state,
        dataState: hasFinalParams,
      });
    }

    if (hasChangedColumns) {
      this.setState({
        ...this.state,
        columns: hasChangedColumns,
      });
    }
  }

  componentWillUnmount() {
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));
    let finalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    let hasExpandedId = JSON.parse(window.localStorage.getItem("expandeId"));
    let hasExportFilter = window.localStorage.getItem("exportFilter");

    if (hasChangedColumns) {
      window.localStorage.removeItem("cols");
    }
    if (finalParams) {
      window.localStorage.removeItem("finalParams");
    }
    if (hasExpandedId) {
      window.localStorage.removeItem("expandeId");
    }
    if (hasExportFilter) {
      window.localStorage.removeItem("exportFilter");
    }
  }

  dataStateChange = (e) => {
    const reqGridParams = e.data;
    window.localStorage.setItem("finalParams", JSON.stringify(reqGridParams));

    this.setState({
      ...this.state,
      dataState: reqGridParams,
    });
  };

  dataReceived = (gridData, reqGridParams) => {
    this.setState({
      ...this.state,
      gridData: gridData,
      initialGridData: gridData,
      dataState:
        reqGridParams === !_.isEmpty(reqGridParams)
          ? reqGridParams
          : this.state.dataState, //TODO MAYBE PASS THE final params from local storage for persist the filters on REFRESH PAGE !
    });
  };

  handleSuccessSnackbar = () => {
    this.setState({
      ...this.state,
      successMessage:
        this.state.openSuccessMessage === true ? this.state.successMessage : "",
      openSuccessMessage: !this.state.openSuccessMessage,
    });
  };

  remove = async (dataItem) => {};

  cancel = (dataItem = {}) => {
    if (dataItem.description === undefined) {
      const data =
        this.state.initialGridData && this.state.initialGridData.data;
      const total = this.state.initialGridData.total;

      this.setState(
        {
          ...this.state,
          onEditItem: {},
          gridData: {
            data: data,
            total: total,
          },
          errorModalContent:
            this.state.errorModalContent === ""
              ? ""
              : this.state.errorModalContent,
          openErrorModal:
            this.state.openErrorModal !== true
              ? false
              : this.state.errorModalContent,
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 500);
        }
      );
    } else {
      const originalItem =
        this.state.initialGridData &&
        this.state.initialGridData.data.find((p) => p.id === dataItem.id);
      originalItem.inEdit = false;

      const data =
        this.state.initialGridData &&
        this.state.initialGridData.data.map((item) =>
          item.id === originalItem.id ? originalItem : item
        );

      const total = this.state.initialGridData.total;

      this.setState({
        ...this.state,
        onEditItem: {},
        gridData: {
          data: data,
          total: total,
        },
      });
    }
  };

  removeItem(data, item) {
    let index = data.findIndex((p) => p === item || p.id === item.id);
    if (index >= 0) {
      data.splice(index, 1);
    }
  }

  handleErrorClose = (loginError) => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      openErrorModalContent: "",
    });
  };

  discard = (dataItem, hasError = false) => {
    let data = [...this.state.gridData.data];
    const total = this.state.gridData.total;

    if (hasError) {
      data[0].inEdit = true;

      this.setState(
        {
          ...this.state,
          gridData: {
            data: data,
            total: total,
          },
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 3000);
        }
      );
    } else {
      this.removeItem(data, dataItem);

      this.setState(
        {
          ...this.state,
          onEditItem: {},
          gridData: {
            data: data,
            total: total,
          },
          visible: false,
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 3000);
        }
      );
    }
  };

  update = async (dataItem) => {};

  fetchClientName = async (dataItem) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let filter = {
      order: ["relation_name asc"],
      where: { id: dataItem.clientID },
    };

    const client = await serverApi(
      "GET",
      `masterEntities`,
      { filter: filter },
      "",
      options
    );
    if (client?.data) {
      this.setState({
        ...this.state,
        valueClientName: client?.data[0]?.relation_name || "",
        postClientID: client?.data[0]?.id || null,
      });
    }
  };

  enterEdit = async (dataItem) => {
    await this.fetchClientName(dataItem);

    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    let total = this.state.gridData.total;
    this.setState({
      ...this.state,
      visibleWindow: true,
      onEditItem: dataItem,
      gridData: {
        data:
          this.state.gridData &&
          this.state.gridData.data.map((item) =>
            item.id === dataItem.id ? { ...item } : item
          ),
        total: total,
      },
    });
  };

  renderGridColumns = () => {
    let selectionCol = [];
    let normalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.isFilterBoolean === "yes") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              cell={DropDownCell}
            />
          );
        } else {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={temp.field === "id" ? false : true} //id not editable on edit
            />
          );
        }
      });

    return [...selectionCol, ...normalCols];
  };

  handleColumnsChange = (event) => {
    let columns = this.state.columns;

    let oldColumns = columns.filter((c) => c.visible).map((c) => c.field);
    let newColumns = event.target.value;

    let unique1 = oldColumns.filter((c) => newColumns.indexOf(c) === -1);
    let unique2 = newColumns.filter((c) => oldColumns.indexOf(c) === -1);

    const columnLastUpdated = unique1.concat(unique2)[0];

    let index = columns.findIndex(
      (column) => column.field === columnLastUpdated
    );
    columns[index].visible = !columns[index].visible;

    this.setState(
      {
        ...this.state,
        columns: columns,
      },
      () => {
        let columns = this.state.columns;
        window.localStorage.setItem("cols", JSON.stringify(columns));
      }
    );
  };

  getExportData = async () => {
    await this.exportToCsv(this.state, this.props);
  };

  exportToCsv = async (state, props) => {
    const count = state.gridData.total;
    const where = window.localStorage.getItem("exportFilter") || {};
    const numberOfCalls = Math.min(
      Math.round(count / 1000) + 1,
      5 // Limit to 5000
    );
    let finalSort = this.state.dataState?.sort?.length
      ? this.state.dataState.sort[0].field +
        " " +
        this.state.dataState.sort[0].dir
      : "id asc";

    const promises = [];
    for (let i = 0; i < numberOfCalls; i++) {
      promises.push(
        this.getDataPromise({
          skip: i * 1000,
          limit: 1000,
          where: !_.isEmpty(where) ? JSON.parse(where) : {},
          order: [finalSort],
          include: ["masterEntity"],
        })
      );
    }

    try {
      const responses = await Promise.all(promises);
      let allData = [];
      responses.forEach((resp) => {
        allData = [...allData, ...resp.data];
      });
      if (allData.length > 0) {
        this.setState({
          ...this.state,
          exportData: allData,
        });
        // this._export.save(allData, this.state.columns);
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent:
          e?.response?.data?.error?.message || "Please try again",
      });
    }
  };

  getDataPromise = (filter) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    return new Promise((resolve, reject) => {
      const data = serverApi(
        "GET",
        `fleets?filter=${JSON.stringify(filter)}`,
        "",
        "",
        options
      );
      data
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  onClearFilters = () => {
    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));

    let init = JSON.stringify({ take: 100, skip: 0 });
    if (hasFinalParams && hasFinalParams.filter)
      window.localStorage.setItem("finalParams", init);

    this.dataStateChange({ data: { take: 100, skip: 0 } });
  };

  toggleWindow = async (e) => {
    this.setState({
      ...this.state,
      visibleWindow: !this.state.visibleWindow,
      valueClientName: "",
      onEditItem: {},
      postClientID: null,
      fleet_name: "",
    });
  };

  addNew = () => {
    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    const newDataItem = { inEdit: true };

    this.setState({
      gridData: {
        data: [newDataItem, ...this.state.gridData.data],
        total: this.state.gridData.total,
      },
      visible: true,
    });
  };

  onDialogInputChange = (event) => {
    let checkForEditDato = this.state.onEditItem || {};

    let target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.props ? target.props.name : target.name;

    if (_.isEmpty(checkForEditDato)) {
      this.setState({
        ...this.state,
        [name]: value,
      });
    } else {
      this.setState({
        ...this.state,
        onEditItem: {
          ...this.state.onEditItem,
          [name]: value,
        },
      });
    }
  };

  fetchAutocompleteData = (filterValue) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let filter = {
      order: ["relation_name asc"],
      where: {
        and: [
          {
            client_flag: true,
          },
          { relation_name: { regexp: `/^${filterValue}/i` } },
        ],
      },
    };

    const fetchDropdownData = serverApi(
      "GET",
      `masterEntities`,
      { filter: filter },
      "",
      options
    );

    fetchDropdownData
      .then((response) => {
        this.setState({
          ...this.state,
          client_flag: response?.data?.sort((a, b) =>
            a.relation_name > b.relation_name ? 1 : -1
          ),
        });
      })
      .catch((error) => {});
  };

  filterData = (value, name) => {
    const data = this.state.client_flag.map((x) => x.relation_name);

    const filter = {
      value: value,
      operator: "startswith",
      ignoreCase: true,
    };

    let results = filterBy(data, filter);
    return results;
  };

  onAutoCompleteChange = async (event, value, field) => {
    const { name } = event.target;
    let stateData = {};

    switch (field) {
      case "client_flag": {
        stateData =
          value.length < 3
            ? {
                data:
                  this.state.client_flag.length > 0
                    ? this.state.client_flag.map((temp) => temp.relation_name)
                    : [],
                openedClientNames: false,
              }
            : { data: this.filterData(value, name), openedClientNames: true };

        let eventType = event.nativeEvent.type;

        let valueSelected =
          eventType === "click" ||
          (eventType === "keydown" && event.nativeEvent.keyCode === 13);

        if (valueSelected) {
          let postClientId = null;
          postClientId =
            this.state.client_flag.length === 1
              ? this.state.client_flag[0].id
              : (this.state.client_flag || []).filter(
                  (temp) => temp.relation_name === value
                )[0]?.id;

          this.setState({
            ...this.state,
            postClientID: postClientId || "",
          });
        }

        if (valueSelected && stateData.data.includes(value)) {
          stateData.openedClientNames = false;
        }

        this.setState(
          {
            valueClientName: value,
            ...stateData,
          },
          () => this.fetchAutocompleteData(value, field)
        );

        break;
      }

      default:
        break;
    }
  };

  saveHandler = async () => {
    let checkForEditDato = this.state.onEditItem || {};
    const editingMode = !_.isEmpty(checkForEditDato) ? true : false;

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    //create mode
    if (!editingMode) {
      try {
        let payload = {
          clientID: this.state.postClientID,
          fleet_name: this.state.fleet_name,
        };
        //check if payload empty
        await serverApi("POST", "fleets", {}, payload, options);

        this.setState(
          {
            ...this.state,
            client_flag: [],
            data: [],
            fleet_name: "",
            postClientID: "",
            valueClientName: "",
            openedClientNames: false,
            successMessage: "A new fleet was successfully created.",
            openSuccessMessage: true,
            visibleWindow: false,
          },
          () => {
            this.refreshGridDataAfterCrud();
            setTimeout(() => {
              this.setState({
                ...this.state,
                successMessage: "",
                openSuccessMessage: false,
              });
            }, 5000);
          }
        );
      } catch (error) {
        this.setState(
          {
            ...this.state,
            openErrorModal: true,
            errorModalContent:
              error?.response?.data?.error?.message ||
              "Create a new fleet process has failed.",
          },
          () => {
            setTimeout(() => {
              this.setState({
                ...this.state,
                openErrorModal: false,
                errorModalContent: "",
              });
            }, 5000);
          }
        );
      }
    }
    //edit mode
    else {
      let finalClientId =
        this.state.postClientID === this.state.onEditItem.clientID
          ? this.state.onEditItem.clientID
          : this.state.postClientID;

      //todo use : this.state.editingModeOnGroup
      let editPayload = {
        clientID: finalClientId || this.state.onEditItem.clientID,
        fleet_name: this.state.onEditItem.fleet_name,
      };

      //checks before update warehouse mapping
      let dropdownErrors = [];
      if (this.state.valueClientName !== "" && !this.state.postClientID)
        dropdownErrors.push("Client name");
      if (!_.isEmpty(dropdownErrors)) {
        this.setState(
          {
            ...this.state,
            openErrorModal: true,
            errorModalContent: `Wrong inputs: ${String(
              dropdownErrors
            )}. You should use dropdown values to continue.`,
          },
          () => {
            setTimeout(() => {
              this.handleErrorClose();
            }, 5000);
          }
        );
        return;
      }

      try {
        let update = await serverApi(
          "PATCH",
          `fleets/${this.state.onEditItem.id}`,
          {},
          editPayload,
          options
        );

        if (update) {
          this.setState(
            {
              ...this.state,
              onEditItem: {},
              visibleWindow: false,
              data: [],
              successMessage: "Fleet was successfully updated.",
              openSuccessMessage: true,
            },
            () => {
              this.refreshGridDataAfterCrud();
              setTimeout(() => {
                this.setState({
                  ...this.state,
                  successMessage: "",
                  openSuccessMessage: false,
                  valueClientName: "",
                  postClientID: null,
                });
              }, 4000);
            }
          );
        }
      } catch (error) {
        this.setState(
          {
            ...this.state,
            openErrorModal: true,
            errorModalContent:
              error?.response?.data?.error?.message ||
              "Fleet update process has failed.",
          },
          () => {
            setTimeout(() => {
              this.setState({
                ...this.state,
                openErrorModal: false,
                errorModalContent: "",
              });
            }, 5000);
          }
        );
      }
    }
  };

  onChange = (event, field) => {
    let editingMode = _.isEmpty(this.state.onEditItem) ? false : true;

    const { value } = event.target;

    switch (field) {
      case "client_flag": {
        if (value === "") {
          if (!editingMode) {
            this.setState({
              openedClientNames: false,
              valueClientName: value,
            });
          } else {
            let editItem = this.state.onEditItem;
            editItem.client_name = value;
            this.setState({
              openedClientNames: false,
              valueClientName: value,
            });
          }
        } else {
          if (!editingMode) {
            this.setState(
              {
                openedClientNames: false,
                valueClientName: value,
              },
              () => {
                this.onAutoCompleteChange(event, value, field);
              }
            );
          } else {
            let editItem = this.state.onEditItem;
            editItem.client_name = value;
            this.setState(
              {
                openedClientNames: false,
                ...this.state.editItem,
              },
              () => {
                this.onAutoCompleteChange(event, value, field);
              }
            );
          }
        }

        break;
      }

      default:
        break;
    }
  };

  refreshGridDataAfterCrud = async () => {
    let token = window.localStorage.getItem("access_token");
    let options = {
      token: token,
    };
    let refreshParams = window.localStorage.getItem("refreshParams");
    refreshParams = JSON.parse(refreshParams);

    const filter = {
      include: ["masterEntity"],
      where: { and: [{}] },
      limit: "1000",
      skip: "0",
    };

    let finalFilter = null;
    if (!_.isEmpty(refreshParams)) {
      finalFilter = refreshParams;
    } else {
      finalFilter = { filter };
    }

    const responseData = await serverApi(
      "GET",
      "fleets",
      finalFilter,
      "",
      options
    );

    let count;
    const countMasterEntity = await serverApi(
      "GET",
      `fleets/count?where=${JSON.stringify(refreshParams.filter.where)}`,
      "",
      "",
      options
    );
    if (countMasterEntity) {
      count = countMasterEntity.data.count;
    }

    let sendObj = {
      gridData: {
        data: responseData.data,
        total: count,
      },
    };

    this.dataReceived(sendObj.gridData, null);
  };

  listNoDataRender = (element, field) => {
    let showMsg = "";
    if (field === "client") showMsg = "No client found";
    const noData = (
      <h4 style={{ fontSize: "1em" }}>
        <span className="k-icon k-i-warning" style={{ fontSize: "2.5em" }} />
        <br />
        <br />
        {showMsg}
      </h4>
    );

    return React.cloneElement(element, { ...element.props }, noData);
  };

  expandChange = (event) => {
    window.localStorage.setItem("expandeId", event.dataItem.id);

    //if add new vessel is true then expand cannot be done !
    if (this.state.gridData.data[0].inEdit === true) {
      return;
    } else {
      event.dataItem.expanded = !event.dataItem.expanded;
    }

    this.forceUpdate();
  };

  render() {
    const { gridData, columns } = this.state;
    const hasEditedItem = gridData && gridData.data.some((p) => p.inEdit);
    let checkForEditDato = this.state.onEditItem;
    let windowTitle = !_.isEmpty(checkForEditDato)
      ? `Edit Fleet`
      : `Create Fleet`;

    const { hasBackofficeRead } = this.props;

    return (
      <div>
        <Grid
          {...this.state.dataState}
          {...this.state.gridData}
          style={{ height: "92vh" }}
          filterable
          sortable={true}
          resizable
          editField={this.editField}
          onItemChange={this.itemChange}
          expandField="expanded"
          onExpandChange={this.expandChange}
          onDataStateChange={this.dataStateChange}
          pageable={{
            buttonCount: 5,
            info: true,
            pageSizes: [100, 200, 500, 1000, 1500],
          }}
          detail={(dataItem) => (
            <div>
              <ExpandedTabs
                tabs={[
                  {
                    label: "Vessels To Fleet",
                    component: (
                      <VesselsToFleet
                        hasBackofficeRead={hasBackofficeRead}
                        {...dataItem}
                      />
                    ), // Index
                  },
                ]}
              ></ExpandedTabs>
            </div>
          )}
        >
          <GridToolbar>
            <GridContainer xs={12} direction={"row"}>
              {!hasBackofficeRead && (
                <Tooltip
                  justify={"flex-start"}
                  placement="top"
                  title={"Add Fleet Manually"}
                >
                  <IconButton onClick={this.toggleWindow}>
                    <FontAwesomeIcon
                      color="#0D5869"
                      onClick={this.toggleWindow}
                      size="1.6x"
                      icon={faPlus}
                    />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip
                justify={"flex-end"}
                placement="top"
                title={"Clear Column Filters"}
              >
                <IconButton
                  disabled={false}
                  aria-label=""
                  onClick={(e) => this.onClearFilters(e)}
                  look="flat"
                >
                  <FontAwesomeIcon
                    color="#0D5869"
                    size="1.6x"
                    icon={faEraser}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip
                justify={"flex-start"}
                placement="top"
                title={"Refresh Data"}
              >
                <IconButton
                  disabled={false}
                  aria-label=""
                  onClick={this.refreshGridDataAfterCrud}
                >
                  <FontAwesomeIcon color="#0D5869" size="1x" icon={faSyncAlt} />
                </IconButton>
              </Tooltip>
              <ViewSettings
                {...this.props}
                export={{
                  data: this.state.exportData || [],
                  exportFunction: this.getExportData,
                  fileName: "Fleets_",
                  columns: columnsSchema(),
                }}
                viewSettings={{
                  type: "",
                }}
                refreshSettings={{
                  data: [],
                }}
              />
              {!this.state.hideColumns ? (
                <div style={{ marginTop: hasBackofficeRead ? "-12px" : "" }}>
                  <FormControl className={customSelectStyles.selectFormControl}>
                    <InputLabel
                      htmlFor="multiple-select"
                      className={customSelectStyles.selectLabel}
                    />
                    <Select
                      autoWidth={true}
                      multiple
                      value={columns
                        .filter((column) => column.visible)
                        .map((column) => column.field)}
                      onChange={this.handleColumnsChange}
                      MenuProps={{ className: customSelectStyles.selectMenu }}
                      inputProps={{
                        name: "multipleSelect",
                        id: "multiple-select",
                      }}
                      renderValue={(selected) => "Columns"}
                    >
                      {columns
                        .filter((col) => !col.notShownInTableColumnSelection)
                        .map((column) => {
                          let name = column.title;
                          let value = column.field;
                          let isVisible = column.visible;
                          let isDisabled = column.noDisabledColumn;

                          return (
                            <MenuItem
                              style={{ backgroundColor: "white" }}
                              key={name}
                              value={value}
                              disabled={isDisabled}
                            >
                              <Checkbox color={"default"} checked={isVisible} />
                              <ListItemText
                                style={{ marginLeft: "7px" }}
                                primary={name}
                              />
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
              ) : null}
            </GridContainer>
          </GridToolbar>
          {!hasBackofficeRead && (
            <Column
              cell={this.CommandCell}
              title="Actions"
              filterable={false}
              width={hasEditedItem ? "240px" : "140px"}
            />
          )}
          {this.renderGridColumns()}
        </Grid>
        {this.state.visibleWindow && (
          <Window
            title={windowTitle}
            onClose={() => {
              this.setState({ ...this.state, visibleWindow: false });
            }}
            width={750}
            height={350}
            initialTop={10}
          >
            <div>
              <br />
              <fieldset style={{ borderColor: "rgba(13, 88, 105, 1)" }}>
                <div>
                  <h5>Fleet Name</h5>
                  <input
                    style={{ width: "450px" }}
                    className="k-textbox"
                    name="fleet_name"
                    value={
                      !_.isEmpty(checkForEditDato)
                        ? this.state.onEditItem.fleet_name
                        : this.state.fleet_name
                    }
                    onChange={this.onDialogInputChange}
                    placeholder="Enter fleet name"
                  />
                  <br />
                  <AutoComplete
                    style={{ width: "450px" }}
                    data={(this.state.client_flag || []).map(
                      (temp) => temp.relation_name
                    )}
                    value={this.state.valueClientName}
                    label={"Client name"}
                    name={"relation_name"}
                    onChange={(e) => this.onChange(e, "client_flag")}
                    listNoDataRender={(e) => this.listNoDataRender(e, "client")}
                  />
                </div>
                <br />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <button
                    style={{ justifyContent: "flex-start" }}
                    type="button"
                    className="k-button k-primary"
                    onClick={(e) => this.toggleWindow(e)}
                  >
                    Cancel
                  </button>
                  <button
                    style={{ justifyContent: "flex-end" }}
                    type="button"
                    className="k-button k-primary"
                    onClick={(e) => this.saveHandler(e)}
                    // disabled={this.state.valueClientName !== "" ? false : true}
                  >
                    Save
                  </button>
                </div>
              </fieldset>
              {this.state.windowError ? (
                <Error style={{ color: "red" }}>
                  <h3>{this.state.windowErrorMessage || ""}</h3>
                </Error>
              ) : null}
            </div>
          </Window>
        )}

        <Loader
          dataState={this.state.dataState}
          onDataRecieved={this.dataReceived}
          include={[]}
          getURL={"fleets"}
          countURL={"fleets/count"}
        />

        <Snackbar
          place="tc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"warning"}
          message={this.state.errorModalContent}
          open={this.state.openErrorModal}
          closeNotification={() =>
            this.handleErrorClose(this.state.errorModalContent)
          }
          close
        />

        <Snackbar
          place="tc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"success"}
          message={this.state.successMessage}
          open={this.state.openSuccessMessage}
          closeNotification={() =>
            this.handleSuccessSnackbar(this.state.successMessage)
          }
          close
        />
      </div>
    );
  }
}

export default Fleets;
