import React from "react";
import { MyCommandCell } from "../../CRM/View/myCommandCell";
import { columnsSchema } from "../View/schema";
import { Loader } from "./loader";
import { DropDownCell } from "../../CRM/View/dropDownCell.js";
import { serverApi } from "../../../networking/config";
import {
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Tooltip as MaterialTooltip,
} from "@material-ui/core";
import { Window } from "@progress/kendo-react-dialogs";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import GridContainer from "../../../components/Grid/GridContainer";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import ViewSettings from "../../common/ViewSettings";
import customSelectStyles from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import { Checkbox } from "@progress/kendo-react-inputs";
import Snackbar from "../../../components/Snackbar/Snackbar";
import { groupBy } from "@progress/kendo-data-query";
import {
  setExpandedState,
  setGroupIds,
} from "@progress/kendo-react-data-tools";
import { filterBy } from "@progress/kendo-data-query";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { Error } from "@progress/kendo-react-labels";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const initialGroup = [
  {
    field: "group_name",
  },
];

const processWithGroups = (data, group) => {
  const newDataState = groupBy(data, group);
  setGroupIds({
    data: newDataState,
    group: group,
  });
  return newDataState;
};

class WarehouseMappingView extends React.Component {
  editField = "inEdit";
  CommandCell;

  constructor(props) {
    super(props);

    this.state = {
      editingMode: false,
      client_flag: [],
      agent_flag: [],
      location_flag: [],
      valueClientName: "",
      valueAgentName: "",
      valueDestinationName: "",
      selectedClientOnGroup: false,

      visibleWindow: false,
      columns: (columnsSchema && columnsSchema()) || [],
      group: initialGroup,
      result: processWithGroups([], initialGroup),
      collapsedState: [],
      gridData: {
        data: [],
        total: 0,
      },
      initialGridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 1000,
        skip: 0,
      },
      openErrorModal: false,
      errorModalContent: "",
      delay: 400,
      loading: false,
      selectedRowIndex: 0,
      selectedItem: null,
      googleOptions: [],
      expandedRowIndex: null,
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      hasDisable: false,
      showSecondAction: false,
    });
  }

  componentDidMount() {
    let isFirstLoad = true;
    window.localStorage.setItem("isFirstLoad", isFirstLoad);

    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));

    if (hasFinalParams) {
      this.setState({
        ...this.state,
        dataState: hasFinalParams,
      });
    }

    if (hasChangedColumns) {
      this.setState({
        ...this.state,
        columns: hasChangedColumns,
      });
    }
  }

  componentWillUnmount() {
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));
    let finalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    let hasExpandedId = JSON.parse(window.localStorage.getItem("expandeId"));
    let hasExportFilter = window.localStorage.getItem("exportFilter");

    if (hasChangedColumns) {
      window.localStorage.removeItem("cols");
    }
    if (finalParams) {
      window.localStorage.removeItem("finalParams");
    }
    if (hasExpandedId) {
      window.localStorage.removeItem("expandeId");
    }
    if (hasExportFilter) {
      window.localStorage.removeItem("exportFilter");
    }
  }

  dataStateChange = (e) => {
    const reqGridParams = e.data;
    window.localStorage.setItem("finalParams", JSON.stringify(reqGridParams));

    this.setState({
      ...this.state,
      dataState: reqGridParams,
    });
  };

  dataReceived = (gridData, reqGridParams) => {
    this.setState({
      ...this.state,
      group: initialGroup,
      result: processWithGroups(gridData.data || [], initialGroup), // remove it or do it in gridData
      collapsedState: [],
      gridData: gridData,
      initialGridData: gridData,
      dataState:
        reqGridParams === !_.isEmpty(reqGridParams)
          ? reqGridParams
          : this.state.dataState,
    });
    // , () => this.handleGroupChange({})
  };

  renderGridColumns = () => {
    let selectionCol = [];
    let normalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.isFilterBoolean === "yes") {
          return (
            <Column
              field={temp.field}
              locked={temp.locked}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              cell={DropDownCell}
            />
          );
        } else if (
          temp.field === "client_name" ||
          temp.field === "agent_name" ||
          temp.field === "destination_name"
        ) {
          return (
            <Column
              field={temp.field}
              locked={temp.locked}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"300"}
            />
          );
        } else {
          return (
            <Column
              field={temp.field}
              locked={temp.locked}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={
                temp.field === "id" || temp.field === "false" ? false : true
              } //id not editable on edit
            />
          );
        }
      });
    return [...selectionCol, ...normalCols];
  };

  updateItem = (data, item) => {
    let index = data.findIndex(
      (p) => p === item || (item.id && p.id === item.id)
    );
    if (index >= 0) {
      //todo check again
      delete item.inEdit;
      data[index] = { ...item };
    }
  };

  handleSuccessSnackbar = () => {
    this.setState({
      ...this.state,
      successMessage:
        this.state.openSuccessMessage === true ? this.state.successMessage : "",
      openSuccessMessage: !this.state.openSuccessMessage,
    });
  };

  handleErrorClose = (loginError) => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      openErrorModalContent: "",
    });
  };

  removeItem(data, item) {
    let index = data.findIndex(
      (p) => p === item || item.id || p.id === item.id
    );
    if (index >= 0) {
      data.splice(index, 1);
    }
  }

  cancel = (dataItem) => {
    if (
      dataItem.otherSystemID === undefined ||
      dataItem.otherSystemID === "" ||
      dataItem.group_id === undefined ||
      dataItem.group_id === "" ||
      dataItem.group_name === undefined ||
      dataItem.group_name === "" ||
      dataItem.clientID === undefined ||
      dataItem.clientID === "" ||
      dataItem.client_name === undefined ||
      dataItem.client_name === "" ||
      dataItem.receivingAgentID === undefined ||
      dataItem.receivingAgentID === "" ||
      dataItem.agent_name === undefined ||
      dataItem.agent_name === "" ||
      dataItem.destinationID === undefined ||
      dataItem.destinationID === "" ||
      dataItem.destination_name === undefined ||
      dataItem.destination_name === ""
    ) {
      const data =
        this.state.initialGridData && this.state.initialGridData.data;
      const total = this.state.initialGridData.total;

      this.setState({
        ...this.state,
        result: data,
        gridData: {
          data: data,
          total: total,
        },
        errorModalContent:
          this.state.errorModalContent !== ""
            ? ""
            : this.state.errorModalContent,
        openErrorModal:
          this.state.openErrorModal === true
            ? false
            : this.state.errorModalContent,
      });
    } else {
      const originalItem =
        this.state.initialGridData &&
        this.state.initialGridData.data.find((p) => p.id === dataItem.id);
      originalItem.inEdit = false;

      const data =
        this.state.gridData &&
        this.state.gridData.data.map((item) =>
          item.id === originalItem.id ? originalItem : item
        );
      const total = this.state.gridData.total;

      this.setState({
        ...this.state,
        result: data,
        gridData: {
          data: data,
          total: total,
        },
      });
    }
  };

  remove = async (dataItem) => {};

  enterEdit = (dataItem) => {
    //EDIT ACTION ON GROUP ROW -> BUG KENDO GRID -> BYPASS IT
    if (dataItem["aggregates"] !== undefined) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            "You cannot edit the group from this page.Please visit group to client page instead.",
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 4000);
        }
      );
    } else {
      let total = this.state.gridData.total;
      this.setState({
        ...this.state,
        isClientOnGroup: dataItem.group_name === "NO GROUP" ? false : true, //use for edit
        editingMode: true,
        editingModeOnGroup: dataItem.group_name === "NO GROUP" ? false : true, //use for edit
        onEditItem: dataItem,
        editVisibleWindow: true,
        // editOnSaveClient: true,
        // editOnSaveOthersystemID: true,
        // editOnSaveAgent: true,
        // editOnSaveDestination: true,
        gridData: {
          data:
            this.state.gridData &&
            this.state.gridData.data.map((item) =>
              item.id === dataItem.id ? { ...item, inEdit: true } : item
            ),
          total: total,
        },
        initialGridData: {
          data:
            this.state.initialGridData &&
            this.state.initialGridData.data.map((item) =>
              item.id === dataItem.id ? { ...item, inEdit: true } : item
            ),
          total: total,
        },
      }); //, () => this.handleGroupChange({})
    }
  };

  discard = (dataItem, hasError = false) => {
    let data = [...this.state.gridData.data];
    const total = this.state.gridData.total;

    if (hasError) {
      data[0].inEdit = true;

      this.setState(
        {
          ...this.state,
          gridData: {
            data: data,
            total: total,
          },
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 3000);
        }
      );
    } else {
      this.removeItem(data, dataItem);

      this.setState(
        {
          ...this.state,
          gridData: {
            data: data,
            total: total,
          },
          visible: false,
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 3000);
        }
      );
    }
  };

  update = async (dataItem) => {};

  handleColumnsChange = (event) => {
    let columns = this.state.columns;

    let oldColumns = columns.filter((c) => c.visible).map((c) => c.field);
    let newColumns = event.target.value;

    let unique1 = oldColumns.filter((c) => newColumns.indexOf(c) === -1);
    let unique2 = newColumns.filter((c) => oldColumns.indexOf(c) === -1);

    const columnLastUpdated = unique1.concat(unique2)[0];

    let index = columns.findIndex(
      (column) => column.field === columnLastUpdated
    );
    columns[index].visible = !columns[index].visible;

    this.setState(
      {
        ...this.state,
        columns: columns,
      },
      () => {
        let columns = this.state.columns;
        window.localStorage.setItem("cols", JSON.stringify(columns));
      }
    );
  };

  handleGroupChange = (event) => {
    const newDataState = processWithGroups(
      this.state.gridData.data,
      event?.group
    );
    this.setState({
      ...this.state,
      result: newDataState,
      group: event?.group,
    });
  };

  expandChange = (event) => {
    const item = event.dataItem;

    if (item.groupId) {
      const collapsedIds = !event.value
        ? [...this.state.collapsedState, item.groupId]
        : this.state.collapsedState.filter(
            (groupId) => groupId !== item.groupId
          );
      this.setState({
        ...this.state,
        collapsedState: collapsedIds,
      });
    }
  };

  itemChange = (event) => {
    const total = this.state.gridData.total;
    const data =
      this.state.gridData &&
      this.state.gridData.data.map((item) =>
        item.id === event.dataItem.id
          ? { ...item, [event.field]: event.value }
          : item
      );

    this.setState({
      result: data,
      gridData: {
        data: data,
        total: total,
      },
    });
  };

  onClearFilters = () => {
    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));

    let init = JSON.stringify({ take: 1000, skip: 0 });
    if (hasFinalParams && hasFinalParams.filter)
      window.localStorage.setItem("finalParams", init);

    this.dataStateChange({ data: { take: 1000, skip: 0 } });
  };

  getExportData = async () => {
    await this.exportToCsv(this.state, this.props);
  };

  getDataPromise = (filter) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    return new Promise((resolve, reject) => {
      const request = serverApi(
        "GET",
        `ifwhMappingBoVs?filter=${JSON.stringify(filter)}`,
        "",
        "",
        options
      );
      request
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  exportToCsv = async (state, props) => {
    const count = state.gridData.total;
    const where = window.localStorage.getItem("exportFilter") || {};
    const numberOfCalls = Math.min(
      Math.round(count / 1000) + 1,
      5 // Limit to 5000
    );
    let finalSort = this.state.dataState?.sort?.length
      ? this.state.dataState.sort[0].field +
        " " +
        this.state.dataState.sort[0].dir
      : "id asc";

    const promises = [];
    for (let i = 0; i < numberOfCalls; i++) {
      promises.push(
        this.getDataPromise({
          skip: i * 1000,
          limit: 1000,
          where: !_.isEmpty(where) ? JSON.parse(where) : {},
          order: [finalSort],
        })
      );
    }

    try {
      const responses = await Promise.all(promises);
      let allData = [];
      responses.forEach((resp) => {
        allData = [...allData, ...resp.data];
      });
      if (allData.length > 0) {
        this.setState({
          ...this.state,
          exportData: allData,
        });
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent:
          e?.response?.data?.error?.message || "Please try again",
      });
    }
  };

  addNew = () => {
    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    const newDataItem = { inEdit: true };

    this.setState({
      gridData: {
        data: [newDataItem, ...this.state.gridData.data],
        total: this.state.gridData.total,
      },
      visible: true,
    });
  };

  toggleWindow = async (e) => {
    this.setState({
      ...this.state,
      visibleWindow: !this.state.visibleWindow,
      valueAgentName: "",
      valueClientName: "",
      valueDestinationName: "",
    });
  };

  openEditWindow = () => {
    this.setState({
      ...this.state,
      editVisibleWindow: !this.state.editVisibleWindow,
      valueAgentName: "",
      valueClientName: "",
      valueDestinationName: "",
      data: [],
      errorModalContent: "",
      getGroupToClientData: [],
      selectedClientOnGroup: false,
    });
  };

  onChange = (event, field) => {
    //prevent enter keyboard
    if (
      event.nativeEvent.type === "keydown" &&
      event.nativeEvent.keyCode === 13
    )
      return;

    let editingMode = this.state.editingMode;
    const { value } = event.target;
    switch (field) {
      case "client_flag": {
        if (value === "") {
          if (!editingMode) {
            this.setState({
              openedClientNames: false,
              valueClientName: value,
            });
          } else {
            let editItem = this.state.onEditItem;
            editItem.client_name = value;
            this.setState({
              openedClientNames: false,
              ...this.state.editItem,
            });
          }
        } else {
          if (!editingMode) {
            this.setState(
              {
                openedClientNames: false,
                valueClientName: value,
              },
              () => {
                this.onAutoCompleteChange(event, value, field);
              }
            );
          } else {
            let editItem = this.state.onEditItem;
            editItem.client_name = value;
            this.setState(
              {
                openedClientNames: false,
                ...this.state.editItem,
              },
              () => {
                this.onAutoCompleteChange(event, value, field);
              }
            );
          }
        }

        break;
      }
      case "agent_flag": {
        if (value === "") {
          if (!editingMode) {
            this.setState({
              openedAgentNames: false,
              valueAgentName: value,
            });
          } else {
            let editItem = this.state.onEditItem;
            editItem.agent_name = value;
            this.setState({
              openedAgentNames: false,
              ...this.state.editItem,
            });
          }
        } else {
          if (!editingMode) {
            this.setState(
              {
                openedAgentNames: false,
                valueAgentName: value,
              },
              () => {
                this.onAutoCompleteChange(event, value, field);
              }
            );
          } else {
            let editItem = this.state.onEditItem;
            editItem.agent_name = value;
            this.setState(
              {
                openedAgentNames: false,
                ...this.state.editItem,
              },
              () => {
                this.onAutoCompleteChange(event, value, field);
              }
            );
          }
        }
        break;
      }
      case "location_flag": {
        if (value === "") {
          if (!editingMode) {
            this.setState({
              openedDestinationNames: false,
              valueDestinationName: value,
            });
          } else {
            let editItem = this.state.onEditItem;
            editItem.destination_name = value;
            this.setState({
              openedDestinationNames: false,
              ...this.state.editItem,
            });
          }
        } else {
          if (!editingMode) {
            this.setState(
              {
                openedDestinationNames: false,
                valueDestinationName: value,
              },
              () => {
                this.onAutoCompleteChange(event, value, field);
              }
            );
          } else {
            let editItem = this.state.onEditItem;
            editItem.destination_name = value;
            this.setState(
              {
                openedDestinationNames: false,
                ...this.state.editItem,
              },
              () => {
                this.onAutoCompleteChange(event, value, field);
              }
            );
          }
        }
        break;
      }

      default:
        break;
    }
  };

  onAutoCompleteChange = async (event, value, field) => {
    const { name } = event.target;
    let stateData = {};

    switch (field) {
      case "client_flag": {
        stateData =
          value.length < 3
            ? {
                data:
                  this.state.client_flag.length > 0
                    ? this.state.client_flag.map((temp) => temp.relation_name)
                    : [],
                openedClientNames: false,
              }
            : { data: this.filterData(value, name), openedClientNames: true };

        let eventType = event.nativeEvent.type;

        let valueSelected =
          eventType === "click" ||
          (eventType === "keydown" && event.nativeEvent.keyCode === 13);

        if (valueSelected) {
          let postClientId = null;
          if (!this.state.editingMode) {
            postClientId =
              this.state.client_flag.length === 1
                ? this.state.client_flag[0].id
                : (this.state.client_flag || []).filter(
                    (temp) => temp.relation_name === this.state.valueClientName
                  )[0].id;
          } else {
            postClientId =
              this.state.client_flag.length === 1
                ? this.state.onEditItem.clientID
                : (this.state.client_flag || []).filter(
                    (temp) =>
                      temp.relation_name === this.state.onEditItem.client_name
                  )[0].id;
          }

          this.setState(
            {
              ...this.state,
              postClientID: postClientId || "",
            },
            async () => {
              const belongToClientGroup = await this.checkClientGroup();
              if (belongToClientGroup.data.length > 0) {
                let groupID = belongToClientGroup.data[0].clientgroup_id;
                //clientgroup table on dbs : 51 dev / prod 0 / uat 9 -> TODO SET SAME ON 3 DBs
                //FIXED !!! -> NO GROUP is 0 on all databases.
                if (groupID !== null || groupID !== 0) {
                  let filter = {
                    order: ["relation_name asc"],
                    where: {
                      and: [
                        { clientgroup_id: groupID },
                        {
                          client_flag: true,
                        },
                      ],
                    },
                  };
                  let options = {
                    token: window.localStorage.getItem("access_token"),
                  };
                  const getGroupToClientData = await serverApi(
                    "GET",
                    `masterEntities`,
                    { filter: filter },
                    "",
                    options
                  );
                  this.setState({
                    ...this.state,
                    selectedClientOnGroup: true,
                    getGroupToClientData: getGroupToClientData?.data || [],
                  });
                } else {
                  this.setState({
                    ...this.state,
                    selectedClientOnGroup: false,
                    getGroupToClientData: [],
                  });
                }
              }
            }
          );
        }

        if (valueSelected && stateData.data.includes(value)) {
          stateData.openedClientNames = false;
        }

        this.setState(
          {
            valueClientName: value,
            ...stateData,
          },
          () => this.fetchAutocompleteData(value, field)
        );

        break;
      }
      case "agent_flag": {
        stateData =
          value.length < 3
            ? {
                data:
                  this.state.agent_flag.length > 0
                    ? this.state.agent_flag.map((temp) => temp.relation_name)
                    : [],
                openedAgentNames: false,
              }
            : { data: this.filterData(value, name), openedAgentNames: true };

        let eventType = event.nativeEvent.type;

        let valueSelected =
          eventType === "click" ||
          (eventType === "keydown" && event.nativeEvent.keyCode === 13);

        if (valueSelected) {
          let postAgentID = null;

          if (!this.state.editingMode) {
            postAgentID =
              this.state.agent_flag.length === 1
                ? this.state.agent_flag[0].id
                : (this.state.agent_flag || []).filter(
                    (temp) => temp.relation_name === this.state.valueAgentName
                  )[0].id;
          } else {
            postAgentID =
              this.state.agent_flag.length === 1
                ? this.state.onEditItem.receivingAgentID
                : (this.state.agent_flag || []).filter(
                    (temp) =>
                      temp.relation_name === this.state.onEditItem.agent_name
                  )[0].id;
          }

          this.setState({
            ...this.state,
            postAgentID: postAgentID || "",
          });
        }

        if (valueSelected && stateData.data.includes(value)) {
          stateData.openedAgentNames = false;
        }

        this.setState(
          {
            valueAgentName: value,
            ...stateData,
          },
          () => this.fetchAutocompleteData(value, field)
        );

        break;
      }
      case "location_flag": {
        stateData =
          value.length < 3
            ? {
                data:
                  this.state.location_flag.length > 0
                    ? this.state.location_flag.map((temp) => temp.relation_name)
                    : [],
                openedDestinationNames: false,
              }
            : {
                data: this.filterData(value, name),
                openedDestinationNames: true,
              };

        let eventType = event.nativeEvent.type;

        let valueSelected =
          eventType === "click" ||
          (eventType === "keydown" && event.nativeEvent.keyCode === 13);

        if (valueSelected) {
          let postDestinationID = null;

          if (!this.state.editingMode) {
            postDestinationID =
              this.state.location_flag.length === 1
                ? this.state.location_flag[0].id
                : (this.state.location_flag || []).filter(
                    (temp) =>
                      temp.relation_name === this.state.valueDestinationName
                  )[0].id;
          } else {
            postDestinationID =
              this.state.location_flag.length === 1
                ? this.state.onEditItem[0].destinationID
                : (this.state.location_flag || []).filter(
                    (temp) =>
                      temp.relation_name ===
                      this.state.onEditItem.destination_name
                  )[0].id;
          }

          this.setState({
            ...this.state,
            postDestinationID: postDestinationID || "",
          });
        }

        if (valueSelected && stateData.data.includes(value)) {
          stateData.openedDestinationNames = false;
        }

        this.setState(
          {
            valueDestinationName: value,
            ...stateData,
          },
          () => this.fetchAutocompleteData(value, field)
        );

        break;
      }
      default:
        break;
    }
  };

  checkClientGroup = async () => {
    try {
      let options = {
        token: window.localStorage.getItem("access_token"),
      };

      let filter = {
        order: ["relation_name asc"],
        where: {
          and: [
            { id: this.state.postClientID },
            {
              client_flag: true,
            },
          ],
        },
      };

      const clientGroupReq = await serverApi(
        "GET",
        `masterEntities`,
        { filter: filter },
        "",
        options
      );
      return clientGroupReq;
    } catch (error) {}
  };

  fetchAutocompleteData = (filterValue, field) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let filter = {
      order: ["relation_name asc"],
      where: {
        and: [
          {
            [field]: true,
          },
          {
            or: [{ relation_name: { regexp: `/^${filterValue}/i` } }],
          },
        ],
      },
    };

    if (field === "client_flag") {
      filter.where.and[1].or.push({ id: { regexp: `/^${filterValue}/i` } });
    }

    const fetchDropdownData = serverApi(
      "GET",
      `masterEntities`,
      { filter: filter },
      "",
      options
    );

    fetchDropdownData
      .then((response) => {
        this.setState({
          ...this.state,
          [field]: response.data.sort((a, b) =>
            a.relation_name > b.relation_name ? 1 : -1
          ),
        });
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  };

  filterData = (value, name) => {
    const data = this.state.client_flag.map((test) => test.relation_name);

    const filter = {
      value: value,
      operator: "startswith",
      ignoreCase: true,
    };

    let results = filterBy(data, filter);
    return results;
  };

  listNoDataRender = (element, field) => {
    let showMsg = "";
    if (field === "client") showMsg = "No client found";
    if (field === "destination") showMsg = "No destination found";
    if (field === "agent") showMsg = "No agent found";
    const noData = (
      <h4 style={{ fontSize: "1em" }}>
        <span className="k-icon k-i-warning" style={{ fontSize: "2.5em" }} />
        <br />
        <br />
        {showMsg}
      </h4>
    );

    return React.cloneElement(element, { ...element.props }, noData);
  };

  saveHandler = async () => {
    let editingMode = this.state.editVisibleWindow === true ? true : false;

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    //create mode
    if (!editingMode) {
      try {
        let getGroupToClientData = this.state.getGroupToClientData || [];

        let finalData = [];

        //todo check if wh mapping new record has client provided that belongs to NO GROUP
        let checkFlag = null;

        let checkArr =
          (this.state.getGroupToClientData || []).some(
            (x) => x.clientgroup_id === 0 || x.clientgroup_id == null
          ) || [];
        if (checkArr.length > 0 || checkArr === true) {
          checkFlag = true;
        } else {
          checkFlag = false;
        }

        if (checkFlag) {
          finalData.push({
            clientID: this.state.postClientID,
            otherSystemID:
              this.state.otherSystemID || this.state.onEditItem.otherSystemID,
            receivingAgentID: this.state.postAgentID,
            destinationID: this.state.postDestinationID,
          });
        } else if (getGroupToClientData?.length > 0 && checkFlag === false) {
          getGroupToClientData = getGroupToClientData.map((x) => x.id);
          _.forEach(getGroupToClientData, (dt) => {
            finalData.push({
              clientID: dt,
              otherSystemID:
                this.state.otherSystemID || this.state.onEditItem.otherSystemID,
              receivingAgentID: this.state.postAgentID,
              destinationID: this.state.postDestinationID,
            });
          });
        }

        // eslint-disable-next-line no-unused-vars
        for (let dato of finalData) {
          await serverApi("POST", "if_wh_mappings", {}, dato, options);
        }

        this.setState(
          {
            ...this.state,
            agent_flag: [],
            client_flag: [],
            location_flag: [],
            otherSystemID: "",
            postAgentID: "",
            postClientID: "",
            postDestinationID: "",
            valueAgentName: "",
            valueClientName: "",
            valueDestinationName: "",
            openedAgentNames: false,
            openedClientNames: false,
            openedDestinationNames: false,
            selectedClientOnGroup: false,
            getGroupToClientData: [],
            successMessage: "Warehouse Mapping(s) successfully created.",
            openSuccessMessage: true,
            visibleWindow: false,
          },
          () => {
            this.refreshGridDataAfterCrud();
            setTimeout(() => {
              this.setState({
                ...this.state,
                successMessage: "",
                openSuccessMessage: false,
              });
            }, 5000);
          }
        );
      } catch (error) {
        this.setState({
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            error?.response?.data?.error?.message ||
            "Create Warehouse Mapping(s) failed.",
        });
      }
    }
    //edit mode
    else {
      let finalOtherSytemID =
        this.state.otherSystemID === this.state.onEditItem.otherSystemID
          ? this.state.onEditItem.otherSystemID
          : this.state.otherSystemID;

      let finalClientId =
        this.state.postClientID === this.state.onEditItem.clientID
          ? this.state.onEditItem.clientID
          : this.state.postClientID;

      let finalAgentId =
        this.state.postAgentID === this.state.onEditItem.receivingAgentID
          ? this.state.onEditItem.receivingAgentID
          : this.state.postAgentID;

      let finalDestinationId =
        this.state.postDestinationID === this.state.onEditItem.destinationID
          ? this.state.onEditItem.destinationID
          : this.state.postDestinationID;

      //todo use : this.state.editingModeOnGroup
      let editPayload = {
        clientID: finalClientId || this.state.onEditItem.clientID,
        otherSystemID: finalOtherSytemID || this.state.onEditItem.otherSystemID,
        receivingAgentID:
          finalAgentId || this.state.onEditItem.receivingAgentID,
        destinationID:
          finalDestinationId || this.state.onEditItem.destinationID,
      };

      //checks before update warehouse mapping
      let dropdownErrors = [];
      if (this.state.valueClientName !== "" && !this.state.postClientID)
        dropdownErrors.push("Client name");
      if (this.state.valueAgentName !== "" && !this.state.postAgentID)
        dropdownErrors.push("Agent name");
      if (
        this.state.valueDestinationName !== "" &&
        !this.state.postDestinationID
      )
        dropdownErrors.push("Destination name");
      if (!_.isEmpty(dropdownErrors)) {
        this.setState(
          {
            ...this.state,
            openErrorModal: true,
            errorModalContent: `Wrong inputs: ${String(
              dropdownErrors
            )}. You should use dropdown values to continue.`,
          },
          () => {
            setTimeout(() => {
              this.handleErrorClose();
            }, 5000);
          }
        );
        return;
      }

      try {
        let filter1 = {
          filter: {
            where: {
              id: editPayload.clientID,
            },
          },
        };
        const attachedGroups = await serverApi(
          "GET",
          `masterEntities`,
          filter1,
          "",
          options
        );
        //checks if client belongs to a group of clients apart from the NO GROUP (clientgroup_id = 0)
        if (
          !this.state.editingModeOnGroup &&
          attachedGroups.data.length > 0 &&
          attachedGroups.data[0].clientgroup_id !== 0
        ) {
          this.setState(
            {
              ...this.state,
              openErrorModal: true,
              valueClientName: "",
              data: [],
              errorModalContent:
                "The client provided belongs to a group of clients. Please choose another client.",
            },
            () => {
              setTimeout(() => {
                this.handleErrorClose();
              }, 4000);
            }
          );
          return;
        }

        let addVesselToClientRequest = await serverApi(
          "PATCH",
          `if_wh_mappings/${this.state.onEditItem.id}`,
          {},
          editPayload,
          options
        );

        if (addVesselToClientRequest) {
          this.setState(
            {
              ...this.state,
              onEditItem: {},
              editVisibleWindow: false,
              successMessage: "Warehouse Mapping successfully updated.",
              openSuccessMessage: true,
            },
            () => {
              this.refreshGridDataAfterCrud();
              setTimeout(() => {
                this.setState({
                  ...this.state,
                  successMessage: "",
                  openSuccessMessage: false,
                  valueAgentName: "",
                  valueClientName: "",
                  valueDestinationName: "",
                  postAgentID: null,
                  postDestinationID: null,
                  postClientID: null,
                });
              }, 4000);
            }
          );
        }
      } catch (error) {
        this.setState({
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            error?.response?.data?.error?.message ||
            "Warehouse Mapping failed to update.",
        });
      }
    }
  };

  onDialogInputChange = (event) => {
    let target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.props ? target.props.name : target.name;

    if (!this.state.editingMode) {
      this.setState({
        ...this.state,
        gridData: {
          ...this.state.gridData,
          [name]: value,
        },
        [name]: value,
      });
    } else {
      let editItem = this.state.onEditItem;
      editItem.otherSystemID = value;
      this.setState({
        ...this.state,
        ...this.state.editItem,
      });
    }
  };

  refreshGridDataAfterCrud = async () => {
    let token = window.localStorage.getItem("access_token");
    let options = {
      token: token,
    };
    let refreshParams = window.localStorage.getItem("refreshParams");
    refreshParams = JSON.parse(refreshParams);

    const filter = {
      include: [
        {
          relation: "client",
          scope: {
            fields: ["relation_name"],
          },
        },
        {
          relation: "agent",
          scope: {
            fields: ["relation_name"],
          },
        },
        {
          relation: "destination",
          scope: {
            fields: ["relation_name"],
          },
        },
      ],
      where: { and: [{}] },
      limit: "1000",
      skip: "0",
    };

    let finalFilter = null;
    if (!_.isEmpty(refreshParams)) {
      finalFilter = refreshParams;
    } else {
      finalFilter = { filter };
    }

    const responseData = await serverApi(
      "GET",
      "ifwhMappingBoVs",
      finalFilter,
      "",
      options
    );

    let count;
    const countMasterEntity = await serverApi(
      "GET",
      `ifwhMappingBoVs/count?where=${JSON.stringify(
        refreshParams.filter.where
      )}`,
      "",
      "",
      options
    );
    if (countMasterEntity) {
      count = countMasterEntity.data.count;
    }

    let sendObj = {
      gridData: {
        data: responseData.data,
        total: count,
      },
    };

    this.dataReceived(sendObj.gridData, null);
  };

  render() {
    const { gridData, columns } = this.state;
    const hasEditedItem = gridData && gridData.data.some((p) => p.inEdit);

    const result = this.state.result;
    const collapsedState = this.state.collapsedState;
    let newData = setExpandedState({
      data: result,
      collapsedIds: collapsedState,
    });

    let windowTitle = `Create Warehouse Mapping`;
    let editWindowTitle = `Edit Warehouse Mapping`;

    const { hasBackofficeRead } = this.props;

    return (
      <div>
        <Grid
          {...this.state.dataState}
          // {...this.state.gridData}
          data={newData}
          style={{ height: "92vh" }}
          groupable={true}
          filterable={true}
          sortable={true}
          resizable
          editField={this.editField}
          onGroupChange={this.handleGroupChange}
          group={this.state.group}
          onExpandChange={this.expandChange}
          expandField="expanded"
          onItemChange={this.itemChange}
          onDataStateChange={this.dataStateChange}
        >
          <GridToolbar>
            <GridContainer xs={12} direction={"row"}>
              {!hasBackofficeRead && (
                <MaterialTooltip
                  justify={"flex-start"}
                  placement="top"
                  title="Add Warehouse Mapping Manually"
                >
                  <IconButton onClick={this.toggleWindow}>
                    <FontAwesomeIcon
                      color="#0D5869"
                      onClick={this.toggleWindow}
                      size="1.6x"
                      icon={faPlus}
                    />
                  </IconButton>
                </MaterialTooltip>
              )}

              <MaterialTooltip
                justify={"flex-start"}
                placement="top"
                title="Clear Column Filters"
              >
                <IconButton
                  disabled={false}
                  aria-label=""
                  onClick={(e) => this.onClearFilters(e)}
                  look="flat"
                >
                  <FontAwesomeIcon
                    color="#0D5869"
                    size="1.6x"
                    icon={faEraser}
                  />
                </IconButton>
              </MaterialTooltip>

              <MaterialTooltip
                justify={"flex-start"}
                placement="top"
                title={"Refresh Data"}
              >
                <IconButton
                  disabled={false}
                  aria-label=""
                  onClick={this.refreshGridDataAfterCrud}
                >
                  <FontAwesomeIcon color="#0D5869" size="1x" icon={faSyncAlt} />
                </IconButton>
              </MaterialTooltip>

              <ViewSettings
                {...this.props}
                export={{
                  data: this.state.exportData || [],
                  exportFunction: this.getExportData,
                  fileName: `If_Warehouse_Mappings_${new Date().toISOString}`,
                  columns: columnsSchema(),
                }}
                viewSettings={{
                  type: "",
                }}
                refreshSettings={{
                  data: [],
                }}
              />
              {!this.state.hideColumns ? (
                <div style={{ marginTop: hasBackofficeRead ? "-12px" : "" }}>
                  <FormControl className={customSelectStyles.selectFormControl}>
                    <InputLabel
                      htmlFor="multiple-select"
                      className={customSelectStyles.selectLabel}
                    />
                    <Select
                      autoWidth={true}
                      multiple
                      value={columns
                        .filter((column) => column.visible)
                        .map((column) => column.field)}
                      onChange={this.handleColumnsChange}
                      MenuProps={{ className: customSelectStyles.selectMenu }}
                      inputProps={{
                        name: "multipleSelect",
                        id: "multiple-select",
                      }}
                      renderValue={(selected) => "Columns"}
                    >
                      {columns
                        .filter((col) => !col.notShownInTableColumnSelection)
                        .map((column) => {
                          let name = column.title;
                          let value = column.field;
                          let isVisible = column.visible;
                          let isDisabled = column.noDisabledColumn;

                          return (
                            <MenuItem
                              style={{ backgroundColor: "white" }}
                              key={name}
                              value={value}
                              disabled={isDisabled}
                            >
                              <Checkbox color={"default"} checked={isVisible} />
                              <ListItemText
                                style={{ marginLeft: "7px" }}
                                primary={name}
                              />
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
              ) : null}
            </GridContainer>
          </GridToolbar>
          {!hasBackofficeRead && (
            <Column
              cell={this.CommandCell}
              title="Actions"
              filterable={false}
              width={hasEditedItem ? "240px" : "140px"}
            />
          )}
          {this.renderGridColumns()}
        </Grid>

        {this.state.visibleWindow && (
          <Window
            title={windowTitle}
            onClose={() => {
              this.setState({ ...this.state, visibleWindow: false });
            }}
            width={750}
            height={500}
            initialTop={10}
          >
            <div>
              <br />
              <fieldset style={{ borderColor: "rgba(13, 88, 105, 1)" }}>
                <div>
                  <h5>Other System Id</h5>
                  <input
                    maxLength={50}
                    style={{ width: "450px" }}
                    className="k-textbox"
                    name="otherSystemID"
                    value={
                      this.state.gridData.data.filter(
                        (temp) => temp.inEdit === true
                      ).otherSystemID
                    }
                    onChange={this.onDialogInputChange}
                    placeholder="Provide a value with max 50 characters length."
                  />
                  <br />
                  <AutoComplete
                    style={{ width: "450px" }}
                    data={this.state.client_flag.map(
                      (temp) => temp.relation_name
                    )}
                    value={this.state.valueClientName}
                    label={"Client name"}
                    name={"relation_name"}
                    onChange={(e) => this.onChange(e, "client_flag")}
                    listNoDataRender={(e) => this.listNoDataRender(e, "client")}
                  />
                  <br />
                  <AutoComplete
                    style={{ width: "450px" }}
                    data={this.state.agent_flag.map(
                      (temp) => temp.relation_name
                    )}
                    value={this.state.valueAgentName}
                    label={"Agent name"}
                    name={"relation_name"}
                    onChange={(e) => this.onChange(e, "agent_flag")}
                    listNoDataRender={(e) => this.listNoDataRender(e, "agent")}
                  />
                  <br />
                  <AutoComplete
                    style={{ width: "450px" }}
                    data={this.state.location_flag.map(
                      (temp) => temp.relation_name
                    )}
                    value={this.state.valueDestinationName}
                    label={"Destination name"}
                    name={"relation_name"}
                    onChange={(e) => this.onChange(e, "location_flag")}
                    listNoDataRender={(e) =>
                      this.listNoDataRender(e, "destination")
                    }
                  />
                </div>
                <br />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <button
                    style={{ justifyContent: "flex-start" }}
                    type="button"
                    className="k-button k-primary"
                    onClick={(e) => this.toggleWindow(e)}
                  >
                    Cancel
                  </button>
                  <button
                    style={{ justifyContent: "flex-end" }}
                    type="button"
                    className="k-button k-primary"
                    onClick={(e) => this.saveHandler(e)}
                    disabled={
                      this.state.valueClientName !== "" &&
                      this.state.valueAgentName !== "" &&
                      this.state.valueDestinationName !== ""
                        ? false
                        : true
                    }
                  >
                    Save
                  </button>
                </div>
              </fieldset>
              {this.state.windowError ? (
                <Error style={{ color: "red" }}>
                  <h3>{this.state.windowErrorMessage || ""}</h3>
                </Error>
              ) : null}
            </div>
          </Window>
        )}

        {this.state.editVisibleWindow && (
          <Window
            title={editWindowTitle}
            onClose={() => {
              this.setState({
                ...this.state,
                editVisibleWindow: false,
                data: [],
                errorModalContent: "",
                getGroupToClientData: [],
                selectedClientOnGroup: false,
              });
            }}
            width={750}
            height={500}
            initialTop={10}
          >
            <div>
              <br />
              <fieldset style={{ borderColor: "rgba(13, 88, 105, 1)" }}>
                <div>
                  <h5>Other System Id</h5>
                  <input
                    maxLength={50}
                    style={{ width: "450px" }}
                    className="k-textbox"
                    name="otherSystemID"
                    value={this.state.onEditItem.otherSystemID}
                    onChange={this.onDialogInputChange}
                    placeholder="Provide a value with max 50 characters length."
                  />
                  <br />
                  <AutoComplete
                    className=""
                    style={{ width: "450px" }}
                    disabled={this.state.editingModeOnGroup ? true : false}
                    data={this.state.client_flag.map(
                      (temp) => temp.relation_name
                    )}
                    value={this.state.onEditItem.client_name}
                    label={"Client name"}
                    name={"relation_name"}
                    onChange={(e) => this.onChange(e, "client_flag")}
                    listNoDataRender={(e) => this.listNoDataRender(e, "client")}
                  />
                  <br />
                  <AutoComplete
                    style={{ width: "450px" }}
                    data={this.state.agent_flag.map(
                      (temp) => temp.relation_name
                    )}
                    value={this.state.onEditItem.agent_name}
                    label={"Agent name"}
                    name={"relation_name"}
                    onChange={(e) => this.onChange(e, "agent_flag")}
                    listNoDataRender={(e) => this.listNoDataRender(e, "agent")}
                  />
                  <br />
                  <AutoComplete
                    style={{ width: "450px" }}
                    data={this.state.location_flag.map(
                      (temp) => temp.relation_name
                    )}
                    value={this.state.onEditItem.destination_name}
                    label={"Destination name"}
                    name={"relation_name"}
                    onChange={(e) => this.onChange(e, "location_flag")}
                    listNoDataRender={(e) =>
                      this.listNoDataRender(e, "destination")
                    }
                  />
                </div>
                <br />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <button
                    style={{ justifyContent: "flex-start" }}
                    type="button"
                    className="k-button k-primary"
                    onClick={(e) => this.openEditWindow(e)}
                  >
                    Cancel
                  </button>
                  <button
                    style={{ justifyContent: "flex-end" }}
                    type="button"
                    className="k-button k-primary"
                    onClick={(e) => this.saveHandler(e)}
                    disabled={
                      this.state.onEditItem.client_name !== "" &&
                      this.state.onEditItem.agent_name !== "" &&
                      this.state.onEditItem.destination_name !== ""
                        ? false
                        : true
                    }
                  >
                    Save
                  </button>
                </div>
              </fieldset>
              {this.state.windowError ? (
                <Error style={{ color: "red" }}>
                  <h3>{this.state.windowErrorMessage || ""}</h3>
                </Error>
              ) : null}
            </div>
          </Window>
        )}

        <Loader
          dataState={this.state.dataState}
          onDataRecieved={this.dataReceived}
          include={[]}
          getURL={"ifwhMappingBoVs"}
          countURL={"ifwhMappingBoVs/count"}
        />

        <Snackbar
          place="bc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"warning"}
          message={this.state.errorModalContent}
          open={this.state.openErrorModal}
          closeNotification={() =>
            this.handleErrorClose(this.state.errorModalContent)
          }
          close
        />

        <Snackbar
          place="tc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"success"}
          message={this.state.successMessage}
          open={this.state.openSuccessMessage}
          closeNotification={() =>
            this.handleSuccessSnackbar(this.state.successMessage)
          }
          close
        />
      </div>
    );
  }
}

export default WarehouseMappingView;
