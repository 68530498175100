export const columnsSchema = () => [
  {
    field: "id",
    title: "ID",
    visible: true,
    minWidth: 140,
    sortable: true,
    filterable: false,
    locked: "locked",
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
    editable: "false",
    // cell: true
  },
  // {
  //   field: "modelID",
  //   title: "Model ID",
  //   visible: true,
  //   minWidth: 160,
  //   sortable: true,
  //   filterable: true,
  //   locked: "locked",
  //   noDisabledColumn: true,
  //   notShownInTableColumnSelection: false,
  //   // editable: "false"
  //   // cell: true
  // },
  // {
  //   field: "modelName",
  //   title: "Model Name",
  //   visible: true,
  //   minWidth: 250,
  //   sortable: true,
  //   filterable: true,
  //   locked: "locked",
  //   // editable: "false"
  // },
  {
    field: "name",
    title: "Name",
    visible: true,
    minWidth: 530,
    sortable: true,
    filterable: true,
    locked: "locked",
  },
  {
    field: "value",
    title: "Value",
    visible: true,
    minWidth: 430,
    sortable: true,
    filterable: true,
    locked: "locked",
  },
];
