import React from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";

export class PrimaryFlag extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      localizedData: this.props.localizedData || [
        { text: "true", value: true },
        { text: "false", value: false },
      ],
      dataValue: "",
    };
  }

  componentWillUnmount() {
    let hasEditItem = JSON.parse(window.localStorage.getItem("editItem"));
    if (hasEditItem) {
      window.localStorage.removeItem("editItem");
    }
  }

  handleChange = e => {
    if (
      window.location.href.includes("vessels") &&
      this.props.expanded &&
      this.props.field === "primary_flg"
    ) {
      let editItem = {
        masterEntity: this.props.dataItem.masterEntity || {},
        clientID: this.props.dataItem.clientID || "",
        vesselID: this.props.dataItem.vesselID || "",
        inEdit: true,
        primary_flg: e.target.value.value,
      };

      window.localStorage.setItem("editItem", JSON.stringify(editItem));

      this.setState({
        dataValue: e.target.value.value,
      });
    } else {
      this.props.onChange({
        dataItem: this.props.dataItem,
        field: this.props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value.value,
      });
    }
  };

  render() {
    const { dataItem, field, width, localizedData } = this.props;
    let dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataValue === null) {
      dataValue = this.state.dataValue;
    }
    if (dataValue === false && this.state.dataValue === true) {
      dataValue = true;
    } else if (dataValue === true && this.state.dataValue === false) {
      dataValue = false;
    }

    let is_disabled = false;
    let value =
      localizedData.find(c => c.value === dataValue) ||
      this.state.localizedData.find(c => c.value === dataValue);
    if (value.value === true) {
      //dataValue
      is_disabled = true;
    }

    return (
      <td>
        {dataItem && dataItem.inEdit
          ? <DropDownList
              style={{ width: width ? width : "100px" }}
              onChange={this.handleChange}
              disabled={is_disabled}
              value={
                (localizedData || []).find(c => c.value === dataValue) ||
                this.localizedData.find(c => c.value === dataValue)
              }
              data={localizedData || this.localizedData}
              textField="text"
            />
          : (dataValue !== undefined && dataValue.toString()) || ""}
      </td>
    );
  }
}
