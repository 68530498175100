import { connect } from "react-redux";
import compose from "ramda/src/compose";
// import {crmPageActions} from './state';
import Vessels from "./View/index";

const mapActionCreators = {};

const mapStateToProps = (state) => {
  return {
    user: state.MainLayoutReducer.user,
    hasBackofficeRead: state.MainLayoutReducer.user.hasBackofficeRead,
    countryCodes: state.MainLayoutReducer.countryCodes,
    vesselLovTypes: state.MainLayoutReducer.vesselLovTypes,
    usaStates: state.MainLayoutReducer.usaStates,
  };
};

export default compose(connect(mapStateToProps, mapActionCreators))(Vessels);
