import React from "react";
import logo from "../../../assets/img/navLogo.png";

const SidebarLogo = (props) => {
  const { root } = props;

  return (
    <div className="logo">
      <a href={root} className="simple-text logo-mini">
        <div className="logo-img">
          <img src={logo} alt="logo" />
        </div>
      </a>
      <a href="#/" className="simple-text logo-normal">
        Backoffice
      </a>
    </div>
  );
};

export default SidebarLogo;
