/**
 * Created by kfousteris on 23/1/2019.
 */
import Axios from "axios";
import _ from "lodash";
import { loadProgressBar } from "axios-progress-bar";
import "./nprogress.css";

const axiosOptions = (method, url, params, data, options, contentType) => {
  /**
   * Configure API Endpoints Dynamically based on the fact that different environments
   * will be accessed by different hostnames in the browser
   */
  let final = null;
  let environmentLocation =
    window && window.location && window.location.hostname;
  let isDev = null;

  if (environmentLocation === "localhost") {
    final = "http://localhost:3000/api";
    isDev = true;
  }

  if (environmentLocation === "dev2.swift-navigator.com") {
    final = "https://dev2.swift-navigator.com:443/api";
    isDev = true;
  } else if (environmentLocation === "uat2.swift-navigator.com") {
    final = "https://uat2.swift-navigator.com/api";
    isDev = false;
  } else if (environmentLocation === "prod2.swift-navigator.com") {
    final = "https://prod2.swift-navigator.com/api";
    isDev = false;
  }

  //backup of dev2 server (works only for backoffice app right now due to model-config that only backoffice models are exposed REST)
  //TODO change the final urls because they have changed.
  if (environmentLocation === "dataserver-dev2.swift-navigator.com") {
    final = "https://dataserver-dev2.swift-navigator.com:443/api";
    isDev = true;
  } else if (environmentLocation === "dataserver-prod2.swift-navigator.com") {
    final = "https://dataserver-prod2.swift-navigator.com/api";
    isDev = false;
  } else if (environmentLocation === "dataserver-uat2.swift-navigator.com") {
    final = "https://dataserver-uat2.swift-navigator.com/api";
    isDev = false;
  }

  if (environmentLocation === "cloudnomads-dev2.swift-navigator.com") {
    final = "https://dev2.swift-navigator.com:443/api";
    isDev = true;
  } else if (environmentLocation === "cloudnomads-uat2.swift-navigator.com") {
    final = "https://uat2.swift-navigator.com/api";
    isDev = false;
  } else if (environmentLocation === "cloudnomads-prod2.swift-navigator.com") {
    final = "https://prod2.swift-navigator.com/api";
    isDev = false;
  }

  window.localStorage.setItem("isDev", isDev);

  const BASE_URL = final;
  window.localStorage.setItem("final", final);

  // console.log("window.location.hostnam", window.location.hostnam);
  // console.log("BASE_URL", BASE_URL);
  // console.log("url", url);

  return _.extend(
    {
      method: method,
      url: `${BASE_URL}/${url}`,
      params: params,
      data: data,
      headers: !options.security
        ? {
            Accept: "application/json",
            "Content-Type": contentType || "application/json",
            Authorization: `${localStorage.getItem("access_token")}`,
          }
        : {
            Accept: "application/json",
            "Content-Type": contentType || "application/json",
          },
      timeout: 120000,
    },
    options || {}
  );
};

const serverApi = async (method, url, params, data, options, contentType) => {
  loadProgressBar();

  const request = Axios(
    axiosOptions(method, url, params, data, options, contentType)
  );

  return await request
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // if (error && error.response && error.response.status === 401) {
      //   let logoutURL = null
      //   let errorURL = error.response.config.url
      //   if (errorURL.includes('localhost')) {
      //     logoutURL = 'http://localhost:7002/auth/logout'
      //   } else if (errorURL.includes('dev2')) {
      //     logoutURL = "https://dev2.swift-navigator.com/auth/logout"
      //   } else if (errorURL.includes('uat2')) {
      //     logoutURL = "https://uat2.swift-navigator.com/auth/logout"
      //   } else if (errorURL.includes('prod2')) {
      //     logoutURL = "https://prod2.swift-navigator.com/auth/logout"
      //   }

      //   localStorage.clear()
      //   setTimeout(() => {
      //     window.location.href = logoutURL
      //   }, 3000)
      // }

      throw error;
    });
};

export const getAWSWebSocketURL = () => {
  let final = null;
  let environmentLocation =
    window && window.location && window.location.hostname;
  if (
    environmentLocation === "dev2.swift-navigator.com" ||
    environmentLocation === "localhost"
  ) {
    //MIGRATION NEW AWS ACCOUNT
    final = "wss://dq6drsawh5.execute-api.eu-west-2.amazonaws.com/testing";
  } else if (environmentLocation === "prod2.swift-navigator.com") {
    final = "wss://95cd261ek7.execute-api.eu-west-2.amazonaws.com/production";
  } else if (environmentLocation === "uat2.swift-navigator.com") {
    final = "wss://syqbkfpcti.execute-api.eu-west-2.amazonaws.com/uat";
  }
  return final;
};

export { serverApi };
