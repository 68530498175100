import React from "react";
import ReactDOM from "react-dom";
import { toODataString } from "@progress/kendo-data-query";
import _ from "lodash";
import { serverApi } from "../../../networking/config";

export class Loader extends React.Component {
  lastSuccess = "";
  pending = "";

  requestDataIfNeeded = async () => {
    if (
      this.pending ||
      toODataString(this.props.dataState) === this.lastSuccess
    ) {
      return;
    }
    this.pending = toODataString(this.props.dataState);

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let params = { ...this.props.dataState };

    params["skip"] = this.props.dataState.skip;
    params["limit"] = this.props.dataState.take;
    params["where"] =
      this.props.dataState.filter !== null ? this.props.dataState.filter : {};

    if (!_.isEmpty(this.props.dataState.sort)) {
      params["order"] =
        this.props.dataState.sort[0].field +
        " " +
        this.props.dataState.sort[0].dir;
      delete params.sort;
    }

    delete params.take;
    let filterColumn =
      params && (params.filter === null || params.filter === undefined)
        ? ""
        : params.filter.filters[0] !== null
          ? params.filter.filters[0].field
          : "";

    let filterValue =
      params && (params.filter === null || params.filter === undefined)
        ? ""
        : params.filter.filters[0] !== null
          ? params.filter.filters[0].value
          : "";

    // let finalFilterValue = null;
    // if (params.filter) {
    //   finalFilterValue = (params.filter.filters[0].field === 'reqPriorityID')
    //     ? _.find([], ['lov_value', `${filterValue}`]).id
    //     : filterValue;
    // } else {
    //   finalFilterValue = '';
    // }

    let whereFinal = [];
    // const includeParam = !_.isEmpty(this.state[domain].includeArray) ? this.state[domain].includeArray : '';

    let booleanColumns = [
      "supplier_flag",
      "client_flag",
      "transport_flag",
      "agent_flag",
      ` + sm_agent_flag`,
      "sm_vendor_flag",
      "location_flag",
    ];

    if (params.filter === null || params.filter === undefined) {
      // whereFinal.push({})
    } else {
      if (params.filter.filters.length > 1) {
        _.forEach(params.filter.filters, temp => {
          //fixes the issue with & when searching...fucking loopback 3
          let finalValue = temp.value;
          if (
            (typeof finalValue === "string" || finalValue instanceof String) &&
            finalValue.includes("&")
          ) {
            finalValue = temp.value.replace("&", ".");
          } else if (typeof finalValue === "boolean") {
            finalValue = temp.value;
          }

          if (booleanColumns.includes(temp.field)) {
            whereFinal.push({ [`${temp.field}`]: `${finalValue}` });
          } else {
            whereFinal.push({
              [`${temp.field}`]: {
                regexp: `/^${finalValue}/i`,
              },
            });
          }
        });
      } else {
        let isFirstLoad = window.localStorage.getItem("isFirstLoad");
        if (booleanColumns.includes(filterColumn) && isFirstLoad === "true") {
          whereFinal = [
            {
              [`${filterColumn}`]: `${filterValue}`,
            },
          ];
        } else {
          //fix the issue with & when searching...fucking loopback 3
          let finalValue = filterValue;
          if (finalValue.includes("&")) {
            finalValue = filterValue.replace("&", ".");
          }

          if (whereFinal.length === 1) {
            whereFinal.push({
              [`${filterColumn}`]: {
                regexp: `/^${finalValue}/i`,
              },
            });
          } else {
            whereFinal.push({
              [`${filterColumn}`]: {
                regexp: `/^${finalValue}/i`,
              },
            });
          }
        }
      }
    }

    let params2 = {
      filter: {
        include: `${this.props.include}` || [],
        // fields: [],
        where: {
          and: whereFinal,
        },
        order: [`${params.order}` !== [] ? `${params.order}` : []],
        limit: `${params.limit}`,
        skip: `${params.skip}`,
      },
    };

    if (params2.filter.order[0] === "undefined") {
      delete params2.filter.order;
    }

    if (params2.filter.where.and && params2.filter.where.and.length === 1) {
      params2.filter.where = whereFinal[0];
    }

    let finalParams = { ...params2 };

    let responseData;
    if (this.props.getURL.includes("filter")) {
      window.localStorage.setItem("refreshParams", JSON.stringify({}));
      responseData = await serverApi("GET", "wsConnections", {}, "", options);
    } else {
      window.localStorage.setItem(
        "exportFilter",
        JSON.stringify(finalParams.filter.where),
      );
      window.localStorage.setItem("refreshParams", JSON.stringify(finalParams));
      responseData = await serverApi(
        "GET",
        `wsConnections`,
        finalParams,
        "",
        options,
      );
    }

    this.lastSuccess = this.pending;
    this.pending = "";

    if (toODataString(this.props.dataState) === this.lastSuccess) {
      if (window.localStorage.getItem("finalParams")) {
        if (
          JSON.stringify(
            JSON.parse(window.localStorage.getItem("finalParams")).filter,
          ) !== ""
        ) {
          let final = JSON.parse(window.localStorage.getItem("finalParams"))
            .filter;

          responseData.data = responseData.data.map(x => ({
            ...x,
            roles: (x.roles || []).map(x => x.name).join(", "),
          }));
          function findOcc(arr, key) {
            let arr2 = [];
            arr.forEach(x => {
              // Checking if there is any object in arr2
              // which contains the key value
              if (
                arr2.some(val => {
                  return val[key] === x[key];
                })
              ) {
                // If yes! then increase the occurrence by 1
                arr2.forEach(k => {
                  if (k[key] === x[key]) {
                    k["occurrence"]++;
                  }
                });
              } else {
                // If not! Then create a new object initialize
                // it with the present iteration key's value and
                // set the occurrence to 1
                let a = {};
                a[key] = x[key];
                a["occurrence"] = 1;
                arr2.push(a);
              }
            });
            return arr2;
          }
          let key = "id";
          let final2 = findOcc(responseData.data, key);

          const temp = responseData.data.map(item => {
            return [item[key], item];
          });
          let uniqueUsers = [...new Map(temp).values()];
          let finalData = [];
          final2.forEach(occ => {
            (uniqueUsers || []).forEach(dt => {
              if (occ.id === dt.id) {
                finalData.push({
                  ...dt,
                  connections: occ.occurrence,
                });
              }
            });
          });

          this.props.onDataRecieved.call(
            undefined,
            {
              data: responseData.data,
              total:
                responseData.data === undefined ? 0 : responseData.data.length,
            },
            final,
          );
        }
      } else {
        responseData.data = responseData.data.map(x => ({
          ...x,
          roles: (x.roles || []).map(x => x.name).join(", "),
        }));
        function findOcc(arr, key) {
          let arr2 = [];
          arr.forEach(x => {
            // Checking if there is any object in arr2
            // which contains the key value
            if (
              arr2.some(val => {
                return val[key] === x[key];
              })
            ) {
              // If yes! then increase the occurrence by 1
              arr2.forEach(k => {
                if (k[key] === x[key]) {
                  k["occurrence"]++;
                }
              });
            } else {
              // If not! Then create a new object initialize
              // it with the present iteration key's value and
              // set the occurrence to 1
              let a = {};
              a[key] = x[key];
              a["occurrence"] = 1;
              arr2.push(a);
            }
          });
          return arr2;
        }
        let key = "id";

        let final = findOcc(responseData.data, key);
        const temp = responseData.data.map(item => {
          return [item[key], item];
        });
        let uniqueUsers = [...new Map(temp).values()];
        let finalData = [];
        final.forEach(occ => {
          (uniqueUsers || []).forEach(dt => {
            if (occ.id === dt.id) {
              finalData.push({
                ...dt,
                connections: occ.occurrence,
              });
            }
          });
        });

        this.props.onDataRecieved.call(undefined, {
          data: responseData.data,
          total: responseData.data === undefined ? 0 : responseData.data.length,
        });
      }
    } else {
      this.requestDataIfNeeded();
    }
  };

  render() {
    this.requestDataIfNeeded();

    return this.pending && <LoadingPanel />;
  }
}

class LoadingPanel extends React.Component {
  render() {
    const loadingPanel = (
      <div className="k-loading-mask">
        <span className="k-loading-text">Loading . . .</span>
        <div className="k-loading-image" />
        <div className="k-loading-color" />
      </div>
    );

    const gridContent = document && document.querySelector(".k-grid-content");
    return gridContent
      ? ReactDOM.createPortal(loadingPanel, gridContent)
      : loadingPanel;
  }
}
