import React from "react";
import {
  Grid,
  GridColumn as Column,
  GridDetailRow,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { serverApi } from "../../../../../networking/config";
import _ from "lodash";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import BlockUi from "react-block-ui";
import { MyCommandCell } from "../../../../Clients/View/components/CellHOC";
import GridContainer from "../../../../../components/Grid/GridContainer";
import { IconButton, Tooltip } from "@material-ui/core";
import ViewSettings from "../../../../common/ViewSettings";
import { Window } from "@progress/kendo-react-dialogs";
import { Error } from "@progress/kendo-react-labels";
import { columnsSchema } from "../../../../Roles/View/schema";
import { DropDownCell } from "../../../../CRM/View/dropDownCell";
import RegularButton from "../../../../../components/CustomButtons/Button";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDatesOnTable } from "../../../../../lib/GeneralUtils/utils";

export default class RolesOfUser extends GridDetailRow {
  editField = "inEdit";
  CommandCell;
  _export;

  constructor(props) {
    super(props);

    this.state = {
      blockingMessage: "Loading, please wait. . .",
      visibleWindow: false,
      isDropOpened: false,
      columns: (columnsSchema && columnsSchema()) || [],
      gridData: {
        data: [],
        total: 10,
      },
      initialGridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 10,
        skip: 0,
      },
      openErrorModal: false,
      errorModalContent: "",
      delay: 400,
      loading: false,
      usernames: [],
      windowError: false,
      windowErrorMessage: "",
      disableEditButton: true,
      inputValue: "",
      clientAttachedToAnotherGroup: null,
      exportData: [],
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      disableEditButton: false,
      showSecondAction: true,
      showFirstAction: false,
    });
  }

  async componentDidMount() {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let userID = this.props.dataItem.id;
    let fil = {
      filter: {
        where: {
          principalId: userID,
        },
        include: [
          {
            relation: "role",
          },
        ],
      },
    };

    let attachedUsersToRole = [];
    let usersOfRole = await serverApi("GET", `roleMappings`, fil, "", options);
    let all_users = _.cloneDeep(usersOfRole);
    usersOfRole = usersOfRole.data;
    usersOfRole.forEach((x) => {
      if (x?.role) {
        if (x.principalId === this.props.dataItem.id.toString()) {
          attachedUsersToRole.push(x);
        }
      }
    });
    let allUsers = all_users;
    let usernames =
      allUsers.data
        .sort((a, b) => (a.username > b.username ? 1 : -1))
        .map((x) => x.username) || [];

    let finalData = [];
    const formattedData = formatDatesOnTable(
      attachedUsersToRole
        .map((x) => ({
          ...x,
          ...x.role,
        }))
        .flat(),
      ["created", "modified"]
    );

    if (formattedData && formattedData.data.length > 1) {
      _.forEach(formattedData.data, (temp) => {
        finalData.push({
          id: temp.roleId,
          name: temp.role.name || "N/A",
          description: temp.role.description || "N/A",
          modified: temp.modified || "N/A",
          created: temp.created || "N/A",
        });
      });
      finalData.sort((a, b) => (a.name > b.name ? 1 : -1));

      this.setState({
        ...this.state,
        userID: this.props.dataItem.id,
        gridData: {
          data: finalData,
          total: finalData.length,
        },
        initialGridData: {
          data: finalData,
          total: finalData.length,
        },
        user_names: usernames,
        valueUserName: "",

        // visibleClients: result[0].finalUsers || [],
        // finalUserClients: result[0].finalUsers || [],
        // initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    } else if (formattedData && formattedData.data.length === 1) {
      _.forEach(formattedData.data, (temp) => {
        finalData.push({
          id: temp.roleId,
          name: temp.role.name || "N/A",
          description: temp.role.description || "N/A",
          modified: temp.modified || "N/A",
          created: temp.created || "N/A",
        });
      });
      finalData.sort((a, b) => (a.name > b.name ? 1 : -1));

      this.setState({
        ...this.state,
        userID: this.props.dataItem.id,
        gridData: {
          data: [finalData[0]],
          total: 1,
        },
        initialGridData: {
          data: [finalData[0]],
          total: 1,
        },
        user_names: usernames,
        valueUserName: "",
        // visibleClients: result[0].finalUsers || [],
        // finalUserClients: result[0].finalUsers || [],
        // initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    } else {
      this.setState({
        ...this.state,
        userID: this.props.dataItem.id,
        gridData: {
          data: [],
          total: 0,
        },
        initialGridData: {
          data: [],
          total: 0,
        },
        user_names: usernames,
        valueUserName: "",
        // visibleClients: result[0].finalUsers || [],
        // finalUserClients: result[0].finalUsers || [],
        // initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    }
  }

  constructUsersAndExistingUsersArray = (allRoles, attachedRoles, userID) => {
    let tempArr = _.cloneDeep(attachedRoles);
    let roleIDs = tempArr.map((x) => x.roleId);
    let finalRoles = [];
    _.forEach(allRoles, (temp1) => {
      finalRoles.push({
        name: temp1.name || "N/A",
        id: temp1.id,
        checked: false,
        userID: userID,
        roleIDs: roleIDs,
      });
    });

    let finalAttachedRoles = [];
    _.forEach(attachedRoles, (temp2) => {
      const userID = parseInt(temp2.principalId);
      let roleIDs = [];
      roleIDs.push(temp2.roleId);
      finalAttachedRoles.push({
        name: temp2.role.name || "N/A",
        checked: true,
        deleteID: temp2.id,
        id: temp2.roleId,
        userID: userID,
        roleIDs: roleIDs,
      });
    });

    finalRoles.sort((a, b) => (a.name > b.name ? 1 : -1));
    finalAttachedRoles.sort((a, b) => (a.name > b.name ? 1 : -1));

    return [
      {
        finalUsers: finalRoles,
        finalAttachedUsers: finalAttachedRoles,
      },
    ];
  };

  renderGridColumns = (dataItem, changeTheCellComponent) => {
    let selectionCol = [];
    let normalCols = [];
    let finalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            width="70px"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.isFilterBoolean === "yes") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"420"}
              cell={DropDownCell}
            />
          );
        } else {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={temp.field === "id" || temp.editable ? false : true} //id not editable on edit
            />
          );
        }
      });

    finalCols = [...selectionCol, ...normalCols];
    return finalCols;
  };

  remove = async (dataItem) => {
    //new endpoint snUsers/rolesBulkUpdate
    // const data = [...this.state.gridData.data];
    // let index = data.findIndex(
    //   (p) => p === dataItem || (dataItem.id && p.id === dataItem.id)
    // );
    // if (index >= 0) {
    //   data.splice(index, 1);
    // }
    // let options = {
    //   token: window.localStorage.getItem("access_token"),
    // };
    // try {
    //   this.setState({visibleWindow: false, blocking: true});
    //   let userID = this.props.dataItem.id;
    //   let fil = {
    //     filter: {
    //       where: {
    //         principalId: userID,
    //       },
    //       include: [
    //         {
    //           relation: "role",
    //         },
    //       ],
    //     },
    //   };
    //   let usersOfRole = serverApi("GET", `roleMappings`, fil, "", options);
    //   usersOfRole
    //     .then((response) => {
    //       let deleteID = response.data.filter(x => x.roleId === dataItem.id)[0].id
    //       let removeUserFromRole = serverApi(
    //         "DELETE",
    //         `roleMappings/${deleteID}`,
    //         "",
    //         "",
    //         options,
    //         ""
    //       );
    //       removeUserFromRole
    //         .then(async (deleteResponse) => {
    //           let userID = this.props.dataItem.id;
    //           let fil = {
    //             filter: {
    //               where: {
    //                 principalId: userID,
    //               },
    //               include: [
    //                 {
    //                   relation: "role",
    //                 },
    //               ],
    //             },
    //           };
    //           let attachedUsersToRole = [];
    //           let usersOfRole = await serverApi("GET", `roleMappings`, fil, "", options);
    //           let all_users = _.cloneDeep(usersOfRole);
    //           usersOfRole = usersOfRole.data;
    //           usersOfRole.filter((x) => {
    //             if (x?.role) {
    //               if (x.principalId === this.props.dataItem.id.toString()) {
    //                 attachedUsersToRole.push(x);
    //               }
    //             }
    //           });
    //           let allUsers = all_users;
    //           let usernames =
    //             allUsers.data
    //               .sort((a, b) => (a.username > b.username ? 1 : -1))
    //               .map((x) => x.username) || [];
    //           let result = this.constructUsersAndExistingUsersArray(
    //             allUsers.data,
    //             attachedUsersToRole
    //           );
    //           result[0].finalAttachedUsers.forEach((item) => {
    //             let user = result[0].finalUsers.find((x) => x.id === item.id);
    //             if (user) {
    //               user.checked = true;
    //             }
    //           });
    //           let finalData = [];
    //           _.forEach(attachedUsersToRole, (temp) => {
    //             finalData.push({
    //               id: temp.roleId,
    //               name: temp.role.name || 'N/A',
    //               description: temp.role.description || 'N/A',
    //               modified: temp.role.modified || 'N/A',
    //               created: temp.role.created || 'N/A',
    //             });
    //           });
    //           finalData.sort((a, b) => (a.name > b.name ? 1 : -1));
    //           this.setState({
    //             ...this.state,
    //             userID: this.props.dataItem.id,
    //             gridData: {
    //               data: finalData,
    //               total: finalData.length,
    //             },
    //             initialGridData: {
    //               data: finalData,
    //               total: finalData.length,
    //             },
    //             user_names: usernames,
    //             valueUserName: "",
    //             visibleClients: result[0].finalUsers || [],
    //             finalUserClients: result[0].finalUsers || [],
    //             initialFinalUserClients: result[0].finalUsers || [],
    //             blocking: false,
    //           });
    //         })
    //         .catch((error) => {
    //           this.setState({
    //             ...this.state,
    //             openErrorModal: true,
    //             errorModalContent:
    //               error?.response?.data?.error?.message || "Please try again",
    //             blocking: false,
    //           });
    //         });
    //     })
    //     .catch((error) => {
    //       this.setState({
    //         ...this.state,
    //         openErrorModal: true,
    //         errorModalContent:
    //           error?.response?.data?.error?.message || "Please try again",
    //         blocking: false,
    //       });
    //     });
    // } catch (error) {
    //   this.setState({
    //     ...this.state,
    //     openErrorModal: true,
    //     errorModalContent:
    //       error?.response?.data?.error?.message || "Please try again",
    //     blocking: false,
    //   });
    // }
  };

  getExportData = async () => {
    await this.exportToCsv(this.state, this.props);
  };

  exportToCsv = async (state, props) => {
    const count = state.gridData.total;
    const numberOfCalls = Math.min(
      Math.round(count / 1000) + 1,
      5 // Limit to 5000
    );

    const promises = [];
    for (let i = 0; i < numberOfCalls; i++) {
      promises.push(
        this.getPromise({
          skip: i * 1000,
          limit: 1000,
          order: ["id asc"],
        })
      );
    }

    try {
      const responses = await Promise.all(promises);
      let allData = [];
      responses.forEach((resp) => {
        let finalData = [];
        _.forEach(resp, (temp) => {
          finalData.push({
            id: temp.roleId,
            name: temp.role.name || "N/A",
            description: temp.role.description || "N/A",
            modified: temp.role.modified || "N/A",
            created: temp.role.created || "N/A",
          });
        });
        finalData.sort((a, b) => (a.name > b.name ? 1 : -1));
        allData = [...allData, ...finalData];
      });
      if (allData.length > 0) {
        this.setState({
          ...this.state,
          exportData: allData,
        });
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent:
          (e.response.data && e.response.data.error.message) ||
          "Please try again",
      });
    }
  };

  getPromise = () => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    return new Promise((resolve, reject) => {
      let userID = this.props.dataItem.id;
      let fil = {
        filter: {
          where: {
            principalId: userID,
          },
          include: [
            {
              relation: "role",
            },
          ],
        },
      };

      let attachedUsersToRoles = [];
      let usersOfRole = serverApi("GET", `roleMappings`, fil, "", options);
      usersOfRole
        .then((response) => {
          let all_users = _.cloneDeep(response);
          usersOfRole = all_users.data;
          usersOfRole.forEach((x) => {
            if (x?.role) {
              if (x.principalId === this.props.dataItem.id.toString()) {
                attachedUsersToRoles.push(x);
              }
            }
          });
          resolve(attachedUsersToRoles);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  toggleWindow = async (e) => {
    const options = { token: window.localStorage.getItem("access_token") };
    this.setState({ ...this.state, blocking: true });

    let userID = this.props.dataItem.id;
    let fil = {
      filter: {
        where: {
          principalId: userID.toString(),
        },
        include: [
          {
            relation: "role",
          },
        ],
      },
    };

    let attachedUsersToRole = [];
    let usersOfRole = await serverApi("GET", `roleMappings`, fil, "", options);
    let allRoles = await serverApi("GET", "roles", "", "", options);
    let all_users = _.cloneDeep(usersOfRole);
    let allUsers = all_users;

    usersOfRole = usersOfRole.data;
    usersOfRole.forEach((x) => {
      if (x?.role) {
        if (x.principalId === this.props.dataItem.id.toString()) {
          attachedUsersToRole.push(x);
        }
      }
    });

    let usernames =
      allUsers.data
        .sort((a, b) => (a.username > b.username ? 1 : -1))
        .map((x) => x.username) || [];

    let result = this.constructUsersAndExistingUsersArray(
      allRoles.data,
      attachedUsersToRole,
      userID
    );
    result[0].finalAttachedUsers.forEach((item) => {
      let user = result[0].finalUsers.find((x) => x.id === item.id);
      if (user) {
        user.checked = true;
      }
    });

    if (attachedUsersToRole && attachedUsersToRole.length > 1) {
      let finalData = [];
      _.forEach(attachedUsersToRole, (temp) => {
        finalData.push({
          id: temp.roleId,
          name: temp.role.name || "N/A",
          description: temp.role.description || "N/A",
          modified: temp.role.modified || "N/A",
          created: temp.role.created || "N/A",
        });
      });
      finalData.sort((a, b) => (a.name > b.name ? 1 : -1));

      this.setState({
        ...this.state,
        userID: this.props.dataItem.id,
        visibleWindow: !this.state.visibleWindow,
        gridData: {
          data: finalData,
          total: finalData.length,
        },
        initialGridData: {
          data: finalData,
          total: finalData.length,
        },
        user_names: usernames,
        valueUserName: "",

        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    } else if (attachedUsersToRole && attachedUsersToRole.length === 1) {
      let finalData = [];
      _.forEach(attachedUsersToRole, (temp) => {
        finalData.push({
          id: temp.roleId,
          name: temp.role.name || "N/A",
          description: temp.role.description || "N/A",
          modified: temp.role.modified || "N/A",
          created: temp.role.created || "N/A",
        });
      });
      finalData.sort((a, b) => (a.name > b.name ? 1 : -1));

      this.setState({
        ...this.state,
        userID: this.props.dataItem.id,
        visibleWindow: !this.state.visibleWindow,
        gridData: {
          data: [finalData[0]],
          total: 1,
        },
        initialGridData: {
          data: [finalData[0]],
          total: 1,
        },
        user_names: usernames,
        valueUserName: "",
        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    } else {
      this.setState({
        ...this.state,
        userID: this.props.dataItem.id,
        visibleWindow: !this.state.visibleWindow,
        gridData: {
          data: [],
          total: 0,
        },
        initialGridData: {
          data: [],
          total: 0,
        },
        user_names: usernames,
        valueUserName: "",
        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    }
  };

  renderList = (finalUsers = [], users = [], doRefresh) => {
    if (doRefresh === true) {
      window.location.reload();
    } else {
      if (finalUsers.length === users.length) {
        return (
          users &&
          users.map((item) => (
            <ul style={{ marginBottom: "-5px" }} key={item.id}>
              <Checkbox
                name={item.name}
                key={item.id}
                value={item.checked}
                onChange={(e) => this.handleChange(e, item.name, item.id)}
                label={item.name}
                disabled={this.state.disableAllCheckboxes}
              />
            </ul>
          ))
        );
      } else {
        return (
          finalUsers &&
          finalUsers.map((item) => (
            <ul style={{ marginBottom: "-5px" }} key={item.id}>
              <Checkbox
                name={item.name}
                key={item.id}
                value={item.checked}
                onChange={(e) => this.handleChange(e, item.name, item.id)}
                label={item.name}
                disabled={this.state.disableAllCheckboxes}
              />
            </ul>
          ))
        );
      }
    }
  };

  closeWindow = () => {
    this.setState({
      ...this.state,
      visibleWindow: false,
      inputValue: "",
    });
  };

  handleChangeOLD = (event, name, key, state) => {
    let finalUserClient = [...this.state.finalUserClients];
    let finalObj = finalUserClient.find((temp) => temp.id === key);

    let id = finalObj.id;
    finalObj.checked = !finalObj.checked;

    const roleID = JSON.parse(id);
    const userID = this.props.dataItem.id;

    this.setState(
      {
        finalUserClients: finalUserClient,
      },
      (key) => {
        let options = {
          quotationApplication: false,
          token: window.localStorage.getItem("access_token"),
        };
        let putOrDelete = event.value === false ? "DELETE" : "POST";

        if (putOrDelete === "POST") {
          const url = `roleMappings`;

          let payload = {
            principalType: "USER",
            principalId: `${userID}`,
            roleId: roleID,
          };

          this.setState({ blocking: true });
          const postRequest = serverApi(
            "POST",
            url,
            "",
            payload,
            options,
            "",
            ""
          );
          postRequest
            .then(async (response) => {
              let userID = this.props.dataItem.id;
              let fil = {
                filter: {
                  where: {
                    principalId: userID,
                  },
                  include: [
                    {
                      relation: "role",
                    },
                  ],
                },
              };

              let attachedUsersToRole = [];
              let usersOfRole = await serverApi(
                "GET",
                `roleMappings`,
                fil,
                "",
                options
              );
              // let all_users = _.cloneDeep(usersOfRole);
              usersOfRole = usersOfRole.data;
              usersOfRole.forEach((x) => {
                if (x?.role) {
                  if (x.principalId === this.props.dataItem.id.toString()) {
                    attachedUsersToRole.push(x);
                  }
                }
              });
              // let allUsers = all_users;
              // let usernames =
              //   allUsers.data
              //     .sort((a, b) => (a.username > b.username ? 1 : -1))
              //     .map((x) => x.username) || [];

              let finalData = [];
              _.forEach(attachedUsersToRole, (temp) => {
                finalData.push({
                  id: temp.roleId,
                  name: temp.role.name || "N/A",
                  description: temp.role.description || "N/A",
                  modified: temp.role.modified || "N/A",
                  created: temp.role.created || "N/A",
                });
              });
              finalData.sort((a, b) => (a.name > b.name ? 1 : -1));

              this.setState({
                ...this.state,
                gridData: {
                  data: finalData,
                  total: finalData.length,
                },
                initialGridData: {
                  data: finalData,
                  total: finalData.length,
                },
                blocking: false,
              });
            })
            .catch((error) => {
              this.setState({
                ...this.state,
                openErrorModal: true,
                errorModalContent:
                  (error &&
                    error.response.data &&
                    error.response.data.error.message) ||
                  "Please try again",
                blocking: false,
              });
            });
        } else {
          this.setState({ blocking: true });

          let userID = this.props.dataItem.id;
          let fil = {
            filter: {
              where: {
                principalId: userID,
              },
              include: [
                {
                  relation: "role",
                },
              ],
            },
          };

          let usersOfRole = serverApi("GET", `roleMappings`, fil, "", options);
          usersOfRole
            .then((response) => {
              let deleteID = response.data.filter((x) => x.roleId === roleID)[0]
                .id;

              let removeUserFromRole = serverApi(
                "DELETE",
                `roleMappings/${deleteID}`,
                "",
                "",
                options,
                ""
              );
              removeUserFromRole
                .then(async (deleteResponse) => {
                  let userID = this.props.dataItem.id;
                  let fil = {
                    filter: {
                      where: {
                        principalId: userID,
                      },
                      include: [
                        {
                          relation: "role",
                        },
                      ],
                    },
                  };

                  let attachedUsersToRole = [];
                  let usersOfRole = await serverApi(
                    "GET",
                    `roleMappings`,
                    fil,
                    "",
                    options
                  );
                  usersOfRole = usersOfRole.data;
                  usersOfRole.forEach((x) => {
                    if (x?.role) {
                      if (x.principalId === this.props.dataItem.id.toString()) {
                        attachedUsersToRole.push(x);
                      }
                    }
                  });

                  let finalData = [];
                  _.forEach(attachedUsersToRole, (temp) => {
                    finalData.push({
                      id: temp.roleId,
                      name: temp.role.name || "N/A",
                      description: temp.role.description || "N/A",
                      modified: temp.role.modified || "N/A",
                      created: temp.role.created || "N/A",
                    });
                  });
                  finalData.sort((a, b) => (a.name > b.name ? 1 : -1));

                  this.setState({
                    ...this.state,
                    gridData: {
                      data: finalData,
                      total: finalData.length,
                    },
                    initialGridData: {
                      data: finalData,
                      total: finalData.length,
                    },
                    blocking: false,
                  });
                })
                .catch((error) => {
                  this.setState({
                    ...this.state,
                    openErrorModal: true,
                    errorModalContent:
                      error?.response?.data?.error?.message ||
                      "Please try again",
                    blocking: false,
                  });
                });
            })
            .catch((error) => {
              this.setState({
                ...this.state,
                openErrorModal: true,
                errorModalContent:
                  error?.response?.data?.error?.message || "Please try again",
                blocking: false,
              });
            });
        }
      }
    );
  };

  handleChange = async (event, name, key) => {
    let finalUserClient = [...this.state.finalUserClients];
    let finalObj = finalUserClient.find((temp) => temp.id === key);

    let action = event.value === false ? "DELETE" : "POST";
    if (action === "POST") {
      finalObj.roleIDs.push(key);
    } else if (action === "DELETE") {
      let index = finalObj.roleIDs.indexOf(key);
      if (index !== -1) {
        finalObj.roleIDs.splice(index, 1);
      }
    }

    finalObj.checked = !finalObj.checked;

    this.setState(
      {
        ...this.state,
        finalUserClients: finalUserClient,
      },
      async () => {}
    );
  };

  cancel = (dataItem = {}) => {};

  discard = (dataItem, hasError = false) => {};

  update = async (dataItem) => {};

  itemChange = (event) => {};

  handleSearch = (e) => {
    let initialFinalUserClients = this.state.initialFinalUserClients;

    if (e.value === "") {
      let searchArr = [];
      initialFinalUserClients.forEach((temp) => {
        if (temp.name.toUpperCase().includes(e.value.toUpperCase())) {
          searchArr.push(temp);
        }
      });

      if (
        this.state.clientAttachedToAnotherGroup !== null &&
        this.state.clientAttachedToAnotherGroup !== undefined
      ) {
        let newArr = searchArr.filter(
          (t) => t.id !== this.state.clientAttachedToAnotherGroup
        );
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalUserClients: newArr,
          openErrorModal: false,
          errorModalContent: "",
        });
      } else {
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalUserClients: initialFinalUserClients,
          openErrorModal: false,
          errorModalContent: "",
        });
      }
    } else {
      let searchArr = [];
      //case insensitive
      initialFinalUserClients.forEach((temp) => {
        if (temp && temp.name.toUpperCase().includes(e.value.toUpperCase())) {
          searchArr.push(temp);
        }
      });

      if (
        this.state.clientAttachedToAnotherGroup !== null &&
        this.state.clientAttachedToAnotherGroup !== undefined
      ) {
        let newArr = searchArr.filter(
          (t) => t.id !== this.state.clientAttachedToAnotherGroup
        );
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalUserClients: newArr,
          openErrorModal: false,
          errorModalContent: "",
        });
      } else {
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalUserClients: searchArr,
          openErrorModal: false,
          errorModalContent: "",
        });
      }
    }
  };

  saveNewRoles = async () => {
    const finalUsers = [
      ...this.state.finalUserClients.filter((x) => x.checked === true),
    ];
    const initialUsers = this.state.initialFinalUserClients.filter(
      (x) => x.checked
    );
    const final = [...finalUsers, ...initialUsers];

    const uniqueData = final.filter(
      (thing, index, self) => index === self.findIndex((t) => t.id === thing.id)
    );

    const clonedUsers = _.cloneDeep(uniqueData);
    let roleIDS = [];
    roleIDS = clonedUsers.map((x) => x.id);

    //check for at least one role for the user
    if (roleIDS.length === 0) {
      this.setState(
        {
          ...this.state,
          errorModalContent:
            "At least one role is required for the process to continue.",
          openErrorModal: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              ...this.state,
              errorModalContent: "",
              openErrorModal: false,
            });
          }, 4000);
        }
      );
      return;
    }

    let payload = {
      data: [],
    };
    payload.data.push({
      userID: this.props.dataItem.id,
      roleIDs: roleIDS,
    });

    this.setState({
      ...this.state,
      blocking: true,
      blockingMessage: "Please wait a few seconds...",
    });
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    const url = `/snUsers/roles/bulkUpdate`;

    try {
      await serverApi("POST", url, "", payload, options, "", "");
      await this.fetchLatestData();

      this.setState({
        ...this.state,
        blocking: false,
        blockingMessage: "",
        inputValue: "",
      });
    } catch (error) {}
  };

  fetchLatestData = async () => {
    const options = { token: window.localStorage.getItem("access_token") };
    this.setState({ ...this.state, blocking: true });

    let userID = this.props.dataItem.id;
    let fil = {
      filter: {
        where: {
          principalId: userID.toString(),
        },
        include: [
          {
            relation: "role",
          },
        ],
      },
    };

    let attachedUsersToRole = [];
    let usersOfRole = await serverApi("GET", `roleMappings`, fil, "", options);
    let allRoles = await serverApi("GET", "roles", "", "", options);
    let all_users = _.cloneDeep(usersOfRole);
    let allUsers = all_users;

    usersOfRole = usersOfRole.data;
    usersOfRole.forEach((x) => {
      if (x?.role) {
        if (x.principalId === this.props.dataItem.id.toString()) {
          attachedUsersToRole.push(x);
        }
      }
    });

    let usernames =
      allUsers.data
        .sort((a, b) => (a.username > b.username ? 1 : -1))
        .map((x) => x.username) || [];

    let result = this.constructUsersAndExistingUsersArray(
      allRoles.data,
      attachedUsersToRole,
      userID
    );
    result[0].finalAttachedUsers.forEach((item) => {
      let user = result[0].finalUsers.find((x) => x.id === item.id);
      if (user) {
        user.checked = true;
      }
    });

    if (attachedUsersToRole && attachedUsersToRole.length > 1) {
      let finalData = [];
      _.forEach(attachedUsersToRole, (temp) => {
        finalData.push({
          id: temp.roleId,
          name: temp.role.name || "N/A",
          description: temp.role.description || "N/A",
          modified: temp.role.modified || "N/A",
          created: temp.role.created || "N/A",
        });
      });
      finalData.sort((a, b) => (a.name > b.name ? 1 : -1));

      this.setState({
        ...this.state,
        userID: this.props.dataItem.id,
        gridData: {
          data: finalData,
          total: finalData.length,
        },
        initialGridData: {
          data: finalData,
          total: finalData.length,
        },
        user_names: usernames,
        valueUserName: "",

        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    } else if (attachedUsersToRole && attachedUsersToRole.length === 1) {
      let finalData = [];
      _.forEach(attachedUsersToRole, (temp) => {
        finalData.push({
          id: temp.roleId,
          name: temp.role.name || "N/A",
          description: temp.role.description || "N/A",
          modified: temp.role.modified || "N/A",
          created: temp.role.created || "N/A",
        });
      });
      finalData.sort((a, b) => (a.name > b.name ? 1 : -1));

      this.setState({
        ...this.state,
        userID: this.props.dataItem.id,
        gridData: {
          data: [finalData[0]],
          total: 1,
        },
        initialGridData: {
          data: [finalData[0]],
          total: 1,
        },
        user_names: usernames,
        valueUserName: "",
        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    } else {
      this.setState({
        ...this.state,
        userID: this.props.dataItem.id,
        gridData: {
          data: [],
          total: 0,
        },
        initialGridData: {
          data: [],
          total: 0,
        },
        user_names: usernames,
        valueUserName: "",
        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    }
  };

  render() {
    const dataItem = this.props.dataItem;
    const { gridData } = this.state;
    const { inputValue } = this.state;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <div
          style={{
            width: "72%",
          }}
        >
          <h4 style={{ fontFamily: "bold", textSize: "7px" }}>
            Roles of user : {dataItem && dataItem.username}
          </h4>

          <Grid
            {...gridData}
            filterable={false}
            style={{ className: "grid-no-select" }}
            sortable={true}
            resizable
            editField={this.editField}
            onItemChange={this.itemChange}
            pageable={false}
          >
            <GridToolbar>
              <GridContainer xs={12} justify={"flex-start"} direction={"row"}>
                <Tooltip
                  justify={"flex-start"}
                  placement="top"
                  title={"Add/Remove Role"}
                >
                  <IconButton onClick={this.toggleWindow}>
                    <FontAwesomeIcon
                      color="#0D5869"
                      size="1.6x"
                      icon={faPencilAlt}
                    />
                  </IconButton>
                </Tooltip>
                <ViewSettings
                  {...this.props}
                  export={{
                    data: this.state.exportData || [],
                    fileName: `Attached_Roles_to_User_${this.props.dataItem.username}_`,
                    exportFunction: this.getExportData,
                    columns: columnsSchema(),
                  }}
                  viewSettings={{
                    type: "",
                  }}
                  // refreshSettings={true}
                  // refreshFunction={this.refreshGridDataAfterCrud}g
                />
              </GridContainer>
            </GridToolbar>

            {this.renderGridColumns(dataItem)}
          </Grid>
        </div>
        {this.state.visibleWindow && (
          <Window
            title={`Add/Remove role from user: ${this.props.dataItem.username}`}
            // style={{backgroundColor:'#0d5869'}}
            onClose={this.closeWindow}
            width={900}
            height={600}
            initialTop={10}
          >
            <div>
              <>
                <BlockUi
                  tag="div"
                  blocking={this.state.blocking}
                  message="Loading, please wait. . ."
                  renderChildren={true}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "50px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          position: "sticky",
                          top: "0px",
                          zIndex: 2,
                          backgroundColor: "ffff",
                        }}
                      >
                        <RegularButton
                          style={{ backgroundColor: "#0d5869" }}
                          roundSmall="true"
                          onClick={this.saveNewRoles}
                          size="sm"
                          // disabled={this.state.disableSaveNewDepartments || false}
                        >
                          Save changes
                        </RegularButton>
                      </div>
                      {this.state.openErrorModal ? (
                        <Error style={{ color: "red", fontSize: "15px" }}>
                          {this.state.errorModalContent || ""}
                        </Error>
                      ) : null}
                      <div
                        style={{
                          display: "flex",
                          marginLeft: "35px",
                          marginTop: "20px",
                        }}
                      >
                        <Input
                          name="users"
                          value={inputValue}
                          style={{ marginBottom: "20px" }}
                          label="Search user"
                          onChange={(e) => this.handleSearch(e)}
                          minLength={3}
                        />
                      </div>

                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {this.renderList(
                          this.state.finalUserClients,
                          this.state.visibleClients
                        )}
                      </div>
                    </div>
                  </div>
                </BlockUi>
              </>
              {this.state.windowError ? (
                <Error style={{ color: "red" }}>
                  <h3>{this.state.windowErrorMessage || ""}</h3>
                </Error>
              ) : null}
            </div>
          </Window>
        )}
      </div>
    );
  }
}
