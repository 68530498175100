import React, { Component, Fragment } from "react";
import { serverApi } from "../../networking/config";
import {
  constructCustomTableState,
  makeSchemaReadOnly,
} from "../../lib/GeneralUtils/utils";
import { push } from "../reactRouterHelpers/history";
import filter from "ramda/src/filter";
import _ from "lodash";
import { enums } from "../Enums";
import ErrorModal from "../../components/ErrorModal";
import SuccessModal from "../../components/SuccessModal";
import Button from "../../components/CustomButtons/Button";
import { buttonStyles } from "../../routes/Dispatches/stylesLib";
import GridActionsHOC from "../../containers/ContainerGrid/GridActionsHOC";
import {
  GenericCount,
  AbstractEdit,
  AbstractGetDataByID,
} from "../../networking/apiCalls/index";
import { filterBy } from "@progress/kendo-data-query";
import CustomSnackbar from "../../components/CustomSnackbar";
import Snackbar from "../../components/Snackbar/Snackbar";

import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import "loaders.css/loaders.min.css";
import { GridColumn as Column } from "@progress/kendo-react-grid/dist/npm/GridColumn";

const HandleAllHOC = ({ domain = "" }) => {
  /**
   * HandleAllHOC is a High Order Component that is used for reusing component logic/functionality.
   * HOCs are not part of the React API, they are a pattern that emerges rom React's compositional nature.
   * A HOC is a pure function with zero side-effects.
   *
   * You may have noticed similarities between HOCs and a pattern called container components.
   * Container components are part of a strategy of separating responsibility between high-level and low-level concerns.
   * Containers manage things like subscriptions and state, and pass props to components that handle things like rendering UI.
   * HOCs use containers as part of their implementation. You can think of HOCs as parameterized container component definitions.
   *
   * More specific, the HandleAllHoc here is implemented to host all the functionality of the container component that are
   * attached to this HOC.(example of containers are the Kendo ContainerGrid component).
   * Lets say we want to use a ContainerGrid container along with the HOC, so the Child in this example would be the ContainerGrid with
   * some enhancements(that's why i gave the component name such as EnhancedChild).
   * Because the Child has its own props and functionality, and when is it "attached" with the HOC it getting some more methods attached to it.
   *
   * To sum, whenever we want to use a ContainerGrid component we attach the container grid with the hoc and we have
   * all the default functionality ready
   * such as, server side filtering,sorting, paging, grid actions etc...
   */
  return (Child) => {
    class EnhancedChild extends Component {
      lastSelectedIndex = 0;

      constructor(props) {
        super(props);

        /**
         * Construct the state of the EnhancedChild depending on the Child(grid,editForm, etc...)
         * Here we set the default ContainerGrid(tableList) state and then whenever a user does some operation on the grid
         * we take the params and send a request to the server for server-side functionality.
         */

        this._isMounted = false;

        let tableListState =
          domain === "tableList"
            ? {
                blocking: false,
                openSnackbar: false,
                showPagination: this.props.showPagination,
                gridData: {
                  data: [],
                  total: 0,
                },
                total: 0,
                dataState: {
                  take: 10,
                  skip: 0,
                },
                limit: 10,
                skip: 0,
                columns:
                  (this.props.columnsSchema && this.props.columnsSchema()) ||
                  [],
                columns2:
                  (this.props.columnsSchema2 && this.props.columnsSchema2()) ||
                  [],
                columns3:
                  (this.props.columnsSchema3 && this.props.columnsSchema3()) ||
                  [],
                columns4:
                  (this.props.columnsSchema4 && this.props.columnsSchema4()) ||
                  [],
                columns5:
                  (this.props.columnsSchema5 && this.props.columnsSchema5()) ||
                  [],
                dropdownFieldName: this.props.dropdownFieldName || "",
                urlSpecific: this.props.urlSpecific,
                includeArray: this.props.includeArray || [],
                gridActions: this.props.gridActions,
                BlockUiMessage:
                  this.props.BlockUiMessage || "Loading please wait...",
              }
            : {};

        this.state = {
          [domain]: {
            ...tableListState,
          },
        };
      }

      componentWillUnmount() {
        this._isMounted = false;
        clearInterval();
      }

      /**
       * Depending on what is the domain, we use the componentDidMount React lifecycle to fetch data from the server,
       * and then we use the setState method of React API to provide the enhanced component with the new state.
       */
      componentDidMount() {
        this._isMounted = true;

        if (domain === "Edit") {
          let options = { token: window.localStorage.getItem("access_token") };

          const id = this.props.match.params.id;
          const includeArray = this.props.includeArray;
          const url = this.props.urlFetchPrefix;
          // let params = {
          //   "filter": {}
          // }
          // if (!_.isEmpty(includeArray)) {
          //   params = {
          //     "filter": {
          //       "include": includeArray,
          //     }
          //   }
          // }

          const request = AbstractGetDataByID(id, url, includeArray, options);

          request
            .then((response) => {
              this.setState({
                [domain]: {
                  ...this.state[domain],
                  editData: response.data,
                  openSnackbar: false,
                },
              });
            })
            .catch((error) => {
              this.setState({
                [domain]: {
                  ...this.state[domain],
                  openErrorModal: true,
                  errorModalContent: "Please try again to edit the form",
                },
              });
            });
        } else if (domain === "tableList") {
          //todo check user management grid auto piraxtike gia crm
          if (this.props.karfoto === "/noCall") {
          } else {
            if (
              this.props.karfoto ===
              `logTables?filter=%7B%22include%22%3A%20%22creator%22%2C%20%20%20%20%22where%22%3A%7B%20%22and%22%3A%20%5B%7B%22model_name%22%3A%20%22BOX%22%2C%20%22model_id%22%3A%20%22${
                this.props.match.params.id || ""
              }%22%7D%5D%7D%7D`
            ) {
              this.refreshGridDataAfterCrud("");
            } else {
              // let token = window.localStorage.getItem('access_token')

              this.setState({
                [domain]: {
                  ...this.state[domain],
                  blocking: true,
                },
              });

              // let finalURL = this.props.urlSpecific !== "" ? this.props.urlSpecific : this.props.karfoto
              let hasFinalParams = JSON.parse(
                window.localStorage.getItem("finalParams")
              );

              let hasSelectedItems = JSON.parse(
                window.localStorage.getItem("selectedItems")
              );

              this.dataStateChange(
                { data: hasFinalParams || { take: 10, skip: 0 } },
                true,
                hasSelectedItems
              );
            }
          }
        } else if (domain === "Create") {
          //TODO make request for fetching LOVS and extra input data from server
          // let options = {quotationApplication: false, token: window.localStorage.getItem('access_token')};

          const createFormSchema = this.props.createSchema();

          const createSchemWithoutButtons = filter(
            (s) => (s.type ? s.type !== enums.SchemaFieldsTypes.BUTTON : true),
            createFormSchema
          ).map((a) => a.name);

          let obj = createSchemWithoutButtons.reduce(
            (o, key) => Object.assign(o, { [key]: "" }),
            {}
          );

          this.setState({
            [domain]: {
              ...this.state[domain],
              createData: { ...obj },
            },
          });
        }
      }

      renderGridActions = () => {
        const GridActions = this.props.gridActions;

        return GridActionsHOC(
          GridActions,
          this.redirectToEditForm,
          this.redirectToCreateForm,
          this.stayWhereYouAre,
          this.goToAddOrderTable,
          this.addOrderToDispatch,
          this.deleteDispatchFinancial
        );
      };

      /**
       * This function constructs the columns of the grid.
       * As you can see we have columnsSchema as a prop function and then we map for each item
       * in the array to construct each Column needed by the Kendo ContainerGrid Component.
       */
      renderGridColumns = () => {
        let selectionCol = [];
        let normalCols = [];

        _.forEach(this.props.columnsSchema(), (col) => {
          if (col.field === "selected") {
            selectionCol.push([
              <Column
                field="selected"
                width="65px"
                filterable={false}
                headerSelectionValue={
                  this.state[domain].gridData.data.findIndex(
                    (dataItem) => dataItem.selected === false
                  ) === -1
                }
              />,
            ]);
          }
        });

        normalCols = this.props
          .columnsSchema()
          .filter((temp1) => temp1.visible)
          .map((temp) => {
            return (
              <Column
                field={temp.field}
                filterable={temp.filterable}
                title={temp.title}
                width={temp.minWidth || "auto"}
                filter={temp.filter}
                visible={temp.visible}
                minGridWidth={"400"}
                // cell={() =>
                //     <td>
                //         <a rel="noopener noreferrer" href="https://p.2hog.codes/" target="_blank">Link Here</a>
                //     </td>
                // }
              />
            );
          });

        return [...selectionCol, ...normalCols];
      };

      renderGridColumns2 = () => {
        let selectionCol = [];
        let normalCols = [];

        _.forEach(this.props.columnsSchema2(), (col) => {
          if (col.field === "selected") {
            selectionCol.push([
              <Column
                field="selected"
                width="65px"
                filterable={false}
                headerSelectionValue={
                  this.state[domain].gridData.data.findIndex(
                    (dataItem) => dataItem.selected === false
                  ) === -1
                }
              />,
            ]);
          }
        });

        normalCols = this.props
          .columnsSchema2()
          .filter((temp1) => temp1.visible)
          .map((temp) => {
            return (
              <Column
                field={temp.field}
                filterable={temp.filterable}
                title={temp.title}
                width={temp.minWidth || "auto"}
                filter={temp.filter}
                visible={temp.visible}
                minGridWidth={"400"}
                // cell={() =>
                //     <td>
                //         <a rel="noopener noreferrer" href="https://p.2hog.codes/" target="_blank">Link Here</a>
                //     </td>
                // }
              />
            );
          });

        return [...selectionCol, ...normalCols];
      };

      renderGridColumns3 = () => {
        let selectionCol = [];
        let normalCols = [];

        _.forEach(this.props.columnsSchema3(), (col) => {
          if (col.field === "selected") {
            selectionCol.push([
              <Column
                field="selected"
                width="65px"
                filterable={false}
                headerSelectionValue={
                  this.state[domain].gridData.data.findIndex(
                    (dataItem) => dataItem.selected === false
                  ) === -1
                }
              />,
            ]);
          }
        });

        normalCols = this.props
          .columnsSchema3()
          .filter((temp1) => temp1.visible)
          .map((temp) => {
            return (
              <Column
                field={temp.field}
                filterable={temp.filterable}
                title={temp.title}
                width={temp.minWidth || "auto"}
                filter={temp.filter}
                visible={temp.visible}
                minGridWidth={"400"}
                // cell={() =>
                //     <td>
                //         <a rel="noopener noreferrer" href="https://p.2hog.codes/" target="_blank">Link Here</a>
                //     </td>
                // }
              />
            );
          });

        return [...selectionCol, ...normalCols];
      };

      renderGridColumns4 = () => {
        let selectionCol = [];
        let normalCols = [];

        _.forEach(this.props.columnsSchema4(), (col) => {
          if (col.field === "selected") {
            selectionCol.push([
              <Column
                field="selected"
                width="65px"
                filterable={false}
                headerSelectionValue={
                  this.state[domain].gridData.data.findIndex(
                    (dataItem) => dataItem.selected === false
                  ) === -1
                }
              />,
            ]);
          }
        });

        normalCols = this.props
          .columnsSchema4()
          .filter((temp1) => temp1.visible)
          .map((temp) => {
            return (
              <Column
                field={temp.field}
                filterable={temp.filterable}
                title={temp.title}
                width={temp.minWidth || "auto"}
                filter={temp.filter}
                visible={temp.visible}
                minGridWidth={"400"}
                // cell={() =>
                //     <td>
                //         <a rel="noopener noreferrer" href="https://p.2hog.codes/" target="_blank">Link Here</a>
                //     </td>
                // }
              />
            );
          });

        return [...selectionCol, ...normalCols];
      };

      renderGridColumns5 = () => {
        let selectionCol = [];
        let normalCols = [];

        _.forEach(this.props.columnsSchema5(), (col) => {
          if (col.field === "selected") {
            selectionCol.push([
              <Column
                field="selected"
                width="65px"
                filterable={false}
                headerSelectionValue={
                  this.state[domain].gridData.data.findIndex(
                    (dataItem) => dataItem.selected === false
                  ) === -1
                }
              />,
            ]);
          }
        });

        normalCols = this.props
          .columnsSchema5()
          .filter((temp1) => temp1.visible)
          .map((temp) => {
            return (
              <Column
                field={temp.field}
                filterable={temp.filterable}
                title={temp.title}
                width={temp.minWidth || "auto"}
                filter={temp.filter}
                visible={temp.visible}
                minGridWidth={"400"}
                // cell={() =>
                //     <td>
                //         <a rel="noopener noreferrer" href="https://p.2hog.codes/" target="_blank">Link Here</a>
                //     </td>
                // }
              />
            );
          });

        return [...selectionCol, ...normalCols];
      };

      selectionChange = (event) => {
        const data = this.state[domain].gridData.data.map((item) => {
          if (item.box_id === event.dataItem.box_id) {
            item.selected = !event.dataItem.selected;

            let selectedArray =
              JSON.parse(window.localStorage.getItem("selectedItems")) || [];

            if (item.selected === false) {
              selectedArray = selectedArray.filter(
                (temp) => temp.box_id !== item.box_id
              );
              // selectedArray.push(item)
            } else if (item.selected === true) {
              selectedArray.push(item);
            }
            window.localStorage.setItem(
              "selectedItems",
              JSON.stringify(selectedArray)
            );
          }
          return item;
        });

        this.setState({
          [domain]: {
            ...this.state[domain],
            blocking: false,
            gridData: {
              data: data,
            },
          },
        });
      };

      rowClick = (event) => {
        let last = this.lastSelectedIndex;
        const data = [...this.state[domain].gridData.data];
        const current = data.findIndex(
          (dataItem) => dataItem === event.dataItem
        );

        if (!event.nativeEvent.shiftKey) {
          this.lastSelectedIndex = last = current;
        }

        if (!event.nativeEvent.ctrlKey) {
          data.forEach((item) => (item.selected = false));
        }

        const select = !event.dataItem.selected;
        for (
          let i = Math.min(last, current);
          i <= Math.max(last, current);
          i++
        ) {
          data[i].selected = select;
        }

        this.setState({
          [domain]: {
            ...this.state[domain],
            blocking: false,
            gridData: {
              data: data,
            },
          },
        });
      };

      headerSelectionChange = (event) => {
        const checked = event.syntheticEvent.target.checked;

        const data = this.state[domain].gridData.data.map((item) => {
          item.selected = checked;
          return item;
        });

        this.setState({
          [domain]: {
            ...this.state[domain],
            blocking: false,
            gridData: {
              data: data,
            },
          },
        });
      };

      /**
       * Custom function which is responsible for setting the new state whenever a user change a field in the ui(dropdown, input, etc...)
       * @param e
       */
      onChange = (event, componentType) => {
        const { name, value } = event.target;

        const onChangeDomain = this.state[domain];

        if (componentType === "kendoAutocomplete") {
          this.setState(
            {
              operators: [],
              openedOperator: false,
              valueOperator: "",
            },
            () => {
              this.onAutoCompleteChange(event, value);
            }
          );
        } else {
          if (onChangeDomain.hasOwnProperty("editData")) {
            this.setState({
              [domain]: {
                ...this.state[domain],
                editData: {
                  ...this.state[domain].editData,
                  [name]: value,
                },
                relatedToPropertiesErrors: {
                  ...this.state[domain].relatedToPropertiesErrors,
                  [name]: "",
                },
              },
            });
          } else if (onChangeDomain.hasOwnProperty("createData")) {
            this.setState({
              [domain]: {
                ...this.state[domain],
                createData: {
                  ...this.state[domain].createData,
                  [name]: value,
                },
                relatedToPropertiesErrors: {
                  ...this.state[domain].relatedToPropertiesErrors,
                  [name]: "",
                },
              },
            });
          } else {
            this.setState({
              [domain]: {
                ...this.state[domain],
                createData: {
                  ...this.state[domain].createData,
                  [name]: value,
                },
                relatedToPropertiesErrors: {
                  ...this.state[domain].relatedToPropertiesErrors,
                  [name]: "",
                },
              },
            });
          }
        }
      };

      onAutoCompleteChange = (event, value) => {
        const { name } = event.target;

        let stateData = {};

        stateData =
          value.length < 4
            ? {
                data: this.state.operators.map((temp) => temp.relation_name),
                openedOperator: false,
              }
            : { data: this.filterData(value, name), openedOperator: true };

        let eventType = event.nativeEvent.type;

        let valueSelected =
          eventType === "click" ||
          (eventType === "keydown" && event.nativeEvent.keyCode === 13);

        if (valueSelected && stateData.data.includes(value)) {
          stateData.openedOperator = false;
        }

        this.setState(
          {
            valueOperator: value,
            ...stateData,
          },
          () => this.fetchAutocompleteData(value)
        );
      };

      fetchAutocompleteData = (filterValue) => {
        let options = {
          quotationApplication: false,
          token: window.localStorage.getItem("access_token"),
        };

        let filter = {
          order: ["location_flag DESC"],
          fields: ["id", "relation_name"],
          where: {
            and: [
              {
                or: [
                  { relation_name: { like: `${filterValue}%` } },
                  { relation_location_iso_code: { like: `${filterValue}%` } },
                ],
              },
              {
                or: [
                  {
                    supplier_flag: true,
                  },
                  {
                    agent_flag: true,
                  },
                  {
                    location_flag: true,
                  },
                ],
              },
            ],
          },
        };

        const fetchDropdownData = serverApi(
          "GET",
          `https://dev.swift-navigator.com/api/relations`,
          { filter: filter },
          "",
          options
        );

        fetchDropdownData
          .then((response) => {
            console.log("66666", response);

            this.setState({
              operators: response.data.dataArray || [],
            });
          })
          .catch((error) => {
            console.log(error);

            throw error;
          });
      };

      filterData = (value, name) => {
        const data = this.state.operators.map((test) => test.relation_name);

        const filter = {
          value: value,
          operator: "startswith",
          ignoreCase: true,
        };

        return filterBy(data, filter);
      };

      /**
       * e === the event that is fired uppon each user interaction (example filter a column with a specific value)
       * Catches the event and the set the new state that would be used to make the request to the server
       * with that exact params that the users asked for.
       * Finally, we call onFetchData function as callback when set state is finished.
       * @param e
       */
      dataStateChange = (e, isFirstLoad, hasSelectedItems) => {
        const reqGridParams = e.data;
        window.localStorage.setItem(
          "finalParams",
          JSON.stringify(reqGridParams)
        );

        // if (e.data.filter && e.data.filter.filters && e.data.filter.filters[0].value.trim('').length <= 3) {
        //     this.setState({
        //         [domain]: {
        //             ...this.state[domain],
        //             dataState: reqGridParams,
        //             blocking:false
        //         }
        //     })
        //
        // }
        // else {
        let options = {
          token: window.localStorage.getItem("access_token"),
        };

        if (this.state[domain].dataState.filter) {
          let x = JSON.stringify(this.state[domain].dataState.filter);
          let t = JSON.stringify(reqGridParams.filter);

          if (x !== t) {
            const countRequest = GenericCount(this.props.urlSpecific, options);
            countRequest
              .then((countResponse) => {
                let roundedPages = countResponse.data.count;

                this.setState({
                  [domain]: {
                    ...this.state[domain],
                    total: roundedPages,
                    blocking: false,
                  },
                });
              })
              .catch((error) => {
                console.log(error);
              });
          }
        } else {
          if (window.location.href.includes("wh")) {
          } else if (window.location.href.includes("logbox")) {
          } else if (window.location.href.includes("invoicelog")) {
          } else {
            if (isFirstLoad) {
              const countRequest = GenericCount(
                this.props.urlSpecific,
                options
              );
              countRequest
                .then((countResponse) => {
                  let roundedPages = countResponse.data.count;

                  this.setState({
                    [domain]: {
                      ...this.state[domain],
                      total: roundedPages,
                      blocking: false,
                    },
                  });
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          }
        }

        this.setState(
          {
            [domain]: {
              ...this.state[domain],
              dataState: reqGridParams,
            },
          },
          () => this.onFetchData(hasSelectedItems)
        );

        // }
      };

      /**
       *  This function is responsible for constructing the correct request for
       *  fetching data from server. (Needed by loopback) Finally, we call fetchData function that
       *  would use the axios package to make the request to server.
       */
      onFetchData = (hasSelectedItems) => {
        let params = { ...this.state[domain].dataState };

        params["skip"] = this.state[domain].dataState.skip;
        params["limit"] = this.state[domain].dataState.take;
        params["where"] =
          this.state[domain].dataState.filter !== null
            ? this.state[domain].dataState.filter
            : {};

        if (!_.isEmpty(this.state[domain].dataState.sort)) {
          params["order"] =
            this.state[domain].dataState.sort[0].field +
            " " +
            this.state[domain].dataState.sort[0].dir;
          delete params.sort;
        }

        this.setState(
          {
            [domain]: {
              ...this.state[domain],
              params,
            },
          },
          () => this.fetchData(hasSelectedItems)
        );
      };

      /**
       *  The following code is used to construct the request for each grid state change ,(server side)
       *  We apply the default loopback REST API filter params that are mandatory to each request.
       *
       *  Also we make some validation that will catch all use cases for server side functionality.
       *
       *  Loopback 3 REST API Params explanation are below :
       *  fields: include/exclude specific properties from server response
       *  include: An include filter enables you to include results from related models in a query, for example models that have belongsTo or hasMany relations, to optimize the number of requests
       *  limit: A limit filter limits the number of records returned to the specified number (or less).
       *  order: An order filter specifies how to sort the results: ascending (ASC) or descending (DESC) based on the specified property.
       *  skip: A skip filter omits the specified number of returned records. This is useful, for example, to paginate responses.
       *  where: A where filter specifies a set of logical conditions to match, similar to a WHERE clause in a SQL query.
       **/
      fetchData = (hasSelectedItems) => {
        delete this.state[domain].params.take;

        let filterColumn =
          this.state[domain].params &&
          (this.state[domain].params.filter === null ||
            this.state[domain].params.filter === undefined)
            ? ""
            : this.state[domain].params.filter.filters[0] !== null
            ? this.state[domain].params.filter.filters[0].field
            : "";

        let filterValue =
          this.state[domain].params &&
          (this.state[domain].params.filter === null ||
            this.state[domain].params.filter === undefined)
            ? ""
            : this.state[domain].params.filter.filters[0] !== null
            ? this.state[domain].params.filter.filters[0].value
            : "";

        let finalFilterValue = null;

        if (this.state[domain].params.filter) {
          finalFilterValue =
            this.state[domain].params.filter.filters[0].field ===
            "reqPriorityID"
              ? _.find(this.state[domain].priorityArray, [
                  "lov_value",
                  `${filterValue}`,
                ]).id
              : filterValue;
        } else {
          finalFilterValue = "";
        }

        const includeParam = !_.isEmpty(this.state[domain].includeArray)
          ? this.state[domain].includeArray
          : "";

        let whereFinal = [];
        if (
          this.state[domain].params.filter === null ||
          this.state[domain].params.filter === undefined
        ) {
          whereFinal.push({});
        } else {
          if (this.state[domain].params.filter.filters.length > 1) {
            _.forEach(this.state[domain].params.filter.filters, (temp) => {
              whereFinal.push({ [`${temp.field}`]: temp.value });
            });
          } else {
            whereFinal = [
              {
                [`${filterColumn}`]: {
                  like: `${finalFilterValue}%`,
                },
              },
            ];
          }
        }

        let columns = this.state[domain].columns
          .filter((column) => column.visible)
          .map((column) => column.field);

        let params = {
          filter: {
            include: includeParam,
            // fields: [],
            where: {
              and: whereFinal,
            },
            order: [
              `${this.state[domain].params.order}` !== []
                ? `${this.state[domain].params.order}`
                : [],
            ],
            limit: `${this.state[domain].params.limit}`,
            skip: `${this.state[domain].params.skip}`,
          },
        };

        if (params.filter.order[0] === "undefined") {
          delete params.filter.order;
        }

        let options = {
          token: window.localStorage.getItem("access_token"),
        };

        let finalParams = { ...params, ...{ fields: columns } };

        // window.localStorage.setItem('finalParams', JSON.stringify(finalParams))

        if (this.props.karfoto !== "" && !this.props.urlSpecific) {
          delete finalParams.filter.where;
          delete finalParams.filter.limit;
          delete finalParams.filter.skip;
          // delete finalParams.filter.order
        }

        if (
          this.props.karfoto ===
          `logTables?filter=%7B%22include%22%3A%20%22creator%22%2C%20%20%20%20%22where%22%3A%7B%20%22and%22%3A%20%5B%7B%22model_name%22%3A%20%22BOX%22%2C%20%22model_id%22%3A%20%22${this.props.match.params.id}%22%7D%5D%7D%7D`
        ) {
          finalParams = {};
        }

        if (this.props.karfoto === "/noCall") {
        } else if (
          this.props.karfoto3 === `boxes/${this.props.match.params.id}`
        ) {
          if (this.props.match.params.id === ":id") {
            return;
          }

          // let filterObject = {
          //   filter: {
          //     "include": [
          //       {
          //         "relation": "inventoryChecks"
          //       },
          //       {
          //         "relation": "order",
          //         "scope": {
          //           "include": [{
          //             "relation": "logs"
          //           }]
          //         }
          //       },
          //       {
          //         "relation": "logs"
          //       }
          //     ]
          //   }
          // }

          let url3 = `boxes/${this.props.match.params.id}/detailedLogs`;
          const inventoryCheckRequest = serverApi("GET", url3, "", "", options);

          this.setState({
            [domain]: {
              ...this.state[domain],
              blocking: true,
            },
          });

          inventoryCheckRequest
            .then((invResponse) => {
              const dataToPass = _.cloneDeep(invResponse.data);
              let labelPrinted =
                invResponse.data &&
                invResponse.data.logs &&
                invResponse.data.logs &&
                invResponse.data.logs.labelPrinted;

              let finalArray1 = [];
              let finalArray2 = [];
              let finalArray3 = [];
              let final4 = [];
              let final5 = [];

              finalArray1 = constructCustomTableState(
                "log-box",
                dataToPass.logs.boxLogs || []
              );
              finalArray2 = constructCustomTableState(
                "log-box-order-table",
                dataToPass.logs.orderLogs || []
              );
              finalArray3 = constructCustomTableState(
                "inventory-checks",
                dataToPass.logs.inventoryChecks || []
              );
              final4 = constructCustomTableState(
                "orderToDispatches",
                dataToPass.logs.orderToDispatches || []
              );
              final5 = constructCustomTableState(
                "whPickingDispatchToBoxes",
                dataToPass.logs.whPicks || []
              );

              this.setState({
                [domain]: {
                  ...this.state[domain],
                  blocking: false,
                  labelPrinted: labelPrinted,
                  allGrid: {
                    gridData: {
                      data: finalArray1 || [],
                      total: this.state[domain].total,
                    },
                    gridData1: {
                      data: finalArray2 || [],
                      total: this.state[domain].total,
                    },
                    gridData2: {
                      data: finalArray3 || [],
                      total: this.state[domain].total,
                    },
                    gridData4: {
                      data: final4 || [],
                      total: this.state[domain].total,
                    },
                    gridData5: {
                      data: final5 || [],
                      total: this.state[domain].total,
                    },
                  },
                },
              });
            })
            .catch((error) => {
              console.log(error);
              let conditionError =
                error && error.response && error.response.data.error.message;

              if (conditionError === "ORA-01722: invalid number") {
                this.setState(
                  {
                    [domain]: {
                      ...this.state[domain],
                      blocking: false,
                      allGrid: {
                        gridData: {
                          data:
                            (this.state[domain].allGrid &&
                              this.state[domain].allGrid.gridData.data) ||
                            [],
                          total: this.state[domain].total,
                        },
                        gridData1: {
                          data:
                            (this.state[domain].allGrid &&
                              this.state[domain].allGrid.gridData1.data) ||
                            [],
                          total: this.state[domain].total,
                        },
                        gridData2: {
                          data: [],
                          total: this.state[domain].total,
                        },
                      },
                    },
                  },
                  () => {
                    setTimeout(() => {
                      this.handleErrorClose();
                    }, 3000);
                  }
                );
              } else {
                this.setState(
                  {
                    [domain]: {
                      ...this.state[domain],
                      blocking: false,
                      allGrid: {
                        gridData: {
                          data:
                            (this.state[domain].allGrid &&
                              this.state[domain].allGrid.gridData.data) ||
                            [],
                          total: this.state[domain].total,
                        },
                        gridData1: {
                          data:
                            (this.state[domain].allGrid &&
                              this.state[domain].allGrid.gridData1.data) ||
                            [],
                          total: this.state[domain].total,
                        },
                        gridData2: {
                          data: [],
                          total: this.state[domain].total,
                        },
                      },
                      openErrorSnackbar: true,
                      errorSnackbarContent: conditionError,
                    },
                  },
                  () => {
                    setTimeout(() => {
                      this.handleErrorClose();
                    }, 3000);
                  }
                );
              }
            });
        } else if (
          this.props.karfoto3 === `invoices/${this.props.match.params.id}`
        ) {
          if (this.props.match.params.id === ":id") {
            return;
          }

          let url3 = `invoices/${this.props.match.params.id}/invoiceLogs`;
          const inventoryCheckRequest = serverApi("GET", url3, "", "", options);

          this.setState({
            [domain]: {
              ...this.state[domain],
              blocking: true,
            },
          });

          inventoryCheckRequest
            .then((invResponse) => {
              function formatDate(dateString, locale) {
                if (dateString === null || dateString === undefined) {
                  return null;
                }

                let val = null;
                const date = new Date(dateString);
                const options = {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                };
                val = date.toLocaleDateString("el-GR" || locale, options);
                return val;
              }

              const dataToPass = _.cloneDeep(invResponse.data);
              let finalArray1 = [];
              finalArray1 = constructCustomTableState(
                "invoices",
                dataToPass.logs.invoiceLogs || []
              );

              const finalArr = {
                data: finalArray1?.map((item) => {
                  const formattedItem = { ...item };
                  ["timestamp"].forEach((field) => {
                    formattedItem[field] = formatDate(item[field]);
                  });
                  return formattedItem;
                }),
                total: finalArray1.length,
              };

              this.setState({
                [domain]: {
                  ...this.state[domain],
                  blocking: false,
                  allGrid: {
                    gridData: {
                      data: finalArr.data || [],
                      total: finalArr.total,
                    },
                  },
                },
              });
            })
            .catch((error) => {
              // console.log(error)
              let conditionError =
                error && error.response && error.response.data.error.message;

              if (conditionError === "ORA-01722: invalid number") {
                this.setState(
                  {
                    [domain]: {
                      ...this.state[domain],
                      blocking: false,
                      allGrid: {
                        gridData: {
                          data:
                            (this.state[domain].allGrid &&
                              this.state[domain].allGrid.gridData.data) ||
                            [],
                          total: this.state[domain].total,
                        },
                      },
                    },
                  },
                  () => {
                    setTimeout(() => {
                      this.handleErrorClose();
                    }, 3000);
                  }
                );
              } else {
                this.setState(
                  {
                    [domain]: {
                      ...this.state[domain],
                      blocking: false,
                      allGrid: {
                        gridData: {
                          data:
                            (this.state[domain].allGrid &&
                              this.state[domain].allGrid.gridData.data) ||
                            [],
                          total: this.state[domain].total,
                        },
                      },
                      openErrorSnackbar: true,
                      errorSnackbarContent: conditionError,
                    },
                  },
                  () => {
                    setTimeout(() => {
                      this.handleErrorClose();
                    }, 3000);
                  }
                );
              }
            });
        } else {
          this._isMounted &&
            this.setState({
              [domain]: {
                ...this.state[domain],
                blocking: true,
              },
            });

          const request = serverApi(
            "GET",
            `${
              this.props.urlSpecific !== ""
                ? this.props.urlSpecific
                : this.props.karfoto
            }`,
            finalParams,
            "",
            options
          );

          if (this.props.urlSpecific === "logBOs?&filter[include]=creator") {
            request
              .then((response) => {
                if (
                  response.data.response &&
                  response.data.response.dataArray
                ) {
                  _.forEach(
                    response.data.response.dataArray,
                    function (dataItem) {
                      if (dataItem.DG === "Y") {
                        dataItem.flag = "DG";
                      }
                      if (
                        dataItem.priority === "HIGH" ||
                        dataItem.priority === "CRITICAL"
                      ) {
                        let lastVal = "PR";
                        if (dataItem.flag !== "") {
                          dataItem.flag = `${dataItem.flag},${lastVal}`;
                        }
                      }
                    }
                  );

                  this._isMounted &&
                    this.setState({
                      [domain]: {
                        ...this.state[domain],
                        blocking: false,
                        gridData: {
                          data: response.data.response.dataArray,
                          total: response.data.response.total,
                        },
                      },
                    });
                } else if (response.data.dataArray) {
                  this._isMounted &&
                    this.setState({
                      [domain]: {
                        ...this.state[domain],
                        blocking: false,
                        gridData: {
                          data: response.data.dataArray,
                          total: response.data.total,
                        },
                      },
                    });
                } else {
                  let filterParams = JSON.parse(
                    window.localStorage.getItem("finalParams")
                  ).filter;

                  if (filterParams) {
                    this._isMounted &&
                      this.setState({
                        [domain]: {
                          ...this.state[domain],
                          blocking: false,
                          gridData: {
                            data: response.data,
                            total: response.data.length,
                          },
                        },
                      });
                  } else {
                    const newValues = response.data.map((value) => {
                      return {
                        ...value,
                        username: value?.creator?.username || "N/A",
                      };
                    });

                    this._isMounted &&
                      this.setState({
                        [domain]: {
                          ...this.state[domain],
                          blocking: false,
                          gridData: {
                            data: newValues || [],
                            total: this.state[domain].total,
                          },
                        },
                      });
                  }
                }
              })
              .catch((error) => {
                this.setState({
                  [domain]: {
                    ...this.state[domain],
                    openErrorModal: true,
                    errorModalContent: `Please refresh and if persist contact the administrator`,
                    blocking: false,
                  },
                });
              });
          } else {
            request
              .then((response) => {
                if (
                  response.config.url.includes(
                    "dispatches/?filter[where][picking_status]=PICKING&filter[where][origin_ID]=9290&filter[where][status]=PFD&filter[include][1][orders]=boxes&filter[include][2]=picker"
                  )
                ) {
                  let finalArray = [];

                  const dataToPass = _.cloneDeep(response.data);
                  finalArray = constructCustomTableState(
                    "warehouse-picklist",
                    dataToPass
                  );

                  if (hasSelectedItems && hasSelectedItems.length > 0) {
                    _.forEach(hasSelectedItems, (tempLocalItem) => {
                      let localID = tempLocalItem.box_id;

                      _.map(finalArray, (dataItem) => {
                        if (dataItem.box_id === localID) {
                          // Object.assign({selected: true}, dataItem)
                          dataItem.selected = true;
                        } else if (dataItem.box_id !== localID) {
                          Object.assign({ selected: false }, dataItem);
                        }
                      });
                    });
                  } else {
                    finalArray.map((dataItem) =>
                      Object.assign({ selected: false }, dataItem)
                    );
                  }

                  this.setState({
                    [domain]: {
                      ...this.state[domain],
                      blocking: false,
                      gridData: {
                        data: finalArray,
                        total: this.state[domain].total,
                      },
                    },
                  });
                } else if (
                  response.config.url.includes(
                    `api/logTables?filter=%7B%22include%22%3A%20%22creator%22%2C%20%20%20%20%22where%22%3A%7B%20%22and%22%3A%20%5B%7B%22model_name%22%3A%20%22BOX%22%2C%20%22model_id%22%3A%20%22${this.props.match.params.id}%22%7D%5D%7D%7D`
                  )
                ) {
                  // let finalArray = []
                  // const dataToPass = _.cloneDeep(response.data)
                  // finalArray = constructCustomTableState('log-box', dataToPass)
                  //
                  // this.setState({
                  //     [domain]: {
                  //         ...this.state[domain],
                  //         blocking: false,
                  //         allGrid: {
                  //             gridData: {
                  //                 data: finalArray,
                  //                 total: this.state[domain].total
                  //             },
                  //             gridData1: {
                  //                 data: [],
                  //                 total: this.state[domain].total
                  //             },
                  //             gridData2: {
                  //                 data: [],
                  //                 total: this.state[domain].total
                  //             }
                  //         }
                  //     }
                  // }, () => {
                  //     this.refreshGridDataAfterCrud('logBox')
                  // })
                } else {
                  if (
                    response.data.response &&
                    response.data.response.dataArray
                  ) {
                    _.forEach(
                      response.data.response.dataArray,
                      function (dataItem) {
                        if (dataItem.DG === "Y") {
                          dataItem.flag = "DG";
                        }
                        if (
                          dataItem.priority === "HIGH" ||
                          dataItem.priority === "CRITICAL"
                        ) {
                          let lastVal = "PR";
                          if (dataItem.flag !== "") {
                            dataItem.flag = `${dataItem.flag},${lastVal}`;
                          }
                        }
                      }
                    );

                    this._isMounted &&
                      this.setState({
                        [domain]: {
                          ...this.state[domain],
                          blocking: false,
                          gridData: {
                            data: response.data.response.dataArray,
                            total: response.data.response.total,
                          },
                        },
                      });
                  } else if (response.data.dataArray) {
                    this._isMounted &&
                      this.setState({
                        [domain]: {
                          ...this.state[domain],
                          blocking: false,
                          gridData: {
                            data: response.data.dataArray,
                            total: response.data.total,
                          },
                        },
                      });
                  } else {
                    let filterParams = JSON.parse(
                      window.localStorage.getItem("finalParams")
                    ).filter;

                    if (filterParams) {
                      this._isMounted &&
                        this.setState({
                          [domain]: {
                            ...this.state[domain],
                            blocking: false,
                            gridData: {
                              data: response.data,
                              total: response.data.length,
                            },
                          },
                        });
                    } else {
                      this._isMounted &&
                        this.setState({
                          [domain]: {
                            ...this.state[domain],
                            blocking: false,
                            gridData: {
                              data: response.data,
                              total: this.state[domain].total,
                            },
                          },
                        });
                    }
                  }
                }
              })
              .catch((error) => {
                this.setState({
                  [domain]: {
                    ...this.state[domain],
                    openErrorModal: true,
                    errorModalContent: `Please refresh and if persist contact the administrator`,
                    blocking: false,
                  },
                });
              });
          }
        }
      };

      handleColumnsChange = (event) => {
        let columns = this.state[domain].columns;

        let oldColumns = columns.filter((c) => c.visible).map((c) => c.field);
        let newColumns = event.target.value;

        let unique1 = oldColumns.filter((c) => newColumns.indexOf(c) === -1);
        let unique2 = newColumns.filter((c) => oldColumns.indexOf(c) === -1);

        const columnLastUpdated = unique1.concat(unique2)[0];

        let index = columns.findIndex(
          (column) => column.field === columnLastUpdated
        );

        columns[index].visible = !columns[index].visible;

        this.setState({
          [domain]: {
            ...this.state[domain],
            columns,
          },
        });
      };

      redirectToCreateForm = () => {
        const { gridActions } = this.props;

        let path = gridActions.filter((temp) =>
          temp.title.includes("Create")
        )[0].actionRoutePath;

        push(`${path}`);
      };

      goToAddOrderTable = () => {
        push(`${this.props.history.location.pathname}/addOrder`);
      };

      stayWhereYouAre = (dataItem, path) => {
        this.setState(
          {
            [domain]: {
              ...this.state[domain],
              openSnackbar: true,
              action: "Cancel Delete",
              snackbarContent: `Delete confirmation for ID ${dataItem.id}`,
            },
          },
          () => {
            const deleteTimer = setTimeout(() => {
              this.makeDeleteCall(dataItem);
            }, 3000);

            window.localStorage.setItem("deleteTimer", deleteTimer);
          }
        );
      };

      deleteDispatchFinancial = (dataItem, path) => {
        this.setState(
          {
            [domain]: {
              ...this.state[domain],
              openSnackbar: true,
              action: "Cancel Delete",
              snackbarContent: `Delete confirmation for Financial with ID ${dataItem.id}`,
            },
          },
          () => {
            const deleteFinancialTimer = setTimeout(() => {
              this.makeDeleteFinancial(dataItem);
            }, 3000);

            window.localStorage.setItem(
              "deleteFinancialTimer",
              deleteFinancialTimer
            );
          }
        );
      };

      addOrderToDispatch = (dataItem, path) => {
        const options = {
          quotationApplication: false,
          token: window.localStorage.getItem("access_token"),
        };

        const dispID = window.localStorage.getItem("dispatchID");

        const request = serverApi(
          "POST",
          `https://dev.swift-navigator.com:443/api/dispatchesImportedV4s/${dispID}/orderToDispatch`,
          "",
          { orderID: `${dataItem.id}` },
          options
        );

        request
          .then((response) => {
            // console.log(response)

            let orderIDtoExclude = response.data.orderID;

            this.setState(
              {
                [domain]: {
                  ...this.state[domain],
                  openSuccessModal: true,
                  successModalContent: `Order added to dispatch`,
                  successModalTimeout: 1000,
                },
              },
              () => {
                let req1 = serverApi(
                  "GET",
                  "https://dev.swift-navigator.com/api/ordersImportedV4s?filter[limit]=50&filter[skip]=0&filter[order][]=vessel_name+asc&filter[order][]=status_code%20%20%20%20%20%20%20%20%20%20%20%20+desc&filter[order][]=id+asc&filter[where][and][0][status_code][]=PEND&filter[where][and][0][status_code][]=RAS&filter[where][and]%20%20%20%20%20%20%20%20%20%20%20%20[0][status_code][]=EXPONS&filter[where][and][0][status_code][]=STOCK-TBC&filter[where][and][0][status_code][]=STOCK&filter[where][and][1][destination_ID]=9290&filter=%7B%22include%22:%22%22,%22limit%22:10,%22skip%22:0,%22order%22:[%22id+asc%22],%22where%22:%7B%7D%7D",
                  "",
                  "",
                  options
                );

                req1
                  .then((response2) => {
                    let FirstArray = response2.data.dataArray;
                    let finalArray = [];
                    let finalTotal = response2.data.total;

                    FirstArray.forEach((temp) => {
                      if (temp.id !== orderIDtoExclude) {
                        finalArray.push(temp);
                      }
                    });

                    let finalGridData = {
                      data: finalArray,
                      total: finalTotal,
                    };

                    this.setState({
                      [domain]: {
                        ...this.state[domain],
                        gridData: finalGridData,
                      },
                    });
                  })
                  .catch((error) => {
                    this.setState({
                      [domain]: {
                        ...this.state[domain],
                        openErrorModal: true,
                        errorModalContent: `Error ! ! ! !`,
                      },
                    });
                  });
              }
            );
          })
          .catch((error) => {
            let conditionError =
              error && error.response && error.response.data.error.message;

            if (conditionError.includes("Duplicate")) {
              this.setState({
                [domain]: {
                  ...this.state[domain],
                  openErrorModal: true,
                  errorModalContent: `The order with ID: ${dataItem.id} is already included in this dispatch`,
                },
              });
            } else {
              this.setState({
                [domain]: {
                  ...this.state[domain],
                  openErrorModal: true,
                  errorModalContent: `Error ! ! ! !`,
                },
              });
            }
            // console.log(error)
          });
      };

      refreshGridDataAfterCrud1 = () => {
        let token = window.localStorage.getItem("access_token");
        let options = {
          token: token,
        };

        let finalArray = [];
        const request = serverApi(
          "GET",
          `logTables?filter=%7B%22include%22%3A%20%22creator%22%2C%20%20%20%20%22where%22%3A%7B%20%22and%22%3A%20%5B%7B%22model_name%22%3A%20%22BOX%22%2C%20%22model_id%22%3A%20%22${this.props.match.params.id}%22%7D%5D%7D%7D`,
          "",
          "",
          options
        );
        request
          .then((response) => {
            const dataToPass = _.cloneDeep(response.data);
            finalArray = constructCustomTableState("log-box", dataToPass);

            this.setState({
              [domain]: {
                ...this.state[domain],
                blocking: false,
                allGrid: {
                  gridData: {
                    data: finalArray || [],
                    total: this.state[domain].total,
                  },
                  gridData1: {
                    data: this.state[domain].allGrid.gridData1.data || [],
                    total: this.state[domain].total,
                  },
                  gridData2: {
                    data: this.state[domain].allGrid.gridData2.data || [],
                    total: this.state[domain].total,
                  },
                },
              },
            });
          })
          .catch((error) => {
            let conditionError =
              error && error.response && error.response.data.error.message;

            this.setState({
              [domain]: {
                ...this.state[domain],
                blocking: false,
                allGrid: {
                  gridData: {
                    data: finalArray || [],
                    total: this.state[domain].total,
                  },
                  gridData1: {
                    data: this.state[domain].allGrid.gridData1.data || [],
                    total: this.state[domain].total,
                  },
                  gridData2: {
                    data: this.state[domain].allGrid.gridData2.data || [],
                    total: this.state[domain].total,
                  },
                },
                openErrorSnackbar: true,
                errorSnackbarContent: conditionError || "Please try again",
              },
            });
          });
      };

      refreshGridDataAfterCrud2 = () => {
        let token = window.localStorage.getItem("access_token");
        let options = {
          token: token,
        };

        let orderId = window.localStorage.getItem("orderIDNextCall");

        let finalArray2 = [];
        if (orderId) {
          let url = `logtables?filter[where][and][0][model_name]=ORDER&filter[where][and][1][model_id]=${orderId}&filter%5Binclude%5D=creator`;
          const orderLogRequest = serverApi("GET", url, {}, "", options);

          orderLogRequest
            .then((resp) => {
              const dataToPass = _.cloneDeep(resp.data);
              finalArray2 = constructCustomTableState(
                "log-box-order-table",
                dataToPass
              );

              this.setState({
                [domain]: {
                  ...this.state[domain],
                  blocking: false,
                  allGrid: {
                    gridData: {
                      data: this.state[domain].allGrid.gridData.data || [],
                      total: this.state[domain].total,
                    },
                    gridData1: {
                      data: finalArray2 || [],
                      total: this.state[domain].total,
                    },
                    gridData2: {
                      data: this.state[domain].allGrid.gridData2.data || [],
                      total: this.state[domain].total,
                    },
                  },
                },
              });
            })
            .catch((error) => {
              // console.log(error)

              this.setState({
                [domain]: {
                  ...this.state[domain],
                  blocking: false,
                  // openErrorModal: true,
                  // errorModalContent: `No data found the order log table`,
                  allGrid: {
                    gridData: {
                      data: this.state[domain].allGrid.gridData.data || [],
                      total: this.state[domain].total,
                    },
                    gridData1: {
                      data: [],
                      total: this.state[domain].total,
                    },
                    gridData2: {
                      data: this.state[domain].allGrid.gridData2.data || [],
                      total: this.state[domain].total,
                    },
                  },
                },
              });
            });
        } else {
          this.setState({
            [domain]: {
              ...this.state[domain],
              blocking: false,
              allGrid: {
                gridData: {
                  data: this.state[domain].allGrid.gridData.data || [],
                  total: this.state[domain].total,
                },
                gridData1: {
                  data: [],
                  total: this.state[domain].total,
                },
                gridData2: {
                  data: this.state[domain].allGrid.gridData2.data || [],
                  total: this.state[domain].total,
                },
              },
            },
          });
        }
      };

      refreshGridDataAfterCrud3 = () => {
        let token = window.localStorage.getItem("access_token");
        let options = {
          token: token,
        };

        let url3 = `boxes/${this.props.match.params.id}?filter[include]=inventoryChecks`;

        this.setState({
          [domain]: {
            ...this.state[domain],
            blocking: true,
          },
        });

        let finalArray3 = [];

        const inventoryCheckRequest = serverApi("GET", url3, "", "", options);
        inventoryCheckRequest
          .then((invResponse) => {
            // let labelPrinted = "2020-01-27T13:56:45.000Z"
            // window.localStorage.setItem('labelPrinted', labelPrinted)

            const dataToPass = _.cloneDeep(invResponse.data);
            finalArray3 = constructCustomTableState(
              "inventory-checks",
              dataToPass
            );

            this.setState({
              [domain]: {
                ...this.state[domain],
                blocking: false,
                allGrid: {
                  gridData: {
                    data: this.state[domain].allGrid.gridData.data || [],
                    total: this.state[domain].total,
                  },
                  gridData1: {
                    data: this.state[domain].allGrid.gridData1.data || [],
                    total: this.state[domain].total,
                  },
                  gridData2: {
                    data: finalArray3 || [],
                    total: this.state[domain].total,
                  },
                },
              },
            });
          })
          .catch((error) => {
            // console.log(error)
            let conditionError =
              error && error.response && error.response.data.error.message;

            this.setState({
              [domain]: {
                ...this.state[domain],
                blocking: false,
                allGrid: {
                  gridData: {
                    data: this.state[domain].allGrid.gridData.data || [],
                    total: this.state[domain].total,
                  },
                  gridData1: {
                    data: this.state[domain].allGrid.gridData1.data || [],
                    total: this.state[domain].total,
                  },
                  gridData2: {
                    data: [],
                    total: this.state[domain].total,
                  },
                },
                openErrorSnackbar: true,
                errorSnackbarContent: conditionError || "Please try again",
              },
            });
          });
      };

      refreshGridDataAfterCrud = (view = "") => {
        let token = window.localStorage.getItem("access_token");
        let options = {
          token: token,
        };

        if (view !== "logBox") {
          if (view === "lastTwoGrids") {
            let orderID =
              (this.state["domain"].allGrid &&
                this.state["domain"].allGrid.gridData1 &&
                this.state["domain"].allGrid.gridData1.data[0].id) ||
              false;
            if (orderID) {
              let orderToDispatchRequest = serverApi(
                "GET",
                `orderToDispatches?filter=%7B%22include%22%3A%20%22dispatch%22%2C%20%20%20%20%20%20%22where%22%3A%20%7B%22orderID%22%3A%20${orderID}%20%7D%7D`,
                "",
                "",
                options
              );
              orderToDispatchRequest
                .then((response) => {
                  let data = _.cloneDeep(response.data);
                  let boxID =
                    (this.state[domain] && this.state[domain].boxID) || 0;

                  let final4 = [];
                  final4 = constructCustomTableState("orderToDispatches", data);

                  let final5 = [];
                  let lastResponse = [];
                  if (boxID !== 0) {
                    let whPickingDispatchRequest = serverApi(
                      "GET",
                      `whPickingDispatchToBoxes?filter=%7B%22include%22%3A%20%5B%22dispatch%22%2C%20%22picker%22%5D%2C%20%20%22where%22%3A%20%7B%22boxID%22%3A%20%22${boxID}%22%7D%7D`,
                      "",
                      "",
                      options
                    );
                    whPickingDispatchRequest
                      .then((response2) => {
                        lastResponse = response2.data;
                        final5 = constructCustomTableState(
                          "whPickingDispatchToBoxes",
                          lastResponse
                        );

                        this.setState({
                          [domain]: {
                            ...this.state[domain],
                            blocking: false,
                            allGrid: {
                              gridData: {
                                data:
                                  this.state[domain].allGrid.gridData.data ||
                                  [],
                                total: this.state[domain].total,
                              },
                              gridData1: {
                                data:
                                  this.state[domain].allGrid.gridData1.data ||
                                  [],
                                total: this.state[domain].total,
                              },
                              gridData2: {
                                data:
                                  this.state[domain].allGrid.gridData2.data ||
                                  [],
                                total: this.state[domain].total,
                              },
                              gridData4: {
                                data: final4 || [],
                                total: this.state[domain].total,
                              },
                              gridData5: {
                                data: final5 || [],
                                total: this.state[domain].total,
                              },
                            },
                          },
                        });
                      })
                      .catch((error) => {
                        // console.log(error)
                      });
                  }
                })
                .catch((e) => {
                  // console.log(e)
                });
            }
          } else {
            let hasFinalParams = JSON.parse(
              window.localStorage.getItem("finalParams")
            );
            let hasSelectedItems = JSON.parse(
              window.localStorage.getItem("selectedItems")
            );
            this.dataStateChange(
              { data: hasFinalParams || { take: 10, skip: 0 } },
              true,
              hasSelectedItems
            );
          }
        } else {
          // let finalArray2 = []
          //
          // const boxLogRequest1 = serverApi('GET', `${this.props.urlSpecific !== "" ? this.props.urlSpecific : this.props.karfoto}`,
          //     '', '', options);
          //
          // boxLogRequest1
          //     .then(response => {
          //
          //         let finalArray = []
          //         const dataToPass = _.cloneDeep(response.data)
          //         finalArray = constructCustomTableState('log-box', dataToPass)
          //
          //         this.setState({
          //             [domain]: {
          //                 ...this.state[domain],
          //                 blocking: true,
          //                 allGrid: {
          //                     gridData: {
          //                         data: finalArray || [],
          //                         total: this.state[domain].total
          //                     },
          //                     gridData1: {
          //                         data: [],
          //                         total: this.state[domain].total
          //                     },
          //                     gridData2: {
          //                         data: this.state[domain].allGrid.gridData2.data || [],
          //                         total: this.state[domain].total
          //                     }
          //                 }
          //             }
          //         })
          //
          //         let orderId = window.localStorage.getItem('orderIDNextCall')
          //         let url = `logtables?filter[where][and][0][model_name]=ORDER&filter[where][and][1][model_id]=${orderId}&filter%5Binclude%5D=creator`
          //
          //         if (response.data.length > 0) {
          //             const orderLogRequest = serverApi('GET', url,
          //                 {}, '', options);
          //
          //             orderLogRequest
          //                 .then((resp) => {
          //
          //                     const dataToPass = _.cloneDeep(resp.data)
          //                     finalArray2 = constructCustomTableState('log-box-order-table', dataToPass)
          //                     console.log("123", this.state[domain].allGrid)
          //
          //                     this.setState({
          //                         [domain]: {
          //                             ...this.state[domain],
          //                             blocking: false,
          //                             allGrid: {
          //                                 gridData: {
          //                                     data: this.state[domain].allGrid.gridData.data || [],
          //                                     total: this.state[domain].total
          //                                 },
          //                                 gridData1: {
          //                                     data: finalArray2 || [],
          //                                     total: this.state[domain].total
          //                                 },
          //                                 gridData2: {
          //                                     data: this.state[domain].allGrid.gridData2.data || [],
          //                                     total: this.state[domain].total
          //                                 }
          //                             }
          //                         }
          //                     })
          //
          //                 })
          //                 .catch(error => {
          //                     console.log(error)
          //
          //                     this.setState({
          //                         [domain]: {
          //                             ...this.state[domain],
          //                             blocking: false,
          //                             // openErrorModal: true,
          //                             // errorModalContent: `No data found the order log table`,
          //                             allGrid: {
          //                                 gridData: {
          //                                     data: this.state[domain].allGrid.gridData.data || [],
          //                                     total: this.state[domain].total
          //                                 },
          //                                 gridData1: {
          //                                     data: [],
          //                                     total: this.state[domain].total
          //                                 },
          //                                 gridData2: {
          //                                     data: this.state[domain].allGrid.gridData2.data || [],
          //                                     total: this.state[domain].total
          //                                 }
          //                             }
          //                         }
          //                     })
          //                 });
          //         } else {
          //             this.setState({
          //                 [domain]: {
          //                     ...this.state[domain],
          //                     blocking: false,
          //                     allGrid: {
          //                         gridData: {
          //                             data: this.state[domain].allGrid.gridData.data || [],
          //                             total: this.state[domain].total
          //                         },
          //                         gridData1: {
          //                             data: [],
          //                             total: this.state[domain].total
          //                         },
          //                         gridData2: {
          //                             data: this.state[domain].allGrid.gridData2.data || [],
          //                             total: this.state[domain].total
          //                         }
          //                     }
          //                 }
          //             })
          //         }
          //
          //     })
          //     .catch(error => {
          //         this.setState({
          //             [domain]: {
          //                 ...this.state[domain],
          //                 openErrorModal: true,
          //                 errorModalContent: `Please refresh and if persist contact the administrator`,
          //                 blocking: false
          //             }
          //         })
          //     });
        }
      };

      refreshOrdersGridDataAfterCrud = () => {
        let token = window.localStorage.getItem("access_token");
        let options = {
          token: token,
        };

        let request = serverApi(
          "GET",
          `${this.props.urlSpecific}`,
          {
            filter: {
              // "include": [""],
              limit: 10,
              skip: 0,
              order: ["id asc"],
              where: {},
            },
          },
          "",
          options
        );

        request
          .then((response) => {
            //todo removed because the remove order from dispathc old functionality

            // let finalArray = []
            //
            // let arrOfOrderId = response.data.map(temp => temp.orderID)
            // window.localStorage.setItem('ordersToExclude', arrOfOrderId)
            // const dataToPass = response.data
            // finalArray = constructCustomTableState('orderToDispatch', dataToPass)

            this.setState({
              [domain]: {
                ...this.state[domain],
                gridData: {
                  data: response.data || [],
                },
                showPagination: false,
                blocking: false,
              },
            });
          })
          .catch((error) => {
            console.log(error);
          });
      };

      makeDeleteCall = (dataItem) => {
        // If the request takes longer than `timeout`, the request will be aborted.
        const options = {
          token: window.localStorage.getItem("access_token"),
        };

        const req = serverApi(
          "DELETE",
          `${this.props.urlSpecific}/${dataItem.id}`,
          "",
          "",
          options
        );

        req
          .then((response) => {
            this.setState(
              {
                [domain]: {
                  ...this.state[domain],
                  openSnackbar: false,
                  snackbarContent: "",
                },
              },
              () =>
                setTimeout(
                  () => this.refreshOrdersGridDataAfterCrud(dataItem.id),
                  500
                )
            );
          })
          .catch((error) => {
            console.log(error);

            this.setState({
              [domain]: {
                ...this.state[domain],
                openErrorModal: true,
                errorModalContent:
                  error.response.data.error.message ||
                  "Error occured, please try again",
                openSnackbar: false,
                snackbarContent: "",
              },
            });
          });
      };

      makeDeleteFinancial = (dataItem) => {
        // If the request takes longer than `timeout`, the request will be aborted.
        const options = {
          quotationApplication: false,
          token: window.localStorage.getItem("access_token"),
        };

        let dispatchId = window.localStorage.getItem("dispatchID");

        const req = serverApi(
          "DELETE",
          `https://dev.swift-navigator.com:443/api/dispatchesImportedV4s/${dispatchId}/genericFinancials/${dataItem.id}`,
          "",
          "",
          options
        );

        req
          .then((response) => {
            console.log(response);

            this.setState(
              {
                [domain]: {
                  ...this.state[domain],
                  openSnackbar: false,
                  snackbarContent: "",
                },
              },
              () => setTimeout(() => this.refreshGridDataAfterCrud(), 500)
            );
          })
          .catch((error) => {
            console.log(error);

            this.setState({
              [domain]: {
                ...this.state[domain],
                openErrorModal: true,
                errorModalContent:
                  `${error.response.data.error.message}` ||
                  "Error occurred, please again",
                openSnackbar: false,
                snackbarContent: "",
              },
            });
          });
      };

      onCloseSnackbar = (cancel) => {
        if (cancel === "yes") {
          clearTimeout(window.localStorage.getItem("deleteTimer"));
          clearTimeout(window.localStorage.getItem("deleteFinancialTimer"));
        }

        this.setState(
          {
            [domain]: {
              ...this.state[domain],
              openSnackbar: false,
              snackbarContent: "",
            },
          },
          () => {
            window.localStorage.removeItem("deleteTimer");
            window.localStorage.removeItem("deleteFinancialTimer");
          }
        );
      };

      redirectToEditForm = (dataItem, path, rowClick) => {
        // let basePath = this.props.history.location.pathname

        if (path === "/app/userManagement/roles/") {
          this.props.history.push(`/app/userManagement/roles/${dataItem.id}`);
        } else {
          if (rowClick === "y") {
            let basePath = this.props.history.location.pathname;
            this.props.history.push(`${basePath}/edit/${dataItem}`);
          } else {
            this.props.history.push(
              `${path === undefined ? "edit" : path}/${dataItem.id}`
            );
          }
        }
      };

      onEditSubmit = (e) => {
        e.preventDefault();

        const id = this.props.match.params.id;
        const editPayload = this.state[domain].editData;

        let options = {
          token: window.localStorage.getItem("access_token"),
        };

        const request = AbstractEdit(
          id,
          editPayload,
          this.props.urlSubmitPrefix,
          options
        );

        request
          .then((response) => {
            console.log(response);

            this.setState(
              {
                [domain]: {
                  ...this.state[domain],
                  openSuccessModal: true,
                  successModalContent: "Form submitted",
                },
              },
              () => setTimeout(() => this.props.history.goBack(), 2000)
            );
          })
          .catch((error) => {
            console.log(error, "error");

            this.setState({
              [domain]: {
                ...this.state[domain],
                openErrorModal: true,
                errorModalContent:
                  `${error.response.data.error.message}` ||
                  "Error occured, please try again",
              },
            });
          });
      };

      onCreateSubmit = (e) => {
        e.preventDefault();

        const options = {
          quotationApplication: false,
          token: window.localStorage.getItem("access_token"),
        };
        const payload = this.state[domain].createData;
        const submitURL = this.props.urlSubmitPrefix;

        const createRequest = serverApi(
          "POST",
          `${submitURL}`,
          "",
          payload,
          options
        );

        createRequest
          .then((response) => {
            this.setState(
              {
                [domain]: {
                  ...this.state[domain],
                  errors: {},
                  relatedToPropertiesErrors: null,
                  openSuccessModal: true,
                  successModalContent: "Record has been successfully created",
                },
              },
              () => {
                setTimeout(
                  () => this.redirectToEditForm({ id: response.data.id }),
                  1500
                );
              }
            );
          })
          .catch((error) => {
            if (error) {
              // let propertiesWithErrors = {
              //   clientReference: "lathos re",
              //   documentno: "malakas",
              //   carrier: "trobas",
              //   status: "twra",
              //   modality: "arxidi",
              //   ETD: "SEX",
              //   RTD: "VALE",
              //   ETA: "VALE",
              //   RTA: "VALE",
              //   deadline_dt: "VALE",
              //   origin: "Lathos origin",
              //   destination: "Lathos destination"
              // }

              // let propertyErrorKeys = Object.keys(error.response.data.error.details.messages)

              this.setState({
                [domain]: {
                  ...this.state[domain],
                  errors: error.response.data.error.message || "oust re",
                  // relatedToPropertiesErrors: propertiesWithErrors,
                  openErrorModal: true,
                  errorModalContent: error.response.data.error.message,
                },
              });
            }
          });
      };

      clearFormData = () => {
        //TODO FIX THE CLEAR depending on the state.
        let params = { ...this.state[domain] };

        Object.keys(params).forEach((key, index) => {
          if (key !== "sort" && key !== "size" && key !== "page") {
            params[key] = "";
          }
        });

        this.setState(
          {
            [domain]: {
              ...this.state[domain],
              params,
            },
          },
          () => this.fetchData()
        );
      };

      clearErrorNotification = () => {
        this.setState({
          [domain]: {
            ...this.state[domain],
            errors: {
              ...this.state[domain].errors,
              message: null,
            },
          },
        });
      };

      closeErrorModal = () => {
        this.setState({
          [domain]: {
            ...this.state[domain],
            openErrorModal: false,
            errorModalContent: "",
          },
        });
      };

      closeSuccessModal = () => {
        this.setState({
          [domain]: {
            ...this.state[domain],
            openSuccessModal: false,
            successModalContent: "",
          },
        });
      };

      cancelFormSubmit = () => {
        //TODO handle the cancel button depending on the urlFetch to push to the correct view for react-router

        if (this.props.urlFetchPrefix === "masterEntities") {
          this.props.history.push("/app/crm");
        }
      };

      goBackFunc = () => {
        this.props.history.goBack();
      };

      clearGridColumnFilters = () => {
        //TODO further code for also checking if has a sort column to clear also the property

        delete this.state[domain].params;
        delete this.state[domain].dataState.filter;

        // window.localStorage.removeItem('finalParams')
        this.onFetchData();
      };

      handleEditRow = (id) => {
        push(
          process.env.PUBLIC_URL + `/app/${this.props.urlSpecific}/edit/${id}`
        );
      };

      handleErrorClose = (loginError) => {
        this.setState({
          [domain]: {
            ...this.state[domain],
            openErrorSnackbar: false,
            errorSnackbarContent: "",
          },
        });
      };

      /**
       * This is a custom function that is used to return an object that will be used as props object inside the Child component.
       * For example if the domain is tableList, then we provide the Child the needed props that the grid must have to be fully
       * functional with all the actions ,etc...
       * @returns {}
       */
      defineChildProps = () => {
        switch (domain) {
          case "tableList":
            return {
              dataStateChange: this.dataStateChange,
              onFetchData: this.onFetchData,
              renderGridActions: this.renderGridActions,
              clearFormData: this.clearFormData,
              redirectToEditForm: this.redirectToEditForm,
              redirectToCreateForm: this.redirectToCreateForm,
              goToAddOrderTable: this.goToAddOrderTable,
              // gridColumns: this.state[domain].gridColumns,
              totalPages: this.state[domain].gridData.total,
              gridData: this.state[domain].gridData,
              allGrid: this.state[domain].allGrid,
              dataState: this.state[domain].dataState,
              showPagination: this.state[domain].showPagination,
              goBackFunc: this.goBackFunc,
              handleColumnsChange: this.handleColumnsChange,
              columns: this.state[domain].columns,
              columns2: this.state[domain].columns2,
              columns3: this.state[domain].columns3,
              columns4: this.state[domain].columns4,
              columns5: this.state[domain].columns5,
              renderGridColumns: this.renderGridColumns,
              renderGridColumns2: this.renderGridColumns2,
              renderGridColumns3: this.renderGridColumns3,
              renderGridColumns4: this.renderGridColumns4,
              renderGridColumns5: this.renderGridColumns5,
              selectionChange: this.selectionChange,
              rowClick: this.rowClick,
              headerSelectionChange: this.headerSelectionChange,
              clearGridColumnFilters: this.clearGridColumnFilters,
              refreshGridDataAfterCrud: this.refreshGridDataAfterCrud,
              refreshGridDataAfterCrud1: this.refreshGridDataAfterCrud1,
              refreshGridDataAfterCrud2: this.refreshGridDataAfterCrud2,
              refreshGridDataAfterCrud3: this.refreshGridDataAfterCrud3,
              labelPrinted: this.state[domain].labelPrinted,
            };

          case "Edit":
            const hasUserEditAuthority = true;
            const translatedSchema = this.props.editSchema();

            const schemaWithoutButtons = filter(
              (s) =>
                s.type ? s.type !== enums.SchemaFieldsTypes.BUTTON : true,
              translatedSchema
            );

            const buttonSchema = filter(
              (s) => s.type === enums.SchemaFieldsTypes.BUTTON,
              translatedSchema
            );

            const EditFormButtons = _.map(buttonSchema, (b) => {
              return (
                <Button
                  variant="contained"
                  color={b.color}
                  className={buttonStyles[b.className]}
                  onClick={this[b.onClick]}
                >
                  {Array.isArray(b.text)
                    ? // eslint-disable-next-line no-eval
                      eval(b.expression)
                      ? b.text[0]
                      : b.text[1]
                    : b.text}
                </Button>
              );
            });

            const editSchema = hasUserEditAuthority
              ? schemaWithoutButtons
              : makeSchemaReadOnly(schemaWithoutButtons);

            return {
              editData: this.state[domain].editData,
              departmentsArr: this.state[domain].departmentsArr,
              errors: this.state[domain].errors,
              relatedToPropertiesErrors:
                this.state[domain].relatedToPropertiesErrors,
              editSchema: editSchema,
              clearErrorNotification: this.clearErrorNotification,
              EditFormButtons,
            };

          case "Create":
            const hasUserCreateAuthority = true;
            const translatedCreateSchema = this.props.createSchema();

            const createSchemaWithoutButtons = filter(
              (s) =>
                s.type ? s.type !== enums.SchemaFieldsTypes.BUTTON : true,
              translatedCreateSchema
            );

            const createButtonSchema = filter(
              (s) => s.type === enums.SchemaFieldsTypes.BUTTON,
              translatedCreateSchema
            );

            //TODO check if error inside form and disable the submit button
            // const formHasErrors = this.state[domain].errors === "" ? false : true

            const CreateFormButtons = _.map(createButtonSchema, (b) => {
              return (
                <Button
                  variant="contained"
                  color={b.color}
                  className={buttonStyles[b.className]}
                  onClick={this[b.onClick]}
                >
                  {Array.isArray(b.text)
                    ? // eslint-disable-next-line no-eval
                      eval(b.expression)
                      ? b.text[0]
                      : b.text[1]
                    : b.text}
                </Button>
              );
            });

            const createSchema = hasUserCreateAuthority
              ? createSchemaWithoutButtons
              : makeSchemaReadOnly(createSchemaWithoutButtons);

            return {
              createData: this.state[domain].createData,
              onCreateFormSubmit: this.onCreateSubmit,
              cancelFormSubmit: this.cancelFormSubmit,
              clearErrorNotification: this.clearErrorNotification,
              createSchema: createSchema,
              propertyErrors: this.state[domain].propertyErrors,
              relatedToPropertiesErrors:
                this.state[domain].relatedToPropertiesErrors,
              CreateFormButtons,
            };

          default:
            return {};
        }
      };

      render() {
        const extraChildProps = this.defineChildProps();

        /**
         * The Child component is the container Component that was attached to the HOC.
         * For more questions please call me:  K.Fouster
         */
        return (
          <Fragment>
            <BlockUi
              tag="div"
              blocking={this.state[domain].blocking}
              message={this.state[domain].BlockUiMessage}
            >
              <SuccessModal
                open={this.state[domain].openSuccessModal}
                onCloseModal={this.closeSuccessModal}
                modalContent={this.state[domain].successModalContent}
                timeout={this.state[domain].successModalTimeout}
              />
              <ErrorModal
                open={this.state[domain].openErrorModal}
                onCloseModal={this.closeErrorModal}
                modalContent={this.state[domain].errorModalContent}
              />
              <CustomSnackbar
                open={this.state[domain].openSnackbar}
                onCloseSnackbar={this.onCloseSnackbar}
                action={this.state[domain].action}
                snackbarContent={this.state[domain].snackbarContent}
                makeDeleteCall={this.makeDeleteCall}
              />
              <Snackbar
                place="tc"
                style={{
                  width: "100%",
                  overflow: "hidden",
                }}
                color={"warning"}
                message={this.state[domain].errorSnackbarContent}
                open={this.state[domain].openErrorSnackbar}
                // closeNotification={() => this.handleErrorClose()}
                // close
              />
              <Child
                onChange={this.onChange}
                {...this.props}
                {...extraChildProps}
              />
            </BlockUi>
          </Fragment>
        );
      }
    }

    return EnhancedChild;
  };
};

export default HandleAllHOC;
