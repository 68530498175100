import React, { Component } from "react";
import { Loader } from "./loader";
import { columnsSchema } from "../View/schema";
import { MyCommandCell } from "../../CRM/View/myCommandCell";
import { DropDownCell } from "../../CRM/View/dropDownCell.js";
import { serverApi } from "../../../networking/config";
import {
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
} from "@material-ui/core";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser, faSyncAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import ViewSettings from "../../common/ViewSettings";
import customSelectStyles from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import { Checkbox } from "@progress/kendo-react-inputs";
import Snackbar from "../../../components/Snackbar/Snackbar";
import { AbstractEdit } from "../../../networking/apiCalls";
import GridContainer from "../../../components/Grid/GridContainer";
import {
  showCreatorOnTable,
  removeUnwantedKeyValuesFromArr,
} from "../../../lib/GeneralUtils/utils";
import DropdownFilterCell from "../../common/dropdownFilterCell";
class SeaportsView extends Component {
  editField = "inEdit";
  CommandCell;

  constructor(props) {
    super(props);

    this.state = {
      deleteVisible: false,
      visibleWindow: false,
      isDropOpened: false,
      columns: (columnsSchema && columnsSchema()) || [],
      initialColumns: (columnsSchema && columnsSchema()) || [],
      gridData: {
        data: [],
        total: 0,
      },
      initialGridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 100,
        skip: 0,
      },
      openErrorModal: false,
      errorModalContent: "",
      delay: 400,
      loading: false,
      selectedRowIndex: 0,
      selectedItem: null,
      googleOptions: [],
      expandedRowIndex: null,
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: () => {},
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      hasDisable: false,
      showSecondAction: false,
    });
  }

  componentDidMount() {
    let isFirstLoad = true;
    window.localStorage.setItem("isFirstLoad", isFirstLoad);

    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));

    if (hasFinalParams) {
      this.setState({
        ...this.state,
        dataState: hasFinalParams,
      });
    }

    if (hasChangedColumns) {
      this.setState({
        ...this.state,
        columns: hasChangedColumns,
      });
    }
  }

  componentWillUnmount() {
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));
    let finalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    let hasExpandedId = JSON.parse(window.localStorage.getItem("expandeId"));
    let hasExportFilter = window.localStorage.getItem("exportFilter");

    if (hasChangedColumns) {
      window.localStorage.removeItem("cols");
    }
    if (finalParams) {
      window.localStorage.removeItem("finalParams");
    }
    if (hasExpandedId) {
      window.localStorage.removeItem("expandeId");
    }
    if (hasExportFilter) {
      window.localStorage.removeItem("exportFilter");
    }
  }

  dataStateChange = (e) => {
    const reqGridParams = e.data;
    window.localStorage.setItem("finalParams", JSON.stringify(reqGridParams));

    this.setState({
      ...this.state,
      dataState: reqGridParams,
    });
  };

  dataReceived = (gridData, reqGridParams) => {
    const finalData = showCreatorOnTable(gridData);
    this.setState({
      ...this.state,
      gridData: finalData,
      initialGridData: finalData,
      dataState:
        reqGridParams === !_.isEmpty(reqGridParams)
          ? reqGridParams
          : this.state.dataState, //TODO MAYBE PASS THE final params from local storage for persist the filters on REFRESH PAGE !
    });
  };

  renderGridColumns = () => {
    let selectionCol = [];
    let normalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            width="65px"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((x) => x.visible)
      .map((temp) => {
        if (temp.field === "relation_country") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              filterCell={(props) => {
                return (
                  <>
                    <DropdownFilterCell
                      {...props}
                      filterable={true}
                      width="150px"
                      data={(this.props.countryCodes || [])
                        .map((x) => x.alpha_2 + "," + x.name)
                        .sort((a, b) => (a > b ? 1 : -1))}
                      hasMultipleValues={true}
                    />
                  </>
                );
              }}
              cell={(props) => {
                let data = (this.props.countryCodes || [])
                  .map((x) => ({
                    text: x.alpha_2 + "," + x.name,
                    value: x.alpha_2,
                  }))
                  .sort((a, b) => (a.text > b.text ? 1 : -1));
                return (
                  <>
                    <DropDownCell
                      {...props}
                      filterable={true}
                      width="210px"
                      data={data}
                    />
                  </>
                );
              }}
            />
          );
        } else if (temp.field === "state") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              filterCell={(props) => {
                return (
                  <>
                    <DropdownFilterCell
                      {...props}
                      filterable={true}
                      width="120px"
                      data={(this.props.usaStates || [])
                        .map((x) => x.lov_value_1)
                        .filter((x) => x)
                        .sort((a, b) => (a > b ? 1 : -1))}
                    />
                  </>
                );
              }}
              cell={(props) => {
                let stateData = (this.props.usaStates || [])
                  .map((x) => ({
                    text: x.lov_value_1,
                    value: x.lov_value_1,
                  }))
                  .filter((x) => x)
                  .sort((a, b) => (a.text > b.text ? 1 : -1))
                  .filter((obj) => obj.text !== null && obj.value !== null);
                return (
                  <>
                    <DropDownCell
                      {...props}
                      filterable={true}
                      width="100px"
                      data={stateData}
                    />
                  </>
                );
              }}
            />
          );
        } else {
          return (
            <Column
              sortable={temp.sortable}
              locked={temp.locked}
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={
                temp.field === "id" ||
                temp.field === "ar_account" ||
                temp.field === "ap_account" ||
                temp.editable
                  ? false
                  : true
              }
            />
          );
        }
      });

    return [...selectionCol, ...normalCols];
  };

  cancel = (dataItem) => {
    if (
      dataItem.relation_name === undefined ||
      dataItem.relation_name === "" ||
      dataItem.relation_country === undefined ||
      dataItem.relation_country === "" ||
      dataItem.relation_postal_code === undefined ||
      dataItem.relation_postal_code === "" ||
      dataItem.relation_city === undefined ||
      dataItem.relation_city === "" ||
      dataItem.relation_location_iso_code === undefined ||
      dataItem.relation_location_iso_code === "" ||
      dataItem.icao_code === undefined ||
      dataItem.icao_code === "" ||
      dataItem.latitude === undefined ||
      dataItem.latitude === "" ||
      dataItem.longitude === undefined ||
      dataItem.longitude === ""
    ) {
      const data =
        this.state.initialGridData && this.state.initialGridData.data;
      const total = this.state.initialGridData.total;

      this.setState({
        ...this.state,
        gridData: {
          data: data,
          total: total,
        },
        errorModalContent:
          this.state.errorModalContent !== ""
            ? ""
            : this.state.errorModalContent,
        openErrorModal:
          this.state.openErrorModal === true
            ? false
            : this.state.errorModalContent,
      });
    } else {
      const originalItem =
        this.state.initialGridData &&
        this.state.initialGridData.data.find((p) => p.id === dataItem.id);
      originalItem.inEdit = false;

      const data =
        this.state.gridData &&
        this.state.gridData.data.map((item) =>
          item.id === originalItem.id ? originalItem : item
        );
      const total = this.state.gridData.total;

      this.setState({
        ...this.state,
        gridData: {
          data: data,
          total: total,
        },
      });
    }
  };

  applyNewColumnsOnGrid = (dataItem) => {
    let columns = this.state.initialColumns;
    let newColumns = columns.filter(
      (x) => x.field !== "id" && x.field !== "approval_status"
    );
    newColumns.forEach((x) => (x.minWidth = 290));
    if (dataItem && dataItem.relation_country !== "US") {
      newColumns = newColumns.filter((col) => col.field !== "state");
    }

    if (dataItem && dataItem.relation_country !== "US") {
      newColumns = newColumns.filter((col) => col.field !== "state");
    }
    this.setState(
      {
        ...this.state,
        hideColumns: true,
        columns: newColumns,
        initialColumns: columns,
      },
      () => {}
    );
  };

  applyInitialColumnsOnGrid = () => {
    let initialColumns = this.state.initialColumns;
    let newColumns = initialColumns;
    this.setState({
      ...this.state,
      hideColumns: false,
      columns: newColumns,
    });
  };

  enterEdit = (dataItem) => {
    this.applyNewColumnsOnGrid(dataItem);

    let total = this.state.gridData.total;

    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    let columns = this.state.initialColumns;
    if (dataItem && dataItem.relation_country !== "US") {
      columns = columns.filter((col) => col.field !== "state");
    }

    this.setState({
      ...this.state,
      columns: columns,
      gridData: {
        data:
          this.state.gridData &&
          this.state.gridData.data.map((item) =>
            item.id === dataItem.id ? { ...item, inEdit: true } : item
          ),
        total: total,
      },
      initialGridData: {
        data:
          this.state.initialGridData &&
          this.state.initialGridData.data.map((item) =>
            item.id === dataItem.id ? { ...item, inEdit: true } : item
          ),
        total: total,
      },
    });
  };

  updateItem = (data, item) => {
    let index = data.findIndex(
      (p) => p === item || (item.id && p.id === item.id)
    );
    if (index >= 0) {
      //todo check again
      delete item.inEdit;
      data[index] = { ...item };
    }
  };

  handleSuccessSnackbar = () => {
    this.setState({
      ...this.state,
      successMessage:
        this.state.openSuccessMessage === true ? this.state.successMessage : "",
      openSuccessMessage: !this.state.openSuccessMessage,
    });
  };

  add = (dataItem) => {
    const submitURL = "masterEntities";
    const options = { token: window.localStorage.getItem("access_token") };

    dataItem.inEdit = undefined;
    let payload = dataItem;
    delete payload.inEdit;

    payload.supplier_flag = false;
    payload.client_flag = false;
    payload.transport_flag = false;
    payload.agent_flag = false;
    payload.sm_agent_flag = false;
    payload.sm_vendor_flag = false;
    payload.airport_flag = false;
    payload.wh_flag = false;
    payload.verified_flag = false;
    payload.can_create_bol_flag = false;
    payload.can_create_coo_flag = false;

    payload.location_flag = true;
    payload.seaport_flag = true;

    payload.relation_postal_code = "N/A";

    let errorMsg = [];
    if (payload.relation_name === undefined || payload.relation_name === "")
      errorMsg.push("Name");
    if (
      payload.relation_country === undefined ||
      payload.relation_country === ""
    )
      errorMsg.push("Country");
    if (payload.relation_city === undefined || payload.relation_city === "")
      errorMsg.push("City");
    if (
      payload.relation_location_iso_code === undefined ||
      payload.relation_location_iso_code === ""
    )
      errorMsg.push("ISO");
    if (payload.latitude === undefined || payload.latitude === "")
      errorMsg.push("Latitude");
    if (payload.longitude === undefined || payload.longitude === "")
      errorMsg.push("Longitude");

    const error2 = payload.relation_country === "US" && !payload.state;
    if (error2)
      errorMsg.push(
        "You need to provide the state field in order to create a supplier"
      );

    if (!_.isEmpty(errorMsg)) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent: `Required fields in order to create a seaport: ${errorMsg.join(
            ","
          )}`,
        },
        () => {
          setTimeout(() => {
            this.cancel(dataItem);
            this.discard(dataItem);
            this.handleErrorClose();
          }, 4000);
          return null;
        }
      );
    } else {
      const createRequest = serverApi(
        "POST",
        `${submitURL}`,
        "",
        payload,
        options
      );
      createRequest
        .then(async (createResponse) => {
          this.setState(
            {
              ...this.state,
              deleteVisible: false,
              successMessage: "The seaport was successfully created",
              openSuccessMessage: true,
            },
            () => {
              setTimeout(() => {
                this.handleSuccessSnackbar();
                this.refreshGridDataAfterCrud();
              }, 4000);
            }
          );
        })
        .catch((createError) => {
          this.setState(
            {
              ...this.state,
              openErrorModal: true,
              errorModalContent:
                createError?.response?.data?.error?.message ||
                "Please try again",
            },
            () => this.discard(dataItem, true)
          );
        });
    }
  };

  update = async (dataItem) => {
    const data = [...this.state.gridData.data];

    const updatedItem = { ...dataItem, inEdit: undefined };
    this.updateItem(data, updatedItem);

    let editPayload = updatedItem;
    delete editPayload.id;
    delete editPayload.expanded;

    editPayload.supplier_flag = dataItem.supplier_flag || false;
    editPayload.verified_flag = dataItem.verified_flag || false;
    editPayload.transport_flag = dataItem.transport_flag || false;
    editPayload.agent_flag = dataItem.agent_falg || false;
    editPayload.location_flag = dataItem.location_flag || false;
    editPayload.sm_agent_flag = dataItem.sm_agent_flag || false;
    editPayload.sm_vendor_flag = dataItem.sm_vendor_flag || false;
    editPayload.airport_flag = dataItem.airport_flag || false;
    editPayload.wh_flag = dataItem.wh_flag || false;

    if (editPayload.relation_country !== "US") editPayload.state = null;

    try {
      let errorMsg = [];

      if (
        editPayload.relation_name === undefined ||
        editPayload.relation_name === ""
      )
        errorMsg.push("Name");
      if (
        editPayload.relation_country === undefined ||
        editPayload.relation_country === ""
      )
        errorMsg.push("Country");
      if (
        editPayload.relation_city === undefined ||
        editPayload.relation_city === ""
      )
        errorMsg.push("City");
      if (
        editPayload.relation_city === undefined ||
        editPayload.relation_city === ""
      )
        errorMsg.push("City");
      if (
        editPayload.relation_location_iso_code === undefined ||
        editPayload.relation_location_iso_code === ""
      )
        errorMsg.push("ISO");
      if (editPayload.latitude === undefined || editPayload.latitude === "")
        errorMsg.push("Latitude");
      if (editPayload.longitude === undefined || editPayload.longitude === "")
        errorMsg.push("Longitude");

      const error1 =
        editPayload.relation_country === "US" && !editPayload.state;

      if (error1) {
        this.setState(
          {
            ...this.state,
            openErrorModal: true,
            errorModalContent:
              "You need to provide the state field in order to update a supplier",
          },
          () => {
            setTimeout(() => {
              this.setState(
                {
                  openErrorModal: false,
                  errorModalContent: "",
                },
                () => {
                  this.cancel(dataItem);
                }
              );
            }, 4000);
          }
        );
      } else if (!_.isEmpty(errorMsg)) {
        //error handle
        this.setState(
          {
            ...this.state,
            openErrorModal: true,
            errorModalContent: `Required fields in order to update the seaport: ${errorMsg.join(
              ","
            )}`,
          },
          () => {
            setTimeout(() => {
              this.cancel(dataItem);
            }, 4000);
          }
        );
      } else {
        removeUnwantedKeyValuesFromArr(editPayload);
        const editRequest = await AbstractEdit(
          dataItem.id,
          editPayload,
          "masterEntities",
          {
            token: window.localStorage.getItem("access_token"),
          }
        );
        if (editRequest.status === 200) {
          this.setState(
            {
              ...this.state,
              gridData: {
                data: data,
                total: this.state.gridData.total,
              },
              successMessage: "Seaport was successfully updated",
              openSuccessMessage: true,
            },
            () => {
              setTimeout(() => {
                this.handleSuccessSnackbar();
                this.refreshGridDataAfterCrud();
              }, 4000);
            }
          );
        }
      }
    } catch (e) {
      let initialGridData = this.state.initialGridData;

      this.setState(
        {
          ...this.state,
          gridData: initialGridData,
          openErrorModal: true,
          errorModalContent:
            e?.response?.data?.error?.message || "Please try again",
        },
        () => this.discard(dataItem, true)
      );
    }
  };

  removeItem(data, item) {
    let index = data.findIndex(
      (p) => p === item || item.id || p.id === item.id
    );
    if (index >= 0) {
      data.splice(index, 1);
    }
  }

  handleErrorClose = (loginError) => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      openErrorModalContent: "",
    });
  };

  discard = (dataItem, hasError = false) => {
    let data = [...this.state.gridData.data];
    const total = this.state.gridData.total;

    if (hasError) {
      data[0].inEdit = true;

      this.setState(
        {
          ...this.state,
          gridData: {
            data: data,
            total: total,
          },
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 4000);
        }
      );
    } else {
      this.removeItem(data, dataItem);

      this.setState(
        {
          ...this.state,
          gridData: {
            data: data,
            total: total,
          },
          visible: false,
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 4000);
        }
      );
    }
  };

  handleColumnsChange = (event) => {
    let columns = this.state.columns;

    let oldColumns = columns.filter((c) => c.visible).map((c) => c.field);
    let newColumns = event.target.value;

    let unique1 = oldColumns.filter((c) => newColumns.indexOf(c) === -1);
    let unique2 = newColumns.filter((c) => oldColumns.indexOf(c) === -1);

    const columnLastUpdated = unique1.concat(unique2)[0];

    let index = columns.findIndex(
      (column) => column.field === columnLastUpdated
    );
    columns[index].visible = !columns[index].visible;

    this.setState(
      {
        ...this.state,
        columns: columns,
      },
      () => {
        let columns = this.state.columns;
        window.localStorage.setItem("cols", JSON.stringify(columns));
      }
    );
  };

  refreshGridDataAfterCrud = async () => {
    let token = window.localStorage.getItem("access_token");
    let options = {
      token: token,
    };
    let refreshParams = window.localStorage.getItem("refreshParams");
    refreshParams = JSON.parse(refreshParams);

    let responseData = await serverApi(
      "GET",
      `masterEntities`,
      refreshParams,
      "",
      options
    );
    const finalData = showCreatorOnTable(responseData);

    let count;
    const countMasterEntity = await serverApi(
      "GET",
      `masterEntities/count?where=${JSON.stringify(
        refreshParams.filter.where
      )}`,
      "",
      "",
      options
    );
    if (countMasterEntity) {
      count = countMasterEntity.data.count;
    }

    let sendObj = {
      gridData: {
        data: finalData.data,
        total: count,
      },
    };

    this.dataReceived(sendObj.gridData, null);
  };

  getExportData = async () => {
    await this.exportToCsv(this.state, this.props);
  };

  exportToCsv = async (state, props) => {
    const count = state.gridData.total;
    const where = window.localStorage.getItem("exportFilter") || {};
    const numberOfCalls = Math.min(
      Math.round(count / 1000) + 1,
      5 // Limit to 5000
    );
    let finalSort = this.state.dataState?.sort?.length
      ? this.state.dataState.sort[0].field +
        " " +
        this.state.dataState.sort[0].dir
      : "id asc";

    const promises = [];
    for (let i = 0; i < numberOfCalls; i++) {
      promises.push(
        this.getOrdersPromise({
          skip: i * 1000,
          limit: 1000,
          where: !_.isEmpty(where) ? JSON.parse(where) : {},
          order: [finalSort],
        })
      );
    }

    try {
      const responses = await Promise.all(promises);
      let allData = [];
      responses.forEach((resp) => {
        allData = [...allData, ...resp.data];
      });
      if (allData.length > 0) {
        this.setState({
          ...this.state,
          exportData: allData,
        });
        // this._export.save(allData, this.state.columns);
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent:
          e?.response?.data?.error?.message || "Please try again",
      });
    }
  };

  getOrdersPromise = (filter) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    return new Promise((resolve, reject) => {
      const masterEntityRequest = serverApi(
        "GET",
        `masterEntities?filter=${JSON.stringify(filter)}`,
        "",
        "",
        options
      );
      masterEntityRequest
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  onClearFilters = () => {
    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));

    let init = JSON.stringify({ take: 100, skip: 0 });
    if (hasFinalParams && hasFinalParams.filter)
      window.localStorage.setItem("finalParams", init);

    this.dataStateChange({ data: { take: 100, skip: 0 } });
  };

  expandChange = (event) => {
    window.localStorage.setItem("expandeId", event.dataItem.id);

    //if add new vessel is true then expand cannot be done !
    if (this.state.gridData.data[0].inEdit === true) {
      return;
    } else {
      event.dataItem.expanded = !event.dataItem.expanded;
    }

    this.forceUpdate();
  };

  itemChange = (event) => {
    const { field, value } = event;

    if (field === "relation_country" && value === "US") {
      let initialColumns = this.state.initialColumns;
      let newColumns = initialColumns;
      this.setState({
        ...this.state,
        hideColumns: false,
        columns: newColumns,
      });
    } else if (field === "relation_country" && value !== "US") {
      this.setState({
        ...this.state,
        columns:
          (columnsSchema && columnsSchema()).filter(
            (col) => col.field !== "state"
          ) || [],
      });
    }

    const total = this.state.gridData.total;
    const data =
      this.state.gridData &&
      this.state.gridData.data.map((item) =>
        item.id === event.dataItem.id
          ? { ...item, [event.field]: event.value }
          : item
      );

    this.setState({
      gridData: {
        data: data,
        total: total,
      },
    });
  };

  addNew = () => {
    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    const newDataItem = { inEdit: true };

    this.setState({
      gridData: {
        data: [newDataItem, ...this.state.gridData.data],
        total: this.state.gridData.total,
      },
      visible: true,
    });
  };

  render() {
    const { gridData, columns } = this.state;
    const hasEditedItem = gridData && gridData.data.some((p) => p.inEdit);

    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    let hasColumnFilters = true;
    if (found) {
      hasColumnFilters = false;
    }
    const { hasBackofficeRead } = this.props;

    return (
      <div>
        <Grid
          {...this.state.dataState}
          {...this.state.gridData}
          filterable={hasColumnFilters}
          style={{ height: "92vh" }}
          sortable={true}
          resizable
          editField={this.editField}
          onItemChange={this.itemChange}
          onDataStateChange={this.dataStateChange}
          pageable={{
            buttonCount: 5,
            info: true,
            pageSizes: [100, 200, 500, 1000, 1500],
          }}
        >
          <GridToolbar>
            <GridContainer xs={12} direction={"row"}>
              {!hasBackofficeRead && (
                <Tooltip
                  justify={"flex-start"}
                  placement="top"
                  title={"Add Seaport Manually"}
                >
                  <IconButton onClick={this.addNew}>
                    <FontAwesomeIcon
                      color="#0D5869"
                      onClick={this.addNew}
                      size="1.6x"
                      icon={faPlus}
                    />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip
                justify={"flex-end"}
                placement="top"
                title={"Clear Column Filters"}
              >
                <IconButton
                  disabled={false}
                  aria-label=""
                  onClick={(e) => this.onClearFilters(e)}
                  look="flat"
                >
                  <FontAwesomeIcon
                    color="#0D5869"
                    size="1.6x"
                    icon={faEraser}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip
                justify={"flex-start"}
                placement="top"
                title={"Refresh Data"}
              >
                <IconButton
                  disabled={false}
                  aria-label=""
                  onClick={this.refreshGridDataAfterCrud}
                >
                  <FontAwesomeIcon color="#0D5869" size="1x" icon={faSyncAlt} />
                </IconButton>
              </Tooltip>
              <ViewSettings
                {...this.props}
                export={{
                  data: this.state.exportData || [],
                  exportFunction: this.getExportData,
                  fileName: "Seaports_",
                  columns: columnsSchema(),
                }}
                viewSettings={{
                  type: "",
                }}
                refreshSettings={{
                  data: [],
                }}
              />
              {!this.state.hideColumns ? (
                <div style={{ marginTop: hasBackofficeRead ? "-12px" : "" }}>
                  <FormControl className={customSelectStyles.selectFormControl}>
                    <InputLabel
                      htmlFor="multiple-select"
                      className={customSelectStyles.selectLabel}
                    />
                    <Select
                      autoWidth={true}
                      multiple
                      value={columns
                        .filter((column) => column.visible)
                        .map((column) => column.field)}
                      onChange={this.handleColumnsChange}
                      MenuProps={{ className: customSelectStyles.selectMenu }}
                      inputProps={{
                        name: "multipleSelect",
                        id: "multiple-select",
                      }}
                      renderValue={(selected) => "Columns"}
                    >
                      {columns
                        .filter((col) => !col.notShownInTableColumnSelection)
                        .map((column) => {
                          let name = column.title;
                          let value = column.field;
                          let isVisible = column.visible;
                          let isDisabled = column.noDisabledColumn;

                          return (
                            <MenuItem
                              style={{ backgroundColor: "white" }}
                              key={name}
                              value={value}
                              disabled={isDisabled}
                            >
                              <Checkbox color={"default"} checked={isVisible} />
                              <ListItemText
                                style={{ marginLeft: "7px" }}
                                primary={name}
                              />
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
              ) : null}
            </GridContainer>
          </GridToolbar>

          {!hasBackofficeRead && (
            <Column
              cell={this.CommandCell}
              title="Actions"
              filterable={false}
              width={hasEditedItem ? "240px" : "220px"}
            />
          )}

          {this.renderGridColumns()}
        </Grid>

        <Loader
          dataState={this.state.dataState}
          onDataRecieved={this.dataReceived}
          include={["creator"]}
          getURL={"masterEntities"}
          countURL={
            "masterEntities/count?where=%7B%22seaport_flag%22%3A%22true%22%7D"
          }
        />

        <Snackbar
          place="tc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"warning"}
          message={this.state.errorModalContent}
          open={this.state.openErrorModal}
        />

        <Snackbar
          place="tc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"success"}
          message={this.state.successMessage}
          open={this.state.openSuccessMessage}
        />
      </div>
    );
  }
}

export default SeaportsView;
