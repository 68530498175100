import React, { useRef } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
// import Icon from "components/Icon/Icon";
// import {icons} from "components/Icon/Icon";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import "./ViewSettings.scss";
import { formatDate } from "../../../lib/general/utils";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ViewSettings(props) {
  const exportRef = useRef(null);
  return (
    <>
      <div className="header-settings">
        {props.export && (
          <>
            <Tooltip placement="top" title={"Export to Excel"}>
              <IconButton
                onClick={async (event) => {
                  event.persist();
                  await props.export.exportFunction();
                  await exportRef.current.save();
                }}
              >
                <FontAwesomeIcon
                  color="#0D5869"
                  size="1.6x"
                  icon={faDownload}
                />
              </IconButton>
            </Tooltip>
            <ExcelExport
              data={props.export?.data || []}
              filterable={true}
              fileName={`${props.export.fileName}${formatDate(
                new Date(Date.now()),
                "dd_MM_yyyy_HH_mm"
              )}.xlsx`}
              headerPaddingCellOptions={{ background: "#ff0000" }}
              ref={exportRef}
            >
              {(props.export?.columns || []).map((item, index) => {
                return (
                  <ExcelExportColumn
                    key={index}
                    field={item.field}
                    title={item.title}
                    locked={item.export?.locked || false}
                    width={item.export?.width || 200}
                  />
                );
              })}
            </ExcelExport>
          </>
        )}
        {/*{props.refreshSettings && (*/}
        {/*    <Tooltip title={"Refresh"}>*/}
        {/*        <IconButton*/}
        {/*            // disabled={props.inProgress}*/}
        {/*            onClick={(event) => {*/}
        {/*                debugger*/}
        {/*               props.refreshFunction()*/}
        {/*            }}*/}
        {/*        >*/}
        {/*            <Icon icon={icons.RefreshIcon} style={{ color: "#707070" }} />*/}
        {/*        </IconButton>*/}
        {/*    </Tooltip>*/}
        {/*)}*/}
      </div>
    </>
  );
}

export default connect(
  (state) => {
    return {};
  },
  (dispatch) => bindActionCreators({}, dispatch)
)(ViewSettings);
