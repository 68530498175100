import React, { useState } from "react";
// import { useEffect } from "react";

import { Popover, Button } from "@material-ui/core";
import PrimaryTextField from "components/PrimaryTextField";
// import { Hook, Unhook } from "console-feed";
// import { useScreenshot } from "use-react-screenshot";
import { formatDate } from "../../../lib/general/utils";
import { serverApi } from "../../../networking/config";

export default function SidebarBugReport(props) {
  const [bugDesc, setBugDesc] = useState("");
  const [bugDescError, setBugDescError] = useState(false);
  // const [logs, setLogs] = useState([]);
  // const [screenshot, takeScreenshot] = useScreenshot();

  // useEffect(() => {
  //   Hook(
  //     window.console,
  //     (log) => setLogs((currLogs) => [...currLogs, log]),
  //     false
  //   );
  //   return () => Unhook(window.console);
  // }, []);

  return (
    <Popover
      open={Boolean(props.bugAnchorEl)}
      anchorEl={props.bugAnchorEl}
      onClose={() => {
        props.onClose();
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <div style={{ width: "400px", marginBottom: "5px", margin: "10px" }}>
        <h4>Please describe below the issue</h4>
        <PrimaryTextField
          value={bugDesc}
          hasError={bugDescError}
          onChange={(e) => {
            setBugDesc(e.target.value);
            setBugDescError(false);
          }}
          multiline
          rows={10}
        />
        <div>
          <Button
            variant="contained"
            classes={{ label: "sm-primary-button-label text-normal" }}
            color="primary"
            disableElevation
            onClick={async () => {
              let hasError = false;

              setBugDescError(false);

              if (bugDesc === "") {
                setBugDescError(true);
                hasError = true;
              }

              if (hasError) {
                return;
              }

              // const screenShotData = await takeScreenshot(
              //   props.mainContainerRef.current
              // );

              //replace array with logs state variable
              const consoleLogs = []
                .filter((x) => x.method === "error")
                .map((x) => (x.data.length > 0 ? x.data[0] + "<br />" : ""))
                .join("");

              const recipients = ["k.fousteris@swiftmarine.eu"];

              if (recipients.length === 0) {
                return;
              }

              /**
               *  --- REMOVED BECAUSE MS-FLOW has limitation for  33.000 character length.-----
               *  <b>Screenshot:</b><br /><br />
               *  <img src="${screenShotData}" alt="screenshot">
               **/

              let filename = `${formatDate(
                new Date(),
                "dd MMM yyyy HH:mm:ss"
              )}_Bug_Screenshot.png`;
              let attachment = [
                {
                  filename: filename,
                  // content: screenShotData.split("base64,")[1],
                  encoding: "base64",
                },
              ];

              // check/validation for microsoft flow : maximum characters length: 32768
              if (consoleLogs.length >= 32768) {
                // consoleLogs.substring(0, 32767);
                consoleLogs.substring(0, 250);
              }

              try {
                let options = {
                  token: window.localStorage.getItem("access_token"),
                };

                await serverApi(
                  "POST",
                  "emailMessages",
                  "",
                  {
                    subject: "Backoffice - Bug Report",
                    to: recipients,
                    attachments: attachment,
                    html: `
                        This is a bug report generated from Navigator.<br />
                        <br />
                        <b>User wrote:</b><br />
                        ${bugDesc}<br />
                        <br />
                        <b>Info:</b><br/>
                        Url: ${window.location.href}<br />
                        Username: ${props.user.username}<br /> 
                        User Id: ${props.user.id}<br />
                        <br />
                        <b>Console logs:</b><br />
                        ${consoleLogs || ""}<br /><br />
                      `,
                  },
                  options
                );

                setBugDesc("");
                setBugDescError(false);
                props.onClose();
              } catch (error) {
                console.log(error);
              }
            }}
          >
            Send
          </Button>
        </div>
      </div>
    </Popover>
  );
}
