import React from "react";
import { withRouter } from "react-router";

// import IconButton from "@material-ui/core/IconButton";

import "./Header.scss";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Icon from "components/Icon/Icon";
import { icons } from "components/Icon/Icon";
import Button from "@material-ui/core/Button";

// import { uiActions } from 'store/ui';

class CustomHeader extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
    };
  }

  componentDidMount() {
    this.showTitle();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.showTitle();
    }
  }

  showTitle = () => {
    let title = "";
    let path = this.props.location.pathname.toLowerCase();

    if (path.includes("app/master/clients")) {
      title = "Clients";
    } else if (path.includes("app/master/entities")) {
      title = "Master Entities";
    } else if (path.includes("app/master/vessels")) {
      title = "Vessels";
    } else if (path.includes("app/master/usermanagement")) {
      title = "Edit User Departments";
    } else if (path.includes("app/master/visibleclients")) {
      title = "Visible Clients";
    } else if (path.includes("app/master/snusers")) {
      title = "Operator Accounts";
    } else if (path === "app/master/departments") {
      title = "Departments";
    } else if (path.includes("app/master/agents")) {
      title = "Agents-Vendors";
    } else if (path.includes("/app/master/clienttogroup")) {
      title = "Client To Group";
    } else if (path.includes("/app/master/grouptoclient")) {
      title = "Group To Client";
    } else if (path.includes("/app/master/domains")) {
      title = "Domains";
    } else if (path.includes("/app/settings")) {
      title = "Settings";
    } else if (path.includes("/app/master/suppliers")) {
      title = "Suppliers";
    } else if (path.includes("/app/master/deps")) {
      title = "Departments";
    } else if (path.includes("/app/master/operators")) {
      title = "Operators";
    } else if (path.includes("/app/master/departments/edit/")) {
      title = "User Departments";
    } else if (path.includes("app/master/user-clients")) {
      title = "Client Users";
    } else if (path.includes("app/master/user-to-clients/edit")) {
      title = "Edit user clients";
    } else if (path.includes("app/master/roles")) {
      title = "Roles";
    } else if (path.includes("app/master/users/all")) {
      title = "All users";
    } else if (path === "/app/master/whusers") {
      title = "Warehouse users";
    } else if (path === "/app/master/boxes") {
      title = "Boxes";
    } else if (path === "/app/master/orders") {
      title = "Orders";
    } else if (path === "/app/master/dispatches") {
      title = "Dispatches";
    } else if (path.includes("/app/master/backofficelogs")) {
      title = "Backoffice Log";
    } else if (path.includes("/app/master/agentusers")) {
      title = "Agent users";
    } else if (path.includes("/app/master/wsusers")) {
      title = "Websocket users";
    } else if (path.includes("/app/master/airports")) {
      title = "Airports";
    } else if (path === "/app/master/whmappings") {
      title = "If Warehouse Mappings";
    } else if (path.includes("/app/master/seaports")) {
      title = "Seaports";
    } else if (path.includes("/app/master/longrunningsesssions")) {
      title = "Long Running Sessions";
    } else if (path.includes("/app/master/fleets")) {
      title = "Fleets";
    } else if (path.includes("/app/master/assets")) {
      title = "Assets";
    } else if (path.includes("/app/master/carriers")) {
      title = "Carriers";
    } else if (path.includes("/app/master/warehouses")) {
      title = "Warehouses";
    } else if (path.includes("/app/master/modelsettingdefinitions")) {
      title = "Model Setting Definitions";
    } else if (path.includes("/app/master/approvalactiontiers")) {
      title = "Approval Action Tiers";
    }

    this.setState({
      title: title,
    });
  };

  //todo uncomment for toggle sidebar button
  render() {
    return (
      <div className="header">
        <span className="title">{this.state.title}</span>
        {this.props.children}
        <Button
          onClick={() => {
            this.props.setSidebarCollapsed(!this.props.isSidebarCollapsed);
            this.props.setSidebarLocked(!this.props.isSidebarCollapsed);
          }}
          style={{ marginLeft: "7px", color: "#0d5869" }}
        >
          <Icon icon={icons.MenuIcon} />
        </Button>
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      // isSidebarCollapsed: state.ui.isSidebarCollapsed,
      // isSidebarLocked: state.ui.isSidebarLocked
    };
  },
  (dispatch) =>
    bindActionCreators(
      {
        // ...uiActions
      },
      dispatch
    )
)(withRouter(CustomHeader));
