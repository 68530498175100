import React from "react";
import ReactDOM from "react-dom";
import { toODataString } from "@progress/kendo-data-query";
import { serverApi } from "../../../networking/config";
import _ from "lodash";

export class ProductsLoader extends React.Component {
  lastSuccess = "";
  pending = "";

  requestDataIfNeeded = async () => {
    try {
      function escapeRegExp(text) {
        return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
      }

      if (
        this.pending ||
        toODataString(this.props.dataState) === this.lastSuccess
      ) {
        return;
      }
      this.pending = toODataString(this.props.dataState);

      let options = {
        token: this.props.token,
      };

      let params = { ...this.props.dataState };

      params["skip"] = this.props.dataState.skip;
      params["limit"] = this.props.dataState.take;
      params["where"] =
        this.props.dataState.filter !== null ? this.props.dataState.filter : {};

      if (!_.isEmpty(this.props.dataState.sort)) {
        params["order"] =
          this.props.dataState.sort[0].field +
          " " +
          this.props.dataState.sort[0].dir;
        delete params.sort;
      }

      delete params.take;
      let filterColumn =
        params && (params.filter === null || params.filter === undefined)
          ? ""
          : params.filter.filters[0] !== null
          ? params.filter.filters[0].field
          : "";

      let filterValue =
        params && (params.filter === null || params.filter === undefined)
          ? ""
          : params.filter.filters[0] !== null
          ? params.filter.filters[0].value
          : "";

      // let finalFilterValue = null;

      // if (params.filter) {
      //   finalFilterValue =
      //     params.filter.filters[0].field === "reqPriorityID"
      //       ? _.find([], ["lov_value", `${filterValue}`]).id
      //       : filterValue;
      // } else {
      //   finalFilterValue = "";
      // }

      // const includeParam = !_.isEmpty(this.state[domain].includeArray) ? this.state[domain].includeArray : '';
      let whereFinal = [];
      let booleanColumns = [
        "supplier_flag",
        "client_flag",
        "transport_flag",
        "agent_flag",
        "sm_agent_flag",
        "sm_vendor_flag",
        "location_flag",
        "airport_flag",
        "wh_flag",
        "seaport_flag",
        "stocktbc_visible",
        "isBillingEntity",
      ];

      if (params.filter === null || params.filter === undefined) {
        whereFinal.push({});
      } else {
        if (params.filter.filters.length > 1) {
          _.forEach(params.filter.filters, (temp) => {
            //fix the issue with & when searching...fucking loopback 3
            let finalValue = temp.value;
            if (typeof finalValue !== "boolean") {
              finalValue = escapeRegExp(finalValue);
            }

            if (
              (typeof finalValue === "string" ||
                finalValue instanceof String) &&
              finalValue.includes("&")
            ) {
              finalValue = finalValue.replaceAll("&", ".");
            } else if (typeof finalValue === "boolean") {
              finalValue = temp.value;
            }

            if (booleanColumns.includes(temp.field)) {
              whereFinal.push({ [`${temp.field}`]: `${finalValue}` });
            } else {
              whereFinal.push({
                [`${temp.field}`]: {
                  regexp: `/^${finalValue}/i`,
                },
              });
            }
          });
        } else {
          let isFirstLoad = window.localStorage.getItem("isFirstLoad");
          if (booleanColumns.includes(filterColumn) && isFirstLoad === "true") {
            whereFinal = [
              {
                [`${filterColumn}`]: `${filterValue}`,
              },
            ];
          } else {
            //fix the issue with & when searching...fucking loopback 3
            let finalValue = filterValue;
            finalValue = escapeRegExp(finalValue);
            if (finalValue?.includes("&")) {
              finalValue = finalValue.replaceAll("&", ".");
            }
            whereFinal = [
              {
                [`${filterColumn}`]: {
                  regexp: `/^${finalValue}/i`,
                },
              },
            ];
          }
        }
      }

      let params2 = {
        filter: {
          include: this.props.include,
          // where: {
          //   and: whereFinal,
          // },
          where: {
            and: whereFinal.map((filter) => {
              if (filter.paymentTermsTypeId) {
                const filterValue = filter.paymentTermsTypeId.regexp
                  .replace(/^\/\^/, "") // Remove regex start
                  .replace(/\/i$/, "") // Remove regex end
                  .replace(/\\/g, ""); // Remove any escape characters

                const matchedType = this.props.paymentTermsLovTypes.find(
                  (x) => x.lov_value.trim() === filterValue.trim()
                );

                return {
                  paymentTermsTypeId: matchedType ? matchedType.id : 0, // Replace lov_value with the ID
                };
              }
              return filter;
            }),
          },
          order: [`${params.order}` !== [] ? `${params.order}` : []],
          limit: `${params.limit}`,
          skip: `${params.skip}`,
        },
      };

      if (params2.filter.order[0] === "undefined") {
        delete params2.filter.order;
      }

      let finalParams = { ...params2 };
      let flag = window.localStorage.getItem("flagSet");
      let responseData;

      if (flag) {
        // console.log(finalParams)
        let params = {
          filter: {
            include: [],
            where: {
              and: [
                {
                  supplier_flag: true,
                },
                {
                  data_validation_error_flg: true,
                },
              ],
            },
            limit: finalParams.filter.limit,
            skip: finalParams.filter.skip,
          },
        };
        responseData = await serverApi(
          "GET",
          `masterEntities`,
          params,
          "",
          options
        );
      } else {
        window.localStorage.setItem(
          "exportFilter",
          JSON.stringify(finalParams.filter.where)
        );
        responseData = await serverApi(
          "GET",
          `masterEntities`,
          finalParams,
          "",
          options
        );
      }

      //TODO fix count calls with where !!!
      let count;
      if (this.props.dataState.filter) {
        let x = JSON.stringify(this.props.dataState.filter);
        let t = JSON.stringify(
          JSON.parse(window.localStorage.getItem("finalParams")).filter
        );

        if (x !== t) {
          const countMasterEntity = await serverApi(
            "GET",
            "masterEntities/count",
            "",
            "",
            options
          );
          if (countMasterEntity) {
            count = countMasterEntity.data.count;
          }
        } else {
          const countMasterEntity = await serverApi(
            "GET",
            `masterEntities/count?where=${JSON.stringify(
              params2.filter.where
            )}`,
            "",
            "",
            options
          );
          if (countMasterEntity) {
            count = countMasterEntity.data.count;
          }
        }
      } else {
        if (true) {
          const countMasterEntity = await serverApi(
            "GET",
            "masterEntities/count",
            "",
            "",
            options
          );
          if (countMasterEntity) {
            count = countMasterEntity.data.count;
          }
        }
      }

      if (flag) {
        if (flag === "data_validation_error_flg") {
          const countMasterEntity = await serverApi(
            "GET",
            `masterEntities/count?where={"and":[{"supplier_flag": true}, {"data_validation_error_flg": true}]}`,
            "",
            "",
            options
          );
          if (countMasterEntity) {
            count = countMasterEntity.data.count;
          }
        } else if (flag === "supplier_flag") {
          const countMasterEntity = await serverApi(
            "GET",
            `masterEntities/count?where={"supplier_flag": true}`,
            "",
            "",
            options
          );
          if (countMasterEntity) {
            count = countMasterEntity.data.count;
          }
        } else if (flag === "client_flag") {
          const countMasterEntity = await serverApi(
            "GET",
            `masterEntities/count?where={"client_flag": true}`,
            "",
            "",
            options
          );
          if (countMasterEntity) {
            count = countMasterEntity.data.count;
          }
        } else if (flag === "agent_flag") {
          const countMasterEntity = await serverApi(
            "GET",
            `masterEntities/count?where={"agent_flag": true}`,
            "",
            "",
            options
          );
          if (countMasterEntity) {
            count = countMasterEntity.data.count;
          }
        } else if (flag === "wh_flag") {
          const countMasterEntity = await serverApi(
            "GET",
            `masterEntities/count?where={"wh_flag": true}`,
            "",
            "",
            options
          );
          if (countMasterEntity) {
            count = countMasterEntity.data.count;
          }
        }
      }

      window.localStorage.setItem("refreshParams", JSON.stringify(finalParams));

      this.lastSuccess = this.pending;
      this.pending = "";

      if (toODataString(this.props.dataState) === this.lastSuccess) {
        if (window.localStorage.getItem("finalParams")) {
          if (
            JSON.stringify(
              JSON.parse(window.localStorage.getItem("finalParams")).filter
            ) !== ""
          ) {
            let final = JSON.parse(
              window.localStorage.getItem("finalParams")
            ).filter;

            this.props.onDataRecieved.call(
              undefined,
              {
                data: responseData.data,
                total: count === undefined ? responseData.data.length : count,
              },
              final
            );
          }
        } else {
          this.props.onDataRecieved.call(undefined, {
            data: responseData.data,
            total: count === undefined ? responseData.data.length : count,
          });
        }
      } else {
        this.requestDataIfNeeded();
      }
    } catch (error) {
      console.log("err:", error);
    }
  };

  render() {
    this.requestDataIfNeeded();

    return this.pending && <LoadingPanel />;
  }
}

class LoadingPanel extends React.Component {
  render() {
    const loadingPanel = (
      <div className="k-loading-mask">
        <span className="k-loading-text">Loading . . .</span>
        <div className="k-loading-image" />
        <div className="k-loading-color" />
      </div>
    );

    const gridContent = document && document.querySelector(".k-grid-content");
    return gridContent
      ? ReactDOM.createPortal(loadingPanel, gridContent)
      : loadingPanel;
  }
}
