export const columnsSchema = () => [
  {
    field: "id",
    title: "ID",
    visible: true,
    minWidth: 150,
    sortable: true,
    filterable: true,
    locked: "locked",
  },
  {
    field: "name",
    title: "Name",
    visible: true,
    minWidth: 170,
    sortable: true,
    filterable: true,
    locked: "locked",
  },
  {
    field: "type",
    title: "Type",
    visible: true,
    minWidth: 170,
    sortable: true,
    filterable: true,
    locked: "locked",
  },
  {
    field: "location",
    title: "Location",
    visible: true,
    minWidth: 140,
    sortable: true,
    filterable: true,
    locked: "locked",
  },
  {
    field: "email",
    title: "Email",
    visible: true,
    minWidth: 170,
    sortable: true,
    filterable: true,
    locked: "locked",
  },
  {
    field: "agent.relation_name",
    title: "Agent Name",
    visible: true,
    minWidth: 170,
    sortable: false,
    filterable: false,
    locked: "locked",
    editable: "false",
  },
  {
    field: "agent.id",
    title: "Agent Id",
    visible: true,
    minWidth: 170,
    sortable: false,
    filterable: false,
    locked: "locked",
    editable: "false",
  },
];
