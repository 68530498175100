/* eslint-disable */
import {applyMiddleware, compose, createStore} from 'redux';
import thunkMiddleware from 'redux-thunk';
import applicationReducers from './reducers/applicationReducers';
import {createLogger} from 'redux-logger';
import {persistStore} from 'redux-persist';

/**
 * Logger for Redux for development reasons
 */
const loggerMiddleware = createLogger();

/**
 * Audit log middleware functionality for recording different
 * behaviour of logged-in users.
 * @param dispatch
 * @param getState
 * @returns {function(*): Function}
 */
export function logToAgent({dispatch, getState}) {
  return next => action => {
    if (action.type === 'AUDIT_LOG_AGENT') {

      let staticAuditLogData = {
        applicationName: 'QUOTATION_WEB_APP',
        username: 'admin',
        timestamp: new Date().toISOString(),
        ip: '123',
        browserAgent: '123',
        os: '123',
        sessionId: window.localStorage.getItem('access_token') || '123',
      };

      const requestData = {...staticAuditLogData, ...action.payload};

      try {
        /**
         * Implement a POST call to save something in Oracle db
         */
        // InsertMeetup({name: 'audit', city: 'audit', address: 'audit'})

      } catch (error) {
        console.log(error);
      }
    }

    return next(action);

  };
}

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = ({}) => {

  /**
   * Redux middleware configuration depending the NODE_ENV variable
   */
  let middleware = [];

  /**
   * condition to know whether app is in production or development
   */
  if (process.env.NODE_ENV !== 'production') {
    // middleware = [thunkMiddleware, loggerMiddleware, logToAgent];
    middleware = [thunkMiddleware, logToAgent];
  } else {
    middleware = [thunkMiddleware, logToAgent];
  }

  /**
   * Store Instantiation
   */
  const store = createStore(
    applicationReducers,
    composeEnhancer(applyMiddleware(...middleware)),
  );

  return store;
};

let store, persistor = null;

store = configureStore({});
persistor = persistStore(store);

export {store, persistor};
