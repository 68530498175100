import React from "react";
import {
  Grid,
  GridColumn as Column,
  GridDetailRow,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { columnsSchema } from "./schema";
import { MyCommandCell } from "../CellHOC";
import { serverApi } from "../../../../../networking/config";
import _ from "lodash";
import { DropDownCell } from "../../../../CRM/View/dropDownCell";
import GridContainer from "../../../../../components/Grid/GridContainer";
import { IconButton, Tooltip } from "@material-ui/core";
import { Window } from "@progress/kendo-react-dialogs";
import BlockUi from "react-block-ui";
import { Error } from "@progress/kendo-react-labels";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import {
  AbstractDelete,
  AbstractEdit,
} from "../../../../../networking/apiCalls";
import RegularButton from "../../../../../components/CustomButtons/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";

class Approvers extends GridDetailRow {
  editField = "inEdit";
  CommandCell;
  _export;

  constructor(props) {
    super(props);

    this.state = {
      approvers_data: [],
      approverValue: "",
      visibleWindow: false,
      isDropOpened: false,
      columns: (columnsSchema && columnsSchema()) || [],
      initialColumns: (columnsSchema && columnsSchema()) || [],
      gridData: {
        data: [],
        total: 10,
      },
      initialGridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 10,
        skip: 0,
      },
      openErrorModal: false,
      errorModalContent: "",
      delay: 400,
      loading: false,
      contacts: [],
      windowError: false,
      windowErrorMessage: "",
      disableEditButton: true,

      //create properties
      contact_name: "",
      contact_country: "",
      contact_email: "",
      contact_type: "",
      wh_notification_flg: false,
      notify_stock_email: false,
      notify_stock_ws: false,
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      disableEditButton: false,
      showSecondAction: true,
      showFirstAction: true,
    });
  }

  getUsersPromise = (filter) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    return new Promise((resolve, reject) => {
      const req = serverApi(
        "GET",
        `snUsers?filter=${JSON.stringify(filter)}`,
        "",
        "",
        options
      );
      req
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  async componentDidMount() {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    const attachedData = await serverApi(
      "GET",
      `approvalActionTiers/${this.props.dataItem.id}/approvalActionTierApprovers`,
      null,
      "",
      options
    );
    let clonedAttachedData = _.cloneDeep(attachedData);


    let allUsers = {
      data: [],
    };

    let howMany = await serverApi("GET", "snUsers/count", "", "", "", options);
    const numberOfCalls = Math.min(
      Math.round(howMany.data.count / 1000) + 1,
      5 // Limit to 5000
    );

    const promises = [];
    for (let i = 0; i < numberOfCalls; i++) {
      promises.push(
        this.getUsersPromise({
          skip: i * 1000,
          limit: 1000,
          order: ["id asc"],
          include: [
            {
              relation: "userToDepts",
              scope: {
                include: [
                  {
                    relation: "department",
                  },
                ],
              },
            },
          ],
        })
      );
    }

    try {
      let responses = await Promise.all(promises);
      responses.length && responses.map((x) => x.data).flat();

      responses.forEach((resp) => {
        _.forEach(resp.data, (temp) => {
          allUsers.data.push({
            ...temp,
          });
        });
      });
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent: e.response.data.error.message || "Please try again",
      });
    }

    const clonedData = _.cloneDeep(allUsers);
    this.setState({ ...this.state, usersOfDepartment: clonedData.data });

    const userIdToUsername = {};
    allUsers.data.forEach((user) => {
      userIdToUsername[user.id] = user.username;
    });

    if (attachedData && attachedData.data.length === 1) {
      let finalAttachedClients = [];
      _.forEach(clonedAttachedData.data, (temp) =>
        finalAttachedClients.push({
          id: temp.id,
          approvalActionAmountTierId: temp.approvalActionAmountTierId || "N/A",
          priority: temp.priority || "N/A",
          username: userIdToUsername[temp.userId] || "N/A",
          userId: temp.userId || "N/A",
          created: temp.created || "N/A",
          updated: temp.updated || "N/A",
          updatedBy: temp.updatedBy || "N/A",
          createdBy: temp.createdBy || "N/A",
        })
      );

      this.setState({
        ...this.state,
        ID: this.props.dataItem.id,
        gridData: {
          data: [finalAttachedClients[0]],
          total: 1,
        },
        initialGridData: {
          data: [finalAttachedClients[0]],
          total: 1,
        },
        valueClientName: "",
        blocking: false,
      });
    } else if (clonedAttachedData && clonedAttachedData.data.length > 1) {
      const finalData = clonedAttachedData.data.map((temp) => ({
        id: temp.id,
        approvalActionAmountTierId: temp.approvalActionAmountTierId || "N/A",
        priority: temp.priority || "N/A",
        userId: temp.userId || "N/A",
        username: userIdToUsername[temp.userId] || "N/A",
        created: temp.created || "N/A",
        updated: temp.updated || "N/A",
        updatedBy: temp.updatedBy || "N/A",
        createdBy: temp.createdBy || "N/A",
      }));

      this.setState({
        ...this.state,
        clientID: this.props.dataItem.id,
        gridData: {
          data: finalData,
          total: finalData.length,
        },
        initialGridData: {
          data: finalData,
          total: finalData.length,
        },
        blocking: false,
      });
    } else {
      this.setState({
        ...this.state,
        clientID: this.props.dataItem.id,
        gridData: {
          data: [],
          total: 0,
        },
        initialGridData: {
          data: [],
          total: 0,
        },
        blocking: false,
      });
    }
  }

  renderGridColumns = (dataItem, changeTheCellComponent) => {
    let selectionCol = [];
    let normalCols = [];
    let finalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            width="65px"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.isFilterBoolean === "yes") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              cell={DropDownCell}
            />
          );
        } else if (temp.field === "priority") {
          if (!dataItem.inEdit && !this.state.gridData.data.find(x => x.approvalActionAmountTierId === dataItem.id)?.inEdit
          ) {
            return (
              <Column
                field={temp.field}
                filterable={temp.filterable}
                title={temp.title}
                width={temp.minWidth || "auto"}
                filter={temp.filter}
                visible={temp.visible}
                minGridWidth={"400"}
                editable={true}
              />
            )
          }
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={true}
              cell={(props) => {
                const onPriorityChange = (e) => {
                  const newValue = e.value;
                  const updatedData = this.state.gridData.data.map((item) => {
                    if (item.inEdit && item.id === props.dataItem.id) {
                      return { ...item, priority: newValue };
                    }
                    return item;
                  });
                  this.setState({
                    gridData: { ...this.state.gridData, data: updatedData },
                  });
                };
                return (
                  <NumericTextBox
                    style={{ width: "500px" }}
                    name="priority"
                    value={props?.dataItem?.priority || ""}
                    onChange={onPriorityChange}
                  />
                );
              }}
            />
          );
        } else {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={temp.field === "id" || temp.editable ? false : true}
            />
          );
        }
      });

    finalCols = [...selectionCol, ...normalCols];
    return finalCols;
  };

  toggleWindow = async () => {
    this.setState({
      visibleWindow: !this.state.visibleWindow,
    });
  };

  applyInitialColumnsOnGrid = () => {
    let initialColumns = this.state.initialColumns;
    let newColumns = initialColumns;
    this.setState({
      ...this.state,
      hideColumns: false,
      columns: newColumns,
    });
  };

  applyNewColumnsOnGrid = (dataItem) => {
    let columns = this.state.initialColumns;
    let newColumns = columns.filter((x) => x.field === "priority");
    newColumns.forEach((x) => (x.minWidth = 290));

    this.setState(
      {
        ...this.state,
        hideColumns: true,
        columns: newColumns,
        initialColumns: columns,
      },
      () => { }
    );
  };

  enterEdit = (dataItem) => {
    this.applyNewColumnsOnGrid(dataItem);

    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    let total = this.state.gridData.total;

    this.setState({
      gridData: {
        data:
          this.state.gridData &&
          this.state.gridData.data.map((item) =>
            item.id === dataItem.id ? { ...item, inEdit: true } : item
          ),
        total: total,
      }
    });
  };

  discard = (dataItem, hasError = false) => {
    let data = [...this.state.gridData.data];
    const total = this.state.gridData.total;

    if (hasError) {
      data[0].inEdit = true;
      this.setState({
        gridData: {
          data: data,
          total: total,
        },
      });
    } else {
      this.removeItem(data, dataItem);

      this.setState({
        gridData: {
          data: data,
          total: total,
        },
        visible: false,
      });
    }
  };

  cancel = (dataItem) => {
    const originalItem =
      this.state.initialGridData &&
      this.state.initialGridData.data.find((p) => p.id === dataItem.id);
    originalItem.inEdit = false;

    const data =
      this.state.gridData &&
      this.state.gridData.data.map((item) =>
        item.id === originalItem.id ? originalItem : item
      );

    const total = this.state.gridData.total;

    this.setState(
      {
        ...this.state,
        gridData: {
          data: data,
          total: total,
        },
        openErrorModal: false,
        openErrorModalContent: "",
      },
      () => this.applyInitialColumnsOnGrid()
    );
  };

  removeItem(data, item) {
    let index = data.findIndex((p) => p === item || p.id === item.id);
    if (index >= 0) {
      data.splice(index, 1);
    }
  }

  remove = async (dataItem) => {
    const data = [...this.state.gridData.data];
    let index = data.findIndex((p) => p === dataItem || p.id === dataItem.id);
    if (index >= 0) {
      data.splice(index, 1);
    }

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    // API call to delete user
    const url = `approvalActionTiers/${this.props.dataItem.id}/approvalActionTierApprovers/${dataItem.id}`;

    try {
      const deleteItemRequest = await serverApi(
        "DELETE",
        url,
        "",
        "",
        options,
        "",
        ""
      );

      if (deleteItemRequest.status === 204) {
        //clear the column filters if grid data is 0 or let them if grid data is >= 1
        if (data.length <= 0) {
          this.setState({
            ...this.state,
            visibleWindow: false,
            gridData: {
              data: data,
              total: data.length,
            },
            initialGridData: {
              data: data,
              total: data.length,
            },
            dataState: {
              skip: 0,
              take: 10,
            },
          });
        } else {
          this.setState({
            ...this.state,
            visibleWindow: false,
            gridData: {
              data: data,
              total: data.length,
            },
            initialGridData: {
              data: data,
              total: data.length,
            },
          });
        }
      }
    } catch (e) {
      this.setState({
        ...this.state,
        visibleWindow: false,
        openErrorModal: true,
        errorModalContent:
          e?.response?.data?.error?.message ||
          "Remove approver has failed.Please try again and if the problem persist contact the administrator.",
      });
    }
  };

  add = (dataItem) => { };

  addNew = () => {
    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    const newDataItem = { inEdit: true };

    this.setState({
      gridData: {
        data: [newDataItem, ...this.state.gridData.data],
        total: this.state.gridData.total,
      },
      visibleWindow: true,
    });
  };

  handleErrorClose = (loginError) => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      openErrorModalContent: "",
    });
  };

  handleSuccessSnackbar = () => {
    this.setState({
      ...this.state,
      successMessage:
        this.state.openSuccessMessage === true ? this.state.successMessage : "",
      openSuccessMessage: !this.state.openSuccessMessage,
    });
  };

  update = async (dataItem) => {
    const data = [...this.state.gridData.data];
    const updatedItem = { ...dataItem, inEdit: undefined };

    this.updateItem(data, updatedItem);

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let editPayload = updatedItem;
    if (editPayload.expanded) delete editPayload.expanded;

    if (editPayload.priority === undefined || editPayload.priority === "") {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent: "Priority is required when updating the record.",
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 3000);
        }
      );
    } else {
      editPayload = {
        priority: parseInt(updatedItem.priority),
      };
      try {
        const editUrl = `approvalActionTiers/${this.props.dataItem.id}/approvalActionTierApprovers/${dataItem.id}`;
        const editRequest = await serverApi(
          "PUT",
          editUrl,
          "",
          editPayload,
          options,
          "",
          ""
        );

        // const countMasterEntity = await serverApi('GET', 'assets/count', '', '', options)

        if (editRequest.status === 200) {
          this.setState(
            {
              ...this.state,
              gridData: {
                data: data,
                total: this.state.gridData.total,
              },
              successMessage: "Record updated successfully",
              openSuccessMessage: true,
            },
            () => {
              setTimeout(() => {
                this.handleSuccessSnackbar();
                this.applyInitialColumnsOnGrid();
                this.fetchLatestData();
              }, 900);
            }
          );
        }
      } catch (e) {
        let initialGridData = this.state.initialGridData;

        this.setState(
          {
            ...this.state,
            gridData: initialGridData,
            openErrorModal: true,
            errorModalContent:
              e.response.data.error.message || "Please try again",
          },
          () => this.discard(dataItem, true)
        );
      }
    }
  };

  updateItem = (data, item) => {
    let index = data.findIndex(
      (p) => p === item || (item.id && p.id === item.id)
    );
    if (index >= 0) {
      data[index] = { ...item };
    }
  };

  itemChange = (event) => {
    const total = this.state.gridData.total;
    const data =
      this.state.gridData &&
      this.state.gridData.data.map((item) =>
        item.id === event.dataItem.id
          ? { ...item, [event.field]: event.value }
          : item
      );

    this.setState({
      gridData: {
        data: data,
        total: total,
      },
    });
  };

  closeWindow = () => {
    this.setState({
      ...this.state,
      visibleWindow: false,
    });
  };


  fetchLatestData = async () => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    const attachedData = await serverApi(
      "GET",
      `approvalActionTiers/${this.props.dataItem.id}/approvalActionTierApprovers`,
      null,
      "",
      options
    );
    let clonedAttachedData = _.cloneDeep(attachedData);


    let allUsers = {
      data: [],
    };

    let howMany = await serverApi("GET", "snUsers/count", "", "", "", options);
    const numberOfCalls = Math.min(
      Math.round(howMany.data.count / 1000) + 1,
      5 // Limit to 5000
    );

    const promises = [];
    for (let i = 0; i < numberOfCalls; i++) {
      promises.push(
        this.getUsersPromise({
          skip: i * 1000,
          limit: 1000,
          order: ["id asc"],
          include: [
            {
              relation: "userToDepts",
              scope: {
                include: [
                  {
                    relation: "department",
                  },
                ],
              },
            },
          ],
        })
      );
    }

    try {
      let responses = await Promise.all(promises);
      responses.length && responses.map((x) => x.data).flat();

      responses.forEach((resp) => {
        _.forEach(resp.data, (temp) => {
          allUsers.data.push({
            ...temp,
          });
        });
      });
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent: e.response.data.error.message || "Please try again",
      });
    }

    const clonedData = _.cloneDeep(allUsers);
    this.setState({ ...this.state, usersOfDepartment: clonedData.data });

    const userIdToUsername = {};
    allUsers.data.forEach((user) => {
      userIdToUsername[user.id] = user.username;
    });

    if (attachedData && attachedData.data.length === 1) {
      let finalAttachedClients = [];
      _.forEach(clonedAttachedData.data, (temp) =>
        finalAttachedClients.push({
          id: temp.id,
          approvalActionAmountTierId: temp.approvalActionAmountTierId || "N/A",
          priority: temp.priority || "N/A",
          username: userIdToUsername[temp.userId] || "N/A",
          userId: temp.userId || "N/A",
          created: temp.created || "N/A",
          updated: temp.updated || "N/A",
          updatedBy: temp.updatedBy || "N/A",
          createdBy: temp.createdBy || "N/A",
        })
      );

      this.setState({
        ...this.state,
        ID: this.props.dataItem.id,
        gridData: {
          data: [finalAttachedClients[0]],
          total: 1,
        },
        initialGridData: {
          data: [finalAttachedClients[0]],
          total: 1,
        },
        valueClientName: "",
        blocking: false,
      });
    } else if (clonedAttachedData && clonedAttachedData.data.length > 1) {
      const finalData = clonedAttachedData.data.map((temp) => ({
        id: temp.id,
        approvalActionAmountTierId: temp.approvalActionAmountTierId || "N/A",
        priority: temp.priority || "N/A",
        userId: temp.userId || "N/A",
        username: userIdToUsername[temp.userId] || "N/A",
        created: temp.created || "N/A",
        updated: temp.updated || "N/A",
        updatedBy: temp.updatedBy || "N/A",
        createdBy: temp.createdBy || "N/A",
      }));

      this.setState({
        ...this.state,
        clientID: this.props.dataItem.id,
        gridData: {
          data: finalData,
          total: finalData.length,
        },
        initialGridData: {
          data: finalData,
          total: finalData.length,
        },
        blocking: false,
      });
    } else {
      this.setState({
        ...this.state,
        clientID: this.props.dataItem.id,
        gridData: {
          data: [],
          total: 0,
        },
        initialGridData: {
          data: [],
          total: 0,
        },
        blocking: false,
      });
    }
  }

  onBlur = () => {
    const matchedVessels = (this.state.approvers_data || []).filter(
      (x) => x.username === this.state.approverValue
    );

    if (matchedVessels.length === 1) {
      this.updateVesselDetails(matchedVessels[0]);
    }

    this.setState({ openedVesselNames: false });
  };

  fetchAutocompleteData = (filterValue) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    const fetchDropdownData = serverApi(
      "GET",
      `snUsers`,
      {
        filter: {
          where: {
            and: [
              {
                username: { regexp: `/${filterValue}/i` },
              },
              { status: "ACTIVE" },
              // {
              //   id: { inq: this.state.usersOfDepartment.map((x) => x.userID) },
              // },
            ],
          },
          order: ["username asc"],
        },
      },
      "",
      options
    );

    fetchDropdownData
      .then((response) => {
        const sanitizedData = (response.data || []).filter(
          (x) => x && x.username // Ensure object and `username` exist
        );

        this.setState({
          approvers_data: sanitizedData,
        });
      })
      .catch((error) => {
        console.error("Error in fetchDropdownData:", error);
      });
  };

  filterData = (value, name) => {
    const data = this.state.approvers_data || [];
    const filter = {
      value: value || "",
      operator: "contains",
      ignoreCase: true,
    };

    console.log("Filtering data:", { data, filter });

    let results = [];
    try {
      results = filterBy(data, filter);
    } catch (error) {
      console.error("Error during filtering:", error);
    }
    return results;
  };

  onChange = (event) => {
    //prevent enter keyboard
    if (
      event.nativeEvent.type === "keydown" &&
      event.nativeEvent.keyCode === 13
    )
      return;

    const { value } = event.target;
    if (value === "") {
      this.setState({
        openedVesselNames: false,
        approverValue: value,
      });
    } else {
      this.setState(
        {
          openedVesselNames: false,
          approverValue: value,
          windowError: false,
          windowErrorMessage: "",
        },
        () => {
          this.onAutoCompleteChange(event, value);
        }
      );
    }
  };

  onAutoCompleteChange = (event, value) => {
    const { name } = event.target;

    let stateData = {};

    stateData =
      value.length < 3
        ? {
          data:
            this.state.approvers_data.length > 0
              ? this.state.approvers_data.map((x) => x.username)
              : [],
          openedVesselNames: false,
        }
        : { data: this.filterData(value, name), openedVesselNames: true };

    let eventType = event.nativeEvent.type;

    let valueSelected =
      eventType === "click" ||
      (eventType === "keydown" && event.nativeEvent.keyCode === 13);

    if (valueSelected) {
      let userId =
        (this.state.approvers_data &&
          this.state.approvers_data.length === 1 &&
          this.state.approvers_data[0].id) ||
        (this.state.approvers_data &&
          this.state.approvers_data.length > 1 &&
          this.state.approvers_data.filter(
            (x) => x.username === this.state.approverValue
          )[0].id);

      this.setState({
        ...this.state,
        userId: userId,
      });
    }

    if (valueSelected && stateData.data.includes(value)) {
      stateData.openedVesselNames = false;
    }

    this.setState(
      {
        approverValue: value,
        ...stateData,
      },
      () => {
        if (value?.length >= 3) {
          this.fetchAutocompleteData(value);
        }
      }
    );
  };

  listNoDataRender = (element) => {
    const noData = (
      <h4 style={{ fontSize: "1em" }}>
        <span className="k-icon k-i-warning" style={{ fontSize: "2.5em" }} />
        <br />
        <br />
        No approvers found
      </h4>
    );

    return React.cloneElement(element, { ...element.props }, noData);
  };

  onDialogInputChange = (event, uniqueKey) => {
    const value = event.value || ""; // Capture numeric value
    this.setState((prevState) => ({
      ...prevState,
      windowError: false,
      windowErrorMessage: "",
      [uniqueKey]: value, // Update state dynamically for the specific key
    }));
  };

  saveHandler = async (e) => {
    try {
      let options = {
        token: window.localStorage.getItem("access_token"),
      };

      const payload = {
        userId: this.state.userId,
        priority: this.state["priority"] || null,
      };

      const userExists = this.state.gridData.data.some(
        (item) => item.userId === payload.userId
      );

      if (userExists) {
        // If userId already exists, show a warning and return
        this.setState({
          ...this.state,
          approverValue: "",
          priority: "",
          windowError: true,
          windowErrorMessage:
            "This approver already exists. Please choose another user.",
        });
        return;
      }

      if (!payload.userId || !payload.priority) {
        this.setState(
          {
            ...this.state,
            windowError: true,
            windowErrorMessage: "Priority and username are required",
          },
          () => {
            // this.toggleWindow();
          }
        );

        return;
      } else {
        // API call to add user
        const url = `approvalActionTiers/${this.props.dataItem.id}/approvalActionTierApprovers`;
        await serverApi("POST", url, "", payload, options, "", "");

        this.setState({
          ...this.state,
          visibleWindow: false,
          approverValue: "",
          priority: null,
        });

        this.fetchLatestData();
      }
    } catch (e) {
      let message = e?.response?.data?.error?.message || "Please try again.";
      if (e?.response?.data?.error?.message.includes("unique constraint")) {
        message =
          "Duplicate id detected because the approvers you are trying to Already exists.Please try with a new approver.";
      }

      this.setState({
        ...this.state,
        visibleWindow: true,
        windowError: true,
        windowErrorMessage: message,
      });
    }
  };

  render() {
    const dataItem = this.props.dataItem;
    const { gridData } = this.state;
    const { hasBackofficeRead } = this.props;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <div
          style={{
            width: "83%",
          }}
        >
          <h4 style={{ fontFamily: "bold", textSize: "7px" }}>
            Model name : {dataItem.modelName} / Model Action :{" "}
            {dataItem.modelAction}
          </h4>

          <Grid
            {...gridData}
            filterable={false}
            style={{ className: "grid-no-select" }}
            sortable={false}
            resizable
            editField={this.editField}
            onItemChange={this.itemChange}
            pageable={false}
          >
            <GridToolbar>
              <GridContainer xs={12} justify={"flex-start"} direction={"row"}>
                {!hasBackofficeRead && (
                  <Tooltip
                    justify={"flex-start"}
                    placement="top"
                    title={"Attach Approvers"}
                  >
                    <IconButton onClick={this.toggleWindow}>
                      <FontAwesomeIcon
                        color="#0D5869"
                        size="1.6x"
                        icon={faPencilAlt}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </GridContainer>
            </GridToolbar>

            {!hasBackofficeRead && (
              <Column
                cell={this.CommandCell}
                title="Actions"
                filterable={false}
                width="240px"
              />
            )}
            {this.renderGridColumns(dataItem)}
          </Grid>
        </div>
        {this.state.visibleWindow && (
          <Window
            title="Attach Approvers"
            onClose={this.closeWindow}
            width={600}
            height={400}
            initialTop={110}
          >
            <>
              <BlockUi
                tag="div"
                blocking={this.state.blocking}
                message="Loading, please wait. . ."
                renderChildren={true}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "50px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                    }}
                  >
                    {this.state.windowError ? (
                      <Error style={{ color: "red" }}>
                        <h3>{this.state.windowErrorMessage || ""}</h3>
                      </Error>
                    ) : null}

                    {this.state.openErrorModal ? (
                      <Error style={{ color: "red", fontSize: "15px" }}>
                        {this.state.errorModalContent || ""}
                      </Error>
                    ) : null}

                    <AutoComplete
                      style={{ width: "450px" }}
                      data={(this.state.approvers_data || []).filter(
                        (x) => x && x.username
                      )}
                      textField="username"
                      value={this.state.approverValue}
                      onChange={this.onChange}
                      label={
                        "Search Approvers... (Minimum 3 characters length)"
                      }
                      listNoDataRender={this.listNoDataRender}
                    // placeholder="Search client name"
                    />
                    <span style={{ flex: 1 }}></span>
                    {this.state.approverValue !== "" ? (
                      <div>
                        <h5>Priotity</h5>
                        <NumericTextBox
                          name={`priority`}
                          value={this.state[`priority`] || ""}
                          onChange={(e) =>
                            this.onDialogInputChange(e, `priority`)
                          }
                          style={{ width: "120px" }}
                          placeholder="Priority"
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <button
                    style={{ justifyContent: "flex-end" }}
                    type="button"
                    className="k-button k-primary"
                    onClick={(e) => this.saveHandler(e)}
                    disabled={this.state.approverValue !== "" ? false : true}
                  >
                    Save
                  </button>
                </div>
              </BlockUi>
            </>
          </Window>
        )}
      </div>
    );
  }
}

export default Approvers;
