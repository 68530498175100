import React, { Component } from "react";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { Loader } from "./loader";
import { AbstractEdit } from "../../../networking/apiCalls";
import _ from "lodash";
import { MyCommandCell } from "../../CRM/View/myCommandCell";
import { DropDownCell } from "../../CRM/View/dropDownCell.js";
import { serverApi } from "../../../networking/config";
import {
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
} from "@material-ui/core";
import customSelectStyles from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import { columnsSchema } from "../View/schema";
import { Checkbox } from "@progress/kendo-react-inputs";
import GridContainer from "../../../components/Grid/GridContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser, faSyncAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import Snackbar from "../../../components/Snackbar/Snackbar";
import ViewSettings from "../../common/ViewSettings";
import ExpandedTabs from "../../common/ExpandedTabs";
import CarrierToModality from "./components/CarrierToModality";
import {
  showCreatorOnTable,
  removeUnwantedKeyValuesFromArr,
} from "../../../lib/GeneralUtils/utils";
import DropdownFilterCell from "../../common/dropdownFilterCell";

class Carriers extends Component {
  editField = "inEdit";
  CommandCell;

  constructor(props) {
    super(props);

    this.state = {
      deleteVisible: false,
      visibleWindow: false,
      isDropOpened: false,
      columns: (columnsSchema && columnsSchema()) || [],
      initialColumns: (columnsSchema && columnsSchema()) || [],
      gridData: {
        data: [],
        total: 0,
      },
      initialGridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 100,
        skip: 0,
      },
      openErrorModal: false,
      errorModalContent: "",
      delay: 400,
      loading: false,
      selectedRowIndex: 0,
      selectedItem: null,
      googleOptions: [],
      expandedRowIndex: null,
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.disableClient,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      hasDisable: true,
      showSecondAction: false,
    });
  }

  componentDidMount() {
    let isFirstLoad = true;
    window.localStorage.setItem("isFirstLoad", isFirstLoad);

    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));

    if (hasFinalParams) {
      this.setState({
        ...this.state,
        dataState: hasFinalParams,
      });
    }

    if (hasChangedColumns) {
      this.setState({
        ...this.state,
        columns: hasChangedColumns,
      });
    }
  }

  componentWillUnmount() {
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));
    let finalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    let hasExpandedId = JSON.parse(window.localStorage.getItem("expandeId"));
    let hasExportFilter = window.localStorage.getItem("exportFilter");

    if (hasChangedColumns) {
      window.localStorage.removeItem("cols");
    }
    if (finalParams) {
      window.localStorage.removeItem("finalParams");
    }
    if (hasExpandedId) {
      window.localStorage.removeItem("expandeId");
    }
    if (hasExportFilter) {
      window.localStorage.removeItem("exportFilter");
    }
  }

  dataStateChange = (e) => {
    const reqGridParams = e.data;
    window.localStorage.setItem("finalParams", JSON.stringify(reqGridParams));

    this.setState({
      ...this.state,
      dataState: reqGridParams,
    });
  };

  dataReceived = (gridData, reqGridParams) => {
    const finalData = showCreatorOnTable(gridData);
    this.setState({
      ...this.state,
      gridData: finalData,
      initialGridData: finalData,
      dataState:
        reqGridParams === !_.isEmpty(reqGridParams)
          ? reqGridParams
          : this.state.dataState,
    });
  };

  CountryCell = (props) => (
    <DropdownFilterCell
      {...props}
      showItemRender={true}
      data={(this.props.countryCodes || []).map((x) => x.alpha_2)}
      defaultValue={"Select country"}
    />
  );

  StateCell = (props) => (
    <DropdownFilterCell
      {...props}
      showItemRender={true}
      data={(this.props.usaStates || [])
        .map((x) => x.lov_value_1)
        .filter((x) => x)}
      defaultValue={"Select state"}
    />
  );

  renderGridColumns = () => {
    let selectionCol = [];
    let normalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.field === "relation_country") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              filterCell={(props) => {
                return (
                  <>
                    <DropdownFilterCell
                      {...props}
                      filterable={true}
                      width="140px"
                      data={(this.props.countryCodes || [])
                        .map((x) => x.alpha_2)
                        .sort((a, b) => (a > b ? 1 : -1))}
                    />
                  </>
                );
              }}
              cell={(props) => {
                let data = (this.props.countryCodes || [])
                  .map((x) => ({
                    text: x.alpha_2,
                    value: x.alpha_2,
                  }))
                  .sort((a, b) => (a.text > b.text ? 1 : -1));
                return (
                  <>
                    <DropDownCell
                      {...props}
                      filterable={true}
                      width="150px"
                      data={data}
                    />
                  </>
                );
              }}
            />
          );
        } else if (temp.field === "state") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              filterCell={(props) => {
                return (
                  <>
                    <DropdownFilterCell
                      {...props}
                      width="120px"
                      data={(this.props.usaStates || [])
                        .map((x) => x.lov_value_1)
                        .filter((x) => x)
                        .sort((a, b) => (a > b ? 1 : -1))}
                    />
                  </>
                );
              }}
              cell={(props) => {
                let stateData = (this.props.usaStates || [])
                  .map((x) => ({
                    text: x.lov_value_1,
                    value: x.lov_value_1,
                  }))
                  .filter((x) => x)
                  .sort((a, b) => (a.text > b.text ? 1 : -1))
                  .filter((obj) => obj.text !== null && obj.value !== null);
                return (
                  <>
                    <DropDownCell
                      {...props}
                      filterable={true}
                      width="100px"
                      data={stateData}
                    />
                  </>
                );
              }}
            />
          );
        } else {
          return (
            <Column
              field={temp.field}
              locked={temp.locked}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={
                temp.field === "id" ||
                temp.field === "createdByForUi" ||
                temp.field === "dateCreatedForUi" ||
                temp.field === "ap_account" ||
                temp.field === "ar_account"
                  ? false
                  : true
              } //id not editable on edit
            />
          );
        }
      });

    return [...selectionCol, ...normalCols];
  };

  handleColumnsChange = (event) => {
    let columns = this.state.columns;

    let oldColumns = columns.filter((c) => c.visible).map((c) => c.field);
    let newColumns = event.target.value;

    let unique1 = oldColumns.filter((c) => newColumns.indexOf(c) === -1);
    let unique2 = newColumns.filter((c) => oldColumns.indexOf(c) === -1);

    const columnLastUpdated = unique1.concat(unique2)[0];

    let index = columns.findIndex(
      (column) => column.field === columnLastUpdated
    );
    columns[index].visible = !columns[index].visible;

    this.setState(
      {
        ...this.state,
        columns: columns,
      },
      () => {
        let columns = this.state.columns;
        window.localStorage.setItem("cols", JSON.stringify(columns));
      }
    );
  };

  update = async (dataItem) => {
    const data = [...this.state.gridData.data];
    const updatedItem = { ...dataItem, inEdit: undefined };
    this.updateItem(data, updatedItem);

    const editPayload = dataItem;
    delete editPayload.inEdit;
    delete editPayload.inAdd;
    if (editPayload.expanded) delete editPayload.expanded;

    editPayload.client_flag = dataItem.client_flag || false;
    editPayload.agent_flag = dataItem.agent_flag || false;
    editPayload.location_flag = dataItem.location_flag || false;
    editPayload.sm_agent_flag = dataItem.sm_agent_flag || false;
    editPayload.sm_vendor_flag = dataItem.sm_vendor_flag || false;
    editPayload.airport_flag = dataItem.airport_flag || false;
    editPayload.wh_flag = dataItem.wh_flag || false;
    editPayload.seaport_flag = dataItem.seaport_flag || false;
    editPayload.supplier_flag = dataItem.seaport_flag || false;

    editPayload.verified_flag = dataItem.verified_flag || false;

    if (editPayload.relation_country !== "US") editPayload.state = null;
    const error1 = editPayload.relation_country === "US" && !editPayload.state;

    if (error1) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            "You need to provide the state field in order to create a carrier",
        },
        () => {
          this.discard(dataItem, true);
          return null;
        }
      );
    }

    if (
      editPayload.relation_name === undefined ||
      editPayload.relation_name === "" ||
      editPayload.relation_country === undefined ||
      editPayload.relation_country === "" ||
      editPayload.relation_postal_code === undefined ||
      editPayload.relation_postal_code === ""
    ) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            "Please provide Name,Postal and Country attributes in order to edit a carrier",
        },
        () => {
          setTimeout(() => {
            this.discard(dataItem, true);
            return null;
          }, 4000);
        }
      );
    } else {
      try {
        removeUnwantedKeyValuesFromArr(editPayload);

        const editRequest = await AbstractEdit(
          dataItem.id,
          editPayload,
          "masterEntities",
          {
            token: window.localStorage.getItem("access_token"),
          }
        );
        const countRequest = await serverApi(
          "GET",
          'masterEntities/count?where={"transport_flag":true}',
          "",
          "",
          {
            token: window.localStorage.getItem("access_token"),
          }
        );

        if (editRequest.status === 200 && countRequest) {
          this.setState(
            {
              ...this.state,
              gridData: {
                data: data,
                total: countRequest.data.count,
              },
              deleteVisible: false,
              successMessage: "The carrier was successfully updated",
              openSuccessMessage: true,
            },
            () => {
              setTimeout(() => {
                this.handleSuccessSnackbar();
                this.refreshGridDataAfterCrud();
              }, 3000);
            }
          );
        }
      } catch (e) {
        this.setState(
          {
            ...this.state,
            openErrorModal: true,
            errorModalContent:
              e?.response?.data?.error?.message ||
              "Error when updating carrier.Please try again",
          },
          () => this.discard(dataItem, true)
        );
      }
    }
  };

  handleErrorClose = (loginError) => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      openErrorModalContent: "",
    });
  };

  cancel = (dataItem) => {
    if (
      dataItem.relation_name === undefined ||
      dataItem.relation_country === undefined ||
      dataItem.relation_postal_code === undefined
    ) {
      const data =
        this.state.initialGridData && this.state.initialGridData.data;
      const total = this.state.initialGridData.total;

      this.setState({
        ...this.state,
        gridData: {
          data: data,
          total: total,
        },
        errorModalContent:
          this.state.errorModalContent !== ""
            ? ""
            : this.state.errorModalContent,
        openErrorModal:
          this.state.openErrorModal === true
            ? false
            : this.state.errorModalContent,
      });
    } else {
      const originalItem =
        this.state.initialGridData &&
        this.state.initialGridData.data.find((p) => p.id === dataItem.id);
      originalItem.inEdit = false;

      const data =
        this.state.gridData &&
        this.state.gridData.data.map((item) =>
          item.id === originalItem.id ? originalItem : item
        );
      const total = this.state.gridData.total;

      this.setState({
        ...this.state,
        gridData: {
          data: data,
          total: total,
        },
      });
    }
  };

  discard = (dataItem, hasError = false) => {
    let data = [...this.state.gridData.data];
    const total = this.state.gridData.total;

    if (hasError) {
      data[0].inEdit = true;

      this.setState(
        {
          ...this.state,
          gridData: {
            data: data,
            total: total,
          },
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 3000);
        }
      );
    } else {
      this.removeItem(data, dataItem);

      this.setState(
        {
          ...this.state,
          gridData: {
            data: data,
            total: total,
          },
          visible: false,
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 3000);
        }
      );
    }
  };

  applyNewColumnsOnGrid = (dataItem) => {
    let columns = this.state.initialColumns;
    let newColumns = columns.filter(
      (x) => x.field !== "id" && x.field !== "clientgroup_id"
    );
    if (dataItem && dataItem.relation_country !== "US") {
      newColumns = newColumns.filter((col) => col.field !== "state");
    }

    this.setState({
      ...this.state,
      hideColumns: true,
      columns: newColumns,
    });
  };

  removeItem(data, item) {
    let index = data.findIndex((p) => p === item || p.id === item.id);
    if (index >= 0) {
      data.splice(index, 1);
    }
  }

  enterEdit = (dataItem) => {
    this.applyNewColumnsOnGrid(dataItem);
    let total = this.state.gridData.total;

    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    this.setState({
      gridData: {
        data:
          this.state.gridData &&
          this.state.gridData.data.map((item) =>
            item.id === dataItem.id ? { ...item, inEdit: true } : item
          ),
        total: total,
      },
      initialGridData: {
        data:
          this.state.initialGridData &&
          this.state.initialGridData.data.map((item) =>
            item.id === dataItem.id ? { ...item, inEdit: true } : item
          ),
        total: total,
      },
    });
  };

  addNew = () => {
    let checkForEditDato = this.state.gridData.data || [];
    const found =
      checkForEditDato && checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    const newDataItem = {
      inEdit: true,
      transport_flag: true,
      inAdd: true,
    };

    this.setState({
      ...this.state,
      gridData: {
        data: [newDataItem, ...this.state.gridData.data],
        total: this.state.gridData.total,
      },
      visible: true,
    });
  };

  add = (dataItem) => {
    dataItem.inEdit = undefined;
    const submitURL = "masterEntities";
    const options = { token: window.localStorage.getItem("access_token") };

    let payload = dataItem;
    delete payload.inEdit;
    delete payload.inAdd;
    payload.client_flag = false;
    payload.transport_flag = true;
    payload.agent_flag = false;
    payload.location_flag = false;
    payload.sm_agent_flag = false;
    payload.sm_vendor_flag = false;
    payload.airport_flag = false;
    payload.wh_flag = false;
    payload.seaport_flag = false;
    payload.supplier_flag = false;
    payload.verified_flag = false;

    if (
      payload.relation_name === undefined ||
      payload.relation_name === "" ||
      payload.relation_country === undefined ||
      payload.relation_country === "" ||
      payload.relation_postal_code === undefined ||
      payload.relation_postal_code === ""
    ) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            "Please provide Name,Postal and Country attributes in order to create a new carrier.",
        },
        () => {
          setTimeout(() => {
            this.cancel(dataItem);
            this.handleErrorClose();
          }, 4000);
          return null;
        }
      );
    } else {
      const createRequest = serverApi(
        "POST",
        `${submitURL}`,
        "",
        payload,
        options
      );
      createRequest
        .then(async (createResponse) => {
          this.setState(
            {
              ...this.state,
              inAdd: false,
              deleteVisible: false,
              successMessage: "The carrier was successfully created",
              openSuccessMessage: true,
            },
            () => {
              setTimeout(() => {
                this.handleSuccessSnackbar();
                this.refreshGridDataAfterCrud();
              }, 4000);
            }
          );
        })
        .catch((createError) => {
          this.setState(
            {
              ...this.state,
              openErrorModal: true,
              errorModalContent:
                createError?.response?.data?.error?.message ||
                "Please try again",
            },
            () => this.discard(dataItem, true)
          );
        });
    }
  };

  applyInitialColumnsOnGrid = () => {
    let initialColumns = this.state.initialColumns;
    let newColumns = initialColumns;
    this.setState({
      ...this.state,
      hideColumns: false,
      columns: newColumns,
    });
  };

  itemChange = (event) => {
    const { field, value } = event;

    if (field === "relation_country" && value === "US") {
      this.applyInitialColumnsOnGrid();
    } else if (field === "relation_country" && value !== "US") {
      this.setState({
        ...this.state,
        columns:
          this.state.columns.filter((col) => col.field !== "state") || [],
      });
    }
    // if (event.field === 'supplier_flag' || (event.field === "verified_flag" && event.dataItem.inAdd)) {
    //   return
    // }
    const total = this.state.gridData.total;
    const data =
      this.state.gridData &&
      this.state.gridData.data.map((item) =>
        item.id === event.dataItem.id
          ? { ...item, [event.field]: event.value }
          : item
      );

    this.setState({
      gridData: {
        data: data,
        total: total,
      },
    });
  };

  handleSuccessSnackbar = () => {
    this.setState({
      ...this.state,
      successMessage:
        this.state.openSuccessMessage === true ? this.state.successMessage : "",
      openSuccessMessage: !this.state.openSuccessMessage,
    });
  };

  updateItem = (data, item) => {
    let index = data.findIndex(
      (p) => p === item || (item.id && p.id === item.id)
    );
    if (index >= 0) {
      data[index] = { ...item };
    }
  };

  exportToCsv = async (state, props) => {
    const count = state.gridData.total;
    const where = window.localStorage.getItem("exportFilter") || {};
    const numberOfCalls = Math.min(
      Math.round(count / 1000) + 1,
      5 // Limit to 5000
    );
    let finalSort = this.state.dataState?.sort?.length
      ? this.state.dataState.sort[0].field +
        " " +
        this.state.dataState.sort[0].dir
      : "id asc";

    const promises = [];
    for (let i = 0; i < numberOfCalls; i++) {
      promises.push(
        this.getOrdersPromise({
          skip: i * 1000,
          limit: 1000,
          where: !_.isEmpty(where) ? JSON.parse(where) : {},
          order: [finalSort],
        })
      );
    }

    try {
      const responses = await Promise.all(promises);
      let allData = [];
      responses.forEach((resp) => {
        allData = [...allData, ...resp.data];
      });
      if (allData.length > 0) {
        this.setState({
          ...this.state,
          exportData: allData,
        });
        // this._export.save(allData, this.state.columns);
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent:
          (e.response.data && e.response.data.error.message) ||
          "Please try again",
      });
    }
  };

  getOrdersPromise = (filter) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    return new Promise((resolve, reject) => {
      const masterEntityRequest = serverApi(
        "GET",
        `masterEntities?filter=${JSON.stringify(filter)}`,
        "",
        "",
        options
      );
      masterEntityRequest
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getExportData = async () => {
    await this.exportToCsv(this.state, this.props);
  };

  onClearFilters = () => {
    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    this.props.history.push(`/app/master/carriers`);

    let init = JSON.stringify({ take: 100, skip: 0 });
    if (hasFinalParams && hasFinalParams.filter)
      window.localStorage.setItem("finalParams", init);

    this.dataStateChange({ data: { take: 100, skip: 0 } });
  };

  refreshGridDataAfterCrud = async () => {
    let token = window.localStorage.getItem("access_token");
    let options = {
      token: token,
    };
    let refreshParams = window.localStorage.getItem("refreshParams");
    refreshParams = JSON.parse(refreshParams);

    let responseData = await serverApi(
      "GET",
      `masterEntities`,
      refreshParams,
      "",
      options
    );
    const finalData = showCreatorOnTable(responseData);

    let count;
    const countMasterEntity = await serverApi(
      "GET",
      `masterEntities/count?where=${JSON.stringify(
        refreshParams.filter.where
      )}`,
      "",
      "",
      options
    );
    if (countMasterEntity) {
      count = countMasterEntity.data.count;
    }

    let sendObj = {
      gridData: {
        data: finalData.data,
        total: count,
      },
    };

    this.dataReceived(sendObj.gridData, null);
  };

  expandChange = (event) => {
    window.localStorage.setItem("expandeId", event.dataItem.id);

    //if add new vessel is true then expand cannot be done !
    if (this.state.gridData.data[0].inEdit === true) {
      return;
    } else {
      event.dataItem.expanded = !event.dataItem.expanded;
    }

    this.forceUpdate();
  };

  render() {
    const { gridData, columns } = this.state;
    const hasEditedItem = gridData && gridData.data.some((p) => p.inEdit);

    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    let hasColumnFilters = true;
    if (found) {
      hasColumnFilters = false;
    }

    const { hasBackofficeRead } = this.props;

    return (
      <div>
        {/* {this.state.deleteVisible &&
          <Dialog onClose={() => this.toggleDeleteDialog({}, this.state)}>
            <p style={{ margin: "25px", textAlign: "center" }}>
              Are you sure you want to disable this client?
            </p>
            <DialogActionsBar>
              <button
                className="k-button"
                onClick={() => this.toggleDeleteDialog({}, this.state)}
              >
                No
              </button>
              <button className="k-button" onClick={() => this.disableAction()}>
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>} */}

        <Grid
          {...this.state.dataState}
          {...this.state.gridData}
          filterable={hasColumnFilters}
          style={{ height: "92vh" }}
          sortable={true}
          resizable
          editField={this.editField}
          detail={(dataItem) => (
            <div>
              <ExpandedTabs
                tabs={[
                  {
                    label: "Carrier To Modality",
                    component: (
                      <CarrierToModality
                        {...dataItem}
                        hasBackofficeRead={hasBackofficeRead}
                      />
                    ),
                  },
                ]}
              />
            </div>
          )}
          onItemChange={this.itemChange}
          onDataStateChange={this.dataStateChange}
          expandField="expanded"
          onExpandChange={this.expandChange}
          pageable={{
            buttonCount: 5,
            info: true,
            pageSizes: [100, 200, 500, 1000, 1500],
          }}
        >
          <GridToolbar>
            <GridContainer xs={12} direction={"row"}>
              {!hasBackofficeRead && (
                <Tooltip
                  justify={"flex-start"}
                  placement="top"
                  title={"Add Carrier"}
                >
                  <IconButton onClick={this.addNew}>
                    <FontAwesomeIcon
                      color="#0D5869"
                      onClick={this.addNew}
                      size="1.6x"
                      icon={faPlus}
                    />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip
                justify={"flex-end"}
                placement="top"
                title={"Clear Column Filters"}
              >
                <IconButton
                  disabled={false}
                  aria-label=""
                  onClick={(e) => this.onClearFilters(e)}
                  look="flat"
                >
                  <FontAwesomeIcon
                    color="#0D5869"
                    size="1.6x"
                    icon={faEraser}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip
                justify={"flex-start"}
                placement="top"
                title={"Refresh Data"}
              >
                <IconButton
                  disabled={false}
                  aria-label=""
                  onClick={this.refreshGridDataAfterCrud}
                >
                  <FontAwesomeIcon color="#0D5869" size="1x" icon={faSyncAlt} />
                </IconButton>
              </Tooltip>
              <ViewSettings
                {...this.props}
                export={{
                  data: this.state.exportData || [],
                  exportFunction: this.getExportData,
                  fileName: "Carriers_",
                  columns: columnsSchema().filter((x) => x.field !== "id"),
                }}
                viewSettings={{
                  type: "",
                }}
                refreshSettings={{
                  data: [],
                }}
              />
              {!this.state.hideColumns ? (
                <div style={{ marginTop: hasBackofficeRead ? "-12px" : "" }}>
                  <FormControl className={customSelectStyles.selectFormControl}>
                    <InputLabel
                      htmlFor="multiple-select"
                      className={customSelectStyles.selectLabel}
                    />
                    <Select
                      autoWidth={true}
                      multiple
                      value={columns
                        .filter((column) => column.visible)
                        .map((column) => column.field)}
                      onChange={this.handleColumnsChange}
                      MenuProps={{ className: customSelectStyles.selectMenu }}
                      inputProps={{
                        name: "multipleSelect",
                        id: "multiple-select",
                      }}
                      renderValue={(selected) => "Columns"}
                    >
                      {columns
                        .filter((col) => !col.notShownInTableColumnSelection)
                        .map((column) => {
                          let name = column.title;
                          let value = column.field;
                          let isVisible = column.visible;
                          let isDisabled = column.noDisabledColumn;

                          return (
                            <MenuItem
                              style={{ backgroundColor: "white" }}
                              key={name}
                              value={value}
                              disabled={isDisabled}
                            >
                              <Checkbox color={"default"} checked={isVisible} />
                              <ListItemText
                                style={{ marginLeft: "7px" }}
                                primary={name}
                              />
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
              ) : null}
            </GridContainer>
          </GridToolbar>

          {!hasBackofficeRead && (
            <Column
              cell={this.CommandCell}
              title="Actions"
              filterable={false}
              width={hasEditedItem ? "240px" : "180px"}
            />
          )}

          {this.renderGridColumns()}
        </Grid>

        <Loader
          dataState={this.state.dataState}
          onDataRecieved={this.dataReceived}
          include={["creator"]}
          getURL={"masterEntities"}
          countURL={`masterEntities/count?where={"transport_flag":"true"}`}
          view={"carrier"}
        />

        <Snackbar
          place="tc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"warning"}
          message={this.state.errorModalContent}
          open={this.state.openErrorModal}
          closeNotification={() =>
            this.handleErrorClose(this.state.errorModalContent)
          }
          close
        />

        <Snackbar
          place="tc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"success"}
          message={this.state.successMessage}
          open={this.state.openSuccessMessage}
          // closeNotification={() =>
          //   this.handleSuccessSnackbar(this.state.successMessage)}
          // close
        />
      </div>
    );
  }
}

export default Carriers;
