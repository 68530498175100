import {connect} from 'react-redux';
import compose from 'ramda/src/compose';
import WarehouseMappingView from "./View";

const mapActionCreators = {}

const mapStateToProps = (state) => {
    return {
        user: state.MainLayoutReducer.user,
        hasBackofficeRead: state.MainLayoutReducer.user.hasBackofficeRead
      };
}

export default compose(
    connect(mapStateToProps, mapActionCreators))
(WarehouseMappingView)

