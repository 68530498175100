export const columnsSchema = () => [
  {
    field: "id",
    title: "ID",
    visible: true,
    minWidth: 180,
    sortable: true,
    filterable: true,
    locked: true,
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
  },
  {
    field: "relation_name",
    title: "Name",
    visible: true,
    minWidth: 320,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    // locked: "locked",
    locked: true,
  },
  {
    field: "agent_type",
    title: "Agent Type",
    visible: true,
    minWidth: 220,
    sortable: true,
    filterable: true,
    noDisabledColumn: false,
    isDropdown: "yes",
  },
  {
    field: "relation_address",
    title: "Address",
    visible: true,
    minWidth: 320,
    sortable: true,
    filterable: true,
    noDisabledColumn: false,
    notShownInTableColumnSelection: false,
    // locked: "locked",
  },
  {
    field: "relation_postal_code",
    title: "Postal",
    visible: true,
    minWidth: 200,
    sortable: true,
    filterable: true,
    // locked: "locked",
  },
  {
    field: "relation_country",
    title: "Country",
    visible: true,
    minWidth: 240,
    sortable: true,
    filterable: true,
    // locked: "locked",
    isDropdown: "yes",
  },
  {
    field: "state",
    title: "State",
    visible: true,
    minWidth: 220,
    sortable: true,
    filterable: true,
    noDisabledColumn: false,
    isDropdown: "yes",
  },
  {
    field: "relation_city",
    title: "City",
    visible: true,
    minWidth: 340,
    sortable: true,
    filterable: true,
    // locked: "locked",
    notShownInTableColumnSelection: false,
  },
  {
    field: "icao_code",
    title: "ICAO Code",
    visible: true,
    minWidth: 140,
    sortable: true,
    filterable: true,
    // locked: "locked",
    notShownInTableColumnSelection: false,
    // editable: "false"
  },
  {
    field: "ap_account",
    title: "AP account",
    visible: true,
    minWidth: 150,
    sortable: true,
    filterable: true,
    // locked: "locked",
    notShownInTableColumnSelection: false,
  },
  {
    field: "ar_account",
    title: "AR account",
    visible: true,
    minWidth: 150,
    sortable: true,
    filterable: true,
    // locked: "locked",
    notShownInTableColumnSelection: false,
  },
  {
    field: "ein_number",
    title: "EIN Number",
    visible: true,
    minWidth: 350,
    sortable: true,
    filterable: true,
    // locked: "locked",
    notShownInTableColumnSelection: false,
  },
  {
    field: "ss_number",
    title: "SS Number",
    visible: true,
    minWidth: 350,
    sortable: true,
    filterable: true,
    // locked: "locked",
    notShownInTableColumnSelection: false,
  },
  {
    field: "tsa_number",
    title: "TSA Number",
    visible: true,
    minWidth: 350,
    sortable: true,
    filterable: true,
    // locked: "locked",
    notShownInTableColumnSelection: false,
  },
  {
    field: "vat_number",
    title: "VAT",
    visible: true,
    minWidth: 220,
    sortable: true,
    filterable: true,
    noDisabledColumn: false,
  },
  {
    field: "iata_code",
    title: "IATA Code",
    visible: true,
    minWidth: 350,
    sortable: true,
    filterable: true,
    // locked: "locked",
    notShownInTableColumnSelection: false,
  },
  {
    field: "duns",
    title: "DUNS",
    visible: true,
    minWidth: 200,
    sortable: true,
    filterable: true,
    // locked: "locked",
    notShownInTableColumnSelection: false,
    editable: "false",
  },
  {
    field: "relation_tel",
    title: "Telephone",
    visible: true,
    sortable: true,
    minWidth: 300,
    filterable: true,
    // locked: "locked",
  },
  {
    field: "relation_mail",
    title: "Email",
    visible: true,
    sortable: true,
    minWidth: 260,
    filterable: true,
    // locked: "locked",
  },
  {
    field: "relation_web",
    title: "Web",
    visible: true,
    sortable: true,
    minWidth: 260,
    filterable: true,
    // locked: "locked",
  },
  {
    field: "paymentTerms",
    title: "PAYMENT TERMS",
    visible: true,
    sortable: true,
    minWidth: 260,
    filterable: true,
    locked: false,
  },
  {
    field: "createdByForUi",
    title: "Created By",
    visible: true,
    minWidth: 200,
    sortable: false,
    filterable: false,
    // locked: "locked",
    notShownInTableColumnSelection: false,
    editable: "false",
  },
  {
    field: "dateCreatedForUi",
    title: "Created Date",
    visible: true,
    minWidth: 200,
    sortable: false,
    filterable: false,
    // locked: "locked",
    notShownInTableColumnSelection: false,
    editable: "false",
  },
];

export const similaritySchema = () => [
  // {
  //   field: "id",
  //   title: "ID",
  //   visible: true,
  //   minWidth: 170,
  //   sortable: false,
  //   filterable: false,
  //   locked: false,
  // },
  {
    field: "RELATION_NAME",
    title: "Name",
    visible: true,
    minWidth: 240,
    sortable: false,
    filterable: false,
    locked: false,
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
  },
  {
    field: "RELATION_COUNTRY",
    title: "Country",
    visible: true,
    minWidth: 185,
    sortable: false,
    filterable: false,
    locked: false,
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
  },
  {
    field: "RELATION_ADDRESS",
    title: "Address",
    visible: true,
    minWidth: 190,
    sortable: false,
    filterable: false,
    locked: false,
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
  },
  {
    field: "RELATION_TEL",
    title: "Telephone",
    visible: true,
    minWidth: 170,
    sortable: false,
    filterable: false,
    locked: false,
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
  },
  {
    field: "RELATION_MAIL",
    title: "Web",
    visible: true,
    minWidth: 170,
    sortable: false,
    filterable: false,
    locked: false,
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
  },
  {
    field: "SIMILARITY",
    title: "Similarity",
    visible: true,
    minWidth: 170,
    sortable: false,
    filterable: false,
    locked: false,
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
  },
];
