import {serverApi} from '../config'

export const AbstractGetDataByID = (id,url,params,options) => {
  try {

    const finalUrl = `${url}/${id}`
    const response = serverApi('GET', finalUrl,params,'',options,'')

    return response;
  }
  catch(error) {
    throw error
  }
}
