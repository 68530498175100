import React from "react";
import { GridCell } from "@progress/kendo-react-grid";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import _ from "lodash";
import Fab from "@material-ui/core/Fab";
import Icon from "@material-ui/core/Icon";

export default function GridActionsHOC(
  GridActions,
  redirectToEditForm,
  redirectToCreateForm,
  stayWhereYouAre,
  goToAddOrderTable,
  addOrderToDispatch,
  deleteDispatchFinancial,
  redirectToRoleForm,
) {
  return class extends GridCell {
    constructor(props) {
      super(props);

      this.state = {
        visible: false,
        assignVisible: false,
        editTransportVisible: false,
        editItemsVisible: false,
        deleteVisible: false,
        operators: [],
        openedOperator: false,
        valueOperator: "",
      };
    }

    // goToMainPage = (dataItem) => {
    //
    //   const {operators, valueOperator} = this.state;
    //
    //   let finalOperator = _.find(operators, {userRealName: valueOperator}).id;
    //
    //   const {id} = this.props.dataItem;
    //   let options = {quotationApplication: true};
    //
    //   const mainPayload = {
    //     quoteReqID: `${id}`,
    //     status: 'OPERATOR_PENDING',
    //     dateAssigned: moment(new Date().toISOString()),
    //     dateCreated: moment(new Date().toISOString()),
    //     assignToOperatorID: `${finalOperator}`,
    //     assignByManagerID: `1`,
    //   };
    //
    //   try {
    //     const postToMain = serverApi('POST', '/api/QuoteMains', '', mainPayload, options);
    //
    //     postToMain
    //       .then(resp => {
    //         console.log('test', resp);
    //       });
    //   } catch (error) {
    //     console.log('ERROR', error);
    //     throw error;
    //   }
    //
    // };
    //
    // onAutoCompleteChange = (event) => {
    //
    //   const {name, value} = event.target;
    //   let stateData = {};
    //
    //   stateData = value.length < 4 ?
    //     {
    //       data: this.state.operators.map(temp => temp.userRealName),
    //       openedOperator: false,
    //     } :
    //     {data: this.filterData(value, name), openedOperator: true};
    //
    //   let eventType = event.nativeEvent.type;
    //
    //   let valueSelected = eventType === 'click' ||
    //     (eventType === 'keydown' && event.nativeEvent.keyCode === 13);
    //
    //   if (valueSelected && stateData.data.includes(value)) {
    //     stateData.openedOperator = false;
    //   }
    //
    //   this.setState({
    //     valueOperator: event.target.value,
    //     ...stateData,
    //   }, () => this.debouncedFetchOperators(event.target.value));
    //
    // };
    //
    // debouncedFetchOperators = (filterValue) => {
    //
    //   let options = {
    //     quotationApplication: false,
    //     token: '1aig15JcsK4lLO60xbQWAimzbWvegaDhlNOkwugGOfCMJkX4K3U4DnI5cGJUEaum',
    //   };
    //
    //   let filter = {};
    //
    //   filter = {
    //     'fields': [
    //       'id',
    //       'userRealName',
    //     ],
    //     'where': {
    //       'userRealName': {
    //         'like': `${filterValue}%`,
    //       },
    //     },
    //   };
    //
    //   const quoteOperators = serverApi('GET', 'https://dev.swift-navigator.com:443/api/snUsers', {filter: filter}, '', options);
    //
    //   quoteOperators
    //     .then(response => {
    //       console.log(response);
    //       this.setState({
    //         operators: response.data || [],
    //       });
    //     })
    //     .catch(error => {
    //       console.log(error);
    //
    //       throw error;
    //     });
    //
    // };
    //
    // filterData = (value, name) => {
    //
    //   const data = this.state.operators.map(test => test.userRealName);
    //
    //   const filter = {
    //     value: value,
    //     operator: 'startswith',
    //     ignoreCase: true,
    //   };
    //
    //   return filterBy(data, filter);
    //
    // };
    //
    // toggleDialogDelete = () => {
    //
    //   this.setState({
    //     deleteVisible: !this.state.deleteVisible,
    //   });
    // };
    //
    // closeAndDelete = () => {
    //
    //   setTimeout(() => stayWhereYouAre(this.props.dataItem), 800);
    //
    // };

    renderedActions = _.map(GridActions, actn => {
      let action = undefined;

      if (
        actn.title.includes("Edit") &&
        actn.actionType === "redirectToEditForm"
      ) {
        action = redirectToEditForm;
      } else if (
        actn.title.includes("Remove") &&
        actn.actionType === "stayWhereYouAre"
      ) {
        action = stayWhereYouAre;
      } else if (actn.title.includes("goToAddOrdersTable")) {
        action = goToAddOrderTable;
      } else if (
        actn.title === "Insert Order To Dispatch" &&
        actn.actionType === "addOrderToDispatch"
      ) {
        action = addOrderToDispatch;
      } else if (
        actn.title === "Delete Financial" &&
        actn.actionType === "deleteDispatchFinancial"
      ) {
        action = deleteDispatchFinancial;
      } else if (
        actn.title === "Edit User Roles" &&
        actn.actionType === "redirectToRoleForm"
      ) {
        action = redirectToEditForm;
      } else {
        action = null;
      }

      //TODO provide a prop that would change the icon child ( remove needs a delete icon etc... )
      //check if is delete and if yes open a modal here and make the call to HOC
      //take the code from render and change it to here if Remove is the grid action
      return actn.title !== "" &&
      !actn.title.includes("Create") &&
      !actn.title.includes("Add")
        ? <div style={{ display: "inline", padding: "5px" }}>
            <Tooltip justify={"flex-start"} title={`${actn.title}`}>
              {actn.title === "Edit User Roles"
                ? <Fab size={"small"} color="inherit" aria-label="">
                    <Icon>
                      <AccessibilityIcon
                        onClick={() => {
                          action(this.props.dataItem, actn.actionRoutePath);
                        }}
                      />
                    </Icon>
                  </Fab>
                : <Fab size={"small"} color="inherit" aria-label="">
                    <Icon>
                      <EditIcon
                        onClick={() => {
                          action(this.props.dataItem, actn.actionRoutePath);
                        }}
                      />
                    </Icon>
                  </Fab>}
            </Tooltip>
          </div>
        : null;
    });

    render() {
      return (
        <td>
          {this.renderedActions}
        </td>
      );
    }
  };
}
