export const columnsSchema = () => [
  {
    field: "id",
    title: "ID",
    visible: true,
    minWidth: 190,
    sortable: true,
    filterable: true,
    locked: "locked",
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
    // cell: true
  },
  {
    field: "billingEntity.relation_name",
    title: "Billing Entity Name",
    visible: true,
    minWidth: 350,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    locked: "locked",
  },
  {
    field: "billingEntityId",
    title: "Billing Entity Id",
    visible: true,
    minWidth: 250,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    locked: "locked",
  },
];
