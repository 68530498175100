import React from "react";
import {
  Grid,
  GridColumn as Column,
  GridDetailRow,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { serverApi } from "../../../../networking/config";
import _ from "lodash";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import BlockUi from "react-block-ui";
import { columnsSchema } from "./schema";
import { MyCommandCell } from "../../../Clients/View/components/CellHOC";
import { DropDownCell } from "../../../CRM/View/dropDownCell";
import GridContainer from "../../../../components/Grid/GridContainer";
import { IconButton, Tooltip } from "@material-ui/core";
import ViewSettings from "../../../common/ViewSettings";
import { Window } from "@progress/kendo-react-dialogs";
import { Error } from "@progress/kendo-react-labels";
import { AbstractEdit } from "../../../../networking/apiCalls";
import RegularButton from "../../../../components/CustomButtons/Button";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ExpandedUsers extends GridDetailRow {
  editField = "inEdit";
  CommandCell;
  _export;

  constructor(props) {
    super(props);

    this.state = {
      visibleWindow: false,
      isDropOpened: false,
      columns: (columnsSchema && columnsSchema()) || [],
      gridData: {
        data: [],
        total: 10,
      },
      initialGridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 10,
        skip: 0,
      },
      openErrorModal: false,
      errorModalContent: "",
      delay: 400,
      loading: false,
      usernames: [],
      windowError: false,
      windowErrorMessage: "",
      disableEditButton: true,
      inputValue: "",
      clientAttachedToAnotherGroup: null,
      exportData: [],
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      disableEditButton: false,
      showSecondAction: true,
      showFirstAction: false,
    });
  }

  async componentDidMount() {
    let isFirstLoad = true;
    window.localStorage.setItem("isFirstLoad", isFirstLoad);

    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));

    if (hasFinalParams) {
      this.setState({
        ...this.state,
        dataState: hasFinalParams,
      });
    }

    if (hasChangedColumns) {
      this.setState({
        ...this.state,
        columns: hasChangedColumns,
      });
    }

    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    this.setState({ ...this.state, blocking: true });

    const roleID = this.props.dataItem.id;
    let fil = {
      filter: {
        where: {
          roleId: roleID.toString(),
        },
        include: [
          {
            relation: "roles",
          },
        ],
      },
    };
    let attachedUsersToRole = [];
    let usersOfRole = await serverApi("GET", `snUsers`, fil, "", options);
    let all_users = _.cloneDeep(usersOfRole);
    usersOfRole = usersOfRole.data.filter((x) => x.roles.length > 0);
    usersOfRole.forEach((x) => {
      if (x?.roles) {
        (x?.roles || []).forEach((temp) => {
          if (temp.id === this.props.dataItem.id) {
            attachedUsersToRole.push(x);
          }
        });
      }
    });
    let allUsers = all_users;
    let usernames =
      allUsers.data
        .sort((a, b) => (a.username > b.username ? 1 : -1))
        .map((x) => x.username) || [];

    // let result = this.constructUsersAndExistingUsersArray(
    //   allUsers.data,
    //   attachedUsersToRole,
    //   roleID
    // );
    // result[0].finalAttachedUsers.forEach((item) => {
    //   let user = result[0].finalUsers.find((x) => x.id === item.id);
    //   if (user) {
    //     user.checked = true;
    //   }
    // });

    if (attachedUsersToRole && attachedUsersToRole.length > 1) {
      let finalData = [];
      _.forEach(attachedUsersToRole, (temp) => {
        finalData.push({
          id: temp.id,
          username: temp.username,
          userRealName: temp.userRealName,
          email: temp.email || "N/A",
          created: temp.created || "N/A",
          last_login_date: temp.last_login_date || "N/A",
        });
      });
      finalData.sort((a, b) => (a.username > b.username ? 1 : -1));

      this.setState({
        ...this.state,
        roleID: this.props.dataItem.id,
        gridData: {
          data: finalData,
          total: finalData.length,
        },
        initialGridData: {
          data: finalData,
          total: finalData.length,
        },
        user_names: usernames,
        valueUserName: "",

        // visibleClients: result[0].finalUsers || [],
        // finalUserClients: result[0].finalUsers || [],
        // initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    } else if (attachedUsersToRole && attachedUsersToRole.length === 1) {
      let finalData = [];
      _.forEach(attachedUsersToRole, (temp) => {
        finalData.push({
          id: temp.id,
          username: temp.username,
          userRealName: temp.userRealName,
          email: temp.email || "N/A",
          created: temp.created || "N/A",
          last_login_date: temp.last_login_date || "N/A",
        });
      });
      finalData.sort((a, b) => (a.username > b.username ? 1 : -1));

      this.setState({
        ...this.state,
        roleID: this.props.dataItem.id,
        gridData: {
          data: [finalData[0]],
          total: 1,
        },
        initialGridData: {
          data: [finalData[0]],
          total: 1,
        },
        user_names: usernames,
        valueUserName: "",
        // visibleClients: result[0].finalUsers || [],
        // finalUserClients: result[0].finalUsers || [],
        // initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    } else {
      this.setState({
        ...this.state,
        roleID: this.props.dataItem.id,
        gridData: {
          data: [],
          total: 0,
        },
        initialGridData: {
          data: [],
          total: 0,
        },
        user_names: usernames,
        valueUserName: "",
        // visibleClients: result[0].finalUsers || [],
        // finalUserClients: result[0].finalUsers || [],
        // initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    }
  }

  renderGridColumns = (dataItem, changeTheCellComponent) => {
    let selectionCol = [];
    let normalCols = [];
    let finalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            width="70px"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.isFilterBoolean === "yes") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"420"}
              cell={DropDownCell}
            />
          );
        } else {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={temp.field === "id" || temp.editable ? false : true} //id not editable on edit
            />
          );
        }
      });

    finalCols = [...selectionCol, ...normalCols];
    return finalCols;
  };

  constructUsersAndExistingUsersArray = (
    allUsers,
    attachedUsers,
    expandedRole
  ) => {
    let finalUsers = [];
    _.forEach(allUsers, (temp1) => {
      if (temp1.roles.length === 0) return;
      let rolesIDs = [];
      if (temp1.roles.length > 0) {
        rolesIDs = temp1.roles.map((x) => x.id);
      }

      finalUsers.push({
        name: temp1.username || "N/A",
        id: temp1.id,
        checked: false,
        roleIDs: rolesIDs,
        userID: temp1.id,
      });
    });

    let finalAttachedUsers = [];
    _.forEach(attachedUsers, (temp2) => {
      if (temp2.roles.length === 0) return;
      let rolesIDs = [];
      if (temp2.roles.length > 0) {
        rolesIDs = temp2.roles.map((x) => x.id);
      }
      finalAttachedUsers.push({
        name: temp2.username || "N/A",
        checked: true,
        deleteID: temp2.id,
        id: temp2.id,
        userID: temp2.id,
        roleIDs: rolesIDs,
      });
    });

    finalUsers.sort((a, b) => (a.name > b.name ? 1 : -1));
    finalAttachedUsers.sort((a, b) => (a.name > b.name ? 1 : -1));

    return [
      {
        finalUsers: finalUsers,
        finalAttachedUsers: finalAttachedUsers,
      },
    ];
  };

  handleChangeOLD = (event, name, key, state) => {
    let finalUserClient = [...this.state.finalUserClients];
    let finalObj = finalUserClient.find((temp) => temp.id === key);
    let id = finalObj.id;
    finalObj.checked = !finalObj.checked;

    const userID = JSON.parse(id);
    const roleID = this.props.dataItem.id;

    this.setState(
      {
        finalUserClients: finalUserClient,
      },
      (key) => {
        let options = {
          quotationApplication: false,
          token: window.localStorage.getItem("access_token"),
        };
        let putOrDelete = event.value === false ? "DELETE" : "POST";

        if (putOrDelete === "POST") {
          const url = `roleMappings`;

          let payload = {
            principalType: "USER",
            principalId: `${userID}`,
            roleId: roleID,
          };

          this.setState({ blocking: true });
          const postRequest = serverApi(
            "POST",
            url,
            "",
            payload,
            options,
            "",
            ""
          );
          postRequest
            .then(async (response) => {
              const roleID = this.props.dataItem.id;
              let fil = {
                filter: {
                  where: {
                    roleId: roleID.toString(),
                  },
                  include: [
                    {
                      relation: "roles",
                    },
                  ],
                },
              };
              let attachedUsersToRole = [];
              let usersOfRole = await serverApi(
                "GET",
                `snUsers`,
                fil,
                "",
                options
              );
              usersOfRole = usersOfRole.data.filter((x) => x.roles.length > 0);
              usersOfRole.forEach((x) => {
                if (x?.roles) {
                  (x?.roles || []).forEach((temp) => {
                    if (temp.id === this.props.dataItem.id) {
                      attachedUsersToRole.push(x);
                    }
                  });
                }
              });
              let finalData = [];
              _.forEach(attachedUsersToRole, (temp) => {
                finalData.push({
                  id: temp.id,
                  username: temp.username,
                  userRealName: temp.userRealName,
                  email: temp.email || "N/A",
                  created: temp.created || "N/A",
                  last_login_date: temp.last_login_date || "N/A",
                });
              });
              finalData.sort((a, b) => (a.username > b.username ? 1 : -1));

              this.setState({
                ...this.state,
                gridData: {
                  data: finalData,
                  total: finalData.length,
                },
                initialGridData: {
                  data: finalData,
                  total: finalData.length,
                },
                blocking: false,
              });
            })
            .catch((error) => {
              this.setState({
                ...this.state,
                openErrorModal: true,
                errorModalContent:
                  (error &&
                    error.response.data &&
                    error.response.data.error.message) ||
                  "Please try again",
                blocking: false,
              });
            });
        } else {
          //todo de very very carefully
          this.setState({ blocking: true });
          let roleID = this.props.dataItem.id;
          let fil = {
            filter: {
              where: {
                roleId: roleID.toString(),
              },
              include: [
                {
                  relation: "role",
                },
              ],
            },
          };

          let usersOfRole = serverApi("GET", `roleMappings`, fil, "", options);
          usersOfRole
            .then((response) => {
              let deleteID = response.data.filter(
                (x) => JSON.parse(x.principalId) === userID
              )[0].id;

              let removeUserFromRole = serverApi(
                "DELETE",
                `roleMappings/${deleteID}`,
                "",
                "",
                options,
                ""
              );
              removeUserFromRole
                .then(async (deleteResponse) => {
                  let attachedUsersToRole = [];
                  let fil2 = {
                    filter: {
                      where: {
                        roleId: roleID.toString(),
                      },
                      include: [
                        {
                          relation: "roles",
                        },
                      ],
                    },
                  };
                  let usersOfRole = await serverApi(
                    "GET",
                    `snUsers`,
                    fil2,
                    "",
                    options
                  );
                  usersOfRole = usersOfRole.data.filter(
                    (x) => x.roles.length > 0
                  );
                  usersOfRole.forEach((x) => {
                    if (x?.roles) {
                      (x?.roles || []).forEach((temp) => {
                        if (temp.id === this.props.dataItem.id) {
                          attachedUsersToRole.push(x);
                        }
                      });
                    }
                  });
                  let finalData = [];
                  _.forEach(attachedUsersToRole, (temp) => {
                    finalData.push({
                      id: temp.id,
                      username: temp.username,
                      userRealName: temp.userRealName,
                      email: temp.email || "N/A",
                      created: temp.created || "N/A",
                      last_login_date: temp.last_login_date || "N/A",
                    });
                  });
                  finalData.sort((a, b) => (a.username > b.username ? 1 : -1));

                  this.setState({
                    ...this.state,
                    gridData: {
                      data: finalData,
                      total: finalData.length,
                    },
                    initialGridData: {
                      data: finalData,
                      total: finalData.length,
                    },
                    blocking: false,
                  });
                })
                .catch((error) => {
                  this.setState({
                    ...this.state,
                    openErrorModal: true,
                    errorModalContent:
                      error?.response?.data?.error?.message ||
                      "Please try again",
                    blocking: false,
                  });
                });
            })
            .catch((error) => {
              this.setState({
                ...this.state,
                openErrorModal: true,
                errorModalContent:
                  error?.response?.data?.error?.message || "Please try again",
                blocking: false,
              });
            });
        }
      }
    );
  };

  handleChange = async (event, name, key) => {
    let finalUserClient = [...this.state.finalUserClients];
    let finalObj = finalUserClient.find((u) => u.id === key);

    let action = event.value === false ? "DELETE" : "POST";
    if (action === "POST") {
      let insertedRole = this.props.dataItem.id || this.state.roleID;
      finalObj.roleIDs.push(insertedRole);
    } else if (action === "DELETE") {
      let index = finalObj.roleIDs.indexOf(this.props.dataItem.id);
      if (index !== -1) {
        finalObj.roleIDs.splice(index, 1);
        finalObj.forPayload = true;
      } else {
        finalObj.forPayload = false;
      }
    }

    finalObj.checked = !finalObj.checked;

    this.setState(
      {
        ...this.state,
        finalUserClients: finalUserClient,
      },
      async () => {}
    );
  };

  remove = async (dataItem) => {
    // const data = [...this.state.gridData.data];
    // let index = data.findIndex(
    //   (p) => p === dataItem || (dataItem.id && p.id === dataItem.id)
    // );
    // if (index >= 0) {
    //   data.splice(index, 1);
    // }
    // let options = {
    //   token: window.localStorage.getItem("access_token"),
    // };
    // try {
    //   this.setState({visibleWindow: false, blocking: true});
    //   let roleID = this.props.dataItem.id;
    //   let fil = {
    //     filter: {
    //       where: {
    //         roleId: roleID.toString(),
    //       },
    //       include: [
    //         {
    //           relation: "role",
    //         },
    //       ],
    //     },
    //   };
    //   let usersOfRole = serverApi("GET", `roleMappings`, fil, "", options);
    //   usersOfRole
    //     .then((response) => {
    //       let deleteID = response.data.filter(x => JSON.parse(x.principalId) === dataItem.id)[0].id
    //       let removeUserFromRole = serverApi(
    //         "DELETE",
    //         `roleMappings/${deleteID}`,
    //         "",
    //         "",
    //         options,
    //         ""
    //       );
    //       removeUserFromRole
    //         .then(async (deleteResponse) => {
    //           let attachedUsersToRole = [];
    //           let fil2 = {
    //             filter: {
    //               where: {
    //                 roleId: roleID.toString(),
    //               },
    //               include: [
    //                 {
    //                   relation: "roles",
    //                 },
    //               ],
    //             },
    //           };
    //           let usersOfRole = await serverApi(
    //             "GET",
    //             `snUsers`,
    //             fil2,
    //             "",
    //             options
    //           );
    //           usersOfRole = usersOfRole.data.filter((x) => x.roles.length > 0);
    //           usersOfRole.filter((x) => {
    //             if (x?.roles) {
    //               (x?.roles || []).forEach((temp) => {
    //                 if (temp.id === this.props.dataItem.id) {
    //                   attachedUsersToRole.push(x);
    //                 }
    //               });
    //             }
    //           });
    //           let finalData = [];
    //           _.forEach(attachedUsersToRole, (temp) => {
    //             finalData.push({
    //               id: temp.id,
    //               username: temp.username,
    //               userRealName: temp.userRealName,
    //               email: temp.email || "N/A",
    //               created: temp.created || "N/A",
    //               last_login_date: temp.last_login_date || "N/A",
    //             });
    //           });
    //           finalData.sort((a, b) => (a.username > b.username ? 1 : -1));
    //           this.setState({
    //             ...this.state,
    //             gridData: {
    //               data: finalData,
    //               total: finalData.length,
    //             },
    //             initialGridData: {
    //               data: finalData,
    //               total: finalData.length,
    //             },
    //             blocking: false,
    //             inputValue: "",
    //           });
    //         })
    //         .catch((error) => {
    //           this.setState({
    //             ...this.state,
    //             openErrorModal: true,
    //             errorModalContent:
    //               error?.response?.data?.error?.message || "Please try again",
    //             blocking: false,
    //           });
    //         });
    //     })
    //     .catch((error) => {
    //       this.setState({
    //         ...this.state,
    //         openErrorModal: true,
    //         errorModalContent:
    //           error?.response?.data?.error?.message || "Please try again",
    //         blocking: false,
    //       });
    //     });
    // } catch (error) {
    //   this.setState({
    //     ...this.state,
    //     openErrorModal: true,
    //     errorModalContent:
    //       error?.response?.data?.error?.message || "Please try again",
    //     blocking: false,
    //   });
    // }
  };

  getExportData = async () => {
    await this.exportToCsv(this.state, this.props);
  };

  getPromise = () => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let attachedUsersToRoles = [];
    const roleID = this.props.dataItem.id;
    let fil = {
      filter: {
        where: {
          roleId: roleID.toString(),
        },
        include: [
          {
            relation: "roles",
          },
        ],
      },
    };
    return new Promise((resolve, reject) => {
      let usersOfRole = serverApi("GET", `snUsers`, fil, "", options);
      usersOfRole
        .then((response) => {
          usersOfRole = response.data.filter((x) => x.roles.length > 0);
          usersOfRole.forEach((x) => {
            if (x?.roles) {
              (x?.roles || []).forEach((temp) => {
                if (temp.id === this.props.dataItem.id) {
                  attachedUsersToRoles.push(x);
                }
              });
            }
          });
          resolve(attachedUsersToRoles);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  exportToCsv = async (state, props) => {
    const count = state.gridData.total;
    const numberOfCalls = Math.min(
      Math.round(count / 1000) + 1,
      5 // Limit to 5000
    );

    const promises = [];
    for (let i = 0; i < numberOfCalls; i++) {
      promises.push(
        this.getPromise({
          skip: i * 1000,
          limit: 1000,
          order: ["id asc"],
        })
      );
    }

    try {
      const responses = await Promise.all(promises);
      let allData = [];
      responses.forEach((resp) => {
        let finalData = [];
        _.forEach(resp, (temp) => {
          finalData.push({
            id: temp.id,
            username: temp.username,
            userRealName: temp.userRealName,
            email: temp.email || "N/A",
            created: temp.created || "N/A",
            last_login_date: temp.last_login_date || "N/A",
          });
        });
        finalData.sort((a, b) => (a.username > b.username ? 1 : -1));
        allData = [...allData, ...finalData];
      });
      if (allData.length > 0) {
        this.setState({
          ...this.state,
          exportData: allData,
        });
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent:
          (e.response.data && e.response.data.error.message) ||
          "Please try again",
      });
    }
  };

  handleSearch = (e) => {
    let initialFinalUserClients = this.state.initialFinalUserClients;

    if (e.value === "") {
      let searchArr = [];
      initialFinalUserClients.forEach((temp) => {
        if (temp.name.toUpperCase().includes(e.value.toUpperCase())) {
          searchArr.push(temp);
        }
      });

      if (
        this.state.clientAttachedToAnotherGroup !== null &&
        this.state.clientAttachedToAnotherGroup !== undefined
      ) {
        let newArr = searchArr.filter(
          (t) => t.id !== this.state.clientAttachedToAnotherGroup
        );
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalUserClients: newArr,
          openErrorModal: false,
          errorModalContent: "",
        });
      } else {
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalUserClients: initialFinalUserClients,
          openErrorModal: false,
          errorModalContent: "",
        });
      }
    } else {
      let searchArr = [];
      //case insensitive
      initialFinalUserClients.forEach((temp) => {
        if (temp && temp.name.toUpperCase().includes(e.value.toUpperCase())) {
          searchArr.push(temp);
        }
      });

      if (
        this.state.clientAttachedToAnotherGroup !== null &&
        this.state.clientAttachedToAnotherGroup !== undefined
      ) {
        let newArr = searchArr.filter(
          (t) => t.id !== this.state.clientAttachedToAnotherGroup
        );
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalUserClients: newArr,
          openErrorModal: false,
          errorModalContent: "",
        });
      } else {
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalUserClients: searchArr,
          openErrorModal: false,
          errorModalContent: "",
        });
      }
    }
  };

  closeWindow = () => {
    this.setState({
      ...this.state,
      visibleWindow: false,
      inputValue: "",
    });
  };

  toggleWindow = async (e) => {
    const options = { token: window.localStorage.getItem("access_token") };
    this.setState({ ...this.state, blocking: true });

    const roleID = this.props.dataItem.id;
    let fil = {
      filter: {
        where: {
          roleId: roleID.toString(),
        },
        include: [
          {
            relation: "roles",
          },
        ],
      },
    };
    let attachedUsersToRole = [];
    let usersOfRole = await serverApi("GET", `snUsers`, fil, "", options);
    let all_users = _.cloneDeep(usersOfRole);
    usersOfRole = usersOfRole.data.filter((x) => x.roles.length > 0);
    usersOfRole.forEach((x) => {
      if (x?.roles) {
        (x?.roles || []).forEach((temp) => {
          if (temp.id === this.props.dataItem.id) {
            attachedUsersToRole.push(x);
          }
        });
      }
    });
    let allUsers = all_users;

    let result = this.constructUsersAndExistingUsersArray(
      allUsers.data,
      attachedUsersToRole,
      roleID
    );
    let existingUsersToRole = [];
    result[0].finalAttachedUsers.forEach((item) => {
      let user = result[0].finalUsers.find((x) => x.id === item.id);
      if (user) {
        user.checked = true;
        existingUsersToRole.push(user);
      }
    });

    //todo refactor to proper code ! ! !
    if (attachedUsersToRole && attachedUsersToRole.length > 1) {
      let finalData = [];
      _.forEach(attachedUsersToRole, (temp) => {
        finalData.push({
          id: temp.id,
          username: temp.username,
          userRealName: temp.userRealName,
          email: temp.email || "N/A",
          created: temp.created || "N/A",
          last_login_date: temp.last_login_date || "N/A",
        });
      });
      finalData.sort((a, b) => (a.username > b.username ? 1 : -1));

      this.setState({
        ...this.state,
        existingUsersToRole: existingUsersToRole,
        roleID: this.props.dataItem.id,
        visibleWindow: !this.state.visibleWindow,
        gridData: {
          data: finalData,
          total: finalData.length,
        },
        initialGridData: {
          data: finalData,
          total: finalData.length,
        },
        valueUserName: "",

        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    } else if (attachedUsersToRole && attachedUsersToRole.length === 1) {
      let finalData = [];
      _.forEach(attachedUsersToRole, (temp) => {
        finalData.push({
          id: temp.id,
          username: temp.username,
          userRealName: temp.userRealName,
          email: temp.email || "N/A",
          created: temp.created || "N/A",
          last_login_date: temp.last_login_date || "N/A",
        });
      });
      finalData.sort((a, b) => (a.username > b.username ? 1 : -1));

      this.setState({
        ...this.state,
        existingUsersToRole: existingUsersToRole,
        roleID: this.props.dataItem.id,
        visibleWindow: !this.state.visibleWindow,
        gridData: {
          data: [finalData[0]],
          total: 1,
        },
        initialGridData: {
          data: [finalData[0]],
          total: 1,
        },
        valueUserName: "",
        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    } else {
      this.setState({
        ...this.state,
        existingUsersToRole: existingUsersToRole,
        roleID: this.props.dataItem.id,
        visibleWindow: !this.state.visibleWindow,
        gridData: {
          data: [],
          total: 0,
        },
        initialGridData: {
          data: [],
          total: 0,
        },
        valueUserName: "",
        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    }
  };

  renderList = (finalUsers = [], users = [], doRefresh) => {
    if (doRefresh === true) {
      window.location.reload();
    } else {
      if (finalUsers.length === users.length) {
        return (
          users &&
          users.map((item) => (
            <ul style={{ marginBottom: "-5px" }} key={item.id}>
              <Checkbox
                name={item.name}
                key={item.id}
                value={item.checked}
                onChange={(e) => this.handleChange(e, item.name, item.id)}
                label={item.name}
                disabled={this.state.disableAllCheckboxes}
              />
            </ul>
          ))
        );
      } else {
        return (
          finalUsers &&
          finalUsers.map((item) => (
            <ul style={{ marginBottom: "-5px" }} key={item.id}>
              <Checkbox
                name={item.name}
                key={item.id}
                value={item.checked}
                onChange={(e) => this.handleChange(e, item.name, item.id)}
                label={item.name}
                disabled={this.state.disableAllCheckboxes}
              />
            </ul>
          ))
        );
      }
    }
  };

  cancel = (dataItem = {}) => {
    if (dataItem.name === undefined || dataItem.description === undefined) {
      const data =
        this.state.initialGridData && this.state.initialGridData.data;
      const total = this.state.initialGridData.total;

      this.setState({
        ...this.state,
        gridData: {
          data: data,
          total: total,
        },
        errorModalContent:
          this.state.errorModalContent !== ""
            ? ""
            : this.state.errorModalContent,
        openErrorModal:
          this.state.openErrorModal === true
            ? false
            : this.state.errorModalContent,
      });
    } else {
      const originalItem =
        this.state.initialGridData &&
        this.state.initialGridData.data.find((p) => p.id === dataItem.id);
      originalItem.inEdit = false;

      const data =
        this.state.initialGridData &&
        this.state.initialGridData.data.map((item) =>
          item.id === originalItem.id ? originalItem : item
        );

      const total = this.state.initialGridData.total;

      this.setState({
        ...this.state,
        gridData: {
          data: data,
          total: total,
        },
      });
    }
  };

  discard = (dataItem, hasError = false) => {
    let data = [...this.state.gridData.data];
    const total = this.state.gridData.total;

    if (hasError) {
      let index = data.findIndex((p) => p.id === dataItem.id);

      data[index].inEdit = false;

      this.setState(
        {
          ...this.state,
          gridData: {
            data: data,
            total: total,
          },
        },
        () => {
          setTimeout(() => {
            this.setState({
              ...this.state,
              openErrorModal: false,
              errorModalContent: "",
            });
          }, 3000);
        }
      );
    } else {
      this.removeItem(data, dataItem);

      this.setState({
        ...this.state,
        gridData: {
          data: data,
          total: total,
        },
        visible: false,
      });
    }
  };

  update = async (dataItem) => {
    const data = [...this.state.gridData.data];
    const updatedItem = { ...dataItem, inEdit: undefined };
    this.updateItem(data, updatedItem);

    let editPayload = updatedItem;

    if (
      editPayload.name === "" ||
      editPayload.name === undefined ||
      editPayload.description === "" ||
      editPayload.description === undefined
    ) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            "Error: Required fields to edit the role: Name and Description.",
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 3500);
        }
      );
    } else {
      try {
        delete editPayload.inEdit;
        delete editPayload.expanded;

        const editRequest = await AbstractEdit(
          dataItem.id,
          editPayload,
          "departments",
          {
            token: window.localStorage.getItem("access_token"),
          }
        );
        const countRequest = await serverApi(
          "GET",
          "departments/count",
          "",
          "",
          {
            token: window.localStorage.getItem("access_token"),
          }
        );

        if (editRequest.status === 200 && countRequest) {
          this.setState(
            {
              ...this.state,
              gridData: {
                data: data,
                total: countRequest.data.count,
              },
              deleteVisible: false,
              successMessage: "The department was successfully updated.",
              openSuccessMessage: true,
            },
            () => {
              setTimeout(() => {
                this.handleSuccessClose();
              }, 3000);
            }
          );
        }
      } catch (e) {
        this.setState(
          {
            ...this.state,
            openErrorModal: true,
            errorModalContent:
              (e.response.data && e.response.data.error.message) ||
              "Please try again",
          },
          () => this.discard(dataItem, true)
        );
      }
    }
  };

  itemChange = (event) => {
    let fieldsArr = [
      "vessel.vessel_name",
      "vessel.vessel_imo_no",
      "vessel.vessel_type",
      "vessel.vessel_flag_iso",
      "vessel.vessel_build_dt",
      "vessel.vessel_builder",
      "vessel.vessel_scrapped_dt",
      "vessel.vessel_deadweight",
      "vessel.vessel_build_country",
    ];

    let fieldName;
    let index;
    if (fieldsArr.includes(event.field)) {
      index = fieldsArr.indexOf(event.field);
      fieldName = fieldsArr[index].split(".")[1];
    }

    const finalData = [];
    const total = this.state.gridData.total;

    let finalItem;

    (this.state.gridData.data || []).forEach((item) => {
      let vessel = {
        ...item.vessel,
        [fieldName]: event.value,
      };

      if (item.id === event.dataItem.id) {
        delete item.vessel;
        finalItem = {
          ...item,
          vessel,
        };
        finalData.push(finalItem);
      } else {
        finalItem = item;
        finalData.push(finalItem);
      }
    });

    this.setState(
      {
        gridData: {
          data: finalData,
          total: total,
        },
      },
      () => this.renderGridColumns(finalItem, true)
    );
  };

  saveNewUsers = async () => {
    const finalUsers = [
      ...this.state.finalUserClients.filter((x) => x.checked === true),
      ...this.state.finalUserClients.filter((x) => x.forPayload === true),
    ]; //, ...this.state.finalUserClients.filter(x => x.forPayload === true)
    const initialUsers = this.state.initialFinalUserClients.filter(
      (x) => x.checked
    );
    const final = [...finalUsers, ...initialUsers];
    const uniqueData = final.filter(
      (thing, index, self) => index === self.findIndex((t) => t.id === thing.id)
    );
    let result = uniqueData.filter(
      (thing, index, self) => index === self.findIndex((t) => t.id === thing.id)
    );
    result = [
      ...result.filter((x) => x.forPayload),
      ...result
        .filter(
          ({ name }) =>
            !this.state.existingUsersToRole.some((e) => e.name === name)
        )
        .filter((x) => x.checked),
    ];

    let payload = {
      data: [],
    };

    //check for each user if has at least one role
    if ((result || []).some((x) => x.roleIDs.length === 0) === true) {
      this.setState(
        {
          ...this.state,
          visibleWindow: false,
          windowErrorMessage: `At least one role for each user is required for the process to continue. Check if you accidentally removed a user that belongs only to ${this.props.dataItem.name} role.`,
          windowError: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              ...this.state,
              windowErrorMessage: "",
              windowError: false,
            });
          }, 6000);
        }
      );
      return;
    }

    (result || []).forEach((usr) => {
      payload.data.push({
        userID: usr.id,
        roleIDs: usr.roleIDs,
      });
    });

    const promises = [];
    const batchSize = 5;

    let numberOfCalls = Math.ceil(result.length / batchSize);

    function sliceIntoChunks(arr, chunkSize) {
      const res = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
      }
      return res;
    }

    let payloadForPromises = sliceIntoChunks(result, batchSize);

    for (let i = 0; i < numberOfCalls; i++) {
      promises.push(
        this.constructPromise({
          data: payloadForPromises[i],
        })
      );
    }

    try {
      this.setState({
        ...this.state,
        blocking: true,
        blockingMessage: "Please wait a few seconds...",
      });

      const responses = await Promise.allSettled(promises);
      if (responses) {
        if (responses.some((x) => x.status !== "rejected")) {
          this.setState(
            {
              ...this.state,
              blocking: false,
              blockingMessage: "",
              inputValue: "",
              visibleWindow: false,
            },
            async () => {
              await this.props.openSuccessModal(
                "The process has finished successfully."
              );
              await this.fetchLatestData();
            }
          );
        } else {
          this.setState(
            {
              ...this.state,
              blocking: false,
              blockingMessage: "",
              inputValue: "",
              visibleWindow: false,
            },
            async () => {
              await this.props.openSuccessModal(
                "The process was partially finished ,please refresh the page and make the appropriate actions."
              );
              await this.fetchLatestData();
            }
          );
        }
      }
    } catch (error) {
      this.setState(
        {
          ...this.state,
          windowErrorMessage:
            error?.response?.data?.error?.message ||
            "Process failed please try again.If the problem persist contact the administrator.",
          windowError: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              ...this.state,
              windowErrorMessage: "",
              windowError: false,
              blocking: false,
              blockingMessage: "",
            });
          }, 5000);
        }
      );
    }
  };

  constructPromise = async (payload) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    const url = `/snUsers/roles/bulkUpdate`;
    return await serverApi("POST", url, "", payload, options, "", "");
  };

  fetchLatestData = async () => {
    const options = { token: window.localStorage.getItem("access_token") };
    this.setState({ ...this.state, blocking: true });

    const roleID = this.props.dataItem.id;
    let fil = {
      filter: {
        where: {
          roleId: roleID.toString(),
        },
        include: [
          {
            relation: "roles",
          },
        ],
      },
    };
    let attachedUsersToRole = [];
    let usersOfRole = await serverApi("GET", `snUsers`, fil, "", options);
    let all_users = _.cloneDeep(usersOfRole);
    usersOfRole = usersOfRole.data.filter((x) => x.roles.length > 0);
    usersOfRole.forEach((x) => {
      if (x?.roles) {
        (x?.roles || []).forEach((temp) => {
          if (temp.id === this.props.dataItem.id) {
            attachedUsersToRole.push(x);
          }
        });
      }
    });
    let allUsers = all_users;

    let result = this.constructUsersAndExistingUsersArray(
      allUsers.data,
      attachedUsersToRole,
      roleID
    );
    result[0].finalAttachedUsers.forEach((item) => {
      let user = result[0].finalUsers.find((x) => x.id === item.id);
      if (user) {
        user.checked = true;
      }
    });

    //todo refactor to proper code ! ! !
    if (attachedUsersToRole && attachedUsersToRole.length > 1) {
      let finalData = [];
      _.forEach(attachedUsersToRole, (temp) => {
        finalData.push({
          id: temp.id,
          username: temp.username,
          userRealName: temp.userRealName,
          email: temp.email || "N/A",
          created: temp.created || "N/A",
          last_login_date: temp.last_login_date || "N/A",
        });
      });
      finalData.sort((a, b) => (a.username > b.username ? 1 : -1));

      this.setState({
        ...this.state,
        roleID: this.props.dataItem.id,
        gridData: {
          data: finalData,
          total: finalData.length,
        },
        initialGridData: {
          data: finalData,
          total: finalData.length,
        },
        valueUserName: "",

        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    } else if (attachedUsersToRole && attachedUsersToRole.length === 1) {
      let finalData = [];
      _.forEach(attachedUsersToRole, (temp) => {
        finalData.push({
          id: temp.id,
          username: temp.username,
          userRealName: temp.userRealName,
          email: temp.email || "N/A",
          created: temp.created || "N/A",
          last_login_date: temp.last_login_date || "N/A",
        });
      });
      finalData.sort((a, b) => (a.username > b.username ? 1 : -1));

      this.setState({
        ...this.state,
        roleID: this.props.dataItem.id,
        gridData: {
          data: [finalData[0]],
          total: 1,
        },
        initialGridData: {
          data: [finalData[0]],
          total: 1,
        },
        valueUserName: "",
        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    } else {
      this.setState({
        ...this.state,
        roleID: this.props.dataItem.id,
        gridData: {
          data: [],
          total: 0,
        },
        initialGridData: {
          data: [],
          total: 0,
        },
        valueUserName: "",
        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    }
  };

  render() {
    const dataItem = this.props.dataItem;
    const { gridData } = this.state;
    const { inputValue } = this.state;

    return (
      <div>
        <div>
          <h4 style={{ fontFamily: "bold", textSize: "7px" }}>
            Role name : {dataItem && dataItem.name}
          </h4>

          {this.state.windowError ? (
            <Error style={{ color: "red" }}>
              <h3>{this.state.windowErrorMessage || ""}</h3>
            </Error>
          ) : null}
        </div>

        <Grid
          {...gridData}
          filterable={false}
          style={{ className: "grid-no-select" }}
          sortable={true}
          resizable
          editField={this.editField}
          onItemChange={this.itemChange}
          pageable={false}
        >
          <GridToolbar>
            <GridContainer xs={12} justify={"flex-start"} direction={"row"}>
              <Tooltip
                justify={"flex-start"}
                placement="top"
                title={"Add/Remove User"}
              >
                <IconButton onClick={this.toggleWindow}>
                  <FontAwesomeIcon
                    color="#0D5869"
                    size="1.6x"
                    icon={faPencilAlt}
                  />
                </IconButton>
              </Tooltip>
              <ViewSettings
                {...this.props}
                export={{
                  data: this.state.exportData || [],
                  fileName: `Attached_Users_to_Role_${this.props.dataItem.name}_`,
                  exportFunction: this.getExportData,
                  columns: columnsSchema(),
                }}
                viewSettings={{
                  type: "",
                }}
                // refreshSettings={true}
                // refreshFunction={this.refreshGridDataAfterCrud}g
              />
            </GridContainer>
          </GridToolbar>

          {/* <Column
            cell={this.CommandCell}
            title="Actions"
            filterable={false}
            width="200px"
          /> */}
          {this.renderGridColumns(dataItem)}
        </Grid>

        {this.state.visibleWindow && (
          <Window
            title={`Add/Remove Users from Role: ${this.props.dataItem.name}`}
            // style={{backgroundColor:'#0d5869'}}
            onClose={this.closeWindow}
            width={900}
            height={600}
            initialTop={10}
          >
            <>
              <BlockUi
                tag="div"
                blocking={this.state.blocking}
                message="Loading, please wait. . ."
                renderChildren={true}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "50px",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      style={{
                        position: "sticky",
                        top: "0px",
                        zIndex: 2,
                        backgroundColor: "ffff",
                      }}
                    >
                      <RegularButton
                        style={{ backgroundColor: "#0d5869" }}
                        roundSmall="true"
                        onClick={this.saveNewUsers}
                        size="sm"
                        // disabled={this.state.disableSaveNewDepartments || false}
                      >
                        Save changes
                      </RegularButton>
                    </div>
                    {this.state.openErrorModal ? (
                      <Error style={{ color: "red", fontSize: "15px" }}>
                        {this.state.errorModalContent || ""}
                      </Error>
                    ) : null}
                    <div
                      style={{
                        display: "flex",
                        marginLeft: "35px",
                        marginTop: "20px",
                      }}
                    >
                      <Input
                        name="users"
                        value={inputValue}
                        style={{ marginBottom: "20px" }}
                        label="Search user"
                        onChange={(e) => this.handleSearch(e)}
                        minLength={3}
                      />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {this.renderList(
                        this.state.finalUserClients,
                        this.state.visibleClients
                      )}
                    </div>
                  </div>
                </div>
              </BlockUi>
            </>
          </Window>
        )}
      </div>
    );
  }
}

export default ExpandedUsers;
