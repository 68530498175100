import * as React from "react";
import PrimaryTabs from "../../../components/PrimaryTabs/PrimaryTabs";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { roles } from "lib/Enums";

const ExpandedTabs = props => {
  const [tabValue, setTabValue] = useState(null); //set to 0 for automated open the first tab or the n-est

  const user = props.user;
  const tabs = props.tabs;

  useEffect(
    user => {
      chooseViewPerRole(user);
    },
    // eslint-disable-next-line
    [user, tabs]
  );

  const chooseViewPerRole = () => {
    let roleIds = user.roleIds || [];
    let tabs = props.tabs || [];

    if (
      roleIds.indexOf(roles.SUPERROLE) > -1 ||
      roleIds.indexOf(roles.BACKOFFICE) > -1 ||
      roleIds.indexOf(roles.BACKOFFICEMASTERDATAREAD) > -1 ||
      roleIds.indexOf(roles.BACKOFFICEMASTERDATA) > -1
    ) {
      return (
        <PrimaryTabs
          tabValue={tabValue}
          onChange={value => {
            setTabValue(value);
          }}
          tabs={tabs}
        />
      );
    } else {
      return <div />;
    }
  };

  return (
    <div>
      {chooseViewPerRole()}
    </div>
  );
};

export default connect(state => {
  return {
    user: state.MainLayoutReducer.user
  };
}, null)(ExpandedTabs);
