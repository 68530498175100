export const columnsSchema = () => [
  {
    field: "id",
    title: "ID",
    visible: true,
    minWidth: 170,
    sortable: true,
    filterable: true,
    locked: "locked",
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
    // cell: true
  },
  {
    field: "name",
    title: "Modality",
    visible: true,
    minWidth: 230,
    sortable: true,
    filterable: true,
    locked: "locked",
  },
  {
    field: "iataCode",
    title: "IATA Code(2 letter code)",
    visible: true,
    minWidth: 220,
    sortable: true,
    filterable: true,
    locked: "locked",
  },
  {
    field: "iataAirlineCodeNumber",
    title: "IATA Airline Code Number(3 letter code)",
    visible: true,
    minWidth: 170,
    sortable: true,
    filterable: true,
    locked: "locked",
  },
  {
    field: "scac",
    title: "SCAC",
    visible: true,
    minWidth: 220,
    sortable: true,
    filterable: true,
    locked: "locked",
  },
];
