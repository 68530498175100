import React from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { isBoolean } from "lodash";
import { filterBy } from "@progress/kendo-data-query";

export const DropDownCell = (props) => {
  const [data, setData] = React.useState(
    (props.data || []).slice() ||
      [
        { text: "Y", value: true },
        { text: "N", value: false },
      ].slice()
  );

  const handleChange = (e) => {
    props.onChange({
      dataItem: props.dataItem,
      field: props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value.value,
    });
  };

  const filterData = (filter) => {
    const data = (props.data || []).slice();

    // Add the "NONE" option to the filtered data if it's not already present
    const noneOption = { text: "NONE", value: null };
    const filteredData = data.some((item) => item.value === null)
      ? data // "NONE" option is already present, no need to add it
      : [noneOption, ...data];

    return filterBy(filteredData, filter);
  };

  const filterChange = (event) => {
    setData(filterData(event.filter));
  };

  const { dataItem, field, width, filterable, style } = props;
  let dataValue = dataItem[field] === null ? "" : dataItem[field];
  if (isBoolean(dataItem[field])) {
    if (dataValue === true) {
      dataValue = "Yes";
    } else {
      dataValue = "No";
    }
  }

  // Handle "NONE" differently
  if (dataValue === "NONE") {
    return (
      <td style={style || {}}>
        <DropDownList
          style={{ width: width ? width : "100px" }}
          onChange={handleChange}
          value={{ text: "NONE", value: null }}
          data={data}
          textField="text"
          dataItemKey="value"
          filterable={filterable}
          onFilterChange={filterChange}
        />
      </td>
    );
  }

  return (
    <td style={style || {}}>
      {dataItem && dataItem.inEdit ? (
        <DropDownList
          style={{ width: width ? width : "100px" }}
          onChange={handleChange}
          value={(data || []).find((c) => c.value === dataItem[field])}
          data={data}
          textField="text"
          dataItemKey="value"
          filterable={filterable}
          onFilterChange={filterChange}
        />
      ) : (
        (dataValue !== undefined && dataValue.toString()) || ""
      )}
    </td>
  );
};
