import React from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { isBoolean } from "lodash";

const DropdownFilterCell = (props) => {
  const [data, setData] = React.useState((props.data || []).slice());

  const hasValue = (value) => Boolean(value && value !== props.defaultValue);

  const onChange = (event) => {
    let finalVal = event.target.value;
    const hasValueNew = hasValue(event.target.value);

    if (event.target.value === "International") {
      finalVal = "I";
    } else if (event.target.value === "Domestic") {
      finalVal = "D";
    } else if (event.target.value === "No") {
      finalVal = false;
    } else if (event.target.value === "Yes") {
      finalVal = true;
    } else if (props.hasMultipleValues) {
      finalVal = event.target.value.slice(0, 2);
    }

    props.onChange({
      value: hasValueNew ? finalVal : "",
      operator: hasValueNew ? "eq" : "",
      syntheticEvent: event.syntheticEvent,
    });
  };

  const filterData = (filter) => {
    const data = (props.data || []).slice();
    return filterBy(data, filter);
  };

  const filterChange = (event) => {
    setData(filterData(event.filter));
  };

  const onClearButtonClick = (event) => {
    event.preventDefault();
    props.onChange({
      value: "",
      operator: "",
      syntheticEvent: event,
    });
  };

  let { value, defaultValue, filterable } = props;

  if (isBoolean(value)) {
    if (value === true) {
      value = "Yes";
    } else {
      value = "No";
    }
  }

  if (value === "D") {
    value = "Domestic";
  } else if (value === "I") {
    value = "International";
  }

  return (
    <div>
      <DropDownList
        style={{
          width: props.width || "135px",
          color: "#4ac2d0",
        }}
        data={data}
        onChange={onChange}
        // value={value || defaultValue} -> an theloume na deixnume stin column to select status san label
        value={value}
        defaultItem={defaultValue}
        filterable={filterable || true}
        onFilterChange={filterChange}
      />
      {value && (
        <Button
          title="Clear"
          style={{
            cursor: "pointer",
            marginLeft: "5px",
          }}
          // disabled={!hasValue(props.value)}
          onClick={onClearButtonClick}
          icon="filter-clear"
        />
      )}
    </div>
  );
};

export default DropdownFilterCell;
