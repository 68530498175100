import React, { createRef, useState, useEffect } from "react";
import CustomSidebar from "../../components/Sidebar/CustomSidebar";
import Header from "../../components/Header/CustomeHeader";
import compose from "ramda/src/compose";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Routes from "../../frontendRouting";
import { actions } from "./state";
import Snackbar from "../../components/Snackbar/Snackbar";
import * as Sentry from "@sentry/react";
import { getAWSWebSocketURL } from "../../networking/config";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import ModalNewVersion from "../../routes/common/Modals/ModalNewVersion";
import { NAV } from "navigatorsdk";

function showTitle(props) {
  let title = "";
  let path = props?.location?.pathname?.toLowerCase() || "";

  if (path.includes("app/master/clients")) {
    title = "BO - Clients";
  } else if (path.includes("app/master/entities")) {
    title = "BO - Master Entities";
  } else if (path.includes("app/master/vessels")) {
    title = "BO - Vessels";
  } else if (path.includes("app/master/usermanagement")) {
    title = "Edit User Departments";
  } else if (path.includes("app/master/visibleclients")) {
    title = "Visible Clients";
  } else if (path.includes("app/master/snusers")) {
    title = "Operator Accounts";
  } else if (path === "app/master/departments") {
    title = "BO - Departments";
  } else if (path.includes("app/master/agents")) {
    title = "BO - Agents-Vendors";
  } else if (path.includes("/app/master/clienttogroup")) {
    title = "BO - Client To Group";
  } else if (path.includes("/app/master/grouptoclient")) {
    title = "BO - Group To Client";
  } else if (path.includes("/app/master/domains")) {
    title = "BO - Domains";
  } else if (path.includes("/app/settings")) {
    title = "BO - Settings";
  } else if (path.includes("/app/master/suppliers")) {
    title = "BO - Suppliers";
  } else if (path.includes("/app/master/deps")) {
    title = "BO - Departments";
  } else if (path.includes("/app/master/operators")) {
    title = "BO - Operators";
  } else if (path.includes("/app/master/departments/edit/")) {
    title = "User Departments";
  } else if (path.includes("app/master/user-clients")) {
    title = "BO - Client Users";
  } else if (path.includes("app/master/user-to-clients/edit")) {
    title = "Edit user clients";
  } else if (path.includes("app/master/roles")) {
    title = "BO - Roles";
  } else if (path.includes("app/master/users/all")) {
    title = "BO - All users";
  } else if (path === "/app/master/whusers") {
    title = "BO - Warehouse users";
  } else if (path === "/app/master/agentusers") {
    title = "BO - Agent users";
  } else if (path === "/app/master/wsusers") {
    title = "BO - Websocket users";
  } else if (path === "/app/master/boxes") {
    title = "Boxes";
  } else if (path === "/app/master/orders") {
    title = "Orders";
  } else if (path === "/app/master/dispatches") {
    title = "Dispatches";
  } else if (path.includes("/app/master/backofficelogs")) {
    title = "BO - Backoffice Log";
  } else if (path.includes("/app/master/invoicelog")) {
    title = "BO - Invoice Log";
  } else if (path.includes("/app/master/logbox")) {
    title = "BO -  Box Log";
  } else if (path === "/app/master/whmappings") {
    title = "BO - If Warehouse Mappings";
  } else if (path === "/app/master/airports") {
    title = "BO - Airports";
  } else if (path === "/app/master/seaports") {
    title = "BO - Seaports";
  } else if (path === "/app/master/longrunningsesssions") {
    title = "BO - Long Running Sessions";
  } else if (path === "/app/master/fleets") {
    title = "BO - Fleets";
  } else if (path === "/app/master/assets") {
    title = "BO - Assets";
  } else if (path === "/app/master/carriers") {
    title = "BO - Carriers";
  } else if (path === "/app/master/warehouses") {
    title = "BO - Warehouses";
  } else if (path === "/app/master/modelsettingdefinitions") {
    title = "BO - Model Setting Definitions";
  } else if (path === "/app/master/approvalactiontiers") {
    title = "BO - Approval Action Tiers";
  }
  return title;
}

function MainLayout(props) {
  const ref = createRef(null);
  const [showReleaseInfo, setShowReleaseInfo] = useState(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openErrorModalContent, setOpenErrorModalContent] = useState("");
  const [sidebarCategories, setSidebarCategories] = useState([
    { id: 1, collapsed: false },
    { id: 7, collapsed: false },
    { id: 37, collapsed: false },
    { id: 10, collapsed: false },
    { id: 17, collapsed: false },
    { id: 50, collapsed: false },
    { id: 50, collapsed: false },
  ]);

  const handleErrorClose = () => {
    setOpenErrorModal(false);
    setOpenErrorModalContent("");
  };

  const openCloseSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const addNotificationListener = () => {
    window.awsWebSocket.onmessage = function (event) {
      let data = null;
      try {
        data = JSON.parse(event.data);
      } catch {
        data = event.data;
      }

      if (
        typeof data !== "string" &&
        data.name === "newVersionReleaseBackoffice"
      ) {
        setShowReleaseInfo(true);
      }
    };
  };

  const addAWSWebSocket = (accessToken) => {
    // let closedWithError = false;
    let pingInterval;
    const socketURL = getAWSWebSocketURL();
    if (socketURL) {
      window.awsWebSocket = new WebSocket(socketURL, [accessToken]);
      window.awsWebSocket.addEventListener("open", function (event) {
        const pingMessage = JSON.stringify({
          action: "ping",
        });
        pingInterval = setInterval(() => {
          window.awsWebSocket.send(pingMessage);
        }, 10000);
      });
      window.awsWebSocket.addEventListener("close", function (event) {
        if (pingInterval) clearInterval(pingInterval);
        // closedWithError = false;
      });
      window.awsWebSocket.addEventListener("error", function (event) {
        console.log("WS Error", event);
        // closedWithError = true;
      });
      //to receive the message from server
      window.awsWebSocket.addEventListener("onmessage", function (event) {
        //  console.log("on message", event)
      });
    }
  };

  useEffect(() => {
    if (window.awsWebSocket && window.awsWebSocket.onmessage == null) {
      addNotificationListener();
    }
    //eslint-disable-next-line
  }, [window.awsWebSocket?.readyState]);

  useEffect(() => {
    (async () => {
      let request = await props.getUserInfo();
      if (request) {
        Sentry.setUser({
          email: request?.email || "N/A",
          id: request?.id || "N/A",
          username: request?.username || "N/A",
        });
      }
    })();
    props.getCountryCodes();
    props.getLovs();
    addAWSWebSocket(window.localStorage.getItem("access_token"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = showTitle(props);
  }, [props]);

  useEffect(() => {
    if (window.localStorage.getItem("access_token")) {
      NAV.setAccessToken(window.localStorage.getItem("access_token"));
    }
  }, []);

  return (
    <div
      ref={ref}
      className={`main-container ${isSidebarCollapsed ? "sidebar-mini" : ""}`}
    >
      <CustomSidebar
        user={props.user}
        mainContainerRef={ref}
        isSidebarLocked={false} //todo change if button in header
        setSidebarCollapsed={(value) => {}}
        isSidebarCollapsed={isSidebarCollapsed}
        sidebarCategories={sidebarCategories}
        setSidebarCategories={(sidebarCategories) => {
          setSidebarCategories(sidebarCategories);
        }}
        onLogout={() => {
          //support of the backup server: dataserver-dev2
          let environmentLocation =
            window && window.location && window.location.hostname;
          if (environmentLocation === "localhost") {
            window.location.href = "http://localhost:7002/auth/logout";
          } else if (environmentLocation === "dev2.swift-navigator.com") {
            window.location.href =
              "https://dev2.swift-navigator.com/auth/logout";
          } else if (environmentLocation === "uat2.swift-navigator.com") {
            window.location.href =
              "https://uat2.swift-navigator.com/auth/logout";
          } else if (environmentLocation === "prod2.swift-navigator.com") {
            window.location.href =
              "https://prod2.swift-navigator.com/auth/logout";
          } else if (
            environmentLocation === "dataserver-dev2.swift-navigator.com"
          ) {
            window.location.href =
              "https://dataserver-dev2.swift-navigator.com/auth/logout";
          } else if (
            environmentLocation === "dataserver-prod2.swift-navigator.com"
          ) {
            window.location.href =
              "https://dataserver-prod2.swift-navigator.com/auth/logout";
          } else if (
            environmentLocation === "dataserver-uat2.swift-navigator.com"
          ) {
            window.location.href =
              "https://dataserver-uat2.swift-navigator.com/auth/logout";
          }
        }}
      />

      <div className={`main-panel`}>
        <Header
          setSidebarCollapsed={() => openCloseSidebar()}
          setSidebarLocked={() => openCloseSidebar()}
          isSidebarCollapsed={isSidebarCollapsed}
        >
          {props.header}
        </Header>

        <Snackbar
          place="tc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"warning"}
          message={openErrorModalContent}
          open={openErrorModal}
          closeNotification={() => handleErrorClose()}
          close
        />

        <div style={{ marginTop: "0px" }}>{props.children}</div>
        <Routes user={props.user} />
      </div>

      <div>
        <NotificationGroup
          style={{
            top: 0,
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <Fade direction={"up"}>
            {showReleaseInfo && (
              <Notification
                type={{ style: "warning", icon: true }}
                closable={true}
                onClose={() => setShowReleaseInfo(false)}
              >
                <ModalNewVersion />
              </Notification>
            )}
          </Fade>
        </NotificationGroup>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.MainLayoutReducer.user,
  };
};

const mapActionCreators = { ...actions };

export default compose(connect(mapStateToProps, mapActionCreators))(
  withRouter(MainLayout)
);
