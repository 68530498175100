import React, { Component } from "react";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { Loader } from "../../../components/KendoLoader";
import { AbstractDelete, AbstractEdit } from "../../../networking/apiCalls";
import _ from "lodash";
import { MyCommandCell } from "../../CRM/View/myCommandCell";
import { DropDownCell } from "../../CRM/View/dropDownCell.js";
import { serverApi } from "../../../networking/config";
import {
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
} from "@material-ui/core";
import customSelectStyles from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import { columnsSchema, similaritySchema } from "../View/schema";
import { Checkbox } from "@progress/kendo-react-inputs";
import {
  Dialog,
  DialogActionsBar,
  Window,
} from "@progress/kendo-react-dialogs";
import GridContainer from "../../../components/Grid/GridContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser, faSyncAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import Snackbar from "../../../components/Snackbar/Snackbar";
import ClientToVesselExpanded from "./components/clientToVessel";
import ClientToBillingEntityExpanded from "./components/clientToBillingEntity/ClientToBillingEntityExpanded";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import axios from "axios";
import { filterBy } from "@progress/kendo-data-query";
import { columnsSchemaForGoogle } from "../../CRM/View/schema";
import ViewSettings from "../../common/ViewSettings";
import ExpandedTabs from "../../common/ExpandedTabs";
import Contacts from "../../MasterEntityContacts/Contacts";
import ClientToDomain from "./components/clientToDomain/ClientToDomain";
import ClientToGroup from "./components/clientToGroup/ClientToGroup";
import ClientToUserExpanded from "./components/clientToUser/ClientToUser";
import {
  showCreatorOnTable,
  removeUnwantedKeyValuesFromArr,
  showPaymentTermsTypeOnTable,
} from "../../../lib/GeneralUtils/utils";
// import { Tooltip as KendoTooltip } from "@progress/kendo-react-tooltip";
// import TooltipCell from "../../common/tooltipCell";
import DropdownFilterCell from "../../common/dropdownFilterCell";
// import { NAV } from "navigatorsdk";
// import Button from "../../../components/CustomButtons/Button";
// import AddIcon from "@material-ui/icons/Add";
import { formatDatesOnTable } from "../../../lib/GeneralUtils/utils";

class ClientView extends Component {
  editField = "inEdit";
  CommandCell;

  constructor(props) {
    super(props);

    this.state = {
      approvalHistoryModal: false,
      similarityData: [],
      similarityColumns: (similaritySchema && similaritySchema()) || [],
      deleteVisible: false,
      visibleWindow: false,
      isDropOpened: false,
      columns: (columnsSchema && columnsSchema()) || [],
      initialColumns: (columnsSchema && columnsSchema()) || [],
      columnsForGoogle:
        (columnsSchemaForGoogle && columnsSchemaForGoogle()) || [],
      gridData: {
        data: [],
        total: 0,
      },
      initialGridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 100,
        skip: 0,
      },
      openErrorModal: false,
      errorModalContent: "",
      delay: 400,
      loading: false,
      countries: [
        { id: "0", text: "" },
        { id: "AD", text: "Andorra(AD)" },
        { id: "AE", text: "United Arab Emirates(AE)" },
        { id: "AF", text: "Afghanistan(AF)" },
        { id: "AG", text: "Antigua and Barbuda(AG)" },
        { id: "AI", text: "Anguilla(AI)" },
        { id: "AL", text: "Albania(AL)" },
        { id: "AM", text: "Armenia(AM)" },
        { id: "AO", text: "Angola(AO)" },
        { id: "AQ", text: "Antarctica(AQ)" },
        { id: "AR", text: "Argentina(AR)" },
        { id: "AS", text: "American Samoa(AS)" },
        { id: "AT", text: "Austria(AT)" },
        { id: "AU", text: "Australia(AU)" },
        { id: "AW", text: "Aruba(AW)" },
        { id: "AX", text: "Alland Islands(AX)" },
        { id: "AZ", text: "Azerbaijan(AZ)" },
        { id: "BA", text: "Bosnia and Herzegovina(BA)", phone: "387" },
        { id: "BB", text: "Barbados(BB)", phone: "1-246" },
        { id: "BD", text: "Bangladesh(BD)", phone: "880" },
        { id: "BE", text: "Belgium(BE)", phone: "32" },
        { id: "BF", text: "Burkina Faso(BF)", phone: "226" },
        { id: "BG", text: "Bulgaria(BG)", phone: "359" },
        { id: "BH", text: "Bahrain(BH)", phone: "973" },
        { id: "BI", text: "Burundi(BI)", phone: "257" },
        { id: "BJ", text: "Benin(BJ)", phone: "229" },
        { id: "BL", text: "Saint Barthelemy(BL)", phone: "590" },
        { id: "BM", text: "Bermuda(BM)", phone: "1-441" },
        { id: "BN", text: "Brunei Darussalam(BN)", phone: "673" },
        { id: "BO", text: "Bolivia(BO)", phone: "591" },
        { id: "BR", text: "Brazil(BR)", phone: "55" },
        { id: "BS", text: "Bahamas(BS)", phone: "1-242" },
        { id: "BT", text: "Bhutan(BT)", phone: "975" },
        { id: "BV", text: "Bouvet Island(BV)", phone: "47" },
        { id: "BW", text: "Botswana(BW)", phone: "267" },
        { id: "BY", text: "Belarus(BY)", phone: "375" },
        { id: "BZ", text: "Belize(BZ)", phone: "501" },
        { id: "CA", text: "Canada(CA)", phone: "1", suggested: true },
        { id: "CC", text: "Cocos (Keeling) Islands(CC)", phone: "61" },
        { id: "CD", text: "Congo, Democratic Republic(CD)", phone: "243" },
        { id: "CG", text: "Congo, Republic of the(CG)", phone: "242" },
        { id: "CF", text: "Central African Republic(CF)", phone: "236" },
        { id: "CH", text: "Switzerland(CH)", phone: "41" },
        { id: "CI", text: "Cote d'Ivoire(CI)", phone: "225" },
        { id: "CK", text: "Cook Islands(CK)", phone: "682" },
        { id: "CL", text: "Chile(CL)", phone: "56" },
        { id: "CM", text: "Cameroon(CM)", phone: "237" },
        { id: "CN", text: "China(CN)", phone: "86" },
        { id: "CO", text: "Colombia(CO)", phone: "57" },
        { id: "CR", text: "Costa Rica(CR)", phone: "506" },
        { id: "CU", text: "Cuba(CU)", phone: "53" },
        { id: "CV", text: "Cape Verde(CV)", phone: "238" },
        { id: "CW", text: "Curacao(CW)", phone: "599" },
        { id: "CX", text: "Christmas Island(CX)", phone: "61" },
        { id: "CY", text: "Cyprus(CY)", phone: "357" },
        { id: "CZ", text: "Czech Republic(CZ)", phone: "420" },
        { id: "DE", text: "Germany(DE)", phone: "49", suggested: true },
        { id: "DJ", text: "Djibouti(DJ)", phone: "253" },
        { id: "DK", text: "Denmark(DK)", phone: "45" },
        { id: "DM", text: "Dominica(DM)", phone: "1-767" },
        { id: "DO", text: "Dominican Republic(DO)", phone: "1-809" },
        { id: "DZ", text: "Algeria(DZ)", phone: "213" },
        { id: "EC", text: "Ecuador(EC)", phone: "593" },
        { id: "EE", text: "Estonia(EE)", phone: "372" },
        { id: "EG", text: "Egypt(EG)", phone: "20" },
        { id: "EH", text: "Western Sahara(EH)", phone: "212" },
        { id: "ER", text: "Eritrea(ER)", phone: "291" },
        { id: "ES", text: "Spain(ES)", phone: "34" },
        { id: "ET", text: "Ethiopia(ET)", phone: "251" },
        { id: "FI", text: "Finland(FI)", phone: "358" },
        { id: "FJ", text: "Fiji(FJ)", phone: "679" },
        { id: "FK", text: "Falkland Islands (Malvinas)(FK)", phone: "500" },
        { id: "FM", text: "Micronesia, Federated States of(FM)", phone: "691" },
        { id: "FO", text: "Faroe Islands(FO)", phone: "298" },
        { id: "FR", text: "France(FR)", phone: "33", suggested: true },
        { id: "GA", text: "Gabon(GA)", phone: "241" },
        { id: "GB", text: "United Kingdom(GB)", phone: "44" },
        { id: "GD", text: "Grenada(GD)", phone: "1-473" },
        { id: "GE", text: "Georgia(GE)", phone: "995" },
        { id: "GF", text: "French Guiana(GF)", phone: "594" },
        { id: "GG", text: "Guernsey(GG)", phone: "44" },
        { id: "GH", text: "Ghana(GH)", phone: "233" },
        { id: "GI", text: "Gibraltar(GI)", phone: "350" },
        { id: "GL", text: "Greenland(GL)", phone: "299" },
        { id: "GM", text: "Gambia(GM)", phone: "220" },
        { id: "GN", text: "Guinea(GN)", phone: "224" },
        { id: "GP", text: "Guadeloupe(GP)", phone: "590" },
        { id: "GQ", text: "Equatorial Guinea(GQ)", phone: "240" },
        { id: "GR", text: "Greece(GR)", phone: "30", suggested: true },
        {
          id: "GS",
          text: "South Georgia and the South Sandwich Islands(GS)",
          phone: "500",
        },
        { id: "GT", text: "Guatemala(GT)", phone: "502" },
        { id: "GU", text: "Guam(GU)", phone: "1-671" },
        { id: "GW", text: "Guinea-Bissau(GW)", phone: "245" },
        { id: "GY", text: "Guyana(GY)", phone: "592" },
        { id: "HK", text: "Hong Kong(HK)", phone: "852" },
        {
          id: "HM",
          text: "Heard Island and McDonald Islands(HM)",
          phone: "672",
        },
        { id: "HN", text: "Honduras(HN)", phone: "504" },
        { id: "HR", text: "Croatia(HR)", phone: "385" },
        { id: "HT", text: "Haiti(HT)", phone: "509" },
        { id: "HU", text: "Hungary(HU)", phone: "36" },
        { id: "ID", text: "Indonesia(ID)", phone: "62" },
        { id: "IE", text: "Ireland(IE)", phone: "353" },
        { id: "IL", text: "Israel(IL)", phone: "972" },
        { id: "IM", text: "Isle of Man(IM)", phone: "44" },
        { id: "IN", text: "India(IN)", phone: "91" },
        { id: "IO", text: "British Indian Ocean Territory(IO)", phone: "246" },
        { id: "IQ", text: "Iraq(IQ)", phone: "964" },
        { id: "IR", text: "Iran, Islamic Republic of(IR)", phone: "98" },
        { id: "IS", text: "Iceland(IS)", phone: "354" },
        { id: "IT", text: "Italy(IT)", phone: "39" },
        { id: "JE", text: "Jersey(JE)", phone: "44" },
        { id: "JM", text: "Jamaica(JM)", phone: "1-876" },
        { id: "JO", text: "Jordan(JO)", phone: "962" },
        { id: "JP", text: "Japan(JP)", phone: "81", suggested: true },
        { id: "KE", text: "Kenya(KE)", phone: "254" },
        { id: "KG", text: "Kyrgyzstan(KG)", phone: "996" },
        { id: "KH", text: "Cambodia(KH)", phone: "855" },
        { id: "KI", text: "Kiribati(KI)", phone: "686" },
        { id: "KM", text: "Comoros(KM)", phone: "269" },
        { id: "KN", text: "Saint Kitts and Nevis(KN)", phone: "1-869" },
        {
          id: "KP",
          text: "Korea, Democratic People's Republic of(KP)",
          phone: "850",
        },
        { id: "KR", text: "Korea, Republic of(KR)", phone: "82" },
        { id: "KW", text: "Kuwait(KW)", phone: "965" },
        { id: "KY", text: "Cayman Islands(KY)", phone: "1-345" },
        { id: "KZ", text: "Kazakhstan(KZ)", phone: "7" },
        {
          id: "LA",
          text: "Lao People's Democratic Republic(LA)",
          phone: "856",
        },
        { id: "LB", text: "Lebanon(LB)", phone: "961" },
        { id: "LC", text: "Saint Lucia(LC)", phone: "1-758" },
        { id: "LI", text: "Liechtenstein(LI)", phone: "423" },
        { id: "LK", text: "Sri Lanka(LK)", phone: "94" },
        { id: "LR", text: "Liberia(LR)", phone: "231" },
        { id: "LS", text: "Lesotho(LS)", phone: "266" },
        { id: "LT", text: "Lithuania(LT)", phone: "370" },
        { id: "LU", text: "Luxembourg(LU)", phone: "352" },
        { id: "LV", text: "Latvia(LV)", phone: "371" },
        { id: "LY", text: "Libya(LY)", phone: "218" },
        { id: "MA", text: "Morocco(MA)", phone: "212" },
        { id: "MC", text: "Monaco(MC)", phone: "377" },
        { id: "MD", text: "Moldova, Republic of(MD)", phone: "373" },
        { id: "ME", text: "Montenegro(ME)", phone: "382" },
        { id: "MF", text: "Saint Martin (French part)(MF)", phone: "590" },
        { id: "MG", text: "Madagascar(MG)", phone: "261" },
        { id: "MH", text: "Marshall Islands(MH)", phone: "692" },
        {
          id: "MK",
          text: "Macedonia, the Former Yugoslav Republic of(MK)",
          phone: "389",
        },
        { id: "ML", text: "Mali(ML)", phone: "223" },
        { id: "MM", text: "Myanmar(MM)", phone: "95" },
        { id: "MN", text: "Mongolia(MN)", phone: "976" },
        { id: "MO", text: "Macao(MO)", phone: "853" },
        { id: "MP", text: "Northern Mariana Islands(MP)", phone: "1-670" },
        { id: "MQ", text: "Martinique(MQ)", phone: "596" },
        { id: "MR", text: "Mauritania(MR)", phone: "222" },
        { id: "MS", text: "Montserrat(MS)", phone: "1-664" },
        { id: "MT", text: "Malta(MT)", phone: "356" },
        { id: "MU", text: "Mauritius(MU)", phone: "230" },
        { id: "MV", text: "Maldives(MV)", phone: "960" },
        { id: "MW", text: "Malawi(MW)", phone: "265" },
        { id: "MX", text: "Mexico(MX)", phone: "52" },
        { id: "MY", text: "Malaysia(MY)", phone: "60" },
        { id: "MZ", text: "Mozambique(MZ)", phone: "258" },
        { id: "NA", text: "Namibia(NA)", phone: "264" },
        { id: "NC", text: "New Caledonia(NC)", phone: "687" },
        { id: "NE", text: "Niger(NE)", phone: "227" },
        { id: "NF", text: "Norfolk Island(NF)", phone: "672" },
        { id: "NG", text: "Nigeria(NG)", phone: "234" },
        { id: "NI", text: "Nicaragua(NI)", phone: "505" },
        { id: "NL", text: "Netherlands(NL)", phone: "31" },
        { id: "NO", text: "Norway(NO)", phone: "47" },
        { id: "NP", text: "Nepal(NP)", phone: "977" },
        { id: "NR", text: "Nauru(NR)", phone: "674" },
        { id: "NU", text: "Niue(NU)", phone: "683" },
        { id: "NZ", text: "New Zealand(NZ)", phone: "64" },
        { id: "OM", text: "Oman(OM)", phone: "968" },
        { id: "PA", text: "Panama(PA)", phone: "507" },
        { id: "PE", text: "Peru(PE)", phone: "51" },
        { id: "PF", text: "French Polynesia(PF)", phone: "689" },
        { id: "PG", text: "Papua New Guinea(PG)", phone: "675" },
        { id: "PH", text: "Philippines(PH)", phone: "63" },
        { id: "PK", text: "Pakistan(PK)", phone: "92" },
        { id: "PL", text: "Poland(PL)", phone: "48" },
        { id: "PM", text: "Saint Pierre and Miquelon(PM)", phone: "508" },
        { id: "PN", text: "Pitcairn(PN)", phone: "870" },
        { id: "PR", text: "Puerto Rico(PR)", phone: "1" },
        { id: "PS", text: "Palestine, State of(PS)", phone: "970" },
        { id: "PT", text: "Portugal(PT)", phone: "351" },
        { id: "PW", text: "Palau(PW)", phone: "680" },
        { id: "PY", text: "Paraguay(PY)", phone: "595" },
        { id: "QA", text: "Qatar(QA)", phone: "974" },
        { id: "RE", text: "Reunion(RE)", phone: "262" },
        { id: "RO", text: "Romania(RO)", phone: "40" },
        { id: "RS", text: "Serbia(RS)", phone: "381" },
        { id: "RU", text: "Russian Federation(RU)", phone: "7" },
        { id: "RW", text: "Rwanda(RW)", phone: "250" },
        { id: "SA", text: "Saudi Arabia(SA)", phone: "966" },
        { id: "SB", text: "Solomon Islands(SB)", phone: "677" },
        { id: "SC", text: "Seychelles(SC)", phone: "248" },
        { id: "SD", text: "Sudan(SD)", phone: "249" },
        { id: "SE", text: "Sweden(SE)", phone: "46" },
        { id: "SG", text: "Singapore(SG)", phone: "65" },
        { id: "SH", text: "Saint Helena(SH)", phone: "290" },
        { id: "SI", text: "Slovenia(SI)", phone: "386" },
        { id: "SJ", text: "Svalbard and Jan Mayen(SJ)", phone: "47" },
        { id: "SK", text: "Slovakia(SK)", phone: "421" },
        { id: "SL", text: "Sierra Leone(SL)", phone: "232" },
        { id: "SM", text: "San Marino(SM)", phone: "378" },
        { id: "SN", text: "Senegal(SN)", phone: "221" },
        { id: "SO", text: "Somalia(SO)", phone: "252" },
        { id: "SR", text: "Suriname(SR)", phone: "597" },
        { id: "SS", text: "South Sudan(SS)", phone: "211" },
        { id: "ST", text: "Sao Tome and Principe(ST)", phone: "239" },
        { id: "SV", text: "El Salvador(SV)", phone: "503" },
        { id: "SX", text: "Sint Maarten (Dutch part)(SX)", phone: "1-721" },
        { id: "SY", text: "Syrian Arab Republic(SY)", phone: "963" },
        { id: "SZ", text: "Swaziland(SZ)", phone: "268" },
        { id: "TC", text: "Turks and Caicos Islands(TC)", phone: "1-649" },
        { id: "TD", text: "Chad(TD)", phone: "235" },
        { id: "TF", text: "French Southern Territories(TF)", phone: "262" },
        { id: "TG", text: "Togo(TG)", phone: "228" },
        { id: "TH", text: "Thailand(TH)", phone: "66" },
        { id: "TJ", text: "Tajikistan(TJ)", phone: "992" },
        { id: "TK", text: "Tokelau(TK)", phone: "690" },
        { id: "TL", text: "Timor-Leste(TL)", phone: "670" },
        { id: "TM", text: "Turkmenistan(TM)", phone: "993" },
        { id: "TN", text: "Tunisia(TN)", phone: "216" },
        { id: "TO", text: "Tonga(TO)", phone: "676" },
        { id: "TR", text: "Turkey(TR)", phone: "90" },
        { id: "TT", text: "Trinidad and Tobago(TT)", phone: "1-868" },
        { id: "TV", text: "Tuvalu(TV)", phone: "688" },
        { id: "TW", text: "Taiwan, Province of China(TW)", phone: "886" },
        { id: "TZ", text: "United Republic of Tanzania(TZ)", phone: "255" },
        { id: "UA", text: "Ukraine(UA)", phone: "380" },
        { id: "UG", text: "Uganda(UG)", phone: "256" },
        { id: "US", text: "United States(US)", phone: "1", suggested: true },
        { id: "UY", text: "Uruguay(UY)", phone: "598" },
        { id: "UZ", text: "Uzbekistan(UZ)", phone: "998" },
        { id: "VA", text: "Holy See (Vatican City State)(VA)", phone: "379" },
        {
          id: "VC",
          text: "Saint Vincent and the Grenadines(VC)",
          phone: "1-784",
        },
        { id: "VE", text: "Venezuela(VE)", phone: "58" },
        { id: "VG", text: "British Virgin Islands(VG)", phone: "1-284" },
        { id: "VI", text: "US Virgin Islands(VI)", phone: "1-340" },
        { id: "VN", text: "Vietnam(VN)", phone: "84" },
        { id: "VU", text: "Vanuatu(VU)", phone: "678" },
        { id: "WF", text: "Wallis and Futuna(WF)", phone: "681" },
        { id: "WS", text: "Samoa(WS)", phone: "685" },
        { id: "XK", text: "Kosovo(XK)", phone: "383" },
        { id: "YE", text: "Yemen(YE)", phone: "967" },
        { id: "YT", text: "Mayotte(YT)", phone: "262" },
        { id: "ZA", text: "South Africa(ZA)", phone: "27" },
        { id: "ZM", text: "Zambia(ZM)", phone: "260" },
        { id: "ZW", text: "Zimbabwe(ZW)", phone: "263" },
      ].slice(),
      initialCountries: [
        { id: "0", text: "" },
        { id: "AD", text: "Andorra(AD)" },
        { id: "AE", text: "United Arab Emirates(AE)" },
        { id: "AF", text: "Afghanistan(AF)" },
        { id: "AG", text: "Antigua and Barbuda(AG)" },
        { id: "AI", text: "Anguilla(AI)" },
        { id: "AL", text: "Albania(AL)" },
        { id: "AM", text: "Armenia(AM)" },
        { id: "AO", text: "Angola(AO)" },
        { id: "AQ", text: "Antarctica(AQ)" },
        { id: "AR", text: "Argentina(AR)" },
        { id: "AS", text: "American Samoa(AS)" },
        { id: "AT", text: "Austria(AT)" },
        { id: "AU", text: "Australia(AU)" },
        { id: "AW", text: "Aruba(AW)" },
        { id: "AX", text: "Alland Islands(AX)" },
        { id: "AZ", text: "Azerbaijan(AZ)" },
        { id: "BA", text: "Bosnia and Herzegovina(BA)", phone: "387" },
        { id: "BB", text: "Barbados(BB)", phone: "1-246" },
        { id: "BD", text: "Bangladesh(BD)", phone: "880" },
        { id: "BE", text: "Belgium(BE)", phone: "32" },
        { id: "BF", text: "Burkina Faso(BF)", phone: "226" },
        { id: "BG", text: "Bulgaria(BG)", phone: "359" },
        { id: "BH", text: "Bahrain(BH)", phone: "973" },
        { id: "BI", text: "Burundi(BI)", phone: "257" },
        { id: "BJ", text: "Benin(BJ)", phone: "229" },
        { id: "BL", text: "Saint Barthelemy(BL)", phone: "590" },
        { id: "BM", text: "Bermuda(BM)", phone: "1-441" },
        { id: "BN", text: "Brunei Darussalam(BN)", phone: "673" },
        { id: "BO", text: "Bolivia(BO)", phone: "591" },
        { id: "BR", text: "Brazil(BR)", phone: "55" },
        { id: "BS", text: "Bahamas(BS)", phone: "1-242" },
        { id: "BT", text: "Bhutan(BT)", phone: "975" },
        { id: "BV", text: "Bouvet Island(BV)", phone: "47" },
        { id: "BW", text: "Botswana(BW)", phone: "267" },
        { id: "BY", text: "Belarus(BY)", phone: "375" },
        { id: "BZ", text: "Belize(BZ)", phone: "501" },
        { id: "CA", text: "Canada(CA)", phone: "1", suggested: true },
        { id: "CC", text: "Cocos (Keeling) Islands(CC)", phone: "61" },
        { id: "CD", text: "Congo, Democratic Republic(CD)", phone: "243" },
        { id: "CG", text: "Congo, Republic of the(CG)", phone: "242" },
        { id: "CF", text: "Central African Republic(CF)", phone: "236" },
        { id: "CH", text: "Switzerland(CH)", phone: "41" },
        { id: "CI", text: "Cote d'Ivoire(CI)", phone: "225" },
        { id: "CK", text: "Cook Islands(CK)", phone: "682" },
        { id: "CL", text: "Chile(CL)", phone: "56" },
        { id: "CM", text: "Cameroon(CM)", phone: "237" },
        { id: "CN", text: "China(CN)", phone: "86" },
        { id: "CO", text: "Colombia(CO)", phone: "57" },
        { id: "CR", text: "Costa Rica(CR)", phone: "506" },
        { id: "CU", text: "Cuba(CU)", phone: "53" },
        { id: "CV", text: "Cape Verde(CV)", phone: "238" },
        { id: "CW", text: "Curacao(CW)", phone: "599" },
        { id: "CX", text: "Christmas Island(CX)", phone: "61" },
        { id: "CY", text: "Cyprus(CY)", phone: "357" },
        { id: "CZ", text: "Czech Republic(CZ)", phone: "420" },
        { id: "DE", text: "Germany(DE)", phone: "49", suggested: true },
        { id: "DJ", text: "Djibouti(DJ)", phone: "253" },
        { id: "DK", text: "Denmark(DK)", phone: "45" },
        { id: "DM", text: "Dominica(DM)", phone: "1-767" },
        { id: "DO", text: "Dominican Republic(DO)", phone: "1-809" },
        { id: "DZ", text: "Algeria(DZ)", phone: "213" },
        { id: "EC", text: "Ecuador(EC)", phone: "593" },
        { id: "EE", text: "Estonia(EE)", phone: "372" },
        { id: "EG", text: "Egypt(EG)", phone: "20" },
        { id: "EH", text: "Western Sahara(EH)", phone: "212" },
        { id: "ER", text: "Eritrea(ER)", phone: "291" },
        { id: "ES", text: "Spain(ES)", phone: "34" },
        { id: "ET", text: "Ethiopia(ET)", phone: "251" },
        { id: "FI", text: "Finland(FI)", phone: "358" },
        { id: "FJ", text: "Fiji(FJ)", phone: "679" },
        { id: "FK", text: "Falkland Islands (Malvinas)(FK)", phone: "500" },
        { id: "FM", text: "Micronesia, Federated States of(FM)", phone: "691" },
        { id: "FO", text: "Faroe Islands(FO)", phone: "298" },
        { id: "FR", text: "France(FR)", phone: "33", suggested: true },
        { id: "GA", text: "Gabon(GA)", phone: "241" },
        { id: "GB", text: "United Kingdom(GB)", phone: "44" },
        { id: "GD", text: "Grenada(GD)", phone: "1-473" },
        { id: "GE", text: "Georgia(GE)", phone: "995" },
        { id: "GF", text: "French Guiana(GF)", phone: "594" },
        { id: "GG", text: "Guernsey(GG)", phone: "44" },
        { id: "GH", text: "Ghana(GH)", phone: "233" },
        { id: "GI", text: "Gibraltar(GI)", phone: "350" },
        { id: "GL", text: "Greenland(GL)", phone: "299" },
        { id: "GM", text: "Gambia(GM)", phone: "220" },
        { id: "GN", text: "Guinea(GN)", phone: "224" },
        { id: "GP", text: "Guadeloupe(GP)", phone: "590" },
        { id: "GQ", text: "Equatorial Guinea(GQ)", phone: "240" },
        { id: "GR", text: "Greece(GR)", phone: "30", suggested: true },
        {
          id: "GS",
          text: "South Georgia and the South Sandwich Islands(GS)",
          phone: "500",
        },
        { id: "GT", text: "Guatemala(GT)", phone: "502" },
        { id: "GU", text: "Guam(GU)", phone: "1-671" },
        { id: "GW", text: "Guinea-Bissau(GW)", phone: "245" },
        { id: "GY", text: "Guyana(GY)", phone: "592" },
        { id: "HK", text: "Hong Kong(HK)", phone: "852" },
        {
          id: "HM",
          text: "Heard Island and McDonald Islands(HM)",
          phone: "672",
        },
        { id: "HN", text: "Honduras(HN)", phone: "504" },
        { id: "HR", text: "Croatia(HR)", phone: "385" },
        { id: "HT", text: "Haiti(HT)", phone: "509" },
        { id: "HU", text: "Hungary(HU)", phone: "36" },
        { id: "ID", text: "Indonesia(ID)", phone: "62" },
        { id: "IE", text: "Ireland(IE)", phone: "353" },
        { id: "IL", text: "Israel(IL)", phone: "972" },
        { id: "IM", text: "Isle of Man(IM)", phone: "44" },
        { id: "IN", text: "India(IN)", phone: "91" },
        { id: "IO", text: "British Indian Ocean Territory(IO)", phone: "246" },
        { id: "IQ", text: "Iraq(IQ)", phone: "964" },
        { id: "IR", text: "Iran, Islamic Republic of(IR)", phone: "98" },
        { id: "IS", text: "Iceland(IS)", phone: "354" },
        { id: "IT", text: "Italy(IT)", phone: "39" },
        { id: "JE", text: "Jersey(JE)", phone: "44" },
        { id: "JM", text: "Jamaica(JM)", phone: "1-876" },
        { id: "JO", text: "Jordan(JO)", phone: "962" },
        { id: "JP", text: "Japan(JP)", phone: "81", suggested: true },
        { id: "KE", text: "Kenya(KE)", phone: "254" },
        { id: "KG", text: "Kyrgyzstan(KG)", phone: "996" },
        { id: "KH", text: "Cambodia(KH)", phone: "855" },
        { id: "KI", text: "Kiribati(KI)", phone: "686" },
        { id: "KM", text: "Comoros(KM)", phone: "269" },
        { id: "KN", text: "Saint Kitts and Nevis(KN)", phone: "1-869" },
        {
          id: "KP",
          text: "Korea, Democratic People's Republic of(KP)",
          phone: "850",
        },
        { id: "KR", text: "Korea, Republic of(KR)", phone: "82" },
        { id: "KW", text: "Kuwait(KW)", phone: "965" },
        { id: "KY", text: "Cayman Islands(KY)", phone: "1-345" },
        { id: "KZ", text: "Kazakhstan(KZ)", phone: "7" },
        {
          id: "LA",
          text: "Lao People's Democratic Republic(LA)",
          phone: "856",
        },
        { id: "LB", text: "Lebanon(LB)", phone: "961" },
        { id: "LC", text: "Saint Lucia(LC)", phone: "1-758" },
        { id: "LI", text: "Liechtenstein(LI)", phone: "423" },
        { id: "LK", text: "Sri Lanka(LK)", phone: "94" },
        { id: "LR", text: "Liberia(LR)", phone: "231" },
        { id: "LS", text: "Lesotho(LS)", phone: "266" },
        { id: "LT", text: "Lithuania(LT)", phone: "370" },
        { id: "LU", text: "Luxembourg(LU)", phone: "352" },
        { id: "LV", text: "Latvia(LV)", phone: "371" },
        { id: "LY", text: "Libya(LY)", phone: "218" },
        { id: "MA", text: "Morocco(MA)", phone: "212" },
        { id: "MC", text: "Monaco(MC)", phone: "377" },
        { id: "MD", text: "Moldova, Republic of(MD)", phone: "373" },
        { id: "ME", text: "Montenegro(ME)", phone: "382" },
        { id: "MF", text: "Saint Martin (French part)(MF)", phone: "590" },
        { id: "MG", text: "Madagascar(MG)", phone: "261" },
        { id: "MH", text: "Marshall Islands(MH)", phone: "692" },
        {
          id: "MK",
          text: "Macedonia, the Former Yugoslav Republic of(MK)",
          phone: "389",
        },
        { id: "ML", text: "Mali(ML)", phone: "223" },
        { id: "MM", text: "Myanmar(MM)", phone: "95" },
        { id: "MN", text: "Mongolia(MN)", phone: "976" },
        { id: "MO", text: "Macao(MO)", phone: "853" },
        { id: "MP", text: "Northern Mariana Islands(MP)", phone: "1-670" },
        { id: "MQ", text: "Martinique(MQ)", phone: "596" },
        { id: "MR", text: "Mauritania(MR)", phone: "222" },
        { id: "MS", text: "Montserrat(MS)", phone: "1-664" },
        { id: "MT", text: "Malta(MT)", phone: "356" },
        { id: "MU", text: "Mauritius(MU)", phone: "230" },
        { id: "MV", text: "Maldives(MV)", phone: "960" },
        { id: "MW", text: "Malawi(MW)", phone: "265" },
        { id: "MX", text: "Mexico(MX)", phone: "52" },
        { id: "MY", text: "Malaysia(MY)", phone: "60" },
        { id: "MZ", text: "Mozambique(MZ)", phone: "258" },
        { id: "NA", text: "Namibia(NA)", phone: "264" },
        { id: "NC", text: "New Caledonia(NC)", phone: "687" },
        { id: "NE", text: "Niger(NE)", phone: "227" },
        { id: "NF", text: "Norfolk Island(NF)", phone: "672" },
        { id: "NG", text: "Nigeria(NG)", phone: "234" },
        { id: "NI", text: "Nicaragua(NI)", phone: "505" },
        { id: "NL", text: "Netherlands(NL)", phone: "31" },
        { id: "NO", text: "Norway(NO)", phone: "47" },
        { id: "NP", text: "Nepal(NP)", phone: "977" },
        { id: "NR", text: "Nauru(NR)", phone: "674" },
        { id: "NU", text: "Niue(NU)", phone: "683" },
        { id: "NZ", text: "New Zealand(NZ)", phone: "64" },
        { id: "OM", text: "Oman(OM)", phone: "968" },
        { id: "PA", text: "Panama(PA)", phone: "507" },
        { id: "PE", text: "Peru(PE)", phone: "51" },
        { id: "PF", text: "French Polynesia(PF)", phone: "689" },
        { id: "PG", text: "Papua New Guinea(PG)", phone: "675" },
        { id: "PH", text: "Philippines(PH)", phone: "63" },
        { id: "PK", text: "Pakistan(PK)", phone: "92" },
        { id: "PL", text: "Poland(PL)", phone: "48" },
        { id: "PM", text: "Saint Pierre and Miquelon(PM)", phone: "508" },
        { id: "PN", text: "Pitcairn(PN)", phone: "870" },
        { id: "PR", text: "Puerto Rico(PR)", phone: "1" },
        { id: "PS", text: "Palestine, State of(PS)", phone: "970" },
        { id: "PT", text: "Portugal(PT)", phone: "351" },
        { id: "PW", text: "Palau(PW)", phone: "680" },
        { id: "PY", text: "Paraguay(PY)", phone: "595" },
        { id: "QA", text: "Qatar(QA)", phone: "974" },
        { id: "RE", text: "Reunion(RE)", phone: "262" },
        { id: "RO", text: "Romania(RO)", phone: "40" },
        { id: "RS", text: "Serbia(RS)", phone: "381" },
        { id: "RU", text: "Russian Federation(RU)", phone: "7" },
        { id: "RW", text: "Rwanda(RW)", phone: "250" },
        { id: "SA", text: "Saudi Arabia(SA)", phone: "966" },
        { id: "SB", text: "Solomon Islands(SB)", phone: "677" },
        { id: "SC", text: "Seychelles(SC)", phone: "248" },
        { id: "SD", text: "Sudan(SD)", phone: "249" },
        { id: "SE", text: "Sweden(SE)", phone: "46" },
        { id: "SG", text: "Singapore(SG)", phone: "65" },
        { id: "SH", text: "Saint Helena(SH)", phone: "290" },
        { id: "SI", text: "Slovenia(SI)", phone: "386" },
        { id: "SJ", text: "Svalbard and Jan Mayen(SJ)", phone: "47" },
        { id: "SK", text: "Slovakia(SK)", phone: "421" },
        { id: "SL", text: "Sierra Leone(SL)", phone: "232" },
        { id: "SM", text: "San Marino(SM)", phone: "378" },
        { id: "SN", text: "Senegal(SN)", phone: "221" },
        { id: "SO", text: "Somalia(SO)", phone: "252" },
        { id: "SR", text: "Suriname(SR)", phone: "597" },
        { id: "SS", text: "South Sudan(SS)", phone: "211" },
        { id: "ST", text: "Sao Tome and Principe(ST)", phone: "239" },
        { id: "SV", text: "El Salvador(SV)", phone: "503" },
        { id: "SX", text: "Sint Maarten (Dutch part)(SX)", phone: "1-721" },
        { id: "SY", text: "Syrian Arab Republic(SY)", phone: "963" },
        { id: "SZ", text: "Swaziland(SZ)", phone: "268" },
        { id: "TC", text: "Turks and Caicos Islands(TC)", phone: "1-649" },
        { id: "TD", text: "Chad(TD)", phone: "235" },
        { id: "TF", text: "French Southern Territories(TF)", phone: "262" },
        { id: "TG", text: "Togo(TG)", phone: "228" },
        { id: "TH", text: "Thailand(TH)", phone: "66" },
        { id: "TJ", text: "Tajikistan(TJ)", phone: "992" },
        { id: "TK", text: "Tokelau(TK)", phone: "690" },
        { id: "TL", text: "Timor-Leste(TL)", phone: "670" },
        { id: "TM", text: "Turkmenistan(TM)", phone: "993" },
        { id: "TN", text: "Tunisia(TN)", phone: "216" },
        { id: "TO", text: "Tonga(TO)", phone: "676" },
        { id: "TR", text: "Turkey(TR)", phone: "90" },
        { id: "TT", text: "Trinidad and Tobago(TT)", phone: "1-868" },
        { id: "TV", text: "Tuvalu(TV)", phone: "688" },
        { id: "TW", text: "Taiwan, Province of China(TW)", phone: "886" },
        { id: "TZ", text: "United Republic of Tanzania(TZ)", phone: "255" },
        { id: "UA", text: "Ukraine(UA)", phone: "380" },
        { id: "UG", text: "Uganda(UG)", phone: "256" },
        { id: "US", text: "United States(US)", phone: "1", suggested: true },
        { id: "UY", text: "Uruguay(UY)", phone: "598" },
        { id: "UZ", text: "Uzbekistan(UZ)", phone: "998" },
        { id: "VA", text: "Holy See (Vatican City State)(VA)", phone: "379" },
        {
          id: "VC",
          text: "Saint Vincent and the Grenadines(VC)",
          phone: "1-784",
        },
        { id: "VE", text: "Venezuela(VE)", phone: "58" },
        { id: "VG", text: "British Virgin Islands(VG)", phone: "1-284" },
        { id: "VI", text: "US Virgin Islands(VI)", phone: "1-340" },
        { id: "VN", text: "Vietnam(VN)", phone: "84" },
        { id: "VU", text: "Vanuatu(VU)", phone: "678" },
        { id: "WF", text: "Wallis and Futuna(WF)", phone: "681" },
        { id: "WS", text: "Samoa(WS)", phone: "685" },
        { id: "XK", text: "Kosovo(XK)", phone: "383" },
        { id: "YE", text: "Yemen(YE)", phone: "967" },
        { id: "YT", text: "Mayotte(YT)", phone: "262" },
        { id: "ZA", text: "South Africa(ZA)", phone: "27" },
        { id: "ZM", text: "Zambia(ZM)", phone: "260" },
        { id: "ZW", text: "Zimbabwe(ZW)", phone: "263" },
      ].slice(),
      selectedRowIndex: 0,
      selectedItem: null,
      googleOptions: [],
      expandedRowIndex: null,
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.disableClient,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      hasDisable: true,
      showSecondAction: true,
    });
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    if (this.props.match.params.id !== "" && id) {
      let tempFilters = {
        filter: {
          logic: "and",
          filters: [{ field: "id", operator: "contains", value: `${id}` }],
        },
        skip: 0,
        take: 100,
      };
      window.localStorage.setItem("finalParams", JSON.stringify(tempFilters));
    }

    let isFirstLoad = true;
    window.localStorage.setItem("isFirstLoad", isFirstLoad);

    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));

    if (hasFinalParams) {
      this.setState({
        ...this.state,
        dataState: hasFinalParams,
      });
    }

    if (hasChangedColumns) {
      this.setState({
        ...this.state,
        columns: hasChangedColumns,
      });
    }
  }

  componentWillUnmount() {
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));
    let finalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    let hasExpandedId = JSON.parse(window.localStorage.getItem("expandeId"));
    let hasExportFilter = window.localStorage.getItem("exportFilter");

    if (hasChangedColumns) {
      window.localStorage.removeItem("cols");
    }
    if (finalParams) {
      window.localStorage.removeItem("finalParams");
    }
    if (hasExpandedId) {
      window.localStorage.removeItem("expandeId");
    }
    if (hasExportFilter) {
      window.localStorage.removeItem("exportFilter");
    }
  }

  dataStateChange = (e) => {
    const reqGridParams = e.data;
    window.localStorage.setItem("finalParams", JSON.stringify(reqGridParams));

    this.setState({
      ...this.state,
      dataState: reqGridParams,
    });
  };

  dataReceived = (gridData, reqGridParams) => {
    let finalData = showCreatorOnTable(gridData);
    finalData = showPaymentTermsTypeOnTable(finalData);

    if (finalData?.data?.length > 0) {
      if (this.props.match.params.id !== "" && this.props.match.params.id) {
        let temp =
          finalData &&
          finalData.data &&
          finalData.data.filter(
            (x) =>
              x.approval_status === "Pending" ||
              x.approval_status === "Rejected"
          );
        if (temp.length > 0) {
          this.setState(
            {
              ...this.state,
            },
            () => {
              this.onToggle("warning");
            }
          );
        }
      }
    }

    this.setState({
      ...this.state,
      gridData: finalData,
      initialGridData: finalData,
      dataState:
        reqGridParams === !_.isEmpty(reqGridParams)
          ? reqGridParams
          : this.state.dataState, //TODO MAYBE PASS THE final params from local storage for persist the filters on REFRESH PAGE !
    });
  };

  onToggle = (flag) => this.setState({ [flag]: !this.state[flag] });

  renderGridColumns = () => {
    let selectionCol = [];
    let normalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.field === "approval_status") {
          return (
            <Column
              field={temp.field}
              locked={temp.locked}
              filterable={temp.filterable}
              title={temp.title}
              width={210}
              filter={temp.filter}
              visible={temp.visible}
              // minGridWidth={"400"}
              filterCell={(props) => {
                return (
                  <>
                    <DropdownFilterCell
                      {...props}
                      filterable={true}
                      data={[
                        "Approved",
                        "Pending",
                        "Not approved",
                        "No response",
                      ].sort((a, b) => (a > b ? 1 : -1))}
                    />
                  </>
                );
              }}
              editable={
                temp.field === "id" ||
                temp.field === "createdByForUi" ||
                temp.field === "dateCreatedForUi" ||
                temp.field === "ap_account" ||
                temp.field === "ar_account"
                  ? false
                  : true
              } //id not editable on edit
              cell={(props) => {
                const color =
                  props.dataItem.approval_status === "Approved"
                    ? "#16b3169c"
                    : props.dataItem.approval_status === "Not approved"
                    ? "#f317008a"
                    : null;
                const style = {
                  color: color,
                  width: "150px",
                };
                return (
                  <>
                    <DropDownCell
                      {...props}
                      style={style}
                      filterable={true}
                      data={[
                        { text: "Pending", value: "Pending" },
                        { text: "Not approved", value: "Not approved" },
                        { text: "Approved", value: "Approved" },
                      ].sort((a, b) => (a > b ? 1 : -1))}
                    />
                  </>
                );
              }}
              // cell={cellWithBackGround}
            />
          );
        } else if (temp.field === "paymentTermsTypeId") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              filterCell={(props) => {
                return (
                  <>
                    <DropdownFilterCell
                      {...props}
                      filterable={false}
                      width="120px"
                      data={this.props.paymentTermsLovTypes
                        .map((x) => x.text)
                        .sort((a, b) => (a > b ? 1 : -1))}
                    />
                  </>
                );
              }}
              editable={
                temp.field === "id" ||
                temp.field === "createdByForUi" ||
                temp.field === "dateCreatedForUi" ||
                temp.field === "ap_account" ||
                temp.field === "ar_account"
                  ? false
                  : true
              } //id not editable on edit
              cell={(props) => {
                let data = (this.props.paymentTermsLovTypes || [])
                  .map((x) => ({
                    text: x.text,
                    value: x.text,
                  }))
                  .sort((a, b) => (a.text > b.text ? 1 : -1));
                return (
                  <>
                    <DropDownCell
                      {...props}
                      filterable={false}
                      width="150px"
                      data={data}
                    />
                  </>
                );
              }}
            />
          );
        } else if (temp.field === "relation_country") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              filterCell={(props) => {
                return (
                  <>
                    <DropdownFilterCell
                      {...props}
                      filterable={true}
                      width="210px"
                      data={(this.props.countryCodes || [])
                        .map((x) => x.alpha_2 + "," + x.name)
                        .sort((a, b) => (a > b ? 1 : -1))}
                      hasMultipleValues={true}
                    />
                  </>
                );
              }}
              cell={(props) => {
                let data = (this.props.countryCodes || [])
                  .map((x) => ({
                    text: x.alpha_2 + "," + x.name,
                    value: x.alpha_2,
                  }))
                  .sort((a, b) => (a.text > b.text ? 1 : -1));
                return (
                  <>
                    <DropDownCell
                      {...props}
                      filterable={true}
                      width="210px"
                      data={data}
                    />
                  </>
                );
              }}
            />
          );
        } else if (temp.field === "state") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              filterCell={(props) => {
                return (
                  <>
                    <DropdownFilterCell
                      {...props}
                      filterable={true}
                      width="120px"
                      data={(this.props.usaStates || [])
                        .map((x) => x.lov_value_1)
                        .filter((x) => x)
                        .sort((a, b) => (a > b ? 1 : -1))}
                    />
                  </>
                );
              }}
              cell={(props) => {
                let stateData = (this.props.usaStates || [])
                  .map((x) => ({
                    text: x.lov_value_1,
                    value: x.lov_value_1,
                  }))
                  .filter((x) => x)
                  .sort((a, b) => (a.text > b.text ? 1 : -1))
                  .filter((obj) => obj.text !== null && obj.value !== null);
                return (
                  <>
                    <DropDownCell
                      {...props}
                      filterable={true}
                      width="100px"
                      data={stateData}
                    />
                  </>
                );
              }}
            />
          );
        } else if (temp.isDropdown === "yes") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={
                temp.field === "id" ||
                temp.field === "createdByForUi" ||
                temp.field === "dateCreatedForUi" ||
                temp.field === "ap_account" ||
                temp.field === "ar_account"
                  ? false
                  : true
              } //id not editable on edit
            />
          );
        } else {
          return (
            <Column
              field={temp.field}
              locked={temp.locked}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={
                temp.field === "id" ||
                temp.field === "createdByForUi" ||
                temp.field === "dateCreatedForUi" ||
                temp.field === "ap_account" ||
                temp.field === "ar_account"
                  ? false
                  : true
              } //id not editable on edit
            />
          );
        }
      });

    return [...selectionCol, ...normalCols];
  };

  handleColumnsChange = (event) => {
    let columns = this.state.columns;

    let oldColumns = columns.filter((c) => c.visible).map((c) => c.field);
    let newColumns = event.target.value;

    let unique1 = oldColumns.filter((c) => newColumns.indexOf(c) === -1);
    let unique2 = newColumns.filter((c) => oldColumns.indexOf(c) === -1);

    const columnLastUpdated = unique1.concat(unique2)[0];

    let index = columns.findIndex(
      (column) => column.field === columnLastUpdated
    );
    columns[index].visible = !columns[index].visible;

    this.setState(
      {
        ...this.state,
        columns: columns,
      },
      () => {
        let columns = this.state.columns;
        window.localStorage.setItem("cols", JSON.stringify(columns));
      }
    );
  };

  refreshGridDataAfterCrud = async () => {
    let token = window.localStorage.getItem("access_token");
    let options = {
      token: token,
    };
    let refreshParams = window.localStorage.getItem("refreshParams");
    refreshParams = JSON.parse(refreshParams);

    const responseData = await serverApi(
      "GET",
      "masterEntities",
      refreshParams,
      "",
      options
    );

    let finalData = showCreatorOnTable(responseData);
    finalData = showPaymentTermsTypeOnTable(finalData);

    let count;
    const countMasterEntity = await serverApi(
      "GET",
      `masterEntities/count?where=${JSON.stringify(
        refreshParams?.filter?.where
      )}`,
      "",
      "",
      options
    );
    if (countMasterEntity) {
      count = countMasterEntity.data.count;
    }

    let sendObj = {
      gridData: {
        data: finalData.data,
        total: count,
      },
    };

    this.dataReceived(sendObj.gridData, null);
  };

  toggleDeleteDialog(dataItem = {}, state) {
    this.setState({
      ...state,
      deleteVisible: !this.state.deleteVisible,
      itemForDelete: dataItem,
    });
  }

  enterEdit = (dataItem) => {
    this.applyNewColumnsOnGrid(dataItem);

    let total = this.state.gridData.total;

    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    let columns = this.state.initialColumns;
    if (dataItem && dataItem.relation_country !== "US") {
      columns = columns.filter((col) => col.field !== "state");
    }

    this.setState({
      ...this.state,
      columns: columns,
      gridData: {
        data:
          this.state.gridData &&
          this.state.gridData.data.map((item) =>
            item.id === dataItem.id ? { ...item, inEdit: true } : item
          ),
        total: total,
      },
      initialGridData: {
        data:
          this.state.initialGridData &&
          this.state.initialGridData.data.map((item) =>
            item.id === dataItem.id ? { ...item, inEdit: true } : item
          ),
        total: total,
      },
    });
  };

  remove = async (dataItem) => {
    const data = [...this.state.gridData.data];

    let index = data.findIndex(
      (p) => p === dataItem || (dataItem.id && p.id === dataItem.id)
    );
    if (index >= 0) {
      data.splice(index, 1);
    }

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let url = "masterEntities";

    try {
      let deleteItemRequest = await AbstractDelete(url, dataItem.id, options);

      if (deleteItemRequest.status === 200) {
        //clear the column filters if grid data is 0 or let them if grid data is >= 1
        if (data.length <= 0) {
          this.setState({
            ...this.state,
            gridData: {
              data: data,
              total: data.length,
            },
            dataState: {
              skip: 0,
              take: 100,
            },
          });
        } else {
          this.setState({
            ...this.state,
            gridData: {
              data: data,
              total: data.length,
            },
          });
        }
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent:
          (e.response.data && e.response.data.error.message) ||
          "Please try again",
      });
    }
  };

  disableClient = async (dataItem) => {
    this.toggleDeleteDialog(dataItem, this.state);
  };

  add = async (dataItem) => {
    const submitURL = "masterEntities";
    const options = { token: window.localStorage.getItem("access_token") };

    let payload = dataItem;
    payload.supplier_flag = dataItem.supplier_flag || false;
    payload.transport_flag = dataItem.transport_flag || false;
    payload.agent_flag = dataItem.agent_flag || false;
    payload.location_flag = dataItem.location_flag || false;
    payload.sm_agent_flag = dataItem.sm_agent_flag || false;
    payload.sm_vendor_flag = dataItem.sm_vendor_flag || false;
    payload.airport_flag = dataItem.airport_flag || false;
    payload.wh_flag = dataItem.wh_flag || false;
    payload.seaport_flag = dataItem.seaport_flag || false;
    // payload.approval_status = "Pending";
    payload.clientgroup_id = 0;

    if (dataItem.paymentTermsTypeId) {
      payload.paymentTermsTypeId = (this.props.paymentTermsLovTypes || []).find(
        (x) => x.lov_value === dataItem.paymentTermsTypeId
      )?.id;
    }

    let hasErrors = [];

    const error1 =
      payload.relation_name === undefined ||
      payload.relation_name === "" ||
      payload.relation_country === undefined ||
      payload.relation_country === "" ||
      payload.relation_postal_code === undefined ||
      payload.relation_postal_code === "" ||
      payload.relation_city === undefined ||
      payload.relation_city === "" ||
      payload.relation_mail === undefined ||
      payload.relation_mail === "" ||
      payload.relation_mail === undefined ||
      payload.relation_mail === "";

    const error2 = payload.relation_country === "US" && !payload.state;
    const error3 = payload.relation_country !== "US" && !payload.vat_number;
    const error4 =
      payload.relation_country === "US" &&
      !payload.ein_number &&
      !!payload.ss_number;
    const error7 = !payload.relation_tel;

    const isEmailValid = (email) => {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    };
    const error8 =
      !payload.relation_mail || !isEmailValid(payload.relation_mail);

    let error6 = null;
    if (payload.duns) {
      let pattern = new RegExp("^\\d{9}$");
      if (!payload.duns.match(pattern)) {
        error6 = true;
        hasErrors.push(`Duns should be 9 digits`);
      }
    }

    if (error1 || error3)
      hasErrors.push(
        `Required fields in order to create a new client: Name, Postal, Country, City, VAT , Email and Telephone`
      );
    if (error2)
      hasErrors.push(
        "You need to provide the state field in order to create a client"
      );
    if (error4) hasErrors.push("EIN Number is required");
    if (error6) hasErrors.push(`Duns required and should be 9 digits`);
    if (error7) hasErrors.push("Telephone Number is required");
    if (error8) hasErrors.push("A Valid Email is required");

    if (error1 || error2 || error3 || error4 || error6 || error7 || error8) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent: hasErrors[0],
        },
        () => {
          setTimeout(() => {
            this.cancel(dataItem);
            this.discard(dataItem, true);
            this.handleErrorClose();
            return null;
          }, 4000);
        }
      );
    } else {
      const hasSimilarity = await this.checkForSimilar(payload);
      if (!hasSimilarity) {
        dataItem.inEdit = undefined;
        delete payload.inEdit;
        delete payload.inAdd;
        const createRequest = serverApi(
          "POST",
          `${submitURL}`,
          "",
          payload,
          options
        );
        createRequest
          .then(async (createResponse) => {
            this.setState(
              {
                ...this.state,
                inAdd: false,
                deleteVisible: false,
                successMessage: "The client was successfully created",
                openSuccessMessage: true,
              },
              () => {
                setTimeout(() => {
                  this.handleSuccessSnackbar();
                  this.refreshGridDataAfterCrud();
                  this.applyInitialColumnsOnGrid();
                }, 4000);
              }
            );
          })
          .catch((createError) => {
            this.setState(
              {
                ...this.state,
                openErrorModal: true,
                errorModalContent:
                  (createError.response.data &&
                    createError.response.data.error.message) ||
                  "Please try again",
              },
              () => this.discard(dataItem, true)
            );
          });
      } else {
        this.toggleSimilarityWindow();
      }
    }
  };

  checkForSimilar = async (payload) => {
    try {
      const findCountryRecord = this.props.countryCodes.filter(
        (x) => x.alpha_2 === payload.relation_country
      );
      if (findCountryRecord.length) {
        const request = await serverApi(
          "GET",
          `masterEntities/findSimilarNames?fieldname=relation_name&name=${payload.relation_name}&country=${findCountryRecord[0].name}&flags=client_flag`,
          "",
          "",
          {
            token: window.localStorage.getItem("access_token"),
          }
        );
        if (request.status === 200) {
          if (request.data.result.length) {
            //todo handle > 80% similatiry
            let data = request.data.result || [];
            let finalData = [];
            data.forEach((x) => {
              let similarity =
                x.SIMILARITY && parseInt(x.SIMILARITY.split("%")[0]);
              if (similarity > 80) finalData.push(x);
            });

            if (finalData.length > 0) {
              this.setState({
                ...this.state,
                similarityData: finalData,
              });
              return true;
            } else {
              return false;
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            "An error occured, please try again and if the contact persist contact the administration",
        },
        () => this.discard({}, true)
      );
    }
  };

  discard = (dataItem, hasError = false) => {
    let data = [...this.state.gridData.data];
    const total = this.state.gridData.total;

    if (hasError) {
      data[0].inEdit = true;

      this.setState(
        {
          ...this.state,
          gridData: {
            data: data,
            total: total,
          },
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 4000);
        }
      );
    } else {
      this.removeItem(data, dataItem);

      this.setState(
        {
          ...this.state,
          gridData: {
            data: data,
            total: total,
          },
          visible: false,
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 3000);
        }
      );
    }
  };

  update = async (dataItem) => {
    const data = [...this.state.gridData.data];
    const updatedItem = { ...dataItem, inEdit: undefined };
    this.updateItem(data, updatedItem);

    const editPayload = dataItem;
    delete editPayload.expanded;
    delete editPayload.inEdit;
    delete editPayload.inAdd;
    delete editPayload.clientGroup;
    delete editPayload.ar_account;
    delete editPayload.ap_account;

    if (editPayload.expanded) delete editPayload.expanded;

    if (editPayload.relation_country !== "US") editPayload.state = null;

    editPayload.supplier_flag = dataItem.supplier_flag;
    editPayload.verified_flag = dataItem.verified_flag;
    editPayload.transport_flag = dataItem.transport_flag;
    editPayload.agent_flag = dataItem.agent_flag;
    editPayload.location_flag = dataItem.location_flag;
    editPayload.sm_agent_flag = dataItem.sm_agent_flag;
    editPayload.sm_vendor_flag = dataItem.sm_vendor_flag;
    editPayload.airport_flag = dataItem.airport_flag;
    editPayload.wh_flag = dataItem.wh_flag;
    editPayload.seaport_flag = dataItem.seaport_flag;
    editPayload.paymentTerms =
      dataItem.paymentTerms === "" ? null : dataItem.paymentTerms;

    const error1 = editPayload.relation_country === "US" && !editPayload.state;
    const error2 =
      editPayload.relation_name === undefined ||
      editPayload.relation_name === "" ||
      editPayload.relation_country === undefined ||
      editPayload.relation_country === "" ||
      editPayload.relation_postal_code === undefined ||
      editPayload.relation_postal_code === "" ||
      editPayload.relation_city === undefined ||
      editPayload.relation_city === "";

    if (dataItem.paymentTermsTypeId) {
      editPayload.paymentTermsTypeId = (
        this.props.paymentTermsLovTypes || []
      ).find((x) => x.lov_value === dataItem.paymentTermsTypeId)?.id;
    }

    const error3 = isNaN(editPayload.paymentTermsTypeId);

    if (error3) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent: "Error: Payment terms should be an integer",
        },
        () => {
          setTimeout(() => {
            this.setState({
              ...this.state,
              errorModalContent: "",
              openErrorModal: false,
            });
            this.cancel(dataItem);
          }, 4000);
        }
      );
    } else if (error2 || error1) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent: error2
            ? "Required fields in order to create a new client: Name, Postal, Country and City"
            : "You need to provide the state field in order to create a client",
        },
        () => {
          this.discard(dataItem, true);
          return null;
        }
      );
    } else {
      try {
        removeUnwantedKeyValuesFromArr(editPayload);

        const existingItem = await serverApi(
          "GET",
          `masterEntities/${updatedItem.id}`,
          {},
          {},
          {
            token: window.localStorage.getItem("access_token"),
          }
        );

        const fieldsToUpdate = {};
        // eslint-disable-next-line no-unused-vars
        for (const key in editPayload) {
          if (
            JSON.stringify(existingItem.data[key]) !==
            JSON.stringify(editPayload[key])
          ) {
            fieldsToUpdate[key] = editPayload[key];
          }
        }

        const editRequest = await AbstractEdit(
          dataItem.id,
          fieldsToUpdate,
          "masterEntities",
          {
            token: window.localStorage.getItem("access_token"),
          }
        );
        const countRequest = await serverApi(
          "GET",
          'masterEntities/count?where={"client_flag":"true"}',
          "",
          "",
          {
            token: window.localStorage.getItem("access_token"),
          }
        );

        if (editRequest.status === 200 && countRequest) {
          this.setState(
            {
              ...this.state,
              gridData: {
                data: data,
                total: countRequest.data.count,
              },
              deleteVisible: false,
              successMessage: "The client was successfully updated",
              openSuccessMessage: true,
            },
            () => {
              setTimeout(() => {
                this.handleSuccessSnackbar();
                this.refreshGridDataAfterCrud();
              }, 3000);
            }
          );
        }
      } catch (e) {
        this.setState(
          {
            ...this.state,
            openErrorModal: true,
            errorModalContent:
              (e.response.data && e.response.data.error.message) ||
              "Please try again",
          },
          () => this.discard(dataItem, true)
        );
      }
    }
  };

  cancel = (dataItem) => {
    if (dataItem.relation_name === undefined) {
      const data =
        this.state.initialGridData && this.state.initialGridData.data;
      const total = this.state.initialGridData.total;

      this.setState({
        ...this.state,
        gridData: {
          data: data,
          total: total,
        },
        errorModalContent:
          this.state.errorModalContent !== ""
            ? ""
            : this.state.errorModalContent,
        openErrorModal:
          this.state.openErrorModal === true
            ? false
            : this.state.errorModalContent,
      });
    } else {
      const originalItem =
        this.state.initialGridData &&
        this.state.initialGridData.data.find((p) => p.id === dataItem.id);

      if (originalItem) {
        if (originalItem.inEdit) {
          originalItem.inEdit = false;
        }
        if (originalItem.inAdd) {
          originalItem.inAdd = false;
        }

        const data =
          this.state.gridData &&
          this.state.gridData.data.map((item) =>
            item.id === originalItem.id ? originalItem : item
          );
        const total = this.state.gridData.total;

        this.setState({
          ...this.state,
          gridData: {
            data: data,
            total: total,
          },
        });
      }
    }
  };

  addNew = async () => {
    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    const newDataItem = {
      inEdit: true,
      client_flag: true,
      approval_status: "",
      inAdd: true,
    };

    this.setState(
      {
        ...this.state,
        gridData: {
          data: [newDataItem, ...this.state.gridData.data],
          total: this.state.gridData.total,
        },
        visible: true,
      },
      () => {
        this.applyNewColumnsOnGrid();
      }
    );
  };

  updateItem = (data, item) => {
    let index = data.findIndex(
      (p) => p === item || (item.id && p.id === item.id)
    );
    if (index >= 0) {
      data[index] = { ...item };
    }
  };

  applyNewColumnsOnGrid = (dataItem) => {
    let columns = this.state.initialColumns;
    let newColumns = columns.filter(
      (x) => x.field !== "id" && x.field !== "approval_status"
    );
    newColumns.forEach((x) => (x.minWidth = 290));
    if (dataItem && dataItem.relation_country !== "US") {
      newColumns = newColumns.filter((col) => col.field !== "state");
    }

    if (dataItem && dataItem.relation_country !== "US") {
      newColumns = newColumns.filter((col) => col.field !== "state");
    }

    // Remove 'paymentTerms' and 'paymentTermsTypeId' if client_flag or isBillingEntity is false
    if (!dataItem?.client_flag || !dataItem?.isBillingEntity) {
      newColumns = newColumns.filter(
        (col) =>
          col.field !== "paymentTerms" && col.field !== "paymentTermsTypeId"
      );
    }

    this.setState(
      {
        ...this.state,
        hideColumns: true,
        columns: newColumns,
        initialColumns: columns,
      },
      () => {}
    );
  };

  applyInitialColumnsOnGrid = () => {
    let initialColumns = this.state.initialColumns;
    let newColumns = initialColumns;
    this.setState({
      ...this.state,
      hideColumns: false,
      columns: newColumns,
    });
  };

  itemChange = (event) => {
    const { field, value, dataItem } = event;

    if (
      field === "client_flag" ||
      (field === "approval_status" && dataItem.inAdd)
    ) {
      return;
    }

    if (field === "relation_country" && value === "US") {
      this.applyInitialColumnsOnGrid();
    } else if (field === "relation_country" && value !== "US") {
      this.setState({
        ...this.state,
        columns:
          (columnsSchema && columnsSchema()).filter(
            (col) => col.field !== "state"
          ) || [],
      });
    }

    const total = this.state.gridData.total;
    const data =
      this.state.gridData &&
      this.state.gridData.data.map((item) =>
        item.id === event.dataItem.id
          ? { ...item, [event.field]: event.value }
          : item
      );

    this.setState({
      gridData: {
        data: data,
        total: total,
      },
    });
  };

  removeItem(data, item) {
    let index = data.findIndex(
      (p) => p === item || (item.id && p.id === item.id)
    );
    if (index >= 0) {
      data.splice(index, 1);
    }
  }

  onClearFilters = () => {
    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    this.props.history.push(`/app/master/clients`);

    let init = JSON.stringify({ take: 100, skip: 0 });
    if (hasFinalParams && hasFinalParams.filter)
      window.localStorage.setItem("finalParams", init);

    this.dataStateChange({ data: { take: 100, skip: 0 } });
  };

  closeErrorModal = () => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      errorModalContent: "",
    });
  };

  expandChange = (event) => {
    window.localStorage.setItem("expandeId", event.dataItem.id);

    //if add new vessel is true then expand cannot be done !
    if (this.state.gridData.data[0].inEdit === true) {
      return;
    } else {
      event.dataItem.expanded = !event.dataItem.expanded;
    }

    this.forceUpdate();
  };

  handleErrorClose = (loginError) => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      openErrorModalContent: "",
    });
  };

  deleteAction = async () => {
    this.setState({
      ...this.state,
      deleteVisible: false,
    });

    let dataItem = this.state.itemForDelete;

    const data = [...this.state.gridData.data];

    let index = data.findIndex(
      (p) => p === dataItem || (dataItem.id && p.id === dataItem.id)
    );
    if (index >= 0) {
      data.splice(index, 1);
    }

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let url = "masterEntities";

    try {
      let deleteItemRequest = await AbstractDelete(url, dataItem.id, options);

      if (deleteItemRequest.status === 200) {
        //clear the column filters if grid data is 0 or let them if grid data is >= 1
        if (data.length <= 0) {
          this.setState({
            ...this.state,
            gridData: {
              data: data,
              total: data.length,
            },
            dataState: {
              skip: 0,
              take: 100,
            },
            deleteVisible: false,
          });
        } else {
          this.setState({
            ...this.state,
            gridData: {
              data: data,
              total: this.state.gridData.total - 1,
            },
            dataState: {
              take: this.state.dataState.take,
              skip: this.state.dataState.skip,
            },
            deleteVisible: false,
          });
        }
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent:
          (e.response.data && e.response.data.error.message) ||
          "Please try again",
      });
    }
  };

  disableAction = async () => {
    let dataItem = this.state.itemForDelete;

    let filterOBJ = {
      filter: {
        where: {
          clientID: dataItem.id,
        },
        include: ["vessel"],
      },
    };

    const responseData = await serverApi(
      "GET",
      `vesselToClients`,
      filterOBJ,
      "",
      {
        token: window.localStorage.getItem("access_token"),
      }
    );

    if (responseData && responseData.data.length > 0) {
      this.setState(
        {
          ...this.state,
          deleteVisible: false,
          openErrorModal: true,
          errorModalContent:
            "Remove all vessels first and then disable the client.",
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 4000);
        }
      );

      return;
    }

    try {
      const editRequest = await AbstractEdit(
        dataItem.id,
        { client_flag: false },
        "masterEntities",
        {
          token: window.localStorage.getItem("access_token"),
        }
      );

      if (editRequest) {
        this.setState(
          {
            ...this.state,
            deleteVisible: false,
            successMessage: "The client was successfully disabled.",
            openSuccessMessage: true,
          },
          () => {
            setTimeout(() => {
              this.handleSuccessSnackbar();
              this.refreshGridDataAfterCrud();
            }, 4000);
          }
        );
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent: "Please refresh",
      });
    }
  };

  rowClick = (event) => {
    // window.localStorage.setItem('expandeId', event.dataItem.id)
    //
    // //if add new vessel is true then expand cannot be done !
    // if (this.state.gridData.data[0].inEdit === true) {
    //     return
    // } else {
    //     event.dataItem.expanded = !event.dataItem.expanded;
    // }
    //
    // this.forceUpdate();
  };

  handleSuccessSnackbar = () => {
    this.setState({
      ...this.state,
      successMessage:
        this.state.openSuccessMessage === true ? this.state.successMessage : "",
      openSuccessMessage: !this.state.openSuccessMessage,
    });
  };

  toggleWindow = (e) => {
    const data = this.state.initialGridData.data;
    let countriesInit = this.state.countries;
    if (this.state.countries.length <= this.state.initialCountries.length) {
      countriesInit = this.state.initialCountries;
    }

    this.setState({
      visibleWindow: !this.state.visibleWindow,
      gridData: {
        data: [...data],
        total: 10,
      },
      finalGoogleData: [],
      countries: countriesInit,
      relation_country: "",
      relation_name: "",
    });
  };

  toggleSimilarityWindow = () => {
    this.setState({
      similarityWindow: !this.state.similarityWindow,
    });
  };

  callGoogleApi = async () => {
    let nameParam =
      this.state.gridData.relation_name || this.state.relation_name;
    let countryParam =
      this.state.gridData.relation_country || this.state.relation_country;
    let finalCountryParam;
    finalCountryParam = this.state.countries
      .find((x) => x.text.includes(countryParam))
      .text.replace(/ *\([^)]*\) */g, "");

    const googleRequest = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${nameParam}+${finalCountryParam}&key=AIzaSyBtKMwh-j9WYW6X-bmvyENVac5d1sFcOlo`,
      {}
    );

    let secondCallDetailsUrl = "masterEntities/googlePlaceSearch";
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let googleReq2;

    let googleData;
    let finalGoogleData = [];
    let relation_postal_code;
    let relation_address;
    let relation_city;
    let relation_country;
    let finalRelationAddress;
    let streetNumber = "";
    let placeIdsArray = [];
    let placeIDResponses = [];
    let latitude, longitude;

    googleData = googleRequest.data.results;

    _.forEach(googleData, (res) => {
      placeIdsArray.push({ place_id: res.place_id });

      if (res.address_components) {
        let googleResponse = _.cloneDeep(res.address_components);

        _.forEach(googleResponse, (rsp) => {
          if (rsp.types.includes("country")) {
            relation_country = rsp.short_name;
          }
          if (rsp.types.includes("postal_code")) {
            relation_postal_code = rsp.long_name;
          }
          if (rsp.types.includes("street_number")) {
            streetNumber = rsp.long_name;
          }
          if (rsp.types.includes("route")) {
            relation_address = rsp.short_name;
          }
          if (
            rsp.types.includes("locality") &&
            rsp.types.includes("political")
          ) {
            relation_city = rsp.short_name;
          }

          latitude = res.geometry.location.lat.toString();
          longitude = res.geometry.location.lng.toString();
        });

        finalRelationAddress = `${relation_address}  ${streetNumber}`;
      }
    });

    //eslint-disable-next-line
    for (let plcID of placeIdsArray) {
      if (googleRequest.data.results.length > 0) {
        googleReq2 = await serverApi(
          "POST",
          secondCallDetailsUrl,
          {},
          { searchPlace: `${plcID.place_id}` },
          options
        );

        placeIDResponses.push(googleReq2.data.result[0]);
      }
    }

    for (let i = 0; i < placeIDResponses.length; i++) {
      finalGoogleData.push({
        relation_name:
          placeIDResponses &&
          placeIDResponses.length > 0 &&
          placeIDResponses[i].name !== ""
            ? placeIDResponses[i].name
            : nameParam,
        relation_country: relation_country,
        relation_postal_code: relation_postal_code,
        relation_address: finalRelationAddress,
        relation_city: relation_city,
        latitude: latitude,
        longitude: longitude,
        relation_tel:
          placeIDResponses &&
          placeIDResponses.length > 0 &&
          placeIDResponses[i].international_phone_number !== ""
            ? placeIDResponses[i].international_phone_number
            : "NA",
        relation_mail: "NA",
        relation_web:
          placeIDResponses &&
          placeIDResponses.length > 0 &&
          placeIDResponses[i].website !== ""
            ? placeIDResponses[i].website
            : "NA",
      });
    }

    if (finalGoogleData.length > 0) {
      finalGoogleData[0].selected = true;
    }

    let gridData = { ...this.state.gridData };
    let data = gridData.data;

    data[0] = {
      inEdit: true,
      relation_postal_code: "",
      relation_city: "",
      relation_address: "",
      relation_country: gridData && gridData.relation_country,
      relation_name: gridData && gridData.relation_name,
      relation_tel:
        (finalGoogleData &&
          finalGoogleData.length > 0 &&
          finalGoogleData[0].relation_tel) ||
        "NA",
      relation_web:
        (finalGoogleData &&
          finalGoogleData.length > 0 &&
          finalGoogleData[0].relation_web) ||
        "NA",
    };

    delete gridData.relation_country;
    delete gridData.relation_name;

    this.setState({
      ...this.state,
      gridData: gridData,
      visible: true,
      finalGoogleData: finalGoogleData,
      showZeroResultsMessage: finalGoogleData.length === 0 ? true : false,
      selectedItem: finalGoogleData[this.state.selectedRowIndex],
      initialHeight: 700,
      initialWidth: 1600,
    });
  };

  saveGoogleOption = (e, gridData, finalGoogleData, selectedItem) => {
    let data = gridData && gridData.data;

    let finalName = this.state.relation_name;
    if (this.state.gridData.relation_name !== this.state.relation_name) {
      finalName = this.state.relation_name;
    } else {
      finalName = this.state.gridData.relation_name;
    }

    let finalCountry = this.state.relation_country;
    if (this.state.gridData.relation_country !== this.state.relation_country) {
      finalCountry = this.state.relation_country;
    } else {
      finalCountry = this.state.gridData.relation_country;
    }

    data[0] = {
      inEdit: true,

      relation_postal_code: selectedItem && selectedItem.relation_postal_code,
      relation_address: selectedItem && selectedItem.relation_address,
      relation_city: selectedItem && selectedItem.relation_city,
      relation_tel: (selectedItem && selectedItem.relation_tel) || "",
      relation_web: (selectedItem && selectedItem.relation_web) || "",
      relation_country:
        selectedItem.relation_country !== finalCountry
          ? selectedItem.relation_country
          : finalCountry,
      relation_name:
        selectedItem.relation_name !== finalName
          ? selectedItem.relation_name
          : finalName,
    };

    this.setState({
      ...this.state,
      gridData: gridData,
      visibleWindow: false,
      finalGoogleData: [],
      width: "80%",
    });
  };

  filterChange = (event) => {
    let filteredResults = this.filterData(event.filter);

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.setState({
        countries:
          filteredResults !== undefined
            ? filteredResults
            : this.state.initialCountries,
        loading: false,
      });
    }, this.state.delay);

    this.setState({
      ...this.state,
      loading: true,
    });
  };

  filterData(filter) {
    if (filter.value === "") {
      this.setState({
        countries: this.state.initialCountries,
      });
      return;
    } else {
      const data = this.state.initialCountries;
      filter.operator = "startswith";
      let filteredResults = filterBy(data, filter);
      return filteredResults;
    }
  }

  onDialogInputChange = (event) => {
    let target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.props ? target.props.name : target.name;

    this.setState({
      ...this.state,
      gridData: {
        ...this.state.gridData,
        [name]: value,
      },
      [name]: value,
    });
  };

  handleDropDownChange = (event) => {
    let target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.props ? target.props.name : target.name;

    this.setState({
      ...this.state,
      isDropOpened: !this.state.isDropOpened,
      gridData: {
        ...this.state.gridData,
        [name]: value.id,
      },
      [name]: value.id,
    });
  };

  renderGridColumnsForGoogle = () => {
    let selectionCol = [];
    let normalCols = [];

    _.forEach(this.state.columnsForGoogle, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            width="65px"
            filterable={false}
            headerSelectionValue={
              this.state.finalGoogleData.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columnsForGoogle
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.isFilterBoolean === "yes") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              cell={DropDownCell}
            />
          );
        } else {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={temp.field === "id" ? false : true} //id not editable on edit

              // cell={() =>
              //     <td>
              //         <a rel="noopener noreferrer" href="https://p.2hog.codes/" target="_blank">Link Here</a>
              //     </td>
              // }
            />
          );
        }
      });

    return [...selectionCol, ...normalCols];
  };

  renderGridColumnsForSimilarity = () => {
    let selectionCol = [];
    let normalCols = [];

    _.forEach(this.state.similarityColumns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            width="65px"
            filterable={false}
            headerSelectionValue={
              this.state.finalGoogleData.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.similarityColumns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }
        return (
          <Column
            sortable={temp.sortable}
            locked={temp.locked}
            field={temp.field}
            filterable={temp.filterable}
            title={temp.title}
            width={temp.minWidth || "auto"}
            filter={temp.filter}
            visible={temp.visible}
            minGridWidth={"400"}
            editable={
              temp.field === "id" ||
              temp.field === "ar_account" ||
              temp.field === "ap_account" ||
              temp.editable
                ? false
                : true
            }
          />
        );
      });

    return [...selectionCol, ...normalCols];
  };
  finalSort;
  exportToCsv = async (state, props) => {
    const count = state.gridData.total;
    const where = window.localStorage.getItem("exportFilter") || {};
    const numberOfCalls = Math.min(
      Math.round(count / 1000) + 1,
      5 // Limit to 5000
    );
    let finalSort = this.state.dataState?.sort?.length
      ? this.state.dataState.sort[0].field +
        " " +
        this.state.dataState.sort[0].dir
      : "id asc";

    const promises = [];
    for (let i = 0; i < numberOfCalls; i++) {
      promises.push(
        this.getOrdersPromise({
          skip: i * 1000,
          limit: 1000,
          where: !_.isEmpty(where) ? JSON.parse(where) : {},
          order: [finalSort],
        })
      );
    }

    try {
      const responses = await Promise.all(promises);
      let allData = [];
      responses.forEach((resp) => {
        allData = [...allData, ...resp.data];
      });
      if (allData.length > 0) {
        this.setState({
          ...this.state,
          exportData: allData,
        });
        // this._export.save(allData, this.state.columns);
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent:
          (e.response.data && e.response.data.error.message) ||
          "Please try again",
      });
    }
  };

  getOrdersPromise = (filter) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    return new Promise((resolve, reject) => {
      const masterEntityRequest = serverApi(
        "GET",
        `masterEntities?filter=${JSON.stringify(filter)}`,
        "",
        "",
        options
      );
      masterEntityRequest
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getExportData = async () => {
    await this.exportToCsv(this.state, this.props);
  };

  selectionChange = (event) => {
    (this.state.finalGoogleData || []).map((item, index) => {
      if (item.relation_tel === event.dataItem.relation_tel) {
        item.selected = !event.dataItem.selected;

        // let selectedArray = JSON.parse(window.localStorage.getItem('selectedItems')) || []

        if (item.selected === false) {
          // selectedArray = selectedArray.filter(temp => (temp.box_id !== item.box_id));
          // selectedArray.push(item)
        } else if (item.selected === true) {
          // selectedArray.push(item)
        }
        // window.localStorage.setItem('selectedItems', JSON.stringify(selectedArray))
      } else {
        let finalToggleGoodleData = this.state.finalGoogleData;
        finalToggleGoodleData[index].selected = false;

        this.setState({
          ...this.state,
          finalGoogleData: finalToggleGoodleData,
        });
      }

      this.selectGoogleOption(item, index);
      return item;
    });

    // this.setState({
    //         ...this.state,
    //         blocking: false,
    //         gridData: {
    //             data: data
    //         }
    //
    // })
  };

  selectGoogleOption = (item, index) => {
    this.setState({
      selectedRowIndex: index,
      selectedItem: item,
    });
  };

  rowRender = (trElement, dataItem) => {
    const { approval_status } = dataItem.dataItem;
    let color = "";
    if (approval_status === "Approved") {
      color = "#62b715";
    } else if (
      approval_status === "Not approved" ||
      approval_status === "No response"
    ) {
      color = "#ed7070";
    } else {
      color = "#0d5869";
    }

    const trProps = {
      ...trElement.props,
      onContextMenu: (e) => {
        e.preventDefault();
      },
      style: {
        color: color,
      },
    };

    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  bypassCheck = () => {
    const submitURL = "masterEntities";
    const options = { token: window.localStorage.getItem("access_token") };
    const dataItem = this.state.gridData.data.filter((x) => x.inEdit)[0] || {};

    let payload = dataItem;
    payload.supplier_flag = false;
    payload.transport_flag = false;
    payload.agent_flag = false;
    payload.location_flag = false;
    payload.sm_agent_flag = false;
    payload.sm_vendor_flag = false;
    payload.airport_flag = false;
    payload.wh_flag = false;
    payload.seaport_flag = false;
    // payload.approval_status = "Pending";
    delete payload.inEdit;
    delete payload.inAdd;

    const createRequest = serverApi(
      "POST",
      `${submitURL}`,
      "",
      payload,
      options
    );
    createRequest
      .then(async (createResponse) => {
        this.setState(
          {
            ...this.state,
            similarityWindow: false,
            inAdd: false,
            deleteVisible: false,
            successMessage: "The client was successfully created",
            openSuccessMessage: true,
          },
          () => {
            setTimeout(() => {
              this.handleSuccessSnackbar();
              this.refreshGridDataAfterCrud();
            }, 4000);
          }
        );
      })
      .catch((createError) => {
        this.setState(
          {
            ...this.state,
            openErrorModal: true,
            errorModalContent:
              (createError.response.data &&
                createError.response.data.error.message) ||
              "Please try again",
          },
          () => this.discard(dataItem || {}, true)
        );
      });
  };

  handleRowClick = async (event) => {
    if (!this.clickTimer) {
      // First click
      this.clickTimer = setTimeout(() => {
        // Single click action
        this.clickTimer = null; // Reset the timer
      }, 200); // Set an appropriate delay for detecting double-click
    } else {
      // Second click
      clearTimeout(this.clickTimer); // Cancel the single click action
      this.clickTimer = null; // Reset the timer
      // Double-click action

      const rowData = event.dataItem;
      const recordId = rowData.id;

      let token = window.localStorage.getItem("access_token");
      let options = {
        token: token,
      };

      let approvalReq = await serverApi(
        "GET",
        `approvalRequests`,
        {
          filter: {
            where: {
              modelName: "MASTERENTITY",
              modelId: recordId,
              action: "CLIENT_APPROVAL",
            },
            include: "requestor",
          },
        },
        "",
        options
      );

      const finalData = formatDatesOnTable(approvalReq, ["requestedDate"]);

      this.setState(
        {
          ...this.state,
          approvalData: finalData || [],
          approvalReqName: rowData.relation_name,
        },
        () => {
          this.setState({ ...this.state, approvalHistoryModal: true });
        }
      );
    }
  };

  removeContact = async (dataItem) => {
    const data = [...this.state.gridData.data];

    let index = data.findIndex((p) => p === dataItem || p.id === dataItem.id);
    if (index >= 0) {
      data.splice(index, 1);
    }

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let url = "masterEntityContacts";

    try {
      let deleteItemRequest = await AbstractDelete(
        url,
        `${dataItem.id}`,
        options
      );

      if (deleteItemRequest.status === 200) {
        this.setState({
          ...this.state,
          dataItem: {},
          deleteVisible: false,
        });
      }
    } catch (e) {
      this.setState({
        ...this.state,
        dataItem: {},
        deleteVisible: false,
        openErrorModal: true,
        errorModalContent:
          e?.response?.data?.error?.message || "Please try again",
      });
    }
  };

  toggleDeleteDialog(dataItem = {}, state) {
    this.setState({
      ...state,
      deleteVisible: !this.state.deleteVisible,
      itemForDelete: dataItem,
    });
  }

  render() {
    const {
      gridData,
      columns,
      finalGoogleData,
      selectedItem,
      similarityData,
      visibleWindow,
      similarityWindow,
    } = this.state;
    const hasEditedItem = gridData && gridData.data.some((p) => p.inEdit);

    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    let hasColumnFilters = true;
    if (found) {
      hasColumnFilters = false;
    }

    const { hasBackofficeRead } = this.props;

    return (
      <div>
        {this.state.deleteVisible && (
          <Dialog onClose={() => this.toggleDeleteDialog({}, this.state)}>
            <p style={{ margin: "25px", textAlign: "center" }}>
              Are you sure you want to disable this client?
            </p>
            <DialogActionsBar>
              <button
                className="k-button"
                onClick={() => this.toggleDeleteDialog({}, this.state)}
              >
                No
              </button>
              <button className="k-button" onClick={() => this.disableAction()}>
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.deleteVisible && (
          <Dialog onClose={() => this.toggleDeleteDialog({}, this.state)}>
            <p style={{ margin: "25px", textAlign: "center" }}>
              Are you sure you want to delete this contact?
            </p>
            <DialogActionsBar>
              <button
                className="k-button"
                onClick={() => this.toggleDeleteDialog({}, this.state)}
              >
                No
              </button>
              <button
                className="k-button"
                onClick={() => {
                  this.removeContact(this.state.dataItem);
                }}
              >
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
        <Grid
          {...this.state.dataState}
          {...this.state.gridData}
          filterable={hasColumnFilters}
          onRowClick={this.handleRowClick}
          style={{ height: "92vh" }}
          sortable={true}
          resizable
          editField={this.editField}
          detail={(dataItem) => (
            <div>
              <ExpandedTabs
                tabs={[
                  {
                    label: "Client Vessel",
                    component: (
                      <ClientToVesselExpanded
                        {...dataItem}
                        hasBackofficeRead={hasBackofficeRead}
                      />
                    ), // Index
                  },
                  {
                    label: "Contacts",
                    component: (
                      <Contacts
                        countries={this.state.countries}
                        {...dataItem}
                        hasBackofficeRead={hasBackofficeRead}
                        expandedLabel="CLIENT NAME"
                        view="Client"
                        showRemoveWindow={(dataItem) => {
                          this.setState({
                            ...this.state,
                            deleteVisible: true,
                            dataItem: dataItem,
                          });
                        }}
                      />
                    ), // Index
                  },
                  {
                    label: "Domains",
                    component: (
                      <ClientToDomain
                        {...dataItem}
                        hasBackofficeRead={hasBackofficeRead}
                      />
                    ), // Index
                  },
                  {
                    label: "Client Group",
                    component: (
                      <ClientToGroup
                        {...dataItem}
                        hasBackofficeRead={hasBackofficeRead}
                      />
                    ), // Index
                  },
                  {
                    label: "Client User",
                    component: (
                      <ClientToUserExpanded
                        {...dataItem}
                        hasBackofficeRead={hasBackofficeRead}
                      />
                    ), // Index
                  },
                  {
                    label: "Client Billing Entity",
                    component: (
                      <ClientToBillingEntityExpanded
                        {...dataItem}
                        hasBackofficeRead={hasBackofficeRead}
                      />
                    ), // Index
                  },
                ]}
              />
            </div>
          )}
          onItemChange={this.itemChange}
          onDataStateChange={this.dataStateChange}
          // rowRender={this.rowRender}
          expandField="expanded"
          onExpandChange={this.expandChange}
          pageable={{
            buttonCount: 5,
            info: true,
            pageSizes: [100, 200, 500, 1000, 1500],
          }}
        >
          <GridToolbar>
            <GridContainer xs={12} direction={"row"}>
              {!hasBackofficeRead && (
                <Tooltip
                  justify={"flex-start"}
                  placement="top"
                  title={"Add Client Manually"}
                >
                  <IconButton onClick={this.addNew}>
                    <FontAwesomeIcon
                      color="#0D5869"
                      onClick={this.addNew}
                      size="1.6x"
                      icon={faPlus}
                    />
                  </IconButton>
                </Tooltip>
              )}
              {/* {!hasBackofficeRead &&
                <Tooltip
                  justify={"flex-start"}
                  placement="top"
                  title={"Add Client"}
                >
                  <IconButton>
                    <Fab size="medium">
                      <AddIcon onClick={this.toggleWindow} />
                    </Fab>
                  </IconButton>
                </Tooltip>} */}

              <Tooltip
                justify={"flex-end"}
                placement="top"
                title={"Clear Column Filters"}
              >
                <IconButton
                  disabled={false}
                  aria-label=""
                  onClick={(e) => this.onClearFilters(e)}
                  look="flat"
                >
                  <FontAwesomeIcon
                    color="#0D5869"
                    size="1.6x"
                    icon={faEraser}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip
                justify={"flex-start"}
                placement="top"
                title={"Refresh Data"}
              >
                <IconButton
                  disabled={false}
                  aria-label=""
                  onClick={this.refreshGridDataAfterCrud}
                >
                  <FontAwesomeIcon color="#0D5869" size="1x" icon={faSyncAlt} />
                </IconButton>
              </Tooltip>
              <ViewSettings
                {...this.props}
                export={{
                  data: this.state.exportData || [],
                  exportFunction: this.getExportData,
                  fileName: "Clients_",
                  columns: columnsSchema(),
                }}
                viewSettings={{
                  type: "",
                }}
                refreshSettings={{
                  data: [],
                }}
              />
              {!this.state.hideColumns ? (
                <div style={{ marginTop: hasBackofficeRead ? "5px" : "5px" }}>
                  <FormControl className={customSelectStyles.selectFormControl}>
                    <InputLabel
                      htmlFor="multiple-select"
                      className={customSelectStyles.selectLabel}
                    />
                    <Select
                      autoWidth={true}
                      multiple
                      value={columns
                        .filter((column) => column.visible)
                        .map((column) => column.field)}
                      onChange={this.handleColumnsChange}
                      MenuProps={{ className: customSelectStyles.selectMenu }}
                      inputProps={{
                        name: "multipleSelect",
                        id: "multiple-select",
                      }}
                      renderValue={(selected) => "Columns"}
                    >
                      {columns
                        .filter((col) => !col.notShownInTableColumnSelection)
                        .map((column) => {
                          let name = column.title;
                          let value = column.field;
                          let isVisible = column.visible;
                          let isDisabled = column.noDisabledColumn;

                          return (
                            <MenuItem
                              style={{ backgroundColor: "white" }}
                              key={name}
                              value={value}
                              disabled={isDisabled}
                            >
                              <Checkbox color={"default"} checked={isVisible} />
                              <ListItemText
                                style={{ marginLeft: "7px" }}
                                primary={name}
                              />
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
              ) : null}
            </GridContainer>
          </GridToolbar>

          {!hasBackofficeRead && (
            <Column
              cell={this.CommandCell}
              title="Actions"
              filterable={false}
              width={hasEditedItem ? "240px" : "220px"}
            />
          )}

          {this.renderGridColumns()}
        </Grid>

        <Loader
          dataState={this.state.dataState}
          onDataRecieved={this.dataReceived}
          include={["creator", "paymentTermsType"]}
          getURL={"masterEntities"}
          countURL={'masterEntities/count?where={"client_flag":true}'}
          view={"client"}
          paymentTermsLovTypes={this.props.paymentTermsLovTypes}
        />

        <Snackbar
          place="tc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"warning"}
          message={this.state.errorModalContent}
          open={this.state.openErrorModal}
        />

        <Snackbar
          place="tc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"success"}
          message={this.state.successMessage}
          open={this.state.openSuccessMessage}
        />

        {visibleWindow && (
          <Window
            title={"Provide name and country"}
            onClose={this.toggleWindow}
            width={1600}
            height={700}
          >
            <div>
              <fieldset style={{ borderColor: "rgba(13, 88, 105, 1)" }}>
                <legend>Search Input</legend>
                <div>
                  <h5>Name</h5>
                  <input
                    className="k-textbox"
                    name="relation_name"
                    value={
                      this.state.gridData.data.filter(
                        (temp) => temp.inEdit === true
                      ).relation_name
                    }
                    onChange={this.onDialogInputChange}
                    placeholder="Provide Name"
                  />
                  <h5>Country</h5>
                  <DropDownList
                    // label={"Country"}
                    data={this.state.countries.sort((a, b) =>
                      a.text > b.text ? 1 : -1
                    )}
                    value={this.state.relation_country.text}
                    textField="text"
                    // opened={this.state.isDropOpened}
                    style={{ width: "310px" }}
                    // onFocus={e => {
                    //
                    //     this.setState({
                    //         isDropOpened : !this.state.isDropOpened
                    //     })
                    //
                    // }}
                    // onBlur={e => {
                    //     this.setState({
                    //         isDropOpened : false
                    //     })
                    // }}
                    filterable={true}
                    name="relation_country"
                    loading={this.state.loading}
                    onFilterChange={this.filterChange}
                    onChange={this.handleDropDownChange}
                  />
                </div>
                <br />
                <br />
                <button
                  style={{ marginRight: "5px" }}
                  type="button"
                  className="k-button k-primary"
                  onClick={(e) => this.callGoogleApi(e)}
                  disabled={
                    this.state.relation_name === "" ||
                    !this.state.relation_name ||
                    this.state.relation_country === "" ||
                    !this.state.relation_country
                      ? true
                      : false
                  }
                >
                  Search
                </button>
              </fieldset>
              {
                <div>
                  <h3 style={{ color: "#ff9d00" }}>
                    {this.state.showZeroResultsMessage
                      ? "No  results from your input. \n Please try with different inputs."
                      : ""}
                  </h3>
                </div>
              }
              {!_.isEmpty(finalGoogleData) && finalGoogleData.length > 0 ? (
                <div>
                  <fieldset style={{ borderColor: "rgba(13, 88, 105, 1)" }}>
                    <legend>Results</legend>
                    <Grid
                      data={finalGoogleData}
                      heigh={"100%"}
                      resizable
                      selectedField="selected"
                      onSelectionChange={this.selectionChange}
                      // onHeaderSelectionChange={headerSelectionChange}
                      // onRowClick={rowClick ? rowClick : false}
                      filterable={false}
                      // reorderable={reorderable}
                      sortable={false}
                      expandField="expanded"
                      // detail={showExpandedData}
                      // onExpandChange={this.expandChange123}
                      // onRowClick={(e) => {
                      //   let id = e.dataItem.id
                      //   redirectToEditForm(id, '', 'y')
                      // }}
                      pageable={false}
                      // onDataStateChange={dataStateChange}
                      ref={(grid) => {
                        this._grid = grid;
                      }}
                    >
                      {[...this.renderGridColumnsForGoogle()]}
                    </Grid>
                    <br />
                    <br />
                    {!_.isEmpty(finalGoogleData) &&
                    finalGoogleData.length > 0 ? (
                      <div>
                        <button
                          type="button"
                          className="k-button k-primary"
                          onClick={(e) =>
                            this.saveGoogleOption(
                              e,
                              gridData,
                              finalGoogleData,
                              selectedItem
                            )
                          }
                        >
                          Use option
                        </button>
                      </div>
                    ) : null}
                  </fieldset>
                </div>
              ) : null}
            </div>
          </Window>
        )}

        {similarityWindow && (
          <Window
            title={"Existing clients in navigator"}
            onClose={this.toggleSimilarityWindow}
            width={1420}
            height={700}
            style={{ marginLeft: "-100px" }}
          >
            <span>
              <fieldset style={{ borderColor: "rgba(13, 88, 105, 1)" }}>
                <div style={{ display: "contents", flexDirection: "row" }}>
                  <legend>
                    These existing clients have similar names.Is this the client
                    you want one of them?
                  </legend>
                  <button
                    type="button"
                    className="k-button k-primary"
                    onClick={(e) => {
                      this.bypassCheck();
                    }}
                  >
                    Bypass check and save
                  </button>
                </div>
                <div>
                  <Grid
                    data={similarityData}
                    heigh={"100%"}
                    resizable
                    filterable={false}
                    sortable={false}
                  >
                    {this.renderGridColumnsForSimilarity()}
                  </Grid>
                </div>
              </fieldset>
            </span>
          </Window>
        )}

        {this.state.approvalHistoryModal && (
          <Window
            title={`Approval Request History / Client: ${this.state.approvalReqName}`}
            onClose={() => {
              this.setState({
                ...this.state,
                approvalHistoryModal: !this.state.approvalHistoryModal,
              });
            }}
            width={1020}
            height={650}
          >
            <span>
              <fieldset style={{ borderColor: "rgba(13, 88, 105, 1)" }}>
                <div>
                  <Grid
                    data={this.state.approvalData}
                    heigh={"70%"}
                    resizable
                    filterable={false}
                    sortable={false}
                  >
                    <Column
                      title="Status"
                      field="status"
                      width="175px"
                      filterable={false}
                    />
                    <Column
                      title="Request Date"
                      field="requestedDate"
                      width="195px"
                      filterable={false}
                    />
                    <Column
                      title="Requestor"
                      field="requestor.userRealName"
                      width="225px"
                      filterable={false}
                    />
                    <Column
                      title="Reason"
                      field="reason"
                      width="777px"
                      filterable={false}
                    />
                  </Grid>
                </div>
              </fieldset>
            </span>
          </Window>
        )}
      </div>
    );
  }
}

export default ClientView;
