export const columnsSchema = () => [
  {
    field: 'id',
    title: 'ID',
    visible: true,
    minWidth: 165,
    sortable: true,
    filterable: true,
    locked: 'locked',
    noDisabledColumn: true,
    notShownInTableColumnSelection: false
  },
  {
    field: 'status',
    title: 'STATUS',
    visible: true,
    minWidth: 180,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    locked: 'locked',
  },
  {
    field: 'username',
    title: 'Username',
    visible: true,
    minWidth: 230,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    locked: 'locked',
  },
  {
    field: 'userRealName',
    title: 'Name',
    visible: true,
    minWidth: 230,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    locked: 'locked',
  },
  {
    field: 'email',
    title: 'Email',
    visible: true,
    minWidth: 200,
    sortable: true,
    filterable: true,
    locked: 'locked',
  },
  {
    field: 'bugify_token',
    title: 'Bugify token',
    visible: false,
    minWidth: 190,
    sortable: true,
    filterable: false,
    locked: 'locked',
    editable: 'false'

  },
  {
    field: 'last_login_date',
    title: 'Last login',
    visible: true,
    minWidth: 200,
    sortable: true,
    filterable: false,
    locked: 'locked',
    editable: 'false'
  }, {
    field: 'last_password_change',
    title: 'Last Password Change',
    visible: true,
    minWidth: 200,
    sortable: true,
    filterable: false,
    locked: 'locked',
    editable: 'false'
  },
  {
    field: 'created',
    title: 'Created',
    visible: true,
    minWidth: 200,
    sortable: true,
    filterable: false,
    locked: 'locked',
    editable: 'false'
  }, {
    field: 'lastUpdated',
    title: 'Last Updated',
    visible: true,
    minWidth: 200,
    sortable: true,
    filterable: false,
    locked: 'locked',
    editable: 'false'
  },
  {
    field: 'termination_date',
    title: 'Termination Date',
    visible: true,
    minWidth: 190,
    sortable: true,
    filterable: false,
    locked: 'locked',
    editable: 'false'
  },
];
