export const columnsSchema = () => [
  {
    field: 'id',
    title: 'ID',
    visible: true,
    minWidth: 170,
    sortable: true,
    filterable: true,
    locked: 'locked',
  },
  {
    field: 'name',
    title: 'Department',
    visible: true,
    minWidth: 230,
    sortable: true,
    filterable: true,
    locked: 'locked',
    editable: 'false'
  },
  {
    field: 'user',
    title: 'User',
    visible: true,
    minWidth: 300,
    sortable: true,
    filterable: true,
    locked: 'locked',
    editable: 'false'
  },
  {
    field: "primary_flg",
    title: "Primary Department",
    visible: true,
    sortable: false,
    minWidth: 170,
    filterable: false,
    locked: 'locked',
    filter: 'boolean',
    isFilterBoolean: "yes",
    editable: 'false'
  },
  {
    field: 'type',
    title: 'Type',
    visible: true,
    minWidth: 200,
    sortable: true,
    filterable: true,
    locked: 'locked',
    editable: 'false'

  },
  {
    field: "start_date",
    title: "VALID FROM",
    visible: true,
    sortable: true,
    minWidth: 260,
    filterable: true,
    locked: 'locked'
  },
  {
    field: "end_date",
    title: "VALID UNTIL",
    visible: true,
    sortable: true,
    minWidth: 260,
    filterable: true,
    locked: 'locked'
  },

];
