export const columnSchema = () => [
  {
    field: 'deleteID',
    title: 'ID',
    visible: true,
    minWidth: 210,
    sortable: true,
    filterable: true,
    locked: 'locked',
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
    // cell: true
  },
  {
    field: 'vessel_name',
    title: 'Vessel Name',
    visible: true,
    sortable: true,
    filterable: true,
    locked: 'locked',
  }

];