import {connect} from 'react-redux';
import compose from 'ramda/src/compose';
import Departments from "./View/index";

const mapStateToProps = (state) => {
    return {
        ...state
    }
}

export default compose(
    connect(mapStateToProps, null))
(Departments)

