export const columnsSchema = () => [
  {
    field: "id",
    title: "ID",
    visible: true,
    minWidth: 170,
    sortable: true,
    filterable: true,
    locked: true,
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
  },
  {
    field: "vessel_name",
    title: "NAME",
    visible: true,
    minWidth: 320,
    sortable: true,
    filterable: true,
    locked: true,
  },
  {
    field: "is_active",
    title: "Active",
    visible: true,
    sortable: true,
    minWidth: 190,
    filterable: true,
    locked: false,
    filter: "boolean",
    isDropdown: "yes",
  },
  {
    field: "has_clients",
    title: "Has clients",
    visible: true,
    sortable: true,
    minWidth: 190,
    filterable: true,
    locked: false,
    filter: "boolean",
    isDropdown: "yes",
  },
  {
    field: "fleets",
    title: "Fleets",
    visible: true,
    minWidth: 350,
    sortable: true,
    filterable: true,
    locked: false,
  },
  {
    field: "vessel_imo_no",
    title: "IMO",
    visible: true,
    minWidth: 220,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    locked: false,
  },
  {
    field: "is_government",
    title: "Is Government",
    visible: true,
    sortable: true,
    minWidth: 190,
    filterable: true,
    locked: false,
    filter: "boolean",
    isDropdown: "yes",
  },
  {
    field: "vessel_type_lov",
    title: "Category",
    visible: true,
    sortable: true,
    minWidth: 210,
    filterable: true,
    locked: false,
  },
  {
    field: "vessel_type",
    title: "TYPE",
    visible: true,
    minWidth: 220,
    sortable: true,
    filterable: true,
    noDisabledColumn: false,
    notShownInTableColumnSelection: false,
    locked: false,
  },
  {
    field: "vessel_flag_iso",
    title: "ISO FLAG",
    visible: true,
    minWidth: 220,
    sortable: true,
    filterable: true,
    locked: false,
    isDropdown: "yes",
  },
  {
    field: "billingStrRegular",
    title: "Billing Str Regular",
    visible: true,
    minWidth: 240,
    sortable: true,
    filterable: true,
    noDisabledColumn: false,
    locked: false,
  },
  {
    field: "billingStrSubmersible",
    title: "Billing Str Submersible",
    visible: true,
    minWidth: 240,
    sortable: true,
    filterable: true,
    noDisabledColumn: false,
    locked: false,
  },
  {
    field: "blanketPO",
    title: "Blanket PO",
    visible: true,
    minWidth: 240,
    sortable: true,
    filterable: true,
    noDisabledColumn: false,
    locked: false,
  },
  {
    field: "billingRef",
    title: "Billing Ref",
    visible: true,
    minWidth: 240,
    sortable: true,
    filterable: true,
    noDisabledColumn: false,
    locked: false,
  },
  {
    field: "createdByForUi",
    title: "Created By",
    visible: true,
    minWidth: 200,
    sortable: false,
    filterable: false,
    // locked: "locked",
    notShownInTableColumnSelection: false,
    editable: "false",
  },
  {
    field: "updatedByForUi",
    title: "Updated By",
    visible: true,
    minWidth: 200,
    sortable: false,
    filterable: false,
    // locked: "locked",
    notShownInTableColumnSelection: false,
    editable: "false",
  },
  {
    field: "valid_from",
    title: "VALID FROM",
    visible: true,
    sortable: true,
    minWidth: 260,
    filterable: false,
    locked: false,
  },
  {
    field: "valid_until",
    title: "VALID UNTIL",
    visible: true,
    sortable: true,
    minWidth: 260,
    filterable: false,
    locked: false,
  },
  {
    field: "vessel_build_dt",
    title: "BUILD-DT",
    visible: false,
    minWidth: 240,
    sortable: true,
    filterable: false,
    locked: false,
    notShownInTableColumnSelection: false,
  },
  {
    field: "vessel_builder",
    title: "BUILDER",
    visible: false,
    sortable: true,
    minWidth: 250,
    filterable: true,
    locked: false,
  },
  {
    field: "vessel_scrapped_dt",
    title: "SCRAPPED-DT",
    visible: false,
    sortable: true,
    minWidth: 260,
    filterable: true,
    locked: false,
  },
  {
    field: "vessel_deadweight",
    title: "WEIGHT",
    visible: false,
    sortable: true,
    minWidth: 150,
    filterable: true,
    locked: false,
  },
  {
    field: "vessel_build_country",
    title: "BUILD-COUNTRY",
    visible: false,
    sortable: true,
    minWidth: 260,
    filterable: true,
    locked: false,
  },
];

export const exportSchema = () => [
  {
    field: "id",
    title: "ID",
    visible: true,
    minWidth: 170,
    sortable: true,
    filterable: true,
    locked: false,
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
    // cell: true
  },
  {
    field: "vessel_name",
    title: "NAME",
    visible: true,
    minWidth: 350,
    sortable: true,
    filterable: true,
    locked: false,
  },
  {
    field: "vessel_imo_no",
    title: "IMO",
    visible: true,
    minWidth: 280,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    locked: false,
  },
  {
    field: "vessel_type",
    title: "TYPE",
    visible: true,
    minWidth: 250,
    sortable: true,
    filterable: true,
    noDisabledColumn: false,
    notShownInTableColumnSelection: false,
    locked: false,
  },
  {
    field: "vessel_flag_iso",
    title: "ISO FLAG",
    visible: true,
    minWidth: 150,
    sortable: true,
    filterable: true,
    locked: false,
  },
  {
    field: "valid_from",
    title: "VALID FROM",
    visible: true,
    sortable: true,
    minWidth: 260,
    filterable: true,
    locked: false,
  },
  {
    field: "valid_until",
    title: "VALID UNTIL",
    visible: true,
    sortable: true,
    minWidth: 260,
    filterable: true,
    locked: false,
  },
  {
    field: "vessel_build_dt",
    title: "BUILD-DT",
    visible: true,
    minWidth: 240,
    sortable: true,
    filterable: true,
    locked: false,
    notShownInTableColumnSelection: false,
  },
  {
    field: "vessel_builder",
    title: "BUILDER",
    visible: true,
    sortable: true,
    minWidth: 250,
    filterable: true,
    locked: false,
  },
  {
    field: "vessel_scrapped_dt",
    title: "SCRAPPED-DT",
    visible: true,
    sortable: true,
    minWidth: 260,
    filterable: true,
    locked: false,
  },
  {
    field: "vessel_deadweight",
    title: "WEIGHT",
    visible: true,
    sortable: true,
    minWidth: 150,
    filterable: true,
    locked: false,
  },
  {
    field: "vessel_build_country",
    title: "BUILD-COUNTRY",
    visible: true,
    sortable: true,
    minWidth: 260,
    filterable: true,
    locked: false,
  },
];

export const fleetmonSchema = () => [
  {
    field: "selected",
    width: "100px",
    notShownInTableColumnSelection: true,
  },
  {
    field: "vessel_name",
    title: "NAME",
    visible: true,
    // minWidth: 350,
    sortable: true,
    filterable: true,
    locked: false,
  },
  {
    field: "vessel_imo_no",
    title: "IMO",
    visible: true,
    // minWidth: 190,
    sortable: false,
    filterable: false,
    noDisabledColumn: true,
    locked: false,
  },
  {
    field: "vessel_type",
    title: "TYPE",
    visible: true,
    // minWidth: 250,
    sortable: false,
    filterable: false,
    noDisabledColumn: false,
    notShownInTableColumnSelection: false,
    locked: false,
  },
  {
    field: "vessel_flag_iso",
    title: "FLAG-ISO",
    visible: true,
    // minWidth: 170,
    sortable: true,
    filterable: true,
    locked: false,
  },
  // {
  //   field: 'vessel_name',
  //   title: 'NAME',
  //   visible: true,
  //   // minWidth: 350,
  //   sortable: true,
  //   filterable: true,
  //   locked: 'locked',
  // },
  // {
  //     field: 'vessel_build_dt',
  //     title: 'BUILD-DT',
  //     visible: true,
  //     minWidth: 240,
  //     sortable: true,
  //     filterable: true,
  //     locked: 'locked',
  //     notShownInTableColumnSelection: false,
  // },
  // {
  //     field: "vessel_builder",
  //     title: "BUILDER",
  //     visible: true,
  //     sortable: true,
  //     minWidth: 250,
  //     filterable: true,
  //     locked: 'locked'
  // },
  // {
  //     field: "vessel_scrapped_dt",
  //     title: "SCRAPPED-DT",
  //     visible: true,
  //     sortable: true,
  //     minWidth: 260,
  //     filterable: true,
  //     locked: 'locked'
  // },
  // {
  //     field: "vessel_deadweight",
  //     title: "WEIGHT",
  //     visible: true,
  //     sortable: true,
  //     minWidth: 150,
  //     filterable: true,
  //     locked: 'locked'
  // },
  // {
  //     field: "vessel_build_country",
  //     title: "BUILD-COUNTRY",
  //     visible: true,
  //     sortable: true,
  //     minWidth: 260,
  //     filterable: true,
  //     locked: 'locked'
  // }
];
