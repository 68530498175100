import React from "react";
import {
  Grid,
  GridColumn as Column,
  GridDetailRow,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { columnsSchema } from "./schema";
import { MyCommandCell } from "../../../../Vessels/View/components/CellHOC";
import _ from "lodash";
import { PrimaryFlag } from "../PrimaryFlagDropdown";
import GridContainer from "../../../../../components/Grid/GridContainer";
import { serverApi } from "../../../../../networking/config";
import { Window } from "@progress/kendo-react-dialogs";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { Error } from "@progress/kendo-react-labels";
import { IconButton, Tooltip } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import BlockUi from "react-block-ui";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

class UserToVessel extends GridDetailRow {
  editField = "inEdit";
  CommandCell;

  constructor(props) {
    super(props);

    this.state = {
      inputValue: "",
      visibleWindow: false,
      notifyEmailOperator: "",
      isDropOpened: false,
      columns: (columnsSchema && columnsSchema()) || [],
      gridData: {
        data: [],
        total: 10,
      },
      initialGridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 10,
        skip: 0,
      },
      openErrorModal: false,
      errorModalContent: "",
      delay: 400,
      loading: false,
      client_names: [],
      windowError: false,
      windowErrorMessage: "",
      disableEditButton: true,
      activeOrdersFlg: false,

      primary_flg_checked: false,
      is_primary_flg_switchDisabled: false,
      active_dispatches_checked: false,
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      disableEditButton: false,
      showSecondAction: true,
      showFirstAction: true,
    });
  }

  async componentDidMount() {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    const vesselID = this.props.dataItem.id;
    let fil = {
      filter: {
        where: {
          vesselID: vesselID,
        },
        include: ["user"],
      },
    };

    let responseData = await serverApi(
      "GET",
      `userToVessels`,
      fil,
      "",
      options
    );

    let allClientRecords = {
      data: [],
    };

    let howMany = await serverApi(
      "GET",
      `snUsers/count`,
      {
        where: {
          and: [],
        },
      },
      "",
      options
    );
    const numberOfCalls = Math.min(
      Math.round(howMany.data.count / 1000) + 1,
      5 // Limit to 5000
    );

    const promises = [];
    for (let i = 0; i < numberOfCalls; i++) {
      promises.push(
        this.getClientPromise({
          skip: i * 1000,
          limit: 1000,
          order: ["id asc"],
          where: {
            and: [],
          },
          include: ["roles"],
        })
      );
    }

    try {
      let responses = await Promise.all(promises);
      responses.length && responses.map((x) => x.data).flat();

      responses.forEach((resp) => {
        _.forEach(
          resp.data.filter((x) => {
            const roles = x?.roles || [];
            return roles.some((role) => role.name === "client");
          }),
          (temp) => {
            allClientRecords.data.push({
              ...temp,
            });
          }
        );
      });
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent: e.response.data.error.message || "Please try again",
      });
    }

    let clientNames =
      allClientRecords.data
        .sort((a, b) => (a.username > b.username ? 1 : -1))
        .map((x) => x.username) || [];

    let result = this.constructUsersAndExistingUsersArray(
      allClientRecords.data,
      responseData.data
    );
    result[0].finalAttachedUsers.forEach((item) => {
      let user = result[0].finalUsers.find((x) => x.name === item.name);
      if (user) {
        user.checked = true;
      }
    });

    if (responseData && responseData.data.length > 1) {
      let finalData = [];
      _.forEach(responseData.data, (temp) => {
        finalData.push({
          id: temp.id,
          userID: temp.userID,
          vesselID: temp.vesselID,
          username: temp.user.username,
          deleteID: temp.id,
        });
      });
      finalData.sort((a, b) => (a.username > b.username ? 1 : -1));

      this.setState({
        ...this.state,
        userID: this.props.dataItem.id,
        gridData: {
          data: finalData,
          total: finalData.length,
        },
        initialGridData: {
          data: finalData,
          total: finalData.length,
        },
        clientNames: clientNames,
        valueUserName: "",

        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    } else if (responseData && responseData.data.length === 1) {
      let finalData = [];
      _.forEach(responseData.data, (temp) => {
        finalData.push({
          id: temp.id,
          userID: temp.userID,
          vesselID: temp.vesselID,
          username: temp.user.username,
          deleteID: temp.id,
        });
      });
      finalData.sort((a, b) => (a.username > b.username ? 1 : -1));

      this.setState({
        ...this.state,
        userID: this.props.dataItem.id,
        gridData: {
          data: [finalData[0]],
          total: 1,
        },
        initialGridData: {
          data: [finalData[0]],
          total: 1,
        },
        clientNames: clientNames,
        valueUserName: "",
        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    } else {
      this.setState({
        ...this.state,
        userID: this.props.dataItem.id,
        gridData: {
          data: [],
          total: 0,
        },
        initialGridData: {
          data: [],
          total: 0,
        },
        clientNames: clientNames,
        valueUserName: "",
        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    }
  }

  refreshGridDataAfterCrud = async () => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    const vesselID = this.props.dataItem.id;
    let fil = {
      filter: {
        where: {
          vesselID: vesselID,
        },
        include: ["user"],
      },
    };

    let responseData = await serverApi(
      "GET",
      `userToVessels`,
      fil,
      "",
      options
    );

    let allClientRecords = {
      data: [],
    };

    let howMany = await serverApi(
      "GET",
      `snUsers/count`,
      {
        where: {
          and: [],
        },
      },
      "",
      options
    );
    const numberOfCalls = Math.min(
      Math.round(howMany.data.count / 1000) + 1,
      5 // Limit to 5000
    );

    const promises = [];
    for (let i = 0; i < numberOfCalls; i++) {
      promises.push(
        this.getClientPromise({
          skip: i * 1000,
          limit: 1000,
          order: ["id asc"],
          where: {
            and: [],
          },
          include: ["roles"],
        })
      );
    }

    try {
      let responses = await Promise.all(promises);
      responses.length && responses.map((x) => x.data).flat();

      responses.forEach((resp) => {
        _.forEach(
          resp.data.filter((x) => {
            const roles = x?.roles || [];
            return roles.some((role) => role.name === "client");
          }),
          (temp) => {
            allClientRecords.data.push({
              ...temp,
            });
          }
        );
      });
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent: e.response.data.error.message || "Please try again",
      });
    }

    let clientNames =
      allClientRecords.data
        .sort((a, b) => (a.username > b.username ? 1 : -1))
        .map((x) => x.username) || [];

    let result = this.constructUsersAndExistingUsersArray(
      allClientRecords.data,
      responseData.data
    );
    result[0].finalAttachedUsers.forEach((item) => {
      let user = result[0].finalUsers.find((x) => x.name === item.name);
      if (user) {
        user.checked = true;
      }
    });

    if (responseData && responseData.data.length > 1) {
      let finalData = [];
      _.forEach(responseData.data, (temp) => {
        finalData.push({
          id: temp.id,
          userID: temp.userID,
          vesselID: temp.vesselID,
          username: temp.user.username,
          deleteID: temp.id,
        });
      });
      finalData.sort((a, b) => (a.username > b.username ? 1 : -1));

      this.setState({
        ...this.state,
        userID: this.props.dataItem.id,
        gridData: {
          data: finalData,
          total: finalData.length,
        },
        initialGridData: {
          data: finalData,
          total: finalData.length,
        },
        clientNames: clientNames,
        valueUserName: "",

        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    } else if (responseData && responseData.data.length === 1) {
      let finalData = [];
      _.forEach(responseData.data, (temp) => {
        finalData.push({
          id: temp.id,
          userID: temp.userID,
          vesselID: temp.vesselID,
          username: temp.user.username,
          deleteID: temp.id,
        });
      });
      finalData.sort((a, b) => (a.username > b.username ? 1 : -1));

      this.setState({
        ...this.state,
        userID: this.props.dataItem.id,
        gridData: {
          data: [finalData[0]],
          total: 1,
        },
        initialGridData: {
          data: [finalData[0]],
          total: 1,
        },
        clientNames: clientNames,
        valueUserName: "",
        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    } else {
      this.setState({
        ...this.state,
        userID: this.props.dataItem.id,
        gridData: {
          data: [],
          total: 0,
        },
        initialGridData: {
          data: [],
          total: 0,
        },
        clientNames: clientNames,
        valueUserName: "",
        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    }
  };

  constructUsersAndExistingUsersArray = (allClients, attachedUsers) => {
    let finalUsers = [];
    _.forEach(allClients, (temp1) => {
      finalUsers.push({
        name: temp1.username || "N/A",
        id: temp1.id,
        checked: false,
      });
    });

    let finalAttachedUsers = [];
    _.forEach(attachedUsers, (temp2) => {
      finalAttachedUsers.push({
        name: temp2.username || "N/A",
        vesselID: temp2.vesselID,
        checked: true,
        deleteID: temp2.id,
        id: temp2.id,
      });
    });

    finalUsers.sort((a, b) => (a.name > b.name ? 1 : -1));
    finalAttachedUsers.sort((a, b) => (a.name > b.name ? 1 : -1));

    return [
      {
        finalUsers: finalUsers,
        finalAttachedUsers: finalAttachedUsers,
      },
    ];
  };

  getClientPromise = (filter) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    return new Promise((resolve, reject) => {
      const req = serverApi(
        "GET",
        `snUsers?filter=${JSON.stringify(filter)}`,
        "",
        "",
        options
      );
      req
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  renderGridColumns = (dataItem, changeTheCellComponent, propakia) => {
    let selectionCol = [];
    let normalCols = [];
    let finalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            width="70px"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.isDropdown === "yes") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              cell={(props) => {
                let field = props.field;
                switch (field) {
                  case "primary_flg":
                    return (
                      <>
                        <PrimaryFlag
                          {...props}
                          width="120px"
                          localizedData={[
                            { text: "true", value: true },
                            { text: "false", value: false },
                          ]}
                        />
                      </>
                    );
                  default:
                    break;
                }
              }}
            />
          );
        } else {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={temp.field === "id" || temp.editable ? false : true} //id not editable on edit
            />
          );
        }
      });

    finalCols = [...selectionCol, ...normalCols];
    return finalCols;
  };

  handleRowClick = (event) => {
    if (!this.clickTimer) {
      // First click
      this.clickTimer = setTimeout(() => {
        // Single click action
        this.clickTimer = null; // Reset the timer
      }, 200); // Set an appropriate delay for detecting double-click
    } else {
      // Second click
      clearTimeout(this.clickTimer); // Cancel the single click action
      this.clickTimer = null; // Reset the timer
      // Double-click action

      // Perform any additional actions or logic
      const rowData = event.dataItem;
      const recordId = rowData.clientID; // Replace `id` with the actual property name containing the record ID

      // Construct the URL for the record's details page
      let final = "http://localhost:5002";
      let environmentLocation =
        window && window.location && window.location.hostname;
      if (environmentLocation === "dev2.swift-navigator.com") {
        final = "https://dev2.swift-navigator.com";
      } else if (environmentLocation === "uat2.swift-navigator.com") {
        final = "https://uat2.swift-navigator.com";
      } else if (environmentLocation === "prod2.swift-navigator.com") {
        final = "https://prod2.swift-navigator.com";
      }
      const recordUrl = `${final}/app/master/clients/${recordId}`;
      // Open the URL in a new browser tab
      window.open(recordUrl, "_blank");
    }
  };

  getUsersPromise = (filter) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    return new Promise((resolve, reject) => {
      const req = serverApi(
        "GET",
        `snUsers?filter=${JSON.stringify(filter)}`,
        "",
        "",
        options
      );
      req
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  renderList = (finalUsers = [], users = [], doRefresh) => {
    if (doRefresh === true) {
      window.location.reload();
    } else {
      if (finalUsers.length === users.length) {
        return (
          users &&
          users.map((item) => (
            <ul style={{ marginBottom: "-5px" }} key={item.id}>
              <Checkbox
                name={item.name}
                key={item.id}
                value={
                  this.state.gridData.data.some((x) => x.userID === item.id) ||
                  item.checked
                }
                onChange={(e) => this.handleChange(e, item.name, item.id)}
                label={item.name}
                disabled={this.state.disableAllCheckboxes}
              />
            </ul>
          ))
        );
      } else {
        return (
          finalUsers &&
          finalUsers.map((item) => (
            <ul style={{ marginBottom: "-5px" }} key={item.id}>
              <Checkbox
                name={item.name}
                key={item.id}
                value={
                  this.state.gridData.data.some((x) => x.userID === item.id) ||
                  item.checked
                }
                onChange={(e) => this.handleChange(e, item.name, item.id)}
                label={item.name}
                disabled={this.state.disableAllCheckboxes}
              />
            </ul>
          ))
        );
      }
    }
  };

  toggleWindow = (e) => {
    const data = this.state.initialGridData.data;

    this.setState({
      ...this.state,
      visibleWindow: !this.state.visibleWindow,
      gridData: {
        data: [...data],
        total: 100,
      },
      client_names: [],
      valueClientName: "",
      windowError: false,
      windowErrorMessage: "",
      openErrorModal: false,
      errorModalContent: "",
    });
  };

  handleSearch = (e) => {
    let initialFinalUserClients = this.state.initialFinalUserClients;

    if (e.value === "") {
      let searchArr = [];
      initialFinalUserClients.forEach((temp) => {
        if (temp.name.toUpperCase().includes(e.value.toUpperCase())) {
          searchArr.push(temp);
        }
      });

      if (
        this.state.clientAttachedToAnotherGroup !== null &&
        this.state.clientAttachedToAnotherGroup !== undefined
      ) {
        let newArr = searchArr.filter(
          (t) => t.id !== this.state.clientAttachedToAnotherGroup
        );
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalUserClients: newArr,
          openErrorModal: false,
          errorModalContent: "",
        });
      } else {
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalUserClients: initialFinalUserClients,
          openErrorModal: false,
          errorModalContent: "",
        });
      }
    } else {
      let searchArr = [];
      //case insensitive
      initialFinalUserClients.forEach((temp) => {
        if (temp && temp.name.toUpperCase().includes(e.value.toUpperCase())) {
          searchArr.push(temp);
        }
      });

      if (
        this.state.clientAttachedToAnotherGroup !== null &&
        this.state.clientAttachedToAnotherGroup !== undefined
      ) {
        let newArr = searchArr.filter(
          (t) => t.id !== this.state.clientAttachedToAnotherGroup
        );
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalUserClients: newArr,
          openErrorModal: false,
          errorModalContent: "",
        });
      } else {
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalUserClients: searchArr,
          openErrorModal: false,
          errorModalContent: "",
        });
      }
    }
  };

  handleChange = (event, name, key) => {
    let finalUserClient = [...this.state.finalUserClients];
    let finalObj = finalUserClient.find((test) => test.id === key);
    finalObj.checked = !finalObj.checked;

    const vesselID = this.props.dataItem.id;
    let data = [...this.state.gridData.data];

    this.setState(
      {
        ...this.state,
        finalUserClients: finalUserClient,
      },
      () => {
        let postOrDeleteClient = event.value === false ? "DELETE" : "POST";
        let options = {
          token: window.localStorage.getItem("access_token"),
        };

        if (postOrDeleteClient === "POST") {
          const url = `userToVessels`;

          const addUserToClient = serverApi(
            "POST",
            url,
            "",
            {
              vesselID: vesselID,
              userID: key,
            },
            options,
            "",
            ""
          );

          addUserToClient
            .then(async (response) => {
              this.refreshGridDataAfterCrud();
            })
            .catch((error) => {
              this.setState({
                ...this.state,
                openErrorModal: true,
                errorModalContent:
                  error?.response?.data?.error?.message ||
                  "Add user to vessel failed.Please try again.",
                blocking: false,
              });
            });
        } else if (postOrDeleteClient === "DELETE") {
          const deleteObj = data.find((obj) => obj.userID === key);
          const delete_id = deleteObj.deleteID;
          const url = `userToVessels/${delete_id}`;

          const removeUserFromClient = serverApi(
            "DELETE",
            url,
            "",
            "",
            options,
            "",
            ""
          );
          removeUserFromClient
            .then(async (response) => {
              this.refreshGridDataAfterCrud();
            })
            .catch((error) => {
              this.setState({
                ...this.state,
                openErrorModal: true,
                errorModalContent:
                  error?.response?.data?.error?.message ||
                  "Remove user from vessel the failed.Please try again.",
                blocking: false,
              });
            });
        }
      }
    );
  };

  remove = async (dataItem) => {
    this.setState({ ...this.state, visibleWindow: false });
    const data = [...this.state.gridData.data];

    let index = data.findIndex((p) => p === dataItem || p.id === dataItem.id);
    if (index >= 0) {
      data.splice(index, 1);
    }

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let delete_ID = dataItem.deleteID;
    let url = `userToVessels/${delete_ID}`;

    try {
      const removeUserFromClient = await serverApi(
        `DELETE`,
        url,
        "",
        "",
        options
      );
      if (removeUserFromClient.status === 200) {
        let findUser = this.state.visibleClients.find(
          (x) => x.id === dataItem.id
        );
        if (findUser) {
          findUser.checked = false;
        }
        this.setState({
          ...this.state,
          gridData: {
            data: data,
            total: this.state.gridData.total,
          },
          initialGridData: {
            data: data,
            total: this.state.gridData.total,
          },
        });
      }
    } catch (error) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent:
          error?.response?.data?.error?.message ||
          "Remove user from vessel failed.Please try again.",
      });
    }
  };

  render() {
    const dataItem = this.props.dataItem;
    const { gridData } = this.state;
    const { hasBackofficeRead } = this.props;
    const hasEditedItem = gridData && gridData.data.some((p) => p.inEdit);

    return (
      <div style={{ width: "1500px" }}>
        <div>
          {this.state.openErrorModal ? (
            <Error style={{ color: "red" }}>
              <h3>{this.state.errorModalContent || ""}</h3>
            </Error>
          ) : null}
          {this.state.windowError ? (
            <Error style={{ color: "red", fontSize: "15px" }}>
              {this.state.windowErrorMessage || ""}
            </Error>
          ) : null}
          <Grid
            {...gridData}
            onRowClick={this.handleRowClick}
            filterable={false}
            style={{ className: "grid-no-select" }}
            sortable={true}
            resizable
            editField={this.editField}
            onItemChange={this.itemChange}
            pageable={false}
          >
            <GridToolbar>
              <GridContainer xs={12} justify={"flex-start"} direction={"row"}>
                {!hasBackofficeRead && (
                  <Tooltip
                    justify={"flex-start"}
                    placement="top"
                    title={"Add/Remove User"}
                  >
                    <IconButton onClick={this.toggleWindow}>
                      <FontAwesomeIcon
                        color="#0D5869"
                        size="1.6x"
                        icon={faPencilAlt}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </GridContainer>
            </GridToolbar>

            {!hasBackofficeRead && (
              <Column
                cell={this.CommandCell}
                title="Actions"
                filterable={false}
                width={hasEditedItem ? "250px" : "220px"}
              />
            )}

            {this.renderGridColumns(dataItem, "", this.props)}
          </Grid>
        </div>

        {this.state.visibleWindow && (
          <Window
            title={`Add/Remove Users from Vessel: ${this.props.dataItem.vessel_name}`}
            // style={{backgroundColor:'#0d5869'}}
            onClose={this.toggleWindow}
            width={900}
            height={600}
            initialTop={110}
          >
            <div>
              <>
                <BlockUi
                  tag="div"
                  blocking={this.state.blocking}
                  message="Loading, please wait. . ."
                  renderChildren={true}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "50px",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {this.state.openErrorModal ? (
                        <Error style={{ color: "red", fontSize: "15px" }}>
                          {this.state.errorModalContent || ""}
                        </Error>
                      ) : null}
                      <div
                        style={{
                          display: "flex",
                          marginLeft: "35px",
                          marginTop: "20px",
                        }}
                      >
                        <Input
                          name="users"
                          value={this.state.inputValue}
                          style={{ marginBottom: "20px" }}
                          label="Search user"
                          onChange={(e) => this.handleSearch(e)}
                          minLength={2}
                        />
                      </div>

                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {this.renderList(
                          this.state.finalUserClients,
                          this.state.visibleClients
                        )}
                      </div>
                    </div>
                  </div>
                </BlockUi>
              </>
              {this.state.windowError ? (
                <Error style={{ color: "red" }}>
                  <h3>{this.state.windowErrorMessage || ""}</h3>
                </Error>
              ) : null}
            </div>
          </Window>
        )}
      </div>
    );
  }
}

export default UserToVessel;
