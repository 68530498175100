export const columnsSchema = () => [
  {
    field: "id",
    title: "ID",
    visible: true,
    minWidth: 180,
    sortable: true,
    filterable: true,
    locked: "locked",
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
    // cell: true
  },
  {
    field: "contact_name",
    title: "Name",
    visible: true,
    minWidth: 250,
    sortable: true,
    filterable: true,
    noDisabledColumn: false,
    notShownInTableColumnSelection: false,
    locked: "locked",
    isDropdown: "false",
  },
  {
    field: "contact_type",
    title: "Type",
    visible: true,
    minWidth: 290,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    isDropdown: "false",
    editable: "false",
  },
  {
    field: "is_active",
    title: "ACTIVE",
    visible: true,
    minWidth: 220,
    sortable: true,
    filterable: true,
    isDropdown: "yes",
    // cell: true
  },
  {
    field: "contact_city",
    title: "City",
    visible: true,
    minWidth: 220,
    sortable: true,
    filterable: true,
    isDropdown: "false",
  },
  {
    field: "contact_country",
    title: "Country",
    visible: true,
    minWidth: 240,
    sortable: true,
    filterable: true,
    locked: "locked",
    notShownInTableColumnSelection: false,
    isDropdown: "false",
  },
  {
    field: "contact_state_code",
    title: "Contact State Code",
    visible: true,
    minWidth: 200,
    sortable: true,
    filterable: true,
    locked: false,
    notShownInTableColumnSelection: false,
  },
  {
    field: "contact_postal_code",
    title: "Postal Code",
    visible: true,
    sortable: true,
    minWidth: 240,
    filterable: true,
    isDropdown: "false",
  },
  {
    field: "contact_address",
    title: "Address",
    visible: true,
    sortable: true,
    minWidth: 300,
    filterable: true,
    isDropdown: "false",
  },
  {
    field: "contact_phone",
    title: "Phone",
    visible: true,
    sortable: true,
    minWidth: 250,
    filterable: true,
    isDropdown: "false",
  },
  {
    field: "contact_email",
    title: "Email",
    visible: true,
    sortable: true,
    minWidth: 300,
    filterable: true,
    isDropdown: "false",
  },
  {
    field: "website_url",
    title: "Website url",
    visible: true,
    sortable: true,
    minWidth: 300,
    filterable: true,
    isDropdown: "false",
  },
  {
    field: "ein_number",
    title: "EIN Number",
    visible: true,
    minWidth: 250,
    sortable: true,
    filterable: true,
    notShownInTableColumnSelection: false,
  },
  {
    field: "ss_number",
    title: "SS Number",
    visible: true,
    minWidth: 250,
    sortable: true,
    filterable: true,
    notShownInTableColumnSelection: false,
  },
  {
    field: "tsa_number",
    title: "TSA Number",
    visible: true,
    minWidth: 250,
    sortable: true,
    filterable: true,
    notShownInTableColumnSelection: false,
  },
  {
    field: "duns",
    title: "Duns",
    visible: true,
    minWidth: 200,
    sortable: true,
    filterable: true,
    notShownInTableColumnSelection: false,
  },
  {
    field: "company_name",
    title: "Company Name",
    visible: true,
    minWidth: 200,
    sortable: true,
    filterable: true,
    notShownInTableColumnSelection: false,
  },

  {
    field: "contact_vat_number",
    title: "Contact VAT Number",
    visible: true,
    minWidth: 200,
    sortable: true,
    filterable: true,
    notShownInTableColumnSelection: false,
  },
  // {
  //   field: "createdByForUi",
  //   title: "Created By",
  //   visible: true,
  //   minWidth: 200,
  //   sortable: false,
  //   filterable: false,
  //   locked: false,
  //   notShownInTableColumnSelection: false,
  //   editable: "false",
  // },
  // {
  //   field: "dateCreatedForUi",
  //   title: "Created Date",
  //   visible: true,
  //   minWidth: 200,
  //   sortable: false,
  //   filterable: false,
  //   locked: false,
  //   notShownInTableColumnSelection: false,
  //   editable: "false",
  // },
];
