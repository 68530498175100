import {connect} from 'react-redux';
import compose from 'ramda/src/compose';
// import {crmPageActions} from './state';
import LogBox from "./View/index";
// import {dhlPageActions} from "../DHL_Wizard/state";

// const mapActionCreators = {...crmPageActions, ...{fetchInitialOrderData: dhlPageActions.fetchInitialOrderData}}

const mapStateToProps = (state) => {
    return {
        ...state
    }
}

export default compose(
    connect(mapStateToProps, null))
(LogBox)

