import React from "react";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { GridLoader } from "./loader";
import { AbstractEdit } from "../../../networking/apiCalls";
import _ from "lodash";
import { MyCommandCell } from "../../common/commandCellHOC";
import { serverApi } from "../../../networking/config";
import {
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
} from "@material-ui/core";
import customSelectStyles from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import ErrorModal from "../../../components/ErrorModal";
import { Window } from "@progress/kendo-react-dialogs";
import { filterBy } from "@progress/kendo-data-query";
import GridContainer from "../../../components/Grid/GridContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser, faPlus } from "@fortawesome/free-solid-svg-icons";
import Snackbar from "../../../components/Snackbar/Snackbar";
import { columnsSchema } from "./schema";
import { DropDownCell } from "../../CRM/View/dropDownCell";
import { Popup } from "@progress/kendo-react-popup";
import { Menu } from "@progress/kendo-react-layout";
import { verifyEmail } from "lib/general/utils";
import ViewSettings from "../../common/ViewSettings";
import { MenuItem as KendoMenuItem } from "@progress/kendo-react-layout";
import Button from "../../../components/CustomButtons/Button";
import SuccessModal from "../../../components/SuccessModal";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import ChangePassword from "./components/ChangePassword";
import { ChangePasswordCall } from "../../../networking/apiCalls/ChangePasswordCall";
import { formatDatesOnTable } from "../../../lib/GeneralUtils/utils";

class WarehouseView extends React.Component {
  editField = "inEdit";
  CommandCell;

  constructor(props) {
    super(props);

    this.state = {
      visibleWindow: false,
      resetPasswordWindow: false,
      terminateUserWindow: false,
      changePasswordWindow: false,
      setCustomPasswordWindow: false,
      isDropOpened: false,
      columns: columnsSchema && columnsSchema(),
      gridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 100,
        skip: 0,
      },
      openErrorModal: false,
      errorModalContent: "",
      visible: false,
      expandedRowIndex: null,
      selectedRowIndex: 0,
      selectedItem: null,
      delay: 400,
      loading: false,
      open: false,
      emailHasError: false,
      username: "",
      userRealName: "",
      email: "",

      //
      selectedDepartments: [],
      departmentsAvailable: [],

      disableButton: false,
      typeOfUser: "Warehouse",
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      hasDisable: true,
    });
  }

  componentDidMount() {
    let isFirstLoad = true;
    window.localStorage.setItem("isFirstLoad", isFirstLoad);

    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));
    let hasFlagEnabled = window.localStorage.getItem("flagSet");
    if (hasFinalParams) {
      this.setState({
        ...this.state,
        dataState: hasFinalParams,
      });
    }
    if (hasChangedColumns) {
      this.setState({
        ...this.state,
        columns: hasChangedColumns,
      });
    }
    if (hasFlagEnabled) {
      window.localStorage.removeItem("flagSet");
    }

    this.setState({
      ...this.state,
      typeOfUser: "Warehouse",
    });
  }

  componentWillUnmount() {
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));
    if (hasChangedColumns) {
      window.localStorage.removeItem("cols");
    }
    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    if (hasFinalParams) {
      window.localStorage.removeItem("finalParams");
    }

    let hasFlagEnabled = window.localStorage.getItem("flagSet");
    if (hasFlagEnabled) {
      window.localStorage.removeItem("flagSet");
    }

    let hasExportFilter = window.localStorage.getItem("exportFilter");
    if (hasExportFilter) {
      window.localStorage.removeItem("exportFilter");
    }

    let hasResetMail = window.localStorage.getItem("resetMail");
    if (hasResetMail) {
      window.localStorage.removeItem("resetMail");
    }
    let hasUsername = window.localStorage.getItem("username");
    if (hasUsername) {
      window.localStorage.removeItem("username");
    }
  }

  renderGridColumns = () => {
    let selectionCol = [];
    let normalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            width="65px"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.isFilterBoolean === "yes") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              cell={DropDownCell}
            />
          );
        } else {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={temp.field === "id" || temp.editable ? false : true}
              // cell={(props) => {
              //  return (
              //    <td>
              //      <a rel="noopener noreferrer" href="https://p.2hog.codes/" target="_blank">Link here</a>
              //    </td>
              //  )
              // }}
            />
          );
        }
      });

    return [...selectionCol, ...normalCols];
  };

  filterChange = (event) => {
    let filteredResults = this.filterData(event.filter);

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.setState({
        countries:
          filteredResults !== undefined
            ? filteredResults
            : this.state.initialCountries,
        loading: false,
      });
    }, this.state.delay);

    this.setState({
      loading: true,
    });
  };

  filterData(filter) {
    if (filter.value === "") {
      this.setState({
        countries: this.state.initialCountries,
      });

      return;
    } else {
      const data = this.state.initialCountries;
      filter.operator = "startswith";
      let filteredResults = filterBy(data, filter);

      return filteredResults;
    }
  }

  toggleWindow = async (e) => {
    let tempDepartments = [];
    const options = { token: window.localStorage.getItem("access_token") };
    const getAllDepsRequest = serverApi(
      "GET",
      `departments`,
      "",
      "",
      options,
      ""
    );
    const allRoles = await serverApi("GET", "roles", "", "", options);
    let finalRoles = [];
    _.forEach(allRoles.data, (temp1) => {
      finalRoles.push({
        name: temp1.name || "N/A",
        id: temp1.id,
      });
    });

    finalRoles = finalRoles.sort((a, b) => (a.name > b.name ? 1 : -1));
    //remove 161,121 and 61 ids (warehouse,warehouseCensus agent roles)
    finalRoles = finalRoles.filter(
      (x) => x.id !== 121 && x.id !== 161 && x.id !== 61
    );

    getAllDepsRequest
      .then((response2) => {
        let allDepartmentsArr = response2?.data || [];
        let opsDepts = allDepartmentsArr.filter((x) => x.type === "WAREHOUSE");
        opsDepts.forEach((temp) => {
          tempDepartments.push({
            name: `${temp.name} [${temp?.location}]`,
            deptID: temp.id,
          });
        });
        let departmentsArr = tempDepartments;
        this.setState({
          ...this.state,
          departmentsAvailable: tempDepartments.map((x) => x.name),
          departmentsArr: departmentsArr,
          allRoles: finalRoles.map((x) => x.name),
          blocking: false,
          visibleWindow: !this.state.visibleWindow,
        });
      })
      .catch((e) => {
        this.setState(
          {
            ...this.state,
            blocking: false,
            openErrorModal: true,
            errorModalContent:
              e?.response?.data?.error?.message || "Please try again",
          },
          () => {
            setTimeout(() => {
              this.handleErrorClose();
            }, 4000);
          }
        );
      });
  };

  openResetPasswordWindow = () => {
    this.setState({
      ...this.state,
      resetPasswordWindow: true,
    });
  };

  closeResetPasswordWindow = () => {
    this.setState({
      ...this.state,
      resetPasswordWindow: false,
      openDataItem: {},
    });
  };

  openTerminateWindow = () => {
    this.setState({
      ...this.state,
      terminateUserWindow: true,
    });
  };

  closeTerminateWindow = () => {
    this.setState({
      ...this.state,
      terminateUserWindow: false,
      openDataItem: {},
    });
  };

  closeChangePasswordWindow = () => {
    this.setState({
      ...this.state,
      changePasswordWindow: false,
      openDataItem: {},
    });
  };

  openChangePasswordWindow = () => {
    this.setState({
      ...this.state,
      changePasswordWindow: true,
    });
  };

  closeSetCustomPasswordWindow = () => {
    this.setState({
      ...this.state,
      setCustomPasswordWindow: false,
      changePasswordWindow: false,
      openDataItem: {},
    });
  };

  openSetCustomPasswordWindow = () => {
    this.setState({
      ...this.state,
      setCustomPasswordWindow: true,
      changePasswordWindow: false,
    });
  };

  enterEdit = (dataItem) => {
    let total = this.state.gridData.total;

    this.setState({
      gridData: {
        data:
          this.state.gridData &&
          this.state.gridData.data.map((item) =>
            item.id === dataItem.id ? { ...item, inEdit: true } : item
          ),
        total: total,
      },
      initialGridData: {
        data:
          this.state.initialGridData &&
          this.state.initialGridData.data.map((item) =>
            item.id === dataItem.id ? { ...item, inEdit: true } : item
          ),
        total: total,
      },
    });
  };

  remove = async (dataItem) => {};

  add = (dataItem) => {};

  discard = (dataItem, hasError = false) => {
    let data = [...this.state.gridData.data];
    const total = this.state.gridData.total;

    if (hasError) {
      let index = data.findIndex((p) => p.id === dataItem.id);

      data[index].inEdit = false;

      this.setState(
        {
          ...this.state,
          gridData: {
            data: data,
            total: total,
          },
        },
        () => {
          setTimeout(() => {
            this.setState({
              ...this.state,
              openErrorModal: false,
              errorModalContent: "",
            });
          }, 3000);
        }
      );
    } else {
      this.removeItem(data, dataItem);

      this.setState({
        ...this.state,
        gridData: {
          data: data,
          total: total,
        },
        visible: false,
      });
    }
  };

  update = async (dataItem) => {
    const data = [...this.state.gridData.data];
    const updatedItem = { ...dataItem, inEdit: undefined };

    this.updateItem(data, updatedItem);

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let editPayload = {};
    editPayload.username = dataItem.username;
    editPayload.userRealName = dataItem.userRealName;
    editPayload.email = dataItem.email;
    editPayload.lastUpdated = new Date();

    if (
      editPayload.username === "" ||
      editPayload.username === undefined ||
      editPayload.userRealName === "" ||
      editPayload.userRealName === undefined ||
      editPayload.email === "" ||
      editPayload.email === undefined
    ) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            "Error: Required fields to edit the warehouse user: Username,Name and Email.",
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 3500);
        }
      );
    } else {
      try {
        let count;
        const editRequest = await AbstractEdit(
          dataItem.id,
          editPayload,
          "snUsers",
          options
        );
        const countUsers = await serverApi(
          "GET",
          `snuserWarehouseVs/count`,
          "",
          "",
          options
        );
        if (countUsers) {
          count = countUsers.data.count;
        }

        if (editRequest.status === 200 && countUsers) {
          let params = {
            filter: {
              limit: `100`,
              skip: `0`,
            },
          };
          const url = "snuserWarehouseVs";
          let responseData = await serverApi(
            "GET",
            `${url}`,
            params,
            "",
            options
          );

          this.setState({
            ...this.state,
            dataState: {
              take: 100,
              skip: 0,
            },
            gridData: {
              data: responseData.data,
              total: count,
            },
            initialGridData: {
              data: responseData.data,
              total: count,
            },
          });
        }
      } catch (e) {
        let initialGridData = this.state.initialGridData;
        this.setState(
          {
            ...this.state,
            gridData: initialGridData,
            openErrorModal: true,
            errorModalContent:
              e.response.data.error.message || "Please try again",
          },
          () => this.discard(dataItem, true)
        );
      }
    }
  };

  cancel = (dataItem = {}) => {
    const originalItem =
      this.state.initialGridData &&
      this.state.initialGridData.data.find((p) => p.id === dataItem.id);
    originalItem.inEdit = false;

    const data =
      this.state.initialGridData &&
      this.state.initialGridData.data.map((item) =>
        item.id === originalItem.id ? originalItem : item
      );

    const total = this.state.initialGridData.total;

    this.setState({
      ...this.state,
      gridData: {
        data: data,
        total: total,
      },
    });
  };

  updateItem = (data, item) => {
    let index = data.findIndex(
      (p) => p === item || (item.id && p.id === item.id)
    );
    if (index >= 0) {
      data[index] = { ...item };
    }
  };

  itemChange = (event) => {
    const total = this.state.gridData.total;
    const data =
      this.state.gridData &&
      this.state.gridData.data.map((item) =>
        item.id === event.dataItem.id
          ? { ...item, [event.field]: event.value }
          : item
      );

    this.setState({
      gridData: {
        data: data,
        total: total,
      },
    });
  };

  addNew = () => {
    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    const newDataItem = { inEdit: true };

    this.setState({
      gridData: {
        data: [newDataItem, ...this.state.gridData.data],
        total: this.state.gridData.total,
      },
      visible: true,
    });
  };

  removeItem(data, item) {
    let index = data.findIndex((p) => p === item || p.id === item.id);
    if (index >= 0) {
      data.splice(index, 1);
    }
  }

  dataStateChange = (e) => {
    const reqGridParams = e.data;
    window.localStorage.setItem("finalParams", JSON.stringify(reqGridParams));

    this.setState({
      ...this.state,
      dataState: reqGridParams,
    });
  };

  dataRecieved = (gridData, reqGridParams) => {
    const finalData = formatDatesOnTable(gridData, [
      "last_login_date",
      "last_password_change",
      "created",
      "lastUpdated",
      "termination_date",
    ]);

    this.setState({
      ...this.state,
      gridData: finalData,
      initialGridData: finalData,
      dataState:
        reqGridParams === !_.isEmpty(reqGridParams)
          ? reqGridParams
          : this.state.dataState, //TODO MAYBE PASS THE final params from local storage for persist the filters on REFRESH PAGE !
    });
  };

  onClearFilters = () => {
    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));

    let init = JSON.stringify({ take: 100, skip: 0 });
    if (hasFinalParams && hasFinalParams.filter)
      window.localStorage.setItem("finalParams", init);

    this.dataStateChange({ data: { take: 100, skip: 0 } });
  };

  onDialogInputChange = (event) => {
    let target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.props ? target.props.name : target.name;

    if (name === "email") {
      let isEmailOk = verifyEmail(value);

      if (isEmailOk !== false) {
        this.setState({
          ...this.state,
          gridData: {
            ...this.state.gridData,
            [name]: value,
          },
          [name]: value,
          emailHasError: false,
        });
      } else {
        this.setState({
          ...this.state,
          gridData: {
            ...this.state.gridData,
            [name]: value,
          },
          [name]: value,
          emailHasError: true,
        });
      }
    }

    if (name !== "email") {
      this.setState({
        ...this.state,
        gridData: {
          ...this.state.gridData,
          [name]: value,
        },
        [name]: value,
      });
    }
  };

  handleErrorClose = (loginError) => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      openErrorModalContent: "",
    });
  };

  rowClick = (event) => {
    // let id = this.state.openDataItem.id
    // this.redirectToEdiUserDepartments(id)
  };

  redirectToEdiUserDepartments = (id) => {
    this.props.history.push(`/app/master/departments/edit/${id}`);
  };

  handleContextMenuOpen = (e, dataItem) => {
    this.dataItem = dataItem;
    this.dataItemIndex = this.state.gridData.data.findIndex(
      (p) => p.id === this.dataItem.id
    );
    this.offset = { left: e.clientX, top: e.clientY };

    if (_.isEmpty(this.state.openDataItem)) {
      //todo handle two popup opened
      this.setState({
        open: true,
        openDataItem: dataItem,
      });
    } else {
      return;
    }
  };

  rowRender = (trElement, dataItem) => {
    const trProps = {
      ...trElement.props,
      onContextMenu: (e) => {
        e.preventDefault();

        const { status } = dataItem.dataItem;
        if (status === "TERMINATED") {
          this.setState(
            {
              ...this.state,
              rightClickText: ["Enable warehouse"],
            },
            () => {}
          );
        } else if (status === "ACTIVE") {
          this.setState(
            {
              ...this.state,
              rightClickText: [
                "Edit Departments",
                "Change password",
                "Reset password",
                "Terminate warehouse",
              ],
            },
            () => {}
          );
        } else {
          return;
        }

        this.handleContextMenuOpen(e, dataItem.dataItem);
      },
      style: {
        // color: 'orange'
      },
    };

    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  refreshGridDataAfterCrud = async () => {
    let token = window.localStorage.getItem("access_token");
    let options = {
      token: token,
    };

    let refreshParams = window.localStorage.getItem("refreshParams");
    refreshParams = JSON.parse(refreshParams);

    const responseData = await serverApi(
      "GET",
      "snuserWarehouseVs",
      refreshParams,
      "",
      options
    );

    let count;
    const countMasterEntity = await serverApi(
      "GET",
      `snuserWarehouseVs/count?where=${JSON.stringify(
        refreshParams.filter.where
      )}`,
      "",
      "",
      options
    );
    if (countMasterEntity) {
      count = countMasterEntity.data.count;
    }

    let sendObj = {
      gridData: {
        data: responseData.data,
        total: count,
      },
    };

    this.dataRecieved(sendObj.gridData, null);
  };

  enableUser = async () => {
    const options = { token: window.localStorage.getItem("access_token") };
    let payload = {
      username: this.state.openDataItem.username,
      status: "ACTIVE",
    };

    try {
      const enable = await serverApi(
        "POST",
        "snUsers/enableUser",
        "",
        payload,
        options
      );
      if (enable) {
        this.setState(
          {
            ...this.state,
            terminateUserWindow: false,
            openDataItem: {},
            openSuccessModal: true,
            successModalContent: `Enable user process has finished successfully.`,
          },
          () => {
            setTimeout(() => {
              this.refreshGridDataAfterCrud();
            }, 3000);
          }
        );
      }
    } catch (e) {
      this.setState({
        ...this.state,
        terminateUserWindow: false,
        openDataItem: {},
        openErrorModalMui: true,
        errorModalContentMui:
          e?.response?.data?.error?.message ||
          "Enable of user process has failed.Please try again.",
      });
    }
  };

  handleOnSelect = (e) => {
    let id = this.state.openDataItem.id;
    let email = this.state.openDataItem.email;
    let username = this.state.openDataItem.username;

    switch (e.item.text) {
      case "Enable warehouse":
        this.enableUser(id, username);
        break;
      case "Edit Departments":
        this.redirectToEdiUserDepartments(id);
        break;
      case "Change password":
        this.goToChangePasswordPage(id, email, username);
        break;
      case "Reset password":
        this.goToResetPasswordPage(email, username);
        break;
      case "Terminate warehouse":
        this.goToTerminateUserPage(id, username);
        break;
      default:
        break;
    }

    this.setState({
      open: false,
    });
  };

  goToChangePasswordPage = (id, email, username) => {
    this.openChangePasswordWindow();
  };

  goToResetPasswordPage = (email, username) => {
    this.openResetPasswordWindow();
  };

  goToTerminateUserPage = (userID, username) => {
    this.openTerminateWindow();
  };

  onFocusHandler = () => {
    clearTimeout(this.blurTimeoutRef);
    this.blurTimeoutRef = undefined;
  };

  onBlurTimeout = () => {
    this.setState({
      open: false,
      openDataItem: {},
    });

    this.blurTimeoutRef = undefined;
  };

  onBlurHandler = (event) => {
    clearTimeout(this.blurTimeoutRef);
    this.blurTimeoutRef = setTimeout(this.onBlurTimeout);
  };

  onPopupOpen = () => {
    this.menuWrapperRef.querySelector("[tabindex]").focus();
  };

  createUser = async () => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let finalDepartments = [];
    _.forEach(this.state.selectedDepartments, (selectedDept) => {
      _.forEach(this.state.departmentsArr, (dept) => {
        if (selectedDept === dept.name) {
          finalDepartments.push(dept);
        }
      });
    });

    let payload = {
      username: this.state.username,
      email: this.state.email,
      userRealName: this.state.userRealName,
      departments: finalDepartments,
      uiRoles: this.state.selectedRoles,
    };

    try {
      const create = await serverApi(
        "POST",
        "snUsers/createWarehouse",
        "",
        payload,
        options
      );
      if (create) {
        let count;
        let params = {
          filter: {
            limit: `100`,
            skip: `0`,
          },
        };
        const url = "snuserWarehouseVs";
        let responseData = await serverApi(
          "GET",
          `${url}`,
          params,
          "",
          options
        );
        const countMasterEntity = await serverApi(
          "GET",
          `snuserWarehouseVs/count`,
          "",
          "",
          options
        );
        if (countMasterEntity) {
          count = countMasterEntity.data.count;
        }

        this.setState({
          ...this.state,
          gridData: {
            data: responseData.data,
            total: count,
          },
          initialGridData: {
            data: responseData.data,
            total: count,
          },
          selectedDepartments: [],
          departmentsAvailable: [],
          visibleWindow: false,
          openSuccessModal: true,
          successModalContent: `Create warehouse user process finished successfully.`,
          selectedRoles: [],
        });
      }
    } catch (e) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            e?.response?.data?.error?.message || "Please try again",
        },
        () => {
          setTimeout(() => {
            this.setState({
              ...this.state,
              openErrorModal: false,
              errorModalContent: "",
            });
          }, 5000);
        }
      );
    }
  };

  handleColumnsChange = (event) => {
    let columns = this.state.columns;

    let oldColumns = columns.filter((c) => c.visible).map((c) => c.field);
    let newColumns = event.target.value;

    let unique1 = oldColumns.filter((c) => newColumns.indexOf(c) === -1);
    let unique2 = newColumns.filter((c) => oldColumns.indexOf(c) === -1);

    const columnLastUpdated = unique1.concat(unique2)[0];

    let index = columns.findIndex(
      (column) => column.field === columnLastUpdated
    );
    columns[index].visible = !columns[index].visible;

    this.setState(
      {
        ...this.state,
        columns: columns,
      },
      () => {
        let columns = this.state.columns;
        window.localStorage.setItem("cols", JSON.stringify(columns));
      }
    );
  };

  getExportData = async () => {
    await this.exportToCsv(this.state, this.props);
  };

  getPromise = (filter) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    return new Promise((resolve, reject) => {
      const Request = serverApi(
        "GET",
        `snuserWarehouseVs?filter=${JSON.stringify(filter)}`,
        "",
        "",
        options
      );
      Request.then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  };

  exportToCsv = async (state, props) => {
    const count = state.gridData.total;
    const where = window.localStorage.getItem("exportFilter") || {};
    const numberOfCalls = Math.min(
      Math.round(count / 1000) + 1,
      5 // Limit to 5000
    );
    let finalSort = this.state.dataState?.sort?.length
      ? this.state.dataState.sort[0].field +
        " " +
        this.state.dataState.sort[0].dir
      : "id asc";

    const promises = [];
    for (let i = 0; i < numberOfCalls; i++) {
      promises.push(
        this.getPromise({
          skip: i * 1000,
          limit: 1000,
          where: !_.isEmpty(where) ? JSON.parse(where) : {},
          order: [finalSort],
        })
      );
    }

    try {
      const responses = await Promise.all(promises);
      let allData = [];
      responses.forEach((resp) => {
        allData = [...allData, ...resp.data];
      });
      if (allData.length > 0) {
        this.setState({
          ...this.state,
          exportData: allData,
        });
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent: e.response.data.error.message || "Please try again",
      });
    }
  };

  handleSuccessSnackbar = () => {
    this.setState({
      ...this.state,
      successMessage:
        this.state.openSuccessMessage === true ? this.state.successMessage : "",
      openSuccessMessage: !this.state.openSuccessMessage,
    });
  };

  closeSuccessModal = () => {
    this.setState({ openSuccessModal: false, successModalContent: "" });
  };

  sendResetPassword = async () => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    let resetEmail =
      `${this.state.openDataItem.email}` ||
      window.localStorage.getItem("resetMail");
    if (resetEmail) {
      try {
        let resetRequest = await serverApi(
          "POST",
          `snUsers/reset`,
          "",
          {
            email: resetEmail,
          },
          options
        );

        if (resetRequest) {
          //todo show a proper message
          this.setState(
            {
              ...this.state,
              openDataItem: {},
              resetPasswordWindow: false,
              openSuccessModal: true,
              successModalContent: `The reset password link was successfully sent to the warehouse user.`,
            },
            () => {
              setTimeout(() => {
                window.localStorage.removeItem("resetMail");
              }, 3000);
            }
          );
        }
      } catch (e) {
        this.setState({
          ...this.state,
          openDataItem: {},
          resetPasswordWindow: false,
          openErrorModalMui: true,
          errorModalContentMui:
            e.response.data.error.message ||
            "Reset password process failed.Please try again",
        });
      }
    }
  };

  closeErrorModalMui = () => {
    this.setState({ openErrorModalMui: false, errorModalContentMui: "" });
  };

  handleDeptsChange = (event) => {
    this.setState({
      ...this.state,
      selectedDepartments: event.target.value,
    });
  };

  closeWindow = () => {
    this.setState({
      ...this.state,
      visibleWindow: false,
      emailHasError: false,
      selectedDepartments: [],
    });
  };

  terminateUser = async () => {
    const options = { token: window.localStorage.getItem("access_token") };
    let payload = {
      username: this.state.openDataItem.username,
    };

    try {
      const terminateRequest = await serverApi(
        "POST",
        "snUsers/terminateWarehouse",
        "",
        payload,
        options
      );
      if (terminateRequest) {
        this.setState(
          {
            ...this.state,
            terminateUserWindow: false,
            openDataItem: {},
            openSuccessModal: true,
            successModalContent: `Terminate warehouse user process finished successfully.`,
          },
          () => {
            setTimeout(() => {
              this.refreshGridDataAfterCrud();
            }, 3000);
          }
        );
      }
    } catch (e) {
      this.setState({
        ...this.state,
        terminateUserWindow: false,
        openDataItem: {},
        openErrorModalMui: true,
        errorModalContentMui:
          e?.response?.data?.error?.message ||
          "Terminate of warehouse user process failed.Please try again",
      });
    }
  };

  handleRolesChange = (event) => {
    this.setState({
      ...this.state,
      selectedRoles: event.target.value,
    });
  };

  renderCreateWarehouseForm = () => {
    return (
      <div>
        <fieldset style={{ borderColor: "rgba(13, 88, 105, 1)" }}>
          {/*<legend></legend>*/}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <h3>Real Name</h3>
              <Input
                style={{ width: "500px" }}
                name="userRealName"
                value={
                  this.state.gridData.data.filter(
                    (temp) => temp.inEdit === true
                  ).userRealName
                }
                onChange={this.onDialogInputChange}
                placeholder="e.g.: Firstname Lastname"
              />
            </span>
            <span>
              <h3>Username</h3>
              <Input
                style={{ width: "500px" }}
                name="username"
                value={
                  this.state.gridData.data.filter(
                    (temp) => temp.inEdit === true
                  ).username
                }
                onChange={this.onDialogInputChange}
                placeholder="e.g.: f.lastname (please use the real name to provide a username)"
              />
              {/*<Hint id={'usernameHint'}>e.g.: f.lastname (the first letter of the firstname , then '.' and final the lastname</Hint>*/}
            </span>
            <span>
              <h3>Email</h3>
              <Input
                style={{ width: "500px" }}
                name="email"
                value={
                  this.state.gridData.data.filter(
                    (temp) => temp.inEdit === true
                  ).email
                }
                onChange={this.onDialogInputChange}
                placeholder="e.g.: example@mail.com"
              />
              {
                <div>
                  <h3 style={{ color: "#ff9d00" }}>
                    {this.state.emailHasError === true
                      ? "Please enter a valid email address such as : example@mail.com"
                      : ""}
                  </h3>
                </div>
              }
            </span>
            <span>
              <h3>Department</h3>
              <MultiSelect
                data={this.state.departmentsAvailable || []}
                value={this.state.selectedDepartments}
                onChange={this.handleDeptsChange}
                placeholder="Select at least one department from the dropdown."
              />
            </span>
            <span>
              <h3>Roles</h3>
              <MultiSelect
                data={this.state.allRoles || []}
                value={this.state.selectedRoles}
                onChange={this.handleRolesChange}
                placeholder="Select role from the dropdown.(optional)"
                header={
                  <span
                    style={{
                      marginLeft: "60px",
                      color: "orange",
                      fontSize: "20px",
                    }}
                  >
                    Warehouse,Warehouse Census and Agent roles are preselected
                    and cannot be removed.
                  </span>
                }
              />
            </span>
          </div>
          <br />
          <button
            type="button"
            className="k-button k-primary"
            onClick={(e) => this.createUser(e)}
            disabled={
              this.state.selectedDepartments.length < 1 ||
              this.state.username === "" ||
              this.state.email === "" ||
              this.state.userRealName === "" ||
              this.state.emailHasError === true
                ? true
                : false
            }
          >
            Submit
          </button>
        </fieldset>
        <br />
      </div>
    );
  };

  autogenerateAndChangePassword = () => {
    this.setState({
      ...this.state,
      disableButton: true,
    });
    let params = {
      newPassword: "**********",
      confirmNewPassword: "**********",
      customPassword: true,
      typeOfUser: this.state.typeOfUser,
    };
    const url2 = `snUsers/update-password-from-token`;
    const userEmail = this.state.openDataItem.email;
    const username = this.state.openDataItem.username;
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    const changePassResponse = ChangePasswordCall(
      params,
      url2,
      userEmail,
      username,
      options
    );
    changePassResponse
      .then(async (response) => {
        if (response) {
          let params = {
            filter: {
              limit: `100`,
              skip: `0`,
            },
          };
          const url = "snuserWarehouseVs";
          let count;
          let responseData = await serverApi(
            "GET",
            `${url}`,
            params,
            "",
            options
          );
          const countData = await serverApi(
            "GET",
            `snuserWarehouseVs/count`,
            "",
            "",
            options
          );
          if (countData) {
            count = countData.data.count;
          }

          // this.toggleChangePasswordWindow()

          setTimeout(() => {
            this.setState({
              ...this.state,
              changePasswordWindow: false,
              openDataItem: {},
              gridData: {
                data: responseData.data,
                total: count,
              },
              initialGridData: {
                data: responseData.data,
                total: count,
              },
              openSuccessModal: true,
              disableButton: false,
              successModalContent: `Change password was successfully completed.An email will be sent to the user and also to helpdesk@swiftmarine.eu for security reasons.`,
            });
          }, 500);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          this.setState({
            openErrorModal: true,
            errorModalContent:
              error?.response?.data?.error?.message ||
              "Change password process failed.",
            loading: false,
            disableButton: false,
            changePasswordWindow: false,
          });
        }, 500);
      });
  };

  render() {
    const { gridData, columns } = this.state;
    const hasEditedItem = gridData && gridData.data.some((p) => p.inEdit);

    return (
      <div>
        <Popup
          offset={this.offset}
          show={this.state.open}
          open={this.onPopupOpen}
          popupClass={"popup-content"}
        >
          <div
            onFocus={this.onFocusHandler}
            onBlur={this.onBlurHandler}
            tabIndex={-1}
            ref={(el) => (this.menuWrapperRef = el)}
          >
            <Menu
              vertical={true}
              style={{ display: "inline-block" }}
              onSelect={this.handleOnSelect}
            >
              {/* <KendoMenuItem text="Edit departments" />
              <KendoMenuItem text="Change password" />
              <KendoMenuItem text="Reset password" />
              <KendoMenuItem text="Terminate warehouse" /> */}

              {(this.state.rightClickText || []).map((text) => {
                return <KendoMenuItem text={text} />;
              })}
            </Menu>
          </div>
        </Popup>
        <Grid
          {...this.state.dataState}
          {...this.state.gridData}
          filterable={true}
          // onRowClick={e => this.rowClick(e)}
          style={{ height: "92vh" }}
          sortable={true}
          resizable
          editField={this.editField}
          rowRender={this.rowRender}
          onItemChange={this.itemChange}
          onDataStateChange={this.dataStateChange}
          pageable={{
            buttonCount: 5,
            info: true,
            pageSizes: [100, 200, 500, 1000, 1500],
          }}
        >
          <GridToolbar>
            <GridContainer xs={12} direction={"row"}>
              <Tooltip
                justify={"flex-start"}
                placement="top"
                title={"Create Warehouse User"}
              >
                <IconButton onClick={this.toggleWindow}>
                  <FontAwesomeIcon
                    color="#0D5869"
                    // onClick={this.toggleWindow}
                    size="1.6x"
                    icon={faPlus}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip
                justify={"flex-start"}
                placement="top"
                title={"Clear Filters"}
              >
                <IconButton
                  disabled={false}
                  aria-label=""
                  onClick={(e) => this.onClearFilters(e)}
                  look="flat"
                >
                  <FontAwesomeIcon
                    color="#0D5869"
                    size="1.6x"
                    icon={faEraser}
                  />
                </IconButton>
              </Tooltip>
              <ViewSettings
                {...this.props}
                export={{
                  data: this.state.exportData || [],
                  fileName: "Warehouse_Users_",
                  exportFunction: this.getExportData,
                  columns: columnsSchema(),
                }}
                viewSettings={{
                  type: "",
                }}
                refreshSettings={{
                  data: [],
                }}
              />
              <FormControl className={customSelectStyles.selectFormControl}>
                <InputLabel
                  htmlFor="multiple-select"
                  className={customSelectStyles.selectLabel}
                ></InputLabel>
                <Select
                  autoWidth={true}
                  multiple
                  value={columns
                    .filter((column) => column.visible)
                    .map((column) => column.field)}
                  onChange={this.handleColumnsChange}
                  MenuProps={{ className: customSelectStyles.selectMenu }}
                  inputProps={{
                    name: "multipleSelect",
                    id: "multiple-select",
                  }}
                  renderValue={(selected) => "Columns"}
                >
                  {columns
                    .filter((col) => !col.notShownInTableColumnSelection)
                    .map((column) => {
                      let name = column.title;
                      let value = column.field;
                      let isVisible = column.visible;
                      let isDisabled = column.noDisabledColumn;

                      return (
                        <MenuItem
                          style={{ backgroundColor: "white" }}
                          key={name}
                          value={value}
                          disabled={isDisabled}
                        >
                          <Checkbox color={"default"} checked={isVisible} />
                          <ListItemText
                            style={{ marginLeft: "7px" }}
                            primary={name}
                          />
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </GridContainer>
          </GridToolbar>

          <Column
            cell={this.CommandCell}
            title="Actions"
            filterable={false}
            width={hasEditedItem ? "240px" : "140px"}
          />
          {this.renderGridColumns()}
        </Grid>

        <GridLoader
          dataState={this.state.dataState}
          onDataRecieved={this.dataRecieved}
          getURL={"snuserWarehouseVs"}
        />

        <SuccessModal
          open={this.state.openSuccessModal}
          onCloseModal={this.closeSuccessModal}
          modalContent={this.state.successModalContent}
          timeout={3500}
        />

        <ErrorModal
          open={this.state.openErrorModalMui}
          onCloseModal={this.closeErrorModalMui}
          modalContent={this.state.errorModalContentMui}
          timeout={3500}
        />

        <Snackbar
          place="tc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"success"}
          message={this.state.successMessage}
          open={this.state.openSuccessMessage}
          closeNotification={() =>
            this.handleSuccessSnackbar(this.state.successMessage)
          }
          close
        />

        <Snackbar
          place="tc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"warning"}
          message={this.state.errorModalContent}
          open={this.state.openErrorModal}
          closeNotification={() =>
            this.handleErrorClose(this.state.errorModalContent)
          }
          // close
        />

        {this.state.visibleWindow && (
          <Window
            // title={<TitleComponent/>}
            title={"Create new warehouse user"}
            onClose={this.closeWindow}
            width={1050}
            height={750}
          >
            {this.renderCreateWarehouseForm()}
          </Window>
        )}

        {this.state.resetPasswordWindow && (
          <Window
            title={`Reset password process for warehouse: ${this.state.openDataItem.username}`}
            onClose={this.closeResetPasswordWindow}
            width={800}
            height={300}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <fieldset style={{ borderColor: "rgba(13, 88, 105, 1)" }}>
                <div
                  style={{
                    fontSize: "20px",
                    marginTop: "20px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  An email will be sent to the warehouse user with a reset link
                  in order to change the password himself. The link will be
                  active for 15 minutes. <br />
                  <span>Are you sure you want to continue?</span>
                </div>
                <Button
                  size="sm"
                  color="success"
                  type="button"
                  onClick={(e) => this.sendResetPassword(e)}
                >
                  Reset
                </Button>
              </fieldset>
            </div>
          </Window>
        )}

        {this.state.terminateUserWindow && (
          <Window
            title={`Terminate process for warehouse user : ${this.state.openDataItem.username}`}
            onClose={this.closeTerminateWindow}
            width={800}
            height={350}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <fieldset style={{ borderColor: "rgba(13, 88, 105, 1)" }}>
                <div
                  style={{
                    fontSize: "20px",
                    marginTop: "20px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  After the termination process has succeeded, the warehouse
                  user will not be able to login to navigator.
                  <br />
                  <br />
                  <span>Are you sure you want to continue?</span>
                </div>
                <Button
                  size="sm"
                  color="warning"
                  type="button"
                  onClick={(e) => this.terminateUser(e)}
                >
                  Terminate
                </Button>
              </fieldset>
            </div>
          </Window>
        )}

        {this.state.changePasswordWindow && (
          <Window
            title={`Change password process for warehouse user: ${this.state.openDataItem.username}`}
            onClose={this.closeChangePasswordWindow}
            width={800}
            height={400}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <fieldset style={{ borderColor: "rgba(13, 88, 105, 1)" }}>
                <div
                  style={{
                    fontSize: "20px",
                    marginTop: "20px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  Would you like to continue with a secure password generator
                  process or set a custom password of your choice?
                  <br />
                  In case you click YES, the change password process will start
                  automatically.
                </div>
                <br />
                <h3>
                  Please note that after the process has succeeded, an email
                  will be sent to the warehouse user and also to
                  helpdesk@swiftmarine.eu for security reasons.
                </h3>
                <br />
                <span>Are you sure you want to continue?</span>
                <div>
                  <Button
                    size="md"
                    color="success"
                    type="button"
                    disabled={this.state.disableButton}
                    onClick={(e) => {
                      this.autogenerateAndChangePassword();
                    }}
                  >
                    YES
                  </Button>
                  <Button
                    color="warning"
                    size="md"
                    type="button"
                    // onClick={e => push(`/app/master/user/passwordChange/${this.state.openDataItem.id}`)}
                    onClick={(e) => this.openSetCustomPasswordWindow()}
                  >
                    NO
                  </Button>
                </div>
              </fieldset>
            </div>
          </Window>
        )}

        {this.state.setCustomPasswordWindow && (
          <Window
            title={`Change Password Process`}
            onClose={this.closeSetCustomPasswordWindow}
            width={700}
            height={500}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <fieldset style={{ borderColor: "rgba(13, 88, 105, 1)" }}>
                <ChangePassword
                  openDataItem={this.state.openDataItem}
                  closeSetCustomPasswordWindow={
                    this.closeSetCustomPasswordWindow
                  }
                  typeOfUser={this.state.typeOfUser || "Warehouse"}
                />
              </fieldset>
            </div>
          </Window>
        )}
      </div>
    );
  }
}

export default WarehouseView;
