import React from "react";
import { columnsSchema } from "./schema";
import { MyCommandCell } from "../../../../Clients/View/components/CellHOC";
import { serverApi } from "../../../../../networking/config";
import _ from "lodash";
import { GridColumn as Column } from "@progress/kendo-react-grid/dist/npm/GridColumn";
import { DropDownCell } from "../../../../CRM/View/dropDownCell";
import { AutoComplete2 } from "../../../../Vessels/View/components/relationNameDropDown";
import { Grid, GridToolbar } from "@progress/kendo-react-grid";
import GridContainer from "../../../../../components/Grid/GridContainer";
import { IconButton, Tooltip } from "@material-ui/core";
import { Error } from "@progress/kendo-react-labels";
import { Window } from "@progress/kendo-react-dialogs";
import BlockUi from "react-block-ui";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class DepartmentToDomain extends React.Component {
  editField = "inEdit";
  CommandCell;
  _export;

  constructor(props) {
    super(props);

    this.state = {
      visibleWindow: false,
      isDropOpened: false,
      columns: (columnsSchema && columnsSchema()) || [],
      gridData: {
        data: [],
        total: 10,
      },
      initialGridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 10,
        skip: 0,
      },
      openErrorModal: false,
      errorModalContent: "",
      delay: 400,
      loading: false,
      department_names: [],
      windowError: false,
      windowErrorMessage: "",
      disableEditButton: true,
      inputValue: "",
      clientAttachedToAnotherDomain: null,
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      disableEditButton: false,
      showSecondAction: true,
      showFirstAction: false,
    });
  }

  async componentDidMount() {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let filter1 = {
      filter: {
        where: {
          domainID: this.props.dataItem && this.props.dataItem.id,
        },
        include: ["department"],
      },
    };
    let filter2 = {
      filter: {
        where: {
          and: [
            {
              client_flag: true,
            },
          ],
        },
      },
    };
    const attachedDepartments = await serverApi(
      "GET",
      `departmentToDomains`,
      filter1,
      "",
      options
    );
    let clonedAttachedDepartments = _.cloneDeep(attachedDepartments);

    const getDepartments = await serverApi(
      "GET",
      `departments`,
      filter2,
      "",
      options
    );

    let result = this.constructClientsAndExistingClientsArray(
      getDepartments.data,
      attachedDepartments.data
    );

    result[0].attachedDepartments.forEach((item) => {
      let client = result[0].allDepartments.find((x) => x.id === item.id);
      if (client) {
        client.checked = true;
      }
    });

    if (attachedDepartments && attachedDepartments.data.length === 1) {
      let finalAttachedDepartments = [];
      _.forEach(clonedAttachedDepartments.data, (temp) => {
        finalAttachedDepartments.push({
          id: temp.departmentID,
          name: temp.department.name,
          type: temp.department.type,
          deleteID: temp.id,
        });
      });

      this.setState({
        ...this.state,
        domainID: this.props.dataItem.id,
        gridData: {
          data: [finalAttachedDepartments[0]],
          total: 1,
        },
        initialGridData: {
          data: [finalAttachedDepartments[0]],
          total: 1,
        },
        department_names: getDepartments.data.sort((a, b) =>
          a.relation_name > b.relation_name ? 1 : -1
        ),
        valueClientName: "",

        visibleClients: result[0].allDepartments || [],
        finalUserClients: result[0].allDepartments,
        initialFinalUserClients: result[0].allDepartments,
        blocking: false,
      });
    } else if (attachedDepartments && attachedDepartments.data.length > 1) {
      let finalData = [];
      _.forEach(attachedDepartments.data, (temp) => {
        finalData.push({
          id: temp.departmentID,
          name: temp.department.name,
          type: temp.department.type,
          deleteID: temp.id,
        });
      });
      finalData.sort((a, b) => (a.relation_name > b.relation_name ? 1 : -1));

      this.setState({
        ...this.state,
        domainID: this.props.dataItem.id, //todo
        gridData: {
          data: finalData,
          total: finalData.length,
        },
        initialGridData: {
          data: finalData,
          total: finalData.length,
        },
        department_names: getDepartments.data.sort((a, b) =>
          a.relation_name > b.relation_name ? 1 : -1
        ),

        visibleClients: result[0].allDepartments || [],
        finalUserClients: result[0].allDepartments,
        initialFinalUserClients: result[0].allDepartments,
        blocking: false,
      });
    } else {
      this.setState({
        ...this.state,
        domainID: this.props.dataItem.id, //todo
        gridData: {
          data: [],
          total: 0,
        },
        initialGridData: {
          data: [],
          total: 0,
        },
        department_names: getDepartments.data,

        visibleClients: result[0].allDepartments || [],
        finalUserClients: result[0].allDepartments,
        initialFinalUserClients: result[0].allDepartments,
        blocking: false,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  constructClientsAndExistingClientsArray = (
    getDepartments,
    attachedDepartments
  ) => {
    let finalDepartments = [];
    _.forEach(getDepartments, (temp1) => {
      finalDepartments.push({
        name: temp1.name,
        id: temp1.id,
        checked: false,
      });
    });

    let finalAttachedDepartments = [];
    _.forEach(attachedDepartments, (temp2) => {
      finalAttachedDepartments.push({
        name: temp2.department.name,
        id: temp2.departmentID,
        checked: true,
        deleteID: temp2.id,
      });
    });

    finalDepartments.sort((a, b) => (a.name > b.name ? 1 : -1));
    finalAttachedDepartments.sort((a, b) => (a.name > b.name ? 1 : -1));

    return [
      {
        allDepartments: finalDepartments,
        attachedDepartments: finalAttachedDepartments,
      },
    ];
  };

  renderGridColumns = (dataItem, changeTheCellComponent) => {
    let selectionCol = [];
    let normalCols = [];
    let finalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            width="70px"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.isFilterBoolean === "yes") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"420"}
              cell={DropDownCell}
            />
          );
        } else if (temp.field === "masterEntity.relation_name") {
          return (
            <Column
              field="masterEntity.relation_name"
              title="NAME"
              width="400px"
              cell={AutoComplete2}
            />
          );
        } else {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={temp.field === "id" || temp.editable ? false : true} //id not editable on edit
            />
          );
        }
      });

    finalCols = [...selectionCol, ...normalCols];
    return finalCols;
  };

  enterEdit = (dataItem) => {};

  discard = (dataItem, hasError = false) => {};

  cancel = (dataItem) => {};

  removeItem(data, item) {}

  remove = async (dataItem) => {
    const data = [...this.state.gridData.data];

    let index = data.findIndex((p) => p === dataItem || p.id === dataItem.id);
    if (index >= 0) {
      data.splice(index, 1);
    }

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let delete_ID = dataItem.deleteID;
    let url = `departmentToDomains/${delete_ID}`;

    try {
      const removeClientFromDomain = await serverApi(
        `DELETE`,
        url,
        "",
        "",
        options
      );
      if (removeClientFromDomain.status === 200) {
        let findClient = this.state.visibleClients.find(
          (x) => x.id === dataItem.id
        );
        if (findClient) {
          findClient.checked = false;
        }

        this.setState(
          {
            ...this.state,
            gridData: {
              data: data,
              total: this.state.gridData.total,
            },
            initialGridData: {
              data: data,
              total: this.state.gridData.total,
            },
            successMessage: "Client was successfully removed from domain.",
            openSuccessMessage: true,
          },
          () => {
            setTimeout(() => {
              this.handleSuccessSnackbar();
            }, 2500);
          }
        );
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent: e.response.data.error.message || "Please try again",
      });
    }
  };

  toggleWindow = (e) => {
    const data = this.state.initialGridData.data;

    this.setState({
      ...this.state,
      visibleWindow: !this.state.visibleWindow,
      gridData: {
        data: [...data],
        total: 100,
      },
      department_names: [],
      valueClientName: "",
      windowError: false,
      windowErrorMessage: "",
      openErrorModal: false,
      errorModalContent: "",
      // inputValue:'',
      // initialFinalUserClients: initialFinalUserClients
    });
  };

  openErrorModal = (e) => {
    this.setState({
      ...this.state,
      openErrorModal: true,
      errorModalContent: e.response.data.error.message || "Please try again",
    });
  };

  handleSuccessSnackbar = () => {
    this.setState({
      ...this.state,
      successMessage:
        this.state.openSuccessMessage === true ? this.state.successMessage : "",
      openSuccessMessage: !this.state.openSuccessMessage,
    });
  };

  handleSearch = (e) => {
    let initialFinalUserClients = this.state.initialFinalUserClients;

    if (e.value === "") {
      let searchArr = [];
      initialFinalUserClients.forEach((temp) => {
        if (temp.name.toUpperCase().includes(e.value.toUpperCase())) {
          searchArr.push(temp);
        }
      });

      if (
        this.state.clientAttachedToAnotherDomain !== null &&
        this.state.clientAttachedToAnotherDomain !== undefined
      ) {
        let newArr = searchArr.filter(
          (t) => t.id !== this.state.clientAttachedToAnotherDomain
        );
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalUserClients: newArr,
          openErrorModal: false,
          errorModalContent: "",
        });
      } else {
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalUserClients: initialFinalUserClients,
          openErrorModal: false,
          errorModalContent: "",
        });
      }
    } else {
      let searchArr = [];
      //case insensitive
      initialFinalUserClients.forEach((temp) => {
        if (temp.name.toUpperCase().includes(e.value.toUpperCase())) {
          searchArr.push(temp);
        }
      });

      this.setState({
        ...this.state,
        inputValue: e.value,
        finalUserClients: searchArr,
        openErrorModal: false,
        errorModalContent: "",
      });
    }
  };

  renderClientList = (finalDepartments = [], departments = [], doRefresh) => {
    if (doRefresh === true) {
      window.location.reload();
    } else {
      if (finalDepartments.length === departments.length) {
        return (
          departments &&
          departments.map((item) => (
            <ul style={{ marginBottom: "-5px" }} key={item.id}>
              <Checkbox
                name={item.name}
                key={item.id}
                value={item.checked}
                onChange={(e) => this.handleChange(e, item.name, item.id)}
                label={item.name}
                disabled={this.state.disableAllCheckboxes}
              />
            </ul>
          ))
        );
      } else {
        return (
          finalDepartments &&
          finalDepartments.map((item) => (
            <ul style={{ marginBottom: "-5px" }} key={item.id}>
              <Checkbox
                name={item.name}
                key={item.id}
                value={item.checked}
                onChange={(e) => this.handleChange(e, item.name, item.id)}
                label={item.name}
                disabled={this.state.disableAllCheckboxes}
              />
            </ul>
          ))
        );
      }
    }
  };

  handleChange = (event, name, key) => {
    let finalUserClient = [...this.state.finalUserClients];
    let finalObj = finalUserClient.find((test) => test.id === key);
    finalObj.checked = !finalObj.checked;

    let domainID = this.state.domainID;
    let data = [...this.state.gridData.data];

    this.setState(
      {
        ...this.state,
        finalUserClients: finalUserClient,
      },
      () => {
        let postOrDeleteClient = event.value === false ? "DELETE" : "POST";
        let options = {
          token: window.localStorage.getItem("access_token"),
        };

        if (postOrDeleteClient === "POST") {
          const url = `departmentToDomains`;

          const addDepartmentToDomain = serverApi(
            "POST",
            url,
            "",
            {
              departmentID: key,
              domainID: domainID,
            },
            options,
            "",
            ""
          );

          addDepartmentToDomain
            .then(async (response) => {
              const filter1 = {
                filter: {
                  where: {
                    domainID: domainID,
                  },
                  include: ["department"],
                },
              };
              const attachedDepartments = await serverApi(
                "GET",
                `departmentToDomains`,
                filter1,
                "",
                options
              );
              let finalData = [];
              _.forEach(attachedDepartments.data, (temp) => {
                finalData.push({
                  id: temp.departmentID,
                  name: temp.department.name,
                  type: temp.department.type,
                  deleteID: temp.id,
                });
              });
              finalData.sort((a, b) => (a.name > b.name ? 1 : -1));
              this.setState({
                ...this.state,
                gridData: {
                  data: finalData,
                  total: finalData.length,
                },
                initialGridData: {
                  data: finalData,
                  total: finalData.length,
                },
                blocking: false,
                openErrorModal: false,
                errorModalContent: "",
              });
            })
            .catch((error) => {
              this.setState({
                ...this.state,
                openErrorModal: true,
                errorModalContent:
                  error.response.data.error.message || "Please try again",
                blocking: false,
              });
            });
        } else if (postOrDeleteClient === "DELETE") {
          const deleteObj = data.find((obj) => obj.id === key);
          const delete_id = deleteObj.deleteID;
          const url = `departmentToDomains/${delete_id}`;

          const removeDepartmentFromDomain = serverApi(
            "DELETE",
            url,
            "",
            "",
            options,
            "",
            ""
          );
          removeDepartmentFromDomain
            .then(async (response) => {
              let filter1 = {
                filter: {
                  where: {
                    domainID: domainID,
                  },
                  include: ["department"],
                },
              };
              const attachedDepartments = await serverApi(
                "GET",
                `departmentToDomains`,
                filter1,
                "",
                options
              );
              let finalData = [];
              _.forEach(attachedDepartments.data, (temp) => {
                finalData.push({
                  id: temp.departmentID,
                  name: temp.department.name,
                  type: temp.department.type,
                  deleteID: temp.id,
                });
              });
              finalData.sort((a, b) => (a.name > b.name ? 1 : -1));
              this.setState({
                ...this.state,
                gridData: {
                  data: finalData,
                  total: finalData.length,
                },
                initialGridData: {
                  data: finalData,
                  total: finalData.length,
                },
                blocking: false,
              });
            })
            .catch((error) => {
              this.setState({
                ...this.state,
                openErrorModal: true,
                errorModalContent:
                  error?.response?.data?.error?.message || "Please try again",
                blocking: false,
              });
            });
        }
      }
    );
  };

  render() {
    const dataItem = this.props.dataItem;
    const { gridData } = this.state;
    const { inputValue } = this.state;

    return (
      <div style={{ marginRight: "50px" }}>
        <div>
          <h4 style={{ fontFamily: "bold", textSize: "7px" }}>
            Domain name : {dataItem && dataItem.description}
          </h4>
        </div>
        <Grid
          {...gridData}
          filterable={false}
          style={{ className: "grid-no-select" }}
          sortable={false}
          resizable
          editField={this.editField}
          onItemChange={this.itemChange}
          pageable={false}
        >
          <GridToolbar>
            <GridContainer xs={12} justify={"flex-start"} direction={"row"}>
              <Tooltip
                justify={"flex-start"}
                placement="top"
                title={"Attach Department"}
              >
                <IconButton onClick={this.toggleWindow}>
                  <FontAwesomeIcon
                    color="#0D5869"
                    onClick={this.toggleWindow}
                    size="1.6x"
                    icon={faPlus}
                  />
                </IconButton>
              </Tooltip>
            </GridContainer>
          </GridToolbar>

          <Column
            cell={this.CommandCell}
            title="Actions"
            filterable={false}
            width="220px"
          />
          {this.renderGridColumns(dataItem)}
        </Grid>

        {this.state.visibleWindow && (
          <Window
            title={`Attach departments to domain: ${this.props.dataItem.description}`}
            // style={{backgroundColor:'#0d5869'}}
            onClose={this.toggleWindow}
            width={900}
            height={600}
            initialTop={110}
          >
            <>
              <BlockUi
                tag="div"
                blocking={this.state.blocking}
                message="Loading, please wait. . ."
                renderChildren={true}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "50px",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {this.state.openErrorModal ? (
                      <Error style={{ color: "red", fontSize: "15px" }}>
                        {this.state.errorModalContent || ""}
                      </Error>
                    ) : null}
                    <div
                      style={{
                        display: "flex",
                        marginLeft: "35px",
                        marginTop: "20px",
                      }}
                    >
                      <Input
                        name="departments"
                        value={inputValue}
                        style={{ marginBottom: "20px" }}
                        label="Search department"
                        onChange={(e) => this.handleSearch(e)}
                        minLength={2}
                      />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {this.renderClientList(
                        this.state.finalUserClients,
                        this.state.visibleClients
                      )}
                    </div>
                  </div>
                </div>
              </BlockUi>
            </>
            {this.state.windowError ? (
              <Error style={{ color: "red" }}>
                <h3>{this.state.windowErrorMessage || ""}</h3>
              </Error>
            ) : null}
          </Window>
        )}
      </div>
    );
  }
}
