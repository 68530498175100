import React from 'react';

import './NoAccess.scss';

export function NoAccess() {
    return (        
        <div className="no-content-message">
            You do not have access to view this page.
        </div>
    )
}