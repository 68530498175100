import { connect } from "react-redux";
import compose from "ramda/src/compose";
import Supplier from "./View/index";

const mapActionCreators = {};

const mapStateToProps = state => {
  return {
    user: state.MainLayoutReducer.user,
    hasBackofficeRead: state.MainLayoutReducer.user.hasBackofficeRead,
    countryCodes: state.MainLayoutReducer.countryCodes,
    usaStates: state.MainLayoutReducer.usaStates,
  };
};

export default compose(connect(mapStateToProps, mapActionCreators))(Supplier);
