/* eslint-disable */
import React from "react";
import { format } from "date-fns";
import map from "ramda/src/map";
import all from "ramda/src/all";
import contains from "ramda/src/contains";
import _ from "lodash";
// import Fields from '../../components/Fields'

/**
 * Helper function that returns a UUID in case
 * you need to provide a random number with
 * some custom logic inside
 * @returns {string}
 */
export const createUUID = () => {
  let dt = new Date().getTime();
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const validateUsername = (username) => {
  let testLength = username.length;

  return testLength < 3 ? false : true;
};

/**
 * The email provided must be a valid email input
 * @param email = user's input value
 * @param s = validation rule
 * @returns {boolean}
 */
export const validateEmailWithRegex = (email, s) => {
  let re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
};

/**
 *  The password provided must have at least one number, one lowercase and one uppercase letter
 // and total at least six characters
 * @param password
 * @returns {boolean}
 */
export const validatePassword = (password) => {
  let re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;

  return re.test(password);
};

/**
 * We check what crud authorities the login users has whenever
 * a grid component is the main page.
 * @param userAuthorities
 * @param authoritiesToCheck
 * @returns {true/false}
 */
export const checkAuthorities = (userAuthorities = [], authoritiesToCheck) => {
  const _userAuthorities = _.map(userAuthorities, "Description");

  if (Array.isArray(authoritiesToCheck)) {
    return all(true)(
      map(
        (authority) => contains(authority, _userAuthorities),
        authoritiesToCheck
      )
    );
  } else {
    return contains(authoritiesToCheck, _userAuthorities);
  }
};

export const makeSchemaReadOnly = (schema) => {
  return map((schm) => {
    schm.readOnly = true;

    return schm;
  }, schema);
};

/**
 * Handle date formats in columns
 * using date-fns npm package
 * @param raw
 * @returns {string}
 */
export const dateRenderer = (raw) => {
  if (raw.value === null) return "";

  let date = new Date(raw.value);

  return format(date, "MM/DD/YYYY HH:MM");
};

export const colorFromString = (str) => {
  if (!str) return "#000000";
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = "#";
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
};

export const initialsFromName = (str, delimiter = " ") => {
  if (!str) return "";

  let initials = "";

  let parts = str.split(delimiter);
  if (parts.length > 0) {
    let first = parts[0];
    if (first && first.length > 0) {
      initials += first.substr(0, 1);
    }
  }
  if (parts.length > 1) {
    let second = parts[1];
    if (second && second.length > 1) {
      initials += second.substr(0, 1);
    }
  }

  initials = initials.toUpperCase();

  if (initials.length === 1 && delimiter === " ") {
    initials = initialsFromName(str, ".");
  }

  return initials;
};

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const dayOfWeekNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const twoDigitPad = (num) => {
  return num < 10 ? "0" + num : num;
};

export const isValidDate = (date) => {
  return date instanceof Date && !isNaN(date);
};

export const formatDate = (date, patternStr) => {
  if (isValidDate(date) === false) {
    return;
  } else {
    if (!patternStr) {
      patternStr = "M/d/yyyy";
    }
    var day = date.getDate(),
      month = date.getMonth(),
      year = date.getFullYear(),
      hour = date.getHours(),
      minute = date.getMinutes(),
      second = date.getSeconds(),
      miliseconds = date.getMilliseconds(),
      h = hour % 12,
      hh = twoDigitPad(h),
      HH = twoDigitPad(hour),
      mm = twoDigitPad(minute),
      ss = twoDigitPad(second),
      aaa = hour < 12 ? "AM" : "PM",
      EEEE = dayOfWeekNames[date.getDay()],
      EEE = EEEE.substr(0, 3),
      dd = twoDigitPad(day),
      M = month + 1,
      MM = twoDigitPad(M),
      MMMM = monthNames[month],
      MMM = MMMM.substr(0, 3),
      yyyy = year + "",
      yy = yyyy.substr(2, 2);
    // checks to see if month name will be used
    patternStr = patternStr
      .replace("hh", hh)
      .replace("h", h)
      .replace("HH", HH)
      .replace("H", hour)
      .replace("mm", mm)
      .replace("m", minute)
      .replace("ss", ss)
      .replace("s", second)
      .replace("S", miliseconds)
      .replace("dd", dd)
      .replace("d", day)
      .replace("EEEE", EEEE)
      .replace("EEE", EEE)
      .replace("yyyy", yyyy)
      .replace("yy", yy);
    if (patternStr.indexOf("MMM") > -1) {
      patternStr = patternStr.replace("MMMM", MMMM).replace("MMM", MMM);
    } else {
      patternStr = patternStr.replace("MM", MM).replace("M", M);
    }
    patternStr = patternStr.replace("aaa", aaa);
  }

  return patternStr;
};

// function that returns true if value is email, false otherwise
export const verifyEmail = (value) => {
  var emailRex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
};

// function that verifies if a string has a given length or not
export const verifyLength = (value, length) => {
  if (value.length >= length) {
    return true;
  }
  return false;
};

export const verifyMobile = (string) =>
  [...string].every((c) => "0123456789-".includes(c));

function evaluateFilter(item, filter) {
  const { field, operator, value } = filter;

  switch (operator) {
    case "eq":
      return item[field] === value;
    case "neq":
      return item[field] !== value;
    case "lt":
      return item[field] < value;
    case "lte":
      return item[field] <= value;
    case "gt":
      return item[field] > value;
    case "gte":
      return item[field] >= value;
    default:
      return false;
  }
}

function createPredicate(filterDefinition) {
  return function (item) {
    const filters = filterDefinition.filters;
    const condition = filterDefinition.condition || "AND";
    if (condition === "AND") {
      return filters.every((filter) => evaluateFilter(item, filter));
    } else if (condition === "OR") {
      return filters.some((filter) => evaluateFilter(item, filter));
    } else {
      throw new Error("Invalid condition");
    }
  };
}

export function findApplicableSettings(settings, testObject) {
  return settings.filter((setting) => {
    if (!setting.appliesTo) {
      return true;
    }
    try {
      const filterDefinition = JSON.parse(setting.appliesTo);
      const predicate = createPredicate(filterDefinition);
      const result = predicate(testObject);

      return result;
    } catch (error) {
      return false;
    }
  });
}
