import React from "react";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { GridLoader } from "../../common/loader";
import { AbstractDelete, AbstractEdit } from "../../../networking/apiCalls";
import { MyCommandCell } from "../../common/commandCellHOC";
import { serverApi } from "../../../networking/config";
import { IconButton, Tooltip } from "@material-ui/core";
import { filterBy } from "@progress/kendo-data-query";
import GridContainer from "../../../components/Grid/GridContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser, faPlus } from "@fortawesome/free-solid-svg-icons";
import Snackbar from "../../../components/Snackbar/Snackbar";
import { columnsSchema } from "./schema";
import { DropDownCell } from "../../CRM/View/dropDownCell";
import UsersToDepartment from "./components/UsersToDepartment/UsersToDepartment";
import { Menu, MenuItem as KendoMenuItem } from "@progress/kendo-react-layout";
import { Popup } from "@progress/kendo-react-popup";
import { Window } from "@progress/kendo-react-dialogs";
import { Error } from "@progress/kendo-react-labels";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import ExpandedTabs from "../../common/ExpandedTabs";
import _ from "lodash";
import SuccessModal from "../../../components/SuccessModal";

class Departments extends React.Component {
  editField = "inEdit";
  CommandCell;

  constructor(props) {
    super(props);

    this.state = {
      visibleWindow: false,
      isDropOpened: false,
      columns: columnsSchema && columnsSchema(),
      gridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 100,
        skip: 0,
      },
      openErrorModal: false,
      errorModalContent: "",
      visible: false,
      expandedRowIndex: null,
      selectedRowIndex: 0,
      selectedItem: null,
      delay: 400,
      loading: false,
      open: false,
      emailHasError: false,
      username: "",
      userRealName: "",
      email: "",
      agent_names: [],
      valueAgentName: "",
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      hasDisable: false,
      showSecondAction: false,
    });
  }

  componentDidMount() {
    let isFirstLoad = true;
    window.localStorage.setItem("isFirstLoad", isFirstLoad);

    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));

    if (hasFinalParams) {
      this.setState({
        ...this.state,
        dataState: hasFinalParams,
      });
    }

    if (hasChangedColumns) {
      this.setState({
        ...this.state,
        columns: hasChangedColumns,
      });
    }
  }

  componentWillUnmount() {
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));
    if (hasChangedColumns) {
      window.localStorage.removeItem("cols");
    }

    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    if (hasFinalParams) {
      window.localStorage.removeItem("finalParams");
    }

    let hasFlagEnabled = window.localStorage.getItem("flagSet");
    if (hasFlagEnabled) {
      window.localStorage.removeItem("flagSet");
    }

    let hasExportFilter = window.localStorage.getItem("exportFilter");
    if (hasExportFilter) {
      window.localStorage.removeItem("exportFilter");
    }
  }

  renderGridColumns = () => {
    let selectionCol = [];
    let normalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            width="65px"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.isFilterBoolean === "yes") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              sortable={temp.sortable}
              cell={DropDownCell}
            />
          );
        } else {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              sortable={temp.sortable}
              editable={temp.field === "id" || temp.editable ? false : true}
            />
          );
        }
      });

    return [...selectionCol, ...normalCols];
  };

  onAutoCompleteChange = (event, value) => {
    const { name } = event.target;

    let stateData = {};

    stateData =
      value.length < 3
        ? {
            data:
              this.state.agent_names.length > 0
                ? this.state.agent_names.map((temp) => temp.relation_name)
                : [],
            openedAgentNames: false,
          }
        : { data: this.filterData(value, name), openedAgentNames: true };

    let eventType = event.nativeEvent.type;

    let valueSelected =
      eventType === "click" ||
      (eventType === "keydown" && event.nativeEvent.keyCode === 13);

    if (valueSelected) {
      let postAgentId =
        this.state.agent_names.length === 1
          ? this.state.agent_names[0].id
          : this.state.agent_names.filter(
              (temp) => temp.relation_name === this.state.valueAgentName
            )[0].id;

      let relation_postal_code =
        this.state.agent_names.length === 1
          ? this.state.agent_names[0].relation_postal_code
          : this.state.agent_names.filter(
              (temp) => temp.relation_name === this.state.valueAgentName
            )[0].relation_postal_code;

      let relation_country =
        this.state.agent_names.length === 1
          ? this.state.agent_names[0].relation_country
          : this.state.agent_names.filter(
              (temp) => temp.relation_name === this.state.valueAgentName
            )[0].relation_country;

      let relation_city =
        this.state.agent_names.length === 1
          ? this.state.agent_names[0].relation_city
          : this.state.agent_names.filter(
              (temp) => temp.relation_name === this.state.valueAgentName
            )[0].relation_city;

      this.setState({
        ...this.state,
        relation_postal_code: relation_postal_code || "",
        relation_country: relation_country || "",
        relation_city: relation_city || "",
        agent_flag: true,
        postAgentID: postAgentId || "",
      });
    }

    if (valueSelected && stateData.data.includes(value)) {
      stateData.openedAgentNames = false;
    }

    this.setState(
      {
        valueAgentName: value,
        ...stateData,
      },
      () => this.fetchAutocompleteData(value)
    );
  };

  fetchAutocompleteData = (filterValue) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let filter = {
      order: ["relation_name asc"],
      where: {
        and: [
          { relation_name: { regexp: `/^${filterValue}/i` } },
          {
            agent_flag: true,
          },
        ],
      },
    };

    const fetchDropdownData = serverApi(
      "GET",
      `masterEntities`,
      { filter: filter },
      "",
      options
    );

    fetchDropdownData
      .then((response) => {
        this.setState({
          ...this.state,
          agent_names: response.data.sort((a, b) =>
            a.relation_name > b.relation_name ? 1 : -1
          ),
        });
      })
      .catch((error) => {
        console.log(error);

        throw error;
      });
  };

  filterData = (value, name) => {
    const data = this.state.agent_names.map((test) => test.relation_name);

    const filter = {
      value: value,
      operator: "startswith",
      ignoreCase: true,
    };

    let results = filterBy(data, filter);
    return results;
  };

  enterEdit = (dataItem) => {
    let total = this.state.gridData.total;

    this.setState({
      gridData: {
        data:
          this.state.gridData &&
          this.state.gridData.data.map((item) =>
            item.id === dataItem.id ? { ...item, inEdit: true } : item
          ),
        total: total,
      },
      initialGridData: {
        data:
          this.state.initialGridData &&
          this.state.initialGridData.data.map((item) =>
            item.id === dataItem.id ? { ...item, inEdit: true } : item
          ),
        total: total,
      },
    });
  };

  remove = async (dataItem) => {};

  add = (dataItem) => {
    dataItem.inEdit = undefined;

    const submitURL = "departments";
    let count;
    const options = { token: window.localStorage.getItem("access_token") };
    let payload = dataItem;
    delete payload.inEdit;

    if (
      payload.name === undefined ||
      payload.type === undefined ||
      payload.location === undefined
    ) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            "Error: Required fields to create a new department: Name,Type and Location",
        },
        () => {
          setTimeout(() => {
            this.cancel(dataItem);
          }, 3500);
        }
      );
    } else {
      const createRequest = serverApi(
        "POST",
        `${submitURL}`,
        "",
        payload,
        options
      );
      createRequest
        .then(async (createResponse) => {
          const responseData = await serverApi(
            "GET",
            `departments?filter={"include":[],"where":{"and":[{}]},"limit":"10","skip":"0"}`,
            {},
            "",
            options
          );
          const countDep = await serverApi(
            "GET",
            "departments/count",
            "",
            "",
            options
          );
          if (countDep) {
            count = countDep.data.count;
          }

          this.setState(
            {
              ...this.state,
              gridData: {
                data: responseData.data,
                total: count === undefined ? responseData.data.length : count,
              },
              initialGridData: {
                data: responseData.data,
                total: count === undefined ? responseData.data.length : count,
              },
              deleteVisible: false,
              successMessage: "The department was successfully created.",
              openSuccessMessage: true,
            },
            () => {
              setTimeout(() => {
                this.handleSuccessClose();
              }, 3000);
            }
          );
        })
        .catch((createError) => {
          this.setState(
            {
              ...this.state,
              openErrorModal: true,
              errorModalContent:
                createError.response.data.error.message || "Please try again",
            },
            () => this.discard(dataItem, true)
          );
        });
    }
  };

  discard = (dataItem, hasError = false) => {
    let data = [...this.state.gridData.data];
    const total = this.state.gridData.total;

    if (hasError) {
      let index = data.findIndex((p) => p.id === dataItem.id);

      data[index].inEdit = false;

      this.setState(
        {
          ...this.state,
          gridData: {
            data: data,
            total: total,
          },
        },
        () => {
          setTimeout(() => {
            this.setState({
              ...this.state,
              openErrorModal: false,
              errorModalContent: "",
            });
          }, 3000);
        }
      );
    } else {
      this.removeItem(data, dataItem);

      this.setState({
        ...this.state,
        gridData: {
          data: data,
          total: total,
        },
        visible: false,
      });
    }
  };

  handleSuccessSnackbar = () => {
    this.setState({
      ...this.state,
      successMessage:
        this.state.openSuccessMessage === true ? this.state.successMessage : "",
      openSuccessMessage: this.state.openSuccessMessage === true ? true : false,
    });
  };

  update = async (dataItem) => {
    const data = [...this.state.gridData.data];
    const updatedItem = { ...dataItem, inEdit: undefined };
    this.updateItem(data, updatedItem);

    let editPayload = updatedItem;

    if (
      editPayload.name === "" ||
      editPayload.name === undefined ||
      editPayload.type === "" ||
      editPayload.type === undefined ||
      editPayload.location === "" ||
      editPayload.location === undefined
    ) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            "Error: Required fields to edit the department: Name,Type and Location.",
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 3500);
        }
      );
    } else {
      try {
        delete editPayload.inEdit;
        delete editPayload.expanded;

        const editRequest = await AbstractEdit(
          dataItem.id,
          editPayload,
          "departments",
          {
            token: window.localStorage.getItem("access_token"),
          }
        );
        const countRequest = await serverApi(
          "GET",
          "departments/count",
          "",
          "",
          {
            token: window.localStorage.getItem("access_token"),
          }
        );

        if (editRequest.status === 200 && countRequest) {
          this.setState(
            {
              ...this.state,
              gridData: {
                data: data,
                total: countRequest.data.count,
              },
              deleteVisible: false,
              successMessage: "The department was successfully updated.",
              openSuccessMessage: true,
            },
            () => {
              setTimeout(() => {
                this.handleSuccessClose();
              }, 3000);
            }
          );
        }
      } catch (e) {
        this.setState(
          {
            ...this.state,
            openErrorModal: true,
            errorModalContent:
              e?.response?.data?.error?.message ||
              "Update failed.Please try again",
          },
          () => this.discard(dataItem, true)
        );
      }
    }
  };

  closeWindow = () => {
    this.setState({
      ...this.state,
      visibleWindow: false,
      emailHasError: false,
      selectedDepartments: [],
      open: false,
    });
  };

  cancel = (dataItem = {}) => {
    if (
      dataItem.name === undefined ||
      dataItem.type === undefined ||
      dataItem.email === undefined
    ) {
      const data =
        this.state.initialGridData && this.state.initialGridData.data;
      const total = this.state.initialGridData.total;

      this.setState({
        ...this.state,
        gridData: {
          data: data,
          total: total,
        },
        errorModalContent:
          this.state.errorModalContent !== ""
            ? ""
            : this.state.errorModalContent,
        openErrorModal:
          this.state.openErrorModal === true
            ? false
            : this.state.errorModalContent,
      });
    } else {
      const originalItem =
        this.state.initialGridData &&
        this.state.initialGridData.data.find((p) => p.id === dataItem.id);
      originalItem.inEdit = false;

      const data =
        this.state.initialGridData &&
        this.state.initialGridData.data.map((item) =>
          item.id === originalItem.id ? originalItem : item
        );

      const total = this.state.initialGridData.total;

      this.setState({
        ...this.state,
        gridData: {
          data: data,
          total: total,
        },
      });
    }
  };

  updateItem = (data, item) => {
    let index = data.findIndex(
      (p) => p === item || (item.id && p.id === item.id)
    );
    if (index >= 0) {
      data[index] = { ...item };
    }
  };

  itemChange = (event) => {
    const total = this.state.gridData.total;
    const data =
      this.state.gridData &&
      this.state.gridData.data.map((item) =>
        item.id === event.dataItem.id
          ? { ...item, [event.field]: event.value }
          : item
      );

    this.setState({
      gridData: {
        data: data,
        total: total,
      },
    });
  };

  addNew = () => {
    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    const newDataItem = { inEdit: true };

    this.setState({
      gridData: {
        data: [newDataItem, ...this.state.gridData.data],
        total: this.state.gridData.total,
      },
      visible: true,
    });
  };

  cancelCurrentChanges = () => {
    this.setState({
      ...this.state,
      gridData: this.state.initialGridData,
    });
  };

  removeItem(data, item) {
    let index = data.findIndex((p) => p === item || p.id === item.id);
    if (index >= 0) {
      data.splice(index, 1);
    }
  }

  dataStateChange = (e) => {
    const reqGridParams = e.data;
    window.localStorage.setItem("finalParams", JSON.stringify(reqGridParams));

    this.setState({
      ...this.state,
      dataState: reqGridParams,
    });
  };

  dataRecieved = (gridData, reqGridParams) => {
    this.setState({
      ...this.state,
      gridData: gridData,
      initialGridData: gridData,
      dataState:
        reqGridParams === !_.isEmpty(reqGridParams)
          ? reqGridParams
          : this.state.dataState, //TODO MAYBE PASS THE final params from local storage for persist the filters on REFRESH PAGE !
    });
  };

  onClearFilters = () => {
    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));

    let init = JSON.stringify({ take: 100, skip: 0 });
    if (hasFinalParams && hasFinalParams.filter)
      window.localStorage.setItem("finalParams", init);

    this.dataStateChange({ data: { take: 100, skip: 0 } });
  };

  expandChange = (event) => {
    window.localStorage.setItem("expandeId", event.dataItem.id);

    if (this.state.gridData.data[0].inEdit === true) {
      return;
    } else {
      event.dataItem.expanded = !event.dataItem.expanded;
    }

    this.forceUpdate();
  };

  closeErrorModal = () => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      errorModalContent: "",
    });
  };

  handleSubmit(event) {
    event.preventDefault();
  }

  handleErrorClose = (loginError) => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      openErrorModalContent: "",
    });
  };

  handleSuccessClose = () => {
    this.setState({
      ...this.state,
      openSuccessMessage: false,
      successMessage: "",
    });
  };

  handleContextMenuOpen = (e, dataItem) => {
    if (dataItem?.type === "WAREHOUSE") {
      this.dataItem = dataItem;
      this.dataItemIndex = this.state.gridData.data.findIndex(
        (p) => p.id === this.dataItem.id
      );
      this.offset = { left: e.clientX, top: e.clientY };

      if (_.isEmpty(this.state.openDataItem)) {
        //todo handle two popup opened
        this.setState({
          ...this.state,
          open: true,
          openDataItem: dataItem,
        });
      } else {
        return;
      }
    } else {
      return;
    }
  };

  rowRender = (trElement, dataItem) => {
    const trProps = {
      ...trElement.props,
      onContextMenu: (e) => {
        e.preventDefault();
        this.handleContextMenuOpen(e, dataItem.dataItem);
      },
      style: {
        // color: 'orange'
      },
    };

    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  onPopupOpen = () => {
    this.menuWrapperRef.querySelector("[tabindex]").focus();
  };

  onFocusHandler = () => {
    clearTimeout(this.blurTimeoutRef);
    this.blurTimeoutRef = undefined;
  };

  onBlurTimeout = () => {
    this.setState({
      open: false,
      openDataItem: {},
    });

    this.blurTimeoutRef = undefined;
  };

  goToChangeAgentPage = () => {
    this.openChangeAgentWindow();
  };

  openChangeAgentWindow = async () => {
    const options = { token: window.localStorage.getItem("access_token") };

    let filter = {
      filter: {
        where: {
          and: [
            {
              agent_flag: true,
            },
          ],
        },
        limit: 1000,
      },
    };
    const getAgents = await serverApi(
      "GET",
      `masterEntities`,
      filter,
      "",
      options
    );
    if (getAgents) {
      this.setState({
        ...this.state,
        visibleWindow: true,
        agent_names: getAgents.data.sort((a, b) =>
          a.relation_name > b.relation_name ? 1 : -1
        ),
        valueAgentName: "",
      });
    }
  };

  onBlurHandler = (event) => {
    clearTimeout(this.blurTimeoutRef);
    this.blurTimeoutRef = setTimeout(this.onBlurTimeout);
  };

  handleOnSelect = (e) => {
    let data = this.state.openDataItem;
    let type = e.item.text;
    switch (type) {
      case "Change Agent":
        this.goToChangeAgentPage(data);
        break;

      default:
        break;
    }

    this.setState({
      ...this.state,
      open: false,
    });
  };

  listNoDataRender = (element) => {
    const noData = (
      <h4 style={{ fontSize: "1em" }}>
        <span className="k-icon k-i-warning" style={{ fontSize: "2.5em" }} />
        <br />
        <br />
        No agents found
      </h4>
    );

    return React.cloneElement(element, { ...element.props }, noData);
  };

  onChange = (event) => {
    //prevent enter keyboard
    if (
      event.nativeEvent.type === "keydown" &&
      event.nativeEvent.keyCode === 13
    )
      return;

    const { value } = event.target;

    if (value === "") {
      this.setState({
        openedAgentNames: false,
        valueAgentName: value,
      });
    } else {
      this.setState(
        {
          openedAgentNames: false,
          valueAgentName: value,
        },
        () => {
          this.onAutoCompleteChange(event, value);
        }
      );
    }
  };

  saveAgent = async () => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let editPayload = {
      agentID: this.state.postAgentID,
    };

    try {
      const editRequest = await AbstractEdit(
        this.state.openDataItem.id,
        editPayload,
        "departments",
        options
      );
      const responseData = await serverApi(
        "GET",
        `departments?filter={"include":"agent","where":{"and":[{"type":{"regexp":"/^warehouse/i"}}]},"order":["type desc"],"limit":"100","skip":"0"}`,
        {},
        "",
        options
      );
      const countDep = await serverApi(
        "GET",
        "departments/count",
        "",
        "",
        options
      );
      let count = null;
      if (countDep) {
        count = countDep.data.count;
      }

      if (editRequest.status === 200 && count && responseData) {
        const oldAgentId = this.state.openDataItem.agentID;
        let filter = {
          where: {
            and: [{ agentID: oldAgentId }],
          },
        };
        const getAgentToWhRecords = await serverApi(
          "GET",
          `agentToWarehouses`,
          { filter: filter },
          "",
          options
        );

        //eslint-disable-next-line
        for (let rec of getAgentToWhRecords.data) {
          await AbstractDelete("agentToWarehouses", rec.id, options);
        }

        this.setState(
          {
            ...this.state,
            gridData: {
              data: responseData.data,
              total: count === undefined ? responseData.data.length : count,
            },
            initialGridData: {
              data: responseData.data,
              total: count === undefined ? responseData.data.length : count,
            },
            successMessage: `Department's agent was successfully updated.`,
            openSuccessMessage: true,
            visibleWindow: false,
            open: false,
            openDataItem: {},
          },
          () => {
            setTimeout(() => {
              this.handleSuccessClose();
            }, 3000);
          }
        );
      }
    } catch (e) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          visibleWindow: false,
          open: false,
          openDataItem: {},
          errorModalContent:
            e?.response?.data?.error?.message ||
            "Department's agent could not be altered.Process is terminated.",
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 3000);
        }
      );
    }
  };

  closeSuccessModal = () => {
    this.setState({ openSuccessModal: false, successModalContent: "" });
  };

  openSuccessModal = (message) => {
    this.setState({ openSuccessModal: true, successModalContent: message });
  };

  render() {
    const { gridData } = this.state;
    const hasEditedItem = gridData && gridData.data.some((p) => p.inEdit);

    return (
      <div>
        <Popup
          offset={this.offset}
          show={this.state.open}
          open={this.onPopupOpen}
          popupClass={"popup-content"}
        >
          <div
            onFocus={this.onFocusHandler}
            onBlur={this.onBlurHandler}
            tabIndex={-1}
            ref={(el) => (this.menuWrapperRef = el)}
          >
            <Menu
              vertical={true}
              style={{ display: "inline-block" }}
              onSelect={this.handleOnSelect}
            >
              <KendoMenuItem text="Change Agent" />
            </Menu>
          </div>
        </Popup>
        <Grid
          {...this.state.dataState}
          {...this.state.gridData}
          filterable={true}
          sortable={true}
          resizable
          style={{ height: "92vh" }}
          editField={this.editField}
          detail={(dataItem) => (
            <div>
              <ExpandedTabs
                tabs={[
                  {
                    label: "User To Department",
                    component: (
                      <UsersToDepartment
                        openSuccessModal={(message) =>
                          this.openSuccessModal(message)
                        }
                        {...dataItem}
                      />
                    ), // Index
                  },
                ]}
              ></ExpandedTabs>
            </div>
          )}
          onItemChange={this.itemChange}
          onDataStateChange={this.dataStateChange}
          rowRender={this.rowRender}
          expandField="expanded"
          onExpandChange={this.expandChange}
          pageable={{
            buttonCount: 5,
            info: true,
            pageSizes: [100, 200, 500, 1000, 1500],
          }}
        >
          <GridToolbar>
            <GridContainer xs={12} direction={"row"}>
              <Tooltip
                justify={"flex-start"}
                placement="top"
                title={"Add New Department"}
              >
                <IconButton onClick={this.addNew}>
                  <FontAwesomeIcon
                    color="#0D5869"
                    onClick={this.addNew}
                    size="1.6x"
                    icon={faPlus}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip
                justify={"flex-start"}
                placement="top"
                title={"Clear Filters"}
              >
                <IconButton
                  disabled={false}
                  aria-label=""
                  onClick={(e) => this.onClearFilters(e)}
                  look="flat"
                >
                  <FontAwesomeIcon
                    color="#0D5869"
                    size="1.6x"
                    icon={faEraser}
                  />
                </IconButton>
              </Tooltip>
            </GridContainer>
          </GridToolbar>

          <Column
            cell={this.CommandCell}
            title="Actions"
            filterable={false}
            width={hasEditedItem ? "240px" : "140px"}
          />

          {this.renderGridColumns()}
        </Grid>

        <GridLoader
          dataState={this.state.dataState}
          onDataRecieved={this.dataRecieved}
          getUrl={"departments"}
          include={"agent"}
        />

        <SuccessModal
          open={this.state.openSuccessModal}
          onCloseModal={this.closeSuccessModal}
          modalContent={this.state.successModalContent}
          timeout={4000}
        />

        <Snackbar
          place="tc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"warning"}
          message={this.state.errorModalContent}
          open={this.state.openErrorModal}
          closeNotification={() =>
            this.handleErrorClose(this.state.errorModalContent)
          }
          // close
        />

        <Snackbar
          place="tc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"success"}
          message={this.state.successMessage}
          open={this.state.openSuccessMessage}
          // closeNotification={() => this.handleSuccessClose()}
          // close
        />

        {this.state.visibleWindow && (
          <Window
            title={`Change the agent of department: ${this.state.openDataItem.name}`}
            onClose={() => {
              this.setState({
                ...this.state,
                visibleWindow: false,
                open: false,
                openDataItem: {},
                relation_postal_code: "",
                relation_country: "",
                relation_city: "",
              });
            }}
            width={1050}
            height={550}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              {this.state.windowError ? (
                <Error style={{ color: "red" }}>
                  <h3>{this.state.windowErrorMessage || ""}</h3>
                </Error>
              ) : null}
              <fieldset style={{ borderColor: "rgba(13, 88, 105, 1)" }}>
                <legend>{`Department Name: ${this.state.openDataItem.name}`}</legend>

                <AutoComplete
                  style={{ width: "450px", marginTop: "20px" }}
                  data={this.state.agent_names.map(
                    (temp) => temp.relation_name
                  )}
                  value={this.state.valueAgentName}
                  label={"Search Agent Name"}
                  name={"relation_name"}
                  onChange={(e) => this.onChange(e)}
                  listNoDataRender={this.listNoDataRender}
                  // placeholder="Search client name"
                />
                {this.state.valueClientName !== "" ? (
                  <div>
                    <h5>Postal</h5>
                    <input
                      className="k-textbox"
                      style={{ width: "450px" }}
                      name="relation_postal_code"
                      value={this.state.relation_postal_code || ""}
                      disabled={true}
                    />
                    <h5>Country</h5>
                    <input
                      className="k-textbox"
                      name="relation_country"
                      style={{ width: "450px" }}
                      value={this.state.relation_country || ""}
                      disabled={true}
                    />
                    <h5>City</h5>
                    <input
                      className="k-textbox"
                      name="relation_city"
                      style={{ width: "450px" }}
                      value={this.state.relation_city || ""}
                      disabled={true}
                    />
                  </div>
                ) : null}
                <br />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <button
                    style={{ justifyContent: "flex-start" }}
                    type="button"
                    className="k-button k-primary"
                    onClick={(e) => {
                      this.setState({
                        ...this.state,
                        visibleWindow: false,
                        open: false,
                        openDataItem: {},
                        relation_postal_code: "",
                        relation_country: "",
                        relation_city: "",
                      });
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    style={{ justifyContent: "flex-end" }}
                    type="button"
                    className="k-button k-primary"
                    onClick={(e) => this.saveAgent(e)}
                    disabled={this.state.valueAgentName !== "" ? false : true}
                  >
                    Save
                  </button>
                </div>
              </fieldset>
            </div>
          </Window>
        )}
      </div>
    );
  }
}

export default Departments;
