import React from "react";
import { columnsSchema } from "./colSchema";
import { MyCommandCell } from "../../../../Clients/View/components/CellHOC";
import { serverApi } from "../../../../../networking/config";
import _ from "lodash";
import { GridColumn as Column } from "@progress/kendo-react-grid/dist/npm/GridColumn";
import { Grid, GridToolbar } from "@progress/kendo-react-grid";
import GridContainer from "../../../../../components/Grid/GridContainer";
import { IconButton, Tooltip } from "@material-ui/core";
import { DropDownCell } from "../../../../CRM/View/dropDownCell";
import { AutoComplete2 } from "../../../../Vessels/View/components/relationNameDropDown";
import { Window } from "@progress/kendo-react-dialogs";
import BlockUi from "react-block-ui";
import { Error } from "@progress/kendo-react-labels";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import ViewSettings from "../../../../common/ViewSettings";
import ErrorModal from "../../../../../components/ErrorModal";
import RegularButton from "../../../../../components/CustomButtons/Button";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class UsersToDepartment extends React.Component {
  editField = "inEdit";
  CommandCell;
  _export;

  constructor(props) {
    super(props);

    this.state = {
      blockingMessage: "Loading, please wait. . .",
      visibleWindow: false,
      isDropOpened: false,
      columns: (columnsSchema && columnsSchema()) || [],
      gridData: {
        data: [],
        total: 10,
      },
      initialGridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 10,
        skip: 0,
      },
      openErrorModal: false,
      errorModalContent: "",
      delay: 400,
      loading: false,
      usernames: [],
      windowError: false,
      windowErrorMessage: "",
      disableEditButton: true,
      inputValue: "",
      clientAttachedToAnotherGroup: null,
      exportData: [],
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      disableEditButton: false,
      showSecondAction: false,
      showFirstAction: false,
    });
  }

  async componentDidMount() {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    const deptId = this.props.dataItem.id;
    let fil = {
      filter: {
        where: {
          deptID: deptId,
        },
        include: [
          {
            relation: "snUser",
            scope: {
              include: [
                {
                  relation: "userToDepts",
                },
              ],
            },
          },
        ],
      },
    };
    let usersOfDepartment = await serverApi(
      "GET",
      `userToDepts`,
      fil,
      "",
      options
    );

    let allUsers = {
      data: [],
    };

    // let allUsers = await serverApi('GET', `snUsers`, {
    //   filter: {
    //     include: [
    //       {
    //         relation: "userToDepts",
    //         scope: {
    //           include: [
    //             {
    //               relation: "department"
    //             },
    //           ],
    //         },
    //       },
    //     ]
    //   }
    // }, '', options)

    let howMany = await serverApi("GET", "snUsers/count", "", "", "", options);
    const numberOfCalls = Math.min(
      Math.round(howMany.data.count / 1000) + 1,
      5 // Limit to 5000
    );

    const promises = [];
    for (let i = 0; i < numberOfCalls; i++) {
      promises.push(
        this.getUsersPromise({
          skip: i * 1000,
          limit: 1000,
          order: ["id asc"],
          include: [
            {
              relation: "userToDepts",
              scope: {
                include: [
                  {
                    relation: "department",
                  },
                ],
              },
            },
          ],
        })
      );
    }

    try {
      let responses = await Promise.all(promises);
      responses.length && responses.map((x) => x.data).flat();

      responses.forEach((resp) => {
        _.forEach(resp.data, (temp) => {
          allUsers.data.push({
            ...temp,
          });
        });
      });
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent: e.response.data.error.message || "Please try again",
      });
    }

    let usernames =
      allUsers.data
        .sort((a, b) => (a.username > b.username ? 1 : -1))
        .map((x) => x.username) || [];

    //REMOVES FROM checkbox lists the users having primary department the same as the expanded department
    // let expandedUsersWithPrimaryDepartment = (usersOfDepartment.data || []).filter(x => x.primary_flg === true)?.map(x=> x.snUser.username) || []
    // expandedUsersWithPrimaryDepartment.forEach(usr => {
    //   allUsers.data = allUsers.data.filter(x => x.username !== usr)
    // })

    let existingUsersToDepartment = [];
    let result = this.constructUsersAndExistingUsersArray(
      allUsers.data,
      usersOfDepartment.data,
      deptId
    );
    result[0].finalAttachedUsers.forEach((item) => {
      let user = result[0].finalUsers.find((x) => x.id === item.id);
      if (user) {
        user.checked = true;
        existingUsersToDepartment.push(user);
      }
    });

    this.setState({ ...this.state, usersOfDepartment: usersOfDepartment.data });

    if (usersOfDepartment && usersOfDepartment.data.length > 1) {
      let finalData = [];
      _.forEach(usersOfDepartment.data, (temp) => {
        finalData.push({
          id: temp.userID,
          primary_flg: temp.primary_flg,
          username: temp.snUser.username,
          userRealName: temp.snUser.userRealName,
          email: temp.snUser.email || "N/A",
          // bugify_token: temp.snUser.bugify_token || 'N/A',
          created: temp.snUser.created || "N/A",
          last_login_date: temp.snUser.last_login_date || "N/A",
          deleteID: temp.id,
        });
      });
      finalData.sort((a, b) => (a.username > b.username ? 1 : -1));

      this.setState({
        ...this.state,
        existingUsersToDepartment: existingUsersToDepartment,
        deptID: this.props.dataItem.id,
        gridData: {
          data: finalData,
          total: finalData.length,
        },
        initialGridData: {
          data: finalData,
          total: finalData.length,
        },
        user_names: usernames,
        valueUserName: "",

        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    } else if (usersOfDepartment && usersOfDepartment.data.length === 1) {
      let finalData = [];
      _.forEach(usersOfDepartment.data, (temp) => {
        finalData.push({
          id: temp.userID,
          primary_flg: temp.primary_flg,
          username: temp.snUser.username,
          userRealName: temp.snUser.userRealName,
          email: temp.snUser.email || "N/A",
          // bugify_token: temp.snUser.bugify_token || 'N/A',
          created: temp.snUser.created || "N/A",
          last_login_date: temp.snUser.last_login_date || "N/A",
          deleteID: temp.id,
        });
      });
      finalData.sort((a, b) => (a.username > b.username ? 1 : -1));

      this.setState({
        ...this.state,
        existingUsersToDepartment: existingUsersToDepartment,
        deptID: this.props.dataItem.id,
        gridData: {
          data: [finalData[0]],
          total: 1,
        },
        initialGridData: {
          data: [finalData[0]],
          total: 1,
        },
        user_names: usernames,
        valueUserName: "",
        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    } else {
      this.setState({
        ...this.state,
        existingUsersToDepartment: existingUsersToDepartment,
        deptID: this.props.dataItem.id,
        gridData: {
          data: [],
          total: 0,
        },
        initialGridData: {
          data: [],
          total: 0,
        },
        user_names: usernames,
        valueUserName: "",
        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    }
  }

  getUsersPromise = (filter) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    return new Promise((resolve, reject) => {
      const req = serverApi(
        "GET",
        `snUsers?filter=${JSON.stringify(filter)}`,
        "",
        "",
        options
      );
      req
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  componentWillUnmount() {}

  constructUsersAndExistingUsersArray = (
    allUsers,
    attachedUsers,
    expandedDepartment
  ) => {
    let finalUsers = [];
    _.forEach(allUsers, (temp1) => {
      const primaryDepartment =
        ((temp1.userToDepts || []).filter((x) => x.primary_flg === true)[0] &&
          (temp1.userToDepts || []).filter((x) => x.primary_flg === true)[0]
            ?.department?.name) ||
        "N/A";
      const primaryDepartmentId =
        ((temp1.userToDepts || []).filter((x) => x.primary_flg === true)[0] &&
          (temp1.userToDepts || []).filter((x) => x.primary_flg === true)[0]
            ?.department?.id) ||
        "N/A";

      if (primaryDepartment === "N/A") return;
      let isDisabled =
        expandedDepartment === primaryDepartmentId ? true : false;
      let finalName =
        `Username: ${temp1.username} - Primary department: ${primaryDepartment}` ||
        "N/A";
      if (isDisabled) finalName = `${finalName} - Cannot be removed`;
      finalUsers.push({
        name: finalName,
        id: temp1.id,
        checked: false,
        username: temp1.username,
        deptIDs: (temp1.userToDepts || []).map((x) => x.deptID),
        primaryDeptID: primaryDepartmentId,
        disabled: isDisabled,
      });
    });

    let finalAttachedUsers = [];
    _.forEach(attachedUsers, (temp2) => {
      const primaryDepartmentId =
        ((temp2.snUser.userToDepts || []).filter(
          (x) => x.primary_flg === true
        )[0] &&
          (temp2.snUser.userToDepts || []).filter(
            (x) => x.primary_flg === true
          )[0].deptID) ||
        "N/A";

      finalAttachedUsers.push({
        name: temp2.snUser.username || "N/A",
        deptId: temp2.deptID,
        checked: true,
        deleteID: temp2.id,
        id: temp2.snUser.id,
        deptIDs: (temp2.snUser.userToDepts || []).map((x) => x.deptID),
        primaryDeptID: primaryDepartmentId,
      });
    });

    finalUsers.sort((a, b) => (a.name > b.name ? 1 : -1));
    finalAttachedUsers.sort((a, b) => (a.name > b.name ? 1 : -1));

    return [
      {
        finalUsers: finalUsers,
        finalAttachedUsers: finalAttachedUsers,
      },
    ];
  };

  fetchLatestData = async () => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    const deptId = this.props.dataItem.id;
    let fil = {
      filter: {
        where: {
          deptID: deptId,
        },
        include: [
          {
            relation: "snUser",
            scope: {
              include: [
                {
                  relation: "userToDepts",
                },
              ],
            },
          },
        ],
      },
    };
    let usersOfDepartment = await serverApi(
      "GET",
      `userToDepts`,
      fil,
      "",
      options
    );
    let allUsers = await serverApi(
      "GET",
      `snUsers`,
      {
        filter: {
          include: [
            {
              relation: "userToDepts",
              scope: {
                include: [
                  {
                    relation: "department",
                  },
                ],
              },
            },
          ],
        },
      },
      "",
      options
    );
    let usernames =
      allUsers.data
        .sort((a, b) => (a.username > b.username ? 1 : -1))
        .map((x) => x.username) || [];

    //REMOVES FROM checkbox lists the users having primary department the same as the expanded department
    // let expandedUsersWithPrimaryDepartment = (usersOfDepartment.data || []).filter(x => x.primary_flg === true)?.map(x=> x.snUser.username) || []
    // expandedUsersWithPrimaryDepartment.forEach(usr => {
    //   allUsers.data = allUsers.data.filter(x => x.username !== usr)
    // })

    let result = this.constructUsersAndExistingUsersArray(
      allUsers.data,
      usersOfDepartment.data,
      deptId
    );
    result[0].finalAttachedUsers.forEach((item) => {
      let user = result[0].finalUsers.find((x) => x.id === item.id);
      if (user) {
        user.checked = true;
      }
    });

    this.setState({ ...this.state, usersOfDepartment: usersOfDepartment.data });

    if (usersOfDepartment && usersOfDepartment.data.length > 1) {
      let finalData = [];
      _.forEach(usersOfDepartment.data, (temp) => {
        finalData.push({
          id: temp.userID,
          primary_flg: temp.primary_flg,
          username: temp.snUser.username,
          userRealName: temp.snUser.userRealName,
          email: temp.snUser.email || "N/A",
          // bugify_token: temp.snUser.bugify_token || 'N/A',
          created: temp.snUser.created || "N/A",
          last_login_date: temp.snUser.last_login_date || "N/A",
          deleteID: temp.id,
        });
      });
      finalData.sort((a, b) => (a.username > b.username ? 1 : -1));

      this.setState({
        ...this.state,
        deptID: this.props.dataItem.id,
        gridData: {
          data: finalData,
          total: finalData.length,
        },
        initialGridData: {
          data: finalData,
          total: finalData.length,
        },
        user_names: usernames,
        valueUserName: "",

        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    } else if (usersOfDepartment && usersOfDepartment.data.length === 1) {
      let finalData = [];
      _.forEach(usersOfDepartment.data, (temp) => {
        finalData.push({
          id: temp.userID,
          primary_flg: temp.primary_flg,
          username: temp.snUser.username,
          userRealName: temp.snUser.userRealName,
          email: temp.snUser.email || "N/A",
          // bugify_token: temp.snUser.bugify_token || 'N/A',
          created: temp.snUser.created || "N/A",
          last_login_date: temp.snUser.last_login_date || "N/A",
          deleteID: temp.id,
        });
      });
      finalData.sort((a, b) => (a.username > b.username ? 1 : -1));

      this.setState({
        ...this.state,
        deptID: this.props.dataItem.id,
        gridData: {
          data: [finalData[0]],
          total: 1,
        },
        initialGridData: {
          data: [finalData[0]],
          total: 1,
        },
        user_names: usernames,
        valueUserName: "",
        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    } else {
      this.setState({
        ...this.state,
        deptID: this.props.dataItem.id,
        gridData: {
          data: [],
          total: 0,
        },
        initialGridData: {
          data: [],
          total: 0,
        },
        user_names: usernames,
        valueUserName: "",
        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    }
  };

  toggleWindow = (e) => {
    const data = this.state.initialGridData.data;

    this.setState({
      ...this.state,
      visibleWindow: !this.state.visibleWindow,
      gridData: {
        data: [...data],
        total: 100,
      },
      client_names: [],
      valueClientName: "",
      windowError: false,
      windowErrorMessage: "",
      openErrorModal: false,
      errorModalContent: "",
      // inputValue:'',
      // initialFinalUserClients: initialFinalUserClients
    });
  };

  renderGridColumns = (dataItem, changeTheCellComponent) => {
    let selectionCol = [];
    let normalCols = [];
    let finalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            width="70px"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.isFilterBoolean === "yes") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"420"}
              cell={DropDownCell}
            />
          );
        } else if (temp.field === "masterEntity.relation_name") {
          return (
            <Column
              field="masterEntity.relation_name"
              title="NAME"
              width="400px"
              cell={AutoComplete2}
            />
          );
        } else {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={temp.field === "id" || temp.editable ? false : true} //id not editable on edit
            />
          );
        }
      });

    finalCols = [...selectionCol, ...normalCols];
    return finalCols;
  };

  handleSearch = (e) => {
    let initialFinalUserClients = this.state.initialFinalUserClients;

    if (e.value === "") {
      let searchArr = [];
      initialFinalUserClients.forEach((temp) => {
        if (temp.name.toUpperCase().includes(e.value.toUpperCase())) {
          searchArr.push(temp);
        }
      });

      if (
        this.state.clientAttachedToAnotherGroup !== null &&
        this.state.clientAttachedToAnotherGroup !== undefined
      ) {
        let newArr = searchArr.filter(
          (t) => t.id !== this.state.clientAttachedToAnotherGroup
        );
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalUserClients: newArr,
          openErrorModal: false,
          errorModalContent: "",
        });
      } else {
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalUserClients: initialFinalUserClients,
          openErrorModal: false,
          errorModalContent: "",
        });
      }
    } else {
      let searchArr = [];
      //case insensitive
      initialFinalUserClients.forEach((temp) => {
        if (temp && temp.name.toUpperCase().includes(e.value.toUpperCase())) {
          searchArr.push(temp);
        }
      });

      if (
        this.state.clientAttachedToAnotherGroup !== null &&
        this.state.clientAttachedToAnotherGroup !== undefined
      ) {
        let newArr = searchArr.filter(
          (t) => t.id !== this.state.clientAttachedToAnotherGroup
        );
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalUserClients: newArr,
          openErrorModal: false,
          errorModalContent: "",
        });
      } else {
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalUserClients: searchArr,
          openErrorModal: false,
          errorModalContent: "",
        });
      }
    }
  };

  handleSuccessSnackbar = () => {
    this.setState({
      ...this.state,
      successMessage:
        this.state.openSuccessMessage === true ? this.state.successMessage : "",
      openSuccessMessage: !this.state.openSuccessMessage,
    });
  };

  remove = async (dataItem) => {
    // REMOVED BY NEW FUNCTIONALITY OF bulkUpdate endpoint
    //check for not letting primary dept to be deleted
    // if (dataItem.primary_flg === true) {
    //   this.setState({
    //     ...this.state,
    //     openErrorModalMui: true,
    //     errorModalContentMui: 'Firstly set another primary department and then remove this department.',
    //   }, () => {
    //     setTimeout(() => {
    //       this.setState({
    //         ...this.state,
    //         openErrorModalMui: false,
    //         errorModalContentMui: '',
    //       })
    //     }, 5000)
    //   })
    // } else {
    //   const data = [...this.state.gridData.data];
    //   let index = data.findIndex(p => p === dataItem || dataItem.id && p.id === dataItem.id);
    //   if (index >= 0) {
    //     data.splice(index, 1);
    //   }
    //   let options = {
    //     token: window.localStorage.getItem('access_token')
    //   };
    //   let userId = dataItem.id
    //   let deptId = this.props.dataItem.id
    //   let delete_ID = dataItem.deleteID
    //   let url = `userToDepts/${delete_ID}`
    //   try {
    //     const removeUserFromDepartment = await serverApi(`DELETE`, url, '', '', options)
    //     if (removeUserFromDepartment.status === 200) {
    //       let findUser = this.state.visibleClients.find(x => x.id === dataItem.id)
    //       if (findUser) {
    //         findUser.checked = false
    //       }
    //       this.setState({
    //         ...this.state,
    //         gridData: {
    //           data: data,
    //           total: this.state.gridData.total
    //         },
    //         initialGridData: {
    //           data: data,
    //           total: this.state.gridData.total
    //         }
    //       }, () => {
    //         this.fetchLatestData()
    //       });
    //     }
    //   } catch (e) {
    //     this.setState({
    //       ...this.state,
    //       openErrorModal: true,
    //       errorModalContent: e?.response?.data?.error?.message || "Please try again"
    //     })
    //   }
    // }
  };

  // fetchLatestUserDepartments = async () => {
  //   let options = {
  //     token: window.localStorage.getItem('access_token')
  //   };

  //   const deptId = this.props.dataItem.id
  //   let fil = {
  //     filter: {
  //       where: {
  //         deptID: deptId
  //       },
  //       include: ["snUser"]
  //     }
  //   }
  //   let usersOfDepartment = await serverApi('GET', `userToDepts`, fil, '', options);
  //   let allUsers = await serverApi('GET', `snUsers`, '', '', options)
  //   let usernames = allUsers.data.sort((a, b) => (a.username > b.username) ? 1 : -1).map(x => x.username) || []

  //   let result = this.constructUsersAndExistingUsersArray(allUsers.data, usersOfDepartment.data)
  //   result[0].finalAttachedUsers.forEach(item => {
  //     let user = result[0].finalUsers.find(x => x.id === item.id)
  //     if (user) {
  //       user.checked = true
  //     }
  //   })

  //   if (usersOfDepartment && usersOfDepartment.data.length > 1) {
  //     let finalData = []
  //     _.forEach(usersOfDepartment.data, temp => {
  //       finalData.push({
  //         id: temp.userID,
  //         primary_flg: temp.primary_flg,
  //         username: temp.snUser.username,
  //         userRealName: temp.snUser.userRealName,
  //         email: temp.snUser.email || 'N/A',
  //         // bugify_token: temp.snUser.bugify_token || 'N/A',
  //         created: temp.snUser.created || 'N/A',
  //         last_login_date: temp.snUser.last_login_date || 'N/A',
  //         deleteID: temp.id
  //       })
  //     })
  //     finalData.sort((a, b) => (a.username > b.username) ? 1 : -1)

  //     this.setState({
  //       ...this.state,
  //       deptID: this.props.dataItem.id,
  //       gridData: {
  //         data: finalData,
  //         total: finalData.length
  //       },
  //       initialGridData: {
  //         data: finalData,
  //         total: finalData.length
  //       },
  //       user_names: usernames,
  //       valueUserName: "",

  //       visibleClients: result[0].finalUsers || [],
  //       finalUserClients: result[0].finalUsers || [],
  //       initialFinalUserClients: result[0].finalUsers || [],
  //       blocking: false
  //     })
  //   } else if (usersOfDepartment && usersOfDepartment.data.length === 1) {
  //     let finalData = []
  //     _.forEach(usersOfDepartment.data, temp => {
  //       finalData.push({
  //         id: temp.userID,
  //         primary_flg: temp.primary_flg,
  //         username: temp.snUser.username,
  //         userRealName: temp.snUser.userRealName,
  //         email: temp.snUser.email || 'N/A',
  //         // bugify_token: temp.snUser.bugify_token || 'N/A',
  //         created: temp.snUser.created || 'N/A',
  //         last_login_date: temp.snUser.last_login_date || 'N/A',
  //         deleteID: temp.id
  //       })
  //     })
  //     finalData.sort((a, b) => (a.username > b.username) ? 1 : -1)

  //     this.setState({
  //       ...this.state,
  //       deptID: this.props.dataItem.id,
  //       gridData: {
  //         data: [finalData[0]],
  //         total: 1
  //       },
  //       initialGridData: {
  //         data: [finalData[0]],
  //         total: 1
  //       },
  //       user_names: usernames,
  //       valueUserName: "",
  //       visibleClients: result[0].finalUsers || [],
  //       finalUserClients: result[0].finalUsers || [],
  //       initialFinalUserClients: result[0].finalUsers || [],
  //       blocking: false
  //     })

  //   } else {
  //     this.setState({
  //       ...this.state,
  //       deptID: this.props.dataItem.id,
  //       gridData: {
  //         data: [],
  //         total: 0
  //       },
  //       initialGridData: {
  //         data: [],
  //         total: 0
  //       },
  //       user_names: usernames,
  //       valueUserName: "",
  //       visibleClients: result[0].finalUsers || [],
  //       finalUserClients: result[0].finalUsers || [],
  //       initialFinalUserClients: result[0].finalUsers || [],
  //       blocking: false
  //     })
  //   }
  // }

  renderList = (finalUsers = [], users = [], doRefresh) => {
    if (doRefresh === true) {
      window.location.reload();
    } else {
      if (finalUsers.length === users.length) {
        return (
          users &&
          users.map((item) => (
            <ul style={{ marginBottom: "-5px" }} key={item.id}>
              <Checkbox
                name={item.name}
                key={item.id}
                value={item.checked}
                onChange={(e) => this.handleChange(e, item.name, item.id)}
                label={item.name}
                disabled={item.disabled || false}
              />
            </ul>
          ))
        );
      } else {
        return (
          finalUsers &&
          finalUsers.map((item) => (
            <ul style={{ marginBottom: "-5px" }} key={item.id}>
              <Checkbox
                name={item.name}
                key={item.id}
                value={item.checked}
                onChange={(e) => this.handleChange(e, item.name, item.id)}
                label={item.name}
                disabled={item.disabled || false}
              />
            </ul>
          ))
        );
      }
    }
  };

  handleChange = async (event, name, key) => {
    let finalUserClient = [...this.state.finalUserClients];
    let finalObj = finalUserClient.find((test) => test.id === key);

    let action = event.value === false ? "DELETE" : "POST";
    if (action === "POST") {
      let insertedDept = this.props.dataItem.id || this.state.deptID;
      finalObj.deptIDs.push(insertedDept);
    } else if (action === "DELETE") {
      let index = finalObj.deptIDs.indexOf(this.props.dataItem.id);
      if (index !== -1) {
        finalObj.deptIDs.splice(index, 1);
        finalObj.forPayload = true;
      } else {
        finalObj.forPayload = false;
      }
    }

    finalObj.checked = !finalObj.checked;

    this.setState(
      {
        ...this.state,
        finalUserClients: finalUserClient,
      },
      async () => {}
    );
  };

  saveNewDepartments = async () => {
    const finalUsers = [
      ...this.state.finalUserClients.filter((x) => x.checked === true),
      ...this.state.finalUserClients.filter((x) => x.forPayload === true),
    ]; //, ...this.state.finalUserClients.filter(x => x.forPayload === true)
    const initialUsers = this.state.initialFinalUserClients.filter(
      (x) => x.checked
    );
    const final = [...finalUsers, ...initialUsers];
    const uniqueData = final.filter(
      (thing, index, self) => index === self.findIndex((t) => t.id === thing.id)
    );
    let result = uniqueData.filter(
      (thing, index, self) => index === self.findIndex((t) => t.id === thing.id)
    );
    result = [
      ...result.filter((x) => x.forPayload),
      ...result
        .filter(
          ({ name }) =>
            !this.state.existingUsersToDepartment.some((e) => e.name === name)
        )
        .filter((x) => x.checked),
    ];
    result = result.map((x) => {
      return {
        ...x,
        userID: x.id,
      };
    });

    let payload = {
      data: [],
    };

    (result || []).forEach((usr) => {
      payload.data.push({
        userID: usr.id,
        deptIDs: usr.deptIDs,
        primaryDeptID: usr.primaryDeptID,
      });
    });

    const promises = [];
    const batchSize = 5;

    let numberOfCalls = Math.ceil(result.length / batchSize);

    function sliceIntoChunks(arr, chunkSize) {
      const res = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
      }
      return res;
    }

    let payloadForPromises = sliceIntoChunks(result, batchSize);

    for (let i = 0; i < numberOfCalls; i++) {
      promises.push(
        this.constructPromise({
          data: payloadForPromises[i],
        })
      );
    }

    try {
      this.setState({
        ...this.state,
        blocking: true,
        blockingMessage: "Please wait a few seconds...",
      });

      const responses = await Promise.allSettled(promises);
      if (responses) {
        if (responses.some((x) => x.status !== "rejected")) {
          this.setState(
            {
              ...this.state,
              blocking: false,
              blockingMessage: "",
              inputValue: "",
              visibleWindow: false,
            },
            async () => {
              await this.props.openSuccessModal(
                "The process has finished successfully."
              );
              await this.fetchLatestData();
            }
          );
        } else {
          this.setState(
            {
              ...this.state,
              blocking: false,
              blockingMessage: "",
              inputValue: "",
              visibleWindow: false,
            },
            async () => {
              await this.props.openSuccessModal(
                "The process was partially finished ,please refresh the page and make the appropriate actions."
              );
              await this.fetchLatestData();
            }
          );
        }
      }
    } catch (error) {
      this.setState(
        {
          ...this.state,
          windowErrorMessage:
            error?.response?.data?.error?.message ||
            "Process failed please try again.If the problem persist contact the administrator.",
          windowError: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              ...this.state,
              windowErrorMessage: "",
              windowError: false,
            });
          }, 5000);
        }
      );
    }
  };

  constructPromise = async (payload) => {
    const url = `userToDepts/bulkUpdate`;
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    return await serverApi("POST", url, "", payload, options, "", "");
  };

  getExportData = async () => {
    await this.exportToCsv(this.state, this.props);
  };

  getPromise = () => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    const deptId = this.props.dataItem.id;
    let fil = {
      filter: {
        where: {
          deptID: deptId,
        },
        include: ["snUser"],
      },
    };

    return new Promise((resolve, reject) => {
      const usersOfDepartment = serverApi(
        "GET",
        `userToDepts`,
        fil,
        "",
        options
      );
      usersOfDepartment
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  exportToCsv = async (state, props) => {
    const count = state.gridData.total;
    const numberOfCalls = Math.min(
      Math.round(count / 1000) + 1,
      5 // Limit to 5000
    );

    const promises = [];
    for (let i = 0; i < numberOfCalls; i++) {
      promises.push(
        this.getPromise({
          skip: i * 1000,
          limit: 1000,
          order: ["id asc"],
        })
      );
    }
  };

  closeErrorModalMui = () => {
    this.setState({ openErrorModalMui: false, errorModalContentMui: "" });
  };

  render() {
    const dataItem = this.props.dataItem;
    const { gridData } = this.state;
    const { inputValue } = this.state;

    return (
      <div>
        <div>
          <h4 style={{ fontFamily: "bold", textSize: "7px" }}>
            Department name : {dataItem && dataItem.name}
          </h4>
          {this.state.openErrorModal ? (
            <Error style={{ color: "red", fontSize: "15px" }}>
              {this.state.errorModalContent || ""}
            </Error>
          ) : null}
          {this.state.windowError ? (
            <Error style={{ color: "red" }}>
              <h3>{this.state.windowErrorMessage || ""}</h3>
            </Error>
          ) : null}
        </div>

        <Grid
          {...(gridData || [])}
          filterable={false}
          style={{ className: "grid-no-select" }}
          sortable={true}
          resizable
          editField={this.editField}
          onItemChange={this.itemChange}
          pageable={false}
        >
          <GridToolbar>
            <GridContainer xs={12} justify={"flex-start"} direction={"row"}>
              <Tooltip
                justify={"flex-start"}
                placement="top"
                title={"Add/Remove User"}
              >
                <IconButton onClick={this.toggleWindow}>
                  <FontAwesomeIcon
                    color="#0D5869"
                    onClick={this.toggleWindow}
                    size="1.6x"
                    icon={faPencilAlt}
                  />
                </IconButton>
              </Tooltip>
              <ViewSettings
                {...this.props}
                export={{
                  data: this.state.exportData || [],
                  fileName: "Users_",
                  exportFunction: this.getExportData,
                  columns: columnsSchema(),
                }}
                viewSettings={{
                  type: "",
                }}
                // refreshSettings={true}
                // refreshFunction={this.refreshGridDataAfterCrud}
              />
            </GridContainer>
          </GridToolbar>

          {/* <Column cell={this.CommandCell} title="Actions" filterable={false} width="200px" /> */}
          {this.renderGridColumns(dataItem)}
        </Grid>

        <ErrorModal
          open={this.state.openErrorModalMui}
          onCloseModal={this.closeErrorModalMui}
          modalContent={this.state.errorModalContentMui}
          timeout={5000}
        />

        {this.state.visibleWindow && (
          <Window
            title={`Add/Remove Users from Department: ${this.props.dataItem.name}`}
            // style={{backgroundColor:'#0d5869'}}
            onClose={this.toggleWindow}
            width={900}
            height={600}
            initialTop={110}
          >
            <>
              <BlockUi
                tag="div"
                blocking={this.state.blocking}
                message={this.state.blockingMessage}
                renderChildren={true}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "50px",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      style={{
                        position: "sticky",
                        top: "0px",
                        zIndex: 2,
                        backgroundColor: "ffff",
                      }}
                    >
                      <RegularButton
                        style={{ backgroundColor: "#0d5869" }}
                        roundSmall="true"
                        onClick={this.saveNewDepartments}
                        size="sm"
                        // disabled={this.state.disableSaveNewDepartments || false}
                      >
                        Save changes
                      </RegularButton>
                    </div>
                    <Input
                      name="users"
                      value={inputValue}
                      style={{ marginBottom: "20px" }}
                      label="Search user"
                      onChange={(e) => this.handleSearch(e)}
                      minLength={2}
                    />

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {this.renderList(
                        this.state.finalUserClients,
                        this.state.visibleClients
                      )}
                    </div>
                  </div>
                </div>
              </BlockUi>
            </>
            {this.state.windowError ? (
              <Error style={{ color: "red" }}>
                <h3>{this.state.windowErrorMessage || ""}</h3>
              </Error>
            ) : null}
          </Window>
        )}
      </div>
    );
  }
}
