export const columnsSchema = () => [
  {
    field: "id",
    title: "ID",
    visible: true,
    minWidth: 170,
    sortable: true,
    filterable: true,
    locked: true,
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
  },
  {
    field: "modelName",
    title: "Model Name",
    visible: true,
    // minWidth: 250,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    locked: true,
  },
  {
    field: "settingName",
    title: "Setting Name ",
    visible: true,
    // minWidth: 600,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    locked: true,
  },
  {
    field: "description",
    title: "Descriptions",
    visible: true,
    // minWidth: 700,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    locked: true,
  },
];
