export const columnsSchema = () => [
  {
    field: "id",
    title: "ID",
    visible: true,
    minWidth: 190,
    sortable: true,
    filterable: true,
    locked: true,
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
  },
  {
    field: "relation_name",
    title: "Name",
    visible: true,
    minWidth: 230,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    locked: true,
  },
  {
    field: "relation_address",
    title: "Address",
    visible: true,
    minWidth: 230,
    sortable: true,
    filterable: true,
    noDisabledColumn: false,
    notShownInTableColumnSelection: false,
    locked: false,
  },
  {
    field: "relation_postal_code",
    title: "Postal",
    visible: true,
    minWidth: 180,
    sortable: true,
    filterable: true,
    locked: false,
  },
  {
    field: "relation_city",
    title: "City",
    visible: true,
    minWidth: 220,
    sortable: true,
    filterable: true,
    locked: false,
    notShownInTableColumnSelection: false,
  },
  {
    field: "relation_country",
    title: "Country",
    visible: true,
    minWidth: 220,
    sortable: true,
    filterable: true,
    locked: false,
    isDropdown: "no",
  },
  {
    field: "state",
    title: "State",
    visible: true,
    minWidth: 200,
    sortable: true,
    filterable: true,
    noDisabledColumn: false,
    isDropdown: "yes",
    locked: false,
  },
  {
    field: "relation_tel",
    title: "Telephone",
    visible: true,
    sortable: true,
    minWidth: 210,
    filterable: true,
    locked: false,
  },
  {
    field: "relation_mail",
    title: "Email",
    visible: true,
    sortable: true,
    minWidth: 210,
    filterable: true,
    locked: false,
  },
  {
    field: "createdByForUi",
    title: "Created By",
    visible: true,
    minWidth: 200,
    sortable: true,
    filterable: false,
    locked: false,
    notShownInTableColumnSelection: false,
    editable: "false",
  },
  {
    field: "dateCreatedForUi",
    title: "Created Date",
    visible: true,
    minWidth: 200,
    sortable: true,
    filterable: false,
    locked: false,
    notShownInTableColumnSelection: false,
    editable: "false",
  },
];
