import React from "react";
import ReactDOM from "react-dom";
import { toODataString } from "@progress/kendo-data-query";
import { serverApi } from "../../../../src/networking/config";
import _ from "lodash";

export class GridLoader extends React.Component {
  lastSuccess = "";
  pending = "";

  requestDataIfNeeded = async () => {
    if (
      this.pending ||
      toODataString(this.props.dataState) === this.lastSuccess
    ) {
      return;
    }
    this.pending = toODataString(this.props.dataState);

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let params = { ...this.props.dataState };

    params["skip"] = this.props.dataState.skip;
    params["limit"] = this.props.dataState.take;
    params["where"] =
      this.props.dataState.filter !== null ? this.props.dataState.filter : {};

    if (!_.isEmpty(this.props.dataState.sort)) {
      params["order"] =
        this.props.dataState.sort[0].field +
        " " +
        this.props.dataState.sort[0].dir;
      delete params.sort;
    }

    delete params.take;
    let filterColumn =
      params && (params.filter === null || params.filter === undefined)
        ? ""
        : params.filter.filters[0] !== null
        ? params.filter.filters[0].field
        : "";

    let filterValue =
      params && (params.filter === null || params.filter === undefined)
        ? ""
        : params.filter.filters[0] !== null
        ? params.filter.filters[0].value
        : "";

    // let finalFilterValue = null;

    // if (params.filter) {
    //   finalFilterValue = (params.filter.filters[0].field === 'reqPriorityID')
    //     ? _.find([], ['lov_value', `${filterValue}`]).id
    //     : filterValue;
    // } else {
    //   finalFilterValue = '';
    // }

    // const includeParam = !_.isEmpty(this.state[domain].includeArray) ? this.state[domain].includeArray : '';
    let whereFinal = [];
    let booleanColumns = [
      "supplier_flag",
      "client_flag",
      "transport_flag",
      "agent_flag",
      "sm_agent_flag",
      "sm_vendor_flag",
      "location_flag",
    ];

    if (params.filter === null || params.filter === undefined) {
      whereFinal.push({});
    } else {
      if (params.filter.filters.length > 1) {
        _.forEach(params.filter.filters, (temp) => {
          //fixes the issue with & when searching...fucking loopback 3
          let finalValue = temp.value;
          if (
            (typeof finalValue === "string" || finalValue instanceof String) &&
            finalValue.includes("&")
          ) {
            finalValue = temp.value.replace("&", ".");
          } else if (typeof finalValue === "boolean") {
            finalValue = temp.value;
          }

          if (booleanColumns.includes(temp.field)) {
            whereFinal.push({ [`${temp.field}`]: `${finalValue}` });
          } else {
            whereFinal.push({
              [`${temp.field}`]: {
                regexp: `/^${finalValue}/i`,
              },
            });
          }
        });
      } else {
        let isFirstLoad = window.localStorage.getItem("isFirstLoad");
        if (booleanColumns.includes(filterColumn) && isFirstLoad === "true") {
          whereFinal = [
            {
              [`${filterColumn}`]: `${filterValue}`,
            },
          ];
        } else {
          //fixes the issue with & when searching...fucking loopback 3
          let finalValue = filterValue;
          if (finalValue.includes("&")) {
            finalValue = filterValue.replace("&", ".");
          }
          whereFinal = [
            {
              [`${filterColumn}`]: {
                regexp: `/^${finalValue}/i`,
              },
            },
          ];
        }
      }
    }

    let params2 = {
      filter: {
        // include: ["roles","departments"],
        // fields: [],
        where: {
          and: whereFinal,
        },
        order: [`${params.order}` !== [] ? `${params.order}` : []],
        limit: `${params.limit}`,
        skip: `${params.skip}`,
      },
    };

    if (params2.filter.order[0] === "undefined") {
      delete params2.filter.order;
    }

    if (this.props.include) {
      params2.filter.include = this.props.include;
    }

    let finalParams = { ...params2 };
    const url = "snuserWarehouseVs";
    window.localStorage.setItem(
      "exportFilter",
      JSON.stringify(finalParams.filter.where)
    );

    let responseData = await serverApi(
      "GET",
      `${url}`,
      finalParams,
      "",
      options
    );

    let count;
    if (this.props.dataState.filter) {
      let x = JSON.stringify(this.props.dataState.filter);
      let t = JSON.stringify(
        JSON.parse(window.localStorage.getItem("finalParams")).filter
      );

      if (x !== t) {
        const countMasterEntity = await serverApi(
          "GET",
          `${url}/count`,
          "",
          "",
          options
        );
        if (countMasterEntity) {
          count = countMasterEntity.data.count;
        }
      } else {
        const countMasterEntity = await serverApi(
          "GET",
          `snuserWarehouseVs/count?where=${JSON.stringify(
            params2.filter.where
          )}`,
          "",
          "",
          options
        );
        if (countMasterEntity) {
          count = countMasterEntity.data.count;
        }
      }
    } else {
      if (true) {
        const countMasterEntity = await serverApi(
          "GET",
          `${url}/count`,
          "",
          "",
          options
        );
        if (countMasterEntity) {
          count = countMasterEntity.data.count;
        }
      }
    }

    // const responseCount = await serverApi('GET', `masterEntities/count`, '', '', options);

    this.lastSuccess = this.pending;
    this.pending = "";

    if (toODataString(this.props.dataState) === this.lastSuccess) {
      if (window.localStorage.getItem("finalParams")) {
        if (
          JSON.stringify(
            JSON.parse(window.localStorage.getItem("finalParams")).filter
          ) !== ""
        ) {
          let final = JSON.parse(
            window.localStorage.getItem("finalParams")
          ).filter;

          this.props.onDataRecieved.call(
            undefined,
            {
              data: responseData.data,
              total: count === undefined ? responseData.data.length : count,
            },
            final
          );
        }
      } else {
        this.props.onDataRecieved.call(undefined, {
          data: responseData.data,
          total: count === undefined ? responseData.data.length : count,
        });
      }
    } else {
      this.requestDataIfNeeded();
    }
  };

  render() {
    this.requestDataIfNeeded();

    return this.pending && <LoadingPanel />;
  }
}

class LoadingPanel extends React.Component {
  render() {
    const loadingPanel = (
      <div className="k-loading-mask">
        <span className="k-loading-text">Loading . . .</span>
        <div className="k-loading-image"></div>
        <div className="k-loading-color"></div>
      </div>
    );

    const gridContent = document && document.querySelector(".k-grid-content");
    return gridContent
      ? ReactDOM.createPortal(loadingPanel, gridContent)
      : loadingPanel;
  }
}
