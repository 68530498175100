export const columnsSchema = () => [
  {
    field: "id",
    title: "ID",
    visible: true,
    minWidth: 140,
    sortable: true,
    filterable: true,
    locked: true,
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
  },
  {
    field: "relation_name",
    title: "Name",
    visible: true,
    minWidth: 360,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    locked: true,
  },
  {
    field: "relation_address",
    title: "Address",
    visible: true,
    minWidth: 270,
    sortable: true,
    filterable: true,
    locked: false,
  },
  {
    field: "relation_postal_code",
    title: "Postal Code",
    visible: true,
    minWidth: 190,
    sortable: true,
    filterable: true,
    locked: false,
  },
  {
    field: "relation_city",
    title: "City",
    visible: true,
    minWidth: 280,
    sortable: true,
    filterable: true,
    locked: false,
    notShownInTableColumnSelection: false,
  },
  {
    field: "relation_country",
    title: "Country",
    visible: true,
    minWidth: 240,
    sortable: true,
    filterable: true,
    locked: false,
    isDropdown: "yes",
  },
  {
    field: "state",
    title: "State",
    visible: true,
    minWidth: 200,
    sortable: true,
    filterable: true,
    locked: false,
    noDisabledColumn: false,
    isDropdown: "yes",
  },
  {
    field: "relation_tel",
    title: "Telephone",
    visible: true,
    sortable: true,
    minWidth: 250,
    filterable: true,
    locked: false,
  },
  {
    field: "relation_mail",
    title: "Email",
    visible: true,
    sortable: true,
    minWidth: 260,
    filterable: true,
    locked: false,
  },
  {
    field: "relation_web",
    title: "Web",
    visible: false,
    sortable: true,
    minWidth: 260,
    filterable: true,
    locked: false,
  },
  {
    field: "client_flag",
    title: "Is Client",
    visible: true,
    sortable: true,
    minWidth: 190,
    filterable: true,
    locked: false,
    filter: "boolean",
    isDropdown: "yes",
  },
  {
    field: "supplier_flag",
    title: "Is Supplier",
    visible: true,
    sortable: true,
    minWidth: 190,
    filterable: true,
    locked: false,
    filter: "boolean",
    isDropdown: "yes",
  },
  {
    field: "agent_flag",
    title: "Is Agent",
    visible: true,
    sortable: true,
    minWidth: 190,
    filterable: true,
    locked: false,
    filter: "boolean",
    isDropdown: "yes",
  },
  {
    field: "wh_flag",
    title: "Is Warehouse",
    visible: true,
    sortable: true,
    minWidth: 190,
    filterable: true, //todo check again
    locked: false,
    filter: "boolean",
    isDropdown: "yes",
  },
  {
    field: "location_flag",
    title: "Is Location",
    visible: true,
    sortable: true,
    minWidth: 190,
    filterable: true,
    locked: false,
    filter: "boolean",
    isDropdown: "yes",
  },
  {
    field: "transport_flag",
    title: "Is Transport",
    visible: false,
    sortable: true,
    minWidth: 190,
    filterable: true,
    locked: false,
    filter: "boolean",
    isDropdown: "yes",
  },
  {
    field: "sm_agent_flag",
    title: "Is SM-Agent",
    visible: false,
    sortable: true,
    minWidth: 190,
    filterable: true,
    locked: false,
    filter: "boolean",
    isDropdown: "yes",
  },
  {
    field: "sm_vendor_flag",
    title: "Is SM-Vendor",
    visible: false,
    sortable: true,
    minWidth: 190,
    filterable: true,
    locked: false,
    filter: "boolean",
    isDropdown: "yes",
  },
  {
    field: "seaport_flag",
    title: "Is Seaport",
    visible: false,
    sortable: true,
    minWidth: 190,
    filterable: true,
    locked: false,
    filter: "boolean",
    isDropdown: "yes",
  },
  {
    field: "airport_flag",
    title: "Is Airport",
    visible: false,
    sortable: true,
    minWidth: 190,
    filterable: true,
    locked: false,
    filter: "boolean",
    isDropdown: "yes",
  },
  {
    field: "stocktbc_visible",
    title: "Stock-TBC Visible",
    visible: false,
    sortable: true,
    minWidth: 190,
    filterable: true,
    locked: false,
    filter: "boolean",
    isDropdown: "yes",
    editable: "false",
  },
  {
    field: "isBillingEntity",
    title: "Is Billing Entity",
    visible: true,
    sortable: true,
    minWidth: 190,
    filterable: true,
    locked: false,
    filter: "boolean",
    isDropdown: "yes",
    editable: "false",
  },
  {
    field: "paymentTerms",
    title: "Payment Terms",
    visible: true,
    minWidth: 220,
    sortable: false,
    filterable: true,
    noDisabledColumn: false,
  },
  {
    field: "paymentTermsTypeId",
    title: "Payment Terms Type",
    visible: true,
    minWidth: 220,
    sortable: false,
    filterable: true,
    noDisabledColumn: false,
  },
  {
    field: "clientgroup_id",
    title: "Client group id",
    visible: true,
    minWidth: 140,
    sortable: true,
    filterable: true,
    locked: false,
    noDisabledColumn: false,
    notShownInTableColumnSelection: false,
    editable: "false",
  },
  {
    field: "real_location_id",
    title: "Real Location id",
    visible: true,
    minWidth: 140,
    sortable: true,
    filterable: true,
    locked: false,
    noDisabledColumn: false,
    notShownInTableColumnSelection: false,
    editable: "false",

    // cell: true
  },
  {
    field: "relation_location_iso_code",
    title: "Iso",
    visible: true,
    sortable: true,
    minWidth: 260,
    filterable: true,
    locked: false,
  },
  {
    field: "icao_code",
    title: "ICAO Code",
    visible: true,
    minWidth: 170,
    sortable: true,
    filterable: true,
    locked: false,
    notShownInTableColumnSelection: false,
  },
  {
    field: "latitude",
    title: "Latitude",
    visible: true,
    minWidth: 100,
    sortable: true,
    filterable: true,
    locked: false,
    notShownInTableColumnSelection: false,
  },
  {
    field: "longitude",
    title: "Longitude",
    visible: true,
    minWidth: 100,
    sortable: true,
    filterable: true,
    locked: false,
    notShownInTableColumnSelection: false,
  },
  {
    field: "client_pricelist_category",
    title: "Client Pricelist Cat",
    visible: true,
    minWidth: 100,
    sortable: true,
    filterable: true,
    locked: false,
    notShownInTableColumnSelection: false,
    editable: "false",
  },
  {
    field: "ap_account",
    title: "AP account",
    visible: true,
    minWidth: 150,
    sortable: true,
    filterable: true,
    locked: false,
    notShownInTableColumnSelection: false,
  },
  {
    field: "ar_account",
    title: "AR account",
    visible: true,
    minWidth: 150,
    sortable: true,
    filterable: true,
    locked: false,
    notShownInTableColumnSelection: false,
  },
  {
    field: "ein_number",
    title: "EIN Number",
    visible: true,
    minWidth: 250,
    sortable: true,
    filterable: true,
    locked: false,
    notShownInTableColumnSelection: false,
    editable: "false",
  },
  {
    field: "ss_number",
    title: "SS Number",
    visible: true,
    minWidth: 250,
    sortable: true,
    filterable: true,
    locked: false,
    notShownInTableColumnSelection: false,
    editable: "false",
  },
  {
    field: "tsa_number",
    title: "TSA Number",
    visible: true,
    minWidth: 250,
    sortable: true,
    filterable: true,
    locked: false,
    notShownInTableColumnSelection: false,
    editable: "false",
  },
  {
    field: "approval_status",
    title: "Approval status",
    visible: true,
    minWidth: 220,
    sortable: true,
    filterable: true,
    locked: false,
    notShownInTableColumnSelection: false,
    editable: "false",
    isDropdown: "yes",
  },
  {
    field: "agent_type",
    title: "Agent Type",
    visible: true,
    minWidth: 220,
    sortable: true,
    filterable: true,
    noDisabledColumn: false,
    isDropdown: "yes",
  },
  {
    field: "vat_number",
    title: "VAT",
    visible: true,
    minWidth: 220,
    sortable: true,
    filterable: true,
    noDisabledColumn: false,
  },
  {
    field: "duns",
    title: "DUNS",
    visible: true,
    minWidth: 200,
    sortable: true,
    filterable: true,
    locked: false,
    notShownInTableColumnSelection: false,
    editable: "false",
  },
  {
    field: "createdByForUi",
    title: "Created By",
    visible: true,
    minWidth: 200,
    sortable: false,
    filterable: false,
    locked: false,
    notShownInTableColumnSelection: false,
    editable: "false",
  },
  {
    field: "dateCreatedForUi",
    title: "Created Date",
    visible: true,
    minWidth: 200,
    sortable: false,
    filterable: false,
    locked: false,
    notShownInTableColumnSelection: false,
    editable: "false",
  },
];

export const columnsSchemaForGoogle = () => [
  {
    field: "selected",
    width: "100px",
    notShownInTableColumnSelection: true,
  },
  {
    field: "relation_name",
    title: "Name",
    visible: true,
    minWidth: 340,
    sortable: true,
    filterable: true,
    locked: false,
  },
  {
    field: "relation_country",
    title: "Country",
    visible: true,
    minWidth: 240,
    sortable: true,
    filterable: true,
    noDisabledColumn: false,
    notShownInTableColumnSelection: false,
    locked: false,
  },
  {
    field: "state",
    title: "State",
    visible: true,
    minWidth: 150,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    locked: false,
    isDropdown: "yes",
  },
  {
    field: "relation_postal_code",
    title: "Postal",
    visible: true,
    minWidth: 160,
    sortable: true,
    filterable: true,
    locked: false,
  },

  {
    field: "relation_address",
    title: "Address",
    visible: true,
    minWidth: 220,
    sortable: true,
    filterable: true,
    locked: false,
  },
  {
    field: "relation_city",
    title: "City",
    visible: true,
    minWidth: 100,
    sortable: true,
    filterable: true,
    locked: false,
    notShownInTableColumnSelection: false,
  },
  {
    field: "relation_tel",
    title: "Telephone",
    visible: true,
    sortable: true,
    minWidth: 230,
    filterable: true,
    locked: false,
  },
  {
    field: "relation_mail",
    title: "Email",
    visible: false,
    sortable: true,
    minWidth: 260,
    filterable: true,
    locked: false,
  },
  {
    field: "relation_fax",
    title: "Fax",
    visible: false,
    sortable: true,
    minWidth: 260,
    filterable: true,
    locked: false,
  },
  {
    field: "relation_web",
    title: "Web",
    visible: true,
    sortable: true,
    minWidth: 260,
    filterable: true,
    locked: false,
  },
];

export const googleColumnsSchema = () => [
  {
    field: "postalCodeGoogle",
    title: "Postal Code",
    width: "150px",
  },
  {
    field: "addressGoogle",
    title: "Address",
    width: "300px",
  },
  {
    field: "cityGoogle",
    title: "City",
    width: "150px",
  },
];
