import React from 'react'
import {Grid, GridColumn as Column, GridDetailRow, GridToolbar} from '@progress/kendo-react-grid';
import {columnsSchema} from "./schema";
import {MyCommandCell} from "../CellHOC";
import {serverApi} from "../../../../../networking/config";
import _ from "lodash";
import {DropDownCell} from "../../../../CRM/View/dropDownCell";
import GridContainer from "../../../../../components/Grid/GridContainer";
import {IconButton, Tooltip} from "@material-ui/core";
import {Window} from "@progress/kendo-react-dialogs";
import BlockUi from "react-block-ui";
import {Error} from "@progress/kendo-react-labels";
import {Checkbox, Input} from "@progress/kendo-react-inputs";
import {AbstractDelete} from "../../../../../networking/apiCalls";
import RegularButton from "../../../../../components/CustomButtons/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";

class ClientToDomain extends GridDetailRow {
  editField = "inEdit";
  CommandCell;
  _export;

  constructor(props) {
    super(props);

    this.state = {
      visibleWindow: false,
      isDropOpened: false,
      columns: (columnsSchema && columnsSchema()) || [],
      gridData: {
        data: [],
        total: 10
      },
      initialGridData: {
        data: [], total: 0
      },
      dataState: {
        take: 10, skip: 0
      },
      openErrorModal: false,
      errorModalContent: '',
      delay: 400,
      loading: false,
      contacts: [],
      windowError: false,
      windowErrorMessage: "",
      disableEditButton: true,

      //create properties
      contact_name: "",
      contact_country: "",
      contact_email: "",
      contact_type: "",
      wh_notification_flg: false,
      notify_stock_email: false,
      notify_stock_ws: false,
    }

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      disableEditButton: true,
      showSecondAction: false,
      showFirstAction: false
    });

  }

  async componentDidMount() {
    let options = {
      token: window.localStorage.getItem('access_token')
    };

    let filter1 = {
      filter: {
        where: {
          clientID: this.props.dataItem && this.props.dataItem.id
        },
        include: ["domain"]
      }
    }

    const attachedDomains = await serverApi('GET', `clientToDomains`, filter1, '', options);
    let clonedAttachedDomains = _.cloneDeep(attachedDomains)

    const getDomains = await serverApi('GET', `domains`, '', '', options);

    // let result = this.constructClientsAndExistingClientsArray(getDomains.data, clonedAttachedDomains.data)

    // result[0].attachedDomains.forEach(item => {
    //   let client = result[0].allDomains.find(x => x.name === item.name)
    //   if (client) {
    //     client.checked = true
    //   }
    // })

    if (attachedDomains && attachedDomains.data.length === 1) {
      let finalAttachedClients = []
      _.forEach(clonedAttachedDomains.data, (temp) => {
        finalAttachedClients.push({
          id: temp.domainID,
          description: temp?.domain?.description || 'N/A',
          bankName: temp?.domain?.bankName || 'N/A',
          bicCode: temp?.domain?.bicCode || 'N/A',
          vatNumber: temp?.domain?.vatNumber || 'N/A',
          deleteID: temp.id
        })
      })

      this.setState({
        ...this.state,
        ID: this.props.dataItem.id,
        gridData: {
          data: [finalAttachedClients[0]],
          total: 1
        },
        initialGridData: {
          data: [finalAttachedClients[0]],
          total: 1
        },
        domain_names: getDomains.data.sort((a, b) => (a.relation_name > b.relation_name) ? 1 : -1),
        valueClientName: "",

        // visibleClients: result[0].allDomains || [],
        // finalUserClients: result[0].allDomains,
        // initialFinalUserClients: result[0].allDomains,
        blocking: false

      })

    } else if (attachedDomains && attachedDomains.data.length > 1) {
      let finalData = []
      _.forEach(attachedDomains.data, temp => {
        finalData.push({
          id: temp.domainID,
          description: temp?.domain?.description || 'N/A',
          bankName: temp?.domain?.bankName || 'N/A',
          bicCode: temp?.domain?.bicCode || 'N/A',
          vatNumberre: temp?.domain?.vatNumber || 'N/A',
          deleteID: temp.id
        })
      })
      finalData.sort((a, b) => (a.relation_name > b.relation_name) ? 1 : -1)

      this.setState({
        ...this.state,
        clientID: this.props.dataItem.id, //todo
        gridData: {
          data: finalData,
          total: finalData.length
        },
        initialGridData: {
          data: finalData,
          total: finalData.length
        },
        domain_names: getDomains.data.sort((a, b) => (a.description > b.description) ? 1 : -1),

        // visibleClients: result[0].allDomains || [],
        // finalUserClients: result[0].allDomains,
        // initialFinalUserClients: result[0].allDomains,
        blocking: false

      })

    } else {
      this.setState({
        ...this.state,
        clientID: this.props.dataItem.id, //todo
        gridData: {
          data: [],
          total: 0
        },
        initialGridData: {
          data: [],
          total: 0
        },
        domain_names: getDomains.data,

        // visibleClients: result[0].allDomains || [],
        // finalUserClients: result[0].allDomains,
        // initialFinalUserClients: result[0].allDomains,
        blocking: false
      })
    }
  }

  renderGridColumns = (dataItem, changeTheCellComponent) => {

    let selectionCol = []
    let normalCols = []
    let finalCols = []

    _.forEach(this.state.columns, col => {
      if (col.field === "selected") {

        selectionCol.push([
          <Column
            field="selected"
            width="65px"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(dataItem => dataItem.selected === false) === -1
            }
          />
        ])
      }
    })

    normalCols = this.state.columns.filter(temp1 => temp1.visible).map(temp => {

      if (temp.title === "Actions") {
        return null;
      }

      if (temp.isFilterBoolean === "yes") {
        return (
          <Column
            field={temp.field}
            filterable={temp.filterable}
            title={temp.title}
            width={temp.minWidth || 'auto'}
            filter={temp.filter}
            visible={temp.visible}
            minGridWidth={"400"}
            cell={DropDownCell}

          />
        )
      }
        //   else if (temp.field === 'contact_type') {
        //     return (
        //       <Column
        //         field="contact_type"
        //         title="Type"
        //         width="365px"
        //         cell={() => <div>Dropdown component for type</div>}
        //       />
        //     );
      // }
      else {
        return (
          <Column
            field={temp.field}
            filterable={temp.filterable}
            title={temp.title}
            width={temp.minWidth || 'auto'}
            filter={temp.filter}
            visible={temp.visible}
            minGridWidth={"400"}
            editable={
              temp.field === 'id' || temp.editable
                ? false
                : true
            }
          />
        );
      }

    })

    finalCols = [...selectionCol, ...normalCols]
    return finalCols

  };

  constructClientsAndExistingClientsArray = (data, attachedData, clientID) => {
    let tempArr = _.cloneDeep(attachedData)
    let domainIDs = tempArr.map(x => x.domainID)
    let finalDomains = []
    _.forEach(data, (temp1) => {
      finalDomains.push({
        name: temp1.description,
        id: temp1.id,
        clientID: clientID,
        domainIDs: domainIDs,
        checked: false
      })
    })

    let finalAttachedDomains = []
    _.forEach(attachedData, (temp2) => {
      const clientID = parseInt(temp2.clientID)
      let domainIDs = []
      domainIDs.push(temp2.domainID)
      finalAttachedDomains.push({
        name: temp2?.domain?.description || 'N/A',
        id: temp2.clientID,
        checked: true,
        deleteID: temp2.id,
        clientID: clientID,
        domainIDs: domainIDs,
      })
    })

    finalDomains.sort((a, b) => (a.name > b.name) ? 1 : -1)
    finalAttachedDomains.sort((a, b) => (a.name > b.name) ? 1 : -1)

    return [{
      allDomains: finalDomains,
      attachedDomains: finalAttachedDomains
    }]
  }

  toggleWindow = async (e) => {
    const options = {token: window.localStorage.getItem('access_token')};
    let filter1 = {
      filter: {
        where: {
          clientID: this.props.dataItem.id
        },
        include: ["domain"]
      }
    }

    let attachedDomainsToClient = [];
    let attachedDomains = await serverApi('GET', `clientToDomains`, filter1, '', options);
    let clonedAttachedDomains = _.cloneDeep(attachedDomains)

    const getDomains = await serverApi('GET', `domains`, '', '', options);

    (attachedDomains.data || []).forEach((x) => {
      if (x?.domain) {
        if (x.clientID === this.props.dataItem.id) {
          attachedDomainsToClient.push(x);
        }
      }
    });

    let result = this.constructClientsAndExistingClientsArray(getDomains.data, attachedDomainsToClient, this.props.dataItem.id)

    result[0].attachedDomains.forEach(item => {
      let client = result[0].allDomains.find(x => x.name === item.name)
      if (client) {
        client.checked = true
      }
    })

    if (attachedDomains && attachedDomains?.data?.length === 1) {
      let finalAttachedClients = []
      _.forEach(clonedAttachedDomains.data, (temp) => {
        finalAttachedClients.push({
          id: temp.domainID,
          description: temp?.domain?.description || 'N/A',
          bankName: temp?.domain?.bankName || 'N/A',
          bicCode: temp?.domain?.bicCode || 'N/A',
          vatNumber: temp?.domain?.vatNumber || 'N/A',
          deleteID: temp.id
        })
      })

      this.setState({
        ...this.state,
        visibleWindow: !this.state.visibleWindow,
        clientID: this.props.dataItem.id,
        gridData: {
          data: [finalAttachedClients[0]],
          total: 1
        },
        initialGridData: {
          data: [finalAttachedClients[0]],
          total: 1
        },
        domain_names: getDomains.data.sort((a, b) => (a.relation_name > b.relation_name) ? 1 : -1),
        valueClientName: "",

        visibleClients: result[0].allDomains || [],
        finalUserClients: result[0].allDomains,
        initialFinalUserClients: result[0].allDomains,
        blocking: false

      })

    } else if (attachedDomains && attachedDomains.data.length > 1) {
      let finalData = []
      _.forEach(attachedDomains.data, temp => {
        finalData.push({
          id: temp.domainID,
          description: temp?.domain?.description || 'N/A',
          bankName: temp?.domain?.bankName || 'N/A',
          bicCode: temp?.domain?.bicCode || 'N/A',
          vatNumber: temp?.domain?.vatNumber || 'N/A',
          deleteID: temp.id
        })
      })
      finalData.sort((a, b) => (a.relation_name > b.relation_name) ? 1 : -1)

      this.setState({
        ...this.state,
        visibleWindow: !this.state.visibleWindow,
        clientID: this.props.dataItem.id, //todo
        gridData: {
          data: finalData,
          total: finalData.length
        },
        initialGridData: {
          data: finalData,
          total: finalData.length
        },
        domain_names: getDomains.data.sort((a, b) => (a.description > b.description) ? 1 : -1),

        visibleClients: result[0].allDomains || [],
        finalUserClients: result[0].allDomains,
        initialFinalUserClients: result[0].allDomains,
        blocking: false

      })

    } else {
      this.setState({
        ...this.state,
        visibleWindow: !this.state.visibleWindow,
        clientID: this.props.dataItem.id, //todo
        gridData: {
          data: [],
          total: 0
        },
        initialGridData: {
          data: [],
          total: 0
        },
        domain_names: getDomains.data,

        visibleClients: result[0].allDomains || [],
        finalUserClients: result[0].allDomains,
        initialFinalUserClients: result[0].allDomains,
        blocking: false
      })
    }

  }

  handleChange = async (event, name, key) => {

    let finalUserClient = [...this.state.finalUserClients];
    let finalObj = finalUserClient.find((temp) => temp.id === key);

    let action = event.value === false ? 'DELETE' : 'POST'
    if (action === 'POST') {
      finalObj.domainIDs.push(key);
    } else if (action === 'DELETE') {
      let index = finalObj.domainIDs.indexOf(key);
      if (index !== -1) {
        finalObj.domainIDs.splice(index, 1);
      }
    }

    finalObj.checked = !finalObj.checked

    this.setState({
      ...this.state,
      finalUserClients: finalUserClient
    }, async () => {})

  }

  handleChangeOLD = (event, name, key) => {

    let finalUserClient = [...this.state.finalUserClients]
    let finalObj = finalUserClient.find(test => test.id === key)
    finalObj.checked = !finalObj.checked

    let data = [...this.state.gridData.data];

    this.setState({
      ...this.state,
      finalUserClients: finalUserClient
    }, () => {

      let postOrDeleteClient = event.value === false ? 'DELETE' : 'POST'
      let options = {
        token: window.localStorage.getItem('access_token')
      };

      if (postOrDeleteClient === 'POST') {
        const url = `clientToDomains`
        const addClientToDomain = serverApi('POST', url, '', {
          clientID: this.props.dataItem.id,
          domainID: key
        }, options, '', '')

        addClientToDomain
          .then(async response => {
            let filter1 = {
              filter: {
                where: {
                  clientID: this.props.dataItem && this.props.dataItem.id
                },
                include: ["domain"]
              }
            }

            const attachedData = await serverApi('GET', `clientToDomains`, filter1, '', options);
            let finalData = []
            _.forEach(attachedData.data, temp => {
              finalData.push({
                id: temp.domainID,
                description: temp.domain.description,
                deleteID: temp.id
              })
            })
            finalData.sort((a, b) => (a.description > b.description) ? 1 : -1)
            this.setState({
              ...this.state,
              gridData: {
                data: finalData,
                total: finalData.length
              },
              initialGridData: {
                data: finalData,
                total: finalData.length
              },
              blocking: false,
              openErrorModal: false,
              errorModalContent: "",
            })
          })
          .catch(error => {
            this.setState({
              ...this.state,
              openErrorModal: true,
              errorModalContent: error?.response?.data?.error?.message || "Please try again",
              blocking: false
            })
          })


      } else if (postOrDeleteClient === 'DELETE') {
        const deleteObj = data.find(obj => obj.id === key)
        const delete_id = deleteObj.deleteID
        const url = `clientToDomains/${delete_id}`

        const removeClientFromDomain = serverApi('DELETE', url, '', '', options, '', '')
        removeClientFromDomain.then(async response => {

          let filter1 = {
            filter: {
              where: {
                clientID: this.props.dataItem && this.props.dataItem.id
              },
              include: ["domain"]
            }
          }
          const attachedClients = await serverApi('GET', `clientToDomains`, filter1, '', options);
          let finalData = []
          _.forEach(attachedClients.data, temp => {
            finalData.push({
              id: temp.domainID,
              description: temp.domain.description,
              deleteID: temp.id
            })
          })
          finalData.sort((a, b) => (a.description > b.description) ? 1 : -1)

          this.setState({
            ...this.state,
            gridData: {
              data: finalData,
              total: finalData.length
            },
            initialGridData: {
              data: finalData,
              total: finalData.length
            },
            blocking: false
          })
        })
          .catch(error => {
            this.setState({
              ...this.state,
              openErrorModal: true,
              errorModalContent: error?.response?.data?.error?.message || "Please try again",
              blocking: false
            })
          })
      }
    })
  }

  renderClientList = (finalClients = [], clients = [], doRefresh) => {

    if (doRefresh === true) {
      window.location.reload()
    } else {
      if (finalClients.length === clients.length) {
        return (
          clients && clients.map(item => (
              <ul style={{marginBottom: '-5px'}} key={item.id}>
                <Checkbox
                  name={item.name}
                  key={item.id}
                  value={item.checked}
                  onChange={(e) => this.handleChange(e, item.name, item.id)}
                  label={item.name}
                  disabled={this.state.disableAllCheckboxes}
                />
              </ul>
            )
          )
        )
      } else {
        return (
          finalClients && finalClients.map(item => (
              <ul style={{marginBottom: '-5px'}} key={item.id}>
                <Checkbox
                  name={item.name}
                  key={item.id}
                  value={item.checked}
                  onChange={(e) => this.handleChange(e, item.name, item.id)}
                  label={item.name}
                  disabled={this.state.disableAllCheckboxes}
                />
              </ul>
            )
          )
        )
      }
    }
  }

  handleSearch = (e) => {
    let initialFinalUserClients = this.state.initialFinalUserClients

    if (e.value === "") {
      let searchArr = []
      initialFinalUserClients.forEach(temp => {
        if (temp.name.toUpperCase().includes(e.value.toUpperCase())) {
          searchArr.push(temp)
        }
      });

      if (this.state.clientAttachedToAnotherDomain !== null && this.state.clientAttachedToAnotherDomain !== undefined) {
        let newArr = searchArr.filter(t => t.id !== this.state.clientAttachedToAnotherDomain)
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalUserClients: newArr,
          openErrorModal: false,
          errorModalContent: ""
        })
      } else {
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalUserClients: initialFinalUserClients,
          openErrorModal: false,
          errorModalContent: ""
        })
      }
    } else {

      let searchArr = []
      //case insensitive
      initialFinalUserClients.forEach(temp => {
        if (temp.name.toUpperCase().includes(e.value.toUpperCase())) {
          searchArr.push(temp)
        }
      });

      this.setState({
        ...this.state,
        inputValue: e.value,
        finalUserClients: searchArr,
        openErrorModal: false,
        errorModalContent: ""
      })
    }
  }

  enterEdit = (dataItem) => {

    let checkForEditDato = this.state.gridData.data
    const found = checkForEditDato.some(item => item.inEdit === true);
    if (found)
      return

    let total = this.state.gridData.total

    this.setState({
      gridData: {
        data: this.state.gridData && this.state.gridData.data.map(item =>
          item.id === dataItem.id ?
            {...item, inEdit: true} : item
        ),
        total: total
      }
    });
  }

  discard = (dataItem, hasError = false) => {

    let data = [...this.state.gridData.data];
    const total = this.state.gridData.total

    if (hasError) {
      data[0].inEdit = true
      this.setState({
        gridData: {
          data: data,
          total: total
        }
      });
    } else {
      this.removeItem(data, dataItem);

      this.setState({
        gridData: {
          data: data,
          total: total
        },
        visible: false
      });
    }

  }

  cancel = (dataItem) => {

    const originalItem = this.state.initialGridData && this.state.initialGridData.data.find(p => p.id === dataItem.id);
    originalItem.inEdit = false

    const data = this.state.gridData && this.state.gridData.data.map(item => item.id === originalItem.id ? originalItem : item);

    const total = this.state.gridData.total

    this.setState({
      ...this.state,
      gridData: {
        data: data,
        total: total
      },
      openErrorModal: false,
      openErrorModalContent: ''
    });
  }

  removeItem(data, item) {

    let index = data.findIndex(p => p === item || p.id === item.id);
    if (index >= 0) {
      data.splice(index, 1);
    }
  }

  remove = async (dataItem) => {
    const data = [...this.state.gridData.data];
    let index = data.findIndex(p => p === dataItem || p.id === dataItem.id);
    if (index >= 0) {
      data.splice(index, 1);
    }

    let options = {
      token: window.localStorage.getItem('access_token')
    };

    let url = 'clientToDomains'

    try {
      let deleteItemRequest = await AbstractDelete(url, `${dataItem.deleteID || ''}`, options)

      if (deleteItemRequest.status === 200) {
        //clear the column filters if grid data is 0 or let them if grid data is >= 1
        if (data.length <= 0) {
          this.setState({
            ...this.state,
            visibleWindow: false,
            gridData: {
              data: data, total: data.length
            },
            initialGridData: {
              data: data, total: data.length
            },
            dataState: {
              skip: 0,
              take: 10
            }
          });
        } else {
          this.setState({
            ...this.state,
            visibleWindow: false,
            gridData: {
              data: data, total: data.length
            },
            initialGridData: {
              data: data, total: data.length
            },
          });
        }


      }
    } catch (e) {
      this.setState({
        ...this.state,
        visibleWindow: false,
        openErrorModal: true,
        errorModalContent: e?.response?.data?.error?.message || "Remove domain has failed.Please try again and if the problem persist contact the administrator."
      })
    }

  }

  add = (dataItem) => {


  }

  addNew = () => {

    let checkForEditDato = this.state.gridData.data
    const found = checkForEditDato.some(item => item.inEdit === true);
    if (found)
      return

    const newDataItem = {inEdit: true};

    this.setState({
      gridData: {
        data: [newDataItem, ...this.state.gridData.data],
        total: this.state.gridData.total
      },
      visibleWindow: true
    });

  }

  update = async (dataItem) => {

  }

  updateItem = (data, item) => {
    let index = data.findIndex(p => p === item || (item.id && p.id === item.id));
    if (index >= 0) {
      data[index] = {...item};
    }
  }

  itemChange = (event) => {
    const total = this.state.gridData.total
    const data = this.state.gridData && this.state.gridData.data.map(item =>
      item.id === event.dataItem.id ?
        {...item, [event.field]: event.value} : item
    );

    this.setState({
      gridData: {
        data: data,
        total: total
      }
    });
  }

  closeWindow = () => {
    this.setState({
      ...this.state,
      visibleWindow: false
    })
  }

  saveNewDomains = async () => {
    const finalUsers = [...this.state.finalUserClients.filter(x => x.checked === true)]
    const initialUsers = this.state.initialFinalUserClients.filter(x => x.checked)
    const final = [...finalUsers, ...initialUsers]

    const uniqueData = final.filter((thing, index, self) =>
      index === self.findIndex((t) => ( t.id === thing.id))
    ) 

    const clonedUsers = _.cloneDeep(uniqueData)
    let domainsIDs = []
    domainsIDs = clonedUsers.map(x => x.id)

    let payload = {
      data : []
    };
    payload.data.push({
      clientID: this.props.dataItem.id,
      domainsIDs: domainsIDs,
    })

    this.setState({...this.state,blocking: true, blockingMessage: "Please wait a few seconds..."})
    let options = {
      token: window.localStorage.getItem('access_token')
    };

    const url = `clientToDomains/bulkUpdate`

    try {
      await serverApi('POST', url, '', payload, options, '', '')
      await this.fetchLatestData()

      this.setState({...this.state,blocking: false, blockingMessage: "", inputValue: ""})

    } catch(error) {

    }


  }

  fetchLatestData = async () => {
    const options = {token: window.localStorage.getItem('access_token')};
    let filter1 = {
      filter: {
        where: {
          clientID: this.props.dataItem.id
        },
        include: ["domain"]
      }
    }

    let attachedDomainsToClient = [];
    let attachedDomains = await serverApi('GET', `clientToDomains`, filter1, '', options);
    let clonedAttachedDomains = _.cloneDeep(attachedDomains)

    const getDomains = await serverApi('GET', `domains`, '', '', options);

    (attachedDomains.data || []).forEach((x) => {
      if (x?.domain) {
        if (x.clientID === this.props.dataItem.id) {
          attachedDomainsToClient.push(x);
        }
      }
    });

    let result = this.constructClientsAndExistingClientsArray(getDomains.data, attachedDomainsToClient, this.props.dataItem.id)

    result[0].attachedDomains.forEach(item => {
      let client = result[0].allDomains.find(x => x.name === item.name)
      if (client) {
        client.checked = true
      }
    })

    if (attachedDomains && attachedDomains?.data?.length === 1) {
      let finalAttachedClients = []
      _.forEach(clonedAttachedDomains.data, (temp) => {
        finalAttachedClients.push({
          id: temp.domainID,
          description: temp?.domain?.description || 'N/A',
          bankName: temp?.domain?.bankName || 'N/A',
          bicCode: temp?.domain?.bicCode || 'N/A',
          vatNumber: temp?.domain?.vatNumber || 'N/A',
          deleteID: temp.id
        })
      })

      this.setState({
        ...this.state,
        visibleWindow: !this.state.visibleWindow,
        clientID: this.props.dataItem.id,
        gridData: {
          data: [finalAttachedClients[0]],
          total: 1
        },
        initialGridData: {
          data: [finalAttachedClients[0]],
          total: 1
        },
        domain_names: getDomains.data.sort((a, b) => (a.relation_name > b.relation_name) ? 1 : -1),
        valueClientName: "",

        visibleClients: result[0].allDomains || [],
        finalUserClients: result[0].allDomains,
        initialFinalUserClients: result[0].allDomains,
        blocking: false

      })

    } else if (attachedDomains && attachedDomains.data.length > 1) {
      let finalData = []
      _.forEach(attachedDomains.data, temp => {
        finalData.push({
          id: temp.domainID,
          description: temp?.domain?.description || 'N/A',
          bankName: temp?.domain?.bankName || 'N/A',
          bicCode: temp?.domain?.bicCode || 'N/A',
          vatNumber: temp?.domain?.vatNumber || 'N/A',
          deleteID: temp.id
        })
      })
      finalData.sort((a, b) => (a.relation_name > b.relation_name) ? 1 : -1)

      this.setState({
        ...this.state,
        visibleWindow: !this.state.visibleWindow,
        clientID: this.props.dataItem.id, //todo
        gridData: {
          data: finalData,
          total: finalData.length
        },
        initialGridData: {
          data: finalData,
          total: finalData.length
        },
        domain_names: getDomains.data.sort((a, b) => (a.description > b.description) ? 1 : -1),

        visibleClients: result[0].allDomains || [],
        finalUserClients: result[0].allDomains,
        initialFinalUserClients: result[0].allDomains,
        blocking: false

      })

    } else {
      this.setState({
        ...this.state,
        visibleWindow: !this.state.visibleWindow,
        clientID: this.props.dataItem.id, //todo
        gridData: {
          data: [],
          total: 0
        },
        initialGridData: {
          data: [],
          total: 0
        },
        domain_names: getDomains.data,

        visibleClients: result[0].allDomains || [],
        finalUserClients: result[0].allDomains,
        initialFinalUserClients: result[0].allDomains,
        blocking: false
      })
    }
  }

  render() {

    const dataItem = this.props.dataItem;
    const {gridData} = this.state;
    const {inputValue} = this.state
    const { hasBackofficeRead } = this.props;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <div style={{
          width: "83%",
        }}>
          <h4 style={{fontFamily: 'bold', textSize: '7px'}}>
            Client name : {dataItem && dataItem.relation_name}
          </h4>

          <Grid
            {...gridData}
            filterable={false}
            style={{className: "grid-no-select"}}
            sortable={false}
            resizable
            editField={this.editField}
            onItemChange={this.itemChange}
            pageable={false}
          >
            <GridToolbar>
              <GridContainer xs={12} justify={"flex-start"} direction={"row"}>
              {
              !hasBackofficeRead && (
                <Tooltip justify={"flex-start"} placement="top" title={"Attach/Remove Domain"}>
                  <IconButton onClick={this.toggleWindow}>
                    <FontAwesomeIcon
                        color="#0D5869"
                        // onClick={this.toggleWindow}
                        size="1.6x" icon={faPencilAlt}
                        />
                    </IconButton>
                   </Tooltip>
                )
              }
              </GridContainer>
            </GridToolbar>

            {/* {!hasBackofficeRead &&
            <Column
              cell={this.CommandCell}
              title="Actions"
              filterable={false}
              width="240px"
            />}             */}
             {this.renderGridColumns(dataItem)}
          </Grid>
        </div>
        {
          this.state.visibleWindow &&
          <Window
            title={`Attach domains to client: ${this.props.dataItem.relation_name}`}
            // style={{backgroundColor:'#0d5869'}}
            onClose={this.closeWindow}
            width={900}
            height={600}
            initialTop={110}
          >
            <>
              <BlockUi tag="div" blocking={this.state.blocking} message="Loading, please wait. . ."
                       renderChildren={true}
              >
                <div style={{display: 'flex', flexDirection: 'row', marginBottom: '50px'}}>
                  <div style={{display: 'flex', flexDirection: 'column', position:'relative'}}>
                    <div style={{position:'sticky', top: "0px", zIndex: 2, backgroundColor: 'ffff'}}>
                        <RegularButton
                          style={{backgroundColor: "#0d5869"}}
                          roundSmall="true"
                          onClick={this.saveNewDomains}
                          size="sm"
                          disabled={hasBackofficeRead ? true : false}
                        >
                          Save changes
                        </RegularButton>
                    </div>
                  
                    {
                      this.state.openErrorModal
                        ? <Error style={{color: 'red', fontSize: '15px'}}>
                          {this.state.errorModalContent || ""}
                        </Error>
                        : null
                    }
                    <div style={{display: 'flex', marginLeft: '35px', marginTop: '20px'}}>
                      <Input
                        name="domains"
                        value={inputValue}
                        style={{marginBottom: '20px'}}
                        label="Search domains"
                        onChange={e => this.handleSearch(e)}
                        minLength={2}
                      />
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      {this.renderClientList(this.state.finalUserClients, this.state.visibleClients)}
                    </div>

                  </div>
                </div>
              </BlockUi>
            </>
            {
              this.state.windowError
                ? <Error style={{color: 'red'}}>
                  <h3>{this.state.windowErrorMessage || ""}</h3>
                </Error>
                : null
            }
          </Window>
        }
      </div>
    )
  }
}

export default ClientToDomain
