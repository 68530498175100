import React from 'react'
import { Grid, GridColumn as Column, GridDetailRow, GridNoRecords, GridToolbar } from '@progress/kendo-react-grid';
import { columnsSchema } from "./schema";
import { MyCommandCell } from "../CellHOC";
import { serverApi } from "../../../../../networking/config";
import _ from "lodash";
import { DropDownCell } from "../../../../CRM/View/dropDownCell";
import GridContainer from "../../../../../components/Grid/GridContainer";
import { Window } from "@progress/kendo-react-dialogs";
import BlockUi from "react-block-ui";
import { Error } from "@progress/kendo-react-labels";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { IconButton, Tooltip } from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";

class ClientToGroup extends GridDetailRow {
  editField = "inEdit";
  CommandCell;
  _export;

  constructor(props) {
    super(props);

    this.state = {
      visibleWindow: false,
      isDropOpened: false,
      columns: (columnsSchema && columnsSchema()) || [],
      gridData: {
        data: [],
        total: 10
      },
      initialGridData: {
        data: [], total: 0
      },
      dataState: {
        take: 10, skip: 0
      },
      openErrorModal: false,
      errorModalContent: '',
      delay: 400,
      loading: false,
      contacts: [],
      windowError: false,
      windowErrorMessage: "",
      disableEditButton: true,

      //create properties
      contact_name: "",
      contact_country: "",
      contact_email: "",
      contact_type: "",
      wh_notification_flg: false,
      notify_stock_email: false,
      notify_stock_ws: false,
    }

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      disableEditButton: false,
      showSecondAction: true,
      showFirstAction: false
    });

  }

  async componentDidMount() {
    let options = {
      token: window.localStorage.getItem('access_token')
    };

    let filter1 = {
      filter: {
        where: {
          id: this.props.dataItem && this.props.dataItem.id
        },
        include: ["clientGroup"]
      }
    }
    const attachedGroups = await serverApi('GET', `masterEntities`, filter1, '', options);
    const getGroups = await serverApi('GET', `clientGroups`, '', '', options);

    let result = this.constructClientsAndExistingClientsArray(getGroups.data, attachedGroups.data)
    result[0].attachedGroups.forEach(item => {
      let client = result[0].allGroups.find(x => x.name === item.name)
      if (client) {
        client.checked = true
      }
    })

    if (attachedGroups && attachedGroups.data.length === 1) {
      let finalAttachedClients = []
      _.forEach(attachedGroups.data, (temp) => {
        if (temp?.clientGroup) {
          finalAttachedClients.push({
            id: temp.clientgroup_id,
            groupName: temp?.clientGroup?.groupName,
            deleteID: temp.id
          })
        }
        if (temp?.clientgroup_id === 0) {
          finalAttachedClients.push({
            id: 0,
            groupName: 'NO GROUP',
            deleteID: temp.id
          })
        }
      })

      this.setState({
        ...this.state,
        ID: this.props.dataItem.id,
        gridData: {
          data: finalAttachedClients.length > 0 ? [finalAttachedClients[0]] : [],
          total: finalAttachedClients.length > 0 ? 1 : 0
        },
        initialGridData: {
          data: finalAttachedClients.length > 0 ? [finalAttachedClients[0]] : [],
          total: finalAttachedClients.length > 0 ? 1 : 0
        },
        group_names: getGroups.data.sort((a, b) => (a.groupName > b.groupName) ? 1 : -1),
        valueClientName: "",

        visibleClients: result[0].allGroups || [],
        finalUserClients: result[0].allGroups,
        initialFinalUserClients: result[0].allGroups,
        blocking: false

      })

    }
  }

  constructClientsAndExistingClientsArray = (data, attachedData) => {
    let finalGroups = []
    _.forEach(data, (temp1) => {
      finalGroups.push({
        name: temp1.groupName,
        id: temp1.id,
        checked: false
      })
    })

    let finalAttachedGroups = []
    _.forEach(attachedData, (temp2) => {
      if (temp2?.clientGroup) {
        finalAttachedGroups.push({
          name: temp2?.clientGroup?.groupName,
          id: temp2.clientgroup_id,
          checked: true,
          deleteID: temp2.id
        })
      }
      if (temp2?.clientgroup_id === 0) {
        finalAttachedGroups.push({
          id: 0,
          checked: true,
          groupName: 'NO GROUP',
          deleteID: temp2.id
        })
      }
    })

    finalGroups.sort((a, b) => (a.name > b.name) ? 1 : -1)
    finalAttachedGroups.sort((a, b) => (a.name > b.name) ? 1 : -1)

    finalAttachedGroups.forEach(temp1 => {
      finalGroups.forEach(temp2 => {
        if (temp1.checked === true && temp2.id === temp1.id) {
          temp2.checked = true
        }
      })
    })

    return [{
      allGroups: finalGroups,
      attachedGroups: finalAttachedGroups
    }]
  }

  renderGridColumns = (dataItem, changeTheCellComponent) => {

    let selectionCol = []
    let normalCols = []
    let finalCols = []

    _.forEach(this.state.columns, col => {
      if (col.field === "selected") {

        selectionCol.push([
          <Column
            field="selected"
            width="65px"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(dataItem => dataItem.selected === false) === -1
            }
          />
        ])
      }
    })

    normalCols = this.state.columns.filter(temp1 => temp1.visible).map(temp => {

      if (temp.title === "Actions") {
        return null
      }

      if (temp.isFilterBoolean === "yes") {
        return (
          <Column
            field={temp.field}
            filterable={temp.filterable}
            title={temp.title}
            width={temp.minWidth || 'auto'}
            filter={temp.filter}
            visible={temp.visible}
            minGridWidth={"400"}
            cell={DropDownCell}

          />
        )
      }
      //   else if (temp.field === 'contact_type') {
      //     return (
      //       <Column
      //         field="contact_type"
      //         title="Type"
      //         width="365px"
      //         cell={() => <div>Dropdown component for type</div>}
      //       />
      //     );
      // }
      else {
        return (
          <Column
            field={temp.field}
            filterable={temp.filterable}
            title={temp.title}
            width={temp.minWidth || 'auto'}
            filter={temp.filter}
            visible={temp.visible}
            minGridWidth={"400"}
            editable={
              temp.field === 'id' || temp.editable
                ? false
                : true
            }
          />
        );
      }

    })

    finalCols = [...selectionCol, ...normalCols]
    return finalCols

  };

  toggleWindow = async (e) => {
    const options = { token: window.localStorage.getItem('access_token') };
    let filter1 = {
      filter: {
        where: {
          id: this.props.dataItem && this.props.dataItem.id
        },
        include: ["clientGroup"]
      }
    }

    const attachedGroups = await serverApi('GET', `masterEntities`, filter1, '', options);
    const getGroups = await serverApi('GET', `clientGroups`, '', '', options);

    let result = this.constructClientsAndExistingClientsArray(getGroups.data, attachedGroups.data)
    result[0].attachedGroups.forEach(item => {
      let client = result[0].allGroups.find(x => x.name === item.name)
      if (client) {
        client.checked = true
      }
    })

    if (attachedGroups && attachedGroups.data.length === 1) {
      let finalAttachedClients = []
      _.forEach(attachedGroups.data, (temp) => {
        if (temp?.clientGroup) {
          finalAttachedClients.push({
            id: temp.clientgroup_id,
            groupName: temp?.clientGroup?.groupName,
            deleteID: temp.id
          })
        }
        if (temp?.clientgroup_id === 0) {
          finalAttachedClients.push({
            id: 0,
            groupName: 'NO GROUP',
            deleteID: temp.id
          })
        }
      })

      this.setState({
        ...this.state,
        visibleWindow: !this.state.visibleWindow,
        ID: this.props.dataItem.id,
        gridData: {
          data: finalAttachedClients.length > 0 ? [finalAttachedClients[0]] : [],
          total: finalAttachedClients.length > 0 ? 1 : 0
        },
        initialGridData: {
          data: finalAttachedClients.length > 0 ? [finalAttachedClients[0]] : [],
          total: finalAttachedClients.length > 0 ? 1 : 0
        },
        group_names: getGroups.data.sort((a, b) => (a.groupName > b.groupName) ? 1 : -1),
        valueClientName: "",

        visibleClients: result[0].allGroups || [],
        finalUserClients: result[0].allGroups,
        initialFinalUserClients: result[0].allGroups,
        blocking: false

      })

    }

  }

  handleChange = async (event, name, key) => {
    let options = {
      token: window.localStorage.getItem('access_token')
    }
    this.setState({
      ...this.state,
      blocking: true
    })
    let finalUserClient = [...this.state.finalUserClients]
    let finalObj = finalUserClient.find(test => test.id === key)
    finalObj.checked = !finalObj.checked
    let groupID = key
    let postOrDeleteClient = event.value === false ? 'DELETE' : 'PATCH'
    if (postOrDeleteClient === 'PATCH') {
      let findPreviousChecked = finalUserClient.find(test => test.checked === true)
      if (findPreviousChecked) {
        let temp = {
          "relation_name": this.props.dataItem.relation_name,
          "relation_postal_code": this.props.dataItem.relation_postal_code,
          "relation_country": this.props.dataItem.relation_country,
          "clientgroup_id": null
        }
        const addClientToGroup = await serverApi('PATCH', `masterEntities/${this.props.dataItem.id}`, '', temp, options, '', '')
        if (addClientToGroup) {
          this.setState({ ...this.state })
        }
      }
    }

    this.setState({
      ...this.state,
      finalUserClients: finalUserClient
    }, async () => {

      if (postOrDeleteClient === 'PATCH') {
        const url = `masterEntities/${this.props.dataItem.id}`
        let payload = {
          "relation_name": this.props.dataItem.relation_name,
          "relation_postal_code": this.props.dataItem.relation_postal_code,
          "relation_country": this.props.dataItem.relation_country,
          "clientgroup_id": groupID
        }
        const addClientToGroup = serverApi('PATCH', url, '', payload, options, '', '')
        addClientToGroup
          .then(async response => {
            let filter1 = {
              filter: {
                where: {
                  id: this.props.dataItem && this.props.dataItem.id
                },
                include: ["clientGroup"]
              }
            }
            const attachedGroups = await serverApi('GET', `masterEntities`, filter1, '', options);
            const getGroups = await serverApi('GET', `clientGroups`, '', '', options);

            let result = this.constructClientsAndExistingClientsArray(getGroups.data, attachedGroups.data)
            result[0].attachedGroups.forEach(item => {
              let client = result[0].allGroups.find(x => x.name === item.name)
              if (client) {
                client.checked = true
              }
            })

            if (attachedGroups && attachedGroups.data.length === 1) {
              let finalAttachedClients = []
              _.forEach(attachedGroups.data, (temp) => {
                if (temp?.clientGroup) {
                  finalAttachedClients.push({
                    id: temp.clientgroup_id,
                    groupName: temp?.clientGroup?.groupName,
                    deleteID: temp.id
                  })
                }
                if (temp?.clientgroup_id === 0) {
                  finalAttachedClients.push({
                    id: 0,
                    groupName: 'NO GROUP',
                    deleteID: temp.id
                  })
                }
              })

              this.setState({
                ...this.state,
                loadingMessage: "",
                ID: this.props.dataItem.id,
                gridData: {
                  data: finalAttachedClients.length > 0 ? [finalAttachedClients[0]] : [],
                  total: finalAttachedClients.length > 0 ? 1 : 0
                },
                initialGridData: {
                  data: finalAttachedClients.length > 0 ? [finalAttachedClients[0]] : [],
                  total: finalAttachedClients.length > 0 ? 1 : 0
                },
                group_names: getGroups.data.sort((a, b) => (a.groupName > b.groupName) ? 1 : -1),
                valueClientName: "",
                visibleClients: result[0].allGroups || [],
                finalUserClients: result[0].allGroups,
                initialFinalUserClients: result[0].allGroups,
                blocking: false
              }, () => this.setState({ ...this.state, visibleWindow: false }))
            }
          })
          .catch(error => {
            this.setState({
              ...this.state,
              loadingMessage: "",
              openErrorModal: true,
              errorModalContent: error?.response?.data?.error?.message || "Please try again",
              blocking: false
            })
          })

      } else if (postOrDeleteClient === 'DELETE') {
        const url = `masterEntities/${this.props.dataItem.id}`

        let payload = {
          "relation_name": this.props.dataItem.relation_name,
          "relation_postal_code": this.props.dataItem.relation_postal_code,
          "relation_country": this.props.dataItem.relation_country,
          "clientgroup_id": null
        }
        const removeClientFromDomain = serverApi('PATCH', url, '', payload, options, '', '')
        removeClientFromDomain.then(async response => {
          let filter1 = {
            filter: {
              where: {
                id: this.props.dataItem && this.props.dataItem.id
              },
              include: ["clientGroup"]
            }
          }
          const attachedGroups = await serverApi('GET', `masterEntities`, filter1, '', options);
          const getGroups = await serverApi('GET', `clientGroups`, '', '', options);

          let result = this.constructClientsAndExistingClientsArray(getGroups.data, attachedGroups.data)
          result[0].attachedGroups.forEach(item => {
            let client = result[0].allGroups.find(x => x.name === item.name)
            if (client) {
              client.checked = true
            }
          })

          if (attachedGroups && attachedGroups.data.length === 1) {
            let finalAttachedClients = []
            _.forEach(attachedGroups.data, (temp) => {
              if (temp?.clientGroup) {
                finalAttachedClients.push({
                  id: temp.clientgroup_id,
                  groupName: temp?.clientGroup?.groupName,
                  deleteID: temp.id
                })
              }
            })

            this.setState({
              ...this.state,
              loadingMessage: "",
              ID: this.props.dataItem.id,
              gridData: {
                data: finalAttachedClients.length > 0 ? [finalAttachedClients[0]] : [],
                total: finalAttachedClients.length > 0 ? 1 : 0
              },
              initialGridData: {
                data: finalAttachedClients.length > 0 ? [finalAttachedClients[0]] : [],
                total: finalAttachedClients.length > 0 ? 1 : 0
              },
              group_names: getGroups.data.sort((a, b) => (a.groupName > b.groupName) ? 1 : -1),
              valueClientName: "",
              visibleClients: result[0].allGroups || [],
              finalUserClients: result[0].allGroups,
              initialFinalUserClients: result[0].allGroups,
              blocking: false
            })
          }
        })
          .catch(error => {
            this.setState({
              ...this.state,
              openErrorModal: true,
              errorModalContent: error?.response?.data?.error?.message || "Please try again",
              blocking: false
            })
          })
      }
    })
  }

  renderList = (finalClients = [], clients = [], doRefresh) => {

    if (doRefresh === true) {
      window.location.reload()
    } else {
      if (finalClients.length === clients.length) {

        return (
          clients && clients.map(item => (
            <ul style={{ marginBottom: '-5px' }} key={item.id}>
              <Checkbox
                name={item.name}
                key={item.id}
                value={item.checked}
                onChange={(e) => this.handleChange(e, item.name, item.id)}
                label={item.name}
                disabled={this.state.disableAllCheckboxes}
              />
            </ul>
          )
          )
        )
      } else {
        return (
          finalClients && finalClients.map(item => (
            <ul style={{ marginBottom: '-5px' }} key={item.id}>
              <Checkbox
                name={item.name}
                key={item.id}
                value={item.checked}
                onChange={(e) => this.handleChange(e, item.name, item.id)}
                label={item.name}
                disabled={this.state.disableAllCheckboxes}
              />
            </ul>
          )
          )
        )
      }
    }
  }

  handleSearch = (e) => {
    let initialFinalUserClients = this.state.initialFinalUserClients

    if (e.value === "") {
      let searchArr = []
      initialFinalUserClients.forEach(temp => {
        if (temp.name.toUpperCase().includes(e.value.toUpperCase())) {
          searchArr.push(temp)
        }
      });

      if (this.state.clientAttachedToAnotherDomain !== null && this.state.clientAttachedToAnotherDomain !== undefined) {
        let newArr = searchArr.filter(t => t.id !== this.state.clientAttachedToAnotherDomain)
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalUserClients: newArr,
          openErrorModal: false,
          errorModalContent: ""
        })
      } else {
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalUserClients: initialFinalUserClients,
          openErrorModal: false,
          errorModalContent: ""
        })
      }
    } else {

      let searchArr = []
      //case insensitive
      initialFinalUserClients.forEach(temp => {
        if (temp.name.toUpperCase().includes(e.value.toUpperCase())) {
          searchArr.push(temp)
        }
      });

      this.setState({
        ...this.state,
        inputValue: e.value,
        finalUserClients: searchArr,
        openErrorModal: false,
        errorModalContent: ""
      })
    }
  }

  enterEdit = (dataItem) => {

    let checkForEditDato = this.state.gridData.data
    const found = checkForEditDato.some(item => item.inEdit === true);
    if (found)
      return

    let total = this.state.gridData.total

    this.setState({
      gridData: {
        data: this.state.gridData && this.state.gridData.data.map(item =>
          item.id === dataItem.id ?
            { ...item, inEdit: true } : item
        ),
        total: total
      }
    });
  }

  discard = (dataItem, hasError = false) => {

    let data = [...this.state.gridData.data];
    const total = this.state.gridData.total

    if (hasError) {
      data[0].inEdit = true
      this.setState({
        gridData: {
          data: data,
          total: total
        }
      });
    } else {
      this.removeItem(data, dataItem);

      this.setState({
        gridData: {
          data: data,
          total: total
        },
        visible: false
      });
    }

  }

  cancel = (dataItem) => {

    const originalItem = this.state.initialGridData && this.state.initialGridData.data.find(p => p.id === dataItem.id);
    originalItem.inEdit = false

    const data = this.state.gridData && this.state.gridData.data.map(item => item.id === originalItem.id ? originalItem : item);

    const total = this.state.gridData.total

    this.setState({
      ...this.state,
      gridData: {
        data: data,
        total: total
      },
      openErrorModal: false,
      openErrorModalContent: ''
    });
  }

  removeItem(data, item) {

    let index = data.findIndex(p => p === item || p.id === item.id);
    if (index >= 0) {
      data.splice(index, 1);
    }
  }

  remove = async (dataItem) => {
    const data = [...this.state.gridData.data];
    let index = data.findIndex(p => p === dataItem || p.id === dataItem.id);
    if (index >= 0) {
      data.splice(index, 1);
    }
    try {
      let options = {
        token: window.localStorage.getItem('access_token')
      };

      // let filter1 = {
      //   filter: {
      //     where: {
      //       clientgroup_id: dataItem.id
      //     },
      //     include: [
      //       'masterEntity'
      //     ]
      //   }
      // }
      let payload = {
        "relation_name": this.props.dataItem.relation_name,
        "relation_postal_code": this.props.dataItem.relation_postal_code,
        "relation_country": this.props.dataItem.relation_country,
        "clientgroup_id": null
      }
      const patchRequest = await serverApi('PATCH', `masterEntities/${dataItem.deleteID}`, '', payload, options)
      if (patchRequest.status === 200) {
        let filter2 = {
          filter: {
            where: {
              id: this.props.dataItem && this.props.dataItem.id
            },
            include: ["clientGroup"]
          }
        }

        const attachedGroups = await serverApi('GET', `masterEntities`, filter2, '', options);
        const getGroups = await serverApi('GET', `clientGroups`, '', '', options);

        let result = this.constructClientsAndExistingClientsArray(getGroups.data, attachedGroups.data)

        result[0].attachedGroups.forEach(item => {
          let client = result[0].allGroups.find(x => x.name === item.name)
          if (client) {
            client.checked = true
          }
        })

        if (attachedGroups && attachedGroups.data.length === 1) {
          let finalAttachedClients = []
          _.forEach(attachedGroups.data, (temp) => {
            if (temp?.clientGroup) {
              finalAttachedClients.push({
                id: temp.clientgroup_id,
                groupName: temp?.clientGroup?.groupName,
                deleteID: temp.id
              })
            }
            if (temp?.clientgroup_id === 0) {
              finalAttachedClients.push({
                id: 0,
                groupName: 'NO GROUP',
                deleteID: temp.id
              })
            }
          })

          this.setState({
            ...this.state,
            ID: this.props.dataItem.id,
            visibleWindow: false,
            gridData: {
              data: finalAttachedClients.length > 0 ? [finalAttachedClients[0]] : [],
              total: finalAttachedClients.length > 0 ? 1 : 0
            },
            initialGridData: {
              data: finalAttachedClients.length > 0 ? [finalAttachedClients[0]] : [],
              total: finalAttachedClients.length > 0 ? 1 : 0
            },
            group_names: getGroups.data.sort((a, b) => (a.groupName > b.groupName) ? 1 : -1),
            valueClientName: "",

            visibleClients: result[0].allGroups || [],
            finalUserClients: result[0].allGroups,
            initialFinalUserClients: result[0].allGroups,
            blocking: false

          })

        }
      }

    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent: e?.response?.data?.error?.message || "Delete contact has failed.Please try again and if the problem persist contact the administrator."
      })
    }

  }

  add = (dataItem) => {


  }

  addNew = () => {

    let checkForEditDato = this.state.gridData.data
    const found = checkForEditDato.some(item => item.inEdit === true);
    if (found)
      return

    const newDataItem = { inEdit: true };

    this.setState({
      gridData: {
        data: [newDataItem, ...this.state.gridData.data],
        total: this.state.gridData.total
      },
      visibleWindow: true
    });

  }

  update = async (dataItem) => {

  }

  updateItem = (data, item) => {
    let index = data.findIndex(p => p === item || (item.id && p.id === item.id));
    if (index >= 0) {
      data[index] = { ...item };
    }
  }

  itemChange = (event) => {
    const total = this.state.gridData.total
    const data = this.state.gridData && this.state.gridData.data.map(item =>
      item.id === event.dataItem.id ?
        { ...item, [event.field]: event.value } : item
    );

    this.setState({
      gridData: {
        data: data,
        total: total
      }
    });
  }

  render() {

    const dataItem = this.props.dataItem;
    const { gridData } = this.state;
    const { inputValue } = this.state
    const { hasBackofficeRead } = this.props;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <div style={{
          width: "35%",
          marginBottom: '20px'
        }}>
          <h4 style={{ fontFamily: 'bold', textSize: '7px' }}>
            Client name : {dataItem && dataItem.relation_name}
          </h4>

          <Grid
            {...gridData}
            filterable={false}
            style={{ className: "grid-no-select" }}
            sortable={false}
            resizable
            editField={this.editField}
            onItemChange={this.itemChange}
            pageable={false}
          >
            <GridNoRecords>
              No data found . . .
            </GridNoRecords>
            <GridToolbar>
              <GridContainer justify={"flex-start"} direction={"row"}>
              {
              !hasBackofficeRead && (
                <Tooltip justify={"flex-start"} placement="top" title={"Attach Group To Client"}>
                  <IconButton >
                    <FontAwesomeIcon
                        color="#0D5869"
                        onClick={this.toggleWindow}
                        size="1.6x" icon={faPencilAlt}
                        />
                    </IconButton>
                   </Tooltip>
                )
              }
              </GridContainer>
            </GridToolbar>

            {!hasBackofficeRead &&
            <Column
              cell={this.CommandCell}
              title="Actions"
              filterable={false}
              width="240px"
            />}           
             {this.renderGridColumns(dataItem)}

          </Grid>
        </div>

        {
          this.state.visibleWindow &&
          <Window
            title={`*Each client should be assigned only in one Group.`}
            // style={{backgroundColor:'#0d5869'}}
            onClose={() => this.setState({
              ...this.state,
              visibleWindow: false
            })}
            width={900}
            height={600}
            initialTop={110}
          >
            <>
              <BlockUi tag="div" blocking={this.state.blocking} message="Loading, please wait. . ."
                renderChildren={true}
              >
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '50px' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {
                      this.state.loadingMessage !== ""
                        ? <Error style={{ color: 'orange', fontSize: '15px' }}>
                          {this.state.loadingMessage || ""}
                        </Error>
                        : null
                    }
                    {
                      this.state.openErrorModal
                        ? <Error style={{ color: 'red', fontSize: '15px' }}>
                          {this.state.errorModalContent || ""}
                        </Error>
                        : null
                    }
                    <div style={{ display: 'flex', marginLeft: '35px', marginTop: '20px' }}>
                      <Input
                        name="domains"
                        value={inputValue}
                        style={{ marginBottom: '20px' }}
                        label="Search groups"
                        onChange={e => this.handleSearch(e)}
                        minLength={2}
                      />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {this.renderList(this.state.finalUserClients, this.state.visibleClients)}
                    </div>

                  </div>
                </div>
              </BlockUi>
            </>
            {
              this.state.windowError
                ? <Error style={{ color: 'red' }}>
                  <h3>{this.state.windowErrorMessage || ""}</h3>
                </Error>
                : null
            }
          </Window>
        }
      </div>
    )
  }

}

export default ClientToGroup
