/**
 * Created by kfousteris on 12/06/2019.
 */

import React from 'react'
import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

const ErrorModal = (props) => {
    const {open, modalContent, onCloseModal,timeout} = props

    return (
        open ? <SweetAlert
            danger
            closeOnClickOutside={true}
            style={{display: "block", marginTop: "-100px", textColor: 'white', width:'50%'}}
            title={modalContent}
            timeout={timeout || 5000}
            onConfirm={onCloseModal}
            onCancel={onCloseModal}
            confirmBtnCssClass={
                props.classes.button + " " + props.classes.danger
            }

        /> : null
    )
}

export default withStyles(sweetAlertStyle)(ErrorModal)
