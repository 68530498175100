export const columnsSchema = () => [
  {
    field: 'id',
    title: 'ID',
    visible: true,
    // minWidth: 170,
    sortable: true,
    filterable: true,
    locked: 'locked',
  },
  {
    field: 'name',
    title: 'Name',
    visible: true,
    // minWidth: 170,
    sortable: true,
    filterable: true,
    locked: 'locked',
    editable: 'false'

  },
  {
    field: "primary_flg",
    title: "Primary Department",
    visible: true,
    sortable: false,
    minWidth: 170,
    filterable: false,
    locked: 'locked',
    filter: 'boolean',
    isFilterBoolean: "yes",
    editable: 'false'
  },
  {
    field: 'type',
    title: 'Type',
    visible: true,
    // minWidth: 170,
    sortable: true,
    filterable: true,
    locked: 'locked',
    editable: 'false'

  },
  {
    field: 'location',
    title: 'Location',
    visible: true,
    // minWidth: 170,
    sortable: true,
    filterable: true,
    locked: 'locked',
    editable: 'false'

  },
  {
    field: 'email',
    title: 'Email',
    visible: true,
    // minWidth: 170,
    sortable: true,
    filterable: true,
    locked: 'locked',
    editable: 'false'

  },
  // {
  //     field: 'agent.relation_name',
  //     title: 'Agent Name',
  //     visible: true,
  //     // minWidth: 170,
  //     sortable: true,
  //     filterable: true,
  //     locked: 'locked',
  //     editable: 'false'
  // }
];
