export const columnsSchema = () => [
  {
    field: "id",
    title: "ID",
    visible: true,
    minWidth: 200,
    sortable: true,
    filterable: true,
    locked: true,
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
  },
  {
    field: "priority",
    title: "Priority",
    visible: true,
    minWidth: 220,
    sortable: true,
    filterable: true,
    noDisabledColumn: false,
    locked: false,
  },
  {
    field: "userId",
    title: "User ID",
    visible: true,
    minWidth: 220,
    sortable: true,
    filterable: true,
    noDisabledColumn: false,
    locked: false,
  },
  {
    field: "username",
    title: "Username",
    visible: true,
    minWidth: 350,
    sortable: true,
    filterable: true,
    noDisabledColumn: false,
    locked: false,
  },
  // {
  //   field: "created",
  //   title: "Created",
  //   visible: true,
  //   minWidth: 200,
  //   sortable: true,
  //   filterable: true,
  //   noDisabledColumn: false,
  //   locked: false,
  // },
  // {
  //   field: "updated",
  //   title: "Updated",
  //   visible: true,
  //   minWidth: 200,
  //   sortable: true,
  //   filterable: true,
  //   noDisabledColumn: false,
  //   locked: false,
  // },
  // {
  //   field: "updatedBy",
  //   title: "Updated By",
  //   visible: true,
  //   minWidth: 150,
  //   sortable: true,
  //   filterable: true,
  //   noDisabledColumn: false,
  //   locked: false,
  // },
  // {
  //   field: "createdBy",
  //   title: "Created By",
  //   visible: true,
  //   minWidth: 150,
  //   sortable: true,
  //   filterable: true,
  //   noDisabledColumn: false,
  //   locked: false,
  // },
];
