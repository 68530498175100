export const columnsSchema = () => [
  {
    field: 'id',
    title: 'Department ID',
    visible: true,
    minWidth: 200,
    sortable: true,
    filterable: true,
    locked: 'locked',
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
    // cell: true
  },
  {
    field: 'name',
    title: 'Department Name',
    visible: true,
    minWidth: 420,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    locked: 'locked',
  },{
    field: 'type',
    title: 'Department Type',
    visible: true,
    minWidth: 420,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    locked: 'locked',
  },
  {
    field: 'deleteID',
    title: 'delete id',
    visible: false,
    // minWidth: 350,
    sortable: false,
    filterable: false,
    noDisabledColumn: true,
    locked: 'locked',
  },
]
