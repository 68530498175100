import React from "react";
import { columnsSchema } from "./schema";
import { MyCommandCell } from "../../../../Clients/View/components/CellHOC";
import { serverApi } from "../../../../../networking/config";
import _ from "lodash";
import { GridColumn as Column } from "@progress/kendo-react-grid/dist/npm/GridColumn";
import { Grid } from "@progress/kendo-react-grid";
import { DropDownCell } from "../../../../CRM/View/dropDownCell";
import ViewSettings from "../../../../common/ViewSettings";
import { formatDatesOnTable } from "../../../../../lib/GeneralUtils/utils";

export default class UserToDepartmentHistory extends React.Component {
  editField = "inEdit";
  CommandCell;
  _export;

  constructor(props) {
    super(props);

    this.state = {
      visibleWindow: false,
      removePrimaryDepartmentWindow: false,
      isDropOpened: false,
      columns: (columnsSchema && columnsSchema()) || [],
      gridData: {
        data: [],
        total: 10,
      },
      initialGridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 10,
        skip: 0,
      },
      openErrorModal: false,
      openErrorModalRemove: false,
      errorModalContent: "",
      errorModalContentRemove: "",
      delay: 400,
      loading: false,
      usernames: [],
      windowError: false,
      windowErrorRemove: false,
      windowErrorMessageRemove: "",
      windowErrorMessage: "",
      disableEditButton: true,
      inputValue: "",
      clientAttachedToAnotherGroup: null,
      exportData: [],
      open: false,
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      disableEditButton: false,
      showSecondAction: true,
      showFirstAction: false,
    });
  }

  async componentDidMount() {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    const userID = this.props.dataItem.id;
    let fil = {
      filter: {
        where: {
          userID: userID,
        },
        include: [
          {
            relation: "department",
          },
          {
            relation: "snUser",
          },
        ],
      },
    };

    let usersOfDepartment = await serverApi(
      "GET",
      `usertodept_histories`,
      fil,
      "",
      options
    );
    const formattedData = formatDatesOnTable(usersOfDepartment, [
      "start_date",
      "end_date",
    ]);

    let finalData = [];
    if (formattedData && formattedData.data.length > 1) {
      _.forEach(formattedData.data, (temp) => {
        let finalName = temp?.department?.name;
        finalData.push({
          id: temp.deptID,
          name: finalName || "N/A",
          user: temp?.snUser?.username,
          type: temp?.department?.type || "N/A",
          primary_flg: temp.primary_flg,
          start_date: temp.start_date,
          end_date: temp.end_date,
        });
      });
      finalData.sort((a, b) => (a.start_date < b.start_date ? 1 : -1));

      this.setState({
        ...this.state,
        userID: this.props.dataItem.id,
        gridData: {
          data: finalData,
          total: finalData.length,
        },
        initialGridData: {
          data: finalData,
          total: finalData.length,
        },
        valueUserName: "",
        blocking: false,
      });
    } else if (formattedData && formattedData.data.length === 1) {
      _.forEach(formattedData.data, (temp) => {
        let finalName = temp?.department?.name;

        finalData.push({
          id: temp.deptID,
          name: finalName || "N/A",
          user: temp?.snUser?.username,
          type: temp?.department?.type || "N/A",
          primary_flg: temp.primary_flg,
          start_date: temp.start_date,
          end_date: temp.end_date,
        });
      });
      finalData.sort((a, b) => (a.start_date < b.start_date ? 1 : -1));

      this.setState({
        ...this.state,
        userID: this.props.dataItem.id,
        gridData: {
          data: [finalData[0]],
          total: 1,
        },
        initialGridData: {
          data: [finalData[0]],
          total: 1,
        },
        valueUserName: "",
        blocking: false,
      });
    } else {
      this.setState({
        ...this.state,
        userID: this.props.dataItem.id,
        gridData: {
          data: [],
          total: 0,
        },
        initialGridData: {
          data: [],
          total: 0,
        },
        valueUserName: "",
        blocking: false,
      });
    }
  }

  componentWillUnmount() {}

  fetchLatestUserDepartments = async () => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    const userID = this.props.dataItem.id;
    let fil = {
      filter: {
        where: {
          userID: userID,
        },
        include: [
          {
            relation: "department",
          },
          {
            relation: "snUser",
          },
        ],
      },
    };

    let usersOfDepartment = await serverApi(
      "GET",
      `usertodept_histories`,
      fil,
      "",
      options
    );
    if (usersOfDepartment && usersOfDepartment.data.length > 1) {
      let finalData = [];
      _.forEach(usersOfDepartment.data, (temp) => {
        let finalName = temp?.department?.name;
        finalData.push({
          id: temp.deptID,
          name: finalName || "N/A",
          user: temp?.snUser?.username,
          type: temp?.department?.type || "N/A",
          primary_flg: temp.primary_flg,
          start_date: temp.start_date,
          end_date: temp.end_date,
        });
      });
      finalData.sort((a, b) => (a.start_date < b.start_date ? 1 : -1));

      this.setState({
        ...this.state,
        userID: this.props.dataItem.id,
        gridData: {
          data: finalData,
          total: finalData.length,
        },
        initialGridData: {
          data: finalData,
          total: finalData.length,
        },
        valueUserName: "",
        blocking: false,
      });
    } else if (usersOfDepartment && usersOfDepartment.data.length === 1) {
      let finalData = [];
      _.forEach(usersOfDepartment.data, (temp) => {
        let finalName = temp?.department?.name;

        finalData.push({
          id: temp.deptID,
          name: finalName || "N/A",
          user: temp?.snUser?.username,
          type: temp?.department?.type || "N/A",
          primary_flg: temp.primary_flg,
          start_date: temp.start_date,
          end_date: temp.end_date,
        });
      });
      finalData.sort((a, b) => (a.start_date < b.start_date ? 1 : -1));

      this.setState({
        ...this.state,
        userID: this.props.dataItem.id,
        gridData: {
          data: [finalData[0]],
          total: 1,
        },
        initialGridData: {
          data: [finalData[0]],
          total: 1,
        },
        valueUserName: "",
        blocking: false,
      });
    } else {
      this.setState({
        ...this.state,
        userID: this.props.dataItem.id,
        gridData: {
          data: [],
          total: 0,
        },
        initialGridData: {
          data: [],
          total: 0,
        },
        valueUserName: "",
        blocking: false,
      });
    }
  };

  add = (dataImte) => {};

  toggleWindow = (e) => {};

  toggleWindowRemove = (e) => {};

  renderGridColumns = (dataItem, changeTheCellComponent) => {
    let selectionCol = [];
    let normalCols = [];
    let finalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            width="70px"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.isFilterBoolean === "yes") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"420"}
              cell={DropDownCell}
              // cell={(propakia) => <PrimaryFlag {...propakia}  />}
            />
          );
        } else {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={temp.field === "id" || temp.editable ? false : true} //id not editable on edit
            />
          );
        }
      });

    finalCols = [...selectionCol, ...normalCols];
    return finalCols;
  };

  handleSearch = (e) => {};

  handleSuccessSnackbar = () => {};

  remove = async (dataItem) => {};

  cancel = (dataItem) => {};

  removeItem(data, item) {}

  discard = (dataItem, hasError = false) => {};

  renderList = (finalUsers = [], users = [], doRefresh) => {};

  handleChange = async (event, name, key) => {};

  getExportData = async () => {
    await this.exportToCsv(this.state, this.props);
  };

  getPromise = () => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    const userID = this.props.dataItem.id;

    let fil = {
      filter: {
        where: {
          userID: userID,
        },
        include: [
          {
            relation: "department",
          },
          {
            relation: "snUser",
          },
        ],
      },
    };

    return new Promise((resolve, reject) => {
      const usersOfDepartment = serverApi(
        "GET",
        `usertodept_histories`,
        fil,
        "",
        options
      );
      usersOfDepartment
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  exportToCsv = async (state, props) => {
    const count = state.gridData.total;
    const numberOfCalls = Math.min(
      Math.round(count / 1000) + 1,
      5 // Limit to 5000
    );

    const promises = [];
    for (let i = 0; i < numberOfCalls; i++) {
      promises.push(
        this.getPromise({
          skip: i * 1000,
          limit: 1000,
          order: ["id asc"],
        })
      );
    }

    try {
      const responses = await Promise.all(promises);
      let allData = [];
      responses.forEach((resp) => {
        let finalData = [];
        _.forEach(resp.data, (temp) => {
          let finalName = temp?.department?.name;

          finalData.push({
            id: temp.deptID,
            name: finalName || "N/A",
            user: temp?.snUser?.username,
            type: temp?.department?.type || "N/A",
            primary_flg: temp.primary_flg,
            start_date: temp.start_date,
            end_date: temp.end_date,
          });
        });
        finalData.sort((a, b) => (a.username > b.username ? 1 : -1));
        allData = [...allData, ...finalData];
      });
      if (allData.length > 0) {
        this.setState({
          ...this.state,
          exportData: allData,
        });
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent:
          e?.response?.data?.error?.message || "Please try again",
      });
    }
  };

  updateItem = (data, item) => {};

  update = async (dataItem) => {};

  enterEdit = (dataItem) => {};

  itemChange = (event) => {};

  render() {
    const dataItem = this.props.dataItem;
    const { gridData } = this.state;

    return (
      <div>
        <div style={{ display: "flex" }}>
          <h4 style={{ fontFamily: "bold", textSize: "7px" }}>
            <ViewSettings
              {...this.props}
              export={{
                data: this.state.exportData || [],
                fileName: "History_Of_User_Departments",
                exportFunction: this.getExportData,
                columns: columnsSchema(),
              }}
              viewSettings={{
                type: "",
              }}
              refreshSettings={true}
              refreshFunction={this.fetchLatestUserDepartments}
            />
          </h4>
        </div>

        <Grid
          {...(gridData || [])}
          filterable={false}
          style={{ className: "grid-no-select" }}
          sortable={true}
          resizable
          rowRender={this.rowRender}
          editField={this.editField}
          onItemChange={this.itemChange}
          pageable={false}
        >
          {this.renderGridColumns(dataItem)}
        </Grid>
      </div>
    );
  }
}
