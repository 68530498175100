export const columnsSchema = () => [
  {
    field: "vessel.id",
    title: "ID",
    visible: false,
    minWidth: 200,
    sortable: true,
    filterable: true,
    locked: "locked",
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
    // cell: true
  },
  {
    field: "vessel.vessel_name",
    title: "NAME",
    visible: true,
    minWidth: 280,
    sortable: true,
    filterable: true,
    locked: "locked",
    editable: "false",
  },
  {
    field: "vessel.vessel_imo_no",
    title: "IMO",
    visible: true,
    minWidth: 260,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    locked: "locked",
    editable: "false",
  },
  {
    field: "vessel.vessel_type",
    title: "TYPE",
    visible: true,
    minWidth: 250,
    sortable: true,
    filterable: true,
    noDisabledColumn: false,
    notShownInTableColumnSelection: false,
    locked: "locked",
    editable: "false",
  },
  {
    field: "vessel.vessel_flag_iso",
    title: "FLAG-ISO",
    visible: true,
    minWidth: 220,
    sortable: true,
    filterable: true,
    locked: "locked",
    editable: "false",
  },
  {
    field: "vessel.vessel_build_dt",
    title: "BUILD-DT",
    visible: true,
    minWidth: 240,
    sortable: true,
    filterable: true,
    locked: "locked",
    notShownInTableColumnSelection: false,
    editable: "false",
  },
  {
    field: "vessel.vessel_builder",
    title: "BUILDER",
    visible: true,
    sortable: true,
    minWidth: 250,
    filterable: true,
    locked: "locked",
    editable: "false",
  },
  {
    field: "vessel.vessel_scrapped_dt",
    title: "SCRAPPED-DT",
    visible: true,
    sortable: true,
    minWidth: 300,
    filterable: true,
    locked: "locked",
    editable: "false",
  },
  {
    field: "vessel.vessel_deadweight",
    title: "WEIGHT",
    visible: true,
    sortable: true,
    minWidth: 240,
    filterable: true,
    locked: "locked",
    editable: "false",
  },
  {
    field: "vessel.vessel_build_country",
    title: "BUILD-COUNTRY",
    visible: true,
    sortable: true,
    minWidth: 300,
    filterable: true,
    locked: "locked",
    editable: "false",
  },
];
