
export const enums = {
    RendererTypes:{
        FILTER_INPUT:'filterInput',
        FILTER_SELECT:'filterSelect',
        INPUT:'input',
        PASSWORD_INPUT: 'passwordInput',
        LINK:'link',
        SELECT:'select',
        DATE:'date',
        DATE_RANGE:'dateRange',
        SEARCH_INPUT:'searchInput',
        RENDER_GALLERY_IMAGES:'renderGalleryImages',
        RENDER_API_KEY:'renderConditionalInput',
        RENDER_COUNT_UP:'renderCountUp',
        RENDER_TABLE:'renderTable',
        ASSOCIATION:'association',
        INPUT_CURRENCY_RENDERER:'InputCurrencyRenderer',
        SELECT_AUTOCOMPLETE: 'SelectAutocomplete',
        SELECT_AUTOCOMPLETE_KENDO_REACT: 'SelectAutocompleteKendoReact',
        CREDIT_BUTTON: 'creditButton',
        TEXT_EDITOR:'textEditor',
        SWITCH:'switch'
    },
    SchemaFieldsTypes:{
        BUTTON:'button',
        FIELDS:'fields'
    },
    UploadTypes: {
        BUTTON: 'button',
        FIELD: 'field'
    }
}

export const roles = {
  OPERATOR: 6,
  AGENT: 121,
  SUPERROLE: 301,
  CLIENT: 7,
  ACCOUNTANT: 3,
  WAREHOUSE: 61,
  SUPPLIER: 302,
  TEAMLEADER: 281,
  CUSTOMSBROKER: 303,
  QUOTESUPERVISOR: 304,
  VESSEL: 41,
  CLIENTMB: 101,
  BACKOFFICE: 305,
  GUESTSUPPLIER: 221,
  BACKOFFICEMASTERDATAREAD: 421,
  BACKOFFICEMASTERDATA: 422,
};

export const countryCodes =  [
	{
		"NAME" : "Antilles",
		"ALPHA_2" : "AN",
		"ALPHA_3" : "ANT",
		"REGION" : "Europe",
		"SUB_REGION" : null,
		"LATITUDE" : 51.768797,
		"LONGITUDE" : 5.333509,
		"CURRENCY" : "ANG"
	},
	{
		"NAME" : "Afghanistan",
		"ALPHA_2" : "AF",
		"ALPHA_3" : "AFG",
		"REGION" : "Asia",
		"SUB_REGION" : "Southern Asia",
		"LATITUDE" : 33.93911,
		"LONGITUDE" : 67.709953,
		"CURRENCY" : "AFN"
	},
	{
		"NAME" : "Aland Islands",
		"ALPHA_2" : "AX",
		"ALPHA_3" : "ALA",
		"REGION" : "Europe",
		"SUB_REGION" : "Northern Europe",
		"LATITUDE" : 60.1995487,
		"LONGITUDE" : 20.3711715,
		"CURRENCY" : "EUR"
	},
	{
		"NAME" : "Albania",
		"ALPHA_2" : "AL",
		"ALPHA_3" : "ALB",
		"REGION" : "Europe",
		"SUB_REGION" : "Southern Europe",
		"LATITUDE" : 41.153332,
		"LONGITUDE" : 20.168331,
		"CURRENCY" : "ALL"
	},
	{
		"NAME" : "Algeria",
		"ALPHA_2" : "DZ",
		"ALPHA_3" : "DZA",
		"REGION" : "Africa",
		"SUB_REGION" : "Northern Africa",
		"LATITUDE" : 28.033886,
		"LONGITUDE" : 1.659626,
		"CURRENCY" : "DZD"
	},
	{
		"NAME" : "American Samoa",
		"ALPHA_2" : "AS",
		"ALPHA_3" : "ASM",
		"REGION" : "Oceania",
		"SUB_REGION" : "Polynesia",
		"LATITUDE" : -14.270972,
		"LONGITUDE" : -170.132217,
		"CURRENCY" : "EUR"
	},
	{
		"NAME" : "Andorra",
		"ALPHA_2" : "AD",
		"ALPHA_3" : "AND",
		"REGION" : "Europe",
		"SUB_REGION" : "Southern Europe",
		"LATITUDE" : 42.546245,
		"LONGITUDE" : 1.601554,
		"CURRENCY" : "EUR"
	},
	{
		"NAME" : "Angola",
		"ALPHA_2" : "AO",
		"ALPHA_3" : "AGO",
		"REGION" : "Africa",
		"SUB_REGION" : "Middle Africa",
		"LATITUDE" : -11.202692,
		"LONGITUDE" : 17.873887,
		"CURRENCY" : "AOA"
	},
	{
		"NAME" : "Anguilla",
		"ALPHA_2" : "AI",
		"ALPHA_3" : "AIA",
		"REGION" : "Americas",
		"SUB_REGION" : "Caribbean",
		"LATITUDE" : 18.220554,
		"LONGITUDE" : -63.068615,
		"CURRENCY" : "XCD"
	},
	{
		"NAME" : "Antarctica",
		"ALPHA_2" : "AQ",
		"ALPHA_3" : "ATA",
		"REGION" : null,
		"SUB_REGION" : null,
		"LATITUDE" : -75.250973,
		"LONGITUDE" : -0.071389,
		"CURRENCY" : null
	},
	{
		"NAME" : "Antigua and Barbuda",
		"ALPHA_2" : "AG",
		"ALPHA_3" : "ATG",
		"REGION" : "Americas",
		"SUB_REGION" : "Caribbean",
		"LATITUDE" : 17.060816,
		"LONGITUDE" : -61.796428,
		"CURRENCY" : "XCD"
	},
	{
		"NAME" : "Argentina",
		"ALPHA_2" : "AR",
		"ALPHA_3" : "ARG",
		"REGION" : "Americas",
		"SUB_REGION" : "South America",
		"LATITUDE" : -38.416097,
		"LONGITUDE" : -63.616672,
		"CURRENCY" : "ARS"
	},
	{
		"NAME" : "Armenia",
		"ALPHA_2" : "AM",
		"ALPHA_3" : "ARM",
		"REGION" : "Asia",
		"SUB_REGION" : "Western Asia",
		"LATITUDE" : 40.069099,
		"LONGITUDE" : 45.038189,
		"CURRENCY" : "AMD"
	},
	{
		"NAME" : "Aruba",
		"ALPHA_2" : "AW",
		"ALPHA_3" : "ABW",
		"REGION" : "Americas",
		"SUB_REGION" : "Caribbean",
		"LATITUDE" : 12.52111,
		"LONGITUDE" : -69.968338,
		"CURRENCY" : "ANG"
	},
	{
		"NAME" : "Australia",
		"ALPHA_2" : "AU",
		"ALPHA_3" : "AUS",
		"REGION" : "Oceania",
		"SUB_REGION" : "Australia and New Zealand",
		"LATITUDE" : -25.274398,
		"LONGITUDE" : 133.775136,
		"CURRENCY" : "AUD"
	},
	{
		"NAME" : "Austria",
		"ALPHA_2" : "AT",
		"ALPHA_3" : "AUT",
		"REGION" : "Europe",
		"SUB_REGION" : "Western Europe",
		"LATITUDE" : 47.516231,
		"LONGITUDE" : 14.550072,
		"CURRENCY" : "EUR"
	},
	{
		"NAME" : "Azerbaijan",
		"ALPHA_2" : "AZ",
		"ALPHA_3" : "AZE",
		"REGION" : "Asia",
		"SUB_REGION" : "Western Asia",
		"LATITUDE" : 40.143105,
		"LONGITUDE" : 47.576927,
		"CURRENCY" : "AZN"
	},
	{
		"NAME" : "Bahamas",
		"ALPHA_2" : "BS",
		"ALPHA_3" : "BHS",
		"REGION" : "Americas",
		"SUB_REGION" : "Caribbean",
		"LATITUDE" : 25.03428,
		"LONGITUDE" : -77.39628,
		"CURRENCY" : "BSD"
	},
	{
		"NAME" : "Bahrain",
		"ALPHA_2" : "BH",
		"ALPHA_3" : "BHR",
		"REGION" : "Asia",
		"SUB_REGION" : "Western Asia",
		"LATITUDE" : 25.930414,
		"LONGITUDE" : 50.637772,
		"CURRENCY" : "BHD"
	},
	{
		"NAME" : "Bangladesh",
		"ALPHA_2" : "BD",
		"ALPHA_3" : "BGD",
		"REGION" : "Asia",
		"SUB_REGION" : "Southern Asia",
		"LATITUDE" : 23.684994,
		"LONGITUDE" : 90.356331,
		"CURRENCY" : "BDT"
	},
	{
		"NAME" : "Barbados",
		"ALPHA_2" : "BB",
		"ALPHA_3" : "BRB",
		"REGION" : "Americas",
		"SUB_REGION" : "Caribbean",
		"LATITUDE" : 13.193887,
		"LONGITUDE" : -59.543198,
		"CURRENCY" : "BBD"
	},
	{
		"NAME" : "Belarus",
		"ALPHA_2" : "BY",
		"ALPHA_3" : "BLR",
		"REGION" : "Europe",
		"SUB_REGION" : "Eastern Europe",
		"LATITUDE" : 53.709807,
		"LONGITUDE" : 27.953389,
		"CURRENCY" : "BYR"
	},
	{
		"NAME" : "Belgium",
		"ALPHA_2" : "BE",
		"ALPHA_3" : "BEL",
		"REGION" : "Europe",
		"SUB_REGION" : "Western Europe",
		"LATITUDE" : 50.503887,
		"LONGITUDE" : 4.469936,
		"CURRENCY" : "EUR"
	},
	{
		"NAME" : "Belize",
		"ALPHA_2" : "BZ",
		"ALPHA_3" : "BLZ",
		"REGION" : "Americas",
		"SUB_REGION" : "Central America",
		"LATITUDE" : 17.189877,
		"LONGITUDE" : -88.49765,
		"CURRENCY" : "BZD"
	},
	{
		"NAME" : "Benin",
		"ALPHA_2" : "BJ",
		"ALPHA_3" : "BEN",
		"REGION" : "Africa",
		"SUB_REGION" : "Western Africa",
		"LATITUDE" : 9.30769,
		"LONGITUDE" : 2.315834,
		"CURRENCY" : "XOF"
	},
	{
		"NAME" : "Bermuda",
		"ALPHA_2" : "BM",
		"ALPHA_3" : "BMU",
		"REGION" : "Americas",
		"SUB_REGION" : "Northern America",
		"LATITUDE" : 32.321384,
		"LONGITUDE" : -64.75737,
		"CURRENCY" : "BMD"
	},
	{
		"NAME" : "Bhutan",
		"ALPHA_2" : "BT",
		"ALPHA_3" : "BTN",
		"REGION" : "Asia",
		"SUB_REGION" : "Southern Asia",
		"LATITUDE" : 27.514162,
		"LONGITUDE" : 90.433601,
		"CURRENCY" : "INR"
	},
	{
		"NAME" : "Bolivia (Plurinational State of)",
		"ALPHA_2" : "BO",
		"ALPHA_3" : "BOL",
		"REGION" : "Americas",
		"SUB_REGION" : "South America",
		"LATITUDE" : -16.290154,
		"LONGITUDE" : -63.588653,
		"CURRENCY" : "BOB"
	},
	{
		"NAME" : "Bonaire, Sint Eustatius and Saba",
		"ALPHA_2" : "BQ",
		"ALPHA_3" : "BES",
		"REGION" : "Americas",
		"SUB_REGION" : "Caribbean",
		"LATITUDE" : 12.1683718,
		"LONGITUDE" : -68.308183,
		"CURRENCY" : "USD"
	},
	{
		"NAME" : "Bosnia and Herzegovina",
		"ALPHA_2" : "BA",
		"ALPHA_3" : "BIH",
		"REGION" : "Europe",
		"SUB_REGION" : "Southern Europe",
		"LATITUDE" : 43.915886,
		"LONGITUDE" : 17.679076,
		"CURRENCY" : "BAM"
	},
	{
		"NAME" : "Botswana",
		"ALPHA_2" : "BW",
		"ALPHA_3" : "BWA",
		"REGION" : "Africa",
		"SUB_REGION" : "Southern Africa",
		"LATITUDE" : -22.328474,
		"LONGITUDE" : 24.684866,
		"CURRENCY" : "BWP"
	},
	{
		"NAME" : "Bouvet Island",
		"ALPHA_2" : "BV",
		"ALPHA_3" : "BVT",
		"REGION" : null,
		"SUB_REGION" : null,
		"LATITUDE" : -54.423199,
		"LONGITUDE" : 3.413194,
		"CURRENCY" : "NOK"
	},
	{
		"NAME" : "Brazil",
		"ALPHA_2" : "BR",
		"ALPHA_3" : "BRA",
		"REGION" : "Americas",
		"SUB_REGION" : "South America",
		"LATITUDE" : -14.235004,
		"LONGITUDE" : -51.92528,
		"CURRENCY" : "BRL"
	},
	{
		"NAME" : "British Indian Ocean Territory",
		"ALPHA_2" : "IO",
		"ALPHA_3" : "IOT",
		"REGION" : null,
		"SUB_REGION" : null,
		"LATITUDE" : -6.343194,
		"LONGITUDE" : 71.876519,
		"CURRENCY" : "USD"
	},
	{
		"NAME" : "Brunei Darussalam",
		"ALPHA_2" : "BN",
		"ALPHA_3" : "BRN",
		"REGION" : "Asia",
		"SUB_REGION" : "South-Eastern Asia",
		"LATITUDE" : 4.535277,
		"LONGITUDE" : 114.727669,
		"CURRENCY" : "BND"
	},
	{
		"NAME" : "Bulgaria",
		"ALPHA_2" : "BG",
		"ALPHA_3" : "BGR",
		"REGION" : "Europe",
		"SUB_REGION" : "Eastern Europe",
		"LATITUDE" : 42.733883,
		"LONGITUDE" : 25.48583,
		"CURRENCY" : "BGN"
	},
	{
		"NAME" : "Burkina Faso",
		"ALPHA_2" : "BF",
		"ALPHA_3" : "BFA",
		"REGION" : "Africa",
		"SUB_REGION" : "Western Africa",
		"LATITUDE" : 12.238333,
		"LONGITUDE" : -1.561593,
		"CURRENCY" : "XOF"
	},
	{
		"NAME" : "Burundi",
		"ALPHA_2" : "BI",
		"ALPHA_3" : "BDI",
		"REGION" : "Africa",
		"SUB_REGION" : "Eastern Africa",
		"LATITUDE" : -3.373056,
		"LONGITUDE" : 29.918886,
		"CURRENCY" : "BIF"
	},
	{
		"NAME" : "Cambodia",
		"ALPHA_2" : "KH",
		"ALPHA_3" : "KHM",
		"REGION" : "Asia",
		"SUB_REGION" : "South-Eastern Asia",
		"LATITUDE" : 12.565679,
		"LONGITUDE" : 104.990963,
		"CURRENCY" : "KHR"
	},
	{
		"NAME" : "Cameroon",
		"ALPHA_2" : "CM",
		"ALPHA_3" : "CMR",
		"REGION" : "Africa",
		"SUB_REGION" : "Middle Africa",
		"LATITUDE" : 7.369722,
		"LONGITUDE" : 12.354722,
		"CURRENCY" : "XAF"
	},
	{
		"NAME" : "Canada",
		"ALPHA_2" : "CA",
		"ALPHA_3" : "CAN",
		"REGION" : "Americas",
		"SUB_REGION" : "Northern America",
		"LATITUDE" : 56.130366,
		"LONGITUDE" : -106.346771,
		"CURRENCY" : "CAD"
	},
	{
		"NAME" : "Cabo Verde",
		"ALPHA_2" : "CV",
		"ALPHA_3" : "CPV",
		"REGION" : "Africa",
		"SUB_REGION" : "Western Africa",
		"LATITUDE" : 16.002082,
		"LONGITUDE" : -24.013197,
		"CURRENCY" : "CVE"
	},
	{
		"NAME" : "Cayman Islands",
		"ALPHA_2" : "KY",
		"ALPHA_3" : "CYM",
		"REGION" : "Americas",
		"SUB_REGION" : "Caribbean",
		"LATITUDE" : 19.513469,
		"LONGITUDE" : -80.566956,
		"CURRENCY" : "KYD"
	},
	{
		"NAME" : "Central African Republic",
		"ALPHA_2" : "CF",
		"ALPHA_3" : "CAF",
		"REGION" : "Africa",
		"SUB_REGION" : "Middle Africa",
		"LATITUDE" : 6.611111,
		"LONGITUDE" : 20.939444,
		"CURRENCY" : "XAF"
	},
	{
		"NAME" : "Chad",
		"ALPHA_2" : "TD",
		"ALPHA_3" : "TCD",
		"REGION" : "Africa",
		"SUB_REGION" : "Middle Africa",
		"LATITUDE" : 15.454166,
		"LONGITUDE" : 18.732207,
		"CURRENCY" : "XAF"
	},
	{
		"NAME" : "Chile",
		"ALPHA_2" : "CL",
		"ALPHA_3" : "CHL",
		"REGION" : "Americas",
		"SUB_REGION" : "South America",
		"LATITUDE" : -35.675147,
		"LONGITUDE" : -71.542969,
		"CURRENCY" : "CLP"
	},
	{
		"NAME" : "China",
		"ALPHA_2" : "CN",
		"ALPHA_3" : "CHN",
		"REGION" : "Asia",
		"SUB_REGION" : "Eastern Asia",
		"LATITUDE" : 35.86166,
		"LONGITUDE" : 104.195397,
		"CURRENCY" : "CNY"
	},
	{
		"NAME" : "Christmas Island",
		"ALPHA_2" : "CX",
		"ALPHA_3" : "CXR",
		"REGION" : null,
		"SUB_REGION" : null,
		"LATITUDE" : -10.447525,
		"LONGITUDE" : 105.690449,
		"CURRENCY" : "AUD"
	},
	{
		"NAME" : "Cocos (Keeling) Islands",
		"ALPHA_2" : "CC",
		"ALPHA_3" : "CCK",
		"REGION" : null,
		"SUB_REGION" : null,
		"LATITUDE" : -12.164165,
		"LONGITUDE" : 96.870956,
		"CURRENCY" : "AUD"
	},
	{
		"NAME" : "Colombia",
		"ALPHA_2" : "CO",
		"ALPHA_3" : "COL",
		"REGION" : "Americas",
		"SUB_REGION" : "South America",
		"LATITUDE" : 4.570868,
		"LONGITUDE" : -74.297333,
		"CURRENCY" : "COP"
	},
	{
		"NAME" : "Comoros",
		"ALPHA_2" : "KM",
		"ALPHA_3" : "COM",
		"REGION" : "Africa",
		"SUB_REGION" : "Eastern Africa",
		"LATITUDE" : -11.875001,
		"LONGITUDE" : 43.872219,
		"CURRENCY" : "KMF"
	},
	{
		"NAME" : "Congo",
		"ALPHA_2" : "CG",
		"ALPHA_3" : "COG",
		"REGION" : "Africa",
		"SUB_REGION" : "Middle Africa",
		"LATITUDE" : -0.228021,
		"LONGITUDE" : 15.827659,
		"CURRENCY" : "XAF"
	},
	{
		"NAME" : "Congo (Democratic Republic of the)",
		"ALPHA_2" : "CD",
		"ALPHA_3" : "COD",
		"REGION" : "Africa",
		"SUB_REGION" : "Middle Africa",
		"LATITUDE" : -4.038333,
		"LONGITUDE" : 21.758664,
		"CURRENCY" : "CDF"
	},
	{
		"NAME" : "Cook Islands",
		"ALPHA_2" : "CK",
		"ALPHA_3" : "COK",
		"REGION" : "Oceania",
		"SUB_REGION" : "Polynesia",
		"LATITUDE" : -21.236736,
		"LONGITUDE" : -159.777671,
		"CURRENCY" : "NZD"
	},
	{
		"NAME" : "Costa Rica",
		"ALPHA_2" : "CR",
		"ALPHA_3" : "CRI",
		"REGION" : "Americas",
		"SUB_REGION" : "Central America",
		"LATITUDE" : 9.748917,
		"LONGITUDE" : -83.753428,
		"CURRENCY" : "CRC"
	},
	{
		"NAME" : "Côte d'Ivoire",
		"ALPHA_2" : "CI",
		"ALPHA_3" : "CIV",
		"REGION" : "Africa",
		"SUB_REGION" : "Western Africa",
		"LATITUDE" : 7.539989,
		"LONGITUDE" : -5.54708,
		"CURRENCY" : "XOF"
	},
	{
		"NAME" : "Croatia",
		"ALPHA_2" : "HR",
		"ALPHA_3" : "HRV",
		"REGION" : "Europe",
		"SUB_REGION" : "Southern Europe",
		"LATITUDE" : 45.1,
		"LONGITUDE" : 15.2,
		"CURRENCY" : "HRK"
	},
	{
		"NAME" : "Cuba",
		"ALPHA_2" : "CU",
		"ALPHA_3" : "CUB",
		"REGION" : "Americas",
		"SUB_REGION" : "Caribbean",
		"LATITUDE" : 21.521757,
		"LONGITUDE" : -77.781167,
		"CURRENCY" : "CUP"
	},
	{
		"NAME" : "Curaçao",
		"ALPHA_2" : "CW",
		"ALPHA_3" : "CUW",
		"REGION" : "Americas",
		"SUB_REGION" : "Caribbean",
		"LATITUDE" : 12.16957,
		"LONGITUDE" : -68.99002,
		"CURRENCY" : "ANG"
	},
	{
		"NAME" : "Cyprus",
		"ALPHA_2" : "CY",
		"ALPHA_3" : "CYP",
		"REGION" : "Asia",
		"SUB_REGION" : "Western Asia",
		"LATITUDE" : 35.126413,
		"LONGITUDE" : 33.429859,
		"CURRENCY" : "CYP"
	},
	{
		"NAME" : "Czech Republic",
		"ALPHA_2" : "CZ",
		"ALPHA_3" : "CZE",
		"REGION" : "Europe",
		"SUB_REGION" : "Eastern Europe",
		"LATITUDE" : 49.817492,
		"LONGITUDE" : 15.472962,
		"CURRENCY" : "CZK"
	},
	{
		"NAME" : "Denmark",
		"ALPHA_2" : "DK",
		"ALPHA_3" : "DNK",
		"REGION" : "Europe",
		"SUB_REGION" : "Northern Europe",
		"LATITUDE" : 56.26392,
		"LONGITUDE" : 9.501785,
		"CURRENCY" : "DKK"
	},
	{
		"NAME" : "Djibouti",
		"ALPHA_2" : "DJ",
		"ALPHA_3" : "DJI",
		"REGION" : "Africa",
		"SUB_REGION" : "Eastern Africa",
		"LATITUDE" : 11.825138,
		"LONGITUDE" : 42.590275,
		"CURRENCY" : "DJF"
	},
	{
		"NAME" : "Dominica",
		"ALPHA_2" : "DM",
		"ALPHA_3" : "DMA",
		"REGION" : "Americas",
		"SUB_REGION" : "Caribbean",
		"LATITUDE" : 15.414999,
		"LONGITUDE" : -61.370976,
		"CURRENCY" : "XCD"
	},
	{
		"NAME" : "Dominican Republic",
		"ALPHA_2" : "DO",
		"ALPHA_3" : "DOM",
		"REGION" : "Americas",
		"SUB_REGION" : "Caribbean",
		"LATITUDE" : 18.735693,
		"LONGITUDE" : -70.162651,
		"CURRENCY" : "DOP"
	},
	{
		"NAME" : "Ecuador",
		"ALPHA_2" : "EC",
		"ALPHA_3" : "ECU",
		"REGION" : "Americas",
		"SUB_REGION" : "South America",
		"LATITUDE" : -1.831239,
		"LONGITUDE" : -78.183406,
		"CURRENCY" : "ECS"
	},
	{
		"NAME" : "Egypt",
		"ALPHA_2" : "EG",
		"ALPHA_3" : "EGY",
		"REGION" : "Africa",
		"SUB_REGION" : "Northern Africa",
		"LATITUDE" : 26.820553,
		"LONGITUDE" : 30.802498,
		"CURRENCY" : "EGP"
	},
	{
		"NAME" : "El Salvador",
		"ALPHA_2" : "SV",
		"ALPHA_3" : "SLV",
		"REGION" : "Americas",
		"SUB_REGION" : "Central America",
		"LATITUDE" : 13.794185,
		"LONGITUDE" : -88.89653,
		"CURRENCY" : "SVC"
	},
	{
		"NAME" : "Equatorial Guinea",
		"ALPHA_2" : "GQ",
		"ALPHA_3" : "GNQ",
		"REGION" : "Africa",
		"SUB_REGION" : "Middle Africa",
		"LATITUDE" : 1.650801,
		"LONGITUDE" : 10.267895,
		"CURRENCY" : "XAF"
	},
	{
		"NAME" : "Eritrea",
		"ALPHA_2" : "ER",
		"ALPHA_3" : "ERI",
		"REGION" : "Africa",
		"SUB_REGION" : "Eastern Africa",
		"LATITUDE" : 15.179384,
		"LONGITUDE" : 39.782334,
		"CURRENCY" : "ETB"
	},
	{
		"NAME" : "Estonia",
		"ALPHA_2" : "EE",
		"ALPHA_3" : "EST",
		"REGION" : "Europe",
		"SUB_REGION" : "Northern Europe",
		"LATITUDE" : 58.595272,
		"LONGITUDE" : 25.013607,
		"CURRENCY" : "EEK"
	},
	{
		"NAME" : "Ethiopia",
		"ALPHA_2" : "ET",
		"ALPHA_3" : "ETH",
		"REGION" : "Africa",
		"SUB_REGION" : "Eastern Africa",
		"LATITUDE" : 9.145,
		"LONGITUDE" : 40.489673,
		"CURRENCY" : "ETB"
	},
	{
		"NAME" : "Falkland Islands (Malvinas)",
		"ALPHA_2" : "FK",
		"ALPHA_3" : "FLK",
		"REGION" : "Americas",
		"SUB_REGION" : "South America",
		"LATITUDE" : -51.796253,
		"LONGITUDE" : -59.523613,
		"CURRENCY" : "FKP"
	},
	{
		"NAME" : "Faroe Islands",
		"ALPHA_2" : "FO",
		"ALPHA_3" : "FRO",
		"REGION" : "Europe",
		"SUB_REGION" : "Northern Europe",
		"LATITUDE" : 61.892635,
		"LONGITUDE" : -6.911806,
		"CURRENCY" : "DKK"
	},
	{
		"NAME" : "Fiji",
		"ALPHA_2" : "FJ",
		"ALPHA_3" : "FJI",
		"REGION" : "Oceania",
		"SUB_REGION" : "Melanesia",
		"LATITUDE" : -16.578193,
		"LONGITUDE" : 179.414413,
		"CURRENCY" : "FJD"
	},
	{
		"NAME" : "Finland",
		"ALPHA_2" : "FI",
		"ALPHA_3" : "FIN",
		"REGION" : "Europe",
		"SUB_REGION" : "Northern Europe",
		"LATITUDE" : 61.92411,
		"LONGITUDE" : 25.748151,
		"CURRENCY" : "EUR"
	},
	{
		"NAME" : "France",
		"ALPHA_2" : "FR",
		"ALPHA_3" : "FRA",
		"REGION" : "Europe",
		"SUB_REGION" : "Western Europe",
		"LATITUDE" : 46.227638,
		"LONGITUDE" : 2.213749,
		"CURRENCY" : "EUR"
	},
	{
		"NAME" : "French Guiana",
		"ALPHA_2" : "GF",
		"ALPHA_3" : "GUF",
		"REGION" : "Americas",
		"SUB_REGION" : "South America",
		"LATITUDE" : 3.933889,
		"LONGITUDE" : -53.125782,
		"CURRENCY" : "EUR"
	},
	{
		"NAME" : "French Polynesia",
		"ALPHA_2" : "PF",
		"ALPHA_3" : "PYF",
		"REGION" : "Oceania",
		"SUB_REGION" : "Polynesia",
		"LATITUDE" : -17.679742,
		"LONGITUDE" : -149.406843,
		"CURRENCY" : "XPF"
	},
	{
		"NAME" : "French Southern Territories",
		"ALPHA_2" : "TF",
		"ALPHA_3" : "ATF",
		"REGION" : null,
		"SUB_REGION" : null,
		"LATITUDE" : -49.280366,
		"LONGITUDE" : 69.348557,
		"CURRENCY" : "EUR"
	},
	{
		"NAME" : "Gabon",
		"ALPHA_2" : "GA",
		"ALPHA_3" : "GAB",
		"REGION" : "Africa",
		"SUB_REGION" : "Middle Africa",
		"LATITUDE" : -0.803689,
		"LONGITUDE" : 11.609444,
		"CURRENCY" : "XAF"
	},
	{
		"NAME" : "Gambia",
		"ALPHA_2" : "GM",
		"ALPHA_3" : "GMB",
		"REGION" : "Africa",
		"SUB_REGION" : "Western Africa",
		"LATITUDE" : 13.443182,
		"LONGITUDE" : -15.310139,
		"CURRENCY" : "GMD"
	},
	{
		"NAME" : "Georgia",
		"ALPHA_2" : "GE",
		"ALPHA_3" : "GEO",
		"REGION" : "Asia",
		"SUB_REGION" : "Western Asia",
		"LATITUDE" : 42.315407,
		"LONGITUDE" : 43.356892,
		"CURRENCY" : "GEL"
	},
	{
		"NAME" : "Germany",
		"ALPHA_2" : "DE",
		"ALPHA_3" : "DEU",
		"REGION" : "Europe",
		"SUB_REGION" : "Western Europe",
		"LATITUDE" : 51.165691,
		"LONGITUDE" : 10.451526,
		"CURRENCY" : "EUR"
	},
	{
		"NAME" : "Ghana",
		"ALPHA_2" : "GH",
		"ALPHA_3" : "GHA",
		"REGION" : "Africa",
		"SUB_REGION" : "Western Africa",
		"LATITUDE" : 7.946527,
		"LONGITUDE" : -1.023194,
		"CURRENCY" : "GHS"
	},
	{
		"NAME" : "Gibraltar",
		"ALPHA_2" : "GI",
		"ALPHA_3" : "GIB",
		"REGION" : "Europe",
		"SUB_REGION" : "Southern Europe",
		"LATITUDE" : 36.137741,
		"LONGITUDE" : -5.345374,
		"CURRENCY" : "GIP"
	},
	{
		"NAME" : "Greece",
		"ALPHA_2" : "GR",
		"ALPHA_3" : "GRC",
		"REGION" : "Europe",
		"SUB_REGION" : "Southern Europe",
		"LATITUDE" : 39.074208,
		"LONGITUDE" : 21.824312,
		"CURRENCY" : "EUR"
	},
	{
		"NAME" : "Greenland",
		"ALPHA_2" : "GL",
		"ALPHA_3" : "GRL",
		"REGION" : "Americas",
		"SUB_REGION" : "Northern America",
		"LATITUDE" : 71.706936,
		"LONGITUDE" : -42.604303,
		"CURRENCY" : "DKK"
	},
	{
		"NAME" : "Grenada",
		"ALPHA_2" : "GD",
		"ALPHA_3" : "GRD",
		"REGION" : "Americas",
		"SUB_REGION" : "Caribbean",
		"LATITUDE" : 12.262776,
		"LONGITUDE" : -61.604171,
		"CURRENCY" : "XCD"
	},
	{
		"NAME" : "Guadeloupe",
		"ALPHA_2" : "GP",
		"ALPHA_3" : "GLP",
		"REGION" : "Americas",
		"SUB_REGION" : "Caribbean",
		"LATITUDE" : 16.995971,
		"LONGITUDE" : -62.067641,
		"CURRENCY" : "EUR"
	},
	{
		"NAME" : "Guam",
		"ALPHA_2" : "GU",
		"ALPHA_3" : "GUM",
		"REGION" : "Oceania",
		"SUB_REGION" : "Micronesia",
		"LATITUDE" : 13.444304,
		"LONGITUDE" : 144.793731,
		"CURRENCY" : "USD"
	},
	{
		"NAME" : "Guatemala",
		"ALPHA_2" : "GT",
		"ALPHA_3" : "GTM",
		"REGION" : "Americas",
		"SUB_REGION" : "Central America",
		"LATITUDE" : 15.783471,
		"LONGITUDE" : -90.230759,
		"CURRENCY" : "GTQ"
	},
	{
		"NAME" : "Guernsey",
		"ALPHA_2" : "GG",
		"ALPHA_3" : "GGY",
		"REGION" : "Europe",
		"SUB_REGION" : "Northern Europe",
		"LATITUDE" : 49.465691,
		"LONGITUDE" : -2.585278,
		"CURRENCY" : "GBP"
	},
	{
		"NAME" : "Guinea",
		"ALPHA_2" : "GN",
		"ALPHA_3" : "GIN",
		"REGION" : "Africa",
		"SUB_REGION" : "Western Africa",
		"LATITUDE" : 9.945587,
		"LONGITUDE" : -9.696645,
		"CURRENCY" : "GNF"
	},
	{
		"NAME" : "Guinea-Bissau",
		"ALPHA_2" : "GW",
		"ALPHA_3" : "GNB",
		"REGION" : "Africa",
		"SUB_REGION" : "Western Africa",
		"LATITUDE" : 11.803749,
		"LONGITUDE" : -15.180413,
		"CURRENCY" : "XOF"
	},
	{
		"NAME" : "Guyana",
		"ALPHA_2" : "GY",
		"ALPHA_3" : "GUY",
		"REGION" : "Americas",
		"SUB_REGION" : "South America",
		"LATITUDE" : 4.860416,
		"LONGITUDE" : -58.93018,
		"CURRENCY" : "GYD"
	},
	{
		"NAME" : "Haiti",
		"ALPHA_2" : "HT",
		"ALPHA_3" : "HTI",
		"REGION" : "Americas",
		"SUB_REGION" : "Caribbean",
		"LATITUDE" : 18.971187,
		"LONGITUDE" : -72.285215,
		"CURRENCY" : "HTG"
	},
	{
		"NAME" : "Heard Island and McDonald Islands",
		"ALPHA_2" : "HM",
		"ALPHA_3" : "HMD",
		"REGION" : null,
		"SUB_REGION" : null,
		"LATITUDE" : -53.08181,
		"LONGITUDE" : 73.504158,
		"CURRENCY" : "AUD"
	},
	{
		"NAME" : "Holy See",
		"ALPHA_2" : "VA",
		"ALPHA_3" : "VAT",
		"REGION" : "Europe",
		"SUB_REGION" : "Southern Europe",
		"LATITUDE" : 41.902916,
		"LONGITUDE" : 12.453389,
		"CURRENCY" : "EUR"
	},
	{
		"NAME" : "Honduras",
		"ALPHA_2" : "HN",
		"ALPHA_3" : "HND",
		"REGION" : "Americas",
		"SUB_REGION" : "Central America",
		"LATITUDE" : 15.199999,
		"LONGITUDE" : -86.241905,
		"CURRENCY" : "HNL"
	},
	{
		"NAME" : "Hong Kong",
		"ALPHA_2" : "HK",
		"ALPHA_3" : "HKG",
		"REGION" : "Asia",
		"SUB_REGION" : "Eastern Asia",
		"LATITUDE" : 22.396428,
		"LONGITUDE" : 114.109497,
		"CURRENCY" : "HKD"
	},
	{
		"NAME" : "Hungary",
		"ALPHA_2" : "HU",
		"ALPHA_3" : "HUN",
		"REGION" : "Europe",
		"SUB_REGION" : "Eastern Europe",
		"LATITUDE" : 47.162494,
		"LONGITUDE" : 19.503304,
		"CURRENCY" : "HUF"
	},
	{
		"NAME" : "Iceland",
		"ALPHA_2" : "IS",
		"ALPHA_3" : "ISL",
		"REGION" : "Europe",
		"SUB_REGION" : "Northern Europe",
		"LATITUDE" : 64.963051,
		"LONGITUDE" : -19.020835,
		"CURRENCY" : "ISK"
	},
	{
		"NAME" : "India",
		"ALPHA_2" : "IN",
		"ALPHA_3" : "IND",
		"REGION" : "Asia",
		"SUB_REGION" : "Southern Asia",
		"LATITUDE" : 20.593684,
		"LONGITUDE" : 78.96288,
		"CURRENCY" : "INR"
	},
	{
		"NAME" : "Indonesia",
		"ALPHA_2" : "ID",
		"ALPHA_3" : "IDN",
		"REGION" : "Asia",
		"SUB_REGION" : "South-Eastern Asia",
		"LATITUDE" : -0.789275,
		"LONGITUDE" : 113.921327,
		"CURRENCY" : "IDR"
	},
	{
		"NAME" : "Iran (Islamic Republic of)",
		"ALPHA_2" : "IR",
		"ALPHA_3" : "IRN",
		"REGION" : "Asia",
		"SUB_REGION" : "Southern Asia",
		"LATITUDE" : 32.427908,
		"LONGITUDE" : 53.688046,
		"CURRENCY" : "IRR"
	},
	{
		"NAME" : "Iraq",
		"ALPHA_2" : "IQ",
		"ALPHA_3" : "IRQ",
		"REGION" : "Asia",
		"SUB_REGION" : "Western Asia",
		"LATITUDE" : 33.223191,
		"LONGITUDE" : 43.679291,
		"CURRENCY" : "IQD"
	},
	{
		"NAME" : "Ireland",
		"ALPHA_2" : "IE",
		"ALPHA_3" : "IRL",
		"REGION" : "Europe",
		"SUB_REGION" : "Northern Europe",
		"LATITUDE" : 53.41291,
		"LONGITUDE" : -8.24389,
		"CURRENCY" : "EUR"
	},
	{
		"NAME" : "Isle of Man",
		"ALPHA_2" : "IM",
		"ALPHA_3" : "IMN",
		"REGION" : "Europe",
		"SUB_REGION" : "Northern Europe",
		"LATITUDE" : 54.236107,
		"LONGITUDE" : -4.548056,
		"CURRENCY" : "GBP"
	},
	{
		"NAME" : "Israel",
		"ALPHA_2" : "IL",
		"ALPHA_3" : "ISR",
		"REGION" : "Asia",
		"SUB_REGION" : "Western Asia",
		"LATITUDE" : 31.046051,
		"LONGITUDE" : 34.851612,
		"CURRENCY" : "ILS"
	},
	{
		"NAME" : "Italy",
		"ALPHA_2" : "IT",
		"ALPHA_3" : "ITA",
		"REGION" : "Europe",
		"SUB_REGION" : "Southern Europe",
		"LATITUDE" : 41.87194,
		"LONGITUDE" : 12.56738,
		"CURRENCY" : "EUR"
	},
	{
		"NAME" : "Jamaica",
		"ALPHA_2" : "JM",
		"ALPHA_3" : "JAM",
		"REGION" : "Americas",
		"SUB_REGION" : "Caribbean",
		"LATITUDE" : 18.109581,
		"LONGITUDE" : -77.297508,
		"CURRENCY" : "JMD"
	},
	{
		"NAME" : "Japan",
		"ALPHA_2" : "JP",
		"ALPHA_3" : "JPN",
		"REGION" : "Asia",
		"SUB_REGION" : "Eastern Asia",
		"LATITUDE" : 36.204824,
		"LONGITUDE" : 138.252924,
		"CURRENCY" : "JPY"
	},
	{
		"NAME" : "Jersey",
		"ALPHA_2" : "JE",
		"ALPHA_3" : "JEY",
		"REGION" : "Europe",
		"SUB_REGION" : "Northern Europe",
		"LATITUDE" : 49.214439,
		"LONGITUDE" : -2.13125,
		"CURRENCY" : "GBP"
	},
	{
		"NAME" : "Jordan",
		"ALPHA_2" : "JO",
		"ALPHA_3" : "JOR",
		"REGION" : "Asia",
		"SUB_REGION" : "Western Asia",
		"LATITUDE" : 30.585164,
		"LONGITUDE" : 36.238414,
		"CURRENCY" : "JOD"
	},
	{
		"NAME" : "Kazakhstan",
		"ALPHA_2" : "KZ",
		"ALPHA_3" : "KAZ",
		"REGION" : "Asia",
		"SUB_REGION" : "Central Asia",
		"LATITUDE" : 48.019573,
		"LONGITUDE" : 66.923684,
		"CURRENCY" : "KZT"
	},
	{
		"NAME" : "Kenya",
		"ALPHA_2" : "KE",
		"ALPHA_3" : "KEN",
		"REGION" : "Africa",
		"SUB_REGION" : "Eastern Africa",
		"LATITUDE" : -0.023559,
		"LONGITUDE" : 37.906193,
		"CURRENCY" : "KES"
	},
	{
		"NAME" : "Kiribati",
		"ALPHA_2" : "KI",
		"ALPHA_3" : "KIR",
		"REGION" : "Oceania",
		"SUB_REGION" : "Micronesia",
		"LATITUDE" : -3.370417,
		"LONGITUDE" : -168.734039,
		"CURRENCY" : "AUD"
	},
	{
		"NAME" : "Korea (Democratic People's)",
		"ALPHA_2" : "KP",
		"ALPHA_3" : "PRK",
		"REGION" : "Asia",
		"SUB_REGION" : "Eastern Asia",
		"LATITUDE" : 40.339852,
		"LONGITUDE" : 127.510093,
		"CURRENCY" : "KPW"
	},
	{
		"NAME" : "Korea",
		"ALPHA_2" : "KR",
		"ALPHA_3" : "KOR",
		"REGION" : "Asia",
		"SUB_REGION" : "Eastern Asia",
		"LATITUDE" : 35.907757,
		"LONGITUDE" : 127.766922,
		"CURRENCY" : "KRW"
	},
	{
		"NAME" : "Kuwait",
		"ALPHA_2" : "KW",
		"ALPHA_3" : "KWT",
		"REGION" : "Asia",
		"SUB_REGION" : "Western Asia",
		"LATITUDE" : 29.31166,
		"LONGITUDE" : 47.481766,
		"CURRENCY" : "KWD"
	},
	{
		"NAME" : "Kyrgyzstan",
		"ALPHA_2" : "KG",
		"ALPHA_3" : "KGZ",
		"REGION" : "Asia",
		"SUB_REGION" : "Central Asia",
		"LATITUDE" : 41.20438,
		"LONGITUDE" : 74.766098,
		"CURRENCY" : "KGS"
	},
	{
		"NAME" : "Lao People's Democratic Republic",
		"ALPHA_2" : "LA",
		"ALPHA_3" : "LAO",
		"REGION" : "Asia",
		"SUB_REGION" : "South-Eastern Asia",
		"LATITUDE" : 19.85627,
		"LONGITUDE" : 102.495496,
		"CURRENCY" : "LAK"
	},
	{
		"NAME" : "Latvia",
		"ALPHA_2" : "LV",
		"ALPHA_3" : "LVA",
		"REGION" : "Europe",
		"SUB_REGION" : "Northern Europe",
		"LATITUDE" : 56.879635,
		"LONGITUDE" : 24.603189,
		"CURRENCY" : "LVL"
	},
	{
		"NAME" : "Lebanon",
		"ALPHA_2" : "LB",
		"ALPHA_3" : "LBN",
		"REGION" : "Asia",
		"SUB_REGION" : "Western Asia",
		"LATITUDE" : 33.854721,
		"LONGITUDE" : 35.862285,
		"CURRENCY" : "LBP"
	},
	{
		"NAME" : "Lesotho",
		"ALPHA_2" : "LS",
		"ALPHA_3" : "LSO",
		"REGION" : "Africa",
		"SUB_REGION" : "Southern Africa",
		"LATITUDE" : -29.609988,
		"LONGITUDE" : 28.233608,
		"CURRENCY" : "LSL"
	},
	{
		"NAME" : "Liberia",
		"ALPHA_2" : "LR",
		"ALPHA_3" : "LBR",
		"REGION" : "Africa",
		"SUB_REGION" : "Western Africa",
		"LATITUDE" : 6.428055,
		"LONGITUDE" : -9.429499,
		"CURRENCY" : "LRD"
	},
	{
		"NAME" : "Libya",
		"ALPHA_2" : "LY",
		"ALPHA_3" : "LBY",
		"REGION" : "Africa",
		"SUB_REGION" : "Northern Africa",
		"LATITUDE" : 26.3351,
		"LONGITUDE" : 17.228331,
		"CURRENCY" : "LYD"
	},
	{
		"NAME" : "Liechtenstein",
		"ALPHA_2" : "LI",
		"ALPHA_3" : "LIE",
		"REGION" : "Europe",
		"SUB_REGION" : "Western Europe",
		"LATITUDE" : 47.166,
		"LONGITUDE" : 9.555373,
		"CURRENCY" : "CHF"
	},
	{
		"NAME" : "Lithuania",
		"ALPHA_2" : "LT",
		"ALPHA_3" : "LTU",
		"REGION" : "Europe",
		"SUB_REGION" : "Northern Europe",
		"LATITUDE" : 55.169438,
		"LONGITUDE" : 23.881275,
		"CURRENCY" : "LTL"
	},
	{
		"NAME" : "Luxembourg",
		"ALPHA_2" : "LU",
		"ALPHA_3" : "LUX",
		"REGION" : "Europe",
		"SUB_REGION" : "Western Europe",
		"LATITUDE" : 49.815273,
		"LONGITUDE" : 6.129583,
		"CURRENCY" : "EUR"
	},
	{
		"NAME" : "Macao",
		"ALPHA_2" : "MO",
		"ALPHA_3" : "MAC",
		"REGION" : "Asia",
		"SUB_REGION" : "Eastern Asia",
		"LATITUDE" : 22.198745,
		"LONGITUDE" : 113.543873,
		"CURRENCY" : "MOP"
	},
	{
		"NAME" : "Macedonia (the former Yugoslav Republic of)",
		"ALPHA_2" : "MK",
		"ALPHA_3" : "MKD",
		"REGION" : "Europe",
		"SUB_REGION" : "Southern Europe",
		"LATITUDE" : 41.608635,
		"LONGITUDE" : 21.745275,
		"CURRENCY" : "MKD"
	},
	{
		"NAME" : "Madagascar",
		"ALPHA_2" : "MG",
		"ALPHA_3" : "MDG",
		"REGION" : "Africa",
		"SUB_REGION" : "Eastern Africa",
		"LATITUDE" : -18.766947,
		"LONGITUDE" : 46.869107,
		"CURRENCY" : "MGA"
	},
	{
		"NAME" : "Malawi",
		"ALPHA_2" : "MW",
		"ALPHA_3" : "MWI",
		"REGION" : "Africa",
		"SUB_REGION" : "Eastern Africa",
		"LATITUDE" : -13.254308,
		"LONGITUDE" : 34.301525,
		"CURRENCY" : "MWK"
	},
	{
		"NAME" : "Malaysia",
		"ALPHA_2" : "MY",
		"ALPHA_3" : "MYS",
		"REGION" : "Asia",
		"SUB_REGION" : "South-Eastern Asia",
		"LATITUDE" : 4.210484,
		"LONGITUDE" : 101.975766,
		"CURRENCY" : "MYR"
	},
	{
		"NAME" : "Maldives",
		"ALPHA_2" : "MV",
		"ALPHA_3" : "MDV",
		"REGION" : "Asia",
		"SUB_REGION" : "Southern Asia",
		"LATITUDE" : 3.202778,
		"LONGITUDE" : 73.22068,
		"CURRENCY" : "MVR"
	},
	{
		"NAME" : "Mali",
		"ALPHA_2" : "ML",
		"ALPHA_3" : "MLI",
		"REGION" : "Africa",
		"SUB_REGION" : "Western Africa",
		"LATITUDE" : 17.570692,
		"LONGITUDE" : -3.996166,
		"CURRENCY" : "XOF"
	},
	{
		"NAME" : "Malta",
		"ALPHA_2" : "MT",
		"ALPHA_3" : "MLT",
		"REGION" : "Europe",
		"SUB_REGION" : "Southern Europe",
		"LATITUDE" : 35.937496,
		"LONGITUDE" : 14.375416,
		"CURRENCY" : "MTL"
	},
	{
		"NAME" : "Marshall Islands",
		"ALPHA_2" : "MH",
		"ALPHA_3" : "MHL",
		"REGION" : "Oceania",
		"SUB_REGION" : "Micronesia",
		"LATITUDE" : 7.131474,
		"LONGITUDE" : 171.184478,
		"CURRENCY" : "USD"
	},
	{
		"NAME" : "Martinique",
		"ALPHA_2" : "MQ",
		"ALPHA_3" : "MTQ",
		"REGION" : "Americas",
		"SUB_REGION" : "Caribbean",
		"LATITUDE" : 14.641528,
		"LONGITUDE" : -61.024174,
		"CURRENCY" : "EUR"
	},
	{
		"NAME" : "Mauritania",
		"ALPHA_2" : "MR",
		"ALPHA_3" : "MRT",
		"REGION" : "Africa",
		"SUB_REGION" : "Western Africa",
		"LATITUDE" : 21.00789,
		"LONGITUDE" : -10.940835,
		"CURRENCY" : "MRO"
	},
	{
		"NAME" : "Mauritius",
		"ALPHA_2" : "MU",
		"ALPHA_3" : "MUS",
		"REGION" : "Africa",
		"SUB_REGION" : "Eastern Africa",
		"LATITUDE" : -20.348404,
		"LONGITUDE" : 57.552152,
		"CURRENCY" : "MUR"
	},
	{
		"NAME" : "Mayotte",
		"ALPHA_2" : "YT",
		"ALPHA_3" : "MYT",
		"REGION" : "Africa",
		"SUB_REGION" : "Eastern Africa",
		"LATITUDE" : -12.8275,
		"LONGITUDE" : 45.166244,
		"CURRENCY" : "EUR"
	},
	{
		"NAME" : "Mexico",
		"ALPHA_2" : "MX",
		"ALPHA_3" : "MEX",
		"REGION" : "Americas",
		"SUB_REGION" : "Central America",
		"LATITUDE" : 23.634501,
		"LONGITUDE" : -102.552784,
		"CURRENCY" : "MXN"
	},
	{
		"NAME" : "Micronesia (Federated States of)",
		"ALPHA_2" : "FM",
		"ALPHA_3" : "FSM",
		"REGION" : "Oceania",
		"SUB_REGION" : "Micronesia",
		"LATITUDE" : 7.425554,
		"LONGITUDE" : 150.550812,
		"CURRENCY" : "USD"
	},
	{
		"NAME" : "Moldova (Republic of)",
		"ALPHA_2" : "MD",
		"ALPHA_3" : "MDA",
		"REGION" : "Europe",
		"SUB_REGION" : "Eastern Europe",
		"LATITUDE" : 47.411631,
		"LONGITUDE" : 28.369885,
		"CURRENCY" : "MDL"
	},
	{
		"NAME" : "Monaco",
		"ALPHA_2" : "MC",
		"ALPHA_3" : "MCO",
		"REGION" : "Europe",
		"SUB_REGION" : "Western Europe",
		"LATITUDE" : 43.750298,
		"LONGITUDE" : 7.412841,
		"CURRENCY" : "EUR"
	},
	{
		"NAME" : "Mongolia",
		"ALPHA_2" : "MN",
		"ALPHA_3" : "MNG",
		"REGION" : "Asia",
		"SUB_REGION" : "Eastern Asia",
		"LATITUDE" : 46.862496,
		"LONGITUDE" : 103.846656,
		"CURRENCY" : "MNT"
	},
	{
		"NAME" : "Montenegro",
		"ALPHA_2" : "ME",
		"ALPHA_3" : "MNE",
		"REGION" : "Europe",
		"SUB_REGION" : "Southern Europe",
		"LATITUDE" : 42.708678,
		"LONGITUDE" : 19.37439,
		"CURRENCY" : "EUR"
	},
	{
		"NAME" : "Montserrat",
		"ALPHA_2" : "MS",
		"ALPHA_3" : "MSR",
		"REGION" : "Americas",
		"SUB_REGION" : "Caribbean",
		"LATITUDE" : 16.742498,
		"LONGITUDE" : -62.187366,
		"CURRENCY" : "XCD"
	},
	{
		"NAME" : "Morocco",
		"ALPHA_2" : "MA",
		"ALPHA_3" : "MAR",
		"REGION" : "Africa",
		"SUB_REGION" : "Northern Africa",
		"LATITUDE" : 31.791702,
		"LONGITUDE" : -7.09262,
		"CURRENCY" : "MAD"
	},
	{
		"NAME" : "Mozambique",
		"ALPHA_2" : "MZ",
		"ALPHA_3" : "MOZ",
		"REGION" : "Africa",
		"SUB_REGION" : "Eastern Africa",
		"LATITUDE" : -18.665695,
		"LONGITUDE" : 35.529562,
		"CURRENCY" : "MZN"
	},
	{
		"NAME" : "Myanmar",
		"ALPHA_2" : "MM",
		"ALPHA_3" : "MMR",
		"REGION" : "Asia",
		"SUB_REGION" : "South-Eastern Asia",
		"LATITUDE" : 21.913965,
		"LONGITUDE" : 95.956223,
		"CURRENCY" : "MMK"
	},
	{
		"NAME" : "Namibia",
		"ALPHA_2" : "NA",
		"ALPHA_3" : "NAM",
		"REGION" : "Africa",
		"SUB_REGION" : "Southern Africa",
		"LATITUDE" : -22.95764,
		"LONGITUDE" : 18.49041,
		"CURRENCY" : "NAD"
	},
	{
		"NAME" : "Nauru",
		"ALPHA_2" : "NR",
		"ALPHA_3" : "NRU",
		"REGION" : "Oceania",
		"SUB_REGION" : "Micronesia",
		"LATITUDE" : -0.522778,
		"LONGITUDE" : 166.931503,
		"CURRENCY" : "AUD"
	},
	{
		"NAME" : "Nepal",
		"ALPHA_2" : "NP",
		"ALPHA_3" : "NPL",
		"REGION" : "Asia",
		"SUB_REGION" : "Southern Asia",
		"LATITUDE" : 28.394857,
		"LONGITUDE" : 84.124008,
		"CURRENCY" : "NPR"
	},
	{
		"NAME" : "Netherlands",
		"ALPHA_2" : "NL",
		"ALPHA_3" : "NLD",
		"REGION" : "Europe",
		"SUB_REGION" : "Western Europe",
		"LATITUDE" : 52.132633,
		"LONGITUDE" : 5.291266,
		"CURRENCY" : "EUR"
	},
	{
		"NAME" : "New Caledonia",
		"ALPHA_2" : "NC",
		"ALPHA_3" : "NCL",
		"REGION" : "Oceania",
		"SUB_REGION" : "Melanesia",
		"LATITUDE" : -20.904305,
		"LONGITUDE" : 165.618042,
		"CURRENCY" : "XPF"
	},
	{
		"NAME" : "New Zealand",
		"ALPHA_2" : "NZ",
		"ALPHA_3" : "NZL",
		"REGION" : "Oceania",
		"SUB_REGION" : "Australia and New Zealand",
		"LATITUDE" : -40.900557,
		"LONGITUDE" : 174.885971,
		"CURRENCY" : "NZD"
	},
	{
		"NAME" : "Nicaragua",
		"ALPHA_2" : "NI",
		"ALPHA_3" : "NIC",
		"REGION" : "Americas",
		"SUB_REGION" : "Central America",
		"LATITUDE" : 12.865416,
		"LONGITUDE" : -85.207229,
		"CURRENCY" : "NIO"
	},
	{
		"NAME" : "Niger",
		"ALPHA_2" : "NE",
		"ALPHA_3" : "NER",
		"REGION" : "Africa",
		"SUB_REGION" : "Western Africa",
		"LATITUDE" : 17.607789,
		"LONGITUDE" : 8.081666,
		"CURRENCY" : "XOF"
	},
	{
		"NAME" : "Nigeria",
		"ALPHA_2" : "NG",
		"ALPHA_3" : "NGA",
		"REGION" : "Africa",
		"SUB_REGION" : "Western Africa",
		"LATITUDE" : 9.081999,
		"LONGITUDE" : 8.675277,
		"CURRENCY" : "NGN"
	},
	{
		"NAME" : "Niue",
		"ALPHA_2" : "NU",
		"ALPHA_3" : "NIU",
		"REGION" : "Oceania",
		"SUB_REGION" : "Polynesia",
		"LATITUDE" : -19.054445,
		"LONGITUDE" : -169.867233,
		"CURRENCY" : "NZD"
	},
	{
		"NAME" : "Norfolk Island",
		"ALPHA_2" : "NF",
		"ALPHA_3" : "NFK",
		"REGION" : "Oceania",
		"SUB_REGION" : "Australia and New Zealand",
		"LATITUDE" : -29.040835,
		"LONGITUDE" : 167.954712,
		"CURRENCY" : "AUD"
	},
	{
		"NAME" : "Northern Mariana Islands",
		"ALPHA_2" : "MP",
		"ALPHA_3" : "MNP",
		"REGION" : "Oceania",
		"SUB_REGION" : "Micronesia",
		"LATITUDE" : 17.33083,
		"LONGITUDE" : 145.38469,
		"CURRENCY" : "USD"
	},
	{
		"NAME" : "Norway",
		"ALPHA_2" : "NO",
		"ALPHA_3" : "NOR",
		"REGION" : "Europe",
		"SUB_REGION" : "Northern Europe",
		"LATITUDE" : 60.472024,
		"LONGITUDE" : 8.468946,
		"CURRENCY" : "NOK"
	},
	{
		"NAME" : "Oman",
		"ALPHA_2" : "OM",
		"ALPHA_3" : "OMN",
		"REGION" : "Asia",
		"SUB_REGION" : "Western Asia",
		"LATITUDE" : 21.512583,
		"LONGITUDE" : 55.923255,
		"CURRENCY" : "OMR"
	},
	{
		"NAME" : "Pakistan",
		"ALPHA_2" : "PK",
		"ALPHA_3" : "PAK",
		"REGION" : "Asia",
		"SUB_REGION" : "Southern Asia",
		"LATITUDE" : 30.375321,
		"LONGITUDE" : 69.345116,
		"CURRENCY" : "PKR"
	},
	{
		"NAME" : "Palau",
		"ALPHA_2" : "PW",
		"ALPHA_3" : "PLW",
		"REGION" : "Oceania",
		"SUB_REGION" : "Micronesia",
		"LATITUDE" : 7.51498,
		"LONGITUDE" : 134.58252,
		"CURRENCY" : "USD"
	},
	{
		"NAME" : "Palestine, State of",
		"ALPHA_2" : "PS",
		"ALPHA_3" : "PSE",
		"REGION" : "Asia",
		"SUB_REGION" : "Western Asia",
		"LATITUDE" : 31.952162,
		"LONGITUDE" : 35.233154,
		"CURRENCY" : null
	},
	{
		"NAME" : "Panama",
		"ALPHA_2" : "PA",
		"ALPHA_3" : "PAN",
		"REGION" : "Americas",
		"SUB_REGION" : "Central America",
		"LATITUDE" : 8.537981,
		"LONGITUDE" : -80.782127,
		"CURRENCY" : "PAB"
	},
	{
		"NAME" : "Papua New Guinea",
		"ALPHA_2" : "PG",
		"ALPHA_3" : "PNG",
		"REGION" : "Oceania",
		"SUB_REGION" : "Melanesia",
		"LATITUDE" : -6.314993,
		"LONGITUDE" : 143.95555,
		"CURRENCY" : "PGK"
	},
	{
		"NAME" : "Paraguay",
		"ALPHA_2" : "PY",
		"ALPHA_3" : "PRY",
		"REGION" : "Americas",
		"SUB_REGION" : "South America",
		"LATITUDE" : -23.442503,
		"LONGITUDE" : -58.443832,
		"CURRENCY" : "PYG"
	},
	{
		"NAME" : "Peru",
		"ALPHA_2" : "PE",
		"ALPHA_3" : "PER",
		"REGION" : "Americas",
		"SUB_REGION" : "South America",
		"LATITUDE" : -9.189967,
		"LONGITUDE" : -75.015152,
		"CURRENCY" : "PEN"
	},
	{
		"NAME" : "Philippines",
		"ALPHA_2" : "PH",
		"ALPHA_3" : "PHL",
		"REGION" : "Asia",
		"SUB_REGION" : "South-Eastern Asia",
		"LATITUDE" : 12.879721,
		"LONGITUDE" : 121.774017,
		"CURRENCY" : "PHP"
	},
	{
		"NAME" : "Pitcairn",
		"ALPHA_2" : "PN",
		"ALPHA_3" : "PCN",
		"REGION" : "Oceania",
		"SUB_REGION" : "Polynesia",
		"LATITUDE" : -24.703615,
		"LONGITUDE" : -127.439308,
		"CURRENCY" : "NZD"
	},
	{
		"NAME" : "Poland",
		"ALPHA_2" : "PL",
		"ALPHA_3" : "POL",
		"REGION" : "Europe",
		"SUB_REGION" : "Eastern Europe",
		"LATITUDE" : 51.919438,
		"LONGITUDE" : 19.145136,
		"CURRENCY" : "PLN"
	},
	{
		"NAME" : "Portugal",
		"ALPHA_2" : "PT",
		"ALPHA_3" : "PRT",
		"REGION" : "Europe",
		"SUB_REGION" : "Southern Europe",
		"LATITUDE" : 39.399872,
		"LONGITUDE" : -8.224454,
		"CURRENCY" : "EUR"
	},
	{
		"NAME" : "Puerto Rico",
		"ALPHA_2" : "PR",
		"ALPHA_3" : "PRI",
		"REGION" : "Americas",
		"SUB_REGION" : "Caribbean",
		"LATITUDE" : 18.220833,
		"LONGITUDE" : -66.590149,
		"CURRENCY" : "USD"
	},
	{
		"NAME" : "Qatar",
		"ALPHA_2" : "QA",
		"ALPHA_3" : "QAT",
		"REGION" : "Asia",
		"SUB_REGION" : "Western Asia",
		"LATITUDE" : 25.354826,
		"LONGITUDE" : 51.183884,
		"CURRENCY" : "QAR"
	},
	{
		"NAME" : "Réunion",
		"ALPHA_2" : "RE",
		"ALPHA_3" : "REU",
		"REGION" : "Africa",
		"SUB_REGION" : "Eastern Africa",
		"LATITUDE" : -21.115141,
		"LONGITUDE" : 55.536384,
		"CURRENCY" : "EUR"
	},
	{
		"NAME" : "Romania",
		"ALPHA_2" : "RO",
		"ALPHA_3" : "ROU",
		"REGION" : "Europe",
		"SUB_REGION" : "Eastern Europe",
		"LATITUDE" : 45.943161,
		"LONGITUDE" : 24.96676,
		"CURRENCY" : "RON"
	},
	{
		"NAME" : "Russian Federation",
		"ALPHA_2" : "RU",
		"ALPHA_3" : "RUS",
		"REGION" : "Europe",
		"SUB_REGION" : "Eastern Europe",
		"LATITUDE" : 61.52401,
		"LONGITUDE" : 105.318756,
		"CURRENCY" : "RUB"
	},
	{
		"NAME" : "Rwanda",
		"ALPHA_2" : "RW",
		"ALPHA_3" : "RWA",
		"REGION" : "Africa",
		"SUB_REGION" : "Eastern Africa",
		"LATITUDE" : -1.940278,
		"LONGITUDE" : 29.873888,
		"CURRENCY" : "RWF"
	},
	{
		"NAME" : "Saint Barthélemy",
		"ALPHA_2" : "BL",
		"ALPHA_3" : "BLM",
		"REGION" : "Americas",
		"SUB_REGION" : "Caribbean",
		"LATITUDE" : 17.8999964,
		"LONGITUDE" : -62.83333,
		"CURRENCY" : "EUR"
	},
	{
		"NAME" : "Saint Helena, Ascension and Tristan da Cunha",
		"ALPHA_2" : "SH",
		"ALPHA_3" : "SHN",
		"REGION" : "Africa",
		"SUB_REGION" : "Western Africa",
		"LATITUDE" : -24.143474,
		"LONGITUDE" : -10.030696,
		"CURRENCY" : "SHP"
	},
	{
		"NAME" : "Saint Kitts and Nevis",
		"ALPHA_2" : "KN",
		"ALPHA_3" : "KNA",
		"REGION" : "Americas",
		"SUB_REGION" : "Caribbean",
		"LATITUDE" : 17.357822,
		"LONGITUDE" : -62.782998,
		"CURRENCY" : "XCD"
	},
	{
		"NAME" : "Saint Lucia",
		"ALPHA_2" : "LC",
		"ALPHA_3" : "LCA",
		"REGION" : "Americas",
		"SUB_REGION" : "Caribbean",
		"LATITUDE" : 13.909444,
		"LONGITUDE" : -60.978893,
		"CURRENCY" : "XCD"
	},
	{
		"NAME" : "Saint Martin (French part)",
		"ALPHA_2" : "MF",
		"ALPHA_3" : "MAF",
		"REGION" : "Americas",
		"SUB_REGION" : "Caribbean",
		"LATITUDE" : 18.0858547,
		"LONGITUDE" : -63.0618597,
		"CURRENCY" : "EUR"
	},
	{
		"NAME" : "Saint Pierre and Miquelon",
		"ALPHA_2" : "PM",
		"ALPHA_3" : "SPM",
		"REGION" : "Americas",
		"SUB_REGION" : "Northern America",
		"LATITUDE" : 46.941936,
		"LONGITUDE" : -56.27111,
		"CURRENCY" : "EUR"
	},
	{
		"NAME" : "Saint Vincent and the Grenadines",
		"ALPHA_2" : "VC",
		"ALPHA_3" : "VCT",
		"REGION" : "Americas",
		"SUB_REGION" : "Caribbean",
		"LATITUDE" : 12.984305,
		"LONGITUDE" : -61.287228,
		"CURRENCY" : "XCD"
	},
	{
		"NAME" : "Samoa",
		"ALPHA_2" : "WS",
		"ALPHA_3" : "WSM",
		"REGION" : "Oceania",
		"SUB_REGION" : "Polynesia",
		"LATITUDE" : -13.759029,
		"LONGITUDE" : -172.104629,
		"CURRENCY" : "EUR"
	},
	{
		"NAME" : "San Marino",
		"ALPHA_2" : "SM",
		"ALPHA_3" : "SMR",
		"REGION" : "Europe",
		"SUB_REGION" : "Southern Europe",
		"LATITUDE" : 43.94236,
		"LONGITUDE" : 12.457777,
		"CURRENCY" : "EUR"
	},
	{
		"NAME" : "Sao Tome and Principe",
		"ALPHA_2" : "ST",
		"ALPHA_3" : "STP",
		"REGION" : "Africa",
		"SUB_REGION" : "Middle Africa",
		"LATITUDE" : 0.18636,
		"LONGITUDE" : 6.613081,
		"CURRENCY" : "STD"
	},
	{
		"NAME" : "Saudi Arabia",
		"ALPHA_2" : "SA",
		"ALPHA_3" : "SAU",
		"REGION" : "Asia",
		"SUB_REGION" : "Western Asia",
		"LATITUDE" : 23.885942,
		"LONGITUDE" : 45.079162,
		"CURRENCY" : "SAR"
	},
	{
		"NAME" : "Senegal",
		"ALPHA_2" : "SN",
		"ALPHA_3" : "SEN",
		"REGION" : "Africa",
		"SUB_REGION" : "Western Africa",
		"LATITUDE" : 14.497401,
		"LONGITUDE" : -14.452362,
		"CURRENCY" : "XOF"
	},
	{
		"NAME" : "Serbia",
		"ALPHA_2" : "RS",
		"ALPHA_3" : "SRB",
		"REGION" : "Europe",
		"SUB_REGION" : "Southern Europe",
		"LATITUDE" : 44.016521,
		"LONGITUDE" : 21.005859,
		"CURRENCY" : "RSD"
	},
	{
		"NAME" : "Seychelles",
		"ALPHA_2" : "SC",
		"ALPHA_3" : "SYC",
		"REGION" : "Africa",
		"SUB_REGION" : "Eastern Africa",
		"LATITUDE" : -4.679574,
		"LONGITUDE" : 55.491977,
		"CURRENCY" : "SCR"
	},
	{
		"NAME" : "Sierra Leone",
		"ALPHA_2" : "SL",
		"ALPHA_3" : "SLE",
		"REGION" : "Africa",
		"SUB_REGION" : "Western Africa",
		"LATITUDE" : 8.460555,
		"LONGITUDE" : -11.779889,
		"CURRENCY" : "SLL"
	}
]


