import React, { Component } from "react";
import { columnsSchema } from "./schema";
import { Input } from "@progress/kendo-react-inputs";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { Loader } from "./loader";
import { IconButton, Tooltip } from "@material-ui/core";
import { MyCommandCell } from "../../CRM/View/myCommandCell";
import { DropDownCell } from "../../CRM/View/dropDownCell.js";
import { serverApi } from "../../../networking/config";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser, faSyncAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import Snackbar from "../../../components/Snackbar/Snackbar";
import GridContainer from "../../../components/Grid/GridContainer";
import { Window } from "@progress/kendo-react-dialogs";
import { AbstractEdit, AbstractDelete } from "../../../networking/apiCalls";
import Button from "../../../components/CustomButtons/Button";
import { Popup } from "@progress/kendo-react-popup";
import { Menu, MenuItem as KendoMenuItem } from "@progress/kendo-react-layout";

const ImageComponent = (props) => {
  const dataItem = props.dataItem;
  return (
    <section>
      <img alt="" src={`${dataItem.data_base64}`} />
    </section>
  );
};

class Assets extends Component {
  editField = "inEdit";
  CommandCell;

  constructor(props) {
    super(props);

    this.state = {
      file: null,
      base64URL: "",
      name: "",
      type: "",
      visibleWindow: false,
      isDropOpened: false,
      columns: columnsSchema && columnsSchema(),
      gridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 10,
        skip: 0,
      },
      openErrorModal: false,
      errorModalContent: "",
      visible: false,
      expandedRowIndex: null,
      selectedRowIndex: 0,
      selectedItem: null,
      delay: 400,
      loading: false,
      open: false,
      emailHasError: false,
      username: "",
      userRealName: "",
      email: "",

      rightClickText: ["Edit base 64 file"],

      selectedAsset: "",
      assetsAvailable: [],

      relation_names: [],
      relationNames: [],
      relationName: "",
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      hasDisable: false,
      showSecondAction: true,
    });
  }

  componentDidMount() {
    let isFirstLoad = true;
    window.localStorage.setItem("isFirstLoad", isFirstLoad);

    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));

    if (hasFinalParams) {
      this.setState({
        ...this.state,
        dataState: hasFinalParams,
      });
    }

    if (hasChangedColumns) {
      this.setState({
        ...this.state,
        columns: hasChangedColumns,
      });
    }
  }

  componentWillUnmount() {
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));
    let finalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    let hasExpandedId = JSON.parse(window.localStorage.getItem("expandeId"));
    if (finalParams) {
      window.localStorage.removeItem("finalParams");
    }
    if (hasChangedColumns) {
      window.localStorage.removeItem("cols");
    }
    if (hasExpandedId) {
      window.localStorage.removeItem("expandeId");
    }
  }

  renderGridColumns = () => {
    let selectionCol = [];
    let normalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            width="65px"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.isFilterBoolean === "yes") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              cell={DropDownCell}
            />
          );
        } else {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={
                temp.field === "id" ||
                temp.field === "data_base64" ||
                temp.editable
                  ? false
                  : true
              }
            />
          );
        }
      });

    return [...selectionCol, ...normalCols];
  };

  enterEdit = (dataItem) => {
    let total = this.state.gridData.total;

    this.setState({
      gridData: {
        data:
          this.state.gridData &&
          this.state.gridData.data.map((item) =>
            item.id === dataItem.id ? { ...item, inEdit: true } : item
          ),
        total: total,
      },
      initialGridData: {
        data:
          this.state.initialGridData &&
          this.state.initialGridData.data.map((item) =>
            item.id === dataItem.id ? { ...item, inEdit: true } : item
          ),
        total: total,
      },
    });
  };

  remove = async (dataItem) => {
    const data = [...this.state.gridData.data];

    let index = data.findIndex((p) => p === dataItem || p.id === dataItem.id);
    if (index >= 0) {
      data.splice(index, 1);
    }

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let url = "assets";

    try {
      let deleteItemRequest = await AbstractDelete(
        url,
        `${dataItem.id || ""}`,
        options
      );

      if (deleteItemRequest.status === 200) {
        //clear the column filters if grid data is 0 or let them if grid data is >= 1
        if (data.length <= 0) {
          this.setState({
            ...this.state,
            gridData: {
              data: data,
              total: data.length,
            },
            initialGridData: {
              data: data,
              total: data.length,
            },
            dataState: {
              skip: 0,
              take: 10,
            },
          });
        } else {
          this.setState({
            ...this.state,
            gridData: {
              data: data,
              total: data.length,
            },
            initialGridData: {
              data: data,
              total: data.length,
            },
          });
        }
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent:
          e?.response?.data?.error?.message || "Please try again",
      });
    }
  };

  add = (dataItem) => {};

  discard = (dataItem, hasError = false) => {
    let data = [...this.state.gridData.data];
    const total = this.state.gridData.total;

    if (hasError) {
      let index = data.findIndex((p) => p.id === dataItem.id);

      data[index].inEdit = false;

      this.setState(
        {
          ...this.state,
          gridData: {
            data: data,
            total: total,
          },
        },
        () => {
          setTimeout(() => {
            this.setState({
              ...this.state,
              openErrorModal: false,
              errorModalContent: "",
            });
          }, 3500);
        }
      );
    } else {
      this.removeItem(data, dataItem);

      this.setState({
        ...this.state,
        gridData: {
          data: data,
          total: total,
        },
        visible: false,
      });
    }
  };

  update = async (dataItem) => {
    const data = [...this.state.gridData.data];
    const updatedItem = { ...dataItem, inEdit: undefined };

    this.updateItem(data, updatedItem);

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let editPayload = updatedItem;
    if (editPayload.expanded) delete editPayload.expanded;

    if (editPayload.name === undefined || editPayload.name === "") {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            "Name is required when updating the assets record.",
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 3000);
        }
      );
    } else {
      try {
        const editRequest = await AbstractEdit(
          dataItem.id,
          editPayload,
          "assets",
          options
        );
        // const countMasterEntity = await serverApi('GET', 'assets/count', '', '', options)

        if (editRequest.status === 200) {
          this.setState(
            {
              ...this.state,
              gridData: {
                data: data,
                total: this.state.gridData.total,
              },
              successMessage: "Saved successfully",
              openSuccessMessage: true,
            },
            () => {
              setTimeout(() => {
                this.handleSuccessSnackbar();
              }, 900);
            }
          );
        }
      } catch (e) {
        let initialGridData = this.state.initialGridData;

        this.setState(
          {
            ...this.state,
            gridData: initialGridData,
            openErrorModal: true,
            errorModalContent:
              e.response.data.error.message || "Please try again",
          },
          () => this.discard(dataItem, true)
        );
      }
    }
  };

  cancel = (dataItem = {}) => {
    const originalItem =
      this.state.initialGridData &&
      this.state.initialGridData.data.find((p) => p.id === dataItem.id);
    originalItem.inEdit = false;

    const data =
      this.state.initialGridData &&
      this.state.initialGridData.data.map((item) =>
        item.id === originalItem.id ? originalItem : item
      );

    const total = this.state.initialGridData.total;

    this.setState({
      ...this.state,
      gridData: {
        data: data,
        total: total,
      },
    });
  };

  updateItem = (data, item) => {
    let index = data.findIndex(
      (p) => p === item || (item.id && p.id === item.id)
    );
    if (index >= 0) {
      //todo check again
      delete item.inEdit;
      data[index] = { ...item };
    }
  };

  itemChange = (event) => {
    if (event.field === "data_base64") return;

    const total = this.state.gridData.total;
    const data =
      this.state.gridData &&
      this.state.gridData.data.map((item) =>
        item.id === event.dataItem.id
          ? { ...item, [event.field]: event.value }
          : item
      );

    this.setState({
      gridData: {
        data: data,
        total: total,
      },
    });
  };

  addNew = () => {
    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    const newDataItem = { inEdit: true };

    this.setState({
      ...this.state,
      gridData: {
        data: [newDataItem, ...this.state.gridData.data],
        total: this.state.gridData.total,
      },
      visible: true,
    });
  };

  removeItem(data, item) {
    let index = data.findIndex((p) => p === item || p.id === item.id);
    if (index >= 0) {
      data.splice(index, 1);
    }
  }

  dataStateChange = (e) => {
    const reqGridParams = e.data;
    window.localStorage.setItem("finalParams", JSON.stringify(reqGridParams));

    this.setState({
      ...this.state,
      dataState: reqGridParams,
    });
  };

  dataRecieved = (gridData, reqGridParams) => {
    this.setState({
      ...this.state,
      gridData: gridData,
      initialGridData: gridData,
      dataState:
        reqGridParams === !_.isEmpty(reqGridParams)
          ? reqGridParams
          : this.state.dataState, //TODO MAYBE PASS THE final params from local storage for persist the filters on REFRESH PAGE !
    });
  };

  onClearFilters = () => {
    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));

    let init = JSON.stringify({ take: 10, skip: 0 });
    if (hasFinalParams && hasFinalParams.filter)
      window.localStorage.setItem("finalParams", init);

    this.dataStateChange({ data: { take: 10, skip: 0 } });
  };

  handleErrorClose = (loginError) => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      openErrorModalContent: "",
    });
  };

  handleSuccessSnackbar = () => {
    this.setState({
      ...this.state,
      successMessage:
        this.state.openSuccessMessage === true ? this.state.successMessage : "",
      openSuccessMessage: !this.state.openSuccessMessage,
    });
  };

  expandChange = (event) => {
    window.localStorage.setItem("expandeId", event.dataItem.id);

    if (this.state.gridData.data[0].inEdit === true) {
      return;
    } else {
      event.dataItem.expanded = !event.dataItem.expanded;
    }

    /**
     * It's important to note that forceUpdate() will skip checking the logic in shouldComponentUpdate()
     * (if you have any), where as setState() does not skip it.
     */
    this.forceUpdate();
    // this.setState({...this.state})
  };

  rowClick = (event) => {};

  refreshGridDataAfterCrud = async () => {
    let token = window.localStorage.getItem("access_token");
    let options = {
      token: token,
    };

    let refreshParams = window.localStorage.getItem("refreshParams");
    refreshParams = JSON.parse(refreshParams);
    let responseData = await serverApi(
      "GET",
      `assets`,
      refreshParams,
      "",
      options
    );

    let count;
    const countMasterEntity = await serverApi(
      "GET",
      `assets/count?where=${JSON.stringify(refreshParams.filter.where)}`,
      "",
      "",
      options
    );
    if (countMasterEntity) {
      count = countMasterEntity.data.count;
    }

    let sendObj = {
      gridData: {
        data: responseData.data || [],
        total: count ? count : responseData?.data?.length || 0,
      },
    };

    this.dataRecieved(sendObj.gridData, null);
  };

  openEditAsset = async () => {
    const options = { token: window.localStorage.getItem("access_token") };
    try {
      const assets = await serverApi("GET", `assets`, "", "", options, "");
      if (assets) {
        this.setState(
          { ...this.state, assetsAvailable: assets.data || [] },
          () => this.openEditAssetWindow()
        );
      }
    } catch (error) {
      // console.log(error)
      setTimeout(() => {
        this.setState({
          openErrorModal: true,
          errorModalContent:
            error?.response?.data?.error?.message || "Update asset failed.",
          loading: false,
          disableButton: false,
          assetWindow: false,
        });
      }, 2000);
    }
  };

  openEditRelation = () => {
    this.openEditRelationWindow();
  };

  openEditAssetWindow = () => {
    this.setState({
      ...this.state,
      assetWindow: true,
    });
  };

  openEditRelationWindow = () => {
    this.setState({
      ...this.state,
      relationName: "",
      relationWindow: true,
    });
  };

  handleOnSelect = (e) => {
    switch (e.item.text) {
      case "Edit asset":
        this.openEditAsset();
        break;
      case "Edit relation":
        this.openEditRelation();
        break;
      default:
        break;
    }

    this.setState({
      open: false,
    });
  };

  onFocusHandler = () => {
    clearTimeout(this.blurTimeoutRef);
    this.blurTimeoutRef = undefined;
  };

  onBlurHandler = (event) => {
    clearTimeout(this.blurTimeoutRef);
    this.blurTimeoutRef = setTimeout(this.onBlurTimeout);
  };

  onBlurTimeout = () => {
    this.setState({
      open: false,
      openDataItem: {},
    });

    this.blurTimeoutRef = undefined;
  };

  rowRender = (trElement, dataItem) => {
    const trProps = {
      ...trElement.props,
      onContextMenu: (e) => {
        e.preventDefault();
        this.handleContextMenuOpen(e, dataItem.dataItem);
      },
      style: {
        // color: 'orange'
      },
    };

    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  handleContextMenuOpen = (e, dataItem) => {
    //if item is inEdit mode prohibit right click
    if (dataItem.inEdit === true) {
      return;
    }

    // if (dataItem.status === 'TERMINATED') {
    //   this.setState({
    //     open: false,
    //     openDataItem: {}
    //   });
    //   return
    // }

    this.dataItem = dataItem;
    this.dataItemIndex = this.state.gridData.data.findIndex(
      (p) => p.id === this.dataItem.id
    );
    this.offset = { left: e.clientX, top: e.clientY };

    if (_.isEmpty(this.state.openDataItem)) {
      //todo handle two popup opened
      this.setState({
        open: true,
        openDataItem: dataItem,
      });
    } else {
      return;
    }
  };

  onPopupOpen = () => {
    this.menuWrapperRef.querySelector("[tabindex]").focus();
  };

  onChangeAsset = (event) => {
    this.setState({
      ...this.state,
      selectedAsset: event.target.value,
    });
  };

  closeWindow = () => {
    this.setState({
      ...this.state,
      assetWindow: false,
      relationWindow: false,
      selectedAsset: [],
      open: false,
      openDataItem: {},
    });
  };

  handleDropDownChange = (event) => {
    let target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.props ? target.props.name : target.name;

    this.setState({
      ...this.state,
      [name]: value.id,
    });
  };

  onChange = (event) => {
    //prevent enter keyboard
    if (
      event.nativeEvent.type === "keydown" &&
      event.nativeEvent.keyCode === 13
    )
      return;

    const { value } = event.target;

    if (value === "") {
      this.setState({
        openedRelationNames: false,
        relationName: value,
      });
    } else {
      this.setState(
        {
          openedRelationNames: false,
          relationName: value,
        },
        () => {
          this.onAutoCompleteChange(event, value);
        }
      );
    }
  };

  toggleWindow = (e) => {
    this.setState({
      visibleWindow: !this.state.visibleWindow,
    });
  };

  expandChange = (event) => {
    window.localStorage.setItem("expandeId", event.dataItem.id);

    if (this.state.gridData.data[0].inEdit === true) {
      return;
    } else {
      event.dataItem.expanded = !event.dataItem.expanded;
    }

    /**
     * It's important to note that forceUpdate() will skip checking the logic in shouldComponentUpdate()
     * (if you have any), where as setState() does not skip it.
     */
    this.forceUpdate();
    // this.setState({...this.state})
  };

  openEditFile = () => {
    this.setState({
      ...this.state,
      editWindow: true,
    });
  };

  handleOnSelect = (e) => {
    switch (e.item.text) {
      case "Edit base 64 file":
        this.openEditFile();
        break;
      default:
        break;
    }

    this.setState({
      open: false,
    });
  };

  onFocusHandler = () => {
    clearTimeout(this.blurTimeoutRef);
    this.blurTimeoutRef = undefined;
  };

  onBlurHandler = (event) => {
    clearTimeout(this.blurTimeoutRef);
    this.blurTimeoutRef = setTimeout(this.onBlurTimeout);
  };

  onBlurTimeout = () => {
    this.setState({
      open: false,
      openDataItem: {},
    });

    this.blurTimeoutRef = undefined;
  };

  rowRender = (trElement, dataItem) => {
    const trProps = {
      ...trElement.props,
      onContextMenu: (e) => {
        e.preventDefault();
        this.handleContextMenuOpen(e, dataItem.dataItem);
      },
      style: {
        // color: 'orange'
      },
    };

    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  handleContextMenuOpen = (e, dataItem) => {
    //if item is inEdit mode prohibit right click
    if (dataItem.inEdit === true) {
      return;
    }

    // if (dataItem.status === 'TERMINATED') {
    //   this.setState({
    //     open: false,
    //     openDataItem: {}
    //   });
    //   return
    // }

    this.dataItem = dataItem;
    this.dataItemIndex = this.state.gridData.data.findIndex(
      (p) => p.id === this.dataItem.id
    );
    this.offset = { left: e.clientX, top: e.clientY };

    if (_.isEmpty(this.state.openDataItem)) {
      //todo handle two popup opened
      this.setState({
        open: true,
        openDataItem: dataItem,
      });
    } else {
      return;
    }
  };

  onPopupOpen = () => {
    this.menuWrapperRef.querySelector("[tabindex]").focus();
  };

  onDialogInputChange = (event) => {
    let target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.props ? target.props.name : target.name;
    this.setState({
      ...this.state,
      gridData: {
        ...this.state.gridData,
        [name]: value,
      },
      [name]: value,
    });
  };

  getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        // console.log("Called", reader);
        baseURL = reader.result;
        // console.log(baseURL);
        resolve(baseURL);
      };
      // console.log(fileInfo);
    });
  };

  handleFileInputChange = (e) => {
    let { file } = this.state;

    file = e.target.files[0];

    if (!file?.type?.match("image.*")) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            "Only image files are supported. Please try again.",
          base64URL: "",
          file: null,
        },
        () => {
          setTimeout(() => {
            this.setState({
              ...this.state,
              openErrorModal: false,
              errorModalContent: "",
            });
          }, 4000);
        }
      );
      return;
    }

    this.getBase64(file)
      .then((result) => {
        file["base64"] = result;
        // console.log("File Is", file);
        this.setState({
          base64URL: result,
          file,
        });
      })
      .catch((err) => {
        this.setState({
          ...this.state,
          base64URL: "",
          file: null,
          openErrorModal: true,
          openErrorModalContent:
            err?.response.data.error.message ||
            "Only image files are supported. Please try again.",
        });
      });

    this.setState({
      file: e.target.files[0],
    });
  };

  saveAsset = async () => {
    const options = { token: window.localStorage.getItem("access_token") };
    if (this.state.name !== "") {
      const request = await serverApi(
        "POST",
        "assets",
        "",
        {
          name: this.state.name,
          type: this.state.type,
          data_base64: this.state.base64URL,
        },
        options
      );
      if (request.status === 200) this.refreshGridDataAfterCrud();
      this.setState({
        ...this.state,
        visibleWindow: false,
        name: "",
        type: "",
        base64URL: "",
        file: null,
      });
    }
  };

  updateAsset = async () => {
    const options = { token: window.localStorage.getItem("access_token") };

    if (this.state.base64URL !== "") {
      const editRequest = await AbstractEdit(
        this.state.openDataItem.id,
        {
          data_base64: this.state.base64URL,
        },
        "assets",
        options
      );
      if (editRequest.status === 200) this.refreshGridDataAfterCrud();
    }
  };

  render() {
    const { gridData } = this.state;
    const hasEditedItem = gridData && gridData.data.some((p) => p.inEdit);
    const { hasBackofficeRead } = this.props;

    return (
      <div>
        <Popup
          offset={this.offset}
          show={this.state.open}
          open={this.onPopupOpen}
          popupClass={"popup-content"}
        >
          <div
            onFocus={this.onFocusHandler}
            onBlur={this.onBlurHandler}
            tabIndex={-1}
            ref={(el) => (this.menuWrapperRef = el)}
          >
            <Menu
              vertical={true}
              style={{ display: "inline-block" }}
              onSelect={this.handleOnSelect}
            >
              {this.state.rightClickText.map((text) => {
                return <KendoMenuItem text={text} />;
              })}
            </Menu>
          </div>
        </Popup>
        <Grid
          {...this.state.dataState}
          {...this.state.gridData}
          filterable={true}
          style={{ height: "92vh" }}
          sortable={true}
          detail={ImageComponent}
          expandField="expanded"
          onExpandChange={this.expandChange}
          resizable
          editField={this.editField}
          rowRender={this.rowRender}
          onItemChange={this.itemChange}
          onDataStateChange={this.dataStateChange}
          pageable={{
            buttonCount: 10,
            info: true,
            pageSizes: [10, 20, 50],
          }}
        >
          <GridToolbar>
            <GridContainer xs={12} justify={"flex-start"} direction={"row"}>
              {!hasBackofficeRead && (
                <Tooltip
                  justify={"flex-start"}
                  placement="top"
                  title={"Create Asset"}
                >
                  <IconButton onClick={this.toggleWindow}>
                    <FontAwesomeIcon
                      color="#0D5869"
                      onClick={this.toggleWindow}
                      size="1.6x"
                      icon={faPlus}
                    />
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip
                justify={"flex-start"}
                placement="top"
                title={"Clear All Filters"}
              >
                <IconButton
                  disabled={false}
                  aria-label=""
                  onClick={(e) => this.onClearFilters(e)}
                  look="flat"
                >
                  <FontAwesomeIcon
                    color="#0D5869"
                    size="1.6x"
                    icon={faEraser}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip
                justify={"flex-start"}
                placement="top"
                title={"Refresh Data"}
              >
                <IconButton
                  disabled={false}
                  aria-label=""
                  onClick={this.refreshGridDataAfterCrud}
                >
                  <FontAwesomeIcon color="#0D5869" size="1x" icon={faSyncAlt} />
                </IconButton>
              </Tooltip>
            </GridContainer>
          </GridToolbar>

          {!hasBackofficeRead && (
            <Column
              cell={this.CommandCell}
              title="Actions"
              filterable={false}
              width={hasEditedItem ? "240px" : "220px"}
            />
          )}
          {this.renderGridColumns()}
        </Grid>

        <Loader
          dataState={this.state.dataState}
          onDataRecieved={this.dataRecieved}
          getURL={"assets"}
          include={""}
        />

        <Snackbar
          place="tc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"warning"}
          message={this.state.errorModalContent}
          open={this.state.openErrorModal}
          closeNotification={() =>
            this.handleErrorClose(this.state.errorModalContent)
          }
          close
        />

        <Snackbar
          place="tc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"success"}
          message={this.state.successMessage}
          open={this.state.openSuccessMessage}
          closeNotification={() =>
            this.handleSuccessSnackbar(this.state.successMessage)
          }
          close
        />

        {this.state.visibleWindow && (
          <Window
            title={"Create an asset"}
            onClose={() =>
              this.setState({ ...this.state, visibleWindow: false })
            }
            width={900}
            height={700}
          >
            <fieldset style={{ borderColor: "rgba(13, 88, 105, 1)" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>
                  <h3>Name</h3>
                  <Input
                    style={{ width: "500px" }}
                    name="name"
                    value={
                      this.state.gridData.data.filter(
                        (temp) => temp.inEdit === true
                      ).name
                    }
                    onChange={this.onDialogInputChange}
                  />
                </span>
                <span>
                  <h3>Type</h3>
                  <Input
                    style={{ width: "500px" }}
                    name="type"
                    value={
                      this.state.gridData.data.filter(
                        (temp) => temp.inEdit === true
                      ).type
                    }
                    onChange={this.onDialogInputChange}
                  />
                </span>

                <h3>Upload image</h3>
                <input
                  type="file"
                  name="file"
                  accept="image/*"
                  onChange={this.handleFileInputChange}
                />
                <br />
                <Button
                  size="sm"
                  color="success"
                  type="button"
                  onClick={(e) => this.saveAsset(e)}
                  disabled={this.state.name !== "" ? false : true}
                >
                  Save
                </Button>
              </div>
            </fieldset>
          </Window>
        )}

        {this.state.editWindow && (
          <Window
            title={"Upload an image"}
            onClose={() =>
              this.setState({
                ...this.state,
                openDataItem: {},
                editWindow: false,
              })
            }
            width={400}
            height={400}
          >
            <div>
              <input
                type="file"
                name="file"
                accept="image/*"
                onChange={this.handleFileInputChange}
              />
              <br />
              <Button
                size="sm"
                color="success"
                type="button"
                onClick={(e) => this.updateAsset(e)}
                disabled={this.state.base64URL !== "" ? false : true}
              >
                Save
              </Button>
            </div>
          </Window>
        )}
      </div>
    );
  }
}

export default Assets;
