import React from "react";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { Loader } from "./loader";
import { AbstractEdit } from "../../../networking/apiCalls";
import _ from "lodash";
import { MyCommandCell } from "../../common/commandCellHOC";
import { serverApi } from "../../../networking/config";
import { IconButton, Tooltip } from "@material-ui/core";
import GridContainer from "../../../components/Grid/GridContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser, faSyncAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import Snackbar from "../../../components/Snackbar/Snackbar";
import { columnsSchema } from "./schema";
import { DropDownCell } from "../../CRM/View/dropDownCell";
import DomainToClient from "./components/domainToClient";
import ExpandedTabs from "../../common/ExpandedTabs";
import DepartmentToDomain from "./components/departmentToDomain";
import { Popup } from "@progress/kendo-react-popup";
import { Menu, MenuItem as KendoMenuItem } from "@progress/kendo-react-layout";
import { Window } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import Button from "../../../components/CustomButtons/Button";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import ModelSetting from "../../CRM/View/components/modelSetting";

class Domains extends React.Component {
  editField = "inEdit";
  CommandCell;

  constructor(props) {
    super(props);

    this.state = {
      visibleWindow: false,
      isDropOpened: false,
      columns: columnsSchema && columnsSchema(),
      gridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 10,
        skip: 0,
      },
      openErrorModal: false,
      errorModalContent: "",
      visible: false,
      expandedRowIndex: null,
      selectedRowIndex: 0,
      selectedItem: null,
      delay: 400,
      loading: false,
      open: false,
      emailHasError: false,
      username: "",
      userRealName: "",
      email: "",

      rightClickText: ["Edit relation", "Edit asset"],

      selectedAsset: "",
      assetsAvailable: [],

      relation_names: [],
      relationNames: [],
      relationName: "",
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      hasDisable: false,
      showSecondAction: false,
    });
  }

  componentDidMount() {
    let isFirstLoad = true;
    window.localStorage.setItem("isFirstLoad", isFirstLoad);

    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));

    if (hasFinalParams) {
      this.setState({
        ...this.state,
        dataState: hasFinalParams,
      });
    }

    if (hasChangedColumns) {
      this.setState({
        ...this.state,
        columns: hasChangedColumns,
      });
    }
  }

  componentWillUnmount() {
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));
    let finalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    let hasExpandedId = JSON.parse(window.localStorage.getItem("expandeId"));
    if (finalParams) {
      window.localStorage.removeItem("finalParams");
    }
    if (hasChangedColumns) {
      window.localStorage.removeItem("cols");
    }
    if (hasExpandedId) {
      window.localStorage.removeItem("expandeId");
    }
  }

  renderGridColumns = () => {
    let selectionCol = [];
    let normalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            width="65px"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.isFilterBoolean === "yes") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              cell={DropDownCell}
            />
          );
        } else {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={temp.field === "id" || temp.editable ? false : true}
              // editable={temp.field === 'id' ? false : true} //id not editable on edit

              // cell={() =>
              //     <td>
              //         <a rel="noopener noreferrer" href="https://p.2hog.codes/" target="_blank">Link Here</a>
              //     </td>
              // }
            />
          );
        }
      });

    return [...selectionCol, ...normalCols];
  };

  enterEdit = (dataItem) => {
    let total = this.state.gridData.total;

    this.setState({
      gridData: {
        data:
          this.state.gridData &&
          this.state.gridData.data.map((item) =>
            item.id === dataItem.id ? { ...item, inEdit: true } : item
          ),
        total: total,
      },
      initialGridData: {
        data:
          this.state.initialGridData &&
          this.state.initialGridData.data.map((item) =>
            item.id === dataItem.id ? { ...item, inEdit: true } : item
          ),
        total: total,
      },
    });
  };

  remove = async (dataItem) => {};

  add = (dataItem) => {
    dataItem.inEdit = undefined;

    const submitURL = "domains";
    let count;
    const options = { token: window.localStorage.getItem("access_token") };
    let payload = dataItem;
    delete payload.inEdit;

    if (payload.description === undefined) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            "Please provide a domain description in order to add a new domain.",
        },
        () => {
          setTimeout(() => {
            this.cancel(dataItem);
          }, 2500);
        }
      );
    } else {
      const createRequest = serverApi(
        "POST",
        `${submitURL}`,
        "",
        payload,
        options
      );
      createRequest
        .then(async (createResponse) => {
          const responseData = await serverApi(
            "GET",
            `domains?filter=%7B%22include%22:[],%22where%22:%7B%22and%22:[%7B%7D]%7D,%22limit%22:%2210%22,%22skip%22:%220%22%7D`,
            {},
            "",
            options
          );
          const countMasterEntity = await serverApi(
            "GET",
            "domains/count",
            "",
            "",
            options
          );
          if (countMasterEntity) {
            count = countMasterEntity.data.count;
          }

          this.setState(
            {
              ...this.state,
              gridData: {
                data: responseData.data,
                total: count === undefined ? responseData.data.length : count,
              },
              successMessage: "The domain was successfully created.",
              openSuccessMessage: true,
            },
            () => {
              setTimeout(() => {
                this.handleSuccessSnackbar();
              }, 2200);
            }
          );
        })
        .catch((createError) => {
          this.setState(
            {
              ...this.state,
              openErrorModal: true,
              errorModalContent:
                createError.response.data.error.message || "Please refresh",
            },
            () => this.discard(dataItem, true)
          );
        });
    }
  };

  discard = (dataItem, hasError = false) => {
    let data = [...this.state.gridData.data];
    const total = this.state.gridData.total;

    if (hasError) {
      let index = data.findIndex((p) => p.id === dataItem.id);

      data[index].inEdit = false;

      this.setState(
        {
          ...this.state,
          gridData: {
            data: data,
            total: total,
          },
        },
        () => {
          setTimeout(() => {
            this.setState({
              ...this.state,
              openErrorModal: false,
              errorModalContent: "",
            });
          }, 3500);
        }
      );
    } else {
      this.removeItem(data, dataItem);

      this.setState({
        ...this.state,
        gridData: {
          data: data,
          total: total,
        },
        visible: false,
      });
    }
  };

  update = async (dataItem) => {
    const data = [...this.state.gridData.data];
    const updatedItem = { ...dataItem, inEdit: undefined };

    this.updateItem(data, updatedItem);

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let editPayload = updatedItem;

    if (
      editPayload.description === undefined ||
      editPayload.description === ""
    ) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            "Description is required when updating the domain record.",
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 3000);
        }
      );
    } else {
      try {
        const editRequest = await AbstractEdit(
          dataItem.id,
          editPayload,
          "domains",
          options
        );
        // const countMasterEntity = await serverApi('GET', 'domains/count', '', '', options)

        if (editRequest.status === 200) {
          this.setState(
            {
              ...this.state,
              gridData: {
                data: data,
                total: this.state.gridData.total,
              },
              successMessage: "Saved successfully",
              openSuccessMessage: true,
            },
            () => {
              setTimeout(() => {
                this.handleSuccessSnackbar();
              }, 900);
            }
          );
        }
      } catch (e) {
        let initialGridData = this.state.initialGridData;

        this.setState(
          {
            ...this.state,
            gridData: initialGridData,
            openErrorModal: true,
            errorModalContent:
              e.response.data.error.message || "Please try again",
          },
          () => this.discard(dataItem, true)
        );
      }
    }
  };

  cancel = (dataItem = {}) => {
    if (dataItem.description === undefined) {
      const data =
        this.state.initialGridData && this.state.initialGridData.data;
      const total = this.state.initialGridData.total;

      this.setState(
        {
          ...this.state,
          gridData: {
            data: data,
            total: total,
          },
          errorModalContent:
            this.state.errorModalContent === ""
              ? ""
              : this.state.errorModalContent,
          openErrorModal:
            this.state.openErrorModal !== true
              ? false
              : this.state.errorModalContent,
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 500);
        }
      );
    } else {
      const originalItem =
        this.state.initialGridData &&
        this.state.initialGridData.data.find((p) => p.id === dataItem.id);
      originalItem.inEdit = false;

      const data =
        this.state.initialGridData &&
        this.state.initialGridData.data.map((item) =>
          item.id === originalItem.id ? originalItem : item
        );

      const total = this.state.initialGridData.total;

      this.setState({
        ...this.state,
        gridData: {
          data: data,
          total: total,
        },
      });
    }
  };

  updateItem = (data, item) => {
    let index = data.findIndex(
      (p) => p === item || (item.id && p.id === item.id)
    );
    if (index >= 0) {
      //todo check again
      delete item.inEdit;
      data[index] = { ...item };
    }
  };

  itemChange = (event) => {
    const total = this.state.gridData.total;
    const data =
      this.state.gridData &&
      this.state.gridData.data.map((item) =>
        item.id === event.dataItem.id
          ? { ...item, [event.field]: event.value }
          : item
      );

    this.setState({
      gridData: {
        data: data,
        total: total,
      },
    });
  };

  addNew = () => {
    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    const newDataItem = { inEdit: true };

    this.setState({
      ...this.state,
      gridData: {
        data: [newDataItem, ...this.state.gridData.data],
        total: this.state.gridData.total,
      },
      visible: true,
    });
  };

  removeItem(data, item) {
    let index = data.findIndex((p) => p === item || p.id === item.id);
    if (index >= 0) {
      data.splice(index, 1);
    }
  }

  dataStateChange = (e) => {
    const reqGridParams = e.data;
    window.localStorage.setItem("finalParams", JSON.stringify(reqGridParams));

    this.setState({
      ...this.state,
      dataState: reqGridParams,
    });
  };

  dataRecieved = (gridData, reqGridParams) => {
    this.setState({
      ...this.state,
      gridData: gridData,
      initialGridData: gridData,
      dataState:
        reqGridParams === !_.isEmpty(reqGridParams)
          ? reqGridParams
          : this.state.dataState, //TODO MAYBE PASS THE final params from local storage for persist the filters on REFRESH PAGE !
    });
  };

  onClearFilters = () => {
    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));

    let init = JSON.stringify({ take: 10, skip: 0 });
    if (hasFinalParams && hasFinalParams.filter)
      window.localStorage.setItem("finalParams", init);

    this.dataStateChange({ data: { take: 10, skip: 0 } });
  };

  handleErrorClose = (loginError) => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      openErrorModalContent: "",
    });
  };

  handleSuccessSnackbar = () => {
    this.setState({
      ...this.state,
      successMessage:
        this.state.openSuccessMessage === true ? this.state.successMessage : "",
      openSuccessMessage: !this.state.openSuccessMessage,
    });
  };

  expandChange = (event) => {
    window.localStorage.setItem("expandeId", event.dataItem.id);

    if (this.state.gridData.data[0].inEdit === true) {
      return;
    } else {
      event.dataItem.expanded = !event.dataItem.expanded;
    }

    /**
     * It's important to note that forceUpdate() will skip checking the logic in shouldComponentUpdate()
     * (if you have any), where as setState() does not skip it.
     */
    this.forceUpdate();
    // this.setState({...this.state})
  };

  rowClick = (event) => {};

  refreshGridDataAfterCrud = async () => {
    let token = window.localStorage.getItem("access_token");
    let options = {
      token: token,
    };

    let refreshParams = window.localStorage.getItem("refreshParams");
    refreshParams = JSON.parse(refreshParams);
    refreshParams.filter.include = ["departments"];
    let responseData = await serverApi(
      "GET",
      `domains`,
      refreshParams,
      "",
      options
    );

    let count;
    const countMasterEntity = await serverApi(
      "GET",
      `domains/count?where=${JSON.stringify(refreshParams.filter.where)}`,
      "",
      "",
      options
    );
    if (countMasterEntity) {
      count = countMasterEntity.data.count;
    }

    let sendObj = {
      gridData: {
        data: responseData.data || [],
        total: count ? count : responseData?.data?.length || 0,
      },
    };

    this.dataRecieved(sendObj.gridData, null);
  };

  openEditAsset = async () => {
    const options = { token: window.localStorage.getItem("access_token") };
    try {
      const assets = await serverApi("GET", `assets`, "", "", options, "");
      if (assets) {
        this.setState(
          { ...this.state, assetsAvailable: assets.data || [] },
          () => this.openEditAssetWindow()
        );
      }
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        this.setState({
          openErrorModal: true,
          errorModalContent:
            error?.response?.data?.error?.message || "Update asset failed.",
          loading: false,
          disableButton: false,
          assetWindow: false,
        });
      }, 2000);
    }
  };

  openEditRelation = () => {
    this.openEditRelationWindow();
  };

  openEditAssetWindow = () => {
    this.setState({
      ...this.state,
      assetWindow: true,
    });
  };

  openEditRelationWindow = () => {
    this.setState({
      ...this.state,
      relationName: "",
      relationWindow: true,
    });
  };

  handleOnSelect = (e) => {
    switch (e.item.text) {
      case "Edit asset":
        this.openEditAsset();
        break;
      case "Edit relation":
        this.openEditRelation();
        break;
      default:
        break;
    }

    this.setState({
      open: false,
    });
  };

  onFocusHandler = () => {
    clearTimeout(this.blurTimeoutRef);
    this.blurTimeoutRef = undefined;
  };

  onBlurHandler = (event) => {
    clearTimeout(this.blurTimeoutRef);
    this.blurTimeoutRef = setTimeout(this.onBlurTimeout);
  };

  onBlurTimeout = () => {
    this.setState({
      open: false,
      openDataItem: {},
    });

    this.blurTimeoutRef = undefined;
  };

  rowRender = (trElement, dataItem) => {
    const trProps = {
      ...trElement.props,
      onContextMenu: (e) => {
        e.preventDefault();
        this.handleContextMenuOpen(e, dataItem.dataItem);
      },
      style: {
        // color: 'orange'
      },
    };

    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  handleContextMenuOpen = (e, dataItem) => {
    //if item is inEdit mode prohibit right click
    if (dataItem.inEdit === true) {
      return;
    }

    // if (dataItem.status === 'TERMINATED') {
    //   this.setState({
    //     open: false,
    //     openDataItem: {}
    //   });
    //   return
    // }

    this.dataItem = dataItem;
    this.dataItemIndex = this.state.gridData.data.findIndex(
      (p) => p.id === this.dataItem.id
    );
    this.offset = { left: e.clientX, top: e.clientY };

    if (_.isEmpty(this.state.openDataItem)) {
      //todo handle two popup opened
      this.setState({
        open: true,
        openDataItem: dataItem,
      });
    } else {
      return;
    }
  };

  onPopupOpen = () => {
    this.menuWrapperRef.querySelector("[tabindex]").focus();
  };

  onChangeAsset = (event) => {
    this.setState({
      ...this.state,
      selectedAsset: event.target.value,
    });
  };

  closeWindow = () => {
    this.setState({
      ...this.state,
      assetWindow: false,
      relationWindow: false,
      selectedAsset: [],
      open: false,
      openDataItem: {},
    });
  };

  handleDropDownChange = (event) => {
    let target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.props ? target.props.name : target.name;

    this.setState({
      ...this.state,
      [name]: value.id,
    });
  };

  saveAsset = async () => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    try {
      let editPayload = { assetID: this.state.selectedAsset };
      const editRequest = await AbstractEdit(
        this.state.openDataItem.id,
        editPayload,
        "domains",
        options
      );
      if (editRequest.status === 200) {
        let params = {
          filter: {
            limit: `100`,
            skip: `0`,
          },
        };
        const url = "domains";
        let responseData = await serverApi(
          "GET",
          `${url}`,
          params,
          "",
          options
        );

        this.setState({
          ...this.state,
          assetWindow: false,
          openDataItem: {},
          dataState: {
            take: 100,
            skip: 0,
          },
          gridData: {
            data: responseData.data,
            total: responseData.data.length,
          },
          initialGridData: {
            data: responseData.data,
            total: responseData.data.length,
          },
        });
      }
    } catch (e) {
      let initialGridData = this.state.initialGridData;
      this.setState({
        ...this.state,
        gridData: initialGridData,
        openErrorModal: true,
        errorModalContent: e.response.data.error.message || "Please try again",
      });
    }
  };

  saveRelation = async () => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    try {
      let editPayload = { relationID: this.state.postRelationID };
      const editRequest = await AbstractEdit(
        this.state.openDataItem.id,
        editPayload,
        "domains",
        options
      );
      if (editRequest.status === 200) {
        let params = {
          filter: {
            limit: `100`,
            skip: `0`,
          },
        };
        const url = "domains";
        let responseData = await serverApi(
          "GET",
          `${url}`,
          params,
          "",
          options
        );

        this.setState({
          ...this.state,
          relationWindow: false,
          openDataItem: {},
          dataState: {
            take: 100,
            skip: 0,
          },
          gridData: {
            data: responseData.data,
            total: responseData.data.length,
          },
          initialGridData: {
            data: responseData.data,
            total: responseData.data.length,
          },
        });
      }
    } catch (e) {
      let initialGridData = this.state.initialGridData;
      this.setState({
        ...this.state,
        gridData: initialGridData,
        openErrorModal: true,
        errorModalContent: e.response.data.error.message || "Please try again",
      });
    }
  };

  listNoDataRender = (element) => {
    const noData = (
      <h4 style={{ fontSize: "1em" }}>
        <span className="k-icon k-i-warning" style={{ fontSize: "2.5em" }} />
        <br />
        <br />
        No relation data found
      </h4>
    );

    return React.cloneElement(element, { ...element.props }, noData);
  };

  onChange = (event) => {
    //prevent enter keyboard
    if (
      event.nativeEvent.type === "keydown" &&
      event.nativeEvent.keyCode === 13
    )
      return;

    const { value } = event.target;

    if (value === "") {
      this.setState({
        openedRelationNames: false,
        relationName: value,
      });
    } else {
      this.setState(
        {
          openedRelationNames: false,
          relationName: value,
        },
        () => {
          this.onAutoCompleteChange(event, value);
        }
      );
    }
  };

  onAutoCompleteChange = (event, value) => {
    const { name } = event.target;

    let stateData = {};

    stateData =
      value.length < 3
        ? {
            data:
              this.state.relationNames.length > 0
                ? this.state.relationNames.map((temp) => temp.relation_name)
                : [],
            openedVesselNames: false,
          }
        : { data: this.filterData(value, name), openedVesselNames: true };

    let eventType = event.nativeEvent.type;

    let valueSelected =
      eventType === "click" ||
      (eventType === "keydown" && event.nativeEvent.keyCode === 13);

    if (valueSelected) {
      let final = [];
      const selected =
        this.state.relationName.split(",")[0] +
        "," +
        this.state.relationName.split(",")[1] +
        "," +
        this.state.relationName.split(",")[2];
      this.state.relation_names.forEach((x) => {
        let temp = x.relation_name + "," + x.relation_country;
        let temp2 = x.relation_name + "," + x.relation_country + "," + x.id;
        if (temp === selected || temp2 === selected) {
          final.push(x);
        }
      });

      let postRelationID = final[0]?.id;

      this.setState({
        ...this.state,
        postRelationID: postRelationID || "",
      });
    }

    if (valueSelected && stateData.data.includes(value)) {
      stateData.openedVesselNames = false;
    }

    this.setState(
      {
        relationName: value,
        ...stateData,
      },
      () => {
        if (value?.length >= 3) {
          this.fetchAutocompleteData(value);
        }
      }
    );
  };

  fetchAutocompleteData = (filterValue) => {
    if (!_.isString(filterValue))
      filterValue = filterValue?.target?.value || filterValue;
    if (filterValue.length < 3) return;
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    const fetchDropdownData = serverApi(
      "GET",
      `masterEntities`,
      {
        filter: {
          where: {
            and: [
              {
                relation_name: { regexp: `/${filterValue}/i` },
              },
            ],
          },
        },
      },
      "",
      options
    );

    fetchDropdownData
      .then((response) => {
        this.setState({
          ...this.state,
          relation_names: response.data || [],
          relationNames:
            response.data.map(
              (temp) =>
                temp.relation_name + "," + temp.relation_country + "," + temp.id
            ) || [],
        });
      })
      .catch((error) => {
        console.log(error);

        throw error;
      });
  };

  filterData = (value, name) => {
    const data = this.state.relationNames.map((temp) => temp.relation_name);

    const filter = {
      value: value,
      operator: "contains",
      ignoreCase: true,
    };

    let results = filterBy(data, filter);
    return results;
  };

  render() {
    const { gridData } = this.state;
    const hasEditedItem = gridData && gridData.data.some((p) => p.inEdit);
    const { hasBackofficeRead } = this.props;

    return (
      <div>
        <Popup
          offset={this.offset}
          show={this.state.open}
          open={this.onPopupOpen}
          popupClass={"popup-content"}
        >
          <div
            onFocus={this.onFocusHandler}
            onBlur={this.onBlurHandler}
            tabIndex={-1}
            ref={(el) => (this.menuWrapperRef = el)}
          >
            <Menu
              vertical={true}
              style={{ display: "inline-block" }}
              onSelect={this.handleOnSelect}
            >
              {this.state.rightClickText.map((text) => {
                return <KendoMenuItem text={text} />;
              })}
            </Menu>
          </div>
        </Popup>
        <Grid
          {...this.state.dataState}
          {...this.state.gridData}
          filterable={true}
          // onRowClick={e => this.rowClick(e)}
          style={{ className: "grid-no-select" }}
          sortable={true}
          resizable
          editField={this.editField}
          rowRender={this.rowRender}
          onItemChange={this.itemChange}
          onDataStateChange={this.dataStateChange}
          detail={(dataItem) => (
            <div>
              <ExpandedTabs
                tabs={[
                  {
                    label: "Client-Domain",
                    component: <DomainToClient {...dataItem} />,
                  },
                  {
                    label: "Department-Domain",
                    component: <DepartmentToDomain {...dataItem} />,
                  },
                  {
                    label: "Model Settings",
                    component: (
                      <ModelSetting
                        {...dataItem}
                        hasBackofficeRead={hasBackofficeRead}
                        modelName="domain"
                      />
                    ), // Index
                  },
                ]}
              ></ExpandedTabs>
            </div>
          )}
          expandField="expanded"
          onExpandChange={this.expandChange}
          pageable={{
            buttonCount: 5,
            info: true,
            pageSizes: [5, 10, 20, 50],
          }}
        >
          <GridToolbar>
            <GridContainer xs={12} justify={"flex-start"} direction={"row"}>
              <Tooltip
                justify={"flex-start"}
                placement="top"
                title={"Add Domain"}
              >
                <IconButton onClick={this.addNew}>
                  <FontAwesomeIcon
                    color="#0D5869"
                    onClick={this.addNew}
                    size="1.6x"
                    icon={faPlus}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip
                justify={"flex-start"}
                placement="top"
                title={"Clear All Filters"}
              >
                <IconButton
                  disabled={false}
                  aria-label=""
                  onClick={(e) => this.onClearFilters(e)}
                  look="flat"
                >
                  <FontAwesomeIcon
                    color="#0D5869"
                    size="1.6x"
                    icon={faEraser}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip
                justify={"flex-start"}
                placement="top"
                title={"Refresh Data"}
              >
                <IconButton
                  disabled={false}
                  aria-label=""
                  onClick={this.refreshGridDataAfterCrud}
                >
                  <FontAwesomeIcon color="#0D5869" size="1x" icon={faSyncAlt} />
                </IconButton>
              </Tooltip>
            </GridContainer>
          </GridToolbar>

          <Column
            cell={this.CommandCell}
            title="Actions"
            filterable={false}
            width={hasEditedItem ? "240px" : "140px"}
          />
          {this.renderGridColumns()}
        </Grid>

        <Loader
          dataState={this.state.dataState}
          onDataRecieved={this.dataRecieved}
          getURL={"domains"}
          include={""}
        />

        <Snackbar
          place="tc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"warning"}
          message={this.state.errorModalContent}
          open={this.state.openErrorModal}
          closeNotification={() =>
            this.handleErrorClose(this.state.errorModalContent)
          }
          close
        />

        <Snackbar
          place="tc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"success"}
          message={this.state.successMessage}
          open={this.state.openSuccessMessage}
          closeNotification={() =>
            this.handleSuccessSnackbar(this.state.successMessage)
          }
          close
        />

        {this.state.assetWindow && (
          <Window
            title={"Edit asset"}
            onClose={this.closeWindow}
            width={550}
            height={250}
          >
            <div>
              <fieldset style={{ borderColor: "rgba(13, 88, 105, 1)" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <h3>
                    Select an asset for domain:{" "}
                    {this.state.openDataItem.description}
                  </h3>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <DropDownList
                      data={this.state.assetsAvailable.sort((a, b) =>
                        a.name > b.name ? 1 : -1
                      )}
                      name="selectedAsset"
                      textField="name"
                      onChange={this.handleDropDownChange}
                      value={this.state.selectedAsset.name}
                      filterable={false}
                      style={{ width: "310px" }}
                    />
                    <Button
                      size="sm"
                      color="success"
                      type="button"
                      disabled={this.state.selectedAsset === "" ? true : false}
                      onClick={this.saveAsset}
                    >
                      SAVE
                    </Button>
                  </div>
                </div>
              </fieldset>
            </div>
          </Window>
        )}

        {this.state.relationWindow && (
          <Window
            title={"Edit relation"}
            onClose={this.closeWindow}
            width={750}
            height={350}
          >
            <div>
              <fieldset style={{ borderColor: "rgba(13, 88, 105, 1)" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <h3>
                    Select a relation for domain:{" "}
                    {this.state.openDataItem.description}
                  </h3>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <AutoComplete
                      style={{ width: "450px", marginTop: "20px" }}
                      data={this.state.relationNames}
                      value={this.state.relationName}
                      label={"Search Relation... (Minimum 3 characters length)"}
                      name={"relation_name"}
                      onChange={(e) => this.onChange(e)}
                      listNoDataRender={this.listNoDataRender}
                    />
                    <Button
                      size="sm"
                      color="success"
                      type="button"
                      disabled={this.state.postRelationID === "" ? true : false}
                      onClick={this.saveRelation}
                    >
                      SAVE
                    </Button>
                  </div>
                </div>
              </fieldset>
            </div>
          </Window>
        )}
      </div>
    );
  }
}

export default Domains;
