export const boxLogColumnSchema = () => [

    {
        field: 'id',
        title: 'ID',
        visible: true,
        minWidth: 150,
        sortable: true,
        filterable: false,
        noDisabledColumn: true,
        notShownInTableColumnSelection: false,
        // cell: true
    },
    {
        field: 'userRealName',
        title: 'User',
        visible: true,
        minWidth: 250,
        sortable: true,
        filterable: false,
        noDisabledColumn: true,
        notShownInTableColumnSelection: false,
        // cell: true
    },
    {
        field: 'obj_before',
        title: 'Action',
        visible: true,
        minWidth: 170,
        sortable: true,
        filterable: false,
        noDisabledColumn: false,
        notShownInTableColumnSelection: false,
        // locked: 'locked',
    },
    {
        field: 'timestamp',
        title: 'Datetime',
        visible: true,
        minWidth: 350,
        sortable: true,
        filterable: false,
        // locked: 'locked',
    },
    {
        field: 'warehouse_position',
        title: 'Position',
        visible: true,
        minWidth: 150,
        sortable: true,
        filterable: false,
        locked: 'locked',
    },
    {
        field: 'customsDocNo',
        title: 'Custom doc',
        visible: true,
        minWidth: 150,
        sortable: true,
        filterable: false,
        locked: 'locked',
    },
    {
        field: 'T1',
        title: 'T1',
        visible: true,
        minWidth: 170,
        sortable: true,
        filterable: false,
        locked: 'locked',
        notShownInTableColumnSelection: false,
    },
    {
        field: 'customsStatus',
        title: 'Custom status',
        visible: true,
        minWidth: 170,
        sortable: true,
        filterable: false,
        locked: 'locked',
        notShownInTableColumnSelection: false,
    },
    {
        field: 'ams_ti_num',
        title: 'AMS T1',
        visible: true,
        minWidth: 170,
        sortable: true,
        filterable: false,
        locked: 'locked',
        notShownInTableColumnSelection: false,
    },
    {
        field: 'dangerousGood',
        title: 'DG',
        visible: true,
        minWidth: 170,
        sortable: true,
        filterable: false,
        locked: 'locked',
        notShownInTableColumnSelection: false,
    },
    {
        field: 'length',
        title: 'Length',
        visible: true,
        minWidth: 170,
        sortable: true,
        filterable: false,
        locked: 'locked',
        notShownInTableColumnSelection: false,
    },
    {
        field: 'width',
        title: 'Width',
        visible: true,
        minWidth: 170,
        sortable: true,
        filterable: false,
        locked: 'locked',
        notShownInTableColumnSelection: false,
    },
    {
        field: 'H',
        title: 'Height',
        visible: true,
        minWidth: 170,
        sortable: true,
        filterable: false,
        locked: 'locked',
        notShownInTableColumnSelection: false,
    },
    {
        field: 'GROSS_WEIGHT',
        title: 'Gross_Weight',
        visible: true,
        minWidth: 170,
        sortable: true,
        filterable: false,
        locked: 'locked',
        notShownInTableColumnSelection: false,
    },

    {
        field: 'itemvalue',
        title: 'Order val',
        visible: true,
        minWidth: 170,
        sortable: true,
        filterable: false,
        locked: 'locked',
        notShownInTableColumnSelection: false,
    },
    // {
    //     field: 'userID',
    //     title: 'Value current',
    //     visible: true,
    //     minWidth: 170,
    //     sortable: true,
    //     filterable: false,
    //     locked: 'locked',
    //     notShownInTableColumnSelection: false,
    // },
    {
        field: 'net_weight',
        title: 'Net weight',
        visible: true,
        minWidth: 170,
        sortable: true,
        filterable: false,
        locked: 'locked',
        notShownInTableColumnSelection: false,
    },
    {
        field: 'packaging',
        title: 'Packaging',
        visible: true,
        minWidth: 170,
        sortable: true,
        filterable: false,
        locked: 'locked',
        notShownInTableColumnSelection: false,
    },
    {
        field: 'uncode',
        title: 'UN',
        visible: true,
        minWidth: 170,
        sortable: true,
        filterable: false,
        locked: 'locked',
        notShownInTableColumnSelection: false,
    },
    // {
    //     field: 'userID',
    //     title: 'Remarks',
    //     visible: true,
    //     minWidth: 170,
    //     sortable: true,
    //     filterable: false,
    //     locked: 'locked',
    //     notShownInTableColumnSelection: false,
    // }
];

export const orderLogColumnSchema = () => [
    {
        field: 'id',
        title: 'Order id',
        visible: true,
        minWidth: 150,
        sortable: true,
        filterable: false,
        noDisabledColumn: true,
        notShownInTableColumnSelection: false,
        // cell: true
    },
    {
        field: 'userID',
        title: 'User',
        visible: true,
        minWidth: 350,
        sortable: true,
        filterable: false,
        noDisabledColumn: true,
        notShownInTableColumnSelection: false,
        // cell: true
    },
    // {
    //     field: 'obj_before',
    //     title: 'Action',
    //     visible: true,
    //     minWidth: 950,
    //     sortable: true,
    //     filterable: false,
    //     noDisabledColumn: false,
    //     notShownInTableColumnSelection: false,
    //     // locked: 'locked',
    // },
    {
        field: 'vessel_name',
        title: 'Vessel Name',
        visible: true,
        minWidth: 300,
        sortable: true,
        filterable: false,
        // locked: 'locked',
    },
    {
        field: 'current',
        title: 'Location',
        visible: true,
        minWidth: 450,
        sortable: true,
        filterable: false,
        locked: 'locked',
    },
    {
        field: 'status',
        title: 'Status',
        visible: true,
        // minWidth: 250,
        sortable: true,
        filterable: false,
        locked: 'locked',
    },
    {
        field: 'timestampt',
        title: 'Timestamp',
        visible: true,
        minWidth: 480,
        sortable: true,
        filterable: false,
        locked: 'locked',
        notShownInTableColumnSelection: false,
    }


];

export const inventoryScansColumnsSchema = () => [
    {
        field: 'id',
        title: 'ID',
        visible: true,
        minWidth: 180,
        sortable: true,
        filterable: false,
        noDisabledColumn: true,
        notShownInTableColumnSelection: false,
        // cell: true
    },
    {
        field: 'boxID',
        title: 'Box-ID',
        visible: true,
        minWidth: 280,
        sortable: true,
        filterable: false,
        noDisabledColumn: true,
        notShownInTableColumnSelection: false,
        // cell: true
    },
    {
        field: 'scan_location',
        title: 'Scan Location',
        visible: true,
        minWidth: 350,
        sortable: true,
        filterable: false,
        noDisabledColumn: false,
        notShownInTableColumnSelection: false,
        locked: 'locked',
    },
    {
        field: 'scan_date',
        title: 'Scan Date',
        visible: true,
        minWidth: 580,
        sortable: true,
        filterable: false,
        locked: 'locked',
    },
    {
        field: 'current',
        title: 'Current Location',
        visible: true,
        minWidth: 340,
        sortable: true,
        filterable: false,
        locked: 'locked',
    },
    {
        field: 'status',
        title: 'Nav Status',
        visible: true,
        minWidth: 300,
        sortable: true,
        filterable: false,
        locked: 'locked',
    },
    {
        field: 'userID',
        title: 'User id',
        visible: true,
        minWidth: 450,
        sortable: true,
        filterable: false,
        locked: 'locked',
        notShownInTableColumnSelection: false,
    }
];

export const orderToDispatchColumnsSchema = () => [
    {
        field: 'dispatchID',
        title: 'Dispatch-ID',
        visible: true,
        minWidth: 370,
        sortable: true,
        filterable: false,
        noDisabledColumn: true,
        notShownInTableColumnSelection: false,
        // cell: true
    },
    {
        field: 'origin',
        title: 'Origin',
        visible: true,
        minWidth: 400,
        sortable: true,
        filterable: false,
        noDisabledColumn: true,
        notShownInTableColumnSelection: false,
        // cell: true
    },
    {
        field: 'destination',
        title: 'Destination',
        visible: true,
        minWidth: 440,
        sortable: true,
        filterable: false,
        noDisabledColumn: true,
        notShownInTableColumnSelection: false,
        // cell: true
    },
]

export const wh_picking_dispatch_to_boxColumnsSchema = () => [
    {
        field: 'dispatchID',
        title: 'Dispatch-ID',
        visible: true,
        minWidth: 360,
        sortable: true,
        filterable: false,
        noDisabledColumn: true,
        notShownInTableColumnSelection: false,
        // cell: true
    },
    {
        field: 'username',
        title: 'Username',
        visible: true,
        minWidth: 400,
        sortable: true,
        filterable: false,
        noDisabledColumn: true,
        notShownInTableColumnSelection: false,
        // cell: true
    },
    {
        field: 'pick_date',
        title: 'Pick Date',
        visible: true,
        minWidth: 450,
        sortable: true,
        filterable: false,
        locked: 'locked',
    },
]


