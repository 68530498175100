export const columnsSchema = () => [
    {
        field: 'id',
        title: 'Client ID',
        visible: true,
        // minWidth: 190,
        sortable: true,
        filterable: true,
        locked: 'locked',
        noDisabledColumn: true,
        notShownInTableColumnSelection: false,
        // cell: true
    },
    {
        field: 'relation_name',
        title: 'Client Name',
        visible: true,
        // minWidth: 350,
        sortable: true,
        filterable: true,
        noDisabledColumn: true,
        locked: 'locked',
    }
]
