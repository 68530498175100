import React from "react";
import { columnsSchema } from "./schema";
import { MyCommandCell } from "../../../../Clients/View/components/CellHOC";
import { serverApi } from "../../../../../networking/config";
import _ from "lodash";
import { GridColumn as Column } from "@progress/kendo-react-grid/dist/npm/GridColumn";
import { Grid } from "@progress/kendo-react-grid";
import { DropDownCell } from "../../../../CRM/View/dropDownCell";
import ViewSettings from "../../../../common/ViewSettings";
import { groupBy } from "@progress/kendo-data-query";
import {
  setExpandedState,
  setGroupIds,
} from "@progress/kendo-react-data-tools";
import { formatDatesOnTable } from "../../../../../lib/GeneralUtils/utils";

const initialGroup = [
  {
    field: "client_name",
  },
];

const processWithGroups = (data, group) => {
  const newDataState = groupBy(data, group);
  setGroupIds({
    data: newDataState,
    group: group,
  });
  return newDataState;
};

export default class VesselClientHistory extends React.Component {
  editField = "inEdit";
  CommandCell;
  _export;

  constructor(props) {
    super(props);

    this.state = {
      visibleWindow: false,
      removePrimaryDepartmentWindow: false,
      isDropOpened: false,
      columns: (columnsSchema && columnsSchema()) || [],
      result: processWithGroups([], initialGroup),
      group: initialGroup,
      collapsedState: [],
      gridData: {
        data: [],
        total: 10,
      },
      initialGridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 10,
        skip: 0,
      },
      openErrorModal: false,
      openErrorModalRemove: false,
      errorModalContent: "",
      errorModalContentRemove: "",
      delay: 400,
      loading: false,
      usernames: [],
      windowError: false,
      windowErrorRemove: false,
      windowErrorMessageRemove: "",
      windowErrorMessage: "",
      disableEditButton: true,
      inputValue: "",
      clientAttachedToAnotherGroup: null,
      exportData: [],
      open: false,
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      disableEditButton: false,
      showSecondAction: true,
      showFirstAction: false,
    });
  }

  async componentDidMount() {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    const vesselID = this.props.dataItem.id;
    let fil = {
      filter: {
        where: {
          vesselID: vesselID,
        },
        include: [
          {
            relation: "vessel",
          },
          {
            relation: "masterEntity",
          },
        ],
      },
    };

    let historyData = await serverApi(
      "GET",
      `vesselclientconnection_histories`,
      fil,
      "",
      options
    );

    const formattedData = formatDatesOnTable(
      historyData.data
        .map((x) => ({
          ...x,
          ...x.vessel,
        }))
        .flat(),
      ["start_date", "end_date"]
    );
    let finalData = [];

    if (formattedData && formattedData.data.length > 1) {
      _.forEach(formattedData.data, (temp) => {
        finalData.push({
          id: temp.id,
          client_name: temp.masterEntity.relation_name || "N/A",
          vessel_name: temp.vessel.vessel_name || "N/A",
          client_id: temp.masterEntity.id || "N/A",
          vessel_id: temp.vessel.id || "N/A",
          primary_flg: temp.primary_flg,
          start_date: temp.start_date || null,
          end_date: temp.end_date || null,
        });
      });
      finalData.sort((a, b) => (a.start_date < b.start_date ? 1 : -1));

      this.setState({
        ...this.state,
        group: initialGroup,
        result: processWithGroups(finalData || [], initialGroup), // remove it or do it in gridData
        vesselID: this.props.dataItem.id,
        gridData: {
          data: finalData,
          total: finalData.length,
        },
        initialGridData: {
          data: finalData,
          total: finalData.length,
        },
        valueUserName: "",
        blocking: false,
      });
    } else if (formattedData && formattedData.data.length === 1) {
      _.forEach(formattedData.data, (temp) => {
        finalData.push({
          id: temp.id,
          client_name: temp.masterEntity.relation_name || "N/A",
          vessel_name: temp.vessel.vessel_name || "N/A",
          client_id: temp.masterEntity.id || "N/A",
          vessel_id: temp.vessel.id || "N/A",
          primary_flg: temp.primary_flg,
          start_date: temp.start_date || null,
          end_date: temp.end_date || null,
        });
      });
      finalData.sort((a, b) => (a.start_date < b.start_date ? 1 : -1));

      this.setState({
        ...this.state,
        group: initialGroup,
        result: processWithGroups(finalData || [], initialGroup), // remove it or do it in gridData
        vesselID: this.props.dataItem.id,
        gridData: {
          data: [finalData[0]],
          total: 1,
        },
        initialGridData: {
          data: [finalData[0]],
          total: 1,
        },
        valueUserName: "",
        blocking: false,
      });
    } else {
      this.setState({
        ...this.state,
        group: initialGroup,
        result: processWithGroups([], initialGroup), // remove it or do it in gridData
        vesselID: this.props.dataItem.id,
        gridData: {
          data: [],
          total: 0,
        },
        initialGridData: {
          data: [],
          total: 0,
        },
        valueUserName: "",
        blocking: false,
      });
    }
  }

  componentWillUnmount() {}

  fetchLatestUserDepartments = async () => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    const vesselID = this.props.dataItem.id;
    let fil = {
      filter: {
        where: {
          vesselID: vesselID,
        },
        include: [
          {
            relation: "vessel",
          },
          {
            relation: "masterEntity",
          },
        ],
      },
    };

    let historyData = await serverApi(
      "GET",
      `vesselclientconnection_histories`,
      fil,
      "",
      options
    );

    if (historyData && historyData.data.length > 1) {
      let finalData = [];
      _.forEach(historyData.data, (temp) => {
        finalData.push({
          id: temp.id,
          client_name: temp.masterEntity.relation_name || "N/A",
          vessel_name: temp.vessel.vessel_name || "N/A",
          client_id: temp.masterEntity.id || "N/A",
          vessel_id: temp.vessel.id || "N/A",
          primary_flg: temp.primary_flg,
          start_date: temp.start_date || null,
          end_date: temp.end_date || null,
        });
      });
      finalData.sort((a, b) => (a.start_date < b.start_date ? 1 : -1));

      this.setState({
        ...this.state,
        vesselID: this.props.dataItem.id,
        gridData: {
          data: finalData,
          total: finalData.length,
        },
        initialGridData: {
          data: finalData,
          total: finalData.length,
        },
        valueUserName: "",
        blocking: false,
      });
    } else if (historyData && historyData.data.length === 1) {
      let finalData = [];

      _.forEach(historyData.data, (temp) => {
        finalData.push({
          id: temp.id,
          client_name: temp.masterEntity.relation_name || "N/A",
          vessel_name: temp.vessel.vessel_name || "N/A",
          client_id: temp.masterEntity.id || "N/A",
          vessel_id: temp.vessel.id || "N/A",
          primary_flg: temp.primary_flg,
          start_date: temp.start_date || null,
          end_date: temp.end_date || null,
        });
      });
      finalData.sort((a, b) => (a.start_date < b.start_date ? 1 : -1));

      this.setState({
        ...this.state,
        vesselID: this.props.dataItem.id,
        gridData: {
          data: [finalData[0]],
          total: 1,
        },
        initialGridData: {
          data: [finalData[0]],
          total: 1,
        },
        valueUserName: "",
        blocking: false,
      });
    } else {
      this.setState({
        ...this.state,
        vesselID: this.props.dataItem.id,
        gridData: {
          data: [],
          total: 0,
        },
        initialGridData: {
          data: [],
          total: 0,
        },
        valueUserName: "",
        blocking: false,
      });
    }
  };

  add = (dataImte) => {};

  toggleWindow = (e) => {};

  toggleWindowRemove = (e) => {};

  renderGridColumns = (dataItem, changeTheCellComponent) => {
    let selectionCol = [];
    let normalCols = [];
    let finalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            width="70px"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.isFilterBoolean === "yes") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"420"}
              cell={DropDownCell}
              // cell={(propakia) => <PrimaryFlag {...propakia}  />}
            />
          );
        } else {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={temp.field === "id" || temp.editable ? false : true} //id not editable on edit
            />
          );
        }
      });

    finalCols = [...selectionCol, ...normalCols];
    return finalCols;
  };

  handleSearch = (e) => {};

  handleSuccessSnackbar = () => {};

  remove = async (dataItem) => {};

  cancel = (dataItem) => {};

  removeItem(data, item) {}

  discard = (dataItem, hasError = false) => {};

  renderList = (finalUsers = [], users = [], doRefresh) => {};

  handleChange = async (event, name, key) => {};

  getExportData = async () => {
    await this.exportToCsv(this.state, this.props);
  };

  getPromise = () => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    const vesselID = this.props.dataItem.id;

    let fil = {
      filter: {
        where: {
          vesselID: vesselID,
        },
        include: [
          {
            relation: "vessel",
          },
          {
            relation: "masterEntity",
          },
        ],
      },
    };

    return new Promise((resolve, reject) => {
      const historyData = serverApi(
        "GET",
        `vesselclientconnection_histories`,
        fil,
        "",
        options
      );
      historyData
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  exportToCsv = async (state, props) => {
    const count = state.gridData.total;
    const numberOfCalls = Math.min(
      Math.round(count / 1000) + 1,
      5 // Limit to 5000
    );

    const promises = [];
    for (let i = 0; i < numberOfCalls; i++) {
      promises.push(
        this.getPromise({
          skip: i * 1000,
          limit: 1000,
          order: ["id asc"],
        })
      );
    }

    try {
      const responses = await Promise.all(promises);
      let allData = [];
      responses.forEach((resp) => {
        let finalData = [];
        _.forEach(resp.data, (temp) => {
          finalData.push({
            id: temp.id,
            client_name: temp.masterEntity.relation_name || "N/A",
            vessel_name: temp.vessel.vessel_name || "N/A",
            client_id: temp.masterEntity.id || "N/A",
            vessel_id: temp.vessel.id || "N/A",
            primary_flg: temp.primary_flg,
            start_date: temp.start_date || null,
            end_date: temp.end_date || null,
          });
        });
        finalData.sort((a, b) => (a.username > b.username ? 1 : -1));
        allData = [...allData, ...finalData];
      });
      if (allData.length > 0) {
        this.setState({
          ...this.state,
          exportData: allData,
        });
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent:
          (e.response.data && e.response.data.error.message) ||
          "Please try again",
      });
    }
  };

  updateItem = (data, item) => {};

  update = async (dataItem) => {};

  enterEdit = (dataItem) => {};

  itemChange = (event) => {};

  rowRender = (trElement, dataItem) => {
    const colorFlag =
      dataItem.dataItem.start_date !== "" && dataItem.dataItem.end_date === null
        ? "#0d5869"
        : "#ed7070";

    const trProps = {
      ...trElement.props,
      onContextMenu: (e) => {
        e.preventDefault();

        const { is_active, vessel_imo_no, has_clients } = dataItem.dataItem;

        if (is_active === true && vessel_imo_no) {
          if (has_clients === true) {
            this.setState(
              {
                ...this.state,
                rightClickText: ["Change Vessel Name", "Set to inactive"], // TODO CHECK : BYBASS IF THE VESSEL BELONG TO CLIENT(s)
              },
              () => {}
            );
          } else if (has_clients === false || has_clients === undefined) {
            this.setState(
              {
                ...this.state,
                rightClickText: ["Change Vessel Name", "Set to inactive"],
              },
              () => {}
            );
          }
        } else if (is_active === false) {
          this.setState(
            {
              ...this.state,
              rightClickText: ["Set to active"],
            },
            () => {}
          );
        } else if (is_active === true && !vessel_imo_no) {
          this.setState(
            {
              ...this.state,
              rightClickText: ["Set to inactive"],
            },
            () => {}
          );
        }

        this.handleContextMenuOpen(e, dataItem.dataItem);
      },
      style: {
        color: colorFlag,
      },
    };

    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  render() {
    const dataItem = this.props.dataItem;

    const result = this.state.result;
    const collapsedState = this.state.collapsedState;
    let newData = setExpandedState({
      data: result,
      collapsedIds: collapsedState,
    });

    return (
      <div>
        <div style={{ display: "flex" }}>
          <h4 style={{ fontFamily: "bold", textSize: "7px" }}>
            <ViewSettings
              {...this.props}
              export={{
                data: this.state.exportData || [],
                fileName: "History_Of_VesselToClient_",
                exportFunction: this.getExportData,
                columns: columnsSchema(),
              }}
              viewSettings={{
                type: "",
              }}
              refreshSettings={true}
              refreshFunction={this.fetchLatestUserDepartments}
            />
          </h4>
        </div>

        <Grid
          // {...gridData || []}
          data={newData}
          filterable={false}
          style={{ className: "grid-no-select" }}
          sortable={true}
          groupable={true}
          resizable
          group={this.state.group}
          rowRender={this.rowRender}
          editField={this.editField}
          onItemChange={this.itemChange}
          pageable={false}
        >
          {this.renderGridColumns(dataItem)}
        </Grid>
      </div>
    );
  }
}
