import {connect} from 'react-redux';
import compose from 'ramda/src/compose';
import Warehouses from "./View/index";

const mapStateToProps = (state) => {
    return {
        user: state.MainLayoutReducer.user,
        hasBackofficeRead: state.MainLayoutReducer.user.hasBackofficeRead,
        countryCodes: state.MainLayoutReducer.countryCodes,
        usaStates: state.MainLayoutReducer.usaStates,
      };
}

export default compose(
    connect(mapStateToProps, null))
(Warehouses)

