import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import CustomInput from "../../../../../components/CustomInput/CustomInput.jsx";
import Button from "../../../../../components/CustomButtons/Button.jsx";
// import {Card, CardHeader, CardText, CardFooter, CardIcon, CardBody} from "../../../../../components/Card";
import loginPageStyle from "../../../../../assets/jss/material-dashboard-pro-react/views/loginPageStyle";
import SuccessModal from "../../../../../components/SuccessModal";
import ErrorModal from "../../../../../components/ErrorModal";
import {ChangePasswordCall} from "../../../../../networking/apiCalls/ChangePasswordCall";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";

class ChangePassword extends Component {

  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      loading: false,

      username: '',
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',

      openSuccessModal: false,
      successModalContent: '',

      // openErrorModal: false,
      // errorModalContent: ''

      errorUsername: '',
      errorOldPassword: '',
      errorNewPassword: '',
      errorConfirmNewPassword: '',
    };
  }

  componentWillUnmount() {
    window.localStorage.removeItem('changePassEmail')
    window.localStorage.removeItem('changePassUsername')
  }

  closeSuccessModal = () => {
    this.setState({openSuccessModal: false, successModalContent: ''})
  }

  closeErrorModal = () => {
    this.setState({openErrorModal: false, errorModalContent: ''})

  }

  onInputChange = (event) => {
    if (this.state.newPassword === event.target.value) {
      this.setState({errorConfirmNewPassword: ''})
    }
    if (this.state.confirmNewPassword === event.target.value) {
      this.setState({errorConfirmNewPassword: ''})
    }

    this.setState({
      [event.target.id]: event.target.value,
      autogeneratePasswords: false
    })
  }

  onKeyboardEnter = (e, username, oldPassword, newPassword, confirmNewPassword) => {
    e.preventDefault()

    if (newPassword !== confirmNewPassword) {
      this.setState({
        errorConfirmNewPassword: 'Your new password and confirmation password do not match',
        //Set message of error alert and open it
        //openErrorModal: true,
        //errorModalContent: 'Your new password and confirmation password do not match'
      })
    } else {
      this.setState({
        loading: true,
        errorNewPassword: "",
        errorConfirmNewPassword: "",
      }, () => this.onChangePasswordFormSubmit(newPassword, confirmNewPassword))
    }

  }

  onChangePasswordFormSubmit = (newPassword, confirmNewPassword) => {
    let informOperatorEmail = []
    if (this.state.informOperatorEmail !== "") {
      informOperatorEmail.push(this.state.informOperatorEmail)
    } else {
      informOperatorEmail = this.props.informOperatorEmail
    }

    // if (this.props.typeOfUser === 'Operator') {
    //   informOperatorEmail = []
    // }

    let params = {
      newPassword: newPassword,
      confirmNewPassword: confirmNewPassword,
      typeOfUser:this.props.typeOfUser,
      informOperatorEmail: informOperatorEmail || [],
    }

    this.setState({
      errorConfirmNewPassword: '',

    }, () => {

      const url2 = `snUsers/update-password-from-token`
      const userEmail = this.props.openDataItem.email
      const username = this.props.openDataItem.username
      let options = {
        token: window.localStorage.getItem('access_token')
      };

      const changePassResponse = ChangePasswordCall(params, url2, userEmail, username, options)
      changePassResponse
        .then(response => {
          if (response) {
            this.setState({
              openSuccessModal: true,
              successModalContent: `Change password process was successfully completed.`
            }, () => {
              setTimeout(() => {
                this.props.closeSetCustomPasswordWindow()
              }, 2800)
            })
          }
        })
        .catch(error => {
          this.setState({
            openErrorModal: true,
            errorModalContent: error?.response?.data?.error?.message || 'Change password process failed.',
            loading: false
          })
        })
    })

  }

  render() {

    const {classes} = this.props;
    const {username, oldPassword, newPassword, confirmNewPassword, informOperatorEmail} = this.state;

    const hasMinimum12Chars = newPassword.length >= 12;
    const hasAtLeast1Lowercase = /[a-z]/.test(newPassword);
    const hasAtLeast1Uppercase = /[A-Z]/.test(newPassword);
    const hasAtLeast2Digits = /\d{2}/.test(newPassword);

    return (
      <div>
        <form
          onSubmit={(e) => this.onKeyboardEnter(e, username, oldPassword, newPassword, confirmNewPassword)}
        >
          <div className={classes.socialLine}>
            <h2>Please set custom password for user: {this.props.openDataItem.username}</h2>
          </div>
          <SuccessModal
            open={this.state.openSuccessModal}
            onCloseModal={this.closeSuccessModal}
            modalContent={this.state.successModalContent}
            timeout={4000}
          />

          <ErrorModal
            open={this.state.openErrorModal}
            onCloseModal={this.closeErrorModal}
            modalContent={this.state.errorModalContent}
          />
          <CustomInput
            label="New Password *"
            id="newPassword"

            fullWidth={true}
            onChange={event => this.onInputChange(event)}
            endAdornment={<InputAdornment position="end">
              <Icon className={classes.inputAdornmentIcon}>
                lock_outline
              </Icon>
            </InputAdornment>}
            // type={'password'}
            value={newPassword}
            error={this.state.errorNewPassword}
          />
          {newPassword.length > 0 && (
            <div className="check-wrap">
              <div
                className={`check-row ${
                  hasMinimum12Chars ? "valid" : ""
                }`}
              >
                {hasMinimum12Chars ? <DoneIcon/> : <CloseIcon/>}
                <span className="check-text">
                          Minimum 12 characters
                        </span>
              </div>
              <div
                className={`check-row ${
                  hasAtLeast1Uppercase ? "valid" : ""
                }`}
              >
                {hasAtLeast1Uppercase ? <DoneIcon/> : <CloseIcon/>}
                <span className="check-text">
                          At least 1 uppercase letter
                        </span>
              </div>
              <div
                className={`check-row ${
                  hasAtLeast1Lowercase ? "valid" : ""
                }`}
              >
                {hasAtLeast1Lowercase ? <DoneIcon/> : <CloseIcon/>}
                <span className="check-text">
                          At least 1 lowercase letter
                        </span>
              </div>
              <div
                className={`check-row ${
                  hasAtLeast2Digits ? "valid" : ""
                }`}
              >
                {hasAtLeast2Digits ? <DoneIcon/> : <CloseIcon/>}
                <span className="check-text">At least 2 digits</span>
              </div>
            </div>
          )}
          <br/>
          <CustomInput
            label="Confirm New Password *"
            id="confirmNewPassword"
            fullWidth={true}
            onChange={event => this.onInputChange(event)}
            endAdornment={<InputAdornment position="end">
              <Icon className={classes.inputAdornmentIcon}>
                lock_outline
              </Icon>
            </InputAdornment>}
            // type={'password'}
            value={confirmNewPassword}
            error={this.state.errorConfirmNewPassword}
          />
          <br />
          {
            this.props.typeOfUser === 'Operator' ? null : <CustomInput
              label="Optional operator email"
              id="informOperatorEmail"
              fullWidth={true}
              onChange={event => this.onInputChange(event)}
              value={informOperatorEmail}
            />
          }

          <div style={{position: 'relative'}}>
            <Button
              color="warning"
              simple
              size="md"
              block
              type="submit"
              disabled={
                (
                  newPassword === '' || confirmNewPassword === '' ||
                  !hasMinimum12Chars || !hasAtLeast1Uppercase || !hasAtLeast1Lowercase || !hasAtLeast2Digits
                )
              }
            >
              Submit
            </Button>
            <br/>
          </div>
        </form>
      </div>
    )
  }
}

export default withStyles(loginPageStyle)(ChangePassword);
