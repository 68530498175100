import React from "react";
import { columnsSchema } from "./schema";
import { MyCommandCell } from "../../../../Clients/View/components/CellHOC";
import { serverApi } from "../../../../../networking/config";
import _ from "lodash";
import { GridColumn as Column } from "@progress/kendo-react-grid/dist/npm/GridColumn";
import { Grid, GridToolbar } from "@progress/kendo-react-grid";
import GridContainer from "../../../../../components/Grid/GridContainer";
import { IconButton, Tooltip } from "@material-ui/core";
import { DropDownCell } from "../../../../CRM/View/dropDownCell";
import { AutoComplete2 } from "../../../../Vessels/View/components/relationNameDropDown";
import { Window } from "@progress/kendo-react-dialogs";
import BlockUi from "react-block-ui";
import { Error } from "@progress/kendo-react-labels";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import ViewSettings from "../../../../common/ViewSettings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

export default class AgentToWarehouse extends React.Component {
  editField = "inEdit";
  CommandCell;
  _export;

  constructor(props) {
    super(props);

    this.state = {
      visibleWindow: false,
      isDropOpened: false,
      columns: (columnsSchema && columnsSchema()) || [],
      gridData: {
        data: [],
        total: 10,
      },
      initialGridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 10,
        skip: 0,
      },
      openErrorModal: false,
      errorModalContent: "",
      delay: 400,
      loading: false,
      warehouses: [],
      windowError: false,
      windowErrorMessage: "",
      disableEditButton: true,
      inputValue: "",
      clientAttachedToAnotherGroup: null,
      exportData: [],
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      disableEditButton: false,
      showSecondAction: false,
      showFirstAction: false,
    });
  }

  async componentDidMount() {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    const agentID = this.props.dataItem.id;
    let fil = {
      filter: {
        where: {
          agentID: agentID,
        },
        include: ["warehouse"],
      },
    };
    let agentsOfWarehouse = await serverApi(
      "GET",
      `agentToWarehouses`,
      fil,
      "",
      options
    );
    let allWarehouses = await serverApi(
      "GET",
      `masterEntities`,
      {
        filter: {
          where: {
            wh_flag: true,
          },
        },
      },
      "",
      options
    );
    let warehouses =
      allWarehouses.data
        .sort((a, b) => (a.relation_name > b.relation_name ? 1 : -1))
        .map((x) => x.relation_name) || [];

    let result = this.constructUsersAndExistingUsersArray(
      allWarehouses.data,
      agentsOfWarehouse.data
    );
    result[0].finalAttachedUsers.forEach((item) => {
      let user = result[0].finalUsers.find((x) => x.id === item.id);
      if (user) {
        user.checked = true;
      }
    });

    if (agentsOfWarehouse && agentsOfWarehouse.data.length > 1) {
      let finalData = [];
      _.forEach(agentsOfWarehouse.data, (temp) => {
        finalData.push({
          id: temp.id,
          warehouseID: temp.warehouseID,
          agentID: agentID,
          warehouseName: temp?.warehouse?.relation_name,
          deleteID: temp.id,
        });
      });

      this.setState({
        ...this.state,
        agentID: this.props.dataItem.id,
        gridData: {
          data: finalData,
          total: finalData.length,
        },
        initialGridData: {
          data: finalData,
          total: finalData.length,
        },
        user_names: warehouses,
        valueUserName: "",

        visibleWarehouses: result[0].finalUsers || [],
        finalWarehouses: result[0].finalUsers || [],
        initialFinalWarehouses: result[0].finalUsers || [],
        blocking: false,
      });
    } else if (agentsOfWarehouse && agentsOfWarehouse.data.length === 1) {
      let finalData = [];
      _.forEach(agentsOfWarehouse.data, (temp) => {
        finalData.push({
          id: temp.id,
          agentID: agentID,
          warehouseID: temp.warehouseID,
          warehouseName: temp.warehouse.relation_name,
          deleteID: temp.id,
        });
      });

      this.setState({
        ...this.state,
        agentID: this.props.dataItem.id,
        gridData: {
          data: [finalData[0]],
          total: 1,
        },
        initialGridData: {
          data: [finalData[0]],
          total: 1,
        },
        user_names: warehouses,
        valueUserName: "",
        visibleWarehouses: result[0].finalUsers || [],
        finalWarehouses: result[0].finalUsers || [],
        initialFinalWarehouses: result[0].finalUsers || [],
        blocking: false,
      });
    } else {
      this.setState({
        ...this.state,
        agentID: this.props.dataItem.id,
        gridData: {
          data: [],
          total: 0,
        },
        initialGridData: {
          data: [],
          total: 0,
        },
        user_names: warehouses,
        valueUserName: "",
        visibleWarehouses: result[0].finalUsers || [],
        finalWarehouses: result[0].finalUsers || [],
        initialFinalWarehouses: result[0].finalUsers || [],
        blocking: false,
      });
    }
  }

  constructUsersAndExistingUsersArray = (warehouses, attachedWarehouses) => {
    let finalUsers = [];
    _.forEach(warehouses, (temp1) => {
      finalUsers.push({
        warehouseName: temp1.relation_name || "N/A",
        id: temp1.id,
        checked: false,
      });
    });

    let finalAttachedUsers = [];
    _.forEach(attachedWarehouses, (temp2) => {
      finalAttachedUsers.push({
        warehouseName: temp2?.warehouse?.relation_name || "N/A",
        warehouseId: temp2.warehouseID,
        checked: true,
        deleteID: temp2.id,
        id: temp2.warehouseID,
      });
    });

    finalUsers.sort((a, b) => (a.relation_name > b.relation_name ? 1 : -1));
    finalAttachedUsers.sort((a, b) =>
      a.relation_name > b.relation_name ? 1 : -1
    );

    return [
      {
        finalUsers: finalUsers,
        finalAttachedUsers: finalAttachedUsers,
      },
    ];
  };

  componentWillUnmount() {}

  remove = async (dataItem) => {};

  renderGridColumns = (dataItem, changeTheCellComponent) => {
    let selectionCol = [];
    let normalCols = [];
    let finalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            width="70px"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.isFilterBoolean === "yes") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"420"}
              cell={DropDownCell}
            />
          );
        } else if (temp.field === "masterEntity.relation_name") {
          return (
            <Column
              field="masterEntity.relation_name"
              title="NAME"
              width="400px"
              cell={AutoComplete2}
            />
          );
        } else {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={temp.field === "id" || temp.editable ? false : true} //id not editable on edit
            />
          );
        }
      });

    finalCols = [...selectionCol, ...normalCols];
    return finalCols;
  };

  getExportData = async () => {
    await this.exportToCsv(this.state, this.props);
  };

  exportToCsv = async (state, props) => {
    const count = state.gridData.total;
    const numberOfCalls = Math.min(
      Math.round(count / 1000) + 1,
      5 // Limit to 5000
    );

    const promises = [];
    for (let i = 0; i < numberOfCalls; i++) {
      promises.push(
        this.getPromise({
          skip: i * 1000,
          limit: 1000,
          order: ["id asc"],
        })
      );
    }

    try {
      const responses = await Promise.all(promises);
      let allData = [];
      (responses || []).forEach((resp) => {
        let finalData = [];
        _.forEach(resp.data, (temp) => {
          finalData.push({
            id: temp.id,
            warehouseID: temp.warehouseID,
            agentID: this.props.dataItem.id,
            warehouseName: temp?.warehouse?.relation_name,
            deleteID: temp.id,
          });
        });
        finalData.sort((a, b) => (a.relation_name > b.relation_name ? 1 : -1));
        allData = [...allData, ...finalData];
      });
      if (allData.length > 0) {
        this.setState({
          ...this.state,
          exportData: allData,
        });
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent:
          e?.response?.data?.error?.message ||
          "Export data failed.Please try again",
      });
    }
  };

  getPromise = () => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    const agentId = this.props.dataItem.id;
    let fil = {
      filter: {
        where: {
          agentID: agentId,
        },
        include: ["warehouse"],
      },
    };

    return new Promise((resolve, reject) => {
      const data = serverApi("GET", `agentToWarehouses`, fil, "", options);
      data
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  toggleWindow = (e) => {
    const data = this.state.initialGridData.data;

    this.setState({
      ...this.state,
      visibleWindow: !this.state.visibleWindow,
      gridData: {
        data: [...data],
        total: 100,
      },
      warehouses: [],
      valueClientName: "",
      windowError: false,
      windowErrorMessage: "",
      openErrorModal: false,
      errorModalContent: "",
    });
  };

  renderList = (finalUsers = [], users = [], doRefresh) => {
    if (doRefresh === true) {
      window.location.reload();
    } else {
      if (finalUsers.length === users.length) {
        return (
          users &&
          users.map((item) => (
            <ul style={{ marginBottom: "-5px" }} key={item.id}>
              <Checkbox
                name={item.warehouseName}
                key={item.id}
                value={item.checked}
                onChange={(e) =>
                  this.handleChange(e, item.warehouseName, item.id)
                }
                label={item.warehouseName}
                disabled={this.state.disableAllCheckboxes}
              />
            </ul>
          ))
        );
      } else {
        return (
          finalUsers &&
          finalUsers.map((item) => (
            <ul style={{ marginBottom: "-5px" }} key={item.id}>
              <Checkbox
                name={item.warehouseName}
                key={item.id}
                value={item.checked}
                onChange={(e) =>
                  this.handleChange(e, item.warehouseName, item.id)
                }
                label={item.warehouseName}
                disabled={this.state.disableAllCheckboxes}
              />
            </ul>
          ))
        );
      }
    }
  };

  handleChange = (event, name, key) => {
    let finalWarehouse = [...this.state.finalWarehouses];
    let finalObj = finalWarehouse.find((test) => test.id === key);
    finalObj.checked = !finalObj.checked;

    let agentID = this.state.agentID;
    let data = [...this.state.gridData.data];

    this.setState(
      {
        ...this.state,
        finalWarehouses: finalWarehouse,
      },
      () => {
        let postOrDeleteClient = event.value === false ? "DELETE" : "POST";
        let options = {
          token: window.localStorage.getItem("access_token"),
        };

        if (postOrDeleteClient === "POST") {
          const url = `agentToWarehouses`;
          const addUserToDepartment = serverApi(
            "POST",
            url,
            "",
            {
              warehouseID: key,
              agentID: agentID,
            },
            options,
            "",
            ""
          );

          addUserToDepartment
            .then(async (response) => {
              let fil = {
                filter: {
                  where: {
                    agentID: agentID,
                  },
                  include: ["warehouse"],
                },
              };
              let data = await serverApi(
                "GET",
                `agentToWarehouses`,
                fil,
                "",
                options
              );

              let finalData = [];
              _.forEach(data.data, (temp) => {
                finalData.push({
                  id: temp.id,
                  warehouseID: temp.warehouseID,
                  agentID: this.props.dataItem.id,
                  warehouseName: temp?.warehouse?.relation_name,
                  deleteID: temp.id,
                });
              });

              this.setState({
                ...this.state,
                gridData: {
                  data: finalData,
                  total: finalData.length,
                },
                initialGridData: {
                  data: finalData,
                  total: finalData.length,
                },
                blocking: false,
                openErrorModal: false,
                errorModalContent: "",
              });
            })
            .catch((error) => {
              this.setState({
                ...this.state,
                openErrorModal: true,
                errorModalContent:
                  error?.response?.data?.error?.message ||
                  "Add warehouse to agent failed.Please try again",
                blocking: false,
              });
            });
        } else if (postOrDeleteClient === "DELETE") {
          const deleteObj = data.find((obj) => obj.warehouseID === key);
          const delete_id = deleteObj.deleteID;

          const url = `agentToWarehouses/${delete_id}`;
          const removeData = serverApi("DELETE", url, "", "", options, "", "");
          removeData
            .then(async (response) => {
              let fil = {
                filter: {
                  where: {
                    agentID: agentID,
                  },
                  include: ["warehouse"],
                },
              };
              let agentData = await serverApi(
                "GET",
                `agentToWarehouses`,
                fil,
                "",
                options
              );
              let finalData = [];
              _.forEach(agentData.data, (temp) => {
                finalData.push({
                  id: temp.id,
                  warehouseID: temp.warehouseID,
                  agentID: this.props.dataItem.id,
                  warehouseName: temp?.warehouse?.relation_name,
                  deleteID: temp.id,
                });
              });

              this.setState({
                ...this.state,
                gridData: {
                  data: finalData,
                  total: finalData.length,
                },
                initialGridData: {
                  data: finalData,
                  total: finalData.length,
                },
                blocking: false,
              });
            })
            .catch((error) => {
              this.setState({
                ...this.state,
                openErrorModal: true,
                errorModalContent:
                  error?.response?.data?.error?.message ||
                  "Remove warehouse from agent failed.Please try again",
                blocking: false,
              });
            });
        }
      }
    );
  };

  handleSearch = (e) => {
    let initialFinalWarehouses = this.state.initialFinalWarehouses;

    if (e.value === "") {
      let searchArr = [];
      initialFinalWarehouses.forEach((temp) => {
        if (temp.warehouseName.toUpperCase().includes(e.value.toUpperCase())) {
          searchArr.push(temp);
        }
      });

      if (
        this.state.clientAttachedToAnotherGroup !== null &&
        this.state.clientAttachedToAnotherGroup !== undefined
      ) {
        let newArr = searchArr.filter(
          (t) => t.id !== this.state.clientAttachedToAnotherGroup
        );
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalWarehouses: newArr,
          openErrorModal: false,
          errorModalContent: "",
        });
      } else {
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalWarehouses: initialFinalWarehouses,
          openErrorModal: false,
          errorModalContent: "",
        });
      }
    } else {
      let searchArr = [];
      //case insensitive
      initialFinalWarehouses.forEach((temp) => {
        if (
          temp &&
          temp.warehouseName.toUpperCase().includes(e.value.toUpperCase())
        ) {
          searchArr.push(temp);
        }
      });

      if (
        this.state.clientAttachedToAnotherGroup !== null &&
        this.state.clientAttachedToAnotherGroup !== undefined
      ) {
        let newArr = searchArr.filter(
          (t) => t.id !== this.state.clientAttachedToAnotherGroup
        );
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalWarehouses: newArr,
          openErrorModal: false,
          errorModalContent: "",
        });
      } else {
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalWarehouses: searchArr,
          openErrorModal: false,
          errorModalContent: "",
        });
      }
    }
  };

  render() {
    const dataItem = this.props.dataItem;
    const { gridData } = this.state;
    const { inputValue } = this.state;
    const { hasBackofficeRead } = this.props;

    return (
      <div>
        <h4 style={{ fontFamily: "bold", textSize: "7px" }}>
          Agent name : {dataItem && dataItem.relation_name}
        </h4>

        <div style={{ marginRight: "100px", width: "1000px" }}>
          <Grid
            {...(gridData || [])}
            filterable={false}
            style={{ className: "grid-no-select" }}
            sortable={true}
            resizable
            editField={this.editField}
            onItemChange={this.itemChange}
            pageable={false}
          >
            <GridToolbar>
              <GridContainer xs={12} justify={"flex-start"} direction={"row"}>
                {!hasBackofficeRead && (
                  <Tooltip
                    justify={"flex-start"}
                    placement="top"
                    title={"Add/Remove Warehouse"}
                  >
                    <IconButton>
                      <FontAwesomeIcon
                        color="#0D5869"
                        onClick={this.toggleWindow}
                        size="1.6x"
                        icon={faPencilAlt}
                      />
                    </IconButton>
                  </Tooltip>
                )}
                <ViewSettings
                  {...this.props}
                  export={{
                    data: this.state.exportData || [],
                    fileName: `${dataItem.relation_name}_AgentToWarehousesData_`,
                    exportFunction: this.getExportData,
                    columns: columnsSchema().filter((x) => x.export),
                  }}
                  viewSettings={{
                    type: "",
                  }}
                />
              </GridContainer>
            </GridToolbar>
            {/*<Column cell={this.CommandCell} title="Actions" filterable={false} width="200px"/>*/}
            {this.renderGridColumns(dataItem)}
          </Grid>

          {this.state.visibleWindow && (
            <Window
              title={`Add/Remove Warehouse from Agent: ${this.props.dataItem.relation_name}`}
              onClose={this.toggleWindow}
              width={750}
              height={520}
              initialTop={110}
            >
              <div>
                <div>
                  <BlockUi
                    tag="div"
                    blocking={this.state.blocking}
                    message="Loading, please wait. . ."
                    renderChildren={true}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: "50px",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {this.state.openErrorModal ? (
                          <Error style={{ color: "red", fontSize: "15px" }}>
                            {this.state.errorModalContent || ""}
                          </Error>
                        ) : null}
                        <div
                          style={{
                            display: "flex",
                            marginLeft: "35px",
                            marginTop: "20px",
                          }}
                        >
                          <Input
                            name="users"
                            value={inputValue}
                            style={{ marginBottom: "20px" }}
                            label="Search warehouse"
                            onChange={(e) => this.handleSearch(e)}
                            minLength={2}
                          />
                        </div>

                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {this.renderList(
                            this.state.finalWarehouses,
                            this.state.visibleWarehouses
                          )}
                        </div>
                      </div>
                    </div>
                  </BlockUi>
                </div>
                {this.state.windowError ? (
                  <Error style={{ color: "red" }}>
                    <h3>{this.state.windowErrorMessage || ""}</h3>
                  </Error>
                ) : null}
              </div>
            </Window>
          )}
        </div>
      </div>
    );
  }
}
