/**
 * Created by kfousteris on 12/06/2019.
 */
import React from 'react'
import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

const SuccessModal = (props) => {

    const {open, modalContent, onCloseModal,timeout} = props

    return (
        open ?
            <SweetAlert
                success
                style={{display: "block", marginTop: "-100px"}}
                title={modalContent}
                showConfirm={false}
                onConfirm={onCloseModal}
                timeout={timeout || 3000}
                confirmBtnCssClass={
                    props.classes.button + " " + props.classes.success
                }
            />
            : null
    )
}

export default withStyles(sweetAlertStyle)(SuccessModal)
