import { connect } from "react-redux";
import compose from "ramda/src/compose";
import ModelSettingDefinition from "./View";

const mapActionCreators = {};

const mapStateToProps = (state) => {
  return {
    user: state.MainLayoutReducer.user,
    hasBackofficeRead: true,
    countryCodes: state.MainLayoutReducer.countryCodes,
    usaStates: state.MainLayoutReducer.usaStates,
  };
};

export default compose(connect(mapStateToProps, mapActionCreators))(
  ModelSettingDefinition
);
