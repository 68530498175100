export const columnsSchema = () => [
  {
    field: "id",
    title: "ID",
    visible: true,
    minWidth: 150,
    sortable: true,
    filterable: true,
    locked: "locked",
    noDisabledColumn: true,
    notShownInTableColumnSelection: false
  },
  {
    field: "name",
    title: "Name",
    visible: true,
    // minWidth: 300,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    locked: "locked"
  },
  {
    field: "type",
    title: "Type",
    visible: true,
    // minWidth: 150,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    locked: "locked"
  },
  {
    field: "data_base64",
    title: "Base64 File",
    visible: false,
    // minWidth: 600,
    sortable: true,
    filterable: true,
    locked: "locked"
  }
];
