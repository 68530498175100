export const columnsSchema = () => [
  {
    field: "id",
    title: "ID",
    visible: true,
    minWidth: 140,
    sortable: true,
    filterable: true,
    locked: "locked",
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
    // cell: true
  },
  {
    field: "description",
    title: "Description",
    visible: true,
    minWidth: 320,
    sortable: true,
    filterable: true,
    locked: "locked",
  },
  {
    field: "vatNumber",
    title: "Vat Number",
    visible: true,
    minWidth: 170,
    sortable: true,
    filterable: true,
    locked: "locked",
    // editable:'false'
  },
  {
    field: "helpdesk_email",
    title: "Helpdesk email",
    visible: true,
    minWidth: 170,
    sortable: true,
    filterable: true,
    locked: "locked",
    // editable:'false'
  },
  {
    field: "subsidiary_id",
    title: "Subsidiary",
    visible: true,
    minWidth: 170,
    sortable: true,
    filterable: true,
    locked: "locked",
    // editable:'false'
  },
  {
    field: "bankName",
    title: "Bank Name",
    visible: true,
    minWidth: 170,
    sortable: true,
    filterable: true,
    locked: "locked",
  },
  {
    field: "bicCode",
    title: "Bic Code",
    visible: true,
    minWidth: 170,
    sortable: true,
    filterable: true,
    locked: "locked",
  },
  {
    field: "relationID",
    title: "Relation ID",
    visible: true,
    minWidth: 170,
    sortable: true,
    filterable: true,
    locked: "locked",
    editable: "false",
  },
  {
    field: "assetID",
    title: "Asset ID",
    visible: true,
    minWidth: 180,
    sortable: true,
    filterable: true,
    locked: "locked",
    editable: "false",
  },
  // {
  //     field: 'masterEntityContacts.contact_name',
  //     title: 'Name',
  //     visible: true,
  //     // minWidth: 170,
  //     sortable: true,
  //     filterable: true,
  //     locked: 'locked',
  //     editable: 'false'
  // }
];
