import React from "react";
import {
  Grid,
  GridColumn as Column,
  GridDetailRow,
  // GridToolbar,
} from "@progress/kendo-react-grid";
import { columnsSchema } from "./schema";
import { MyCommandCell } from "../../../../Clients/View/components/CellHOC";
import _ from "lodash";
import { DropDownCell } from "../../../../CRM/View/dropDownCell";
// import GridContainer from "../../../../../components/Grid/GridContainer";
import { serverApi } from "../../../../../networking/config";
import { AbstractDelete } from "../../../../../networking/apiCalls";
import { Window } from "@progress/kendo-react-dialogs";
import { filterBy } from "@progress/kendo-data-query";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { Error } from "@progress/kendo-react-labels";
// import { IconButton, Tooltip } from "@material-ui/core";
// import Fab from "@material-ui/core/Fab";
// import AddIcon from "@material-ui/icons/Add";
// import { formatDatesOnTable } from "../../../../../lib/GeneralUtils/utils";

class ClientToUserExpanded extends GridDetailRow {
  editField = "inEdit";
  CommandCell;
  _export;

  constructor(props) {
    super(props);

    this.state = {
      visibleWindow: false,
      isDropOpened: false,
      columns: (columnsSchema && columnsSchema()) || [],
      gridData: {
        data: [],
        total: 10,
      },
      initialGridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 10,
        skip: 0,
      },
      openErrorModal: false,
      errorModalContent: "",
      delay: 400,
      loading: false,
      vessel_names: [],
      windowError: false,
      windowErrorMessage: "",
      disableEditButton: true,
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      disableEditButton: false,
      showSecondAction: false,
      showFirstAction: false,
    });
  }

  async componentDidMount() {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let filter1 = {
      filter: {
        where: {
          clientID: this.props.dataItem && this.props.dataItem.id,
        },
        include: {
          relation: "snUser",
          scope: {
            include: [
              {
                relation: "roles",
              },
            ],
          },
        },
      },
    };
    let responseData = await serverApi(
      "GET",
      `userToClients`,
      filter1,
      "",
      options
    );

    responseData.data = responseData.data.filter((x) => {
      const roles = x?.snUser?.roles || [];
      return roles.some(
        (role) => role.name === "client" || role.name === "clientMB"
      );
    });

    const getUsers = await serverApi("GET", `snUsers`, "", "", options);

    let clientUsers =
      getUsers.data
        .sort((a, b) => (a.username > b.username ? 1 : -1))
        .map((x) => x.username) || [];

    let result = this.constructUsersAndExistingUsersArray(
      getUsers.data,
      responseData.data
    );
    result[0].finalAttachedUsers.forEach((item) => {
      let user = result[0].finalUsers.find((x) => x.name === item.name);
      if (user) {
        user.checked = true;
      }
    });

    if (responseData && responseData?.data?.length > 1) {
      let finalData = [];
      _.forEach(responseData?.data, (temp) => {
        finalData.push({
          id: temp.userID,
          username: temp.snUser.username,
          userRealName: temp.snUser.userRealName || "N/A",
          email: temp.snUser.email || "N/A",
          bugify_token: temp.snUser.bugify_token || "N/A",
          last_login_date: temp.snUser.last_login_date || "N/A",
          last_password_change: temp.snUser.last_password_change || "N/A",
          lastUpdated: temp.snUser.lastUpdated || "N/A",
          created: temp.snUser.created || "N/A",
          deleteID: temp.id,
        });
      });
      finalData.sort((a, b) => (a.username > b.username ? 1 : -1));

      this.setState({
        ...this.state,
        userID: this.props.dataItem.id,
        gridData: {
          data: finalData,
          total: finalData.length,
        },
        initialGridData: {
          data: finalData,
          total: finalData.length,
        },
        clientUsers: clientUsers,
        valueUserName: "",

        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    } else if (responseData && responseData?.data?.length === 1) {
      let finalData = [];
      _.forEach(responseData.data, (temp) => {
        finalData.push({
          id: temp.userID,
          username: temp.snUser.username,
          userRealName: temp.snUser.userRealName || "N/A",
          email: temp.snUser.email || "N/A",
          bugify_token: temp.snUser.bugify_token || "N/A",
          last_login_date: temp.snUser.last_login_date || "N/A",
          last_password_change: temp.snUser.last_password_change || "N/A",
          lastUpdated: temp.snUser.lastUpdated || "N/A",
          created: temp.snUser.created || "N/A",
          deleteID: temp.id,
        });
      });
      finalData.sort((a, b) => (a.username > b.username ? 1 : -1));

      this.setState({
        ...this.state,
        userID: this.props.dataItem.id,
        gridData: {
          data: [finalData[0]],
          total: 1,
        },
        initialGridData: {
          data: [finalData[0]],
          total: 1,
        },
        clientUsers: clientUsers,
        valueUserName: "",
        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    } else {
      this.setState({
        ...this.state,
        userID: this.props.dataItem.id,
        gridData: {
          data: [],
          total: 0,
        },
        initialGridData: {
          data: [],
          total: 0,
        },
        clientUsers: clientUsers,
        valueUserName: "",
        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    }
  }

  constructUsersAndExistingUsersArray = (allClients, attachedUsers) => {
    let finalUsers = [];
    _.forEach(allClients, (temp1) => {
      finalUsers.push({
        name: temp1.username,
        id: temp1.id,
        checked: false,
      });
    });

    let finalAttachedUsers = [];
    _.forEach(attachedUsers, (temp2) => {
      finalAttachedUsers.push({
        name: temp2.snUser.username || "N/A",
        clientID: temp2.clientID,
        checked: true,
        deleteID: temp2.id,
        id: temp2.id,
      });
    });

    finalUsers.sort((a, b) => (a.name > b.name ? 1 : -1));
    finalAttachedUsers.sort((a, b) => (a.name > b.name ? 1 : -1));

    return [
      {
        finalUsers: finalUsers,
        finalAttachedUsers: finalAttachedUsers,
      },
    ];
  };

  exportToCsv = () => {
    this._export.save(this.state.gridData.data, this.state.columns);
  };

  renderGridColumns = (dataItem, changeTheCellComponent) => {
    let selectionCol = [];
    let normalCols = [];
    let finalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            width="65px"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.isFilterBoolean === "yes") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              cell={DropDownCell}
            />
          );
        } else {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={temp.field === "id" || temp.editable ? false : true} //id not editable on edit
            />
          );
        }
      });

    finalCols = [...selectionCol, ...normalCols];
    return finalCols;
  };

  onChange = (event) => {
    if (
      event.nativeEvent.type === "keydown" &&
      event.nativeEvent.keyCode === 13
    )
      return;

    const { value } = event.target;

    if (value === "") {
      this.setState({
        openedUserNames: false,
        valueUserName: value,
      });
    } else {
      this.setState(
        {
          openedUserNames: false,
          valueUserName: value,
        },
        () => {
          this.onAutoCompleteChange(event, value);
        }
      );
    }
  };

  onAutoCompleteChange = (event, value) => {
    const { name } = event.target;

    let stateData = {};

    stateData =
      value.length < 3
        ? {
            data:
              this.state.user_names.length > 0
                ? this.state.user_names.map((temp) => temp.username)
                : [],
            openedUserNames: false,
          }
        : { data: this.filterData(value, name), openedUserNames: true };

    let eventType = event.nativeEvent.type;

    let valueSelected =
      eventType === "click" ||
      (eventType === "keydown" && event.nativeEvent.keyCode === 13);

    if (valueSelected) {
      let postUserId =
        (this.state.user_names &&
          this.state.user_names.length === 1 &&
          this.state.user_names[0].id) ||
        (this.state.user_names &&
          this.state.user_names.length > 1 &&
          this.state.user_names.filter(
            (temp) => temp.username === this.state.valueUserName
          )[0].id);

      this.setState({
        ...this.state,
        postUserId: postUserId || "",
      });
    }

    if (valueSelected && stateData.data.includes(value)) {
      stateData.openedUserNames = false;
    }

    this.setState(
      {
        valueUserName: value,
        ...stateData,
      },
      () => this.fetchAutocompleteData(value)
    );
  };

  fetchAutocompleteData = (filterValue) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    const fetchDropdownData = serverApi(
      "GET",
      `snUsers`,
      {
        filter: {
          where: {
            username: { regexp: `/${filterValue}/i` },
          },
          order: ["username asc"],
        },
      },
      "",
      options
    );

    fetchDropdownData
      .then((response) => {
        this.setState({
          ...this.state,
          user_names: response.data,
        });
      })
      .catch((error) => {
        console.log(error);

        throw error;
      });
  };

  filterData = (value, name) => {
    const data = this.state.user_names.map((temp) => temp.vessel_name);

    const filter = {
      value: value,
      operator: "startswith",
      ignoreCase: true,
    };

    let results = filterBy(data, filter);
    return results;
  };

  enterEdit = (dataItem) => {
    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    let total = this.state.gridData.total;

    this.setState({
      gridData: {
        data:
          this.state.gridData &&
          this.state.gridData.data.map((item) =>
            item.id === dataItem.id ? { ...item, inEdit: true } : item
          ),
        total: total,
      },
    });
  };

  discard = (dataItem, hasError = false) => {
    let data = [...this.state.gridData.data];
    const total = this.state.gridData.total;

    if (hasError) {
      data[0].inEdit = true;
      this.setState({
        gridData: {
          data: data,
          total: total,
        },
      });
    } else {
      this.removeItem(data, dataItem);

      this.setState({
        gridData: {
          data: data,
          total: total,
        },
        visible: false,
      });
    }
  };

  cancel = (dataItem) => {
    const originalItem =
      this.state.initialGridData &&
      this.state.initialGridData.data.find((p) => p.id === dataItem.id);
    originalItem.inEdit = false;

    const data =
      this.state.gridData &&
      this.state.gridData.data.map((item) =>
        item.id === originalItem.id ? originalItem : item
      );

    const total = this.state.gridData.total;

    this.setState({
      ...this.state,
      gridData: {
        data: data,
        total: total,
      },
      openErrorModal: false,
      openErrorModalContent: "",
    });
  };

  removeItem(data, item) {
    let index = data.findIndex(
      (p) => p === item || (item.id && p.id === item.id)
    );
    if (index >= 0) {
      data.splice(index, 1);
    }
  }

  remove = async (dataItem) => {
    const data = [...this.state.gridData.data];

    let index = data.findIndex(
      (p) => p === dataItem || (dataItem.id && p.id === dataItem.id)
    );
    if (index >= 0) {
      data.splice(index, 1);
    }

    try {
      let options = {
        token: window.localStorage.getItem("access_token"),
      };

      let url = "userToClients";
      let deleteItemRequest = await AbstractDelete(
        url,
        `${(dataItem && dataItem.deleteID) || ""}`,
        options
      );

      if (deleteItemRequest.status === 200) {
        //clear the column filters if grid data is 0 or let them if grid data is >= 1
        if (data.length <= 0) {
          this.setState({
            ...this.state,
            gridData: {
              data: data,
              total: data.length,
            },
            initialGridData: {
              data: data,
              total: data.length,
            },
            dataState: {
              skip: 0,
              take: 10,
            },
          });
        } else {
          this.setState({
            ...this.state,
            gridData: {
              data: data,
              total: data.length,
            },
            initialGridData: {
              data: data,
              total: data.length,
            },
          });
        }
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent:
          e?.response?.data?.error?.message || "Please try again",
      });
    }
  };

  add = (dataItem) => {
    dataItem.inEdit = undefined;

    // let data = this.state.gridData.data;

    // const submitURL = "vessels";
    // let count;
    // const options = { token: window.localStorage.getItem("access_token") };
    // let payload = dataItem;
    // delete payload.inEdit;
  };

  addNew = () => {
    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    const newDataItem = { inEdit: true };

    this.setState({
      gridData: {
        data: [newDataItem, ...this.state.gridData.data],
        total: this.state.gridData.total,
      },
      visibleWindow: true,
    });
  };

  update = async (dataItem) => {};

  updateItem = (data, item) => {
    let index = data.findIndex(
      (p) => p === item || (item.id && p.id === item.id)
    );
    if (index >= 0) {
      data[index] = { ...item };
    }
  };

  itemChange = (event) => {
    let fieldsArr = [
      "vessel.vessel_name",
      "vessel.vessel_imo_no",
      "vessel.vessel_type",
      "vessel.vessel_flag_iso",
      "vessel.vessel_build_dt",
      "vessel.vessel_builder",
      "vessel.vessel_scrapped_dt",
      "vessel.vessel_deadweight",
      "vessel.vessel_build_country",
    ];

    let fieldName;
    let index;
    if (fieldsArr.includes(event.field)) {
      index = fieldsArr.indexOf(event.field);
      fieldName = fieldsArr[index].split(".")[1];
    }

    const finalData = [];
    const total = this.state.gridData.total;

    let finalItem;

    // eslint-disable-next-line no-unused-vars
    const data =
      this.state.gridData &&
      this.state.gridData.data.map((item) => {
        let vessel = {
          ...item.vessel,
          [fieldName]: event.value,
        };

        if (item.id === event.dataItem.id) {
          delete item.vessel;
          finalItem = {
            ...item,
            vessel,
          };
          finalData.push(finalItem);
        } else {
          finalItem = item;
          finalData.push(finalItem);
        }
        return finalItem;
      });

    this.setState(
      {
        gridData: {
          data: finalData,
          total: total,
        },
      },
      () => this.renderGridColumns(finalItem, true)
    );
  };

  refreshGridDataAfterCrud = async () => {};

  toggleWindow = (e) => {
    const data = this.state.initialGridData.data;

    this.setState({
      visibleWindow: !this.state.visibleWindow,
      gridData: {
        data: [...data],
        total: 10,
      },
      user_names: [],
      valueUserName: "",
      windowError: false,
      windowErrorMessage: "",
    });
  };

  listNoDataRender = (element) => {
    const noData = (
      <h4 style={{ fontSize: "1em" }}>
        <span className="k-icon k-i-warning" style={{ fontSize: "2.5em" }} />
        <br />
        <br />
        No users found
      </h4>
    );

    return React.cloneElement(element, { ...element.props }, noData);
  };

  saveUserToClient = async (e) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    try {
      let payload = {
        clientID: this.props.dataItem.id,
        userID: this.state.postUserId,
      };

      let addUserToClientRequest = await serverApi(
        "POST",
        "userToClients",
        {},
        payload,
        options
      );

      if (addUserToClientRequest.status === 200) {
        this.setState(
          {
            ...this.state,
            visibleWindow: false,
          },
          async () => {
            let filter1 = {
              filter: {
                where: {
                  clientID: this.props.dataItem && this.props.dataItem.id,
                },
                include: ["snUser"],
              },
            };
            const responseData = await serverApi(
              "GET",
              `userToClients`,
              filter1,
              "",
              options
            );
            const getUsers = await serverApi("GET", `snUsers`, "", "", options);
            let clientUsers =
              getUsers.data
                .sort((a, b) => (a.username > b.username ? 1 : -1))
                .map((x) => x.username) || [];

            let result = this.constructUsersAndExistingUsersArray(
              getUsers.data,
              responseData.data
            );
            result[0].finalAttachedUsers.forEach((item) => {
              let user = result[0].finalUsers.find((x) => x.name === item.name);
              if (user) {
                user.checked = true;
              }
            });

            if (responseData && responseData?.data?.length > 1) {
              let finalData = [];
              _.forEach(responseData?.data, (temp) => {
                finalData.push({
                  id: temp.userID,
                  username: temp.snUser.username,
                  userRealName: temp.snUser.userRealName || "N/A",
                  email: temp.snUser.email || "N/A",
                  bugify_token: temp.snUser.bugify_token || "N/A",
                  last_login_date: temp.snUser.last_login_date || "N/A",
                  last_password_change:
                    temp.snUser.last_password_change || "N/A",
                  lastUpdated: temp.snUser.lastUpdated || "N/A",
                  created: temp.snUser.created || "N/A",
                  deleteID: temp.id,
                });
              });
              finalData.sort((a, b) => (a.username > b.username ? 1 : -1));

              this.setState({
                ...this.state,
                userID: this.props.dataItem.id,
                gridData: {
                  data: finalData,
                  total: finalData.length,
                },
                initialGridData: {
                  data: finalData,
                  total: finalData.length,
                },
                clientUsers: clientUsers,
                valueUserName: "",

                visibleClients: result[0].finalUsers || [],
                finalUserClients: result[0].finalUsers || [],
                initialFinalUserClients: result[0].finalUsers || [],
                blocking: false,
              });
            } else if (responseData && responseData?.data?.length === 1) {
              let finalData = [];
              _.forEach(responseData.data, (temp) => {
                finalData.push({
                  id: temp.userID,
                  username: temp.snUser.username,
                  userRealName: temp.snUser.userRealName || "N/A",
                  email: temp.snUser.email || "N/A",
                  bugify_token: temp.snUser.bugify_token || "N/A",
                  last_login_date: temp.snUser.last_login_date || "N/A",
                  last_password_change:
                    temp.snUser.last_password_change || "N/A",
                  lastUpdated: temp.snUser.lastUpdated || "N/A",
                  created: temp.snUser.created || "N/A",
                  deleteID: temp.id,
                });
              });
              finalData.sort((a, b) => (a.username > b.username ? 1 : -1));

              this.setState({
                ...this.state,
                userID: this.props.dataItem.id,
                gridData: {
                  data: [finalData[0]],
                  total: 1,
                },
                initialGridData: {
                  data: [finalData[0]],
                  total: 1,
                },
                clientUsers: clientUsers,
                valueUserName: "",
                visibleClients: result[0].finalUsers || [],
                finalUserClients: result[0].finalUsers || [],
                initialFinalUserClients: result[0].finalUsers || [],
                blocking: false,
              });
            } else {
              this.setState({
                ...this.state,
                userID: this.props.dataItem.id,
                gridData: {
                  data: [],
                  total: 0,
                },
                initialGridData: {
                  data: [],
                  total: 0,
                },
                clientUsers: clientUsers,
                valueUserName: "",
                visibleClients: result[0].finalUsers || [],
                finalUserClients: result[0].finalUsers || [],
                initialFinalUserClients: result[0].finalUsers || [],
                blocking: false,
              });
            }
          }
        );
      }
    } catch (e) {
      let message = e.response.data.error.message;
      if (e?.response?.data?.error?.message.includes("unique constraint")) {
        message =
          "Duplicate id detected because the vessel you are trying to attach already exists.Please try with a new vessel.";
      }

      this.setState({
        ...this.state,
        visibleWindow: true,
        windowError: true,
        windowErrorMessage: message || "Please try again",
      });
    }
  };

  closeErrorModal = () => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      errorModalContent: "",
    });
  };

  handleErrorClose = (loginError) => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      openErrorModalContent: "",
    });
  };

  openErrorModal = (e) => {
    this.setState({
      ...this.state,
      openErrorModal: true,
      errorModalContent:
        (e.response.data && e.response.data.error.message) ||
        "Please try again",
    });
  };

  handleRowClick = (event) => {
    if (!this.clickTimer) {
      // First click
      this.clickTimer = setTimeout(() => {
        // Single click action
        this.clickTimer = null; // Reset the timer
      }, 200); // Set an appropriate delay for detecting double-click
    } else {
      // Second click
      clearTimeout(this.clickTimer); // Cancel the single click action
      this.clickTimer = null; // Reset the timer
      // Double-click action

      const rowData = event.dataItem;
      const recordId = rowData.id;

      let final = "http://localhost:5002";
      let environmentLocation =
        window && window.location && window.location.hostname;
      if (environmentLocation === "dev2.swift-navigator.com") {
        final = "https://dev2.swift-navigator.com";
      } else if (environmentLocation === "uat2.swift-navigator.com") {
        final = "https://uat2.swift-navigator.com";
      } else if (environmentLocation === "prod2.swift-navigator.com") {
        final = "https://prod2.swift-navigator.com";
      }
      const recordUrl = `${final}/app/master/users/all/${recordId}`;
      // Open the URL in a new browser tab
      window.open(recordUrl, "_blank");
    }
  };

  render() {
    const dataItem = this.props.dataItem;
    const { gridData } = this.state;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <div
          style={{
            width: "80%",
            marginBottom: "20px",
          }}
        >
          <h4>NAME : {dataItem && dataItem.relation_name}</h4>
          {this.state.openErrorModal ? (
            <Error style={{ color: "red" }}>
              <h3>{this.state.errorModalContent || ""}</h3>
            </Error>
          ) : null}

          <Grid
            {...gridData}
            onRowClick={this.handleRowClick}
            filterable={false}
            style={{ className: "grid-no-select" }}
            sortable={true}
            resizable
            editField={this.editField}
            onItemChange={this.itemChange}
            pageable={false}
          >
            {/* <GridToolbar>
              <GridContainer xs={12} justify={"flex-start"} direction={"row"}>
                <Tooltip
                  justify={"flex-start"}
                  placement="top"
                  title={"Add User"}
                >
                  <IconButton>
                    <Fab size="medium">
                      <AddIcon onClick={this.toggleWindow}></AddIcon>
                    </Fab>
                  </IconButton>
                </Tooltip>
              </GridContainer>
            </GridToolbar> */}

            {/* <Column
              cell={this.CommandCell}
              title="Actions"
              filterable={false}
              width="200px"
            /> */}
            {this.renderGridColumns(dataItem)}
          </Grid>
        </div>
        {this.state.visibleWindow && (
          <Window
            title={`Attach user to the client below`}
            onClose={this.toggleWindow}
            width={550}
            height={350}
            initialTop={10}
            initialLeft={20}
          >
            <div>
              <br />
              <fieldset style={{ borderColor: "rgba(13, 88, 105, 1)" }}>
                <br />
                <legend>{`Client Name: ${dataItem.relation_name}`}</legend>
                <div>
                  <AutoComplete
                    style={{ width: "450px" }}
                    data={this.state.user_names.map((temp) => temp.username)}
                    value={this.state.valueUserName}
                    label={"Username"}
                    // name={'relation_name'}
                    onChange={(e) => this.onChange(e)}
                    listNoDataRender={this.listNoDataRender}
                    // placeholder="Search client name"
                  />
                </div>
                <br />
                <br />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <button
                    style={{ justifyContent: "flex-end" }}
                    type="button"
                    className="k-button k-primary"
                    onClick={(e) => this.saveUserToClient(e)}
                    disabled={this.state.valueUserName !== "" ? false : true}
                  >
                    Save
                  </button>
                </div>
              </fieldset>
              {this.state.windowError ? (
                <Error style={{ color: "red" }}>
                  <h3>{this.state.windowErrorMessage || ""}</h3>
                </Error>
              ) : null}
            </div>
          </Window>
        )}
      </div>
    );
  }
}

export default ClientToUserExpanded;
