export const invoiceSchema = () => [
  {
    field: "id",
    title: "ID",
    visible: true,
    minWidth: 240,
    sortable: true,
    filterable: false,
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
  },
  {
    field: "userRealName",
    title: "User",
    visible: true,
    minWidth: 320,
    sortable: true,
    filterable: false,
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
  },
  {
    field: "obj_before",
    title: "Action",
    visible: true,
    minWidth: 240,
    sortable: true,
    filterable: false,
    noDisabledColumn: false,
    notShownInTableColumnSelection: false,
  },
  {
    field: "timestamp",
    title: "Datetime",
    visible: true,
    minWidth: 240,
    sortable: true,
    filterable: false,
  },
  {
    field: "vessel_name",
    title: "Vessel",
    visible: true,
    minWidth: 320,
    sortable: true,
    filterable: false,
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
  },
  {
    field: "client_name",
    title: "Client",
    visible: true,
    minWidth: 380,
    sortable: true,
    filterable: false,
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
  },
];
