import React from 'react';
import {
    Router,
    Switch
} from 'react-router-dom';
import history from './lib/reactRouterHelpers/history';
import MainLayout from "./layouts/MainLayout";
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import './App.scss';
import {Provider} from "react-redux";
import {store} from './store/configureStore';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#4ac2d0'
        },
        secondary: {
            main: '#4ac2d0'
        }
    }
});

const Application = () => {
    return (
        <Provider store={store}>
            <Router history={history}>
                <MuiThemeProvider theme={theme}>
                    <Switch>
                        <MainLayout/>
                    </Switch>
                </MuiThemeProvider>
            </Router>
        </Provider>
    );
}

export default Application



