import {serverApi} from "../config";

export const GenericCount = (specific, options) => {

  try {

    const url = `snUsers/count`

    const request = serverApi('GET', url, '', '', options)

    return request

  } catch (err) {
    console.log(err)
  }

}
