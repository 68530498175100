import React from "react";
import {Switch, Redirect} from "react-router-dom";
import RouteWrapper from "./Route";

import {getAllRoutes, root} from "./routes";
import compose from "ramda/src/compose";
import {connect} from "react-redux";
import {withRouter} from "react-router";

const Routes = (props) => {

    let allRoutes = getAllRoutes();

    return (
        <Switch>
            {
                allRoutes.filter(x => x.component).map(route => {
                    return (
                        <RouteWrapper
                            user={props.user}
                            exact
                            key={route.id}
                            path={route.path}
                            component={route.component}
                            documentTitle={route.documentTitle}
                        />
                    )
                })
            }
            <RouteWrapper
                user={props.user}
                component={{
                    view: () => <Redirect to={root}/>
                }}
            />
        </Switch>

    );
}

const mapStateToProps = state => {
    return {
        user: state.MainLayoutReducer.user
    }
}

export default compose(
    connect(mapStateToProps, null))
(withRouter(Routes));


