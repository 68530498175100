import { serverApi} from '../config'

export const AbstractEdit = (id, payload, model, options) => {

    try {
        const url = `${model}/${id}`
        const httpVerb = 'PATCH'
        const response = serverApi(`${httpVerb}`, url, '', payload, options)
        return response;
    }
    catch(error) {
        console.log(error)
    }
}
