import React, { Component } from "react";
import { columnsSchema, similaritySchema } from "./schema";
import { MyCommandCell } from "../../CRM/View/myCommandCell";
import _ from "lodash";
import { GridColumn as Column } from "@progress/kendo-react-grid/dist/npm/GridColumn";
import { DropDownCell } from "../../CRM/View/dropDownCell";
import { AbstractDelete, AbstractEdit } from "../../../networking/apiCalls";
import { serverApi } from "../../../networking/config";
import { filterBy } from "@progress/kendo-data-query";
import {
  Dialog,
  DialogActionsBar,
  Window,
} from "@progress/kendo-react-dialogs";
import { Grid, GridToolbar } from "@progress/kendo-react-grid";
import GridContainer from "../../../components/Grid/GridContainer";
import {
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser, faSyncAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import ViewSettings from "../../common/ViewSettings";
import customSelectStyles from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Loader } from "./loader";
import Snackbar from "../../../components/Snackbar/Snackbar";
// import {
//   Notification,
//   NotificationGroup,
// } from "@progress/kendo-react-notification";
// import { Fade } from "@progress/kendo-react-animation";
import {
  showCreatorOnTable,
  removeUnwantedKeyValuesFromArr,
} from "../../../lib/GeneralUtils/utils";
// import TooltipCell from '../../common/tooltipCell';
// import { Tooltip as KendoTooltip } from "@progress/kendo-react-tooltip";
import DropdownFilterCell from "../../common/dropdownFilterCell";

export default class Suppliers extends Component {
  editField = "inEdit";
  CommandCell;

  constructor(props) {
    super(props);

    this.state = {
      similarityData: [],
      similarityColumns: (similaritySchema && similaritySchema()) || [],
      deleteVisible: false,
      visibleWindow: false,
      isDropOpened: false,
      columns: (columnsSchema && columnsSchema()) || [],
      initialColumns: (columnsSchema && columnsSchema()) || [],
      gridData: {
        data: [],
        total: 0,
      },
      initialGridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 100,
        skip: 0,
      },
      openErrorModal: false,
      errorModalContent: "",
      delay: 400,
      loading: false,
      selectedRowIndex: 0,
      selectedItem: null,
      googleOptions: [],
      expandedRowIndex: null,

      warning: false,
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.disableSupplier,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      hasDisable: true,
      showSecondAction: true,
    });
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    if (this.props.match.params.id !== "" && id) {
      let tempFilters = {
        filter: {
          logic: "and",
          filters: [{ field: "id", operator: "contains", value: `${id}` }],
        },
        skip: 0,
        take: 100,
      };
      window.localStorage.setItem("finalParams", JSON.stringify(tempFilters));
    }

    let isFirstLoad = true;
    window.localStorage.setItem("isFirstLoad", isFirstLoad);

    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));

    if (hasFinalParams) {
      this.setState({
        ...this.state,
        dataState: hasFinalParams,
      });
    }

    if (hasChangedColumns) {
      this.setState({
        ...this.state,
        columns: hasChangedColumns,
      });
    }
  }

  componentWillUnmount() {
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));
    let finalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    let hasExpandedId = JSON.parse(window.localStorage.getItem("expandeId"));
    let hasExportFilter = window.localStorage.getItem("exportFilter");

    if (hasChangedColumns) {
      window.localStorage.removeItem("cols");
    }
    if (finalParams) {
      window.localStorage.removeItem("finalParams");
    }
    if (hasExpandedId) {
      window.localStorage.removeItem("expandeId");
    }
    if (hasExportFilter) {
      window.localStorage.removeItem("exportFilter");
    }
  }

  dataStateChange = (e) => {
    const reqGridParams = e.data;
    window.localStorage.setItem("finalParams", JSON.stringify(reqGridParams));

    this.setState({
      ...this.state,
      dataState: reqGridParams,
    });
  };

  dataReceived = (gridData, reqGridParams) => {
    const finalData = showCreatorOnTable(gridData);
    if (finalData?.data?.length > 0) {
      if (this.props.match.params.id !== "" && this.props.match.params.id) {
        let temp =
          finalData &&
          finalData.data &&
          finalData.data.filter((x) => x.verified_flag === false);
        if (temp.length > 0) {
          this.setState(
            {
              ...this.state,
            },
            () => {
              this.onToggle("warning");
            }
          );
        }
      }
    }

    this.setState({
      ...this.state,
      gridData: finalData,
      initialGridData: finalData,
      dataState:
        reqGridParams === !_.isEmpty(reqGridParams)
          ? reqGridParams
          : this.state.dataState,
    });
  };

  renderGridColumns = () => {
    let selectionCol = [];
    let normalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.field === "relation_country") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              filterCell={(props) => {
                return (
                  <>
                    <DropdownFilterCell
                      {...props}
                      filterable={true}
                      width="150px"
                      data={(this.props.countryCodes || [])
                        .map((x) => x.alpha_2 + "," + x.name)
                        .sort((a, b) => (a > b ? 1 : -1))}
                      hasMultipleValues={true}
                    />
                  </>
                );
              }}
              cell={(props) => {
                let data = (this.props.countryCodes || [])
                  .map((x) => ({
                    text: x.alpha_2 + "," + x.name,
                    value: x.alpha_2,
                  }))
                  .sort((a, b) => (a.text > b.text ? 1 : -1));
                return (
                  <>
                    <DropDownCell
                      {...props}
                      filterable={true}
                      width="210px"
                      data={data}
                    />
                  </>
                );
              }}
            />
          );
        } else if (temp.field === "state") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              filterCell={(props) => {
                return (
                  <>
                    <DropdownFilterCell
                      {...props}
                      width="120px"
                      data={(this.props.usaStates || [])
                        .map((x) => x.lov_value_1)
                        .filter((x) => x)
                        .sort((a, b) => (a > b ? 1 : -1))}
                    />
                  </>
                );
              }}
              cell={(props) => {
                let stateData = (this.props.usaStates || [])
                  .map((x) => ({
                    text: x.lov_value_1,
                    value: x.lov_value_1,
                  }))
                  .filter((x) => x)
                  .sort((a, b) => (a.text > b.text ? 1 : -1))
                  .filter((obj) => obj.text !== null && obj.value !== null);
                return (
                  <>
                    <DropDownCell
                      {...props}
                      width="100px"
                      filterable={true}
                      data={stateData}
                    />
                  </>
                );
              }}
            />
          );
        } else if (temp.field === "verified_flag") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              filterCell={(props) => {
                return (
                  <>
                    <DropdownFilterCell
                      {...props}
                      width="120px"
                      data={[
                        { text: "Yes", value: true },
                        { text: "No", value: false },
                      ].map((x) => x.text)}
                    />
                  </>
                );
              }}
              cell={(props) => {
                const color =
                  props.dataItem.verified_flag === true
                    ? "#16b3169c"
                    : props.dataItem.verified_flag === false
                    ? "#f317008a"
                    : null;

                const style = {
                  color: color,
                  width: "150px",
                };
                return (
                  <>
                    <DropDownCell
                      {...props}
                      style={style}
                      filterable={false}
                      data={[
                        { text: "Yes", value: true },
                        { text: "No", value: false },
                      ].sort((a, b) => (a.text > b.text ? 1 : -1))}
                    />
                  </>
                );
              }}
            />
          );
        } else if (temp.isDropdown === "yes") {
          return (
            <Column
              sortable={temp.sortable}
              locked={temp.locked}
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              cell={(props) => {
                let field = props.field;
                switch (field) {
                  case "client_flag":
                  case "verified_flag":
                  case "supplier_flag":
                  case "agent_flag":
                  case "wh_flag":
                  case "location_flag":
                  case "transport_flag":
                  case "sm_agent_flag":
                  case "sm_vendor_flag":
                  case "seaport_flag":
                  case "airport_flag":
                  case "stocktbc_visible":
                    return (
                      <>
                        <DropDownCell
                          {...props}
                          filterable={true}
                          width="150px"
                          data={[
                            { text: "true", value: true },
                            { text: "false", value: false },
                          ]}
                        />
                      </>
                    );

                  default:
                    break;
                }
              }}
            />
          );
        } else {
          return (
            <Column
              sortable={temp.sortable}
              locked={temp.locked}
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={
                temp.field === "id" ||
                temp.field === "ar_account" ||
                temp.field === "ap_account" ||
                temp.editable
                  ? false
                  : true
              }
            />
          );
        }
      });
    return [...selectionCol, ...normalCols];
  };

  handleColumnsChange = (event) => {
    let columns = this.state.columns;

    let oldColumns = columns.filter((c) => c.visible).map((c) => c.field);
    let newColumns = event.target.value;

    let unique1 = oldColumns.filter((c) => newColumns.indexOf(c) === -1);
    let unique2 = newColumns.filter((c) => oldColumns.indexOf(c) === -1);

    const columnLastUpdated = unique1.concat(unique2)[0];

    let index = columns.findIndex(
      (column) => column.field === columnLastUpdated
    );
    columns[index].visible = !columns[index].visible;

    this.setState(
      {
        ...this.state,
        columns: columns,
      },
      () => {
        let columns = this.state.columns;
        window.localStorage.setItem("cols", JSON.stringify(columns));
      }
    );
  };

  toggleDeleteDialog(dataItem = {}, state) {
    this.setState({
      ...state,
      deleteVisible: !this.state.deleteVisible,
      itemForDelete: dataItem,
    });
  }

  applyNewColumnsOnGrid = (dataItem) => {
    let columns = this.state.initialColumns;
    let newColumns = columns.filter(
      (x) => x.field !== "id" && x.field !== "approval_status"
    );
    newColumns.forEach((x) => (x.minWidth = 290));
    if (dataItem && dataItem.relation_country !== "US") {
      newColumns = newColumns.filter((col) => col.field !== "state");
    }

    if (dataItem && dataItem.relation_country !== "US") {
      newColumns = newColumns.filter((col) => col.field !== "state");
    }
    this.setState(
      {
        ...this.state,
        hideColumns: true,
        columns: newColumns,
        initialColumns: columns,
      },
      () => {}
    );
  };

  enterEdit = (dataItem) => {
    this.applyNewColumnsOnGrid(dataItem);

    let total = this.state.gridData.total;
    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    let columns = this.state.initialColumns;
    if (dataItem && dataItem.relation_country !== "US") {
      columns = columns.filter((col) => col.field !== "state");
    }

    this.setState({
      ...this.state,
      columns: columns,
      gridData: {
        data:
          this.state.gridData &&
          this.state.gridData.data.map((item) =>
            item.id === dataItem.id ? { ...item, inEdit: true } : item
          ),
        total: total,
      },
    });
  };

  remove = async (dataItem) => {
    const data = [...this.state.gridData.data];

    let index = data.findIndex((p) => p === dataItem || p.id === dataItem.id);
    if (index >= 0) {
      data.splice(index, 1);
    }

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let url = "masterEntities";

    try {
      let deleteItemRequest = await AbstractDelete(url, dataItem.id, options);

      if (deleteItemRequest.status === 200) {
        //clear the column filters if grid data is 0 or let them if grid data is >= 1
        if (data.length <= 0) {
          this.setState({
            ...this.state,
            gridData: {
              data: data,
              total: data.length,
            },
            dataState: {
              skip: 0,
              take: 100,
            },
          });
        } else {
          this.setState({
            ...this.state,
            gridData: {
              data: data,
              total: data.length,
            },
          });
        }
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent: e.response.data.error.message || "Please try again",
      });
    }
  };

  disableSupplier = async (dataItem) => {
    this.toggleDeleteDialog(dataItem, this.state);
  };

  add = async (dataItem) => {
    const submitURL = "masterEntities";
    const options = { token: window.localStorage.getItem("access_token") };

    let payload = dataItem;
    payload.client_flag = false;
    payload.transport_flag = false;
    payload.agent_flag = false;
    payload.location_flag = false;
    payload.sm_agent_flag = false;
    payload.sm_vendor_flag = false;
    payload.airport_flag = false;
    payload.wh_flag = false;
    payload.seaport_flag = false;

    const hasSimilarity = await this.checkForSimilar(payload);
    if (!hasSimilarity) {
      dataItem.inEdit = undefined;

      const error1 =
        payload.relation_name === undefined ||
        payload.relation_name === "" ||
        payload.relation_country === undefined ||
        payload.relation_country === "" ||
        payload.relation_postal_code === undefined ||
        payload.relation_postal_code === "";
      const error2 = payload.relation_country === "US" && !payload.state;

      if (error1 || error2) {
        this.setState(
          {
            ...this.state,
            openErrorModal: true,
            errorModalContent: error1
              ? "Required fields in order to create a new supplier: Name, Postal, Country and City"
              : "You need to provide the state field in order to create a supplier",
          },
          () => {
            setTimeout(() => {
              this.cancel(dataItem);
              this.discard(dataItem);
              this.handleErrorClose();
            }, 4000);
            return null;
          }
        );
      } else {
        delete payload.inEdit;
        delete payload.inAdd;

        const createRequest = serverApi(
          "POST",
          `${submitURL}`,
          "",
          payload,
          options
        );
        createRequest
          .then(async (createResponse) => {
            this.setState(
              {
                ...this.state,
                inAdd: false,
                deleteVisible: false,
                successMessage: "The supplier was successfully created",
                openSuccessMessage: true,
              },
              () => {
                setTimeout(() => {
                  this.handleSuccessSnackbar();
                  this.refreshGridDataAfterCrud();
                }, 4000);
              }
            );
          })
          .catch((createError) => {
            this.setState(
              {
                ...this.state,
                openErrorModal: true,
                errorModalContent:
                  createError.response.data.error.message || "Please try again",
              },
              () => this.discard(dataItem, true)
            );
          });
      }
    } else {
      this.toggleSimilarityWindow();
    }
  };

  toggleSimilarityWindow = () => {
    this.setState({
      similarityWindow: !this.state.similarityWindow,
    });
  };

  discard = (dataItem, hasError = false) => {
    let data = [...this.state.gridData.data];
    const total = this.state.gridData.total;

    if (hasError) {
      data[0].inEdit = true;

      this.setState(
        {
          ...this.state,
          gridData: {
            data: data,
            total: total,
          },
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 3000);
        }
      );
    } else {
      this.removeItem(data, dataItem);

      this.setState(
        {
          ...this.state,
          gridData: {
            data: data,
            total: total,
          },
          visible: false,
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 3000);
        }
      );
    }
  };

  checkForSimilar = async (payload) => {
    try {
      const findCountryRecord = this.props.countryCodes.filter(
        (x) => x.alpha_2 === payload.relation_country
      );
      if (findCountryRecord.length) {
        const request = await serverApi(
          "GET",
          `masterEntities/findSimilarNames?fieldname=relation_name&name=${payload.relation_name}&country=${findCountryRecord[0].name}&flags=supplier_flag`,
          "",
          "",
          {
            token: window.localStorage.getItem("access_token"),
          }
        );
        if (request.status === 200) {
          if (request.data.result.length) {
            //todo handle > 80% similatiry
            let data = request.data.result || [];
            let finalData = [];
            data.forEach((x) => {
              let similarity =
                x.SIMILARITY && parseInt(x.SIMILARITY.split("%")[0]);
              if (similarity > 80) finalData.push(x);
            });

            if (finalData.length > 0) {
              this.setState({
                ...this.state,
                similarityData: finalData,
              });
              return true;
            } else {
              return false;
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            "An error occured, please try again and if the contact persist contact the administration",
        },
        () => this.discard({}, true)
      );
    }
  };

  //todo verified_flag alter when final prod2 flow is released
  update = async (dataItem) => {
    const data = [...this.state.gridData.data];
    const updatedItem = { ...dataItem, inEdit: undefined };
    this.updateItem(data, updatedItem);

    const editPayload = dataItem;
    delete editPayload.expanded;
    delete editPayload.inEdit;
    delete editPayload.inAdd;

    editPayload.client_flag = dataItem.client_flag || false;
    editPayload.transport_flag = dataItem.transport_flag || false;
    editPayload.agent_flag = dataItem.agent_flag || false;
    editPayload.location_flag = dataItem.location_flag || false;
    editPayload.sm_agent_flag = dataItem.sm_agent_flag || false;
    editPayload.sm_vendor_flag = dataItem.sm_vendor_flag || false;
    editPayload.airport_flag = dataItem.airport_flag || false;
    editPayload.wh_flag = dataItem.wh_flag || false;
    editPayload.seaport_flag = dataItem.seaport_flag || false;

    editPayload.verified_flag = dataItem.verified_flag || false;

    editPayload.paymentTerms =
      dataItem.paymentTerms === "" ? null : dataItem.paymentTerms;

    if (editPayload.relation_country !== "US") editPayload.state = null;

    editPayload.paymentTerms =
      dataItem.paymentTerms === "" ? null : dataItem.paymentTerms;

    const error1 =
      editPayload.relation_name === undefined ||
      editPayload.relation_name === "" ||
      editPayload.relation_country === undefined ||
      editPayload.relation_country === "" ||
      editPayload.relation_postal_code === undefined ||
      editPayload.relation_postal_code === "";

    const error2 = editPayload.relation_country === "US" && !editPayload.state;

    if (error1 || error2) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent: error1
            ? "Please provide Name,Postal and Country attributes in order to edit a supplier"
            : "You need to provide the state field in order to create a client",
        },
        () => {
          setTimeout(() => {
            this.cancel(dataItem);
            this.handleErrorClose();
          }, 4000);
        }
      );
    } else {
      try {
        removeUnwantedKeyValuesFromArr(editPayload);
        const editRequest = await AbstractEdit(
          dataItem.id,
          editPayload,
          "masterEntities",
          {
            token: window.localStorage.getItem("access_token"),
          }
        );
        const countRequest = await serverApi(
          "GET",
          'masterEntities/count?where={"supplier_flag":true}',
          "",
          "",
          {
            token: window.localStorage.getItem("access_token"),
          }
        );

        if (editRequest.status === 200 && countRequest) {
          this.setState(
            {
              ...this.state,
              gridData: {
                data: data,
                total: countRequest.data.count,
              },
              deleteVisible: false,
              successMessage: "The supplier was successfully updated.",
              openSuccessMessage: true,
            },
            () => {
              setTimeout(() => {
                this.handleSuccessSnackbar();
                this.refreshGridDataAfterCrud();
              }, 3000);
            }
          );
        }
      } catch (e) {
        this.setState(
          {
            ...this.state,
            openErrorModal: true,
            errorModalContent:
              e.response.data.error.message || "Please try again",
          },
          () => this.discard(dataItem, true)
        );
      }
    }
  };

  cancel = (dataItem) => {
    if (
      dataItem.relation_name === undefined ||
      dataItem.relation_country === undefined ||
      dataItem.relation_postal_code === undefined
    ) {
      const data =
        this.state.initialGridData && this.state.initialGridData.data;
      const total = this.state.initialGridData.total;

      this.setState({
        ...this.state,
        gridData: {
          data: data,
          total: total,
        },
        errorModalContent:
          this.state.errorModalContent !== ""
            ? ""
            : this.state.errorModalContent,
        openErrorModal:
          this.state.openErrorModal === true
            ? false
            : this.state.errorModalContent,
      });
    } else {
      const originalItem =
        this.state.initialGridData &&
        this.state.initialGridData.data.find((p) => p.id === dataItem.id);
      if (originalItem) {
        originalItem.inEdit = false;

        const data =
          this.state.gridData &&
          this.state.gridData.data.map((item) =>
            item.id === originalItem.id ? originalItem : item
          );
        const total = this.state.gridData.total;

        this.setState({
          ...this.state,
          gridData: {
            data: data,
            total: total,
          },
        });
      }
    }
  };

  addNew = () => {
    let checkForEditDato = this.state.gridData.data || [];
    const found =
      checkForEditDato && checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    const newDataItem = {
      inEdit: true,
      supplier_flag: true,
      verified_flag: true,
      inAdd: true,
    };

    this.setState({
      ...this.state,
      gridData: {
        data: [newDataItem, ...this.state.gridData.data],
        total: this.state.gridData.total,
      },
      visible: true,
    });
  };

  updateItem = (data, item) => {
    let index = data.findIndex(
      (p) => p === item || (item.id && p.id === item.id)
    );
    if (index >= 0) {
      data[index] = { ...item };
    }
  };

  applyInitialColumnsOnGrid = () => {
    let initialColumns = this.state.initialColumns;
    let newColumns = initialColumns;
    this.setState({
      ...this.state,
      hideColumns: false,
      columns: newColumns,
    });
  };

  itemChange = (event) => {
    const { field, value, dataItem } = event;

    if (
      field === "supplier_flag" ||
      (field === "verified_flag" && dataItem.inAdd)
    ) {
      return;
    }

    if (field === "relation_country" && value === "US") {
      this.applyInitialColumnsOnGrid();
    } else if (field === "relation_country" && value !== "US") {
      this.setState({
        ...this.state,
        columns:
          (columnsSchema && columnsSchema()).filter(
            (col) => col.field !== "state"
          ) || [],
      });
    }

    const total = this.state.gridData.total;
    const data =
      this.state.gridData &&
      this.state.gridData.data.map((item) =>
        item.id === event.dataItem.id
          ? { ...item, [event.field]: event.value }
          : item
      );

    this.setState({
      gridData: {
        data: data,
        total: total,
      },
    });
  };

  removeItem(data, item) {
    let index = data.findIndex((p) => p === item || p.id === item.id);
    if (index >= 0) {
      data.splice(index, 1);
    }
  }

  onClearFilters = () => {
    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    this.props.history.push(`/app/master/suppliers`);

    let init = JSON.stringify({ take: 100, skip: 0 });
    if (hasFinalParams && hasFinalParams.filter)
      window.localStorage.setItem("finalParams", init);

    this.dataStateChange({ data: { take: 100, skip: 0 } });
  };

  handleErrorClose = (loginError) => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      openErrorModalContent: "",
    });
  };

  disableAction = async () => {
    let dataItem = this.state.itemForDelete;
    let editPayload = {};
    editPayload.supplier_flag = false;

    try {
      const editRequest = await AbstractEdit(
        dataItem.id,
        editPayload,
        "masterEntities",
        {
          token: window.localStorage.getItem("access_token"),
        }
      );

      if (editRequest) {
        this.setState(
          {
            ...this.state,
            deleteVisible: false,
            successMessage: "The supplier was successfully disabled.",
            openSuccessMessage: true,
          },
          () => {
            setTimeout(() => {
              this.handleSuccessSnackbar();
            }, 3000);
          }
        );
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent: "Please refresh",
      });
    }
  };

  rowClick = (event) => {
    // window.localStorage.setItem('expandeId', event.dataItem.id)
    //
    // //if add new vessel is true then expand cannot be done !
    // if (this.state.gridData.data[0].inEdit === true) {
    //     return
    // } else {
    //     event.dataItem.expanded = !event.dataItem.expanded;
    // }
    //
    // this.forceUpdate();
  };

  handleSuccessSnackbar = () => {
    this.setState({
      ...this.state,
      successMessage:
        this.state.openSuccessMessage === true ? this.state.successMessage : "",
      openSuccessMessage: !this.state.openSuccessMessage,
    });
  };

  toggleWindow = (e) => {
    const data = this.state.initialGridData.data;
    let countriesInit = this.state.countries;
    if (this.state.countries.length <= this.state.initialCountries.length) {
      countriesInit = this.state.initialCountries;
    }

    this.setState({
      visibleWindow: !this.state.visibleWindow,
      gridData: {
        data: [...data],
        total: 10,
      },
      finalGoogleData: [],
      countries: countriesInit,
      relation_country: "",
      relation_name: "",
    });
  };

  filterChange = (event) => {
    let filteredResults = this.filterData(event.filter);

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.setState({
        countries:
          filteredResults !== undefined
            ? filteredResults
            : this.state.initialCountries,
        loading: false,
      });
    }, this.state.delay);

    this.setState({
      ...this.state,
      loading: true,
    });
  };

  filterData(filter) {
    if (filter.value === "") {
      this.setState({
        countries: this.state.initialCountries,
      });
      return;
    } else {
      const data = this.state.initialCountries;
      filter.operator = "startswith";
      let filteredResults = filterBy(data, filter);
      return filteredResults;
    }
  }

  onDialogInputChange = (event) => {
    let target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.props ? target.props.name : target.name;

    this.setState({
      ...this.state,
      gridData: {
        ...this.state.gridData,
        [name]: value,
      },
      [name]: value,
    });
  };

  handleDropDownChange = (event) => {
    let target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.props ? target.props.name : target.name;

    this.setState({
      ...this.state,
      isDropOpened: !this.state.isDropOpened,
      gridData: {
        ...this.state.gridData,
        [name]: value.id,
      },
      [name]: value.id,
    });
  };

  exportToCsv = async (state, props) => {
    const count = state.gridData.total;
    const where = window.localStorage.getItem("exportFilter") || {};
    const numberOfCalls = Math.min(
      Math.round(count / 1000) + 1,
      5 // Limit to 5000
    );
    let finalSort = this.state.dataState?.sort?.length
      ? this.state.dataState.sort[0].field +
        " " +
        this.state.dataState.sort[0].dir
      : "id asc";

    const promises = [];
    for (let i = 0; i < numberOfCalls; i++) {
      promises.push(
        this.getPromise({
          skip: i * 1000,
          limit: 1000,
          where: !_.isEmpty(where) ? JSON.parse(where) : {},
          order: [finalSort],
        })
      );
    }

    try {
      const responses = await Promise.all(promises);
      let allData = [];
      responses.forEach((resp) => {
        allData = [...allData, ...resp.data];
      });
      if (allData.length > 0) {
        this.setState({
          ...this.state,
          exportData: allData,
        });
        // this._export.save(allData, this.state.columns);
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent: e.response.data.error.message || "Please try again",
      });
    }
  };

  getPromise = (filter) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    return new Promise((resolve, reject) => {
      const masterEntityRequest = serverApi(
        "GET",
        `masterEntities?filter=${JSON.stringify(filter)}`,
        "",
        "",
        options
      );
      masterEntityRequest
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getExportData = async () => {
    await this.exportToCsv(this.state, this.props);
  };

  selectionChange = (event) => {
    (this.state.finalGoogleData || []).map((item, index) => {
      if (item.relation_tel === event.dataItem.relation_tel) {
        item.selected = !event.dataItem.selected;

        // let selectedArray = JSON.parse(window.localStorage.getItem('selectedItems')) || []

        if (item.selected === false) {
          // selectedArray = selectedArray.filter(temp => (temp.box_id !== item.box_id));
          // selectedArray.push(item)
        } else if (item.selected === true) {
          // selectedArray.push(item)
        }
        // window.localStorage.setItem('selectedItems', JSON.stringify(selectedArray))
      } else {
        let finalToggleGoodleData = this.state.finalGoogleData;
        finalToggleGoodleData[index].selected = false;

        this.setState({
          ...this.state,
          finalGoogleData: finalToggleGoodleData,
        });
      }

      this.selectGoogleOption(item, index);
      return item;
    });

    // this.setState({
    //         ...this.state,
    //         blocking: false,
    //         gridData: {
    //             data: data
    //         }
    //
    // })
  };

  onToggle = (flag) => this.setState({ [flag]: !this.state[flag] });

  refreshGridDataAfterCrud = async () => {
    let token = window.localStorage.getItem("access_token");
    let options = {
      token: token,
    };
    let refreshParams = window.localStorage.getItem("refreshParams");
    refreshParams = JSON.parse(refreshParams);

    let responseData = await serverApi(
      "GET",
      `masterEntities`,
      refreshParams,
      "",
      options
    );
    const finalData = showCreatorOnTable(responseData);

    let count;
    const countMasterEntity = await serverApi(
      "GET",
      `masterEntities/count?where=${JSON.stringify(
        refreshParams.filter.where
      )}`,
      "",
      "",
      options
    );
    if (countMasterEntity) {
      count = countMasterEntity.data.count;
    }

    let sendObj = {
      gridData: {
        data: finalData.data,
        total: count,
      },
    };

    this.dataReceived(sendObj.gridData, null);
  };

  rowRender = (trElement, dataItem) => {
    const { verified_flag } = dataItem.dataItem;
    let color = "";
    if (verified_flag === true) {
      color = "#62b715";
    } else if (verified_flag === false) {
      color = "#ed7070";
    } else {
      color = "#0d5869";
    }

    const trProps = {
      ...trElement.props,
      onContextMenu: (e) => {
        e.preventDefault();
      },
      style: {
        color: color,
      },
    };

    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  renderGridColumnsForSimilarity = () => {
    let selectionCol = [];
    let normalCols = [];

    _.forEach(this.state.similarityColumns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            width="65px"
            filterable={false}
            headerSelectionValue={
              this.state.finalGoogleData.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.similarityColumns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }
        return (
          <Column
            sortable={temp.sortable}
            locked={temp.locked}
            field={temp.field}
            filterable={temp.filterable}
            title={temp.title}
            width={temp.minWidth || "auto"}
            filter={temp.filter}
            visible={temp.visible}
            minGridWidth={"400"}
            editable={
              temp.field === "id" ||
              temp.field === "ar_account" ||
              temp.field === "ap_account" ||
              temp.editable
                ? false
                : true
            }
          />
        );
      });

    return [...selectionCol, ...normalCols];
  };

  bypassCheck = () => {
    const submitURL = "masterEntities";
    const options = { token: window.localStorage.getItem("access_token") };
    const dataItem = this.state.gridData.data.filter((x) => x.inEdit)[0] || {};

    let payload = dataItem;
    payload.client_flag = false;
    payload.transport_flag = false;
    payload.agent_flag = false;
    payload.location_flag = false;
    payload.sm_agent_flag = false;
    payload.sm_vendor_flag = false;
    payload.airport_flag = false;
    payload.wh_flag = false;
    payload.seaport_flag = false;

    dataItem.inEdit = undefined;
    delete payload.inEdit;
    delete payload.inAdd;

    const error1 =
      payload.relation_name === undefined ||
      payload.relation_name === "" ||
      payload.relation_country === undefined ||
      payload.relation_country === "" ||
      payload.relation_postal_code === undefined ||
      payload.relation_postal_code === "";
    const error2 = payload.relation_country === "US" && !payload.state;

    if (error1 || error2) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent: error1
            ? "Required fields in order to create a new supplier: Name, Postal, Country and City"
            : "You need to provide the state field in order to create a supplier",
        },
        () => {
          setTimeout(() => {
            this.cancel(dataItem);
            this.handleErrorClose();
          }, 4000);
        }
      );
    }
    delete payload.inEdit;
    delete payload.inAdd;

    const createRequest = serverApi(
      "POST",
      `${submitURL}`,
      "",
      payload,
      options
    );
    createRequest
      .then(async (createResponse) => {
        this.setState(
          {
            ...this.state,
            similarityWindow: false,
            inAdd: false,
            deleteVisible: false,
            successMessage: "The supplier was successfully created.",
            openSuccessMessage: true,
          },
          () => {
            setTimeout(() => {
              this.handleSuccessSnackbar();
              this.refreshGridDataAfterCrud();
            }, 4000);
          }
        );
      })
      .catch((createError) => {
        this.setState(
          {
            ...this.state,
            similarityWindow: false,
            openErrorModal: true,
            errorModalContent:
              createError.response.data.error.message || "Please try again",
          },
          () => this.discard(dataItem, true)
        );
      });
  };

  render() {
    const { similarityData, similarityWindow } = this.state;

    const { gridData, columns } = this.state;
    const hasEditedItem = gridData?.data?.some((p) => p.inEdit);

    let checkForEditDato = this.state.gridData.data || [];
    const found =
      checkForEditDato && checkForEditDato.some((item) => item.inEdit === true);
    let hasColumnFilters = true;
    if (found) {
      hasColumnFilters = false;
    }

    // const position = {
    //   topCenter: {
    //     top: 0,
    //     left: "50%",
    //     transform: "translateX(-50%)",
    //   }
    // };

    const { hasBackofficeRead } = this.props;

    return (
      <div>
        {/* <NotificationGroup
         style={position.topCenter}
        >
         <Fade direction={this.state.warning ? "up" : "down"}>
           {this.state.warning && (
             <Notification
               type={{style: "warning", icon: true}}
                closable={true}
               onClose={() => this.setState({warning: false})}
             >
               <span
                 style={{fontSize: '14px'}}>A new supplier was created from v2.Please verify the record below.</span>
              </Notification>
            )}
          </Fade>
        </NotificationGroup> */}

        {/* <button className="k-button" onClick={() => this.onToggle("warning")}>
         {(this.state.warning ? "hide " : "show ") + "warning"}
        </button> */}

        {this.state.deleteVisible && (
          <Dialog onClose={() => this.toggleDeleteDialog({}, this.state)}>
            <p style={{ margin: "25px", textAlign: "center" }}>
              Are you sure you want to disable this supplier?
            </p>
            <DialogActionsBar>
              <button
                className="k-button"
                onClick={() => this.toggleDeleteDialog({}, this.state)}
              >
                No
              </button>
              <button className="k-button" onClick={() => this.disableAction()}>
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}

        {/* <KendoTooltip openDelay={100} position="top" anchorElement="target"> */}
        <Grid
          {...this.state.dataState}
          {...this.state.gridData}
          filterable={hasColumnFilters}
          style={{ height: "92vh" }}
          sortable={true}
          resizable
          onRowClick={(e) => this.rowClick(e)}
          editField={this.editField}
          onItemChange={this.itemChange}
          onDataStateChange={this.dataStateChange}
          // rowRender={this.rowRender}
          pageable={{
            buttonCount: 5,
            info: true,
            pageSizes: [100, 200, 500, 1000, 1500],
          }}
        >
          <GridToolbar>
            <GridContainer xs={12} direction={"row"}>
              {!hasBackofficeRead && (
                <Tooltip
                  justify={"flex-start"}
                  placement="top"
                  title={"Add Supplier Manually"}
                >
                  <IconButton onClick={this.addNew}>
                    <FontAwesomeIcon
                      color="#0D5869"
                      onClick={this.addNew}
                      size="1.6x"
                      icon={faPlus}
                    />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip
                justify={"flex-end"}
                placement="top"
                title={"Clear Column Filters"}
              >
                <IconButton
                  disabled={false}
                  aria-label=""
                  onClick={(e) => this.onClearFilters(e)}
                  look="flat"
                >
                  <FontAwesomeIcon
                    color="#0D5869"
                    size="1.6x"
                    icon={faEraser}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip
                justify={"flex-start"}
                placement="top"
                title={"Refresh Data"}
              >
                <IconButton
                  disabled={false}
                  aria-label=""
                  onClick={this.refreshGridDataAfterCrud}
                >
                  <FontAwesomeIcon color="#0D5869" size="1x" icon={faSyncAlt} />
                </IconButton>
              </Tooltip>
              <ViewSettings
                {...this.props}
                export={{
                  data: this.state.exportData || [],
                  exportFunction: this.getExportData,
                  fileName: "Supplier_",
                  columns: columnsSchema(),
                }}
                viewSettings={{
                  type: "",
                }}
                refreshSettings={{
                  data: [],
                }}
              />
              {!this.state.hideColumns ? (
                <div style={{ marginTop: hasBackofficeRead ? "5px" : "5px" }}>
                  <FormControl className={customSelectStyles.selectFormControl}>
                    <InputLabel
                      htmlFor="multiple-select"
                      className={customSelectStyles.selectLabel}
                    />
                    <Select
                      autoWidth={true}
                      multiple
                      value={columns
                        .filter((column) => column.visible)
                        .map((column) => column.field)}
                      onChange={this.handleColumnsChange}
                      MenuProps={{ className: customSelectStyles.selectMenu }}
                      inputProps={{
                        name: "multipleSelect",
                        id: "multiple-select",
                      }}
                      renderValue={(selected) => "Columns"}
                    >
                      {columns
                        .filter((col) => !col.notShownInTableColumnSelection)
                        .map((column) => {
                          let name = column.title;
                          let value = column.field;
                          let isVisible = column.visible;
                          let isDisabled = column.noDisabledColumn;

                          return (
                            <MenuItem
                              style={{ backgroundColor: "white" }}
                              key={name}
                              value={value}
                              disabled={isDisabled}
                            >
                              <Checkbox color={"default"} checked={isVisible} />
                              <ListItemText
                                style={{ marginLeft: "7px" }}
                                primary={name}
                              />
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
              ) : null}
            </GridContainer>
          </GridToolbar>

          {!hasBackofficeRead && (
            <Column
              cell={this.CommandCell}
              title="Actions"
              filterable={false}
              width={hasEditedItem ? "240px" : "230px"}
            />
          )}

          {this.renderGridColumns()}
        </Grid>
        {/* </KendoTooltip> */}

        <Loader
          dataState={this.state.dataState}
          onDataRecieved={this.dataReceived}
          include={["creator"]}
          getURL={"masterEntities"}
          countURL={'masterEntities/count?where={"supplier_flag":true}'}
          view={"suppliers"}
        />

        <Snackbar
          place="tc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"warning"}
          message={this.state.errorModalContent}
          open={this.state.openErrorModal}
          closeNotification={() =>
            this.handleErrorClose(this.state.errorModalContent)
          }
          close
        />

        <Snackbar
          place="tc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"success"}
          message={this.state.successMessage}
          open={this.state.openSuccessMessage}
          // closeNotification={() => this.handleSuccessSnackbar(this.state.successMessage)}
          // close
        />

        {similarityWindow && (
          <Window
            title={"Existing supplier in navigator"}
            onClose={this.toggleSimilarityWindow}
            width={1420}
            height={700}
            style={{ marginLeft: "-100px" }}
          >
            <span>
              <fieldset style={{ borderColor: "rgba(13, 88, 105, 1)" }}>
                <div style={{ display: "contents", flexDirection: "row" }}>
                  <legend>
                    These existing suppliers have similar names.Is this the
                    supplier you want one of them?
                  </legend>
                  <button
                    type="button"
                    className="k-button k-primary"
                    onClick={(e) => {
                      this.bypassCheck();
                    }}
                  >
                    Bypass check and save
                  </button>
                </div>
                <div>
                  <Grid
                    data={similarityData}
                    heigh={"100%"}
                    resizable
                    filterable={false}
                    sortable={false}
                  >
                    {this.renderGridColumnsForSimilarity()}
                  </Grid>
                </div>
              </fieldset>
            </span>
          </Window>
        )}
      </div>
    );
  }
}
