import {serverApi} from '../config'

export const Login = (payload) => {

  try {
    const url = `https://dev.swift-navigator.com:443/api/ApplicationUsers/login`
    const response = serverApi('POST', url, '', payload)

    return response;
  }
  catch(error) {
    console.log(error)
  }
}
