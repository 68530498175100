import React, { Component } from "react";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { Loader } from "./loader";
import { AbstractDelete, AbstractEdit } from "../../../networking/apiCalls";
import _ from "lodash";
import { MyCommandCell } from "../../CRM/View/myCommandCell";
import { DropDownCell } from "../../CRM/View/dropDownCell.js";
import { serverApi } from "../../../networking/config";
import {
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
} from "@material-ui/core";
import customSelectStyles from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import { columnsSchema } from "../View/schema";
import { Checkbox } from "@progress/kendo-react-inputs";
import {
  Dialog,
  DialogActionsBar,
  Window,
} from "@progress/kendo-react-dialogs";
import GridContainer from "../../../components/Grid/GridContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser, faSyncAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import Snackbar from "../../../components/Snackbar/Snackbar";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import axios from "axios";
import { filterBy } from "@progress/kendo-data-query";
import { columnsSchemaForGoogle } from "../../CRM/View/schema";
import ViewSettings from "../../common/ViewSettings";
import ExpandedTabs from "../../common/ExpandedTabs";
import CustomInput from "../../../components/CustomInput/CustomInputSecond";
import { TextArea } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels"; // Import the Error component

import {
  showCreatorOnTable,
  removeUnwantedKeyValuesFromArr,
} from "../../../lib/GeneralUtils/utils";
// import { Tooltip as KendoTooltip } from "@progress/kendo-react-tooltip";
// import TooltipCell from "../../common/tooltipCell";
import DropdownFilterCell from "../../common/dropdownFilterCell";
// import { NAV } from "navigatorsdk";
// import Button from "../../../components/CustomButtons/Button";
// import AddIcon from "@material-ui/icons/Add";
import { formatDatesOnTable } from "../../../lib/GeneralUtils/utils";

class ModelSettingDefinition extends Component {
  editField = "inEdit";
  CommandCell;

  constructor(props) {
    super(props);

    this.state = {
      models: [
        { text: "masterEntity", id: 1 },
        { text: "snUser", id: 2 },
        { text: "domain", id: 3 },
        { text: "masterEntityContact", id: 4 },
      ],
      valueTypes: [
        { text: "boolean", id: 1 },
        { text: "integer", id: 2 },
        { text: "string", id: 3 },
        { text: "object", id: 4 },
      ],
      currentModel: {},
      currentValue: {},
      editFormVisible: false,
      originalDataItem: {},
      addFormVisible: false,
      newRecord: {
        modelName: "",
        settingName: "",
        valueType: "",
        isArray: false,
        options: "",
        lookup: "",
        valueSchema: "",
        notes: "",
      },
      approvalHistoryModal: false,
      similarityData: [],
      deleteVisible: false,
      visibleWindow: false,
      isDropOpened: false,
      columns: (columnsSchema && columnsSchema()) || [],
      initialColumns: (columnsSchema && columnsSchema()) || [],
      gridData: {
        data: [],
        total: 0,
      },
      initialGridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 100,
        skip: 0,
      },
      openErrorModal: false,
      errorModalContent: "",
      delay: 400,
      loading: false,
      selectedRowIndex: 0,
      selectedItem: null,
      googleOptions: [],
      expandedRowIndex: null,
      dataItem: {
        modelName: "",
        settingName: "",
        description: "",
        appliesTo: "",
        valueType: "",
        isArray: false,
        options: "",
        lookup: "",
        valueSchema: "",
        notes: "",
      },
      validationErrors: {},
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.disableRecord,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      hasDisable: false,
      showSecondAction: false,
      showFirstAction: true,
    });
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    if (this.props.match.params.id !== "" && id) {
      let tempFilters = {
        filter: {
          logic: "and",
          filters: [{ field: "id", operator: "contains", value: `${id}` }],
        },
        skip: 0,
        take: 100,
      };
      window.localStorage.setItem("finalParams", JSON.stringify(tempFilters));
    }

    let isFirstLoad = true;
    window.localStorage.setItem("isFirstLoad", isFirstLoad);

    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));

    if (hasFinalParams) {
      this.setState({
        ...this.state,
        dataState: hasFinalParams,
      });
    }

    if (hasChangedColumns) {
      this.setState({
        ...this.state,
        columns: hasChangedColumns,
      });
    }
  }

  componentWillUnmount() {
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));
    if (hasChangedColumns) {
      window.localStorage.removeItem("cols");
    }

    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    if (hasFinalParams) {
      window.localStorage.removeItem("finalParams");
    }

    let hasFlagEnabled = window.localStorage.getItem("flagSet");
    if (hasFlagEnabled) {
      window.localStorage.removeItem("flagSet");
    }

    let hasExportFilter = window.localStorage.getItem("exportFilter");
    if (hasExportFilter) {
      window.localStorage.removeItem("exportFilter");
    }
  }

  dataStateChange = (e) => {
    const reqGridParams = e.data;
    window.localStorage.setItem("finalParams", JSON.stringify(reqGridParams));

    this.setState({
      ...this.state,
      dataState: reqGridParams,
    });
  };

  dataReceived = (gridData, reqGridParams) => {
    const finalData = showCreatorOnTable(gridData);

    if (finalData?.data?.length > 0) {
      if (this.props.match.params.id !== "" && this.props.match.params.id) {
        let temp =
          finalData &&
          finalData.data &&
          finalData.data.filter(
            (x) =>
              x.approval_status === "Pending" ||
              x.approval_status === "Rejected"
          );
        if (temp.length > 0) {
          this.setState(
            {
              ...this.state,
            },
            () => {
              this.onToggle("warning");
            }
          );
        }
      }
    }

    this.setState({
      ...this.state,
      gridData: finalData,
      initialGridData: finalData,
      dataState:
        reqGridParams === !_.isEmpty(reqGridParams)
          ? reqGridParams
          : this.state.dataState, //TODO MAYBE PASS THE final params from local storage for persist the filters on REFRESH PAGE !
    });
  };

  renderGridColumns = () => {
    let selectionCol = [];
    let normalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.field === "approval_status") {
          return (
            <Column
              field={temp.field}
              locked={temp.locked}
              filterable={temp.filterable}
              title={temp.title}
              width={210}
              filter={temp.filter}
              visible={temp.visible}
              // minGridWidth={"400"}
              filterCell={(props) => {
                return (
                  <>
                    <DropdownFilterCell
                      {...props}
                      filterable={true}
                      data={[
                        "Approved",
                        "Pending",
                        "Not approved",
                        "No response",
                      ].sort((a, b) => (a > b ? 1 : -1))}
                    />
                  </>
                );
              }}
              editable={
                temp.field === "id" ||
                temp.field === "createdByForUi" ||
                temp.field === "dateCreatedForUi" ||
                temp.field === "ap_account" ||
                temp.field === "ar_account"
                  ? false
                  : true
              } //id not editable on edit
              cell={(props) => {
                const color =
                  props.dataItem.approval_status === "Approved"
                    ? "#16b3169c"
                    : props.dataItem.approval_status === "Not approved"
                    ? "#f317008a"
                    : null;
                const style = {
                  color: color,
                  width: "150px",
                };
                return (
                  <>
                    <DropDownCell
                      {...props}
                      style={style}
                      filterable={true}
                      data={[
                        { text: "Pending", value: "Pending" },
                        { text: "Not approved", value: "Not approved" },
                        { text: "Approved", value: "Approved" },
                      ].sort((a, b) => (a > b ? 1 : -1))}
                    />
                  </>
                );
              }}
              // cell={cellWithBackGround}
            />
          );
        } else if (temp.field === "relation_country") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              filterCell={(props) => {
                return (
                  <>
                    <DropdownFilterCell
                      {...props}
                      filterable={true}
                      width="210px"
                      data={(this.props.countryCodes || [])
                        .map((x) => x.alpha_2 + "," + x.name)
                        .sort((a, b) => (a > b ? 1 : -1))}
                      hasMultipleValues={true}
                    />
                  </>
                );
              }}
              cell={(props) => {
                let data = (this.props.countryCodes || [])
                  .map((x) => ({
                    text: x.alpha_2 + "," + x.name,
                    value: x.alpha_2,
                  }))
                  .sort((a, b) => (a.text > b.text ? 1 : -1));
                return (
                  <>
                    <DropDownCell
                      {...props}
                      filterable={true}
                      width="210px"
                      data={data}
                    />
                  </>
                );
              }}
            />
          );
        } else if (temp.field === "state") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              filterCell={(props) => {
                return (
                  <>
                    <DropdownFilterCell
                      {...props}
                      filterable={true}
                      width="120px"
                      data={(this.props.usaStates || [])
                        .map((x) => x.lov_value_1)
                        .filter((x) => x)
                        .sort((a, b) => (a > b ? 1 : -1))}
                    />
                  </>
                );
              }}
              cell={(props) => {
                let stateData = (this.props.usaStates || [])
                  .map((x) => ({
                    text: x.lov_value_1,
                    value: x.lov_value_1,
                  }))
                  .filter((x) => x)
                  .sort((a, b) => (a.text > b.text ? 1 : -1))
                  .filter((obj) => obj.text !== null && obj.value !== null);
                return (
                  <>
                    <DropDownCell
                      {...props}
                      filterable={true}
                      width="100px"
                      data={stateData}
                    />
                  </>
                );
              }}
            />
          );
        } else if (temp.isDropdown === "yes") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={
                temp.field === "id" ||
                temp.field === "createdByForUi" ||
                temp.field === "dateCreatedForUi" ||
                temp.field === "ap_account" ||
                temp.field === "ar_account"
                  ? false
                  : true
              } //id not editable on edit
            />
          );
        } else {
          return (
            <Column
              field={temp.field}
              locked={temp.locked}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={
                temp.field === "id" ||
                temp.field === "createdByForUi" ||
                temp.field === "dateCreatedForUi" ||
                temp.field === "ap_account" ||
                temp.field === "ar_account"
                  ? false
                  : true
              } //id not editable on edit
            />
          );
        }
      });

    return [...selectionCol, ...normalCols];
  };

  toggleDeleteDialog = () => {
    this.setState({ deleteVisible: !this.state.deleteVisible });
  };

  validateField = (name, value) => {
    let error = "";
    if (name === "appliesTo" || name === "lookup" || name === "valueSchema") {
      try {
        JSON.parse(value);
      } catch (e) {
        error = "Must be a valid JSON object.";
      }
    } else if (name === "options") {
      try {
        const parsed = JSON.parse(value);
        if (!Array.isArray(parsed)) {
          error = "Must be a valid JSON array.";
        }
      } catch (e) {
        error = "Must be a valid JSON array.";
      }
    }

    const validationErrors = {
      ...this.state.validationErrors,
      [`${name}ErrorMessage`]: error,
      [`${name}Error`]: !!error,
    };
    this.setState({ validationErrors });
  };

  handleCheckboxChange = (e, field) => {
    const dataItem = {
      ...this.state.dataItem,
      [field]: e.value,
    };
    this.setState({ ...this.state, dataItem });
  };

  handleTextArea = (e, field) => {
    const dataItem = {
      ...this.state.dataItem,
      [field]: e.value,
    };
    this.setState({ dataItem }, () => {
      this.validateField(field, e.value);
    });
  };

  toggleDeleteDialog = () => {
    this.setState({ deleteVisible: !this.state.deleteVisible });
  };

  handleCheckboxChange = (e, field) => {
    const dataItem = {
      ...this.state.dataItem,
      [field]: e.value,
    };
    this.setState({ ...this.state, dataItem });
  };

  toggleDeleteDialog = () => {
    this.setState({ deleteVisible: !this.state.deleteVisible });
  };

  toggleWindow = () => {
    this.setState({
      ...this.state,
      editFormVisible: !this.state.editFormVisible,
      dataItem: {},
      originalDataItem: null,
    });
  };

  handleCheckboxChange = (e, field) => {
    const dataItem = {
      ...this.state.dataItem,
      [field]: e.value,
    };
    this.setState({ ...this.state, dataItem });
  };

  handleTextChange = (e, field) => {
    const dataItem = {
      ...this.state.dataItem,
      [field]: e.target.value,
    };
    this.setState({ ...this.state, dataItem });
  };

  updateRecord = async () => {
    const requiredFields = ["modelName", "settingName", "valueType", "isArray"];
    const optionalJsonValidatedFields = [
      "appliesTo",
      "options",
      "lookup",
      "valueSchema",
      "description",
      "notes",
    ];

    // Validate optional fields with JSON structures
    optionalJsonValidatedFields.forEach((field) => {
      if (this.state.dataItem[field]) {
        this.validateField(field, this.state.dataItem[field]);
      } else {
        this.setState((prevState) => ({
          validationErrors: {
            ...prevState.validationErrors,
            [field]: "",
          },
        }));
      }
    });

    setTimeout(async () => {
      const hasErrors = Object.values(this.state.validationErrors).some(
        (error) => !!error
      );
      const hasAllRequired = requiredFields.every(
        (field) =>
          this.state.dataItem[field] !== undefined &&
          this.state.dataItem[field] !== ""
      );

      if (!hasErrors && hasAllRequired) {
        let changes = {};

        [...requiredFields, ...optionalJsonValidatedFields].forEach((field) => {
          let oldValue, newValue;
          if (field === "modelName") {
            oldValue = this.state.originalDataItem[field];
            newValue = this.state.currentModel.text;
          } else if (field === "valueType") {
            oldValue = this.state.originalDataItem[field];
            newValue = this.state.currentValue.text;
          }

          if (field === "modelName" || field === "valueType") {
            if (newValue && oldValue && newValue !== oldValue) {
              changes[field] = newValue;
            }
          } else {
            newValue = this.state.dataItem[field];
            oldValue = this.state.originalDataItem[field];
            if (newValue !== oldValue) {
              changes[field] = newValue;
            }
          }
        });

        if (Object.keys(changes).length > 0) {
          try {
            const editRequest = await AbstractEdit(
              this.state.dataItem.id,
              changes,
              "modelsettingdefinitions",
              {
                token: window.localStorage.getItem("access_token"),
              }
            );
            if (editRequest.status === 200) {
              this.setState(
                {
                  ...this.state,
                  currentModel: {},
                  currentValue: {},
                  dataItem: {},
                  editFormVisible: false,
                  originalDataItem: JSON.parse(
                    JSON.stringify(this.state.dataItem)
                  ),
                  successMessage:
                    "Model setting definition was successfully updated",
                  openSuccessMessage: true,
                },
                () => {
                  setTimeout(() => {
                    this.handleSuccessSnackbar();
                    this.refreshGridDataAfterCrud();
                  }, 4000);
                }
              );
            }
          } catch (e) {
            this.setState({
              openErrorModal: true,
              errorModalContent:
                (e.response &&
                  e.response.data &&
                  e.response.data.error &&
                  e.response.data.error.message) ||
                "Updating model setting definition failed, please try again",
            });
          }
        }
      }
    }, 0);
  };

  validateNewRecord = (dataItem) => {
    let errors = {};
    const fieldsToValidate = [
      "modelName",
      "settingName",
      "valueType",
      "options",
      "lookup",
      "valueSchema",
      "appliesTo",
    ];

    fieldsToValidate.forEach((field) => {
      const error = this.validateField(field, dataItem[field]);
      if (error) {
        errors[`${field}Error`] = error;
      }
    });

    this.setState({ validationErrors: errors });
    return Object.keys(errors).length === 0;
  };

  createRecord = async () => {
    const { dataItem } = this.state;
    if (this.validateNewRecord(dataItem)) {
      try {
        const payload = {
          modelName: this.state.currentModel.text,
          valueType: this.state.currentValue.text,
          settingName: dataItem.settingName,
          description: dataItem.description,
          appliesTo: dataItem.appliesTo,
          isArray: dataItem.isArray,
          options: dataItem.options,
          lookup: dataItem.lookup,
          valueSchema: dataItem.valueSchema,
          notes: dataItem.notes,
        };

        const requiredFields = [
          "modelName",
          "valueType",
          "settingName",
          "isArray",
        ];
        let missingFields = [];

        requiredFields.forEach((field) => {
          if (
            payload[field] === undefined ||
            payload[field] === null ||
            payload[field] === ""
          ) {
            const displayName =
              field.charAt(0).toUpperCase() +
              field
                .slice(1)
                .replace(/([A-Z])/g, " $1")
                .trim();
            missingFields.push(displayName);
          }
        });

        if (missingFields.length > 0) {
          this.setState({
            showCreateErrors: true,
            showCreateErrorsMessages: `${missingFields.join(", ")} ${
              missingFields.length > 1 ? "are" : "is"
            } required.`,
          });
          return;
        }

        const response = await serverApi(
          "POST",
          "modelsettingdefinitions",
          "",
          payload,
          {
            token: window.localStorage.getItem("access_token"),
          },
          "",
          ""
        );
        if (response.status === 200) {
          this.setState(
            {
              ...this.state,
              addFormVisible: false,
              successMessage:
                "Model setting definition was successfully created",
              openSuccessMessage: true,
            },
            () => {
              setTimeout(() => {
                this.handleSuccessSnackbar();
                this.refreshGridDataAfterCrud();
              }, 4000);
            }
          );
        }
      } catch (e) {
        this.setState({
          openErrorModal: true,
          errorModalContent:
            (e.response &&
              e.response.data &&
              e.response.data.error &&
              e.response.data.error.message) ||
            "Create model setting definition failed, please try again",
        });
      }
    }
  };

  handleColumnsChange = (event) => {
    let columns = this.state.columns;

    let oldColumns = columns.filter((c) => c.visible).map((c) => c.field);
    let newColumns = event.target.value;

    let unique1 = oldColumns.filter((c) => newColumns.indexOf(c) === -1);
    let unique2 = newColumns.filter((c) => oldColumns.indexOf(c) === -1);

    const columnLastUpdated = unique1.concat(unique2)[0];

    let index = columns.findIndex(
      (column) => column.field === columnLastUpdated
    );
    columns[index].visible = !columns[index].visible;

    this.setState(
      {
        ...this.state,
        columns: columns,
      },
      () => {
        let columns = this.state.columns;
        window.localStorage.setItem("cols", JSON.stringify(columns));
      }
    );
  };

  refreshGridDataAfterCrud = async () => {
    let token = window.localStorage.getItem("access_token");
    let options = {
      token: token,
    };
    let refreshParams = window.localStorage.getItem("refreshParams");
    refreshParams = JSON.parse(refreshParams);

    const responseData = await serverApi(
      "GET",
      "modelsettingdefinitions",
      refreshParams,
      "",
      options
    );

    const finalData = showCreatorOnTable(responseData);

    let count;
    const countMasterEntity = await serverApi(
      "GET",
      `modelsettingdefinitions/count?where=${JSON.stringify(
        refreshParams.filter.where
      )}`,
      "",
      "",
      options
    );
    if (countMasterEntity) {
      count = countMasterEntity.data.count;
    }

    let sendObj = {
      gridData: {
        data: finalData.data,
        total: count,
      },
    };

    this.dataReceived(sendObj.gridData, null);
  };

  toggleDeleteDialog(dataItem = {}, state) {
    this.setState({
      ...state,
      deleteVisible: !this.state.deleteVisible,
      itemForDelete: dataItem,
    });
  }

  enterEdit = (dataItem) => {
    const modelMatch =
      this.state.models.find((model) => model.text === dataItem.modelName) ||
      {};
    const valueTypeMatch =
      this.state.valueTypes.find(
        (valueType) => valueType.text === dataItem.valueType
      ) || {};

    this.setState({
      editFormVisible: true,
      currentModel: modelMatch,
      currentValue: valueTypeMatch,
      dataItem: {
        ...dataItem,
        modelName: modelMatch,
        valueType: valueTypeMatch,
      },
      originalDataItem: JSON.parse(JSON.stringify(dataItem)),
    });
  };

  remove = async (dataItem) => {
    const data = [...this.state.gridData.data];

    let index = data.findIndex(
      (p) => p === dataItem || (dataItem.id && p.id === dataItem.id)
    );
    if (index >= 0) {
      data.splice(index, 1);
    }

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let url = "modelsettingdefinitions";

    try {
      let deleteItemRequest = await AbstractDelete(url, dataItem.id, options);

      if (deleteItemRequest.status === 200) {
        //clear the column filters if grid data is 0 or let them if grid data is >= 1
        if (data.length <= 0) {
          this.setState({
            ...this.state,
            gridData: {
              data: data,
              total: data.length,
            },
            dataState: {
              skip: 0,
              take: 100,
            },
          });
        } else {
          this.setState({
            ...this.state,
            gridData: {
              data: data,
              total: data.length,
            },
          });
        }
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent:
          (e.response.data && e.response.data.error.message) ||
          "Please try again",
      });
    }
  };

  disableRecord = async (dataItem) => {
    this.toggleDeleteDialog(dataItem, this.state);
  };

  add = async (dataItem) => {};

  discard = (dataItem, hasError = false) => {
    let data = [...this.state.gridData.data];
    const total = this.state.gridData.total;

    if (hasError) {
      data[0].inEdit = true;

      this.setState(
        {
          ...this.state,
          gridData: {
            data: data,
            total: total,
          },
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 4000);
        }
      );
    } else {
      this.removeItem(data, dataItem);

      this.setState(
        {
          ...this.state,
          gridData: {
            data: data,
            total: total,
          },
          visible: false,
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 3000);
        }
      );
    }
  };

  update = async (dataItem) => {};

  cancel = (dataItem) => {
    if (dataItem.modelName === undefined) {
      const data =
        this.state.initialGridData && this.state.initialGridData.data;
      const total = this.state.initialGridData.total;

      this.setState({
        ...this.state,
        gridData: {
          data: data,
          total: total,
        },
        errorModalContent:
          this.state.errorModalContent !== ""
            ? ""
            : this.state.errorModalContent,
        openErrorModal:
          this.state.openErrorModal === true
            ? false
            : this.state.errorModalContent,
      });
    } else {
      const originalItem =
        this.state.initialGridData &&
        this.state.initialGridData.data.find((p) => p.id === dataItem.id);

      if (originalItem) {
        if (originalItem.inEdit) {
          originalItem.inEdit = false;
        }
        if (originalItem.inAdd) {
          originalItem.inAdd = false;
        }

        const data =
          this.state.gridData &&
          this.state.gridData.data.map((item) =>
            item.id === originalItem.id ? originalItem : item
          );
        const total = this.state.gridData.total;

        this.setState({
          ...this.state,
          gridData: {
            data: data,
            total: total,
          },
        });
      }
    }
  };

  addNew = async () => {
    this.setState({
      ...this.state,
      addFormVisible: true,
    });
  };

  updateItem = (data, item) => {
    let index = data.findIndex(
      (p) => p === item || (item.id && p.id === item.id)
    );
    if (index >= 0) {
      data[index] = { ...item };
    }
  };

  applyNewColumnsOnGrid = (dataItem) => {
    let columns = this.state.initialColumns;
    let newColumns = columns.filter(
      (x) => x.field !== "id" && x.field !== "approval_status"
    );
    newColumns.forEach((x) => (x.minWidth = 290));
    if (dataItem && dataItem.relation_country !== "US") {
      newColumns = newColumns.filter((col) => col.field !== "state");
    }

    if (dataItem && dataItem.relation_country !== "US") {
      newColumns = newColumns.filter((col) => col.field !== "state");
    }
    this.setState(
      {
        ...this.state,
        hideColumns: true,
        columns: newColumns,
        initialColumns: columns,
      },
      () => {}
    );
  };

  applyInitialColumnsOnGrid = () => {
    let initialColumns = this.state.initialColumns;
    let newColumns = initialColumns;
    this.setState({
      ...this.state,
      hideColumns: false,
      columns: newColumns,
    });
  };

  itemChange = (event) => {
    const { field, value, dataItem } = event;

    const total = this.state.gridData.total;
    const data =
      this.state.gridData &&
      this.state.gridData.data.map((item) =>
        item.id === event.dataItem.id
          ? { ...item, [event.field]: event.value }
          : item
      );

    this.setState({
      gridData: {
        data: data,
        total: total,
      },
    });
  };

  removeItem(data, item) {
    let index = data.findIndex(
      (p) => p === item || (item.id && p.id === item.id)
    );
    if (index >= 0) {
      data.splice(index, 1);
    }
  }

  onClearFilters = () => {
    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    this.props.history.push(`/app/master/modelsettingdefinitions`);

    let init = JSON.stringify({ take: 100, skip: 0 });
    if (hasFinalParams && hasFinalParams.filter)
      window.localStorage.setItem("finalParams", init);

    this.dataStateChange({ data: { take: 100, skip: 0 } });
  };

  closeErrorModal = () => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      errorModalContent: "",
    });
  };

  expandChange = (event) => {
    window.localStorage.setItem("expandeId", event.dataItem.id);

    //if add new vessel is true then expand cannot be done !
    if (this.state.gridData.data[0].inEdit === true) {
      return;
    } else {
      event.dataItem.expanded = !event.dataItem.expanded;
    }

    this.forceUpdate();
  };

  handleErrorClose = (loginError) => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      openErrorModalContent: "",
    });
  };

  deleteAction = async () => {
    this.setState({
      ...this.state,
      deleteVisible: false,
    });

    let dataItem = this.state.itemForDelete;

    const data = [...this.state.gridData.data];

    let index = data.findIndex(
      (p) => p === dataItem || (dataItem.id && p.id === dataItem.id)
    );
    if (index >= 0) {
      data.splice(index, 1);
    }

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let url = "modelsettingdefinitions";

    try {
      let deleteItemRequest = await AbstractDelete(url, dataItem.id, options);

      if (deleteItemRequest.status === 200) {
        //clear the column filters if grid data is 0 or let them if grid data is >= 1
        if (data.length <= 0) {
          this.setState({
            ...this.state,
            gridData: {
              data: data,
              total: data.length,
            },
            dataState: {
              skip: 0,
              take: 100,
            },
            deleteVisible: false,
          });
        } else {
          this.setState({
            ...this.state,
            gridData: {
              data: data,
              total: this.state.gridData.total - 1,
            },
            dataState: {
              take: this.state.dataState.take,
              skip: this.state.dataState.skip,
            },
            deleteVisible: false,
          });
        }
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent:
          (e.response.data && e.response.data.error.message) ||
          "Please try again",
      });
    }
  };

  disableAction = async () => {
    let dataItem = this.state.itemForDelete;

    try {
      const editRequest = await AbstractEdit(
        dataItem.id,
        { is_active: false },
        "modelsettingdefinitions",
        {
          token: window.localStorage.getItem("access_token"),
        }
      );

      if (editRequest) {
        this.setState(
          {
            ...this.state,
            deleteVisible: false,
            successMessage: "The model setting was successfully disabled.",
            openSuccessMessage: true,
          },
          () => {
            setTimeout(() => {
              this.handleSuccessSnackbar();
              this.refreshGridDataAfterCrud();
            }, 4000);
          }
        );
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent: "Please refresh",
      });
    }
  };

  handleSuccessSnackbar = () => {
    this.setState({
      ...this.state,
      successMessage:
        this.state.openSuccessMessage === true ? this.state.successMessage : "",
      openSuccessMessage: !this.state.openSuccessMessage,
    });
  };

  toggleSimilarityWindow = () => {
    this.setState({
      similarityWindow: !this.state.similarityWindow,
    });
  };

  filterChange = (event) => {
    let filteredResults = this.filterData(event.filter);

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.setState({
        countries:
          filteredResults !== undefined
            ? filteredResults
            : this.state.initialCountries,
        loading: false,
      });
    }, this.state.delay);

    this.setState({
      ...this.state,
      loading: true,
    });
  };

  filterData(filter) {
    if (filter.value === "") {
      this.setState({
        countries: this.state.initialCountries,
      });
      return;
    } else {
      const data = this.state.initialCountries;
      filter.operator = "startswith";
      let filteredResults = filterBy(data, filter);
      return filteredResults;
    }
  }

  onDialogInputChange = (event) => {
    let target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.props ? target.props.name : target.name;

    this.setState({
      ...this.state,
      gridData: {
        ...this.state.gridData,
        [name]: value,
      },
      [name]: value,
    });
  };

  handleDropDownChange = (e, field) => {
    if (field === "modelName") {
      this.setState({ currentModel: e.target.value, [field]: e.target.value });
    } else {
      this.setState({ currentValue: e.target.value, [field]: e.target.value });
    }
  };

  exportToCsv = async (state, props) => {
    const count = state.gridData.total;
    const where = window.localStorage.getItem("exportFilter") || {};
    const numberOfCalls = Math.min(
      Math.round(count / 1000) + 1,
      5 // Limit to 5000
    );
    let finalSort = this.state.dataState?.sort?.length
      ? this.state.dataState.sort[0].field +
        " " +
        this.state.dataState.sort[0].dir
      : "id asc";

    const promises = [];
    for (let i = 0; i < numberOfCalls; i++) {
      promises.push(
        this.getDataPromise({
          skip: i * 1000,
          limit: 1000,
          where: !_.isEmpty(where) ? JSON.parse(where) : {},
          order: [finalSort],
        })
      );
    }

    try {
      const responses = await Promise.all(promises);
      let allData = [];
      responses.forEach((resp) => {
        allData = [...allData, ...resp.data];
      });
      if (allData.length > 0) {
        this.setState({
          ...this.state,
          exportData: allData,
        });
        // this._export.save(allData, this.state.columns);
      }
    } catch (e) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            (e.response.data && e.response.data.error.message) ||
            "Please try again",
        },
        () => {
          setTimeout(() => {
            this.setState({
              ...this.state,
              openErrorModal: false,
              openErrorModalContent: "",
            });
          }, 3000);
        }
      );
    }
  };

  getDataPromise = (filter) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    return new Promise((resolve, reject) => {
      const masterEntityRequest = serverApi(
        "GET",
        `modelsettingdefinitions?filter=${JSON.stringify(filter)}`,
        "",
        "",
        options
      );
      masterEntityRequest
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getExportData = async () => {
    await this.exportToCsv(this.state, this.props);
  };

  renderForm = (isEdit) => {
    return (
      <div>
        <fieldset style={{ borderColor: "rgba(13, 88, 105, 1)" }}>
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1, paddingRight: "10px" }}>
              <div>
                <h5>Model Name</h5>
                <DropDownList
                  data={[
                    { text: "masterEntity", id: 1 },
                    { text: "snUser", id: 2 },
                    { text: "domain", id: 3 },
                    { text: "masterEntityContact", id: 4 },
                  ].sort((a, b) => (a.text > b.text ? 1 : -1))}
                  value={this.state.currentModel}
                  textField="text"
                  dataItemKey="id"
                  style={{ width: "100%" }}
                  filterable={true}
                  name="modelName"
                  onChange={(e) => this.handleDropDownChange(e, "modelName")}
                  placeholder="Model Name"
                />
              </div>
              <div>
                <h5>Value Type</h5>
                <DropDownList
                  data={[
                    { text: "boolean", id: 1 },
                    { text: "integer", id: 2 },
                    { text: "string", id: 3 },
                    { text: "object", id: 4 },
                  ].sort((a, b) => (a.text > b.text ? 1 : -1))}
                  value={this.state.currentValue || ""}
                  textField="text"
                  style={{ width: "100%" }}
                  filterable={true}
                  name="valueType"
                  onChange={(e) => this.handleDropDownChange(e, "valueType")}
                  placeholder="Value Type"
                />
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <h5 style={{ marginBottom: "-5px" }}>Setting Name</h5>
                  <CustomInput
                    style={{ width: "100%" }}
                    disabled={false}
                    autocomplete="false"
                    id="settingName"
                    fullWidth={true}
                    onChange={(e) => this.handleTextChange(e, "settingName")}
                    value={this.state.dataItem.settingName || ""}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <div style={{ flex: 1, paddingRight: "10px" }}>
              <div>
                <h5>Description</h5>
                <TextArea
                  style={{ width: "100%" }}
                  value={this.state.dataItem.description || ""}
                  name="description"
                  onChange={(e) => this.handleTextArea(e, "description")}
                  rows={3}
                />
                {this.state.validationErrors.descriptionError && (
                  <Error style={{ color: "red", fontSize: "15px" }}>
                    {this.state.validationErrors.descriptionErrorMessage}
                  </Error>
                )}
              </div>
              <div>
                <h5>Applies To</h5>
                <TextArea
                  style={{ width: "100%" }}
                  value={this.state.dataItem.appliesTo || ""}
                  name="appliesTo"
                  onChange={(e) => this.handleTextArea(e, "appliesTo")}
                  rows={3}
                />
                {this.state.validationErrors.appliesToError && (
                  <Error style={{ color: "red", fontSize: "15px" }}>
                    {this.state.validationErrors.appliesToErrorMessage}
                  </Error>
                )}
              </div>
            </div>
            <div style={{ flex: 1, paddingLeft: "10px" }}>
              <div>
                <h5>Options</h5>
                <TextArea
                  style={{ width: "100%" }}
                  value={this.state.dataItem.options || ""}
                  name="options"
                  onChange={(e) => this.handleTextArea(e, "options")}
                  rows={3}
                />
                {this.state.validationErrors.optionsError && (
                  <Error style={{ color: "red", fontSize: "15px" }}>
                    {this.state.validationErrors.optionsErrorMessage}
                  </Error>
                )}
              </div>
              <div>
                <h5>Lookup</h5>
                <TextArea
                  style={{ width: "100%" }}
                  value={this.state.dataItem.lookup || ""}
                  name="lookup"
                  onChange={(e) => this.handleTextArea(e, "lookup")}
                  rows={3}
                />
                {this.state.validationErrors.lookupError && (
                  <Error style={{ color: "red", fontSize: "15px" }}>
                    {this.state.validationErrors.lookupErrorMessage}
                  </Error>
                )}
              </div>
            </div>
          </div>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <div style={{ flex: 1, paddingRight: "10px" }}>
              <div>
                <h5>Value Schema</h5>
                <TextArea
                  style={{ width: "100%" }}
                  value={this.state.dataItem.valueSchema || ""}
                  name="valueSchema"
                  onChange={(e) => this.handleTextArea(e, "valueSchema")}
                  rows={3}
                />
                {this.state.validationErrors.valueSchemaError && (
                  <Error style={{ color: "red", fontSize: "15px" }}>
                    {this.state.validationErrors.valueSchemaErrorMessage}
                  </Error>
                )}
              </div>
              <div>
                <h5>Notes</h5>
                <TextArea
                  style={{ width: "100%" }}
                  value={this.state.dataItem.notes || ""}
                  name="notes"
                  onChange={(e) => this.handleTextArea(e, "notes")}
                  rows={3}
                />
                {this.state.validationErrors.notesError && (
                  <Error style={{ color: "red", fontSize: "15px" }}>
                    {this.state.validationErrors.notesErrorMessage}
                  </Error>
                )}
              </div>
              <div style={{ marginTop: "10px" }}>
                <Checkbox
                  name="isArray"
                  checked={this.state.dataItem.isArray || false}
                  onChange={(e) => this.handleCheckboxChange(e, "isArray")}
                  label="Is Array"
                  placeholder="Is Array"
                />
              </div>
            </div>
          </div>
          <br />
          <button
            style={{ marginRight: "5px" }}
            type="button"
            className="k-button k-primary"
            onClick={isEdit ? this.updateRecord : this.createRecord}
            disabled={false}
          >
            {isEdit ? "Update" : "Create"}
          </button>
          {!isEdit && this.state.showCreateErrors && (
            <h4 style={{ color: "orange" }}>
              {this.state.showCreateErrorsMessages}
            </h4>
          )}
        </fieldset>
      </div>
    );
  };

  render() {
    const { columns } = this.state;

    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    let hasColumnFilters = true;
    if (found) {
      hasColumnFilters = false;
    }

    const { hasBackofficeRead } = this.props;

    return (
      <div>
        {this.state.deleteVisible && (
          <Dialog onClose={() => this.toggleDeleteDialog({}, this.state)}>
            <p style={{ margin: "25px", textAlign: "center" }}>
              Are you sure you want to disable this model setting?
            </p>
            <DialogActionsBar>
              <button
                className="k-button"
                onClick={() => this.toggleDeleteDialog({}, this.state)}
              >
                No
              </button>
              <button className="k-button" onClick={() => this.disableAction()}>
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
        <Grid
          {...this.state.dataState}
          {...this.state.gridData}
          filterable={hasColumnFilters}
          style={{ height: "92vh" }}
          sortable={true}
          resizable
          editField={this.editField}
          onItemChange={this.itemChange}
          onDataStateChange={this.dataStateChange}
          expandField="expanded"
          onExpandChange={this.expandChange}
          pageable={{
            buttonCount: 5,
            info: true,
            pageSizes: [100, 200, 500, 1000, 1500],
          }}
        >
          <GridToolbar>
            <GridContainer xs={12} direction={"row"}>
              <Tooltip
                justify={"flex-start"}
                placement="top"
                title={"Add Definition Manually"}
              >
                <IconButton onClick={this.addNew}>
                  <FontAwesomeIcon
                    color="#0D5869"
                    onClick={this.addNew}
                    size="1.6x"
                    icon={faPlus}
                  />
                </IconButton>
              </Tooltip>

              <Tooltip
                justify={"flex-end"}
                placement="top"
                title={"Clear Column Filters"}
              >
                <IconButton
                  disabled={false}
                  aria-label=""
                  onClick={(e) => this.onClearFilters(e)}
                  look="flat"
                >
                  <FontAwesomeIcon
                    color="#0D5869"
                    size="1.6x"
                    icon={faEraser}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip
                justify={"flex-start"}
                placement="top"
                title={"Refresh Data"}
              >
                <IconButton
                  disabled={false}
                  aria-label=""
                  onClick={this.refreshGridDataAfterCrud}
                >
                  <FontAwesomeIcon color="#0D5869" size="1x" icon={faSyncAlt} />
                </IconButton>
              </Tooltip>
              <ViewSettings
                {...this.props}
                export={{
                  data: this.state.exportData || [],
                  exportFunction: this.getExportData,
                  fileName: "ModelSettingDefinitions_",
                  columns: columnsSchema(),
                }}
                viewSettings={{
                  type: "",
                }}
                refreshSettings={{
                  data: [],
                }}
              />
              {!this.state.hideColumns ? (
                <div style={{ marginTop: hasBackofficeRead ? "5px" : "5px" }}>
                  <FormControl className={customSelectStyles.selectFormControl}>
                    <InputLabel
                      htmlFor="multiple-select"
                      className={customSelectStyles.selectLabel}
                    />
                    <Select
                      autoWidth={true}
                      multiple
                      value={columns
                        .filter((column) => column.visible)
                        .map((column) => column.field)}
                      onChange={this.handleColumnsChange}
                      MenuProps={{ className: customSelectStyles.selectMenu }}
                      inputProps={{
                        name: "multipleSelect",
                        id: "multiple-select",
                      }}
                      renderValue={(selected) => "Columns"}
                    >
                      {columns
                        .filter((col) => !col.notShownInTableColumnSelection)
                        .map((column) => {
                          let name = column.title;
                          let value = column.field;
                          let isVisible = column.visible;
                          let isDisabled = column.noDisabledColumn;

                          return (
                            <MenuItem
                              style={{ backgroundColor: "white" }}
                              key={name}
                              value={value}
                              disabled={isDisabled}
                            >
                              <Checkbox color={"default"} checked={isVisible} />
                              <ListItemText
                                style={{ marginLeft: "7px" }}
                                primary={name}
                              />
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
              ) : null}
            </GridContainer>
          </GridToolbar>

          <Column
            cell={this.CommandCell}
            title="Actions"
            filterable={false}
            width="160px"
          />

          {this.renderGridColumns()}
        </Grid>

        <Loader
          dataState={this.state.dataState}
          onDataRecieved={this.dataReceived}
          include={[""]}
          getURL={"modelsettingdefinitions"}
          countURL={"modelsettingdefinitions/count"}
          view={""}
        />

        <Snackbar
          place="tc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"warning"}
          message={this.state.errorModalContent}
          open={this.state.openErrorModal}
        />

        <Snackbar
          place="tc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"success"}
          message={this.state.successMessage}
          open={this.state.openSuccessMessage}
        />

        {this.state.editFormVisible && (
          <Window
            title={`Update record: ${this.state.dataItem.id}`}
            onClose={this.toggleWindow}
            width={800}
            height={690}
          >
            {this.renderForm(true)}
          </Window>
        )}

        {this.state.addFormVisible && (
          <Window
            title={`Create record`}
            onClose={() =>
              this.setState({ ...this.state, addFormVisible: false })
            }
            width={800}
            height={690}
          >
            {this.renderForm(false)}
          </Window>
        )}
      </div>
    );
  }
}

export default ModelSettingDefinition;
