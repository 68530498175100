export const columnsSchema = () => [
  {
    field: "id",
    title: "ID",
    visible: true,
    minWidth: 140,
    sortable: true,
    filterable: true,
    locked: true,
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
    // cell: true
  },
  {
    field: "otherSystemID",
    title: "Other System ID",
    visible: true,
    minWidth: 150,
    sortable: true,
    filterable: true,
    locked: true,
  },
  {
    field: "group_id",
    title: "Group ID",
    visible: false,
    minWidth: 140,
    sortable: true,
    filterable: true,
     locked: false,
  },
  {
    field: "group_name",
    title: "Group Name",
    visible: true,
    minWidth: 250,
    sortable: true,
    filterable: true,
     locked: false,
  },

  {
    field: "client_name",
    title: "Client",
    visible: true,
    minWidth: 400,
    sortable: true,
    filterable: true,
     locked: false,
    editable: "true",
  },

  {
    field: "agent_name",
    title: "Receiving Agent",
    visible: true,
    minWidth: 400,
    sortable: true,
    filterable: true,
     locked: false,
  },
  {
    field: "destination_name",
    title: "Destination",
    visible: true,
    minWidth: 400,
    sortable: true,
    filterable: true,
     locked: false,
  },
  {
    field: "clientID",
    title: "Client ID",
    visible: true,
    minWidth: 140,
    sortable: true,
    filterable: true,
     locked: false,
  },
  {
    field: "destinationID",
    title: "Destination ID",
    visible: true,
    minWidth: 140,
    sortable: true,
    filterable: true,
     locked: false,
  },
  {
    field: "receivingAgentID",
    title: "Receiving Agent ID",
    visible: true,
    minWidth: 540,
    sortable: true,
    filterable: true,
     locked: false,
  },
];
