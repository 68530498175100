export const columnsSchema = () => [
  {
    field: 'id',
    title: 'Client ID',
    visible: true,
    minWidth: 200,
    sortable: true,
    filterable: true,
    locked: 'locked',
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
    // cell: true
  },
  {
    field: 'relation_name',
    title: 'Client Name',
    visible: true,
    minWidth: 420,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    locked: 'locked',
  },
  {
    field: 'deleteID',
    title: 'delete id',
    visible: false,
    // minWidth: 350,
    sortable: false,
    filterable: false,
    noDisabledColumn: true,
    locked: 'locked',
  },
  {
    field: 'relation_address',
    title: 'ADDRESS',
    visible: true,
    minWidth: 480,
    sortable: true,
    filterable: true,
    noDisabledColumn: false,
    notShownInTableColumnSelection: false,
    locked: 'locked',
  },
  {
    field: 'relation_postal_code',
    title: 'POSTAL',
    visible: true,
    minWidth: 200,
    sortable: true,
    filterable: true,
    locked: 'locked',
  },
  {
    field: 'relation_country',
    title: 'COUNTRY',
    visible: true,
    minWidth: 280,
    sortable: true,
    filterable: true,
    locked: 'locked',
  },
  {
    field: 'relation_city',
    title: 'CITY',
    visible: true,
    minWidth: 320,
    sortable: true,
    filterable: true,
    locked: 'locked',
    notShownInTableColumnSelection: false,
  },
]
