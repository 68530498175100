export const columnsSchema = () => [
  {
    field: "id",
    title: "ID",
    visible: true,
    minWidth: 170,
    sortable: true,
    filterable: true,
    locked: "locked",
    noDisabledColumn: true,
    notShownInTableColumnSelection: false
    // cell: true
  },
  {
    field: "fleet_name",
    title: "Fleet Name",
    visible: true,
    // minWidth: 170,
    sortable: true,
    filterable: true,
    locked: "locked"
  },
 
  {
    field: "clientID",
    title: "Client Id",
    visible: true,
    // minWidth: 170,
    sortable: true,
    filterable: true,
    locked: "locked"
  },
  {
    field: "created_by",
    title: "Created By",
    visible: true,
    // minWidth: 170,
    sortable: true,
    filterable: true,
    locked: "locked"
  },
  {
    field: "masterEntity.relation_name",
    title: "Client Name",
    visible: true,
    // minWidth: 170,
    sortable: true,
    filterable: false,
    locked: "locked"
  },
  {
    field: "date_created",
    title: "Date Created",
    visible: true,
    // minWidth: 170,
    sortable: true,
    filterable: false,
    locked: "locked",
    editable: "false"
  },
  {
    field: "date_updated",
    title: "Date Updated",
    visible: true,
    // minWidth: 170,
    sortable: true,
    filterable: false,
    locked: "locked",
    editable: "false"
  }
];
