import React from "react";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { serverApi } from "../../../../../networking/config";
import { filterBy } from "@progress/kendo-data-query";

export class AutoComplete2 extends React.Component {
  state = {
    client_names: [],
    valueClientName:
      this.props.dataItem &&
      this.props.dataItem.masterEntity &&
      this.props.dataItem.masterEntity.relation_name
  };

  onChange = event => {
    //prevent enter keyboard
    if (
      event.nativeEvent.type === "keydown" &&
      event.nativeEvent.keyCode === 13
    )
      return;

    const {  value } = event.target;

    if (value === "") {
      this.setState({
        openedClientNames: false,
        valueClientName: value
      });
    } else {
      this.setState(
        {
          openedClientNames: false,
          valueClientName: value
        },
        () => {
          this.onAutoCompleteChange(event, value);
        }
      );
    }
  };

  onAutoCompleteChange = (event, value) => {
    const { name } = event.target;

    let stateData = {};

    stateData =
      value.length < 3
        ? {
            data:
              this.state.client_names.length > 0
                ? this.state.client_names.map(temp => temp.relation_name)
                : [],
            openedClientNames: false
          }
        : { data: this.filterData(value, name), openedClientNames: true };

    let eventType = event.nativeEvent.type;

    let valueSelected =
      eventType === "click" ||
      (eventType === "keydown" && event.nativeEvent.keyCode === 13);

    if (valueSelected) {
      this.setState({
        ...this.state,
        relation_postal_code:
          this.state.client_names[0].relation_postal_code || "",
        relation_country: this.state.client_names[0].relation_country || "",
        client_flag: true,
        postClientID:
          (this.state.client_names &&
            this.state.client_names.length > 0 &&
            this.state.client_names[0].id) ||
          ""
      });
    }

    if (valueSelected && stateData.data.includes(value)) {
      stateData.openedClientNames = false;
    }

    this.setState(
      {
        valueClientName: value,
        ...stateData
      },
      () => this.fetchAutocompleteData(value)
    );
  };

  fetchAutocompleteData = filterValue => {
    let options = {
      token: window.localStorage.getItem("access_token")
    };

    let filter = {
      order: ["relation_name ASC"],
      where: {
        and: [
          { relation_name: { like: `${filterValue}%` } },
          {
            and: [
              {
                supplier_flag: false
              },
              {
                client_flag: true
              }
            ]
          }
        ]
      }
    };

    const fetchDropdownData = serverApi(
      "GET",
      `masterEntities`,
      { filter: filter },
      "",
      options
    );

    fetchDropdownData
      .then(response => {
        if (response.data.length === 0) {
          setTimeout(() => {
            this.setState({
              ...this.state,
              valueClientName:
                (this.props.dataItem &&
                  this.props.dataItem.masterEntity &&
                  this.props.dataItem.masterEntity.relation_name) ||
                "",
              client_names: response.data
            });
          }, 700);
        } else if (response.data.length > 1) {
          let editItem = {
            masterEntity:
              (response.data && response.data.length > 0 && response.data[0]) ||
              "",
            clientID:
              (response.data &&
                response.data.length > 0 &&
                response.data[0].id) ||
              "",
            vesselID: this.props.dataItem.vesselID || "",
            inEdit: true
          };

          window.localStorage.setItem("editItem", JSON.stringify(editItem));

          this.setState({
            ...this.state,
            client_names: response.data
          });
        }
      })
      .catch(error => {
        console.log(error);

        throw error;
      });
  };

  filterData = (value, name) => {
    const data = this.state.client_names.map(test => test.relation_name);

    const filter = {
      value: value,
      operator: "startswith",
      ignoreCase: true
    };

    let results = filterBy(data, filter);
    return results;
  };

  listNoDataRender = element => {
    const noData = (
      <h4 style={{ fontSize: "1em" }}>
        <span className="k-icon k-i-warning" style={{ fontSize: "2.5em" }} />
        <br />
        <br />
        No client names found
      </h4>
    );

    return React.cloneElement(element, { ...element.props }, noData);
  };

  render() {
    const { dataItem, } = this.props;
    const dataValue =
      dataItem &&
      dataItem.masterEntity &&
      dataItem.masterEntity.relation_name === null
        ? ""
        : dataItem &&
          dataItem.masterEntity &&
          dataItem.masterEntity.relation_name;

    return (
      <td>
        {dataItem && dataItem.inEdit
          ? <AutoComplete
              style={{ width: "450px" }}
              data={this.state.client_names.map(temp => temp.relation_name)}
              value={this.state.valueClientName}
              name={"masterEntity.relation_name"}
              onChange={e => this.onChange(e)}
              listNoDataRender={this.listNoDataRender}
            />
          : (dataValue !== undefined && dataValue.toString()) || ""}
      </td>
    );
  }
}
