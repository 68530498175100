import React from "react";
import TableList2 from "../../containers/ContainerGrid/gridWithoutHOC";
import navigatorLogo from "../../assets/img/navigatorLogo.svg";
import { invoiceSchema } from "./schema";

// const invoiceLogs = [
//     {
//         "id": 2166623,
//         "userid": 1,
//         "timestamp": "2020-05-31T15:52:20.000Z",
//         "model_name": "INVOICE",
//         "model_id": 81,
//         "obj_before": "NEW-RECORD",
//         "obj_after": "{\"vesselID\":15391,\"vesselName\":\"Sofia\",\"clientName\":\"Euronav Ship Management (Hellas) Ltd.\",\"clientID\":84358,\"createdByUserID\":1,\"createdByUserName\":\"sokaris.stavros\"}",
//         "creator": {
//             "userRealName": "Stavros Sokaris",
//             "bugify_token": null,
//             "dept": "OPS1",
//             "last_login_date": null,
//             "last_password_change": null,
//             "realm": null,
//             "username": "sokaris.stavros",
//             "email": "s.sokaris@swiftmarine.eu",
//             "emailVerified": null,
//             "id": 1
//         }
//     }
// ]

const InvoiceLogPage = (props) => {
  const showExtraComponents = {
    showCreateButton: false,
    showExportButton: false,
    showRefreshDataButton: true,
    showToggleFilters: false,
    showGoBackButton: false,
    showClearFilters: false,
    showPagination: false,
    showGridActions: false,
    showToggleColumns: false,
    rowClick: false,
  };

  const gridActions = [];

  const includeArray = [""];

  return (
    <>
      <TableList2
        {...props}
        showTable2={false}
        showTable3={false}
        showTable4={false}
        showTable5={false}
        BlockUiMessage={"Loading table data . . . "}
        showHeaderNavigator={true}
        columnsSchema={invoiceSchema}
        howManyTable={["1"]}
        gridActions={gridActions}
        includeArray={includeArray}
        dropdownFieldName={""}
        urlSpecific={""}
        karfoto={`logTables?filter={"include": "creator", "where":{ "and": [{"model_name": "INVOICE", "model_id": "${props.match.params.id}"}]}}`}
        karfoto3={`invoices/${props.match.params.id}`}
        tableTitle={"Invoice Log"}
        logo={navigatorLogo}
        showExtraComponents={showExtraComponents}
        filterable={false}
        reorderable={true}
        sortable={false}
        showGridActions={false}
        showPagination={false}
      />
    </>
  );
};

export default InvoiceLogPage;
