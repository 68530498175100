import React, { Fragment } from "react";
import { Collapse, Nav } from "react-bootstrap";
import PerfectScrollbar from "perfect-scrollbar";
import { withRouter } from "react-router";

import "./Sidebar.scss";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import Icon from "components/Icon/Icon";
import { icons } from "components/Icon/Icon";
import SidebarLogo from "./components/SidebarLogo";
import SidebarLink from "./components/SidebarLink";

import { routes, root } from "../../frontendRouting/routes";
import { getCurrentCategory } from "../../frontendRouting/routes";
import { Popover, Button } from "@material-ui/core";
import SidebarBugReport from "./components/SidebarBugReport";
import { Tooltip } from "@material-ui/core";

var ps;

class CustomSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      bugAnchorEl: null
    };

    this.srRef = React.createRef();
  }

  toggleMenu = (route, open) => {
    let categories = [...this.props.sidebarCategories];
    let previousOpenedCategory = this.props.sidebarCategories.filter(
      x => x.collapsed === true
    );
    let category = categories.find(x => x.id === route.id);
    if (category) {
      category.collapsed = open || !category.collapsed;
      if (previousOpenedCategory.length > 0) {
        categories.forEach(categ => {
          previousOpenedCategory.forEach(prev => {
            if (categ.id === prev.id) {
              categ.collapsed = false;
            }
          });
        });
      }
      this.props.setSidebarCategories(categories);
    }
  };

  expandCategory = () => {
    let path = this.props.location.pathname.toLowerCase();
    if (path.endsWith("/")) {
      path = path.substr(0, path.length - 1);
    }
    const id = this.props.match.params.id;
    const currentCategory = getCurrentCategory(path, id);
    if (currentCategory) {
      this.toggleMenu(currentCategory, true);
    }
  };

  componentDidMount() {
    this.expandCategory();

    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.srRef.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }

    if (!this.props.isSidebarLocked) {
      this.props.setSidebarCollapsed(true);
    }
  }

  componentDidUpdate() {
    if (navigator.platform.indexOf("Win") > -1) {
      setTimeout(() => {
        ps.update();
      }, 350);
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  isCollapsed = route => {
    let collapsed = false;
    let category = this.props.sidebarCategories.find(x => x.id === route.id);
    if (category) {
      collapsed = category.collapsed;
    }
    return collapsed;
  };

  render() {
    const user = this.props.user;
    const userRoles = user.roleIds;
    const userRoutes = routes.filter(
      route =>
        !route.access || route.access.roles.some(r => userRoles.includes(r))
    );
    const topRoutes = userRoutes.filter(x => x.sidebar && !x.sidebar.bottom);
    const bottomRoutes = userRoutes.filter(x => x.sidebar && x.sidebar.bottom);

    return (
      <div
        onMouseOver={() => {
          this.props.setSidebarCollapsed(false);
        }}
        onMouseLeave={() => {
          if (!this.props.isSidebarLocked) {
            this.setState({
              anchorEl: null
            });
            this.props.setSidebarCollapsed(true);
          }
        }}
        className={`sidebar ${this.props.isSidebarCollapsed
          ? "collapsed"
          : ""}`}
      >
        <SidebarLogo root={root} />
        <div className="sidebar-wrapper" ref={this.srRef}>
          <Nav className="flex-column">
            {topRoutes.map((route, index) => {
              if (route.isCategory) {
                const userChildrenRoutes = route.children.filter(
                  route =>
                    !route.access ||
                    route.access.roles.some(r => userRoles.includes(r))
                );
                return (
                  <Fragment key={index}>
                    <Tooltip
                      placement="left-end"
                      title={
                        this.props.isSidebarCollapsed ? route.sidebar.text : ""
                      }
                    >
                      <div
                        onClick={() => {
                          this.toggleMenu(route);
                        }}
                        className={`nav-link nav-category ${this.props.location.pathname.indexOf(
                          route.isCategory
                        ) >= 0
                          ? "active"
                          : ""}`}
                      >
                        {route.sidebar.icon}
                        <span className="sidebar-text">
                          {route.sidebar.text}
                          <Icon
                            icon={icons.ArrowDropDownIcon}
                            className={`caret ${this.isCollapsed(route)
                              ? "rotate-180"
                              : ""}`}
                          />
                        </span>
                      </div>
                    </Tooltip>

                    <Collapse in={this.isCollapsed(route)}>
                      <Nav className="flex-column">
                        {userChildrenRoutes.map((child, index) => {
                          return (
                            <SidebarLink
                              key={index}
                              route={child}
                              isChild
                              user={this.props.user}
                              isSidebarCollapsed={this.props.isSidebarCollapsed}
                            />
                          );
                        })}
                      </Nav>
                    </Collapse>
                  </Fragment>
                );
              } else {
                return (
                  <SidebarLink
                    key={index}
                    route={route}
                    user={this.props.user}
                    isSidebarCollapsed={this.props.isSidebarCollapsed}
                  />
                );
              }
            })}
            <div className="test">
              {bottomRoutes.map((route, index) => {
                return (
                  <SidebarLink
                    key={index}
                    route={route}
                    user={this.props.user}
                    isSidebarCollapsed={this.props.isSidebarCollapsed}
                    onClick={event => {
                      if (route.isAccountMenu) {
                        this.setState({
                          anchorEl: event.currentTarget
                        });
                      } else if (route.isReportBugMenu) {
                        this.setState({
                          bugAnchorEl: event.currentTarget
                        });
                      }
                    }}
                  />
                );
              })}
            </div>
          </Nav>
        </div>

        <Popover
          open={Boolean(this.state.anchorEl)}
          anchorEl={this.state.anchorEl}
          onClose={() => {
            this.setState({
              anchorEl: null
            });
            if (!this.props.isSidebarLocked) {
              setTimeout(() => {
                this.props.setSidebarCollapsed(true);
              }, 100);
            }
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <div className="popover-logout">
            <div className="pi-wrap">
              <div
                className="contact-avatar"
                style={{ backgroundColor: user.userColor }}
              >
                {user.userShortName}
              </div>
              <div className="pi-name">
                {user.userDisplayName}
              </div>
              <div className="pi-email">
                {user.email}
              </div>
              <div className="pi-roles">
                {user.roles.map(x => x.name).join(" | ")}
              </div>
            </div>
            <div className="pi-actions">
              <Button
                variant="contained"
                classes={{ label: "sm-primary-button-label text-normal" }}
                color="primary"
                disableElevation
                onClick={this.props.onLogout}
              >
                Sign out
              </Button>
            </div>
          </div>
        </Popover>

        <SidebarBugReport
          user={this.props.user}
          bugAnchorEl={this.state.bugAnchorEl}
          sendBugReport={this.props.sendBugReport}
          mainContainerRef={this.props.mainContainerRef}
          onClose={() => {
            this.setState({
              bugAnchorEl: null
            });
            if (!this.props.isSidebarLocked) {
              setTimeout(() => {
                this.props.setSidebarCollapsed(true);
                clearTimeout(this.timeoutId);
                this.timeoutId = null;
              }, 100);
            }
          }}
        />
      </div>
    );
  }
}

export default withRouter(CustomSidebar);
