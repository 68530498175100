import React from "react";
import { NavLink } from "react-router-dom";
import { Tooltip } from "@material-ui/core";

function SidebarLinkContent({ isChild, route, user, isSidebarCollapsed }) {
  let text, icon, secondaryIcon;
  if (route.sidebar) {
    text = route?.sidebar?.text;
    icon = route?.sidebar?.icon;
    secondaryIcon = route?.sidebar?.secondaryIcon;
  }
  if (route.isAccountMenu) {
    text = text && text.replace("@userDisplayName", user.userDisplayName || ""); //after , user.userDisplayName || ""
    if (icon === "@userAvatar") {
      icon = (
        <div
          className="contact-avatar"
          style={{ backgroundColor: user.userColor }}
        >
          {user.userShortName}
        </div>
      );
    }
  }
  return (
    <div>
      {icon &&
        <Tooltip placement="left-end" title={isSidebarCollapsed ? text : ""}>
          {icon}
        </Tooltip>}
      {secondaryIcon}
      {text &&
        <span className={isChild ? "sidebar-normal" : "sidebar-text"}>
          {text}
        </span>}
    </div>
  );
}

function SidebarLink(props) {
  const { route } = props;
  if (!route.path) {
    return (
      <div onClick={props.onClick} className="nav-link clickable">
        <SidebarLinkContent {...props} />
      </div>
    );
  } else {
    if (route.path === '/app/master/vessels/:id') return null;
    return (
      <NavLink
        onClick={props.onClick}
        exact
        to={route.path}
        activeClassName="active"
        className="nav-link"
      >
        <SidebarLinkContent {...props} />
      </NavLink>
    );
  }
}

export default SidebarLink;
