import React, { Component } from "react";
import { connect } from "react-redux";
import compose from "ramda/src/compose";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { MyCommandCell } from "./components/gridActionsCell";
import { columnsSchema } from "./schema";
import _ from "lodash";
import { DropDownCell } from "../../../CRM/View/dropDownCell";
import { AbstractDelete, AbstractEdit } from "../../../../networking/apiCalls";
import { serverApi } from "../../../../networking/config";
import { filterBy } from "@progress/kendo-data-query";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Loader } from "./components/loader";
import Snackbar from "../../../../components/Snackbar/Snackbar";
import GridContainer from "../../../../components/Grid/GridContainer";
import { IconButton, Tooltip } from "@material-ui/core";
import { faEraser, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import ClientsBelongToGroup from "./components/expandedGrid";
import ExpandedTabs from "../../../common/ExpandedTabs";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class GroupToClient extends Component {
  editField = "inEdit";
  CommandCell;

  constructor(props) {
    super(props);

    this.state = {
      deleteVisible: false,
      visibleWindow: false,
      isDropOpened: false,
      columns: (columnsSchema && columnsSchema()) || [],
      gridData: {
        data: [],
        total: 0,
      },
      initialGridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 10,
        skip: 0,
      },
      openErrorModal: false,
      errorModalContent: "",
      delay: 400,
      loading: false,
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.deleteGroup,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      hasDisable: false,
      showSecondAction: true,
    });
  }

  componentDidMount() {
    let isFirstLoad = true;
    window.localStorage.setItem("isFirstLoad", isFirstLoad);

    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));

    if (hasFinalParams) {
      this.setState({
        ...this.state,
        dataState: hasFinalParams,
      });
    }

    if (hasChangedColumns) {
      this.setState({
        ...this.state,
        columns: hasChangedColumns,
      });
    }
  }

  componentWillUnmount() {
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));
    let finalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    let hasExpandedId = JSON.parse(window.localStorage.getItem("expandeId"));
    let hasExportFilter = window.localStorage.getItem("exportFilter");

    if (hasChangedColumns) {
      window.localStorage.removeItem("cols");
    }
    if (finalParams) {
      window.localStorage.removeItem("finalParams");
    }
    if (hasExpandedId) {
      window.localStorage.removeItem("expandeId");
    }
    if (hasExportFilter) {
      window.localStorage.removeItem("exportFilter");
    }
  }

  dataStateChange = (e) => {
    const reqGridParams = e.data;
    window.localStorage.setItem("finalParams", JSON.stringify(reqGridParams));

    this.setState({
      ...this.state,
      dataState: reqGridParams,
    });
  };

  dataReceived = (gridData, reqGridParams) => {
    this.setState({
      ...this.state,
      gridData: gridData,
      initialGridData: gridData,
      dataState:
        reqGridParams === !_.isEmpty(reqGridParams)
          ? reqGridParams
          : this.state.dataState,
    });
  };

  renderGridColumns = () => {
    let selectionCol = [];
    let normalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.isFilterBoolean === "yes") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              cell={DropDownCell}
            />
          );
        } else {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={temp.field === "id" ? false : true} //id not editable on edit
            />
          );
        }
      });

    return [...selectionCol, ...normalCols];
  };

  toggleDeleteDialog(dataItem = {}, state) {
    this.setState({
      ...state,
      deleteVisible: !this.state.deleteVisible,
      itemForDelete: dataItem,
    });
  }

  enterEdit = (dataItem) => {
    let total = this.state.gridData.total;
    this.setState({
      ...this.state,
      gridData: {
        data:
          this.state.gridData &&
          this.state.gridData.data.map((item) =>
            item.id === dataItem.id ? { ...item, inEdit: true } : item
          ),
        total: total,
      },
    });
  };

  remove = async (dataItem) => {
    const data = [...this.state.gridData.data];

    let index = data.findIndex(
      (p) => p === dataItem || dataItem.id || p.id === dataItem.id
    );
    if (index >= 0) {
      data.splice(index, 1);
    }

    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    let url = "clientGroups";
    try {
      let deleteItemRequest = await AbstractDelete(url, dataItem.id, options);

      if (deleteItemRequest.status === 200) {
        //clear the column filters if grid data is 0 or let them if grid data is >= 1
        if (data.length <= 0) {
          this.setState({
            ...this.state,
            gridData: {
              data: data,
              total: data.length,
            },
            dataState: {
              skip: 0,
              take: 10,
            },
          });
        } else {
          this.setState({
            ...this.state,
            gridData: {
              data: data,
              total: data.length,
            },
          });
        }
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent: e.response.data.error.message || "Please try again",
      });
    }
  };

  deleteGroup = async (dataItem) => {
    this.toggleDeleteDialog(dataItem, this.state);
  };

  handleSuccessSnackbar = () => {
    this.setState({
      ...this.state,
      successMessage:
        this.state.openSuccessMessage === true ? this.state.successMessage : "",
      openSuccessMessage: this.state.openSuccessMessage === true ? true : false,
    });
  };

  add = (dataItem) => {
    dataItem.inEdit = undefined;

    const submitURL = "clientGroups";
    let count;
    const options = { token: window.localStorage.getItem("access_token") };
    let payload = dataItem;
    delete payload.inEdit;

    if (payload.groupName === undefined || payload.groupName === "") {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            "Please provide group name in order to create a new group.",
        },
        () => {
          setTimeout(() => {
            this.cancel(dataItem);
          }, 3500);
        }
      );
    } else {
      const createRequest = serverApi(
        "POST",
        `${submitURL}`,
        "",
        payload,
        options
      );
      createRequest
        .then(async (createResponse) => {
          const responseData = await serverApi(
            "GET",
            `clientGroups?filter={"include":[],"where":{"and":[{}]},"limit":"100","skip":"0"}`,
            {},
            "",
            options
          );
          const countMasterEntity = await serverApi(
            "GET",
            "clientGroups/count",
            "",
            "",
            options
          );
          if (countMasterEntity) {
            count = countMasterEntity.data.count;
          }

          this.setState(
            {
              ...this.state,
              gridData: {
                data: responseData.data,
                total: count === undefined ? responseData.data.length : count,
              },
              initialGridData: {
                data: responseData.data,
                total: count === undefined ? responseData.data.length : count,
              },
              deleteVisible: false,
              successMessage: "The group was successfully created.",
              openSuccessMessage: true,
            },
            () => {
              setTimeout(() => {
                this.handleSuccessSnackbar();
              }, 2200);
            }
          );
        })
        .catch((createError) => {
          this.setState(
            {
              ...this.state,
              openErrorModal: true,
              errorModalContent:
                createError.response.data.error.message || "Please try again",
            },
            () => this.discard(dataItem, true)
          );
        });
    }
  };

  discard = (dataItem, hasError = false) => {
    let data = [...this.state.gridData.data];
    const total = this.state.gridData.total;

    if (hasError) {
      data[0].inEdit = true;

      this.setState(
        {
          ...this.state,
          gridData: {
            data: data,
            total: total,
          },
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 3000);
        }
      );
    } else {
      this.removeItem(data, dataItem);

      this.setState(
        {
          ...this.state,
          gridData: {
            data: data,
            total: total,
          },
          visible: false,
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 3000);
        }
      );
    }
  };

  update = async (dataItem) => {
    const data = [...this.state.gridData.data];
    const updatedItem = { ...dataItem, inEdit: undefined };
    this.updateItem(data, updatedItem);

    let editPayload = dataItem;
    if (editPayload.expanded) delete editPayload.expanded;

    if (
      editPayload.groupName === "" ||
      editPayload.groupName === null ||
      editPayload.groupName === undefined
    ) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            "Group name should not be empty in order to edit the group.",
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 3000);
        }
      );
    } else {
      try {
        delete editPayload.inEdit;
        delete editPayload.expanded;
        delete editPayload.clients;

        const editRequest = await AbstractEdit(
          dataItem.id,
          editPayload,
          "clientGroups",
          {
            token: window.localStorage.getItem("access_token"),
          }
        );
        const countRequest = await serverApi(
          "GET",
          "clientGroups/count",
          "",
          "",
          {
            token: window.localStorage.getItem("access_token"),
          }
        );

        if (editRequest.status === 200 && countRequest) {
          this.setState(
            {
              ...this.state,
              gridData: {
                data: data,
                total: countRequest.data.count,
              },
              deleteVisible: false,
              successMessage: "The group was successfully updated.",
              openSuccessMessage: true,
            },
            () => {
              setTimeout(() => {
                this.handleSuccessClose();
              }, 3000);
            }
          );
        }
      } catch (e) {
        this.setState(
          {
            ...this.state,
            openErrorModal: true,
            errorModalContent:
              e.response.data.error.message || "Please try again",
          },
          () => this.discard(dataItem, true)
        );
      }
    }
  };

  cancel = (dataItem) => {
    if (dataItem.groupName === undefined) {
      const data =
        this.state.initialGridData && this.state.initialGridData.data;
      const total = this.state.initialGridData.total;

      this.setState({
        ...this.state,
        gridData: {
          data: data,
          total: total,
        },
        errorModalContent:
          this.state.errorModalContent !== ""
            ? ""
            : this.state.errorModalContent,
        openErrorModal:
          this.state.openErrorModal === true
            ? false
            : this.state.errorModalContent,
      });
    } else {
      let originalItem =
        this.state.initialGridData &&
        this.state.initialGridData.data.find((p) => p.id === dataItem.id);
      originalItem.inEdit = false;

      const data =
        this.state.gridData &&
        this.state.gridData.data.map((item) =>
          item.id === originalItem.id ? originalItem : item
        );
      const total = this.state.gridData.total;

      this.setState({
        ...this.state,
        gridData: {
          data: data,
          total: total,
        },
      });
    }
  };

  addNew = () => {
    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    const newDataItem = {
      inEdit: true,
    };

    this.setState({
      ...this.state,
      gridData: {
        data: [newDataItem, ...this.state.gridData.data],
        total: this.state.gridData.total,
      },
      visible: true,
    });
  };

  updateItem = (data, item) => {
    let index = data.findIndex(
      (p) => p === item || (item.id && p.id === item.id)
    );
    if (index >= 0) {
      data[index] = { ...item };
    }
  };

  itemChange = (event) => {
    const total = this.state.gridData.total;
    const data =
      this.state.gridData &&
      this.state.gridData.data.map((item) =>
        item.id === event.dataItem.id
          ? { ...item, [event.field]: event.value }
          : item
      );

    this.setState({
      ...this.state,
      gridData: {
        data: data,
        total: total,
      },
    });
  };

  removeItem(data, item) {
    let index = data.findIndex(
      (p) => p === item || item.id || p.id === item.id
    );
    if (index >= 0) {
      data.splice(index, 1);
    }
  }

  onClearFilters = () => {
    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));

    let init = JSON.stringify({ take: 10, skip: 0 });
    if (hasFinalParams && hasFinalParams.filter)
      window.localStorage.setItem("finalParams", init);

    this.dataStateChange({ data: { take: 10, skip: 0 } });
  };

  expandChange = (event) => {
    window.localStorage.setItem("expandeId", event.dataItem.id);

    //if add new vessel is true then expand cannot be done !
    if (this.state.gridData.data[0].inEdit === true) {
      return;
    } else {
      event.dataItem.expanded = !event.dataItem.expanded;
    }

    this.forceUpdate();
  };

  handleErrorClose = (loginError) => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      openErrorModalContent: "",
    });
  };

  handleSuccessClose = () => {
    this.setState({
      ...this.state,
      openSuccessMessage: false,
      successMessage: "",
    });
  };

  deleteAction = async () => {
    this.setState({
      ...this.state,
      deleteVisible: false,
    });

    let dataItem = this.state.itemForDelete;

    const data = [...this.state.gridData.data];

    let index = data.findIndex(
      (p) => p === dataItem || dataItem.id || p.id === dataItem.id
    );
    if (index >= 0) {
      data.splice(index, 1);
    }

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let url = "clientGroups";

    try {
      let deleteItemRequest = await AbstractDelete(url, dataItem.id, options);

      if (deleteItemRequest.status === 200) {
        //clear the column filters if grid data is 0 or let them if grid data is >= 1
        if (data.length <= 0) {
          this.setState(
            {
              ...this.state,
              gridData: {
                data: data,
                total: data.length,
              },
              initialGridData: {
                data: data,
                total: data.length,
              },
              dataState: {
                skip: 0,
                take: 100,
              },
              deleteVisible: false,
              successMessage: "The group was successfully deleted.",
              openSuccessMessage: true,
            },
            () => {
              setTimeout(() => {
                this.handleSuccessSnackbar();
              }, 3000);
            }
          );
        } else {
          this.setState(
            {
              ...this.state,
              gridData: {
                data: data,
                total: this.state.gridData.total - 1,
              },
              initialGridData: {
                data: data,
                total: this.state.gridData.total - 1,
              },
              dataState: {
                take: this.state.dataState.take,
                skip: this.state.dataState.skip,
              },
              deleteVisible: false,
              successMessage: "The group was successfully deleted.",
              openSuccessMessage: true,
            },
            () => {
              setTimeout(() => {
                this.handleSuccessSnackbar();
              }, 3000);
            }
          );
        }
      }
    } catch (e) {
      if (e.response.data.error.message.includes("child record found")) {
        this.setState(
          {
            ...this.state,
            openErrorModal: true,
            errorModalContent:
              "Please remove all clients attached to this group and then delete the group.",
          },
          () => {
            setTimeout(() => {
              this.handleErrorClose();
            }, 3000);
          }
        );
      } else {
        this.setState({
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            e.response.data.error.message || "Please try again",
        });
      }
    }
  };

  rowClick = (event) => {
    // window.localStorage.setItem('expandeId', event.dataItem.id)
    //
    // //if add new vessel is true then expand cannot be done !
    // if (this.state.gridData.data[0].inEdit === true) {
    //     return
    // } else {
    //     event.dataItem.expanded = !event.dataItem.expanded;
    // }
    //
    // this.forceUpdate();
  };

  handleSuccessSnackbar = () => {
    this.setState({
      ...this.state,
      successMessage:
        this.state.openSuccessMessage === true ? this.state.successMessage : "",
      openSuccessMessage: !this.state.openSuccessMessage,
    });
  };

  filterChange = (event) => {
    let filteredResults = this.filterData(event.filter);

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.setState({
        countries:
          filteredResults !== undefined
            ? filteredResults
            : this.state.initialCountries,
        loading: false,
      });
    }, this.state.delay);

    this.setState({
      ...this.state,
      loading: true,
    });
  };

  filterData(filter) {
    if (filter.value === "") {
      this.setState({
        countries: this.state.initialCountries,
      });
      return;
    } else {
      const data = this.state.initialCountries;
      filter.operator = "startswith";
      let filteredResults = filterBy(data, filter);
      return filteredResults;
    }
  }

  exportToCsv = async (state, props) => {
    const count = state.gridData.total;
    const where = window.localStorage.getItem("exportFilter") || {};
    const numberOfCalls = Math.min(
      Math.round(count / 1000) + 1,
      5 // Limit to 5000
    );

    const promises = [];
    for (let i = 0; i < numberOfCalls; i++) {
      promises.push(
        this.getExportPromise({
          skip: i * 1000,
          limit: 1000,
          where: JSON.parse(where),
          order: ["id asc"],
        })
      );
    }

    try {
      const responses = await Promise.all(promises);
      let allData = [];
      responses.forEach((resp) => {
        allData = [...allData, ...resp.data];
      });
      if (allData.length > 0) {
        this.setState({
          ...this.state,
          exportData: allData,
        });
        // this._export.save(allData, this.state.columns);
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent: e.response.data.error.message || "Please try again",
      });
    }
  };

  getExportPromise = (filter) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    return new Promise((resolve, reject) => {
      const masterEntityRequest = serverApi(
        "GET",
        `clientGroups?filter=${JSON.stringify(filter)}`,
        "",
        "",
        options
      );
      masterEntityRequest
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getExportData = async () => {
    await this.exportToCsv(this.state, this.props);
  };
  refreshGridDataAfterCrud = async () => {
    let token = window.localStorage.getItem("access_token");
    let options = {
      token: token,
    };

    let refreshParams = window.localStorage.getItem("refreshParams");
    refreshParams = JSON.parse(refreshParams);
    refreshParams.filter.include = ["clients"];
    let responseData = await serverApi(
      "GET",
      `clientGroups`,
      refreshParams,
      "",
      options
    );

    let count;
    const countMasterEntity = await serverApi(
      "GET",
      `clientGroups/count?where=${JSON.stringify(refreshParams.filter.where)}`,
      "",
      "",
      options
    );
    if (countMasterEntity) {
      count = countMasterEntity.data.count;
    }

    let sendObj = {
      gridData: {
        data: responseData.data || [],
        total: count ? count : responseData?.data?.length || 0,
      },
    };

    this.dataReceived(sendObj.gridData, null);
  };

  render() {
    const { gridData } = this.state;
    let checkForEditDato = gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    let hasColumnFilters = true;
    if (found) {
      hasColumnFilters = false;
    }

    let gridHeight = this.state.gridData.data > 100 ? { height: "92vh" } : {};

    const { hasBackofficeRead } = this.props;

    return (
      <div>
        {this.state.deleteVisible && (
          <Dialog onClose={() => this.toggleDeleteDialog({}, this.state)}>
            <p style={{ margin: "25px", textAlign: "center" }}>
              Are you sure you want to delete this group?
            </p>
            <DialogActionsBar>
              <button
                className="k-button"
                onClick={() => this.toggleDeleteDialog({}, this.state)}
              >
                No
              </button>
              <button className="k-button" onClick={() => this.deleteAction()}>
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
        <Grid
          {...this.state.dataState}
          {...this.state.gridData}
          filterable={hasColumnFilters}
          style={gridHeight}
          sortable={true}
          resizable
          onRowClick={(e) => this.rowClick(e)}
          editField={this.editField}
          detail={(dataItem) => (
            <div>
              <ExpandedTabs
                tabs={[
                  {
                    label: "Client-Group",
                    component: (
                      <ClientsBelongToGroup
                        hasBackofficeRead={hasBackofficeRead}
                        {...dataItem}
                      />
                    ),
                  },
                ]}
              ></ExpandedTabs>
            </div>
          )}
          onItemChange={this.itemChange}
          onDataStateChange={this.dataStateChange}
          expandField="expanded"
          onExpandChange={this.expandChange}
          pageable={{
            buttonCount: 5,
            info: true,
            pageSizes: [100, 200, 500, 1000, 1500],
          }}
        >
          <GridToolbar>
            <GridContainer xs={12} direction={"row"}>
              {!hasBackofficeRead && (
                <Tooltip
                  justify={"flex-start"}
                  placement="top"
                  title={"Add Group"}
                >
                  <IconButton onClick={this.addNew}>
                    <FontAwesomeIcon
                      color="#0D5869"
                      onClick={this.addNew}
                      size="1.6x"
                      icon={faPlus}
                    />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip
                justify={"flex-end"}
                placement="top"
                title={"Clear Column Filters"}
              >
                <IconButton
                  disabled={false}
                  aria-label=""
                  onClick={(e) => this.onClearFilters(e)}
                  look="flat"
                >
                  <FontAwesomeIcon
                    color="#0D5869"
                    size="1.6x"
                    icon={faEraser}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip
                justify={"flex-start"}
                placement="top"
                title={"Refresh Data"}
              >
                <IconButton
                  disabled={false}
                  aria-label=""
                  onClick={this.refreshGridDataAfterCrud}
                >
                  <FontAwesomeIcon color="#0D5869" size="1x" icon={faSyncAlt} />
                </IconButton>
              </Tooltip>
            </GridContainer>
          </GridToolbar>

          {!hasBackofficeRead && (
            <Column
              cell={this.CommandCell}
              title="Actions"
              filterable={false}
              width={"250px"}
            />
          )}
          {this.renderGridColumns()}
        </Grid>

        <Loader
          dataState={this.state.dataState}
          onDataRecieved={this.dataReceived}
          include={[]}
          getURL={"clientGroups"}
          countURL={"clientGroups/count"}
          view={"client"}
        />

        <Snackbar
          place="tc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"warning"}
          message={this.state.errorModalContent}
          open={this.state.openErrorModal}
          closeNotification={() =>
            this.handleErrorClose(this.state.errorModalContent)
          }
          close
        />

        <Snackbar
          place="tc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"success"}
          message={this.state.successMessage}
          open={this.state.openSuccessMessage}
          closeNotification={() => this.handleSuccessClose()}
          close
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.MainLayoutReducer.user,
    hasBackofficeRead: state.MainLayoutReducer.user.hasBackofficeRead,
  };
};

export default compose(connect(mapStateToProps, null))(GroupToClient);
