import React from "react";
import {
  Grid,
  GridColumn as Column,
  GridDetailRow,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { columnsSchema } from "./schema";
import { MyCommandCell } from "../../../../Clients/View/components/CellHOC";
import _ from "lodash";
import { DropDownCell } from "../../../../CRM/View/dropDownCell";
import { serverApi } from "../../../../../networking/config";
import { AbstractDelete } from "../../../../../networking/apiCalls";
import { Error } from "@progress/kendo-react-labels";
import { AbstractEdit } from "../../../../../networking/apiCalls";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { IconButton, Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { findApplicableSettings } from "lib/general/utils";
import DropdownFilterCell from "../../../../common/dropdownFilterCell";
import { TextArea } from "@progress/kendo-react-inputs";
import { Window } from "@progress/kendo-react-dialogs";
import { TextField, Switch } from "@material-ui/core";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import Ajv from "ajv";
import { NAV } from "navigatorsdk";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const ajv = new Ajv({ allErrors: true });

const validateDataItem = (setting, dataItem) => {
  if (!setting || !setting.valueSchema) {
    return true;
  }

  const schema = JSON.parse(setting.valueSchema);
  const validate = ajv.compile(schema);

  let dataToValidate = {};

  if (dataItem && typeof dataItem.value === "string" && dataItem.value !== "") {
    try {
      dataToValidate = JSON.parse(dataItem.value);
    } catch (e) {
      console.error("Error parsing dataItem.value:", e);
      return false;
    }
  } else if (dataItem && dataItem.value !== undefined) {
    dataToValidate = dataItem.value;
  }

  console.log("Data to validate:", dataToValidate);
  console.log("Type of data to validate:", typeof dataToValidate);

  if (typeof dataToValidate !== "object" || dataToValidate === null) {
    console.error("Data to validate is not an object.");
    return false;
  }

  if (!validate(dataToValidate)) {
    return false;
  }

  return true;
};

const TextAreaWithValidation = ({ value, onChange, error }) => {
  return (
    <div>
      <TextArea
        value={value}
        onChange={onChange}
        style={{ width: "100%", height: "200px", marginBottom: "15px" }}
      />
      {error && <div style={{ color: "red", marginTop: "10px" }}>{error}</div>}
    </div>
  );
};

export default class ModelSetting extends GridDetailRow {
  editField = "inEdit";
  CommandCell;
  _export;

  constructor(props) {
    super(props);

    this.state = {
      lookupData: [],
      value: JSON.stringify(this.props.dataItem.value, null, 2),
      error: "",
      visibleWindow: false,
      isDropOpened: false,
      columns: (columnsSchema && columnsSchema()) || [],
      gridData: {
        data: [],
        total: 10,
      },
      initialGridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 10,
        skip: 0,
      },
      openErrorModal: false,
      errorModalContent: "",
      delay: 400,
      loading: false,
      model_settings: [],
      windowError: false,
      windowErrorMessage: "",
      disableEditButton: false,
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      disableEditButton: false,
      showSecondAction: true,
      showFirstAction: true,
    });
  }

  async componentDidMount() {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    const response = await serverApi(
      "GET",
      `modelSettings`,
      {
        filter: {
          where: {
            modelID: this.props.dataItem && this.props.dataItem.id,
          },
        },
      },
      "",
      options
    );

    let modelSettingDefinitionsResponse = await serverApi(
      "GET",
      `modelSettingDefinitions`,
      "",
      "",
      options
    );

    modelSettingDefinitionsResponse =
      modelSettingDefinitionsResponse.data.filter(
        (x) => x.modelName === this.props.modelName
      );

    const applicableSettings = findApplicableSettings(
      modelSettingDefinitionsResponse,
      this.props.dataItem
    );

    this.setState(
      {
        ...this.state,
        applicableSettings: applicableSettings,
      },
      () => {
        this.fetchLookupData();
        if (response && response.data.length === 1) {
          this.setState({
            ...this.state,
            modelID: this.props.dataItem.id,
            gridData: {
              data: [response && response.data[0]],
              total: 1,
            },
            initialGridData: {
              data: [response && response.data[0]],
              total: 1,
            },
            model_settings: response.data.sort((a, b) =>
              a.name > b.name ? 1 : -1
            ),
          });
        } else if (response && response.data.length > 1) {
          let finalData = [];
          _.forEach(response.data, (temp) => {
            finalData.push(temp);
          });

          this.setState({
            ...this.state,
            modelID: this.props.dataItem.id,
            gridData: {
              data: finalData,
              total: finalData.length,
            },
            initialGridData: {
              data: finalData,
              total: finalData.length,
            },
            model_settings: response.data,
          });
        } else {
          this.setState({
            ...this.state,
            modelID: this.props.dataItem.id,
            gridData: {
              data: [],
              total: 0,
            },
            initialGridData: {
              data: [],
              total: 0,
            },
            model_settings: response.data,
          });
        }
      }
    );
  }

  fetchLookupData = async () => {
    const { applicableSettings } = this.state;
    const options = {
      token: window.localStorage.getItem("access_token"),
    };

    for (let setting of applicableSettings) {
      if (setting.lookup) {
        let lookupInfo = JSON.parse(setting.lookup);
        if (lookupInfo.model) {
          try {
            const response = await this.fetchDataBasedOnModel(
              lookupInfo.model,
              options
            );
            this.setState((prevState) => ({
              lookupData: {
                ...prevState.lookupData,
                [lookupInfo.model]: response,
              },
            }));
          } catch (error) {
            console.error(
              `Error fetching data for model ${lookupInfo.model}:`,
              error
            );
          }
        }
      }
    }
  };

  fetchDataBasedOnModel = (modelName, options) => {
    //TODO if any other records with lookup apply here the correct api calls
    switch (modelName) {
      case "domain":
        return NAV[modelName.charAt(0).toUpperCase() + modelName.slice(1)].get(
          options
        );
      case "snUser":
        return NAV.Users.get(options);
      default:
        return Promise.reject(
          new Error(`No API defined for model: ${modelName}`)
        );
    }
  };

  handleChange = (event) => {
    let newValue = event.value;

    try {
      const regex = /"mergePDFs"\s*:\s*(true|false|[^\s,}]+)/i;
      const matches = regex.exec(newValue);

      let mergePDFsValue = "false";
      if (matches && matches[1]) {
        mergePDFsValue = /^(true|false)$/i.test(matches[1])
          ? matches[1]
          : "false";
        newValue = newValue.replace(regex, `"mergePDFs":${mergePDFsValue}`);
      }

      let parsedValue = JSON.parse(newValue);
      if (typeof parsedValue !== "object" || parsedValue === null) {
        this.setState({ error: "The input is not a valid JSON object." });
      }

      this.setState({
        ...this.state,
        dataItem: {
          ...this.state.dataItem,
          value: JSON.stringify(parsedValue),
        },
      });
    } catch (err) {
      this.setState({ error: "Invalid JSON: " + err.message });
    }
  };

  handleMergePDFsChange = (checked) => {
    const currentValue = JSON.parse(this.state.dataItem.value);
    currentValue.mergePDFs = checked;
    const jsonValue = JSON.stringify(currentValue);
    this.setState({
      ...this.state,
      dataItem: {
        ...this.state.dataItem,
        value: jsonValue,
      },
    });
  };

  handleDeliveryFormatChange = (newFormat) => {
    const currentValue = JSON.parse(this.state.dataItem.value);
    currentValue.deliveryFormat = newFormat;
    const jsonValue = JSON.stringify(currentValue);
    this.setState({
      ...this.state,
      dataItem: {
        ...this.state.dataItem,
        value: jsonValue,
      },
    });
  };

  handleChangeOLD = (newValue) => {
    try {
      // Check if newValue is a string and parse it, else assume it's already an object
      const parsedValue =
        typeof newValue === "string" ? JSON.parse(newValue) : newValue;

      if (typeof parsedValue !== "object" || parsedValue === null) {
        this.setState({ error: "The input is not a valid JSON object." });
      } else {
        this.setState({
          dataItem: {
            ...this.state.dataItem,
            value: parsedValue,
          },
          error: "", // Clear any previous errors if the JSON is valid
        });
      }
    } catch (err) {
      this.setState({ error: "Invalid JSON: " + err.message }); // Set error message if JSON is invalid
    }
  };

  renderGridColumns = () => {
    let selectionCol = [];
    let normalCols = [];
    let finalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            width="65px"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.isFilterBoolean === "yes") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              cell={DropDownCell}
            />
          );
        } else if (temp.field === "name") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"600"}
              filterCell={(props) => {
                const applicableSettingsData = this.state.applicableSettings
                  .map((x) => x.settingName)
                  .sort((a, b) => (a.text > b.text ? 1 : -1))
                  .filter(Boolean);
                return (
                  <>
                    <DropdownFilterCell
                      {...props}
                      width="450px"
                      filterable={true}
                      data={applicableSettingsData}
                    />
                  </>
                );
              }}
              cell={(props) => {
                const applicableSettingsData = this.state.applicableSettings
                  .map((x) => ({
                    text: x.description,
                    value: x.settingName,
                  }))
                  .sort((a, b) => (a.description > b.description ? 1 : -1))
                  .filter(Boolean);
                return (
                  <>
                    <DropDownCell
                      {...props}
                      filterable={true}
                      width="450px"
                      data={applicableSettingsData}
                    />
                  </>
                );
              }}
            />
          );
        } else {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={
                temp.field === "id" ||
                temp.field === "modelName" ||
                temp.field === "modelID" ||
                temp.editable
                  ? false
                  : true
              } //id not editable on edit
            />
          );
        }
      });

    finalCols = [...selectionCol, ...normalCols];
    return finalCols;
  };

  updateItem = (data, item) => {
    let index = data.findIndex(
      (p) => p === item || (item.id && p.id === item.id)
    );
    if (index >= 0) {
      data[index] = { ...item };
    }
  };

  handleErrorClose = () => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      openErrorModalContent: "",
    });
  };

  update = async (dataItem) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    let parsedValue;
    const data = [...this.state.gridData.data];
    const updatedItem = { ...dataItem, inEdit: undefined };

    this.updateItem(data, updatedItem);
    try {
      parsedValue = JSON.parse(dataItem.value);
      if (parsedValue) {
        if (parsedValue?.deliveryFormat) {
          if (parsedValue?.deliveryFormat === "Print") {
            parsedValue.deliveryFormat = "print";
          } else if (parsedValue?.deliveryFormat === "Post") {
            parsedValue.deliveryFormat = "post";
          }
        }
      }
    } catch (error) {}

    let editPayload = {
      name: dataItem.name,
      value: JSON.stringify(parsedValue) || dataItem.value,
      modelName: this.props.modelName,
      modelID: this.props.dataItem.id,
    };

    //check on how the value should be saved

    //case 1 if saveAsJSONstringify then the value should be saved as JSON.stringified
    if (this.state.saveAsJSONstringify) {
      editPayload.value = JSON.stringify(editPayload.value);
    }

    if (editPayload.name === "" || !editPayload.value) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent: "Required fields to edit: Name and Value",
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 3500);
        }
      );
    } else {
      try {
        const editRequest = await AbstractEdit(
          dataItem.id,
          editPayload,
          "modelSettings",
          options
        );
        if (editRequest.status === 200) {
          this.setState({
            ...this.state,
            dataItem: {},
            editFormVisible: false,
            saveAsJSONstringify: false,
            gridData: {
              data: data,
              total: this.state.gridData.total,
            },
          });
        }
      } catch (e) {
        let initialGridData = this.state.initialGridData;
        this.setState(
          {
            ...this.state,
            gridData: initialGridData,
            openErrorModal: true,
            errorModalContent:
              e?.response?.data?.error?.message ||
              "Update record failed, please try again.",
          },
          () => this.discard(dataItem, true)
        );
      }
    }
  };

  enterEdit = (dataItem) => {
    this.setState({
      editFormVisible: true,
      dataItem: {
        ...dataItem,
      },
      originalDataItem: JSON.parse(JSON.stringify(dataItem)),
    });
  };

  cancel = (dataItem) => {
    const originalItem =
      this.state.initialGridData &&
      this.state.initialGridData.data.find((p) => p.id === dataItem.id);

    if (originalItem) {
      originalItem.inEdit = false;

      const data =
        this.state.gridData &&
        this.state.gridData.data.map((item) =>
          item.id === originalItem.id ? originalItem : item
        );

      const total = this.state.gridData.total;

      this.setState({
        ...this.state,
        gridData: {
          data: data,
          total: total,
        },
        openErrorModal: false,
        openErrorModalContent: "",
      });
    }
  };

  discard = (dataItem, hasError = false) => {
    let data = [...this.state.gridData.data];
    const total = this.state.gridData.total;

    if (hasError) {
      data[0].inEdit = true;
      this.setState({
        gridData: {
          data: data,
          total: total,
        },
      });
    } else {
      this.removeItem(data, dataItem);

      this.setState({
        gridData: {
          data: data,
          total: total,
        },
        visible: false,
      });
    }
  };

  removeItem(data, item) {
    let index = data.findIndex((p) => p === item || p.id === item.id);
    if (index >= 0) {
      data.splice(index, 1);
    }
  }

  remove = async (dataItem) => {
    const data = [...this.state.gridData.data];

    let index = data.findIndex((p) => p === dataItem || p.id === dataItem.id);
    if (index >= 0) {
      data.splice(index, 1);
    }

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let url = "modelSettings";

    try {
      let deleteItemRequest = await AbstractDelete(
        url,
        `${dataItem.id || ""}`,
        options
      );

      if (deleteItemRequest.status === 200) {
        //clear the column filters if grid data is 0 or let them if grid data is >= 1
        if (data.length <= 0) {
          this.setState({
            ...this.state,
            gridData: {
              data: data,
              total: data.length,
            },
            initialGridData: {
              data: data,
              total: data.length,
            },
            dataState: {
              skip: 0,
              take: 10,
            },
          });
        } else {
          this.setState({
            ...this.state,
            gridData: {
              data: data,
              total: data.length,
            },
            initialGridData: {
              data: data,
              total: data.length,
            },
          });
        }
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent:
          e?.response?.data?.error?.message || "Please try again",
      });
    }
  };

  addNew = async () => {
    this.setState({
      ...this.state,
      addFormVisible: true,
    });
  };

  itemChange = (event) => {
    const total = this.state.gridData.total;
    const data =
      this.state.gridData &&
      this.state.gridData.data.map((item) =>
        item.id === event.dataItem.id
          ? { ...item, [event.field]: event.value }
          : item
      );

    this.setState({
      gridData: {
        data: data,
        total: total,
      },
    });
  };

  add = (dataItem) => {
    dataItem.inEdit = undefined;

    const submitURL = "modelSettings";
    let count;
    const options = { token: window.localStorage.getItem("access_token") };
    let payload = dataItem;
    payload = {
      ...payload,
      modelName: this.props.modelName,
      modelID: this.props.dataItem.id,
    };
    delete payload.inEdit;

    //case 1 if saveAsJSONstringify then the value should be saved as JSON.stringified
    if (this.state.saveAsJSONstringify) {
      payload.value = JSON.stringify(payload.value);
    }

    const setting = this.state.applicableSettings.find(
      (x) => x.settingName === this.state.dataItem.name
    );

    const isValid = validateDataItem(setting || {}, dataItem);

    if (!isValid) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            "Based on the requirements the json schema is not valid",
        },
        () => {
          return null;
        }
      );
    }

    if (
      !payload.name ||
      payload.name === "" ||
      !payload.value ||
      payload.value === ""
    ) {
      this.setState(
        {
          ...this.state,
          addFormVisible: false,
          openErrorModal: true,
          errorModalContent:
            "Error required fields to create a new model setting: Name and Value",
        },
        () => {
          setTimeout(() => {
            this.cancel(dataItem);
            this.setState({
              ...this.state,
              gridData: this.state.initialGridData,
              openErrorModal: false,
              errorModalContent: "",
            });
          }, 3500);
        }
      );
    } else {
      const createRequest = serverApi(
        "POST",
        `${submitURL}`,
        "",
        payload,
        options
      );
      createRequest
        .then(async (createResponse) => {
          const responseData = await serverApi(
            "GET",
            `modelSettings`,
            {
              filter: {
                where: {
                  modelID: this.props.dataItem && this.props.dataItem.id,
                },
              },
            },
            "",
            options
          );
          const countDep = await serverApi(
            "GET",
            "modelSettings/count",
            "",
            "",
            options
          );
          if (countDep) {
            count = countDep.data.count;
          }

          this.setState({
            ...this.state,
            dataItem: {},
            saveAsJSONstringify: false,
            addFormVisible: false,
            gridData: {
              data: responseData.data,
              total: count === undefined ? responseData.data.length : count,
            },
            initialGridData: {
              data: responseData.data,
              total: count === undefined ? responseData.data.length : count,
            },
            deleteVisible: false,
          });
        })
        .catch((createError) => {
          this.setState(
            {
              ...this.state,
              openErrorModal: true,
              errorModalContent:
                createError?.response?.data?.error?.message ||
                "Create record failed,please try again",
            },
            () => this.discard(dataItem, true)
          );
        });
    }
  };

  toggleWindow = () => {
    this.setState({
      ...this.state,
      editFormVisible: !this.state.editFormVisible,
      dataItem: {},
      originalDataItem: null,
    });
  };

  renderForm = () => {
    const dataItem = this.state.dataItem || {};

    const setting = (this.state.applicableSettings || []).find(
      (s) => s.settingName === dataItem?.name
    );

    if (!setting && !dataItem) {
      return null;
    }

    const applicableSettingsData = this.state.applicableSettings
      .map((x) => x.settingName)
      .sort((a, b) => (a.settingName > b.settingName ? 1 : -1))
      .filter(Boolean);

    const renderValueComponent = () => {
      if (!setting) return;
      if (setting.options) {
        const options = JSON.parse(setting?.options).map((opt) => ({
          text: opt,
          value: opt,
        }));

        if (setting?.isArray) {
          return (
            <MultiSelect
              data={options.map((x) => x.text)}
              value={
                typeof dataItem.value === "string" && dataItem.value !== ""
                  ? JSON.parse(dataItem.value)
                  : dataItem.value
              }
              onChange={(e) =>
                this.setState({
                  ...this.state,
                  saveAsJSONstringify: true,
                  dataItem: {
                    ...dataItem,
                    value: e.target.value,
                  },
                })
              }
            />
          );
        }
        return (
          <DropDownList
            data={options.map((x) => x.text)}
            value={dataItem?.value}
            onChange={(e) =>
              this.setState({
                ...this.state,
                dataItem: {
                  ...dataItem,
                  value: e.target.value,
                },
              })
            }
          />
        );
      }
      if (setting.lookup) {
        const lookupDetails = JSON.parse(setting.lookup);
        const { model, valueField, textField } = lookupDetails;
        const lookupData = this.state.lookupData[model] || [];
        const data = lookupData.map((item) => item[textField]);
        if (setting.isArray) {
          return (
            <MultiSelect
              data={data}
              value={this.state.selectedDescriptions || []}
              onChange={(e) => {
                const newSelectedDescriptions = e.target.value;

                const { lookupData, applicableSettings, dataItem } = this.state;
                const modelInfo = JSON.parse(
                  applicableSettings.find(
                    (x) => x.settingName === dataItem.name
                  ).lookup
                );
                const records = lookupData[modelInfo.model];

                const newSelectedIDs = newSelectedDescriptions
                  .map((desc) => {
                    const record = records.find(
                      (record) => record.description === desc
                    );
                    return record ? record.id : null;
                  })
                  .filter((id) => id !== null);

                this.setState({
                  ...this.state,
                  dataItem: {
                    ...dataItem,
                    value: newSelectedIDs,
                  },
                  selectedDescriptions: newSelectedDescriptions,
                });
              }}
            />
          );
        }
        return (
          <DropDownList
            data={data}
            value={this.state.selectedDescriptions || []}
            onChange={(e) => {
              const { lookupData, applicableSettings, dataItem } = this.state;
              const modelInfo = JSON.parse(
                applicableSettings.find((x) => x.settingName === dataItem.name)
                  .lookup
              );
              const records = lookupData[modelInfo.model];

              const newSelectedIDs = [e.target.value]
                .map((desc) => {
                  const record = records.find(
                    (record) => record.description === desc
                  );
                  return record ? record.id : null;
                })
                .filter((id) => id !== null);

              this.setState({
                ...this.state,
                dataItem: {
                  ...dataItem,
                  value: newSelectedIDs,
                },
                selectedDescriptions: e.target.value,
              });
            }}
          />
        );
      }
      if (setting.valueType === "boolean") {
        return (
          <Switch
            checked={dataItem.value === true || dataItem.value === "true"}
            onChange={(e) =>
              this.setState({
                ...this.state,
                dataItem: {
                  ...dataItem,
                  value: e.target.checked,
                },
              })
            }
          />
        );
      }
      if (setting.valueType === "string") {
        if (setting.isArray) {
          return (
            <TextField
              label="Comma-separated values"
              variant="outlined"
              fullWidth={true}
              onChange={(e) =>
                this.setState({
                  ...this.state,
                  dataItem: {
                    ...dataItem,
                    value: e.target.value.split(","),
                  },
                })
              }
              value={dataItem.value?.join(",") || ""}
              style={{ marginBottom: "15px" }}
            />
          );
        }
        return (
          <TextField
            variant="outlined"
            fullWidth={true}
            onChange={(e) =>
              this.setState({
                ...this.state,
                dataItem: {
                  ...dataItem,
                  value: e.target.value,
                },
              })
            }
            value={dataItem.value || ""}
            style={{ marginBottom: "15px" }}
          />
        );
      }
      if (setting.valueType === "integer") {
        if (setting.isArray) {
          return (
            <TextField
              label="Comma-separated integers"
              variant="outlined"
              fullWidth={true}
              onChange={(e) =>
                this.setState({
                  ...this.state,
                  dataItem: {
                    ...dataItem,
                    value: e.target.value.split(",").map(Number),
                  },
                })
              }
              value={dataItem.value?.join(",") || ""}
              style={{ marginBottom: "15px" }}
            />
          );
        }
        return (
          <TextField
            variant="outlined"
            fullWidth={true}
            type="number"
            onChange={(e) =>
              this.setState({
                ...this.state,
                dataItem: {
                  ...dataItem,
                  value: Number(e.target.value),
                },
              })
            }
            value={dataItem.value || ""}
            style={{ marginBottom: "15px" }}
          />
        );
      }
      if (setting.valueType === "object") {
        if (setting.settingName === "AUTO_SEND_INVOICE_PACKET") {
          return (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <span>
                  <FormControlLabel
                    label="Merge PDFs"
                    control={
                      <Switch
                        checked={
                          JSON.parse(this.state.dataItem.value)?.mergePDFs ===
                          true
                        }
                        onChange={(e) =>
                          this.handleMergePDFsChange(e.target.checked)
                        }
                        color="primary"
                      />
                    }
                  />
                </span>
                <span>
                  <DropDownList
                    label="Delivery Format"
                    data={[
                      { text: "Print", value: "print" },
                      { text: "Post", value: "post" },
                    ].map((x) => x.text)}
                    value={
                      JSON.parse(this.state.dataItem.value)?.deliveryFormat
                    }
                    onChange={(e) =>
                      this.handleDeliveryFormatChange(e.target.value)
                    }
                  />
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label>Value:</label>
                <TextAreaWithValidation
                  value={this.state.dataItem.value}
                  onChange={this.handleChange}
                  error={this.state.error}
                />
              </div>
            </div>
          );
        } else {
          return (
            <div>
              <label>Value:</label>
              <TextAreaWithValidation
                value={this.state.dataItem.value}
                onChange={this.handleChange}
                error={this.state.error}
              />
            </div>
          );
        }
      }

      return (
        <TextField
          variant="outlined"
          fullWidth={true}
          onChange={(e) =>
            this.setState({
              ...this.state,
              dataItem: {
                ...dataItem,
                value: e.target.value,
              },
            })
          }
          value={dataItem.value || ""}
          style={{ marginBottom: "15px" }}
        />
      );
    };
    return (
      <div>
        <div
          style={{
            width: "600px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <DropDownList
            style={{
              width: "500px",
              color: "#4ac2d0",
              marginBottom: "10px",
            }}
            data={applicableSettingsData}
            onChange={(e) =>
              this.setState({
                ...this.state,
                dataItem: {
                  ...this.state.dataItem,
                  name: e.target.value,
                  value: "",
                },
              })
            }
            value={this.state?.dataItem?.name}
          />
          {renderValueComponent()}
        </div>
        <div>
          <br />
          <button
            type="button"
            className="k-button k-primary"
            onClick={(e) =>
              this.state.addFormVisible
                ? this.add(this.state.dataItem)
                : this.state.editFormVisible
                ? this.update(this.state.dataItem)
                : null
            }
            disabled={false}
          >
            Save
          </button>
          <button
            type="button"
            className="k-button k-secondary"
            onClick={(e) => {
              this.setState({
                ...this.state,
                addFormVisible: false,
                editFormVisible: false,
                error: "",
              });
            }}
            disabled={false}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };

  render() {
    const dataItem = this.props.dataItem;
    const {
      gridData,
      openErrorModal,
      errorModalContent,
      editFormVisible,
      addFormVisible,
    } = this.state;
    const hasEditedItem = gridData && gridData.data.some((p) => p.inEdit);
    const { hasBackofficeRead } = this.props;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <div>
          {openErrorModal ? (
            <Error style={{ color: "red" }}>
              <h3>{errorModalContent || ""}</h3>
            </Error>
          ) : null}

          <Grid
            {...gridData}
            filterable={false}
            style={{ className: "grid-no-select" }}
            sortable={true}
            resizable
            editField={this.editField}
            onItemChange={this.itemChange}
            pageable={false}
          >
            <GridToolbar>
              {!hasBackofficeRead && (
                <Tooltip justify={"flex-start"} placement="top" title={"Add"}>
                  <IconButton onClick={this.addNew}>
                    <FontAwesomeIcon
                      color="#0D5869"
                      onClick={this.addNew}
                      size="1.6x"
                      icon={faPlus}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </GridToolbar>

            {!hasBackofficeRead && (
              <Column
                cell={this.CommandCell}
                title="Actions"
                filterable={false}
                width={hasEditedItem ? "240px" : "220px"}
              />
            )}

            {this.renderGridColumns(dataItem)}
          </Grid>

          {editFormVisible && (
            <Window
              title="Update record"
              onClose={this.toggleWindow}
              width={800}
              height={950}
            >
              {this.renderForm()}
            </Window>
          )}

          {addFormVisible && (
            <Window
              title="Create record"
              onClose={() =>
                this.setState({
                  ...this.state,
                  addFormVisible: false,
                  dataItem: {},
                })
              }
              width={800}
              height={550}
            >
              {this.renderForm()}
            </Window>
          )}
        </div>
      </div>
    );
  }
}
