export const columnsSchema = () => [
  {
    field: "id",
    title: "ID",
    visible: true,
    minWidth: 170,
    sortable: true,
    filterable: true,
    locked: true,
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
  },
  {
    field: "domainId",
    title: "Domain ID",
    visible: true,
    minWidth: 170,
    sortable: true,
    filterable: true,
    noDisabledColumn: false,
    locked: false,
  },
  {
    field: "domain",
    title: "Domain",
    visible: true,
    minWidth: 170,
    sortable: true,
    filterable: true,
    noDisabledColumn: false,
    locked: false,
  },
  {
    field: "deptId",
    title: "Department ID",
    visible: true,
    minWidth: 170,
    sortable: true,
    filterable: true,
    noDisabledColumn: false,
    locked: false,
  },
  {
    field: "department",
    title: "Department",
    visible: true,
    minWidth: 170,
    sortable: true,
    filterable: true,
    noDisabledColumn: false,
    locked: false,
  },
  {
    field: "modelName",
    title: "Model Name",
    visible: true,
    minWidth: 400,
    sortable: true,
    filterable: true,
    noDisabledColumn: false,
    locked: false,
  },
  {
    field: "modelAction",
    title: "Model Action",
    visible: true,
    minWidth: 400,
    sortable: true,
    filterable: true,
    noDisabledColumn: false,
    locked: false,
  },
  {
    field: "amountFrom",
    title: "Amount From",
    visible: true,
    minWidth: 400,
    sortable: true,
    filterable: true,
    noDisabledColumn: false,
    locked: false,
  },
  {
    field: "approversCount",
    title: "Approvers Count",
    visible: true,
    minWidth: 200,
    sortable: true,
    filterable: true,
    noDisabledColumn: false,
    locked: false,
  },
  {
    field: "approvers",
    title: "Approvers",
    visible: true,
    minWidth: 400,
    sortable: true,
    filterable: true,
    noDisabledColumn: false,
    locked: false,
  },
];
