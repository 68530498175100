import React from "react";
import ReactDOM from "react-dom";
import Application from "./Application";
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import * as serviceWorker from "./serviceWorker";
import "./theme/all.css";
import "core-js";
import "regenerator-runtime/runtime";
import "./index.scss";

const tracingOriginsProduction = [
  "https://prod2.swift-navigator.com",
  "https://prod2.swift-navigator.com:443/api",
];

const tracingOriginsDevelopment = [
  "https://dev2.swift-navigator.com",
  "https://dev2.swift-navigator.com:443/api",
];

const tracingOriginsUat = [
  "https://uat2.swift-navigator.com",
  "https://uat2.swift-navigator.com:443/api",
];

Sentry.init({
  dsn:
    "https://a2a68acbf1a94b889f0e0989c0b22bd3@o1164862.ingest.sentry.io/6268568",
  integrations: [
    new BrowserTracing({
      tracingOrigins:
        process.env.REACT_APP_MODE === "production"
          ? tracingOriginsProduction
          : process.env.REACT_APP_MODE === "uat"
          ? tracingOriginsUat
          : tracingOriginsDevelopment,
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  // beforeSend: (event) => {
  //   return event;
  // },
  tracesSampleRate: 0.2,
  environment: process.env.REACT_APP_MODE || "uat" || "development",
  enabled:
    process.env.REACT_APP_MODE === "production" ||
    process.env.REACT_APP_MODE === "uat" ||
    process.env.REACT_APP_MODE === "development",
});

ReactDOM.render(<Application/>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
