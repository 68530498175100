export const schema = () => [

  {
    field: 'id',
    title: 'ID',
    visible: true,
    minWidth: 140,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
    // cell: true
  },
  {
    field: 'userid',
    title: 'User ID',
    visible: true,
    minWidth: 150,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
    // cell: true
  },
  //  {
  //   field: 'username',
  //   title: 'User Name',
  //   visible: true,
  //   minWidth: 370,
  //   sortable: true,
  //   filterable: true,
  //   noDisabledColumn: true,
  //   notShownInTableColumnSelection: false,
  //   // cell: true
  // },
  {
    field: 'model_name',
    title: 'Model Name',
    visible: true,
    minWidth: 270,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
    // cell: true
  },
  {
    field: 'obj_before',
    title: 'Object before',
    visible: true,
    minWidth: 500,
    sortable: true,
    filterable: false,
    noDisabledColumn: false,
    notShownInTableColumnSelection: false,
    // locked: 'locked',
  }, {
    field: 'obj_after',
    title: 'Object after',
    visible: true,
    minWidth: 500,
    sortable: true,
    filterable: false,
    noDisabledColumn: false,
    notShownInTableColumnSelection: false,
    // locked: 'locked',
  },
  {
    field: 'updated',
    title: 'Last Date Action',
    visible: true,
    minWidth: 350,
    sortable: true,
    filterable: true,
    // locked: 'locked',
  },
]
