import {serverApi} from "../config";

export const ChangePasswordCall = (params, url, email, username, options) => {

  try {

    let payload = {
      "newPassword": params.newPassword || "",
      "password": params.newPassword,
      "email": email,
      "username": username,
      "customPassword": params.customPassword || false,
      "typeOfUser": params.typeOfUser,
      "informOperatorEmail": params.informOperatorEmail || []
    }

    const response = serverApi('PATCH', url, '', payload, options)

    return response;
  } catch (error) {
    return error
  }
}
