import React, { Component } from "react";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { Loader } from "../View/loader";
import { columnsSchema } from "./schema";
import { MyCommandCell } from "../../CRM/View/myCommandCell";
import _ from "lodash";
import { DropDownCell } from "../../CRM/View/dropDownCell";
import { serverApi } from "../../../networking/config";
import GridContainer from "../../../components/Grid/GridContainer";
import { IconButton, Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import Snackbar from "../../../components/Snackbar/Snackbar";
import { groupBy } from "@progress/kendo-data-query";
import {
  setExpandedState,
  setGroupIds,
} from "@progress/kendo-react-data-tools";
import { formatDatesOnTable } from "../../../lib/GeneralUtils/utils";

const initialGroup = [
  {
    field: "username",
  },
];

const processWithGroups = (data, group) => {
  const newDataState = groupBy(data, group);
  setGroupIds({
    data: newDataState,
    group: group,
  });
  return newDataState;
};
export default class WebsocketUsers extends Component {
  editField = "inEdit";
  CommandCell;

  constructor(props) {
    super(props);

    this.state = {
      deleteVisible: false,
      visibleWindow: false,
      isDropOpened: false,
      columns: (columnsSchema && columnsSchema()) || [],
      group: initialGroup,
      result: processWithGroups([], initialGroup),
      collapsedState: [],
      gridData: {
        data: [],
        total: 0,
      },
      initialGridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 1000,
        skip: 0,
      },
      openErrorModal: false,
      errorModalContent: "",
      delay: 400,
      loading: false,
      selectedRowIndex: 0,
      selectedItem: null,
      googleOptions: [],
      expandedRowIndex: null,

      warning: false,
    };

    this.CommandCell = MyCommandCell({
      // edit: this.enterEdit,
      // remove: this.disableSupplier,
      // add: this.add,
      // update: this.update,
      // discard: this.discard,
      // cancel: this.cancel,
      // editField: this.editField,
      hasDisable: true,
      showSecondAction: true,
    });
  }

  componentDidMount() {
    let isFirstLoad = true;
    window.localStorage.setItem("isFirstLoad", isFirstLoad);

    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));

    if (hasFinalParams) {
      this.setState({
        ...this.state,
        dataState: hasFinalParams,
      });
    }

    if (hasChangedColumns) {
      this.setState({
        ...this.state,
        columns: hasChangedColumns,
      });
    }
  }

  componentWillUnmount() {
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));
    if (hasChangedColumns) {
      window.localStorage.removeItem("cols");
    }

    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    if (hasFinalParams) {
      window.localStorage.removeItem("finalParams");
    }

    let hasFlagEnabled = window.localStorage.getItem("flagSet");
    if (hasFlagEnabled) {
      window.localStorage.removeItem("flagSet");
    }

    let hasExportFilter = window.localStorage.getItem("exportFilter");
    if (hasExportFilter) {
      window.localStorage.removeItem("exportFilter");
    }
  }

  renderGridColumns = () => {
    let selectionCol = [];
    let normalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            width="65px"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.isFilterBoolean === "yes") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              cell={DropDownCell}
            />
          );
        } else {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={temp.field === "id" || temp.editable ? false : true}
            />
          );
        }
      });

    return [...selectionCol, ...normalCols];
  };

  exportToCsv = async (state, props) => {
    const count = state.gridData.total;
    const where = window.localStorage.getItem("exportFilter") || {};
    const numberOfCalls = Math.min(
      Math.round(count / 1000) + 1,
      5 // Limit to 5000
    );

    const promises = [];
    for (let i = 0; i < numberOfCalls; i++) {
      promises.push(
        this.getPromise({
          skip: i * 1000,
          limit: 1000,
          where: !_.isEmpty(where) ? JSON.parse(where) : {},
          order: ["id asc"],
        })
      );
    }

    try {
      const responses = await Promise.all(promises);
      let allData = [];
      responses.forEach((resp) => {
        allData = [...allData, ...resp.data];
      });
      if (allData.length > 0) {
        this.setState({
          ...this.state,
          exportData: allData,
        });
        // this._export.save(allData, this.state.columns);
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent:
          e?.response?.data?.error?.message ||
          "Exporting data failed.Please try again",
      });
    }
  };

  getPromise = (filter) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    return new Promise((resolve, reject) => {
      const request = serverApi(
        "GET",
        `wsConnections?filter=${JSON.stringify(filter)}`,
        "",
        "",
        options
      );
      request
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getExportData = async () => {
    await this.exportToCsv(this.state, this.props);
  };

  refreshGridDataAfterCrud = async () => {
    let token = window.localStorage.getItem("access_token");
    let options = {
      token: token,
    };

    let refreshParams = window.localStorage.getItem("refreshParams");
    refreshParams = JSON.parse(refreshParams);
    let responseData = await serverApi(
      "GET",
      `wsConnections`,
      refreshParams,
      "",
      options
    );
    responseData.data = responseData.data.map((x) => ({
      ...x,
      roles: (x.roles || []).map((x) => x.name).join(", "),
    }));
    function findOcc(arr, key) {
      let arr2 = [];
      arr.forEach((x) => {
        // Checking if there is any object in arr2
        // which contains the key value
        if (
          arr2.some((val) => {
            return val[key] === x[key];
          })
        ) {
          // If yes! then increase the occurrence by 1
          arr2.forEach((k) => {
            if (k[key] === x[key]) {
              k["occurrence"]++;
            }
          });
        } else {
          // If not! Then create a new object initialize
          // it with the present iteration key's value and
          // set the occurrence to 1
          let a = {};
          a[key] = x[key];
          a["occurrence"] = 1;
          arr2.push(a);
        }
      });
      return arr2;
    }
    let key = "id";
    let final = findOcc(responseData.data, key);
    const temp = responseData.data.map((item) => {
      return [item[key], item];
    });
    let uniqueUsers = [...new Map(temp).values()];
    let finalData = [];
    final.forEach((occ) => {
      (uniqueUsers || []).forEach((dt) => {
        if (occ.id === dt.id) {
          finalData.push({
            ...dt,
            connections: occ.occurrence,
          });
        }
      });
    });

    let sendObj = {
      gridData: {
        data: responseData.data || [],
        total: responseData.data?.length > 0 ? responseData.data?.length : 0,
      },
    };

    this.dataReceived(sendObj.gridData, null);
  };

  dataReceived = (gridData, reqGridParams) => {
    const finalData = formatDatesOnTable(gridData, ["started"]);

    let data = {
      data: finalData.data,
      count: finalData.total,
    };
    //move system-api user at the bottom of the table
    let lastRows = data.data.filter((x) => x.id === 19012);
    let firstRows = data.data.filter((x) => x.id !== 19012);
    data.data = [...firstRows, ...lastRows];

    this.setState({
      ...this.state,
      group: initialGroup,
      result: processWithGroups(data.data || [], initialGroup), // remove it or do it in gridData
      collapsedState: [],
      gridData: data,
      initialGridData: data,
      dataState:
        reqGridParams === !_.isEmpty(reqGridParams)
          ? reqGridParams
          : this.state.dataState,
    });
    // , () => this.handleGroupChange({})
  };

  handleGroupChange = (event) => {
    const newDataState = processWithGroups(
      this.state.gridData.data,
      event?.group
    );
    this.setState({
      ...this.state,
      result: newDataState,
      group: event?.group,
    });
  };

  expandChange = (event) => {
    const item = event.dataItem;

    if (item.groupId) {
      const collapsedIds = !event.value
        ? [...this.state.collapsedState, item.groupId]
        : this.state.collapsedState.filter(
            (groupId) => groupId !== item.groupId
          );
      this.setState({
        ...this.state,
        collapsedState: collapsedIds,
      });
    }
  };

  render() {
    // const { gridData } = this.state;
    // const hasEditedItem = gridData && gridData.data.some(p => p.inEdit);

    const result = this.state.result;
    const collapsedState = this.state.collapsedState;
    let newData = setExpandedState({
      data: result,
      collapsedIds: collapsedState,
    });

    return (
      <div>
        <Grid
          {...this.state.dataState}
          // {...this.state.gridData}
          data={newData}
          filterable={false}
          style={{ height: "92vh" }}
          onGroupChange={this.handleGroupChange}
          group={this.state.group}
          sortable={true}
          groupable={true}
          resizable
          expandField="expanded"
          onExpandChange={this.expandChange}
          // onRowClick={e => this.rowClick(e)}
          editField={this.editField}
          onItemChange={this.itemChange}
          onDataStateChange={this.dataStateChange}
          // detail={(dataItem) => (<div>
          //   <ExpandedTabs
          //     tabs={[
          //       {
          //         label: "Versions & Connections",
          //         component: <div>{console.log(dataItem)}</div>, // Index
          //       },

          //     ]}
          //   >
          //   </ExpandedTabs>
          // </div>
          // )}
          // pageable={{
          //   buttonCount: 5,
          //   info: true,
          //   pageSizes: [50, 100, 200, 500],
          // }}
        >
          <GridToolbar>
            <GridContainer xs={12} direction={"row"}>
              <Tooltip
                justify={"flex-start"}
                placement="top"
                title={"Refresh Data"}
              >
                <IconButton
                  disabled={false}
                  aria-label=""
                  onClick={this.refreshGridDataAfterCrud}
                >
                  <FontAwesomeIcon color="#0D5869" size="1x" icon={faSyncAlt} />
                </IconButton>
              </Tooltip>
            </GridContainer>
          </GridToolbar>

          {this.renderGridColumns()}
        </Grid>

        <Loader
          dataState={this.state.dataState}
          onDataRecieved={this.dataReceived}
          include={[]}
          getURL={"wsConnections"}
          countURL={"wsConnections/count"}
          // view={'wsUsers'}
        />

        <Snackbar
          place="tc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"warning"}
          message={this.state.errorModalContent}
          open={this.state.openErrorModal}
          closeNotification={() =>
            this.handleErrorClose(this.state.errorModalContent)
          }
          close
        />

        <Snackbar
          place="tc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"success"}
          message={this.state.successMessage}
          open={this.state.openSuccessMessage}
          // closeNotification={() => this.handleSuccessSnackbar(this.state.successMessage)}
          // close
        />
      </div>
    );
  }
}
