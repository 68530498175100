import React from "react";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { serverApi } from "../../../../../networking/config";
import { filterBy } from "@progress/kendo-data-query";

export class AutoCompleteVesselName extends React.Component {
  state = {
    vessel_names: [],
    valueVesselName:
      this.props.dataItem &&
      this.props.dataItem.vessel &&
      this.props.dataItem.vessel.vessel_name,
  };

  onChange = (event) => {
    //prevent enter keyboard
    if (
      event.nativeEvent.type === "keydown" &&
      event.nativeEvent.keyCode === 13
    )
      return;

    const { value } = event.target;

    if (value === "") {
      this.setState({
        openedVesselNames: false,
        valueVesselName: value,
      });
    } else {
      this.setState(
        {
          openedVesselNames: false,
          valueVesselName: value,
        },
        () => {
          this.onAutoCompleteChange(event, value);
        }
      );
    }
  };

  onAutoCompleteChange = (event, value) => {
    const { name } = event.target;

    let stateData = {};

    stateData =
      value.length < 3
        ? {
            data:
              this.state.vessel_names.length > 0
                ? this.state.vessel_names.map((temp) => temp.vessel_name)
                : [],
            openedVesselNames: false,
          }
        : { data: this.filterData(value, name), openedVesselNames: true };

    let eventType = event.nativeEvent.type;

    let valueSelected =
      eventType === "click" ||
      (eventType === "keydown" && event.nativeEvent.keyCode === 13);

    if (valueSelected) {
      this.setState({
        ...this.state,
        postVesselID: this.state.vessel_names[0].id || "",
      });
    }

    if (valueSelected && stateData.data.includes(value)) {
      stateData.openedVesselNames = false;
    }

    this.setState(
      {
        valueVesselName: value,
        ...stateData,
      },
      () => this.fetchAutocompleteData(value)
    );
  };

  fetchAutocompleteData = (filterValue) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let filter = {
      where: {
        vessel_name: { regexp: `/${filterValue}/i` },
      },
      order: ["vessel_name asc"],
    };

    const fetchDropdownData = serverApi(
      "GET",
      `vessels`,
      { filter: filter },
      "",
      options
    );

    fetchDropdownData
      .then((response) => {
        if (response.data.length === 0) {
          setTimeout(() => {
            this.setState({
              ...this.state,
              valueVesselName:
                (this.props.dataItem &&
                  this.props.dataItem.vessel &&
                  this.props.dataItem.vessel.vessel_name) ||
                "",
              vessel_names: response.data,
            });
          }, 700);
        } else if (response.data.length > 1) {
          let editItem = {
            vessel:
              (response.data && response.data.length > 0 && response.data[0]) ||
              "",
            clientID: this.props.dataItem.clientID || "",
            vesselID:
              (response.data &&
                response.data.length > 0 &&
                response.data[0].id) ||
              "",
            inEdit: true,
          };

          window.localStorage.setItem("editItem", JSON.stringify(editItem));

          this.setState({
            ...this.state,
            vessel_names: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);

        throw error;
      });
  };

  filterData = (value, name) => {
    const data = this.state.vessel_names.map((test) => test.vessel_name);

    const filter = {
      value: value,
      operator: "startswith",
      ignoreCase: true,
    };

    let results = filterBy(data, filter);
    return results;
  };

  listNoDataRender = (element) => {
    const noData = (
      <h4 style={{ fontSize: "1em" }}>
        <span className="k-icon k-i-warning" style={{ fontSize: "2.5em" }} />
        <br />
        <br />
        No vessels names found
      </h4>
    );

    return React.cloneElement(element, { ...element.props }, noData);
  };

  render() {
    const { dataItem } = this.props;
    const dataValue =
      dataItem && dataItem.vessel && dataItem.vessel.vessel_name === null
        ? ""
        : dataItem && dataItem.vessel && dataItem.vessel.vessel_name;

    return (
      <td>
        {dataItem && dataItem.inEdit ? (
          <AutoComplete
            style={{ width: "470px" }}
            data={this.state.vessel_names.map((temp) => temp.vessel_name)}
            value={this.state.valueVesselName}
            name={"vessel.vessel_name"}
            onChange={(e) => this.onChange(e)}
            listNoDataRender={this.listNoDataRender}
          />
        ) : (
          (dataValue !== undefined && dataValue.toString()) || ""
        )}
      </td>
    );
  }
}
