import React, { Component } from "react";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { Loader } from "./loader";
import { AbstractEdit } from "../../../networking/apiCalls";
import _ from "lodash";
import { MyCommandCell } from "../../CRM/View/myCommandCell";
import { DropDownCell } from "../../CRM/View/dropDownCell.js";
import { serverApi } from "../../../networking/config";
import {
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
} from "@material-ui/core";
import customSelectStyles from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import { columnsSchema, exportSchema } from "../View/schema";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import GridContainer from "../../../components/Grid/GridContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser, faSyncAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import VesselToClientExpanded from "./components/expandedVesselToClientsGrid";
import VesselClientHistory from "./components/VesselClientHistory";
import Snackbar from "../../../components/Snackbar/Snackbar";
import ViewSettings from "../../common/ViewSettings";
import { Window } from "@progress/kendo-react-dialogs";
import { fleetmonSchema } from "./schema";
import { Menu, MenuItem as KendoMenuItem } from "@progress/kendo-react-layout";
import { Popup } from "@progress/kendo-react-popup";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { Error } from "@progress/kendo-react-labels";
import SuccessModal from "../../../components/SuccessModal";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import axios from "axios";
import Search from "@material-ui/icons/Search";
import ExpandedTabs from "../../common/ExpandedTabs";
import FleetsToVessel from "./components/VesselFleets";
import UserToVessel from "./components/UserToVessel";
import DropdownFilterCell from "../../common/dropdownFilterCell";
import {
  formatDatesOnTable,
  showCreatorOnTable,
  removeUnwantedKeyValuesFromArr,
} from "../../../lib/GeneralUtils/utils";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import Contacts from "../../MasterEntityContacts/Contacts";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { AbstractDelete } from "../../../networking/apiCalls";
import { roles } from "../../../lib/Enums/index.js";

import "./vessel.css";
import "./Vessel.scss";

class Vessels extends Component {
  editField = "inEdit";
  CommandCell;

  constructor(props) {
    super(props);

    this.state = {
      existingOperatorsAvailable: [],
      client_names: [],
      noneVesselTypeValue: "",
      imoPlaceHolderTxt: `*In order to create a vessel as an office provide 0000000
                    and click search button`,
      visibleWindowTitle: "Create vessel process",
      newVesselName: "",
      moveActiveOrdersSwitchFlag: false,
      activeOrders: null,
      allowEditVesselName: false,
      hideColumns: false,
      editRecordsFleetmonWindow: false,
      is_yatchChecked: false,
      deleteAllClients: false,
      visibleWindow: false,
      createVesselOfficeWindow: false,
      createVesselManuallyWindow: false,
      isDropOpened: false,
      columns: (columnsSchema && columnsSchema()) || [],
      initialColumns: (columnsSchema && columnsSchema()) || [],
      fleetmonSchema: (fleetmonSchema && fleetmonSchema()) || [],
      gridData: {
        data: [],
        total: 0,
      },
      initialGridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 100,
        skip: 0,
      },
      openErrorModal: false,
      errorModalContent: "",
      delay: 400,
      loading: false,
      expandedRowIndex: null,
      selectedRowIndex: 0,
      selectedItem: null,
      vesselToClientSelected: [],
      // activeOrdersClientSelected: [],
      clientsAvailable: [],
      existingClientsAvailable: [],
      existingClientsAvailableInitial: [],
      activeOrdersFlg: false,
      noneVesselTypeFlg: false,
      isGovernment: false,
      moveToNewClientFlg: false,
      moveActiveOrdersToNewClient: false,
      vessel_name: "",
      clientIDs: [],
      activeOrderClientSelected: "",
      isDisabled: true,
      // showAddVesselManually: false,
      showFleetmonCreateVessel: true,
      notifyEmailOperator: "",
      rightClickText: [
        "Change Vessel Name",
        "Set to inactive",
        "Set to active",
      ],
      countries: [
        { id: "0", text: "" },
        { id: "AD", text: "Andorra(AD)" },
        { id: "AE", text: "United Arab Emirates(AE)" },
        { id: "AF", text: "Afghanistan(AF)" },
        { id: "AG", text: "Antigua and Barbuda(AG)" },
        { id: "AI", text: "Anguilla(AI)" },
        { id: "AL", text: "Albania(AL)" },
        { id: "AM", text: "Armenia(AM)" },
        { id: "AO", text: "Angola(AO)" },
        { id: "AQ", text: "Antarctica(AQ)" },
        { id: "AR", text: "Argentina(AR)" },
        { id: "AS", text: "American Samoa(AS)" },
        { id: "AT", text: "Austria(AT)" },
        { id: "AU", text: "Australia(AU)" },
        { id: "AW", text: "Aruba(AW)" },
        { id: "AX", text: "Alland Islands(AX)" },
        { id: "AZ", text: "Azerbaijan(AZ)" },
        { id: "BA", text: "Bosnia and Herzegovina(BA)", phone: "387" },
        { id: "BB", text: "Barbados(BB)", phone: "1-246" },
        { id: "BD", text: "Bangladesh(BD)", phone: "880" },
        { id: "BE", text: "Belgium(BE)", phone: "32" },
        { id: "BF", text: "Burkina Faso(BF)", phone: "226" },
        { id: "BG", text: "Bulgaria(BG)", phone: "359" },
        { id: "BH", text: "Bahrain(BH)", phone: "973" },
        { id: "BI", text: "Burundi(BI)", phone: "257" },
        { id: "BJ", text: "Benin(BJ)", phone: "229" },
        { id: "BL", text: "Saint Barthelemy(BL)", phone: "590" },
        { id: "BM", text: "Bermuda(BM)", phone: "1-441" },
        { id: "BN", text: "Brunei Darussalam(BN)", phone: "673" },
        { id: "BO", text: "Bolivia(BO)", phone: "591" },
        { id: "BR", text: "Brazil(BR)", phone: "55" },
        { id: "BS", text: "Bahamas(BS)", phone: "1-242" },
        { id: "BT", text: "Bhutan(BT)", phone: "975" },
        { id: "BV", text: "Bouvet Island(BV)", phone: "47" },
        { id: "BW", text: "Botswana(BW)", phone: "267" },
        { id: "BY", text: "Belarus(BY)", phone: "375" },
        { id: "BZ", text: "Belize(BZ)", phone: "501" },
        { id: "CA", text: "Canada(CA)", phone: "1", suggested: true },
        { id: "CC", text: "Cocos (Keeling) Islands(CC)", phone: "61" },
        { id: "CD", text: "Congo, Democratic Republic(CD)", phone: "243" },
        { id: "CG", text: "Congo, Republic of the(CG)", phone: "242" },
        { id: "CF", text: "Central African Republic(CF)", phone: "236" },
        { id: "CH", text: "Switzerland(CH)", phone: "41" },
        { id: "CI", text: "Cote d'Ivoire(CI)", phone: "225" },
        { id: "CK", text: "Cook Islands(CK)", phone: "682" },
        { id: "CL", text: "Chile(CL)", phone: "56" },
        { id: "CM", text: "Cameroon(CM)", phone: "237" },
        { id: "CN", text: "China(CN)", phone: "86" },
        { id: "CO", text: "Colombia(CO)", phone: "57" },
        { id: "CR", text: "Costa Rica(CR)", phone: "506" },
        { id: "CU", text: "Cuba(CU)", phone: "53" },
        { id: "CV", text: "Cape Verde(CV)", phone: "238" },
        { id: "CW", text: "Curacao(CW)", phone: "599" },
        { id: "CX", text: "Christmas Island(CX)", phone: "61" },
        { id: "CY", text: "Cyprus(CY)", phone: "357" },
        { id: "CZ", text: "Czech Republic(CZ)", phone: "420" },
        { id: "DE", text: "Germany(DE)", phone: "49", suggested: true },
        { id: "DJ", text: "Djibouti(DJ)", phone: "253" },
        { id: "DK", text: "Denmark(DK)", phone: "45" },
        { id: "DM", text: "Dominica(DM)", phone: "1-767" },
        { id: "DO", text: "Dominican Republic(DO)", phone: "1-809" },
        { id: "DZ", text: "Algeria(DZ)", phone: "213" },
        { id: "EC", text: "Ecuador(EC)", phone: "593" },
        { id: "EE", text: "Estonia(EE)", phone: "372" },
        { id: "EG", text: "Egypt(EG)", phone: "20" },
        { id: "EH", text: "Western Sahara(EH)", phone: "212" },
        { id: "ER", text: "Eritrea(ER)", phone: "291" },
        { id: "ES", text: "Spain(ES)", phone: "34" },
        { id: "ET", text: "Ethiopia(ET)", phone: "251" },
        { id: "FI", text: "Finland(FI)", phone: "358" },
        { id: "FJ", text: "Fiji(FJ)", phone: "679" },
        { id: "FK", text: "Falkland Islands (Malvinas)(FK)", phone: "500" },
        { id: "FM", text: "Micronesia, Federated States of(FM)", phone: "691" },
        { id: "FO", text: "Faroe Islands(FO)", phone: "298" },
        { id: "FR", text: "France(FR)", phone: "33", suggested: true },
        { id: "GA", text: "Gabon(GA)", phone: "241" },
        { id: "GB", text: "United Kingdom(GB)", phone: "44" },
        { id: "GD", text: "Grenada(GD)", phone: "1-473" },
        { id: "GE", text: "Georgia(GE)", phone: "995" },
        { id: "GF", text: "French Guiana(GF)", phone: "594" },
        { id: "GG", text: "Guernsey(GG)", phone: "44" },
        { id: "GH", text: "Ghana(GH)", phone: "233" },
        { id: "GI", text: "Gibraltar(GI)", phone: "350" },
        { id: "GL", text: "Greenland(GL)", phone: "299" },
        { id: "GM", text: "Gambia(GM)", phone: "220" },
        { id: "GN", text: "Guinea(GN)", phone: "224" },
        { id: "GP", text: "Guadeloupe(GP)", phone: "590" },
        { id: "GQ", text: "Equatorial Guinea(GQ)", phone: "240" },
        { id: "GR", text: "Greece(GR)", phone: "30", suggested: true },
        {
          id: "GS",
          text: "South Georgia and the South Sandwich Islands(GS)",
          phone: "500",
        },
        { id: "GT", text: "Guatemala(GT)", phone: "502" },
        { id: "GU", text: "Guam(GU)", phone: "1-671" },
        { id: "GW", text: "Guinea-Bissau(GW)", phone: "245" },
        { id: "GY", text: "Guyana(GY)", phone: "592" },
        { id: "HK", text: "Hong Kong(HK)", phone: "852" },
        {
          id: "HM",
          text: "Heard Island and McDonald Islands(HM)",
          phone: "672",
        },
        { id: "HN", text: "Honduras(HN)", phone: "504" },
        { id: "HR", text: "Croatia(HR)", phone: "385" },
        { id: "HT", text: "Haiti(HT)", phone: "509" },
        { id: "HU", text: "Hungary(HU)", phone: "36" },
        { id: "ID", text: "Indonesia(ID)", phone: "62" },
        { id: "IE", text: "Ireland(IE)", phone: "353" },
        { id: "IL", text: "Israel(IL)", phone: "972" },
        { id: "IM", text: "Isle of Man(IM)", phone: "44" },
        { id: "IN", text: "India(IN)", phone: "91" },
        { id: "IO", text: "British Indian Ocean Territory(IO)", phone: "246" },
        { id: "IQ", text: "Iraq(IQ)", phone: "964" },
        { id: "IR", text: "Iran, Islamic Republic of(IR)", phone: "98" },
        { id: "IS", text: "Iceland(IS)", phone: "354" },
        { id: "IT", text: "Italy(IT)", phone: "39" },
        { id: "JE", text: "Jersey(JE)", phone: "44" },
        { id: "JM", text: "Jamaica(JM)", phone: "1-876" },
        { id: "JO", text: "Jordan(JO)", phone: "962" },
        { id: "JP", text: "Japan(JP)", phone: "81", suggested: true },
        { id: "KE", text: "Kenya(KE)", phone: "254" },
        { id: "KG", text: "Kyrgyzstan(KG)", phone: "996" },
        { id: "KH", text: "Cambodia(KH)", phone: "855" },
        { id: "KI", text: "Kiribati(KI)", phone: "686" },
        { id: "KM", text: "Comoros(KM)", phone: "269" },
        { id: "KN", text: "Saint Kitts and Nevis(KN)", phone: "1-869" },
        {
          id: "KP",
          text: "Korea, Democratic People's Republic of(KP)",
          phone: "850",
        },
        { id: "KR", text: "Korea, Republic of(KR)", phone: "82" },
        { id: "KW", text: "Kuwait(KW)", phone: "965" },
        { id: "KY", text: "Cayman Islands(KY)", phone: "1-345" },
        { id: "KZ", text: "Kazakhstan(KZ)", phone: "7" },
        {
          id: "LA",
          text: "Lao People's Democratic Republic(LA)",
          phone: "856",
        },
        { id: "LB", text: "Lebanon(LB)", phone: "961" },
        { id: "LC", text: "Saint Lucia(LC)", phone: "1-758" },
        { id: "LI", text: "Liechtenstein(LI)", phone: "423" },
        { id: "LK", text: "Sri Lanka(LK)", phone: "94" },
        { id: "LR", text: "Liberia(LR)", phone: "231" },
        { id: "LS", text: "Lesotho(LS)", phone: "266" },
        { id: "LT", text: "Lithuania(LT)", phone: "370" },
        { id: "LU", text: "Luxembourg(LU)", phone: "352" },
        { id: "LV", text: "Latvia(LV)", phone: "371" },
        { id: "LY", text: "Libya(LY)", phone: "218" },
        { id: "MA", text: "Morocco(MA)", phone: "212" },
        { id: "MC", text: "Monaco(MC)", phone: "377" },
        { id: "MD", text: "Moldova, Republic of(MD)", phone: "373" },
        { id: "ME", text: "Montenegro(ME)", phone: "382" },
        { id: "MF", text: "Saint Martin (French part)(MF)", phone: "590" },
        { id: "MG", text: "Madagascar(MG)", phone: "261" },
        { id: "MH", text: "Marshall Islands(MH)", phone: "692" },
        {
          id: "MK",
          text: "Macedonia, the Former Yugoslav Republic of(MK)",
          phone: "389",
        },
        { id: "ML", text: "Mali(ML)", phone: "223" },
        { id: "MM", text: "Myanmar(MM)", phone: "95" },
        { id: "MN", text: "Mongolia(MN)", phone: "976" },
        { id: "MO", text: "Macao(MO)", phone: "853" },
        { id: "MP", text: "Northern Mariana Islands(MP)", phone: "1-670" },
        { id: "MQ", text: "Martinique(MQ)", phone: "596" },
        { id: "MR", text: "Mauritania(MR)", phone: "222" },
        { id: "MS", text: "Montserrat(MS)", phone: "1-664" },
        { id: "MT", text: "Malta(MT)", phone: "356" },
        { id: "MU", text: "Mauritius(MU)", phone: "230" },
        { id: "MV", text: "Maldives(MV)", phone: "960" },
        { id: "MW", text: "Malawi(MW)", phone: "265" },
        { id: "MX", text: "Mexico(MX)", phone: "52" },
        { id: "MY", text: "Malaysia(MY)", phone: "60" },
        { id: "MZ", text: "Mozambique(MZ)", phone: "258" },
        { id: "NA", text: "Namibia(NA)", phone: "264" },
        { id: "NC", text: "New Caledonia(NC)", phone: "687" },
        { id: "NE", text: "Niger(NE)", phone: "227" },
        { id: "NF", text: "Norfolk Island(NF)", phone: "672" },
        { id: "NG", text: "Nigeria(NG)", phone: "234" },
        { id: "NI", text: "Nicaragua(NI)", phone: "505" },
        { id: "NL", text: "Netherlands(NL)", phone: "31" },
        { id: "NO", text: "Norway(NO)", phone: "47" },
        { id: "NP", text: "Nepal(NP)", phone: "977" },
        { id: "NR", text: "Nauru(NR)", phone: "674" },
        { id: "NU", text: "Niue(NU)", phone: "683" },
        { id: "NZ", text: "New Zealand(NZ)", phone: "64" },
        { id: "OM", text: "Oman(OM)", phone: "968" },
        { id: "PA", text: "Panama(PA)", phone: "507" },
        { id: "PE", text: "Peru(PE)", phone: "51" },
        { id: "PF", text: "French Polynesia(PF)", phone: "689" },
        { id: "PG", text: "Papua New Guinea(PG)", phone: "675" },
        { id: "PH", text: "Philippines(PH)", phone: "63" },
        { id: "PK", text: "Pakistan(PK)", phone: "92" },
        { id: "PL", text: "Poland(PL)", phone: "48" },
        { id: "PM", text: "Saint Pierre and Miquelon(PM)", phone: "508" },
        { id: "PN", text: "Pitcairn(PN)", phone: "870" },
        { id: "PR", text: "Puerto Rico(PR)", phone: "1" },
        { id: "PS", text: "Palestine, State of(PS)", phone: "970" },
        { id: "PT", text: "Portugal(PT)", phone: "351" },
        { id: "PW", text: "Palau(PW)", phone: "680" },
        { id: "PY", text: "Paraguay(PY)", phone: "595" },
        { id: "QA", text: "Qatar(QA)", phone: "974" },
        { id: "RE", text: "Reunion(RE)", phone: "262" },
        { id: "RO", text: "Romania(RO)", phone: "40" },
        { id: "RS", text: "Serbia(RS)", phone: "381" },
        { id: "RU", text: "Russian Federation(RU)", phone: "7" },
        { id: "RW", text: "Rwanda(RW)", phone: "250" },
        { id: "SA", text: "Saudi Arabia(SA)", phone: "966" },
        { id: "SB", text: "Solomon Islands(SB)", phone: "677" },
        { id: "SC", text: "Seychelles(SC)", phone: "248" },
        { id: "SD", text: "Sudan(SD)", phone: "249" },
        { id: "SE", text: "Sweden(SE)", phone: "46" },
        { id: "SG", text: "Singapore(SG)", phone: "65" },
        { id: "SH", text: "Saint Helena(SH)", phone: "290" },
        { id: "SI", text: "Slovenia(SI)", phone: "386" },
        { id: "SJ", text: "Svalbard and Jan Mayen(SJ)", phone: "47" },
        { id: "SK", text: "Slovakia(SK)", phone: "421" },
        { id: "SL", text: "Sierra Leone(SL)", phone: "232" },
        { id: "SM", text: "San Marino(SM)", phone: "378" },
        { id: "SN", text: "Senegal(SN)", phone: "221" },
        { id: "SO", text: "Somalia(SO)", phone: "252" },
        { id: "SR", text: "Suriname(SR)", phone: "597" },
        { id: "SS", text: "South Sudan(SS)", phone: "211" },
        { id: "ST", text: "Sao Tome and Principe(ST)", phone: "239" },
        { id: "SV", text: "El Salvador(SV)", phone: "503" },
        { id: "SX", text: "Sint Maarten (Dutch part)(SX)", phone: "1-721" },
        { id: "SY", text: "Syrian Arab Republic(SY)", phone: "963" },
        { id: "SZ", text: "Swaziland(SZ)", phone: "268" },
        { id: "TC", text: "Turks and Caicos Islands(TC)", phone: "1-649" },
        { id: "TD", text: "Chad(TD)", phone: "235" },
        { id: "TF", text: "French Southern Territories(TF)", phone: "262" },
        { id: "TG", text: "Togo(TG)", phone: "228" },
        { id: "TH", text: "Thailand(TH)", phone: "66" },
        { id: "TJ", text: "Tajikistan(TJ)", phone: "992" },
        { id: "TK", text: "Tokelau(TK)", phone: "690" },
        { id: "TL", text: "Timor-Leste(TL)", phone: "670" },
        { id: "TM", text: "Turkmenistan(TM)", phone: "993" },
        { id: "TN", text: "Tunisia(TN)", phone: "216" },
        { id: "TO", text: "Tonga(TO)", phone: "676" },
        { id: "TR", text: "Turkey(TR)", phone: "90" },
        { id: "TT", text: "Trinidad and Tobago(TT)", phone: "1-868" },
        { id: "TV", text: "Tuvalu(TV)", phone: "688" },
        { id: "TW", text: "Taiwan, Province of China(TW)", phone: "886" },
        { id: "TZ", text: "United Republic of Tanzania(TZ)", phone: "255" },
        { id: "UA", text: "Ukraine(UA)", phone: "380" },
        { id: "UG", text: "Uganda(UG)", phone: "256" },
        { id: "US", text: "United States(US)", phone: "1", suggested: true },
        { id: "UY", text: "Uruguay(UY)", phone: "598" },
        { id: "UZ", text: "Uzbekistan(UZ)", phone: "998" },
        { id: "VA", text: "Holy See (Vatican City State)(VA)", phone: "379" },
        {
          id: "VC",
          text: "Saint Vincent and the Grenadines(VC)",
          phone: "1-784",
        },
        { id: "VE", text: "Venezuela(VE)", phone: "58" },
        { id: "VG", text: "British Virgin Islands(VG)", phone: "1-284" },
        { id: "VI", text: "US Virgin Islands(VI)", phone: "1-340" },
        { id: "VN", text: "Vietnam(VN)", phone: "84" },
        { id: "VU", text: "Vanuatu(VU)", phone: "678" },
        { id: "WF", text: "Wallis and Futuna(WF)", phone: "681" },
        { id: "WS", text: "Samoa(WS)", phone: "685" },
        { id: "XK", text: "Kosovo(XK)", phone: "383" },
        { id: "YE", text: "Yemen(YE)", phone: "967" },
        { id: "YT", text: "Mayotte(YT)", phone: "262" },
        { id: "ZA", text: "South Africa(ZA)", phone: "27" },
        { id: "ZM", text: "Zambia(ZM)", phone: "260" },
        { id: "ZW", text: "Zimbabwe(ZW)", phone: "263" },
      ].slice(),
      initialCountries: [
        { id: "0", text: "" },
        { id: "AD", text: "Andorra(AD)" },
        { id: "AE", text: "United Arab Emirates(AE)" },
        { id: "AF", text: "Afghanistan(AF)" },
        { id: "AG", text: "Antigua and Barbuda(AG)" },
        { id: "AI", text: "Anguilla(AI)" },
        { id: "AL", text: "Albania(AL)" },
        { id: "AM", text: "Armenia(AM)" },
        { id: "AO", text: "Angola(AO)" },
        { id: "AQ", text: "Antarctica(AQ)" },
        { id: "AR", text: "Argentina(AR)" },
        { id: "AS", text: "American Samoa(AS)" },
        { id: "AT", text: "Austria(AT)" },
        { id: "AU", text: "Australia(AU)" },
        { id: "AW", text: "Aruba(AW)" },
        { id: "AX", text: "Alland Islands(AX)" },
        { id: "AZ", text: "Azerbaijan(AZ)" },
        { id: "BA", text: "Bosnia and Herzegovina(BA)", phone: "387" },
        { id: "BB", text: "Barbados(BB)", phone: "1-246" },
        { id: "BD", text: "Bangladesh(BD)", phone: "880" },
        { id: "BE", text: "Belgium(BE)", phone: "32" },
        { id: "BF", text: "Burkina Faso(BF)", phone: "226" },
        { id: "BG", text: "Bulgaria(BG)", phone: "359" },
        { id: "BH", text: "Bahrain(BH)", phone: "973" },
        { id: "BI", text: "Burundi(BI)", phone: "257" },
        { id: "BJ", text: "Benin(BJ)", phone: "229" },
        { id: "BL", text: "Saint Barthelemy(BL)", phone: "590" },
        { id: "BM", text: "Bermuda(BM)", phone: "1-441" },
        { id: "BN", text: "Brunei Darussalam(BN)", phone: "673" },
        { id: "BO", text: "Bolivia(BO)", phone: "591" },
        { id: "BR", text: "Brazil(BR)", phone: "55" },
        { id: "BS", text: "Bahamas(BS)", phone: "1-242" },
        { id: "BT", text: "Bhutan(BT)", phone: "975" },
        { id: "BV", text: "Bouvet Island(BV)", phone: "47" },
        { id: "BW", text: "Botswana(BW)", phone: "267" },
        { id: "BY", text: "Belarus(BY)", phone: "375" },
        { id: "BZ", text: "Belize(BZ)", phone: "501" },
        { id: "CA", text: "Canada(CA)", phone: "1", suggested: true },
        { id: "CC", text: "Cocos (Keeling) Islands(CC)", phone: "61" },
        { id: "CD", text: "Congo, Democratic Republic(CD)", phone: "243" },
        { id: "CG", text: "Congo, Republic of the(CG)", phone: "242" },
        { id: "CF", text: "Central African Republic(CF)", phone: "236" },
        { id: "CH", text: "Switzerland(CH)", phone: "41" },
        { id: "CI", text: "Cote d'Ivoire(CI)", phone: "225" },
        { id: "CK", text: "Cook Islands(CK)", phone: "682" },
        { id: "CL", text: "Chile(CL)", phone: "56" },
        { id: "CM", text: "Cameroon(CM)", phone: "237" },
        { id: "CN", text: "China(CN)", phone: "86" },
        { id: "CO", text: "Colombia(CO)", phone: "57" },
        { id: "CR", text: "Costa Rica(CR)", phone: "506" },
        { id: "CU", text: "Cuba(CU)", phone: "53" },
        { id: "CV", text: "Cape Verde(CV)", phone: "238" },
        { id: "CW", text: "Curacao(CW)", phone: "599" },
        { id: "CX", text: "Christmas Island(CX)", phone: "61" },
        { id: "CY", text: "Cyprus(CY)", phone: "357" },
        { id: "CZ", text: "Czech Republic(CZ)", phone: "420" },
        { id: "DE", text: "Germany(DE)", phone: "49", suggested: true },
        { id: "DJ", text: "Djibouti(DJ)", phone: "253" },
        { id: "DK", text: "Denmark(DK)", phone: "45" },
        { id: "DM", text: "Dominica(DM)", phone: "1-767" },
        { id: "DO", text: "Dominican Republic(DO)", phone: "1-809" },
        { id: "DZ", text: "Algeria(DZ)", phone: "213" },
        { id: "EC", text: "Ecuador(EC)", phone: "593" },
        { id: "EE", text: "Estonia(EE)", phone: "372" },
        { id: "EG", text: "Egypt(EG)", phone: "20" },
        { id: "EH", text: "Western Sahara(EH)", phone: "212" },
        { id: "ER", text: "Eritrea(ER)", phone: "291" },
        { id: "ES", text: "Spain(ES)", phone: "34" },
        { id: "ET", text: "Ethiopia(ET)", phone: "251" },
        { id: "FI", text: "Finland(FI)", phone: "358" },
        { id: "FJ", text: "Fiji(FJ)", phone: "679" },
        { id: "FK", text: "Falkland Islands (Malvinas)(FK)", phone: "500" },
        { id: "FM", text: "Micronesia, Federated States of(FM)", phone: "691" },
        { id: "FO", text: "Faroe Islands(FO)", phone: "298" },
        { id: "FR", text: "France(FR)", phone: "33", suggested: true },
        { id: "GA", text: "Gabon(GA)", phone: "241" },
        { id: "GB", text: "United Kingdom(GB)", phone: "44" },
        { id: "GD", text: "Grenada(GD)", phone: "1-473" },
        { id: "GE", text: "Georgia(GE)", phone: "995" },
        { id: "GF", text: "French Guiana(GF)", phone: "594" },
        { id: "GG", text: "Guernsey(GG)", phone: "44" },
        { id: "GH", text: "Ghana(GH)", phone: "233" },
        { id: "GI", text: "Gibraltar(GI)", phone: "350" },
        { id: "GL", text: "Greenland(GL)", phone: "299" },
        { id: "GM", text: "Gambia(GM)", phone: "220" },
        { id: "GN", text: "Guinea(GN)", phone: "224" },
        { id: "GP", text: "Guadeloupe(GP)", phone: "590" },
        { id: "GQ", text: "Equatorial Guinea(GQ)", phone: "240" },
        { id: "GR", text: "Greece(GR)", phone: "30", suggested: true },
        {
          id: "GS",
          text: "South Georgia and the South Sandwich Islands(GS)",
          phone: "500",
        },
        { id: "GT", text: "Guatemala(GT)", phone: "502" },
        { id: "GU", text: "Guam(GU)", phone: "1-671" },
        { id: "GW", text: "Guinea-Bissau(GW)", phone: "245" },
        { id: "GY", text: "Guyana(GY)", phone: "592" },
        { id: "HK", text: "Hong Kong(HK)", phone: "852" },
        {
          id: "HM",
          text: "Heard Island and McDonald Islands(HM)",
          phone: "672",
        },
        { id: "HN", text: "Honduras(HN)", phone: "504" },
        { id: "HR", text: "Croatia(HR)", phone: "385" },
        { id: "HT", text: "Haiti(HT)", phone: "509" },
        { id: "HU", text: "Hungary(HU)", phone: "36" },
        { id: "ID", text: "Indonesia(ID)", phone: "62" },
        { id: "IE", text: "Ireland(IE)", phone: "353" },
        { id: "IL", text: "Israel(IL)", phone: "972" },
        { id: "IM", text: "Isle of Man(IM)", phone: "44" },
        { id: "IN", text: "India(IN)", phone: "91" },
        { id: "IO", text: "British Indian Ocean Territory(IO)", phone: "246" },
        { id: "IQ", text: "Iraq(IQ)", phone: "964" },
        { id: "IR", text: "Iran, Islamic Republic of(IR)", phone: "98" },
        { id: "IS", text: "Iceland(IS)", phone: "354" },
        { id: "IT", text: "Italy(IT)", phone: "39" },
        { id: "JE", text: "Jersey(JE)", phone: "44" },
        { id: "JM", text: "Jamaica(JM)", phone: "1-876" },
        { id: "JO", text: "Jordan(JO)", phone: "962" },
        { id: "JP", text: "Japan(JP)", phone: "81", suggested: true },
        { id: "KE", text: "Kenya(KE)", phone: "254" },
        { id: "KG", text: "Kyrgyzstan(KG)", phone: "996" },
        { id: "KH", text: "Cambodia(KH)", phone: "855" },
        { id: "KI", text: "Kiribati(KI)", phone: "686" },
        { id: "KM", text: "Comoros(KM)", phone: "269" },
        { id: "KN", text: "Saint Kitts and Nevis(KN)", phone: "1-869" },
        {
          id: "KP",
          text: "Korea, Democratic People's Republic of(KP)",
          phone: "850",
        },
        { id: "KR", text: "Korea, Republic of(KR)", phone: "82" },
        { id: "KW", text: "Kuwait(KW)", phone: "965" },
        { id: "KY", text: "Cayman Islands(KY)", phone: "1-345" },
        { id: "KZ", text: "Kazakhstan(KZ)", phone: "7" },
        {
          id: "LA",
          text: "Lao People's Democratic Republic(LA)",
          phone: "856",
        },
        { id: "LB", text: "Lebanon(LB)", phone: "961" },
        { id: "LC", text: "Saint Lucia(LC)", phone: "1-758" },
        { id: "LI", text: "Liechtenstein(LI)", phone: "423" },
        { id: "LK", text: "Sri Lanka(LK)", phone: "94" },
        { id: "LR", text: "Liberia(LR)", phone: "231" },
        { id: "LS", text: "Lesotho(LS)", phone: "266" },
        { id: "LT", text: "Lithuania(LT)", phone: "370" },
        { id: "LU", text: "Luxembourg(LU)", phone: "352" },
        { id: "LV", text: "Latvia(LV)", phone: "371" },
        { id: "LY", text: "Libya(LY)", phone: "218" },
        { id: "MA", text: "Morocco(MA)", phone: "212" },
        { id: "MC", text: "Monaco(MC)", phone: "377" },
        { id: "MD", text: "Moldova, Republic of(MD)", phone: "373" },
        { id: "ME", text: "Montenegro(ME)", phone: "382" },
        { id: "MF", text: "Saint Martin (French part)(MF)", phone: "590" },
        { id: "MG", text: "Madagascar(MG)", phone: "261" },
        { id: "MH", text: "Marshall Islands(MH)", phone: "692" },
        {
          id: "MK",
          text: "Macedonia, the Former Yugoslav Republic of(MK)",
          phone: "389",
        },
        { id: "ML", text: "Mali(ML)", phone: "223" },
        { id: "MM", text: "Myanmar(MM)", phone: "95" },
        { id: "MN", text: "Mongolia(MN)", phone: "976" },
        { id: "MO", text: "Macao(MO)", phone: "853" },
        { id: "MP", text: "Northern Mariana Islands(MP)", phone: "1-670" },
        { id: "MQ", text: "Martinique(MQ)", phone: "596" },
        { id: "MR", text: "Mauritania(MR)", phone: "222" },
        { id: "MS", text: "Montserrat(MS)", phone: "1-664" },
        { id: "MT", text: "Malta(MT)", phone: "356" },
        { id: "MU", text: "Mauritius(MU)", phone: "230" },
        { id: "MV", text: "Maldives(MV)", phone: "960" },
        { id: "MW", text: "Malawi(MW)", phone: "265" },
        { id: "MX", text: "Mexico(MX)", phone: "52" },
        { id: "MY", text: "Malaysia(MY)", phone: "60" },
        { id: "MZ", text: "Mozambique(MZ)", phone: "258" },
        { id: "NA", text: "Namibia(NA)", phone: "264" },
        { id: "NC", text: "New Caledonia(NC)", phone: "687" },
        { id: "NE", text: "Niger(NE)", phone: "227" },
        { id: "NF", text: "Norfolk Island(NF)", phone: "672" },
        { id: "NG", text: "Nigeria(NG)", phone: "234" },
        { id: "NI", text: "Nicaragua(NI)", phone: "505" },
        { id: "NL", text: "Netherlands(NL)", phone: "31" },
        { id: "NO", text: "Norway(NO)", phone: "47" },
        { id: "NP", text: "Nepal(NP)", phone: "977" },
        { id: "NR", text: "Nauru(NR)", phone: "674" },
        { id: "NU", text: "Niue(NU)", phone: "683" },
        { id: "NZ", text: "New Zealand(NZ)", phone: "64" },
        { id: "OM", text: "Oman(OM)", phone: "968" },
        { id: "PA", text: "Panama(PA)", phone: "507" },
        { id: "PE", text: "Peru(PE)", phone: "51" },
        { id: "PF", text: "French Polynesia(PF)", phone: "689" },
        { id: "PG", text: "Papua New Guinea(PG)", phone: "675" },
        { id: "PH", text: "Philippines(PH)", phone: "63" },
        { id: "PK", text: "Pakistan(PK)", phone: "92" },
        { id: "PL", text: "Poland(PL)", phone: "48" },
        { id: "PM", text: "Saint Pierre and Miquelon(PM)", phone: "508" },
        { id: "PN", text: "Pitcairn(PN)", phone: "870" },
        { id: "PR", text: "Puerto Rico(PR)", phone: "1" },
        { id: "PS", text: "Palestine, State of(PS)", phone: "970" },
        { id: "PT", text: "Portugal(PT)", phone: "351" },
        { id: "PW", text: "Palau(PW)", phone: "680" },
        { id: "PY", text: "Paraguay(PY)", phone: "595" },
        { id: "QA", text: "Qatar(QA)", phone: "974" },
        { id: "RE", text: "Reunion(RE)", phone: "262" },
        { id: "RO", text: "Romania(RO)", phone: "40" },
        { id: "RS", text: "Serbia(RS)", phone: "381" },
        { id: "RU", text: "Russian Federation(RU)", phone: "7" },
        { id: "RW", text: "Rwanda(RW)", phone: "250" },
        { id: "SA", text: "Saudi Arabia(SA)", phone: "966" },
        { id: "SB", text: "Solomon Islands(SB)", phone: "677" },
        { id: "SC", text: "Seychelles(SC)", phone: "248" },
        { id: "SD", text: "Sudan(SD)", phone: "249" },
        { id: "SE", text: "Sweden(SE)", phone: "46" },
        { id: "SG", text: "Singapore(SG)", phone: "65" },
        { id: "SH", text: "Saint Helena(SH)", phone: "290" },
        { id: "SI", text: "Slovenia(SI)", phone: "386" },
        { id: "SJ", text: "Svalbard and Jan Mayen(SJ)", phone: "47" },
        { id: "SK", text: "Slovakia(SK)", phone: "421" },
        { id: "SL", text: "Sierra Leone(SL)", phone: "232" },
        { id: "SM", text: "San Marino(SM)", phone: "378" },
        { id: "SN", text: "Senegal(SN)", phone: "221" },
        { id: "SO", text: "Somalia(SO)", phone: "252" },
        { id: "SR", text: "Suriname(SR)", phone: "597" },
        { id: "SS", text: "South Sudan(SS)", phone: "211" },
        { id: "ST", text: "Sao Tome and Principe(ST)", phone: "239" },
        { id: "SV", text: "El Salvador(SV)", phone: "503" },
        { id: "SX", text: "Sint Maarten (Dutch part)(SX)", phone: "1-721" },
        { id: "SY", text: "Syrian Arab Republic(SY)", phone: "963" },
        { id: "SZ", text: "Swaziland(SZ)", phone: "268" },
        { id: "TC", text: "Turks and Caicos Islands(TC)", phone: "1-649" },
        { id: "TD", text: "Chad(TD)", phone: "235" },
        { id: "TF", text: "French Southern Territories(TF)", phone: "262" },
        { id: "TG", text: "Togo(TG)", phone: "228" },
        { id: "TH", text: "Thailand(TH)", phone: "66" },
        { id: "TJ", text: "Tajikistan(TJ)", phone: "992" },
        { id: "TK", text: "Tokelau(TK)", phone: "690" },
        { id: "TL", text: "Timor-Leste(TL)", phone: "670" },
        { id: "TM", text: "Turkmenistan(TM)", phone: "993" },
        { id: "TN", text: "Tunisia(TN)", phone: "216" },
        { id: "TO", text: "Tonga(TO)", phone: "676" },
        { id: "TR", text: "Turkey(TR)", phone: "90" },
        { id: "TT", text: "Trinidad and Tobago(TT)", phone: "1-868" },
        { id: "TV", text: "Tuvalu(TV)", phone: "688" },
        { id: "TW", text: "Taiwan, Province of China(TW)", phone: "886" },
        { id: "TZ", text: "United Republic of Tanzania(TZ)", phone: "255" },
        { id: "UA", text: "Ukraine(UA)", phone: "380" },
        { id: "UG", text: "Uganda(UG)", phone: "256" },
        { id: "US", text: "United States(US)", phone: "1", suggested: true },
        { id: "UY", text: "Uruguay(UY)", phone: "598" },
        { id: "UZ", text: "Uzbekistan(UZ)", phone: "998" },
        { id: "VA", text: "Holy See (Vatican City State)(VA)", phone: "379" },
        {
          id: "VC",
          text: "Saint Vincent and the Grenadines(VC)",
          phone: "1-784",
        },
        { id: "VE", text: "Venezuela(VE)", phone: "58" },
        { id: "VG", text: "British Virgin Islands(VG)", phone: "1-284" },
        { id: "VI", text: "US Virgin Islands(VI)", phone: "1-340" },
        { id: "VN", text: "Vietnam(VN)", phone: "84" },
        { id: "VU", text: "Vanuatu(VU)", phone: "678" },
        { id: "WF", text: "Wallis and Futuna(WF)", phone: "681" },
        { id: "WS", text: "Samoa(WS)", phone: "685" },
        { id: "XK", text: "Kosovo(XK)", phone: "383" },
        { id: "YE", text: "Yemen(YE)", phone: "967" },
        { id: "YT", text: "Mayotte(YT)", phone: "262" },
        { id: "ZA", text: "South Africa(ZA)", phone: "27" },
        { id: "ZM", text: "Zambia(ZM)", phone: "260" },
        { id: "ZW", text: "Zimbabwe(ZW)", phone: "263" },
      ].slice(),
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      hasDisable: false,
      showSecondAction: false,
    });
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    if (this.props.match.params.id !== "" && id) {
      let tempFilters = {
        filter: {
          logic: "and",
          filters: [{ field: "id", operator: "contains", value: `${id}` }],
        },
        skip: 0,
        take: 100,
      };
      window.localStorage.setItem("finalParams", JSON.stringify(tempFilters));
    }

    let isFirstLoad = true;
    window.localStorage.setItem("isFirstLoad", isFirstLoad);

    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));

    if (hasFinalParams) {
      this.setState({
        ...this.state,
        dataState: hasFinalParams,
      });
    }

    if (hasChangedColumns) {
      this.setState({
        ...this.state,
        columns: hasChangedColumns,
      });
    }
  }

  componentWillUnmount() {
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));
    let finalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    let hasExpandedId = JSON.parse(window.localStorage.getItem("expandeId"));
    let hasRefreshParams = JSON.parse(
      window.localStorage.getItem("refreshParams")
    );
    let hasEditItem = JSON.parse(window.localStorage.getItem("editItem"));
    if (finalParams) {
      window.localStorage.removeItem("finalParams");
    }
    if (hasChangedColumns) {
      window.localStorage.removeItem("cols");
    }
    if (hasExpandedId) {
      window.localStorage.removeItem("expandeId");
    }
    if (hasRefreshParams) {
      window.localStorage.removeItem("refreshParams");
    }
    if (hasEditItem) {
      window.localStorage.removeItem("editItem");
    }
  }

  dataStateChange = (e) => {
    const reqGridParams = e.data;
    window.localStorage.setItem("finalParams", JSON.stringify(reqGridParams));
    this.setState({
      ...this.state,
      dataState: reqGridParams,
    });
  };

  dataRecieved = (gridData, reqGridParams) => {
    const finalData = formatDatesOnTable(gridData, [
      "valid_from",
      "valid_until",
    ]);
    const finalDataWithCreator = showCreatorOnTable(finalData);

    this.setState({
      ...this.state,
      gridData: finalDataWithCreator,
      initialGridData: finalDataWithCreator,
      dataState:
        reqGridParams === !_.isEmpty(reqGridParams)
          ? reqGridParams
          : this.state.dataState,
    });
  };

  renderGridColumns = (hideColumns = []) => {
    let selectionCol = [];
    let normalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            locked={false}
            field="selected"
            width="65px"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });
    normalCols = this.state.columns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.field === "vessel_flag_iso") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              filterCell={(props) => {
                return (
                  <>
                    <DropdownFilterCell
                      {...props}
                      filterable={true}
                      width="150px"
                      data={(this.props.countryCodes || [])
                        .map((x) => x.alpha_2)
                        .sort((a, b) => (a > b ? 1 : -1))}
                    />
                  </>
                );
              }}
              cell={(props) => {
                let data = (this.props.countryCodes || [])
                  .map((x) => ({
                    text: x.alpha_2,
                    value: x.alpha_2,
                  }))
                  .sort((a, b) => (a.text > b.text ? 1 : -1));
                return (
                  <>
                    <DropDownCell
                      {...props}
                      filterable={true}
                      width="150px"
                      data={data}
                    />
                  </>
                );
              }}
            />
          );
        } else if (temp.field === "vessel_type_lov") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              filterCell={(props) => {
                const data = (this.props.vesselLovTypes || [])
                  .map((x) => x.lov_value)
                  .sort((a, b) => (a > b ? 1 : -1));
                data.unshift("NONE");
                return (
                  <>
                    <DropdownFilterCell
                      {...props}
                      filterable={true}
                      width="150px"
                      data={data}
                    />
                  </>
                );
              }}
              cell={(props) => {
                let data = (this.props.vesselLovTypes || []).map((x) => ({
                  text: x.lov_value,
                  value: x.lov_value,
                }));
                data.sort((a, b) => (a.text > b.text ? 1 : -1));
                data.unshift({ text: "NONE", value: "NONE" });
                return (
                  <>
                    <DropDownCell
                      {...props}
                      filterable={true}
                      width="150px"
                      data={data}
                    />
                  </>
                );
              }}
            />
          );
        } else if (temp.isDropdown === "yes") {
          return (
            <Column
              sortable={temp.sortable}
              locked={temp.locked}
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              filterCell={(props) => {
                return (
                  <>
                    <DropdownFilterCell
                      {...props}
                      width="120px"
                      data={[
                        { text: "Yes", value: true },
                        { text: "No", value: false },
                      ].map((x) => x.text)}
                    />
                  </>
                );
              }}
              cell={(props) => {
                let field = props.field;
                switch (field) {
                  case "is_active":
                  case "has_clients":
                  case "is_government":
                    return (
                      <>
                        <DropDownCell
                          {...props}
                          width="100px"
                          filterable={false}
                          data={[
                            { text: "Yes", value: true },
                            { text: "No", value: false },
                          ].sort((a, b) => (a.text > b.text ? 1 : -1))}
                        />
                      </>
                    );

                  default:
                    break;
                }
              }}
            />
          );
        } else {
          return (
            <Column
              sortable={temp.sortable}
              locked={temp.locked}
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={
                temp.field === "id" ||
                temp.field === "ar_account" ||
                temp.field === "ap_account" ||
                temp.editable
                  ? false
                  : true
              }
            />
          );
        }
      });

    return [...selectionCol, ...normalCols];
  };

  handleColumnsChange = (event) => {
    let columns = this.state.columns;

    let oldColumns = columns.filter((c) => c.visible).map((c) => c.field);
    let newColumns = event.target.value;

    let unique1 = oldColumns.filter((c) => newColumns.indexOf(c) === -1);
    let unique2 = newColumns.filter((c) => oldColumns.indexOf(c) === -1);

    const columnLastUpdated = unique1.concat(unique2)[0];

    let index = columns.findIndex(
      (column) => column.field === columnLastUpdated
    );
    columns[index].visible = !columns[index].visible;

    this.setState(
      {
        ...this.state,
        columns: columns,
      },
      () => {
        let columns = this.state.columns;
        window.localStorage.setItem("cols", JSON.stringify(columns));
      }
    );
  };

  handleColumnsChange2 = (event, showHasClients) => {
    let columns = this.state.columns;

    let oldColumns = columns.filter((c) => c.visible).map((c) => c.field);

    let newColumns =
      event && event.target && event.target.value
        ? event.target.value
        : [
            "id",
            "vessel_name",
            "vessel_imo_no",
            "vessel_type",
            "vessel_flag_iso",
            "vessel_build_dt",
            "vessel_builder",
            "vessel_scrapped_dt",
            "vessel_deadweight",
            "vessel_build_country",
          ];

    if (
      showHasClients &&
      JSON.stringify(oldColumns) !== JSON.stringify(newColumns)
    ) {
      newColumns = [
        "id",
        "vessel_name",
        "has_clients",
        "vessel_imo_no",
        "vessel_type",
        "vessel_flag_iso",
        "vessel_build_dt",
        "vessel_builder",
        "vessel_scrapped_dt",
        "vessel_deadweight",
        "vessel_build_country",
      ];
    }

    let unique1 = oldColumns.filter((c) => newColumns.indexOf(c) === -1);
    let unique2 = newColumns.filter((c) => oldColumns.indexOf(c) === -1);

    const columnLastUpdated = unique1.concat(unique2)[0];

    let index =
      columnLastUpdated &&
      columns.findIndex((column) => column.field === columnLastUpdated);
    if (index) columns[index].visible = !columns[index].visible;

    this.setState(
      {
        ...this.state,
        columns: columns,
      },
      () => {
        // let columns = this.state.columns
        window.localStorage.setItem("cols", JSON.stringify(this.state.columns));
      }
    );
  };

  //if you need new editable columns apply here in newColumns
  applyNewColumnsOnGrid = () => {
    let columns = this.state.initialColumns;
    let newColumns = columns.filter(
      (x) =>
        x.field === "vessel_name" ||
        x.field === "vessel_imo_no" ||
        x.field === "vessel_type" ||
        x.field === "vessel_flag_iso" ||
        x.field === "is_government" ||
        x.field === "vessel_type_lov" ||
        x.field === "billingStrRegular" ||
        x.field === "billingStrSubmersible" ||
        x.field === "blanketPO" ||
        x.field === "billingRef"
    );
    newColumns.forEach((x) => (x.minWidth = 380));
    this.setState({
      ...this.state,
      hideColumns: true,
      columns: newColumns,
      initialColumns: columns,
    });
  };

  applyInitialColumnsOnGrid = () => {
    let initialColumns = this.state.initialColumns;
    let newColumns = initialColumns;
    this.setState({
      ...this.state,
      hideColumns: false,
      columns: newColumns,
    });
  };

  refreshGridDataAfterCrud = async () => {
    let token = window.localStorage.getItem("access_token");
    let options = {
      token: token,
    };

    let refreshParams = window.localStorage.getItem("refreshParams");
    refreshParams = JSON.parse(refreshParams);
    refreshParams.filter.include = ["createdBy", "updatedBy", "vesselToClient"];
    let responseData = await serverApi(
      "GET",
      `vesselVs`,
      refreshParams,
      "",
      options
    );

    const finalData = showCreatorOnTable(responseData);

    let count;
    const countMasterEntity = await serverApi(
      "GET",
      `vesselVs/count?where=${JSON.stringify(refreshParams.filter.where)}`,
      "",
      "",
      options
    );
    if (countMasterEntity) {
      count = countMasterEntity.data.count;
    }

    let sendObj = {
      gridData: {
        data: finalData.data || [],
        total: count ? count : finalData?.data?.length || 0,
      },
    };

    this.dataRecieved(sendObj.gridData, null);
  };

  enterEdit = (dataItem) => {
    const hasSuperRole = (this.props?.user?.roles || []).some(
      (role) => role.id === roles.SUPERROLE
    );
    this.applyNewColumnsOnGrid();

    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    let total = this.state.gridData.total;

    this.setState(
      {
        gridData: {
          data:
            this.state.gridData &&
            this.state.gridData.data.map((item) =>
              item.id === dataItem.id ? { ...item, inEdit: true } : item
            ),
          total: total,
        },
        initialGridData: {
          data:
            this.state.initialGridData &&
            this.state.initialGridData.data.map((item) =>
              item.id === dataItem.id ? { ...item, inEdit: true } : item
            ),
          total: total,
        },
      },
      () => {
        this.setState({
          ...this.state,
          itemInEditMode: { ...dataItem },
          hasSuperRole: hasSuperRole,
        });
      }
    );
  };

  remove = async () => {};

  //fleetmon integration
  add = async (dataItem) => {
    //todo check again all process ! ! ! !
    let payload = dataItem;
    dataItem.inEdit = undefined;
    payload.vessel_imo_no = payload?.vessel_imo_no?.toString() || null;
    delete payload.inEdit;

    const foundObject = (this.props.vesselLovTypes || []).find(
      (x) => x.lov_value === this.state.gridData.data[0].vessel_type_lov
    );
    const vesselTypeID = foundObject ? foundObject.id : null;
    payload.vesselTypeId = vesselTypeID;

    const submitURL = "vessels";
    let count;
    const options = { token: window.localStorage.getItem("access_token") };

    try {
      const createRequest = await serverApi(
        "POST",
        `${submitURL}`,
        "",
        payload,
        options
      );
      if (createRequest) {
        const responseData = await serverApi(
          "GET",
          `vesselVs?filter={"include":[],"where":{"and":[{}]},"limit":"100","skip":"0"}`,
          {},
          "",
          options
        );
        const countVessels = await serverApi(
          "GET",
          "vesselVs/count",
          "",
          "",
          options
        );

        try {
          this.refreshGridDataAfterCrud();
          this.setState(
            {
              ...this.state,
              columns: this.state.initialColumns,
              itemInEditMode: {}, //todo check again /
              hideColumns: false,
              allRowsEditable: false,
              gridData: {
                data: responseData.data,
                total: count === undefined ? responseData.data.length : count,
              },
              successMessage: "Vessel was successfully created.",
              openSuccessMessage: true,
              allowEditVesselName: false,
            },
            () => {
              setTimeout(() => {
                this.setState({
                  ...this.state,
                  successMessage: "",
                  openSuccessMessage: false,
                });
              }, 4000);
            }
          );
        } catch (error) {}
      }
    } catch (error) {
      this.setState(
        {
          ...this.state,
          allowEditVesselName: false,
          openErrorModal: true,
          errorModalContent:
            error?.response?.data?.error?.message ||
            "Create new vessel has failed.Please try again",
        },
        () => this.discard(dataItem, true)
      );
    }
  };

  discard = (dataItem, hasError = false) => {
    let data = [...this.state.gridData.data];
    const total = this.state.gridData.total;

    if (hasError) {
      data[0].inEdit = true;
      this.removeItem(data, dataItem);

      this.setState(
        {
          ...this.state,
          columns: this.state.initialColumns,
          hideColumns: false,
          allowEditVesselName: false,
          gridData: {
            data: data,
            total: total,
          },
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 5000);
        }
      );
    } else {
      this.removeItem(data, dataItem);

      this.setState(
        {
          ...this.state,
          columns: this.state.initialColumns,
          hideColumns: false,
          gridData: {
            data: data,
            total: total,
          },
          visible: false,
          allowEditVesselName: false,
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 5000);
        }
      );
    }
  };

  update = async (dataItem) => {
    const data = [...this.state.gridData.data];
    const updatedItem = { ...dataItem, inEdit: undefined };
    this.updateItem(data, updatedItem);

    const editPayload = dataItem;
    if (editPayload.expanded) delete editPayload.expanded;

    removeUnwantedKeyValuesFromArr(editPayload);

    if (
      editPayload.vessel_name === undefined ||
      editPayload.vessel_name === ""
    ) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            "Vessel name cannot be empty when editing.Please provide a valid name.",
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 5000);
        }
      );
    } else if (
      editPayload.vessel_imo_no === undefined ||
      editPayload.vessel_imo_no === ""
    ) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            "Imo number cannot be empty when editing an existing vessel.Please provide a valid one.",
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 5000);
        }
      );
    } else {
      try {
        let options = {
          token: window.localStorage.getItem("access_token"),
        };

        if (
          this.state.validateWithFleetmon ||
          this.state.gridData.data.filter((x) => x.inEdit)[0].vessel_imo_no !==
            this.state.itemInEditMode.vessel_imo_no
        ) {
          let imo = editPayload.vessel_imo_no;
          let url = `https://apiv2.fleetmon.com/vesselsearch/?imo_number=${imo.trim()}&request_limit_info=true`;
          const getFleetmonData = await axios.get(url, {
            auth: {
              username: "swift_navigator",
              password: "swift--2015%",
            },
          });
          let finalFleetmonData = [];
          if (getFleetmonData) {
            this.applyNewColumnsOnGrid();

            if (getFleetmonData.data.vessels.length === 0) {
              this.setState(
                {
                  ...this.state,
                  showZeroResultsMessage: true,
                },
                () => {
                  setTimeout(() => {
                    this.setState({
                      ...this.state,
                      showZeroResultsMessage: false,
                      editRecordsFleetmonWindow: false,
                    });
                    return;
                  }, 4000);
                }
              );
            } else if (
              getFleetmonData.data &&
              getFleetmonData.data.vessels?.length > 0 &&
              getFleetmonData.data.vessels.length === 1
            ) {
              let firstObject = {
                ...getFleetmonData.data.vessels[0],
                vessel_builder: "N/A",
                // vessel_scrapped_dt: 'N/A',
                // vessel_build_dt: 'N/A',
                // vessel_deadweight: '0',
                vessel_build_country: "N/A",
              };
              // firstObject.vessel_deadweight = '0'
              firstObject.vessel_builder = "N/A";
              firstObject.vessel_build_country = "N/A";
              firstObject.is_active = true;
              finalFleetmonData.push(firstObject);

              this.setState({ ...this.state, useOptionRecord: firstObject });
            } else if (getFleetmonData.data.vessels.length > 1) {
              let fleetmonData = getFleetmonData?.data?.vessels || [];
              // eslint-disable-next-line no-unused-vars
              for (let temp of fleetmonData) {
                finalFleetmonData.push({
                  ...temp,
                });
              }
            }
            this.setState({
              ...this.state,
              useOptionRecord: finalFleetmonData[0],
            });
          }

          if (finalFleetmonData?.length > 0) {
            finalFleetmonData[0].selected = true;
          }

          let final = [];
          //eslint-disable-next-line
          for (let ves of finalFleetmonData) {
            final.push({
              selected: ves.selected || false,
              vessel_imo_no: ves.imo_number,
              vessel_type: ves.vt_verbose,
              vessel_flag_iso: ves.cn_iso2,
              vessel_name: ves.name,

              vessel_build_dt: ves.vessel_build_dt,
              vessel_builder: ves.vessel_builder,
              vessel_scrapped_dt: ves.vessel_scrapped_dt,
              vessel_deadweight: ves.vessel_deadweight,
              vessel_build_country: ves.vessel_build_country,
            });
          }

          this.setState({
            ...this.state,
            editRecordsFleetmonWindow: true,
            allRowsEditable: false,
            fleetmonGridSelectionBy:
              getFleetmonData?.data?.vessels?.length &&
              getFleetmonData.data.vessels.length === 1
                ? "vessel_imo_no"
                : "fleetmon_vessel_id",
            fleetmonResponse: getFleetmonData?.data?.vessels || [],
            // gridData: gridData || [],
            finalFleetmonData: final,
            showZeroResultsMessage: final?.length === 0 ? true : false,
            selectedItem: final[this.state.selectedRowIndex],
            initialHeight: 700,
            initialWidth: 1600,
          });
        } else {
          //old code when editing
          delete editPayload.valid_from;
          delete editPayload.valid_until;

          //add vesselTypeId in payload
          const matchingLovTypeID =
            (this.props.vesselLovTypes || []).find(
              (x) =>
                x.lov_value ===
                (this.state.gridData.data || []).find((x) => x.inEdit)
                  ?.vessel_type_lov
            )?.id || null;
          editPayload.vesselTypeId = matchingLovTypeID;

          const existingItem = await serverApi(
            "GET",
            `vessels/${updatedItem.id}`,
            {},
            {},
            {
              token: window.localStorage.getItem("access_token"),
            }
          );

          const fieldsToUpdate = {};
          const excludedFields = ["fleets", "has_clients", "inEdit"];

          for (const key in editPayload) {
            if (excludedFields.includes(key)) {
              continue;
            }

            if (
              JSON.stringify(existingItem.data[key]) !==
              JSON.stringify(editPayload[key])
            ) {
              fieldsToUpdate[key] = editPayload[key];
            }
          }

          const editRequest = await AbstractEdit(
            dataItem.id,
            fieldsToUpdate,
            "vessels",
            options
          );
          const countMasterEntity = await serverApi(
            "GET",
            "vesselVs/count",
            "",
            "",
            options
          );

          if (editRequest.status === 200 && countMasterEntity) {
            this.refreshGridDataAfterCrud();
            this.setState(
              {
                ...this.state,
                validateWithFleetmon: false,
                gridData: {
                  data: data,
                  total: countMasterEntity.data.count,
                },
                successMessage: "The vessel was successfully updated.",
                openSuccessMessage: true,
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    ...this.state,
                    successMessage: "",
                    openSuccessMessage: false,
                    columns: this.state.initialColumns,
                    hideColumns: false,
                  });
                }, 4000);
              }
            );
          }
        }
      } catch (e) {
        this.setState(
          {
            ...this.state,
            openErrorModal: true,
            errorModalContent:
              e?.response?.data?.error?.message ||
              "Update vessel has failed.Please try again.",
          },
          () => this.discard(dataItem, true)
        );
      }
    }
  };

  cancel = (dataItem) => {
    const originalItem =
      this.state.itemInEditMode ||
      this.state.gridData.data.find((p) => p.id === dataItem.id);
    if (originalItem) {
      if (originalItem.inEdit) {
        originalItem.inEdit = false;
      }
    }

    const data =
      (this.state.gridData &&
        this.state.gridData.data.map((item) =>
          item.id === originalItem?.id ? originalItem : item
        )) ||
      (this.state.initialGridData &&
        this.state.initialGridData.data.map((item) =>
          item.id === originalItem?.id ? originalItem : item
        ));

    const total = this.state.gridData.total;

    this.setState(
      {
        ...this.state,
        validateWithFleetmon: false,
        gridData: {
          data: data,
          total: total,
        },
        errorModalContent:
          this.state.errorModalContent !== ""
            ? ""
            : this.state.errorModalContent,
        openErrorModal:
          this.state.openErrorModal !== true
            ? false
            : this.state.errorModalContent,
      },
      () => this.applyInitialColumnsOnGrid()
    );
  };

  addNew = () => {
    //old code add vessel manually
    // let checkForEditDato = this.state.gridData.data
    // const found = checkForEditDato.some(item => item.inEdit === true);
    // if (found)
    //   return
    //
    // const newDataItem = {inEdit: true, has_clients: false};
    //
    // // this.handleColumnsChange(null, false)
    //
    // this.setState({
    //   ...this.state,
    //   gridData: {
    //     data: [newDataItem, ...this.state.gridData.data],
    //     total: this.state.gridData.total
    //   },
    //   visible: true
    // });
  };

  updateItem = (data, item) => {
    let index = data.findIndex(
      (p) => p === item || (item.id && p.id === item.id)
    );
    if (index >= 0) {
      data[index] = { ...item };
    }
  };

  itemChange = (event) => {
    //vessel name is only editable from change vessel name process
    if (
      event.field === "vessel_name" &&
      !this.state.allowEditVesselName &&
      !this.state.hasSuperRole
    ) {
      return;
    }

    //when vessel's imo is about to change , first validate it with fleetmon
    if (event.field === "vessel_imo_no") {
      if (event.value !== this.state.itemInEditMode?.vessel_imo_no) {
        this.setState({
          ...this.state,
          validateWithFleetmon: true,
        });
      }
    }

    //vessel flag iso validation : only 2 Chars ( no numbers) both on edit/add action
    if (event.field === "vessel_flag_iso") {
      if (event.value.length > 2) return;
    }

    //Add action
    if (
      event.dataItem.id === undefined &&
      this.state.allRowsEditable === false
    ) {
      //when creating new vessel(fleetmon integration) only name, is_government and category and is manually editable after clicking the use option button.
      if (
        event.field === "vessel_name" ||
        event.field === "is_government" ||
        event.field === "vessel_type_lov"
      ) {
        const total = this.state.gridData.total;
        const data =
          this.state.gridData &&
          this.state.gridData.data.map((item) =>
            item.id === event.dataItem.id
              ? { ...item, [event.field]: event.value }
              : item
          );

        this.setState({
          ...this.state,
          gridData: {
            data: data,
            total: total,
          },
        });
      } else {
        return;
      }
    }
    //Edit action
    else {
      //if vessels' type is = no_imo then the vessel_type cannot be altered
      if (
        event.field === "vessel_type" &&
        event.dataItem.vessel_type === "NO_IMO"
      ) {
        return;
      }

      //when vessel's imo is about to change , first validate it with fleetmon
      if (event.field === "vessel_imo_no") {
        if (event.value !== this.state.itemInEditMode?.vessel_imo_no) {
          this.setState({
            ...this.state,
            validateWithFleetmon: true,
          });
        } else {
        }
      }

      // if vessel is office(0000000) only allowableColumns are allowed to be edited.
      if (event.dataItem.id && event.field !== "vessel_name") {
        if (event.dataItem.vessel_imo_no === "0000000") {
          //if we need to edit new vessel column should be added here!
          const allowableColumns = [
            "is_government",
            "vessel_type_lov",
            "billingStrRegular",
            "billingStrSubmersible",
            "blanketPO",
            "billingRef",
          ];
          if (event.field.indexOf(allowableColumns) === -1) {
          } else {
            return;
          }
        }
      }

      const total = this.state.gridData.total;
      const data =
        this.state.gridData &&
        this.state.gridData.data.map((item) =>
          item.id === event.dataItem.id
            ? { ...item, [event.field]: event.value }
            : item
        );

      this.setState({
        ...this.state,
        gridData: {
          data: data,
          total: total,
        },
      });
    }
  };

  removeItem(data, item) {
    let index = data.findIndex((p) => p === item || p.id === item.id);
    if (index >= 0) {
      data.splice(index, 1);
    }
  }

  onClearFilters = () => {
    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));

    let init = JSON.stringify({ take: 100, skip: 0 });
    if (hasFinalParams && hasFinalParams.filter)
      window.localStorage.setItem("finalParams", init);

    this.dataStateChange({ data: { take: 100, skip: 0 } });
  };

  closeErrorModal = () => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      errorModalContent: "",
    });
  };

  expandChangeAsClientView = (event) => {
    window.localStorage.setItem("expandeId", event.dataItem.id);

    //if add new vessel is true then expand cannot be done !
    if (this.state.gridData.data[0].inEdit === true) {
      return;
    } else {
      event.dataItem.expanded = !event.dataItem.expanded;
      event.dataItem.toggleWindowRemoveClients = this.toggleWindowRemoveClients;
      event.dataItem.refreshGridDataAfterCrud = this.refreshGridDataAfterCrud;
      event.dataItem.applyInitialColumnsOnGrid = this.applyInitialColumnsOnGrid;
    }

    this.forceUpdate();
  };

  expandChange = (event) => {
    if (event) {
      window.localStorage.setItem("expandeId", event.dataItem.id);
      //if add new vessel is true then expand cannot be done !
      if (this.state.gridData.data[0].inEdit === true) {
        return;
      } else {
        event.dataItem.expanded = !event.dataItem.expanded;
      }
      this.setState({
        ...this.state,
        dataItem: event.dataItem,
      });
    }

    if (!event) {
      let dataItem2 = this.state.dataItem;
      dataItem2.expanded = false;
      this.setState({
        ...this.state,
        dataItem: dataItem2,
      });
    }

    this.forceUpdate();
  };

  handleErrorClose = () => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      openErrorModalContent: "",
    });
  };

  rowClick = (event) => {
    // window.localStorage.setItem('expandeId', event.dataItem.id)
    //
    // //if add new vessel is true then expand cannot be done !
    // if (this.state.gridData.data[0].inEdit === true) {
    //     return
    // } else {
    //     event.dataItem.expanded = !event.dataItem.expanded;
    // }
    //
    // this.forceUpdate();
  };

  handleSuccessSnackbar = () => {
    this.setState({
      ...this.state,
      successMessage:
        this.state.openSuccessMessage === true ? this.state.successMessage : "",
      openSuccessMessage: !this.state.openSuccessMessage,
    });
  };

  getPromise = (filter) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    return new Promise((resolve, reject) => {
      const vesselRequest = serverApi(
        "GET",
        `vessels?filter=${JSON.stringify(filter)}`,
        "",
        "",
        options
      );
      vesselRequest
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  exportHandler = async (state, props) => {
    const count = state.gridData.total;
    const where = window.localStorage.getItem("exportFilter") || {};
    const numberOfCalls = Math.min(
      Math.round(count / 1000) + 1,
      5 // Limit to 5000
    );
    let finalSort = this.state.dataState?.sort?.length
      ? this.state.dataState.sort[0].field +
        " " +
        this.state.dataState.sort[0].dir
      : "id asc";

    const promises = [];
    for (let i = 0; i < numberOfCalls; i++) {
      promises.push(
        this.getPromise({
          skip: i * 1000,
          limit: 1000,
          where: !_.isEmpty(where) ? JSON.parse(where) : {},
          order: [finalSort],
        })
      );
    }

    try {
      const responses = await Promise.all(promises);
      let allData = [];
      responses.forEach((resp) => {
        allData = [...allData, ...resp.data];
      });
      if (allData.length > 0) {
        this.setState({
          ...this.state,
          exportData: allData,
        });
        // this._export.save(allData, this.state.columns);
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent:
          e?.response?.data?.error?.message ||
          "Export data process failed.Please try again",
      });
    }
  };

  getExportData = async () => {
    await this.exportHandler(this.state, this.props);
  };

  openFleetmonPopup = async () => {
    let checkForEditDato = this.state.gridData.data;
    let found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let filter1 = {
      filter: {
        where: {
          lov_type: "NONE_VESSEL_TYPE",
        },
      },
    };
    try {
      const responseData = await serverApi("GET", `lovs`, filter1, "", options);
      if (responseData) {
        this.setState({
          ...this.state,
          visibleWindow: true,
          hideColumns: true,
          finalFleetmonData: [],
          selectedRowIndex: 0,
          selectedItem: null,
          vesselTypeData: (responseData.data || []).map((res) => ({
            text: res.lov_value,
            id: res.id,
          })),
          vesselCategories: (this.props.vesselLovTypes || []).map((vs) => ({
            text: vs.lov_value,
            id: vs.id,
          })),
        });
      }
    } catch (error) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            "Cannot start the vessel creation process, please contact the administration.",
        },
        () => {
          setTimeout(() => {
            this.setState({
              ...this.state,
              openErrorModal: false,
              errorModalContent: "",
            });
          }, 5000);
        }
      );
    }
  };

  toggleWindow = () => {
    // let checkForEditDato = this.state.gridData.data
    // const found = checkForEditDato.some(item => item.inEdit === true);
    // if (found)
    //   return
    //
    // this.setState({
    //   ...this.state,
    //   visibleWindow: !this.state.visibleWindow,
    //   finalFleetmonData: [],
    //   selectedRowIndex: 0,
    //   selectedItem: null,
    // },() => {
    //   this.setState({
    //     ...this.state,
    //     hideColumns: this.state.visibleWindow === true ? false : true,
    //   })
    // })
  };

  renderGridColumnsForFleetmon = () => {
    let selectionCol = [];
    let normalCols = [];

    _.forEach(this.state.fleetmonSchema, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            width="65px"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });
    normalCols = this.state.fleetmonSchema
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }
        if (temp.isFilterBoolean === "yes") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              cell={DropDownCell}
            />
          );
        } else {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={temp.field === "id" ? false : true} //id not editable on edit
            />
          );
        }
      });
    return [...selectionCol, ...normalCols];
  };

  //todo change the validation
  hasNumbers = (t) => {
    let regex = /^(0|[1-9][0-9]{0,2}(?:(,[0-9]{3})*|[0-9]*))(\.[0-9]+){0,1}$/;
    return regex.test(t);
  };

  vesselSearch = async (e, props, queryParam) => {
    this.setState({ ...this.state, showZeroResultsMessage: false });

    const imoParam =
      this.state.gridData.vessel_imo_no || this.state.vessel_imo_no;
    const nameParam = this.state.gridData.vessel_name || this.state.vessel_name;

    // //if imo number is : 0000000 then use a popup to enter data manually.
    if (imoParam === "0000000") {
      this.setState(
        { ...this.state, createVesselOfficeWindow: true, visibleWindow: false },
        () => {
          this.applyNewColumnsOnGrid();
        }
      );
    } else {
      //numeric validation and exactly 7 length for the IMO of the vessel.
      let decide = this.hasNumbers(imoParam) || imoParam === "0000000";
      if (decide) {
        decide = imoParam?.length === 7 ? true : false;
      }

      let paramNameValid = nameParam?.length > 3 ? true : false;

      if (decide !== true && queryParam !== "name") {
        this.setState(
          {
            ...this.state,
            openErrorModal: true,
            errorModalContent:
              "Please provide a valid IMO, containing only numbers in order to search for vessels.",
          },
          () => {
            setTimeout(() => {
              this.setState({
                ...this.state,
                openErrorModal: false,
                errorModalContent: "",
              });
            }, 5000);
          }
        );
      } else if (!paramNameValid && queryParam === "name") {
        this.setState(
          {
            ...this.state,
            openErrorModal: true,
            errorModalContent:
              "Please provide a valid vessel name containing minimum 3 characters.",
          },
          () => {
            setTimeout(() => {
              this.setState({
                ...this.state,
                openErrorModal: false,
                errorModalContent: "",
              });
            }, 5000);
          }
        );
      } else {
        try {
          const options = {
            token: window.localStorage.getItem("access_token"),
          };

          let searchPayload = {
            VesselIMO: null,
            VesselName: null,
          };

          if (queryParam === "imo") {
            searchPayload = {
              VesselIMO: imoParam,
              VesselName: "",
            };
          } else if (queryParam === "name") {
            searchPayload = {
              VesselIMO: "",
              VesselName: nameParam,
            };
          }

          const getFleetmonData = await serverApi(
            "POST",
            `vessels/retriveVesselDetails`,
            "",
            searchPayload,
            options,
            "",
            ""
          );

          // let getFleetmonData = await axios.get(url, {
          //   auth: {
          //     username: "swift_navigator",
          //     password: "swift--2015%",
          //   },
          // });
          let finalFleetmonData = [];

          if (getFleetmonData) {
            this.applyNewColumnsOnGrid();

            // getFleetmonData.data.response.vessels =
            //   getFleetmonData.data.response.vessels.filter((x) => x.imo_number);

            if (getFleetmonData.data.response.vessels.length === 0) {
              this.setState(
                {
                  ...this.state,
                  showZeroResultsMessage: true,
                },
                () => {
                  return;
                }
              );
            } else if (
              getFleetmonData.data &&
              getFleetmonData.data.response.vessels?.length > 0 &&
              getFleetmonData.data.response.vessels.length === 1
            ) {
              let firstObject = {
                ...getFleetmonData.data.response.vessels[0],
                vessel_builder: "N/A",
                // vessel_scrapped_dt: 'N/A',
                // vessel_build_dt: 'N/A',
                // vessel_deadweight: '0',
                vessel_build_country: "N/A",
              };
              // firstObject.vessel_deadweight = '0'
              firstObject.vessel_builder = "N/A";
              firstObject.vessel_build_country = "N/A";
              firstObject.is_active = true;
              finalFleetmonData.push(firstObject);
            } else if (getFleetmonData.data.response.vessels.length > 1) {
              //todo check is_Active flag

              let fleetmonData = getFleetmonData.data.response.vessels;
              // eslint-disable-next-line no-unused-vars
              for (let temp of fleetmonData) {
                finalFleetmonData.push(temp);
              }
            }
          }

          if (finalFleetmonData?.length > 0) {
            finalFleetmonData[0].selected = true;
          }

          let gridData = { ...this.state.gridData };
          let data = gridData.data;

          if (finalFleetmonData?.length > 0) {
            data[0] = {
              inEdit: true,
              vessel_imo_no:
                finalFleetmonData.length > 0 && finalFleetmonData[0].imo_number,
              vessel_type:
                finalFleetmonData.length > 0 && finalFleetmonData[0].vt_verbose,
              vessel_flag_iso:
                finalFleetmonData.length > 0 && finalFleetmonData[0].cn_iso2,
              vessel_name:
                finalFleetmonData.length > 0 && finalFleetmonData[0].name,
              fleetmon_vessel_id: finalFleetmonData.vessel_id,
              vessel_build_dt:
                finalFleetmonData.length > 0 &&
                finalFleetmonData[0].vessel_build_dt,
              vessel_builder:
                finalFleetmonData.length > 0 &&
                finalFleetmonData[0].vessel_builder,
              vessel_scrapped_dt:
                finalFleetmonData.length > 0 &&
                finalFleetmonData[0].vessel_scrapped_dt,
              vessel_deadweight:
                finalFleetmonData.length > 0 &&
                finalFleetmonData[0].vessel_deadweight,
              vessel_build_country:
                finalFleetmonData.length > 0 &&
                finalFleetmonData[0].vessel_build_country,
            };
          }

          let final = [];
          //eslint-disable-next-line
          for (const ves of finalFleetmonData) {
            final.push({
              selected: ves.selected || false,
              vessel_imo_no: ves.imo_number,
              vessel_type: ves.vt_verbose,
              vessel_flag_iso: ves.cn_iso2,
              vessel_name: ves.name,
              fleetmon_vessel_id: ves.vessel_id,
              vessel_build_dt: ves.vessel_build_dt,
              vessel_builder: ves.vessel_builder,
              vessel_scrapped_dt: ves.vessel_scrapped_dt,
              vessel_deadweight: ves.vessel_deadweight,
              vessel_build_country: ves.vessel_build_country,
            });
          }
          this.setState({
            ...this.state,
            allRowsEditable: false,
            fleetmonGridSelectionBy:
              getFleetmonData?.data?.response?.vessels?.length &&
              getFleetmonData.data.response.vessels.length === 1
                ? "vessel_imo_no"
                : "fleetmon_vessel_id",
            fleetmonResponse: getFleetmonData?.data?.response?.vessels || [],
            gridData: gridData || [],
            visible: true,
            finalFleetmonData: final,
            showZeroResultsMessage: final?.length === 0 ? true : false,
            selectedItem: final[this.state.selectedRowIndex],
            initialHeight: 700,
            initialWidth: 1600,
          });
        } catch (e) {
          if (e.response.data.error.message.includes("No vessel found with")) {
            this.createVesselManuallyWhenNoResults();
          } else {
            this.setState(
              {
                ...this.state,
                openErrorModal: true,
                errorModalContent:
                  e?.response?.data?.error?.message ||
                  "Something went wrong,please try again.",
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    ...this.state,
                    openErrorModal: false,
                    errorModalContent: "",
                  });
                }, 5000);
              }
            );
          }
        }
      }
    }
  };

  createVesselManuallyWhenNoResults = async (getFleetmonData) => {
    let finalFleetmonData = [];

    if (getFleetmonData) {
      this.applyNewColumnsOnGrid();

      // getFleetmonData.data.response.vessels =
      //   getFleetmonData.data.response.vessels.filter((x) => x.imo_number);

      if (getFleetmonData.data.response.vessels.length === 0) {
        this.setState(
          {
            ...this.state,
            showZeroResultsMessage: true,
          },
          () => {
            return;
          }
        );
      } else if (
        getFleetmonData.data &&
        getFleetmonData.data.response.vessels?.length > 0 &&
        getFleetmonData.data.response.vessels.length === 1
      ) {
        let firstObject = {
          ...getFleetmonData.data.response.vessels[0],
          vessel_builder: "N/A",
          // vessel_scrapped_dt: 'N/A',
          // vessel_build_dt: 'N/A',
          // vessel_deadweight: '0',
          vessel_build_country: "N/A",
        };
        // firstObject.vessel_deadweight = '0'
        firstObject.vessel_builder = "N/A";
        firstObject.vessel_build_country = "N/A";
        firstObject.is_active = true;
        finalFleetmonData.push(firstObject);
      } else if (getFleetmonData.data.response.vessels.length > 1) {
        //todo check is_Active flag

        let fleetmonData = getFleetmonData.data.response.vessels;
        // eslint-disable-next-line no-unused-vars
        for (let temp of fleetmonData) {
          finalFleetmonData.push(temp);
        }
      }
    }

    if (finalFleetmonData?.length > 0) {
      finalFleetmonData[0].selected = true;
    }

    let gridData = { ...this.state.gridData };
    let data = gridData.data;

    if (finalFleetmonData?.length > 0) {
      data[0] = {
        inEdit: true,
        vessel_imo_no:
          finalFleetmonData.length > 0 && finalFleetmonData[0].imo_number,
        vessel_type:
          finalFleetmonData.length > 0 && finalFleetmonData[0].vt_verbose,
        vessel_flag_iso:
          finalFleetmonData.length > 0 && finalFleetmonData[0].cn_iso2,
        vessel_name: finalFleetmonData.length > 0 && finalFleetmonData[0].name,
        fleetmon_vessel_id: finalFleetmonData.vessel_id,
        vessel_build_dt:
          finalFleetmonData.length > 0 && finalFleetmonData[0].vessel_build_dt,
        vessel_builder:
          finalFleetmonData.length > 0 && finalFleetmonData[0].vessel_builder,
        vessel_scrapped_dt:
          finalFleetmonData.length > 0 &&
          finalFleetmonData[0].vessel_scrapped_dt,
        vessel_deadweight:
          finalFleetmonData.length > 0 &&
          finalFleetmonData[0].vessel_deadweight,
        vessel_build_country:
          finalFleetmonData.length > 0 &&
          finalFleetmonData[0].vessel_build_country,
      };
    }

    let final = [];
    //eslint-disable-next-line
    for (const ves of finalFleetmonData) {
      final.push({
        selected: ves.selected || false,
        vessel_imo_no: ves.imo_number,
        vessel_type: ves.vt_verbose,
        vessel_flag_iso: ves.cn_iso2,
        vessel_name: ves.name,
        fleetmon_vessel_id: ves.vessel_id,
        vessel_build_dt: ves.vessel_build_dt,
        vessel_builder: ves.vessel_builder,
        vessel_scrapped_dt: ves.vessel_scrapped_dt,
        vessel_deadweight: ves.vessel_deadweight,
        vessel_build_country: ves.vessel_build_country,
      });
    }
    this.setState({
      ...this.state,
      allRowsEditable: false,
      fleetmonGridSelectionBy:
        getFleetmonData?.data?.response?.vessels?.length &&
        getFleetmonData.data.response.vessels.length === 1
          ? "vessel_imo_no"
          : "fleetmon_vessel_id",
      fleetmonResponse: getFleetmonData?.data?.response?.vessels || [],
      gridData: gridData || [],
      visible: true,
      finalFleetmonData: final,
      showZeroResultsMessage: final?.length === 0 ? true : false,
      selectedItem: final[this.state.selectedRowIndex],
      initialHeight: 700,
      initialWidth: 1600,
    });
  };

  selectionChange = (event) => {
    const { fleetmonGridSelectionBy } = this.state;

    this.setState(
      {
        ...this.state,
        useOptionRecord: event.dataItem || {},
      },
      () => {
        (this.state.finalFleetmonData || []).map((item, index) => {
          if (
            item[fleetmonGridSelectionBy] ===
            event.dataItem[fleetmonGridSelectionBy]
          ) {
            item.selected = !event.dataItem.selected;
            // let selectedArray = JSON.parse(window.localStorage.getItem('selectedItems')) || []
            if (item.selected === false) {
              // selectedArray = selectedArray.filter(temp => (temp.box_id !== item.box_id));
              // selectedArray.push(item)
            } else if (item.selected === true) {
              // selectedArray.push(item)
            }
            // window.localStorage.setItem('selectedItems', JSON.stringify(selectedArray))
          } else {
            let finalFleetmonData = this.state.finalFleetmonData;
            finalFleetmonData[index].selected = false;

            this.setState({
              ...this.state,
              finalFleetmonData: finalFleetmonData,
            });
          }
          this.selectFleetmonOption(item, index);
          return item;
        });
      }
    );
  };

  selectFleetmonOption = (item, index) => {
    this.setState({
      ...this.state,
      selectedRowIndex: index,
      selectedItem: item,
    });
  };

  saveFleetmonOption = (e, gridData, finalFleetmonData, selectedItem) => {
    let data = gridData && gridData.data;
    let finalIMO = "";
    if (this.state.gridData.vessel_imo_no !== this.state.vessel_imo_no) {
      finalIMO = this.state.vessel_imo_no;
    }
    if (!finalIMO) {
      finalIMO =
        finalFleetmonData.filter((x) => x.selected)[0] &&
        finalFleetmonData.filter((x) => x.selected)[0].vessel_imo_no;
    } else {
      finalIMO = this.state.gridData.vessel_imo_no;
    }

    data[0] = {
      inEdit: true,
      vessel_imo_no: selectedItem?.vessel_imo_no || finalIMO,
      vessel_type: selectedItem.vessel_type,
      vessel_flag_iso: selectedItem.vessel_flag_iso,
      vessel_name: selectedItem.vessel_name,
      vessel_build_dt: selectedItem.vessel_build_dt || null,
      vessel_builder: selectedItem.vessel_builder || "",
      vessel_scrapped_dt: selectedItem.vessel_scrapped_dt || null,
      vessel_deadweight: selectedItem.vessel_deadweight || null,
      vessel_build_country: selectedItem.vessel_build_country || "",
      is_government: false,
      vesselTypeId: null,
    };

    this.setState({
      ...this.state,
      gridData: gridData,
      visibleWindow: false,
      editRecordsFleetmonWindow: false,
      finalFleetmonData: [],
      width: "80%",
      allowEditVesselName: true,
    });
  };

  saveFleetmonOptionAndUpateRecord = async (
    e,
    gridData,
    finalFleetmonData,
    selectedItem
  ) => {
    const options = { token: window.localStorage.getItem("access_token") };

    let data = gridData && gridData.data;
    const { useOptionRecord } = this.state;

    let editPayload = {
      vessel_name:
        useOptionRecord?.name?.toString() ||
        selectedItem?.vessel_name.toString(),
      vessel_type:
        useOptionRecord?.vt_verbose?.toString() ||
        selectedItem?.vessel_type.toString(),
      vessel_flag_iso:
        useOptionRecord?.cn_iso2?.toString() ||
        selectedItem?.vessel_flag_iso.toString(),
      vessel_imo_no:
        useOptionRecord?.imo_number?.toString() ||
        selectedItem?.vessel_imo_no.toString(),
    };

    try {
      const editRequest = await AbstractEdit(
        this.state.itemInEditMode.id,
        editPayload,
        "vessels",
        options
      );
      const countMasterEntity = await serverApi(
        "GET",
        "vesselVs/count",
        "",
        "",
        options
      );

      if (editRequest.status === 200 && countMasterEntity) {
        this.applyInitialColumnsOnGrid();

        this.setState(
          {
            ...this.state,
            visibleWindow: false,
            editRecordsFleetmonWindow: false,
            finalFleetmonData: [],
            width: "80%",
            gridData: {
              data: data || [],
              total: countMasterEntity?.data?.count,
            },
            successMessage: "The vessel was successfully updated.",
            openSuccessMessage: true,
          },
          () => {
            this.discard(this.state.itemInEditMode, false);
            this.removeItem(data, editPayload);
            this.refreshGridDataAfterCrud();
            setTimeout(() => {
              this.setState({
                ...this.state,
                successMessage: "",
                openSuccessMessage: false,
              });
            }, 5000);
          }
        );
      }
    } catch (error) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          editRecordsFleetmonWindow: false,
          errorModalContent:
            error?.response?.data?.error?.message ||
            "Updating vessel has failed.Please try again",
        },
        () => this.discard(this.state.itemInEditMode, false)
      );
    }
  };

  onDialogInputChange = (event) => {
    let target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.props ? target.props.name : target.name;

    this.setState({
      ...this.state,
      isDisabled: value === "" ? true : false,
      gridData: {
        ...this.state.gridData,
        [name]: value,
      },
      [name]: value,
    });
  };

  toggleWindowRemoveClients = (e, dataItem) => {
    this.setState({
      ...this.state,
      deleteAllClients: !this.state.deleteAllClients,
      dataItem: dataItem,
    });
  };

  disableVessel = async (data) => {
    //todo check if has client and error then - BYPASSED

    const options = { token: window.localStorage.getItem("access_token") };

    try {
      const editRequest = await AbstractEdit(
        data.id,
        { is_active: false, valid_until: Date.now() },
        "vessels",
        options
      );
      if (editRequest) {
        let count;
        const responseData = await serverApi(
          "GET",
          `vessels?filter={"include":[],"where":{"and":[{}]},"limit":"100","skip":"0"}`,
          {},
          "",
          options
        );
        const countMasterEntity = await serverApi(
          "GET",
          "vessels/count",
          "",
          "",
          options
        );
        if (countMasterEntity) {
          count = countMasterEntity.data.count;
        }

        this.setState(
          {
            ...this.state,
            openDataItem: {},
            gridData: {
              data: responseData.data,
              total: count === undefined ? responseData.data.length : count,
            },
            successMessage: "The vessel was successfully disabled.",
            openSuccessMessage: true,
          },
          () => {
            this.setState({
              ...this.state,
              successMessage: "",
              openSuccessMessage: false,
            });
          }
        );
      }
    } catch (error) {
      this.setState(
        {
          ...this.state,
          openDataItem: {},
          openErrorModal: true,
          errorModalContent:
            error?.response?.data?.error?.message ||
            "Deactivate vessel's process has failed.Please try again.",
        },
        () => {
          setTimeout(() => {
            this.setState({
              ...this.state,
              openErrorModal: false,
              errorModalContent: "",
            });
          }, 5000);
        }
      );
    }
  };

  enableVessel = async (data) => {
    const options = { token: window.localStorage.getItem("access_token") };

    try {
      const editRequest = await AbstractEdit(
        data.id,
        { is_active: true, valid_from: Date.now(), valid_until: null },
        "vessels",
        options
      );
      if (editRequest) {
        let count;
        const responseData = await serverApi(
          "GET",
          `vessels?filter={"include":[],"where":{"and":[{}]},"limit":"100","skip":"0"}`,
          {},
          "",
          options
        );
        const countMasterEntity = await serverApi(
          "GET",
          "vessels/count",
          "",
          "",
          options
        );
        if (countMasterEntity) {
          count = countMasterEntity.data.count;
        }

        this.setState(
          {
            ...this.state,
            openDataItem: {},
            gridData: {
              data: responseData.data,
              total: count === undefined ? responseData.data.length : count,
            },
            successMessage: "The vessel was successfully enabled.",
            openSuccessMessage: true,
          },
          () => {
            this.setState({
              ...this.state,
              successMessage: "",
              openSuccessMessage: false,
            });
          }
        );
      }
    } catch (error) {
      this.setState(
        {
          ...this.state,
          openDataItem: {},
          openErrorModal: true,
          errorModalContent:
            error?.response?.data?.error?.message ||
            "Activate vessel's process has failed.Please try again.",
        },
        () => {
          setTimeout(() => {
            this.setState({
              ...this.state,
              openErrorModal: false,
              errorModalContent: "",
            });
          }, 5000);
        }
      );
    }
  };

  removeAllClients = async (e, dataItem) => {
    let vesselID = dataItem && dataItem.id;
    let deleteUrl = `vessels/${vesselID}/vesselToClient`;
    const token = window.localStorage.getItem("access_token");

    let refreshParams = window.localStorage.getItem("refreshParams");
    refreshParams = JSON.parse(refreshParams);

    try {
      const responseData = await serverApi("DELETE", deleteUrl, "", "", {
        token: token,
      });

      if (responseData) {
        let count;
        const responseData = await serverApi(
          "GET",
          `vesselVs`,
          refreshParams,
          "",
          {
            token: token,
          }
        );
        const countMasterEntity = await serverApi(
          "GET",
          `vesselVs/count?where=${JSON.stringify(refreshParams.filter.where)}`,
          "",
          "",
          {
            token: token,
          }
        );
        if (countMasterEntity) {
          count = countMasterEntity.data.count;
        }

        this.setState(
          {
            ...this.state,
            gridData: {
              data: responseData.data,
              total: count === undefined ? responseData.data.length : count,
            },
            deleteAllClients: false,
          },
          () => {
            setTimeout(() => {
              this.expandChange(null);
            }, 1000);
          }
        );
      }
    } catch (e) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            "Remove all clients from vessel has failed.Please try again.",
        },
        () => {
          setTimeout(() => {
            this.setState({
              ...this.state,
              openErrorModal: false,
              errorModalContent: "",
            });
          }, 4000);
        }
      );
    }
  };

  handleContextMenuOpen = (e, dataItem) => {
    //if item is inEdit mode prohibit right click
    if (dataItem.inEdit === true) {
      return;
    }

    //backofficeRead role has no authority to perform actions
    if (this.props.hasBackofficeRead) {
      this.setState({
        open: false,
        openDataItem: {},
      });
      return;
    }

    //if is_active flag is false or there is no vessel imo on the record then the change vessel name functionality cannot initiate.
    if (dataItem.is_active === false && !dataItem.vessel_imo_no) {
      this.setState({
        open: false,
        openDataItem: {},
      });
      return;
    } else if (
      dataItem.is_active === false &&
      (dataItem.vessel_imo_no !== "" || dataItem.vessel_imo_no)
    ) {
      this.dataItem = dataItem;
      this.dataItemIndex = this.state.gridData.data.findIndex(
        (p) => p.id === this.dataItem.id
      );
      this.offset = { left: e.clientX, top: e.clientY };

      if (_.isEmpty(this.state.openDataItem)) {
        //todo handle two popup opened
        this.setState({
          open: true,
          openDataItem: dataItem,
        });
      }
    } else if (dataItem.is_active === true || dataItem.vessel_imo_no !== "") {
      this.dataItem = dataItem;
      this.dataItemIndex = this.state.gridData.data.findIndex(
        (p) => p.id === this.dataItem.id
      );
      this.offset = { left: e.clientX, top: e.clientY };

      if (_.isEmpty(this.state.openDataItem)) {
        //todo handle two popup opened
        this.setState({
          open: true,
          openDataItem: dataItem,
        });
      } else {
        return;
      }
    }
  };

  rowRender = (trElement, dataItem) => {
    const trProps = {
      ...trElement.props,
      onContextMenu: (e) => {
        e.preventDefault();

        const { is_active, vessel_imo_no, has_clients } = dataItem.dataItem;

        if (is_active === true && vessel_imo_no) {
          if (has_clients === true) {
            this.setState(
              {
                ...this.state,
                rightClickText: ["Change Vessel Name", "Set to inactive"], // TODO CHECK : BYBASS IF THE VESSEL BELONG TO CLIENT(s)
              },
              () => {}
            );
          } else if (has_clients === false || has_clients === undefined) {
            this.setState(
              {
                ...this.state,
                rightClickText: ["Change Vessel Name", "Set to inactive"],
              },
              () => {}
            );
          }
        } else if (is_active === false) {
          this.setState(
            {
              ...this.state,
              rightClickText: ["Set to active"],
            },
            () => {}
          );
        } else if (is_active === true && !vessel_imo_no) {
          this.setState(
            {
              ...this.state,
              rightClickText: ["Set to inactive"],
            },
            () => {}
          );
        }

        this.handleContextMenuOpen(e, dataItem.dataItem);
      },
      style: {
        color: dataItem.dataItem.is_active ? "#0d5869" : "#ed7070",
      },
    };

    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  onPopupOpen = () => {
    this.menuWrapperRef.querySelector("[tabindex]").focus();
  };

  onFocusHandler = () => {
    clearTimeout(this.blurTimeoutRef);
    this.blurTimeoutRef = undefined;
  };

  onBlurTimeout = () => {
    this.setState({
      open: false,
      openDataItem: {},
    });

    this.blurTimeoutRef = undefined;
  };

  onBlurHandler = (event) => {
    clearTimeout(this.blurTimeoutRef);
    this.blurTimeoutRef = setTimeout(this.onBlurTimeout);
  };

  goToChangeVesselNamePage = () => {
    this.openChangeVesselNameWindow();
  };

  openChangeVesselNameWindow = async () => {
    const options = { token: window.localStorage.getItem("access_token") };

    let tempExistingClients = [];
    let tempExistingOperators = [];
    let tempClients = [];
    const vesselID = this.state.openDataItem.id;
    const filter = {
      filter: {
        where: {
          vesselID: vesselID,
        },
        include: ["masterEntity"],
      },
    };

    const activeOrderFilter = {
      filter: {
        where: {
          and: [
            {
              vesselID: vesselID,
            },
            {
              status_code: {
                inq: ["RAS", "PFD", "PEND", "EXPONS", "STOCK", "STOCK_TBC"],
              },
            },
          ],
        },
      },
    };

    const getActiveOrders = await serverApi(
      "GET",
      "orders/count",
      activeOrderFilter.filter,
      "",
      options
    );

    if (getActiveOrders?.data?.count > 0) {
      this.setState({
        ...this.state,
        moveActiveOrdersSwitchFlag: false,
        activeOrders: getActiveOrders?.data?.count,
      });
    } else if (getActiveOrders?.data?.count === 0) {
      this.setState({
        ...this.state,
        moveActiveOrdersSwitchFlag: true,
        activeOrders: 0,
      });
    }

    const getExistingClients = await serverApi(
      "GET",
      `vesselToClients`,
      filter,
      "",
      options
    );
    const getExistingOperators = await serverApi(
      "GET",
      `snuserOperatorVs`,
      "",
      "",
      options
    );

    let allClientRecords = {
      data: [],
    };
    let howMany = await serverApi(
      "GET",
      `masterEntities/count`,
      {
        where: {
          and: [
            {
              client_flag: true,
            },
          ],
        },
      },
      "",
      options
    );
    const numberOfCalls = Math.min(
      Math.round(howMany.data.count / 1000) + 1,
      5 // Limit to 5000
    );

    const promises = [];
    for (let i = 0; i < numberOfCalls; i++) {
      promises.push(
        this.getClientPromise({
          skip: i * 1000,
          limit: 1000,
          order: ["id asc"],
          where: {
            and: [
              {
                client_flag: true,
              },
            ],
          },
        })
      );
    }

    try {
      let responses = await Promise.all(promises);
      responses.length && responses.map((x) => x.data).flat();
      responses.forEach((resp) => {
        _.forEach(resp.data, (temp) => {
          allClientRecords.data.push({
            ...temp,
          });
        });
      });
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent:
          e?.response?.data?.error?.message || "Please try again",
      });
    }

    _.forEach(getExistingClients?.data, (existingClient) => {
      tempExistingClients.push({
        relation_name: existingClient.masterEntity.relation_name,
        clientID: existingClient.clientID,
      });
    });
    _.forEach(getExistingOperators?.data, (existingOperator) => {
      tempExistingOperators.push({
        email: existingOperator.email,
        userID: existingOperator.id,
      });
    });

    let allClientsArr = allClientRecords?.data || [];
    allClientsArr.forEach((temp) => {
      tempClients.push({
        relation_name: temp.relation_name,
        clientID: temp.id,
      });
    });

    this.setState({
      ...this.state,
      clientsAvailable: tempClients
        .map((x) => x.relation_name)
        .sort((a, b) => (a.relation_name > b.relation_name ? 1 : -1)),
      existingClientsAvailable: tempExistingClients
        .map((x) => x.relation_name)
        .sort((a, b) => (a.relation_name > b.relation_name ? 1 : -1)),
      existingOperatorsAvailable: (tempExistingOperators || []).map(
        (x) => x.email
      ),
      existingClientsAvailableInitial: (tempExistingClients || []).map(
        (x) => x.relation_name
      ),
      existingOperatorsAvailableInitial: (tempExistingOperators || []).map(
        (x) => x.email
      ),
      allClientsArr: allClientRecords.data || [],
      blocking: false,
      openChangeVesselWindow: true,
    });
  };

  getClientPromise = (filter) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    return new Promise((resolve, reject) => {
      const req = serverApi(
        "GET",
        `masterEntities?filter=${JSON.stringify(filter)}`,
        "",
        "",
        options
      );
      req
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  handleOnSelect = (e) => {
    let data = this.state.openDataItem;
    let type = e.item.text;

    switch (type) {
      case "Change Vessel Name":
        this.goToChangeVesselNamePage(data);
        break;

      case "Set to inactive":
        this.disableVessel(data);
        break;

      case "Set to active":
        this.enableVessel(data);
        break;

      default:
    }

    this.setState({
      ...this.state,
      open: false,
    });
  };

  closeChangeVesselWindow = () => {
    this.setState({
      ...this.state,
      openChangeVesselWindow: false,
      openDataItem: {},
      windowErrorMessage: "",
      windowError: false,
      activeOrdersFlg: false,
      moveToNewClientFlg: false,
      vesselToClientSelected: [],
      activeOrdersClientSelected: "",
      notifyEmailOperator: "",
    });
  };

  closeCreateVesselOffice = () => {
    this.setState({
      ...this.state,
      columns: this.state.initialColumns,
      hideColumns: false,
      gridData: {
        ...this.state.gridData,
        vessel_imo_no: "",
      },
      createVesselOfficeWindow: false,
      showZeroResultsMessage: false,
      vessel_name: "",
      vessel_imo_no: "",
      visibleWindow: false,
      finalFleetmonData: [],
      selectedRowIndex: 0,
      selectedItem: null,
    });
  };

  closeCreateVesselManually = () => {
    this.setState({
      ...this.state,
      hideColumns: false,
      createVesselManuallyWindow: false,
      showZeroResultsMessage: false,
      vessel_name: "",
      vessel_imo_no: "",
      vessel_type: "",
      vessel_flag_iso: "",
    });
  };

  handleClientsChange = (event) => {
    let disabledSave = false;
    if (event.target.value.length === 0) {
      disabledSave = true;
    }

    this.setState({
      ...this.state,
      vesselToClientSelected: event.target.value,
      isDisabled: disabledSave,
    });
  };

  changeVesselName = async () => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let finalClients = [];
    let existingSelectedClient = [];
    let notifyEmailOperator = this.state.notifyEmailOperator;

    if (this.state.moveToNewClientFlg) {
      _.forEach(this.state.vesselToClientSelected, (temp) => {
        if (this.state.allClientsArr.filter((x) => x.relation_name === temp)) {
          let clientID = this.state.allClientsArr.filter(
            (x) => x.relation_name === temp
          )[0].id;
          finalClients.push({
            clientID: clientID,
            clientName: temp,
          });
        }
      });
    }

    if (this.state.activeOrdersFlg) {
      if (this.state.activeOrdersClientSelected !== "") {
        let existingClientID =
          this.state.allClientsArr.filter(
            (x) => x.relation_name === this.state.activeOrdersClientSelected
          )[0]?.id || null;
        let existingClientName =
          this.state.allClientsArr.filter(
            (x) => x.relation_name === this.state.activeOrdersClientSelected
          )[0]?.relation_name || null;

        if (existingClientID && existingClientName) {
          existingSelectedClient.push({
            clientID: existingClientID,
            clientName: existingClientName,
          });
        }
      }
    }

    let payload = {
      vesselID: this.state.openDataItem.id,
      vesselName: this.state.vessel_name,
      activeOrdersFlg: this.state.activeOrdersFlg,
      moveToNewClientFlg: this.state.moveToNewClientFlg,
      newClients: finalClients,
      moveOrderToExistingClient: existingSelectedClient,
      notifyEmailOperator: notifyEmailOperator,
    };

    try {
      const request = await serverApi(
        "POST",
        "vessels/changeVesselName",
        "",
        payload,
        options
      );

      let messageRest =
        request?.data?.response?.activeOrdersChanged?.length === 1
          ? "order moved"
          : "orders moved";
      let messageResponse = `${request?.data?.response?.activeOrdersChanged?.length} ${messageRest}`;

      this.setState(
        {
          ...this.state,
          openChangeVesselWindow: false,
          openDataItem: {},
          activeOrdersFlg: false,
          moveToNewClientFlg: false,
          vessel_name: "",
          clientIDs: [],
          vesselToClientSelected: [],
          clientsAvailable: [],
          activeOrdersClientSelected: "",
        },
        () => {
          setTimeout(() => {
            this.setState(
              {
                ...this.state,
                openSuccessModal: true,
                successModalContent: `Change vessel name process has finished successfully. ( ${messageResponse} )`,
                windowErrorMessage: "",
                notifyEmailOperator: "",
                windowError: false,
              },
              () => {
                this.refreshGridDataAfterCrud();
              }
            );
          }, 500);
        }
      );
    } catch (e) {
      let message = e?.response?.data?.error?.message;

      this.setState({
        ...this.state,
        visibleWindow: false,
        windowError: true,
        windowErrorMessage:
          message ||
          "Change vessel process has failed.Please note that all the data will be exactly the same and not altered in the database.",
      });
    }
  };

  handleChange = (name) => (event) => {
    let options = [];

    if (this.state.activeOrdersFlg) {
      if (event.target.checked === true) {
        options = this.state.vesselToClientSelected;
      } else {
        options = this.state.existingClientsAvailable;
      }
    } else if (this.state.activeOrdersFlg && !this.state.moveToNewClientFlg) {
      options = this.state.existingClientsAvailable;
    } else {
      options = this.state.existingClientsAvailable;
    }

    this.setState({
      ...this.state,
      existingClientsAvailable: options,
    });
    if (event.target.checked === false) {
      this.setState({
        [name]: event.target.checked,
        vesselToClientSelected: [],
        existingClientsAvailable: this.state.existingClientsAvailableInitial,
      });
    } else if (event.target.checked === true) {
      this.setState({
        windowError: false,
        windowErrorMessage: "",
        [name]: event.target.checked,
      });
    }
  };

  handleChange2 = (name) => (event) => {
    let options = [];
    if (this.state.moveToNewClientFlg) {
      if (event.target.checked === true) {
        options = this.state.vesselToClientSelected;
      } else {
        options = this.state.existingClientsAvailable;
      }
    } else {
      options = this.state.existingClientsAvailable;
    }

    this.setState({
      ...this.state,
      existingClientsAvailable: options,
    });
    if (event.target.checked === false) {
      this.setState({
        [name]: event.target.checked,
        existingClientsAvailable: this.state.existingClientsAvailableInitial,
      });
    } else if (event.target.checked === true) {
      this.setState({
        windowError: false,
        windowErrorMessage: "",
        [name]: event.target.checked,
      });
    }
  };

  closeSuccessModal = () => {
    this.setState({ openSuccessModal: false, successModalContent: "" });
  };

  openSuccessModal = (message) => {
    this.setState({ openSuccessModal: true, successModalContent: message });
  };

  onChange = (event) => {
    this.setState({
      ...this.state,
      activeOrdersClientSelected: event.target.value,
    });
  };

  onChangeVesselType = (event) => {
    this.setState({
      ...this.state,
      noneVesselTypeValue: event.target.value,
    });
  };

  onChangeNotifyEmail = (event) => {
    this.setState({ ...this.state, notifyEmailOperator: event.target.value });
  };

  onFilterChange = (event) => {
    let filteredResults = this.filterDataNotifyOperators(event.filter);
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.setState({
        existingOperatorsAvailable:
          filteredResults !== undefined
            ? filteredResults
            : this.state.existingOperatorsAvailableInitial,
        loading: false,
      });
    }, this.state.delay);

    this.setState({
      loading: true,
    });
  };

  filterDataNotifyOperators(filter) {
    if (filter.value === "") {
      this.setState({
        existingOperatorsAvailable:
          this.state.existingOperatorsAvailableInitial,
      });

      return;
    } else {
      const data = this.state.existingOperatorsAvailableInitial;
      filter.operator = "startswith";
      let filteredResults = filterBy(data, filter);

      return filteredResults;
    }
  }

  openCreateVesselManually = async () => {
    this.setState({
      ...this.state,
      visibleWindow: false,
      createVesselManuallyWindow: true,
      vessel_name: "",
      vessel_type: "",
      vessel_imo_no: "",
      vessel_flag_iso: "",
      showZeroResultsMessage: false,
    });
  };

  saveVesselOffice = async () => {
    const options = { token: window.localStorage.getItem("access_token") };
    let payload = {};
    let count;
    payload.vessel_name = this.state.vessel_name;
    payload.vessel_imo_no = "0000000";
    payload.vessel_type = "Office";
    payload.is_government = this.state.isGovernment;
    payload.vesselTypeId = this.returnVesselTypeId();

    if (!payload.vessel_name || payload.vessel_name === "") {
      this.setState(
        {
          ...this.state,
          windowError: true,
          windowErrorMessage:
            "Please provide a valid name in order to continue.",
        },
        () => {
          setTimeout(() => {
            this.setState({
              ...this.state,
              windowError: false,
              windowErrorMessage: "",
            });
          }, 4000);
        }
      );

      return;
    }

    try {
      const createRequest = await serverApi(
        "POST",
        `vessels`,
        "",
        payload,
        options
      );
      if (createRequest) {
        const responseData = await serverApi(
          "GET",
          `vesselVs?filter={"include":[],"where":{"and":[{}]},"limit":"100","skip":"0"}`,
          {},
          "",
          options
        );
        const countMasterEntity = await serverApi(
          "GET",
          "vesselVs/count",
          "",
          "",
          options
        );
        if (countMasterEntity) {
          count = countMasterEntity.data.count;
        }
        this.applyInitialColumnsOnGrid();

        this.setState(
          {
            ...this.state,
            columns: this.state.initialColumns,
            gridData: {
              data: responseData.data,
              total: count === undefined ? responseData.data.length : count,
            },
            successMessage: "A new office was successfully created",
            openSuccessMessage: true,
            createVesselOfficeWindow: false,
            vessel_name: "",
            windowError: false,
            windowErrorMessage: "",
            isGovernment: false,
          },
          () => {
            setTimeout(() => {
              this.refreshGridDataAfterCrud();
              this.handleSuccessSnackbar();
            }, 4000);
          }
        );
      }
    } catch (error) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            error?.response?.data?.error?.message ||
            "Create new office has failed.Please try again",
        },
        () => {
          setTimeout(() => {
            this.setState({
              ...this.state,
              openErrorModal: false,
              errorModalContent: "",
            });
          }, 5000);
        }
      );
    }
  };

  returnVesselTypeId = () => {
    const { vesselLovTypes, vesselTypeCategory } = this.props;

    if (!vesselTypeCategory) {
      return null; // Return null or handle the case when vesselTypeCategory is undefined
    }

    const matchingLovType = (vesselLovTypes || []).find(
      (x) => x.lov_value === vesselTypeCategory.text
    );

    return matchingLovType ? matchingLovType.id : null;
  };

  saveVesselManually = async () => {
    const options = { token: window.localStorage.getItem("access_token") };
    let payload = {};
    let count;

    payload.vessel_name = this.state.vessel_name;
    payload.vessel_imo_no = this.state.vessel_imo_no;
    payload.vessel_type = this.state.vessel_type;
    payload.vessel_flag_iso = this.state.vessel_flag_iso;
    payload.is_government = this.state.isGovernment;
    payload.vesselTypeId = this.returnVesselTypeId();

    if (this.state.is_yatchChecked && payload.vessel_name === "") {
      this.setState({
        ...this.state,
        windowError: true,
        windowErrorMessage:
          "Please provide a vessel name in order to continue.",
      });
      return;
    } else if (!this.state.is_yatchChecked) {
      if (
        payload?.vessel_imo_no === "0000000" ||
        payload?.vessel_imo_no === "" ||
        !payload.vessel_imo_no
      ) {
        this.setState({
          ...this.state,
          windowError: true,
          windowErrorMessage:
            "The imo number you provided is invalid.Please enter a valid one to create an actual vessel.",
        });
        return;
      }
      if (!payload.vessel_name || payload.vessel_name === "") {
        this.setState({
          ...this.state,
          windowError: true,
          windowErrorMessage:
            "Vessel name cannot be empty.Please enter a valid one to create an actual vessel.",
        });
        return;
      }
    }

    //numeric validation and exactly 7 length for the IMO of the vessel.
    const digits_only = (string) =>
      [...string].every((c) => "0123456789".includes(c));
    let decide = digits_only(payload?.vessel_imo_no);

    if (this.state.is_yatchChecked) {
      //by pass the validations
    } else {
      if (decide) {
        decide = payload?.vessel_imo_no?.length === 7 ? true : false;
      } else {
        this.setState({
          ...this.state,
          windowError: true,
          windowErrorMessage:
            "Please provide a valid IMO number in order to continue.",
        });
        return;
      }
    }

    try {
      const createRequest =
        decide && (await serverApi("POST", `vessels`, "", payload, options));
      if (createRequest) {
        const responseData = await serverApi(
          "GET",
          `vesselVs?filter={"include":[],"where":{"and":[{}]},"limit":"100","skip":"0"}`,
          {},
          "",
          options
        );
        const countMasterEntity = await serverApi(
          "GET",
          "vesselVs/count",
          "",
          "",
          options
        );
        if (countMasterEntity) {
          count = countMasterEntity.data.count;
        }

        this.setState(
          {
            ...this.state,
            is_yatchChecked: false,
            gridData: {
              data: responseData.data,
              total: count === undefined ? responseData.data.length : count,
            },
            successMessage: "A new vessel was successfully created.",
            openSuccessMessage: true,
            createVesselManuallyWindow: false,
            windowError: false,
            windowErrorMessage: "",
            vessel_name: "",
            vessel_imo_no: "",
            vessel_type: "",
            vessel_flag_iso: "",
          },
          () => {
            setTimeout(() => {
              this.refreshGridDataAfterCrud();
              this.applyInitialColumnsOnGrid();
              this.handleSuccessSnackbar();
            }, 5000);
          }
        );
      }
    } catch (error) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            error?.response?.data?.error?.message ||
            "Create new vessel has failed.Please try again",
        },
        () => {
          setTimeout(() => {
            this.setState({
              ...this.state,
              openErrorModal: false,
              errorModalContent: "",
            });
          }, 5000);
        }
      );
    }
  };

  handleSwitch = (name) => (event) => {
    if (event.target.checked === false) {
      this.setState({ [name]: event.target.checked, vessel_type: "" });
    } else if (event.target.checked === true) {
      this.setState({
        windowError: false,
        windowErrorMessage: "",
        [name]: event.target.checked,
        vessel_type: "NO_IMO",
      });
    }
  };

  listNoDataRender = (element) => {
    const noData = (
      <h4 style={{ fontSize: "1em" }}>
        <span className="k-icon k-i-warning" style={{ fontSize: "2.5em" }} />
        <br />
        <br />
        No client names found
      </h4>
    );

    return React.cloneElement(element, { ...element.props }, noData);
  };

  onChangeClientName = (event) => {
    //prevent enter keyboard
    if (
      event.nativeEvent.type === "keydown" &&
      event.nativeEvent.keyCode === 13
    )
      return;

    const { value } = event.target;

    if (value === "") {
      this.setState({
        openedClientNames: false,
        valueClientName: value,
      });
    } else {
      this.setState(
        {
          openedClientNames: false,
          valueClientName: value,
        },
        () => {
          this.onAutoCompleteChange(event, value);
        }
      );
    }
  };

  onAutoCompleteChange = (event, value) => {
    const { name } = event.target;

    let stateData = {};

    stateData =
      value.length < 3
        ? {
            data:
              this.state.client_names.length > 0
                ? this.state.client_names.map((temp) => temp.relation_name)
                : [],
            openedClientNames: false,
          }
        : { data: this.filterData(value, name), openedClientNames: true };

    let eventType = event.nativeEvent.type;

    let valueSelected =
      eventType === "click" ||
      (eventType === "keydown" && event.nativeEvent.keyCode === 13);

    if (valueSelected) {
      let postClientId =
        this.state.client_names.length === 1
          ? this.state.client_names[0].id
          : (this.state.client_names || []).filter(
              (temp) => temp.relation_name === this.state.valueClientName
            )[0].id;

      this.setState({
        ...this.state,
        client_flag: true,
        postClientID: postClientId || "",
      });
    } else {
      this.setState({
        ...this.state,
        postClientID: null || "",
      });
    }

    if (valueSelected && stateData.data.includes(value)) {
      stateData.openedClientNames = false;
    }

    this.setState(
      {
        valueClientName: value,
        ...stateData,
      },
      () => this.fetchAutocompleteData(value)
    );
  };

  fetchAutocompleteData = (filterValue) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let filter = {
      order: ["relation_name asc"],
      where: {
        and: [
          { relation_name: { regexp: `/^${filterValue}/i` } },
          {
            client_flag: true,
          },
        ],
      },
    };

    const fetchDropdownData = serverApi(
      "GET",
      `masterEntities`,
      { filter: filter },
      "",
      options
    );

    fetchDropdownData
      .then((response) => {
        this.setState({
          ...this.state,
          client_names: response.data.sort((a, b) =>
            a.relation_name > b.relation_name ? 1 : -1
          ),
        });
      })
      .catch((error) => {
        console.log(error);

        throw error;
      });
  };

  filterData = (value, name) => {
    const data = this.state.client_names.map((test) => test.relation_name);
    const filter = {
      value: value,
      operator: "startswith",
      ignoreCase: true,
    };

    let results = filterBy(data, filter);
    return results;
  };

  saveNoneVesselType = async () => {
    try {
      let options = {
        token: window.localStorage.getItem("access_token"),
      };

      if (
        !this.state.valueClientName ||
        !this.state.noneVesselTypeValue.text ||
        !this.state.postClientID
      ) {
        this.setState(
          {
            ...this.state,
            openErrorModal: true,
            errorModalContent: `Please choose a valid client name and vessel type in order to continue.`,
          },
          () => {
            setTimeout(() => {
              this.setState({
                ...this.state,
                openErrorModal: false,
                errorModalContent: "",
              });
            }, 4500);
          }
        );
      } else {
        const createRequest = await serverApi(
          "POST",
          `vessels`,
          "",
          {
            vessel_name:
              this.state.valueClientName +
              " - " +
              this.state.noneVesselTypeValue.text,
            vessel_imo_no: "0000000",
            vessel_type: this.state.noneVesselTypeValue.text,
            is_government: this.state.isGovernment,
            vesselTypeId: this.returnVesselTypeId(),
          },
          options
        );
        if (createRequest) {
          this.refreshGridDataAfterCrud();
          // eslint-disable-next-line no-unused-vars
          let successMessage = `The non type vessel was successfully created`;
          const vesselID = createRequest.data.id;
          const clientID = this.state.postClientID;
          if (vesselID && clientID) {
            let addVesselToClientRequest = await serverApi(
              "POST",
              "vesselToClients",
              {},
              {
                clientID: clientID,
                vesselID: vesselID,
                primary_flg: true,
              },
              options
            );
            if (addVesselToClientRequest.status === 200) {
              successMessage = `${successMessage} ,with primary client: ${this.state.valueClientName}`;
            }
            this.setState(
              {
                ...this.state,
                visibleWindow: false,
                hideColumns: false,
                vessel_imo_no: null,
                noneVesselTypeFlg: false,
                valueClientName: "",
                noneVesselTypeValue: "",
                isGovernment: false,
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    ...this.state,
                    successMessage: "",
                    openSuccessMessage: false,
                  });
                }, 5000);
              }
            );
          } else {
            this.setState(
              {
                ...this.state,
                visibleWindow: false,
                openErrorModal: true,
                errorModalContent: `The creation of the vessel was succeeded, but the client:${this.state.valueClientName} was not set as primary.Use the client-vessel tab in order to add it.`,
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    ...this.state,
                    openErrorModal: false,
                    errorModalContent: "",
                  });
                }, 6000);
              }
            );
          }
        }
      }
    } catch (error) {
      this.setState(
        {
          ...this.state,
          visibleWindow: false,
          hideColumns: false,
          finalFleetmonData: [],
          selectedRowIndex: 0,
          selectedItem: null,
          vessel_imo_no: null,
          noneVesselTypeFlg: false,
          valueClientName: "",
          noneVesselTypeValue: "",
          openErrorModal: true,
          errorModalContent:
            "Create a non vessel type has failed.Please try again",
        },
        () => {
          setTimeout(() => {
            this.setState({
              ...this.state,
              openErrorModal: false,
              errorModalContent: "",
            });
          }, 5000);
        }
      );
    }
  };

  handleDropDownChange = (event, name) => {
    const value = event.value;

    this.setState({
      ...this.state,
      isDropOpened: !this.state.isDropOpened,
      gridData: {
        ...this.state.gridData,
        [name]: value,
      },
      [name]: value,
    });
  };

  onChangeGeneric = (event, name) => {
    this.setState({
      ...this.state,
      [name]: event.target.value,
    });
  };

  removeContact = async (dataItem) => {
    const data = [...this.state.gridData.data];

    let index = data.findIndex((p) => p === dataItem || p.id === dataItem.id);
    if (index >= 0) {
      data.splice(index, 1);
    }

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let url = "masterEntityContacts";

    try {
      let deleteItemRequest = await AbstractDelete(
        url,
        `${dataItem.id}`,
        options
      );

      if (deleteItemRequest.status === 200) {
        this.setState({
          ...this.state,
          dataItem: {},
          deleteVisible: false,
        });
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent:
          e?.response?.data?.error?.message || "Please try again",
      });
    }
  };

  toggleDeleteDialog(dataItem = {}, state) {
    this.setState({
      ...state,
      deleteVisible: !this.state.deleteVisible,
      itemForDelete: dataItem,
    });
  }

  render() {
    const { gridData, columns, finalFleetmonData, selectedItem, dataItem } =
      this.state;
    const hasEditedItem = gridData && gridData.data.some((p) => p.inEdit);

    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    let hasColumnFilters = true;
    if (found) {
      hasColumnFilters = false;
    }
    const { hasBackofficeRead } = this.props;

    return (
      <div>
        <Popup
          offset={this.offset}
          show={this.state.open}
          open={this.onPopupOpen}
          popupClass={"popup-content"}
        >
          <div
            onFocus={this.onFocusHandler}
            onBlur={this.onBlurHandler}
            tabIndex={-1}
            ref={(el) => (this.menuWrapperRef = el)}
          >
            <Menu
              vertical={true}
              style={{ display: "inline-block" }}
              onSelect={this.handleOnSelect}
            >
              {this.state.rightClickText.map((text) => {
                return <KendoMenuItem text={text} />;
              })}
            </Menu>
          </div>
        </Popup>

        {this.state.deleteVisible && (
          <Dialog onClose={() => this.toggleDeleteDialog({}, this.state)}>
            <p style={{ margin: "25px", textAlign: "center" }}>
              Are you sure you want to delete this contact?
            </p>
            <DialogActionsBar>
              <button
                className="k-button"
                onClick={() => this.toggleDeleteDialog({}, this.state)}
              >
                No
              </button>
              <button
                className="k-button"
                onClick={() => {
                  this.removeContact(this.state.dataItem);
                }}
              >
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}

        <Grid
          {...this.state.dataState}
          {...this.state.gridData}
          filterable={hasColumnFilters}
          style={{ height: "92vh" }}
          sortable={true}
          resizable
          editField={this.editField}
          onItemChange={this.itemChange}
          onDataStateChange={this.dataStateChange}
          rowRender={this.rowRender}
          detail={(dataItem) => (
            <div>
              <ExpandedTabs
                tabs={[
                  {
                    label: "Client-Vessel",
                    component: (
                      <VesselToClientExpanded
                        hasBackofficeRead={hasBackofficeRead}
                        openSuccessModal={(message) =>
                          this.openSuccessModal(message)
                        }
                        {...dataItem}
                      />
                    ),
                  },
                  {
                    label: "Client Vessel History",
                    component: (
                      <VesselClientHistory
                        hasBackofficeRead={hasBackofficeRead}
                        {...dataItem}
                      />
                    ),
                  },
                  {
                    label: "Fleets To Vessel",
                    component: (
                      <FleetsToVessel
                        hasBackofficeRead={hasBackofficeRead}
                        {...dataItem}
                      />
                    ),
                  },
                  {
                    label: "User To Vessel",
                    component: (
                      <UserToVessel
                        hasBackofficeRead={hasBackofficeRead}
                        {...dataItem}
                      />
                    ),
                  },
                  {
                    label: "Contacts",
                    component: (
                      <Contacts
                        countries={this.state.countries}
                        hasBackofficeRead={hasBackofficeRead}
                        {...dataItem}
                        expandedLabel="VESSEL NAME"
                        view="Vessel"
                        showRemoveWindow={(dataItem) => {
                          this.setState({
                            ...this.state,
                            deleteVisible: true,
                            dataItem: dataItem,
                          });
                        }}
                      />
                    ),
                  },
                ]}
              ></ExpandedTabs>
            </div>
          )}
          onRowClick={(e) => this.rowClick(e)}
          expandField="expanded"
          onExpandChange={this.expandChangeAsClientView}
          pageable={{
            buttonCount: 5,
            info: true,
            pageSizes: [100, 200, 500, 1000, 1500],
          }}
        >
          <GridToolbar>
            <GridContainer xs={12} direction={"row"}>
              {!hasBackofficeRead && this.state.showFleetmonCreateVessel ? (
                <div>
                  <Tooltip
                    justify={"flex-start"}
                    placement="top"
                    title={"Add Vessel"}
                  >
                    <IconButton onClick={this.openFleetmonPopup}>
                      <FontAwesomeIcon
                        color="#0D5869"
                        onClick={this.openFleetmonPopup}
                        size="1.6x"
                        icon={faPlus}
                      />
                    </IconButton>
                  </Tooltip>
                </div>
              ) : null}

              <Tooltip
                justify={"flex-end"}
                placement="top"
                title={"Clear Filters"}
              >
                <IconButton
                  disabled={false}
                  aria-label=""
                  onClick={this.onClearFilters}
                  look="flat"
                >
                  <FontAwesomeIcon
                    color="#0D5869"
                    size="1.6x"
                    icon={faEraser}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip
                justify={"flex-start"}
                placement="top"
                title={"Refresh Data"}
              >
                <IconButton
                  disabled={false}
                  aria-label=""
                  onClick={this.refreshGridDataAfterCrud}
                >
                  <FontAwesomeIcon color="#0D5869" size="1x" icon={faSyncAlt} />
                </IconButton>
              </Tooltip>
              <ViewSettings
                {...this.props}
                export={{
                  data: this.state.exportData || [],
                  fileName: "Vessels_",
                  exportFunction: this.getExportData,
                  columns: exportSchema(),
                }}
                viewSettings={{
                  type: "",
                }}
              />
              {!this.state.hideColumns ? (
                <div style={{ marginTop: hasBackofficeRead ? "-12px" : "" }}>
                  <FormControl className={customSelectStyles.selectFormControl}>
                    <InputLabel
                      htmlFor="multiple-select"
                      className={customSelectStyles.selectLabel}
                    />
                    <Select
                      autoWidth={true}
                      multiple
                      value={columns
                        .filter((column) => column.visible)
                        .map((column) => column.field)}
                      onChange={this.handleColumnsChange}
                      MenuProps={{ className: customSelectStyles.selectMenu }}
                      inputProps={{
                        name: "multipleSelect",
                        id: "multiple-select",
                      }}
                      renderValue={(selected) => "Columns"}
                    >
                      {columns
                        .filter((col) => !col.notShownInTableColumnSelection)
                        .map((column) => {
                          let name = column.title;
                          let value = column.field;
                          let isVisible = column.visible;
                          let isDisabled = column.noDisabledColumn;

                          return (
                            <MenuItem
                              style={{ backgroundColor: "white" }}
                              key={name}
                              value={value}
                              disabled={isDisabled}
                            >
                              <Checkbox color={"default"} checked={isVisible} />
                              <ListItemText
                                style={{ marginLeft: "7px" }}
                                primary={name}
                              />
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
              ) : null}
            </GridContainer>
          </GridToolbar>

          {!hasBackofficeRead && (
            <Column
              cell={this.CommandCell}
              title="Actions"
              filterable={false}
              width={hasEditedItem ? "240px" : "140px"}
            />
          )}
          {this.renderGridColumns()}
        </Grid>

        <Loader
          dataState={this.state.dataState}
          onDataRecieved={this.dataRecieved}
          include={[]}
          getURL={"vesselVs"}
          countURL={"vesselVs/count"}
        />

        <SuccessModal
          open={this.state.openSuccessModal}
          onCloseModal={this.closeSuccessModal}
          modalContent={this.state.successModalContent}
          timeout={3500}
        />

        <Snackbar
          place="tc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"warning"}
          message={this.state.errorModalContent}
          open={this.state.openErrorModal}
        />

        <Snackbar
          place="tc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"success"}
          message={this.state.successMessage}
          open={this.state.openSuccessMessage}
        />

        {this.state.visibleWindow && (
          <Window
            title={`${this.state.visibleWindowTitle}`}
            onClose={() => {
              let gridData = { ...this.state.gridData };
              let data = gridData.data;

              this.removeItem(data, data[0]);

              this.setState({
                ...this.state,
                vesselTypeCategory: {},
                noneVesselTypeValue: {},
                vessel_imo_no: "",
                imoPlaceHolderTxt: `*In order to create a vessel as an office provide 0000000
                    and click search button`,
                visibleWindowTitle: "Create Vessel Process",
              });

              this.setState(
                {
                  ...this.state,
                  gridData: gridData,
                  visibleWindow: false,
                  hideColumns: false,
                  finalFleetmonData: [],
                  selectedRowIndex: 0,
                  selectedItem: null,
                  vessel_imo_no: null,
                  noneVesselTypeFlg: false,
                  valueClientName: "",
                },
                () => this.applyInitialColumnsOnGrid()
              );
            }}
            width={
              !_.isEmpty(finalFleetmonData) && finalFleetmonData.length > 0
                ? 1200
                : 950
            }
            height={
              !_.isEmpty(finalFleetmonData) && finalFleetmonData.length > 0
                ? 640
                : 550
            }
          >
            <div>
              <fieldset style={{ borderColor: "rgba(13, 88, 105, 1)" }}>
                <span>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.noneVesselTypeFlg}
                        onChange={(event) => {
                          this.setState(
                            {
                              ...this.state,
                              noneVesselTypeFlg: !this.state.noneVesselTypeFlg,
                            },
                            () => {
                              setTimeout(() => {
                                if (!this.state.noneVesselTypeFlg) {
                                  this.setState({
                                    ...this.state,
                                    vesselTypeCategory: {},
                                    noneVesselTypeValue: {},
                                    vessel_imo_no: "",
                                    imoPlaceHolderTxt: `*In order to create a vessel as an office provide 0000000
                    and click search button`,
                                    visibleWindowTitle: "Create Vessel Process",
                                  });
                                } else {
                                  this.setState({
                                    ...this.state,
                                    vesselTypeCategory: {},
                                    noneVesselTypeValue: {},
                                    vessel_imo_no: "0000000",
                                    imoPlaceHolderTxt: ``,
                                    visibleWindowTitle:
                                      "Create Non Vessel Type",
                                  });
                                }
                              }, 5);
                            }
                          );
                        }}
                        value="noneVesselTypeFlg"
                        color="primary"
                      />
                    }
                    label="Non Vessel Type"
                  />
                </span>
                {this.state.noneVesselTypeFlg && (
                  <span>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.isGovernment}
                          onChange={(event) => {
                            this.setState({
                              ...this.state,
                              isGovernment: !this.state.isGovernment,
                            });
                          }}
                          value="isGovernment"
                          color="primary"
                        />
                      }
                      label="Is Government"
                    />
                  </span>
                )}
                <span>
                  <span>
                    {this.state.noneVesselTypeFlg ? (
                      <span>
                        <h3>Vessel Type</h3>
                        <DropDownList
                          data={this.state.vesselTypeData}
                          textField="text"
                          dataItemKey="id"
                          onChange={this.onChangeVesselType}
                          style={{ width: "250px" }}
                          value={this.state.noneVesselTypeValue}
                        />
                      </span>
                    ) : null}
                  </span>
                  {this.state.noneVesselTypeFlg ? (
                    <div>
                      <AutoComplete
                        style={{ width: "450px" }}
                        data={(this.state.client_names || []).map(
                          (temp) => temp.relation_name
                        )}
                        value={this.state.valueClientName}
                        label={"Client name"}
                        name={"relation_name"}
                        onChange={(e) => this.onChangeClientName(e)}
                        listNoDataRender={this.listNoDataRender}
                      />
                    </div>
                  ) : null}
                </span>
                <span>
                  <h5>IMO</h5>
                  <input
                    className="k-textbox"
                    name="vessel_imo_no"
                    value={this.state.vessel_imo_no}
                    onChange={this.onDialogInputChange}
                    placeholder="Search with IMO number"
                    disabled={this.state.noneVesselTypeFlg || false}
                  />
                  {!this.state.noneVesselTypeFlg ? (
                    <IconButton
                      type="submit"
                      onClick={(e) => this.vesselSearch(e, this.props, "imo")}
                    >
                      <Search />
                    </IconButton>
                  ) : null}

                  <h4 style={{ margin: "0px" }}>
                    {this.state.imoPlaceHolderTxt}
                  </h4>
                </span>
                {!this.state.noneVesselTypeFlg ? (
                  <span>
                    <h5>Vessel Name</h5>
                    <input
                      className="k-textbox"
                      name="vessel_name"
                      // style={{ width: "650px" }}
                      value={
                        this.state.gridData.data.filter(
                          (temp) => temp.inEdit === true
                        ).vessel_name
                      }
                      onChange={this.onDialogInputChange}
                      placeholder="Search with Vessel Name"
                    />
                    <IconButton
                      type="submit"
                      onClick={(e) => this.vesselSearch(e, this.props, "name")}
                    >
                      <Search />
                    </IconButton>
                  </span>
                ) : null}

                {this.state.noneVesselTypeFlg &&
                this.state.valueClientName &&
                this.state.noneVesselTypeValue.text ? (
                  <span>
                    <h5>Vessel Name</h5>
                    <input
                      disabled={true}
                      className="k-textbox"
                      name="vessel_name"
                      style={{ width: "650px" }}
                      value={
                        this.state.valueClientName +
                        " - " +
                        this.state.noneVesselTypeValue.text
                      }
                    />
                  </span>
                ) : null}

                <br />
                {this.state.noneVesselTypeFlg &&
                (this.state.postClientID ||
                  this.state.noneVesselTypeValue ||
                  this.state.valueClientName ||
                  this.state.noneVesselTypeValue.text) ? (
                  <div style={{ marginTop: "10px" }}>
                    <div>
                      <h3>Category</h3>
                      <DropDownList
                        data={this.state.vesselCategories}
                        textField="text"
                        dataItemKey="id"
                        onChange={(event) =>
                          this.onChangeGeneric(event, "vesselTypeCategory")
                        }
                        style={{ width: "250px" }}
                        value={this.state.vesselTypeCategory}
                      />
                    </div>
                    <br />
                    <div>
                      <button
                        type="button"
                        className="k-button k-primary"
                        onClick={(e) => this.saveNoneVesselType(e)}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                ) : null}
              </fieldset>
              {
                <div>
                  <h3 style={{ color: "#ff9d00" }}>
                    {this.state.showZeroResultsMessage
                      ? "No  results from your input. \n Please try with different inputs or use the continue button in order to provide data manually."
                      : ""}
                  </h3>
                  <h3>
                    {this.state.showZeroResultsMessage ? (
                      <button
                        type="button"
                        className="k-button k-primary"
                        onClick={(e) => this.openCreateVesselManually()}
                      >
                        Continue
                      </button>
                    ) : null}
                  </h3>
                </div>
              }
              {!_.isEmpty(finalFleetmonData) && finalFleetmonData.length > 0 ? (
                <div>
                  <fieldset style={{ borderColor: "rgba(13, 88, 105, 1)" }}>
                    <legend>Results</legend>
                    <Grid
                      data={finalFleetmonData}
                      heigh={"100%"}
                      resizable
                      selectedField="selected"
                      onSelectionChange={this.selectionChange}
                      filterable={false}
                      sortable={false}
                      expandField="expanded"
                      pageable={false}
                      ref={(grid) => {
                        this._grid = grid;
                      }}
                    >
                      {[...this.renderGridColumnsForFleetmon()]}
                    </Grid>
                    <br />
                    <br />
                    {!_.isEmpty(finalFleetmonData) &&
                    finalFleetmonData.length > 0 ? (
                      <div>
                        <button
                          type="button"
                          className="k-button k-primary"
                          onClick={(e) =>
                            this.saveFleetmonOption(
                              e,
                              gridData,
                              finalFleetmonData,
                              selectedItem
                            )
                          }
                        >
                          Use option
                        </button>
                      </div>
                    ) : null}
                  </fieldset>
                </div>
              ) : null}
            </div>
          </Window>
        )}

        {this.state.editRecordsFleetmonWindow && (
          <Window
            title={"Edit vessel process"}
            onClose={() => {
              // let gridData = {...this.state.gridData};
              // let data = gridData.data;
              // this.removeItem(data, data[0]);

              this.setState({
                ...this.state,
                // gridData: gridData,
                editRecordsFleetmonWindow: false,
                hideColumns: false,
                columns: this.state.initialColumns,
                finalFleetmonData: [],
                selectedRowIndex: 0,
                selectedItem: null,
              });
            }}
            width={
              !_.isEmpty(finalFleetmonData) && finalFleetmonData.length > 0
                ? 1300
                : 650
            }
            height={
              !_.isEmpty(finalFleetmonData) && finalFleetmonData.length > 0
                ? 550
                : this.state.showZeroResultsMessage
                ? 200
                : 450
            }
          >
            <div>
              <h3 style={{ color: "#ff9d00" }}>
                {this.state.showZeroResultsMessage
                  ? "No  results from your input. \n Please try with different inputs."
                  : ""}
              </h3>
              <div>
                {!_.isEmpty(finalFleetmonData) &&
                finalFleetmonData.length > 0 ? (
                  <div>
                    <button
                      type="button"
                      className="k-button k-primary"
                      onClick={(e) =>
                        this.saveFleetmonOptionAndUpateRecord(
                          e,
                          gridData,
                          finalFleetmonData,
                          selectedItem
                        )
                      }
                    >
                      Use option and save
                    </button>
                  </div>
                ) : null}
              </div>
              <div>
                {!_.isEmpty(finalFleetmonData) &&
                finalFleetmonData.length > 0 ? (
                  <div>
                    <div>
                      <fieldset style={{ borderColor: "rgba(13, 88, 105, 1)" }}>
                        <legend>Results</legend>

                        <Grid
                          data={finalFleetmonData}
                          heigh={"100%"}
                          resizable
                          selectedField="selected"
                          onSelectionChange={this.selectionChange}
                          filterable={false}
                          sortable={false}
                          expandField="expanded"
                          pageable={false}
                          ref={(grid) => {
                            this._grid = grid;
                          }}
                        >
                          {[...this.renderGridColumnsForFleetmon()]}
                        </Grid>
                      </fieldset>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </Window>
        )}

        {this.state.deleteAllClients && (
          <Window
            title={`Are you sure you want to remove all clients from vessel: ${
              dataItem && dataItem.vessel_name
            }?`}
            onClose={this.toggleWindowRemoveClients}
            width={1000}
            height={200}
            initialTop={80}
          >
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <button
                  style={{ justifyContent: "flex-end" }}
                  type="button"
                  className="k-button k-primary"
                  onClick={(e) => this.removeAllClients(e, dataItem)}
                >
                  Remove
                </button>
                <button
                  style={{ justifyContent: "flex-start" }}
                  type="button"
                  className="k-button k-primary"
                  onClick={(e) => this.toggleWindowRemoveClients(e, dataItem)}
                >
                  Close
                </button>
              </div>
            </div>
          </Window>
        )}

        {this.state.openChangeVesselWindow && (
          <Window
            title={`Change vessel name - Existing vessel ${this.state.openDataItem.vessel_name}`}
            onClose={this.closeChangeVesselWindow}
            width={950}
            height={700}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              {this.state.windowError ? (
                <Error style={{ color: "red" }}>
                  <h3>{this.state.windowErrorMessage || ""}</h3>
                </Error>
              ) : null}
              <span>
                <h3>{`Active Orders : ${this.state.activeOrders}`}</h3>
              </span>

              <span>
                <h3>Vessel Name</h3>
                <Input
                  style={{ width: "400px" }}
                  name="vessel_name"
                  value={
                    this.state.gridData.data.filter(
                      (temp) => temp.inEdit === true
                    ).vessel_name
                  }
                  onChange={this.onDialogInputChange}
                />
              </span>
              <span>
                <h5>IMO</h5>
                <input
                  className="k-textbox"
                  name="vessel_imo_no"
                  value={this.state.openDataItem.vessel_imo_no}
                  disabled={true}
                  onChange={this.onDialogInputChange}
                />
              </span>
              <br />
              <span>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.moveToNewClientFlg}
                      onChange={this.handleSwitch("moveToNewClientFlg")}
                      value="moveToNewClientFlg"
                      color="primary"
                    />
                  }
                  label="Assign new vessel to new client(s) -> (vessel to client connection)"
                />
              </span>
              {this.state.moveToNewClientFlg === true ? (
                <span>
                  <h3>Clients</h3>
                  <MultiSelect
                    data={this.state.clientsAvailable || []}
                    value={this.state.vesselToClientSelected}
                    onChange={this.handleClientsChange}
                    placeholder="Select at least one client from the dropdown."
                  />
                </span>
              ) : null}
              <br />
              <span>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.activeOrdersFlg}
                      onChange={this.handleChange2("activeOrdersFlg")}
                      value="activeOrdersFlg"
                      color="primary"
                      disabled={this.state.moveActiveOrdersSwitchFlag}
                    />
                  }
                  label="Move active orders"
                />
              </span>
              {this.state.activeOrdersFlg ||
              (this.state.activeOrdersFlg &&
                this.state.moveToNewClientFlg &&
                this.state.vesselToClientSelected.length > 0) ? (
                <span>
                  <h3>Move orders to client</h3>
                  <DropDownList
                    data={this.state.existingClientsAvailable}
                    onChange={this.onChange}
                    onFilterChange={this.onFilterChange}
                    filterable={false}
                    style={{ width: "310px" }}
                  />
                </span>
              ) : null}
            </div>
            <br />
            <span>
              <h3>Notify Operator</h3>
              <DropDownList
                data={this.state.existingOperatorsAvailable.sort((a, b) =>
                  a.text > b.text ? 1 : -1
                )}
                onChange={this.onChangeNotifyEmail}
                onFilterChange={this.onFilterChange}
                filterable={true}
                style={{ width: "310px" }}
              />
            </span>
            <div style={{ marginTop: "10px" }}>
              <button
                type="button"
                className="k-button k-primary"
                onClick={(e) => this.changeVesselName(e)}
                disabled={
                  this.state.isDisabled || this.state.notifyEmailOperator === ""
                }
              >
                Save
              </button>
            </div>
          </Window>
        )}

        {this.state.createVesselOfficeWindow && (
          <Window
            title={`Create vessel (office)`}
            onClose={this.closeCreateVesselOffice}
            width={600}
            height={545}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              {this.state.windowError ? (
                <Error style={{ color: "red" }}>
                  <h3>{this.state.windowErrorMessage || ""}</h3>
                </Error>
              ) : null}
              <span>
                <h3>Vessel Name</h3>
                <Input
                  style={{ width: "400px" }}
                  name="vessel_name"
                  value={this.state.vessel_name}
                  onChange={this.onDialogInputChange}
                  label={"Minimum character length : 3"}
                />
              </span>
              <span>
                <h5>IMO</h5>
                <input
                  className="k-textbox"
                  name="vessel_imo_no"
                  value={"0000000"}
                  disabled={true}
                />
              </span>
              <span>
                <h5>Type</h5>
                <input
                  className="k-textbox"
                  name="vessel_type"
                  value={"Office"}
                  disabled={true}
                />
              </span>
              {this.state.createVesselOfficeWindow && (
                <span>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.isGovernment}
                        onChange={(event) => {
                          this.setState({
                            ...this.state,
                            isGovernment: !this.state.isGovernment,
                          });
                        }}
                        value="isGovernment"
                        color="primary"
                      />
                    }
                    label="Is Government"
                  />
                </span>
              )}
              <span>
                <h3>Category</h3>
                <DropDownList
                  data={this.state.vesselCategories}
                  textField="text"
                  dataItemKey="id"
                  onChange={(event) =>
                    this.onChangeGeneric(event, "vesselTypeCategory")
                  }
                  // onChange={this.onChangeGeneric}
                  style={{ width: "250px" }}
                  value={this.state.vesselTypeCategory}
                />
              </span>
              <br />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <button
                  style={{ justifyContent: "flex-end" }}
                  className="k-button k-primary"
                  onClick={(e) => this.saveVesselOffice(e)}
                  disabled={this.state.isDisabled}
                >
                  Save
                </button>
              </div>
            </div>
          </Window>
        )}

        {this.state.createVesselManuallyWindow && (
          <Window
            title={`Create vessel manually`}
            onClose={this.closeCreateVesselManually}
            width={800}
            height={700}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              {this.state.windowError ? (
                <Error style={{ color: "red" }}>
                  <h3>{this.state.windowErrorMessage || ""}</h3>
                </Error>
              ) : null}
              <span>
                <h3>Vessel Name</h3>
                <Input
                  style={{ width: "400px" }}
                  name="vessel_name"
                  value={this.state.vessel_name}
                  onChange={this.onDialogInputChange}
                  label={"Minimum character length : 3"}
                />
              </span>
              <span>
                <h5>IMO</h5>
                <input
                  className="k-textbox"
                  name="vessel_imo_no"
                  value={this.state.vessel_imo_no}
                  onChange={this.onDialogInputChange}
                  disabled={this.state.is_yatchChecked ? true : false}
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.is_yatchChecked}
                      onChange={this.handleSwitch("is_yatchChecked")}
                      value="is_yatchChecked"
                      color="primary"
                    />
                  }
                  label="No IMO"
                />
              </span>
              <span>
                <h5>Type</h5>
                <input
                  className="k-textbox"
                  name="vessel_type"
                  value={
                    this.state.is_yatchChecked
                      ? "NO_IMO"
                      : this.state.vessel_type
                  }
                  onChange={this.onDialogInputChange}
                  disabled={this.state.is_yatchChecked ? true : false}
                />
              </span>
              <span>
                <h5>Flag ISO</h5>
                <DropdownFilterCell
                  {...this.props}
                  filterable={false}
                  name={this.state.vessel_flag_iso}
                  width="150px"
                  onChange={(e) =>
                    this.handleDropDownChange(e, "vessel_flag_iso")
                  }
                  data={(this.props.countryCodes || [])
                    .map((x) => x.alpha_2)
                    .sort((a, b) => (a > b ? 1 : -1))}
                />
              </span>
              <span>
                <h3>Category</h3>
                <DropDownList
                  data={this.state.vesselCategories}
                  textField="text"
                  dataItemKey="id"
                  onChange={(event) =>
                    this.onChangeGeneric(event, "vesselTypeCategory")
                  }
                  // onChange={this.onChangeGeneric}
                  style={{ width: "250px" }}
                  value={this.state.vesselTypeCategory}
                />
              </span>
              {this.state.createVesselManuallyWindow && (
                <span>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.isGovernment}
                        onChange={(event) => {
                          this.setState({
                            ...this.state,
                            isGovernment: !this.state.isGovernment,
                          });
                        }}
                        value="isGovernment"
                        color="primary"
                      />
                    }
                    label="Is Government"
                  />
                </span>
              )}
              <br />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <button
                  style={{ justifyContent: "flex-end" }}
                  className="k-button k-primary"
                  onClick={(e) => this.saveVesselManually(e)}
                >
                  Save
                </button>
              </div>
            </div>
          </Window>
        )}
      </div>
    );
  }
}

export default Vessels;
