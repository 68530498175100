import { Tab, Tabs } from "@material-ui/core";
import TabPanel from "../TabPanel/TabPanel";
import React from "react";

import "./PrimaryTabs.scss";

export default function PrimaryTabs(props) {
  return (
    <>
      <div className="tabs-wrap">
        <Tabs
          value={props.tabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={(event, value) => {
            props.onChange(value);
          }}
          variant="scrollable"
          scrollButtons="auto"
        >
          {props.tabs && props.tabs.length > 0 && props.tabs.map((tab, index) => {
            return <Tab key={index} label={tab.label} />;
          })}
        </Tabs>
      </div>
      {props.tabs && props.tabs.length > 0 && props.tabs.map((tab, index) => {
        return (
          <TabPanel key={index} index={index} value={props.tabValue}>
            {tab.component}
          </TabPanel>
        );
      })}
    </>
  );
}
