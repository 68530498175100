export const columnsSchema = () => [
  {
    field: "id",
    title: "ID",
    visible: true,
    minWidth: 140,
    sortable: true,
    filterable: true,
    locked: "locked",
  },
  {
    field: "name",
    title: "Name",
    visible: true,
    // minWidth: 340,
    sortable: true,
    filterable: true,
    locked: "locked",
  },
  {
    field: "description",
    title: "Description",
    visible: true,
    // minWidth: 320,
    sortable: true,
    filterable: false,
    locked: "locked",
  },
  {
    field: "created",
    title: "Created",
    visible: true,
    // minWidth: 250,
    sortable: true,
    filterable: true,
    locked: "locked",
    editable: "false",
  },
  {
    field: "modified",
    title: "Modified",
    visible: true,
    // minWidth: 250,
    sortable: true,
    filterable: true,
    locked: "locked",
    editable: "false",
  },
];
