import {connect} from 'react-redux';
import compose from 'ramda/src/compose';
import WebsocketUsers from "./View/index";

const mapStateToProps = (state) => {
  return {
    ...state
  }
}

export default compose(
  connect(mapStateToProps, null))
(WebsocketUsers)

