import React, {Fragment, Component} from 'react';
import {serverApi} from "../../../../networking/config";
import _ from 'lodash';
import {Checkbox, Input} from "@progress/kendo-react-inputs";
import {goBack} from "../../../../lib/reactRouterHelpers/history";
import BlockUi from "react-block-ui";
import Snackbar from "../../../../components/Snackbar/Snackbar";

class EditClientUserClients extends Component {

  constructor(props) {
    super(props)

    this.state = {
      finalUserClients: [],
      visibleClients: [],
      inputValue: "",
      switchChecked: false,
      blocking: false
    }
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      blocking: true
    })

    const id = this.props.match.params.id;
    let options = {
      token: window.localStorage.getItem('access_token')
    };

    let initialUserClients = []
    let tempClients = []
    const snUserRequest = serverApi('GET', `snUsers/${id}?filter[include][0][userToClients][masterEntity]`, '', '', options)

    snUserRequest
      .then(response1 => {
        this.setState({
          ...this.state,
          username: response1.data.username || ""
        })
        let userToClientsArr = response1?.data?.userToClients || []
        _.forEach(userToClientsArr, clnt => {
          let clientName = clnt?.masterEntity?.relation_name || 'N/A'
          let clientID = clnt.clientID
          let deleteID = clnt.id
          initialUserClients.push({
            name: `${clientName}`,
            checked: true,
            clientID: clientID,
            deleteID: deleteID
          })
        })

        const getAllClients = serverApi('GET', `masterEntities?filter={"where":{"client_flag":true}}`, '', '', options, '')
        getAllClients
          .then(response2 => {
            let allClientsArr = response2?.data || []
            allClientsArr.forEach(temp => {
              tempClients.push({
                name: `${temp.relation_name}`,
                clientID: temp.id,
                checked: false
              })
            })

            initialUserClients.forEach(item => {
              let client = tempClients.find(x => x.name === item.name)
              if (client) {
                client.checked = true
              }
            })

            //add to finalUserDepartments the deleteID property needed
            _.forEach(initialUserClients, temp1 => {
              _.map(tempClients, temp2 => {
                if (temp1.clientID === temp2.clientID) {
                  temp2.deleteID = temp1.deleteID
                }
              })
            })

            this.setState({
              visibleClients: initialUserClients.sort((a, b) => (a.name > b.name) ? 1 : -1),
              finalUserClients: tempClients.sort((a, b) => (a.name > b.name) ? 1 : -1),
              initialFinalUserClients: tempClients.sort((a, b) => (a.name > b.name) ? 1 : -1),
              blocking: false
            })


          })
          .catch(e => {
            this.setState({
              ...this.state,
              blocking: false,
              openErrorModal: true,
              errorModalContent: e?.response?.data?.error?.message || "Please try again"
            })
          })
      })
      .catch(e => {
        this.setState({
          ...this.state,
          blocking: false,
          openErrorModal: true,
          errorModalContent: e?.response?.data?.error?.message || "Please try again"
        })
      })
  }

  postOrDelete = async (postOrDeleteClient, payload, finalObj, key) => {
    let options = {
      token: window.localStorage.getItem('access_token')
    };
    const id = this.props.match.params.id;

    if (postOrDeleteClient === 'POST') {
      this.setState({blocking: true})
      const requestPOST = serverApi('POST', `userToClients`, '', payload, options, '')
      requestPOST
        .then(postResponse => {
          console.log(postResponse)
        })
        .catch(e => {
          this.setState({
            ...this.state,
            blocking: false,
            openErrorModal: true,
            errorModalContent: e.response.data.error.message || "Unable to attach the department to user."
          }, () => {
            setTimeout(() => {
              this.handleErrorClose()
            }, 4000)
          })
        })
    } else {
      const snUserRequest = serverApi('GET', `snUsers/${id}?filter[include][0][userToClients][masterEntity]`, '', '', options)
      snUserRequest
        .then(response1 => {
          let userToClientsArr = response1?.data?.userToClients || []
          if (userToClientsArr.length === 1) {
            this.setState({
              ...this.state,
              openErrorModal: true,
              errorModalContent: "You can not remove Client. At least one client must be selected.\n" +
                "Please, add a new client first.",
              clientAttachedToAnotherGroup: key,
              blocking: false
            }, () => {
              let final = this.state.finalUserClients.filter(t => t.id !== key)
              this.setState({
                ...this.state,
                finalUserClients: final
              }, () => {
                setTimeout(() => {
                  this.renderClientsList(final, this.state.visibleClients, true)
                }, 4500)
              })
            })
          } else {
            this.setState({blocking: true})
            const removeClient = serverApi('DELETE', `userToClients/${finalObj.deleteID}`, '', '', options, '')
            removeClient
              .then(deleteResponse => {
                console.log(deleteResponse)
              })
              .catch(e => {
                this.setState({
                  ...this.state,
                  blocking: false,
                  openErrorModal: true,
                  errorModalContent: e.response.data.error.message || "Unable to remove the department to user."
                })
              })
          }
        })
        .catch(error => {
          this.setState({
            ...this.state,
            blocking: false,
            openErrorModal: true,
            errorModalContent: "Unable to remove client.Please try again."
          }, () => {
            setTimeout(() => {
              this.handleErrorClose()
            }, 4000)
          })
        })
    }

  }

  handleChange = (event, name, key) => {
    let options = {
      token: window.localStorage.getItem('access_token')
    };
    let userID = this.props.match.params.id
    let finalUserClients = [...this.state.finalUserClients]
    let finalObj = finalUserClients.find(test => test.clientID === key)
    finalObj.checked = !finalObj.checked

    this.setState({
      ...this.state,
      finalUserClients: finalUserClients
    }, async () => {
      let initialUserClients = []
      let postOrDeleteClient = event.value === false ? 'DELETE' : 'POST'
      let payload = {
        userID: parseInt(userID),
        clientID: finalObj.clientID
      }

      await this.postOrDelete(postOrDeleteClient, payload, finalObj, key)

      let tempClients = []
      const snUserRequest = serverApi('GET', `snUsers/${userID}?filter[include][0][userToClients][masterEntity]`, '', '', options)
      snUserRequest
        .then(response1 => {
          this.setState({
            ...this.state,
            username: response1.data.username || ""
          })
          let userToClientsArr = response1?.data?.userToClients || []
          _.forEach(userToClientsArr, clnt => {
            let clientName = clnt?.masterEntity?.relation_name || 'N/A'
            let clientID = clnt.clientID
            let deleteID = clnt.id
            initialUserClients.push({
              name: `${clientName}`,
              checked: true,
              clientID: clientID,
              deleteID: deleteID
            })
          })

          const getAllClients = serverApi('GET', `masterEntities?filter={"where":{"client_flag":true}}`, '', '', options, '')
          getAllClients
            .then(response2 => {
              let allClientsArr = response2?.data || []
              allClientsArr.forEach(temp => {
                tempClients.push({
                  name: `${temp.relation_name}`,
                  clientID: temp.id,
                  checked: false
                })
              })

              initialUserClients.forEach(item => {
                let client = tempClients.find(x => x.name === item.name)
                if (client) {
                  client.checked = true
                }
              })

              //add to finalUserDepartments the deleteID property needed
              _.forEach(initialUserClients, temp1 => {
                _.map(tempClients, temp2 => {
                  if (temp1.clientID === temp2.clientID) {
                    temp2.deleteID = temp1.deleteID
                  }
                })
              })

              this.setState({
                visibleClients: initialUserClients.sort((a, b) => (a.name > b.name) ? 1 : -1),
                finalUserClients: tempClients.sort((a, b) => (a.name > b.name) ? 1 : -1),
                // finalUserDepartments2: deptWithDomainArr.sort((a, b) => (a.name > b.name) ? 1 : -1),
                initialFinalUserClients: tempClients.sort((a, b) => (a.name > b.name) ? 1 : -1),
                // initialFinalUserClients2: deptWithDomainArr.sort((a, b) => (a.name > b.name) ? 1 : -1),
                blocking: false
              })
            })
            .catch(e => {
              this.setState({
                ...this.state,
                blocking: false,
                openErrorModal: true,
                errorModalContent: e?.response?.data?.error?.message || "Please try again"
              })
            })
        })
        .catch(e => {
          this.setState({
            ...this.state,
            blocking: false,
            openErrorModal: true,
            errorModalContent: e?.response?.data?.error?.message || "Please try again"
          })
        })
    })
  }

  handleSearch = (e) => {
    let initialFinalUserClients = this.state.initialFinalUserClients

    if (e.value === "") {
      this.setState({
        inputValue: e.value,
        finalUserClients: initialFinalUserClients

      })
    } else {
      let searchArr = []
      initialFinalUserClients.forEach(temp => {
        if (temp.name.toUpperCase().includes(e.value.toUpperCase())) {
          searchArr.push(temp)
        }
      });

      this.setState({
        inputValue: e.value,
        finalUserClients: searchArr

      })
    }
  }

  goBack = () => {
    goBack()
  }

  renderClientsList = (finalUserDepartments = [], visibleDepartments = [], doRefresh) => {
    if (doRefresh === true) {
      window.location.reload()
    } else {
      return (
        finalUserDepartments && finalUserDepartments.map(item => (
            <ul style={{marginBottom: '-5px'}} key={item.id}>
              <Checkbox
                name={item.name}
                key={item.name}
                value={item.checked}
                onChange={(e) => this.handleChange(e, item.name, item.clientID)}
                label={item.name}
              />
            </ul>
          )
        )
      )
    }

  };

  handleErrorClose = () => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      openErrorModalContent: ''
    })
  };

  render() {

    const {finalUserClients, visibleClients, inputValue, username} = this.state

    return (
      <Fragment>
        <BlockUi tag="div" blocking={this.state.blocking} message="Loading, please wait. . ."
                 renderChildren={true}>
          <div style={{textAling: 'left', display: 'flex', flexDirection: 'row'}}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <div style={{
                display: 'flex-end',
                marginLeft: '35px',
                marginTop: '20px',
                marginBottom: "40px"
              }}>
                <button className="k-button" onClick={e => this.goBack()}>Back</button>
              </div>
              {
                this.state.blocking
                  ? ""
                  : (
                    <div style={{display: 'flex', marginLeft: '35px', marginTop: '20px'}}>
                      <div className="col-md-3">
                        <span style={{text: 'bold', fontSize: "32px"}}>Username: {username}</span>
                        <br/>
                      </div>
                    </div>
                  )
              }
              <div style={{display: 'flex', marginLeft: '35px', marginTop: '5px'}}>
                <Input
                  name="clients"
                  value={inputValue}
                  style={{marginBottom: '20px'}}
                  label="Search clients"
                  onChange={e => this.handleSearch(e)}
                  minLength={2}
                />
              </div>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                {this.renderClientsList(finalUserClients, visibleClients)}
              </div>
              <br/>
            </div>
          </div>

          <Snackbar
            place="tc"
            style={{
              width: '90%',
              overflow: 'hidden'
            }}
            color={'warning'}
            message={this.state.errorModalContent}
            open={this.state.openErrorModal}
            closeNotification={() => this.handleErrorClose(this.state.errorModalContent)}
            close
          />

        </BlockUi>
      </Fragment>

    );

  }

}

export default EditClientUserClients
