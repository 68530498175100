import { connect } from "react-redux";
import compose from "ramda/src/compose";
import Assets from "./View";

const mapStateToProps = state => {
  return {
    user: state.MainLayoutReducer.user,
    hasBackofficeRead: state.MainLayoutReducer.user.hasBackofficeRead
  };
};

export default compose(connect(mapStateToProps, null))(Assets);
