import React from "react";
import {
  Grid,
  GridColumn as Column,
  GridDetailRow,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { columnsSchema } from "./schema";
import _ from "lodash";
import { Window } from "@progress/kendo-react-dialogs";
import { filterBy } from "@progress/kendo-data-query";
import { Error } from "@progress/kendo-react-labels";
import { IconButton, Tooltip } from "@material-ui/core";
import { MyCommandCell } from "../Clients/View/components/CellHOC";
import GridContainer from "../../components/Grid/GridContainer";
import { serverApi } from "../../networking/config";
import { DropDownCell } from "../CRM/View/dropDownCell";
import { Input } from "@progress/kendo-react-inputs";
import { verifyEmail } from "lib/general/utils.js";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import DropdownFilterCell from "../common/dropdownFilterCell";
import { AbstractDelete } from "../../networking/apiCalls";

class Contacts extends GridDetailRow {
  editField = "inEdit";
  CommandCell;
  _export;

  constructor(props) {
    super(props);

    this.state = {
      selectedClients: [],
      clientsAvailable: [],
      visibleWindow: false,
      disableCreateContact: false,
      isDropOpened: false,
      columns: (columnsSchema && columnsSchema()) || [],
      initialGridColumns: (columnsSchema && columnsSchema()) || [],
      gridData: {
        data: [],
        total: 10,
      },
      initialGridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 10,
        skip: 0,
      },
      openErrorModal: false,
      errorModalContent: "",
      delay: 400,
      loading: false,
      contacts: [],
      windowError: false,
      windowErrorMessage: "",
      disableEditButton: true,

      //create properties
      contact_name: "",
      contact_country: "",
      contact_email: "",
      contact_type: "",
      contact_city: "",
      contact_state_code: "",
      contact_suburb: "",
      contact_phone: "",
      contact_postal_code: "",
      wh_notification_flg: false,
      notify_stock_email: false,
      // notify_stock_ws: false,
      supplier_invoice_flg: false,
      wr_pdf_flg: false,
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.deleteGroup,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      disable: this.disable,
      editField: this.editField,
      hasDisable: false,
      disableEditButton: false,
      showSecondAction: true,
      showThirdAction: true,
      showFirstAction: true,
    });
  }

  async componentDidMount() {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let filter1 = {
      filter: {
        where: {
          vesselID: this.props.dataItem && this.props.dataItem.id,
        },
        include: ["masterEntity"],
      },
    };

    const existingVesselToClients = await serverApi(
      "GET",
      `vesselToClients`,
      filter1,
      "",
      options
    );

    let clientsArr = existingVesselToClients.data
      .map((temp) => ({
        relation_name: temp.masterEntity.relation_name,
        clientID: temp.masterEntity.id,
      }))
      .sort((a, b) => (a.relation_name > b.relation_name ? 1 : -1));

    let filter2 = {
      filter: {
        where: {
          and: [{ lov_type: "CONTACT_TYPE" }, { active_flg: true }],
        },
      },
    };

    let lov_contact_types = await serverApi(
      "GET",
      `lovs`,
      filter2,
      "",
      options
    );

    lov_contact_types.data = (lov_contact_types.data || []).map((ct) => ({
      ...ct,
      text: ct.lov_value,
    }));

    this.setState({
      ...this.state,
      countries: this.props.countries,
      initialCountries: this.props.countries,
      clientsAvailable: clientsArr,
    });

    let responseData,
      getContacts = null;

    if (this.props.view === "Vessel") {
      let filter1 = {
        filter: {
          where: {
            vesselID: this.props.dataItem && this.props.dataItem.id,
          },
          include: ["masterEntity"],
        },
      };

      responseData = await serverApi(
        "GET",
        `masterEntityContacts`,
        filter1,
        "",
        options
      );
      getContacts = await serverApi(
        "GET",
        `masterEntityContacts`,
        "",
        "",
        options
      );
    } else if (this.props.view === "Client") {
      let filter1 = {
        filter: {
          where: {
            relationID: this.props.dataItem && this.props.dataItem.id,
            vesselID: null,
          },
          include: ["masterEntity"],
        },
      };
      responseData = await serverApi(
        "GET",
        `masterEntityContacts`,
        filter1,
        "",
        options
      );
      getContacts = await serverApi(
        "GET",
        `masterEntityContacts`,
        "",
        "",
        options
      );
    }

    let idKey = null;
    const { view } = this.props;
    if (view === "Vessel") {
      idKey = "vesselID";
    } else if (view === "Client") {
      idKey = "clientID";
    }
    let idValue = this.props.dataItem.id;

    if (responseData && responseData.data.length === 1) {
      let finalData = [];
      _.forEach(responseData.data, (x) => {
        finalData.push({
          ...x,
          clientName: x.masterEntity && x.masterEntity.relation_name,
        });
      });
      this.setState({
        ...this.state,
        [idKey]: idValue,
        gridData: {
          data: finalData,
          total: 1,
        },
        initialGridData: {
          data: finalData,
          total: 1,
        },
        contacts: getContacts.data
          .filter((x) => x.contact_name)
          .sort((a, b) => (a.contact_name > b.contact_name ? 1 : -1)),
        valueContactType: "",
      });
    } else if (responseData && responseData.data.length > 1) {
      let finalData = [];
      _.forEach(responseData.data, (x) => {
        finalData.push({
          ...x,
          clientName: x.masterEntity && x.masterEntity.relation_name,
        });
      });

      this.setState({
        ...this.state,
        [idKey]: idValue,
        gridData: {
          data: finalData.map((x) => ({
            ...x,
            clientName: x.masterEntity && x.masterEntity.relation_name,
          })),
          total: finalData.length,
        },
        initialGridData: {
          data: finalData.map((x) => ({
            ...x,
            clientName: x.masterEntity && x.masterEntity.relation_name,
          })),
          total: finalData.length,
        },
        contacts: getContacts.data
          .filter((x) => x.contact_name)
          .sort((a, b) => (a.contact_name > b.contact_name ? 1 : -1)),
      });
    } else {
      this.setState({
        ...this.state,
        [idKey]: idValue,
        gridData: {
          data: [],
          total: 0,
        },
        initialGridData: {
          data: [],
          total: 0,
        },
        contacts: getContacts.data || [],
      });
    }

    if (idKey === "vesselID")
      lov_contact_types.data = [
        { text: "AUTO_PRE_ALERT", id: 1 },
        { text: "AUTO_PRE_ALERT_TECHNICAL", id: 2 },
        { text: "AUTO_PRE_ALERT_NOT_TECHNICAL", id: 3 },
        { text: "AUTO_PRE_ALERT_IT", id: 4 },
        { text: "WAREHOUSE-RECEIPT", id: 5 },
        { text: "BUYER", id: 6 },
      ];

    this.setState({
      ...this.state,
      contactTypes: lov_contact_types.data,
      columns:
        idKey === "vesselID"
          ? this.state.columns
          : this.state.columns.filter((col) => col.field !== "clientName"),
    });
  }

  refreshAfterAction = async () => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let filter1 = {
      filter: {
        where: {
          vesselID: this.props.dataItem && this.props.dataItem.id,
        },
        include: ["masterEntity"],
      },
    };

    const existingVesselToClients = await serverApi(
      "GET",
      `vesselToClients`,
      filter1,
      "",
      options
    );

    let clientsArr = existingVesselToClients.data
      .map((temp) => ({
        relation_name: temp.masterEntity.relation_name,
        clientID: temp.masterEntity.id,
      }))
      .sort((a, b) => (a.relation_name > b.relation_name ? 1 : -1));

    let filter2 = {
      filter: {
        where: {
          lov_type: "CONTACT_TYPE",
        },
      },
    };

    let lov_contact_types = await serverApi(
      "GET",
      `lovs`,
      filter2,
      "",
      options
    );

    lov_contact_types.data = (lov_contact_types.data || []).map((ct) => ({
      ...ct,
      text: ct.lov_value,
    }));

    this.setState({
      ...this.state,
      countries: this.props.countries,
      initialCountries: this.props.countries,
      clientsAvailable: clientsArr,
    });

    let responseData,
      getContacts = null;

    if (this.props.view === "Vessel") {
      let filter1 = {
        filter: {
          where: {
            vesselID: this.props.dataItem && this.props.dataItem.id,
          },
          include: ["masterEntity"],
        },
      };

      responseData = await serverApi(
        "GET",
        `masterEntityContacts`,
        filter1,
        "",
        options
      );
      getContacts = await serverApi(
        "GET",
        `masterEntityContacts`,
        "",
        "",
        options
      );
    } else if (this.props.view === "Client") {
      let filter1 = {
        filter: {
          where: {
            relationID: this.props.dataItem && this.props.dataItem.id,
            vesselID: null,
          },
          include: ["masterEntity"],
        },
      };

      responseData = await serverApi(
        "GET",
        `masterEntityContacts`,
        filter1,
        "",
        options
      );
      getContacts = await serverApi(
        "GET",
        `masterEntityContacts`,
        "",
        "",
        options
      );
    }

    let idKey = null;
    const { view } = this.props;
    if (view === "Vessel") {
      idKey = "vesselID";
    } else if (view === "Client") {
      idKey = "clientID";
    }
    let idValue = this.props.dataItem.id;

    if (responseData && responseData.data.length === 1) {
      let finalData = [];
      _.forEach(responseData.data, (x) => {
        finalData.push({
          ...x,
          clientName: x.masterEntity && x.masterEntity.relation_name,
        });
      });
      this.setState({
        ...this.state,
        [idKey]: idValue,
        gridData: {
          data: finalData,
          total: 1,
        },
        initialGridData: {
          data: finalData,
          total: 1,
        },
        contacts: getContacts.data
          .filter((x) => x.contact_name)
          .sort((a, b) => (a.contact_name > b.contact_name ? 1 : -1)),
        valueContactType: "",
      });
    } else if (responseData && responseData.data.length > 1) {
      let finalData = [];
      _.forEach(responseData.data, (x) => {
        finalData.push({
          ...x,
          clientName: x.masterEntity && x.masterEntity.relation_name,
        });
      });

      this.setState({
        ...this.state,
        [idKey]: idValue,
        gridData: {
          data: finalData.map((x) => ({
            ...x,
            clientName: x.masterEntity && x.masterEntity.relation_name,
          })),
          total: finalData.length,
        },
        initialGridData: {
          data: finalData.map((x) => ({
            ...x,
            clientName: x.masterEntity && x.masterEntity.relation_name,
          })),
          total: finalData.length,
        },
        contacts: getContacts.data
          .filter((x) => x.contact_name)
          .sort((a, b) => (a.contact_name > b.contact_name ? 1 : -1)),
      });
    } else {
      this.setState({
        ...this.state,
        [idKey]: idValue,
        gridData: {
          data: [],
          total: 0,
        },
        initialGridData: {
          data: [],
          total: 0,
        },
        contacts: getContacts.data || [],
      });
    }

    if (idKey === "vesselID")
      lov_contact_types.data = [
        { text: "AUTO_PRE_ALERT", id: 1 },
        { text: "AUTO_PRE_ALERT_TECHNICAL", id: 2 },
        { text: "AUTO_PRE_ALERT_NOT_TECHNICAL", id: 3 },
        { text: "AUTO_PRE_ALERT_IT", id: 4 },
        { text: "WAREHOUSE-RECEIPT", id: 5 },
        { text: "BUYER", id: 6 },
      ];

    this.setState({
      ...this.state,
      contactTypes: lov_contact_types.data,
      columns:
        idKey === "vesselID"
          ? this.state.columns
          : this.state.columns.filter((col) => col.field !== "clientName"),
    });
  };

  renderGridColumns = (dataItem, changeTheCellComponent) => {
    let selectionCol = [];
    let normalCols = [];
    let finalCols = [];

    let idKey = null;
    const { view } = this.props;
    if (view === "Vessel") {
      idKey = "vesselID";
    } else if (view === "Client") {
      idKey = "relationID";
    }

    let contactTypeData;
    if (idKey === "vesselID") {
      contactTypeData = [
        { text: "AUTO_PRE_ALERT", value: "AUTO_PRE_ALERT" },
        { text: "BUYER", value: "BUYER" },
        {
          text: "WAREHOUSE-RECEIPT",
          value: "WAREHOUSE-RECEIPT",
        },
      ];
    } else if (idKey === "relationID") {
      contactTypeData = (this.state.contactTypes || []).map((x) => {
        return {
          text: x.lov_value,
          value: x.lov_value,
        };
      });
    }
    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            width="65px"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.field === "contact_type") {
          return (
            <Column
              sortable={temp.sortable}
              locked={temp.locked}
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              filterCell={(props) => {
                return (
                  <>
                    <DropdownFilterCell
                      {...props}
                      width="120px"
                      data={[
                        { text: "Yes", value: true },
                        { text: "No", value: false },
                      ].map((x) => x.text)}
                    />
                  </>
                );
              }}
              cell={(props) => {
                return (
                  <>
                    <DropDownCell
                      {...props}
                      width="250px"
                      filterable={false}
                      data={contactTypeData}
                    />
                  </>
                );
              }}
            />
          );
        } else if (temp.isDropdown === "yes") {
          return (
            <Column
              sortable={temp.sortable}
              locked={temp.locked}
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              filterCell={(props) => {
                return (
                  <>
                    <DropdownFilterCell
                      {...props}
                      width="120px"
                      data={[
                        { text: "Yes", value: true },
                        { text: "No", value: false },
                      ].map((x) => x.text)}
                    />
                  </>
                );
              }}
              cell={(props) => {
                let field = props.field;
                switch (field) {
                  case "is_active":
                  case "isUrgent":
                  case "wh_notification_flg":
                  case "notify_stock_email":
                  case "wr_pdf_flg":
                  case "supplier_invoice_flg":
                  case "packing_list_flg":
                    return (
                      <>
                        <DropDownCell
                          {...props}
                          width="100px"
                          filterable={false}
                          data={[
                            { text: "Yes", value: true },
                            { text: "No", value: false },
                          ].sort((a, b) => (a.text > b.text ? 1 : -1))}
                        />
                      </>
                    );

                  default:
                    break;
                }
              }}
            />
          );
        } else if (temp.field === "documentsDeliveryType") {
          return (
            <Column
              sortable={temp.sortable}
              locked={temp.locked}
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              filterCell={(props) => {
                return (
                  <>
                    <DropdownFilterCell
                      {...props}
                      width="180px"
                      data={[
                        { text: "Attachments", value: "Attachments" },
                        { text: "Links", value: "Links" },
                      ].map((x) => x.text)}
                    />
                  </>
                );
              }}
              cell={(props) => {
                let field = props.field;
                switch (field) {
                  case "documentsDeliveryType":
                    return (
                      <>
                        <DropDownCell
                          {...props}
                          width="180px"
                          filterable={false}
                          data={[
                            { text: "Attachments", value: "Attachments" },
                            { text: "Links", value: "Links" },
                          ].sort((a, b) => (a.text > b.text ? 1 : -1))}
                        />
                      </>
                    );

                  default:
                    break;
                }
              }}
            />
          );
        } else if (temp.field === "clientName") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              filterCell={(props) => {
                return (
                  <>
                    <DropdownFilterCell
                      {...props}
                      filterable={true}
                      width="190px"
                      data={(this.state.clientsAvailable || [])
                        .map((x) => x.relation_name)
                        .sort((a, b) => (a > b ? 1 : -1))}
                      hasMultipleValues={false}
                    />
                  </>
                );
              }}
              cell={(props) => {
                let data = (this.state.clientsAvailable || [])
                  .map((x) => ({
                    text: x.relation_name,
                    value: x.relation_name,
                  }))
                  .sort((a, b) => (a.text > b.text ? 1 : -1));
                return (
                  <>
                    <DropDownCell
                      {...props}
                      filterable={true}
                      width="240px"
                      data={data}
                    />
                  </>
                );
              }}
            />
          );
        } else {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={temp.field === "id" || temp.editable ? false : true}
            />
          );
        }
      });

    finalCols = [...selectionCol, ...normalCols];
    return finalCols;
  };

  onChange = (event) => {
    const { value } = event.target;

    if (value === "") {
      this.setState({
        openedContacts: false,
        valueContactType: value,
      });
    } else {
      this.setState(
        {
          openedContacts: false,
          valueContactType: value,
        },
        () => {
          this.onAutoCompleteChange(event, value);
        }
      );
    }
  };

  onAutoCompleteChange = (event, value) => {
    const { name } = event.target;

    let stateData = {};

    stateData =
      value.length < 3
        ? {
            data:
              this.state.contacts.length > 0
                ? this.state.contacts.map((temp) => temp.contact_name)
                : [],
            openedContacts: false,
          }
        : { data: this.filterData(value, name), openedContacts: true };

    let eventType = event.nativeEvent.type;

    let valueSelected =
      eventType === "click" ||
      (eventType === "keydown" && event.nativeEvent.keyCode === 13);

    if (valueSelected) {
      let postVesselID =
        this.state.contacts &&
        this.state.contacts.length === 1 &&
        this.state.contacts[0].id &&
        this.state.contacts &&
        this.state.contacts.length > 1 &&
        this.state.contacts.filter(
          (temp) => temp.vessel_name === this.state.valueContactType
        )[0].id;

      let vessel_imo_no =
        this.state.contacts &&
        this.state.contacts.length === 1 &&
        this.state.contacts[0].vessel_imo_no &&
        this.state.contacts &&
        this.state.contacts.length > 1 &&
        this.state.contacts.filter(
          (temp) => temp.vessel_name === this.state.valueContactType
        )[0].vessel_imo_no;

      let vessel_type =
        this.state.contacts &&
        this.state.contacts.length === 1 &&
        this.state.contacts[0].vessel_type &&
        this.state.contacts &&
        this.state.contacts.length > 1 &&
        this.state.contacts.filter(
          (temp) => temp.vessel_name === this.state.valueContactType
        )[0].vessel_type;

      this.setState({
        ...this.state,
        postVesselID: postVesselID || "",
        vessel_imo_no: vessel_imo_no || "",
        vessel_type: vessel_type || "",
      });
    }

    if (valueSelected && stateData.data.includes(value)) {
      stateData.openedContacts = false;
    }

    this.setState(
      {
        valueContactType: value,
        ...stateData,
      },
      () => this.fetchAutocompleteData(value)
    );
  };

  fetchAutocompleteData = (filterValue) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    const fetchDropdownData = serverApi(
      "GET",
      `masterEntityContacts`,
      {
        filter: {
          order: ["contact_type asc"],
        },
      },
      "",
      options
    );

    fetchDropdownData
      .then((response) => {
        this.setState({
          ...this.state,
          contacts: response.data,
        });
      })
      .catch((error) => {
        throw error;
      });
  };

  filterData = (value, name) => {
    const data = this.state.contacts.map((temp) => temp.vessel_name);

    const filter = {
      value: value,
      operator: "startswith",
      ignoreCase: true,
    };

    let results = filterBy(data, filter);
    return results;
  };

  enterEdit = (dataItem) => {
    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    let total = this.state.gridData.total;

    let hide_packing_list_flg = false;
    if (dataItem.contact_type !== "WAREHOUSE-RECEIPT") {
      hide_packing_list_flg = true;
    }

    this.setState({
      gridData: {
        data:
          this.state.gridData &&
          this.state.gridData.data.map((item) =>
            item.id === dataItem.id ? { ...item, inEdit: true } : item
          ),
        total: total,
      },
      columns: hide_packing_list_flg
        ? (columnsSchema && columnsSchema()).filter(
            (col) => col.field !== "packing_list_flg"
          ) || []
        : this.state.initialGridColumns,
    });
  };

  discard = (dataItem, hasError = false) => {
    let data = [...this.state.gridData.data];
    const total = this.state.gridData.total;

    if (hasError) {
      data[0].inEdit = true;
      this.setState({
        gridData: {
          data: data,
          total: total,
        },
      });
    } else {
      this.removeItem(data, dataItem);

      this.setState({
        gridData: {
          data: data,
          total: total,
        },
        visible: false,
      });
    }
  };

  cancel = (dataItem) => {
    const originalItem =
      this.state.initialGridData &&
      this.state.initialGridData.data.find((p) => p.id === dataItem.id);
    originalItem.inEdit = false;

    const data =
      this.state.gridData &&
      this.state.gridData.data.map((item) =>
        item.id === originalItem.id ? originalItem : item
      );

    const total = this.state.gridData.total;

    this.setState({
      ...this.state,
      gridData: {
        data: data,
        total: total,
      },
      openErrorModal: false,
      openErrorModalContent: "",
      columns: this.state.initialGridColumns,
    });
  };

  removeItem(data, item) {
    let index = data.findIndex(
      (p) => p === item || item.id || p.id === item.id
    );
    if (index >= 0) {
      data.splice(index, 1);
    }
  }

  disable = async (dataItem) => {
    try {
      const updatedItem = { ...dataItem, inEdit: undefined };
      let options = {
        token: window.localStorage.getItem("access_token"),
      };
      let editPayload = {
        is_active: false,
        wh_notification_flg: false,
        notify_stock_email: false,
        supplier_invoice_flg: false,
        wr_pdf_flg: false,
      };
      const editRequest = await serverApi(
        "PATCH",
        `masterEntityContacts/${updatedItem.id}`,
        {},
        editPayload,
        options
      );
      if (editRequest.status === 200) {
        let idKey = null;
        const { view } = this.props;
        if (view === "Vessel") {
          idKey = "vesselID";
        } else if (view === "Client") {
          idKey = "relationID";
        }
        let getClientContacts = await serverApi(
          "GET",
          `masterEntityContacts/?filter={"where":{"${idKey}":${this.props.dataItem.id}},"include":["masterEntity"]}`,
          {},
          {},
          options
        );
        this.setState({
          ...this.state,
          gridData: {
            data: getClientContacts?.data || [],
            total: getClientContacts?.data?.length,
          },
          visibleWindow: false,
        });
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent:
          e?.response?.data?.error?.message ||
          "Delete contact has failed.Please try again and if the problem persist contact the administrator.",
      });
    }
  };

  remove = async (dataItem) => {
    const data = [...this.state.gridData.data];

    let index = data.findIndex((p) => p === dataItem || p.id === dataItem.id);
    if (index >= 0) {
      data.splice(index, 1);
    }

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let url = "masterEntityContacts";

    try {
      let deleteItemRequest = await AbstractDelete(
        url,
        `${dataItem.id}`,
        options
      );

      if (deleteItemRequest.status === 200) {
        //clear the column filters if grid data is 0 or let them if grid data is >= 1
        if (data.length <= 0) {
          this.setState({
            ...this.state,
            gridData: {
              data: data,
              total: data.length,
            },
            initialGridData: {
              data: data,
              total: data.length,
            },
            dataState: {
              skip: 0,
              take: 10,
            },
          });
        } else {
          this.setState({
            ...this.state,
            gridData: {
              data: data,
              total: data.length,
            },
            initialGridData: {
              data: data,
              total: data.length,
            },
          });
        }
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent:
          e?.response?.data?.error?.message || "Please try again",
      });
    }
  };

  add = (dataItem) => {};

  addNew = () => {
    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    const newDataItem = { inEdit: true };

    this.setState({
      gridData: {
        data: [newDataItem, ...this.state.gridData.data],
        total: this.state.gridData.total,
      },
      visibleWindow: true,
    });
  };

  update = async (dataItem) => {
    const data = [...this.state.gridData.data];
    let updatedItem = { ...dataItem, inEdit: undefined };
    this.updateItem(data, updatedItem);
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let editPayload = {
      documentsDeliveryType: updatedItem.documentsDeliveryType,
      contact_type: updatedItem.contact_type,
      contact_address: updatedItem.contact_address,
      contact_city: updatedItem.contact_city,
      contact_phone: updatedItem.contact_phone,
      contact_postal_code: updatedItem.contact_postal_code,
      contact_name: updatedItem.contact_name,
      contact_country: updatedItem.contact_country,
      contact_email: updatedItem.contact_email,
      wh_notification_flg: updatedItem.wh_notification_flg,
      notify_stock_email: updatedItem.notify_stock_email,
      is_active: updatedItem.is_active,
      isUrgent: updatedItem.isUrgent,
      supplier_invoice_flg: updatedItem.supplier_invoice_flg,
      packing_list_flg: updatedItem.packing_list_flg,
      wr_pdf_flg: updatedItem.wr_pdf_flg,
      contact_state_code: updatedItem.contact_state_code,
      contact_suburb: updatedItem.contact_suburb,
      relationID:
        this.props.view === "Vessel"
          ? (this.state.clientsAvailable || []).filter(
              (x) => x.relation_name === dataItem.clientName
            ).length > 0
            ? (this.state.clientsAvailable || []).filter(
                (x) => x.relation_name === dataItem.clientName
              )[0]?.clientID
            : null
          : this.props.view === "Client"
          ? updatedItem.relationID
            ? updatedItem?.relationID
            : null
          : null,
    };

    if (editPayload.contact_type !== "WAREHOUSE-RECEIPT") {
      editPayload.wr_pdf_flg = false;
      editPayload.supplier_invoice_flg = false;
    }
    if (
      editPayload.contact_type === "" ||
      editPayload.contact_name === "" ||
      editPayload.contact_email === ""
    ) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent:
          "Update contact process has failed.Name, Type and Email are required to continue.",
      });
    } else {
      try {
        const existingItem = await serverApi(
          "GET",
          `masterEntityContacts/${updatedItem.id}`,
          {},
          {},
          options
        );

        const fieldsToUpdate = {};
        // eslint-disable-next-line no-unused-vars
        for (const key in editPayload) {
          if (
            JSON.stringify(existingItem.data[key]) !==
            JSON.stringify(editPayload[key])
          ) {
            fieldsToUpdate[key] = editPayload[key];
          }
        }
        if (Object.keys(fieldsToUpdate).length > 0) {
          const editRequest = await serverApi(
            "PATCH",
            `masterEntityContacts/${updatedItem.id}`,
            {},
            fieldsToUpdate,
            options
          );

          if (editRequest.status === 200) {
            this.setState({
              ...this.state,
              visibleWindow: false,
              openErrorModal: false,
              errorModalContent: "",
              columns: this.state.initialGridColumns,
            });
            await this.refreshAfterAction();
          }
        } else {
          this.setState(
            {
              ...this.state,
              openWarningModal: true,
              errorWarningContent:
                "Nothing to be saved.Change some valid fields first and then update the record.",
            },
            () => {
              setTimeout(() => {
                this.setState({
                  ...this.state,
                  openWarningModal: false,
                  errorWarningContent: "",
                });
              }, 3500);
            }
          );
        }
      } catch (e) {
        this.setState(
          {
            ...this.state,
            openErrorModal: true,
            errorModalContent:
              e?.response?.data?.error?.message ||
              "Update contact process has failed.Please check again the inputs.",
          },
          () => this.discard(dataItem, true)
        );
      }
    }
  };

  updateItem = (data, item) => {
    let index = data.findIndex(
      (p) => p === item || (item.id && p.id === item.id)
    );
    if (index >= 0) {
      data[index] = { ...item };
    }
  };

  itemChange = (event) => {
    const { field, value, dataItem } = event;

    if (
      field === "packing_list_flg" &&
      dataItem.contact_type !== "WAREHOUSE-RECEIPT"
    ) {
      this.setState({
        ...this.state,
        columns:
          (columnsSchema && columnsSchema()).filter(
            (col) => col.field !== "packing_list_flg"
          ) || [],
      });
      return;
    }

    if (field === "contact_type") return;

    // if (dataItem.contact_type === "WAREHOUSE-RECEIPT") {
    //   this.setState({
    //     ...this.state,
    //     columns: this.state.initialGridColumns,
    //   });
    // }

    const total = this.state.gridData.total;
    const data =
      this.state.gridData &&
      this.state.gridData.data.map((item) =>
        item.id === dataItem.id ? { ...item, [field]: value } : item
      );

    this.setState({
      gridData: {
        data: data,
        total: total,
      },
    });
  };

  refreshGridDataAfterCrud = async () => {};

  toggleWindow = (e) => {
    const data = this.state.initialGridData.data;
    this.setState({
      visibleWindow: !this.state.visibleWindow,
      gridData: {
        data: [...data],
        total: 10,
      },
      contacts: [],
      valueContactType: "",
      windowError: false,
      windowErrorMessage: "",
      wh_notification_flg: false,
      notify_stock_email: false,
      // notify_stock_ws: false,
      wr_pdf_flg: false,
      supplier_invoice_flg: false,
    });
  };

  listNoDataRender = (element) => {
    const noData = (
      <h4 style={{ fontSize: "1em" }}>
        <span className="k-icon k-i-warning" style={{ fontSize: "2.5em" }} />
        <br />
        <br />
        No contacts were found.
      </h4>
    );

    return React.cloneElement(element, { ...element.props }, noData);
  };

  saveNewContact = async (e) => {
    try {
      let options = {
        token: window.localStorage.getItem("access_token"),
      };

      let idKey = null;
      const { view } = this.props;
      if (view === "Vessel") {
        idKey = "vesselID";
      } else if (view === "Client") {
        idKey = "relationID";
      }

      let payload = {
        [idKey]: this.props.dataItem.id,
        contact_type: this.state.valueContactType.text,
        contact_country: this.state.contact_country,
        contact_name: this.state.contact_name,
        contact_email: this.state.contact_email,
        wh_notification_flg: this.state.wh_notification_flg,
        notify_stock_email: this.state.notify_stock_email,
        // notify_stock_ws: this.state.notify_stock_ws,
        supplier_invoice_flg: this.state.supplier_invoice_flg,
        packing_list_flg: this.state.packing_list_flg,
        wr_pdf_flg: this.state.wr_pdf_flg,
        contact_state_code: this.state.contact_state_code,
        contact_suburb: this.state.contact_suburb,
        contact_city: this.state.contact_city,
        contact_phone: this.state.contact_phone,
        contact_postal_code: this.state.contact_postal_code,
        contact_address: this.state.contact_address,
        documentsDeliveryType: this.state.documentsDeliveryTypeValue?.text,
      };

      if (view === "Vessel" && this.state.clientsAvailable.length > 1) {
        payload.relationID = this.state.clientsAvailable.filter(
          (x) => x.relation_name === this.state.selectedClients
        )[0]?.clientID;
      } else if (
        view === "Vessel" &&
        this.state.clientsAvailable.length === 1
      ) {
        payload.relationID = this.state.clientsAvailable[0].clientID;
      }

      //check at least one flag true -> REMOVE BY SAKIS 28/2/2024
      // if (
      //   payload.wh_notification_flg === false &&
      //   payload.notify_stock_email === false &&
      //   // payload.notify_stock_ws === false &&
      //   payload.supplier_invoice_flg === false &&
      //   payload.wr_pdf_flg === false &&
      //   payload.contact_type !== "AES_CONTACT"
      // ) {
      //   this.setState({
      //     ...this.state,
      //     windowError: true,
      //     windowErrorMessage:
      //       "At least one flag set to true is required to continue.",
      //   });
      // } else

      if (
        !payload[idKey] ||
        !payload.contact_type ||
        !payload.contact_name ||
        !payload.contact_email
      ) {
        this.setState({
          ...this.state,
          windowError: true,
          windowErrorMessage: "Type/Name/Email are required to continue.",
        });
      } else if (!payload.relationID && view === "Vessel") {
        this.setState({
          ...this.state,
          windowError: true,
          windowErrorMessage: "Please select a client to continue the process.",
        });
      } else {
        this.setState({
          ...this.state,
          disableCreateContact: false,
        });
        let addVesselToClientRequest = await serverApi(
          "POST",
          "masterEntityContacts",
          {},
          payload,
          options
        );

        if (addVesselToClientRequest.status === 200) {
          this.setState(
            {
              ...this.state,
              visibleWindow: false,
              selectedClients: null,
            },
            () => {
              this.refreshAfterAction();
            }
          );
        }
      }
    } catch (e) {
      debugger;
      let message =
        "Attach contact to client failed.Please try again and if the problem persist contact the administrator.";
      if (e?.response?.data?.error?.message.includes("unique constraint")) {
        message =
          "Duplicate id detected because the vessel you are trying to attach already exists.Please try with a new vessel.";
      } else if (
        e.response &&
        e.response.data &&
        e.response.data.error &&
        e.response.data.error.statusCode === 500
      ) {
        message = e.response.data.error.message || null;
      }
      this.setState({
        ...this.state,
        visibleWindow: true,
        windowError: true,
        windowErrorMessage:
          message ||
          "Create new contact and then attach to the client process has failed.Please try again with correct input.",
      });
    }
  };

  closeErrorModal = () => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      errorModalContent: "",
    });
  };

  handleErrorClose = (loginError) => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      openErrorModalContent: "",
    });
  };

  openErrorModal = (e) => {
    this.setState({
      ...this.state,
      openErrorModal: true,
      errorModalContent: e.response.data.error.message || "Please try again",
    });
  };

  handleClientsChange = (event) => {
    this.setState({
      ...this.state,
      selectedClients: event.target.value,
    });
  };

  onDialogInputChange = (event) => {
    let target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.props ? target.props.name : target.name;

    if (name === "contact_email") {
      let isEmailOk = verifyEmail(value);

      if (isEmailOk !== false) {
        this.setState({
          ...this.state,
          gridData: {
            ...this.state.gridData,
            [name]: value,
          },
          [name]: value,
          emailHasError: false,
        });
      } else {
        this.setState({
          ...this.state,
          gridData: {
            ...this.state.gridData,
            [name]: value,
          },
          [name]: value,
          emailHasError: true,
        });
      }
    }

    if (name !== "email") {
      this.setState({
        ...this.state,
        gridData: {
          ...this.state.gridData,
          [name]: value,
        },
        [name]: value,
      });
    }
  };

  handleChange = (event, field) => {
    this.setState({
      [field]: event.target.value,
    });
  };

  handleDropDownChange = (event) => {
    let target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.props ? target.props.name : target.name;

    this.setState({
      ...this.state,
      isDropOpened: !this.state.isDropOpened,
      gridData: {
        ...this.state.gridData,
        [name]: value.id,
      },
      [name]: value.id,
    });
  };

  filterCountryData = (filter) => {
    if (filter.value === "") {
      this.setState({
        countries: this.state.initialCountries,
      });

      return;
    } else {
      const data = this.state.initialCountries;
      filter.operator = "startswith";
      let filteredResults = filterBy(data, filter);

      return filteredResults;
    }
  };

  filterChange = (event) => {
    let filteredResults = this.filterCountryData(event.filter);

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.setState({
        countries:
          filteredResults !== undefined
            ? filteredResults
            : this.state.initialCountries,
        loading: false,
      });
    }, this.state.delay);

    this.setState({
      loading: true,
    });
  };

  //todo get the dropdown list data from lov api call
  renderCreateContactForm = (dataItem) => {
    return (
      <div>
        {this.state.windowError ? (
          <Error style={{ color: "red" }}>
            <h3>{this.state.windowErrorMessage || ""}</h3>
          </Error>
        ) : null}
        <fieldset style={{ borderColor: "rgba(13, 88, 105, 1)" }}>
          <legend>{`${this.props.view} Name: ${
            this.props.view === "Client"
              ? dataItem.relation_name
              : dataItem.vessel_name
          }`}</legend>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.wh_notification_flg}
                      onChange={(event) => {
                        this.setState({
                          ...this.state,
                          wh_notification_flg: !this.state.wh_notification_flg,
                        });
                      }}
                      color="primary"
                    />
                  }
                  label="WH Notification Flag"
                />
              </span>
              <span>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.notify_stock_email}
                      onChange={(event) => {
                        this.setState({
                          ...this.state,
                          notify_stock_email: !this.state.notify_stock_email,
                        });
                      }}
                      color="primary"
                    />
                  }
                  label="Notify Stock Email Flag"
                />
              </span>
              {/* <span>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.notify_stock_ws}
                      onChange={(event) => {
                        this.setState({
                          ...this.state,
                          notify_stock_ws: !this.state.notify_stock_ws,
                        });
                      }}
                      color="primary"
                    />
                  }
                  label="Notify Stock WS"
                />
              </span> */}
              {this.state.valueContactType.text === "WAREHOUSE-RECEIPT" ? (
                <div>
                  {" "}
                  <span>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.wr_pdf_flg}
                          onChange={(event) => {
                            this.setState({
                              ...this.state,
                              wr_pdf_flg: !this.state.wr_pdf_flg,
                            });
                          }}
                          color="primary"
                        />
                      }
                      label="Warehouse PDF"
                    />
                  </span>
                  <span>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.supplier_invoice_flg}
                          onChange={(event) => {
                            this.setState({
                              ...this.state,
                              supplier_invoice_flg:
                                !this.state.supplier_invoice_flg,
                            });
                          }}
                          color="primary"
                        />
                      }
                      label="Supplier Invoice"
                    />
                  </span>
                  <span>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.packing_list_flg}
                          onChange={(event) => {
                            this.setState({
                              ...this.state,
                              packing_list_flg: !this.state.packing_list_flg,
                            });
                          }}
                          color="primary"
                        />
                      }
                      label="Packing List"
                    />
                  </span>
                </div>
              ) : null}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h5>Contact Type</h5>
              <DropDownList
                style={{ width: "350px" }}
                required={false}
                data={this.state.contactTypes}
                textField="text"
                dataItemKey="id"
                onChange={(e) => this.handleChange(e, "valueContactType")}
                value={this.state.valueContactType}
              />
              <h5>Documents Delivery Type</h5>
              <DropDownList
                style={{ width: "350px" }}
                required={false}
                data={[
                  { text: "Attachments", id: 1 },
                  { id: 2, text: "Links" },
                ]}
                textField="text"
                dataItemKey="id"
                onChange={(e) =>
                  this.handleChange(e, "documentsDeliveryTypeValue")
                }
                value={this.state.documentsDeliveryTypeValue}
              />
              {this.props.view === "Vessel" &&
              this.state.clientsAvailable.length > 0 ? (
                <span>
                  <h3>
                    {this.state.clientsAvailable.length === 1
                      ? "Client"
                      : "Clients"}
                  </h3>
                  <DropDownList
                    data={
                      this.state.clientsAvailable.map((x) => x.relation_name) ||
                      []
                    }
                    onChange={this.handleClientsChange}
                    onFilterChange={this.onFilterChange}
                    value={
                      this.state.clientsAvailable.length === 1
                        ? this.state.clientsAvailable[0].relation_name
                        : this.state.selectedClients
                    }
                    filterable={false}
                    style={{ width: "310px" }}
                    placeholder="Select at least one client from the dropdown."
                    disabled={
                      this.state.clientsAvailable.length === 1 ? true : false
                    }
                  />
                </span>
              ) : null}

              <span>
                <h3>Contact Name</h3>
                <Input
                  required={false}
                  style={{ width: "500px" }}
                  name="contact_name"
                  value={
                    this.state.gridData.data.filter(
                      (temp) => temp.inEdit === true
                    ).contact_name
                  }
                  onChange={this.onDialogInputChange}
                />
              </span>
              <span>
                <h3>Contact Email</h3>
                <Input
                  required={false}
                  style={{ width: "500px" }}
                  name="contact_email"
                  value={
                    this.state.gridData.data.filter(
                      (temp) => temp.inEdit === true
                    ).contact_email
                  }
                  onChange={this.onDialogInputChange}
                  // label="Please enter a valid email address such as : example@mail.com"
                />
              </span>
              <span>
                <h3>Address</h3>
                <Input
                  style={{ width: "400px" }}
                  name="contact_address"
                  value={
                    this.state.gridData.data.filter(
                      (temp) => temp.inEdit === true
                    ).contact_address
                  }
                  onChange={this.onDialogInputChange}
                />
              </span>
              <span>
                <h3>Contact Country</h3>
                <DropDownList
                  data={this.state.countries.sort((a, b) =>
                    a.text > b.text ? 1 : -1
                  )}
                  value={this.state.contact_country.text}
                  textField="text"
                  style={{ width: "310px" }}
                  filterable={true}
                  name="contact_country"
                  onFilterChange={this.filterChange}
                  onChange={this.handleDropDownChange}
                />
              </span>
              {this.state.contact_country &&
                this.state.contact_country.text === "US" && (
                  <span>
                    <h3>State</h3>
                    <DropdownFilterCell
                      {...this.props}
                      name={this.state.selectedState}
                      filterable={true}
                      width="400px"
                      onFilterChange={this.filterChange}
                      onChange={(e) =>
                        this.handleDropDownChange(e, "selectedState")
                      }
                      data={(this.props.usaStates || [])
                        .map((x) => x.lov_value_1)
                        .filter((x) => x)
                        .sort((a, b) => (a > b ? 1 : -1))}
                    />
                  </span>
                )}
              <span>
                <h3>City</h3>
                <Input
                  style={{ width: "400px" }}
                  name="contact_city"
                  value={
                    this.state.gridData.data.filter(
                      (temp) => temp.inEdit === true
                    ).contact_city
                  }
                  onChange={this.onDialogInputChange}
                />
              </span>
              <span>
                <h3>Contact Suburb</h3>
                <Input
                  style={{ width: "400px" }}
                  name="contact_suburb"
                  value={
                    this.state.gridData.data.filter(
                      (temp) => temp.inEdit === true
                    ).contact_suburb
                  }
                  onChange={this.onDialogInputChange}
                />
              </span>
              <span>
                <h3>Contact State Code</h3>
                <Input
                  style={{ width: "400px" }}
                  name="contact_state_code"
                  value={
                    this.state.gridData.data.filter(
                      (temp) => temp.inEdit === true
                    ).contact_state_code
                  }
                  onChange={this.onDialogInputChange}
                />
              </span>
              <span>
                <h3>Postal Code</h3>
                <Input
                  style={{ width: "400px" }}
                  name="contact_postal_code"
                  value={
                    this.state.gridData.data.filter(
                      (temp) => temp.inEdit === true
                    ).contact_postal_code
                  }
                  onChange={this.onDialogInputChange}
                />
              </span>

              <span>
                <h3>Phone</h3>
                <Input
                  style={{ width: "400px" }}
                  name="contact_phone"
                  value={
                    this.state.gridData.data.filter(
                      (temp) => temp.inEdit === true
                    ).contact_phone
                  }
                  onChange={this.onDialogInputChange}
                />
              </span>
            </div>
          </div>

          <br />

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button
              style={{ justifyContent: "flex-end" }}
              type="button"
              className="k-button k-primary"
              onClick={(e) => this.saveNewContact(e)}
              disabled={this.state.disableCreateContact}
            >
              Save
            </button>
          </div>
        </fieldset>
      </div>
    );
  };

  deleteGroup = async (dataItem) => {
    this.props.showRemoveWindow(dataItem);
  };

  render() {
    const dataItem = this.props.dataItem;
    const { gridData } = this.state;
    const hasEditedItem =
      gridData && (gridData.data || []).some((p) => p.inEdit);
    const { hasBackofficeRead } = this.props;

    const entityName =
      this.props.view === "Vessel"
        ? dataItem.vessel_name
        : dataItem.relation_name;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <div
          style={{
            width: "83%",
            marginBottom: "30px",
          }}
        >
          <h4>
            {this.props.expandedLabel} : {entityName}
          </h4>
          {this.state.openErrorModal ? (
            <Error style={{ color: "red" }}>
              <h3>{this.state.errorModalContent || ""}</h3>
            </Error>
          ) : null}
          {this.state.openWarningModal ? (
            <Error style={{ color: "red" }}>
              <h3>{this.state.errorWarningContent || ""}</h3>
            </Error>
          ) : null}

          <Grid
            {...this.state.dataState}
            {...gridData}
            filterable={false}
            style={{ className: "grid-no-select" }}
            sortable={true}
            resizable
            editField={this.editField}
            onItemChange={this.itemChange}
            pageable={false}
          >
            <GridToolbar>
              <GridContainer xs={12} justify={"flex-start"} direction={"row"}>
                {!hasBackofficeRead && (
                  <Tooltip
                    justify={"flex-start"}
                    placement="top"
                    title={"Add Contact"}
                  >
                    <IconButton onClick={this.toggleWindow}>
                      <FontAwesomeIcon
                        color="#0D5869"
                        onClick={this.toggleWindow}
                        size="1.6x"
                        icon={faPlus}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </GridContainer>
            </GridToolbar>

            {!hasBackofficeRead && (
              <Column
                cell={this.CommandCell}
                title="Actions"
                filterable={false}
                width={hasEditedItem ? "350px" : "350px"}
              />
            )}

            {this.renderGridColumns(dataItem)}
          </Grid>
        </div>
        {this.state.visibleWindow && (
          <Window
            title={`Create contact for ${this.props.view}`}
            onClose={this.toggleWindow}
            width={1150}
            height={750}
            // initialTop={10}
          >
            <div>{this.renderCreateContactForm(dataItem)}</div>
          </Window>
        )}
      </div>
    );
  }
}

export default Contacts;
