import React from "react";
import Icon from "components/Icon/Icon";
import { faIcons } from "components/Icon/Icon";
import { roles } from "../lib/Enums";
import CRM from "../routes/CRM";
import ClientView from "../routes/Clients";
import Vessels from "../routes/Vessels";
import Departments from "../routes/Departments";
import Domains from "../routes/Domains";
import LogBox from "../routes/LogBox";
import ChangePassword from "../routes/Operators/View/components/ChangePassword";
import EditUserDepartments from "../routes/Operators/View/components/EditUserDepartments";
import Agents from "../routes/Agents";
import GroupToClient from "../routes/ClientManagement/GroupToClient/View";
import OperatorsView from "../routes/Operators/View";
import WarehouseView from "../routes/WarehouseUser/View";
import Suppliers from "../routes/Supplier";
import InvoiceLogPage from "../routes/InvoiceLogs";
import ClientUsers from "../routes/ClientUsers/View";
import EditClientUserClients from "../routes/ClientUsers/View/components/editUserClients";
import Roles from "../routes/Roles/View";
import AllUsersView from "../routes/SnUsers/View";
import BackofficeLogs from "../routes/BackofficeLogs/View";
import WebsocketUsers from "../routes/WebsocketUsers/container";
import AirportsView from "../routes/AirportToSeaport/container";
import WarehouseMapping from "../routes/IfWarehouseMapping/container";
import SeaportsView from "../routes/Seaports";
import Fleets from "../routes/Fleets";
import Assets from "../routes/Assets";
import Carriers from "../routes/Carriers";
import Warehouses from "../routes/Warehouses";
import ModelSettingDefinition from "../routes/ModelSettingDefinitions";
import ApprovalActionTiers from "../routes/ApprovalActionTiers";

export const root = "/app";

export const getCurrentCategory = (path, id) => {
  let categoryFound = null;
  for (let i = 0; i < routes.length; i++) {
    if (routes[i].isCategory) {
      let route = routes[i].children.find((x) => {
        const routePath = id ? x.path.replace(":id", id) : x.path;
        return routePath === path;
      });
      if (route) {
        categoryFound = routes[i];
        break;
      }
    }
  }
  return categoryFound;
};

export const getCurrentRoute = (path, id) => {
  let routeFound = null;
  for (let i = 0; i < routes.length; i++) {
    if (routes[i].isCategory) {
      let route = routes[i].children.find((x) => {
        const routePath = id && x.path ? x.path.replace(":id", id) : x.path;
        return routePath === path;
      });
      if (route) {
        routeFound = route;
        break;
      }
    } else {
      const routePath =
        id && routes[i].path
          ? routes[i].path.replace(":id", id)
          : routes[i].path;
      if (routePath === path) {
        routeFound = routes[i];
        break;
      }
    }
  }
  return routeFound;
};

export const getAllRoutes = () => {
  let allRoutes = routes.filter((x) => x.component);
  let categories = routes.filter((x) => x.isCategory);
  categories.forEach((x) => {
    allRoutes = allRoutes.concat(x.children.filter((x) => x.component));
  });
  return allRoutes;
};

export const getUserRoutes = (user) => {
  const allRoutes = getAllRoutes();
  return allRoutes.filter(
    (x) => !x.access || x.access.roles.some((r) => user.roleIds.includes(r))
  );
};

export const routes = [
  {
    id: 1,
    isCategory: true,
    sidebar: {
      text: "MASTER DATA",
      icon: <Icon icon={faIcons.faList} className="icon small" />,
    },
    access: {
      roles: [
        roles.SUPERROLE,
        roles.BACKOFFICE,
        roles.BACKOFFICEMASTERDATAREAD,
        roles.BACKOFFICEMASTERDATA,
      ],
    },
    children: [
      {
        id: 2,
        title: "Master Entities",
        documentTitle: "BO - Master Entities",
        path: root + "/master/entities",
        default: true,
        component: {
          view: CRM,
        },
        sidebar: {
          text: "Master Entities",
          icon: <Icon icon={faIcons.faTable} className="icon" />,
        },
        access: {
          roles: [
            roles.SUPERROLE,
            roles.BACKOFFICE,
            roles.BACKOFFICEMASTERDATAREAD,
            roles.BACKOFFICEMASTERDATA,
          ],
        },
      },
      {
        id: 3,
        title: "Clients",
        documentTitle: "Clients",
        path: root + "/master/clients",
        component: {
          view: ClientView,
        },
        sidebar: {
          text: "Clients",
          icon: <Icon icon={faIcons.faUsers} className="icon small" />,
        },
        access: {
          roles: [
            roles.SUPERROLE,
            roles.BACKOFFICE,
            roles.BACKOFFICEMASTERDATAREAD,
            roles.BACKOFFICEMASTERDATA,
          ],
        },
      },
      {
        id: 4,
        title: "Suppliers",
        documentTitle: "Suppliers",
        path: root + "/master/suppliers",
        component: {
          view: Suppliers,
        },
        sidebar: {
          text: "Suppliers",
          icon: <Icon icon={faIcons.faUserCheck} className="icon small" />,
        },
        access: {
          roles: [
            roles.SUPERROLE,
            roles.BACKOFFICE,
            roles.BACKOFFICEMASTERDATAREAD,
            roles.BACKOFFICEMASTERDATA,
          ],
        },
      },
      {
        id: 5,
        title: "Agents-Vendors",
        path: root + "/master/agents",
        component: {
          view: Agents,
        },
        sidebar: {
          text: "Agents-Vendors",
          icon: <Icon icon={faIcons.faInfoCircle} className="icon small" />,
        },
        access: {
          roles: [
            roles.SUPERROLE,
            roles.BACKOFFICE,
            roles.BACKOFFICEMASTERDATAREAD,
            roles.BACKOFFICEMASTERDATA,
          ],
        },
        // secondaryIcon: <Icon icon={faIcons.faShip} className="secondary-icon small sidebar-mini" />
      },
      {
        id: 21,
        title: "Airports",
        path: root + "/master/airports",
        component: {
          view: AirportsView,
        },
        sidebar: {
          text: "Airports",
          icon: <Icon icon={faIcons.faPlane} className="icon small" />,
        },
        access: {
          roles: [
            roles.SUPERROLE,
            roles.BACKOFFICE,
            roles.BACKOFFICEMASTERDATAREAD,
            roles.BACKOFFICEMASTERDATA,
          ],
        },
        // secondaryIcon: <Icon icon={faIcons.faShip} className="secondary-icon small sidebar-mini" />
      },
      {
        id: 21,
        title: "Seaports",
        path: root + "/master/seaports",
        component: {
          view: SeaportsView,
        },
        sidebar: {
          text: "Seaports",
          icon: <Icon icon={faIcons.faAnchor} className="icon small" />,
        },
        access: {
          roles: [
            roles.SUPERROLE,
            roles.BACKOFFICE,
            roles.BACKOFFICEMASTERDATAREAD,
            roles.BACKOFFICEMASTERDATA,
          ],
        },
        // secondaryIcon: <Icon icon={faIcons.faShip} className="secondary-icon small sidebar-mini" />
      },
      {
        id: 45,
        title: "Warehouses",
        path: root + "/master/warehouses",
        component: {
          view: Warehouses,
        },
        sidebar: {
          text: "Warehouses",
          icon: <Icon icon={faIcons.faWarehouse} className="icon small" />,
        },
        access: {
          roles: [
            roles.SUPERROLE,
            roles.BACKOFFICE,
            roles.BACKOFFICEMASTERDATAREAD,
            roles.BACKOFFICEMASTERDATA,
          ],
        },
        // secondaryIcon: <Icon icon={faIcons.faShip} className="secondary-icon small sidebar-mini" />
      },
      {
        id: 6,
        title: "Vessels",
        documentTitle: "Vessels",
        path: root + "/master/vessels",
        component: {
          view: Vessels,
        },
        sidebar: {
          text: "Vessels",
          icon: <Icon icon={faIcons.faShip} className="icon small" />,
        },
        access: {
          roles: [
            roles.SUPERROLE,
            roles.BACKOFFICE,
            roles.BACKOFFICEMASTERDATAREAD,
            roles.BACKOFFICEMASTERDATA,
          ],
        },
      },
      {
        id: 2166,
        title: "Vessels",
        documentTitle: "Vessels",
        path: root + "/master/vessels/:id",
        component: {
          view: Vessels,
        },
        access: {
          roles: [
            roles.SUPERROLE,
            roles.BACKOFFICE,
            roles.BACKOFFICEMASTERDATAREAD,
            roles.BACKOFFICEMASTERDATA,
          ],
        },
      },
      {
        id: 10,
        title: "Fleets",
        documentTitle: "Fleets",
        path: root + "/master/fleets",
        component: {
          view: Fleets,
        },
        sidebar: {
          text: "Fleets",
          icon: <Icon icon={faIcons.faMicrochip} className="icon small" />,
        },
        access: {
          roles: [
            roles.SUPERROLE,
            roles.BACKOFFICE,
            roles.BACKOFFICEMASTERDATAREAD,
          ],
        },
      },
      {
        id: 7,
        title: "Group to client",
        documentTitle: "Group to client",
        path: root + "/master/grouptoclient",
        component: {
          view: GroupToClient,
        },
        sidebar: {
          text: "Group To Client",
          icon: <Icon icon={faIcons.faBuilding} className="icon small" />,
        },
        access: {
          roles: [
            roles.SUPERROLE,
            roles.BACKOFFICE,
            roles.BACKOFFICEMASTERDATAREAD,
          ],
        },
      },
      {
        id: 9,
        title: "If Warehouse Mapping",
        documentTitle: "If Warehouse Mapping",
        path: root + "/master/whMappings",
        component: {
          view: WarehouseMapping,
        },
        sidebar: {
          text: "If  Warehouse Mapping",
          icon: <Icon icon={faIcons.faBuilding} className="icon small" />,
        },
        access: {
          roles: [
            roles.SUPERROLE,
            roles.BACKOFFICE,
            roles.BACKOFFICEMASTERDATAREAD,
          ],
        },
      },
      {
        id: 9,
        title: "Assets",
        documentTitle: "Assets",
        path: root + "/master/assets",
        component: {
          view: Assets,
        },
        sidebar: {
          text: "Assets",
          icon: <Icon icon={faIcons.faFileImage} className="icon small" />,
        },
        access: {
          roles: [
            roles.SUPERROLE,
            roles.BACKOFFICE,
            roles.BACKOFFICEMASTERDATAREAD,
          ],
        },
      },
      {
        id: 9,
        title: "Carriers",
        documentTitle: "Carriers",
        path: root + "/master/carriers",
        component: {
          view: Carriers,
        },
        sidebar: {
          text: "Carriers",
          icon: <Icon icon={faIcons.faTruckPickup} className="icon small" />,
        },
        access: {
          roles: [
            roles.SUPERROLE,
            roles.BACKOFFICE,
            roles.BACKOFFICEMASTERDATAREAD,
            roles.BACKOFFICEMASTERDATA,
          ],
        },
      },
      {
        id: 9,
        title: "Model Setting Definitions",
        documentTitle: "Model Setting Definitions",
        path: root + "/master/modelsettingdefinitions",
        component: {
          view: ModelSettingDefinition,
        },
        sidebar: {
          text: "Model Setting Definitions",
          icon: <Icon icon={faIcons.faWrench} className="icon small" />,
        },
        access: {
          roles: [
            roles.SUPERROLE,
            roles.BACKOFFICE,
            roles.BACKOFFICEMASTERDATAREAD,
            roles.BACKOFFICEMASTERDATA,
          ],
        },
      },
    ],
  },
  {
    id: 10,
    isCategory: true,
    sidebar: {
      text: "USER MANAGEMENT",
      icon: <Icon icon={faIcons.faUserCog} className="icon small" />,
    },
    access: {
      roles: [roles.SUPERROLE, roles.BACKOFFICE],
    },
    children: [
      {
        id: 10,
        title: "All Users",
        documentTitle: "All Users",
        path: root + "/master/users/all",
        component: {
          view: AllUsersView,
        },
        sidebar: {
          text: "All users",
          icon: <Icon icon={faIcons.faUserShield} className="icon small" />,
        },
        access: {
          roles: [roles.SUPERROLE, roles.BACKOFFICE],
        },
      },
      {
        id: 10,
        title: "WS Users",
        documentTitle: "WS Users",
        path: root + "/master/wsUsers",
        component: {
          view: WebsocketUsers,
        },
        sidebar: {
          text: "WS Users",
          icon: <Icon icon={faIcons.faEye} className="icon small" />,
        },
        access: {
          roles: [roles.SUPERROLE, roles.BACKOFFICE],
        },
      },
      {
        id: 13,
        default: true,
        title: "Operators",
        documentTitle: "Operators",
        path: root + "/master/operators",
        component: {
          view: OperatorsView,
        },
        sidebar: {
          text: "Operators",
          icon: <Icon icon={faIcons.faUserFriends} className="icon small" />,
        },
        access: {
          roles: [roles.SUPERROLE, roles.BACKOFFICE],
        },
      },
      {
        id: 14,
        default: true,
        title: "Client Users",
        documentTitle: "Client Users",
        path: root + "/master/user-clients",
        component: {
          view: ClientUsers,
        },
        sidebar: {
          text: "Clients",
          icon: <Icon icon={faIcons.faUserTie} className="icon small" />,
        },
        access: {
          roles: [roles.SUPERROLE, roles.BACKOFFICE],
        },
      },
      {
        id: 15,
        default: true,
        title: "Warehouse",
        documentTitle: "Warehouse",
        path: root + "/master/whUsers",
        component: {
          view: WarehouseView,
        },
        sidebar: {
          text: "Warehouse",
          icon: <Icon icon={faIcons.faWarehouse} className="icon small" />,
        },
        access: {
          roles: [roles.SUPERROLE, roles.BACKOFFICE],
        },
      },
      {
        id: 125,
        default: true,
        title: "Approval Action Tiers",
        documentTitle: "Approval Action Tiers",
        path: root + "/master/approvalActionTiers",
        component: {
          view: ApprovalActionTiers,
        },
        sidebar: {
          text: "Approval Action Tiers",
          icon: <Icon icon={faIcons.faUserCog} className="icon small" />,
        },
        access: {
          roles: [roles.SUPERROLE, roles.BACKOFFICE],
        },
      },
    ],
  },
  {
    id: 50,
    isCategory: true,
    sidebar: {
      text: "ACCESS CONTROL",
      icon: <Icon icon={faIcons.faUniversalAccess} className="icon small" />,
    },
    access: {
      roles: [roles.SUPERROLE, roles.BACKOFFICE],
    },
    children: [
      {
        id: 11,
        title: "Edit user roles",
        documentTitle: "Roles",
        path: root + "/master/roles",
        component: {
          view: Roles,
        },
        sidebar: {
          text: "Roles",
          icon: (
            <Icon icon={faIcons.faExclamationCircle} className="icon small" />
          ),
        },
        access: {
          roles: [roles.SUPERROLE, roles.BACKOFFICE],
        },
      },
      {
        id: 12,
        default: true,
        title: "Departments",
        documentTitle: "Departments",
        path: root + "/master/deps",
        component: {
          view: Departments,
        },
        sidebar: {
          text: "Departments",
          icon: <Icon icon={faIcons.faInfo} className="icon small" />,
        },
        access: {
          roles: [roles.SUPERROLE, roles.BACKOFFICE],
        },
      },
      {
        id: 8,
        title: "Domains",
        documentTitle: "Domains",
        path: root + "/master/domains",
        component: {
          view: Domains,
        },
        sidebar: {
          text: "Domains",
          icon: (
            <Icon icon={faIcons.faExclamationTriangle} className="icon small" />
          ),
        },
        access: {
          roles: [roles.SUPERROLE, roles.BACKOFFICE],
        },
      },
    ],
  },
  {
    id: 7,
    isCategory: true,
    sidebar: {
      text: "LOGS",
      icon: <Icon icon={faIcons.faFileMedicalAlt} className="icon small" />,
    },
    access: {
      roles: [
        roles.SUPERROLE,
        roles.BACKOFFICE,
        roles.OPERATOR,
        roles.WAREHOUSE,
        roles.AGENT,
      ],
    },
    children: [
      {
        id: 8,
        default: true,
        title: "Logs",
        documentTitle: "Logs",
        path: root + "/master/logbox/:id",
        component: {
          view: LogBox,
        },
        sidebar: {
          text: "Box Log",
          icon: <Icon icon={faIcons.faBoxOpen} className="icon small" />,
        },
        access: {
          roles: [
            roles.SUPERROLE,
            roles.BACKOFFICE,
            roles.OPERATOR,
            roles.WAREHOUSE,
            roles.AGENT,
          ],
        },
      },
      {
        id: 9,
        default: true,
        title: "Logs",
        documentTitle: "Logs",
        path: root + "/master/invoicelog/:id",
        component: {
          view: InvoiceLogPage,
        },
        sidebar: {
          text: "Invoice Log",
          icon: <Icon icon={faIcons.faInfo} className="icon small" />,
        },
        access: {
          roles: [roles.SUPERROLE, roles.BACKOFFICE, roles.ACCOUNTANT],
        },
      },
      {
        id: 10,
        default: true,
        title: "Logs",
        documentTitle: "Backoffice Logs",
        path: root + "/master/backofficelogs",
        component: {
          view: BackofficeLogs,
        },
        sidebar: {
          text: "Backoffice Log",
          icon: <Icon icon={faIcons.faEye} className="icon small" />,
        },
        access: {
          roles: [roles.SUPERROLE, roles.BACKOFFICE],
        },
      },
    ],
  },
  // {
  //   id: 37,
  //   isCategory: true,
  //   sidebar: {
  //     text: "DATABASE",
  //     icon: <Icon icon={faIcons.faDatabase} className="icon small" />
  //   },
  //   access: {
  //     roles: [roles.SUPERROLE, roles.BACKOFFICE, roles.BACKOFFICEMASTERDATAREAD]
  //   },
  //   children: [
  //     {
  //       id: 111,
  //       title: "Long Running",
  //       documentTitle: "Long Running",
  //       path: root + "/master/longRunningSesssions",
  //       component: {
  //         view: LongRunningSessions
  //       },
  //       sidebar: {
  //         text: "Long Running",
  //         icon: <Icon icon={faIcons.faServer} className="icon small" />
  //       },
  //       access: {
  //         roles: [roles.SUPERROLE, roles.BACKOFFICE, roles.BACKOFFICEMASTERDATAREAD]
  //       }
  //     },
  //   ]
  // },
  {
    id: 15,
    title: "Change Password",
    documentTitle: "Change Password",
    path: root + "/master/user/passwordChange/:id",
    component: {
      view: ChangePassword,
    },
    access: {
      roles: [roles.SUPERROLE, roles.BACKOFFICE],
    },
  },
  {
    id: 16,
    title: "User Management",
    documentTitle: "User Management",
    path: root + "/master/departments/edit/:id",
    component: {
      view: EditUserDepartments,
    },
    access: {
      roles: [roles.SUPERROLE, roles.BACKOFFICE],
    },
  },
  {
    id: 17,
    title: "Suppliers",
    documentTitle: "Suppliers",
    path: root + "/master/suppliers/:id",
    component: {
      view: Suppliers,
    },
    access: {
      roles: [roles.SUPERROLE, roles.BACKOFFICE],
    },
  },
  {
    id: 20,
    title: "Clients",
    documentTitle: "Clients",
    path: root + "/master/clients/:id",
    component: {
      view: ClientView,
    },
    access: {
      roles: [
        roles.SUPERROLE,
        roles.BACKOFFICE,
        roles.BACKOFFICEMASTERDATAREAD,
        roles.BACKOFFICEMASTERDATA,
      ],
    },
  },
  {
    id: 18,
    title: "Edit user clients",
    documentTitle: "Edit Clients",
    path: root + "/master/user-to-clients/edit/:id",
    component: {
      view: EditClientUserClients,
    },
    access: {
      roles: [roles.SUPERROLE, roles.BACKOFFICE],
    },
  },
  {
    id: 220,
    title: "All Users",
    documentTitle: "All Users",
    path: root + "/master/users/all/:id",
    component: {
      view: AllUsersView,
    },
    access: {
      roles: [
        roles.SUPERROLE,
        roles.BACKOFFICE,
        roles.BACKOFFICEMASTERDATAREAD,
        roles.BACKOFFICEMASTERDATA,
      ],
    },
  },
  {
    id: 13,
    path: root + "/",
    component: {
      view: function () {
        return <></>;
      },
    },
  },
  // {
  //   id: 14,
  //   isReportBugMenu: true,
  //   sidebar: {
  //     text: "Report a bug",
  //     icon: <Icon icon={icons.BugReportIcon} className="icon x-small" />,
  //     bottom: true,
  //   }
  // },
  {
    id: 15,
    isAccountMenu: true,
    sidebar: {
      text: "@userDisplayName",
      icon: "@userAvatar",
      bottom: true,
    },
  },
  // {
  //   isAccountMenu: true,
  //   sidebar: {
  //     text: "@userDisplayName",
  //     icon: "@userAvatar",
  //     bottom: true,
  //   },
  //   access: {
  //     roles: ["*"],
  //   }
  // },
  // {
  //     title: "Settings",
  //     documentTitle: "Settings - Navigator",
  //     path: root + "/settings",
  //     component: {
  //         view: Settings,
  //     },
  //     sidebar: {
  //         text: "Settings",
  //         icon: <Icon icon={icons.SettingsIcon} className="icon x-small"/>,
  //         bottom: true,
  //     },
  //     access: {
  //         roles: [
  //             roles.SUPERROLE,
  //             roles.OPERATOR,
  //         ],
  //     }
  // },
];
