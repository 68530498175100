export const columnsSchema = () => [
  {
    field: "id",
    title: "ID",
    visible: true,
    minWidth: 200,
    sortable: true,
    filterable: true,
    locked: "locked",
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
    // cell: true
  },
  {
    field: "is_active",
    title: "ACTIVE",
    visible: true,
    minWidth: 220,
    sortable: true,
    filterable: true,
    isDropdown: "yes",
    // cell: true
  },
  {
    field: "contact_type",
    title: "Type",
    visible: true,
    minWidth: 290,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    locked: "locked",
    // editable: 'false'
  },
  {
    field: "isUrgent",
    title: "Is Urgent",
    visible: true,
    minWidth: 220,
    sortable: true,
    filterable: true,
    isDropdown: "yes",
    // cell: true
  },
  {
    field: "documentsDeliveryType",
    title: "Documents Delivery Type",
    visible: true,
    minWidth: 245,
    sortable: false,
    filterable: false,
    // cell: true
  },
  {
    field: "contact_name",
    title: "Name",
    visible: true,
    minWidth: 250,
    sortable: true,
    filterable: true,
    noDisabledColumn: false,
    notShownInTableColumnSelection: false,
    locked: "locked",
    // editable: 'false'
  },
  {
    field: "masterEntity.relation_name",
    title: "Client Name",
    visible: true,
    minWidth: 320,
    sortable: true,
    filterable: true,
    noDisabledColumn: false,
    notShownInTableColumnSelection: false,
    locked: "locked",
    // editable: 'false'
  },
  {
    field: "wh_notification_flg",
    title: "WH NOTIFICATION",
    visible: true,
    minWidth: 250,
    sortable: false,
    filterable: false,
    isDropdown: "yes",
  },
  {
    field: "notify_stock_email",
    title: "NOTIFY STOCK EMAIL",
    visible: true,
    minWidth: 250,
    sortable: false,
    filterable: false,
    isDropdown: "yes",
  },
  // {
  //   field: "notify_stock_ws",
  //   title: "NOTIFY STOCK WS",
  //   visible: true,
  //   minWidth: 250,
  //   sortable: false,
  //   filterable: false,
  //   isDropdown: "yes",
  // },
  {
    field: "supplier_invoice_flg",
    title: "SUPPLIER INVOICE",
    visible: true,
    minWidth: 250,
    sortable: false,
    filterable: false,
    isDropdown: "yes",
  },
  {
    field: "wr_pdf_flg",
    title: "WR PDF",
    visible: true,
    minWidth: 250,
    sortable: false,
    filterable: false,
    isDropdown: "yes",
  },
  {
    field: "packing_list_flg",
    title: "Packing List",
    visible: true,
    minWidth: 250,
    sortable: false,
    filterable: false,
    isDropdown: "yes",
  },
  {
    field: "contact_city",
    title: "City",
    visible: true,
    minWidth: 220,
    sortable: true,
    filterable: true,
    locked: "locked",
    // editable: 'false'
  },
  {
    field: "contact_country",
    title: "Country",
    visible: true,
    minWidth: 240,
    sortable: true,
    filterable: true,
    locked: "locked",
    notShownInTableColumnSelection: false,
    // editable: 'false'
  },
  {
    field: "contact_phone",
    title: "Phone",
    visible: true,
    sortable: true,
    minWidth: 250,
    filterable: true,
    locked: "locked",
    // editable: 'false'
  },
  {
    field: "contact_email",
    title: "Email",
    visible: true,
    sortable: true,
    minWidth: 300,
    filterable: true,
    locked: "locked",
    // editable: 'false'
  },
  {
    field: "contact_postal_code",
    title: "Postal Code",
    visible: true,
    sortable: true,
    minWidth: 240,
    filterable: true,
    locked: "locked",
    // editable: 'false'
  },
  {
    field: "contact_address",
    title: "Address",
    visible: true,
    sortable: true,
    minWidth: 300,
    filterable: true,
    locked: "locked",
    // editable: 'false'
  },
  {
    field: "contact_state_code",
    title: "State Code",
    visible: true,
    sortable: true,
    minWidth: 300,
    filterable: true,
    locked: "locked",
    // editable: 'false'
  },
  {
    field: "contact_suburb",
    title: "Suburb",
    visible: true,
    sortable: true,
    minWidth: 300,
    filterable: true,
    locked: "locked",
    // editable: 'false'
  },
  {
    field: "website_url",
    title: "Website url",
    visible: true,
    sortable: true,
    minWidth: 300,
    filterable: true,
    locked: "locked",
    editable: "false",
  },
];
