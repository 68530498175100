import React from "react";
import { columnsSchema } from "./schema";
import { MyCommandCell } from "../../../../Clients/View/components/CellHOC";
import { serverApi } from "../../../../../networking/config";
import _ from "lodash";
import { GridColumn as Column } from "@progress/kendo-react-grid/dist/npm/GridColumn";
import { Grid, GridToolbar } from "@progress/kendo-react-grid";
import GridContainer from "../../../../../components/Grid/GridContainer";
import { IconButton, Tooltip } from "@material-ui/core";
import { DropDownCell } from "../../../../CRM/View/dropDownCell";
import { Window } from "@progress/kendo-react-dialogs";
import BlockUi from "react-block-ui";
import { Error } from "@progress/kendo-react-labels";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import ViewSettings from "../../../../common/ViewSettings";
import { Menu, MenuItem as KendoMenuItem } from "@progress/kendo-react-layout";
import { Popup } from "@progress/kendo-react-popup";
import Snackbar from "../../../../../components/Snackbar/Snackbar";
import RegularButton from "../../../../../components/CustomButtons/Button";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class UserToDeptTab extends React.Component {
  editField = "inEdit";
  CommandCell;
  _export;

  constructor(props) {
    super(props);

    this.state = {
      blockingMessage: "Loading, please wait. . .",
      visibleWindow: false,
      removePrimaryDepartmentWindow: false,
      isDropOpened: false,
      columns: (columnsSchema && columnsSchema()) || [],
      gridData: {
        data: [],
        total: 10,
      },
      initialGridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 10,
        skip: 0,
      },
      openErrorModal: false,
      openErrorModalRemove: false,
      errorModalContent: "",
      errorModalContentRemove: "",
      delay: 400,
      loading: false,
      usernames: [],
      windowError: false,
      windowErrorRemove: false,
      windowErrorMessageRemove: "",
      windowErrorMessage: "",
      disableEditButton: true,
      inputValue: "",
      clientAttachedToAnotherGroup: null,
      exportData: [],
      open: false,
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      disableEditButton: false,
      showSecondAction: true,
      showFirstAction: false,
    });
  }

  async componentDidMount() {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    const userID = this.props.dataItem.id;
    let fil = {
      filter: {
        where: {
          userID: userID,
        },
        include: [
          {
            relation: "snUser",
            scope: {
              include: [
                {
                  relation: "userToDepts",
                },
              ],
            },
          },
          {
            relation: "department",
          },
        ],
      },
    };

    let usersOfDepartment = await serverApi(
      "GET",
      `userToDepts`,
      fil,
      "",
      options
    );
    let allDeps = await serverApi(
      "GET",
      `departments`,
      {
        filter: {
          include: [
            {
              relation: "users",
              scope: {
                include: [
                  {
                    relation: "userToDepts",
                  },
                ],
              },
            },
          ],
        },
      },
      "",
      options
    );
    let departments =
      allDeps.data
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((x) => x.name) || [];

    //REMOVES FROM checkbox lists the users having primary department the same as the expanded department
    // let expandedUsersWithPrimaryDepartment = (usersOfDepartment.data || []).filter(x => x.primary_flg === true)?.map(x=> x.department.name) || []
    // expandedUsersWithPrimaryDepartment.forEach(usr => {
    //   allDeps.data = allDeps.data.filter(x => x.name !== usr)
    // })

    let primaryDepartmentID = null;
    if (usersOfDepartment && usersOfDepartment?.data?.length > 0) {
      let primary =
        (usersOfDepartment.data || []).filter((x) => x.primary_flg === true) ||
        [];
      primaryDepartmentID = primary[0]?.deptID;
    }

    this.setState({
      ...this.state,
      primaryDeptID: primaryDepartmentID || null,
    });

    let result = this.constructUsersAndExistingUsersArray(
      allDeps.data,
      usersOfDepartment.data,
      userID
    );
    result[0].finalAttachedUsers.forEach((item) => {
      let user = result[0].finalUsers.find((x) => x.id === item.id);
      if (user) {
        user.checked = true;
      }
    });

    if (usersOfDepartment && usersOfDepartment.data.length > 1) {
      let finalData = [];
      _.forEach(usersOfDepartment.data, (temp) => {
        let finalName = temp?.department?.name;
        finalData.push({
          id: temp.deptID,
          name: finalName || "N/A",
          type: temp?.department?.type || "N/A",
          primary_flg: temp.primary_flg,
          location: temp?.department?.location || "N/A",
          email: temp?.department?.email || "N/A",
          deleteID: temp.id,
        });
      });
      finalData.sort((a, b) => (a.name > b.name ? 1 : -1));

      this.setState({
        ...this.state,
        userID: this.props.dataItem.id,
        gridData: {
          data: finalData,
          total: finalData.length,
        },
        initialGridData: {
          data: finalData,
          total: finalData.length,
        },
        departments: departments,
        valueUserName: "",

        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    } else if (usersOfDepartment && usersOfDepartment.data.length === 1) {
      let finalData = [];
      _.forEach(usersOfDepartment.data, (temp) => {
        finalData.push({
          id: temp.deptID,
          name: temp?.department?.name || "N/A",
          type: temp?.department?.type || "N/A",
          primary_flg: temp.primary_flg,
          location: temp?.department?.location || "N/A",
          email: temp?.department?.email || "N/A",
          deleteID: temp.id,
        });
      });
      finalData.sort((a, b) => (a.username > b.username ? 1 : -1));

      this.setState({
        ...this.state,
        userID: this.props.dataItem.id,
        gridData: {
          data: [finalData[0]],
          total: 1,
        },
        initialGridData: {
          data: [finalData[0]],
          total: 1,
        },
        departments: departments,
        valueUserName: "",
        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    } else {
      this.setState({
        ...this.state,
        userID: this.props.dataItem.id,
        gridData: {
          data: [],
          total: 0,
        },
        initialGridData: {
          data: [],
          total: 0,
        },
        departments: departments,
        valueUserName: "",
        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    }
  }

  componentWillUnmount() {}

  constructUsersAndExistingUsersArray = (
    allDeps,
    usersOfDepartment,
    expandedUser
  ) => {
    let finalUsers = [];
    _.forEach(allDeps, (temp1) => {
      const expandedUserBelongsToDepartment = temp1.users.filter(
        (x) => x.id === expandedUser
      );
      const deptIDs = [];
      let primaryDeptID = null;

      if (expandedUserBelongsToDepartment.length === 1) {
        const deptIds = (
          expandedUserBelongsToDepartment?.userToDepts || []
        ).map((x) => x.deptID);
        deptIDs.push(deptIds);
        let primaryDepartment =
          expandedUserBelongsToDepartment &&
          expandedUserBelongsToDepartment[0].userToDepts.filter(
            (x) => x.primary_flg === true
          );
        primaryDeptID = primaryDepartment[0]?.deptID || null;
      }

      let finalName = temp1.name;
      if (temp1.id === primaryDeptID)
        finalName = `Primary department:${finalName} - Cannot be removed`;

      finalUsers.push({
        name: finalName || "N/A",
        id: temp1.id,
        checked: false,
        deptIDs: deptIDs,
        userID: expandedUser,
        primaryDeptID: primaryDeptID,
        disabled: temp1.id === primaryDeptID ? true : false,
      });
    });

    let finalAttachedUsers = [];
    _.forEach(usersOfDepartment, (temp2) => {
      const primaryDepartmentId =
        ((temp2.snUser.userToDepts || []).filter(
          (x) => x.primary_flg === true
        )[0] &&
          (temp2.snUser.userToDepts || []).filter(
            (x) => x.primary_flg === true
          )[0].deptID) ||
        "N/A";

      finalAttachedUsers.push({
        name: temp2.department.name || "N/A",
        userID: temp2.userID,
        checked: true,
        deleteID: temp2.id,
        id: temp2.department.id,
        deptIDs: (temp2.snUser.userToDepts || []).map((x) => x.deptID),
        primaryDeptID: primaryDepartmentId,
      });
    });

    finalUsers.sort((a, b) => (a.name > b.name ? 1 : -1));
    finalAttachedUsers.sort((a, b) => (a.name > b.name ? 1 : -1));

    return [
      {
        finalUsers: finalUsers,
        finalAttachedUsers: finalAttachedUsers,
      },
    ];
  };

  fetchLatestUserDepartments = async () => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    const userID = this.props.dataItem.id;
    let fil = {
      filter: {
        where: {
          userID: userID,
        },
        include: ["department"],
      },
    };

    let usersOfDepartment = await serverApi(
      "GET",
      `userToDepts`,
      fil,
      "",
      options
    );
    let allDeps = await serverApi("GET", `departments`, "", "", options);
    let departments =
      allDeps.data
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((x) => x.name) || [];

    let result = this.constructUsersAndExistingUsersArray(
      allDeps.data,
      usersOfDepartment.data
    );
    result[0].finalAttachedUsers.forEach((item) => {
      let user = result[0].finalUsers.find((x) => x.id === item.id);
      if (user) {
        user.checked = true;
      }
    });

    if (usersOfDepartment && usersOfDepartment.data.length > 1) {
      let finalData = [];
      _.forEach(usersOfDepartment.data, (temp) => {
        let finalName = temp?.department?.name;
        finalData.push({
          id: temp.deptID,
          name: finalName || "N/A",
          type: temp?.department?.type || "N/A",
          primary_flg: temp.primary_flg,
          location: temp?.department?.location || "N/A",
          email: temp?.department?.email || "N/A",
          deleteID: temp.id,
        });
      });
      finalData.sort((a, b) => (a.name > b.name ? 1 : -1));

      this.setState({
        ...this.state,
        userID: this.props.dataItem.id,
        gridData: {
          data: finalData,
          total: finalData.length,
        },
        initialGridData: {
          data: finalData,
          total: finalData.length,
        },
        departments: departments,
        valueUserName: "",

        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    } else if (usersOfDepartment && usersOfDepartment.data.length === 1) {
      let finalData = [];
      _.forEach(usersOfDepartment.data, (temp) => {
        finalData.push({
          id: temp.deptID,
          name: temp?.department?.name || "N/A",
          type: temp?.department?.type || "N/A",
          primary_flg: temp.primary_flg,
          location: temp?.department?.location || "N/A",
          email: temp?.department?.email || "N/A",
          deleteID: temp.id,
        });
      });
      finalData.sort((a, b) => (a.username > b.username ? 1 : -1));

      this.setState({
        ...this.state,
        userID: this.props.dataItem.id,
        gridData: {
          data: [finalData[0]],
          total: 1,
        },
        initialGridData: {
          data: [finalData[0]],
          total: 1,
        },
        departments: departments,
        valueUserName: "",
        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    } else {
      this.setState({
        ...this.state,
        userID: this.props.dataItem.id,
        gridData: {
          data: [],
          total: 0,
        },
        initialGridData: {
          data: [],
          total: 0,
        },
        departments: departments,
        valueUserName: "",
        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    }
  };

  add = (dataImte) => {};

  toggleWindow = (e) => {
    const data = this.state.initialGridData.data;

    this.setState({
      ...this.state,
      visibleWindow: !this.state.visibleWindow,
      gridData: {
        data: [...data],
        total: 100,
      },
      client_names: [],
      valueClientName: "",
      windowError: false,
      windowErrorMessage: "",
      openErrorModal: false,
      errorModalContent: "",
    });
  };

  toggleWindowRemove = (e) => {
    const data = this.state.initialGridData.data;

    this.setState({
      ...this.state,
      removePrimaryDepartmentWindow: !this.state.removePrimaryDepartmentWindow,
      gridData: {
        data: [...data],
        total: 100,
      },
      client_names: [],
      valueClientName: "",
      windowError: false,
      windowErrorMessage: "",
      openErrorModal: false,
      errorModalContent: "",
    });
  };

  renderGridColumns = (dataItem, changeTheCellComponent) => {
    let selectionCol = [];
    let normalCols = [];
    let finalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            width="70px"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.isFilterBoolean === "yes") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"420"}
              cell={DropDownCell}
              // cell={(propakia) => <PrimaryFlag {...propakia}  />}
            />
          );
        } else {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={temp.field === "id" || temp.editable ? false : true} //id not editable on edit
            />
          );
        }
      });

    finalCols = [...selectionCol, ...normalCols];
    return finalCols;
  };

  handleSearch = (e) => {
    let initialFinalUserClients = this.state.initialFinalUserClients;

    if (e.value === "") {
      let searchArr = [];
      initialFinalUserClients.forEach((temp) => {
        if (temp.name.toUpperCase().includes(e.value.toUpperCase())) {
          searchArr.push(temp);
        }
      });

      if (
        this.state.clientAttachedToAnotherGroup !== null &&
        this.state.clientAttachedToAnotherGroup !== undefined
      ) {
        let newArr = searchArr.filter(
          (t) => t.id !== this.state.clientAttachedToAnotherGroup
        );
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalUserClients: newArr,
          openErrorModal: false,
          errorModalContent: "",
        });
      } else {
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalUserClients: initialFinalUserClients,
          openErrorModal: false,
          errorModalContent: "",
        });
      }
    } else {
      let searchArr = [];
      //case insensitive
      initialFinalUserClients.forEach((temp) => {
        if (temp && temp.name.toUpperCase().includes(e.value.toUpperCase())) {
          searchArr.push(temp);
        }
      });

      if (
        this.state.clientAttachedToAnotherGroup !== null &&
        this.state.clientAttachedToAnotherGroup !== undefined
      ) {
        let newArr = searchArr.filter(
          (t) => t.id !== this.state.clientAttachedToAnotherGroup
        );
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalUserClients: newArr,
          openErrorModal: false,
          errorModalContent: "",
        });
      } else {
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalUserClients: searchArr,
          openErrorModal: false,
          errorModalContent: "",
        });
      }
    }
  };

  handleSuccessSnackbar = () => {
    this.setState({
      ...this.state,
      successMessage:
        this.state.openSuccessMessage === true ? this.state.successMessage : "",
      openSuccessMessage: !this.state.openSuccessMessage,
    });
  };

  remove = async (dataItem) => {
    //REMOVE -> made with buldUpdate new endpoint
    //check for not letting primary dept to be deleted -> open popup
    // if (dataItem.primary_flg === true) {
    //   this.setState({
    //     ...this.state,
    //     windowError: true,
    //     windowErrorMessage: "Operation not allowed.Firstly set another primary department and then remove this department."
    //   }, () => {
    //     setTimeout(() => {
    //       this.setState({
    //         ...this.state,
    //         windowError: false,
    //         windowErrorMessage: ""
    //       })
    //     }, 5000)
    //   })
    // } else {
    //   const data = [...this.state.gridData.data];
    //   let index = data.findIndex(p => p === dataItem || dataItem.id && p.id === dataItem.id);
    //   if (index >= 0) {
    //     data.splice(index, 1);
    //   }
    //   let options = {
    //     token: window.localStorage.getItem('access_token')
    //   };
    //   let delete_ID = dataItem.deleteID
    //   let url = `userToDepts/${delete_ID}`
    //   try {
    //     this.setState({ visibleWindow: false });
    //     let fil = {
    //       filter: {
    //         where: {
    //           userID: this.state.userID
    //         },
    //         include: ["department"]
    //       }
    //     }
    //     let usersOfDepartment = await serverApi('GET', `userToDepts`, fil, '', options);
    //     const removeUserFromDepartment = await serverApi(`DELETE`, url, '', '', options)
    //     if (removeUserFromDepartment.status === 200) {
    //       let findUser = this.state.visibleClients.find(x => x.id === dataItem.id)
    //       if (findUser) {
    //         findUser.checked = false
    //       }
    //       this.setState({
    //         ...this.state,
    //         gridData: {
    //           data: data,
    //           total: this.state.gridData.total
    //         },
    //         initialGridData: {
    //           data: data,
    //           total: this.state.gridData.total
    //         }
    //       });
    //     }
    //   } catch (error) {
    //     this.setState({
    //       ...this.state,
    //       openErrorModal: true,
    //       errorModalContent: error?.response?.data?.error?.message || "Remove department from user process failed.Please try again."
    //     })
    //   }
    // }
  };

  cancel = (dataItem) => {
    const originalItem =
      this.state.initialGridData &&
      this.state.initialGridData.data.find((p) => p.id === dataItem.id);
    originalItem.inEdit = false;

    const data =
      this.state.initialGridData &&
      this.state.initialGridData.data.map((item) =>
        item.id === originalItem.id ? originalItem : item
      );

    const total = this.state.initialGridData.total;

    this.setState({
      ...this.state,
      gridData: {
        data: data,
        total: total,
      },
      initialGridData: {
        data: data,
        total: total,
      },
      openErrorModal: false,
      openErrorModalContent: "",
    });
  };

  removeItem(data, item) {
    let index = data.findIndex((p) => p === item || p.id === item.id);
    if (index >= 0) {
      data.splice(index, 1);
    }
  }

  discard = (dataItem, hasError = false) => {
    let data = [...this.state.gridData.data];
    const total = this.state.gridData.total;

    if (hasError) {
      data[0].inEdit = true;
      this.setState({
        gridData: {
          data: data,
          total: total,
        },
      });
    } else {
      this.removeItem(data, dataItem);

      this.setState({
        gridData: {
          data: data,
          total: total,
        },
        visible: false,
      });
    }
  };

  renderList = (finalUsers = [], users = [], doRefresh) => {
    if (doRefresh === true) {
      window.location.reload();
    } else {
      if (finalUsers.length === users.length) {
        return (
          users &&
          users.map((item) => (
            <ul style={{ marginBottom: "-5px" }} key={item.id}>
              <Checkbox
                name={item.name}
                key={item.id}
                value={item.checked}
                onChange={(e) => this.handleChange(e, item.name, item.id)}
                label={item.name}
                disabled={item.disabled || false}
              />
            </ul>
          ))
        );
      } else {
        return (
          finalUsers &&
          finalUsers.map((item) => (
            <ul style={{ marginBottom: "-5px" }} key={item.id}>
              <Checkbox
                name={item.name}
                key={item.id}
                value={item.checked}
                onChange={(e) => this.handleChange(e, item.name, item.id)}
                label={item.name}
                disabled={item.disabled || false}
              />
            </ul>
          ))
        );
      }
    }
  };

  handleChange = async (event, name, key) => {
    let finalUserClient = [...this.state.finalUserClients];
    let finalObj = finalUserClient.find((test) => test.id === key);

    let action = event.value === false ? "DELETE" : "POST";
    if (action === "POST") {
      finalObj.deptIDs.push(key);
      finalObj.primaryDeptID = this.state.primaryDeptID;
    } else if (action === "DELETE") {
      let index = finalObj.deptIDs.indexOf(key);
      if (index !== -1) {
        finalObj.deptIDs.splice(index, 1);
      }
    }

    finalObj.checked = !finalObj.checked;

    this.setState(
      {
        ...this.state,
        finalUserClients: finalUserClient,
      },
      async () => {
        // let postOrDeleteClient = event.value === false ? 'DELETE' : 'POST'
        // let options = {
        //   token: window.localStorage.getItem('access_token')
        // };
        // if (postOrDeleteClient === 'POST') {
        //   let fil = {
        //     filter: {
        //       where: {
        //         userID: userID
        //       },
        //       include: ["department"]
        //     }
        //   }
        //   let payload = {
        //     userID: userID,
        //     deptID: key
        //   }
        //   let usersOfDepartment = await serverApi('GET', `userToDepts`, fil, '', options);
        //   if (usersOfDepartment?.data?.length === 0) {
        //     payload = {
        //       userID: userID,
        //       deptID: key,
        //       primary_flg: true
        //     }
        //   } else if (usersOfDepartment?.data?.length > 0) {
        //     let primaryDeptExists = usersOfDepartment.data.some(x => x.primary_flg === true)
        //     if (primaryDeptExists) {
        //       payload = {
        //         userID: userID,
        //         deptID: key,
        //         primary_flg: false
        //       }
        //     } else {
        //       payload = {
        //         userID: userID,
        //         deptID: key,
        //         primary_flg: true
        //       }
        //     }
        //   }
        //   const url = `userToDepts`
        //   const addUserToDepartment = serverApi('POST', url, '', payload, options, '', '')
        //   addUserToDepartment
        //     .then(async response => {
        //       let usersOfDepartment = await serverApi('GET', `userToDepts`, fil, '', options);
        //       let finalData = []
        //       //todo check why N/A on primary_flg
        //       _.forEach(usersOfDepartment.data, temp => {
        //         finalData.push({
        //           id: temp.deptID,
        //           name: temp?.department?.name || 'N/A',
        //           type: temp?.department?.type || 'N/A',
        //           primary_flg: temp.primary_flg,
        //           location: temp?.department?.location || 'N/A',
        //           email: temp?.department?.email || 'N/A',
        //           deleteID: temp.id
        //         })
        //       })
        //       finalData.sort((a, b) => (a.NAME > b.username) ? 1 : -1)
        //       this.setState({
        //         ...this.state,
        //         gridData: {
        //           data: finalData,
        //           total: finalData.length
        //         },
        //         initialGridData: {
        //           data: finalData,
        //           total: finalData.length
        //         },
        //         blocking: false,
        //         openErrorModal: false,
        //         errorModalContent: "",
        //       })
        //     })
        //     .catch(error => {
        //       this.setState({
        //         ...this.state,
        //         openErrorModal: true,
        //         errorModalContent: error?.response?.data?.error?.message || "Add department to the user process failed.Please try again.",
        //         blocking: false
        //       })
        //     })
        // } else if (postOrDeleteClient === 'DELETE') {
        //   let data = [...this.state.gridData.data];
        //   const deleteObj = data.find(obj => obj.id === key)
        //   const delete_id = deleteObj.deleteID
        //   const deptID = deleteObj.id
        //   let fil = {
        //     filter: {
        //       where: {
        //         and: [
        //           {
        //             userID: userID
        //           },
        //           {
        //             deptID: deptID
        //           },
        //           {
        //             primary_flg: true
        //           }
        //         ]
        //       }
        //     }
        //   }
        //   let usersOfDepartment = await serverApi('GET', `userToDepts`, fil, '', options);
        //   if (usersOfDepartment.data.length > 0 && event.value === false) {
        //     this.setState({
        //       ...this.state,
        //       visibleWindow: false,
        //       openErrorModal: true,
        //       errorModalContent: "Operation not allowed.Firstly set another primary department and then remove this department."
        //     }, () => {
        //       setTimeout(() => {
        //         this.setState({
        //           ...this.state,
        //           openErrorModal: false,
        //           errorModalContent: ""
        //         }, () => {
        //           this.fetchLatestUserDepartments()
        //         })
        //       }, 4000);
        //     })
        //   } else {
        //     const url = `userToDepts/${delete_id}`
        //     const removeUserFromDepartment = serverApi('DELETE', url, '', '', options, '', '')
        //     removeUserFromDepartment
        //       .then(async response => {
        //         let fil = {
        //           filter: {
        //             where: {
        //               userID: userID
        //             },
        //             include: ["department"]
        //           }
        //         }
        //         let usersOfDepartment = await serverApi('GET', `userToDepts`, fil, '', options);
        //         let finalData = []
        //         _.forEach(usersOfDepartment.data, temp => {
        //           finalData.push({
        //             id: temp.deptID,
        //             name: temp?.department?.name || 'N/A',
        //             type: temp?.department?.type || 'N/A',
        //             primary_flg: temp.primary_flg,
        //             location: temp?.department?.location || 'N/A',
        //             email: temp?.department?.email || 'N/A',
        //             deleteID: temp.id
        //           })
        //         })
        //         finalData.sort((a, b) => (a.username > b.username) ? 1 : -1)
        //         this.setState({
        //           ...this.state,
        //           gridData: {
        //             data: finalData,
        //             total: finalData.length
        //           },
        //           initialGridData: {
        //             data: finalData,
        //             total: finalData.length
        //           },
        //           blocking: false
        //         })
        //       })
        //       .catch(error => {
        //         this.setState({
        //           ...this.state,
        //           openErrorModal: true,
        //           errorModalContent: error?.response?.data?.error?.message || "Remove department from the user process failed.Please try again",
        //           blocking: false
        //         })
        //       })
        //   }
        // }
      }
    );
  };

  getExportData = async () => {
    await this.exportToCsv(this.state, this.props);
  };

  getPromise = () => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    const userID = this.props.dataItem.id;

    let fil = {
      filter: {
        where: {
          userID: userID,
        },
        include: ["department"],
      },
    };

    return new Promise((resolve, reject) => {
      const usersOfDepartment = serverApi(
        "GET",
        `userToDepts`,
        fil,
        "",
        options
      );
      usersOfDepartment
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  exportToCsv = async (state, props) => {
    const count = state.gridData.total;
    const numberOfCalls = Math.min(
      Math.round(count / 1000) + 1,
      5 // Limit to 5000
    );

    const promises = [];
    for (let i = 0; i < numberOfCalls; i++) {
      promises.push(
        this.getPromise({
          skip: i * 1000,
          limit: 1000,
          order: ["id asc"],
        })
      );
    }

    try {
      const responses = await Promise.all(promises);
      let allData = [];
      responses.forEach((resp) => {
        let finalData = [];
        _.forEach(resp.data, (temp) => {
          finalData.push({
            id: temp.deptID,
            name: temp?.department?.name || "N/A",
            type: temp?.department?.type || "N/A",
            primary_flg: temp.primary_flg,
            location: temp?.department?.location || "N/A",
            email: temp?.department?.email || "N/A",
            deleteID: temp.id,
          });
        });
        finalData.sort((a, b) => (a.username > b.username ? 1 : -1));
        allData = [...allData, ...finalData];
      });
      if (allData.length > 0) {
        this.setState({
          ...this.state,
          exportData: allData,
        });
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent: e.response.data.error.message || "Please try again",
      });
    }
  };

  updateItem = (data, item) => {
    let index = data.findIndex(
      (p) => p === item || (item.id && p.id === item.id)
    );
    if (index >= 0) {
      data[index] = { ...item };
    }
  };

  setPrimaryDeptAction = async () => {
    const userID = this.props.dataItem.id;
    const deptID = this.state.openDataItem.id;

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    try {
      let filter = {
        filter: {
          where: {
            and: [
              {
                userID: userID,
              },
            ],
          },
        },
      };
      const getRecordsForPatch = await serverApi(
        "GET",
        "userToDepts",
        filter,
        "",
        options,
        ""
      );

      let otherDepartmentsForPatch = getRecordsForPatch?.data.filter(
        (x) => x.deptID !== deptID
      );
      //change only the previous primary department and not all the other because of reporting issue. (user to dept history - table for reports )
      otherDepartmentsForPatch = otherDepartmentsForPatch.filter(
        (x) => x.primary_flg === true
      );

      //set the previous primary department to false
      //eslint-disable-next-line
      for (let tempDep of otherDepartmentsForPatch) {
        await serverApi(
          "PATCH",
          `userToDepts/${tempDep.id}`,
          {},
          { primary_flg: false },
          options,
          ""
        );
      }

      //set new primary department
      const selectedPrimaryDept = getRecordsForPatch?.data.filter(
        (x) => x.deptID === deptID
      )[0];
      if (selectedPrimaryDept) {
        const savePrimaryFlag = await serverApi(
          "PATCH",
          `userToDepts/${selectedPrimaryDept.id}`,
          {},
          { primary_flg: true },
          options,
          ""
        );
        if (savePrimaryFlag) {
          this.setState(
            {
              ...this.state,
              openDataItem: {},
              openSuccessMessage: true,
              successMessage:
                "Set as primary department process finished successfully.",
            },
            () => {
              setTimeout(() => {
                this.setState({
                  ...this.state,
                  openDataItem: {},
                  openSuccessMessage: false,
                  successMessage: "",
                });
              }, 4000);
            }
          );

          this.fetchLatestData();
        }
      }
    } catch (e) {
      //todo show some error somehow
      this.setState({
        ...this.state,
        openDataItem: {},
      });
    }
  };

  update = async (dataItem) => {};

  enterEdit = (dataItem) => {
    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    let total = this.state.gridData.total;

    let finalData =
      this.state.gridData &&
      this.state.gridData.data.map((item) =>
        item.id === dataItem.id ? { ...item, inEdit: true } : item
      );

    this.setState({
      ...this.state,
      gridData: {
        data: finalData,
        total: total,
      },
    });
  };

  itemChange = (event) => {
    const total = this.state.gridData.total;
    const data =
      this.state.gridData &&
      this.state.gridData.data.map((item) =>
        item.id === event.dataItem.id
          ? { ...item, [event.field]: event.value }
          : item
      );

    this.setState({
      ...this.state,
      gridData: {
        data: data,
        total: total,
      },
    });
  };

  openPrimaryWindow = (e) => {
    this.setState(
      {
        ...this.state,
      },
      () => {
        setTimeout(() => {
          this.setPrimaryDeptAction();
        }, 500);
      }
    );
  };

  handleOnSelect = (e) => {
    switch (e.item.text) {
      case "Set as primary":
        this.openPrimaryWindow(e);
        break;
      default:
        break;
    }

    this.setState({
      open: false,
    });
  };

  handleContextMenuOpen = (e, dataItem) => {
    this.dataItem = dataItem;
    this.dataItemIndex = this.state.gridData.data.findIndex(
      (p) => p.id === this.dataItem.id
    );
    this.offset = { left: e.clientX, top: e.clientY };

    if (_.isEmpty(this.state.openDataItem)) {
      //todo handle two popup opened
      this.setState({
        open: true,
        openDataItem: dataItem,
      });
    } else {
      return;
    }
  };

  onFocusHandler = () => {
    clearTimeout(this.blurTimeoutRef);
    this.blurTimeoutRef = undefined;
  };

  onBlurTimeout = () => {
    this.setState({
      open: false,
      openDataItem: {},
    });

    this.blurTimeoutRef = undefined;
  };

  onPopupOpen = () => {
    this.menuWrapperRef.querySelector("[tabindex]").focus();
  };

  rowRender = (trElement, dataItem) => {
    const trProps = {
      ...trElement.props,
      onContextMenu: (e) => {
        e.preventDefault();
        this.handleContextMenuOpen(e, dataItem.dataItem);
      },
      style: {
        // color: 'orange'
      },
    };

    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  onBlurHandler = (event) => {
    clearTimeout(this.blurTimeoutRef);
    this.blurTimeoutRef = setTimeout(this.onBlurTimeout);
  };

  handleSuccessSnackbar = () => {
    this.setState({
      ...this.state,
      successMessage:
        this.state.openSuccessMessage === true ? this.state.successMessage : "",
      openSuccessMessage: !this.state.openSuccessMessage,
    });
  };

  saveNewDepartments = async () => {
    const finalUsers = [
      ...this.state.finalUserClients.filter((x) => x.checked === true),
    ];
    const initialUsers = this.state.initialFinalUserClients.filter(
      (x) => x.checked
    );
    const final = [...finalUsers, ...initialUsers];

    const uniqueData = final.filter(
      (thing, index, self) => index === self.findIndex((t) => t.id === thing.id)
    );

    const clonedUsers = _.cloneDeep(uniqueData);
    let departmentIDS = [];
    departmentIDS = clonedUsers.map((x) => x.id);

    let payload = {
      data: [],
    };
    payload.data.push({
      userID: this.props.dataItem.id,
      deptIDs: departmentIDS,
      primaryDeptID: this.state.primaryDeptID,
    });

    this.setState({
      ...this.state,
      blocking: true,
      blockingMessage: "Please wait a few seconds...",
    });

    const url = `userToDepts/bulkUpdate`;
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    await serverApi("POST", url, "", payload, options, "", "");
    await this.fetchLatestData();

    this.setState({
      ...this.state,
      blocking: false,
      blockingMessage: "",
      inputValue: "",
    });
  };

  fetchLatestData = async () => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    const userID = this.props.dataItem.id;
    let fil = {
      filter: {
        where: {
          userID: userID,
        },
        include: [
          {
            relation: "snUser",
            scope: {
              include: [
                {
                  relation: "userToDepts",
                },
              ],
            },
          },
          {
            relation: "department",
          },
        ],
      },
    };

    let usersOfDepartment = await serverApi(
      "GET",
      `userToDepts`,
      fil,
      "",
      options
    );
    let allDeps = await serverApi(
      "GET",
      `departments`,
      {
        filter: {
          include: [
            {
              relation: "users",
              scope: {
                include: [
                  {
                    relation: "userToDepts",
                  },
                ],
              },
            },
          ],
        },
      },
      "",
      options
    );
    let departments =
      allDeps.data
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((x) => x.name) || [];

    //REMOVES FROM checkbox lists the users having primary department the same as the expanded department
    // let expandedUsersWithPrimaryDepartment = (usersOfDepartment.data || []).filter(x => x.primary_flg === true)?.map(x=> x.department.name) || []
    // expandedUsersWithPrimaryDepartment.forEach(usr => {
    //   allDeps.data = allDeps.data.filter(x => x.name !== usr)
    // })

    let primaryDepartmentID = null;
    if (usersOfDepartment && usersOfDepartment?.data?.length > 0) {
      let primary =
        (usersOfDepartment.data || []).filter((x) => x.primary_flg === true) ||
        [];
      primaryDepartmentID = primary[0]?.deptID;
    }

    this.setState({
      ...this.state,
      primaryDeptID: primaryDepartmentID || null,
    });

    let result = this.constructUsersAndExistingUsersArray(
      allDeps.data,
      usersOfDepartment.data,
      userID
    );
    result[0].finalAttachedUsers.forEach((item) => {
      let user = result[0].finalUsers.find((x) => x.id === item.id);
      if (user) {
        user.checked = true;
      }
    });

    if (usersOfDepartment && usersOfDepartment.data.length > 1) {
      let finalData = [];
      _.forEach(usersOfDepartment.data, (temp) => {
        let finalName = temp?.department?.name;
        finalData.push({
          id: temp.deptID,
          name: finalName || "N/A",
          type: temp?.department?.type || "N/A",
          primary_flg: temp.primary_flg,
          location: temp?.department?.location || "N/A",
          email: temp?.department?.email || "N/A",
          deleteID: temp.id,
        });
      });
      finalData.sort((a, b) => (a.name > b.name ? 1 : -1));

      this.setState({
        ...this.state,
        userID: this.props.dataItem.id,
        gridData: {
          data: finalData,
          total: finalData.length,
        },
        initialGridData: {
          data: finalData,
          total: finalData.length,
        },
        departments: departments,
        valueUserName: "",

        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    } else if (usersOfDepartment && usersOfDepartment.data.length === 1) {
      let finalData = [];
      _.forEach(usersOfDepartment.data, (temp) => {
        finalData.push({
          id: temp.deptID,
          name: temp?.department?.name || "N/A",
          type: temp?.department?.type || "N/A",
          primary_flg: temp.primary_flg,
          location: temp?.department?.location || "N/A",
          email: temp?.department?.email || "N/A",
          deleteID: temp.id,
        });
      });
      finalData.sort((a, b) => (a.username > b.username ? 1 : -1));

      this.setState({
        ...this.state,
        userID: this.props.dataItem.id,
        gridData: {
          data: [finalData[0]],
          total: 1,
        },
        initialGridData: {
          data: [finalData[0]],
          total: 1,
        },
        departments: departments,
        valueUserName: "",
        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    } else {
      this.setState({
        ...this.state,
        userID: this.props.dataItem.id,
        gridData: {
          data: [],
          total: 0,
        },
        initialGridData: {
          data: [],
          total: 0,
        },
        departments: departments,
        valueUserName: "",
        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    }
  };

  render() {
    const dataItem = this.props.dataItem;
    const { gridData } = this.state;
    const { inputValue } = this.state;

    return (
      <div>
        <div>
          <h4 style={{ fontFamily: "bold", textSize: "7px" }}>
            Username: {dataItem && dataItem.username}
          </h4>
          {this.state.openErrorModal ? (
            <Error style={{ color: "red", fontSize: "15px" }}>
              {this.state.errorModalContent || ""}
            </Error>
          ) : null}
          {this.state.windowError ? (
            <Error style={{ color: "red" }}>
              <h3>{this.state.windowErrorMessage || ""}</h3>
            </Error>
          ) : null}
        </div>

        <Popup
          offset={this.offset}
          show={this.state.open}
          open={this.onPopupOpen}
          popupClass={"popup-content"}
        >
          <div
            onFocus={this.onFocusHandler}
            onBlur={this.onBlurHandler}
            tabIndex={-1}
            ref={(el) => (this.menuWrapperRef = el)}
          >
            <Menu
              vertical={true}
              style={{ display: "inline-block" }}
              onSelect={this.handleOnSelect}
            >
              <KendoMenuItem text="Set as primary" />
            </Menu>
          </div>
        </Popup>

        <Grid
          {...(gridData || [])}
          filterable={false}
          style={{ className: "grid-no-select" }}
          sortable={true}
          resizable
          rowRender={this.rowRender}
          editField={this.editField}
          onItemChange={this.itemChange}
          pageable={false}
        >
          <GridToolbar>
            <GridContainer xs={12} justify={"flex-start"} direction={"row"}>
              <Tooltip
                justify={"flex-start"}
                placement="top"
                title={"Add/Remove Department"}
              >
                <IconButton onClick={this.toggleWindow}>
                  <FontAwesomeIcon
                    color="#0D5869"
                    // onClick={this.toggleWindow}
                    size="1.6x"
                    icon={faPencilAlt}
                  />
                </IconButton>
              </Tooltip>
              <ViewSettings
                {...this.props}
                export={{
                  data: this.state.exportData || [],
                  fileName: "User_Departments_",
                  exportFunction: this.getExportData,
                  columns: columnsSchema(),
                }}
                viewSettings={{
                  type: "",
                }}
                // refreshSettings={true}
                // refreshFunction={this.refreshGridDataAfterCrud}
              />
            </GridContainer>
          </GridToolbar>

          {/* <Column cell={this.CommandCell} title="Actions" filterable={false} width="250px" /> */}
          {this.renderGridColumns(dataItem)}
        </Grid>

        <Snackbar
          place="bc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"success"}
          message={this.state.successMessage}
          open={this.state.openSuccessMessage}
          closeNotification={() =>
            this.handleSuccessSnackbar(this.state.successMessage)
          }
          close
        />

        {this.state.visibleWindow && (
          <Window
            title={`Add/Remove Departments from user: ${dataItem.username}`}
            // style={{backgroundColor:'#0d5869'}}
            onClose={this.toggleWindow}
            width={900}
            height={600}
            initialTop={110}
          >
            <div>
              <>
                <BlockUi
                  tag="div"
                  blocking={this.state.blocking}
                  message={this.state.blockingMessage}
                  renderChildren={true}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "50px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          position: "sticky",
                          top: "0px",
                          zIndex: 2,
                          backgroundColor: "ffff",
                        }}
                      >
                        <RegularButton
                          style={{ backgroundColor: "#0d5869" }}
                          roundSmall="true"
                          onClick={this.saveNewDepartments}
                          size="sm"
                          // disabled={this.state.disableSaveNewDepartments || false}
                        >
                          Save changes
                        </RegularButton>
                      </div>
                      {this.state.windowError ? (
                        <Error style={{ color: "red" }}>
                          <h3>{this.state.windowErrorMessage || ""}</h3>
                        </Error>
                      ) : null}
                      {this.state.openErrorModal ? (
                        <Error style={{ color: "red", fontSize: "15px" }}>
                          {this.state.errorModalContent || ""}
                        </Error>
                      ) : null}
                      <div
                        style={{
                          display: "flex",
                          marginLeft: "35px",
                          marginTop: "20px",
                        }}
                      >
                        <Input
                          name="users"
                          value={inputValue}
                          style={{ marginBottom: "20px" }}
                          label="Search department"
                          onChange={(e) => this.handleSearch(e)}
                          minLength={2}
                        />
                      </div>

                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {this.renderList(
                          this.state.finalUserClients,
                          this.state.visibleClients
                        )}
                      </div>
                    </div>
                  </div>
                </BlockUi>
              </>
            </div>
          </Window>
        )}
      </div>
    );
  }
}
