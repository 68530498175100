import React from "react";
import { Grid, GridToolbar } from "@progress/kendo-react-grid";
import GridContainer from "../../../components/Grid/GridContainer";
import { IconButton, Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser, faPlus } from "@fortawesome/free-solid-svg-icons";
import { GridColumn as Column } from "@progress/kendo-react-grid/dist/npm/GridColumn";
import { GridLoader } from "../../common/loader";
import Snackbar from "../../../components/Snackbar/Snackbar";
import { columnsSchema } from "../View/schema";
import { MyCommandCell } from "../../common/commandCellHOC";
import _ from "lodash";
import { DropDownCell } from "../../CRM/View/dropDownCell";
import { serverApi } from "../../../networking/config";
import { AbstractEdit } from "../../../networking/apiCalls";
import ExpandedUsers from "./components";
import SuccessModal from "../../../components/SuccessModal";
import ExpandedTabs from "../../common/ExpandedTabs";
import { formatDatesOnTable } from "../../../lib/GeneralUtils/utils";

class Roles extends React.Component {
  editField = "inEdit";
  CommandCell;

  constructor(props) {
    super(props);

    this.state = {
      visibleWindow: false,
      isDropOpened: false,
      columns: columnsSchema && columnsSchema(),
      gridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 100,
        skip: 0,
      },
      openErrorModal: false,
      errorModalContent: "",
      visible: false,
      expandedRowIndex: null,
      selectedRowIndex: 0,
      selectedItem: null,
      delay: 400,
      loading: false,
      open: false,
      emailHasError: false,
      username: "",
      userRealName: "",
      email: "",
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      hasDisable: false,
      showSecondAction: false,
    });
  }

  componentDidMount() {
    let isFirstLoad = true;
    window.localStorage.setItem("isFirstLoad", isFirstLoad);

    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));

    if (hasFinalParams) {
      this.setState({
        ...this.state,
        dataState: hasFinalParams,
      });
    }

    if (hasChangedColumns) {
      this.setState({
        ...this.state,
        columns: hasChangedColumns,
      });
    }
  }

  componentWillUnmount() {
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));
    if (hasChangedColumns) {
      window.localStorage.removeItem("cols");
    }

    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    if (hasFinalParams) {
      window.localStorage.removeItem("finalParams");
    }

    let hasFlagEnabled = window.localStorage.getItem("flagSet");
    if (hasFlagEnabled) {
      window.localStorage.removeItem("flagSet");
    }

    let hasExportFilter = window.localStorage.getItem("exportFilter");
    if (hasExportFilter) {
      window.localStorage.removeItem("exportFilter");
    }
  }

  renderGridColumns = () => {
    let selectionCol = [];
    let normalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            width="65px"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.isFilterBoolean === "yes") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              cell={DropDownCell}
            />
          );
        } else {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={temp.field === "id" || temp.editable ? false : true}
              // editable={temp.field === 'id' ? false : true} //id not editable on edit

              // cell={() =>
              //     <td>
              //         <a rel="noopener noreferrer" href="https://p.2hog.codes/" target="_blank">Link Here</a>
              //     </td>
              // }
            />
          );
        }
      });

    return [...selectionCol, ...normalCols];
  };

  dataStateChange = (e) => {
    const reqGridParams = e.data;
    window.localStorage.setItem("finalParams", JSON.stringify(reqGridParams));

    this.setState({
      ...this.state,
      dataState: reqGridParams,
    });
  };

  dataRecieved = (gridData, reqGridParams) => {
    const finalData = formatDatesOnTable(gridData, [
      "last_login_date",
      "last_password_change",
      "created",
      "lastUpdated",
      "termination_date",
      "modified",
    ]);

    this.setState({
      ...this.state,
      gridData: finalData,
      initialGridData: finalData,
      dataState:
        reqGridParams === !_.isEmpty(reqGridParams)
          ? reqGridParams
          : this.state.dataState,
    });
  };

  onClearFilters = () => {
    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));

    let init = JSON.stringify({ take: 100, skip: 0 });
    if (hasFinalParams && hasFinalParams.filter)
      window.localStorage.setItem("finalParams", init);

    this.dataStateChange({ data: { take: 100, skip: 0 } });
  };

  handleSuccessSnackbar = () => {
    this.setState({
      ...this.state,
      successMessage:
        this.state.openSuccessMessage === true ? this.state.successMessage : "",
      openSuccessMessage: this.state.openSuccessMessage === true ? true : false,
    });
  };

  enterEdit = (dataItem) => {
    let total = this.state.gridData.total;

    this.setState({
      gridData: {
        data:
          this.state.gridData &&
          this.state.gridData.data.map((item) =>
            item.id === dataItem.id ? { ...item, inEdit: true } : item
          ),
        total: total,
      },
      initialGridData: {
        data:
          this.state.initialGridData &&
          this.state.initialGridData.data.map((item) =>
            item.id === dataItem.id ? { ...item, inEdit: true } : item
          ),
        total: total,
      },
    });
  };

  remove = async (dataItem) => {};

  add = (dataItem) => {
    dataItem.inEdit = undefined;

    const submitURL = "snRoles";
    let count;
    const options = { token: window.localStorage.getItem("access_token") };
    let payload = dataItem;
    delete payload.inEdit;

    if (payload.name === undefined || payload.description === undefined) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            "Error: Required fields to create a new role: Name and Description.",
        },
        () => {
          setTimeout(() => {
            this.cancel(dataItem);
          }, 4000);
        }
      );
    } else {
      const createRequest = serverApi(
        "POST",
        `${submitURL}`,
        "",
        payload,
        options
      );
      createRequest
        .then(async (createResponse) => {
          const responseData = await serverApi(
            "GET",
            `snRoles?filter={"include":[],"where":{"and":[{}]},"limit":"100","skip":"0"}`,
            {},
            "",
            options
          );
          const countRoles = await serverApi(
            "GET",
            "snRoles/count",
            "",
            "",
            options
          );
          if (countRoles) {
            count = countRoles.data.count;
          }

          this.setState(
            {
              ...this.state,
              gridData: {
                data: responseData.data,
                total: count === undefined ? responseData.data.length : count,
              },
              initialGridData: {
                data: responseData.data,
                total: count === undefined ? responseData.data.length : count,
              },
              deleteVisible: false,
              successMessage: "The role was successfully created.",
              openSuccessMessage: true,
            },
            () => {
              setTimeout(() => {
                this.handleSuccessClose();
              }, 3000);
            }
          );
        })
        .catch((createError) => {
          this.setState(
            {
              ...this.state,
              openErrorModal: true,
              errorModalContent:
                (createError.response.data &&
                  createError.response.data.error.message) ||
                "Please try again",
            },
            () => this.discard(dataItem, true)
          );
        });
    }
  };

  discard = (dataItem, hasError = false) => {
    let data = [...this.state.gridData.data];
    const total = this.state.gridData.total;

    if (hasError) {
      let index = data.findIndex((p) => p.id === dataItem.id);

      data[index].inEdit = false;

      this.setState(
        {
          ...this.state,
          gridData: {
            data: data,
            total: total,
          },
        },
        () => {
          setTimeout(() => {
            this.setState({
              ...this.state,
              openErrorModal: false,
              errorModalContent: "",
            });
          }, 3000);
        }
      );
    } else {
      this.removeItem(data, dataItem);

      this.setState({
        ...this.state,
        gridData: {
          data: data,
          total: total,
        },
        visible: false,
      });
    }
  };

  update = async (dataItem) => {
    const data = [...this.state.gridData.data];
    const updatedItem = { ...dataItem, inEdit: undefined };
    this.updateItem(data, updatedItem);

    let editPayload = updatedItem;

    if (
      editPayload.name === "" ||
      editPayload.name === undefined ||
      editPayload.description === "" ||
      editPayload.description === undefined
    ) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            "Error: Required fields to edit the role: Name and Description.",
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 3500);
        }
      );
    } else {
      try {
        delete editPayload.inEdit;
        delete editPayload.expanded;

        const editRequest = await AbstractEdit(
          dataItem.id,
          editPayload,
          "snRoles",
          {
            token: window.localStorage.getItem("access_token"),
          }
        );
        const countRequest = await serverApi("GET", "snRoles/count", "", "", {
          token: window.localStorage.getItem("access_token"),
        });

        if (editRequest.status === 200 && countRequest) {
          this.setState(
            {
              ...this.state,
              gridData: {
                data: data,
                total: countRequest.data.count,
              },
              deleteVisible: false,
              successMessage: "The role was successfully updated.",
              openSuccessMessage: true,
            },
            () => {
              setTimeout(() => {
                this.handleSuccessClose();
              }, 3500);
            }
          );
        }
      } catch (e) {
        this.setState(
          {
            ...this.state,
            openErrorModal: true,
            errorModalContent:
              (e.response.data && e.response.data.error.message) ||
              "Please try again",
          },
          () => this.discard(dataItem, true)
        );
      }
    }
  };

  cancel = (dataItem = {}) => {
    if (dataItem.name === undefined || dataItem.description === undefined) {
      const data =
        this.state.initialGridData && this.state.initialGridData.data;
      const total = this.state.initialGridData.total;

      this.setState({
        ...this.state,
        gridData: {
          data: data,
          total: total,
        },
        errorModalContent:
          this.state.errorModalContent !== ""
            ? ""
            : this.state.errorModalContent,
        openErrorModal:
          this.state.openErrorModal === true
            ? false
            : this.state.errorModalContent,
      });
    } else {
      const originalItem =
        this.state.initialGridData &&
        this.state.initialGridData.data.find((p) => p.id === dataItem.id);
      originalItem.inEdit = false;

      const data =
        this.state.initialGridData &&
        this.state.initialGridData.data.map((item) =>
          item.id === originalItem.id ? originalItem : item
        );

      const total = this.state.initialGridData.total;

      this.setState({
        ...this.state,
        gridData: {
          data: data,
          total: total,
        },
      });
    }
  };

  updateItem = (data, item) => {
    let index = data.findIndex(
      (p) => p === item || (item.id && p.id === item.id)
    );
    if (index >= 0) {
      data[index] = { ...item };
    }
  };

  itemChange = (event) => {
    const total = this.state.gridData.total;
    const data =
      this.state.gridData &&
      this.state.gridData.data.map((item) =>
        item.id === event.dataItem.id
          ? { ...item, [event.field]: event.value }
          : item
      );

    this.setState({
      gridData: {
        data: data,
        total: total,
      },
    });
  };

  addNew = () => {
    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    const newDataItem = { inEdit: true };

    this.setState({
      gridData: {
        data: [newDataItem, ...this.state.gridData.data],
        total: this.state.gridData.total,
      },
      visible: true,
    });
  };

  cancelCurrentChanges = () => {
    this.setState({
      ...this.state,
      gridData: this.state.initialGridData,
    });
  };

  removeItem(data, item) {
    let index = data.findIndex(
      (p) => p === item || (item.id && p.id === item.id)
    );
    if (index >= 0) {
      data.splice(index, 1);
    }
  }

  onClearFilters = () => {
    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));

    let init = JSON.stringify({ take: 100, skip: 0 });
    if (hasFinalParams && hasFinalParams.filter)
      window.localStorage.setItem("finalParams", init);

    this.dataStateChange({ data: { take: 100, skip: 0 } });
  };

  expandChange = (event) => {
    window.localStorage.setItem("expandeId", event.dataItem.id);

    if (this.state.gridData.data[0].inEdit === true) {
      return;
    } else {
      event.dataItem.expanded = !event.dataItem.expanded;
    }

    this.forceUpdate();
  };

  closeErrorModal = () => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      errorModalContent: "",
    });
  };

  handleSubmit(event) {
    event.preventDefault();
  }

  handleErrorClose = () => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      openErrorModalContent: "",
    });
  };

  handleSuccessClose = () => {
    this.setState({
      ...this.state,
      openSuccessMessage: false,
      successMessage: "",
    });
  };

  rowClick = (event) => {
    // this.expandChange(event)
    // window.localStorage.setItem('expandeId', event.dataItem.id)
    //
    // //if add new vessel is true then expand cannot be done !
    // if (this.state.gridData.data[0].inEdit === true) {
    //     return
    // } else {
    //     event.dataItem.expanded = !event.dataItem.expanded;
    // }
    //
    // this.forceUpdate();
  };

  closeSuccessModal = () => {
    this.setState({ openSuccessModal: false, successModalContent: "" });
  };

  openSuccessModal = (message) => {
    this.setState({ openSuccessModal: true, successModalContent: message });
  };

  render() {
    const { gridData } = this.state;
    const hasEditedItem = gridData && gridData.data.some((p) => p.inEdit);

    return (
      <div>
        <Grid
          {...this.state.dataState}
          {...this.state.gridData}
          filterable={true}
          sortable={true}
          resizable
          // onRowClick={e => this.rowClick(e)}
          style={{ height: "92vh" }}
          editField={this.editField}
          // detail={ExpandedUsers}
          onItemChange={this.itemChange}
          onDataStateChange={this.dataStateChange}
          expandField="expanded"
          onExpandChange={this.expandChange}
          detail={(dataItem) => (
            <div>
              <ExpandedTabs
                tabs={[
                  {
                    label: "Users To Role",
                    component: (
                      <ExpandedUsers
                        openSuccessModal={(message) =>
                          this.openSuccessModal(message)
                        }
                        {...dataItem}
                      />
                    ),
                  },
                ]}
              />
            </div>
          )}
          pageable={{
            buttonCount: 5,
            info: true,
            pageSizes: [100, 200, 500, 1000, 1500],
          }}
        >
          <GridToolbar>
            <GridContainer xs={12} direction={"row"}>
              <Tooltip
                justify={"flex-start"}
                placement="top"
                title={"Add New Role"}
              >
                <IconButton onClick={this.addNew}>
                  <FontAwesomeIcon
                    color="#0D5869"
                    // onClick={this.toggleWindow}
                    size="1.6x"
                    icon={faPlus}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip
                justify={"flex-start"}
                placement="top"
                title={"Clear Filters"}
              >
                <IconButton
                  disabled={false}
                  aria-label=""
                  onClick={(e) => this.onClearFilters(e)}
                  look="flat"
                >
                  <FontAwesomeIcon
                    color="#0D5869"
                    size="1.6x"
                    icon={faEraser}
                  />
                </IconButton>
              </Tooltip>
            </GridContainer>
          </GridToolbar>

          <Column
            cell={this.CommandCell}
            title="Actions"
            filterable={false}
            width={hasEditedItem ? "240px" : "140px"}
          />

          {this.renderGridColumns()}
        </Grid>

        <GridLoader
          dataState={this.state.dataState}
          onDataRecieved={this.dataRecieved}
          getUrl={"snRoles"}
          include={[]}
        />

        <SuccessModal
          open={this.state.openSuccessModal}
          onCloseModal={this.closeSuccessModal}
          modalContent={this.state.successModalContent}
          timeout={4000}
        />

        <Snackbar
          place="tc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"warning"}
          message={this.state.errorModalContent}
          open={this.state.openErrorModal}
          closeNotification={() =>
            this.handleErrorClose(this.state.errorModalContent)
          }
          // close
        />

        <Snackbar
          place="tc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"success"}
          message={this.state.successMessage}
          open={this.state.openSuccessMessage}
          closeNotification={() => this.handleSuccessClose()}
          close
        />
      </div>
    );
  }
}

export default Roles;
