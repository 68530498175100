import { connect } from "react-redux";
import compose from "ramda/src/compose";
import ApprovalActionTiers from "./View";

const mapActionCreators = {};

const mapStateToProps = (state) => {
  return {
    user: state.MainLayoutReducer.user,
    hasBackofficeRead: false,
  };
};

export default compose(connect(mapStateToProps, mapActionCreators))(
  ApprovalActionTiers
);
