import {serverApi} from '../config'

export const AbstractDelete = (url, id, options) => {
    try {

        let final = ''
        if (id !== '') {
            final = `${url}/${id}`
        } else {
            final = `${url}`
        }

        const response = serverApi('DELETE', final, '', '', options)

        return response;
    } catch (error) {
        console.log(error)
    }
}
