export const columnsSchema = () => [
  {
    field: "id",
    title: "ID",
    visible: true,
    minWidth: 140,
    sortable: true,
    filterable: true,
    locked: "locked",
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
  },
  {
    field: "username",
    title: "Username",
    visible: true,
    minWidth: 240,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    locked: "locked",
  },
  {
    field: "userRealName",
    title: "Name",
    visible: true,
    minWidth: 260,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    locked: "locked",
  },
  {
    field: "email",
    title: "Email",
    visible: true,
    minWidth: 270,
    sortable: true,
    filterable: true,
    locked: "locked",
  },
  // {
  //   field: "last_login_date",
  //   title: "Last login",
  //   visible: true,
  //   minWidth: 220,
  //   sortable: true,
  //   filterable: false,
  //   locked: "locked",
  //   editable: "false",
  // },
];
