import React from "react";
import { schema as columnsSchema } from "./schema";
import { MyCommandCell } from '../../CRM/View/myCommandCell';
import { Loader } from "./loader";
import { DropDownCell } from '../../CRM/View/dropDownCell.js';
import { serverApi } from "../../../networking/config";
import {  IconButton,Tooltip } from "@material-ui/core";
import { Grid, GridColumn as Column,  GridToolbar } from '@progress/kendo-react-grid';
import GridContainer from "../../../components/Grid/GridContainer";
import _ from 'lodash';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import ViewSettings from "../../common/ViewSettings";
import {formatDatesOnTable} from "../../../lib/GeneralUtils/utils"

class BackofficeLogsView extends React.Component {
  editField = "inEdit";
  CommandCell;

  constructor(props) {
    super(props);

    this.state = {
      deleteVisible: false,
      visibleWindow: false,
      isDropOpened: false,
      columns: (columnsSchema && columnsSchema()) || [],
      gridData: {
        data: [], total: 0
      },
      initialGridData: {
        data: [], total: 0
      },
      dataState: {
        take: 100, skip: 0
      },
      openErrorModal: false,
      errorModalContent: '',
      delay: 400,
      loading: false,
      selectedRowIndex: 0,
      selectedItem: null,
      googleOptions: [],
      expandedRowIndex: null,
    };


    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: () => { },
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      hasDisable: false,
      showSecondAction: false,
    });
  }

  componentDidMount() {

    let isFirstLoad = true
    window.localStorage.setItem('isFirstLoad', isFirstLoad)

    let hasFinalParams = JSON.parse(window.localStorage.getItem('finalParams'))
    let hasChangedColumns = JSON.parse(window.localStorage.getItem('cols'))

    if (hasFinalParams) {
      this.setState({
        ...this.state,
        dataState: hasFinalParams
      })
    }

    if (hasChangedColumns) {
      this.setState({
        ...this.state,
        columns: hasChangedColumns
      })
    }

  }

  componentWillUnmount() {
    let hasChangedColumns = JSON.parse(window.localStorage.getItem('cols'))
    let finalParams = JSON.parse(window.localStorage.getItem('finalParams'))
    let hasExpandedId = JSON.parse(window.localStorage.getItem('expandeId'))
    let hasExportFilter = window.localStorage.getItem('exportFilter')

    if (hasChangedColumns) {
      window.localStorage.removeItem('cols')
    }
    if (finalParams) {
      window.localStorage.removeItem('finalParams')
    }
    if (hasExpandedId) {
      window.localStorage.removeItem('expandeId')
    }
    if (hasExportFilter) {
      window.localStorage.removeItem('exportFilter')
    }
  }

  dataStateChange = (e) => {
    const reqGridParams = e.data;
    window.localStorage.setItem('finalParams', JSON.stringify(reqGridParams))

    this.setState({
      ...this.state,
      dataState: reqGridParams
    })

  }

  dataReceived = (gridData, reqGridParams) => {
    const finalData = formatDatesOnTable(gridData, ["updated"]);

    this.setState({
      ...this.state,
      gridData: finalData,
      initialGridData: finalData,
      dataState: reqGridParams === !_.isEmpty(reqGridParams) ? reqGridParams : this.state.dataState   //TODO MAYBE PASS THE final params from local storage for persist the filters on REFRESH PAGE !
    });
  }

  renderGridColumns = () => {

    let selectionCol = []
    let normalCols = []

    _.forEach(this.state.columns, col => {
      if (col.field === "selected") {

        selectionCol.push([
          <Column
            field="selected"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(dataItem => dataItem.selected === false) === -1
            }
          />
        ])
      }
    })

    normalCols = this.state.columns.filter(temp1 => temp1.visible).map(temp => {

      if (temp.title === "Actions") {
        return null
      }

      if (temp.isFilterBoolean === "yes") {
        return (
          <Column
            field={temp.field}
            filterable={temp.filterable}
            title={temp.title}
            width={temp.minWidth || 'auto'}
            filter={temp.filter}
            visible={temp.visible}
            minGridWidth={"400"}
            cell={DropDownCell}
          />
        )
      } else {

        return (
          <Column
            field={temp.field}
            filterable={temp.filterable}
            title={temp.title}
            width={temp.minWidth || 'auto'}
            filter={temp.filter}
            visible={temp.visible}
            minGridWidth={"400"}
            editable={temp.field === 'id' ? false : true} //id not editable on edit
          />
        );
      }


    })

    return [...selectionCol, ...normalCols]

  };

  constructFinalParams = () => {
    let booleanColumns = ["supplier_flag", "client_flag", "transport_flag", "agent_flag", "sm_agent_flag", "sm_vendor_flag", "location_flag",
      "airport_flag", "wh_flag", "seaport_flag", "stocktbc_visible", "verified_flag"]

    let filterParams = JSON.parse(window.localStorage.getItem('finalParams'))
    let whereFinal = []

    if (filterParams === null) {
      whereFinal.push({})
    } else {
      if (filterParams.filter === null || filterParams.filter === undefined) {
        whereFinal.push({})
      } else {
        if (filterParams.filter.filters.length > 1) {
          _.forEach(filterParams.filter.filters, (temp) => {
            if (booleanColumns.includes(temp.field)) {
              whereFinal.push(
                {
                  [`${temp.field}`]: `${temp.value}`,
                }
              )
            } else {
              whereFinal.push(
                {
                  [`${temp.field}`]: {
                    "regexp": `/^${temp.value}/i`
                  }
                }
              )
            }
          })

        } else {
          if (booleanColumns.includes(filterParams.filter.filters[0].field)) {
            whereFinal.push(
              {
                [`${filterParams.filter.filters[0].field}`]: `${filterParams.filter.filters[0].value}`,
              }
            )
          } else {
            whereFinal = [{
              [`${filterParams && filterParams.filter.filters[0].field}`]: {
                "regexp": `/^${filterParams && filterParams.filter.filters[0].value}/i`
              }
            }]
          }

        }
      }
    }

    let params = {
      filter: {
        include: [],
        where: {
          and: whereFinal
        },
        order: [],
        limit: `100`,
        skip: `0`
      },
    };

    if (params.filter.order[0] === 'undefined' || (_.isEmpty((params.filter.order)))) {
      delete params.filter.order;
    }

    return params

  }

  urlAndParams = (final = {}, url1, url2) => {
    let finalUrlParams = {}
    if (_.isEmpty(final)) {
      finalUrlParams = {
        url: url1,
        param: ''
      }
    } else {
      finalUrlParams = {
        url: url2,
        param: final
      }
    }

    return finalUrlParams
  }

  refreshGridDataAfterCrud = async () => {

    let token = window.localStorage.getItem('access_token')
    let options = {
      token: token
    };
    let refreshParams = window.localStorage.getItem('refreshParams')
    refreshParams = JSON.parse(refreshParams)

    //TODO move those 2 func as general utils
    let final = {}
    final = this.constructFinalParams()
    let finalUrlParams = this.urlAndParams(final,
      `logBos?filter={"include":[],"where":${JSON.stringify(refreshParams.filter.where)},"limit":"100","skip":"0"}`,
      `logBos?filter={"include":[],"where":${JSON.stringify(refreshParams.filter.where)},"limit":"100","skip":"0"}`
    )

    delete finalUrlParams.param.filter.include

    const responseData = await serverApi('GET', finalUrlParams.url, '', '', options);
    let count
    const countMasterEntity = await serverApi('GET', `logBos/count?where=${JSON.stringify(refreshParams.filter.where)}`, '', '', options)
    if (countMasterEntity) {
      count = countMasterEntity.data.count
    }

    let sendObj = {
      gridData: {
        data: responseData.data,
        total: count
      }
    }

    this.dataReceived(sendObj.gridData, null)

  }

  onClearFilters = () => {
    let hasFinalParams = JSON.parse(window.localStorage.getItem('finalParams'))

    let init = JSON.stringify({ take: 100, skip: 0 })
    if (hasFinalParams && hasFinalParams.filter)
      window.localStorage.setItem('finalParams', init)

    this.dataStateChange({ data: { take: 100, skip: 0 } })
  }

  getExportData = async () => {
    await this.exportToCsv(this.state, this.props)
  }

  exportToCsv = async (state, props) => {
    const count = state.gridData.total
    const where = window.localStorage.getItem('exportFilter') || {}
    const numberOfCalls = Math.min(
      Math.round(count / 1000) + 1,
      5 // Limit to 5000
    );

    const promises = [];
    for (let i = 0; i < numberOfCalls; i++) {
      promises.push(
        this.getPromise(
          {
            skip: i * 1000,
            limit: 1000,
            where: !_.isEmpty(where) ? JSON.parse(where) : {},
            order: ['id asc'],
          }
        )
      );
    }

    try {

      const responses = await Promise.all(promises);
      let allData = [];
      responses.forEach(resp => {
        allData = [...allData, ...resp.data]
      });
      if (allData.length > 0) {
        this.setState({
          ...this.state,
          exportData: allData
        })
        // this._export.save(allData, this.state.columns);
      }

    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent: e?.response?.data?.error?.message || "Please try again"

      })
    }
  };

  getPromise = (filter) => {
    let options = {
      token: window.localStorage.getItem('access_token')
    };
    return new Promise((resolve, reject) => {
      const masterEntityRequest = serverApi('GET', `logBos?filter=${JSON.stringify(filter)}`, '', '', options)
      masterEntityRequest
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    });
  };


  render() {
    const { gridData, dataState} = this.state;

    let checkForEditDato = this.state.gridData.data
    const found = checkForEditDato.some(item => item.inEdit === true);
    let hasColumnFilters = true
    if (found) {
      hasColumnFilters = false
    }

    return (
      <div>
        <Grid
          {...dataState}
          {...gridData}
          filterable={hasColumnFilters}
          style={{ height: '92vh' }}
          sortable={true}
          resizable
          editField={this.editField}
          onItemChange={this.itemChange}
          onDataStateChange={this.dataStateChange}
          expandField="expanded"
          onExpandChange={this.expandChange}
          pageable={{
            buttonCount: 5,
            info: true,
            pageSizes: [100, 200, 500, 1000, 1500],
          }}
        >
          <GridToolbar>
            <GridContainer xs={12} direction={"row"}>
              <Tooltip justify={'flex-end'} placement="top" title={"Clear Column Filters"}>
                <IconButton
                  disabled={false}
                  aria-label=""
                  onClick={e => this.onClearFilters(e)} look="flat"
                >
                  <FontAwesomeIcon
                    color="#0D5869"
                    size="1.6x" icon={faEraser} />
                </IconButton>
              </Tooltip>
              <Tooltip justify={'flex-start'} placement="top" title={'Refresh Data'}>
                <IconButton
                  disabled={false}
                  aria-label=""
                  onClick={this.refreshGridDataAfterCrud}
                >
                  <FontAwesomeIcon color="#0D5869" size="1x" icon={faSyncAlt} />
                </IconButton>
              </Tooltip>
              <ViewSettings
                {...this.props}
                export={{
                  data: this.state.exportData || [],
                  exportFunction: this.getExportData,
                  fileName: 'Backoffice_Logs_',
                  columns: columnsSchema(),
                }}
                viewSettings={{
                  type: '',
                }}
                refreshSettings={{
                  data: [],
                }}
              />
            </GridContainer>
          </GridToolbar>

          {this.renderGridColumns()}

        </Grid>

        <Loader
          dataState={this.state.dataState}
          onDataRecieved={this.dataReceived}
          include={[]}
          getURL={'logBos'}
          countURL={'logBos/count'}
        />

      </div>
    )
  }

}

export default BackofficeLogsView;