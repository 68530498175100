import React from "react";
import { MyCommandCell } from "../../../Clients/View/components/CellHOC";
import { serverApi } from "../../../../networking/config";
import _ from "lodash";
import { GridColumn as Column } from "@progress/kendo-react-grid/dist/npm/GridColumn";
import { Grid, GridToolbar } from "@progress/kendo-react-grid";
import GridContainer from "../../../../components/Grid/GridContainer";
import { IconButton, Tooltip } from "@material-ui/core";
import { DropDownCell } from "../../../CRM/View/dropDownCell";
import { AutoComplete2 } from "../../../Vessels/View/components/relationNameDropDown";
import { Window } from "@progress/kendo-react-dialogs";
import BlockUi from "react-block-ui";
import { Error } from "@progress/kendo-react-labels";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import ViewSettings from "../../../common/ViewSettings";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const columnsSchema = () => [
  {
    field: "id",
    title: "ID",
    visible: true,
    minWidth: 190,
    sortable: true,
    filterable: true,
    locked: "locked",
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
    // cell: true
  },
  {
    field: "relation_name",
    title: "NAME",
    visible: true,
    minWidth: 350,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    locked: "locked",
  },
  {
    field: "relation_address",
    title: "ADDRESS",
    visible: true,
    minWidth: 480,
    sortable: true,
    filterable: true,
    noDisabledColumn: false,
    notShownInTableColumnSelection: false,
    locked: "locked",
  },
  {
    field: "relation_postal_code",
    title: "POSTAL",
    visible: true,
    minWidth: 200,
    sortable: true,
    filterable: true,
    locked: "locked",
  },
  {
    field: "relation_country",
    title: "COUNTRY",
    visible: true,
    minWidth: 280,
    sortable: true,
    filterable: true,
    locked: "locked",
  },
  {
    field: "relation_city",
    title: "CITY",
    visible: true,
    minWidth: 320,
    sortable: true,
    filterable: true,
    locked: "locked",
    notShownInTableColumnSelection: false,
  },
  {
    field: "relation_mail",
    title: "Email",
    visible: true,
    sortable: true,
    minWidth: 260,
    filterable: true,
    locked: "locked",
  },
];

export default class UserToClient extends React.Component {
  editField = "inEdit";
  CommandCell;
  _export;

  constructor(props) {
    super(props);

    this.state = {
      visibleWindow: false,
      isDropOpened: false,
      columns: (columnsSchema && columnsSchema()) || [],
      gridData: {
        data: [],
        total: 10,
      },
      initialGridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 10,
        skip: 0,
      },
      openErrorModal: false,
      errorModalContent: "",
      delay: 400,
      loading: false,
      usernames: [],
      windowError: false,
      windowErrorMessage: "",
      disableEditButton: true,
      inputValue: "",
      clientAttachedToAnotherGroup: null,
      exportData: [],
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      disableEditButton: false,
      showSecondAction: true,
      showFirstAction: false,
    });
  }

  async componentDidMount() {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    const userID = this.props.dataItem.id;
    let fil = {
      filter: {
        where: {
          userID: userID,
        },
        include: ["masterEntity"],
      },
    };

    let clientsOfMasterEntity = await serverApi(
      "GET",
      `userToClients`,
      fil,
      "",
      options
    );

    let allClientRecords = {
      data: [],
    };

    let howMany = await serverApi(
      "GET",
      `masterEntities/count`,
      {
        where: {
          and: [
            {
              client_flag: true,
            },
          ],
        },
      },
      "",
      options
    );
    const numberOfCalls = Math.min(
      Math.round(howMany.data.count / 1000) + 1,
      5 // Limit to 5000
    );

    const promises = [];
    for (let i = 0; i < numberOfCalls; i++) {
      promises.push(
        this.getClientPromise({
          skip: i * 1000,
          limit: 1000,
          order: ["id asc"],
          where: {
            and: [
              {
                client_flag: true,
              },
            ],
          },
        })
      );
    }

    try {
      let responses = await Promise.all(promises);
      responses.length && responses.map((x) => x.data).flat();

      responses.forEach((resp) => {
        _.forEach(resp.data, (temp) => {
          allClientRecords.data.push({
            ...temp,
          });
        });
      });
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent: e.response.data.error.message || "Please try again",
      });
    }

    let clientNames =
      allClientRecords.data
        .sort((a, b) => (a.relation_name > b.relation_name ? 1 : -1))
        .map((x) => x.relation_name) || [];

    let result = this.constructUsersAndExistingUsersArray(
      allClientRecords.data,
      clientsOfMasterEntity.data
    );
    result[0].finalAttachedUsers.forEach((item) => {
      let user = result[0].finalUsers.find((x) => x.name === item.name);
      if (user) {
        user.checked = true;
      }
    });

    if (clientsOfMasterEntity && clientsOfMasterEntity.data.length > 1) {
      let finalData = [];
      _.forEach(clientsOfMasterEntity.data, (temp) => {
        finalData.push({
          id: temp.masterEntity.id,
          relation_name: temp.masterEntity.relation_name,
          relation_address: temp.masterEntity.relation_address || "N/A",
          relation_postal_code: temp.masterEntity.relation_postal_code || "N/A",
          relation_mail: temp.masterEntity.relation_mail || "N/A",
          relation_city: temp.masterEntity.relation_city || "N/A",
          relation_country: temp.masterEntity.relation_country || "N/A",
          deleteID: temp.id,
        });
      });
      finalData.sort((a, b) => (a.username > b.username ? 1 : -1));

      this.setState({
        ...this.state,
        userID: this.props.dataItem.id,
        gridData: {
          data: finalData,
          total: finalData.length,
        },
        initialGridData: {
          data: finalData,
          total: finalData.length,
        },
        clientNames: clientNames,
        valueUserName: "",

        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    } else if (
      clientsOfMasterEntity &&
      clientsOfMasterEntity.data.length === 1
    ) {
      let finalData = [];
      _.forEach(clientsOfMasterEntity.data, (temp) => {
        finalData.push({
          id: temp.masterEntity.id,
          relation_name: temp.masterEntity.relation_name,
          relation_address: temp.masterEntity.relation_address || "N/A",
          relation_postal_code: temp.masterEntity.relation_postal_code || "N/A",
          relation_mail: temp.masterEntity.relation_mail || "N/A",
          relation_city: temp.masterEntity.relation_city || "N/A",
          relation_country: temp.masterEntity.relation_country || "N/A",
          deleteID: temp.id,
        });
      });
      finalData.sort((a, b) => (a.username > b.username ? 1 : -1));

      this.setState({
        ...this.state,
        userID: this.props.dataItem.id,
        gridData: {
          data: [finalData[0]],
          total: 1,
        },
        initialGridData: {
          data: [finalData[0]],
          total: 1,
        },
        clientNames: clientNames,
        valueUserName: "",
        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    } else {
      this.setState({
        ...this.state,
        userID: this.props.dataItem.id,
        gridData: {
          data: [],
          total: 0,
        },
        initialGridData: {
          data: [],
          total: 0,
        },
        clientNames: clientNames,
        valueUserName: "",
        visibleClients: result[0].finalUsers || [],
        finalUserClients: result[0].finalUsers || [],
        initialFinalUserClients: result[0].finalUsers || [],
        blocking: false,
      });
    }
  }

  getClientPromise = (filter) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    return new Promise((resolve, reject) => {
      const req = serverApi(
        "GET",
        `masterEntities?filter=${JSON.stringify(filter)}`,
        "",
        "",
        options
      );
      req
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  componentWillUnmount() {}

  constructUsersAndExistingUsersArray = (allClients, attachedUsers) => {
    let finalUsers = [];
    _.forEach(allClients, (temp1) => {
      finalUsers.push({
        name: temp1.relation_name || "N/A",
        id: temp1.id,
        checked: false,
      });
    });

    let finalAttachedUsers = [];
    _.forEach(attachedUsers, (temp2) => {
      finalAttachedUsers.push({
        name: temp2.masterEntity.relation_name || "N/A",
        clientID: temp2.clientID,
        checked: true,
        deleteID: temp2.id,
        id: temp2.id,
      });
    });

    finalUsers.sort((a, b) => (a.name > b.name ? 1 : -1));
    finalAttachedUsers.sort((a, b) => (a.name > b.name ? 1 : -1));

    return [
      {
        finalUsers: finalUsers,
        finalAttachedUsers: finalAttachedUsers,
      },
    ];
  };

  toggleWindow = (e) => {
    const data = this.state.initialGridData.data;

    this.setState({
      ...this.state,
      visibleWindow: !this.state.visibleWindow,
      gridData: {
        data: [...data],
        total: 100,
      },
      client_names: [],
      valueClientName: "",
      windowError: false,
      windowErrorMessage: "",
      openErrorModal: false,
      errorModalContent: "",
    });
  };

  renderGridColumns = (dataItem, changeTheCellComponent) => {
    let selectionCol = [];
    let normalCols = [];
    let finalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            width="70px"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.isFilterBoolean === "yes") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"420"}
              cell={DropDownCell}
            />
          );
        } else if (temp.field === "masterEntity.relation_name") {
          return (
            <Column
              field="masterEntity.relation_name"
              title="NAME"
              width="400px"
              cell={AutoComplete2}
            />
          );
        } else {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={temp.field === "id" || temp.editable ? false : true} //id not editable on edit
            />
          );
        }
      });

    finalCols = [...selectionCol, ...normalCols];
    return finalCols;
  };

  handleSearch = (e) => {
    let initialFinalUserClients = this.state.initialFinalUserClients;

    if (e.value === "") {
      let searchArr = [];
      initialFinalUserClients.forEach((temp) => {
        if (temp.name.toUpperCase().includes(e.value.toUpperCase())) {
          searchArr.push(temp);
        }
      });

      if (
        this.state.clientAttachedToAnotherGroup !== null &&
        this.state.clientAttachedToAnotherGroup !== undefined
      ) {
        let newArr = searchArr.filter(
          (t) => t.id !== this.state.clientAttachedToAnotherGroup
        );
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalUserClients: newArr,
          openErrorModal: false,
          errorModalContent: "",
        });
      } else {
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalUserClients: initialFinalUserClients,
          openErrorModal: false,
          errorModalContent: "",
        });
      }
    } else {
      let searchArr = [];
      //case insensitive
      initialFinalUserClients.forEach((temp) => {
        if (temp && temp.name.toUpperCase().includes(e.value.toUpperCase())) {
          searchArr.push(temp);
        }
      });

      if (
        this.state.clientAttachedToAnotherGroup !== null &&
        this.state.clientAttachedToAnotherGroup !== undefined
      ) {
        let newArr = searchArr.filter(
          (t) => t.id !== this.state.clientAttachedToAnotherGroup
        );
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalUserClients: newArr,
          openErrorModal: false,
          errorModalContent: "",
        });
      } else {
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalUserClients: searchArr,
          openErrorModal: false,
          errorModalContent: "",
        });
      }
    }
  };

  handleSuccessSnackbar = () => {
    this.setState({
      ...this.state,
      successMessage:
        this.state.openSuccessMessage === true ? this.state.successMessage : "",
      openSuccessMessage: !this.state.openSuccessMessage,
    });
  };

  remove = async (dataItem) => {
    this.setState({ ...this.state, visibleWindow: false });
    const data = [...this.state.gridData.data];

    let index = data.findIndex((p) => p === dataItem || p.id === dataItem.id);
    if (index >= 0) {
      data.splice(index, 1);
    }

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let delete_ID = dataItem.deleteID;
    let url = `userToClients/${delete_ID}`;

    try {
      const removeUserFromClient = await serverApi(
        `DELETE`,
        url,
        "",
        "",
        options
      );
      if (removeUserFromClient.status === 200) {
        let findUser = this.state.visibleClients.find(
          (x) => x.id === dataItem.id
        );
        if (findUser) {
          findUser.checked = false;
        }
        this.setState({
          ...this.state,
          gridData: {
            data: data,
            total: this.state.gridData.total,
          },
          initialGridData: {
            data: data,
            total: this.state.gridData.total,
          },
        });
      }
    } catch (error) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent:
          error?.response?.data?.error?.message ||
          "Remove user from client failed.Please try again.",
      });
    }
  };

  renderList = (finalUsers = [], users = [], doRefresh) => {
    if (doRefresh === true) {
      window.location.reload();
    } else {
      if (finalUsers.length === users.length) {
        return (
          users &&
          users.map((item) => (
            <ul style={{ marginBottom: "-5px" }} key={item.id}>
              <Checkbox
                name={item.name}
                key={item.id}
                value={item.checked}
                onChange={(e) => this.handleChange(e, item.name, item.id)}
                label={item.name}
                disabled={this.state.disableAllCheckboxes}
              />
            </ul>
          ))
        );
      } else {
        return (
          finalUsers &&
          finalUsers.map((item) => (
            <ul style={{ marginBottom: "-5px" }} key={item.id}>
              <Checkbox
                name={item.name}
                key={item.id}
                value={item.checked}
                onChange={(e) => this.handleChange(e, item.name, item.id)}
                label={item.name}
                disabled={this.state.disableAllCheckboxes}
              />
            </ul>
          ))
        );
      }
    }
  };

  handleChange = (event, name, key) => {
    let finalUserClient = [...this.state.finalUserClients];
    let finalObj = finalUserClient.find((test) => test.id === key);
    finalObj.checked = !finalObj.checked;

    const userID = this.props.dataItem.id;
    let data = [...this.state.gridData.data];

    this.setState(
      {
        ...this.state,
        finalUserClients: finalUserClient,
      },
      () => {
        let postOrDeleteClient = event.value === false ? "DELETE" : "POST";
        let options = {
          token: window.localStorage.getItem("access_token"),
        };

        if (postOrDeleteClient === "POST") {
          const url = `userToClients`;

          const addUserToClient = serverApi(
            "POST",
            url,
            "",
            {
              userID: userID,
              clientID: key,
            },
            options,
            "",
            ""
          );

          addUserToClient
            .then(async (response) => {
              let fil = {
                filter: {
                  where: {
                    userID: userID,
                  },
                  include: ["masterEntity"],
                },
              };
              let usersOfClient = await serverApi(
                "GET",
                `userToClients`,
                fil,
                "",
                options
              );

              let finalData = [];
              _.forEach(usersOfClient.data, (temp) => {
                finalData.push({
                  id: temp.masterEntity.id,
                  relation_name: temp.masterEntity.relation_name,
                  relation_address: temp.masterEntity.relation_address || "N/A",
                  relation_postal_code:
                    temp.masterEntity.relation_postal_code || "N/A",
                  relation_mail: temp.masterEntity.relation_mail || "N/A",
                  relation_city: temp.masterEntity.relation_city || "N/A",
                  relation_country: temp.masterEntity.relation_country || "N/A",
                  deleteID: temp.id,
                });
              });
              finalData.sort((a, b) => (a.username > b.username ? 1 : -1));

              this.setState({
                ...this.state,
                gridData: {
                  data: finalData,
                  total: finalData.length,
                },
                initialGridData: {
                  data: finalData,
                  total: finalData.length,
                },
                blocking: false,
                openErrorModal: false,
                errorModalContent: "",
              });
            })
            .catch((error) => {
              this.setState({
                ...this.state,
                openErrorModal: true,
                errorModalContent:
                  error?.response?.data?.error?.message ||
                  "Add client to user failed.Please try again.",
                blocking: false,
              });
            });
        } else if (postOrDeleteClient === "DELETE") {
          const deleteObj = data.find((obj) => obj.id === key);
          const delete_id = deleteObj.deleteID;
          const url = `userToClients/${delete_id}`;

          const removeUserFromClient = serverApi(
            "DELETE",
            url,
            "",
            "",
            options,
            "",
            ""
          );
          removeUserFromClient
            .then(async (response) => {
              let fil = {
                filter: {
                  where: {
                    userID: userID,
                  },
                  include: ["masterEntity"],
                },
              };
              let usersOfClient = await serverApi(
                "GET",
                `userToClients`,
                fil,
                "",
                options
              );
              let finalData = [];
              _.forEach(usersOfClient.data, (temp) => {
                finalData.push({
                  id: temp.masterEntity.id,
                  relation_name: temp.masterEntity.relation_name,
                  relation_address: temp.masterEntity.relation_address || "N/A",
                  relation_postal_code:
                    temp.masterEntity.relation_postal_code || "N/A",
                  relation_mail: temp.masterEntity.relation_mail || "N/A",
                  relation_city: temp.masterEntity.relation_city || "N/A",
                  relation_country: temp.masterEntity.relation_country || "N/A",
                  deleteID: temp.id,
                });
              });
              finalData.sort((a, b) => (a.username > b.username ? 1 : -1));

              this.setState({
                ...this.state,
                gridData: {
                  data: finalData,
                  total: finalData.length,
                },
                initialGridData: {
                  data: finalData,
                  total: finalData.length,
                },
                blocking: false,
              });
            })
            .catch((error) => {
              this.setState({
                ...this.state,
                openErrorModal: true,
                errorModalContent:
                  error?.response?.data?.error?.message ||
                  "Remove client from the failed.Please try again.",
                blocking: false,
              });
            });
        }
      }
    );
  };

  getExportData = async () => {
    await this.exportToCsv(this.state, this.props);
  };

  getPromise = () => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    const clientID = this.props.dataItem.id;
    let fil = {
      filter: {
        where: {
          clientID: clientID,
        },
        include: ["snUser"],
      },
    };

    return new Promise((resolve, reject) => {
      const usersOfDepartment = serverApi(
        "GET",
        `userToClients`,
        fil,
        "",
        options
      );
      usersOfDepartment
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  exportToCsv = async (state, props) => {
    const count = state.gridData.total;
    const numberOfCalls = Math.min(
      Math.round(count / 1000) + 1,
      5 // Limit to 5000
    );

    const promises = [];
    for (let i = 0; i < numberOfCalls; i++) {
      promises.push(
        this.getPromise({
          skip: i * 1000,
          limit: 1000,
          order: ["id asc"],
        })
      );
    }

    try {
      const responses = await Promise.all(promises);
      let allData = [];
      responses.forEach((resp) => {
        let finalData = [];
        _.forEach(resp.data, (temp) => {
          finalData.push({
            id: temp.userID,
            username: temp.snUser.username,
            userRealName: temp.snUser.userRealName,
            email: temp.snUser.email || "N/A",
            // bugify_token: temp.snUser.bugify_token || 'N/A',
            created: temp.snUser.created || "N/A",
            last_login_date: temp.snUser.last_login_date || "N/A",
            deleteID: temp.id,
          });
        });
        finalData.sort((a, b) => (a.username > b.username ? 1 : -1));
        allData = [...allData, ...finalData];
      });
      if (allData.length > 0) {
        this.setState({
          ...this.state,
          exportData: allData,
        });
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent: e.response.data.error.message || "Please try again",
      });
    }
  };

  render() {
    const dataItem = this.props.dataItem;
    const { gridData } = this.state;
    const { inputValue } = this.state;

    return (
      <div>
        <div>
          <h4 style={{ fontFamily: "bold", textSize: "7px" }}>
            User : {dataItem && dataItem.username}
          </h4>
        </div>

        <Grid
          {...(gridData || [])}
          filterable={false}
          style={{ className: "grid-no-select" }}
          sortable={true}
          resizable
          editField={this.editField}
          onItemChange={this.itemChange}
          pageable={false}
        >
          <GridToolbar>
            <GridContainer xs={12} justify={"flex-start"} direction={"row"}>
              <Tooltip
                justify={"flex-start"}
                placement="top"
                title={"Add/Remove Client"}
              >
                <IconButton onClick={this.toggleWindow}>
                  <FontAwesomeIcon
                    color="#0D5869"
                    size="1.6x"
                    icon={faPencilAlt}
                  />
                </IconButton>
              </Tooltip>
              <ViewSettings
                {...this.props}
                export={{
                  data: this.state.exportData || [],
                  fileName: "Client_Attached_",
                  exportFunction: this.getExportData,
                  columns: columnsSchema(),
                }}
                viewSettings={{
                  type: "",
                }}
                // refreshSettings={true}
                // refreshFunction={this.refreshGridDataAfterCrud}
              />
            </GridContainer>
          </GridToolbar>

          <Column
            cell={this.CommandCell}
            title="Actions"
            filterable={false}
            width="200px"
          />
          {this.renderGridColumns(dataItem)}
        </Grid>

        {this.state.visibleWindow && (
          <Window
            title={`Add/Remove Clients from User: ${this.props.dataItem.username}`}
            // style={{backgroundColor:'#0d5869'}}
            onClose={this.toggleWindow}
            width={900}
            height={600}
            initialTop={110}
          >
            <div>
              <>
                <BlockUi
                  tag="div"
                  blocking={this.state.blocking}
                  message="Loading, please wait. . ."
                  renderChildren={true}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "50px",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {this.state.openErrorModal ? (
                        <Error style={{ color: "red", fontSize: "15px" }}>
                          {this.state.errorModalContent || ""}
                        </Error>
                      ) : null}
                      <div
                        style={{
                          display: "flex",
                          marginLeft: "35px",
                          marginTop: "20px",
                        }}
                      >
                        <Input
                          name="users"
                          value={inputValue}
                          style={{ marginBottom: "20px" }}
                          label="Search client"
                          onChange={(e) => this.handleSearch(e)}
                          minLength={2}
                        />
                      </div>

                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {this.renderList(
                          this.state.finalUserClients,
                          this.state.visibleClients
                        )}
                      </div>
                    </div>
                  </div>
                </BlockUi>
              </>
              {this.state.windowError ? (
                <Error style={{ color: "red" }}>
                  <h3>{this.state.windowErrorMessage || ""}</h3>
                </Error>
              ) : null}
            </div>
          </Window>
        )}
      </div>
    );
  }
}
