// lat /lon / iso / country / city / name minimum properties.
export const columnsSchema = () => [
  {
    field: "id",
    title: "ID",
    visible: true,
    minWidth: 140,
    sortable: true,
    filterable: true,
    locked: true,
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
  },
  {
    field: "relation_name",
    title: "Name",
    visible: true,
    minWidth: 320,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    locked: true,
  },
  {
    field: "relation_city",
    title: "City",
    visible: true,
    minWidth: 320,
    sortable: true,
    filterable: true,
    locked: false,
    notShownInTableColumnSelection: false,
  },
  {
    field: "relation_country",
    title: "Country",
    visible: true,
    minWidth: 200,
    sortable: true,
    filterable: true,
    isDropdown: "no",
    locked: false,
  },
  {
    field: "state",
    title: "State",
    visible: true,
    minWidth: 200,
    sortable: true,
    filterable: true,
    noDisabledColumn: false,
    isDropdown: "yes",
    locked: false,
  },
  {
    field: "relation_location_iso_code",
    title: "Iso",
    visible: true,
    sortable: true,
    minWidth: 280,
    filterable: true,
    locked: false,
  },
  {
    field: "aes_exportportcode",
    title: "AES Export Port Code",
    visible: true,
    minWidth: 150,
    sortable: true,
    filterable: true,
    locked: false,
    notShownInTableColumnSelection: false,
  },
  {
    field: "latitude",
    title: "Latitude",
    visible: true,
    minWidth: 180,
    sortable: true,
    filterable: true,
    locked: false,
    notShownInTableColumnSelection: false,
  },
  {
    field: "longitude",
    title: "Longitude",
    visible: true,
    minWidth: 180,
    sortable: true,
    filterable: true,
    locked: false,
    notShownInTableColumnSelection: false,
  },
  // {
  //   field: "scac",
  //   title: "SCAC",
  //   visible: true,
  //   minWidth: 170,
  //   sortable: true,
  //   filterable: true,
  //   locked: false,
  //   notShownInTableColumnSelection: false,
  // },
  {
    field: "createdByForUi",
    title: "Created By",
    visible: true,
    minWidth: 200,
    sortable: true,
    filterable: false,
    locked: false,
    notShownInTableColumnSelection: false,
    editable: "false",
  },
  {
    field: "dateCreatedForUi",
    title: "Created Date",
    visible: true,
    minWidth: 200,
    sortable: true,
    filterable: false,
    locked: false,
    notShownInTableColumnSelection: false,
    editable: "false",
  },
];
