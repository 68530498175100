export const columnsSchema = () => [
  {
    field: 'id',
    title: 'ID',
    visible: true,
    minWidth: 170,
    sortable: true,
    filterable: true,
    locked: 'locked',
  },
  {
    field: 'client_name',
    title: 'Client',
    visible: true,
    minWidth: 230,
    sortable: true,
    filterable: true,
    locked: 'locked',
    editable: 'false'
  },
  
  {
    field: 'vessel_name',
    title: 'Vessel',
    visible: true,
    minWidth: 300,
    sortable: true,
    filterable: true,
    locked: 'locked',
    editable: 'false'
  },
  {
    field: "primary_flg",
    title: "Primary Flag",
    visible: true,
    sortable: false,
    minWidth: 170,
    filterable: false,
    locked: 'locked',
    filter: 'boolean',
    isFilterBoolean: "yes",
    editable: 'false'
  },
  {
    field: "start_date",
    title: "VALID FROM",
    visible: true,
    sortable: true,
    minWidth: 260,
    filterable: true,
    locked: 'locked'
  },
  {
    field: "end_date",
    title: "VALID UNTIL",
    visible: true,
    sortable: true,
    minWidth: 260,
    filterable: true,
    locked: 'locked'
  },
  {
    field: 'client_id',
    title: 'Client ID',
    visible: true,
    minWidth: 230,
    sortable: true,
    filterable: true,
    locked: 'locked',
    editable: 'false'
  },
  {
    field: 'vessel_id',
    title: 'Vessel ID',
    visible: true,
    minWidth: 300,
    sortable: true,
    filterable: true,
    locked: 'locked',
    editable: 'false'
  },

];
