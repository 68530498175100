import React from "react";
import TableList2 from "../../../containers/ContainerGrid/gridWithoutHOC"
import {inventoryScansColumnsSchema, boxLogColumnSchema, orderLogColumnSchema,orderToDispatchColumnsSchema,wh_picking_dispatch_to_boxColumnsSchema} from "./schema";
import navigatorLogo from "../../../assets/img/navigatorLogo.svg";

const LogBox = (props) => {

    const showExtraComponents = {
        showCreateButton: false,
        showExportButton: false,
        showRefreshDataButton: true,
        showToggleFilters: false,
        showGoBackButton: false,
        showClearFilters: false,
        showPagination: false,
        showGridActions: false,
        showToggleColumns: false,
        rowClick: false
    }

    const gridActions = []

    const includeArray = [""]

    return (
        <>
            <TableList2
                {...props}
                showTable2={true}
                showTable3={true}
                showTable4={true}
                showTable5={true}
                BlockUiMessage={"Loading table data . . . "}
                showHeaderNavigator={true}
                columnsSchema={boxLogColumnSchema}
                columnsSchema2={orderLogColumnSchema}
                columnsSchema3={inventoryScansColumnsSchema}
                columnsSchema4={orderToDispatchColumnsSchema}
                columnsSchema5={wh_picking_dispatch_to_boxColumnsSchema}
                howManyTable={["1", "2", "3","4","5"]}
                gridActions={gridActions}
                includeArray={includeArray}
                dropdownFieldName={""}
                urlSpecific={""}
                karfoto={`logTables?filter=%7B%22include%22%3A%20%22creator%22%2C%20%20%20%20%22where%22%3A%7B%20%22and%22%3A%20%5B%7B%22model_name%22%3A%20%22BOX%22%2C%20%22model_id%22%3A%20%22${props.match.params.id}%22%7D%5D%7D%7D`}
                karfoto3={`boxes/${props.match.params.id}`}
                tableTitle={"Box Log"}
                tableTitle2={"Order Log"}
                tableTitle3={"Inventory Scans"}
                tableTitle4={"Dispatches"}
                tableTitle5={"Picked By"}
                logo={navigatorLogo}
                showExtraComponents={showExtraComponents}
                filterable={false}
                reorderable={true}
                sortable={false}
                showGridActions={false}
                showPagination={false}
            />
        </>
    )

}

export default LogBox;
