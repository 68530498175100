import React from "react";
import {
  Grid,
  GridColumn as Column,
  GridDetailRow,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { columnSchema } from "./schema";
import { MyCommandCell } from "../../../../Clients/View/components/CellHOC";
import _ from "lodash";
import { Window } from "@progress/kendo-react-dialogs";
import { Error } from "@progress/kendo-react-labels";
import { IconButton, Tooltip } from "@material-ui/core";
import BlockUi from "react-block-ui";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import GridContainer from "../../../../../components/Grid/GridContainer";
import RegularButton from "../../../../../components/CustomButtons/Button";
import { serverApi } from "../../../../../networking/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

export default class FleetsToVessel extends GridDetailRow {
  editField = "inEdit";
  CommandCell;
  _export;

  constructor(props) {
    super(props);

    this.state = {
      visibleWindow: false,
      isDropOpened: false,
      columns: (columnSchema && columnSchema()) || [],
      gridData: {
        data: [],
        total: 10,
      },
      initialGridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 10,
        skip: 0,
      },
      openErrorModal: false,
      errorModalContent: "",
      delay: 400,
      loading: false,
      vessel_names: [],
      windowError: false,
      windowErrorMessage: "",
      disableEditButton: true,
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: () => null,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      disableEditButton: false,
      showSecondAction: true,
      showFirstAction: false,
    });
  }

  async componentDidMount() {
    const dataItem = this.props.dataItem;

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let filter1 = {
      filter: {
        where: {
          vesselID: dataItem?.id,
        },
        include: ["fleet"],
      },
    };

    const responseData = await serverApi(
      "GET",
      `vesselFleetConnections`,
      filter1,
      "",
      options
    );
    let clonedAttachedData = _.cloneDeep(responseData);
    // let getFleets = {data: []}

    //todo refactor with count if more than 1000 records
    const possibleFleets = await serverApi(
      "POST",
      `vessels/possibleFleets`,
      "",
      {
        vesselID: dataItem.id,
      },
      options,
      "",
      ""
    );

    let result = this.constructDataAndExistingDaraArrays(
      possibleFleets.data.response,
      clonedAttachedData.data
    );

    result[0].attachedVessels.forEach((item) => {
      let client = result[0].allVessels.find((x) => x.name === item.name);
      if (client) {
        client.checked = true;
      }
    });

    if (responseData && responseData.data.length === 1) {
      let finalData = [];
      _.forEach(responseData.data, (temp) => {
        finalData.push({
          deleteID: temp?.id,
          id: temp.fleet?.id,
          fleet_name: temp?.fleet?.fleet_name || "N/A",
        });
      });

      this.setState({
        ...this.state,
        fleetID: this.props.dataItem.id,
        gridData: {
          data: finalData,
          total: finalData.length,
        },
        initialGridData: {
          data: finalData,
          total: finalData.length,
        },
        vessel_names: possibleFleets.data.response.sort((a, b) =>
          a.FLEET_NAME > b.FLEET_NAME ? 1 : -1
        ),
        valueVesselName: "",
        visibleVessels: result[0].allVessels || [],
        finalVesseslFleet: result[0].allVessels,
        initialfinalVesseslFleet: result[0].allVessels,
        blocking: false,
      });
    } else if (responseData && responseData.data.length > 1) {
      let finalData = [];
      _.forEach(responseData.data, (temp) => {
        finalData.push({
          deleteID: temp?.id,
          id: temp?.fleet?.id,
          fleet_name: temp?.fleet?.fleet_name || "N/A",
        });
      });

      this.setState({
        ...this.state,
        fleetID: this.props.dataItem.id,
        gridData: {
          data: finalData,
          total: finalData.length,
        },
        initialGridData: {
          data: finalData,
          total: finalData.length,
        },
        vessel_names: possibleFleets.data.response.sort((a, b) =>
          a.FLEET_NAME > b.FLEET_NAME ? 1 : -1
        ),
        visibleVessels: result[0].allVessels || [],
        finalVesseslFleet: result[0].allVessels,
        initialfinalVesseslFleet: result[0].allVessels,
        blocking: false,
      });
    } else {
      this.setState({
        ...this.state,
        fleetID: this.props.dataItem.id,
        gridData: {
          data: [],
          total: 0,
        },
        initialGridData: {
          data: [],
          total: 0,
        },
        vessel_names: possibleFleets.data.response.sort((a, b) =>
          a.FLEET_NAME > b.FLEET_NAME ? 1 : -1
        ),
        visibleVessels: result[0].allVessels || [],
        finalVesseslFleet: result[0].allVessels,
        initialfinalVesseslFleet: result[0].allVessels,
        blocking: false,
      });
    }
  }

  constructDataAndExistingDaraArrays = (data, attachedData) => {
    let finalVessels = [];
    _.forEach(data, (temp1) => {
      const fleetIDs = [];

      finalVessels.push({
        name: temp1.FLEET_NAME || "N/A",
        id: temp1.FLEETID,
        checked: false,
        fleetIDs: fleetIDs,
      });
    });

    let finalAttachedVessels = [];
    _.forEach(attachedData, (temp2) => {
      let fleetIDs = [];
      finalAttachedVessels.push({
        name: temp2?.fleet?.fleet_name || "N/A",
        id: temp2.id,
        checked: true,
        deleteID: temp2.id,
        fleetIDs: fleetIDs.push(temp2.fleet.id),
      });
    });

    finalVessels.sort((a, b) => (a.name > b.name ? 1 : -1));
    finalAttachedVessels.sort((a, b) => (a.name > b.name ? 1 : -1));

    return [
      {
        allVessels: finalVessels,
        attachedVessels: finalAttachedVessels,
      },
    ];
  };

  exportToCsv = () => {
    //todo make export without count just like v2.
    this._export.save(this.state.gridData.data, this.state.columns);
  };

  renderGridColumns = (dataItem, changeTheCellComponent) => {
    let selectionCol = [];
    let normalCols = [];
    let finalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            width="65px"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.isFilterBoolean === "yes") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
            />
          );
        } else {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={temp.field === "id" || temp.editable ? false : true} //id not editable on edit
            />
          );
        }
      });

    finalCols = [...selectionCol, ...normalCols];
    return finalCols;
  };

  enterEdit = (dataItem) => {
    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    let total = this.state.gridData.total;

    this.setState({
      gridData: {
        data:
          this.state.gridData &&
          this.state.gridData.data.map((item) =>
            item.id === dataItem.id ? { ...item, inEdit: true } : item
          ),
        total: total,
      },
    });
  };

  discard = (dataItem, hasError = false) => {
    let data = [...this.state.gridData.data];
    const total = this.state.gridData.total;

    if (hasError) {
      data[0].inEdit = true;
      this.setState({
        gridData: {
          data: data,
          total: total,
        },
      });
    } else {
      this.removeItem(data, dataItem);

      this.setState({
        gridData: {
          data: data,
          total: total,
        },
        visible: false,
      });
    }
  };

  cancel = (dataItem) => {
    const originalItem =
      this.state.initialGridData &&
      this.state.initialGridData.data.find((p) => p.id === dataItem.id);
    originalItem.inEdit = false;

    const data =
      this.state.gridData &&
      this.state.gridData.data.map((item) =>
        item.id === originalItem.id ? originalItem : item
      );

    const total = this.state.gridData.total;

    this.setState({
      ...this.state,
      gridData: {
        data: data,
        total: total,
      },
      openErrorModal: false,
      openErrorModalContent: "",
    });
  };

  removeItem(data, item) {
    let index = data.findIndex(
      (p) => p === item || item.id || p.id === item.id
    );
    if (index >= 0) {
      data.splice(index, 1);
    }
  }

  remove = async (dataItem) => {};

  add = (dataItem) => {};

  addNew = () => {
    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    const newDataItem = { inEdit: true };

    this.setState({
      gridData: {
        data: [newDataItem, ...this.state.gridData.data],
        total: this.state.gridData.total,
      },
      visibleWindow: true,
    });
  };

  //TODO change this is not the logic we want
  update = async (dataItem) => {};

  updateItem = (data, item) => {
    let index = data.findIndex(
      (p) => p === item || (item.id && p.id === item.id)
    );
    if (index >= 0) {
      data[index] = { ...item };
    }
  };

  refreshGridDataAfterCrud = async () => {};

  toggleWindow = (e) => {
    const data = this.state.initialGridData.data;

    this.setState({
      visibleWindow: !this.state.visibleWindow,
      gridData: {
        data: [...data],
        total: 10,
      },
      vessel_names: [],
      valueVesselName: "",
      windowError: false,
      windowErrorMessage: "",
      openErrorModal: false,
      errorModalContent: "",
    });
  };

  closeErrorModal = () => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      errorModalContent: "",
    });
  };

  handleErrorClose = (loginError) => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      openErrorModalContent: "",
    });
  };

  openErrorModal = (e) => {
    this.setState({
      ...this.state,
      openErrorModal: true,
      errorModalContent: e.response.data.error.message || "Please try again",
    });
  };

  handlePost = async () => {
    const url = `vesselFleetConnections`;
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    const request = serverApi(
      "POST",
      url,
      "",
      {
        fleetID: this.state.fleetID,
        vesselID: this.props.dataItem.id,
      },
      options,
      "",
      ""
    );

    request
      .then(async (response) => {
        await this.afterHandleChange();

        this.setState({
          ...this.state,
          openErrorModal: false,
          errorModalContent: "",
        });
      })
      .catch((error) => {
        this.setState({
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            error?.response?.data?.error?.message || "Please try again",
          blocking: false,
        });
      });
  };

  handleChangeOLD = (event, name, key) => {
    let finalUserClient = [...this.state.finalVesseslFleet];
    let finalObj = finalUserClient.find((test) => test.id === key);
    finalObj.checked = !finalObj.checked;

    let data = [...this.state.gridData.data];

    this.setState(
      {
        ...this.state,
        finalVesseslFleet: finalUserClient,
        fleetID: key,
      },
      async () => {
        let postOrDeleteClient = event.value === false ? "DELETE" : "POST";
        let options = {
          token: window.localStorage.getItem("access_token"),
        };

        if (postOrDeleteClient === "POST") {
          //check if vessel belongs to a fleet and show a warning
          const vesselFleet = await serverApi(
            "GET",
            `vesselFleetConnections`,
            {
              filter: {
                where: {
                  vesselID: this.props.dataItem.id,
                },
              },
            },
            "",
            options
          );

          if (vesselFleet.data.length > 0) {
            this.setState(
              {
                ...this.state,
                openErrorModal: true,
                errorModalContent:
                  "The vessel belongs to other fleet(s).Press proceed in order to continue or cancel to stop process.",
                fleetID: key,
              },
              () => {
                let finalUserClient = [...this.state.finalVesseslFleet];
                let finalObj = finalUserClient.find((test) => test.id === key);
                finalObj.checked = false;
              }
            );
          } else {
            this.handlePost();
          }
        } else if (postOrDeleteClient === "DELETE") {
          const deleteObj = data.find((obj) => obj.id === key);
          const delete_id = deleteObj.deleteID;

          const url = `vesselFleetConnections/${delete_id}`;

          const removeClientFromDomain = serverApi(
            "DELETE",
            url,
            "",
            "",
            options,
            "",
            ""
          );
          removeClientFromDomain
            .then(async (response) => {
              await this.afterHandleChange();
            })
            .catch((error) => {
              this.setState({
                ...this.state,
                openErrorModal: true,
                errorModalContent:
                  error?.response?.data?.error?.message || "Please try again",
                blocking: false,
              });
            });
        }
      }
    );
  };

  handleChange = (event, name, key) => {
    let finalVesseslFleet = [...this.state.finalVesseslFleet];
    let finalObj = finalVesseslFleet.find((t) => t.id === key);

    let action = event.value === false ? "DELETE" : "POST";
    if (action === "POST") {
      finalObj.fleetIDs.push(key);
    } else if (action === "DELETE") {
      let index = finalObj.fleetIDs.indexOf(key);
      if (index !== -1) {
        finalObj.fleetIDs.splice(index, 1);
      }
    }

    finalObj.checked = !finalObj.checked;

    this.setState(
      {
        ...this.state,
        finalVesseslFleet: finalVesseslFleet,
      },
      async () => {}
    );
  };

  afterHandleChange = async () => {
    const dataItem = this.props.dataItem;

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let filter1 = {
      filter: {
        where: {
          vesselID: dataItem?.id,
        },
        include: ["fleet"],
      },
    };

    const responseData = await serverApi(
      "GET",
      `vesselFleetConnections`,
      filter1,
      "",
      options
    );
    let clonedAttachedData = _.cloneDeep(responseData);
    //todo refactor with count if more than 1000 records
    const possibleFleets = await serverApi(
      "POST",
      `vessels/possibleFleets`,
      "",
      {
        vesselID: dataItem.id,
      },
      options,
      "",
      ""
    );

    let result = this.constructDataAndExistingDaraArrays(
      possibleFleets.data.response,
      clonedAttachedData.data
    );

    result[0].attachedVessels.forEach((item) => {
      let client = result[0].allVessels.find((x) => x.name === item.name);
      if (client) {
        client.checked = true;
      }
    });

    if (responseData && responseData.data.length === 1) {
      let finalData = [];
      _.forEach(responseData.data, (temp) => {
        finalData.push({
          deleteID: temp.id,
          id: temp.fleet.id,
          fleet_name: temp.fleet.fleet_name || "N/A",
        });
      });

      this.setState({
        ...this.state,
        fleetID: this.props.dataItem.id,
        gridData: {
          data: finalData,
          total: finalData.length,
        },
        initialGridData: {
          data: finalData,
          total: finalData.length,
        },
        vessel_names: possibleFleets.data.response.sort((a, b) =>
          a.FLEET_NAME > b.FLEET_NAME ? 1 : -1
        ),
        valueVesselName: "",
        visibleVessels: result[0].allVessels || [],
        finalVesseslFleet: result[0].allVessels,
        initialfinalVesseslFleet: result[0].allVessels,
        blocking: false,
      });
    } else if (responseData && responseData.data.length > 1) {
      let finalData = [];
      _.forEach(responseData.data, (temp) => {
        finalData.push({
          deleteID: temp.id,
          id: temp.fleet.id,
          fleet_name: temp.fleet.fleet_name || "N/A",
        });
      });

      this.setState({
        ...this.state,
        fleetID: this.props.dataItem.id,
        gridData: {
          data: finalData,
          total: finalData.length,
        },
        initialGridData: {
          data: finalData,
          total: finalData.length,
        },
        vessel_names: possibleFleets.data.response.sort((a, b) =>
          a.FLEET_NAME > b.FLEET_NAME ? 1 : -1
        ),
        visibleVessels: result[0].allVessels || [],
        finalVesseslFleet: result[0].allVessels,
        initialfinalVesseslFleet: result[0].allVessels,
        blocking: false,
      });
    } else {
      this.setState({
        ...this.state,
        fleetID: this.props.dataItem.id,
        gridData: {
          data: [],
          total: 0,
        },
        initialGridData: {
          data: [],
          total: 0,
        },
        vessel_names: possibleFleets.data.response.sort((a, b) =>
          a.FLEET_NAME > b.FLEET_NAME ? 1 : -1
        ),
        visibleVessels: result[0].allVessels || [],
        finalVesseslFleet: result[0].allVessels,
        initialfinalVesseslFleet: result[0].allVessels,
        blocking: false,
      });
    }
  };

  renderClientList = (finalClients = [], clients = [], doRefresh) => {
    if (doRefresh === true) {
      window.location.reload();
    } else {
      if (finalClients.length === clients.length) {
        return (
          clients &&
          clients.map((item) => (
            <ul style={{ marginBottom: "-5px" }} key={item.id}>
              <Checkbox
                name={item.name}
                key={item.id}
                value={item.checked}
                onChange={(e) => this.handleChange(e, item.name, item.id)}
                label={item.name}
                disabled={this.state.disableAllCheckboxes}
              />
            </ul>
          ))
        );
      } else {
        return (
          finalClients &&
          finalClients.map((item) => (
            <ul style={{ marginBottom: "-5px" }} key={item.id}>
              <Checkbox
                name={item.name}
                key={item.id}
                value={item.checked}
                onChange={(e) => this.handleChange(e, item.name, item.id)}
                label={item.name}
                disabled={this.state.disableAllCheckboxes}
              />
            </ul>
          ))
        );
      }
    }
  };

  handleSearch = (e) => {
    let initialfinalVesseslFleet = this.state.initialfinalVesseslFleet;

    if (e.value === "") {
      let searchArr = [];
      initialfinalVesseslFleet.forEach((temp) => {
        if (temp.name.toUpperCase().includes(e.value.toUpperCase())) {
          searchArr.push(temp);
        }
      });

      if (
        this.state.clientAttachedToAnotherDomain !== null &&
        this.state.clientAttachedToAnotherDomain !== undefined
      ) {
        let newArr = searchArr.filter(
          (t) => t.id !== this.state.clientAttachedToAnotherDomain
        );
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalVesseslFleet: newArr,
          openErrorModal: false,
          errorModalContent: "",
        });
      } else {
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalVesseslFleet: initialfinalVesseslFleet,
          openErrorModal: false,
          errorModalContent: "",
        });
      }
    } else {
      let searchArr = [];
      //case insensitive
      initialfinalVesseslFleet.forEach((temp) => {
        if (temp.name.toUpperCase().includes(e.value.toUpperCase())) {
          searchArr.push(temp);
        }
      });

      this.setState({
        ...this.state,
        inputValue: e.value,
        finalVesseslFleet: searchArr,
        openErrorModal: false,
        errorModalContent: "",
      });
    }
  };

  fetchLatestData = async () => {
    const dataItem = this.props.dataItem;

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let filter1 = {
      filter: {
        where: {
          vesselID: dataItem?.id,
        },
        include: ["fleet"],
      },
    };

    const responseData = await serverApi(
      "GET",
      `vesselFleetConnections`,
      filter1,
      "",
      options
    );
    let clonedAttachedData = _.cloneDeep(responseData);
    // let getFleets = {data: []}

    //todo refactor with count if more than 1000 records
    const possibleFleets = await serverApi(
      "POST",
      `vessels/possibleFleets`,
      "",
      {
        vesselID: dataItem.id,
      },
      options,
      "",
      ""
    );

    let result = this.constructDataAndExistingDaraArrays(
      possibleFleets.data.response,
      clonedAttachedData.data
    );

    result[0].attachedVessels.forEach((item) => {
      let client = result[0].allVessels.find((x) => x.name === item.name);
      if (client) {
        client.checked = true;
      }
    });

    if (responseData && responseData.data.length === 1) {
      let finalData = [];
      _.forEach(responseData.data, (temp) => {
        finalData.push({
          deleteID: temp?.id,
          id: temp.fleet?.id,
          fleet_name: temp?.fleet?.fleet_name || "N/A",
        });
      });

      this.setState({
        ...this.state,
        fleetID: this.props.dataItem.id,
        gridData: {
          data: finalData,
          total: finalData.length,
        },
        initialGridData: {
          data: finalData,
          total: finalData.length,
        },
        vessel_names: possibleFleets.data.response.sort((a, b) =>
          a.FLEET_NAME > b.FLEET_NAME ? 1 : -1
        ),
        valueVesselName: "",
        visibleVessels: result[0].allVessels || [],
        finalVesseslFleet: result[0].allVessels,
        initialfinalVesseslFleet: result[0].allVessels,
        blocking: false,
      });
    } else if (responseData && responseData.data.length > 1) {
      let finalData = [];
      _.forEach(responseData.data, (temp) => {
        finalData.push({
          deleteID: temp?.id,
          id: temp?.fleet?.id,
          fleet_name: temp?.fleet?.fleet_name || "N/A",
        });
      });

      this.setState({
        ...this.state,
        fleetID: this.props.dataItem.id,
        gridData: {
          data: finalData,
          total: finalData.length,
        },
        initialGridData: {
          data: finalData,
          total: finalData.length,
        },
        vessel_names: possibleFleets.data.response.sort((a, b) =>
          a.FLEET_NAME > b.FLEET_NAME ? 1 : -1
        ),
        visibleVessels: result[0].allVessels || [],
        finalVesseslFleet: result[0].allVessels,
        initialfinalVesseslFleet: result[0].allVessels,
        blocking: false,
      });
    } else {
      this.setState({
        ...this.state,
        fleetID: this.props.dataItem.id,
        gridData: {
          data: [],
          total: 0,
        },
        initialGridData: {
          data: [],
          total: 0,
        },
        vessel_names: possibleFleets.data.response.sort((a, b) =>
          a.FLEET_NAME > b.FLEET_NAME ? 1 : -1
        ),
        visibleVessels: result[0].allVessels || [],
        finalVesseslFleet: result[0].allVessels,
        initialfinalVesseslFleet: result[0].allVessels,
        blocking: false,
      });
    }
  };

  saveData = async () => {
    const finalData = [
      ...this.state.finalVesseslFleet.filter((x) => x.checked === true),
    ];
    const initialData = this.state.finalVesseslFleet.filter((x) => x.checked);
    const final = [...finalData, ...initialData];

    const uniqueData = final.filter(
      (thing, index, self) => index === self.findIndex((t) => t.id === thing.id)
    );

    const clonedUsers = _.cloneDeep(uniqueData);
    let fleetIDs = [];
    fleetIDs = clonedUsers.map((x) => x.id);

    let payload = {
      data: [],
    };
    payload.data.push({
      vesselID: this.props.dataItem.id,
      fleetIDs: fleetIDs,
    });

    this.setState({
      ...this.state,
      blocking: true,
      blockingMessage: "Please wait a few seconds...",
    });

    const url = `vesselFleetConnections/bulkUpdate`;
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    await serverApi("POST", url, "", payload, options, "", "");
    await this.fetchLatestData();

    this.setState({
      ...this.state,
      blocking: false,
      blockingMessage: "",
      inputValue: "",
    });
  };

  render() {
    const dataItem = this.props.dataItem;
    const { gridData } = this.state;
    const { inputValue } = this.state;
    const { hasBackofficeRead } = this.props;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <div
          style={{
            width: "55%",
            marginBottom: "20px",
          }}
        >
          <h4>NAME : {dataItem && dataItem.vessel_name}</h4>

          <Grid
            {...gridData}
            filterable={false}
            style={{ className: "grid-no-select" }}
            sortable={true}
            resizable
            editField={this.editField}
            onItemChange={this.itemChange}
            pageable={false}
          >
            <GridToolbar>
              <GridContainer xs={12} justify={"flex-start"} direction={"row"}>
                {!hasBackofficeRead && (
                  <Tooltip
                    justify={"flex-start"}
                    placement="top"
                    title={"Add/Remove Fleets"}
                  >
                    <IconButton>
                      <FontAwesomeIcon
                        color="#0D5869"
                        onClick={this.toggleWindow}
                        size="1.6x"
                        icon={faPencilAlt}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </GridContainer>
            </GridToolbar>

            {this.renderGridColumns(dataItem)}
          </Grid>
        </div>
        {this.state.visibleWindow && (
          <Window
            title={`Attach fleets to vessel below`}
            onClose={this.toggleWindow}
            width={800}
            height={650}
            initialTop={10}
          >
            <>
              <BlockUi
                tag="div"
                blocking={this.state.blocking}
                message={this.state.blockingMessage}
                renderChildren={true}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "50px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        position: "sticky",
                        top: "0px",
                        zIndex: 2,
                        backgroundColor: "ffff",
                      }}
                    >
                      <RegularButton
                        style={{ backgroundColor: "#0d5869" }}
                        roundSmall="true"
                        onClick={this.saveData}
                        size="sm"
                        // disabled={this.state.disableSaveNewDepartments || false}
                      >
                        Save changes
                      </RegularButton>
                    </div>
                    {this.state.windowError ? (
                      <Error style={{ color: "red" }}>
                        <h3>{this.state.windowErrorMessage || ""}</h3>
                      </Error>
                    ) : null}
                    {this.state.openErrorModal ? (
                      <Error style={{ color: "red", fontSize: "15px" }}>
                        {this.state.errorModalContent || ""}
                      </Error>
                    ) : null}
                    <div
                      style={{
                        display: "flex",
                        marginLeft: "35px",
                        marginTop: "20px",
                      }}
                    >
                      <Input
                        name="seaports"
                        value={inputValue}
                        style={{ marginBottom: "20px" }}
                        label="Search fleets"
                        onChange={(e) => this.handleSearch(e)}
                        minLength={2}
                      />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {this.renderClientList(
                        this.state.finalVesseslFleet,
                        this.state.visibleVessels
                      )}
                    </div>
                  </div>
                </div>
              </BlockUi>
            </>
            {this.state.windowError ? (
              <Error style={{ color: "red" }}>
                <h3>{this.state.windowErrorMessage || ""}</h3>
              </Error>
            ) : null}
          </Window>
        )}
      </div>
    );
  }
}
