import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { getCurrentRoute, getUserRoutes } from "./routes";
import { NoAccess } from "../layouts/NoAccess/NoAccess";

export default function RouteWrapper({
  component,
  ...rest
}) {

  let {
    view: View
  } = component;

  let { documentTitle, user } = rest;

  if (user && user.id) {

    if (rest.path === '/app/') {
      const userRoutes = getUserRoutes(user);
      const defaultRoute = userRoutes.find(x => x.default);
      if (defaultRoute) {
        return <Redirect to={defaultRoute.path} />
      }
    }

    const currentRoute = getCurrentRoute(rest.path);
    const hasAccess = !currentRoute.access || (currentRoute.access.roles.some(r => user.roleIds.includes(r)));
    if (!hasAccess) {
      View = NoAccess;
    }
  }
  else {
    return null;
  }

  return (
    <Route
      {...rest}
      render={props => (
        <View
            {...props}
            documentTitle={documentTitle || "Navigator"}
        />
      )}
    />
  );
}

RouteWrapper.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.element, PropTypes.func]).isRequired
};
