import React, { Component } from "react";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { Loader } from "./loader";
import { AbstractDelete, AbstractEdit } from "../../../networking/apiCalls";
import _ from "lodash";
import { MyCommandCell } from "../../CRM/View/myCommandCell";
import { DropDownCell } from "../../CRM/View/dropDownCell.js";
import { serverApi } from "../../../networking/config";
import {
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
} from "@material-ui/core";
import customSelectStyles from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import { columnsSchema } from "../View/schema";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Window } from "@progress/kendo-react-dialogs";
import GridContainer from "../../../components/Grid/GridContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser, faSyncAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import Snackbar from "../../../components/Snackbar/Snackbar";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import ViewSettings from "../../common/ViewSettings";
import { NumericTextBox } from "@progress/kendo-react-inputs";

import { showCreatorOnTable } from "../../../lib/GeneralUtils/utils";
// import { Tooltip as KendoTooltip } from "@progress/kendo-react-tooltip";
// import TooltipCell from "../../common/tooltipCell";
import DropdownFilterCell from "../../common/dropdownFilterCell";
// import { NAV } from "navigatorsdk";
// import Button from "../../../components/CustomButtons/Button";
// import AddIcon from "@material-ui/icons/Add";
import Approvers from "./components/Approvers";

class ApprovalActionTiers extends Component {
  editField = "inEdit";
  CommandCell;

  constructor(props) {
    super(props);

    this.state = {
      modelNames: [],
      modelActions: [],
      selectedModelName: null,
      selectedModelAction: null,
      opsDomains: [],
      selectedDomain: null,
      opsDepartments: [],
      selectedDepartment: null,
      editFormVisible: false,
      originalDataItem: {},
      addFormVisible: false,
      approvalHistoryModal: false,
      similarityData: [],
      deleteVisible: false,
      visibleWindow: false,
      isDropOpened: false,
      columns: (columnsSchema && columnsSchema()) || [],
      initialColumns: (columnsSchema && columnsSchema()) || [],
      gridData: {
        data: [],
        total: 0,
      },
      initialGridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 100,
        skip: 0,
      },
      openErrorModal: false,
      errorModalContent: "",
      delay: 400,
      loading: false,
      selectedRowIndex: 0,
      selectedItem: null,
      googleOptions: [],
      expandedRowIndex: null,
      dataItem: {},
      validationErrors: {},
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      hasDisable: false,
      showSecondAction: true,
      showFirstAction: true,
    });
  }

  async componentDidMount() {
    let id = this.props.match.params.id;
    if (this.props.match.params.id !== "" && id) {
      let tempFilters = {
        filter: {
          logic: "and",
          filters: [{ field: "id", operator: "contains", value: `${id}` }],
        },
        skip: 0,
        take: 100,
      };
      window.localStorage.setItem("finalParams", JSON.stringify(tempFilters));
    }

    let isFirstLoad = true;
    window.localStorage.setItem("isFirstLoad", isFirstLoad);

    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));

    if (hasFinalParams) {
      this.setState({
        ...this.state,
        dataState: hasFinalParams,
      });
    }

    if (hasChangedColumns) {
      this.setState({
        ...this.state,
        columns: hasChangedColumns,
      });
    }

    const opsDomains = (this.props.user?.departments || [])
      .filter((department) => department.type === "OPS")
      .flatMap((department) => department.domains.map((domain) => domain))
      .filter(
        (domain, index, self) =>
          index === self.findIndex((d) => d.description === domain.description)
      );

    const getApprovalActionData = await serverApi(
      "GET",
      "approvalActions",
      "",
      "",
      {
        token: window.localStorage.getItem("access_token"),
      }
    );

    let modelNames = getApprovalActionData.data.map(
      (action) => action.modelName
    );

    this.setState({
      opsDomains: opsDomains,
      selectedDomain: null,
      opsDepartments: [],
      modelNames: modelNames,
      approvalActions: null,
      getApprovalActionData: getApprovalActionData.data,
    });
  }

  componentWillUnmount() {
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));
    if (hasChangedColumns) {
      window.localStorage.removeItem("cols");
    }

    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    if (hasFinalParams) {
      window.localStorage.removeItem("finalParams");
    }

    let hasFlagEnabled = window.localStorage.getItem("flagSet");
    if (hasFlagEnabled) {
      window.localStorage.removeItem("flagSet");
    }

    let hasExportFilter = window.localStorage.getItem("exportFilter");
    if (hasExportFilter) {
      window.localStorage.removeItem("exportFilter");
    }
  }

  dataStateChange = (e) => {
    const reqGridParams = e.data;
    window.localStorage.setItem("finalParams", JSON.stringify(reqGridParams));

    this.setState({
      ...this.state,
      dataState: reqGridParams,
    });
  };

  dataReceived = (gridData, reqGridParams) => {
    const finalData = showCreatorOnTable(gridData);

    if (finalData?.data?.length > 0) {
      if (this.props.match.params.id !== "" && this.props.match.params.id) {
        let temp =
          finalData &&
          finalData.data &&
          finalData.data.filter(
            (x) =>
              x.approval_status === "Pending" ||
              x.approval_status === "Rejected"
          );
        if (temp.length > 0) {
          this.setState(
            {
              ...this.state,
            },
            () => {
              this.onToggle("warning");
            }
          );
        }
      }
    }

    this.setState({
      ...this.state,
      gridData: finalData,
      initialGridData: finalData,
      dataState:
        reqGridParams === !_.isEmpty(reqGridParams)
          ? reqGridParams
          : this.state.dataState, //TODO MAYBE PASS THE final params from local storage for persist the filters on REFRESH PAGE !
    });
  };

  renderGridColumns = (dataItem) => {
    let selectionCol = [];
    let normalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.field === "approval_status") {
          return (
            <Column
              field={temp.field}
              locked={temp.locked}
              filterable={temp.filterable}
              title={temp.title}
              width={210}
              filter={temp.filter}
              visible={temp.visible}
              // minGridWidth={"400"}
              filterCell={(props) => {
                return (
                  <>
                    <DropdownFilterCell
                      {...props}
                      filterable={true}
                      data={[
                        "Approved",
                        "Pending",
                        "Not approved",
                        "No response",
                      ].sort((a, b) => (a > b ? 1 : -1))}
                    />
                  </>
                );
              }}
              editable={
                temp.field === "id" ||
                  temp.field === "createdByForUi" ||
                  temp.field === "dateCreatedForUi" ||
                  temp.field === "ap_account" ||
                  temp.field === "ar_account"
                  ? false
                  : true
              } //id not editable on edit
              cell={(props) => {
                const color =
                  props.dataItem.approval_status === "Approved"
                    ? "#16b3169c"
                    : props.dataItem.approval_status === "Not approved"
                      ? "#f317008a"
                      : null;
                const style = {
                  color: color,
                  width: "150px",
                };
                return (
                  <>
                    <DropDownCell
                      {...props}
                      style={style}
                      filterable={true}
                      data={[
                        { text: "Pending", value: "Pending" },
                        { text: "Not approved", value: "Not approved" },
                        { text: "Approved", value: "Approved" },
                      ].sort((a, b) => (a > b ? 1 : -1))}
                    />
                  </>
                );
              }}
            // cell={cellWithBackGround}
            />
          );
        } else if (temp.isDropdown === "yes") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={
                temp.field === "id" ||
                  temp.field === "createdByForUi" ||
                  temp.field === "dateCreatedForUi" ||
                  temp.field === "ap_account" ||
                  temp.field === "ar_account"
                  ? false
                  : true
              } //id not editable on edit
            />
          );
        } else {
          return (
            <Column
              field={temp.field}
              locked={temp.locked}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={
                temp.field === "id" ||
                  temp.field === "createdByForUi" ||
                  temp.field === "dateCreatedForUi" ||
                  temp.field === "ap_account" ||
                  temp.field === "ar_account"
                  ? false
                  : true
              } //id not editable on edit
            />
          );
        }
      });

    return [...selectionCol, ...normalCols];
  };

  toggleDeleteDialog = () => {
    this.setState({ deleteVisible: !this.state.deleteVisible });
  };

  handleCheckboxChange = (e, field) => {
    const dataItem = {
      ...this.state.dataItem,
      [field]: e.value,
    };
    this.setState({ ...this.state, dataItem });
  };

  handleTextArea = (e, field) => {
    const dataItem = {
      ...this.state.dataItem,
      [field]: e.value,
    };
    this.setState({ dataItem }, () => {
      this.validateField(field, e.value);
    });
  };

  toggleWindow = () => {
    this.setState({
      ...this.state,
      editFormVisible: !this.state.editFormVisible,
      dataItem: {},
      originalDataItem: null,
    });
  };

  handleTextChange = (e, field) => {
    const dataItem = {
      ...this.state.dataItem,
      [field]: e.target.value,
    };
    this.setState({ ...this.state, dataItem });
  };

  createRecord = async () => {
    try {
      const payload = {
        amountFrom: this.state.amountFrom,
        deptId: this.state.selectedDepartment?.id || null,
        domainId: this.state.selectedDomain?.id || null,
        approvalActionId:
          (this.state.getApprovalActionData || []).find(
            (x) =>
              x.modelAction === this.state.selectedModelAction &&
              x.modelName === this.state.selectedModelName
          )?.id || null,
      };

      const requiredFields = ["deptId", "domainId", "approvalActionId"];
      let missingFields = [];

      requiredFields.forEach((field) => {
        if (
          payload[field] === undefined ||
          payload[field] === null ||
          payload[field] === ""
        ) {
          const displayName =
            field.charAt(0).toUpperCase() +
            field
              .slice(1)
              .replace(/([A-Z])/g, " $1")
              .trim();
          missingFields.push(displayName);
        }
      });

      if (missingFields.length > 0) {
        this.setState({
          showCreateErrors: true,
          showCreateErrorsMessages: `${missingFields.join(", ")} ${missingFields.length > 1 ? "are" : "is"
            } required.`,
        });
        return;
      }

      const response = await serverApi(
        "POST",
        "approvalActionTiers",
        "",
        payload,
        {
          token: window.localStorage.getItem("access_token"),
        },
        "",
        ""
      );
      if (response.status === 200) {
        this.setState(
          {
            ...this.state,
            addFormVisible: false,
            successMessage: "Approval action tier was successfully created",
            openSuccessMessage: true,
            modelNames: [],
            modelActions: [],
            selectedModelName: null,
            selectedModelAction: null,
            opsDomains: [],
            selectedDomain: null,
            opsDepartments: [],
            selectedDepartment: null,
            amountFrom: null,
          },
          () => {
            setTimeout(() => {
              this.handleSuccessSnackbar();
              this.refreshGridDataAfterCrud();
            }, 4000);
          }
        );
      }
    } catch (e) {
      this.setState(
        {
          openErrorModal: true,
          errorModalContent:
            (e.response &&
              e.response.data &&
              e.response.data.error &&
              e.response.data.error.message) ||
            "Create model setting definition failed, please try again",
        },
        () => {
          setTimeout(() => {
            this.setState({
              ...this.state,
              openErrorModal: false,
              errorModalContent: "",
            });
          }, 4000);
        }
      );
    }
  };

  handleColumnsChange = (event) => {
    let columns = this.state.columns;

    let oldColumns = columns.filter((c) => c.visible).map((c) => c.field);
    let newColumns = event.target.value;

    let unique1 = oldColumns.filter((c) => newColumns.indexOf(c) === -1);
    let unique2 = newColumns.filter((c) => oldColumns.indexOf(c) === -1);

    const columnLastUpdated = unique1.concat(unique2)[0];

    let index = columns.findIndex(
      (column) => column.field === columnLastUpdated
    );
    columns[index].visible = !columns[index].visible;

    this.setState(
      {
        ...this.state,
        columns: columns,
      },
      () => {
        let columns = this.state.columns;
        window.localStorage.setItem("cols", JSON.stringify(columns));
      }
    );
  };

  refreshGridDataAfterCrud = async () => {
    let token = window.localStorage.getItem("access_token");
    let options = {
      token: token,
    };
    let refreshParams = window.localStorage.getItem("refreshParams");
    refreshParams = JSON.parse(refreshParams);

    const responseData = await serverApi(
      "GET",
      "backofficeApprovalActionVs",
      refreshParams,
      "",
      options
    );

    const finalData = showCreatorOnTable(responseData);

    let count;
    const countMasterEntity = await serverApi(
      "GET",
      `backofficeApprovalActionVs/count?where=${JSON.stringify(
        refreshParams.filter.where
      )}`,
      "",
      "",
      options
    );
    if (countMasterEntity) {
      count = countMasterEntity.data.count;
    }

    let sendObj = {
      gridData: {
        data: finalData.data,
        total: count,
      },
    };

    this.dataReceived(sendObj.gridData, null);


    const opsDomains = (this.props.user?.departments || [])
      .filter((department) => department.type === "OPS")
      .flatMap((department) => department.domains.map((domain) => domain))
      .filter(
        (domain, index, self) =>
          index === self.findIndex((d) => d.description === domain.description)
      );

    const getApprovalActionData = await serverApi(
      "GET",
      "approvalActions",
      "",
      "",
      {
        token: window.localStorage.getItem("access_token"),
      }
    );

    let modelNames = getApprovalActionData.data.map(
      (action) => action.modelName
    );

    this.setState({
      opsDomains: opsDomains,
      selectedDomain: null,
      opsDepartments: [],
      modelNames: modelNames,
      approvalActions: null,
      getApprovalActionData: getApprovalActionData.data,
    });
  };

  toggleDeleteDialog(dataItem = {}, state) {
    this.setState({
      ...state,
      deleteVisible: !this.state.deleteVisible,
      itemForDelete: dataItem,
    });
  }

  enterEdit = (dataItem) => {
    this.applyNewColumnsOnGrid();

    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    let total = this.state.gridData.total;

    this.setState(
      {
        gridData: {
          data:
            this.state.gridData &&
            this.state.gridData.data.map((item) =>
              item.id === dataItem.id ? { ...item, inEdit: true } : item
            ),
          total: total,
        },
        initialGridData: {
          data:
            this.state.initialGridData &&
            this.state.initialGridData.data.map((item) =>
              item.id === dataItem.id ? { ...item, inEdit: true } : item
            ),
          total: total,
        },
      },
      () => {
        this.setState({
          ...this.state,
          itemInEditMode: { ...dataItem },
        });
      }
    );
  };

  remove = async (dataItem) => {
    const data = [...this.state.gridData.data];

    let index = data.findIndex(
      (p) => p === dataItem || (dataItem.id && p.id === dataItem.id)
    );
    if (index >= 0) {
      data.splice(index, 1);
    }

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let url = "approvalActionTiers";

    try {
      let deleteItemRequest = await AbstractDelete(url, dataItem.id, options);

      if (deleteItemRequest.status === 200) {
        //clear the column filters if grid data is 0 or let them if grid data is >= 1
        if (data.length <= 0) {
          this.setState({
            ...this.state,
            gridData: {
              data: data,
              total: data.length,
            },
            dataState: {
              skip: 0,
              take: 100,
            },
          });
        } else {
          this.setState({
            ...this.state,
            gridData: {
              data: data,
              total: data.length,
            },
          });
        }
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent:
          (e?.response?.data?.error?.message) ||
          "Please try again",
      });
    }
  };

  disableRecord = async (dataItem) => {
    this.toggleDeleteDialog(dataItem, this.state);
  };

  add = async (dataItem) => { };

  discard = (dataItem, hasError = false) => {
    let data = [...this.state.gridData.data];
    const total = this.state.gridData.total;

    if (hasError) {
      data[0].inEdit = true;

      this.setState(
        {
          ...this.state,
          gridData: {
            data: data,
            total: total,
          },
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 4000);
        }
      );
    } else {
      this.removeItem(data, dataItem);

      this.setState(
        {
          ...this.state,
          gridData: {
            data: data,
            total: total,
          },
          visible: false,
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 3000);
        }
      );
    }
  };

  update = async (dataItem) => {
    const data = [...this.state.gridData.data];
    const updatedItem = { ...dataItem, inEdit: undefined };

    this.updateItem(data, updatedItem);

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let editPayload = updatedItem;
    if (editPayload.expanded) delete editPayload.expanded;

    if (
      !editPayload.amountFrom ||
      !Number.isInteger(Number(editPayload.amountFrom))
    ) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            "Amount From should be an integer",
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 3000);
        }
      );
      return;
    }
    else {
      editPayload = {
        amountFrom: parseInt(updatedItem.amountFrom),
      };
      try {
        const editRequest = await AbstractEdit(
          dataItem.id,
          editPayload,
          `approvalActionTiers`,
          options
        );
        // const countMasterEntity = await serverApi('GET', 'assets/count', '', '', options)

        if (editRequest.status === 200) {
          this.setState(
            {
              ...this.state,
              gridData: {
                data: data,
                total: this.state.gridData.total,
              },
              successMessage: "Record updated successfully",
              openSuccessMessage: true,
            },
            () => {
              setTimeout(() => {
                this.handleSuccessSnackbar();
                this.applyInitialColumnsOnGrid();
              }, 900);
            }
          );
        }
      } catch (e) {
        let initialGridData = this.state.initialGridData;

        this.setState(
          {
            ...this.state,
            gridData: initialGridData,
            openErrorModal: true,
            errorModalContent:
              e.response.data.error.message || "Please try again",
          },
          () => this.discard(dataItem, true)
        );
      }
    }
  };

  cancel = (dataItem) => {
    const originalItem =
      this.state.itemInEditMode ||
      this.state.gridData.data.find((p) => p.id === dataItem.id);
    if (originalItem) {
      if (originalItem.inEdit) {
        originalItem.inEdit = false;
      }
    }

    const data =
      (this.state.gridData &&
        this.state.gridData.data.map((item) =>
          item.id === originalItem?.id ? originalItem : item
        )) ||
      (this.state.initialGridData &&
        this.state.initialGridData.data.map((item) =>
          item.id === originalItem?.id ? originalItem : item
        ));

    const total = this.state.gridData.total;

    this.setState(
      {
        ...this.state,
        validateWithFleetmon: false,
        gridData: {
          data: data,
          total: total,
        },
        errorModalContent:
          this.state.errorModalContent !== ""
            ? ""
            : this.state.errorModalContent,
        openErrorModal:
          this.state.openErrorModal !== true
            ? false
            : this.state.errorModalContent,
      },
      () => this.applyInitialColumnsOnGrid()
    );
  };

  addNew = async () => {
    this.setState({
      ...this.state,
      addFormVisible: true,
    });
  };

  updateItem = (data, item) => {
    let index = data.findIndex(
      (p) => p === item || (item.id && p.id === item.id)
    );
    if (index >= 0) {
      data[index] = { ...item };
    }
  };

  applyNewColumnsOnGrid = () => {
    let columns = this.state.initialColumns;
    let newColumns = columns.filter(
      (x) =>
        x.field === "amountFrom"
    );
    newColumns.forEach((x) => {
      if (x.field === "id") return;
      x.minWidth = 380;
    });
    this.setState({
      ...this.state,
      hideColumns: true,
      columns: newColumns,
      initialColumns: columns,
    });
  };

  applyInitialColumnsOnGrid = () => {
    let initialColumns = this.state.initialColumns;
    let newColumns = initialColumns;
    this.setState({
      ...this.state,
      hideColumns: false,
      columns: newColumns,
    });
  };

  itemChange = (event) => {
    const { field, value, dataItem } = event;

    const total = this.state.gridData.total;
    const data =
      this.state.gridData &&
      this.state.gridData.data.map((item) =>
        item.id === event.dataItem.id
          ? { ...item, [event.field]: event.value }
          : item
      );

    this.setState({
      gridData: {
        data: data,
        total: total,
      },
    });
  };

  removeItem(data, item) {
    let index = data.findIndex(
      (p) => p === item || (item.id && p.id === item.id)
    );
    if (index >= 0) {
      data.splice(index, 1);
    }
  }

  onClearFilters = () => {
    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));

    let init = JSON.stringify({ take: 100, skip: 0 });
    if (hasFinalParams && hasFinalParams.filter)
      window.localStorage.setItem("finalParams", init);

    this.dataStateChange({ data: { take: 100, skip: 0 } });
  };

  closeErrorModal = () => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      errorModalContent: "",
    });
  };

  expandChange = (event) => {
    window.localStorage.setItem("expandeId", event.dataItem.id);

    //if add new vessel is true then expand cannot be done !
    if (this.state.gridData.data[0].inEdit === true) {
      return;
    } else {
      event.dataItem.expanded = !event.dataItem.expanded;
    }

    this.forceUpdate();
  };

  handleErrorClose = (loginError) => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      openErrorModalContent: "",
    });
  };

  deleteAction = async () => {
    this.setState({
      ...this.state,
      deleteVisible: false,
    });

    let dataItem = this.state.itemForDelete;

    const data = [...this.state.gridData.data];

    let index = data.findIndex(
      (p) => p === dataItem || (dataItem.id && p.id === dataItem.id)
    );
    if (index >= 0) {
      data.splice(index, 1);
    }

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let url = "backofficeApprovalActionVs";

    try {
      let deleteItemRequest = await AbstractDelete(url, dataItem.id, options);

      if (deleteItemRequest.status === 200) {
        //clear the column filters if grid data is 0 or let them if grid data is >= 1
        if (data.length <= 0) {
          this.setState({
            ...this.state,
            gridData: {
              data: data,
              total: data.length,
            },
            dataState: {
              skip: 0,
              take: 100,
            },
            deleteVisible: false,
          });
        } else {
          this.setState({
            ...this.state,
            gridData: {
              data: data,
              total: this.state.gridData.total - 1,
            },
            dataState: {
              take: this.state.dataState.take,
              skip: this.state.dataState.skip,
            },
            deleteVisible: false,
          });
        }
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent:
          (e.response.data && e.response.data.error.message) ||
          "Please try again",
      });
    }
  };

  disableAction = async () => { };

  handleSuccessSnackbar = () => {
    this.setState({
      ...this.state,
      successMessage:
        this.state.openSuccessMessage === true ? this.state.successMessage : "",
      openSuccessMessage: !this.state.openSuccessMessage,
    });
  };

  toggleSimilarityWindow = () => {
    this.setState({
      similarityWindow: !this.state.similarityWindow,
    });
  };

  filterChange = (event) => {
    let filteredResults = this.filterData(event.filter);

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.setState({
        countries:
          filteredResults !== undefined
            ? filteredResults
            : this.state.initialCountries,
        loading: false,
      });
    }, this.state.delay);

    this.setState({
      ...this.state,
      loading: true,
    });
  };

  filterData(filter) {
    if (filter.value === "") {
      this.setState({
        countries: this.state.initialCountries,
      });
      return;
    } else {
      const data = this.state.initialCountries;
      filter.operator = "startswith";
      let filteredResults = filterBy(data, filter);
      return filteredResults;
    }
  }

  onDialogInputChange = (event) => {
    let target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.props ? target.props.name : target.name;

    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleDropDownChange = (e, field) => {
    if (field === "modelName") {
      this.setState({ currentModel: e.target.value, [field]: e.target.value });
    } else {
      this.setState({ currentValue: e.target.value, [field]: e.target.value });
    }
  };

  exportToCsv = async (state, props) => {
    const count = state.gridData.total;
    const where = window.localStorage.getItem("exportFilter") || {};
    const numberOfCalls = Math.min(
      Math.round(count / 1000) + 1,
      5 // Limit to 5000
    );
    let finalSort = this.state.dataState?.sort?.length
      ? this.state.dataState.sort[0].field +
      " " +
      this.state.dataState.sort[0].dir
      : "id asc";

    const promises = [];
    for (let i = 0; i < numberOfCalls; i++) {
      promises.push(
        this.getDataPromise({
          skip: i * 1000,
          limit: 1000,
          where: !_.isEmpty(where) ? JSON.parse(where) : {},
          order: [finalSort],
        })
      );
    }

    try {
      const responses = await Promise.all(promises);
      let allData = [];
      responses.forEach((resp) => {
        allData = [...allData, ...resp.data];
      });
      if (allData.length > 0) {
        this.setState({
          ...this.state,
          exportData: allData,
        });
        // this._export.save(allData, this.state.columns);
      }
    } catch (e) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            (e.response.data && e.response.data.error.message) ||
            "Please try again",
        },
        () => {
          setTimeout(() => {
            this.setState({
              ...this.state,
              openErrorModal: false,
              openErrorModalContent: "",
            });
          }, 3000);
        }
      );
    }
  };

  getDataPromise = (filter) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    return new Promise((resolve, reject) => {
      const masterEntityRequest = serverApi(
        "GET",
        `backofficeApprovalActionVs?filter=${JSON.stringify(filter)}`,
        "",
        "",
        options
      );
      masterEntityRequest
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getExportData = async () => {
    await this.exportToCsv(this.state, this.props);
  };

  handleDepartmentChange = (e) => {
    this.setState({ ...this.state, selectedDepartment: e.target.value });
  };

  fetchDepartments = async (selectedDomain) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    try {
      let filter1 = {
        filter: {
          where: {
            domainID: selectedDomain.target.value.id,
          },
          include: ["department"],
        },
      };
      const attachedDepartments = await serverApi(
        "GET",
        `departmentToDomains`,
        filter1,
        "",
        options
      );

      this.setState({
        selectedDomain: selectedDomain.target.value,
        opsDepartments: attachedDepartments.data.map(x => x.department),
        selectedDepartments: [],
      });



    } catch (error) {

    }
  }

  handleDomainChange = async (selectedDomain) => {
    this.fetchDepartments(selectedDomain)
    this.setState({
      selectedDomain: selectedDomain.target.value,
    });
  };

  handleModelNameChange = (selectedModelName) => {
    const modelActions = (this.state.getApprovalActionData || [])
      .filter((action) => action.modelName === selectedModelName.target.value)
      .map((action) => action.modelAction);

    this.setState({
      selectedModelName: selectedModelName.target.value,
      modelActions: modelActions,
      selectedModelAction: null,
    });
  };

  handleModelActionChange = (e) => {
    this.setState({ ...this.state, selectedModelAction: e.target.value });
  };

  renderForm = (isEdit) => {
    return (
      <div>
        <fieldset style={{ borderColor: "rgba(13, 88, 105, 1)" }}>
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1, paddingRight: "10px" }}>
              <div>
                <h5>Domains</h5>
                <DropDownList
                  data={this.state.opsDomains || []}
                  value={this.state.selectedDomain || ""}
                  textField="description"
                  dataItemKey="id"
                  style={{ width: "100%" }}
                  filterable={true}
                  name="domain"
                  onChange={(e) => this.handleDomainChange(e)}
                  placeholder="Select a domain"
                />
              </div>

              <div>
                <h5>Departments</h5>
                <DropDownList
                  data={this.state.opsDepartments || []}
                  value={this.state.selectedDepartment || ""}
                  textField="name"
                  dataItemKey="id"
                  style={{ width: "100%" }}
                  filterable={true}
                  name="department"
                  onChange={(e) => this.handleDepartmentChange(e)}
                  placeholder="Select a department"
                  disabled={!this.state.selectedDomain}
                />
              </div>

              <div>
                <h5>Model Name</h5>
                <DropDownList
                  data={[...new Set(this.state.modelNames)] || []} // Convert the Set to an array
                  value={this.state.selectedModelName || ""}
                  style={{ width: "100%" }}
                  filterable={true}
                  name="modelName"
                  onChange={(e) => this.handleModelNameChange(e)}
                  placeholder="Select a model name"
                />
              </div>

              <div>
                <h5>Model Action</h5>
                <DropDownList
                  data={this.state.modelActions || []}
                  value={this.state.selectedModelAction || ""}
                  style={{ width: "100%" }}
                  filterable={true}
                  name="modelAction"
                  onChange={(e) => this.handleModelActionChange(e)}
                  placeholder="Select a model action"
                  disabled={!this.state.selectedModelName}
                />
              </div>

              <span>
                <label>
                  Amount From
                  <br />
                  <NumericTextBox
                    name="amountFrom"
                    value={this.state.amountFrom || ""}
                    onChange={this.onDialogInputChange}
                  />
                </label>
              </span>
            </div>
          </div>

          <br />
          <button
            style={{ marginRight: "5px" }}
            type="button"
            className="k-button k-primary"
            onClick={this.createRecord}
            disabled={false}
          >
            {isEdit ? "Update" : "Create"}
          </button>
          {!isEdit && this.state.showCreateErrors && (
            <h4 style={{ color: "orange" }}>
              {this.state.showCreateErrorsMessages}
            </h4>
          )}
        </fieldset>
      </div>
    );
  };

  render() {
    const { columns, gridData } = this.state;
    const hasEditedItem = gridData && gridData.data.some((p) => p.inEdit);

    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    let hasColumnFilters = true;
    if (found) {
      hasColumnFilters = false;
    }

    const { hasBackofficeRead } = this.props;

    return (
      <div>
        <Grid
          {...this.state.dataState}
          {...this.state.gridData}
          filterable={hasColumnFilters}
          style={{ height: "92vh" }}
          sortable={true}
          resizable
          editField={this.editField}
          onItemChange={this.itemChange}
          onDataStateChange={this.dataStateChange}
          expandField="expanded"
          onExpandChange={this.expandChange}
          pageable={{
            buttonCount: 5,
            info: true,
            pageSizes: [100, 200, 500, 1000, 1500],
          }}
          detail={(dataItem) => (
            <div>
              <Approvers {...dataItem} hasBackofficeRead={hasBackofficeRead} />
            </div>
          )}
        >
          <GridToolbar>
            <GridContainer xs={12} direction={"row"}>
              <Tooltip
                justify={"flex-start"}
                placement="top"
                title={"Add Approval Action Manually"}
              >
                <IconButton onClick={this.addNew}>
                  <FontAwesomeIcon
                    color="#0D5869"
                    onClick={this.addNew}
                    size="1.6x"
                    icon={faPlus}
                  />
                </IconButton>
              </Tooltip>

              <Tooltip
                justify={"flex-end"}
                placement="top"
                title={"Clear Column Filters"}
              >
                <IconButton
                  disabled={false}
                  aria-label=""
                  onClick={(e) => this.onClearFilters(e)}
                  look="flat"
                >
                  <FontAwesomeIcon
                    color="#0D5869"
                    size="1.6x"
                    icon={faEraser}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip
                justify={"flex-start"}
                placement="top"
                title={"Refresh Data"}
              >
                <IconButton
                  disabled={false}
                  aria-label=""
                  onClick={this.refreshGridDataAfterCrud}
                >
                  <FontAwesomeIcon color="#0D5869" size="1x" icon={faSyncAlt} />
                </IconButton>
              </Tooltip>
              <ViewSettings
                {...this.props}
                export={{
                  data: this.state.exportData || [],
                  exportFunction: this.getExportData,
                  fileName: "backofficeApprovalActionVs_",
                  columns: columnsSchema(),
                }}
                viewSettings={{
                  type: "",
                }}
                refreshSettings={{
                  data: [],
                }}
              />
              {!this.state.hideColumns ? (
                <div style={{ marginTop: hasBackofficeRead ? "5px" : "5px" }}>
                  <FormControl className={customSelectStyles.selectFormControl}>
                    <InputLabel
                      htmlFor="multiple-select"
                      className={customSelectStyles.selectLabel}
                    />
                    <Select
                      autoWidth={true}
                      multiple
                      value={columns
                        .filter((column) => column.visible)
                        .map((column) => column.field)}
                      onChange={this.handleColumnsChange}
                      MenuProps={{ className: customSelectStyles.selectMenu }}
                      inputProps={{
                        name: "multipleSelect",
                        id: "multiple-select",
                      }}
                      renderValue={(selected) => "Columns"}
                    >
                      {columns
                        .filter((col) => !col.notShownInTableColumnSelection)
                        .map((column) => {
                          let name = column.title;
                          let value = column.field;
                          let isVisible = column.visible;
                          let isDisabled = column.noDisabledColumn;

                          return (
                            <MenuItem
                              style={{ backgroundColor: "white" }}
                              key={name}
                              value={value}
                              disabled={isDisabled}
                            >
                              <Checkbox color={"default"} checked={isVisible} />
                              <ListItemText
                                style={{ marginLeft: "7px" }}
                                primary={name}
                              />
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
              ) : null}
            </GridContainer>
          </GridToolbar>

          {!hasBackofficeRead && (
            <Column
              cell={this.CommandCell}
              title="Actions"
              filterable={false}
              width={hasEditedItem ? "240px" : "240px"}
            />
          )}

          {this.renderGridColumns(this.props.dataItem)}
        </Grid>

        <Loader
          dataState={this.state.dataState}
          onDataRecieved={this.dataReceived}
          include={[""]}
          getURL={"backofficeApprovalActionVs"}
          countURL={"backofficeApprovalActionVs/count"}
          view={""}
        />

        <Snackbar
          place="tc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"warning"}
          message={this.state.errorModalContent}
          open={this.state.openErrorModal}
        />

        <Snackbar
          place="tc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"success"}
          message={this.state.successMessage}
          open={this.state.openSuccessMessage}
        />

        {this.state.editFormVisible && (
          <Window
            title={`Update record: ${this.state.dataItem.id}`}
            onClose={this.toggleWindow}
            width={800}
            height={690}
          >
            {this.renderForm(true)}
          </Window>
        )}

        {this.state.addFormVisible && (
          <Window
            title={`Create record`}
            onClose={() =>
              this.setState({ ...this.state, addFormVisible: false })
            }
            width={800}
            height={690}
          >
            {this.renderForm(false)}
          </Window>
        )}
      </div>
    );
  }
}

export default ApprovalActionTiers;
