import React from "react";
import {
  Grid,
  GridColumn as Column,
  GridDetailRow,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { columnsSchema } from "./schema";
import { MyCommandCell } from "../../../../Clients/View/components/CellHOC";
import _ from "lodash";
import { DropDownCell } from "../../../../CRM/View/dropDownCell";
import GridContainer from "../../../../../components/Grid/GridContainer";
import { serverApi } from "../../../../../networking/config";
import { AbstractDelete } from "../../../../../networking/apiCalls";
import { Window } from "@progress/kendo-react-dialogs";
import { filterBy } from "@progress/kendo-data-query";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { AutoCompleteVesselName } from "../../../../Clients/View/components/vesselNameDropDown";
import { Error } from "@progress/kendo-react-labels";
import { IconButton, Tooltip } from "@material-ui/core";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ClientToVesselExpanded extends GridDetailRow {
  editField = "inEdit";
  CommandCell;
  _export;

  constructor(props) {
    super(props);

    this.state = {
      visibleWindow: false,
      isDropOpened: false,
      columns: (columnsSchema && columnsSchema()) || [],
      gridData: {
        data: [],
        total: 10,
      },
      initialGridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 10,
        skip: 0,
      },
      openErrorModal: false,
      errorModalContent: "",
      delay: 400,
      loading: false,
      vessel_names: [],
      windowError: false,
      windowErrorMessage: "",
      disableEditButton: true,
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      disableEditButton: false,
      showSecondAction: true,
      showFirstAction: false,
    });
  }

  async componentDidMount() {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let filter1 = {
      filter: {
        where: {
          clientID: this.props.dataItem && this.props.dataItem.id,
        },
        include: ["vessel"],
      },
    };

    const responseData = await serverApi(
      "GET",
      `vesselToClients`,
      filter1,
      "",
      options
    );
    const getVessels = await serverApi("GET", `vessels`, "", "", options);

    if (responseData && responseData.data.length === 1) {
      this.setState({
        ...this.state,
        clientID: this.props.dataItem.id,
        gridData: {
          data: [responseData && responseData.data[0]],
          total: 1,
        },
        initialGridData: {
          data: [responseData && responseData.data[0]],
          total: 1,
        },
        vessel_names: getVessels.data.sort((a, b) =>
          a.relation_name > b.relation_name ? 1 : -1
        ),
        valueVesselName: "",
      });
    } else if (responseData && responseData.data.length > 1) {
      let finalData = [];
      _.forEach(responseData.data, (temp) => {
        finalData.push(temp);
      });

      this.setState({
        ...this.state,
        clientID: this.props.dataItem.id,
        gridData: {
          data: finalData,
          total: finalData.length,
        },
        initialGridData: {
          data: finalData,
          total: finalData.length,
        },
        vessel_names: getVessels.data,
      });
    } else {
      this.setState({
        ...this.state,
        clientID: this.props.dataItem.id,
        gridData: {
          data: [],
          total: 0,
        },
        initialGridData: {
          data: [],
          total: 0,
        },
        vessel_names: getVessels.data,
      });
    }
  }

  exportToCsv = () => {
    //todo make export without count just like v2.
    this._export.save(this.state.gridData.data, this.state.columns);
  };

  renderGridColumns = (dataItem, changeTheCellComponent) => {
    let selectionCol = [];
    let normalCols = [];
    let finalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            width="65px"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.isFilterBoolean === "yes") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              cell={DropDownCell}
            />
          );
        } else if (temp.field === "vessel.vessel_name") {
          return (
            <Column
              field="vessel.vessel_name"
              title="NAME"
              width="365px"
              cell={AutoCompleteVesselName}
            />
          );
        } else {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={temp.field === "id" || temp.editable ? false : true} //id not editable on edit
            />
          );
        }
      });

    finalCols = [...selectionCol, ...normalCols];
    return finalCols;
  };

  onChange = (event) => {
    const { value } = event.target;

    // Check if the value matches any vessel names
    const matchedVessel = this.state.vessel_names.find(
      (vessel) => vessel.vessel_name === value
    );

    if (matchedVessel) {
      // User has selected a vessel from the dropdown
      this.setState({
        valueVesselName: matchedVessel.vessel_name, // Update the input field with the selected name
        postVesselID: matchedVessel.id || "",
        vessel_imo_no: matchedVessel.vessel_imo_no || "",
        vessel_type: matchedVessel.vessel_type || "",
        openedVesselNames: false, // Close the dropdown
      });
    } else {
      // User is editing the input, reset vessel details and fetch new data
      this.setState({
        valueVesselName: value,
        postVesselID: "",
        vessel_imo_no: "",
        vessel_type: "",
        openedVesselNames: true,
      });

      if (value.length >= 3) {
        this.fetchAutocompleteData(value);
      }
    }
  };

  updateVesselDetails = (vessel) => {
    this.setState({
      postVesselID: vessel.id || "",
      vessel_imo_no: vessel.vessel_imo_no || "",
      vessel_type: vessel.vessel_type || "",
    });
  };

  onBlur = () => {
    const matchedVessels = this.state.vessel_names.filter(
      (vessel) => vessel.vessel_name === this.state.valueVesselName
    );

    if (matchedVessels.length === 1) {
      this.updateVesselDetails(matchedVessels[0]);
    }

    this.setState({ openedVesselNames: false });
  };

  fetchAutocompleteData = (filterValue) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    const fetchDropdownData = serverApi(
      "GET",
      `vessels`,
      {
        filter: {
          where: {
            vessel_name: { regexp: `/${filterValue}/i` },
          },
          order: ["vessel_name asc"],
        },
      },
      "",
      options
    );

    fetchDropdownData
      .then((response) => {
        this.setState({
          ...this.state,
          vessel_names: response.data,
        });
      })
      .catch((error) => {
        console.log(error);

        throw error;
      });
  };

  filterData = (value) => {
    const filter = {
      value: value,
      operator: "startswith",
      ignoreCase: true,
    };

    return filterBy(
      this.state.vessel_names.map((vessel) => vessel.vessel_name),
      filter
    );
  };

  enterEdit = (dataItem) => {
    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    let total = this.state.gridData.total;

    this.setState({
      gridData: {
        data:
          this.state.gridData &&
          this.state.gridData.data.map((item) =>
            item.id === dataItem.id ? { ...item, inEdit: true } : item
          ),
        total: total,
      },
    });
  };

  discard = (dataItem, hasError = false) => {
    let data = [...this.state.gridData.data];
    const total = this.state.gridData.total;

    if (hasError) {
      data[0].inEdit = true;
      this.setState({
        gridData: {
          data: data,
          total: total,
        },
      });
    } else {
      this.removeItem(data, dataItem);

      this.setState({
        gridData: {
          data: data,
          total: total,
        },
        visible: false,
      });
    }
  };

  cancel = (dataItem) => {
    const originalItem =
      this.state.initialGridData &&
      this.state.initialGridData.data.find((p) => p.id === dataItem.id);
    originalItem.inEdit = false;

    const data =
      this.state.gridData &&
      this.state.gridData.data.map((item) =>
        item.id === originalItem.id ? originalItem : item
      );

    const total = this.state.gridData.total;

    this.setState({
      ...this.state,
      gridData: {
        data: data,
        total: total,
      },
      openErrorModal: false,
      openErrorModalContent: "",
    });
  };

  removeItem(data, item) {
    let index = data.findIndex(
      (p) => p === item || item.id || p.id === item.id
    );
    if (index >= 0) {
      data.splice(index, 1);
    }
  }

  remove = async (dataItem) => {
    const data = [...this.state.gridData.data];

    let index = data.findIndex(
      (p) => p === dataItem || dataItem.id | (p.id === dataItem.id)
    );
    if (index >= 0) {
      data.splice(index, 1);
    }

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let url = "vesselToClients";

    try {
      let deleteItemRequest = await AbstractDelete(
        url,
        `${dataItem && dataItem.id}`,
        options
      );

      if (deleteItemRequest.status === 200) {
        //clear the column filters if grid data is 0 or let them if grid data is >= 1
        if (data.length <= 0) {
          this.setState({
            ...this.state,
            gridData: {
              data: data,
              total: data.length,
            },
            initialGridData: {
              data: data,
              total: data.length,
            },
            dataState: {
              skip: 0,
              take: 10,
            },
          });
        } else {
          this.setState({
            ...this.state,
            gridData: {
              data: data,
              total: data.length,
            },
            initialGridData: {
              data: data,
              total: data.length,
            },
          });
        }
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent:
          e?.response?.data?.error?.message || "Please try again",
      });
    }
  };

  add = (dataItem) => {};

  addNew = () => {
    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    const newDataItem = { inEdit: true };

    this.setState({
      gridData: {
        data: [newDataItem, ...this.state.gridData.data],
        total: this.state.gridData.total,
      },
    });
  };

  update = async (dataItem) => {
    const data = [...this.state.gridData.data];

    const clonedDateItem = _.cloneDeep(dataItem);
    let dbID = clonedDateItem.id;
    delete clonedDateItem.id;

    let x = JSON.stringify(clonedDateItem);
    let y = window.localStorage.getItem("editItem");

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    try {
      let editPayload = {};
      if (x !== y) {
        let final = JSON.parse(window.localStorage.getItem("editItem"));
        const updatedItem = { ...final, inEdit: undefined };

        this.updateItem(data, updatedItem);

        editPayload = {
          clientID: final.clientID,
          vesselID: final.vesselID,
        };
      } else {
        const updatedItem = { ...clonedDateItem, inEdit: undefined };
        this.updateItem(data, updatedItem);
        editPayload = updatedItem;
      }

      const editRequest = await serverApi(
        "PATCH",
        `vesselToClients/${dbID}`,
        {},
        editPayload,
        options
      );
      if (editRequest.status === 200) {
        let edit = await serverApi(
          "GET",
          `vesselToClients/?filter=%7B%22where%22:%7B%22clientID%22:${this.state.clientID}%7D,%22include%22:[%22vessel%22]%7D`,
          {},
          {},
          options
        );

        this.setState({
          ...this.state,
          gridData: {
            data: edit.data,
            total: edit.data.length,
          },
          visibleWindow: false,
        });
      }
      console.log(editRequest);
    } catch (e) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            e.response.data.error.message || "Please try again",
        },
        () => this.discard(dataItem, true)
      );
    }
  };

  updateItem = (data, item) => {
    let index = data.findIndex(
      (p) => p === item || (item.id && p.id === item.id)
    );
    if (index >= 0) {
      data[index] = { ...item };
    }
  };

  itemChange = (event) => {
    let fieldsArr = [
      "vessel.vessel_name",
      "vessel.vessel_imo_no",
      "vessel.vessel_type",
      "vessel.vessel_flag_iso",
      "vessel.vessel_build_dt",
      "vessel.vessel_builder",
      "vessel.vessel_scrapped_dt",
      "vessel.vessel_deadweight",
      "vessel.vessel_build_country",
    ];

    let fieldName;
    let index;
    if (fieldsArr.includes(event.field)) {
      index = fieldsArr.indexOf(event.field);
      fieldName = fieldsArr[index].split(".")[1];
    }

    const finalData = [];
    const total = this.state.gridData.total;

    let finalItem;

    this.state.gridData &&
      this.state.gridData.data.forEach((item) => {
        let vessel = {
          ...item.vessel,
          [fieldName]: event.value,
        };

        if (item.id === event.dataItem.id) {
          delete item.vessel;
          finalItem = {
            ...item,
            vessel,
          };
          finalData.push(finalItem);
        } else {
          finalItem = item;
          finalData.push(finalItem);
        }
      });

    this.setState(
      {
        gridData: {
          data: finalData,
          total: total,
        },
      },
      () => this.renderGridColumns(finalItem, true)
    );
  };

  refreshGridDataAfterCrud = async () => {};

  toggleWindow = (e) => {
    const data = this.state.initialGridData.data;

    this.setState({
      visibleWindow: !this.state.visibleWindow,
      gridData: {
        data: [...data],
        total: 10,
      },
      vessel_names: [],
      valueVesselName: "",
      windowError: false,
      windowErrorMessage: "",
    });
  };

  onSelect = (event) => {
    const selectedVessel = event.item;
    if (selectedVessel) {
      this.setState({
        postVesselID: selectedVessel.id || "",
        vessel_imo_no: selectedVessel.vessel_imo_no || "",
        vessel_type: selectedVessel.vessel_type || "",
        openedVesselNames: false,
      });
    }
  };

  listNoDataRender = (element) => {
    const noData = (
      <h4 style={{ fontSize: "1em" }}>
        <span className="k-icon k-i-warning" style={{ fontSize: "2.5em" }} />
        <br />
        <br />
        No vessels found
      </h4>
    );

    return React.cloneElement(element, { ...element.props }, noData);
  };

  checkIfPrimaryClientExist = async (vesselID) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    //check if there is vessel to client connection with the specific vessel id
    //if NOT -> primary flag -> TRUE and disabled
    //if YES -> switch primart flag -> user should choose
    const getVesselClientConnection = await serverApi(
      "GET",
      `vesselToClients?filter={"where":{"vesselID":${vesselID}}}`,
      "",
      "",
      options,
      ""
    );
    if (getVesselClientConnection?.data?.length > 0) {
      let primaryClient = getVesselClientConnection.data.filter(
        (x) => x.primary_flg === true
      );
      if (primaryClient.length > 0) {
        this.setState({
          ...this.state,
          primaryClient: primaryClient,
          vesselClientConnection: getVesselClientConnection?.data || [],
          primary_flg: false,
          windowError: true,
          windowErrorMessage: `The vessel with id: ${this.state.postVesselID}, has another primary client.\nThe new client will have primary flag false.Please wait for the process to finish.`,
        });
      }
    } else if (getVesselClientConnection?.data?.length === 0) {
      this.setState({
        ...this.state,
        vesselClientConnection: [],
        primary_flg: true,
      });
    }
  };

  saveVesselToClient = async (e) => {
    try {
      await this.checkIfPrimaryClientExist(this.state.postVesselID);

      setTimeout(async () => {
        try {
          let options = {
            token: window.localStorage.getItem("access_token"),
          };

          let payload = {
            clientID: this.state.clientID,
            vesselID: this.state.postVesselID,
            primary_flg: this.state.primary_flg,
          };

          let addVesselToClientRequest = await serverApi(
            "POST",
            "vesselToClients",
            {},
            payload,
            options
          );

          if (addVesselToClientRequest.status === 200) {
            this.setState(
              {
                ...this.state,
                visibleWindow: false,
              },
              async () => {
                let responseFinal = await serverApi(
                  "GET",
                  `vesselToClients?filter={"where":{"clientID":${this.state.clientID}},"include":["vessel"]}`,
                  {},
                  {},
                  options
                );

                this.setState({
                  ...this.state,
                  gridData: {
                    data: responseFinal.data || [],
                    total: responseFinal.data.length || null,
                  },
                  initialGridData: {
                    data: responseFinal.data || [],
                    total: responseFinal.data.length || null,
                  },
                  visibleWindow: false,
                  windowError: false,
                  windowErrorMessage: "",
                });
              }
            );
          }
        } catch (e) {
          let message =
            e.response.data.error.message ||
            "Attach vessel to client failed.Please try again and if the problem persist contact the administrator.";
          if (e?.response?.data?.error?.message.includes("unique constraint")) {
            message =
              "Duplicate id detected because the vessel you are trying to attach already exists.Please try with a new vessel.";
          }

          this.setState({
            ...this.state,
            visibleWindow: true,
            windowError: true,
            windowErrorMessage: message || "Please try again",
          });
        }
      }, 4000);
    } catch (e) {
      let message =
        "Attach vessel to client failed.Please try again and if the problem persist contact the administrator.";
      if (e?.response?.data?.error?.message.includes("unique constraint")) {
        message =
          "Duplicate id detected because the vessel you are trying to attach already exists.Please try with a new vessel.";
      }

      this.setState({
        ...this.state,
        visibleWindow: true,
        windowError: true,
        windowErrorMessage: message || "Please try again",
      });
    }
  };

  closeErrorModal = () => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      errorModalContent: "",
    });
  };

  handleErrorClose = (loginError) => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      openErrorModalContent: "",
    });
  };

  openErrorModal = (e) => {
    this.setState({
      ...this.state,
      openErrorModal: true,
      errorModalContent: e.response.data.error.message || "Please try again",
    });
  };

  handleRowClick = (event) => {
    if (!this.clickTimer) {
      // First click
      this.clickTimer = setTimeout(() => {
        // Single click action
        this.clickTimer = null; // Reset the timer
      }, 200); // Set an appropriate delay for detecting double-click
    } else {
      // Second click
      clearTimeout(this.clickTimer); // Cancel the single click action
      this.clickTimer = null; // Reset the timer
      // Double-click action

      // Perform any additional actions or logic
      const rowData = event.dataItem;
      const recordId = rowData.vesselID; // Replace `id` with the actual property name containing the record ID

      // Construct the URL for the record's details page
      let final = "http://localhost:5002";
      let environmentLocation =
        window && window.location && window.location.hostname;
      if (environmentLocation === "dev2.swift-navigator.com") {
        final = "https://dev2.swift-navigator.com";
      } else if (environmentLocation === "uat2.swift-navigator.com") {
        final = "https://uat2.swift-navigator.com";
      } else if (environmentLocation === "prod2.swift-navigator.com") {
        final = "https://prod2.swift-navigator.com";
      }
      const recordUrl = `${final}/app/master/vessels/${recordId}`;
      // Open the URL in a new browser tab
      window.open(recordUrl, "_blank");
    }
  };

  render() {
    const dataItem = this.props.dataItem;
    const { gridData } = this.state;
    const hasEditedItem = gridData && gridData.data.some((p) => p.inEdit);
    const { hasBackofficeRead } = this.props;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <div
          style={{
            width: "83%",
          }}
        >
          <h4>NAME : {dataItem && dataItem.relation_name}</h4>
          {this.state.openErrorModal ? (
            <Error style={{ color: "red" }}>
              <h3>{this.state.errorModalContent || ""}</h3>
            </Error>
          ) : null}

          <Grid
            {...gridData}
            onRowClick={this.handleRowClick}
            filterable={false}
            style={{ className: "grid-no-select" }}
            sortable={true}
            resizable
            editField={this.editField}
            onItemChange={this.itemChange}
            pageable={false}
          >
            <GridToolbar>
              <GridContainer xs={12} justify={"flex-start"} direction={"row"}>
                {!hasBackofficeRead && (
                  <Tooltip
                    justify={"flex-start"}
                    placement="top"
                    title={"Add Vessel"}
                  >
                    <IconButton onClick={this.toggleWindow}>
                      <FontAwesomeIcon
                        color="#0D5869"
                        onClick={this.toggleWindow}
                        size="1.6x"
                        icon={faPlus}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </GridContainer>
            </GridToolbar>

            {!hasBackofficeRead && (
              <Column
                cell={this.CommandCell}
                title="Actions"
                filterable={false}
                width={hasEditedItem ? "240px" : "220px"}
              />
            )}

            {this.renderGridColumns(dataItem)}
          </Grid>
        </div>
        {this.state.visibleWindow && (
          <Window
            title={`Attach vessel to the client below`}
            onClose={this.toggleWindow}
            width={1150}
            height={580}
            initialTop={10}
          >
            <div>
              <br />
              <fieldset style={{ borderColor: "rgba(13, 88, 105, 1)" }}>
                <br />
                <legend>{`Client Name: ${dataItem.relation_name}`}</legend>
                <div>
                  <AutoComplete
                    style={{ width: "450px" }}
                    data={this.state.vessel_names}
                    textField="vessel_name"
                    value={this.state.valueVesselName}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    onClose={this.onBlur}
                    label={"Vessel name"}
                    listNoDataRender={this.listNoDataRender}
                    // placeholder="Search client name"
                  />
                  {this.state.valueVesselName !== "" ? (
                    <div>
                      <h5>IMO</h5>
                      <input
                        className="k-textbox"
                        style={{ width: "450px" }}
                        name="vessel_imo_no"
                        value={this.state.vessel_imo_no || ""}
                        disabled={true}
                      />
                      <h5>TYPE</h5>
                      <input
                        className="k-textbox"
                        name="vessel_type"
                        style={{ width: "450px" }}
                        value={this.state.vessel_type || ""}
                        disabled={true}
                      />
                    </div>
                  ) : null}
                </div>
                <br />
                <br />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <button
                    style={{ justifyContent: "flex-end" }}
                    type="button"
                    className="k-button k-primary"
                    onClick={(e) => this.saveVesselToClient(e)}
                    disabled={this.state.valueVesselName !== "" ? false : true}
                  >
                    Save
                  </button>
                </div>
              </fieldset>
              {this.state.windowError ? (
                <Error style={{ color: "red" }}>
                  <h3>{this.state.windowErrorMessage || ""}</h3>
                </Error>
              ) : null}
            </div>
          </Window>
        )}
      </div>
    );
  }
}

export default ClientToVesselExpanded;
