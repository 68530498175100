import React from "react";
import {
  Grid,
  GridColumn as Column,
  GridDetailRow,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { columnsSchema } from "./schema";
import { MyCommandCell } from "../../../../../components/PrimaryActionTableCell/MyCommandCell";
import { serverApi } from "../../../../../networking/config";
import _ from "lodash";
import { DropDownCell } from "../../../../CRM/View/dropDownCell";
import GridContainer from "../../../../../components/Grid/GridContainer";
import { IconButton, Tooltip } from "@material-ui/core";
// import { Window } from "@progress/kendo-react-dialogs";
// import BlockUi from "react-block-ui";
import { Error } from "@progress/kendo-react-labels";
import { Checkbox } from "@progress/kendo-react-inputs";
import { AbstractDelete } from "../../../../../networking/apiCalls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import DropdownFilterCell from "../../../../common/dropdownFilterCell";

class CarrierToModality extends GridDetailRow {
  editField = "inEdit";
  CommandCell;
  _export;

  constructor(props) {
    super(props);

    this.state = {
      visibleWindow: false,
      isDropOpened: false,
      columns: (columnsSchema && columnsSchema()) || [],
      gridData: {
        data: [],
        total: 10,
      },
      initialGridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 10,
        skip: 0,
      },
      openErrorModal: false,
      errorModalContent: "",
      delay: 400,
      loading: false,
      contacts: [],
      windowError: false,
      windowErrorMessage: "",
      disableEditButton: true,

      //create properties
      contact_name: "",
      contact_country: "",
      contact_email: "",
      contact_type: "",
      wh_notification_flg: false,
      notify_stock_email: false,
      notify_stock_ws: false,
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      disableEditButton: false,
      showSecondAction: true,
      showFirstAction: true,
    });
  }

  async componentDidMount() {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let filter1 = {
      filter: {
        where: {
          relationID: this.props.dataItem && this.props.dataItem.id,
        },
        include: ["modality"],
      },
    };

    const attachedData = await serverApi(
      "GET",
      `carrierToModalities`,
      filter1,
      "",
      options
    );
    let clonedAttachedModalities = _.cloneDeep(attachedData);

    const getModalities = await serverApi(
      "GET",
      `lovs`,
      { filter: { where: { lov_type: "MODALITY" } } },
      "",
      options
    );

    let result = this.constructClientsAndExistingClientsArray(
      getModalities.data,
      clonedAttachedModalities.data
    );

    result[0].attachedData.forEach((item) => {
      let client = result[0].allModalities.find((x) => x.name === item.name);
      if (client) {
        client.checked = true;
      }
    });

    if (attachedData && attachedData.data.length === 1) {
      let finalAttachedClients = [];
      _.forEach(clonedAttachedModalities.data, (temp) => {
        finalAttachedClients.push({
          id: temp.id,
          checked: true,
          deleteID: temp.id,
          iataAirlineCodeNumber: temp.iataAirlineCodeNumber,
          iataCode: temp.iataCode,
          scac: temp.scac,
          name: temp.modality.lov_value,
        });
      });

      this.setState({
        ...this.state,
        ID: this.props.dataItem.id,
        gridData: {
          data: [finalAttachedClients[0]],
          total: 1,
        },
        initialGridData: {
          data: [finalAttachedClients[0]],
          total: 1,
        },
        modalities: getModalities.data.sort((a, b) =>
          a.relation_name > b.relation_name ? 1 : -1
        ),
        valueClientName: "",

        visibleClients: result[0].allModalities || [],
        finalUserClients: result[0].allModalities,
        initialFinalUserClients: result[0].allModalities,
        blocking: false,
      });
    } else if (attachedData && attachedData.data.length > 1) {
      let finalData = [];
      _.forEach(attachedData.data, (temp) => {
        finalData.push({
          id: temp.id,
          checked: true,
          deleteID: temp.id,
          iataAirlineCodeNumber: temp.iataAirlineCodeNumber,
          iataCode: temp.iataCode,
          scac: temp.scac,
          name: temp.modality.lov_value,
        });
      });
      finalData.sort((a, b) => (a.relation_name > b.relation_name ? 1 : -1));

      this.setState({
        ...this.state,
        relationID: this.props.dataItem.id, //todo
        gridData: {
          data: finalData,
          total: finalData.length,
        },
        initialGridData: {
          data: finalData,
          total: finalData.length,
        },
        modalities: getModalities.data.sort((a, b) =>
          a.description > b.description ? 1 : -1
        ),

        visibleClients: result[0].allModalities || [],
        finalUserClients: result[0].allModalities,
        initialFinalUserClients: result[0].allModalities,
        blocking: false,
      });
    } else {
      this.setState({
        ...this.state,
        relationID: this.props.dataItem.id, //todo
        gridData: {
          data: [],
          total: 0,
        },
        initialGridData: {
          data: [],
          total: 0,
        },
        modalities: getModalities.data,

        visibleClients: result[0].allModalities || [],
        finalUserClients: result[0].allModalities,
        initialFinalUserClients: result[0].allModalities,
        blocking: false,
      });
    }
  }

  renderGridColumns = (dataItem, changeTheCellComponent) => {
    let selectionCol = [];
    let normalCols = [];
    let finalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            width="65px"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.isFilterBoolean === "yes") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              cell={DropDownCell}
            />
          );
        } else if (temp.field === "name") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              filterCell={(props) => {
                return (
                  <>
                    <DropdownFilterCell
                      {...props}
                      width="240px"
                      data={(this.state.modalities || [])
                        .map((x) => x.lov_value)
                        .sort((a, b) => (a > b ? 1 : -1))}
                    />
                  </>
                );
              }}
              cell={(props) => {
                let stateData = (this.props.usaStates || [])
                  .map((x) => ({
                    text: x.lov_value_1,
                    value: x.lov_value_1,
                  }))
                  .filter((x) => x)
                  .sort((a, b) => (a.text > b.text ? 1 : -1))
                  .filter((obj) => obj.text !== null && obj.value !== null);
                return (
                  <>
                    <DropDownCell
                      {...props}
                      width="240px"
                      filterable={true}
                      data={stateData}
                    />
                  </>
                );
              }}
            />
          );
        } else {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={temp.field === "id" || temp.editable ? false : true}
            />
          );
        }
      });
    finalCols = [...selectionCol, ...normalCols];
    return finalCols;
  };

  constructClientsAndExistingClientsArray = (data, attachedData) => {
    let finalModalities = [];
    _.forEach(data, (temp1) => {
      finalModalities.push({
        name: temp1.lov_value,
        id: temp1.id,
        checked: false,
      });
    });

    let finalAttachedDomains = [];
    _.forEach(attachedData, (temp2) => {
      finalAttachedDomains.push({
        id: temp2.id,
        relationID: temp2.relationID,
        checked: true,
        deleteID: temp2.id,
        lovID: temp2.lovID,
        iataAirlineCodeNumber: temp2.iataAirlineCodeNumber,
        iataCode: temp2.iataCode,
        scac: temp2.scac,
        name: temp2.modality.lov_value,
      });
    });

    finalModalities.sort((a, b) => (a.name > b.name ? 1 : -1));
    finalAttachedDomains.sort((a, b) => (a.name > b.name ? 1 : -1));

    return [
      {
        allModalities: finalModalities,
        attachedData: finalAttachedDomains,
      },
    ];
  };

  refreshGridDataAfterCrud = async () => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    let filter1 = {
      filter: {
        where: {
          relationID: this.props.dataItem && this.props.dataItem.id,
        },
        include: ["modality"],
      },
    };

    const attachedData = await serverApi(
      "GET",
      `carrierToModalities`,
      filter1,
      "",
      options
    );
    let clonedAttachedModalities = _.cloneDeep(attachedData);

    const getModalities = await serverApi(
      "GET",
      `lovs`,
      { filter: { where: { lov_type: "MODALITY" } } },
      "",
      options
    );

    let result = this.constructClientsAndExistingClientsArray(
      getModalities.data,
      clonedAttachedModalities.data
    );

    result[0].attachedData.forEach((item) => {
      let client = result[0].allModalities.find((x) => x.name === item.name);
      if (client) {
        client.checked = true;
      }
    });

    if (attachedData && attachedData.data.length === 1) {
      let finalAttachedClients = [];
      _.forEach(clonedAttachedModalities.data, (temp) => {
        finalAttachedClients.push({
          id: temp.id,
          checked: true,
          deleteID: temp.id,
          iataAirlineCodeNumber: temp.iataAirlineCodeNumber,
          iataCode: temp.iataCode,
          scac: temp.scac,
          name: temp.modality.lov_value,
        });
      });

      this.setState({
        ...this.state,
        ID: this.props.dataItem.id,
        gridData: {
          data: [finalAttachedClients[0]],
          total: 1,
        },
        initialGridData: {
          data: [finalAttachedClients[0]],
          total: 1,
        },
        modalities: getModalities.data.sort((a, b) =>
          a.relation_name > b.relation_name ? 1 : -1
        ),
        valueClientName: "",

        visibleClients: result[0].allModalities || [],
        finalUserClients: result[0].allModalities,
        initialFinalUserClients: result[0].allModalities,
        blocking: false,
      });
    } else if (attachedData && attachedData.data.length > 1) {
      let finalData = [];
      _.forEach(attachedData.data, (temp) => {
        finalData.push({
          id: temp.id,
          checked: true,
          deleteID: temp.id,
          iataAirlineCodeNumber: temp.iataAirlineCodeNumber,
          iataCode: temp.iataCode,
          scac: temp.scac,
          name: temp.modality.lov_value,
        });
      });
      finalData.sort((a, b) => (a.relation_name > b.relation_name ? 1 : -1));

      this.setState({
        ...this.state,
        relationID: this.props.dataItem.id, //todo
        gridData: {
          data: finalData,
          total: finalData.length,
        },
        initialGridData: {
          data: finalData,
          total: finalData.length,
        },
        modalities: getModalities.data.sort((a, b) =>
          a.description > b.description ? 1 : -1
        ),

        visibleClients: result[0].allModalities || [],
        finalUserClients: result[0].allModalities,
        initialFinalUserClients: result[0].allModalities,
        blocking: false,
      });
    } else {
      this.setState({
        ...this.state,
        relationID: this.props.dataItem.id, //todo
        gridData: {
          data: [],
          total: 0,
        },
        initialGridData: {
          data: [],
          total: 0,
        },
        modalities: getModalities.data,

        visibleClients: result[0].allModalities || [],
        finalUserClients: result[0].allModalities,
        initialFinalUserClients: result[0].allModalities,
        blocking: false,
      });
    }
  };

  // toggleWindow = async (e) => {
  //   let options = {
  //     token: window.localStorage.getItem("access_token"),
  //   };

  //   let filter1 = {
  //     filter: {
  //       where: {
  //         relationID: this.props.dataItem && this.props.dataItem.id,
  //       },
  //       include: ["modality"],
  //     },
  //   };

  //   const attachedData = await serverApi(
  //     "GET",
  //     `carrierToModalities`,
  //     filter1,
  //     "",
  //     options
  //   );
  //   let clonedAttachedModalities = _.cloneDeep(attachedData);

  //   const getModalities = await serverApi(
  //     "GET",
  //     `lovs`,
  //     { filter: { where: { lov_type: "MODALITY" } } },
  //     "",
  //     options
  //   );

  //   let result = this.constructClientsAndExistingClientsArray(
  //     getModalities.data,
  //     clonedAttachedModalities.data
  //   );

  //   result[0].attachedData.forEach((item) => {
  //     let client = result[0].allModalities.find((x) => x.name === item.name);
  //     if (client) {
  //       client.checked = true;
  //     }
  //   });

  //   if (attachedData && attachedData.data.length === 1) {
  //     let finalAttachedClients = [];
  //     _.forEach(clonedAttachedModalities.data, (temp) => {
  //       finalAttachedClients.push({
  //         id: temp.id,
  //         relationID: temp.relationID,
  //         checked: true,
  //         deleteID: temp.id,
  //         lovID: temp.lovID,
  //         iataAirlineCodeNumber: temp.iataAirlineCodeNumber,
  //         iataCode: temp.iataCode,
  //         scac: temp.scac,
  //         name: temp.modality.lov_value,
  //       });
  //     });

  //     this.setState({
  //       ...this.state,
  //       visibleWindow: !this.state.visibleWindow,
  //       ID: this.props.dataItem.id,
  //       gridData: {
  //         data: [finalAttachedClients[0]],
  //         total: 1,
  //       },
  //       initialGridData: {
  //         data: [finalAttachedClients[0]],
  //         total: 1,
  //       },
  //       modalities: getModalities.data.sort((a, b) =>
  //         a.relation_name > b.relation_name ? 1 : -1
  //       ),
  //       valueClientName: "",

  //       visibleClients: result[0].allModalities || [],
  //       finalUserClients: result[0].allModalities,
  //       initialFinalUserClients: result[0].allModalities,
  //       blocking: false,
  //     });
  //   } else if (attachedData && attachedData.data.length > 1) {
  //     let finalData = [];
  //     _.forEach(attachedData.data, (temp) => {
  //       finalData.push({
  //         id: temp.id,
  //         relationID: temp.relationID,
  //         checked: true,
  //         deleteID: temp.id,
  //         lovID: temp.lovID,
  //         iataAirlineCodeNumber: temp.iataAirlineCodeNumber,
  //         iataCode: temp.iataCode,
  //         scac: temp.scac,
  //         name: temp.modality.lov_value,
  //       });
  //     });
  //     finalData.sort((a, b) => (a.relation_name > b.relation_name ? 1 : -1));

  //     this.setState({
  //       ...this.state,
  //       visibleWindow: !this.state.visibleWindow,
  //       relationID: this.props.dataItem.id, //todo
  //       gridData: {
  //         data: finalData,
  //         total: finalData.length,
  //       },
  //       initialGridData: {
  //         data: finalData,
  //         total: finalData.length,
  //       },
  //       modalities: getModalities.data.sort((a, b) =>
  //         a.description > b.description ? 1 : -1
  //       ),

  //       visibleClients: result[0].allModalities || [],
  //       finalUserClients: result[0].allModalities,
  //       initialFinalUserClients: result[0].allModalities,
  //       blocking: false,
  //     });
  //   } else {
  //     this.setState({
  //       ...this.state,
  //       visibleWindow: !this.state.visibleWindow,
  //       relationID: this.props.dataItem.id, //todo
  //       gridData: {
  //         data: [],
  //         total: 0,
  //       },
  //       initialGridData: {
  //         data: [],
  //         total: 0,
  //       },
  //       modalities: getModalities.data,

  //       visibleClients: result[0].allModalities || [],
  //       finalUserClients: result[0].allModalities,
  //       initialFinalUserClients: result[0].allModalities,
  //       blocking: false,
  //     });
  //   }
  // };

  handleChange = (event, name, key) => {
    let finalUserClient = [...this.state.finalUserClients];
    let finalObj = finalUserClient.find((test) => test.id === key);
    finalObj.checked = !finalObj.checked;

    let data = [...this.state.gridData.data];

    this.setState(
      {
        ...this.state,
        finalUserClients: finalUserClient,
      },
      async () => {
        let postOrDeleteClient = event.value === false ? "DELETE" : "POST";
        let options = {
          token: window.localStorage.getItem("access_token"),
        };

        if (postOrDeleteClient === "POST") {
          const url = `carrierToModalities`;
          const addCarrierToModality = serverApi(
            "POST",
            url,
            "",
            {
              relationID: this.props.dataItem.id,
              lovID: key,
            },
            options,
            "",
            ""
          );

          addCarrierToModality
            .then(async (response) => {
              let filter1 = {
                filter: {
                  where: {
                    relationID: this.props.dataItem && this.props.dataItem.id,
                  },
                  include: ["modality"],
                },
              };

              const attachedData = await serverApi(
                "GET",
                `carrierToModalities`,
                filter1,
                "",
                options
              );
              let finalData = [];
              _.forEach(attachedData.data, (temp2) => {
                finalData.push({
                  id: temp2.id,
                  relationID: temp2.relationID,
                  checked: true,
                  deleteID: temp2.id,
                  lovID: temp2.lovID,
                  iataAirlineCodeNumber: temp2.iataAirlineCodeNumber,
                  iataCode: temp2.iataCode,
                  scac: temp2.scac,
                  name: temp2.modality.lov_value,
                });
              });
              finalData.sort((a, b) => (a.name > b.name ? 1 : -1));
              this.setState({
                ...this.state,
                gridData: {
                  data: finalData,
                  total: finalData.length,
                },
                initialGridData: {
                  data: finalData,
                  total: finalData.length,
                },
                blocking: false,
                openErrorModal: false,
                errorModalContent: "",
              });
            })
            .catch((error) => {
              this.setState({
                ...this.state,
                openErrorModal: true,
                errorModalContent:
                  error?.response?.data?.error?.message || "Please try again",
                blocking: false,
              });
            });
        } else if (postOrDeleteClient === "DELETE") {
          const deleteObj = data.find((obj) => obj.lovID === key);
          const delete_id = deleteObj.deleteID;
          const url = `carrierToModalities/${delete_id}`;

          const deleteReq = serverApi("DELETE", url, "", "", options, "", "");
          deleteReq
            .then(async () => {
              let filter1 = {
                filter: {
                  where: {
                    relationID: this.props.dataItem && this.props.dataItem.id,
                  },
                  include: ["modality"],
                },
              };

              const attachedData = await serverApi(
                "GET",
                `carrierToModalities`,
                filter1,
                "",
                options
              );
              let finalData = [];
              _.forEach(attachedData.data, (temp2) => {
                finalData.push({
                  id: temp2.id,
                  relationID: temp2.relationID,
                  checked: true,
                  deleteID: temp2.id,
                  lovID: temp2.lovID,
                  iataAirlineCodeNumber: temp2.iataAirlineCodeNumber,
                  iataCode: temp2.iataCode,
                  name: temp2.modality.lov_value,
                });
              });
              finalData.sort((a, b) => (a.name > b.name ? 1 : -1));
              this.setState({
                ...this.state,
                gridData: {
                  data: finalData,
                  total: finalData.length,
                },
                initialGridData: {
                  data: finalData,
                  total: finalData.length,
                },
                blocking: false,
                openErrorModal: false,
                errorModalContent: "",
              });
            })
            .catch((error) => {
              this.setState({
                ...this.state,
                openErrorModal: true,
                errorModalContent:
                  error?.response?.data?.error?.message ||
                  "Remove modality from the failed.Please try again.",
                blocking: false,
              });
            });
        }
      }
    );
  };

  renderWindowList = (finalClients = [], clients = [], doRefresh) => {
    if (doRefresh === true) {
      window.location.reload();
    } else {
      if (finalClients.length === clients.length) {
        return (
          clients &&
          clients.map((item) => (
            <ul style={{ marginBottom: "-5px" }} key={item.id}>
              <Checkbox
                name={item.name}
                key={item.id}
                value={item.checked}
                onChange={(e) => this.handleChange(e, item.name, item.id)}
                label={item.name}
                disabled={this.state.disableAllCheckboxes}
              />
            </ul>
          ))
        );
      } else {
        return finalClients.map((item) => (
          <ul style={{ marginBottom: "-5px" }} key={item.id}>
            <Checkbox
              name={item.name}
              key={item.id}
              value={item.checked}
              onChange={(e) => this.handleChange(e, item.name, item.id)}
              label={item.name}
              disabled={this.state.disableAllCheckboxes}
            />
          </ul>
        ));
      }
    }
  };

  handleSearch = (e) => {
    let initialFinalUserClients = this.state.initialFinalUserClients;

    if (e.value === "") {
      let searchArr = [](initialFinalUserClients || []).forEach((temp) => {
        if (temp.name.toUpperCase().includes(e.value.toUpperCase())) {
          searchArr.push(temp);
        }
      });

      if (
        this.state.clientAttachedToAnotherDomain !== null &&
        this.state.clientAttachedToAnotherDomain !== undefined
      ) {
        let newArr = searchArr.filter(
          (t) => t.id !== this.state.clientAttachedToAnotherDomain
        );
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalUserClients: newArr,
          openErrorModal: false,
          errorModalContent: "",
        });
      } else {
        this.setState({
          ...this.state,
          inputValue: e.value,
          finalUserClients: initialFinalUserClients,
          openErrorModal: false,
          errorModalContent: "",
        });
      }
    } else {
      let searchArr = [](
        //case insensitive
        initialFinalUserClients || []
      ).forEach((temp) => {
        if (temp.name.toUpperCase().includes(e.value.toUpperCase())) {
          searchArr.push(temp);
        }
      });

      this.setState({
        ...this.state,
        inputValue: e.value,
        finalUserClients: searchArr,
        openErrorModal: false,
        errorModalContent: "",
      });
    }
  };

  handleErrorClose = (loginError) => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      openErrorModalContent: "",
    });
  };

  enterEdit = (dataItem) => {
    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    // if (dataItem["name"] !== "Air") {
    //   this.setState({
    //     ...this.state,
    //     openErrorModal: true,
    //     errorModalContent: "You are able to edit only the records with modality name: Air"
    //   }, () => {
    //     setTimeout(() => {
    //       this.handleErrorClose()
    //     }, 4000)
    //   })
    // } else {
    let total = this.state.gridData.total;

    this.setState({
      gridData: {
        data:
          this.state.gridData &&
          this.state.gridData.data.map((item) =>
            item.id === dataItem.id ? { ...item, inEdit: true } : item
          ),
        total: total,
      },
    });
    // }
  };

  discard = (dataItem, hasError = false) => {
    let data = [...this.state.gridData.data];
    const total = this.state.gridData.total;

    if (hasError) {
      data[0].inEdit = true;

      this.setState(
        {
          ...this.state,
          gridData: {
            data: data,
            total: total,
          },
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 3000);
        }
      );
    } else {
      this.removeItem(data, dataItem);

      this.setState(
        {
          ...this.state,
          gridData: {
            data: data,
            total: total,
          },
          visible: false,
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 3000);
        }
      );
    }
  };

  cancel = (dataItem) => {
    if (Object.keys(dataItem).length === 0) {
      this.setState({
        ...this.state,
        gridData: {
          data: this.state.initialGridData.data,
          total: this.state.initialGridData.data.length,
        },
        openErrorModal: false,
        openErrorModalContent: "",
      });
    } else {
      const originalItem =
        this.state.initialGridData &&
        this.state.initialGridData.data.find((p) => p.id === dataItem.id);

      if (originalItem) {
        originalItem.inEdit = false;
        const data = (
          (this.state.gridData && this.state.gridData.data) ||
          []
        ).map((item) => {
          return item?.id === originalItem?.id ? originalItem : item;
        });

        const total = this.state.gridData.total;

        this.setState({
          ...this.state,
          gridData: {
            data: data,
            total: total,
          },
          openErrorModal: false,
          openErrorModalContent: "",
        });
      } else {
        this.setState({
          ...this.state,
          gridData: {
            gridData: {
              data: this.state.initialGridData.data,
              total: this.state.initialGridData.data.length,
            },
          },
          openErrorModal: false,
          openErrorModalContent: "",
        });
      }
    }
  };

  removeItem(data, item) {
    let index = data.findIndex((x) => x.inEdit === item.inEdit);
    if (index >= 0) {
      data.splice(index, 1);
    }
  }

  remove = async (dataItem) => {
    const data = [...this.state.gridData.data];
    let index = data.findIndex(
      (p) => p === dataItem.id && p.id === dataItem.id
    );
    if (index >= 0) {
      data.splice(index, 1);
    }

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let url = "carrierToModalities";

    try {
      let deleteItemRequest = await AbstractDelete(
        url,
        `${dataItem?.deleteID || ""}`,
        options
      );

      if (deleteItemRequest.status === 200) {
        this.refreshGridDataAfterCrud();
      }
    } catch (e) {
      this.setState({
        ...this.state,
        visibleWindow: false,
        openErrorModal: true,
        errorModalContent:
          e?.response?.data?.error?.message ||
          "Remove has failed.Please try again and if the problem persist contact the administrator.",
      });
    }
  };

  add = (dataItem) => {
    dataItem.inEdit = undefined;

    const submitURL = "carrierToModalities";
    const options = { token: window.localStorage.getItem("access_token") };
    let payload = dataItem;
    delete payload.inEdit;

    let errorMsg = [];
    if (payload.name === undefined || payload.name === "")
      errorMsg.push("Modality");
    if (
      payload.name === "Air" &&
      (payload.iataCode === undefined || payload.iataCode === "")
    )
      errorMsg.push("IATA Code (2 letter)");

    if (
      payload.name === "Air" &&
      payload.iataAirlineCodeNumber !== undefined &&
      payload.iataAirlineCodeNumber.length !== 3
    )
      errorMsg.push(
        "IATA Airline Code should have exactly 3 characters length"
      );

    if (payload.scac && payload.scac !== "" && payload?.scac?.length !== 4)
      errorMsg.push("SCAC should have exactly 4 characters length");

    if (!_.isEmpty(errorMsg)) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent: `Required fields : ${errorMsg.join(",")}`,
        },
        () => {
          setTimeout(() => {
            this.cancel(dataItem);
            this.refreshGridDataAfterCrud();
          }, 4000);
        }
      );
    } else {
      if (this.state.modalities.find((x) => x.lov_value === dataItem.name)) {
        const lovID = (this.state.modalities || []).find(
          (x) => x.lov_value === dataItem.name
        ).id;
        const createRequest = serverApi(
          "POST",
          `${submitURL}`,
          "",
          {
            relationID: this.props.dataItem.id,
            lovID: lovID,
            iataCode: dataItem?.iataCode || null,
            scac: dataItem?.scac || null,
            iataAirlineCodeNumber: dataItem?.iataAirlineCodeNumber || null,
          },
          options
        );
        createRequest
          .then(async (createResponse) => {
            this.setState(
              {
                ...this.state,
                deleteVisible: false,
                successMessage: "Saved successfully",
                openSuccessMessage: true,
              },
              () => {
                setTimeout(() => {
                  // this.handleSuccessSnackbar();
                  this.refreshGridDataAfterCrud();
                }, 4000);
              }
            );
          })
          .catch((createError) => {
            this.setState(
              {
                ...this.state,
                openErrorModal: true,
                errorModalContent:
                  createError.response.data.error.message || "Please try again",
              },
              () => this.discard(dataItem, true)
            );
          });
      }
    }
  };

  addNew = () => {
    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    const newDataItem = { inEdit: true };

    this.setState({
      gridData: {
        data: [newDataItem, ...this.state.gridData.data],
        total: this.state.gridData.total,
      },
      // visibleWindow: true,
    });
  };

  update = async (dataItem) => {
    const data = [...this.state.gridData.data];
    const updatedItem = { ...dataItem, inEdit: undefined };
    this.updateItem(data, updatedItem);
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    const lovID = (this.state.modalities || []).find(
      (x) => x.lov_value === dataItem.name
    ).id;

    let editPayload = {
      iataCode: updatedItem.iataCode,
      scac: updatedItem.scac,
      lovID: lovID,
      iataAirlineCodeNumber: updatedItem.iataAirlineCodeNumber,
    };

    if (dataItem.name === "Air" && editPayload?.iataCode?.length !== 2) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            "Required: IATA Code should have exactly 2 characters length",
        },
        () => {
          setTimeout(() => {
            this.setState({
              ...this.state,
              openErrorModal: false,
              errorModalContent: "",
            });
          }, 4000);
        }
      );

      return;
    } else if (
      dataItem.name === "Air" &&
      editPayload?.iataAirlineCodeNumber?.length !== 3
    ) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            "Required: IATA Airline Code should have exactly 3 characters length",
        },
        () => {
          setTimeout(() => {
            this.setState({
              ...this.state,
              openErrorModal: false,
              errorModalContent: "",
            });
          }, 4000);
        }
      );

      return;
    } else if (editPayload?.name?.length === "") {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent: "Required: Modality",
        },
        () => {
          setTimeout(() => {
            this.setState({
              ...this.state,
              openErrorModal: false,
              errorModalContent: "",
            });
          }, 4000);
        }
      );

      return;
    } else if (
      editPayload.scac &&
      editPayload?.scac !== "" &&
      editPayload?.scac.length !== 4
    ) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent: "SCAC should have exactly 4 characters length",
        },
        () => {
          setTimeout(() => {
            this.setState({
              ...this.state,
              openErrorModal: false,
              errorModalContent: "",
            });
          }, 4000);
        }
      );

      return;
    } else {
      try {
        const editRequest = await serverApi(
          "PATCH",
          `carrierToModalities/${updatedItem.id}`,
          {},
          editPayload,
          options
        );
        if (editRequest.status === 200) {
          this.refreshGridDataAfterCrud();
        }
      } catch (e) {
        this.setState(
          {
            ...this.state,
            openErrorModal: true,
            errorModalContent:
              e?.response?.data?.error?.message ||
              "Update contact process has failed.Please check again the inputs.",
          },
          () => {
            this.discard(dataItem, true);
            setTimeout(() => {
              this.setState({
                ...this.state,
                openErrorModal: false,
                errorModalContent: "",
              });
            }, 3500);
          }
        );
      }
    }
  };

  updateItem = (data, item) => {
    let index = data.findIndex(
      (p) => p === item || (item.id && p.id === item.id)
    );
    if (index >= 0) {
      data[index] = { ...item };
    }
  };

  itemChange = (event) => {
    if (
      event.dataItem.name === "Air" &&
      event.field === "iataCode" &&
      event.value.length !== 2
    ) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            "Error: IATA Code should have exactly 2 characters.",
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 4000);
        }
      );
    }

    if (
      event.dataItem.name === "Air" &&
      event.field === "iataAirlineCodeNumber" &&
      event.value.length !== 3
    ) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            "Error: IATA Airline Code should have exactly 3 characters.",
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 4000);
        }
      );
    }

    const total = this.state.gridData.total;
    const data =
      this.state.gridData &&
      this.state.gridData.data.map((item) =>
        item.id === event.dataItem.id
          ? { ...item, [event.field]: event.value }
          : item
      );

    this.setState({
      gridData: {
        data: data,
        total: total,
      },
    });
  };

  closeWindow = () => {
    this.setState({
      ...this.state,
      visibleWindow: false,
    });
  };

  render() {
    const dataItem = this.props.dataItem;
    const { gridData } = this.state;
    // const { inputValue } = this.state;
    const { hasBackofficeRead } = this.props;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <div
          style={{
            width: "72%",
          }}
        >
          <h4 style={{ fontFamily: "bold", textSize: "7px" }}>
            Carrier : {dataItem && dataItem.relation_name}
          </h4>
          {this.state.openErrorModal ? (
            <Error style={{ color: "red", fontSize: "15px" }}>
              <h3>{this.state.errorModalContent || ""}</h3>
            </Error>
          ) : null}
          <Grid
            {...gridData}
            filterable={false}
            style={{ className: "grid-no-select" }}
            sortable={false}
            resizable
            editField={this.editField}
            onItemChange={this.itemChange}
            pageable={false}
          >
            <GridToolbar>
              <GridContainer xs={12} justify={"flex-start"} direction={"row"}>
                {!hasBackofficeRead && (
                  <Tooltip
                    justify={"flex-start"}
                    placement="top"
                    title={"Attach Modality"}
                  >
                    <IconButton onClick={this.addNew}>
                      <FontAwesomeIcon
                        color="#0D5869"
                        onClick={this.addNew}
                        size="1.6x"
                        icon={faPlus}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </GridContainer>
            </GridToolbar>

            {!hasBackofficeRead && (
              <Column
                cell={this.CommandCell}
                title="Actions"
                filterable={false}
                width="240px"
              />
            )}
            {this.renderGridColumns(dataItem)}
          </Grid>
        </div>
        {/* {this.state.visibleWindow && (
          <Window
            title={`Attach modalities to carrier: ${this.props.dataItem.relation_name}`}
            // style={{backgroundColor:'#0d5869'}}
            onClose={this.closeWindow}
            width={900}
            height={600}
            initialTop={110}
          >
            <>
              <BlockUi
                tag="div"
                blocking={this.state.blocking}
                message="Loading, please wait. . ."
                renderChildren={true}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "50px",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {this.state.openErrorModal ? (
                      <Error style={{ color: "red", fontSize: "15px" }}>
                        {this.state.errorModalContent || ""}
                      </Error>
                    ) : null}
                    <div
                      style={{
                        display: "flex",
                        marginLeft: "35px",
                        marginTop: "20px",
                      }}
                    >
                      <Input
                        name="modalities"
                        value={inputValue}
                        style={{ marginBottom: "20px" }}
                        label="Search modalities"
                        onChange={(e) => this.handleSearch(e)}
                        minLength={2}
                      />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {this.renderWindowList(
                        this.state.finalUserClients,
                        this.state.visibleClients
                      )}
                    </div>
                  </div>
                </div>
              </BlockUi>
            </>
            {this.state.windowError ? (
              <Error style={{ color: "red" }}>
                <h3>{this.state.windowErrorMessage || ""}</h3>
              </Error>
            ) : null}
          </Window>
        )} */}
      </div>
    );
  }
}

export default CarrierToModality;
