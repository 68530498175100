import { createAction } from "redux-actions";
// import _ from "lodash";
import { serverApi } from "../../networking/config";
import { merge } from "ramda";
import { colorFromString, initialsFromName } from "../../lib/general/utils";

const constants = {};

constants.FETCH_USER_DATA_PENDING = "FETCH_USER_DATA_PENDING";
constants.FETCH_USER_DATA_FAILURE = "FETCH_USER_DATA_FAILURE";
constants.FETCH_USER_DATA_SUCCESS = "FETCH_USER_DATA_SUCCESS";

constants.SET_COUNTRY_CODES = "SET_COUNTRY_CODES";
constants.SET_USA_STATES = "SET_USA_STATES";
constants.SET_VESSEL_LOV_TYPES = "SET_VESSEL_LOV_TYPES";
constants.SET_PAYMENT_TERMS_TYPES = "SET_PAYMENT_TERMS_TYPES";

export const fetchUserDataPending = createAction(
  constants.FETCH_USER_DATA_PENDING
);
export const fetchUserDataFailure = createAction(
  constants.FETCH_USER_DATA_FAILURE
);
export const fetchUserDataSuccess = createAction(
  constants.FETCH_USER_DATA_SUCCESS
);

const setVesselLovTypes = createAction(constants.SET_VESSEL_LOV_TYPES);
const setPaymentTermsTypes = createAction(constants.SET_PAYMENT_TERMS_TYPES);

const getUserInfo = () => async (dispatch, getState) => {
  dispatch(fetchUserDataPending());

  try {
    let getCookie = (name) => {
      // Split cookie string and get all individual name=value pairs in an array
      var cookieArr = document.cookie.split(";");

      // Loop through the array elements
      for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");

        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if (name === cookiePair[0].trim()) {
          // Decode the cookie value and return
          return decodeURIComponent(cookiePair[1]);
        }
      }

      // Return null if not found
      return null;
    };

    let access_token = getCookie("access_token");
    window.localStorage.setItem("access_token", access_token);

    let options2 = {
      token: window.localStorage.getItem("access_token"),
    };

    const getUser = await serverApi(
      "GET",
      "snUsers/authenticated/user",
      "",
      "",
      options2
    );
    let user = getUser.data && getUser.data.user;
    const userDisplayName = user.userRealName || user.username;
    user.userDisplayName = userDisplayName;
    user.userColor = colorFromString(userDisplayName);
    user.userShortName = initialsFromName(userDisplayName);
    user.hasBackofficeRead = (user.roles || []).some((x) => x.id === 421);
    dispatch(fetchUserDataSuccess(user));
    return user;
  } catch (e) {
    dispatch(fetchUserDataFailure());
    if (e?.response?.status === 401) {
      let final = "http://localhost:7002";
      let environmentLocation =
        window && window.location && window.location.hostname;
      if (environmentLocation === "dev2.swift-navigator.com") {
        final = "https://dev2.swift-navigator.com/";
      } else if (environmentLocation === "uat2.swift-navigator.com") {
        final = "https://uat2.swift-navigator.com/";
      } else if (environmentLocation === "prod2.swift-navigator.com") {
        final = "https://prod2.swift-navigator.com/";
      }
      window.location.href = final + "/auth?url=" + window.location;
    }
  }
};

const setCountryCodes = createAction(constants.SET_COUNTRY_CODES);

const getCountryCodes = () => async (dispatch, getState) => {
  try {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    const request = await serverApi("GET", "countryCodes", "", "", options);
    const payload = (request.data || []).map((x) => ({
      ...x,
      text: x.ALPHA_2,
      value: x.ALPHA_2,
    }));
    dispatch(setCountryCodes(payload));
  } catch (error) {
    console.log(error);
  }
};

const setUsaState = createAction(constants.SET_USA_STATES);

const getLovs = () => async (dispatch, getState) => {
  try {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    const request = await serverApi(
      "GET",
      "lovs",
      {
        filter: {
          where: {
            and: [
              {
                or: [
                  {
                    lov_type: "USA_STATE",
                  },
                  {
                    lov_type: "VESSEL_TYPE",
                  },
                  {
                    lov_type: "PAYMENT_TERMS_TYPES",
                  },
                ],
              },
            ],
          },
        },
      },
      "",
      options
    );

    //you should always check the text and value to have values or empty string because the ui crashes!!
    const payload = (request.data || [])
      .filter((x) => x.lov_type !== "VESSEL_TYPE")
      .map((x) => ({
        ...x,
        text: x?.lov_value_1 || "", // Provide a default value (empty string) if lov_value_1 is null
        value: x?.lov_value_1 || "", // Provide a default value (empty string) if lov_value_1 is null
      }));

    const vesselLovTypes = (request.data || [])
      .filter((x) => x.lov_type === "VESSEL_TYPE")
      .map((vs) => ({
        ...vs,
        text: vs.lov_value,
        value: vs.lov_value,
      }));

    const paymentTermsTypes = (request.data || [])
      .filter((x) => x.lov_type === "PAYMENT_TERMS_TYPES")
      .map((vs) => ({
        ...vs,
        text: vs.lov_value,
        value: vs.id,
      }));
    dispatch(setUsaState(payload));
    dispatch(setVesselLovTypes(vesselLovTypes));
    dispatch(setPaymentTermsTypes(paymentTermsTypes));
  } catch (error) {
    console.log(error);
  }
};

export const actions = {
  getUserInfo,
  getCountryCodes,
  getLovs,
};

const initialState = {
  usaStates: [],
  countryCodes: [],
  user: {
    id: null,
    userRealName: "",
    roles: [],
    roleIds: [],
  },
};

export const MainLayoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_USER_DATA_SUCCESS:
      return merge(state, {
        user: {
          ...action.payload,
          roleIds: action.payload.roles.map((x) => x.id),
        },
      });
    case constants.FETCH_USER_DATA_PENDING:
      return {
        ...state,
      };
    case constants.FETCH_USER_DATA_FAILURE:
      return {
        ...state,
      };

    case constants.SET_COUNTRY_CODES:
      return {
        ...state,
        countryCodes: action.payload,
      };
    case constants.SET_USA_STATES:
      return {
        ...state,
        usaStates: action.payload,
      };

    case constants.SET_VESSEL_LOV_TYPES:
      return {
        ...state,
        vesselLovTypes: action.payload,
      };
    case constants.SET_PAYMENT_TERMS_TYPES:
      return {
        ...state,
        paymentTermsLovTypes: action.payload,
      };
    default:
      return state;
  }
};
