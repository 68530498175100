import React from "react";
import {
  Grid,
  GridColumn as Column,
  GridDetailRow,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { vesselToClientColumnSchema } from "./schema";
import { MyCommandCell } from "../../../../Vessels/View/components/CellHOC";
import _ from "lodash";
import { PrimaryFlag } from "../PrimaryFlagDropdown";
import GridContainer from "../../../../../components/Grid/GridContainer";
import { serverApi } from "../../../../../networking/config";
import { AbstractDelete } from "../../../../../networking/apiCalls";
import { Window } from "@progress/kendo-react-dialogs";
import { filterBy } from "@progress/kendo-data-query";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { Error } from "@progress/kendo-react-labels";
import { IconButton, Tooltip } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
class VesselToClientExpanded extends GridDetailRow {
  editField = "inEdit";
  CommandCell;

  constructor(props) {
    super(props);

    this.state = {
      visibleWindow: false,
      notifyEmailOperator: "",
      isDropOpened: false,
      columns:
        (vesselToClientColumnSchema && vesselToClientColumnSchema()) || [],
      gridData: {
        data: [],
        total: 10,
      },
      initialGridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 10,
        skip: 0,
      },
      openErrorModal: false,
      errorModalContent: "",
      delay: 400,
      loading: false,
      client_names: [],
      windowError: false,
      windowErrorMessage: "",
      disableEditButton: true,
      activeOrdersFlg: false,

      primary_flg_checked: false,
      is_primary_flg_switchDisabled: false,
      active_dispatches_checked: false,
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      disableEditButton: false,
      showSecondAction: true,
      showFirstAction: true,
    });
  }

  async componentDidMount() {
    const dataItem = this.props.dataItem;

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let filter1 = {
      filter: {
        where: {
          vesselID: this.props.dataItem && this.props.dataItem.id,
        },
        include: ["masterEntity"],
      },
    };

    let filter2 = {
      filter: {
        where: {
          and: [
            {
              client_flag: true,
            },
          ],
        },
      },
    };

    const responseData = await serverApi(
      "GET",
      `vesselToClients`,
      filter1,
      "",
      options
    );
    const getClients = await serverApi(
      "GET",
      `masterEntities`,
      filter2,
      "",
      options
    );
    const getExistingOperators = await serverApi(
      "GET",
      `snuserOperatorVs`,
      "",
      "",
      options
    );
    let tempExistingOperators = [];
    _.forEach(getExistingOperators?.data, (existingOperator) => {
      tempExistingOperators.push({
        email: existingOperator.email,
        userID: existingOperator.id,
      });
    });

    const primaryClient = responseData.data.filter((x) => x.primary_flg);

    if (responseData && responseData.data.length === 1) {
      this.setState({
        ...this.state,
        primaryClient: primaryClient,
        existingOperatorsAvailable: tempExistingOperators.map((x) => x.email),
        existingOperatorsAvailableInitial: tempExistingOperators.map(
          (x) => x.email
        ),
        vesselID: this.props.dataItem.id,
        gridData: {
          data: [responseData && responseData.data[0]],
          total: 1,
        },
        initialGridData: {
          data: [responseData && responseData.data[0]],
          total: 1,
        },
        client_names: getClients.data.sort((a, b) =>
          a.relation_name > b.relation_name ? 1 : -1
        ),
        valueClientName: "",
        relation_postal_code: "",
        relation_country: "",
        showDeleteAllButton: false,
      });
    } else if (responseData && responseData.data.length > 1) {
      let finalData = [];
      _.forEach(responseData.data, (temp) => {
        finalData.push(temp);
      });
      this.setState({
        ...this.state,
        primaryClient: primaryClient,
        existingOperatorsAvailable: tempExistingOperators.map((x) => x.email),
        existingOperatorsAvailableInitial: tempExistingOperators.map(
          (x) => x.email
        ),
        vesselID: this.props.dataItem.id,
        gridData: {
          data: finalData,
          total: finalData.length,
        },
        initialGridData: {
          data: finalData,
          total: finalData.length,
        },
        client_names: getClients.data,
        toggleWindowRemoveClients: dataItem.toggleWindowRemoveClients,
        showDeleteAllButton: true,
      });
    } else {
      this.setState({
        ...this.state,
        primaryClient: primaryClient,
        existingOperatorsAvailable: tempExistingOperators.map((x) => x.email),
        existingOperatorsAvailableInitial: tempExistingOperators.map(
          (x) => x.email
        ),
        vesselID: this.props.dataItem.id,
        gridData: {
          data: [],
          total: 0,
        },
        initialGridData: {
          data: [],
          total: 0,
        },
        client_names: getClients.data,
        showDeleteAllButton: false,
      });
    }
  }

  renderGridColumns = (dataItem, changeTheCellComponent, propakia) => {
    let selectionCol = [];
    let normalCols = [];
    let finalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            width="70px"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.isDropdown === "yes") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              cell={(props) => {
                let field = props.field;
                switch (field) {
                  case "primary_flg":
                    return (
                      <>
                        <PrimaryFlag
                          {...props}
                          width="120px"
                          localizedData={[
                            { text: "true", value: true },
                            { text: "false", value: false },
                          ]}
                        />
                      </>
                    );
                  default:
                    break;
                }
              }}
            />
          );
        } else {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={temp.field === "id" || temp.editable ? false : true} //id not editable on edit
            />
          );
        }
      });

    finalCols = [...selectionCol, ...normalCols];
    return finalCols;
  };

  onChange = (event) => {
    const { value } = event.target;

    // Check if the value matches any client names
    const matchedClient = this.state.client_names.find(
      (client) => client.relation_name === value
    );

    if (matchedClient) {
      // User has selected a client from the dropdown
      this.updateClientDetails(matchedClient);
    } else {
      // User is editing the input, reset client details and fetch new data
      this.setState({
        valueClientName: value,
        openedClientNames: true,
        postClientID: "",
        relation_postal_code: "",
        relation_country: "",
        client_flag: false,
      });

      if (value.length >= 3) {
        this.fetchAutocompleteData(value);
      }
    }
  };

  onAutoCompleteChange = (event, value) => {
    const { name } = event.target;

    let stateData = {};

    stateData =
      value.length < 3
        ? {
            data:
              this.state.client_names.length > 0
                ? this.state.client_names.map((temp) => temp.relation_name)
                : [],
            openedClientNames: false,
          }
        : { data: this.filterData(value, name), openedClientNames: true };

    let eventType = event.nativeEvent.type;

    let valueSelected =
      eventType === "click" ||
      (eventType === "keydown" && event.nativeEvent.keyCode === 13);

    if (valueSelected) {
      let postClientId =
        this.state.client_names.length === 1
          ? this.state.client_names[0].id
          : (this.state.client_names || []).filter(
              (temp) => temp.relation_name === this.state.valueClientName
            )[0].id;

      let relation_postal_code =
        this.state.client_names.length === 1
          ? this.state.client_names[0].relation_postal_code
          : (this.state.client_names || []).filter(
              (temp) => temp.relation_name === this.state.valueClientName
            )[0].relation_postal_code;

      let relation_country =
        this.state.client_names.length === 1
          ? this.state.client_names[0].relation_country
          : (this.state.client_names || []).filter(
              (temp) => temp.relation_name === this.state.valueClientName
            )[0].relation_country;

      this.setState({
        ...this.state,
        relation_postal_code: relation_postal_code || "",
        relation_country: relation_country || "",
        client_flag: true,
        postClientID: postClientId || "",
      });
    }

    if (valueSelected && stateData.data.includes(value)) {
      stateData.openedClientNames = false;
    }

    this.setState(
      {
        valueClientName: value,
        ...stateData,
      },
      () => this.fetchAutocompleteData(value)
    );
  };

  onBlur = () => {
    const matchedClients = this.state.client_names.filter(
      (client) => client.relation_name === this.state.valueClientName
    );

    if (matchedClients.length === 1) {
      this.updateClientDetails(matchedClients[0]);
    }

    this.setState({ openedClientNames: false });
  };

  updateClientDetails = (client) => {
    this.setState({
      postClientID: client.id || "",
      relation_postal_code: client.relation_postal_code || "",
      relation_country: client.relation_country || "",
      client_flag: true,
      valueClientName: client.relation_name, // Update the input field with the selected name
      openedClientNames: false, // Close the dropdown
    });
  };

  fetchAutocompleteData = (filterValue) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let filter = {
      order: ["relation_name asc"],
      where: {
        and: [
          { relation_name: { regexp: `/^${filterValue}/i` } },
          { client_flag: true },
        ],
      },
    };

    const fetchDropdownData = serverApi(
      "GET",
      `masterEntities`,
      { filter: filter },
      "",
      options
    );

    fetchDropdownData
      .then((response) => {
        this.setState({
          client_names: response.data.sort((a, b) =>
            a.relation_name.localeCompare(b.relation_name)
          ),
        });
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  };

  filterData = (value) => {
    const filter = {
      value: value,
      operator: "startswith",
      ignoreCase: true,
    };

    return filterBy(
      this.state.client_names.map((client) => client.relation_name),
      filter
    );
  };

  enterEdit = (dataItem) => {
    // if (dataItem.primary_flg) {
    //   this.setState({
    //     ...this.state,
    //     openErrorModal: true,
    //     errorModalContent: "This is the primary client of the vessel.\nIn case you want to make this the primary client use the switch to set it to true"
    //   }, () => {
    //     setTimeout(() => {
    //       this.handleErrorClose()
    //     }, 4000)
    //   })
    // } else {
    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    let total = this.state.gridData.total;

    let finalData =
      this.state.gridData &&
      this.state.gridData.data.map((item) =>
        item.id === dataItem.id ? { ...item, inEdit: true } : item
      );

    this.setState({
      ...this.state,
      gridData: {
        data: finalData,
        total: total,
      },
    });
    // }
  };

  discard = (dataItem, hasError = false) => {
    let data = [...this.state.gridData.data];
    const total = this.state.gridData.total;

    if (hasError) {
      data[0].inEdit = true;
      this.setState({
        gridData: {
          data: data,
          total: total,
        },
      });
    } else {
      this.removeItem(data, dataItem);

      this.setState({
        gridData: {
          data: data,
          total: total,
        },
        visible: false,
      });
    }
  };

  cancel = (dataItem) => {
    const originalItem =
      this.state.initialGridData &&
      this.state.initialGridData.data.find((p) => p.id === dataItem.id);
    originalItem.inEdit = false;

    const data =
      this.state.initialGridData &&
      this.state.initialGridData.data.map((item) =>
        item.id === originalItem.id ? originalItem : item
      );

    const total = this.state.initialGridData.total;

    this.setState({
      ...this.state,
      gridData: {
        data: data,
        total: total,
      },
      initialGridData: {
        data: data,
        total: total,
      },
      openErrorModal: false,
      openErrorModalContent: "",
    });
  };

  removeItem(data, item) {
    let index = data.findIndex((p) => p === item || p.id === item.id);
    if (index >= 0) {
      data.splice(index, 1);
    }
  }

  remove = async (dataItem) => {
    const data = [...this.state.gridData.data];

    let index = data.findIndex((p) => p === dataItem || p.id === dataItem.id);
    if (index >= 0) {
      data.splice(index, 1);
    }

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let url = "vesselToClients";

    try {
      //check remove vessel client data when primary_flag is FALSE else -> restrict this action
      if (dataItem.primary_flg === true) {
        this.setState(
          {
            ...this.state,
            openErrorModal: true,
            errorModalContent:
              "You are not able to remove the primary client of the vessel.You need to attach a new primary client first and then remove the client",
          },
          () => {
            setTimeout(() => {
              this.setState({
                ...this.state,
                openErrorModal: false,
                errorModalContent: "",
              });
            }, 5000);
          }
        );
        return;
      }

      let deleteItemRequest = await AbstractDelete(url, dataItem.id, options);
      if (deleteItemRequest.status === 200) {
        let filter1 = {
          filter: {
            where: {
              relationID: dataItem.clientID,
              and: [
                { relationID: dataItem.clientID },
                { vesselID: dataItem.vesselID },
              ],
            },
          },
        };

        const getRelatedContacts = await serverApi(
          "GET",
          `masterEntityContacts`,
          filter1,
          "",
          options
        );

        // eslint-disable-next-line no-unused-vars
        for (const contact of getRelatedContacts.data || []) {
          await AbstractDelete("masterEntityContacts", contact.id, options);
        }

        //clear the column filters if grid data is 0 or let them if grid data is >= 1
        if (data.length <= 0) {
          this.setState({
            ...this.state,
            gridData: {
              data: data,
              total: data.length,
            },
            initialGridData: {
              data: data,
              total: data.length,
            },
            dataState: {
              skip: 0,
              take: 10,
            },
          });
        } else {
          this.setState({
            ...this.state,
            gridData: {
              data: data,
              total: data.length,
            },
            initialGridData: {
              data: data,
              total: data.length,
            },
          });
        }
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent:
          e?.response?.data?.error?.message || "Remove failed.Please try again",
      });
    }
  };

  add = (dataItem) => {};

  addNew = () => {
    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    const newDataItem = { inEdit: true };

    this.setState({
      gridData: {
        data: [newDataItem, ...this.state.gridData.data],
        total: this.state.gridData.total,
      },
      visibleWindow: true,
    });
  };

  //TODO change this is not the logic we want
  update = async (dataItem) => {
    const data = [...this.state.gridData.data];

    const clonedDateItem = _.cloneDeep(dataItem);
    let dbID = clonedDateItem.id;
    delete clonedDateItem.id;

    let x = JSON.stringify(clonedDateItem);
    let y = window.localStorage.getItem("editItem");

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    try {
      let editPayload = {};
      if (x !== y) {
        let final = JSON.parse(window.localStorage.getItem("editItem"));
        const updatedItem = { ...final, inEdit: undefined };
        this.updateItem(data, updatedItem);
        editPayload = {
          primary_flg: true, // the edit is only doable from false -> true and NOT the opposite
        };
      } else {
        const updatedItem = { ...clonedDateItem, inEdit: undefined };
        this.updateItem(data, updatedItem);
        editPayload = updatedItem;
      }

      await this.checkIfPrimaryClientExist(this.state.vesselID);
      let lastPrimaryClient = this.state.primaryClient || [];
      let patchID = lastPrimaryClient[0]?.id || null;
      if (patchID) {
        await serverApi(
          "PATCH",
          `vesselToClients/${patchID}`,
          {},
          { primary_flg: false },
          options,
          ""
        );
      }

      //update the last record with primary flag TRUE
      await serverApi(
        "PATCH",
        `vesselToClients/${dbID}`,
        {},
        editPayload,
        options
      );

      let edit = await serverApi(
        "GET",
        `vesselToClients/?filter={"where":{"vesselID":${this.state.vesselID}},"include":["masterEntity"]}`,
        {},
        {},
        options
      );

      this.setState({
        ...this.state,
        gridData: {
          data: edit.data,
          total: edit.data.length,
        },
        initialGridData: {
          data: edit.data,
          total: edit.data.length,
        },
        visibleWindow: false,
      });
    } catch (e) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            e?.response?.data?.error?.message ||
            "The process is terminated.Please try again.",
        },
        () => this.discard(dataItem, true)
      );
    }
  };

  updateItem = (data, item) => {
    let index = data.findIndex(
      (p) => p === item || (item.id && p.id === item.id)
    );
    if (index >= 0) {
      data[index] = { ...item };
    }
  };

  itemChange = (event) => {
    let fieldsArr = [
      "masterEntity.relation_name",
      "masterEntity.relation_postal_code",
      "masterEntity.relation_country",
      "masterEntity.relation_city",
      "masterEntity.relation_address",
    ];

    let fieldName;
    let index;
    if (fieldsArr.includes(event.field)) {
      index = fieldsArr.indexOf(event.field);
      fieldName = fieldsArr[index].split(".")[1];
    }

    const finalData = [];
    const total = this.state.gridData.total;

    let finalItem;

    (this.state.gridData.data || []).forEach((item) => {
      let masterEntity = {
        ...item.masterEntity,
        [fieldName]: event.value,
      };

      if (item.id === event.dataItem.id) {
        delete item.masterEntity;
        finalItem = {
          ...item,
          masterEntity,
        };
        finalData.push(finalItem);
      } else {
        finalItem = item;
        finalData.push(finalItem);
      }
    });

    this.setState(
      {
        ...this.state,
        gridData: {
          data: finalData,
          total: total,
        },
      },
      () => this.renderGridColumns(finalItem, true, this.props)
    );
  };

  refreshGridDataAfterCrud = async () => {};

  checkIfPrimaryClientExist = async (vesselID) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    //check if there is vessel to client connection with the specific vessel id
    //if NOT -> primary flag -> TRUE and disabled
    //if YES -> switch primart flag -> user should choose
    const getVesselClientConnection = await serverApi(
      "GET",
      `vesselToClients?filter={"where":{"vesselID":${vesselID}}}`,
      "",
      "",
      options,
      ""
    );
    if (getVesselClientConnection?.data?.length > 0) {
      let primaryClient = getVesselClientConnection.data.filter(
        (x) => x.primary_flg === true
      );
      if (primaryClient.length) {
        this.setState({
          ...this.state,
          primaryClient: primaryClient,
          vesselClientConnection: getVesselClientConnection?.data || [],
          is_primary_flg_switchDisabled: false,
          // windowError: true,
          // windowErrorMessage: `The vessel with id: ${this.state.postVesselID}, has another primary client.\nIn case you want to make this the primary client use the switch to set it to true.`
        });
      }
    } else if (getVesselClientConnection?.data?.length === 0) {
      this.setState({
        ...this.state,
        vesselClientConnection: [],
        primary_flg_checked: true,
        is_primary_flg_switchDisabled: true,
      });
    }
  };

  toggleWindow = (e) => {
    const data = this.state.initialGridData.data;
    let primary_flg_checked,
      is_primary_flg_switchDisabled = false;
    if (data.length === 0) {
      primary_flg_checked = true;
      is_primary_flg_switchDisabled = true;
    } else {
      primary_flg_checked = false;
      is_primary_flg_switchDisabled = false;
    }

    this.setState(
      {
        ...this.state,
        primary_flg_checked: primary_flg_checked,
        is_primary_flg_switchDisabled: is_primary_flg_switchDisabled,
        visibleWindow: !this.state.visibleWindow,
        gridData: {
          data: [...data],
          total: 100,
        },
        client_names: [],
        valueClientName: "",
        windowError: false,
        windowErrorMessage: "",
      },
      () => {
        if (this.state.visibleWindow === false) {
          this.setState({
            ...this.state,
            activeOrdersFlg: false,
            active_dispatches_checked: false,
            primary_flg_checked: false,
          });
        }
      }
    );
  };

  listNoDataRender = (element) => {
    const noData = (
      <h4 style={{ fontSize: "1em" }}>
        <span className="k-icon k-i-warning" style={{ fontSize: "2.5em" }} />
        <br />
        <br />
        No client names found
      </h4>
    );

    return React.cloneElement(element, { ...element.props }, noData);
  };

  saveVesselToClient = async () => {
    if (!this.state.notifyEmailOperator && this.state.activeOrdersFlg) {
      this.setState(
        {
          ...this.state,
          windowErrorMessage:
            "Notify operator field is required in order to continue",
          windowError: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              ...this.state,
              windowErrorMessage: "",
              windowError: false,
            });
          }, 5500);
        }
      );
      return null;
    } else {
      let options = {
        token: window.localStorage.getItem("access_token"),
      };
      const patchTheReportingDate =
        this.state.active_dispatches_checked && this.state.primary_flg_checked
          ? true
          : false;

      //change all the other vesseltoclientconnection data the primary flg to false and this one if primary is true to true and then save the new one.
      let lastPrimaryClient = this.state.primaryClient || [];
      let patchID = this.state.primaryClient[0]?.id || null;
      let previousPrimaryClientHasChanged = false;

      try {
        await this.checkIfPrimaryClientExist(this.state.vesselID);

        let payload = {
          clientID: this.state.postClientID,
          vesselID: this.state.vesselID,
          primary_flg: this.state.primary_flg_checked,
        };
        if (patchID && this.state.primary_flg_checked === true) {
          await serverApi(
            "PATCH",
            `vesselToClients/${patchID}`,
            {},
            { primary_flg: false },
            options,
            ""
          );
          previousPrimaryClientHasChanged = true;
        }

        setTimeout(async () => {
          let addVesselToClientRequest = await serverApi(
            "POST",
            "vesselToClients",
            {},
            payload,
            options
          );

          if (addVesselToClientRequest.status === 200) {
            //only if both flags are true
            if (patchTheReportingDate && lastPrimaryClient) {
              const primaryClient = this.state.gridData.data.find(
                (x) => x.primary_flg === true
              );
              const previousPrimaryClient = primaryClient.clientID;
              const newPrimaryclientID = payload.clientID;
              await serverApi(
                "POST",
                "vesselToClients/fixReportingDateForActiveDispatches",
                {},
                {
                  previousPrimaryclientID: previousPrimaryClient,
                  newPrimaryclientID: newPrimaryclientID,
                  vesselID: this.state.vesselID,
                },
                options
              );
            }

            if (
              this.state.activeOrdersFlg &&
              this.state.notifyEmailOperator !== ""
            ) {
              const newPrimaryclientID = payload.clientID;
              const response = await serverApi(
                "POST",
                "vesselToClients/updateActiveOrders",
                {},
                {
                  clientID: newPrimaryclientID,
                  operatorEmail: this.state.notifyEmailOperator,
                  vesselID: this.state.vesselID,
                },
                options
              );
              let count = response?.data?.updatedActiveOrders?.length;
              let message = `${count} Active orders have been moved`;
              await this.props.openSuccessModal(message);
            }

            this.setState(
              {
                ...this.state,
                visibleWindow: false,
              },
              async () => {
                let getData = await serverApi(
                  "GET",
                  `vesselToClients?filter={"where":{"vesselID":${this.state.vesselID}},"include":["masterEntity"]}`,
                  {},
                  {},
                  options
                );
                this.setState({
                  ...this.state,
                  primary_flg_checked: false,
                  is_primary_flg_switchDisabled: false,
                  active_dispatches_checked: false,
                  gridData: {
                    data: getData?.data || [],
                    total: getData?.data?.length,
                  },
                  initialGridData: {
                    data: getData?.data || [],
                    total: getData?.data?.length,
                  },
                  visibleWindow: false,
                });
              }
            );
          }
        }, 1500);
      } catch (e) {
        if (previousPrimaryClientHasChanged) {
          if (patchID && this.state.primary_flg_checked === true) {
            await serverApi(
              "PATCH",
              `vesselToClients/${patchID}`,
              {},
              { primary_flg: true },
              options,
              ""
            );
          }
        }

        let message =
          e?.response?.data?.error?.message ||
          "Please try again and if the problem persist contact the administrator.";
        if (e?.response?.data?.error?.message?.includes("unique constraint")) {
          message = "Duplicate id detected, please contact the administrator";
        }

        this.setState({
          ...this.state,
          primary_flg_checked: false,
          is_primary_flg_switchDisabled: false,
          active_dispatches_checked: false,
          visibleWindow: true,
          windowError: true,
          windowErrorMessage: message || "Please try again",
        });
      }
    }
  };

  openErrorModal = (e) => {
    this.setState({
      ...this.state,
      openErrorModal: true,
      errorModalContent: e.response.data.error.message || "Please try again",
    });
  };

  handleChange = (name) => (event) => {
    if (event.target.checked === false) {
      this.setState({ [name]: event.target.checked });
    } else if (event.target.checked === true) {
      this.setState({
        windowError: false,
        windowErrorMessage: "",
        [name]: event.target.checked,
      });
    }
  };

  filterData2(filter) {
    if (filter.value === "") {
      this.setState({
        ...this.state,
        existingOperatorsAvailable:
          this.state.existingOperatorsAvailableInitial,
      });

      return;
    } else {
      const data = this.state.existingOperatorsAvailableInitial;
      filter.operator = "startswith";
      let filteredResults = filterBy(data, filter);

      return filteredResults;
    }
  }

  onFilterChange = (event) => {
    let filteredResults = this.filterData2(event.filter);

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.setState({
        ...this.state,
        existingOperatorsAvailable:
          filteredResults !== undefined
            ? filteredResults
            : this.state.existingOperatorsAvailableInitial,
        loading: false,
      });
    }, this.state.delay);

    this.setState({
      ...this.state,
      loading: true,
    });
  };

  filterData(filter) {
    if (filter.value === "") {
      this.setState({
        ...this.state,
        existingOperatorsAvailable:
          this.state.existingOperatorsAvailableInitial,
      });

      return;
    } else {
      const data = this.state.existingOperatorsAvailableInitial;
      filter.operator = "startswith";
      let filteredResults = filterBy(data, filter);

      return filteredResults;
    }
  }

  onChangeNotifyEmail = (event) => {
    this.setState({ ...this.state, notifyEmailOperator: event.target.value });
  };

  // rowRender = (trElement, dataItem) => {
  //   const { primary_flg } = dataItem.dataItem;
  //   let color = "";
  //   if (primary_flg === true) {
  //     color = "#16b3169c";
  //   } else if (primary_flg === false) {
  //     // color = "#ed7070";
  //   } else {
  //     // color = "#0d5869";
  //   }

  //   const trProps = {
  //     ...trElement.props,
  //     onContextMenu: (e) => {
  //       e.preventDefault();
  //       // this.handleContextMenuOpen(e, dataItem.dataItem);
  //     },
  //     style: {
  //       color: color
  //     }
  //   };

  //   return React.cloneElement(trElement, {...trProps}, trElement.props.children);
  // }

  disableSaveBtn = () => {
    let val = false;
    if (
      this.state.postClientID === "" ||
      this.state.valueClientName === undefined ||
      this.state.notifyEmailOperator === "" ||
      !this.state.notifyEmailOperator ||
      this.state.notifyEmailOperator === ""
    ) {
      val = true;
    }
    return val;
  };

  handleRowClick = (event) => {
    if (!this.clickTimer) {
      // First click
      this.clickTimer = setTimeout(() => {
        // Single click action
        this.clickTimer = null; // Reset the timer
      }, 200); // Set an appropriate delay for detecting double-click
    } else {
      // Second click
      clearTimeout(this.clickTimer); // Cancel the single click action
      this.clickTimer = null; // Reset the timer
      // Double-click action

      // Perform any additional actions or logic
      const rowData = event.dataItem;
      const recordId = rowData.clientID; // Replace `id` with the actual property name containing the record ID

      // Construct the URL for the record's details page
      let final = "http://localhost:5002";
      let environmentLocation =
        window && window.location && window.location.hostname;
      if (environmentLocation === "dev2.swift-navigator.com") {
        final = "https://dev2.swift-navigator.com";
      } else if (environmentLocation === "uat2.swift-navigator.com") {
        final = "https://uat2.swift-navigator.com";
      } else if (environmentLocation === "prod2.swift-navigator.com") {
        final = "https://prod2.swift-navigator.com";
      }
      const recordUrl = `${final}/app/master/clients/${recordId}`;
      // Open the URL in a new browser tab
      window.open(recordUrl, "_blank");
    }
  };

  render() {
    const dataItem = this.props.dataItem;
    const { gridData } = this.state;
    const { hasBackofficeRead } = this.props;
    const hasEditedItem = gridData && gridData.data.some((p) => p.inEdit);

    return (
      <div>
        <div>
          <h4>NAME : {dataItem && dataItem.vessel_name}</h4>
          <h4>IMO : {dataItem && dataItem.vessel_imo_no}</h4>
          {this.state.openErrorModal ? (
            <Error style={{ color: "red" }}>
              <h3>{this.state.errorModalContent || ""}</h3>
            </Error>
          ) : null}
          {this.state.windowError ? (
            <Error style={{ color: "red", fontSize: "15px" }}>
              {this.state.windowErrorMessage || ""}
            </Error>
          ) : null}
          <Grid
            {...gridData}
            onRowClick={this.handleRowClick}
            filterable={false}
            style={{ className: "grid-no-select" }}
            sortable={true}
            resizable
            editField={this.editField}
            onItemChange={this.itemChange}
            pageable={false}
            // rowRender={this.rowRender}
          >
            <GridToolbar>
              <GridContainer xs={12} justify={"flex-start"} direction={"row"}>
                {!hasBackofficeRead && (
                  <Tooltip
                    justify={"flex-start"}
                    placement="top"
                    title={"Add Client"}
                  >
                    <IconButton>
                      <FontAwesomeIcon
                        color="#0D5869"
                        onClick={this.toggleWindow}
                        size="1.6x"
                        icon={faPlus}
                      />
                    </IconButton>
                  </Tooltip>
                )}
                {/* {
               !hasBackofficeRead && this.state.showDeleteAllButton ?
                  <Tooltip justify={"flex-start"} placement="top" title={"Remove All Clients"}>
                    <IconButton>
                      <Fab size="medium"
                      // color="secondary"
                      //  aria-label="Add"
                      >
                        <DeleteOutlineIcon
                          onClick={async e => {
                            window.localStorage.setItem('expandedItem', JSON.stringify(dataItem))
                            await this.props.dataItem.toggleWindowRemoveClients(e, dataItem)
                          }}
                        >
                        </DeleteOutlineIcon>
                      </Fab>
                    </IconButton>
                  </Tooltip>
                  : null
              } */}
              </GridContainer>
            </GridToolbar>

            {!hasBackofficeRead && (
              <Column
                cell={this.CommandCell}
                title="Actions"
                filterable={false}
                width={hasEditedItem ? "250px" : "220px"}
              />
            )}

            {this.renderGridColumns(dataItem, "", this.props)}
          </Grid>
        </div>

        {this.state.visibleWindow && (
          <Window
            title={`Set Client`}
            onClose={this.toggleWindow}
            width={900}
            height={600}
            initialTop={10}
          >
            <div>
              <br />
              <fieldset style={{ borderColor: "rgba(13, 88, 105, 1)" }}>
                <br />
                <legend>{`Vessel Name: ${dataItem.vessel_name}`}</legend>
                <div>
                  <AutoComplete
                    style={{ width: "450px" }}
                    data={this.state.client_names.map(
                      (temp) => temp.relation_name
                    )}
                    value={this.state.valueClientName}
                    label={"Client name"}
                    name={"relation_name"}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    onClose={this.onBlur}
                    listNoDataRender={this.listNoDataRender}
                    // placeholder="Search client name"
                  />
                  {this.state.valueClientName !== "" ? (
                    <div>
                      <h5>Postal</h5>
                      <input
                        className="k-textbox"
                        style={{ width: "450px" }}
                        name="relation_postal_code"
                        value={this.state.relation_postal_code || ""}
                        disabled={true}
                      />
                      <h5>Country</h5>
                      <input
                        className="k-textbox"
                        name="relation_country"
                        style={{ width: "450px" }}
                        value={this.state.relation_country || ""}
                        disabled={true}
                      />
                      {gridData?.data?.length === 0 ? (
                        <span>
                          <h5>Primary client</h5>
                          <input
                            className="k-textbox"
                            name="PRIMARY_CLIENT"
                            style={{ width: "450px" }}
                            value={"YES"}
                            disabled={true}
                          />
                        </span>
                      ) : null}
                    </div>
                  ) : null}
                </div>
                <br />
                <br />
                {gridData?.data?.length > 0 ? (
                  <span>
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={this.state.is_primary_flg_switchDisabled}
                          checked={this.state.primary_flg_checked}
                          onChange={this.handleChange("primary_flg_checked")}
                          value="primary_flg_checked"
                          color="primary"
                        />
                      }
                      label="Primary Flag"
                    />
                  </span>
                ) : null}
                <br />
                {this.state.primary_flg_checked === true ? (
                  <span>
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={this.state.is_active_dispatches_disabled}
                          checked={this.state.active_dispatches_checked}
                          onChange={this.handleChange(
                            "active_dispatches_checked"
                          )}
                          value="active_dispatches_checked"
                          color="primary"
                        />
                      }
                      label="Update Reporting Date"
                    />
                  </span>
                ) : null}

                <br />
                <span>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.activeOrdersFlg}
                        onChange={this.handleChange("activeOrdersFlg")}
                        value="activeOrdersFlg"
                        color="primary"
                      />
                    }
                    label="Move active orders"
                  />
                </span>

                {this.state.activeOrdersFlg ? (
                  <div style={{ marginBottom: "10px" }}>
                    <span>
                      <h3>Notify Operator</h3>
                      <DropDownList
                        data={(
                          this.state.existingOperatorsAvailable || []
                        ).sort((a, b) => (a.text > b.text ? 1 : -1))}
                        onChange={this.onChangeNotifyEmail}
                        onFilterChange={this.onFilterChange}
                        filterable={true}
                        style={{ width: "310px" }}
                        required={this.state.windowError || false}
                      />
                    </span>
                  </div>
                ) : null}

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <button
                    style={{ justifyContent: "flex-start" }}
                    type="button"
                    className="k-button k-primary"
                    onClick={(e) => this.toggleWindow(e)}
                  >
                    Cancel
                  </button>
                  <button
                    style={{ justifyContent: "flex-end" }}
                    type="button"
                    className="k-button k-primary"
                    onClick={(e) => this.saveVesselToClient(e)}
                    disabled={false}
                  >
                    Save
                  </button>
                </div>
              </fieldset>
            </div>
          </Window>
        )}
      </div>
    );
  }
}

export default VesselToClientExpanded;
