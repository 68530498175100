import React, { Fragment } from "react";

import "./Icon.scss";

import {
  faPlus,
  faBars,
  faCheck,
  faComments,
  faBell,
  faPaperPlane,
  faRoute,
  faBullhorn,
  faExclamationTriangle,
  faExclamationCircle,
  faInfoCircle,
  faInbox,
  faArchive,
  faFile,
  faCoins,
  faUserCog,
  faQuoteRight,
  faWarehouse,
  faSitemap,
  faFilePdf,
  faFileImage,
  faFileExcel,
  faFileCode,
  faUsersCog,
  faFileMedicalAlt,
  faDatabase,
  faTable,
  faUsers,
  faShip,
  faUserCheck,
  faInfo,
  faBuilding,
  faEye,
  faBoxOpen,
  faSignOutAlt,
  faUserFriends,
  faUserTie,
  faUserShield,
  faPlane,
  faUniversalAccess,
  faAnchor,
  faList,
  faServer,
  faMicrochip,
  faTruckPickup,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RefreshIcon from "@material-ui/icons/Refresh";
import DirectionsBoatIcon from "@material-ui/icons/DirectionsBoat";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CheckIcon from "@material-ui/icons/Check";
import AddIcon from "@material-ui/icons/Add";
import MenuIcon from "@material-ui/icons/Menu";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import ForwardIcon from "@material-ui/icons/Forward";
import SettingsIcon from "@material-ui/icons/Settings";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import LocalAirportIcon from "@material-ui/icons/LocalAirport";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import LocalPostOfficeIcon from "@material-ui/icons/LocalPostOffice";
import SendIcon from "@material-ui/icons/Send";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import EditIcon from "@material-ui/icons/Edit";
import GetAppIcon from "@material-ui/icons/GetApp";
import MoreVertTwoToneIcon from "@material-ui/icons/MoreVertTwoTone";
import SvgIcon from "@material-ui/core/SvgIcon";
import BugReportIcon from "@material-ui/icons/BugReport";

export const faIcons = {
  faPlus: (props) => {
    return <FontAwesomeIcon {...props} icon={faPlus} />;
  },
  faBars: (props) => {
    return <FontAwesomeIcon {...props} icon={faBars} />;
  },
  faCheck: (props) => {
    return <FontAwesomeIcon {...props} icon={faCheck} />;
  },
  faComments: (props) => {
    return <FontAwesomeIcon {...props} icon={faComments} />;
  },
  faBell: (props) => {
    return <FontAwesomeIcon {...props} icon={faBell} />;
  },
  faPaperPlane: (props) => {
    return <FontAwesomeIcon {...props} icon={faPaperPlane} />;
  },
  faRoute: (props) => {
    return <FontAwesomeIcon {...props} icon={faRoute} />;
  },
  faBullhorn: (props) => {
    return <FontAwesomeIcon {...props} icon={faBullhorn} />;
  },
  faExclamationTriangle: (props) => {
    return <FontAwesomeIcon {...props} icon={faExclamationTriangle} />;
  },
  faExclamationCircle: (props) => {
    return <FontAwesomeIcon {...props} icon={faExclamationCircle} />;
  },
  faInfoCircle: (props) => {
    return <FontAwesomeIcon {...props} icon={faInfoCircle} />;
  },
  faInbox: (props) => {
    return <FontAwesomeIcon {...props} icon={faInbox} />;
  },
  faArchive: (props) => {
    return <FontAwesomeIcon {...props} icon={faArchive} />;
  },
  faFile: (props) => {
    return <FontAwesomeIcon {...props} icon={faFile} />;
  },
  faCoins: (props) => {
    return <FontAwesomeIcon {...props} icon={faCoins} />;
  },
  faUserCog: (props) => {
    return <FontAwesomeIcon {...props} icon={faUserCog} />;
  },
  faQuoteRight: (props) => {
    return <FontAwesomeIcon {...props} icon={faQuoteRight} />;
  },
  faWarehouse: (props) => {
    return <FontAwesomeIcon {...props} icon={faWarehouse} />;
  },
  faSitemap: (props) => {
    return <FontAwesomeIcon {...props} icon={faSitemap} />;
  },
  faFilePdf: (props) => {
    return <FontAwesomeIcon {...props} icon={faFilePdf} />;
  },
  faFileImage: (props) => {
    return <FontAwesomeIcon {...props} icon={faFileImage} />;
  },
  faFileExcel: (props) => {
    return <FontAwesomeIcon {...props} icon={faFileExcel} />;
  },
  faFileCode: (props) => {
    return <FontAwesomeIcon {...props} icon={faFileCode} />;
  },
  faFileMedicalAlt: (props) => {
    return <FontAwesomeIcon {...props} icon={faFileMedicalAlt} />;
  },
  faDatabase: (props) => {
    return <FontAwesomeIcon {...props} icon={faDatabase} />;
  },
  faTable: (props) => {
    return <FontAwesomeIcon {...props} icon={faTable} />;
  },
  faUsers: (props) => {
    return <FontAwesomeIcon {...props} icon={faUsers} />;
  },
  faShip: (props) => {
    return <FontAwesomeIcon {...props} icon={faShip} />;
  },
  faUserCheck: (props) => {
    return <FontAwesomeIcon {...props} icon={faUserCheck} />;
  },
  faInfo: (props) => {
    return <FontAwesomeIcon {...props} icon={faInfo} />;
  },
  faBuilding: (props) => {
    return <FontAwesomeIcon {...props} icon={faBuilding} />;
  },
  faEye: (props) => {
    return <FontAwesomeIcon {...props} icon={faEye} />;
  },
  faBoxOpen: (props) => {
    return <FontAwesomeIcon {...props} icon={faBoxOpen} />;
  },
  faSignOutAlt: (props) => {
    return <FontAwesomeIcon {...props} icon={faSignOutAlt} />;
  },
  faUserFriends: (props) => {
    return <FontAwesomeIcon {...props} icon={faUserFriends} />;
  },
  faUserTie: (props) => {
    return <FontAwesomeIcon {...props} icon={faUserTie} />;
  },
  faUserShield: (props) => {
    return <FontAwesomeIcon {...props} icon={faUserShield} />;
  },
  faUsersCog: (props) => {
    return <FontAwesomeIcon {...props} icon={faUsersCog} />;
  },
  faPlane: (props) => {
    return <FontAwesomeIcon {...props} icon={faPlane} />;
  },
  faUniversalAccess: (props) => {
    return <FontAwesomeIcon {...props} icon={faUniversalAccess} />;
  },
  faAnchor: (props) => {
    return <FontAwesomeIcon {...props} icon={faAnchor} />;
  },
  faList: (props) => {
    return <FontAwesomeIcon {...props} icon={faList} />;
  },
  faServer: (props) => {
    return <FontAwesomeIcon {...props} icon={faServer} />;
  },
  faMicrochip: (props) => {
    return <FontAwesomeIcon {...props} icon={faMicrochip} />;
  },
  faTruckPickup: (props) => {
    return <FontAwesomeIcon {...props} icon={faTruckPickup} />;
  },
  faWrench: (props) => {
    return <FontAwesomeIcon {...props} icon={faWrench} />;
  },
};

export const icons = {
  BoxIcon: (props) => {
    return (
      <SvgIcon {...props}>
        <path d="m11.992 24c-.079 0-.159-.019-.231-.057l-11.492-6c-.165-.086-.269-.257-.269-.443v-11.25c0-.173.09-.334.237-.425s.332-.099.486-.022l11.492 5.75c.169.085.276.258.276.447v11.5c0 .175-.091.337-.241.428-.078.048-.168.072-.258.072zm-10.992-6.803 10.492 5.478v-10.366l-10.492-5.25z" />
        <path d="m12.008 24c-.09 0-.18-.024-.259-.072-.149-.091-.241-.253-.241-.428v-11.5c0-.189.107-.362.276-.447l11.492-5.75c.153-.078.339-.07.486.022.148.091.238.252.238.425v11.25c0 .186-.104.357-.269.443l-11.492 6c-.072.038-.152.057-.231.057zm.5-11.691v10.366l10.492-5.478v-10.138zm10.992 5.191h.01z" />
        <path d="m.5 6.75c-.184 0-.36-.101-.448-.276-.124-.247-.023-.547.224-.671l11.508-5.75c.141-.07.307-.07.447 0l11.492 5.75c.247.124.347.424.224.671-.124.247-.423.346-.671.224l-11.268-5.639-11.284 5.638c-.072.036-.148.053-.224.053z" />
        <path d="m18 15c-.276 0-.5-.224-.5-.5v-5.191l-11.216-5.612c-.247-.123-.347-.424-.223-.671s.424-.346.671-.224l11.492 5.75c.169.086.276.259.276.448v5.5c0 .276-.224.5-.5.5z" />
      </SvgIcon>
    );
  },
  OrdersIcon: (props) => {
    return (
      <SvgIcon {...props}>
        <path d="m18.5 24h-13c-1.378 0-2.5-1.122-2.5-2.5v-19c0-1.378 1.122-2.5 2.5-2.5h13c1.378 0 2.5 1.122 2.5 2.5v19c0 1.378-1.122 2.5-2.5 2.5zm-13-23c-.827 0-1.5.673-1.5 1.5v19c0 .827.673 1.5 1.5 1.5h13c.827 0 1.5-.673 1.5-1.5v-19c0-.827-.673-1.5-1.5-1.5z" />
        <path d="m17.5 14h-11c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h11c.276 0 .5.224.5.5s-.224.5-.5.5z" />
        <path d="m17.5 18h-11c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h11c.276 0 .5.224.5.5s-.224.5-.5.5z" />
        <path d="m17.5 10h-11c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h11c.276 0 .5.224.5.5s-.224.5-.5.5z" />
        <path d="m12.5 6h-6c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h6c.276 0 .5.224.5.5s-.224.5-.5.5z" />
      </SvgIcon>
    );
  },
  DispatchIcon: (props) => {
    return (
      <SvgIcon {...props}>
        <path d="m16.5 10h-9c-.276 0-.5-.224-.5-.5v-9c0-.276.224-.5.5-.5h9c.276 0 .5.224.5.5v9c0 .276-.224.5-.5.5zm-8.5-1h8v-8h-8z" />
        <path d="m13.5 4h-3c-.276 0-.5-.224-.5-.5v-3c0-.276.224-.5.5-.5h3c.276 0 .5.224.5.5v3c0 .276-.224.5-.5.5zm-2.5-1h2v-2h-2z" />
        <path d="m12 19h-9.5c-.276 0-.5-.224-.5-.5v-9c0-.276.224-.5.5-.5h9.5c.276 0 .5.224.5.5v9c0 .276-.224.5-.5.5zm-9-1h8.5v-8h-8.5z" />
        <path d="m8.5 13h-3c-.276 0-.5-.224-.5-.5v-3c0-.276.224-.5.5-.5h3c.276 0 .5.224.5.5v3c0 .276-.224.5-.5.5zm-2.5-1h2v-2h-2z" />
        <path d="m21.5 19h-9.5c-.276 0-.5-.224-.5-.5v-9c0-.276.224-.5.5-.5h9.5c.276 0 .5.224.5.5v9c0 .276-.224.5-.5.5zm-9-1h8.5v-8h-8.5z" />
        <path d="m18.5 13h-3c-.276 0-.5-.224-.5-.5v-3c0-.276.224-.5.5-.5h3c.276 0 .5.224.5.5v3c0 .276-.224.5-.5.5zm-2.5-1h2v-2h-2z" />
        <path d="m23.5 24h-4c-.276 0-.5-.224-.5-.5v-1.5h-4v1.5c0 .276-.224.5-.5.5h-5c-.276 0-.5-.224-.5-.5v-1.5h-4v1.5c0 .276-.224.5-.5.5h-4c-.276 0-.5-.224-.5-.5v-5c0-.276.224-.5.5-.5h23c.276 0 .5.224.5.5v5c0 .276-.224.5-.5.5zm-3.5-1h3v-4h-22v4h3v-1.5c0-.276.224-.5.5-.5h5c.276 0 .5.224.5.5v1.5h4v-1.5c0-.276.224-.5.5-.5h5c.276 0 .5.224.5.5z" />
      </SvgIcon>
    );
  },
  DirectionsBoatIcon: (props) => {
    return <DirectionsBoatIcon {...props} />;
  },
  AccountCircleIcon: (props) => {
    return <AccountCircleIcon {...props} />;
  },
  CheckIcon: (props) => {
    return <CheckIcon {...props} />;
  },
  BugReportIcon: (props) => {
    return <BugReportIcon {...props} />;
  },
  AddIcon: (props) => {
    return <AddIcon {...props} />;
  },
  MenuIcon: (props) => {
    return <MenuIcon {...props} />;
  },
  AllInboxIcon: (props) => {
    return <AllInboxIcon {...props} />;
  },
  ForwardIcon: (props) => {
    return <ForwardIcon {...props} />;
  },
  SettingsIcon: (props) => {
    return <SettingsIcon {...props} />;
  },
  PowerSettingsNewIcon: (props) => {
    return <PowerSettingsNewIcon {...props} />;
  },
  SearchIcon: (props) => {
    return <SearchIcon {...props} />;
  },
  CloseIcon: (props) => {
    return <CloseIcon {...props} />;
  },
  ArrowDropDownIcon: (props) => {
    return <ArrowDropDownIcon {...props} />;
  },
  ChevronRightIcon: (props) => {
    return <ChevronRightIcon {...props} />;
  },
  LocalShippingIcon: (props) => {
    return <LocalShippingIcon {...props} />;
  },
  LocalAirportIcon: (props) => {
    return <LocalAirportIcon {...props} />;
  },
  LocalMallIcon: (props) => {
    return <LocalMallIcon {...props} />;
  },
  LocalPostOfficeIcon: (props) => {
    return <LocalPostOfficeIcon {...props} />;
  },
  SendIcon: (props) => {
    return <SendIcon {...props} />;
  },
  ArrowRightAltIcon: (props) => {
    return <ArrowRightAltIcon {...props} />;
  },
  EditIcon: (props) => {
    return <EditIcon {...props} />;
  },
  GetAppIcon: (props) => {
    return <GetAppIcon {...props} />;
  },
  RefreshIcon: (props) => {
    return <RefreshIcon {...props} />;
  },
  MoreVertTwoToneIcon: (props) => {
    return <MoreVertTwoToneIcon {...props} />;
  },
};

class Icon extends React.PureComponent {
  render() {
    let props = { ...this.props };
    delete props.icon;

    return <Fragment>{this.props.icon && this.props.icon(props)}</Fragment>;
  }
}

export default Icon;
