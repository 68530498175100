export const columnsSchema = () => [
  {
    field: 'id',
    title: 'ID',
    visible: true,
    minWidth: 170,
    sortable: true,
    filterable: true,
    locked: 'locked',
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
    // cell: true
  },
  {
    field: 'description',
    title: 'Description',
    visible: true,
    // minWidth: 170,
    sortable: true,
    filterable: true,
    locked: 'locked',
  },
  {
    field: 'bankName',
    title: 'Bank Name',
    visible: true,
    // minWidth: 170,
    sortable: true,
    filterable: true,
    locked: 'locked',
  },
  {
    field: 'bicCode',
    title: 'Bic Code',
    visible: true,
    // minWidth: 170,
    sortable: true,
    filterable: true,
    locked: 'locked',
  },
  {
    field: 'vatNumber',
    title: 'Vat Number',
    visible: true,
    // minWidth: 170,
    sortable: true,
    filterable: true,
    locked: 'locked',
    // editable:'false'
  },
  // {
  //     field: 'masterEntityContacts.contact_name',
  //     title: 'Name',
  //     visible: true,
  //     // minWidth: 170,
  //     sortable: true,
  //     filterable: true,
  //     locked: 'locked',
  //     editable: 'false'
  // }
];
