import { combineReducers } from "redux";
// import { languageReducer } from "../reducers/commonReducers/languageReducer";
// import { generalLayoutReducer } from "../../layouts/GeneralLayout/state";
// import storage from "redux-persist/es/storage/session";
// import { persistReducer } from "redux-persist";
// import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
// import {dispatchesReducer} from "../../routes/Dispatches/state";
// import { loginReducer } from "../../routes/Login/state";
// import {userManagementReducer} from "../../routes/UserManagement/state";
import { MainLayoutReducer } from "../../layouts/MainLayout/state";

//TODO on refresh persist the view
// const generalPersistConfig = {
//   key: "generalLayoutReducer",
//   storage,
//   whitelist: ["viewProps"]
// };

// const loginPersistConfig = {
//   key: "loginReducer",
//   storage,
//   whitelist: ["loginPayload"]
// };

// const mainLayoutPersistConfig = {
//   key: "mainLayoutReducer",
//   storage,
//   whitelist: ["id"]
// };

const applicationReducers = combineReducers({
  // loginReducer: persistReducer(loginPersistConfig, loginReducer),
  // generalLayout: persistReducer(generalPersistConfig, generalLayoutReducer),
  // mainLayoutReducer: persistReducer(mainLayoutPersistConfig, MainLayoutReducer)
  MainLayoutReducer: MainLayoutReducer,
});

export default applicationReducers;
