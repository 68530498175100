import React, { Fragment, Component } from "react";
import { serverApi } from "../../../../../networking/config";
import _ from "lodash";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { goBack } from "../../../../../lib/reactRouterHelpers/history";
import BlockUi from "react-block-ui";
import Snackbar from "../../../../../components/Snackbar/Snackbar";
import { Error } from "@progress/kendo-react-labels";
import { ComboBox } from "@progress/kendo-react-dropdowns";

class EditUserDepartments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      finalUserDepartments: [],
      visibleDepartments: [],
      inputValue: "",
      switchChecked: false,
      blocking: false,
      primaryDepartment: "",
      primaryDepartmentList: [],
    };
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      blocking: true,
    });

    const id = this.props.match.params.id;
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    let typeOfDepartment = null;
    let initialUserDepartments = [];
    let tempDepartments = [];
    const snUserRequest = serverApi(
      "GET",
      `snUsers/${id}?filter[include][0][userToDepts][department]`,
      "",
      "",
      options
    );

    snUserRequest
      .then((response1) => {
        this.setState({
          ...this.state,
          username: response1.data.username || "",
        });
        let userToDeptsArr = response1?.data?.userToDepts || [];
        _.forEach(userToDeptsArr, (dept) => {
          let deptName = dept?.department?.name || "N/A";
          let deptLocation = dept?.department?.location || "N/A";
          let deptID = dept.deptID;
          let deleteID = dept.id;
          let primary_flg = dept.primary_flg;
          typeOfDepartment = dept?.department?.type || "N/A";
          let listItemName = `${deptName} [${deptLocation}]`;

          initialUserDepartments.push({
            name: listItemName,
            checked: true,
            deptID: deptID,
            deleteID: deleteID,
            primary_flg: primary_flg,
          });
        });

        const getAllDepsRequest = serverApi(
          "GET",
          `departments`,
          "",
          "",
          options,
          ""
        );
        getAllDepsRequest
          .then((response2) => {
            let allDepartmentsArr = response2?.data || [];
            // let opsDepts = allDepartmentsArr.filter(x => x.type === 'OPS')
            allDepartmentsArr.forEach((temp) => {
              tempDepartments.push({
                name: `${temp.name} [${temp?.location || "N/A"}]`,
                deptID: temp.id,
                checked: false,
              });
            });

            initialUserDepartments.forEach((item) => {
              let department = tempDepartments.find(
                (x) => x.name === item.name
              );
              if (department) {
                department.checked = true;
              }
            });

            //add to finalUserDepartments the deleteID property needed
            _.forEach(initialUserDepartments, (temp1) => {
              _.map(tempDepartments, (temp2) => {
                if (temp1.deptID === temp2.deptID) {
                  temp2.deleteID = temp1.deleteID;
                }
              });
            });

            let primaryDepartmentName = null;
            let primaryDepartmentArr = initialUserDepartments.filter(
              (x) => x.primary_flg
            );
            if (primaryDepartmentArr.length > 0) {
              primaryDepartmentName = primaryDepartmentArr[0].name || "";
            }
            let primaryDepartmentList =
              tempDepartments.length > 0 &&
              tempDepartments
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((x) => x.name);

            this.setState({
              visibleDepartments: initialUserDepartments.sort((a, b) =>
                a.name > b.name ? 1 : -1
              ),
              finalUserDepartments: tempDepartments.sort((a, b) =>
                a.name > b.name ? 1 : -1
              ),
              initialFinalUserClients: tempDepartments.sort((a, b) =>
                a.name > b.name ? 1 : -1
              ),

              primaryDepartmentList: primaryDepartmentList,
              primaryDepartment: primaryDepartmentName,
              typeOfDepartment: typeOfDepartment,

              blocking: false,
            });
          })
          .catch((e) => {
            this.setState({
              ...this.state,
              blocking: false,
              openErrorModal: true,
              errorModalContent:
                e?.response?.data?.error?.message || "Please try again",
            });
          });
      })
      .catch((e) => {
        this.setState({
          ...this.state,
          blocking: false,
          openErrorModal: true,
          errorModalContent:
            e?.response?.data?.error?.message ||
            "Please refresh the page and if the problem persist contact the administrator.",
        });
      });
  }

  fetchLatestUserDepartments = async () => {
    const id = this.props.match.params.id;
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    let typeOfDepartment = null;
    let initialUserDepartments = [];
    let tempDepartments = [];
    const snUserRequest = serverApi(
      "GET",
      `snUsers/${id}?filter[include][0][userToDepts][department]`,
      "",
      "",
      options
    );

    snUserRequest
      .then((response1) => {
        this.setState({
          ...this.state,
          username: response1.data.username || "",
        });
        let userToDeptsArr = response1?.data?.userToDepts || [];
        _.forEach(userToDeptsArr, (dept) => {
          let deptName = dept?.department?.name || "N/A";
          let deptLocation = dept?.department?.location || "N/A";
          let deptID = dept.deptID;
          let deleteID = dept.id;
          let primary_flg = dept.primary_flg;
          typeOfDepartment = dept?.department?.type || "N/A";
          let listItemName = `${deptName} [${deptLocation}]`;

          initialUserDepartments.push({
            name: listItemName,
            checked: true,
            deptID: deptID,
            deleteID: deleteID,
            primary_flg: primary_flg,
          });
        });

        const getAllDepsRequest = serverApi(
          "GET",
          `departments`,
          "",
          "",
          options,
          ""
        );
        getAllDepsRequest
          .then((response2) => {
            let allDepartmentsArr = response2?.data || [];
            // let opsDepts = allDepartmentsArr.filter(x => x.type === 'OPS')
            allDepartmentsArr.forEach((temp) => {
              tempDepartments.push({
                name: `${temp.name} [${temp?.location || "N/A"}]`,
                deptID: temp.id,
                checked: false,
              });
            });

            initialUserDepartments.forEach((item) => {
              let department = tempDepartments.find(
                (x) => x.name === item.name
              );
              if (department) {
                department.checked = true;
              }
            });

            //add to finalUserDepartments the deleteID property needed
            _.forEach(initialUserDepartments, (temp1) => {
              _.map(tempDepartments, (temp2) => {
                if (temp1.deptID === temp2.deptID) {
                  temp2.deleteID = temp1.deleteID;
                }
              });
            });

            let primaryDepartmentName = null;
            let primaryDepartmentArr = initialUserDepartments.filter(
              (x) => x.primary_flg
            );
            if (primaryDepartmentArr.length > 0) {
              primaryDepartmentName = primaryDepartmentArr[0].name || "";
            }
            let primaryDepartmentList =
              tempDepartments.length > 0 &&
              tempDepartments
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((x) => x.name);

            this.setState(
              {
                visibleDepartments: initialUserDepartments.sort((a, b) =>
                  a.name > b.name ? 1 : -1
                ),
                finalUserDepartments: tempDepartments.sort((a, b) =>
                  a.name > b.name ? 1 : -1
                ),
                initialFinalUserClients: tempDepartments.sort((a, b) =>
                  a.name > b.name ? 1 : -1
                ),

                primaryDepartmentList: primaryDepartmentList,
                primaryDepartment: primaryDepartmentName,
                typeOfDepartment: typeOfDepartment,

                blocking: false,
              },
              () => {
                this.setState({ ...this.state });
                this.renderDepartmentList(
                  this.state.finalUserDepartments,
                  this.state.visibleDepartments
                );
              }
            );
          })
          .catch((e) => {
            this.setState({
              ...this.state,
              blocking: false,
              openErrorModal: true,
              errorModalContent:
                e?.response?.data?.error?.message || "Please try again",
            });
          });
      })
      .catch((e) => {
        this.setState({
          ...this.state,
          blocking: false,
          openErrorModal: true,
          errorModalContent:
            e?.response?.data?.error?.message ||
            "Please refresh the page and if the problem persist contact the administrator.",
        });
      });
  };

  handleChange = async (event, name, key) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    let userID = this.props.match.params.id;
    let finalUserDepartments = [...this.state.finalUserDepartments];
    let finalObj = finalUserDepartments.find((test) => test.deptID === key);
    finalObj.checked = !finalObj.checked;

    let data = this.state.finalUserDepartments;
    const deleteObj = data.find((obj) => obj.deptID === key);
    const delete_id = deleteObj.deleteID;

    let fil = {
      filter: {
        where: {
          and: [
            {
              userID: parseInt(userID),
            },
            {
              deptID: deleteObj.deptID,
            },
            {
              primary_flg: true,
            },
          ],
        },
      },
    };
    let usersOfDepartment = await serverApi(
      "GET",
      `userToDepts`,
      fil,
      "",
      options
    );
    if (usersOfDepartment.data.length > 0 && event.value === false) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            "Operation not allowed.Firstly set another primary department and then remove this department.",
        },
        () => {
          setTimeout(() => {
            this.setState(
              {
                ...this.state,
                openErrorModal: false,
                errorModalContent: "",
              },
              () => {
                window.location.reload();
              }
            );
          }, 3000);
        }
      );
    } else {
      this.setState(
        {
          ...this.state,
          finalUserDepartments: finalUserDepartments,
        },
        async () => {
          let initialUserDepartments = [];
          let postOrDeleteClient = event.value === false ? "DELETE" : "POST";
          let payload = {
            userID: parseInt(userID),
            deptID: finalObj.deptID,
            primary_flg: false,
          };

          if (postOrDeleteClient === "POST") {
            this.setState({ ...this.state, blocking: true });

            const requestPOST = serverApi(
              "POST",
              `userToDepts`,
              "",
              payload,
              options,
              ""
            );
            requestPOST
              .then((postResponse) => {
                let tempDepartments = [];
                const snUserRequest = serverApi(
                  "GET",
                  `snUsers/${userID}?filter[include][0][userToDepts][department]`,
                  "",
                  "",
                  options
                );
                snUserRequest
                  .then((response1) => {
                    this.setState({
                      ...this.state,
                      username: response1.data.username || "",
                    });
                    let userToDeptsArr = response1?.data?.userToDepts || [];
                    // userToDeptsArr.filter(x => x.department.type === 'OPS')
                    _.forEach(userToDeptsArr, (dept) => {
                      let deptName = dept?.department?.name || "N/A";
                      let deptLocation = dept?.department?.location || "N/A";
                      let deptID = dept.deptID;
                      let deleteID = dept.id;
                      initialUserDepartments.push({
                        name: `${deptName} [${deptLocation}]`,
                        checked: true,
                        deptID: deptID,
                        deleteID: deleteID,
                      });
                    });
                    const getAllDepsRequest = serverApi(
                      "GET",
                      `departments`,
                      "",
                      "",
                      options,
                      ""
                    );
                    getAllDepsRequest
                      .then((response2) => {
                        let allDepartmentsArr = response2?.data || [];
                        // let opsDepartments = allDepartmentsArr.filter(x => x.type === 'OPS')
                        allDepartmentsArr.forEach((temp) => {
                          tempDepartments.push({
                            name: `${temp.name} [${temp?.location || "N/A"}]`,
                            deptID: temp.id,
                            checked: false,
                          });
                        });
                        initialUserDepartments.forEach((item) => {
                          let department = tempDepartments.find(
                            (x) => x.name === item.name
                          );
                          if (department) {
                            department.checked = true;
                          }
                        });
                        //add to finalUserDepartments the deleteID property needed
                        _.forEach(initialUserDepartments, (temp1) => {
                          _.map(tempDepartments, (temp2) => {
                            if (temp1.deptID === temp2.deptID) {
                              temp2.deleteID = temp1.deleteID;
                            }
                          });
                        });

                        this.setState({
                          visibleDepartments: initialUserDepartments.sort(
                            (a, b) => (a.name > b.name ? 1 : -1)
                          ),
                          finalUserDepartments: tempDepartments.sort((a, b) =>
                            a.name > b.name ? 1 : -1
                          ),
                          // finalUserDepartments2: deptWithDomainArr.sort((a, b) => (a.name > b.name) ? 1 : -1),
                          initialFinalUserClients: tempDepartments.sort(
                            (a, b) => (a.name > b.name ? 1 : -1)
                          ),
                          // initialFinalUserClients2: deptWithDomainArr.sort((a, b) => (a.name > b.name) ? 1 : -1),
                          blocking: false,
                          openErrorModal: false,
                          errorModalContent: "",
                        });
                      })
                      .catch((e) => {
                        this.setState({
                          ...this.state,
                          blocking: false,
                          openErrorModal: true,
                          errorModalContent:
                            e?.response?.data?.error?.message ||
                            "Please try again",
                        });
                      });
                  })
                  .catch((e) => {
                    this.setState({
                      ...this.state,
                      blocking: false,
                      openErrorModal: true,
                      errorModalContent:
                        e?.response?.data?.error?.message || "Please try again",
                    });
                  });
              })
              .catch((e) => {
                this.setState({
                  ...this.state,
                  blocking: false,
                  openErrorModal: true,
                  errorModalContent:
                    e?.response?.data?.error?.message ||
                    "Unable to attach the department to user.",
                });
              });
          } else if (postOrDeleteClient === "DELETE") {
            this.setState({ ...this.state, blocking: true });
            const url = `userToDepts/${delete_id}`;

            const deleteDepartment = serverApi(
              "DELETE",
              url,
              "",
              "",
              options,
              ""
            );
            deleteDepartment
              .then((deleteResponse) => {
                // console.log('delete')
                let tempDepartments = [];
                const snUserRequest = serverApi(
                  "GET",
                  `snUsers/${userID}?filter[include][0][userToDepts][department]`,
                  "",
                  "",
                  options
                );
                snUserRequest
                  .then((response1) => {
                    this.setState({
                      ...this.state,
                      username: response1.data.username || "",
                    });
                    let userToDeptsArr = response1?.data?.userToDepts || [];
                    // userToDeptsArr.filter(x => x.department.type === 'OPS')
                    _.forEach(userToDeptsArr, (dept) => {
                      let deptName = dept?.department?.name || "N/A";
                      let deptLocation = dept?.department?.location || "N/A";
                      let deptID = dept.deptID;
                      let deleteID = dept.id;
                      initialUserDepartments.push({
                        name: `${deptName} [${deptLocation}]`,
                        checked: true,
                        deptID: deptID,
                        deleteID: deleteID,
                      });
                    });
                    const getAllDepsRequest = serverApi(
                      "GET",
                      `departments`,
                      "",
                      "",
                      options,
                      ""
                    );
                    getAllDepsRequest
                      .then((response2) => {
                        let allDepartmentsArr = response2?.data || [];
                        // let opsDepartments = allDepartmentsArr.filter(x => x.type === 'OPS')
                        allDepartmentsArr.forEach((temp) => {
                          tempDepartments.push({
                            name: `${temp.name} [${temp?.location || "N/A"}]`,
                            deptID: temp.id,
                            checked: false,
                          });
                        });
                        initialUserDepartments.forEach((item) => {
                          let department = tempDepartments.find(
                            (x) => x.name === item.name
                          );
                          if (department) {
                            department.checked = true;
                          }
                        });
                        //add to finalUserDepartments the deleteID property needed
                        _.forEach(initialUserDepartments, (temp1) => {
                          _.map(tempDepartments, (temp2) => {
                            if (temp1.deptID === temp2.deptID) {
                              temp2.deleteID = temp1.deleteID;
                            }
                          });
                        });

                        this.setState({
                          visibleDepartments: initialUserDepartments.sort(
                            (a, b) => (a.name > b.name ? 1 : -1)
                          ),
                          finalUserDepartments: tempDepartments.sort((a, b) =>
                            a.name > b.name ? 1 : -1
                          ),
                          // finalUserDepartments2: deptWithDomainArr.sort((a, b) => (a.name > b.name) ? 1 : -1),
                          initialFinalUserClients: tempDepartments.sort(
                            (a, b) => (a.name > b.name ? 1 : -1)
                          ),
                          // initialFinalUserClients2: deptWithDomainArr.sort((a, b) => (a.name > b.name) ? 1 : -1),
                          blocking: false,
                          openErrorModal: false,
                          errorModalContent: "",
                        });
                      })
                      .catch((e) => {
                        this.setState({
                          ...this.state,
                          blocking: false,
                          openErrorModal: true,
                          errorModalContent:
                            e?.response?.data?.error?.message ||
                            "Please try again",
                        });
                      });
                  })
                  .catch((e) => {
                    this.setState({
                      ...this.state,
                      blocking: false,
                      openErrorModal: true,
                      errorModalContent:
                        e?.response?.data?.error?.message || "Please try again",
                    });
                  });
              })
              .catch((e) => {
                this.setState({
                  ...this.state,
                  blocking: false,
                  openErrorModal: true,
                  errorModalContent:
                    e.response.data.error.message ||
                    "Unable to remove the department to user.",
                });
              });
          }
        }
      );
    }
  };

  handleSearch = (e) => {
    let initialFinalUserClients = this.state.initialFinalUserClients;

    if (e.value === "") {
      this.setState({
        inputValue: e.value,
        finalUserDepartments: initialFinalUserClients,
      });
    } else {
      let searchArr = [];
      initialFinalUserClients.forEach((temp) => {
        if (temp.name.toUpperCase().includes(e.value.toUpperCase())) {
          searchArr.push(temp);
        }
      });

      this.setState({
        inputValue: e.value,
        finalUserDepartments: searchArr,
      });
    }
  };

  goBack = () => {
    goBack();
  };

  renderDepartmentList = (
    finalUserDepartments = [],
    visibleDepartments = [],
    doRefresh
  ) => {
    if (doRefresh === true) {
      window.location.reload();
    } else {
      return (
        finalUserDepartments &&
        finalUserDepartments.map((item) => {
          return (
            <ul style={{ marginBottom: "-5px" }} key={item.id}>
              <Checkbox
                name={item.name}
                key={item.name}
                value={item.checked}
                onChange={(e) => this.handleChange(e, item.name, item.deptID)}
                label={item.name}
                disabled={item.primary_flg === true ? true : false}
              />
            </ul>
          );
        })
      );
    }
  };

  makePostCalls = async () => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let userID = this.props.match.params.id;

    let { finalUserDepartments } = this.state;
    let trueArrForPost = _.filter(
      finalUserDepartments,
      (temp) => temp.checked === false
    );
    let falseArrForDelete = _.filter(
      finalUserDepartments,
      (temp) => temp.checked === true
    );

    let successPosts = [];
    let errorPosts = [];

    if (trueArrForPost.length > 0) {
      // eslint-disable-next-line no-unused-vars
      for (let postClient of trueArrForPost) {
        try {
          let payload = {
            userID: parseInt(userID),
            deptID: postClient.deptID,
          };

          const url = `userToDepts`;
          this.setState({ blocking: true });

          const postRequest = await serverApi(
            "POST",
            url,
            "",
            payload,
            options,
            "",
            ""
          );
          if (postRequest.status === 200) {
            successPosts.push(postRequest.data);
          }
        } catch (e) {
          errorPosts.push("true");
          this.setState({
            ...this.state,
            blocking: false,
            openErrorModal: true,
            errorModalContent:
              e.response.data.error.message ||
              "Unable to attach all departments to user.",
          });
        }
      }
    }

    if (successPosts.length > 0) {
      let final = [...trueArrForPost, ...falseArrForDelete];
      this.renderDepartmentList(final, [], true);
    }
  };

  makeDeleteCalls = () => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let { finalUserDepartments } = this.state;
    // let trueArrForPost = _.filter(finalUserDepartments, temp => temp.checked === false)
    // let falseArrForDelete = _.filter(finalUserDepartments, temp => temp.checked === true)

    let falseArr = _.forEach(finalUserDepartments, (temp) => {
      temp.checked = false;
    });

    let successPosts = [];
    let errorPosts = [];

    const userID =
      window.localStorage.getItem("userID") || this.props.match.params.id;
    const getUser2DepartmentsURL = `userToDepts?filter={"where": {"userID": ${userID}}}`;
    const user2Deps = serverApi(
      "GET",
      getUser2DepartmentsURL,
      "",
      "",
      options,
      "",
      ""
    );
    this.setState({ blocking: true });
    user2Deps
      .then(async (response) => {
        let resp = [...response.data];

        // eslint-disable-next-line no-unused-vars
        for (let small of resp) {
          try {
            let deleteURL = `userToDepts/${small.id}`;
            const deleteRequest = await serverApi(
              "DELETE",
              deleteURL,
              "",
              "",
              options,
              "",
              ""
            );
            if (deleteRequest.status === 200) {
              successPosts.push(deleteRequest.status);
            }
          } catch (e) {
            errorPosts.push("true");
            this.setState({
              ...this.state,
              blocking: false,
              openErrorModal: true,
              errorModalContent:
                e.response.data.error.message ||
                "Unable to remove all departments from user.",
            });
          }
        }
        this.setState({ blocking: false });

        if (successPosts.length > 0) {
          this.renderDepartmentList(falseArr, [], true);
        }
      })
      .catch((e) => {
        this.setState({
          ...this.state,
          blocking: false,
          openErrorModal: true,
          errorModalContent:
            e.response.data.error.message || "Please try again.",
        });
      });
  };

  handleErrorClose = (loginError) => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      openErrorModalContent: "",
    });
  };

  handleDepartmentChange = (event) => {
    this.setState({
      ...this.state,
      primaryDepartment: event.target.value,
    });
  };

  savePrimaryDept = () => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    const userID =
      window.localStorage.getItem("userID") || this.props.match.params.id;
    const getUser2DepartmentsURL = `userToDepts?filter={"where": {"userID": ${userID}}, "include": ["department"]}`;
    const user2Deps = serverApi(
      "GET",
      getUser2DepartmentsURL,
      "",
      "",
      options,
      "",
      ""
    );
    this.setState({ blocking: true });
    user2Deps
      .then(async (response) => {
        let resp = [...response.data];

        let rowId = resp.filter((x) => x.primary_flg)[0].id;
        let setPrimaryDeptID = this.state.initialFinalUserClients.filter(
          (x) => x.name === this.state.primaryDepartment
        )[0].deptID;
        this.state.initialFinalUserClients.filter(
          (x) => x.name === this.state.primaryDepartment
        )[0].checked = true;

        try {
          let patchUrl = `userToDepts/${rowId}`;
          let data = {
            userID: parseInt(userID),
            deptID: setPrimaryDeptID,
            primary_flg: false,
          };
          await serverApi("PATCH", patchUrl, "", data, options, "", "");

          let primaryDeptID = resp.filter(
            (x) =>
              `${x.department.name} [${x.department.location}]` ===
              this.state.primaryDepartment
          )[0].id;
          let patchUrlTrue = `userToDepts/${primaryDeptID}`;
          let data2 = {
            userID: parseInt(userID),
            deptID: setPrimaryDeptID,
            primary_flg: true,
          };
          await serverApi("PATCH", patchUrlTrue, "", data2, options, "", "");

          this.setState(
            {
              ...this.state,
              openErrorModal: false,
              errorModalContent: "",
            },
            () => {
              setTimeout(() => {
                this.setState({
                  ...this.state,
                  blocking: false,
                  openSuccessMessage: true,
                  successMessage:
                    "Primary department was changed successfully.",
                });
              }, 1000);
            }
          );
        } catch (e) {
          this.setState({
            ...this.state,
            blocking: false,
            openErrorModal: true,
            errorModalContent:
              e?.response?.data?.error?.message ||
              "Set primary department of the user failed.Please try again.",
          });
        }

        this.setState({ blocking: false });
      })
      .catch((e) => {
        this.setState({
          ...this.state,
          blocking: false,
          openErrorModal: true,
          errorModalContent:
            e.response.data.error.message || "Please try again.",
        });
      });
  };

  handleSuccessSnackbar = () => {
    this.setState({
      ...this.state,
      successMessage:
        this.state.openSuccessMessage === true ? this.state.successMessage : "",
      openSuccessMessage: !this.state.openSuccessMessage,
    });
  };

  renderPrimaryDepartmentDiv = () => {
    return (
      <div>
        <ComboBox
          style={{ marginLeft: "35px", marginTop: "10px", width: "220px" }}
          placeholder="Primary department"
          onChange={(e) => this.handleDepartmentChange(e)}
          value={this.state.primaryDepartment}
          data={this.state.primaryDepartmentList}
          suggest={true}
        />
        <button
          style={{ marginLeft: "10px" }}
          className="k-button"
          onClick={(e) => this.savePrimaryDept()}
        >
          Set Primary Department
        </button>
      </div>
    );
  };

  render() {
    const { inputValue, username } = this.state;

    return (
      <Fragment>
        <BlockUi
          tag="div"
          blocking={this.state.blocking}
          message="Loading, please wait. . ."
          renderChildren={true}
        >
          <div
            style={{ textAling: "left", display: "flex", flexDirection: "row" }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex-end",
                  marginLeft: "35px",
                  marginTop: "30px",
                  marginBottom: "40px",
                }}
              >
                <button className="k-button" onClick={(e) => this.goBack()}>
                  Back
                </button>
              </div>
              {this.state.blocking ? (
                ""
              ) : (
                <div
                  style={{
                    display: "flex",
                    marginLeft: "35px",
                    marginTop: "20px",
                    justifyContent: "column",
                  }}
                >
                  <span style={{ text: "bold", fontSize: "32px" }}>
                    Username: {username}
                  </span>
                  <br />
                </div>
              )}
              <div
                style={{ display: "flex", marginLeft: "3px", marginTop: "5px" }}
              >
                {/*{this.renderPrimaryDepartmentDiv()}*/}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "35px",
                  marginTop: "5px",
                }}
              >
                {this.state.windowError ? (
                  <Error style={{ color: "red" }}>
                    <h3>{this.state.windowErrorMessage || ""}</h3>
                  </Error>
                ) : null}
                <Input
                  name="departments"
                  value={inputValue}
                  style={{ marginBottom: "20px" }}
                  label="Search departments"
                  onChange={(e) => this.handleSearch(e)}
                  minLength={2}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "25px",
                }}
              >
                {this.renderDepartmentList(
                  this.state.finalUserDepartments,
                  this.state.visibleDepartments
                )}
              </div>
            </div>
          </div>

          <Snackbar
            place="tc"
            style={{
              width: "90%",
              overflow: "hidden",
            }}
            color={"warning"}
            message={this.state.errorModalContent}
            open={this.state.openErrorModal}
            closeNotification={() =>
              this.handleErrorClose(this.state.errorModalContent)
            }
            close
          />

          <Snackbar
            place="tc"
            style={{
              width: "90%",
              overflow: "hidden",
            }}
            color={"success"}
            message={this.state.successMessage}
            open={this.state.openSuccessMessage}
            close
            closeNotification={() =>
              this.handleSuccessSnackbar(this.state.successMessage)
            }
          />
        </BlockUi>
      </Fragment>
    );
  }
}

export default EditUserDepartments;
