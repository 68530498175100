/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
import FormHelperText from "@material-ui/core/FormHelperText";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
// @material-ui/icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
// core components

import customInputStyle from "../../assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";
import extendedFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";

function CustomInput({...props}) {

  const {
    classes,
    onChange,
    value = '',
    name,
    id,
    labelProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    label,
    fullWidth = false,
    margin,
    disabled,
    endAdornment,
    type,
    style,
    onClick,
    emailError,
    passwordError,
    usernameError
  } = props;

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });

  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white
  });

  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined
  });

  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white
  });

  var formControlClasses;


    formControlClasses = classes.formControl;


  var helpTextClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error
  });

  const inputProps = {
    onChange,
    value,
    name,
    margin,
    disabled,
    endAdornment,
    type,
    onClick
  }

  const formControlProps = {
    fullWidth,
    style
  }

  return (
      <FormControl
          {...formControlProps}
          className={formControlClasses}
          error={error}
      >
        {
          label !== undefined
              ? (<InputLabel
                  className={classes.labelRoot + " " + labelClasses}
                  style={{fontSize: 'large', margin: "0px"}}
                  htmlFor={id}
                  {...labelProps}
              >
                {label}
              </InputLabel>)
              : null
        }
        <Input
            classes={{
              input: inputClasses,
              root: marginTop,
              disabled: classes.disabled,
              underline: underlineClasses
            }}
            id={id}
            {...inputProps}
        />
        {
          error !== undefined
              ? (<FormHelperText id={id + "-text"} className={helpTextClasses}>
                {error && (emailError || passwordError || usernameError)}
              </FormHelperText>)
              : null
        }
      </FormControl>
  );
}

CustomInput.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  helpText: PropTypes.node
};

export default withStyles(customInputStyle)(CustomInput);
