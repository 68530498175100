export const columnsSchema = () => [
  {
    field: 'id',
    title: 'User ID',
    visible: true,
    minWidth: 220,
    sortable: true,
    filterable: true,
    locked: 'locked',
    noDisabledColumn: true,
    notShownInTableColumnSelection: false,
    // cell: true
  },
  // {
  //   field: 'connections',
  //   title: 'Connections',
  //   visible: true,
  //   minWidth: 180,
  //   sortable: true,
  //   filterable: true,
  //   noDisabledColumn: true,
  //   locked: 'locked',
  // },
  {
    field: 'connectionid',
    title: 'Connection ID',
    visible: true,
    minWidth: 180,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    locked: 'locked',
  },
  {
    field: 'version',
    title: 'Version',
    visible: true,
    minWidth: 350,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    locked: 'locked',
  },{
    field: 'username',
    title: 'Username',
    visible: true,
    minWidth: 350,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    locked: 'locked',
  },
  {
    field: 'userRealName',
    title: 'Name',
    visible: true,
    minWidth: 350,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    locked: 'locked',
  },
  {
    field: 'roles',
    title: 'Roles',
    visible: true,
    minWidth: 450,
    sortable: true,
    filterable: true,
    noDisabledColumn: true,
    locked: 'locked',
  },
  {
    field: 'started',
    title: 'Started',
    visible: true,
    minWidth: 360,
    sortable: true,
    filterable: false,
    locked: 'locked',
    editable: 'false'
  },

];
