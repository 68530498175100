import React from 'react'
import { Grid, GridColumn as Column, GridDetailRow, GridToolbar } from '@progress/kendo-react-grid';
import { airportToSeaportSchema } from "../schema";
import { MyCommandCell } from '../../../Clients/View/components/CellHOC';
import _ from "lodash";
import { DropDownCell } from "../../../CRM/View/dropDownCell";
import GridContainer from "../../../../components/Grid/GridContainer";
import { serverApi } from "../../../../networking/config";
import { AbstractDelete } from "../../../../networking/apiCalls";
import { Window } from "@progress/kendo-react-dialogs";
import { filterBy } from '@progress/kendo-data-query';
import { AutoComplete } from '@progress/kendo-react-dropdowns';
import { Error } from '@progress/kendo-react-labels';
import { IconButton, Tooltip } from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";

export default class AirportToWarehouse extends GridDetailRow {
  editField = "inEdit";
  CommandCell;
  _export;

  constructor(props) {
    super(props);

    this.state = {
      visibleWindow: false,
      isDropOpened: false,
      columns: (airportToSeaportSchema && airportToSeaportSchema()) || [],
      gridData: {
        data: [],
        total: 10
      },
      initialGridData: {
        data: [], total: 0
      },
      dataState: {
        take: 10, skip: 0
      },
      openErrorModal: false,
      errorModalContent: '',
      delay: 400,
      loading: false,
      seaport_names: [],
      windowError: false,
      windowErrorMessage: "",
      disableEditButton: true,
      valueVesselName: ''
    }

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      disableEditButton: false,
      showSecondAction: true,
      showFirstAction: false
    });

  }

  async componentDidMount() {

    let options = {
      token: window.localStorage.getItem('access_token')
    };

    let filter1 = {
      filter: {
        where: {
          airportID: this.props.dataItem && this.props.dataItem.id
        },
        include: [
          'warehouse'
        ]
      }
    }
    
    const responseData = await serverApi('GET', `warehouseToAirports`, filter1, '', options);

    if (responseData && responseData.data.length === 1) {
      let finalData = []
      _.forEach(responseData.data, temp => {
        finalData.push({
          deleteID: temp.id,
          id: temp.warehouse.id,
          relation_name: temp.warehouse.relation_name || 'N/A',
          relation_location_iso_code: temp.warehouse.relation_location_iso_code || 'N/A',
        })
      })

      this.setState({
        ...this.state,
        airportID: this.props.dataItem.id,
        gridData: {
          data: finalData,
          total: finalData.length
        },
        initialGridData: {
          data: finalData,
          total: finalData.length
        },
        seaport_names: [],
        valueVesselName: ""

      })
    } else if (responseData && responseData.data.length > 1) {

      let finalData = []
      _.forEach(responseData.data, temp => {
        finalData.push({
          deleteID: temp.id,
          id: temp.warehouse.id,
          relation_name: temp.warehouse.relation_name || 'N/A',
          relation_location_iso_code: temp.warehouse.relation_location_iso_code || 'N/A',
        })
      })

      this.setState({
        ...this.state,
        airportID: this.props.dataItem.id,
        gridData: {
          data: finalData,
          total: finalData.length
        },
        initialGridData: {
          data: finalData,
          total: finalData.length
        },
        seaport_names: [] 

      })

    } else {
      this.setState({
        ...this.state,
        airportID: this.props.dataItem.id,
        gridData: {
          data: [],
          total: 0
        },
        initialGridData: {
          data: [],
          total: 0
        },
        seaport_names: []
      })
    }

  }

  exportToCsv = () => {
    //todo make export without count just like v2.
    this._export.save(this.state.gridData.data, this.state.columns);
  };

  renderGridColumns = (dataItem, changeTheCellComponent) => {

    let selectionCol = []
    let normalCols = []
    let finalCols = []

    _.forEach(this.state.columns, col => {
      if (col.field === "selected") {

        selectionCol.push([
          <Column
            field="selected"
            width="65px"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(dataItem => dataItem.selected === false) === -1
            }
          />
        ])
      }
    })

    normalCols = this.state.columns.filter(temp1 => temp1.visible).map(temp => {

      if (temp.title === "Actions") {
        return null
      }

      if (temp.isFilterBoolean === "yes") {
        return (
          <Column
            field={temp.field}
            filterable={temp.filterable}
            title={temp.title}
            width={temp.minWidth || 'auto'}
            filter={temp.filter}
            visible={temp.visible}
            minGridWidth={"400"}
            cell={DropDownCell}

          />
        )
      } else {
        return (
          <Column
            field={temp.field}
            filterable={temp.filterable}
            title={temp.title}
            width={temp.minWidth || 'auto'}
            filter={temp.filter}
            visible={temp.visible}
            minGridWidth={"400"}
            editable={
              temp.field === 'id' || temp.editable
                ? false
                : true
            } //id not editable on edit
          />
        );
      }

    })

    finalCols = [...selectionCol, ...normalCols]
    return finalCols

  };

  onChange = (event) => {
    //prevent enter keyboard 
    if (event.nativeEvent.type === 'keydown' && event.nativeEvent.keyCode === 13) return ;
    
    const { value } = event.target
    if (value === "") {
      this.setState({
        openedVesselNames: false,
        valueVesselName: value,
      })
    } else {
      this.setState({
        openedVesselNames: false,
        valueVesselName: value,
      }, () => {
        this.onAutoCompleteChange(event, value)
      })
    }

  }

  onAutoCompleteChange = (event, value) => {

    const { name } = event.target;

    let stateData = {};

    stateData = value.length < 3 ?
      {
        data: this.state.seaport_names.length > 0 ? this.state.seaport_names.map(temp => temp.relation_name) : [],
        openedVesselNames: false,
      } :
      { data: this.filterData(value, name), openedVesselNames: true };

    let eventType = event.nativeEvent.type;

    let valueSelected = eventType === 'click' ||
      (eventType === 'keydown' && event.nativeEvent.keyCode === 13);

    if (valueSelected) {
      let final = []
      const selected = this.state.valueVesselName.split(',')[0] + ',' + this.state.valueVesselName.split(',')[1] + ',' + this.state.valueVesselName.split(',')[2]
      this.state.seaports.forEach(x => {
        let temp = x.relation_name + ',' + x.relation_country
        let temp2 = x.relation_name + ',' + x.relation_country + ',' + x.relation_location_iso_code
        if (temp === selected || temp2 === selected) {
          final.push(x)
        }
      })

      let postSeaportID = final[0]?.id
      
      this.setState({
        ...this.state,
        warehouseID: postSeaportID || '',
        airportID: this.props.dataItem && this.props.dataItem.id,
      })
    }

    if (valueSelected && stateData.data.includes(value)) {
      stateData.openedVesselNames = false;
    }

    this.setState({
      valueVesselName: value,
      ...stateData,
    }, () => {
      if (value?.length >= 3) {
        this.fetchAutocompleteData(value)
      }
    })};

  fetchAutocompleteData = (filterValue) => {
    if (!_.isString(filterValue)) filterValue = filterValue?.target?.value || filterValue
    if (filterValue.length < 3) return;
    let options = {
      token: window.localStorage.getItem('access_token'),
    };

    const relationIsoCode = filterValue?.split(',')[2] || filterValue

    const fetchDropdownData = serverApi('GET', `masterEntities`, {
      filter: {
        where: {
          wh_flag: true,
          or: [
              {
                relation_name: { regexp: `/${filterValue}/i` },
              },
              {
                relation_location_iso_code: relationIsoCode,
              }
          ],
        }
      }
    }, '', options);

    fetchDropdownData
      .then(response => {
        this.setState({
          ...this.state,
          seaports: response.data || [],
          seaport_names: (response.data || []).map(temp => temp.relation_name + ',' + temp.relation_country + ',' + temp.relation_location_iso_code) || [],
        })
      })
      .catch(error => {
        throw error;
      });

  };

  filterData = (value, name) => {

    const data = this.state.seaport_names.map(temp => temp.relation_name);

    const filter = {
      value: value,
      operator: 'contains',
      ignoreCase: true,
    };

    let results = filterBy(data, filter)
    return results;

  };

  enterEdit = (dataItem) => {

    let checkForEditDato = this.state.gridData.data
    const found = checkForEditDato.some(item => item.inEdit === true);
    if (found)
      return

    let total = this.state.gridData.total

    this.setState({
      gridData: {
        data: this.state.gridData && this.state.gridData.data.map(item =>
          item.id === dataItem.id ?
            { ...item, inEdit: true } : item
        ),
        total: total
      }
    });
  }

  discard = (dataItem, hasError = false) => {

    let data = [...this.state.gridData.data];
    const total = this.state.gridData.total

    if (hasError) {
      data[0].inEdit = true
      this.setState({
        gridData: {
          data: data,
          total: total
        }
      });
    } else {
      this.removeItem(data, dataItem);

      this.setState({
        gridData: {
          data: data,
          total: total
        },
        visible: false
      });
    }

  }

  cancel = (dataItem) => {

    const originalItem = this.state.initialGridData && this.state.initialGridData.data.find(p => p.id === dataItem.id);
    originalItem.inEdit = false

    const data = this.state.gridData && this.state.gridData.data.map(item => item.id === originalItem.id ? originalItem : item);

    const total = this.state.gridData.total

    this.setState({
      ...this.state,
      gridData: {
        data: data,
        total: total
      },
      openErrorModal: false,
      openErrorModalContent: ''
    });
  }

  removeItem(data, item) {

    let index = data.findIndex(p => p === item || p.id === item.id);
    if (index >= 0) {
      data.splice(index, 1);
    }
  }

  remove = async (dataItem) => {

    const data = [...this.state.gridData.data];

    let index = data.findIndex(p => p === dataItem || p.id === dataItem.id);
    if (index >= 0) {
      data.splice(index, 1);
    }


    let options = {
      token: window.localStorage.getItem('access_token')
    };

    let url = 'warehouseToAirports'

    try {

      let deleteItemRequest = await AbstractDelete(url, `${dataItem.deleteID || ''}`, options)

      if (deleteItemRequest.status === 200) {
        if (data.length <= 0) {
          this.setState({
            ...this.state,
            gridData: {
              data: data, total: data.length
            },
            initialGridData: {
              data: data, total: data.length
            },
            dataState: {
              skip: 0,
              take: 10
            }
          });
        } else {
          this.setState({
            ...this.state,
            gridData: {
              data: data, total: data.length
            },
            initialGridData: {
              data: data, total: data.length
            },
          });
        }


      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent: e?.response?.data?.error?.message || "Please try again"
      })
    }


  }

  add = (dataItem) => {
  }

  addNew = () => {

    let checkForEditDato = this.state.gridData.data
    const found = checkForEditDato.some(item => item.inEdit === true);
    if (found)
      return

    const newDataItem = { inEdit: true };

    this.setState({
      gridData: {
        data: [newDataItem, ...this.state.gridData.data],
        total: this.state.gridData.total
      },
      visibleWindow: true
    });

  }

  update = async (dataItem) => {

  }

  updateItem = (data, item) => {
    let index = data.findIndex(p => p === item || (item.id && p.id === item.id));
    if (index >= 0) {
      data[index] = { ...item };
    }
  }

  refreshGridDataAfterCrud = async () => {

    let token = window.localStorage.getItem('access_token')
    let options = {
      token: token
    };

    let filter1 = {
      filter: {
        where: {
          airportID: this.props.dataItem && this.props.dataItem.id
        },
        include: [
          'warehouse'
        ]
      }
    }
    
    const responseData = await serverApi('GET', `warehouseToAirports`, filter1, '', options);

    if (responseData && responseData.data.length === 1) {
      let finalData = []
      _.forEach(responseData.data, temp => {
        finalData.push({
          deleteID: temp.id,
          id: temp.warehouse.id,
          relation_name: temp.warehouse.relation_name || 'N/A',
          relation_location_iso_code: temp.warehouse.relation_location_iso_code || 'N/A',
        })
      })

      this.setState({
        ...this.state,
        airportID: this.props.dataItem.id,
        gridData: {
          data: finalData,
          total: finalData.length
        },
        initialGridData: {
          data: finalData,
          total: finalData.length
        },
        seaport_names: [],
        valueVesselName: ""

      })
    } else if (responseData && responseData.data.length > 1) {

      let finalData = []
      _.forEach(responseData.data, temp => {
        finalData.push({
          deleteID: temp.id,
          id: temp.warehouse.id,
          relation_name: temp.warehouse.relation_name || 'N/A',
          relation_location_iso_code: temp.warehouse.relation_location_iso_code || 'N/A',
        })
      })

      this.setState({
        ...this.state,
        airportID: this.props.dataItem.id,
        gridData: {
          data: finalData,
          total: finalData.length
        },
        initialGridData: {
          data: finalData,
          total: finalData.length
        },
        seaport_names: [] 

      })

    } else {
      this.setState({
        ...this.state,
        airportID: this.props.dataItem.id,
        gridData: {
          data: [],
          total: 0
        },
        initialGridData: {
          data: [],
          total: 0
        },
        seaport_names: []
      })
    }
  }

  toggleWindow = (e) => {

    const data = this.state.initialGridData.data

    this.setState({
      visibleWindow: !this.state.visibleWindow,
      gridData: {
        data: [...data],
        total: 10
      },
      seaport_names: [],
      valueVesselName: '',
      windowError: false,
      windowErrorMessage: ''
    });
  }

  listNoDataRender = (element) => {

    const noData = (
      <h4 style={{ fontSize: '1em' }}>
        <span className="k-icon k-i-warning" style={{ fontSize: '2.5em' }} />
        <br /><br />
        No seaports found
      </h4>
    );

    return React.cloneElement(element, { ...element.props }, noData);
  }

  closeErrorModal = () => {

    this.setState({
      ...this.state,
      openErrorModal: false,
      errorModalContent: ''

    })
  }

  handleErrorClose = (loginError) => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      openErrorModalContent: ''
    })
  };

  openErrorModal = (e) => {

    this.setState({
      ...this.state,
      openErrorModal: true,
      errorModalContent: e.response.data.error.message || "Please try again"
    })
  }

  saveHandler = async (e) => {

    try {
      let options = {
        token: window.localStorage.getItem('access_token')
      };

      let finalSeaportID = null;
      if (!this.state.warehouseID) {
        if (this.state.seaport_names.length === 1) {
          finalSeaportID = this.state.seaport_names[0].id
        }

      } else {
        finalSeaportID = this.state.warehouseID
      }
      let payload = {
        "airportID": this.state.airportID,
        "warehouseID": finalSeaportID,
      }


      if (!payload.warehouseID) {
        this.setState({
          ...this.state,
          visibleWindow: false,
          windowError: true,
          windowErrorMessage: "Please select a warehouse from the dropdown list."
        })
        return;
      }

      let addVesselToClientRequest = await serverApi('POST', 'warehouseToAirports', {},
        payload, options)

      if (addVesselToClientRequest.status === 200) {
        this.setState({
          ...this.state,
          visibleWindow: false
        }, async () => {
          this.refreshGridDataAfterCrud()
        })
      }
    } catch (e) {

      let message = e?.response?.data?.error?.message || 'Please try again.'
      if (e?.response?.data?.error?.message.includes('unique constraint')) {
        message = 'Duplicate id detected because the warehouse you are trying to attach already exists.Please try with a new warehouse.'
      }

      this.setState({
        ...this.state,
        visibleWindow: true,
        windowError: true,
        windowErrorMessage: message
      })
    }

  }

  render() {
    const dataItem = this.props.dataItem;
    const { gridData } = this.state;
    const {hasBackofficeRead} = this.props;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <div style={{
          width: "39%",
          marginBottom: '20px'
        }}>
          <h4>
            NAME : {dataItem && dataItem.relation_name}
          </h4>
          {
            this.state.openErrorModal
              ? <Error style={{ color: 'red' }}>
                <h3>{this.state.errorModalContent || ""}</h3>
              </Error>
              : null
          }

          <Grid
            {...gridData}
            filterable={false}
            style={{ className: "grid-no-select" }}
            sortable={true}
            resizable
            editField={this.editField}
            onItemChange={this.itemChange}
            pageable={false}
          >
            <GridToolbar>
              <GridContainer xs={12} justify={"flex-start"} direction={"row"}>
              {
              !hasBackofficeRead && (
                <Tooltip justify={"flex-start"} placement="top" title={"Add Warehouse"}>
                  <IconButton>
                    <FontAwesomeIcon
                        color="#0D5869"
                        onClick={this.toggleWindow}
                        size="1.6x" icon={faPencilAlt}
                        />
                    </IconButton>
                   </Tooltip>
                )
              }
              </GridContainer>
            </GridToolbar>

            <Column cell={this.CommandCell} title="Actions" filterable={false} width="240px" />
            {this.renderGridColumns(dataItem)}

          </Grid>
        </div>
        {
          this.state.visibleWindow &&
          <Window
            title={`Attach warehouse to the airport`}
            onClose={this.toggleWindow}
            width={1200}
            height={650}
            initialTop={10}
          >
            <div>
              <br />
              <fieldset style={{ borderColor: 'rgba(13, 88, 105, 1)' }}>
                <br />
                <legend>{`Airport Name: ${dataItem.relation_name}`}</legend>
                <div>
                {
                this.state.windowError
                  ? <Error style={{ color: 'red' }}>
                    <h3>{this.state.windowErrorMessage || ""}</h3>
                  </Error>
                  : null
              }
                  <AutoComplete
                    style={{ width: '450px' }}
                    data={this.state.seaport_names}
                    value={this.state.valueVesselName}
                    label={'Search Warehouses... (Minimum 3 characters length)'}
                    // name={'relation_name'}
                    onChange={e => this.onChange(e)}
                    listNoDataRender={this.listNoDataRender}
                  />
                </div>
                <br />
                <br />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <button
                    style={{ justifyContent: 'flex-end' }}
                    type="button"
                    className="k-button k-primary"
                    onClick={e => this.saveHandler(e)}
                    disabled={this.state.valueVesselName !== '' ? false : true}
                  >
                    Save
                  </button>
                </div>
              </fieldset>
              
            </div>

          </Window>
        }
      </div>
    );
  }


}