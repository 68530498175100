/**
 * Created by kfousteris on 12/6/2019
 */
import map from "ramda/src/map";
import _ from "lodash";

// as long as it continues to be invoked, it will not be triggered
export function debounce(func, wait, timeoutReference, immediate = false) {
  //typical debounce function, with the extra that has multiple instance that specified from timeoutReference.
  //Need for each cluster a new function
  return function () {
    const context = this,
      args = arguments;
    const later = function () {
      window[timeoutReference] = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !window[timeoutReference];
    clearTimeout(window[timeoutReference]);
    window[timeoutReference] = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export const makeSchemaReadOnly = (schema) => {
  return map((s) => {
    s.readOnly = true;
    return s;
  }, schema);
};

export const constructCustomTableState = (
  requestURL = "",
  responseFromServer
) => {
  const url = requestURL;
  const response = _.cloneDeep(responseFromServer);

  switch (url) {
    case "whPickingDispatchToBoxes": {
      let finalData = [];
      _.forEach(response, (temp) => {
        finalData.push({
          dispatchID: temp.dispatchID,
          username: temp.picker && temp.picker.username,
          pick_date: temp.actionsDate,
        });
      });
      return finalData;
    }

    case "orderToDispatches": {
      let finalData = [];
      _.forEach(response, (temp) => {
        finalData.push({
          dispatchID: temp.dispatchID,
          origin: (temp && temp.dispatch && temp.dispatch.origin_name) || "",
          destination:
            (temp && temp.dispatch && temp.dispatch.destination) || "",
        });
      });

      return finalData;
    }

    case "inventory-checks": {
      let finalData = [];

      let boxID = response && response.length > 0 && response[0].boxID;

      _.forEach(response, (temp) => {
        finalData.push({
          id: temp.id,
          boxID: boxID,
          scan_location: temp.scan_location,
          scan_date: new Date(temp.scan_date),
          current: temp.current_location,
          status: temp.navigator_status,
          userID: temp.userID,
        });
      });

      return finalData;
    }

    case "log-box": {
      let finalData = [];

      _.forEach(response, (log) => {
        let afterOBJ = JSON.parse(log && log.obj_after);
        let id = log.id;
        let userRealName = log.creator && log.creator.username;
        let obj_before = log.obj_before;
        let timestamp = new Date(log.timestamp);
        let warehouse_position = afterOBJ.warehouse_position;
        let customsDocNo = afterOBJ.customsDocNo;
        let T1 = afterOBJ.T1;
        let customsStatus = afterOBJ.customsStatus;
        let ams_ti_num = afterOBJ.ams_ti_num;
        let dangerousGood = afterOBJ.dangerousGood;
        let LENGTH = `${afterOBJ.length ? afterOBJ.length : ""}`;
        let WIDTH = `${afterOBJ.width ? afterOBJ.width : ""}`;
        let H = afterOBJ.height;
        let GROSS_WEIGHT = `${
          afterOBJ.gross_weight ? afterOBJ.gross_weight : ""
        }`;

        let itemvalue = afterOBJ.itemvalue;
        let net_weight = afterOBJ.net_weight;
        let packaging = afterOBJ.packaging;
        let uncode = afterOBJ.uncode;

        if (afterOBJ.ordersImportedID) {
          let test = afterOBJ.ordersImportedID;
          window.localStorage.setItem("orderIDNextCall", test);
        }

        finalData.push({
          id: id,
          userRealName: userRealName,
          obj_before: obj_before,
          timestamp: new Date(timestamp),
          warehouse_position: warehouse_position,
          customsDocNo: customsDocNo,
          T1: T1,
          customsStatus: customsStatus,
          ams_ti_num: ams_ti_num,
          dangerousGood: dangerousGood,
          length: LENGTH,
          width: WIDTH,
          GROSS_WEIGHT: GROSS_WEIGHT,
          H: H,
          itemvalue: itemvalue,
          net_weight: net_weight,
          packaging: packaging,
          uncode: uncode,
        });
      });

      return finalData;
    }

    case "log-box-order-table": {
      let finalData = [];

      _.forEach(response.logs, (ord) => {
        let username = ord.creator && ord.creator.username;
        let parsedOBJ = JSON.parse(ord && ord.obj_after);

        finalData.push({
          id: ord.model_id,
          userID: username || "",
          obj_before: ord.obj_before,
          vessel_name: parsedOBJ.vessel_name,
          current: parsedOBJ.current_location,
          status: parsedOBJ.status_code,
          timestampt: new Date(ord.timestamp),
        });
      });

      return finalData;
    }

    case "warehouse-picklist": {
      let finalData = [];

      _.forEach(response, (disp) => {
        let dispatch_id = disp.id;
        let pickerName = disp.picker.userRealName;

        _.forEach(disp.orders, (order) => {
          let vessel_name = order.vessel_name;
          let supplier_name = order.supplier_name;

          _.forEach(order.boxes, (box) => {
            let includeWarehousePositionList = [
              null,
              "22-1-1A",
              "22-1-1B",
              "22-1-1C",
              "22-1-1D",
              "22-1-2A",
              "22-1-2B",
              "22-1-2C",
              "22-1-2D",
              "22-1-3A",
              "22-1-3B",
              "22-1-3C",
              "22-1-3D",
              "22-1-4A",
              "22-1-4B",
              "22-1-4C",
              "22-1-4D",
              "22-1-5A",
              "22-1-5B",
              "22-1-5C",
              "22-1-5D",
              "22-2-1A",
              "22-2-1B",
              "22-2-1C",
              "22-2-1D",
              "22-2-2A",
              "22-2-2B",
              "22-2-2C",
              "22-2-2D",
              "22-2-3A",
              "22-2-3B",
              "22-2-3C",
              "22-2-3D",
              "22-2-4A",
              "22-2-4B",
              "22-2-4C",
              "22-2-4D",
              "22-2-5A",
              "22-2-5B",
              "22-2-5C",
              "22-2-5D",
              "22-3-1A",
              "22-3-1B",
              "22-3-1C",
              "22-3-1D",
              "22-3-2A",
              "22-3-2B",
              "22-3-2C",
              "22-3-2D",
              "22-3-3A",
              "22-3-3B",
              "22-3-3C",
              "22-3-3D",
              "22-3-4A",
              "22-3-4B",
              "22-3-4C",
              "22-3-4D",
              "22-3-5A",
              "22-3-5B",
              "22-3-5C",
              "22-3-5D",
              "22-4-1A",
              "22-4-1B",
              "22-4-1C",
              "22-4-1D",
              "22-4-2A",
              "22-4-2B",
              "22-4-2C",
              "22-4-2D",
              "22-4-3A",
              "22-4-3B",
              "22-4-3C",
              "22-4-3D",
              "22-4-4A",
              "22-4-4B",
              "22-4-4C",
              "22-4-4D",
              "22-4-5A",
              "22-4-5B",
              "22-4-5C",
              "22-4-5D",
              "22-5-1A",
              "22-5-1B",
              "22-5-1C",
              "22-5-2A",
              "22-5-2B",
              "22-5-2C",
              "22-5-3A",
              "22-5-3B",
              "22-5-3C",
              "22-5-4A",
              "22-5-4B",
              "22-5-4C",
              "22-5-5A",
              "22-5-5B",
              "22-5-5C",

              "23-1-1A",
              "23-1-1B",
              "23-1-1C",
              "23-1-1D",
              "23-1-2A",
              "23-1-2B",
              "23-1-2C",
              "23-1-2D",
              "23-1-3A",
              "23-1-3B",
              "23-1-3C",
              "23-1-3D",
              "23-1-4A",
              "23-1-4B",
              "23-1-4C",
              "23-1-4D",
              "23-1-5A",
              "23-1-5B",
              "23-1-5C",
              "23-1-5D",
              "23-2-1A",
              "23-2-1B",
              "23-2-1C",
              "23-2-1D",
              "23-2-2A",
              "23-2-2B",
              "23-2-2C",
              "23-2-2D",
              "23-2-3A",
              "23-2-3B",
              "23-2-3C",
              "23-2-3D",
              "23-2-4A",
              "23-2-4B",
              "23-2-4C",
              "23-2-4D",
              "23-2-5A",
              "23-2-5B",
              "23-2-5C",
              "23-2-5D",
              "23-3-1A",
              "23-3-1B",
              "23-3-1C",
              "23-3-1D",
              "23-3-2A",
              "23-3-2B",
              "23-3-2C",
              "23-3-2D",
              "23-3-3A",
              "23-3-3B",
              "23-3-3C",
              "23-3-3D",
              "23-3-4A",
              "23-3-4B",
              "23-3-4C",
              "23-3-4D",
              "23-3-5A",
              "23-3-5B",
              "23-3-5C",
              "23-3-5D",
              "23-4-1A",
              "23-4-1B",
              "23-4-1C",
              "23-4-1D",
              "23-4-2A",
              "23-4-2B",
              "23-4-2C",
              "23-4-2D",
              "23-4-3A",
              "23-4-3B",
              "23-4-3C",
              "23-4-3D",
              "23-4-4A",
              "23-4-4B",
              "23-4-4C",
              "23-4-4D",
              "23-4-5A",
              "23-4-5B",
              "23-4-5C",
              "23-4-5D",
              "23-5-1A",
              "23-5-1B",
              "23-5-1C",
              "23-5-2A",
              "23-5-2B",
              "23-5-2C",
              "23-5-3A",
              "23-5-3B",
              "23-5-3C",
              "23-5-4A",
              "23-5-4B",
              "23-5-4C",
              "23-5-5A",
              "23-5-5B",
              "23-5-5C",

              "24-1-1A",
              "24-1-1B",
              "24-1-1C",
              "24-1-1D",
              "24-1-2A",
              "24-1-2B",
              "24-1-2C",
              "24-1-2D",
              "24-1-3A",
              "24-1-3B",
              "24-1-3C",
              "24-1-3D",
              "24-1-4A",
              "24-1-4B",
              "24-1-4C",
              "24-1-4D",
              "24-1-5A",
              "24-1-5B",
              "24-1-5C",
              "24-1-5D",
              "24-2-1A",
              "24-2-1B",
              "24-2-1C",
              "24-2-1D",
              "24-2-2A",
              "24-2-2B",
              "24-2-2C",
              "24-2-2D",
              "24-2-3A",
              "24-2-3B",
              "24-2-3C",
              "24-2-3D",
              "24-2-4A",
              "24-2-4B",
              "24-2-4C",
              "24-2-4D",
              "24-2-5A",
              "24-2-5B",
              "24-2-5C",
              "24-2-5D",
              "24-3-1A",
              "24-3-1B",
              "24-3-1C",
              "24-3-1D",
              "24-3-2A",
              "24-3-2B",
              "24-3-2C",
              "24-3-2D",
              "24-3-3A",
              "24-3-3B",
              "24-3-3C",
              "24-3-3D",
              "24-3-4A",
              "24-3-4B",
              "24-3-4C",
              "24-3-4D",
              "24-3-5A",
              "24-3-5B",
              "24-3-5C",
              "24-3-5D",
              "24-4-1A",
              "24-4-1B",
              "24-4-1C",
              "24-4-1D",
              "24-4-2A",
              "24-4-2B",
              "24-4-2C",
              "24-4-2D",
              "24-4-3A",
              "24-4-3B",
              "24-4-3C",
              "24-4-3D",
              "24-4-4A",
              "24-4-4B",
              "24-4-4C",
              "24-4-4D",
              "24-4-5A",
              "24-4-5B",
              "24-4-5C",
              "24-4-5D",
              "24-5-1A",
              "24-5-1B",
              "24-5-1C",
              "24-5-2A",
              "24-5-2B",
              "24-5-2C",
              "24-5-3A",
              "24-5-3B",
              "24-5-3C",
              "24-5-4A",
              "24-5-4B",
              "24-5-4C",
              "24-5-5A",
              "24-5-5B",
              "24-5-5C",

              "25-1-1A",
              "25-1-1B",
              "25-1-1C",
              "25-1-1D",
              "25-1-2A",
              "25-1-2B",
              "25-1-2C",
              "25-1-2D",
              "25-1-3A",
              "25-1-3B",
              "25-1-3C",
              "25-1-3D",
              "25-1-4A",
              "25-1-4B",
              "25-1-4C",
              "25-1-4D",
              "25-1-5A",
              "25-1-5B",
              "25-1-5C",
              "25-1-5D",
              "25-2-1A",
              "25-2-1B",
              "25-2-1C",
              "25-2-1D",
              "25-2-2A",
              "25-2-2B",
              "25-2-2C",
              "25-2-2D",
              "25-2-3A",
              "25-2-3B",
              "25-2-3C",
              "25-2-3D",
              "25-2-4A",
              "25-2-4B",
              "25-2-4C",
              "25-2-4D",
              "25-2-5A",
              "25-2-5B",
              "25-2-5C",
              "25-2-5D",
              "25-3-1A",
              "25-3-1B",
              "25-3-1C",
              "25-3-1D",
              "25-3-2A",
              "25-3-2B",
              "25-3-2C",
              "25-3-2D",
              "25-3-3A",
              "25-3-3B",
              "25-3-3C",
              "25-3-3D",
              "25-3-4A",
              "25-3-4B",
              "25-3-4C",
              "25-3-4D",
              "25-3-5A",
              "25-3-5B",
              "25-3-5C",
              "25-3-5D",
              "25-4-1A",
              "25-4-1B",
              "25-4-1C",
              "25-4-1D",
              "25-4-2A",
              "25-4-2B",
              "25-4-2C",
              "25-4-2D",
              "25-4-3A",
              "25-4-3B",
              "25-4-3C",
              "25-4-3D",
              "25-4-4A",
              "25-4-4B",
              "25-4-4C",
              "25-4-4D",
              "25-4-5A",
              "25-4-5B",
              "25-4-5C",
              "25-4-5D",
              "25-5-1A",
              "25-5-1B",
              "25-5-1C",
              "25-5-2A",
              "25-5-2B",
              "25-5-2C",
              "25-5-3A",
              "25-5-3B",
              "25-5-3C",
              "25-5-4A",
              "25-5-4B",
              "25-5-4C",
              "25-5-5A",
              "25-5-5B",
              "25-5-5C",

              "26-1-1A",
              "26-1-1B",
              "26-1-1C",
              "26-1-1D",
              "26-1-2A",
              "26-1-2B",
              "26-1-2C",
              "26-1-2D",
              "26-1-3A",
              "26-1-3B",
              "26-1-3C",
              "26-1-3D",
              "26-1-4A",
              "26-1-4B",
              "26-1-4C",
              "26-1-4D",
              "26-1-4A",
              "26-1-4B",
              "26-1-4C",
              "26-1-4D",
              "26-2-1A",
              "26-2-1B",
              "26-2-1C",
              "26-2-1D",
              "26-2-2A",
              "26-2-2B",
              "26-2-2C",
              "26-2-2D",
              "26-2-3A",
              "26-2-3B",
              "26-2-3C",
              "26-2-3D",
              "26-2-4A",
              "26-2-4B",
              "26-2-4C",
              "26-2-4D",
              "26-2-4A",
              "26-2-4B",
              "26-2-4C",
              "26-2-4D",
              "26-3-1A",
              "26-3-1B",
              "26-3-1C",
              "26-3-1D",
              "26-3-2A",
              "26-3-2B",
              "26-3-2C",
              "26-3-2D",
              "26-3-3A",
              "26-3-3B",
              "26-3-3C",
              "26-3-3D",
              "26-3-4A",
              "26-3-4B",
              "26-3-4C",
              "26-3-4D",
              "26-3-4A",
              "26-3-4B",
              "26-3-4C",
              "26-3-4D",
              "26-4-1A",
              "26-4-1B",
              "26-4-1C",
              "26-4-1D",
              "26-4-2A",
              "26-4-2B",
              "26-4-2C",
              "26-4-2D",
              "26-4-3A",
              "26-4-3B",
              "26-4-3C",
              "26-4-3D",
              "26-4-4A",
              "26-4-4B",
              "26-4-4C",
              "26-4-4D",
              "26-4-4A",
              "26-4-4B",
              "26-4-4C",
              "26-4-4D,",
              "26-5-1A",
              "26-5-1B",
              "26-5-1C",
              "26-5-2A",
              "26-5-2B",
              "26-5-2C",
              "26-5-3A",
              "26-5-3B",
              "26-5-3C",
              "26-5-4A",
              "26-5-4B",
              "26-5-4C",
              "26-5-4A",
              "26-5-4B",
              "26-5-4C",

              "27-1-1A",
              "27-1-1B",
              "27-1-1C",
              "27-1-1D",
              "27-1-2A",
              "27-1-2B",
              "27-1-2C",
              "27-1-2D",
              "27-1-3A",
              "27-1-3B",
              "27-1-3C",
              "27-1-3D",
              "27-1-4A",
              "27-1-4B",
              "27-1-4C",
              "27-1-4D",
              "27-1-5A",
              "27-1-5B",
              "27-1-5C",
              "27-1-5D",
              "27-2-1A",
              "27-2-1B",
              "27-2-1C",
              "27-2-1D",
              "27-2-2A",
              "27-2-2B",
              "27-2-2C",
              "27-2-2D",
              "27-2-3A",
              "27-2-3B",
              "27-2-3C",
              "27-2-3D",
              "27-2-4A",
              "27-2-4B",
              "27-2-4C",
              "27-2-4D",
              "27-2-5A",
              "27-2-5B",
              "27-2-5C",
              "27-2-5D",
              "27-3-1A",
              "27-3-1B",
              "27-3-1C",
              "27-3-1D",
              "27-3-2A",
              "27-3-2B",
              "27-3-2C",
              "27-3-2D",
              "27-3-3A",
              "27-3-3B",
              "27-3-3C",
              "27-3-3D",
              "27-3-4A",
              "27-3-4B",
              "27-3-4C",
              "27-3-4D",
              "27-3-5A",
              "27-3-5B",
              "27-3-5C",
              "27-3-5D",
              "27-4-1A",
              "27-4-1B",
              "27-4-1C",
              "27-4-1D",
              "27-4-2A",
              "27-4-2B",
              "27-4-2C",
              "27-4-2D",
              "27-4-3A",
              "27-4-3B",
              "27-4-3C",
              "27-4-3D",
              "27-4-4A",
              "27-4-4B",
              "27-4-4C",
              "27-4-4D",
              "27-4-5A",
              "27-4-5B",
              "27-4-5C",
              "27-4-5D,",
              "27-5-1A",
              "27-5-1B",
              "27-5-1C",
              "27-5-2A",
              "27-5-2B",
              "27-5-2C",
              "27-5-3A",
              "27-5-3B",
              "27-5-3C",
              "27-5-4A",
              "27-5-4B",
              "27-5-4C",
              "27-5-5A",
              "27-5-5B",
              "27-5-5C",

              "28-1-1A",
              "28-1-1B",
              "28-1-1C",
              "28-1-2A",
              "28-1-2B",
              "28-1-2C",
              "28-1-3A",
              "28-1-3B",
              "28-1-3C",
              "28-1-4A",
              "28-1-4B",
              "28-1-4C",
              "28-1-5A",
              "28-1-5B",
              "28-1-5C",
              "28-2-1A",
              "28-2-1B",
              "28-2-1C",
              "28-2-2A",
              "28-2-2B",
              "28-2-2C",
              "28-2-3A",
              "28-2-3B",
              "28-2-3C",
              "28-2-4A",
              "28-2-4B",
              "28-2-4C",
              "28-2-5A",
              "28-2-5B",
              "28-2-5C",
              "28-3-1A",
              "28-3-1B",
              "28-3-1C",
              "28-3-2A",
              "28-3-2B",
              "28-3-2C",
              "28-3-3A",
              "28-3-3B",
              "28-3-3C",
              "28-3-4A",
              "28-3-4B",
              "28-3-4C",
              "28-3-5A",
              "28-3-5B",
              "28-3-5C",
              "28-4-1A",
              "28-4-1B",
              "28-4-1C",
              "28-4-2A",
              "28-4-2B",
              "28-4-2C",
              "28-4-3A",
              "28-4-3B",
              "28-4-3C",
              "28-4-4B",
              "28-4-4C",
              "28-4-5A",
              "28-4-5B",
              "28-4-5C",
            ];

            let box_id = box.id;
            let warehouse_position = box.warehouse_position;
            let length_width_height = `${box.length} x ${box.width} x ${box.height}`;
            let gross_weight = box.gross_weight;

            if (
              includeWarehousePositionList.indexOf(warehouse_position) === -1 ||
              warehouse_position === null
            ) {
              return;
            } else {
              finalData.push({
                dispatch_id: dispatch_id,
                pickerName: pickerName,
                vessel_name: vessel_name,
                supplier_name: supplier_name,
                box_id: box_id,
                warehouse_position: warehouse_position,
                length_width_height: length_width_height,
                gross_weight: gross_weight,
              });
            }
          });
        });
      });

      return finalData;
    }

    case "orderToDispatch": {
      const orderArrFromServer = response.map((temp) => temp.order);

      let finalArray = [];
      _.forEach(response, (temp) => {
        finalArray.push(temp.id);
      });

      let index = 0;
      while (index < finalArray.length) {
        _.forEach(orderArrFromServer, (temp1, index) => {
          //sto hoc handle all afere to deleteID se id sketo
          temp1.deleteId = finalArray[index];
          index++;
        });
      }

      return orderArrFromServer;
    }

    case "orders": {
      let orderItemsFromServer = response
        .filter(
          (temp2) =>
            temp2.ordersImportedV4_items !== null &&
            temp2.ordersImportedV4_items !== undefined
        )
        .map((temp) => temp.ordersImportedV4_items);
      let index = 0;

      while (index < orderItemsFromServer.length) {
        _.forEach(orderItemsFromServer, (temp1, index) => {
          if (temp1.length === 1) {
            temp1.boxID = temp1[0].id;
            temp1.Dimensions = `${temp1[0].length} x ${temp1[0].width} x ${temp1[0].height}`;
            temp1.weight = `${response[index].weight} KG`;
            temp1.description = temp1[0].description;
            temp1.dangerousGood = temp1[0].dangerousGood;
            temp1.customsStatus = temp1[0].customsStatus;
            temp1.customsDocNo = temp1[0].customsDocNo;
            temp1.warehouse_position = temp1[0].warehouse_position;
            temp1.last_picking_status = temp1[0].last_picking_status;
            temp1.photos = (temp1[0].photos && temp1[0].photos.length) || "N/A";
          } else {
            temp1.boxID = temp1[index].id;
            temp1.Dimensions = `${temp1[index].length} x ${temp1[index].width} x ${temp1[index].height}`;
            temp1.weight = `${response[index].weight} KG`;
            temp1.description = temp1[index].description;
            temp1.dangerousGood = temp1[index].dangerousGood;
            temp1.customsStatus = temp1[index].customsStatus;
            temp1.customsDocNo = temp1[index].customsDocNo;
            temp1.warehouse_position = temp1[index].warehouse_position;
            temp1.last_picking_status = temp1[index].last_picking_status;
            temp1.photos =
              (temp1[index].photos && temp1[index].photos[index].length) ||
              "N/A";
          }

          //   temp1.vessel_name = temp1.vessel_name;
          //   temp1.PO_number = temp1.PO_number;
          //   temp1.status_code = temp1.status_code;
          //   temp1.supplier_name = temp1.supplier_name;

          index++;
        });
      }

      return orderItemsFromServer;
    }

    case "documents": {
      return response;
    }

    case "invoices": {
      let finalData = [];
      _.forEach(response, (temp) => {
        let afterOBJ = JSON.parse(temp && temp.obj_after);
        let vesselName = afterOBJ && afterOBJ.vesselName;
        let clientName = afterOBJ && afterOBJ.clientName;

        finalData.push({
          id: temp.id,
          userRealName:
            (temp && temp.creator && temp.creator.username) || "N/A",
          obj_before: temp.obj_before || "N/A",
          timestamp: new Date(temp.timestamp) || "N/A",
          vessel_name: vesselName || "N/A",
          client_name: clientName || "N/A",
        });
      });
      return finalData;
    }

    default:
      break;
  }
};

// import functions
// import pipe from "ramda/src/pipe";
// import map from "ramda/src/map";
// const pickAuthorName = pipe(
//   path(["result" , "authors"] ),
//   map(item => item.authorName)
// );
// // this will return ["Alice" , "John"]
// let x = pickAuthorName(data);

function formatDate(dateString, locale) {
  if (dateString === null || dateString === undefined) {
    return null;
  }

  let val = null;
  const date = new Date(dateString);
  const options = { month: "2-digit", day: "2-digit", year: "numeric" };
  val = date.toLocaleDateString("el-GR" || locale, options);
  return val;
}

export const formatDatesOnTable = (array, dateFields) => {
  const finalArr = {
    data: (array.data || array).map((item) => {
      const formattedItem = { ...item };
      dateFields.forEach((field) => {
        formattedItem[field] = formatDate(item[field]);
      });
      return formattedItem;
    }),
    total: array.total || array?.data?.length || array.length,
  };

  return finalArr;
};

export const showPaymentTermsTypeOnTable = (
  array,
  fieldName = "paymentTermsType"
) => {
  let finalArr = {
    data: [],
    total: array.total,
  };

  finalArr.data = (array.data || []).map((x) => ({
    ...x,
    paymentTermsTypeId: x[fieldName]?.lov_value || null,
    paymentTermsTypeForUiId: x[fieldName]?.id || "N/A",
  }));

  return finalArr;
};

export const showCreatorOnTable = (
  array,
  fieldName1,
  fieldName2,
  fieldName3
) => {
  let finalArr = {
    data: [],
    total: array.total,
  };
  finalArr.data = (array.data || []).map((x) => ({
    ...x,
    // createdby: x?.creator?.username || 'N/A',
    createdByForUi:
      fieldName1 && fieldName2 && fieldName3
        ? x[fieldName1][fieldName2][fieldName3]
        : x?.creator?.username || x?.createdBy?.username || "N/A",
    updatedByForUi: x?.updatedBy?.username || "N/A",
    // date_created: formatDate(x.date_created),
    dateCreatedForUi: formatDate(x.date_created) || formatDate(x.dateCreated),
  }));
  return finalArr;
};

export const getLocaleDateString = () => {
  const formats = {
    "af-ZA": "yyyy/MM/dd",
    "am-ET": "d/M/yyyy",
    "ar-AE": "dd/MM/yyyy",
    "ar-BH": "dd/MM/yyyy",
    "ar-DZ": "dd-MM-yyyy",
    "ar-EG": "dd/MM/yyyy",
    "ar-IQ": "dd/MM/yyyy",
    "ar-JO": "dd/MM/yyyy",
    "ar-KW": "dd/MM/yyyy",
    "ar-LB": "dd/MM/yyyy",
    "ar-LY": "dd/MM/yyyy",
    "ar-MA": "dd-MM-yyyy",
    "ar-OM": "dd/MM/yyyy",
    "ar-QA": "dd/MM/yyyy",
    "ar-SA": "dd/MM/yy",
    "ar-SY": "dd/MM/yyyy",
    "ar-TN": "dd-MM-yyyy",
    "ar-YE": "dd/MM/yyyy",
    "arn-CL": "dd-MM-yyyy",
    "as-IN": "dd-MM-yyyy",
    "az-Cyrl-AZ": "dd.MM.yyyy",
    "az-Latn-AZ": "dd.MM.yyyy",
    "ba-RU": "dd.MM.yy",
    "be-BY": "dd.MM.yyyy",
    "bg-BG": "dd.M.yyyy",
    "bn-BD": "dd-MM-yy",
    "bn-IN": "dd-MM-yy",
    "bo-CN": "yyyy/M/d",
    "br-FR": "dd/MM/yyyy",
    "bs-Cyrl-BA": "d.M.yyyy",
    "bs-Latn-BA": "d.M.yyyy",
    "ca-ES": "dd/MM/yyyy",
    "co-FR": "dd/MM/yyyy",
    "cs-CZ": "d.M.yyyy",
    "cy-GB": "dd/MM/yyyy",
    "da-DK": "dd-MM-yyyy",
    "de-AT": "dd.MM.yyyy",
    "de-CH": "dd.MM.yyyy",
    "de-DE": "dd.MM.yyyy",
    "de-LI": "dd.MM.yyyy",
    "de-LU": "dd.MM.yyyy",
    "dsb-DE": "d. M. yyyy",
    "dv-MV": "dd/MM/yy",
    "el-GR": "d/M/yyyy",
    "en-029": "MM/dd/yyyy",
    "en-AU": "d/MM/yyyy",
    "en-BZ": "dd/MM/yyyy",
    "en-CA": "dd/MM/yyyy",
    "en-GB": "dd/MM/yyyy",
    "en-IE": "dd/MM/yyyy",
    "en-IN": "dd-MM-yyyy",
    "en-JM": "dd/MM/yyyy",
    "en-MY": "d/M/yyyy",
    "en-NZ": "d/MM/yyyy",
    "en-PH": "M/d/yyyy",
    "en-SG": "d/M/yyyy",
    "en-TT": "dd/MM/yyyy",
    "en-US": "M/d/yyyy",
    "en-ZA": "yyyy/MM/dd",
    "en-ZW": "M/d/yyyy",
    "es-AR": "dd/MM/yyyy",
    "es-BO": "dd/MM/yyyy",
    "es-CL": "dd-MM-yyyy",
    "es-CO": "dd/MM/yyyy",
    "es-CR": "dd/MM/yyyy",
    "es-DO": "dd/MM/yyyy",
    "es-EC": "dd/MM/yyyy",
    "es-ES": "dd/MM/yyyy",
    "es-GT": "dd/MM/yyyy",
    "es-HN": "dd/MM/yyyy",
    "es-MX": "dd/MM/yyyy",
    "es-NI": "dd/MM/yyyy",
    "es-PA": "MM/dd/yyyy",
    "es-PE": "dd/MM/yyyy",
    "es-PR": "dd/MM/yyyy",
    "es-PY": "dd/MM/yyyy",
    "es-SV": "dd/MM/yyyy",
    "es-US": "M/d/yyyy",
    "es-UY": "dd/MM/yyyy",
    "es-VE": "dd/MM/yyyy",
    "et-EE": "d.MM.yyyy",
    "eu-ES": "yyyy/MM/dd",
    "fa-IR": "MM/dd/yyyy",
    "fi-FI": "d.M.yyyy",
    "fil-PH": "M/d/yyyy",
    "fo-FO": "dd-MM-yyyy",
    "fr-BE": "d/MM/yyyy",
    "fr-CA": "yyyy-MM-dd",
    "fr-CH": "dd.MM.yyyy",
    "fr-FR": "dd/MM/yyyy",
    "fr-LU": "dd/MM/yyyy",
    "fr-MC": "dd/MM/yyyy",
    "fy-NL": "d-M-yyyy",
    "ga-IE": "dd/MM/yyyy",
    "gd-GB": "dd/MM/yyyy",
    "gl-ES": "dd/MM/yy",
    "gsw-FR": "dd/MM/yyyy",
    "gu-IN": "dd-MM-yy",
    "ha-Latn-NG": "d/M/yyyy",
    "he-IL": "dd/MM/yyyy",
    "hi-IN": "dd-MM-yyyy",
    "hr-BA": "d.M.yyyy.",
    "hr-HR": "d.M.yyyy",
    "hsb-DE": "d. M. yyyy",
    "hu-HU": "yyyy. MM. dd.",
    "hy-AM": "dd.MM.yyyy",
    "id-ID": "dd/MM/yyyy",
    "ig-NG": "d/M/yyyy",
    "ii-CN": "yyyy/M/d",
    "is-IS": "d.M.yyyy",
    "it-CH": "dd.MM.yyyy",
    "it-IT": "dd/MM/yyyy",
    "iu-Cans-CA": "d/M/yyyy",
    "iu-Latn-CA": "d/MM/yyyy",
    "ja-JP": "yyyy/MM/dd",
    "ka-GE": "dd.MM.yyyy",
    "kk-KZ": "dd.MM.yyyy",
    "kl-GL": "dd-MM-yyyy",
    "km-KH": "yyyy-MM-dd",
    "kn-IN": "dd-MM-yy",
    "ko-KR": "yyyy. MM. dd",
    "kok-IN": "dd-MM-yyyy",
    "ky-KG": "dd.MM.yy",
    "lb-LU": "dd/MM/yyyy",
    "lo-LA": "dd/MM/yyyy",
    "lt-LT": "yyyy.MM.dd",
    "lv-LV": "yyyy.MM.dd.",
    "mi-NZ": "dd/MM/yyyy",
    "mk-MK": "dd.MM.yyyy",
    "ml-IN": "dd-MM-yy",
    "mn-MN": "yy.MM.dd",
    "mn-Mong-CN": "yyyy/M/d",
    "moh-CA": "M/d/yyyy",
    "mr-IN": "dd-MM-yyyy",
    "ms-BN": "dd/MM/yyyy",
    "ms-MY": "dd/MM/yyyy",
    "mt-MT": "dd/MM/yyyy",
    "nb-NO": "dd.MM.yyyy",
    "ne-NP": "M/d/yyyy",
    "nl-BE": "d/MM/yyyy",
    "nl-NL": "d-M-yyyy",
    "nn-NO": "dd.MM.yyyy",
    "nso-ZA": "yyyy/MM/dd",
    "oc-FR": "dd/MM/yyyy",
    "or-IN": "dd-MM-yy",
    "pa-IN": "dd-MM-yy",
    "pl-PL": "dd.MM.yyyy",
    "prs-AF": "dd/MM/yy",
    "ps-AF": "dd/MM/yy",
    "pt-BR": "d/M/yyyy",
    "pt-PT": "dd-MM-yyyy",
    "qut-GT": "dd/MM/yyyy",
    "quz-BO": "dd/MM/yyyy",
    "quz-EC": "dd/MM/yyyy",
    "quz-PE": "dd/MM/yyyy",
    "rm-CH": "dd/MM/yyyy",
    "ro-RO": "dd.MM.yyyy",
    "ru-RU": "dd.MM.yyyy",
    "rw-RW": "M/d/yyyy",
    "sa-IN": "dd-MM-yyyy",
    "sah-RU": "MM.dd.yyyy",
    "se-FI": "d.M.yyyy",
    "se-NO": "dd.MM.yyyy",
    "se-SE": "yyyy-MM-dd",
    "si-LK": "yyyy-MM-dd",
    "sk-SK": "d. M. yyyy",
    "sl-SI": "d.M.yyyy",
    "sma-NO": "dd.MM.yyyy",
    "sma-SE": "yyyy-MM-dd",
    "smj-NO": "dd.MM.yyyy",
    "smj-SE": "yyyy-MM-dd",
    "smn-FI": "d.M.yyyy",
    "sms-FI": "d.M.yyyy",
    "sq-AL": "yyyy-MM-dd",
    "sr-Cyrl-BA": "d.M.yyyy",
    "sr-Cyrl-CS": "d.M.yyyy",
    "sr-Cyrl-ME": "d.M.yyyy",
    "sr-Cyrl-RS": "d.M.yyyy",
    "sr-Latn-BA": "d.M.yyyy",
    "sr-Latn-CS": "d.M.yyyy",
    "sr-Latn-ME": "d.M.yyyy",
    "sr-Latn-RS": "d.M.yyyy",
    "sv-FI": "d.M.yyyy",
    "sv-SE": "yyyy-MM-dd",
    "sw-KE": "M/d/yyyy",
    "syr-SY": "dd/MM/yyyy",
    "ta-IN": "dd-MM-yyyy",
    "te-IN": "dd-MM-yy",
    "tg-Cyrl-TJ": "dd.MM.yy",
    "th-TH": "d/M/yyyy",
    "tk-TM": "dd.MM.yy",
    "tn-ZA": "yyyy/MM/dd",
    "tr-TR": "dd.MM.yyyy",
    "tt-RU": "dd.MM.yyyy",
    "tzm-Latn-DZ": "dd-MM-yyyy",
    "ug-CN": "yyyy-M-d",
    "uk-UA": "dd.MM.yyyy",
    "ur-PK": "dd/MM/yyyy",
    "uz-Cyrl-UZ": "dd.MM.yyyy",
    "uz-Latn-UZ": "dd/MM yyyy",
    "vi-VN": "dd/MM/yyyy",
    "wo-SN": "dd/MM/yyyy",
    "xh-ZA": "yyyy/MM/dd",
    "yo-NG": "d/M/yyyy",
    "zh-CN": "yyyy/M/d",
    "zh-HK": "d/M/yyyy",
    "zh-MO": "d/M/yyyy",
    "zh-SG": "d/M/yyyy",
    "zh-TW": "yyyy/M/d",
    "zu-ZA": "yyyy/MM/dd",
  };

  return formats[navigator.language] || "dd/MM/yyyy";
};

export const removeUnwantedKeyValuesFromArr = (obj) => {
  // eslint-disable-next-line no-unused-vars
  for (let fl of [
    "createdByForUi",
    "updatedByForUi",
    "dateCreatedForUi",
    "creator",
    "createdBy",
    "updatedBy",
    "paymentTermsType",
    "paymentTermsTypeForUiId",
  ]) {
    delete obj[fl];
  }
};

export const getCookieValue = (a) => {
  var b = document.cookie.match("(^|;)\\s*" + a + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
};
