import { connect } from "react-redux";
import compose from "ramda/src/compose";
import MasterEntities from "../CRM/View";

const mapStateToProps = (state) => {
  return {
    user: state.MainLayoutReducer.user,
    hasBackofficeRead: state.MainLayoutReducer.user.hasBackofficeRead,
    countryCodes: state.MainLayoutReducer.countryCodes,
    usaStates: state.MainLayoutReducer.usaStates,
    paymentTermsLovTypes: state.MainLayoutReducer.paymentTermsLovTypes,
  };
};

export default compose(connect(mapStateToProps, null))(MasterEntities);
