import React from "react";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { GridLoader } from "./loader";
import { MyCommandCell } from "../../common/commandCellHOC";
import { columnsSchema } from "./schema";
import { AbstractEdit } from "../../../networking/apiCalls";
import { serverApi } from "../../../networking/config";
import _ from "lodash";
import { Popup } from "@progress/kendo-react-popup";
import { Menu, MenuItem as KendoMenuItem } from "@progress/kendo-react-layout";
import GridContainer from "../../../components/Grid/GridContainer";
import {
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser, faPlus } from "@fortawesome/free-solid-svg-icons";
import ViewSettings from "../../common/ViewSettings";
import customSelectStyles from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import SuccessModal from "../../../components/SuccessModal";
import ErrorModal from "../../../components/ErrorModal";
import Snackbar from "../../../components/Snackbar/Snackbar";
import { DropDownCell } from "../../CRM/View/dropDownCell";
import { Window } from "@progress/kendo-react-dialogs";
import Button from "../../../components/CustomButtons/Button";
import { ChangePasswordCall } from "../../../networking/apiCalls/ChangePasswordCall";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import ChangePassword from "../../Operators/View/components/ChangePassword";
import UserToClient from "./components/userToClientGrid";
import ExpandedTabs from "../../common/ExpandedTabs";
import { verifyEmail } from "lib/general/utils.js";
import { formatDatesOnTable } from "../../../lib/GeneralUtils/utils";

class ClientUsers extends React.Component {
  editField = "inEdit";
  CommandCell;

  constructor(props) {
    super(props);

    this.state = {
      visibleWindow: false,
      resetPasswordWindow: false,
      terminateUserWindow: false,
      changePasswordWindow: false,
      setCustomPasswordWindow: false,
      isDropOpened: false,
      columns: columnsSchema && columnsSchema(),
      gridData: {
        data: [],
        total: 0,
      },
      dataState: {
        take: 100,
        skip: 0,
      },
      openErrorModal: false,
      errorModalContent: "",
      visible: false,
      expandedRowIndex: null,
      selectedRowIndex: 0,
      selectedItem: null,
      delay: 400,
      loading: false,
      open: false,
      emailHasError: false,
      username: "",
      userRealName: "",
      email: "",

      //
      selectedClients: [],
      clientsAvailable: [],

      selectedEmails: [],
      emailsAvailable: [],

      disableButton: false,
      typeOfUser: "Client",
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,
      add: this.add,
      update: this.update,
      discard: this.discard,
      cancel: this.cancel,
      editField: this.editField,
      hasDisable: true,
    });
  }

  componentDidMount() {
    let isFirstLoad = true;
    window.localStorage.setItem("isFirstLoad", isFirstLoad);

    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));
    let hasFlagEnabled = window.localStorage.getItem("flagSet");
    if (hasFinalParams) {
      this.setState({
        ...this.state,
        dataState: hasFinalParams,
      });
    }
    if (hasChangedColumns) {
      this.setState({
        ...this.state,
        columns: hasChangedColumns,
      });
    }
    if (hasFlagEnabled) {
      window.localStorage.removeItem("flagSet");
    }

    this.setState({
      ...this.state,
      typeOfUser: "Client",
    });
  }

  componentWillUnmount() {
    let hasChangedColumns = JSON.parse(window.localStorage.getItem("cols"));
    if (hasChangedColumns) {
      window.localStorage.removeItem("cols");
    }
    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));
    if (hasFinalParams) {
      window.localStorage.removeItem("finalParams");
    }

    let hasFlagEnabled = window.localStorage.getItem("flagSet");
    if (hasFlagEnabled) {
      window.localStorage.removeItem("flagSet");
    }

    let hasExportFilter = window.localStorage.getItem("exportFilter");
    if (hasExportFilter) {
      window.localStorage.removeItem("exportFilter");
    }
  }

  renderGridColumns = () => {
    let selectionCol = [];
    let normalCols = [];

    _.forEach(this.state.columns, (col) => {
      if (col.field === "selected") {
        selectionCol.push([
          <Column
            field="selected"
            width="65px"
            filterable={false}
            headerSelectionValue={
              this.state.gridData.data.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />,
        ]);
      }
    });

    normalCols = this.state.columns
      .filter((temp1) => temp1.visible)
      .map((temp) => {
        if (temp.title === "Actions") {
          return null;
        }

        if (temp.isFilterBoolean === "yes") {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              cell={DropDownCell}
            />
          );
        } else {
          return (
            <Column
              field={temp.field}
              filterable={temp.filterable}
              title={temp.title}
              width={temp.minWidth || "auto"}
              filter={temp.filter}
              visible={temp.visible}
              minGridWidth={"400"}
              editable={temp.field === "id" || temp.editable ? false : true}
              // cell={(props) => {
              //  return (
              //    <td>
              //      <a rel="noopener noreferrer" href="https://p.2hog.codes/" target="_blank">Link here</a>
              //    </td>
              //  )
              // }}
            />
          );
        }
      });

    return [...selectionCol, ...normalCols];
  };

  enterEdit = (dataItem) => {
    let total = this.state.gridData.total;

    this.setState({
      gridData: {
        data:
          this.state.gridData &&
          this.state.gridData.data.map((item) =>
            item.id === dataItem.id ? { ...item, inEdit: true } : item
          ),
        total: total,
      },
      initialGridData: {
        data:
          this.state.initialGridData &&
          this.state.initialGridData.data.map((item) =>
            item.id === dataItem.id ? { ...item, inEdit: true } : item
          ),
        total: total,
      },
    });
  };

  remove = async (dataItem) => {};

  add = (dataItem) => {};

  discard = (dataItem, hasError = false) => {
    let data = [...this.state.gridData.data];
    const total = this.state.gridData.total;

    if (hasError) {
      let index = data.findIndex((p) => p.id === dataItem.id);

      data[index].inEdit = false;

      this.setState(
        {
          ...this.state,
          gridData: {
            data: data,
            total: total,
          },
        },
        () => {
          setTimeout(() => {
            this.setState({
              ...this.state,
              openErrorModal: false,
              errorModalContent: "",
            });
          }, 3000);
        }
      );
    } else {
      this.removeItem(data, dataItem);

      this.setState({
        ...this.state,
        gridData: {
          data: data,
          total: total,
        },
        visible: false,
      });
    }
  };

  update = async (dataItem) => {
    const data = [...this.state.gridData.data];
    const updatedItem = { ...dataItem, inEdit: undefined };

    this.updateItem(data, updatedItem);

    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let editPayload = {};
    editPayload.username = dataItem.username;
    editPayload.userRealName = dataItem.userRealName;
    editPayload.email = dataItem.email;
    editPayload.lastUpdated = new Date();

    if (
      editPayload.username === "" ||
      editPayload.username === undefined ||
      editPayload.userRealName === "" ||
      editPayload.userRealName === undefined ||
      editPayload.email === "" ||
      editPayload.email === undefined
    ) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            "Error: Required fields to edit the client user: Username,Name and Email.",
        },
        () => {
          setTimeout(() => {
            this.handleErrorClose();
          }, 3500);
        }
      );
    } else {
      try {
        let count;
        const editRequest = await AbstractEdit(
          dataItem.id,
          editPayload,
          "snUsers",
          options
        );
        const countUsers = await serverApi(
          "GET",
          `snuserClientVs/count`,
          "",
          "",
          options
        );
        if (countUsers) {
          count = countUsers.data.count;
        }

        if (editRequest.status === 200 && countUsers) {
          let params = {
            filter: {
              limit: `100`,
              skip: `0`,
            },
          };
          const url = "snuserClientVs";
          let responseData = await serverApi(
            "GET",
            `${url}`,
            params,
            "",
            options
          );

          this.setState({
            ...this.state,
            dataState: {
              take: 100,
              skip: 0,
            },
            gridData: {
              data: responseData.data,
              total: count,
            },
            initialGridData: {
              data: responseData.data,
              total: count,
            },
          });
        }
      } catch (e) {
        let initialGridData = this.state.initialGridData;
        this.setState(
          {
            ...this.state,
            gridData: initialGridData,
            openErrorModal: true,
            errorModalContent:
              e.response.data.error.message || "Please try again",
          },
          () => this.discard(dataItem, true)
        );
      }
    }
  };

  cancel = (dataItem = {}) => {
    const originalItem =
      this.state.initialGridData &&
      this.state.initialGridData.data.find((p) => p.id === dataItem.id);
    originalItem.inEdit = false;

    const data =
      this.state.initialGridData &&
      this.state.initialGridData.data.map((item) =>
        item.id === originalItem.id ? originalItem : item
      );

    const total = this.state.initialGridData.total;

    this.setState({
      ...this.state,
      gridData: {
        data: data,
        total: total,
      },
    });
  };

  updateItem = (data, item) => {
    let index = data.findIndex(
      (p) => p === item || (item.id && p.id === item.id)
    );
    if (index >= 0) {
      data[index] = { ...item };
    }
  };

  itemChange = (event) => {
    const total = this.state.gridData.total;
    const data =
      this.state.gridData &&
      this.state.gridData.data.map((item) =>
        item.id === event.dataItem.id
          ? { ...item, [event.field]: event.value }
          : item
      );

    this.setState({
      gridData: {
        data: data,
        total: total,
      },
    });
  };

  addNew = () => {
    let checkForEditDato = this.state.gridData.data;
    const found = checkForEditDato.some((item) => item.inEdit === true);
    if (found) return;

    const newDataItem = { inEdit: true };

    this.setState({
      gridData: {
        data: [newDataItem, ...this.state.gridData.data],
        total: this.state.gridData.total,
      },
      visible: true,
    });
  };

  removeItem(data, item) {
    let index = data.findIndex((p) => p === item || p.id === item.id);
    if (index >= 0) {
      data.splice(index, 1);
    }
  }

  dataStateChange = (e) => {
    const reqGridParams = e.data;
    window.localStorage.setItem("finalParams", JSON.stringify(reqGridParams));

    this.setState({
      ...this.state,
      dataState: reqGridParams,
    });
  };

  dataRecieved = (gridData, reqGridParams) => {
    const finalData = formatDatesOnTable(gridData, [
      "last_login_date",
      "last_password_change",
      "created",
      "lastUpdated",
      "termination_date",
    ]);

    this.setState({
      ...this.state,
      gridData: finalData,
      initialGridData: finalData,
      dataState:
        reqGridParams === !_.isEmpty(reqGridParams)
          ? reqGridParams
          : this.state.dataState,
    });
  };

  onClearFilters = () => {
    let hasFinalParams = JSON.parse(window.localStorage.getItem("finalParams"));

    let init = JSON.stringify({ take: 100, skip: 0 });
    if (hasFinalParams && hasFinalParams.filter)
      window.localStorage.setItem("finalParams", init);

    this.dataStateChange({ data: { take: 100, skip: 0 } });
  };

  handleContextMenuOpen = (e, dataItem) => {
    this.dataItem = dataItem;
    this.dataItemIndex = this.state.gridData.data.findIndex(
      (p) => p.id === this.dataItem.id
    );
    this.offset = { left: e.clientX, top: e.clientY };

    if (_.isEmpty(this.state.openDataItem)) {
      //todo handle two popup opened
      this.setState({
        open: true,
        openDataItem: dataItem,
      });
    } else {
      return;
    }
  };

  rowRender = (trElement, dataItem) => {
    const trProps = {
      ...trElement.props,
      onContextMenu: (e) => {
        e.preventDefault();

        const { status } = dataItem.dataItem;
        if (status === "TERMINATED") {
          this.setState(
            {
              ...this.state,
              rightClickText: ["Enable user"],
            },
            () => {}
          );
        } else if (status === "ACTIVE") {
          this.setState(
            {
              ...this.state,
              rightClickText: ["Change password", "Terminate User"],
            },
            () => {}
          );
        } else {
          return;
        }

        this.handleContextMenuOpen(e, dataItem.dataItem);
      },
      style: {
        // color: 'orange'
      },
    };

    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  redirectToEditClients = (id) => {
    this.props.history.push(`/app/master/user-to-clients/edit/${id}`);
  };

  goToChangePasswordPage = () => {
    this.openChangePasswordWindow();
  };

  openChangePasswordWindow = async () => {
    const options = { token: window.localStorage.getItem("access_token") };

    let tempEmails = [];
    const getAllOperators = await serverApi(
      "GET",
      `snuserClientVs`,
      "",
      "",
      options,
      ""
    );
    let allEmailsArr = getAllOperators?.data || [];
    let opsDepts = allEmailsArr.filter((x) => x.email !== null);
    opsDepts.forEach((temp) => {
      tempEmails.push({
        email: temp.email,
      });
    });
    let emailsArr = tempEmails.sort((a, b) => (a.email > b.email ? 1 : -1));

    this.setState({
      ...this.state,
      changePasswordWindow: true,
      emailsAvailable: tempEmails.map((x) => x.email),
      emailsArr: emailsArr,
    });
  };

  goToTerminateUserPage = () => {
    this.openTerminateWindow();
  };

  openTerminateWindow = () => {
    this.setState({
      ...this.state,
      terminateUserWindow: true,
    });
  };

  closeSetCustomPasswordWindow = () => {
    this.setState({
      ...this.state,
      setCustomPasswordWindow: false,
      changePasswordWindow: false,
      openDataItem: {},
    });
  };

  openSetCustomPasswordWindow = () => {
    this.setState({
      ...this.state,
      setCustomPasswordWindow: true,
      changePasswordWindow: false,
    });
  };

  refreshGridDataAfterCrud = async () => {
    let token = window.localStorage.getItem("access_token");
    let options = {
      token: token,
    };

    let refreshParams = window.localStorage.getItem("refreshParams");
    refreshParams = JSON.parse(refreshParams);

    const responseData = await serverApi(
      "GET",
      "snuserClientVs",
      refreshParams,
      "",
      options
    );

    const finalData = formatDatesOnTable(responseData, [
      "last_login_date",
      "last_password_change",
      "created",
      "lastUpdated",
      "termination_date",
    ]);

    let count;
    const countMasterEntity = await serverApi(
      "GET",
      `snuserClientVs/count?where=${JSON.stringify(
        refreshParams.filter.where
      )}`,
      "",
      "",
      options
    );
    if (countMasterEntity) {
      count = countMasterEntity.data.count;
    }

    let sendObj = {
      gridData: {
        data: finalData.data,
        total: count,
      },
    };

    this.dataRecieved(sendObj.gridData, null);
  };

  enableUser = async () => {
    const options = { token: window.localStorage.getItem("access_token") };
    let payload = {
      username: this.state.openDataItem.username,
      status: "ACTIVE",
    };

    try {
      const enable = await serverApi(
        "POST",
        "snUsers/enableUser",
        "",
        payload,
        options
      );
      if (enable) {
        this.setState(
          {
            ...this.state,
            terminateUserWindow: false,
            openDataItem: {},
            openSuccessModal: true,
            successModalContent: `Enable user process has finished successfully.`,
          },
          () => {
            setTimeout(() => {
              this.refreshGridDataAfterCrud();
            }, 3000);
          }
        );
      }
    } catch (e) {
      this.setState({
        ...this.state,
        terminateUserWindow: false,
        openDataItem: {},
        openErrorModalMui: true,
        errorModalContentMui:
          e?.response?.data?.error?.message ||
          "Enable of user process has failed.Please try again.",
      });
    }
  };

  handleOnSelect = (e) => {
    let id = this.state.openDataItem.id;
    let username = this.state.openDataItem.username;

    switch (e.item.text) {
      // case "Add/Remove Clients":
      //   this.redirectToEditClients(id);
      //   break;
      case "Enable user":
        this.enableUser(id, username);
        break;
      case "Change Password":
        this.goToChangePasswordPage();
        break;
      case "Terminate User":
        this.goToTerminateUserPage(id, username);
        break;
      default:
        break;
    }

    this.setState({
      open: false,
    });
  };

  onFocusHandler = () => {
    clearTimeout(this.blurTimeoutRef);
    this.blurTimeoutRef = undefined;
  };

  onBlurTimeout = () => {
    this.setState({
      open: false,
      openDataItem: {},
    });

    this.blurTimeoutRef = undefined;
  };

  onBlurHandler = (event) => {
    clearTimeout(this.blurTimeoutRef);
    this.blurTimeoutRef = setTimeout(this.onBlurTimeout);
  };

  onPopupOpen = () => {
    this.menuWrapperRef.querySelector("[tabindex]").focus();
  };

  handleColumnsChange = (event) => {
    let columns = this.state.columns;

    let oldColumns = columns.filter((c) => c.visible).map((c) => c.field);
    let newColumns = event.target.value;

    let unique1 = oldColumns.filter((c) => newColumns.indexOf(c) === -1);
    let unique2 = newColumns.filter((c) => oldColumns.indexOf(c) === -1);

    const columnLastUpdated = unique1.concat(unique2)[0];

    let index = columns.findIndex(
      (column) => column.field === columnLastUpdated
    );
    columns[index].visible = !columns[index].visible;

    this.setState(
      {
        ...this.state,
        columns: columns,
      },
      () => {
        let columns = this.state.columns;
        window.localStorage.setItem("cols", JSON.stringify(columns));
      }
    );
  };

  getExportData = async () => {
    await this.exportToCsv(this.state, this.props);
  };

  getPromise = (filter) => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    return new Promise((resolve, reject) => {
      const operatorsRequest = serverApi(
        "GET",
        `snuserClientVs?filter=${JSON.stringify(filter)}`,
        "",
        "",
        options
      );
      operatorsRequest
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  exportToCsv = async (state, props) => {
    const count = state.gridData.total;
    const where = window.localStorage.getItem("exportFilter") || {};
    const numberOfCalls = Math.min(
      Math.round(count / 1000) + 1,
      5 // Limit to 5000
    );
    let finalSort = this.state.dataState?.sort?.length
      ? this.state.dataState.sort[0].field +
        " " +
        this.state.dataState.sort[0].dir
      : "id asc";

    const promises = [];
    for (let i = 0; i < numberOfCalls; i++) {
      promises.push(
        this.getPromise({
          skip: i * 1000,
          limit: 1000,
          where: !_.isEmpty(where) ? JSON.parse(where) : {},
          order: [finalSort],
        })
      );
    }

    try {
      const responses = await Promise.all(promises);
      let allData = [];
      responses.forEach((resp) => {
        allData = [...allData, ...resp.data];
      });
      if (allData.length > 0) {
        this.setState({
          ...this.state,
          exportData: allData,
        });
      }
    } catch (e) {
      this.setState({
        ...this.state,
        openErrorModal: true,
        errorModalContent: e.response.data.error.message || "Please try again",
      });
    }
  };

  closeErrorModalMui = () => {
    this.setState({ openErrorModalMui: false, errorModalContentMui: "" });
  };

  closeSuccessModal = () => {
    this.setState({ openSuccessModal: false, successModalContent: "" });
  };

  closeWindow = () => {
    this.setState({
      ...this.state,
      visibleWindow: false,
      emailHasError: false,
      selectedClients: [],
      selectedEmails: [],
      emailsAvailable: [],
      clientsAvailable: [],
    });
  };

  closeChangePasswordWindow = () => {
    this.setState({
      ...this.state,
      changePasswordWindow: false,
      openDataItem: {},
    });
  };

  terminateUser = async () => {
    const options = { token: window.localStorage.getItem("access_token") };
    let payload = {
      username: this.state.openDataItem.username,
      status: "TERMINATED",
    };

    this.setState({
      ...this.state,
      disableButton: true,
    });

    try {
      const terminateRequest = await serverApi(
        "POST",
        "snUsers/terminateClient",
        "",
        payload,
        options
      );
      if (terminateRequest) {
        this.setState(
          {
            ...this.state,
            terminateUserWindow: false,
            openDataItem: {},
            openSuccessModal: true,
            successModalContent: `Terminate of client user process has finished successfully.`,
            disableButton: false,
          },
          () => {
            setTimeout(() => {
              this.refreshGridDataAfterCrud();
            }, 3000);
          }
        );
      }
    } catch (e) {
      this.setState({
        ...this.state,
        terminateUserWindow: false,
        openDataItem: {},
        openErrorModalMui: true,
        errorModalContentMui:
          e?.response?.data?.error?.message ||
          "Terminate of client user process failed.Please try again",
        disableButton: false,
      });
    }
  };

  autogenerateAndChangePassword = () => {
    this.setState({
      ...this.state,
      disableButton: true,
    });
    let params = {
      newPassword: "**********",
      confirmNewPassword: "**********",
      customPassword: true,
      typeOfUser: this.state.typeOfUser,
      informOperatorEmail: this.state.selectedEmails,
    };
    const url2 = `snUsers/update-password-from-token`;
    const userEmail = this.state.openDataItem.email;
    const username = this.state.openDataItem.username;
    let options = {
      token: window.localStorage.getItem("access_token"),
    };
    const changePassResponse = ChangePasswordCall(
      params,
      url2,
      userEmail,
      username,
      options
    );
    changePassResponse
      .then(async (response) => {
        if (response) {
          let params = {
            filter: {
              limit: `100`,
              skip: `0`,
            },
          };
          const url = "snuserClientVs";
          let count;
          let responseData = await serverApi(
            "GET",
            `${url}`,
            params,
            "",
            options
          );
          const countData = await serverApi(
            "GET",
            `snuserClientVs/count`,
            "",
            "",
            options
          );
          if (countData) {
            count = countData.data.count;
          }

          setTimeout(() => {
            this.setState({
              ...this.state,
              changePasswordWindow: false,
              openDataItem: {},
              gridData: {
                data: responseData.data,
                total: count,
              },
              initialGridData: {
                data: responseData.data,
                total: count,
              },
              openSuccessModal: true,
              disableButton: false,
              successModalContent: `Change password was successfully completed.`,
              selectedEmails: [],
              emailsAvailable: [],
            });
          }, 500);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          this.setState({
            openErrorModal: true,
            errorModalContent:
              error?.response?.data?.error?.message ||
              "Change password process failed.",
            loading: false,
            disableButton: false,
            changePasswordWindow: false,
          });
        }, 500);
      });
  };

  handleClientsChange = (event) => {
    this.setState({
      ...this.state,
      selectedClients: event.target.value,
    });
  };

  handleEmailsChange = (event) => {
    this.setState({
      ...this.state,
      selectedEmails: event.target.value,
    });
  };

  handleErrorClose = () => {
    this.setState({
      ...this.state,
      openErrorModal: false,
      openErrorModalContent: "",
    });
  };

  toggleWindow = async (e) => {
    const options = { token: window.localStorage.getItem("access_token") };

    let tempEmails = [];
    const getAllOperators = await serverApi(
      "GET",
      `snuserOperatorVs`,
      "",
      "",
      options,
      ""
    );
    const allRoles = await serverApi("GET", "roles", "", "", options);
    let finalRoles = [];
    _.forEach(allRoles.data, (temp1) => {
      finalRoles.push({
        name: temp1.name || "N/A",
        id: temp1.id,
      });
    });

    finalRoles = finalRoles.sort((a, b) => (a.name > b.name ? 1 : -1));
    finalRoles = finalRoles.filter((x) => x.name !== "client");

    let allEmailsArr = getAllOperators?.data || [];
    let opsDepts = allEmailsArr.filter((x) => x.email !== null);
    opsDepts.forEach((temp) => {
      tempEmails.push({
        email: temp.email,
      });
    });
    let emailsArr = tempEmails.sort((a, b) => (a.email > b.email ? 1 : -1));

    let tempClients = [];
    async function getAllRecords() {
      const allRecords = [];

      async function getRecords(offset = 0) {
        try {
          // Make API call using axios
          const response = await serverApi(
            "GET",
            `masterEntities?filter={"where":{"client_flag":true},"limit":"1000","skip":${offset}}`,
            "",
            "",
            options,
            ""
          );
          const data = response.data;

          // Append retrieved records to the result array
          allRecords.push(...data);

          if (data.length === 1000) {
            // If there are more records, recursively call the function with an increased offset
            await getRecords(offset + 1000);
          }
        } catch (error) {
          // Handle API errors
          console.error("Error:", error);
        }
      }

      await getRecords();

      return allRecords;
    }

    // Usage example
    getAllRecords()
      .then((records) => {
        // Process the retrieved records here
        let allClientsArr = records || [];
        allClientsArr.forEach((temp) => {
          tempClients.push({
            relation_name: temp.relation_name,
            clientID: temp.id,
          });
        });
        let clientsArr = tempClients.sort((a, b) =>
          a.relation_name > b.relation_name ? 1 : -1
        );

        this.setState({
          ...this.state,
          clientsAvailable: tempClients.map((x) => x.relation_name),
          clientsArr: clientsArr,
          emailsAvailable: tempEmails.map((x) => x.email),
          emailsArr: emailsArr,
          allRoles: finalRoles.map((x) => x.name),
          blocking: false,
          visibleWindow: !this.state.visibleWindow,
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        this.setState(
          {
            ...this.state,
            blocking: false,
            openErrorModal: true,
            errorModalContent:
              e?.response?.data?.error?.message || "Please try again",
          },
          () => {
            setTimeout(() => {
              this.handleErrorClose();
            }, 4000);
          }
        );
      });
  };

  onDialogInputChange = (event) => {
    let target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.props ? target.props.name : target.name;

    if (name === "email") {
      let isEmailOk = verifyEmail(value);

      if (isEmailOk !== false) {
        this.setState({
          ...this.state,
          gridData: {
            ...this.state.gridData,
            [name]: value,
          },
          [name]: value,
          emailHasError: false,
        });
      } else {
        this.setState({
          ...this.state,
          gridData: {
            ...this.state.gridData,
            [name]: value,
          },
          [name]: value,
          emailHasError: true,
        });
      }
    }

    if (name !== "email") {
      this.setState({
        ...this.state,
        gridData: {
          ...this.state.gridData,
          [name]: value,
        },
        [name]: value,
      });
    }
  };

  createUser = async () => {
    let options = {
      token: window.localStorage.getItem("access_token"),
    };

    let finalClients = [];
    _.forEach(this.state.selectedClients, (selectedClient) => {
      _.forEach(this.state.clientsArr, (client) => {
        if (selectedClient === client.relation_name) {
          finalClients.push(client);
        }
      });
    });

    let payload = {
      username: this.state.username,
      email: this.state.email,
      userRealName: this.state.userRealName,
      clients: finalClients,
      emailTo: this.state.selectedEmails,
      uiRoles: this.state.selectedRoles,
    };

    try {
      const create = await serverApi(
        "POST",
        "snUsers/createClient",
        "",
        payload,
        options
      );
      if (create) {
        let count;
        let params = {
          filter: {
            limit: `100`,
            skip: `0`,
          },
        };
        const url = "snuserClientVs";
        let responseData = await serverApi(
          "GET",
          `${url}`,
          params,
          "",
          options
        );
        const countMasterEntity = await serverApi(
          "GET",
          `snuserClientVs/count`,
          "",
          "",
          options
        );
        if (countMasterEntity) {
          count = countMasterEntity.data.count;
        }

        this.setState({
          ...this.state,
          gridData: {
            data: responseData.data,
            total: count,
          },
          initialGridData: {
            data: responseData.data,
            total: count,
          },
          selectedDepartments: [],
          departmentsAvailable: [],
          selectedClients: [],
          clientsAvailable: [],
          selectedEmails: [],
          emailsAvailable: [],
          visibleWindow: false,
          openSuccessModal: true,
          successModalContent: `Create client user process finished successfully.`,
          selectedRoles: [],
        });
      }
    } catch (e) {
      this.setState(
        {
          ...this.state,
          openErrorModal: true,
          errorModalContent:
            e?.response?.data?.error?.message || "Please try again",
        },
        () => {
          setTimeout(() => {
            this.setState({
              ...this.state,
              openErrorModal: false,
              errorModalContent: "",
            });
          }, 5000);
        }
      );
    }
  };

  closeTerminateWindow = () => {
    this.setState({
      ...this.state,
      terminateUserWindow: false,
      openDataItem: {},
    });
  };

  handleRolesChange = (event) => {
    this.setState({
      ...this.state,
      selectedRoles: event.target.value,
    });
  };

  renderCreateClientForm = () => {
    return (
      <div>
        <fieldset style={{ borderColor: "rgba(13, 88, 105, 1)" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <h3>Real Name</h3>
              <Input
                style={{ width: "500px" }}
                name="userRealName"
                value={
                  this.state.gridData.data.filter(
                    (temp) => temp.inEdit === true
                  ).userRealName
                }
                onChange={this.onDialogInputChange}
                // placeholder="e.g.: Firstname Lastname"
              />
            </span>
            <span>
              <h3>Username</h3>
              <Input
                style={{ width: "500px" }}
                name="username"
                value={
                  this.state.gridData.data.filter(
                    (temp) => temp.inEdit === true
                  ).username
                }
                onChange={this.onDialogInputChange}
                // placeholder="e.g.: f.lastname (please use the real name to provide a username)"
              />
            </span>
            <span>
              <h3>Email</h3>
              <Input
                style={{ width: "500px" }}
                name="email"
                value={
                  this.state.gridData.data.filter(
                    (temp) => temp.inEdit === true
                  ).email
                }
                onChange={this.onDialogInputChange}
                placeholder="e.g.: example@mail.com"
              />
              {
                <div>
                  <h3 style={{ color: "#ff9d00" }}>
                    {this.state.emailHasError === true
                      ? "Please enter a valid email address such as : example@mail.com"
                      : ""}
                  </h3>
                </div>
              }
            </span>
            <span>
              <h3>Clients</h3>
              <MultiSelect
                data={this.state.clientsAvailable || []}
                value={this.state.selectedClients}
                onChange={this.handleClientsChange}
                placeholder="Select at least one client from the dropdown."
              />
            </span>
            <span>
              <h3>Roles</h3>
              <MultiSelect
                data={this.state.allRoles || []}
                value={this.state.selectedRoles}
                onChange={this.handleRolesChange}
                placeholder="Select role from the dropdown.(optional)"
                header={
                  <span
                    style={{
                      marginLeft: "60px",
                      color: "orange",
                      fontSize: "20px",
                    }}
                  >
                    Client role is preselected and cannot be removed.
                  </span>
                }
              />
            </span>
            <span>
              <h3>Operator Email</h3>
              <MultiSelect
                data={this.state.emailsAvailable || []}
                value={this.state.selectedEmails}
                onChange={this.handleEmailsChange}
                placeholder="Select at least one email from the dropdown."
              />
            </span>
          </div>
          <br />
          <button
            type="button"
            className="k-button k-primary"
            onClick={(e) => this.createUser(e)}
            disabled={
              this.state.selectedEmails.length < 1 ||
              this.state.selectedClients.length < 1 ||
              this.state.username === "" ||
              this.state.email === "" ||
              this.state.userRealName === "" ||
              this.state.emailHasError === true
                ? true
                : false
            }
          >
            Submit
          </button>
        </fieldset>
        <br />
      </div>
    );
  };

  expandChange = (event) => {
    window.localStorage.setItem("expandeId", event.dataItem.id);

    if (this.state.gridData.data[0].inEdit === true) {
      return;
    } else {
      event.dataItem.expanded = !event.dataItem.expanded;
    }

    this.forceUpdate();
  };

  render() {
    const { gridData, columns } = this.state;
    const hasEditedItem = gridData && gridData.data.some((p) => p.inEdit);

    return (
      <div>
        <Popup
          offset={this.offset}
          show={this.state.open}
          open={this.onPopupOpen}
          popupClass={"popup-content"}
        >
          <div
            onFocus={this.onFocusHandler}
            onBlur={this.onBlurHandler}
            tabIndex={-1}
            ref={(el) => (this.menuWrapperRef = el)}
          >
            <Menu
              vertical={true}
              style={{ display: "inline-block" }}
              onSelect={this.handleOnSelect}
            >
              {/*<KendoMenuItem text="Add/Remove Clients"/>*/}
              {/* <KendoMenuItem text="Change Password" /> */}
              {/* <KendoMenuItem text="Terminate User" /> */}

              {(this.state.rightClickText || []).map((text) => {
                return <KendoMenuItem text={text} />;
              })}
            </Menu>
          </div>
        </Popup>
        <Grid
          {...this.state.dataState}
          {...this.state.gridData}
          filterable={true}
          style={{ height: "92vh" }}
          sortable={true}
          detail={(dataItem) => (
            <div>
              <ExpandedTabs
                tabs={[
                  {
                    label: "Clients",
                    component: <UserToClient {...dataItem} />, // Index
                  },
                ]}
              ></ExpandedTabs>
            </div>
          )}
          expandField="expanded"
          onExpandChange={this.expandChange}
          resizable
          editField={this.editField}
          rowRender={this.rowRender}
          onItemChange={this.itemChange}
          onDataStateChange={this.dataStateChange}
          pageable={{
            buttonCount: 5,
            info: true,
            pageSizes: [100, 200, 500, 1000, 1500],
          }}
        >
          <GridToolbar>
            <GridContainer xs={12} direction={"row"}>
              <Tooltip
                justify={"flex-start"}
                placement="top"
                title={"Create Client"}
              >
                <IconButton onClick={this.toggleWindow}>
                  <FontAwesomeIcon
                    color="#0D5869"
                    // onClick={this.toggleWindow}
                    size="1.6x"
                    icon={faPlus}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip
                justify={"flex-start"}
                placement="top"
                title={"Clear Filters"}
              >
                <IconButton
                  disabled={false}
                  aria-label=""
                  onClick={(e) => this.onClearFilters(e)}
                  look="flat"
                >
                  <FontAwesomeIcon
                    color="#0D5869"
                    size="1.6x"
                    icon={faEraser}
                  />
                </IconButton>
              </Tooltip>
              <ViewSettings
                {...this.props}
                export={{
                  data: this.state.exportData || [],
                  fileName: "Client_Users_",
                  exportFunction: this.getExportData,
                  columns: columnsSchema(),
                }}
                viewSettings={{
                  type: "",
                }}
                refreshSettings={{
                  data: [],
                }}
              />
              <FormControl className={customSelectStyles.selectFormControl}>
                <InputLabel
                  htmlFor="multiple-select"
                  className={customSelectStyles.selectLabel}
                ></InputLabel>
                <Select
                  autoWidth={true}
                  multiple
                  value={columns
                    .filter((column) => column.visible)
                    .map((column) => column.field)}
                  onChange={this.handleColumnsChange}
                  MenuProps={{ className: customSelectStyles.selectMenu }}
                  inputProps={{
                    name: "multipleSelect",
                    id: "multiple-select",
                  }}
                  renderValue={(selected) => "Columns"}
                >
                  {columns
                    .filter((col) => !col.notShownInTableColumnSelection)
                    .map((column) => {
                      let name = column.title;
                      let value = column.field;
                      let isVisible = column.visible;
                      let isDisabled = column.noDisabledColumn;

                      return (
                        <MenuItem
                          style={{ backgroundColor: "white" }}
                          key={name}
                          value={value}
                          disabled={isDisabled}
                        >
                          <Checkbox color={"default"} checked={isVisible} />
                          <ListItemText
                            style={{ marginLeft: "7px" }}
                            primary={name}
                          />
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </GridContainer>
          </GridToolbar>

          <Column
            cell={this.CommandCell}
            title="Actions"
            filterable={false}
            width={hasEditedItem ? "240px" : "140px"}
          />
          {this.renderGridColumns()}
        </Grid>

        <GridLoader
          dataState={this.state.dataState}
          onDataRecieved={this.dataRecieved}
          getURL={"snuserClientVs"}
        />

        <SuccessModal
          open={this.state.openSuccessModal}
          onCloseModal={this.closeSuccessModal}
          modalContent={this.state.successModalContent}
          timeout={3500}
        />

        <ErrorModal
          open={this.state.openErrorModalMui}
          onCloseModal={this.closeErrorModalMui}
          modalContent={this.state.errorModalContentMui}
          timeout={3500}
        />

        <Snackbar
          place="tc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"success"}
          message={this.state.successMessage}
          open={this.state.openSuccessMessage}
          closeNotification={() =>
            this.handleSuccessSnackbar(this.state.successMessage)
          }
          close
        />

        <Snackbar
          place="tc"
          style={{
            width: "90%",
            overflow: "hidden",
          }}
          color={"warning"}
          message={this.state.errorModalContent}
          open={this.state.openErrorModal}
          closeNotification={() =>
            this.handleErrorClose(this.state.errorModalContent)
          }
          // close
        />

        {this.state.visibleWindow && (
          <Window
            // title={<TitleComponent/>}
            title={"Create new client user"}
            onClose={this.closeWindow}
            width={950}
            height={800}
          >
            {this.renderCreateClientForm()}
          </Window>
        )}

        {this.state.changePasswordWindow && (
          <Window
            title={`Change password process for client user: ${this.state.openDataItem.username}`}
            onClose={this.closeChangePasswordWindow}
            width={950}
            height={650}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <fieldset style={{ borderColor: "rgba(13, 88, 105, 1)" }}>
                <div
                  style={{
                    fontSize: "20px",
                    marginTop: "20px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  In case you click YES, the change password process will start
                  automatically by generating a secure password for the user. In
                  addition, in case you want to inform an operator for the
                  process, choose from the dropdown the email of the operator,
                  that will receive the verification email.
                  <br />
                  <h3>EMAIL TO:</h3>
                  <MultiSelect
                    data={this.state.emailsAvailable || []}
                    value={this.state.selectedEmails}
                    onChange={this.handleEmailsChange}
                    placeholder="Optional operator email."
                  />
                </div>
                <br />
                <h3>
                  Please note that after the process has succeeded, an email
                  will be sent also to helpdesk@swiftmarine.eu for security
                  reasons.
                </h3>
                <br />
                <span>Are you sure you want to continue?</span>
                <div>
                  <Button
                    size="md"
                    color="success"
                    type="button"
                    disabled={this.state.disableButton}
                    onClick={(e) => {
                      this.autogenerateAndChangePassword();
                    }}
                  >
                    YES
                  </Button>
                  <Button
                    color="warning"
                    size="md"
                    type="button"
                    onClick={(e) => this.openSetCustomPasswordWindow()}
                  >
                    NO
                  </Button>
                </div>
              </fieldset>
            </div>
          </Window>
        )}

        {this.state.terminateUserWindow && (
          <Window
            title={`Terminate process for client : ${this.state.openDataItem.username}`}
            onClose={this.closeTerminateWindow}
            width={800}
            height={350}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <fieldset style={{ borderColor: "rgba(13, 88, 105, 1)" }}>
                <div
                  style={{
                    fontSize: "20px",
                    marginTop: "20px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  After the termination process has succeeded, the client will
                  not be able to login to navigator.
                  <br />
                  {/*<span>In case you need to enable again the operator, you should visit the users view and start the enable operator process.</span>*/}
                  <br />
                  <span>Are you sure you want to continue?</span>
                </div>
                <Button
                  size="sm"
                  color="warning"
                  type="button"
                  disabled={this.state.disableButton}
                  onClick={(e) => this.terminateUser(e)}
                >
                  Terminate
                </Button>
              </fieldset>
            </div>
          </Window>
        )}

        {this.state.setCustomPasswordWindow && (
          <Window
            title={`Change Password Process`}
            onClose={this.closeSetCustomPasswordWindow}
            width={700}
            height={600}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <fieldset style={{ borderColor: "rgba(13, 88, 105, 1)" }}>
                <ChangePassword
                  openDataItem={this.state.openDataItem}
                  closeSetCustomPasswordWindow={
                    this.closeSetCustomPasswordWindow
                  }
                  typeOfUser={this.state.typeOfUser}
                  informOperatorEmail={this.state.selectedEmails}
                />
              </fieldset>
            </div>
          </Window>
        )}
      </div>
    );
  }
}

export default ClientUsers;
