import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@material-ui/core";
import React from "react";
import "./PrimaryTextField.scss";
import Icon from "components/Icon/Icon";
import { faIcons } from "components/Icon/Icon";

export default function PrimaryTextField({
  startIcon,
  iconButton,
  hasError,
  errorMessage,
  warningMessage,
  iconAfter,
  rootClassName,
  formControlProps,
  ...textFieldProps
}) {
  const value = textFieldProps.value === 0 ? 0 : textFieldProps.value || "";
  let textfieldClass = "";
  if (value) {
    if (iconButton?.showOnHover) {
      textfieldClass = "show-icon-on-hover";
    } else {
      textfieldClass = "show-icon-always";
    }
  }
  const formProps = {
    fullWidth:
      textFieldProps.fullWidth == null ? true : textFieldProps.fullWidth,
    ...formControlProps,
  };
  return (
    <FormControl {...formProps} variant="outlined" error={hasError}>
      <div>
        <TextField
          fullWidth
          {...textFieldProps}
          error={hasError}
          value={value}
          type={textFieldProps.type || "text"}
          variant="outlined"
          classes={{
            root: `sm-outlined-input-small ${textfieldClass} ${
              rootClassName || ""
            }`,
          }}
          InputProps={{
            startAdornment: startIcon && (
              <InputAdornment position="start">{startIcon}</InputAdornment>
            ),
            endAdornment: iconButton && (
              <Tooltip title={iconButton.tooltip || ""}>
                <InputAdornment position="end">
                  <IconButton
                    onClick={iconButton.onClick}
                    classes={{
                      root: "sm-textfield-icon",
                    }}
                    edge="end"
                  >
                    {iconButton.icon}
                  </IconButton>
                </InputAdornment>
              </Tooltip>
            ),
          }}
        />
        {iconAfter &&
          !Array.isArray(iconAfter) &&
          (!iconAfter.visibleOn || iconAfter.visibleOn(value)) && (
            <span className="sm-icon-button-small-wrap">
              <Tooltip
                title={iconAfter.title}
                interactive={iconAfter.interactive}
              >
                <IconButton
                  onClick={() => {
                    if (iconAfter.onClick) iconAfter.onClick();
                  }}
                  classes={{ root: "sm-icon-button-small" }}
                >
                  {iconAfter.icon}
                </IconButton>
              </Tooltip>
            </span>
          )}
        {iconAfter &&
          Array.isArray(iconAfter) &&
          iconAfter.map((icAfter, idx) =>
            !icAfter.visibleOn || icAfter.visibleOn(value) ? (
              <span className="sm-icon-button-small-wrap" key={idx}>
                <Tooltip
                  title={icAfter.title}
                  interactive={icAfter.interactive}
                >
                  <IconButton
                    onClick={() => {
                      if (icAfter.onClick) icAfter.onClick();
                    }}
                    classes={{ root: "sm-icon-button-small" }}
                  >
                    {icAfter.icon}
                  </IconButton>
                </Tooltip>
              </span>
            ) : (
              ""
            )
          )}
      </div>
      <FormHelperText>{errorMessage}</FormHelperText>
      {warningMessage && (
        <div className="sm-input-warning">
          <Icon icon={faIcons.faExclamationTriangle} />
          <span>{warningMessage}</span>
        </div>
      )}
    </FormControl>
  );
}
